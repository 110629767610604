
import React from 'react'
import { Editor, Transforms, Element as SlateElement } from 'slate'

import { MdFormatAlignCenter, MdFormatAlignJustify, MdFormatAlignLeft, MdFormatAlignRight } from 'react-icons/md'

const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify']
const textAlignIcons = {
    'left': MdFormatAlignLeft,
    'center': MdFormatAlignCenter,
    'right': MdFormatAlignRight,
    'justify': MdFormatAlignJustify
}

export default function (props) {

    const { editor } = props

    const { align } = props

    function isFormatActive() {
        const { selection } = editor
        if (!selection) return false

        const [match] = Array.from(
            Editor.nodes(editor, {
                at: Editor.unhangRange(editor, selection),
                match: n => {
                    // console.log("n : ", n, Editor.isEditor(n), SlateElement.isElement(n));
                    return !Editor.isEditor(n) && SlateElement.isElement(n) && n.align === align
                }

            })
        )

        return !!match
    }

    function toggleFormat() {
        const isActive = isFormatActive()

        Transforms.unwrapNodes(editor, {
            match: n =>
                !Editor.isEditor(n) &&
                SlateElement.isElement(n) &&
                !TEXT_ALIGN_TYPES.includes(align),
            split: true,
        })

        const newProperties = {
            align: isActive ? undefined : align,
        }
        Transforms.setNodes(editor, newProperties)
    }

    const TextAlignIcon = textAlignIcons[align]

    return (
        <button className={`editor-button ${isFormatActive() ? " active" : ""}`} onClick={() => toggleFormat()}>
            <TextAlignIcon style={{ fontSize: 14 }} />
        </button>
    )

}
