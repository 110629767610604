// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Dict$BwaxMobile = require("../dict.bs.js");
var Json$BwaxMobile = require("../utils/json.bs.js");
var Plate$BwaxMobile = require("../plate.bs.js");
var Lang_eval$BwaxMobile = require("./lang_eval.bs.js");
var Query_env$BwaxMobile = require("../query/query_env.bs.js");
var Lang_runtime$BwaxMobile = require("./lang_runtime.bs.js");
var Lang_mod_json$BwaxMobile = require("./mod/builtin/lang_mod_json.bs.js");
var Query_builder$BwaxMobile = require("../query/query_builder.bs.js");
var Command_engine$BwaxMobile = require("./command_engine.bs.js");
var Query_commands$BwaxMobile = require("../query/query_commands.bs.js");
var Query_env_cmdm$BwaxMobile = require("../query/query_env_cmdm.bs.js");
var Runtime_common$BwaxMobile = require("./mod/runtime_common.bs.js");
var Lang_entry_base$BwaxMobile = require("./lang_entry_base.bs.js");
var Base_query_types$BwaxMobile = require("../base_query_types.bs.js");
var Lang_typing_print$BwaxMobile = require("./lang_typing_print.bs.js");
var Runtime_mod_random$BwaxMobile = require("./mod/runtime/runtime_mod_random.bs.js");
var Record_dependency_resolver$BwaxMobile = require("./record_dependency_resolver.bs.js");

function list_length(l) {
  return Plate$BwaxMobile.List.length(l);
}

function build_external_env(module_name, externals) {
  return Dict$BwaxMobile.$$String.from_list(Plate$BwaxMobile.List.assoc_map((function (prim) {
                    return /* record */[/* contents */prim];
                  }), Plate$BwaxMobile.List.map((function (param) {
                        var k = param[0];
                        var nk = module_name !== undefined ? module_name + ("." + k) : k;
                        return /* tuple */[
                                nk,
                                /* tuple */[
                                  param[1],
                                  undefined
                                ]
                              ];
                      }), externals)));
}

function build_env(base_env, modules, externals) {
  var external_env = Plate$BwaxMobile.List.foldl((function (acc_env, param) {
          return Dict$BwaxMobile.$$String.union(build_external_env(param[0], param[1]), acc_env);
        }), base_env, externals);
  var iter = function (acc_env, param) {
    return Lang_eval$BwaxMobile.evaluate_defs(acc_env, /* tuple */[
                  param[0],
                  param[1]
                ])[0];
  };
  return Plate$BwaxMobile.List.foldl(iter, external_env, modules);
}

function get_genv(modules) {
  return build_env(Dict$BwaxMobile.$$String.empty(/* () */0), modules, Lang_runtime$BwaxMobile.common_runtime_externals);
}

function prepare_eval_env(gmods, entity_dict, data_type_dict, base_mods) {
  var query_externals = Query_env$BwaxMobile.get_query_externals(entity_dict);
  var cmd_externals = Query_env_cmdm$BwaxMobile.get_cmd_externals(entity_dict);
  var genv = get_genv(gmods);
  return build_env(genv, Plate$BwaxMobile.$$Option.with_default(/* [] */0, base_mods), Pervasives.$at(query_externals, cmd_externals));
}

function merge_assoc_list(al1, al2) {
  return Pervasives.$at(al1, Plate$BwaxMobile.List.filter((function (param) {
                      var k = param[0];
                      return Plate$BwaxMobile.List.every((function (param) {
                                    return Caml_obj.caml_notequal(k, param[0]);
                                  }), al1);
                    }))(al2));
}

function merge_env(base_env, env) {
  return Dict$BwaxMobile.$$String.union(env, base_env);
}

function merge_dts(base_dts, dts) {
  return Pervasives.$at(dts, base_dts);
}

function merge_dts_list(base_dts, dts_list) {
  return Pervasives.$at(Plate$BwaxMobile.List.concat(dts_list), base_dts);
}

function merge_envs(base_env, envs) {
  return Plate$BwaxMobile.List.foldr(Dict$BwaxMobile.$$String.union, envs, base_env);
}

function dict_to_js_obj(dict) {
  return Js_dict.fromList(Dict$BwaxMobile.$$String.to_list(dict));
}

function merge_entities_and_data_types(relevants_of_depended) {
  var collected_entities = /* record */[/* contents */Dict$BwaxMobile.$$String.from_list(/* [] */0)];
  var collected_datatypes = /* record */[/* contents */Dict$BwaxMobile.$$String.from_list(/* [] */0)];
  Plate$BwaxMobile.List.for_each((function (param) {
          Plate$BwaxMobile.List.for_each((function (param) {
                  var entity = param[1];
                  var entity$1 = entity;
                  var fields = entity[/* fields */2];
                  var vfields = entity[/* virtual_fields */3];
                  var collected = collected_entities[0];
                  var match = Dict$BwaxMobile.$$String.get(entity$1[/* name */0], collected);
                  var ne = match !== undefined ? Base_query_types$BwaxMobile.entity_add_fields(/* tuple */[
                          fields,
                          vfields
                        ], match) : Base_query_types$BwaxMobile.entity_add_fields(/* tuple */[
                          fields,
                          vfields
                        ], Base_query_types$BwaxMobile.entity_without_fields(entity$1));
                  collected_entities[0] = Dict$BwaxMobile.$$String.insert(entity$1[/* name */0], ne, collected);
                  return /* () */0;
                }), param[0]);
          return Plate$BwaxMobile.List.for_each((function (param) {
                        var data_type = param[1];
                        var match = data_type[/* trait */2];
                        var match$1;
                        match$1 = typeof match === "number" || match.tag !== /* C_record */1 ? /* tuple */[
                            /* [] */0,
                            /* [] */0
                          ] : /* tuple */[
                            match[0],
                            match[1]
                          ];
                        var data_type$1 = data_type;
                        var fields = match$1[0];
                        var vfields = match$1[1];
                        var collected = collected_datatypes[0];
                        var match$2 = Dict$BwaxMobile.$$String.get(data_type$1[/* name */0], collected);
                        var ndt = match$2 !== undefined ? Base_query_types$BwaxMobile.data_type_add_fields(/* tuple */[
                                fields,
                                vfields
                              ], match$2) : Base_query_types$BwaxMobile.data_type_add_fields(/* tuple */[
                                fields,
                                vfields
                              ], Base_query_types$BwaxMobile.data_type_without_fields(data_type$1));
                        collected_datatypes[0] = Dict$BwaxMobile.$$String.insert(data_type$1[/* name */0], ndt, collected);
                        return /* () */0;
                      }), param[1]);
        }), relevants_of_depended);
  var relevant_data_types = Dict$BwaxMobile.$$String.to_list(collected_datatypes[0]);
  var relevant_entities = Dict$BwaxMobile.$$String.to_list(collected_entities[0]);
  return /* tuple */[
          relevant_entities,
          relevant_data_types
        ];
}

function expr_val_string_assoc_list_to_js_obj(v) {
  var match = v[0];
  var tmp;
  tmp = typeof match === "number" || match.tag !== /* V_list */1 ? /* [] */0 : Plate$BwaxMobile.List.keep_map((function (param) {
            var match = param[0];
            if (typeof match === "number" || match.tag) {
              return ;
            } else {
              var match$1 = match[0];
              if (match$1) {
                var match$2 = match$1[0][0];
                if (typeof match$2 === "number" || match$2.tag !== /* V_literal */5) {
                  return ;
                } else {
                  var match$3 = match$2[0];
                  if (typeof match$3 === "number" || match$3.tag !== /* String */2) {
                    return ;
                  } else {
                    var match$4 = match$1[1];
                    if (match$4) {
                      var match$5 = match$4[0][0];
                      if (typeof match$5 === "number" || match$5.tag !== /* V_literal */5) {
                        return ;
                      } else {
                        var match$6 = match$5[0];
                        if (typeof match$6 === "number" || !(match$6.tag === /* String */2 && !match$4[1])) {
                          return ;
                        } else {
                          return /* tuple */[
                                  match$3[0],
                                  match$6[0]
                                ];
                        }
                      }
                    } else {
                      return ;
                    }
                  }
                }
              } else {
                return ;
              }
            }
          }), match[0]);
  return Js_dict.fromList(tmp);
}

function evaluate_defs(base_env, ast) {
  return Lang_eval$BwaxMobile.evaluate_defs(base_env, ast)[0];
}

function execute_init_to_model_with_multiparams(query_runner, queryCache, domainEnv, entity_dict, data_type_dict, dts, env, params, cb) {
  var route_to = function (s, _replace) {
    console.log("Goto", s);
    return /* () */0;
  };
  var web_env_000 = /* protocol */domainEnv.protocol;
  var web_env_005 = /* isSandbox */domainEnv.isSandbox;
  var web_env = /* record */[
    web_env_000,
    /* host */"",
    /* currentURL */"",
    /* currentURLPath */"",
    /* originalURL */"",
    web_env_005,
    /* isIOS */false,
    /* isWeChat */false,
    /* isMobile */false
  ];
  var domain_env_000 = /* mobileHost */domainEnv.mobileHost;
  var domain_env_001 = /* isSandbox */domainEnv.isSandbox;
  var domain_env_002 = /* protocol */domainEnv.protocol;
  var domain_env_003 = /* tenantCode */domainEnv.tenantCode;
  var domain_env_004 = /* urlPrefix */domainEnv.urlPrefix;
  var domain_env = /* record */[
    domain_env_000,
    domain_env_001,
    domain_env_002,
    domain_env_003,
    domain_env_004
  ];
  var backend_command_handlers = Command_engine$BwaxMobile.get_builtin_command_handlers(/* () */0);
  var query_cache;
  if (queryCache !== undefined) {
    var c = Caml_option.valFromOption(queryCache);
    query_cache = /* record */[
      /* getData */c.getData,
      /* setData */c.setData,
      /* setLoading */c.setLoading,
      /* removeData */c.removeData,
      /* clearCache */c.clearCache
    ];
  } else {
    query_cache = undefined;
  }
  var context = /* record */[
    /* entity_dict */entity_dict,
    /* data_type_dict */data_type_dict,
    /* dts */dts,
    /* env */env,
    /* query_runner */query_runner,
    /* query_cache */query_cache,
    /* timers */undefined,
    /* domain_env */domain_env,
    /* route_to */route_to,
    /* web_env */web_env
  ];
  return Lang_runtime$BwaxMobile.run_init_to_model(backend_command_handlers, context, params, (function (result) {
                if (result.tag) {
                  return Curry._4(cb, undefined, /* array */[], /* array */[], result[0]);
                } else {
                  var match = result[0];
                  return Curry._4(cb, match[0], Curry._1(Plate$BwaxMobile.List.to_array, match[1]), Curry._1(Plate$BwaxMobile.List.to_array, match[2]), undefined);
                }
              }), (function (_msgs) {
                console.log("abandon branch msgs");
                return /* () */0;
              }));
}

function execute_init_to_model_v2(query_runner, queryCache, domainEnv, entity_dict, data_type_dict, dts, env, params, cb) {
  return execute_init_to_model_with_multiparams(query_runner, queryCache, domainEnv, entity_dict, data_type_dict, dts, env, /* :: */[
              params,
              /* [] */0
            ], cb);
}

function evaluate_view_v2(query_runner, queryCache, domainEnv, entity_dict, data_type_dict, dts, env, model) {
  var route_to = function (s, _replace) {
    console.log("Goto", s);
    return /* () */0;
  };
  var query_cache;
  if (queryCache !== undefined) {
    var c = Caml_option.valFromOption(queryCache);
    query_cache = /* record */[
      /* getData */c.getData,
      /* setData */c.setData,
      /* setLoading */c.setLoading,
      /* removeData */c.removeData,
      /* clearCache */c.clearCache
    ];
  } else {
    query_cache = undefined;
  }
  var web_env_000 = /* protocol */domainEnv.protocol;
  var web_env_005 = /* isSandbox */domainEnv.isSandbox;
  var web_env = /* record */[
    web_env_000,
    /* host */"",
    /* currentURL */"",
    /* currentURLPath */"",
    /* originalURL */"",
    web_env_005,
    /* isIOS */false,
    /* isWeChat */false,
    /* isMobile */false
  ];
  var domain_env_000 = /* mobileHost */domainEnv.mobileHost;
  var domain_env_001 = /* isSandbox */domainEnv.isSandbox;
  var domain_env_002 = /* protocol */domainEnv.protocol;
  var domain_env_003 = /* tenantCode */domainEnv.tenantCode;
  var domain_env_004 = /* urlPrefix */domainEnv.urlPrefix;
  var domain_env = /* record */[
    domain_env_000,
    domain_env_001,
    domain_env_002,
    domain_env_003,
    domain_env_004
  ];
  var context = /* record */[
    /* entity_dict */entity_dict,
    /* data_type_dict */data_type_dict,
    /* dts */dts,
    /* env */env,
    /* query_runner */query_runner,
    /* query_cache */query_cache,
    /* timers */undefined,
    /* domain_env */domain_env,
    /* route_to */route_to,
    /* web_env */web_env
  ];
  return Lang_runtime$BwaxMobile.run_view(context, model);
}

var value_to_js = Lang_mod_json$BwaxMobile.any_value_to_plain_json;

function expr_val_assoc_list_to_js_obj(v) {
  var match = v[0];
  var tmp;
  tmp = typeof match === "number" || match.tag !== /* V_list */1 ? /* [] */0 : Plate$BwaxMobile.List.keep_map((function (param) {
            var match = param[0];
            if (typeof match === "number" || match.tag) {
              return ;
            } else {
              var match$1 = match[0];
              if (match$1) {
                var match$2 = match$1[0][0];
                if (typeof match$2 === "number" || match$2.tag !== /* V_literal */5) {
                  return ;
                } else {
                  var match$3 = match$2[0];
                  if (typeof match$3 === "number" || match$3.tag !== /* String */2) {
                    return ;
                  } else {
                    var match$4 = match$1[1];
                    if (match$4 && !match$4[1]) {
                      var k = match$3[0];
                      return Plate$BwaxMobile.$$Option.map((function (j) {
                                    return /* tuple */[
                                            k,
                                            j
                                          ];
                                  }), Lang_mod_json$BwaxMobile.any_value_to_plain_json(match$4[0]));
                    } else {
                      return ;
                    }
                  }
                }
              } else {
                return ;
              }
            }
          }), match[0]);
  return Js_dict.fromList(tmp);
}

function result_value_to_js(value) {
  var match = value[0];
  if (typeof match === "number" || match.tag !== /* V_tagged */6) {
    return /* tuple */[
            Lang_mod_json$BwaxMobile.any_value_to_plain_json(value),
            undefined
          ];
  } else {
    switch (match[0]) {
      case "Err" :
          var match$1 = match[1];
          if (match$1 && !match$1[1]) {
            return /* tuple */[
                    undefined,
                    Caml_option.some(Lang_mod_json$BwaxMobile.any_value_to_plain_json(match$1[0]))
                  ];
          } else {
            return /* tuple */[
                    Lang_mod_json$BwaxMobile.any_value_to_plain_json(value),
                    undefined
                  ];
          }
      case "Ok" :
          var match$2 = match[1];
          if (match$2 && !match$2[1]) {
            return /* tuple */[
                    Lang_mod_json$BwaxMobile.any_value_to_plain_json(match$2[0]),
                    undefined
                  ];
          } else {
            return /* tuple */[
                    Lang_mod_json$BwaxMobile.any_value_to_plain_json(value),
                    undefined
                  ];
          }
      default:
        return /* tuple */[
                Lang_mod_json$BwaxMobile.any_value_to_plain_json(value),
                undefined
              ];
    }
  }
}

function result_value_to_arr(value) {
  var value_to_arr = function (v) {
    var match = v[0];
    if (typeof match === "number" || match.tag) {
      return Curry._1(Plate$BwaxMobile.List.to_array, /* :: */[
                  v,
                  /* [] */0
                ]);
    } else {
      return Curry._1(Plate$BwaxMobile.List.to_array, match[0]);
    }
  };
  var match = value[0];
  if (typeof match !== "number" && match.tag === /* V_tagged */6) {
    switch (match[0]) {
      case "Err" :
          var match$1 = match[1];
          if (match$1 && !match$1[1]) {
            return /* tuple */[
                    undefined,
                    Lang_mod_json$BwaxMobile.any_value_to_plain_json(match$1[0])
                  ];
          }
          break;
      case "Ok" :
          var match$2 = match[1];
          if (match$2 && !match$2[1]) {
            return /* tuple */[
                    value_to_arr(match$2[0]),
                    undefined
                  ];
          }
          break;
      default:
        
    }
  }
  return /* tuple */[
          value_to_arr(value),
          undefined
        ];
}

function is_a_result_value(value) {
  var match = value[0];
  if (typeof match === "number" || match.tag !== /* V_tagged */6) {
    return false;
  } else {
    switch (match[0]) {
      case "Err" :
      case "Ok" :
          break;
      default:
        return false;
    }
    var match$1 = match[1];
    if (match$1 && !match$1[1]) {
      return true;
    } else {
      return false;
    }
  }
}

function value_to_js_by_data_type(data_type_dict, type_key, value) {
  var data_type_fields = function (name_or_key) {
    if (Plate$BwaxMobile.List.contains(type_key, /* :: */[
            "ShortText",
            /* :: */[
              "Text",
              /* :: */[
                "RichText",
                /* :: */[
                  "Date",
                  /* :: */[
                    "DateTime",
                    /* :: */[
                      "Boolean",
                      /* :: */[
                        "Select",
                        /* :: */[
                          "JSON",
                          /* :: */[
                            "JSONB",
                            /* :: */[
                              "Image",
                              /* :: */[
                                "File",
                                /* :: */[
                                  "Number",
                                  /* :: */[
                                    "Integer",
                                    /* :: */[
                                      "Link",
                                      /* :: */[
                                        "FilterCondition",
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ])) {
      return ;
    } else {
      return Plate$BwaxMobile.$$Option.and_then((function (dt) {
                    var match = dt[/* trait */2];
                    if (typeof match === "number" || match.tag !== /* C_record */1) {
                      return ;
                    } else {
                      return Pervasives.$at(match[0], match[1]);
                    }
                  }), Base_query_types$BwaxMobile.try_to_get_data_type(data_type_dict, name_or_key));
    }
  };
  var transform_with_fields = function (fields, _v) {
    while(true) {
      var v = _v;
      var match = v[0];
      if (typeof match === "number") {
        return Lang_mod_json$BwaxMobile.any_value_to_plain_json(v);
      } else {
        switch (match.tag | 0) {
          case /* V_list */1 :
              return Caml_option.some(Curry._1(Plate$BwaxMobile.List.to_array, Plate$BwaxMobile.List.keep_map((function (param) {
                                    return transform_with_fields(fields, param);
                                  }), match[0])));
          case /* V_record */2 :
              return Caml_option.some(Js_dict.fromList(Plate$BwaxMobile.List.keep_map((function (param) {
                                    var v = param[1];
                                    var name = param[0];
                                    return Plate$BwaxMobile.$$Option.and_then((function (f) {
                                                  var match = f[/* dtype */2];
                                                  var mr;
                                                  if (typeof match === "number" || match.tag !== /* Custom_type */3) {
                                                    mr = Lang_mod_json$BwaxMobile.any_value_to_plain_json(v);
                                                  } else {
                                                    var mfs = data_type_fields(match[0]);
                                                    mr = mfs !== undefined ? transform_with_fields(mfs, v) : Lang_mod_json$BwaxMobile.any_value_to_plain_json(v);
                                                  }
                                                  return Plate$BwaxMobile.$$Option.map((function (r) {
                                                                return /* tuple */[
                                                                        f[/* key */1],
                                                                        r
                                                                      ];
                                                              }), mr);
                                                }), Plate$BwaxMobile.List.find((function (f) {
                                                      return f[/* name */0] === name;
                                                    }), fields));
                                  }), match[0])));
          case /* V_tagged */6 :
              if (match[0] === "Just") {
                var match$1 = match[1];
                if (match$1 && !match$1[1]) {
                  _v = match$1[0];
                  continue ;
                } else {
                  return Lang_mod_json$BwaxMobile.any_value_to_plain_json(v);
                }
              } else {
                return Lang_mod_json$BwaxMobile.any_value_to_plain_json(v);
              }
          default:
            return Lang_mod_json$BwaxMobile.any_value_to_plain_json(v);
        }
      }
    };
  };
  var match = Lang_eval$BwaxMobile.extract_from_maybe(value);
  if (match !== undefined) {
    var v = match;
    var maybe_fields = data_type_fields(type_key);
    if (maybe_fields !== undefined) {
      return transform_with_fields(maybe_fields, v);
    } else {
      return Lang_mod_json$BwaxMobile.any_value_to_plain_json(v);
    }
  }
  
}

function transfrom_json_to_assoc_string_list_value(params) {
  return Runtime_common$BwaxMobile.pack_list(Plate$BwaxMobile.List.map((function (param) {
                    return /* tuple */[
                            /* V_tuple */Block.__(0, [/* :: */[
                                  Runtime_common$BwaxMobile.pack_string(param[0]),
                                  /* :: */[
                                    Runtime_common$BwaxMobile.pack_string(param[1]),
                                    /* [] */0
                                  ]
                                ]]),
                            Runtime_common$BwaxMobile.nt
                          ];
                  }), Plate$BwaxMobile.List.keep_map((function (param) {
                        var k = param[0];
                        return Plate$BwaxMobile.$$Option.map((function (s) {
                                      return /* tuple */[
                                              k,
                                              s
                                            ];
                                    }), Js_json.decodeString(param[1]));
                      }), Plate$BwaxMobile.$$Option.with_default(/* [] */0, Plate$BwaxMobile.$$Option.map(Plate$BwaxMobile.List.from_array, Plate$BwaxMobile.$$Option.map(Js_dict.entries, Js_json.decodeObject(params)))))));
}

function get_init_arity(param) {
  var maybe_init_t = Record_dependency_resolver$BwaxMobile.get_init_t(param[1]);
  return Plate$BwaxMobile.$$Option.and_then((function (param) {
                if (typeof param === "number" || param.tag || param[0] !== "Arrow") {
                  return ;
                } else {
                  var match = param[1];
                  if (match) {
                    var match$1 = match[1];
                    if (match$1) {
                      var _return_t = match$1[0];
                      if (typeof _return_t !== "number" && !_return_t.tag && _return_t[0] === "Arrow") {
                        var match$2 = _return_t[1];
                        if (match$2) {
                          var match$3 = match$2[1];
                          if (match$3 && !match$3[1]) {
                            if (match$1[1]) {
                              return ;
                            } else {
                              return 2;
                            }
                          }
                          
                        }
                        
                      }
                      if (match$1[1]) {
                        return ;
                      } else {
                        return 1;
                      }
                    } else {
                      return ;
                    }
                  } else {
                    return ;
                  }
                }
              }), maybe_init_t);
}

function transform_record(entity_dict, data_type_dict, entity_name, json) {
  var found_entity = Plate$BwaxMobile.$$Option.map(Plate$BwaxMobile.snd, Plate$BwaxMobile.List.find((function (param) {
              return param[0] === entity_name;
            }), entity_dict));
  if (found_entity !== undefined) {
    var entity = found_entity;
    var param = entity_dict;
    var param$1 = data_type_dict;
    var param$2 = Query_builder$BwaxMobile.default_transform_option;
    var param$3 = Pervasives.$at(entity[/* fields */2], entity[/* virtual_fields */3]);
    var param$4 = json;
    return Query_builder$BwaxMobile.transform_record_value((function (transform, field, linked_entity, json) {
                  var match = Js_json.classify(json);
                  if (typeof match !== "number") {
                    switch (match.tag | 0) {
                      case /* JSONString */0 :
                          return Runtime_common$BwaxMobile.pack_string(match[0]);
                      case /* JSONObject */2 :
                          var all_fields = Pervasives.$at(linked_entity[/* fields */2], linked_entity[/* virtual_fields */3]);
                          return Curry._3(transform, Query_builder$BwaxMobile.default_transform_option, all_fields, json);
                      default:
                        
                    }
                  }
                  var all_fields$1 = Pervasives.$at(linked_entity[/* fields */2], linked_entity[/* virtual_fields */3]);
                  return Curry._3(transform, Query_builder$BwaxMobile.default_transform_option, all_fields$1, json);
                }), param, param$1, param$2, param$3, param$4);
  }
  
}

function transform_optional_record(entity_dict, data_type_dict, entity_name, maybeJson) {
  if (maybeJson == null) {
    return Runtime_common$BwaxMobile.pack_nothing;
  } else {
    var match = Js_json.classify(maybeJson);
    if (typeof match === "number" && match >= 2) {
      return Runtime_common$BwaxMobile.pack_nothing;
    }
    var v = transform_record(entity_dict, data_type_dict, entity_name, maybeJson);
    return Plate$BwaxMobile.$$Option.map(Runtime_common$BwaxMobile.pack_just, v);
  }
}

function transform_updates(entity_dict, data_type_dict, entity_name, updated_names_arr, json) {
  var updated_names = Curry._1(Plate$BwaxMobile.List.from_array, updated_names_arr);
  var found_entity = Plate$BwaxMobile.$$Option.map(Plate$BwaxMobile.snd, Plate$BwaxMobile.List.find((function (param) {
              return param[0] === entity_name;
            }), entity_dict));
  var make_field = function (f) {
    var updated = Plate$BwaxMobile.List.contains(f[/* name */0], updated_names);
    var n = Base_query_types$BwaxMobile.normalize_field_name(f[/* name */0]);
    var v;
    if (updated) {
      var mv = Plate$BwaxMobile.$$Option.and_then((function (param) {
              var f$1 = f;
              var j = param;
              return Query_builder$BwaxMobile.transform_field_value((function (_transform, _field, _linked_entity, json) {
                            var match = Js_json.classify(json);
                            if (typeof match === "number") {
                              return ;
                            } else {
                              switch (match.tag | 0) {
                                case /* JSONString */0 :
                                    return Runtime_common$BwaxMobile.pack_string(match[0]);
                                case /* JSONObject */2 :
                                    return Plate$BwaxMobile.$$Option.map(Runtime_common$BwaxMobile.pack_string, Plate$BwaxMobile.$$Option.and_then(Js_json.decodeString, Js_dict.get(match[0], "id")));
                                default:
                                  return ;
                              }
                            }
                          }), entity_dict, data_type_dict, Query_builder$BwaxMobile.default_transform_option, f$1, j);
            }), Json$BwaxMobile.get_field(f[/* key */1], json));
      var ov = mv !== undefined ? mv : Runtime_common$BwaxMobile.pack_tagged("Undefined", /* [] */0);
      v = Runtime_common$BwaxMobile.pack_tagged("OldValue", /* :: */[
            ov,
            /* [] */0
          ]);
    } else {
      v = Runtime_common$BwaxMobile.pack_tagged("Inact", /* [] */0);
    }
    return /* tuple */[
            n,
            v
          ];
  };
  if (found_entity !== undefined) {
    return Runtime_common$BwaxMobile.pack_object(Plate$BwaxMobile.List.map(make_field, Base_query_types$BwaxMobile.materialized_fields(found_entity)));
  } else {
    throw [
          Base_query_types$BwaxMobile.Builder_exn,
          "I can't find entity `" + (entity_name + "`")
        ];
  }
}

function transform_raw_record(entity_dict, data_type_dict, entity_name, json) {
  var found_entity = Plate$BwaxMobile.$$Option.map(Plate$BwaxMobile.snd, Plate$BwaxMobile.List.find((function (param) {
              return param[0] === entity_name;
            }), entity_dict));
  var make_field = function (f) {
    var n = Base_query_types$BwaxMobile.normalize_field_name(f[/* name */0]);
    var mv = Plate$BwaxMobile.$$Option.and_then((function (param) {
            var f$1 = f;
            var j = param;
            return Query_builder$BwaxMobile.transform_field_value((function (_transform, _field, _linked_entity, json) {
                          var match = Js_json.classify(json);
                          if (typeof match === "number") {
                            return ;
                          } else {
                            switch (match.tag | 0) {
                              case /* JSONString */0 :
                                  return Runtime_common$BwaxMobile.pack_string(match[0]);
                              case /* JSONObject */2 :
                                  return Plate$BwaxMobile.$$Option.map(Runtime_common$BwaxMobile.pack_string, Plate$BwaxMobile.$$Option.and_then(Js_json.decodeString, Js_dict.get(match[0], "id")));
                              default:
                                return ;
                            }
                          }
                        }), entity_dict, data_type_dict, Query_builder$BwaxMobile.default_transform_option, f$1, j);
          }), Json$BwaxMobile.get_field(f[/* key */1], json));
    return Plate$BwaxMobile.$$Option.map((function (v) {
                  return /* tuple */[
                          n,
                          v
                        ];
                }), mv);
  };
  if (found_entity !== undefined) {
    var binds = Plate$BwaxMobile.List.keep_map(make_field, Base_query_types$BwaxMobile.materialized_fields(found_entity));
    var id_v = Curry._1(Plate$BwaxMobile.$$Option.force, Plate$BwaxMobile.$$Option.map(Runtime_common$BwaxMobile.pack_string, Json$BwaxMobile.get_value("id", Js_json.decodeString, json)));
    return Runtime_common$BwaxMobile.pack_object(/* :: */[
                /* tuple */[
                  "id",
                  id_v
                ],
                binds
              ]);
  } else {
    throw [
          Base_query_types$BwaxMobile.Builder_exn,
          "I can't find entity `" + (entity_name + "`")
        ];
  }
}

function pack_tuple_from_array(val_arr) {
  return Runtime_common$BwaxMobile.pack_tuple(Curry._1(Plate$BwaxMobile.List.from_array, val_arr));
}

function pack_domain_env(domainEnv) {
  return Runtime_common$BwaxMobile.pack_object(/* :: */[
              /* tuple */[
                "mobileHost",
                Runtime_common$BwaxMobile.pack_string(domainEnv.mobileHost)
              ],
              /* :: */[
                /* tuple */[
                  "isSandbox",
                  Runtime_common$BwaxMobile.pack_bool(domainEnv.isSandbox)
                ],
                /* :: */[
                  /* tuple */[
                    "protocol",
                    Runtime_common$BwaxMobile.pack_string(domainEnv.protocol)
                  ],
                  /* :: */[
                    /* tuple */[
                      "tenantCode",
                      Runtime_common$BwaxMobile.pack_maybe(Runtime_common$BwaxMobile.pack_string, domainEnv.tenantCode)
                    ],
                    /* :: */[
                      /* tuple */[
                        "urlPrefix",
                        Runtime_common$BwaxMobile.pack_string(domainEnv.urlPrefix)
                      ],
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]);
}

function pack_web_env(webEnv) {
  return Runtime_common$BwaxMobile.pack_object(/* :: */[
              /* tuple */[
                "protocol",
                Runtime_common$BwaxMobile.pack_string(webEnv.protocol)
              ],
              /* :: */[
                /* tuple */[
                  "host",
                  Runtime_common$BwaxMobile.pack_string(webEnv.host)
                ],
                /* :: */[
                  /* tuple */[
                    "currentURLPath",
                    Runtime_common$BwaxMobile.pack_string(webEnv.currentURLPath)
                  ],
                  /* :: */[
                    /* tuple */[
                      "currentURL",
                      Runtime_common$BwaxMobile.pack_string(webEnv.currentURL)
                    ],
                    /* :: */[
                      /* tuple */[
                        "originalURL",
                        Runtime_common$BwaxMobile.pack_string(webEnv.originalURL)
                      ],
                      /* :: */[
                        /* tuple */[
                          "isSandbox",
                          Runtime_common$BwaxMobile.pack_bool(webEnv.isSandbox)
                        ],
                        /* :: */[
                          /* tuple */[
                            "isIOS",
                            Runtime_common$BwaxMobile.pack_bool(webEnv.isIOS)
                          ],
                          /* :: */[
                            /* tuple */[
                              "isWeChat",
                              Runtime_common$BwaxMobile.pack_bool(webEnv.isWeChat)
                            ],
                            /* :: */[
                              /* tuple */[
                                "isMobile",
                                Runtime_common$BwaxMobile.pack_bool(webEnv.isMobile)
                              ],
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

function lang_value_to_interface_input_json(entity_dict, data_type_dict, input_types, params) {
  return Curry._1(Plate$BwaxMobile.List.to_array, Plate$BwaxMobile.List.map(Plate$BwaxMobile.snd, Query_commands$BwaxMobile.to_interface_inputs(entity_dict, data_type_dict, input_types, params)));
}

var unit = /* tuple */[
  /* V_unit */0,
  /* No_term */0
];

var array_to_list = Lang_entry_base$BwaxMobile.array_to_list;

var build_definition = Query_env$BwaxMobile.build_definition;

var normalize_name = Base_query_types$BwaxMobile.normalize_name;

var string_of_term = Lang_typing_print$BwaxMobile.string_of_term;

var string_of_scheme = Lang_typing_print$BwaxMobile.string_of_scheme;

var string_of_type_info = Lang_typing_print$BwaxMobile.string_of_type_info;

var evaluate = Lang_eval$BwaxMobile.evaluate;

var string_of_value = Lang_eval$BwaxMobile.string_of_value;

var json_to_value_for_type = Runtime_common$BwaxMobile.json_to_value_for_type;

var pack_tuple = Runtime_common$BwaxMobile.pack_tuple;

var is_old_event_handler = Record_dependency_resolver$BwaxMobile.is_old_event_handler;

var determine_updated_event_dependencies = Record_dependency_resolver$BwaxMobile.determine_updated_event_dependencies;

var determine_init_dependencies_as_js = Record_dependency_resolver$BwaxMobile.determine_init_dependencies_as_js;

var determine_view_dependencies_as_js = Record_dependency_resolver$BwaxMobile.determine_view_dependencies_as_js;

var is_empty_record_dep = Record_dependency_resolver$BwaxMobile.is_empty_record_dep;

var transform_plain_record = transform_record;

var nothing = Runtime_common$BwaxMobile.pack_nothing;

var transform_optional_plain_record = transform_optional_record;

var pack_date = Runtime_common$BwaxMobile.pack_date;

var pack_random = Runtime_mod_random$BwaxMobile.pack_random;

var pack_list = Runtime_common$BwaxMobile.pack_list;

var convert_input_output_types = Query_env$BwaxMobile.convert_input_output_types;

exports.unit = unit;
exports.array_to_list = array_to_list;
exports.list_length = list_length;
exports.build_external_env = build_external_env;
exports.build_env = build_env;
exports.build_definition = build_definition;
exports.get_genv = get_genv;
exports.prepare_eval_env = prepare_eval_env;
exports.normalize_name = normalize_name;
exports.merge_assoc_list = merge_assoc_list;
exports.merge_env = merge_env;
exports.merge_dts = merge_dts;
exports.merge_dts_list = merge_dts_list;
exports.merge_envs = merge_envs;
exports.dict_to_js_obj = dict_to_js_obj;
exports.merge_entities_and_data_types = merge_entities_and_data_types;
exports.string_of_term = string_of_term;
exports.string_of_scheme = string_of_scheme;
exports.string_of_type_info = string_of_type_info;
exports.evaluate = evaluate;
exports.expr_val_string_assoc_list_to_js_obj = expr_val_string_assoc_list_to_js_obj;
exports.evaluate_defs = evaluate_defs;
exports.execute_init_to_model_with_multiparams = execute_init_to_model_with_multiparams;
exports.execute_init_to_model_v2 = execute_init_to_model_v2;
exports.evaluate_view_v2 = evaluate_view_v2;
exports.string_of_value = string_of_value;
exports.json_to_value_for_type = json_to_value_for_type;
exports.pack_tuple = pack_tuple;
exports.value_to_js = value_to_js;
exports.expr_val_assoc_list_to_js_obj = expr_val_assoc_list_to_js_obj;
exports.result_value_to_js = result_value_to_js;
exports.result_value_to_arr = result_value_to_arr;
exports.is_a_result_value = is_a_result_value;
exports.value_to_js_by_data_type = value_to_js_by_data_type;
exports.transfrom_json_to_assoc_string_list_value = transfrom_json_to_assoc_string_list_value;
exports.is_old_event_handler = is_old_event_handler;
exports.determine_updated_event_dependencies = determine_updated_event_dependencies;
exports.determine_init_dependencies_as_js = determine_init_dependencies_as_js;
exports.determine_view_dependencies_as_js = determine_view_dependencies_as_js;
exports.is_empty_record_dep = is_empty_record_dep;
exports.get_init_arity = get_init_arity;
exports.transform_record = transform_record;
exports.transform_plain_record = transform_plain_record;
exports.nothing = nothing;
exports.transform_optional_record = transform_optional_record;
exports.transform_optional_plain_record = transform_optional_plain_record;
exports.transform_updates = transform_updates;
exports.transform_raw_record = transform_raw_record;
exports.pack_tuple_from_array = pack_tuple_from_array;
exports.pack_date = pack_date;
exports.pack_domain_env = pack_domain_env;
exports.pack_web_env = pack_web_env;
exports.pack_random = pack_random;
exports.pack_list = pack_list;
exports.convert_input_output_types = convert_input_output_types;
exports.lang_value_to_interface_input_json = lang_value_to_interface_input_json;
/* Dict-BwaxMobile Not a pure module */
