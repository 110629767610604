

import React from 'react'

import dayjs from 'dayjs';
import classNames from 'classnames';

export default function renderTime(time, className) {
    // const 
    const today = dayjs();
    const messageTime = dayjs(time);

    const isSameYear = today.year() == messageTime.year();
    const isSameMonth = isSameYear && today.month() == messageTime.month();
    const isSameDay = isSameMonth && today.date() == messageTime.date();

    const template = isSameDay ? "HH:mm" : (isSameYear ? "MM-DD HH:mm" : "YY-MM-DD HH:mm");

    return (
        <div className={classNames("font-size-11 text-[var(--gray10)] whitespace-nowrap", className)}>
            { messageTime.format(template) }
        </div>
    )
}


export function renderDate(time, className) {
    // const 
    const today = dayjs();
    const messageTime = dayjs(time);

    const isSameYear = today.year() == messageTime.year();
    // const isSameMonth = isSameYear && today.month() == messageTime.month();
    // const isSameDay = isSameMonth && today.date() == messageTime.date();

    const template = (isSameYear ? "MM-DD" : "YY-MM-DD");

    return (
        <div className={classNames("font-size-11 text-[var(--gray10)] whitespace-nowrap", className)}>
            { messageTime.format(template) }
        </div>
    )
}
