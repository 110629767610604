

import React, { useEffect, useState } from 'react'

import SearchItemsCommon from './SearchItemsCommon';

import UploadKnowledgeDocumentButton from './input/UploadKnowledgeDocumentButton';


export default function SearchDocuments({ keyword, onSelectChange, selected, facade }) {

    // 1. support pagination,
    // 2. a list of things, with checkboxes
    // 3. when checked / unchecked, call the onSelectChange({ name: true/false })

    //  { entityName, recordId, knowledgeCategoryKey }

    const [documents, setDocuments] = useState();

    const entityName = "OpenAI-知识文档";

    async function loadData() {

        const [result, error] = await facade.list({
            entityName,
            search: keyword && keyword.trim().length > 0 ? { keyword, fields: ["标题"] } : undefined,
            condition: [[
                { field: "加入知识库", op: "eq", value: true }
            ]],
            sort: [
                { field: "创建时间", order: "DESC" }
            ],
            pageSize: 1000,
            fieldPaths: ["标题", "文档", "深入分析", "加入知识库"],
        });

        if (!error) {
            setDocuments(result)
        }

    };

    useEffect(() => {
        loadData()
    }, [keyword]);


    function renderItem(doc) {
        return (
            <div className="item-line">
                <i className='bx bx-file'></i>
                <div className="item-title">{doc.标题}</div>
            </div>
        )
    }

    function uploadDocumentButton() {
        return (
            <UploadKnowledgeDocumentButton style={{ marginBottom: "1rem" }} onUploaded={docs => {

                // 1. add to documents, 
                // 2. select it

                setDocuments(prev => [ ...docs, ...prev ]);
                onSelectChange([...(docs.map(doc => ({ entityName, recordId: doc.id }))), ...selected])

            }} />
        )
    }

    return (
        <SearchItemsCommon {...{
            items: documents,

            actionButton: uploadDocumentButton(),

            renderItem,
            isItemSelected: doc => selected.some(r => r.recordId == doc.id),
            selectItem: doc => {
                onSelectChange([{ entityName, recordId: doc.id }, ...selected])
            },
            unselectItem: doc => {
                onSelectChange(selected.filter(r => r.recordId !== doc.id))
            }



        }} />
    )
}
