



const emptyContent = [{
    type: 'paragraph',
    children: [{ text: '' }],
}]



export function deserialize(value) {
    if (!value) {
        return emptyContent
    } else {
        const { content } = resolveContentType(value);
        try {
            return JSON.parse(content)
        } catch (e) {
            console.error(e)
            return []
        }
    }
}

export function serialize(slateValue, version) {
    return [
        { contentType: "Slate", version: version === undefined ? 2 : version },
        slateValue
    ].map(JSON.stringify).join("\n");
}

export function serializeV2(slateValue) {
    return [
        { contentType: "Slate", version: 2 },
        slateValue
    ].map(JSON.stringify).join("\n");
}

// return content and version
export function deserializeV2(value) {
    if (!value) {
        return [emptyContent, 2]
    } else {
        const { content, version } = resolveContentType(value);
        try {
            const v = JSON.parse(content)
            return [v, version]  // 保持原来的
        } catch (e) {
            console.error(e)
            return []
        }
    }
}


function resolveContentType(str) {
    /// the split the content by the first line
    const lineBreakIndex = str.indexOf('\n')
    if (lineBreakIndex === -1) {
        return ({
            contentType: "HTML",
            content: str
        })
    } else {
        const firstLine = str.substring(0, lineBreakIndex)
        let meta = null
        try {
            meta = JSON.parse(firstLine)
        } catch (e) { }

        if (meta !== null) {
            return {
                ...meta,
                content: str.substring(lineBreakIndex + 1)
            }
        } else {
            return ({
                contentType: "HTML",
                content: str
            })
        }
    }
}
