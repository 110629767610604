export default function countWords(v) {
    /// fix problem in special characters such as middle-dot, etc.   
    const str = v.replace(/[\u007F-\u00FE]/g, ' ');

    /// make a duplicate first...
    let str1 = str;
    let str2 = str;

    /// the following remove all chinese characters and then count the number of english characters in the string
    str1 = str1.replace(/[^!-~\d\s]+/gi, ' ')

    /// the following remove all english characters and then count the number of chinese characters in the string
    str2 = str2.replace(/[!-~\d\s]+/gi, '')


    let matches1 = str1.match(/[\u00ff-\uffff]|\S+/g);
    let matches2 = str2.match(/[\u00ff-\uffff]|\S+/g);


    let count1 = matches1 ? matches1.length : 0;
    let count2 = matches2 ? matches2.length : 0;

    /// return the total of the mixture
    return count1 + count2
}