
// This file is generated. Don't modify it.
import React from 'react'
export default function PreferenceIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M2,11 L3,11 L3,14 L2,14 L2,11 Z M5.6,8.6 L6.6,8.6 L6.6,14 L5.6,14 L5.6,8.6 Z M9.2,10.4 L10.2,10.4 L10.2,14 L9.2,14 L9.2,10.4 Z M12.8,8 L13.8,8 L13.8,14 L12.8,14 L12.8,8 Z M13,3.71892848 L9.48249024,7.20144708 L6.86185721,4.3419084 L2.58789062,8.46139506 L1.8891061,7.74606284 L6.90157053,2.90516191 L9.51750976,5.75957888 L12.3048159,3 L11,3 L11,2 L14,2 L14,3 L14,5 L13,5 L13,3.71892848 Z" id="PreferenceIcon-path-1"></path>
            </defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Personal/Preference">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="PreferenceIcon-mask-2" fill="white">
                            <use href="#PreferenceIcon-path-1"></use>
                        </mask>
                        <use id="Icon/Personal/Preference" fill={color} href="#PreferenceIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
