
import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';

import ReactDOM from 'react-dom';

import { MdClose } from 'react-icons/md';

import useResizeObserver from '@react-hook/resize-observer'

import './Modal.less';

export default function Modal(props) {
    const [ready, setReady] = useState(false)
    // portal 只在 client 端添加，为了避免 SSR hydration 时的不一致，使用 useEffect 来控制：
    useEffect(() => {
        if (typeof (document) !== "undefined") {
            setReady(true);
        }
    }, [])

    if (ready && typeof (document) !== "undefined") {
        const rootEle = document.getElementById("app");
        return ReactDOM.createPortal(
            <ModalInner {...props} />,
            rootEle
        );
    } else {
        return null
    }
}


function ModalInner(props) {

    const {
        visible, modalContent = null, size, 
        title, footer, 
        onBackgroundClick,
        onClose, // 如果有这个回调，则显示 close icon
        // 
        bodyClassName, bodyStyle = {},
        containerClassName, containerStyle = {},

        header, // 如果给了 header，则覆盖前面的 title 和 onClose icon

        compact,

    } = props;

    const ref = useRef();
    useEffect(() => {
        //
        if (visible && ref.current) {
            ref.current.focus();
        }

        /**
         * https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1041
         * 设置 body overflow hidden 避免 modal 显示时背后页面滚动（by 威豪 2022-10-12）
         */
        if (visible && typeof (document) !== "undefined") {
            const prevOverflow = document.body.style.overflow;
            document.body.style.overflow = "hidden";
            return () => {
                // console.log(">>> set back", prevOverflow);
                // document.body.style.overflow = prevOverflow

                // -TODO Van 2022-11-16 如果同时起两个 modal 是，可能会 add back hidden 的 style
                document.body.style.overflow = ""

            }
        }

    }, [ref.current, visible])


    function handleBackgroundClick () {
        if (onBackgroundClick) {
            onBackgroundClick()
        } else if(onClose) {
            // 直接使用 onClose
            onClose()
        }
    }

    const onKeyDown = e => {
        // handle the escape, make as if the background is clicked.
        if (e.keyCode == 27) {
            handleBackgroundClick();
        }
    }

    const headerRef = useRef(null);
    const footerRef = useRef(null);
    const bodyRef = useRef(null);

    const containerRef = useRef(null);

    const [containerHeight, setContainerHeight] = useState(0);

    const [headerHeight, setHeaderHeight] = useState(0);
    const [footerHeight, setFooterHeight] = useState(0);
    const [scrolledToTop, setScrolledToTop] = useState(true)
    const [scrolledToBottom, setScrolledToBottom] = useState(false)


    useResizeObserver(containerRef, entry => {
        setContainerHeight(entry.contentRect.height);
    });

    useResizeObserver(headerRef, entry => {
        setHeaderHeight(entry.contentRect.height);
    });

    useResizeObserver(footerRef, entry => {
        setFooterHeight(entry.contentRect.height);
    });

    useEffect(() => {
        if(bodyRef.current) {
            const { scrollHeight, offsetHeight } = bodyRef.current
            if(scrollHeight === offsetHeight && offsetHeight !== 0) {
                setScrolledToBottom(true)
            }
        }
    }, [bodyRef.current && bodyRef.current.offsetHeight])

    const bodyMarginBottom = footerHeight ? footerHeight : 0;

    function handleScroll (e) {
        const { scrollTop, scrollHeight, offsetHeight } = e.target 
        
        if(scrollTop === 0 && !scrolledToTop) {
            setScrolledToTop(true)
        } else if (scrollTop > 0 && scrolledToTop) {
            setScrolledToTop(false)
        }

        if(scrollTop + offsetHeight + bodyMarginBottom >= scrollHeight && !scrolledToBottom) {
            setScrolledToBottom(true)
        } else if (scrollTop + offsetHeight + bodyMarginBottom < scrollHeight && scrolledToBottom) {
            setScrolledToBottom(false)
        }
    }

    function getScrollStyle (isHeader) {
        
        return {
            zIndex: 1,
            boxShadow: `0px ${isHeader ? "1" : "-1" }px 2px rgba(0, 0, 0, 0.05)`
        }
    }

    function renderHeader() {
        if(header) {
            return (
                <div className="modal-header-no-style" ref={headerRef}>
                    { header }
                </div>
            )
        }
        function renderCloseIcon() {
            if (onClose) {
                return (
                    <div className="modal-head-left-icon modal-icon-button" onClick={e => {
                        e.stopPropagation()
                        onClose()
                    }}>
                        <MdClose />
                    </div>
                )
            }
            return null
        }

        if (onClose || title) {
            return (
                <div className="modal-header" style={!scrolledToTop ? getScrollStyle(true) : {}} ref={headerRef}>
                    {renderCloseIcon()}
                    {title ? title : null}
                </div>
            )
        }
        return null
    }

    function renderFooter() {
        // if (footer) {
            return (
                <div className="modal-footer" style={!scrolledToBottom ? getScrollStyle(false) : {}} ref={footerRef}>
                    { footer }
                </div>
            )
        // }
        // return null
    }

    const className = (
        "bw-modal" + (visible ? " visible" : " hidden") + (compact ? " compact" : "")
    );

    return (
        <div className={className} onKeyDown={onKeyDown} tabIndex={0} ref={ref}>
            <div className="modal-mask" onClick={e => {
                e.stopPropagation();
                handleBackgroundClick()
            }}></div>
            <div className={"modal-container" + (containerClassName ? " " + containerClassName : "")} ref={containerRef} style={{
                    ...containerStyle 
            }} >
                {renderHeader()}
                <div className={"modal-body" + (bodyClassName ? " " + bodyClassName : "")} style={{
                    ...bodyStyle,
                    // height: (headerHeight || footerHeight) ? containerHeight - (headerHeight + footerHeight) : undefined
                   marginBottom: bodyMarginBottom

                }} onScroll={e => handleScroll(e)} ref={bodyRef}>
                    {modalContent}
                </div>
                {renderFooter()}
            </div>

        </div>
    )

}

export function create(props) {
    return <Modal {...props} />
}