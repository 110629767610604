

import React, { useState, useRef, useEffect } from 'react'

import classNames from 'classnames'

import Link from 'bwax-ui/page/Link'

import getImageURL from 'bwax-ui/getImageURL'

import Button, { Pressable } from 'bwax-ui/components/Button'
import DropdownMenu from 'bwax-ui/components/DropdownMenu';

import WindowScrollHelper from 'bwax-ui/ml/widget/impl/misc/WindowScrollHelper';

import PopoverTrigger from 'bwax-ui/components/PopoverTrigger';


export default function FamilyOfficeLayout_cb({ data, slots, viewEnv }) {

    const { mainContent, footer } = slots;

    const { webEnv } = viewEnv;

    // const homeUrl = "/familyoffice"

    const {
        logo,
        logoInversed,
        homeUrl = "/familyoffice",
        siteName = "群蜂家业",
        tryButtonLabel = "联系我们",

        inverseBg = true,

        supportQrCode,

        mainColor = "cyan",
        navItems = [
            {
                label: "首页",
                url: "/familyoffice"
            },
            {
                label: "群蜂社",
                url: "https://www.qunfengshe.com"
            },
        ]
    } = data;

    function isActive(url) {
        return webEnv.currentURLPath == url;
    }

    function gotoTry() {

    }

    const [scrolledUp, setScrolledUp] = useState(false);
    const scrolledUpRef = useRef();
    scrolledUpRef.current = scrolledUp;

    const scrollHelperRef = useRef();

    useEffect(() => {

        scrollHelperRef.current = new WindowScrollHelper({
            onScrolled: status => {
                const THRESHOLD = 80;
                const scrolledUp = scrolledUpRef.current;
                if (status.y < THRESHOLD && scrolledUp) {
                    setScrolledUp(false)
                } else if (status.y >= THRESHOLD && !scrolledUp) {
                    setScrolledUp(true)
                }
            }
        })
        return _ => {
            scrollHelperRef.current.cleanUp();
        }

    }, []);

    const [narrowMenuShown, setNarrowMenuShown] = useState(false);

    const inverseColor = !scrolledUp && inverseBg && !narrowMenuShown;

    function renderWide() {
        return (
            <div className="h-full hidden md:flex items-center px-6 gap-6 justify-between w-full max-w-6xl">
                <Link to={homeUrl || "/"} className="flex gap-1 items-center">
                    <img className={classNames("transition-all duration-450 light-only", {
                        "h-[34px]": true,
                        "hidden": inverseColor,
                    })} src={getImageURL(logo, "medium")} />
                    <img className={classNames("transition-all duration-450 light-only", {
                        "h-[34px]": true,
                        "hidden": !inverseColor,
                    })} src={getImageURL(logoInversed, "medium")} />
                    {siteName ? (
                        <div className={classNames(
                            "font-medium break-keep", {
                            "font-size-18 ml-2": true,
                            "text-[white]": inverseColor
                        }
                        )}>{siteName}</div>
                    ) : null}
                </Link>
                <div className="flex items-center gap-4">
                    <div className={classNames("flex transition-all duration-300 font-size-15")}>
                        {navItems.map(item => {
                            const { label, url, subItems, openWithNewTab } = item;
                            if (url) {
                                return (
                                    <Link to={url} key={label} className="trk-topnav trk-topnav-wide" openWithNewTab={openWithNewTab}>
                                        <div className={classNames(
                                            "px-4 py-2 rounded",
                                            {
                                                "cursor-pointer": true,
                                                "font-medium": isActive(url),
                                                "text-[white] hover:bg-white/20": inverseColor,
                                                "hover:bg-[var(--mauve3)]": !inverseColor
                                            }
                                        )}>
                                            {item.label}
                                        </div>
                                    </Link>
                                )
                            } else if (subItems) {
                                const items = subItems.map(({ label, url, openWithNewTab }) => {
                                    return {
                                        label, onSelect: _ => {
                                            if (openWithNewTab) {
                                                window.open(url);
                                            } else {
                                                routeTo(url)
                                            }
                                        }
                                    }
                                });
                                return (
                                    <DropdownMenu key={label} items={items} className={"!px-3 !py-2.5 font-size-14"}>
                                        <div className={classNames(
                                            "pl-4 pr-3 py-2 rounded flex gap-1.5 items-center",
                                            {
                                                "hover:bg-[var(--mauve3)] cursor-pointer": true,
                                                "font-medium": isActive(url),
                                            }
                                        )}>
                                            {item.label}
                                            <i className='bx bx-chevron-down'></i>
                                        </div>
                                    </DropdownMenu>
                                )

                            } else {
                                return null
                            }
                        })}
                    </div>
                    <div>
                        <PopoverTrigger content={
                            <div className="p-4 rounded-md w-[240px] h-[240px]">
                                <img src={getImageURL(supportQrCode, "small")} alt="客服企业微信二维码" />
                            </div>
                        }>
                            <Button
                                id="btn-try-at-wide-topnav"
                                size={"normal"}
                                // onPress={gotoTry}
                                className={classNames(
                                    "transition-all duration-300",
                                    "trk-btn-try",
                                    {
                                        // "!px-3": scrolledUp
                                    }
                                )}
                                // appearance="primary" 
                                color={mainColor}>
                                {tryButtonLabel}
                            </Button>

                        </PopoverTrigger>
                    </div>
                </div>
            </div>
        )
    }



    const defaultOverflowRef = useRef();
    useEffect(() => {
        if (typeof (document) !== "undefined") {
            if (narrowMenuShown) {
                defaultOverflowRef.current = document.body.style["overflow"];
                document.body.style["overflow"] = "hidden";
            } else {
                document.body.style["overflow"] = defaultOverflowRef.current;
            }
        }

    }, [narrowMenuShown]);


    function renderNarrow() {
        return (
            <>
                <div className={classNames(
                    "absolute top-0 w-full transition-all h-screen opacity-30 bg-[var(--mauve11)]",
                    {
                        "hidden": !narrowMenuShown,
                    }
                )} onClick={_ => setNarrowMenuShown(false)} />
                <div className={classNames("h-full flex md:hidden items-center w-full relative", {
                    "bg-[var(--lc-color-bg-1)]": narrowMenuShown
                })}>
                    <div className={classNames("flex items-center pl-3 pr-4 gap-6 justify-between w-full")}>
                        <Link to={homeUrl || "/"} className="flex gap-1 items-center">
                            <img className={classNames("transition-all duration-300 h-8", { "hidden": inverseColor })} src={getImageURL(logo, "medium")} />
                            <img className={classNames("transition-all duration-300 h-8", { "hidden": !inverseColor })} src={getImageURL(logoInversed, "medium")} />
                            {siteName ? (
                                <div className={classNames(
                                    "font-medium break-keep", {
                                    "font-size-16": true,
                                    "text-[white]": inverseColor
                                }
                                )}>{siteName}</div>
                            ) : null}
                        </Link>
                        <div className="flex items-center gap-6">
                            {navItems && navItems.length > 0 ? (
                                <div className="flex flex-col justify-between h-5 w-6 py-0.5 overflow-hidden" onClick={_ => {
                                    setNarrowMenuShown(prev => !prev);
                                }}>
                                    {[1, 2, 3].map(i => {
                                        return (
                                            <div key={i} className={classNames(
                                                "h-0.5 bg-[var(--mauve11)] transition-all w-[200%]",
                                                narrowMenuShown ? {
                                                    "-translate-x-[2px] -rotate-45": i == 1,
                                                    "opacity-0": i == 2,
                                                    "-translate-x-[2px] rotate-45": i == 3,
                                                } : null,
                                                {
                                                    "bg-white": inverseColor
                                                }
                                            )} />
                                        )
                                    })}
                                </div>
                            ) : null}

                            <PopoverTrigger content={
                                <div className="p-4 rounded-md w-[240px] h-[240px]">
                                    <img src={getImageURL(supportQrCode, "small")} alt="客服企业微信二维码" />
                                </div>
                            }>
                                <Button
                                    id="btn-try-at-narrow-topnav"
                                    size={"normal"}
                                    onPress={gotoTry}
                                    className={classNames(
                                        "transition-all duration-300",
                                    )}
                                    // appearance="primary" 
                                    color={mainColor}>{tryButtonLabel}</Button>

                            </PopoverTrigger>
                        </div>
                    </div>
                    <div className={classNames(
                        "absolute top-[100%] w-full transition-all origin-top-left px-4 pt-6 pb-12",
                        "flex flex-col bg-[var(--lc-color-bg-1)] shadow-md gap-4",
                        {
                            "scale-y-0": !narrowMenuShown,
                            "scale-y-100": narrowMenuShown
                        }
                    )}>
                        {navItems.map(item => {
                            const { label, url, subItems } = item;
                            if (url) {
                                return (
                                    <Link to={url} key={label} className="trk-topnav trk-topnav-narrow">
                                        <div className={classNames(
                                            "px-4 py-2 rounded",
                                            {
                                                "hover:bg-[var(--mauve3)] cursor-pointer": true,
                                                "font-medium": isActive(url),
                                            }
                                        )} onClick={e => {
                                            setNarrowMenuShown(false);
                                        }}>
                                            {label}
                                        </div>
                                    </Link>
                                )
                            } else if (subItems) {
                                return (
                                    <SubMenu key={label} label={label} subItems={subItems} closeMenu={_ => setNarrowMenuShown(false)} isActive={isActive} />
                                )
                            }
                            return null;
                        })}
                    </div>
                </div>
            </>
        )
    }

    const companyName = "© 深圳市群蜂创业服务有限公司 2016-" + ((new Date().getFullYear()));

    function renderFooter() {
        return (
            <div className={classNames(
                "w-full border-t-[1px] bg-[var(--lc-color-bg-1)] flex flex-col gap-8 sm:gap-4 text-[14px]",
                "px-4 pt-16 pb-12 sm:pt-[5rem] sm:pb-[4.5rem] justify-self-end items-center"
            )}>
                <div className="hidden sm:flex text-[var(--gray-10)] gap-2 text-[12px]">
                    <div>{companyName}</div>
                    <Link className="hover:underline" to="https://beian.miit.gov.cn">粤ICP备16071551号-2</Link>
                </div>
                <div className="sm:hidden flex flex-col text-[var(--gray-10)] gap-2 text-[12px] items-center">
                    <div>{companyName}</div>
                    <Link className="hover:underline" to="https://beian.miit.gov.cn">粤ICP备16071551号-2</Link>
                </div>
            </div>
        )
    }


    return (
        <div className="w-full flex flex-col font-size-14">
            {mainContent || null}
            {renderFooter()}
            <div className={classNames(
                "w-full fixed border-b-[1px] transition-all flex justify-center",
                // "hover:bg-[var(--lc-color-bg-1)] hover:border-b-[(var(--gray3)",
                {
                    // "bg-transparent h-20 sm:h-24 !border-b-[transparent]": !scrolledUp || narrowMenuShown,
                    // "bg-[var(--lc-color-bg-1)] h-14 border-b-[(var(--gray3)]": scrolledUp && !narrowMenuShown,
                    // "bg-[var(--lc-color-bg-1)] h-14 sm:h-16 border-b-[(var(--gray3)]": true

                    "bg-transparent h-14 sm:h-16 !border-b-[transparent]": !scrolledUp,
                    "bg-[var(--lc-color-bg-1)] h-14 sm:h-16 border-b-[(var(--gray3)]": scrolledUp,

                }
            )}>
                {renderWide()}
                {renderNarrow()}
            </div>
        </div>
    )
}
