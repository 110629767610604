import React from 'react'

class InternIcon extends React.Component {

    render() {
        return (

            <svg width="1em" height="1em" viewBox="0 0 12 12">
                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Icon/VIP-services/实习" transform="translate(-2.000000, -2.000000)" fillRule="nonzero">
                        <g transform="translate(-0.000000, 0.000000)">
                            <circle id="Oval" cx="8" cy="8" r="8"></circle>
                            <path d="M4.51861665,6.28588419 L6.46064407,7.15795201 L6.46064407,9.69870637 L5.49662378,9.69870637 L5.49662378,12.6205739 L3.46402029,12.6205739 L3.46402029,9.69870637 L2.5,9.69870637 L2.5,7.15795201 L4.44202742,6.28588419 L2.81303385,5.34538431 L2.81303385,3.42016574 L4.48032204,2.45755645 L6.14761023,3.42016574 L6.14761023,5.34538431 L4.51861665,6.28588419 Z M5.92358116,3.72168074 L3.39715312,3.72168074 L3.16391726,3.45145311 L2.94128337,3.57823665 L3.27848354,3.97575618 L5.92358116,3.97575618 L5.92358116,3.72168074 Z M4.27852805,7.53906516 L4.27852805,9.31759321 L4.78667892,9.31759321 L4.78667892,7.53906516 L4.27852805,7.53906516 Z M8.32891176,6.28550859 L10.2717756,7.15795201 L10.2717756,8.84208951 L8.83067816,9.69857427 L8.83067816,12.6205739 L7.27515183,12.6205739 L7.27515183,9.69870637 L7.07335784,9.69870637 L7.07335784,6.81567413 L8.25232254,6.28625978 L6.62267842,5.34538431 L6.62267842,3.42016574 L8.28996661,2.45755645 L9.9572548,3.42016574 L9.9572548,5.34538431 L8.32891176,6.28550859 Z M8.08965958,7.53906516 L8.08965958,8.80944234 L8.59781045,8.80944234 L8.59781045,7.53906516 L8.08965958,7.53906516 Z M9.73322573,3.72168074 L7.20679769,3.72168074 L6.97356183,3.45145311 L6.75092794,3.57823665 L7.08812811,3.97575618 L9.73322573,3.97575618 L9.73322573,3.72168074 Z M13.5,9.89238939 L13.5,12.3257588 L11.3926402,13.5424435 L9.28528049,12.3257588 L9.28528049,9.89238939 L11.3926402,8.67570467 L13.5,9.89238939 Z M11.9007911,10.9055647 L11.3926402,10.5879704 L10.8844894,10.9055647 L10.8844894,11.5407533 L11.3926402,11.8583476 L11.9007911,11.5407533 L11.9007911,10.9055647 Z" id="Combined-Shape" fill="#FFFFFF"></path>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export default InternIcon
