

import React from 'react'

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import './ContextMenu.less';

export default function DropdownMenuComp({ className,  children, items, withPortal = true, onOpenChange }) {

    // items - [{ icon: react node,  label: string / react node, onSelect }]

    const content = (
        <DropdownMenu.Content
            className="lc-context-menu lc-base"
            sideOffset={5}
            align="end"
        >
            {items.map(({ label, onSelect, icon, isDisabled }, index) => {
                return (
                    <DropdownMenu.Item key={index} className="context-menu-item" onSelect={onSelect} disabled={isDisabled}>
                        <div className="icon">{icon || null}</div>
                        <div className="label">{label}</div>
                    </DropdownMenu.Item>
                )
            })}
        </DropdownMenu.Content>
    )

    return (
        <DropdownMenu.Root onOpenChange={onOpenChange}>
            <DropdownMenu.Trigger asChild className={"lc-menu-trigger" + (className ? " " + className : "")}>
                {children}
            </DropdownMenu.Trigger>
           { withPortal ? (
             <DropdownMenu.Portal>
                { content}
            </DropdownMenu.Portal>
           ) : content }
        </DropdownMenu.Root>
    )
}
