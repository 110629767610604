



// hard code some setting:
export function getFieldLabel(f, entityName) {

    const labelMapping = [
        // ["文档", ["OpenAI-文档翻译任务"], "需要翻译的文档"],
        ["源语言", ["OpenAI-文档翻译任务"], "被翻译的语言（只有这种语言的文字才会被翻译）"],
        ["目标语言", ["OpenAI-文档翻译任务"], "翻译成哪种语言？"],
        ["源语言", ["OpenAI-文档文字识别任务"], "需要识别的文字的语言"],

        

    ];

    const found = labelMapping.find(([fieldName, entityNames, _]) => {
        return fieldName === f.name && (entityNames.length == 0 || entityNames.indexOf(entityName) !== -1)
    });

    return (found && found[2]) || f.name;
}

export function getConstraints({ formData, entityName, field }){
    
    const constraints = [
        ["源语言", "OpenAI-文档翻译任务", _ => formData["目标语言"] != undefined ? [ { disabledValues: [ formData["目标语言"]]} ] : []],
        ["目标语言", "OpenAI-文档翻译任务", _ => formData["源语言"] != undefined ? [ { disabledValues: [ formData["源语言"]]} ] : []]
    ]

    return constraints.filter(([fn, en, _]) => entityName == en && field.name == fn).flatMap(([_fn, _en, get]) => get());

}



