// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$String = require("bs-platform/lib/js/string.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");

function after(str, index) {
  var length = str.length;
  if (index > length) {
    return "";
  } else {
    return $$String.sub(str, index, str.length - index | 0);
  }
}

function toInt(str) {
  try {
    return Caml_format.caml_int_of_string(str);
  }
  catch (exn){
    console.log("String cannot be parsed to int", str);
    return ;
  }
}

function toFloat(str) {
  try {
    return Caml_format.caml_float_of_string(str);
  }
  catch (exn){
    console.log("String cannot be parsed to float", str);
    return ;
  }
}

function join(strs, seperator) {
  return $$String.concat(seperator, strs);
}

function patternTest(str, patternStr) {
  return new RegExp(patternStr).test(str);
}

function testAndGet(str, pattern, groupIndex) {
  return Belt_Option.flatMap(Belt_Option.flatMap(Caml_option.null_to_opt(pattern.exec(str)), (function (result) {
                    return Belt_Array.get(result, groupIndex);
                  })), (function (prim) {
                if (prim == null) {
                  return ;
                } else {
                  return Caml_option.some(prim);
                }
              }));
}

exports.after = after;
exports.toInt = toInt;
exports.toFloat = toFloat;
exports.join = join;
exports.patternTest = patternTest;
exports.testAndGet = testAndGet;
/* No side effect */
