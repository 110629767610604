
import React, { useEffect, useState, useRef } from 'react'

import Avatar from "bwax-ui/components/Avatar";
import numeral from 'numeral';
import dayjs from 'dayjs';
import WindowScrollHelper from 'bwax-ui/ml/widget/impl/misc/WindowScrollHelper';

import Link from "bwax-ui/page/Link";

import Logo from "./components/ProjectLogo"
import classNames from 'classnames';


const statusColors = {
    "筛选中": "orange",
    "已经立项": "grass",
    "不立项": "gray",
}

export function getStatusColor(originalStatus) {
    const parts = originalStatus.split(" ");
    const status = parts[parts.length - 1];
    return statusColors[status];
}

export default function PipelineProjectList_cb({ facade }) {

    const [count, setCount] = useState();
    const [data, setData] = useState();

    const dataRef = useRef();
    dataRef.current = data;

    const [hasMore, setHasMore] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);

    async function loadData(offset, isRefresh = false) {

        setLoadingMore(true);
        const [result, error] = await facade.listAll({
            entityName: "预备项目",
            condition: [[
                { field: "公开显示", op: "eq", value: true }
            ]],
            sort: [
                { field: "状态", order: "ASC" },
                { field: "排序权重", order: "DESC" },
                { field: "创建时间", order: "DESC" },
            ],
            pageSize: 7,
            offset,
            fieldPaths: [
                "名称", "简介", "上传者.姓名", "上传者.用户.头像", "获得的支持", "不支持的PLN", "logo", "状态", "标签", "创建时间", "访问过"
            ]
        });

        setLoadingMore(false);

        if (result) {
            const hasMore = result.pageSize + offset < result.count;
            setHasMore(hasMore);
            setCount(result.count);

            if (isRefresh) {
                setData(result.data);
                dataRef.current = result.data;
            } else {
                const existing = dataRef.current || [];
                const toAppend = result.data.filter(s => !existing.some(e => e.id === s.id));
                const newData = [...existing, ...toAppend];

                setData(newData);
                dataRef.current = newData;
            }
        }
    }


    useEffect(() => {
        loadData(0)
    }, []);

    const loadMore = () => {
        if (hasMore) {
            loadData((data || []).length);
        }
    }

    const loadMoreRef = useRef();
    loadMoreRef.current = loadMore;


    useEffect(() => {
        if (typeof (window) !== "undefined") {
            const scrollHelper = new WindowScrollHelper({
                onScrolledToBottom: _ => {
                    // console.log("should load more", loadMoreRef);
                    loadMoreRef.current && loadMoreRef.current();
                }
            });
            return () => {
                scrollHelper.cleanUp();
            }
        }
    }, []);




    function renderStatus(originalStatus) {

        // 0 筛选中, 1 已经立项, 2 不立项
        const parts = originalStatus.split(" ");
        const status = parts[parts.length - 1];


        const [label, color, icon] = (() => {
            return [status, statusColors[status]]
        })();

        if (label) {
            return (
                <div data-color={color} className={
                    "inline-flex gap-1 items-center rounded-md ml-2 px-1.5 py-1 text-[11px] bg-[var(--color-3)] text-[var(--color-11)] leading-none whitespace-pre"
                }>
                    {label}{icon || null}
                </div>
            )
        }
        return null
    }

    const seperator = (
        <div className="opacity-50">•</div>
    )

    return (
        <div className="w-full max-w-3xl self-center gap-3 flex flex-col px-2">
            {
                (data || []).map(p => {

                    function renderPLN(number, className) {
                        return (
                            <div className={classNames("font-medium mr-1 whitespace-nowrap", className)}>
                                {number ? numeral(number).format("0.0a") : "-"}
                            </div>
                        )
                    }

                    function renderPLNs() {

                        const bigger = "font-medium text-[13px]";
                        const smaller = "text-[12px]";

                        const up = p.获得的支持;
                        const down = p.不支持的PLN;

                        return (
                            <>
                                <div className={classNames("flex justify-between text-[var(--color-10)] px-2 items-center gap-1.5")} data-color={getStatusColor(p.状态)} >
                                    <i className="bx bx-up-arrow text-[10px] opacity-80" />
                                    {renderPLN(up, bigger)}
                                </div>
                                <div className={classNames("flex justify-between text-[var(--color-10)] px-2 items-center gap-1.5")} data-color="gray">
                                    <i className="bx bx-down-arrow text-[10px] opacity-80" />
                                    {renderPLN(down, smaller)}
                                </div>
                            </>
                        )

                        // return (
                        //     <div data-color={getStatusColor(p.状态)} className="font-medium text-[var(--color-10)] mr-1 text-[15px] whitespace-nowrap">
                        //         {p.获得的支持 ? numeral(p.获得的支持).format("0.0a") : "-"}
                        //     </div>
                        // )
                    }

                    const isNew = !p.访问过 && dayjs(p.创建时间).add(14, "day").isAfter(new Date());

                    return (
                        <Link to={"/vip/pipeline/" + p.id} className="flex gap-3 sm:gap-6 px-3 py-6 sm:py-3 hover:bg-[var(--sand1)] rounded-md cursor-pointer" key={p.id}>
                            <Logo className="sm:hidden" logo={p.logo} name={p.名称} size={32} />
                            <Logo className="hidden sm:flex" logo={p.logo} name={p.名称} />
                            <div className="sm:mt-[1px] grow flex flex-col gap-2 sm:gap-3">
                                <div className="flex gap-2 grow">
                                    <div className="flex flex-col gap-2 grow">
                                        <div className="grow leading-relaxed">
                                            <h3 className="inline font-medium text-[16px]">{p.名称}</h3>
                                            { isNew ? (
                                                <div className="inline-block italic bg-[var(--tomato9)] opacity-90 text-[10px] rounded-md px-1 ml-1 pb-[1px] leading-tight -translate-y-[3px] text-[var(--lc-color-inversed)]">new</div>
                                            ) : null}
                                            {
                                                p.简介 ? (
                                                    <>
                                                        <span className="px-2 opacity-50">—</span>
                                                        <span className="text-[var(--color-11)] text-[15px]">{p.简介}</span>
                                                    </>
                                                ) : null
                                            }

                                        </div>
                                        <div className="flex text-[13px] gap-2 text-[var(--gray10)] px-1">
                                            <div className="hidden sm:flex gap-1.5 sm:gap-2 items-center grow flex-wrap">
                                                <Avatar nickName={p.上传者.姓名} avatar={p.上传者.用户.头像} size={16}></Avatar>
                                                <div className="">
                                                    {dayjs(p.创建时间).format("YY-MM-DD")}
                                                </div>
                                                {renderStatus(p.状态)}
                                                {(p.标签 || []).map(t => (
                                                    <React.Fragment key={t}>
                                                        {seperator}
                                                        <div className="text-[var(--gray10)]">{t}</div>
                                                    </React.Fragment>
                                                ))}

                                            </div>
                                            <div className="flex sm:hidden gap-2 flex-col flex-wrap">
                                                <div className="flex gap-1.5">
                                                    <Avatar nickName={p.上传者.姓名} avatar={p.上传者.用户.头像} size={16}></Avatar>
                                                    <div className="ml-1">
                                                        {dayjs(p.创建时间).format("YY-MM-DD")}
                                                    </div>
                                                    {renderStatus(p.状态)}
                                                </div>
                                                <div className="flex gap-1.5 flex-wrap px-0.5">
                                                    {(p.标签 || []).map((t, i) => (
                                                        <React.Fragment key={t}>
                                                            {i !== 0 ? seperator : null}
                                                            <div className="text-[var(--gray10)]">{t}</div>
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                                <div className="rounded-md flex sm:hidden mt-1 justify-center px-0.5 py-1.5 items-center gap-2 self-start bg-[var(--sand2)]">
                                                    {renderPLNs()}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rounded-md hidden sm:flex flex-col justify-center px-0.5 py-2 items-stretch gap-2 self-start bg-[var(--sand1)]">
                                        {renderPLNs()}
                                    </div>
                                </div>

                            </div>
                        </Link>
                    )
                })
            }
            {
                loadingMore ? (
                    Array(data && data.length > 0 ? 5 : 10).fill(0).map((_, index) => {
                        return (
                            <div className="flex gap-3 sm:gap-4 px-3 py-2 rounded" key={index}>
                                <div className="h-[3rem] w-[3rem] min-w-[3rem] bg-[var(--sand2)] rounded" />
                                <div className="grow flex flex-col gap-3">
                                    <div className="flex gap-3 w-full">
                                        <div className="grow bg-[var(--sand2)] h-[3rem] rounded">
                                        </div>
                                        <div className="rounded bg-[var(--sand2)] h-[2.5rem] w-[2.5rem] self-start rounded">
                                        </div>
                                    </div>
                                    <div className="flex bg-[var(--sand2)] h-[1.5rem] w-full">
                                    </div>
                                </div>
                            </div>
                        )
                    })
                ) : null
            }
            {
                !loadingMore && (data && data.length == 0) ? (
                    <div className="py-16 self-center flex flex-col gap-6 items-center">
                        <i className='text-[80px] bx bx-folder-open text-[var(--sand9)] opacity-80'></i>
                        <div className=" text-[var(--gray9)]">目前还没有项目</div>
                    </div>
                ) : null
            }
        </div>
    )
}


