
// This file is generated. Don't modify it.
import React from 'react'
export default function Loading2Icon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M3.00478914,10 C1.90021964,10 1.00478914,9.1045695 1.00478914,8 C1.00478914,6.8954305 1.90021964,6 3.00478914,6 C4.10935864,6 5.00478914,6.8954305 5.00478914,8 C5.00478914,9.1045695 4.10935864,10 3.00478914,10 Z M9.00478914,9.5 C8.17636202,9.5 7.50478914,8.82842712 7.50478914,8 C7.50478914,7.17157288 8.17636202,6.5 9.00478914,6.5 C9.83321627,6.5 10.5047891,7.17157288 10.5047891,8 C10.5047891,8.82842712 9.83321627,9.5 9.00478914,9.5 Z M14.0047891,9 C13.4525044,9 13.0047891,8.55228475 13.0047891,8 C13.0047891,7.44771525 13.4525044,7 14.0047891,7 C14.5570739,7 15.0047891,7.44771525 15.0047891,8 C15.0047891,8.55228475 14.5570739,9 14.0047891,9 Z" id="Loading2Icon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Loading2">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="Loading2Icon-mask-2" fill="white">
                            <use href="#Loading2Icon-path-1"></use>
                        </mask>
                        <use id="Combined-Shape" fill={color} fillRule="nonzero" href="#Loading2Icon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
