

import React, { useState, useEffect, useRef } from 'react'

// import renderTime from 'bwax-ui/renderTime'
import ScrollHelper from 'bwax-ui/ScrollHelper';
import classNames from 'classnames'
import dayjs from 'dayjs';

import RichText from 'bwax-ui/basic/RichText';

export default function DevLog({ facade }) {

    const fieldPaths = ["内容", "日期"];
    const entityName = "开发日志";

    const [data, setData] = useState([]);

    const [hasMore, setHasMore] = useState([])
    const [isLoadingMore, setIsLoadingMore] = useState(false)

    // 
    async function loadData(offset = 0) {
        setIsLoadingMore(true);

        const [result, error] = await facade.listAll({
            entityName,
            // condition: [[{ field: "创建者.id", op: "eq", value: currentUserId }]],
            sort: [{ field: "创建时间", order: "DESC" }],
            pageSize: 8,
            offset,
            fieldPaths,
        });
        setIsLoadingMore(false);
        if (!error) {
            if (result) {
                const hasMore = result.pageSize + offset < result.count;
                setHasMore(hasMore);
                setData(prev => {
                    // 要排除掉已经存在的，然后在 append 到后面
                    const existing = prev || [];
                    const toAppend = result.data.filter(s => !existing.some(e => e.id === s.id));
                    return [...existing, ...toAppend];
                })
            }
        }
    }

    useEffect(() => {
        loadData()
    }, [] )


    const loadMore = () => {
        if(hasMore) {
            loadData(data.length);
        }
    }

    const listRef = useRef()
    const loadMoreRef = useRef();
    loadMoreRef.current = loadMore;

    useEffect(() => {
        if(listRef.current) {
            const scrollHelper = new ScrollHelper(listRef.current, {
                onScrolledToBottom: _ => {
                    loadMoreRef.current && loadMoreRef.current();
                }
            });
            return () => {
                scrollHelper.cleanUp();
            }
        }
    }, [ listRef.current ]);

    function renderItem (item, index) {
        return (
            <div className={classNames("py-5 sm:py-4 flex flex-col gap-2", { "border-t" : index !== 0 })} key={item.id}>
                <div className="font-medium font-size-13">{ dayjs(item.日期).format("YYYY-MM-DD") }</div>
                <div className="">
                    <RichText params={{ value: item.内容 }} />
                </div>
            </div>
        )

    }

    return (
        <div className="flex flex-col gap-2 h-full sm:h-[75vh]">
            <div className="font-medium gap-2 px-4 flex items-center">
                <i className='bx bx-code-alt'></i>
                产品更新日志
            </div>
            <div className="flex flex-col px-6 grow overflow-auto min-h-0" ref={listRef}>
                    { data.map(renderItem)}
                    { isLoadingMore ? (
                        Array(3).fill(0).map((_, index) => (
                            <div className="py-3 flex flex-col gap-4" key={"l" + index}>
                                <div className="h-[1rem] bg-[var(--gray2)] w-[4rem]" />
                                <div className="grow h-[4rem] bg-[var(--gray2)]" />                                
                            </div>
                        ))
                    ) : null }
                </div>
        </div>
    )
}
