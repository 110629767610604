
import React, { useState, useTransition, useEffect, useRef } from 'react';

import PageRenderer from './PageRenderer';

import NProgress from 'nprogress'

NProgress.configure({ showSpinner: false });

export default function PageTransitionRenderer(props) {

    if(typeof(document) === 'undefined') {
        // server side, no transition needed:
        return <PageRenderer {...props} />
    } else {
        return <ClientSideTransitionWrapper {...props} />
    }
}

function ClientSideTransitionWrapper (props) {
    const [
        isPending,
        startTransition,
    ] = useTransition({
        timeoutMs: 3000
    });

    const startedRef = useRef(false);
    useEffect(() => {
        if(isPending) {
            startedRef.current = true;
            // 
            NProgress.start()
        } else {
            if(startedRef.current) {
                startedRef.current = false;
                NProgress.done();
            }
        }

    }, [ isPending ]);

    const {
        location,
        match,
        history,
        ...others
    } = props;


    const [pageTarget, setPageTarget] = useState({
        location, match, history
    });

    // 根据 pathname 的转换，启动 transition
    useEffect(() => {
        // update page target
        if (location.pathname != pageTarget.location.pathname) {
            startTransition(() => {
                setPageTarget({
                    location, match, history,
                })
            })
        }
    }, [location.pathname])

    
    function getPageTarget() {
        if(location.pathname == pageTarget.location.pathname) {
            return {
                // location 的 search / #hash 可能会被改变，所以不要用 pargeTarget 里面的版本
                location, match, history,
            }
        } else {
            return pageTarget
        }

    }


    return (
        <MemoPageRenderer {
            ...{
                // key: pageTarget.pathname,
                ...others,
                ...getPageTarget(),
                currentLocation: location,
            }
        } />
    )
}


// 
const MemoPageRenderer = React.memo(function(props){

    return (
        <PageRenderer {...props} />
    )

}, (_prevProps, nextProps) => {
    
    const { location, currentLocation } = nextProps;

    // 正在 切换去 login？
    if(typeof(document) !== 'undefined' && document.__switching_to_login__) {
        return true
    }

    // console.log("正在切换? ", location, currentLocation)    
    if (location.pathname !== currentLocation.pathname) {
        // 正在切换
        return true
    } else {
        return false
    }
})


