import React from 'react'

import { identity, pickBy } from 'bwax/list'

const EMPTY_BLOCKS = {
    'unstyled': <p><br /></p>,
    'paragraph': <p><br /></p>,
    'header-one': <h1><br /></h1>,
    'header-two': <h2><br /></h2>,
    'header-three': <h3><br /></h3>,
    'header-four': <h4><br /></h4>,
    'header-five': <h5><br /></h5>,
    'header-six': <h6><br /></h6>,
    'blockquote': <blockquote><br /></blockquote>,
}

const convertToHTML = {
    blockToHTML: (block) => {
        const emptyBlock = EMPTY_BLOCKS[block.type]
        if (emptyBlock && block.text.trim() === "") {
            return emptyBlock
        }
    }
}

const convertToReact = {
    blockToReact: (block) => {
        const emptyBlock = EMPTY_BLOCKS[block.type]
        if (emptyBlock && block.text.trim() === "") {
            return emptyBlock
        }
    }
}

export { convertToHTML, convertToReact }
