// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Id = require("bs-platform/lib/js/belt_Id.js");
var Belt_Map = require("bs-platform/lib/js/belt_Map.js");
var Plate$BwaxMobile = require("../plate.bs.js");
var Lang_eval$BwaxMobile = require("./lang_eval.bs.js");

var cmp = Lang_eval$BwaxMobile.compare_value;

var T = Belt_Id.MakeComparable({
      cmp: cmp
    });

function empty(param) {
  return Belt_Map.make(T);
}

function singleton(k, v) {
  return Belt_Map.set(Belt_Map.make(T), k, v);
}

function insert(k, v, dict) {
  return Belt_Map.set(dict, k, v);
}

function update(k, f, dict) {
  return Belt_Map.update(dict, k, f);
}

function remove(k, dict) {
  return Belt_Map.remove(dict, k);
}

function remove_many(ks, dict) {
  return Belt_Map.removeMany(dict, Curry._1(Plate$BwaxMobile.List.to_array, ks));
}

var is_empty = Belt_Map.isEmpty;

function member(k, dict) {
  return Belt_Map.has(dict, k);
}

function get(k, dict) {
  return Belt_Map.get(dict, k);
}

var size = Belt_Map.size;

function some(f, dict) {
  return Belt_Map.some(dict, f);
}

function every(f, dict) {
  return Belt_Map.every(dict, f);
}

function find(f, dict) {
  return Belt_Map.findFirstBy(dict, f);
}

var minimum = Belt_Map.minimum;

var maximum = Belt_Map.maximum;

function is_equal(eq, dict1, dict2) {
  return Belt_Map.eq(dict1, dict2, eq);
}

function keys(dict) {
  return Curry._1(Plate$BwaxMobile.List.from_array, Belt_Map.keysToArray(dict));
}

function values(dict) {
  return Curry._1(Plate$BwaxMobile.List.from_array, Belt_Map.valuesToArray(dict));
}

var to_list = Belt_Map.toList;

function from_list(l) {
  return Belt_Map.fromArray(Curry._1(Plate$BwaxMobile.List.to_array, l), T);
}

function map(f, dict) {
  return Belt_Map.mapWithKey(dict, f);
}

function foldl(f, acc, dict) {
  return Belt_Map.reduce(dict, acc, f);
}

function filter(f, dict) {
  return Belt_Map.keep(dict, f);
}

function partition(f, dict) {
  return Belt_Map.partition(dict, f);
}

function merge(f, first, second) {
  return Belt_Map.merge(first, second, f);
}

exports.T = T;
exports.empty = empty;
exports.singleton = singleton;
exports.insert = insert;
exports.update = update;
exports.remove = remove;
exports.remove_many = remove_many;
exports.is_empty = is_empty;
exports.member = member;
exports.get = get;
exports.size = size;
exports.some = some;
exports.every = every;
exports.find = find;
exports.minimum = minimum;
exports.maximum = maximum;
exports.is_equal = is_equal;
exports.keys = keys;
exports.values = values;
exports.to_list = to_list;
exports.from_list = from_list;
exports.map = map;
exports.foldl = foldl;
exports.filter = filter;
exports.partition = partition;
exports.merge = merge;
/* T Not a pure module */
