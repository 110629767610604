
import React, { useEffect, useState, useRef } from 'react'

import TextareaAutosize from '@mui/base/TextareaAutosize';

import classNames from "classnames"

import './TextArea.less'

const TextArea = React.forwardRef((props, ref) => {

    const { 
        minRows = 2, maxRows, onFocus, onBlur,  color, className,
        value, onChange = _ => {},

        styled = false,

        isDisabled = false,

        ...otherProps
    } = props;

    const inputRef = useRef();

    const [focused, setFocused] = useState(false);

    useEffect(() => {
        if(props.autoFocus && inputRef.current) {
            setTimeout(() => {
                inputRef.current.focus()
            }, 1)
        }
    }, [inputRef.current])

    return (
        <div className={classNames("lc-text-area", className, { "is-styled": styled, "cursor-not-allowed opacity-60": isDisabled })} data-focused={focused} data-color={color} data-has-value={value && value.trim().length > 0}>
            <TextareaAutosize {...{
                minRows, maxRows,
                value: value || "",

                className: isDisabled ? "cursor-not-allowed": "",
                onChange: e => {
                    onChange(e.target.value)
                },

                onFocus: e => {
                    if (onFocus) { onFocus(e) }
                    setFocused(true)
                },
                onBlur: e => {
                    if (onBlur) { onBlur(e) }
                    setFocused(false)
                },
                ref: r => {
                    if(ref) {
                        ref.current = r
                    }
                    inputRef.current = r;
                },
                disabled: isDisabled,
                ...otherProps
            }} />
        </div>
    );
});

export default TextArea;