import React from 'react'

import './DynamicLogo.less'

export default function DynamicLogo() {

    return (
        <div className='dynamic-logo-box'>
            <div className='dynamic-logo'>

            </div>
        </div>
    )
}
