

import { Editor, Element as SlateElement, Transforms, Path, Node } from 'slate'

export function isInLink (editor) {
    const ancestors = Array.from(Node.ancestors(editor, editor.selection.focus.path, { reverse: true }))
    const linkAncestor = ancestors.find(ancestor => {
        const [ancestorNode, _] = ancestor
        return ancestorNode.type === 'link'
    })

    return !!linkAncestor
}

export function removeFirstItemContentFromList (editor) {

    const { selection } = editor
    const { focus } = selection

    const [parentNode, parentPath] = Editor.parent(
        editor,
        isInLink(editor) ? Path.parent(focus.path) : focus.path
    );
    if(parentPath.length <= 3) {
        const [listParentNode, listParentPath] = Editor.parent(editor, selection.focus?.path, { depth: parentPath.length - 1 })

        Transforms.unwrapNodes(editor, {
            match: n => !Editor.isEditor(n) && SlateElement.isElement(n) &&
                (n.type === "numbered-list" || n.type === "bulleted-list"),
            split: true,
        })

        const singleListItem = editor.children.find(n => n.type === "list-item") 
        const singleListItemIndex = editor.children.findIndex(n => n.type === "list-item")
        const splitIndex = parentPath[parentPath.length - 1]

        Transforms.liftNodes(editor, {
            at: Editor.range(editor, { at: [singleListItemIndex] }),
            match: n => singleListItem.children.findIndex(c => c === n) >= splitIndex
        })

        if (parentPath.length > 2) {

            if (splitIndex > 0) {
                const block = { type: listParentNode.type, children: [] }
                Transforms.wrapNodes(editor, block, { at: listParentPath })
            }
        }
    } else {
        // 可能存在嵌套的 list，这样的话 editor.children 中就没有 list-item 了
        if(parentPath[parentPath.length - 1] === 0 && selection.anchor.offset === 0) {
            // 将当前 list-item 提升到上一个 list 中
            Transforms.moveNodes(editor, { at: parentPath.slice(0, parentPath.length - 1), to: Path.parent(parentPath) })
            Transforms.removeNodes(editor, { at: [...parentPath.slice(0, 1), 1]})

        } else {
            Transforms.mergeNodes(editor)
        }
        
    }
   
}

export function removeOtherItemContentFromList (editor, shouldRemoveNode) {
    const { selection } = editor
    const { focus } = selection
    const [parentNode, parentPath] = Editor.parent(
        editor,
        isInLink(editor) ? Path.parent(focus.path) : focus.path
    );
    if(parentPath.length <= 3) {
        Transforms.unwrapNodes(editor, {
            match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && 
                (n.type === "numbered-list" || n.type === "bulleted-list"),
            split: true,
        })
    
        const singleListItem = editor.children.find(n => n.type === "list-item")
        if(singleListItem) {
            const singleListItemIndex = editor.children.findIndex(n => n.type === "list-item")
            const splitIndex = parentPath[parentPath.length - 1]
    
            if(parentPath.length > 2) {
                if(splitIndex > 0) {
                    const newListItemChildren = singleListItem.children.filter((c, idx) => idx < splitIndex)
    
                    Transforms.insertNodes(editor, {
                        type: "list-item",
                        children: newListItemChildren
                    }, { 
                        at: Path.parent(parentPath)
                    })
                }
                
                Transforms.liftNodes(editor, { 
                    at: Editor.range(editor, { at: [ singleListItemIndex ] }),
                    match: n => singleListItem.children.findIndex(c => c === n) >= splitIndex
                })
    
                if(shouldRemoveNode) {
                    Transforms.removeNodes(editor, { at: [singleListItemIndex] })
                }
            }
        }
    } else {
        if(parentPath[parentPath.length - 1] === 0 && selection.anchor.offset === 0) {
            // 将当前 list-item 提升到上一个 list 中
            Transforms.moveNodes(editor, { at: parentPath.slice(0, parentPath.length - 1), to: Path.parent(parentPath) })
            Transforms.removeNodes(editor, { at: [...parentPath.slice(0, 1), 1]})

        } else {
            Transforms.mergeNodes(editor)
        }
    }

    
    
}