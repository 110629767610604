// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Plate$BwaxMobile = require("../plate.bs.js");
var Runtime_common$BwaxMobile = require("../lang/mod/runtime_common.bs.js");
var Base_query_types$BwaxMobile = require("../base_query_types.bs.js");

var number_grouping_funcs = /* :: */[
  /* tuple */[
    "mod",
    /* tuple */[
      "MOD",
      /* :: */[
        /* Arg_int */2,
        /* [] */0
      ]
    ]
  ],
  /* :: */[
    /* tuple */[
      "div",
      /* tuple */[
        "DIV",
        /* :: */[
          /* Arg_float */0,
          /* [] */0
        ]
      ]
    ],
    /* :: */[
      /* tuple */[
        "abs",
        /* tuple */[
          "ABS",
          /* [] */0
        ]
      ],
      /* :: */[
        /* tuple */[
          "ceiling",
          /* tuple */[
            "CEILING",
            /* [] */0
          ]
        ],
        /* :: */[
          /* tuple */[
            "floor",
            /* tuple */[
              "FLOOR",
              /* [] */0
            ]
          ],
          /* :: */[
            /* tuple */[
              "format",
              /* tuple */[
                "FORMAT",
                /* :: */[
                  /* Arg_string */1,
                  /* [] */0
                ]
              ]
            ],
            /* :: */[
              /* tuple */[
                "round",
                /* tuple */[
                  "ROUND",
                  /* :: */[
                    /* Arg_int */2,
                    /* [] */0
                  ]
                ]
              ],
              /* :: */[
                /* tuple */[
                  "sign",
                  /* tuple */[
                    "SIGN",
                    /* [] */0
                  ]
                ],
                /* :: */[
                  /* tuple */[
                    "truncate",
                    /* tuple */[
                      "TRUNCATE",
                      /* :: */[
                        /* Arg_int */2,
                        /* [] */0
                      ]
                    ]
                  ],
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]
  ]
];

var date_grouping_funcs = /* :: */[
  /* tuple */[
    "date",
    /* tuple */[
      "DATE",
      /* [] */0
    ]
  ],
  /* :: */[
    /* tuple */[
      "dateDiff",
      /* tuple */[
        "DATEDIFF",
        /* :: */[
          /* Arg_date */3,
          /* [] */0
        ]
      ]
    ],
    /* :: */[
      /* tuple */[
        "dateFormat",
        /* tuple */[
          "DATE_FORMAT",
          /* :: */[
            /* Arg_string */1,
            /* [] */0
          ]
        ]
      ],
      /* :: */[
        /* tuple */[
          "dayName",
          /* tuple */[
            "DAYNAME",
            /* [] */0
          ]
        ],
        /* :: */[
          /* tuple */[
            "dayOfMonth",
            /* tuple */[
              "DAYOFMONTH",
              /* [] */0
            ]
          ],
          /* :: */[
            /* tuple */[
              "dayOfWeek",
              /* tuple */[
                "DAYOFWEEK",
                /* [] */0
              ]
            ],
            /* :: */[
              /* tuple */[
                "dayOfYear",
                /* tuple */[
                  "DAYOFYEAR",
                  /* [] */0
                ]
              ],
              /* :: */[
                /* tuple */[
                  "hour",
                  /* tuple */[
                    "HOUR",
                    /* [] */0
                  ]
                ],
                /* :: */[
                  /* tuple */[
                    "minute",
                    /* tuple */[
                      "MINUTE",
                      /* [] */0
                    ]
                  ],
                  /* :: */[
                    /* tuple */[
                      "second",
                      /* tuple */[
                        "SECOND",
                        /* [] */0
                      ]
                    ],
                    /* :: */[
                      /* tuple */[
                        "microsecond",
                        /* tuple */[
                          "MICROSECOND",
                          /* [] */0
                        ]
                      ],
                      /* :: */[
                        /* tuple */[
                          "monthName",
                          /* tuple */[
                            "MONTHNAME",
                            /* [] */0
                          ]
                        ],
                        /* :: */[
                          /* tuple */[
                            "quarter",
                            /* tuple */[
                              "QUARTER",
                              /* [] */0
                            ]
                          ],
                          /* :: */[
                            /* tuple */[
                              "month",
                              /* tuple */[
                                "MONTH",
                                /* [] */0
                              ]
                            ],
                            /* :: */[
                              /* tuple */[
                                "week",
                                /* tuple */[
                                  "WEEK",
                                  /* [] */0
                                ]
                              ],
                              /* :: */[
                                /* tuple */[
                                  "weekDay",
                                  /* tuple */[
                                    "WEEKDAY",
                                    /* [] */0
                                  ]
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "weekOfYear",
                                    /* tuple */[
                                      "WEEKOFYEAR",
                                      /* [] */0
                                    ]
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "year",
                                      /* tuple */[
                                        "YEAR",
                                        /* [] */0
                                      ]
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        "yearWeek",
                                        /* tuple */[
                                          "YEARWEEK",
                                          /* [] */0
                                        ]
                                      ],
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]
  ]
];

var string_grouping_funcs = /* :: */[
  /* tuple */[
    "bitLength",
    /* tuple */[
      "BIT_LENGTH",
      /* [] */0
    ]
  ],
  /* :: */[
    /* tuple */[
      "charLength",
      /* tuple */[
        "CHAR_LENGTH",
        /* [] */0
      ]
    ],
    /* :: */[
      /* tuple */[
        "left",
        /* tuple */[
          "LEFT",
          /* :: */[
            /* Arg_int */2,
            /* [] */0
          ]
        ]
      ],
      /* :: */[
        /* tuple */[
          "length",
          /* tuple */[
            "LENGTH",
            /* [] */0
          ]
        ],
        /* :: */[
          /* tuple */[
            "lower",
            /* tuple */[
              "LOWER",
              /* [] */0
            ]
          ],
          /* :: */[
            /* tuple */[
              "lpad",
              /* tuple */[
                "LPAD",
                /* :: */[
                  /* Arg_int */2,
                  /* :: */[
                    /* Arg_char */4,
                    /* [] */0
                  ]
                ]
              ]
            ],
            /* :: */[
              /* tuple */[
                "ltrim",
                /* tuple */[
                  "LTRIM",
                  /* [] */0
                ]
              ],
              /* :: */[
                /* tuple */[
                  "replace",
                  /* tuple */[
                    "REPLACE",
                    /* :: */[
                      /* Arg_string */1,
                      /* :: */[
                        /* Arg_string */1,
                        /* [] */0
                      ]
                    ]
                  ]
                ],
                /* :: */[
                  /* tuple */[
                    "right",
                    /* tuple */[
                      "RIGHT",
                      /* :: */[
                        /* Arg_int */2,
                        /* [] */0
                      ]
                    ]
                  ],
                  /* :: */[
                    /* tuple */[
                      "rpad",
                      /* tuple */[
                        "RPAD",
                        /* :: */[
                          /* Arg_int */2,
                          /* :: */[
                            /* Arg_char */4,
                            /* [] */0
                          ]
                        ]
                      ]
                    ],
                    /* :: */[
                      /* tuple */[
                        "rtrim",
                        /* tuple */[
                          "RTRIM",
                          /* [] */0
                        ]
                      ],
                      /* :: */[
                        /* tuple */[
                          "substring",
                          /* tuple */[
                            "SUBSTRING",
                            /* :: */[
                              /* Arg_int */2,
                              /* :: */[
                                /* Arg_int */2,
                                /* [] */0
                              ]
                            ]
                          ]
                        ],
                        /* :: */[
                          /* tuple */[
                            "substring_index",
                            /* tuple */[
                              "SUBSTRING_INDEX",
                              /* :: */[
                                /* Arg_string */1,
                                /* :: */[
                                  /* Arg_int */2,
                                  /* [] */0
                                ]
                              ]
                            ]
                          ],
                          /* :: */[
                            /* tuple */[
                              "trim",
                              /* tuple */[
                                "TRIM",
                                /* [] */0
                              ]
                            ],
                            /* :: */[
                              /* tuple */[
                                "upper",
                                /* tuple */[
                                  "UPPER",
                                  /* [] */0
                                ]
                              ],
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]
  ]
];

var any_grouping_funcs = /* :: */[
  /* tuple */[
    "exactly",
    /* tuple */[
      "EXACTLY",
      /* [] */0
    ]
  ],
  /* [] */0
];

var number_aggregate_funcs = /* :: */[
  /* tuple */[
    "avg",
    /* tuple */[
      "AVG",
      /* [] */0,
      "Float"
    ]
  ],
  /* :: */[
    /* tuple */[
      "bitAnd",
      /* tuple */[
        "BIT_AND",
        /* [] */0,
        "Float"
      ]
    ],
    /* :: */[
      /* tuple */[
        "bitOr",
        /* tuple */[
          "BIT_OR",
          /* [] */0,
          "Float"
        ]
      ],
      /* :: */[
        /* tuple */[
          "bitXor",
          /* tuple */[
            "BIT_XOR",
            /* [] */0,
            "Float"
          ]
        ],
        /* :: */[
          /* tuple */[
            "groupConcat",
            /* tuple */[
              "GROUP_CONCAT",
              /* :: */[
                /* Arg_string */1,
                /* [] */0
              ],
              "String"
            ]
          ],
          /* :: */[
            /* tuple */[
              "max",
              /* tuple */[
                "MAX",
                /* [] */0,
                "Float"
              ]
            ],
            /* :: */[
              /* tuple */[
                "min",
                /* tuple */[
                  "MIN",
                  /* [] */0,
                  "Float"
                ]
              ],
              /* :: */[
                /* tuple */[
                  "stddev",
                  /* tuple */[
                    "STDDEV_POP",
                    /* [] */0,
                    "Float"
                  ]
                ],
                /* :: */[
                  /* tuple */[
                    "stddevSamp",
                    /* tuple */[
                      "STDDEV_SAMP",
                      /* [] */0,
                      "Float"
                    ]
                  ],
                  /* :: */[
                    /* tuple */[
                      "sum",
                      /* tuple */[
                        "SUM",
                        /* [] */0,
                        "Float"
                      ]
                    ],
                    /* :: */[
                      /* tuple */[
                        "variance",
                        /* tuple */[
                          "VAR_POP",
                          /* [] */0,
                          "Float"
                        ]
                      ],
                      /* :: */[
                        /* tuple */[
                          "varSamp",
                          /* tuple */[
                            "VAR_SAMP",
                            /* [] */0,
                            "Float"
                          ]
                        ],
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]
  ]
];

var string_aggregate_funcs = /* :: */[
  /* tuple */[
    "groupConcat",
    /* tuple */[
      "GROUP_CONCAT",
      /* :: */[
        /* Arg_string */1,
        /* [] */0
      ],
      "String"
    ]
  ],
  /* [] */0
];

var any_aggregate_funcs = /* :: */[
  /* tuple */[
    "count",
    /* tuple */[
      "COUNT",
      /* [] */0,
      "Int"
    ]
  ],
  /* :: */[
    /* tuple */[
      "countDistinct",
      /* tuple */[
        "COUNT_DISTINCT",
        /* [] */0,
        "Int"
      ]
    ],
    /* :: */[
      /* tuple */[
        "jsonArray",
        /* tuple */[
          "JSON_ARRAYAGG",
          /* [] */0,
          "JSONPlural"
        ]
      ],
      /* [] */0
    ]
  ]
];

function arg_to_string(arg) {
  switch (arg) {
    case /* Arg_float */0 :
        return "Float";
    case /* Arg_string */1 :
        return "String";
    case /* Arg_int */2 :
        return "Int";
    case /* Arg_date */3 :
        return "Date";
    case /* Arg_char */4 :
        return "Char";
    
  }
}

var order_field = "order: SortingDirection";

var alias_field = "aliasName: String";

function args_field(args) {
  if (args) {
    if (args[1]) {
      return "args: ( " + (Plate$BwaxMobile.Str.join(", ", Plate$BwaxMobile.List.map(arg_to_string, args)) + " )");
    } else {
      return "args: " + arg_to_string(args[0]);
    }
  }
  
}

function drop_none(l) {
  return Plate$BwaxMobile.List.keep_map((function (x) {
                return x;
              }), l);
}

function src_funcs(funcs) {
  return Plate$BwaxMobile.List.map((function (param) {
                var l_000 = args_field(param[1][1]);
                var l_001 = /* :: */[
                  order_field,
                  /* :: */[
                    alias_field,
                    /* [] */0
                  ]
                ];
                var l = /* :: */[
                  l_000,
                  l_001
                ];
                var fls = Plate$BwaxMobile.List.keep_map((function (x) {
                        return x;
                      }), l);
                return param[0] + (":" + Base_query_types$BwaxMobile.join_field_lines(3, fls));
              }), funcs);
}

var fieldLines = src_funcs(Pervasives.$at(number_grouping_funcs, any_grouping_funcs));

var number_grouping = "type alias NumberGrouping =" + Base_query_types$BwaxMobile.join_field_lines(1, fieldLines);

var fieldLines$1 = src_funcs(any_grouping_funcs);

var boolean_grouping = "type alias BooleanGrouping =" + Base_query_types$BwaxMobile.join_field_lines(1, fieldLines$1);

var fieldLines$2 = src_funcs(Pervasives.$at(string_grouping_funcs, any_grouping_funcs));

var string_grouping = "type alias StringGrouping =" + Base_query_types$BwaxMobile.join_field_lines(1, fieldLines$2);

var fieldLines$3 = src_funcs(Pervasives.$at(date_grouping_funcs, any_grouping_funcs));

var datetime_grouping = "type alias DateGrouping =" + Base_query_types$BwaxMobile.join_field_lines(1, fieldLines$3);

var fieldLines$4 = src_funcs(any_grouping_funcs);

var id_grouping = "type alias IDGrouping =" + Base_query_types$BwaxMobile.join_field_lines(1, fieldLines$4);

var fieldLines$5 = src_funcs(any_grouping_funcs);

var any_grouping = "type alias AnyGrouping =" + Base_query_types$BwaxMobile.join_field_lines(1, fieldLines$5);

var preset_grouping_types_001 = /* :: */[
  boolean_grouping,
  /* :: */[
    string_grouping,
    /* :: */[
      datetime_grouping,
      /* :: */[
        id_grouping,
        /* :: */[
          any_grouping,
          /* [] */0
        ]
      ]
    ]
  ]
];

var preset_grouping_types = /* :: */[
  number_grouping,
  preset_grouping_types_001
];

function build_field_lines(funcs) {
  return Plate$BwaxMobile.List.map((function (param) {
                var match = param[1];
                var having_field = "having: ~" + (match[2] + "Condition");
                var fls = Plate$BwaxMobile.List.keep_map((function (x) {
                        return x;
                      }), /* :: */[
                      args_field(match[1]),
                      /* :: */[
                        order_field,
                        /* :: */[
                          having_field,
                          /* :: */[
                            alias_field,
                            /* [] */0
                          ]
                        ]
                      ]
                    ]);
                return param[0] + (":" + Base_query_types$BwaxMobile.join_field_lines(3, fls));
              }), funcs);
}

var fieldLines$6 = build_field_lines(Pervasives.$at(number_aggregate_funcs, any_aggregate_funcs));

var number_aggregate = "type alias NumberAggregation =" + Base_query_types$BwaxMobile.join_field_lines(1, fieldLines$6);

var fieldLines$7 = build_field_lines(any_aggregate_funcs);

var boolean_aggregate = "type alias BooleanAggregation =" + Base_query_types$BwaxMobile.join_field_lines(1, fieldLines$7);

var fieldLines$8 = build_field_lines(Pervasives.$at(string_aggregate_funcs, any_aggregate_funcs));

var string_aggregate = "type alias StringAggregation =" + Base_query_types$BwaxMobile.join_field_lines(1, fieldLines$8);

var fieldLines$9 = build_field_lines(any_aggregate_funcs);

var datetime_aggregate = "type alias DateAggregation =" + Base_query_types$BwaxMobile.join_field_lines(1, fieldLines$9);

var fieldLines$10 = build_field_lines(any_aggregate_funcs);

var id_aggregate = "type alias IDAggregation =" + Base_query_types$BwaxMobile.join_field_lines(1, fieldLines$10);

var fieldLines$11 = build_field_lines(any_aggregate_funcs);

var any_aggregate = "type alias AnyAggregation =" + Base_query_types$BwaxMobile.join_field_lines(1, fieldLines$11);

var preset_aggregate_types_001 = /* :: */[
  boolean_aggregate,
  /* :: */[
    string_aggregate,
    /* :: */[
      datetime_aggregate,
      /* :: */[
        id_aggregate,
        /* :: */[
          any_aggregate,
          /* [] */0
        ]
      ]
    ]
  ]
];

var preset_aggregate_types = /* :: */[
  number_aggregate,
  preset_aggregate_types_001
];

function field_group_type(resolve_entity_name, field) {
  if (field[/* multivalued */4]) {
    return "~AnyGrouping";
  } else {
    var match = field[/* dtype */2];
    if (typeof match === "number") {
      switch (match) {
        case /* Atom_int */0 :
        case /* Atom_float */1 :
            return "~NumberGrouping";
        case /* Atom_bool */2 :
            return "~BooleanGrouping";
        case /* Atom_string */3 :
            return "~StringGrouping";
        case /* Atom_date */4 :
            return "~DateGrouping";
        default:
          return ;
      }
    } else {
      switch (match.tag | 0) {
        case /* Atom_select */0 :
            return "~StringGrouping";
        case /* Entity_link */1 :
            var ename = Base_query_types$BwaxMobile.normalize_name(Curry._1(resolve_entity_name, match[0]));
            return "~" + Base_query_types$BwaxMobile.group_tname(ename);
        case /* Entity_link_opaque */2 :
            return "~IDGrouping";
        default:
          return ;
      }
    }
  }
}

function field_aggregate_type(resolve_entity_name, field) {
  if (field[/* multivalued */4]) {
    return "~AnyAggregation";
  } else {
    var match = field[/* dtype */2];
    if (typeof match === "number") {
      switch (match) {
        case /* Atom_int */0 :
        case /* Atom_float */1 :
            return "~NumberAggregation";
        case /* Atom_bool */2 :
            return "~BooleanAggregation";
        case /* Atom_string */3 :
            return "~StringAggregation";
        case /* Atom_date */4 :
            return "~DateAggregation";
        default:
          return ;
      }
    } else {
      switch (match.tag | 0) {
        case /* Atom_select */0 :
            return "~StringAggregation";
        case /* Entity_link */1 :
            var ename = Base_query_types$BwaxMobile.normalize_name(Curry._1(resolve_entity_name, match[0]));
            return "~" + Base_query_types$BwaxMobile.aggregate_tname(ename);
        case /* Entity_link_opaque */2 :
            return "~IDAggregation";
        default:
          return ;
      }
    }
  }
}

function src_group_type(entity_dict, entity) {
  var prefix = "type alias " + (Base_query_types$BwaxMobile.group_tname(entity[/* name */0]) + " =");
  var field_src = function (field) {
    return Plate$BwaxMobile.$$Option.map((function (group_type) {
                  var fname = Base_query_types$BwaxMobile.normalize_field_name(field[/* name */0]);
                  return fname + (": " + group_type);
                }), field_group_type((function (param) {
                      return Base_query_types$BwaxMobile.get_entity_name(entity_dict, param);
                    }), field));
  };
  var field_lines_001 = Plate$BwaxMobile.List.keep_map(field_src, Base_query_types$BwaxMobile.materialized_fields(entity));
  var field_lines = /* :: */[
    "id: ~IDGrouping",
    field_lines_001
  ];
  return prefix + Base_query_types$BwaxMobile.join_field_lines(1, field_lines);
}

function src_aggregate_type(entity_dict, entity) {
  var prefix = "type alias " + (Base_query_types$BwaxMobile.aggregate_tname(entity[/* name */0]) + " =");
  var field_src = function (field) {
    return Plate$BwaxMobile.$$Option.map((function (at) {
                  var fname = Base_query_types$BwaxMobile.normalize_field_name(field[/* name */0]);
                  return fname + (": " + at);
                }), field_aggregate_type((function (param) {
                      return Base_query_types$BwaxMobile.get_entity_name(entity_dict, param);
                    }), field));
  };
  var field_lines_001 = Plate$BwaxMobile.List.keep_map(field_src, Base_query_types$BwaxMobile.materialized_fields(entity));
  var field_lines = /* :: */[
    "id: ~IDAggregation",
    field_lines_001
  ];
  return prefix + Base_query_types$BwaxMobile.join_field_lines(1, field_lines);
}

function record_and_then(f, v) {
  var match = v[0];
  if (typeof match === "number" || match.tag !== /* V_record */2) {
    return ;
  } else {
    return Curry._1(f, match[0]);
  }
}

function get_order(al) {
  var match = Plate$BwaxMobile.List.assoc("order", al);
  if (match !== undefined) {
    var match$1 = match[0];
    if (typeof match$1 === "number" || match$1.tag !== /* V_tagged */6) {
      return ;
    } else {
      return match$1[0];
    }
  }
  
}

function get_alias_name(al) {
  var match = Plate$BwaxMobile.List.assoc("aliasName", al);
  if (match !== undefined) {
    var match$1 = match[0];
    if (typeof match$1 === "number" || match$1.tag !== /* V_literal */5) {
      return ;
    } else {
      var match$2 = match$1[0];
      if (typeof match$2 === "number" || match$2.tag !== /* String */2) {
        return ;
      } else {
        return match$2[0];
      }
    }
  }
  
}

function get_offset(al) {
  var match = Plate$BwaxMobile.List.assoc("offset", al);
  if (match !== undefined) {
    var match$1 = match[0];
    if (typeof match$1 === "number" || match$1.tag !== /* V_literal */5) {
      return ;
    } else {
      var match$2 = match$1[0];
      if (typeof match$2 === "number" || match$2.tag) {
        return ;
      } else {
        return match$2[0];
      }
    }
  }
  
}

function get_limit(al) {
  var match = Plate$BwaxMobile.List.assoc("limit", al);
  if (match !== undefined) {
    var match$1 = match[0];
    if (typeof match$1 === "number" || match$1.tag !== /* V_literal */5) {
      return ;
    } else {
      var match$2 = match$1[0];
      if (typeof match$2 === "number" || match$2.tag) {
        return ;
      } else {
        return match$2[0];
      }
    }
  }
  
}

function get_args(al) {
  var match = Plate$BwaxMobile.List.assoc("args", al);
  if (match !== undefined) {
    var v = match;
    var match$1 = v[0];
    if (typeof match$1 === "number" || match$1.tag) {
      return /* :: */[
              v,
              /* [] */0
            ];
    } else {
      return match$1[0];
    }
  } else {
    return /* [] */0;
  }
}

var all_aggregate_funcs = Pervasives.$at(any_aggregate_funcs, Pervasives.$at(string_aggregate_funcs, number_aggregate_funcs));

var all_grouping_funcs = Pervasives.$at(any_grouping_funcs, Pervasives.$at(number_grouping_funcs, Pervasives.$at(string_grouping_funcs, date_grouping_funcs)));

function get_maybe_alias(fname, k, al) {
  return Plate$BwaxMobile.$$Option.otherwise((function (param) {
                if (k === "exactly") {
                  return ;
                } else {
                  return k + ("(" + (fname + ")"));
                }
              }), get_alias_name(al));
}

function resolve_params(args, al) {
  var vl = get_args(al);
  return Plate$BwaxMobile.List.map((function (param) {
                var v = param[1];
                switch (param[0]) {
                  case /* Arg_float */0 :
                      return Runtime_common$BwaxMobile.extract_float(v);
                  case /* Arg_string */1 :
                      return Runtime_common$BwaxMobile.extract_string(v);
                  case /* Arg_int */2 :
                      return Runtime_common$BwaxMobile.extract_int(v);
                  case /* Arg_date */3 :
                      return Runtime_common$BwaxMobile.extract_date(v).toJSON();
                  case /* Arg_char */4 :
                      return Plate$BwaxMobile.Str.from_char(Runtime_common$BwaxMobile.extract_char(v));
                  
                }
              }), Plate$BwaxMobile.List.combine(args, vl));
}

function resolve_grouping_func(fname, settings, funcs) {
  var results = Plate$BwaxMobile.List.map((function (param) {
          var v = param[1];
          var k = param[0];
          var match = Plate$BwaxMobile.List.assoc(k, funcs);
          var match$1 = v[0];
          if (typeof match$1 === "number" || match$1.tag !== /* V_record */2) {
            console.log("___", k, v);
            return /* tuple */[
                    /* [] */0,
                    /* [] */0
                  ];
          } else {
            var al = match$1[0];
            if (match !== undefined) {
              var match$2 = match;
              var func = Caml_option.some(match$2[0]);
              var params = resolve_params(match$2[1], al);
              var maybe_alias = get_maybe_alias(fname, k, al);
              var maybe_order = Plate$BwaxMobile.$$Option.map((function (order) {
                      return /* tuple */[
                              Plate$BwaxMobile.$$Option.with_default(fname, maybe_alias),
                              order
                            ];
                    }), get_order(al));
              return /* tuple */[
                      /* :: */[
                        /* tuple */[
                          fname,
                          func,
                          params,
                          maybe_alias
                        ],
                        /* [] */0
                      ],
                      Plate$BwaxMobile.List.keep_map((function (x) {
                              return x;
                            }), /* :: */[
                            maybe_order,
                            /* [] */0
                          ])
                    ];
            } else {
              return collect_grouping_fields(funcs, /* :: */[
                          /* tuple */[
                            fname + ("." + k),
                            v
                          ],
                          /* [] */0
                        ]);
            }
          }
        }), settings);
  var groups = Plate$BwaxMobile.List.concat(Plate$BwaxMobile.List.map(Plate$BwaxMobile.fst, results));
  var sorts = Plate$BwaxMobile.List.concat(Plate$BwaxMobile.List.map(Plate$BwaxMobile.snd, results));
  return /* tuple */[
          groups,
          sorts
        ];
}

function collect_grouping_fields(funcs, fields) {
  return Plate$BwaxMobile.List.foldl((function (param, param$1) {
                var match = param$1[1][0];
                var sorts = param[1];
                var groups = param[0];
                if (typeof match === "number" || match.tag !== /* V_record */2) {
                  return /* tuple */[
                          groups,
                          sorts
                        ];
                } else {
                  var match$1 = resolve_grouping_func(param$1[0], match[0], funcs);
                  return /* tuple */[
                          Pervasives.$at(groups, match$1[0]),
                          Pervasives.$at(sorts, match$1[1])
                        ];
                }
              }), /* tuple */[
              /* [] */0,
              /* [] */0
            ], fields);
}

function get_having(name, al) {
  var match = Plate$BwaxMobile.List.assoc("having", al);
  if (match !== undefined) {
    return Base_query_types$BwaxMobile.to_condition(/* tuple */[
                name,
                match
              ]);
  }
  
}

function resolve_aggregate_func(fname, settings, funcs) {
  var result_lists = Plate$BwaxMobile.List.map((function (param) {
          var v = param[1];
          var k = param[0];
          var match = Plate$BwaxMobile.List.assoc(k, funcs);
          var match$1 = v[0];
          if (typeof match$1 === "number" || match$1.tag !== /* V_record */2) {
            return /* tuple */[
                    /* [] */0,
                    /* [] */0,
                    /* [] */0
                  ];
          } else {
            var al = match$1[0];
            if (match !== undefined) {
              var match$2 = match;
              var func = Caml_option.some(match$2[0]);
              var params = resolve_params(match$2[1], al);
              var maybe_alias = get_maybe_alias(fname, k, al);
              var maybe_order = Plate$BwaxMobile.$$Option.map((function (order) {
                      return /* tuple */[
                              Plate$BwaxMobile.$$Option.with_default(fname, maybe_alias),
                              order
                            ];
                    }), get_order(al));
              var maybe_having = Plate$BwaxMobile.$$Option.and_then((function (alias) {
                      return get_having(alias, al);
                    }), maybe_alias);
              return /* tuple */[
                      /* :: */[
                        /* tuple */[
                          fname,
                          func,
                          params,
                          maybe_alias
                        ],
                        /* [] */0
                      ],
                      Plate$BwaxMobile.List.keep_map((function (x) {
                              return x;
                            }), /* :: */[
                            maybe_order,
                            /* [] */0
                          ]),
                      Plate$BwaxMobile.List.keep_map((function (x) {
                              return x;
                            }), /* :: */[
                            maybe_having,
                            /* [] */0
                          ])
                    ];
            } else {
              return collect_aggregate_fields(funcs, /* :: */[
                          /* tuple */[
                            fname + ("." + k),
                            v
                          ],
                          /* [] */0
                        ]);
            }
          }
        }), settings);
  var extract_and_concat = function (f) {
    return Plate$BwaxMobile.List.concat(Plate$BwaxMobile.List.map(f, result_lists));
  };
  var aggregates = extract_and_concat((function (param) {
          return param[0];
        }));
  var sorts = extract_and_concat((function (param) {
          return param[1];
        }));
  var havings = extract_and_concat((function (param) {
          return param[2];
        }));
  return /* tuple */[
          aggregates,
          sorts,
          havings
        ];
}

function collect_aggregate_fields(funcs, fields) {
  return Plate$BwaxMobile.List.foldl((function (param, param$1) {
                var match = param$1[1][0];
                var havings = param[2];
                var sorts = param[1];
                var aggregates = param[0];
                if (typeof match === "number" || match.tag !== /* V_record */2) {
                  return /* tuple */[
                          aggregates,
                          sorts,
                          havings
                        ];
                } else {
                  var match$1 = resolve_aggregate_func(param$1[0], match[0], funcs);
                  return /* tuple */[
                          Pervasives.$at(aggregates, match$1[0]),
                          Pervasives.$at(sorts, match$1[1]),
                          Pervasives.$at(havings, match$1[2])
                        ];
                }
              }), /* tuple */[
              /* [] */0,
              /* [] */0,
              /* [] */0
            ], fields);
}

function to_aggregate_params(params) {
  var m_criteria = Plate$BwaxMobile.$$Option.map(Base_query_types$BwaxMobile.to_criteria, Plate$BwaxMobile.List.assoc("criteria", params));
  var match = Plate$BwaxMobile.List.assoc("group", params);
  var match$1;
  if (match !== undefined) {
    var match$2 = match[0];
    match$1 = typeof match$2 === "number" || match$2.tag !== /* V_record */2 ? /* tuple */[
        /* [] */0,
        /* [] */0
      ] : collect_grouping_fields(all_grouping_funcs, match$2[0]);
  } else {
    match$1 = /* tuple */[
      /* [] */0,
      /* [] */0
    ];
  }
  var match$3 = Plate$BwaxMobile.List.assoc("aggregate", params);
  var match$4;
  if (match$3 !== undefined) {
    var match$5 = match$3[0];
    match$4 = typeof match$5 === "number" || match$5.tag !== /* V_record */2 ? /* tuple */[
        /* [] */0,
        /* [] */0,
        /* [] */0
      ] : collect_aggregate_fields(all_aggregate_funcs, match$5[0]);
  } else {
    match$4 = /* tuple */[
      /* [] */0,
      /* [] */0,
      /* [] */0
    ];
  }
  var m_limit = get_limit(params);
  var m_offset = get_offset(params);
  var wrap_option = function (l) {
    if (Plate$BwaxMobile.List.length(l) > 0) {
      return l;
    }
    
  };
  var m_group = wrap_option(match$1[0]);
  var m_aggregate = wrap_option(match$4[0]);
  var all_sorts = Pervasives.$at(match$4[1], match$1[1]);
  var m_sort = Plate$BwaxMobile.List.length(all_sorts) > 0 ? /* S_pairs */Block.__(0, [all_sorts]) : undefined;
  var m_having = wrap_option(match$4[2]);
  return /* tuple */[
          m_criteria,
          m_group,
          m_aggregate,
          m_having,
          m_sort,
          m_offset,
          m_limit
        ];
}

function params_to_query_option(entity, params) {
  return /* Q_aggregate */Block.__(4, [
            entity,
            to_aggregate_params(params)
          ]);
}

exports.number_grouping_funcs = number_grouping_funcs;
exports.date_grouping_funcs = date_grouping_funcs;
exports.string_grouping_funcs = string_grouping_funcs;
exports.any_grouping_funcs = any_grouping_funcs;
exports.number_aggregate_funcs = number_aggregate_funcs;
exports.string_aggregate_funcs = string_aggregate_funcs;
exports.any_aggregate_funcs = any_aggregate_funcs;
exports.arg_to_string = arg_to_string;
exports.order_field = order_field;
exports.alias_field = alias_field;
exports.args_field = args_field;
exports.drop_none = drop_none;
exports.preset_grouping_types = preset_grouping_types;
exports.preset_aggregate_types = preset_aggregate_types;
exports.field_group_type = field_group_type;
exports.field_aggregate_type = field_aggregate_type;
exports.src_group_type = src_group_type;
exports.src_aggregate_type = src_aggregate_type;
exports.record_and_then = record_and_then;
exports.get_order = get_order;
exports.get_alias_name = get_alias_name;
exports.get_offset = get_offset;
exports.get_limit = get_limit;
exports.get_args = get_args;
exports.all_aggregate_funcs = all_aggregate_funcs;
exports.all_grouping_funcs = all_grouping_funcs;
exports.get_maybe_alias = get_maybe_alias;
exports.resolve_params = resolve_params;
exports.resolve_grouping_func = resolve_grouping_func;
exports.collect_grouping_fields = collect_grouping_fields;
exports.get_having = get_having;
exports.resolve_aggregate_func = resolve_aggregate_func;
exports.collect_aggregate_fields = collect_aggregate_fields;
exports.to_aggregate_params = to_aggregate_params;
exports.params_to_query_option = params_to_query_option;
/* fieldLines Not a pure module */
