import React from 'react'

class Intern extends React.Component {

    render() {
        return (
            <svg width="56px" height="56px" viewBox="0 0 56 56">
                <defs>
                    <linearGradient x1="100%" y1="100%" x2="0%" y2="0%" id="linearGradient-1">
                        <stop stopColor="#3C3D3D" offset="0%"></stop>
                        <stop stopColor="#141414" offset="100%"></stop>
                    </linearGradient>
                    <linearGradient x1="5.71247088%" y1="-14.6128221%" x2="95.9594259%" y2="100%" id="linearGradient-2">
                        <stop stopColor="#FFE9C9" offset="0%"></stop>
                        <stop stopColor="#FFCF99" offset="100%"></stop>
                    </linearGradient>
                </defs>
                <g id="会员体系" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="会员套餐" transform="translate(-70.000000, -756.000000)" fillRule="nonzero">
                        <g id="会员特权" transform="translate(0.000000, 556.000000)">
                            <g id="蜂二代实习" transform="translate(16.000000, 188.000000)">
                                <g id="icon3" transform="translate(54.000000, 12.000000)">
                                    <circle id="Oval" fill="url(#linearGradient-1)" cx="28" cy="28" r="28"></circle>
                                    <path d="M19.6400057,23.3093932 L24.9542831,25.6957706 L24.9542831,32.6484394 L22.3162816,32.6484394 L22.3162816,40.6440086 L16.7541465,40.6440086 L16.7541465,32.6484394 L14.116145,32.6484394 L14.116145,25.6957706 L19.4304224,23.3093932 L14.9727491,20.7357544 L14.9727491,15.4674737 L19.535214,12.8333333 L24.097679,15.4674737 L24.097679,20.7357544 L19.6400057,23.3093932 Z M23.4846327,16.292557 L16.5711673,16.292557 L15.932927,15.5530903 L15.3236986,15.9000282 L16.2464329,16.9878238 L23.4846327,16.9878238 L23.4846327,16.292557 Z M18.9830132,26.738671 L18.9830132,31.6055391 L20.3735469,31.6055391 L20.3735469,26.738671 L18.9830132,26.738671 Z M30.0667201,23.3083655 L35.3832863,25.6957706 L35.3832863,30.304343 L31.439783,32.648078 L31.439783,40.6440086 L27.1831497,40.6440086 L27.1831497,32.6484394 L26.6309489,32.6484394 L26.6309489,24.7591414 L29.8571368,23.310421 L25.3976833,20.7357544 L25.3976833,15.4674737 L29.9601482,12.8333333 L34.5226132,15.4674737 L34.5226132,20.7357544 L30.0667201,23.3083655 Z M29.4120164,26.738671 L29.4120164,30.2150054 L30.8025502,30.2150054 L30.8025502,26.738671 L29.4120164,26.738671 Z M33.9095669,16.292557 L26.9961015,16.292557 L26.3578612,15.5530903 L25.7486328,15.9000282 L26.6713671,16.9878238 L33.9095669,16.9878238 L33.9095669,16.292557 Z M44.2171883,33.178445 L44.2171883,39.8372594 L38.4504858,43.1666667 L32.6837834,39.8372594 L32.6837834,33.178445 L38.4504858,29.8490378 L44.2171883,33.178445 Z M39.8410196,35.9509571 L38.4504858,35.0818735 L37.0599521,35.9509571 L37.0599521,37.6891243 L38.4504858,38.5582079 L39.8410196,37.6891243 L39.8410196,35.9509571 Z" id="Combined-Shape" fill="url(#linearGradient-2)"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export default Intern
