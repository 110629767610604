
const preStyle = {
  overflow: "auto",
  padding: "10px"
}

const codeStyle = {
  fontFamily: "Consolas, Menlo, Courier, monospace"
}

export {
  preStyle,
  codeStyle
}
