
// plain javascript 的实现版本，以提高效率：

export function range(start, end, step = 1) {

    // let step = (givenStep !== undefined) ? givenStep : (start <= end ? 1 : -1);
    
    // not a simple one step:
    if(step > 0 && start > end) {
        return []
    } else if(step < 0 && start < end) {
        return []
    }

    if(step == 0) {
        // not a valid range
        // but simply return the first one
        return [start]
    } 
    const count = parseInt(((end - start) / step))  + 1;

    return Array(count).fill(start).map((base, index) => base + (index * step));
    
}



export function repeatAndForget(times, f) {
    for(let i = 0; i < times; i++) {
        f()
    }
}
