

import React, { useState, useContext, useEffect } from 'react'

import Dialog, { DialogConfirm } from 'bwax-ui/components/Dialog';

import OutOfQuotaTip from 'bwax-ui/components/OutOfQuotaTip';
import UsageQuotaContext from '../../UsageQuotaContext';

import { TextareaAutosize } from '@mui/base';

import Button from 'bwax-ui/components/Button';
import { PlusIcon } from '@radix-ui/react-icons';

import { isValidHttpURL } from "bwax/ml/lang/mod/builtin/StringHelper"

import './AddPageBookmarkButton.less';

import { useTrack } from 'bwax-ui/track';

export default function AddPageBookmarkButton({ onAdded, fieldPaths, style }) {

    const track = useTrack();

    const { isOutOfQuota, reloadUsageQuota, facade } = useContext(UsageQuotaContext) || {};


    const [dialogOpen, setDialogOpen] = useState();
    const [url, setUrl] = useState("")

    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState();

    function renderContent() {
        return (
            <TextareaAutosize
                value={url} autoFocus
                aria-label="url-input"
                placeholder={"输入你要添加的网页的地址"}
                disabled={uploading}
                onChange={e => setUrl(e.target.value)}
                className={"lc-page-url-input"}
                maxRows={12}
            // onKeyDown={e => handleKeyDown(e)}
            // onCompositionStart={() => setIsCompositionEditing(true)}
            // onCompositionEnd={() => setIsCompositionEditing(false)}
            />
        )
    }

    async function addPageBookmark() {
        // add bookmark page

        setUploading(true);

        const [pageBookmark, error] = await facade.add({
            entityName: "OpenAI-网页收藏",
            formData: {
                网址: url,
                加入知识库: true,
            },
            fieldPaths: fieldPaths || ["标题", "标签", "加入知识库"]
        });

        if (error) {
            setError(error || "添加网页失败");
            setUploading(false);
            return
        }


        if(reloadUsageQuota) {
            setTimeout(() => {
                reloadUsageQuota()
            }, 2000)
        }

        setUrl("")

        setUploading(false);
        setDialogOpen(false);
        onAdded(pageBookmark);

        return

    }

    const [ outOfQuotaTipShown, setOutOfQuotaTipShown ] = useState();

    useEffect(() => {
        if(!isOutOfQuota) {
            setOutOfQuotaTipShown(false)
        }
    }, [ isOutOfQuota ]);

    function renderOutOfQuotaDialog() {
        return (
            <Dialog open={outOfQuotaTipShown}
                onOpenChange={open => setOutOfQuotaTipShown(open)}
                content={<OutOfQuotaTip />}
            />
        )
    }

    return (
        <>
            <Button style={style} onPress={_ => {
                track("bookmark_start_to_add")    
                if(isOutOfQuota) {
                    setOutOfQuotaTipShown(true)
                } else {
                    setDialogOpen(true);
                }
                
            }}>
                <PlusIcon style={{
                    width: 14,
                    marginRight: "0.5rem"
                }}></PlusIcon>
                添加网页
            </Button>
            <DialogConfirm open={dialogOpen}
                onOpenChange={open => {
                    setDialogOpen(open)
                }}
                //  ref={dialogRef}
                {...{
                    okText: "加入知识库",
                    onCancel: _ => {
                        setUrl("");
                        setDialogOpen(false);
                        setError()
                    },
                    onOk: _ => {
                        track("bookmark_add_page")
                        setError()
                        addPageBookmark();
                    },
                    isProgressing: uploading,
                    isReady: isValidHttpURL(url),
                    error,
                    content: renderContent()

                }}
            />
            { renderOutOfQuotaDialog() }
        </>
    )
}
