// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Dict$BwaxMobile = require("../../../../bwax-js/ml/dict.bs.js");
var Plate$BwaxMobile = require("../../../../bwax-js/ml/plate.bs.js");
var Lang_eval$BwaxMobile = require("../../../../bwax-js/ml/lang/lang_eval.bs.js");
var LangHelper = require("bwax/lang/LangHelper");
var Widget_common$BwaxMobile = require("./widget_common.bs.js");
var Runtime_common$BwaxMobile = require("../../../../bwax-js/ml/lang/mod/runtime_common.bs.js");
var Base_query_types$BwaxMobile = require("../../../../bwax-js/ml/base_query_types.bs.js");
var Lang_typing_base$BwaxMobile = require("../../../../bwax-js/ml/lang/lang_typing_base.bs.js");
var Lang_typing_print$BwaxMobile = require("../../../../bwax-js/ml/lang/lang_typing_print.bs.js");
var Lang_typing_annotation$BwaxMobile = require("../../../../bwax-js/ml/lang/lang_typing_annotation.bs.js");

var module_name = "PageComponent";

function display_term(t) {
  if (typeof t === "number" || t.tag !== /* Var */2) {
    return Lang_typing_print$BwaxMobile.string_of_term(/* [] */0, t);
  } else {
    var match = t[0][/* var_type */0];
    if (typeof match === "number" || match.tag !== /* Record_readonly */1) {
      return Lang_typing_print$BwaxMobile.string_of_term(/* [] */0, t);
    } else {
      return "# " + match[0];
    }
  }
}

var original_element = "Element.Element";

var imported_element = "PageComponent.Element";

var page_common_options = "PageComponent.CommonOptions";

function term_of_page_component(pageComponent) {
  var name = pageComponent.name;
  var nname = Base_query_types$BwaxMobile.normalize_field_name(name);
  return Plate$BwaxMobile.$$Option.map((function (ioTypeMetas) {
                var msg_t = Lang_typing_base$BwaxMobile.any(0);
                var replace_element = function (term) {
                  var process_binds = function (binds) {
                    return Plate$BwaxMobile.List.map((function (param) {
                                  return /* tuple */[
                                          param[0],
                                          replace_element(param[1]),
                                          param[2]
                                        ];
                                }), binds);
                  };
                  if (typeof term === "number") {
                    return term;
                  } else {
                    switch (term.tag | 0) {
                      case /* Term */0 :
                          var sons = term[1];
                          var n = term[0];
                          if (n === "Element") {
                            return /* Term */Block.__(0, [
                                      imported_element,
                                      Plate$BwaxMobile.List.map(replace_element, sons)
                                    ]);
                          } else {
                            return /* Term */Block.__(0, [
                                      n,
                                      Plate$BwaxMobile.List.map(replace_element, sons)
                                    ]);
                          }
                      case /* Term_record */1 :
                          return /* Term_record */Block.__(1, [process_binds(term[0])]);
                      case /* Var */2 :
                          var match = term[0];
                          var match$1 = match[/* var_type */0];
                          if (typeof match$1 === "number" || match$1.tag !== /* Record_writeonly_of */5) {
                            return term;
                          } else {
                            var t = match[/* t */2];
                            return /* Var */Block.__(2, [/* record */[
                                        /* var_type : Record_writeonly_of */Block.__(5, [process_binds(match$1[0])]),
                                        /* n */match[/* n */1],
                                        /* t */t
                                      ]]);
                          }
                      
                    }
                  }
                };
                var replace_msg = function (term) {
                  var process_binds = function (binds) {
                    return Plate$BwaxMobile.List.map((function (param) {
                                  return /* tuple */[
                                          param[0],
                                          replace_msg(param[1]),
                                          param[2]
                                        ];
                                }), binds);
                  };
                  if (typeof term === "number") {
                    return term;
                  } else {
                    switch (term.tag | 0) {
                      case /* Term */0 :
                          var n = term[0];
                          if (n === "Msg") {
                            return msg_t;
                          } else {
                            return /* Term */Block.__(0, [
                                      n,
                                      Plate$BwaxMobile.List.map(replace_msg, term[1])
                                    ]);
                          }
                      case /* Term_record */1 :
                          return /* Term_record */Block.__(1, [process_binds(term[0])]);
                      case /* Var */2 :
                          var match = term[0];
                          var match$1 = match[/* var_type */0];
                          if (typeof match$1 === "number" || match$1.tag !== /* Record_writeonly_of */5) {
                            return term;
                          } else {
                            var t = match[/* t */2];
                            return /* Var */Block.__(2, [/* record */[
                                        /* var_type : Record_writeonly_of */Block.__(5, [process_binds(match$1[0])]),
                                        /* n */match[/* n */1],
                                        /* t */t
                                      ]]);
                          }
                      
                    }
                  }
                };
                var init_param_type_list = Curry._1(Plate$BwaxMobile.List.from_array, LangHelper.untag(ioTypeMetas.initParamTypes));
                var view_param_type_list = Plate$BwaxMobile.$$Option.with_default(/* [] */0, Plate$BwaxMobile.$$Option.map(Plate$BwaxMobile.List.from_array, Plate$BwaxMobile.$$Option.map((function (prim) {
                                return LangHelper.untag(prim);
                              }), ioTypeMetas.viewParamTypes)));
                var init_params = Plate$BwaxMobile.List.map((function (t) {
                        return replace_msg(replace_element(t));
                      }), init_param_type_list);
                var view_params = Plate$BwaxMobile.List.map((function (t) {
                        return replace_msg(replace_element(t));
                      }), view_param_type_list);
                var init_args = init_params ? (
                    init_params[1] ? /* :: */[
                        Lang_typing_base$BwaxMobile.tuple(init_params),
                        /* [] */0
                      ] : /* :: */[
                        init_params[0],
                        /* [] */0
                      ]
                  ) : /* :: */[
                    /* Term */Block.__(0, [
                        "Unit",
                        /* [] */0
                      ]),
                    /* [] */0
                  ];
                var view_args = view_params ? (
                    view_params[1] ? /* :: */[
                        Lang_typing_base$BwaxMobile.tuple(view_params),
                        /* [] */0
                      ] : /* :: */[
                        view_params[0],
                        /* [] */0
                      ]
                  ) : /* [] */0;
                var term = Lang_typing_base$BwaxMobile.arrow_chain(Pervasives.$at(/* :: */[
                          Lang_typing_base$BwaxMobile.record_writeonly(1, page_common_options, /* :: */[
                                msg_t,
                                /* [] */0
                              ]),
                          init_args
                        ], view_args), Lang_typing_base$BwaxMobile.wrap_term(imported_element, msg_t));
                return /* tuple */[
                        nname,
                        term
                      ];
              }), Caml_option.nullable_to_opt(pageComponent.ioTypeMetas));
}

function build_typing_env(param, pageComponents) {
  var base_dts = param[1];
  var import_element = function (dts) {
    var a = Plate$BwaxMobile.List.assoc(original_element, base_dts);
    if (a !== undefined) {
      var match = a;
      var exit = 0;
      if (typeof match === "number") {
        match === /* Special */0;
      } else {
        switch (match.tag | 0) {
          case /* Just */0 :
          case /* Alias */1 :
              exit = 2;
              break;
          case /* Opaque */2 :
              var next_int = Lang_typing_base$BwaxMobile.make_counter(/* () */0);
              var sons = Plate$BwaxMobile.List.map((function (vname) {
                      return Lang_typing_annotation$BwaxMobile.var_of_name(next_int, vname);
                    }), match[0]);
              var gvars = Plate$BwaxMobile.List.keep_map((function (param) {
                      if (typeof param === "number" || param.tag !== /* Var */2) {
                        return ;
                      } else {
                        return param[0];
                      }
                    }), sons);
              return /* :: */[
                      /* tuple */[
                        imported_element,
                        /* Alias */Block.__(1, [/* Forall */[
                              gvars,
                              /* Term */Block.__(0, [
                                  original_element,
                                  sons
                                ])
                            ]])
                      ],
                      dts
                    ];
          
        }
      }
      if (exit === 2) {
        return /* :: */[
                /* tuple */[
                  imported_element,
                  /* Alias */Block.__(1, [match[0]])
                ],
                dts
              ];
      }
      
    }
    console.log("Warning: Element.Element is not in base DTS", a, Curry._1(Plate$BwaxMobile.List.to_array, Plate$BwaxMobile.List.assoc_keys(base_dts)));
    return dts;
  };
  var define_common_options = function (dts) {
    var msg = Lang_typing_base$BwaxMobile.any(0);
    var binds_000 = /* tuple */[
      "isLazy",
      Lang_typing_base$BwaxMobile.bool,
      false
    ];
    var binds_001 = /* :: */[
      /* tuple */[
        "loading",
        Lang_typing_base$BwaxMobile.wrap_term(imported_element, msg),
        false
      ],
      /* :: */[
        /* tuple */[
          "key",
          Lang_typing_base$BwaxMobile.string,
          false
        ],
        /* [] */0
      ]
    ];
    var binds = /* :: */[
      binds_000,
      binds_001
    ];
    var t = /* Term_record */Block.__(1, [binds]);
    var gvars = Plate$BwaxMobile.List.keep_map((function (param) {
            if (typeof param === "number" || param.tag !== /* Var */2) {
              return ;
            } else {
              return param[0];
            }
          }), /* :: */[
          msg,
          /* [] */0
        ]);
    var scheme = /* Forall */[
      gvars,
      t
    ];
    return /* :: */[
            /* tuple */[
              page_common_options,
              /* Alias */Block.__(1, [scheme])
            ],
            dts
          ];
  };
  var dts = define_common_options(import_element(/* [] */0));
  var empty = Dict$BwaxMobile.$$String.empty(/* () */0);
  var tenv = Plate$BwaxMobile.List.foldl((function (acc, pc) {
          var match = term_of_page_component(pc);
          if (match !== undefined) {
            var match$1 = match;
            return Dict$BwaxMobile.$$String.insert(module_name + ("." + match$1[0]), /* tuple */[
                        Lang_typing_base$BwaxMobile.generalize(empty, match$1[1]),
                        true,
                        undefined
                      ], acc);
          } else {
            return acc;
          }
        }), empty, Curry._1(Plate$BwaxMobile.List.from_array, pageComponents));
  return /* tuple */[
          tenv,
          dts
        ];
}

function build_src(pageComponents) {
  var build_page_decl = function (pageComponent) {
    var name = pageComponent.name;
    var nname = Base_query_types$BwaxMobile.normalize_field_name(name);
    return Plate$BwaxMobile.$$Option.and_then((function (ioTypeMetas) {
                  var init_param_type_list = Curry._1(Plate$BwaxMobile.List.from_array, LangHelper.untag(ioTypeMetas.initParamTypes));
                  var view_param_type_list = Plate$BwaxMobile.$$Option.with_default(/* [] */0, Plate$BwaxMobile.$$Option.map(Plate$BwaxMobile.List.from_array, Plate$BwaxMobile.$$Option.map((function (prim) {
                                  return LangHelper.untag(prim);
                                }), ioTypeMetas.viewParamTypes)));
                  var param_type_to_string = function (t) {
                    var str = display_term(t);
                    return Plate$BwaxMobile.Str.replace("Msg", "msg", str);
                  };
                  var init_params_str;
                  if (init_param_type_list) {
                    if (init_param_type_list[1]) {
                      var inner = Plate$BwaxMobile.Str.join(", ", Plate$BwaxMobile.List.map(param_type_to_string, init_param_type_list));
                      init_params_str = " -> (" + (inner + ")");
                    } else {
                      init_params_str = " -> " + param_type_to_string(init_param_type_list[0]);
                    }
                  } else {
                    init_params_str = " -> ()";
                  }
                  var view_params_str;
                  if (view_param_type_list) {
                    if (view_param_type_list[1]) {
                      var inner$1 = Plate$BwaxMobile.Str.join(", ", Plate$BwaxMobile.List.map(param_type_to_string, view_param_type_list));
                      view_params_str = " -> (" + (inner$1 + ")");
                    } else {
                      view_params_str = " -> " + param_type_to_string(view_param_type_list[0]);
                    }
                  } else {
                    view_params_str = "";
                  }
                  var params_line = "~ CommonOptions msg" + (init_params_str + view_params_str);
                  return nname + (": " + (params_line + " -> Element msg = external;"));
                }), Caml_option.nullable_to_opt(pageComponent.ioTypeMetas));
  };
  var pageDecls = Plate$BwaxMobile.Str.join("\n\n", Plate$BwaxMobile.List.keep_map(build_page_decl, Curry._1(Plate$BwaxMobile.List.from_array, pageComponents)));
  return "\nmodule " + (String(module_name) + (";\n\nimport Element exposing (Element);\n\ntype alias CommonOptions msg  = { isLazy: Bool, loading: Element msg, key: String };\n\n" + (String(pageDecls) + "\n  \n")));
}

function pack_element(module_element) {
  return Widget_common$BwaxMobile.element_to_value(/* Delegated */Block.__(23, [
                module_name,
                module_element
              ]));
}

function fail(name) {
  throw [
        Lang_eval$BwaxMobile.Eval_exn,
        module_name + ("." + (name + ": Invalid Arguments"))
      ];
}

function get_loading(config_v) {
  return Plate$BwaxMobile.$$Option.and_then((function (param) {
                var match = param[0];
                if (typeof match === "number" || match.tag !== /* V_raw */7) {
                  return ;
                } else {
                  return match[0];
                }
              }), Plate$BwaxMobile.List.assoc("loading", config_v));
}

function build_externals(pageComponents) {
  var build = function (pageComponent) {
    var name = pageComponent.name;
    var nname = Base_query_types$BwaxMobile.normalize_field_name(name);
    return Plate$BwaxMobile.$$Option.map((function (ioTypeMetas) {
                  var view_params_types = Plate$BwaxMobile.$$Option.with_default(/* [] */0, Plate$BwaxMobile.$$Option.map(Plate$BwaxMobile.List.from_array, ioTypeMetas.viewParamTypes));
                  var has_view_params = Plate$BwaxMobile.List.length(view_params_types) > 0;
                  var as_config = function (options) {
                    var isLazy = Plate$BwaxMobile.$$Option.with_default(false, Plate$BwaxMobile.$$Option.and_then(Lang_eval$BwaxMobile.as_bool, Plate$BwaxMobile.List.assoc("isLazy", options)));
                    var loading = get_loading(options);
                    var key = Plate$BwaxMobile.$$Option.and_then(Lang_eval$BwaxMobile.as_string, Plate$BwaxMobile.List.assoc("key", options));
                    return /* tuple */[
                            isLazy,
                            loading,
                            key
                          ];
                  };
                  var f = has_view_params ? Runtime_common$BwaxMobile.pack_func(3, (function (param) {
                            if (param) {
                              var match = param[0][0];
                              if (typeof match === "number" || match.tag !== /* V_record */2) {
                                return fail(nname);
                              } else {
                                var match$1 = param[1];
                                if (match$1) {
                                  var match$2 = match$1[1];
                                  if (match$2 && !match$2[1]) {
                                    var module_element_001 = as_config(match[0]);
                                    var module_element_002 = match$1[0];
                                    var module_element_003 = match$2[0];
                                    var module_element = /* Page_component */[
                                      name,
                                      module_element_001,
                                      module_element_002,
                                      module_element_003
                                    ];
                                    return Widget_common$BwaxMobile.element_to_value(/* Delegated */Block.__(23, [
                                                  module_name,
                                                  module_element
                                                ]));
                                  } else {
                                    return fail(nname);
                                  }
                                } else {
                                  return fail(nname);
                                }
                              }
                            } else {
                              return fail(nname);
                            }
                          })) : Runtime_common$BwaxMobile.pack_func(2, (function (param) {
                            if (param) {
                              var match = param[0][0];
                              if (typeof match === "number" || match.tag !== /* V_record */2) {
                                return fail(nname);
                              } else {
                                var match$1 = param[1];
                                if (match$1 && !match$1[1]) {
                                  var module_element_001 = as_config(match[0]);
                                  var module_element_002 = match$1[0];
                                  var module_element = /* Page_component */[
                                    name,
                                    module_element_001,
                                    module_element_002,
                                    undefined
                                  ];
                                  return Widget_common$BwaxMobile.element_to_value(/* Delegated */Block.__(23, [
                                                module_name,
                                                module_element
                                              ]));
                                } else {
                                  return fail(nname);
                                }
                              }
                            } else {
                              return fail(nname);
                            }
                          }));
                  return /* tuple */[
                          nname,
                          f
                        ];
                }), Caml_option.nullable_to_opt(pageComponent.ioTypeMetas));
  };
  var externals = Plate$BwaxMobile.List.keep_map(build, Plate$BwaxMobile.List.filter((function (p) {
                return Curry._1(Plate$BwaxMobile.$$Option.is_some, Caml_option.nullable_to_opt(p.ioTypeMetas));
              }))(Curry._1(Plate$BwaxMobile.List.from_array, pageComponents)));
  return /* tuple */[
          module_name,
          externals
        ];
}

exports.module_name = module_name;
exports.display_term = display_term;
exports.original_element = original_element;
exports.imported_element = imported_element;
exports.page_common_options = page_common_options;
exports.term_of_page_component = term_of_page_component;
exports.build_typing_env = build_typing_env;
exports.build_src = build_src;
exports.pack_element = pack_element;
exports.fail = fail;
exports.get_loading = get_loading;
exports.build_externals = build_externals;
/* Dict-BwaxMobile Not a pure module */
