

import React, { useEffect } from 'react'

import PageContainer from 'bwax-ui/ml/widget/PageContainer'

import { hashCode } from 'bwax/utils'

import RenderWithData from 'bwax-ui/store/RenderWithData'
import { GLOBAL, getInstance, buildCacheKey, USER_DURABLE_RUNTIME } from 'bwax/store/DataCache';

import setupPageEnv from './setupPageEnv';

import { loadPageComponent } from 'bwax-ui/ml/widget/impl/misc/PageComponent';

// cache the compiled 

export default function PageEnvContainer(props) {

    // page, params, facade, route_to, pathname, search, dlc, 
    const { page, urlPattern, pathname, isLazy, facade } = props

    // const { pathname } = history.location;

    const { compiled } = page;
    // let t0 = performanceNow();
    const key = urlPattern + "_" + hashCode(
        compiled // + ":" + JSON.stringify(params)
    );

    const loadData = () => resolveRuntime(key, compiled, facade);

    return (
        <RenderWithData {...{
            ...props,
            loadData,
            cacheType: USER_DURABLE_RUNTIME, // 因为 setupPageEnv 涉及到 facade 的 prepare（所以 cacheType 不能是 global runtime）
            dataKey: "PageEnv_" + pathname + "_" + key,
            Component: PageEnvContainer_inner,

            facade,

            isLazy,
            noSuspense: true,
        }} />
    )
}

async function resolveRuntime(key, compiled, facade) {

    try {
        const [ast, dts, entity_dict, data_type_dict, env, externalNames, ] = await setupPageEnv(compiled, facade)

        const runtime = {
            ast,
            dts,
            entity_dict,
            data_type_dict,

            env,
            key,

            externalNames,

            error: undefined
        };
        return runtime

    } catch (e) {

        // TODO error 的处理放进 RenderWithData

        console.log(e, e.stack, facade.baseEnv);

        return {
            error: e,
            key
        };

    }
}

export function PageEnvContainer_inner(props) {

    const {
        data: runtime, params,
        dlc,
        isPreview,
        facade, viewEnv,
        page,
        alternativeContext,
    } = props

    const { env, ast, dts, entity_dict, data_type_dict, key, error, externalNames, } = runtime;

    const pageComponentNames = (externalNames || []).filter(
        n => n.startsWith("PageComponent.")
    ).map(
        n => n.replace("PageComponent.", "")
    );

    useEffect(() => {
        // https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/920
        const dataCache = getInstance(dlc);
        async function preloadPageComponent(name) {
            const cacheKey = buildCacheKey(GLOBAL, "PageComponent_" + name, dlc);
            const cached = dataCache.get(cacheKey);
            console.log(name, cached);
            if(!cached) {
                // preload
                const p = await loadPageComponent(name, dlc);
                dataCache.set(cacheKey, p);
            }
        }
        
        pageComponentNames.forEach(name => {
            preloadPageComponent(name);
        })
    

    }, [pageComponentNames.join(";")])


    if (error) {
        return "ERROR";
    }

    const rendered = (
        <PageContainer
            pageID={key}
            dlc={dlc}
            entity_dict={entity_dict}
            data_type_dict={data_type_dict}
            base_env={env}
            dts={dts}
            ast={ast}
            params={params}
            facade={facade}
            viewEnv={viewEnv}
            isPreview={isPreview}
            styles={page.styles}
            alternativeContext={alternativeContext}
        />
    )
    return rendered;
}

