

export function downloadUrl(url, name) {
    let link = document.createElement('a')
    link.href = url
    if(name) {
        link.download = name
    }
    link.dispatchEvent(new MouseEvent('click'))
}
