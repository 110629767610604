import React, { useRef, useEffect, useState } from 'react';
import Photoswipe from 'photoswipe';
import PhotoswipeUIDefault from 'photoswipe/dist/photoswipe-ui-default';
import events from './events';
import { defaultOptions } from './PhotoSwipeHelper'
import './photoswipe.css'


export default function PhotoSwipe(props) {

    const { items, options, onClose } = props

    const pswpRef = useRef(undefined)

    const [photoSwipe, setPhotoSwipe ] = useState(undefined)

    const closePhotoSwipe = () => {
        if (!photoSwipe) {
            return;
        }
        photoSwipe.close();
    };

    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    const openPhotoSwipe = (props) => {
        let photoSwipe = new Photoswipe(
            pswpRef.current,
            PhotoswipeUIDefault,
            items,
            {
                ...defaultOptions,
                ...options
            }
        );
        setPhotoSwipe(photoSwipe)
        events.forEach((event) => {
            const callback = props[event];
            if (callback || event === 'destroy' || event === 'gettingData') {
                photoSwipe.listen(event, function (index, item) {
                    if (callback) {
                        callback(item);
                    }

                    if (event === 'destroy') {
                        handleClose();
                    }

                    if (event === 'gettingData') {
                        //support unknown size image
                        if (item.w < 1 || item.h < 1) {
                            const img = new Image()
                            img.onload = function () {
                                let [width, height] = [img.width, img.height]
                                // XXX: 避免使用大图片(大于2000 x1500px)于移动端上,
                                // 因为他们将大大减少动画表现并且可能导致事故(特别是在iOS Safari)
                                if (width > 2000) {
                                    width = 2000
                                    height = 2000 * img.height / img.width
                                }
                                item.w = width
                                item.h = height
                                photoSwipe.updateSize(true)
                            }
                            img.src = item.src
                        }
                    }
                })
            }
        });
        photoSwipe.init()
    };

    useEffect(() => {
        const { isOpen } = props
        if (isOpen) {
            openPhotoSwipe(props)
        }

        return () => {
            closePhotoSwipe()
        }
    }, [props])

    return (
        <div
            className={`pswp`}
            tabIndex="-1"
            role="dialog"
            aria-hidden="true"
            ref={pswpRef}
        >
            <div className="pswp__bg" />
            <div className="pswp__scroll-wrap">
                <div className="pswp__container">
                    <div className="pswp__item" />
                    <div className="pswp__item" />
                    <div className="pswp__item" />
                </div>
                <div className="pswp__ui pswp__ui--hidden">
                    <div className="pswp__top-bar">
                        <div className="pswp__counter" />
                        <button
                            className="pswp__button pswp__button--close"
                            title="Close (Esc)"
                        />
                        <button
                            className="pswp__button pswp__button--share"
                            title="Share"
                        />
                        <button
                            className="pswp__button pswp__button--fs"
                            title="Toggle fullscreen"
                        />
                        <button className="pswp__button pswp__button--zoom" title="Zoom in/out" />
                        <div className="pswp__preloader">
                            <div className="pswp__preloader__icn">
                                <div className="pswp__preloader__cut">
                                    <div className="pswp__preloader__donut" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                        <div className="pswp__share-tooltip" />
                    </div>
                    <button
                        className="pswp__button pswp__button--arrow--left"
                        title="Previous (arrow left)"
                    />
                    <button
                        className="pswp__button pswp__button--arrow--right"
                        title="Next (arrow right)"
                    />
                    <div className="pswp__caption">
                        <div className="pswp__caption__center" />
                    </div>
                </div>
            </div>
        </div>
    );
}

