
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet-async'

import Button, { Pressable, iconButton, iconDropdownMenu } from "bwax-ui/components/Button";



/*
最近几天的：
1. 尝试翻译次数
2. 尝试翻译人数
3. 支付翻译次数
4. 支付翻译人数
5. 消费总积分
6. 充值金额

*/

export default function TranslationTrends({ facade }) {

    const DAYS = 5;
    const timeCondition = { field: "创建时间", op: "gte", value: dayjs(new Date).subtract(DAYS - 1, "day").hour(0).minute(0).second(0).millisecond(0).toJSON() };
    const timeGroup = [{
        field: "创建时间", func: "DATE", aliasName: "date"
    }];

    const last7Conditions = [
        { field: "创建时间", op: "gte", value: dayjs(new Date).subtract(7, "day").hour(0).minute(0).second(0).millisecond(0).toJSON() },
        { field: "创建时间", op: "lt", value: dayjs(new Date).hour(0).minute(0).second(0).millisecond(0).toJSON() },
    ]



    const extractDayValues = (result, aliasName, last7) => {
        const days = Array(DAYS).fill(0).map((_, index) => {
            if (index !== 0) {
                return dayjs().subtract(index, "day").format("YYYY-MM-DD");
            } else {
                return dayjs().format("YYYY-MM-DD");
            }
        });

        function getValue(date) {
            const r = result.find(r => r.date == date);
            return r ? r[aliasName] : 0
        }
        // 全部加起来
        return [...(days.map(getValue)), last7[0][aliasName]]

    }

    const [attempts, setAttempts] = useState();
    async function loadAttemps() {
        const [result, error] = await facade.aggregate({
            entityName: "文档翻译-任务",
            aggregate: [{ field: "id", func: "COUNT", aliasName: "count", }],
            group: timeGroup,
            condition: [[timeCondition]]
        })

        const [r, e] = await facade.aggregate({
            entityName: "文档翻译-任务",
            aggregate: [{ field: "id", func: "COUNT", aliasName: "count", }],
            condition: [last7Conditions]
        })
        if (result && r) {
            setAttempts(extractDayValues(result, "count", r))
        }
    }

    const [attemptedUsers, setAttemptedUsers] = useState();
    async function loadAttemptedUsers() {
        const [result, error] = await facade.aggregate({
            entityName: "文档翻译-任务",
            aggregate: [{ field: "创建者.id", func: "COUNT_DISTINCT", aliasName: "count" }],
            group: timeGroup,
            condition: [[timeCondition]]
        })
        const [r, e] = await facade.aggregate({
            entityName: "文档翻译-任务",
            aggregate: [{ field: "创建者.id", func: "COUNT_DISTINCT", aliasName: "count" }],
            condition: [last7Conditions]
        })
        if (result && r) {
            setAttemptedUsers(extractDayValues(result, "count", r))
        }
    }


    // 需要支付 attemtps：
    const [notFreeAttempts, setNotFreeAttempts] = useState();
    async function loadNotFreeAttempts() {
        const [result, error] = await facade.aggregate({
            entityName: "文档翻译-任务",
            aggregate: [{ field: "id", func: "COUNT", aliasName: "count" }],
            group: timeGroup,
            condition: [[
                timeCondition,
                { field: "价格", op: "gt", value: 0 },
                // { field: "已支付", op: "eq", value: false }
            ]]
        })
        const [r, e] = await facade.aggregate({
            entityName: "文档翻译-任务",
            aggregate: [{ field: "id", func: "COUNT", aliasName: "count" }],
            condition: [[
                ...last7Conditions,
                { field: "价格", op: "gt", value: 0 },
                // { field: "已支付", op: "eq", value: false }
            ]]
        })
        if (result && r) {
            setNotFreeAttempts(extractDayValues(result, "count", r))
        }
    }

    const [paidAttempts, setPaidAttempts] = useState();
    async function loadPaidAttempts() {
        const [result, error] = await facade.aggregate({
            entityName: "文档翻译-任务",
            aggregate: [{ field: "id", func: "COUNT", aliasName: "count" }],
            group: timeGroup,
            condition: [[
                timeCondition,
                { field: "价格", op: "gt", value: 0 },
                { field: "已支付", op: "eq", value: true }
            ]]
        })
        const [r, e] = await facade.aggregate({
            entityName: "文档翻译-任务",
            aggregate: [{ field: "id", func: "COUNT", aliasName: "count" }],
            condition: [[
                ...last7Conditions,
                { field: "价格", op: "gt", value: 0 },
                { field: "已支付", op: "eq", value: true }
            ]]
        })
        if (result && r) {
            setPaidAttempts(extractDayValues(result, "count", r))
        }
    }


    const [spends, setSpends] = useState();
    async function loadSpends() {
        const [result, error] = await facade.aggregate({
            entityName: "消费记录",
            aggregate: [{ field: "id", func: "COUNT", aliasName: "count" }],
            group: timeGroup,
            condition: [[
                timeCondition,
            ]]
        })
        const [r, e] = await facade.aggregate({
            entityName: "消费记录",
            aggregate: [{ field: "id", func: "COUNT", aliasName: "count" }],
            condition: [[
                ...last7Conditions,
            ]]
        })
        if (result && r) {
            setSpends(extractDayValues(result, "count", r))
        }
    }

    const [spendUsers, setSpendUsers] = useState();
    async function loadSpendUsers() {
        const [result, error] = await facade.aggregate({
            entityName: "消费记录",
            aggregate: [{ field: "用户.id", func: "COUNT_DISTINCT", aliasName: "count", }],
            group: timeGroup,
            condition: [[
                timeCondition,
                // { field: "价格", op: "gt", value: 0 },
                // { field: "已支付", op: "eq", value: true }
            ]]
        });
        const [r, e] = await facade.aggregate({
            entityName: "消费记录",
            aggregate: [{ field: "用户.id", func: "COUNT_DISTINCT", aliasName: "count", }],
            condition: [[
                ...last7Conditions,
                // { field: "价格", op: "gt", value: 0 },
                // { field: "已支付", op: "eq", value: true }
            ]]
        })
        if (result && r) {
            setSpendUsers(extractDayValues(result, "count", r))
        }
    }


    const [spendAmounts, setSpendAmounts] = useState();
    async function loadSpendAmounts() {
        const [result, error] = await facade.aggregate({
            entityName: "消费记录",
            aggregate: [{ field: "数量", func: "SUM", aliasName: "sum", }],
            group: timeGroup,
            condition: [[
                timeCondition,
                // { field: "价格", op: "gt", value: 0 },
                // { field: "已支付", op: "eq", value: true }
            ]]
        });
        const [r, e] = await facade.aggregate({
            entityName: "消费记录",
            aggregate: [{ field: "数量", func: "SUM", aliasName: "sum", }],
            condition: [[
                ...last7Conditions,
                // { field: "价格", op: "gt", value: 0 },
                // { field: "已支付", op: "eq", value: true }
            ]]
        })
        if (result && r) {
            setSpendAmounts(extractDayValues(result, "sum", r))
        }
    }


    const [paidUsers, setPaidUsers] = useState();
    async function loadPayUsers() {
        const [result, error] = await facade.aggregate({
            entityName: "充值记录",
            aggregate: [{ field: "用户.id", func: "COUNT_DISTINCT", aliasName: "count", }],
            group: timeGroup,
            condition: [[
                timeCondition,
                { field: "相关申请.订单.支付状态", op: "eq", value: "已支付"},
                { field: "作废", op: "ne", value: true },
            ]]
        });
        const [r, e] = await facade.aggregate({
            entityName: "充值记录",
            aggregate: [{ field: "用户.id", func: "COUNT_DISTINCT", aliasName: "count", }],
            condition: [[
                ...last7Conditions,
                { field: "相关申请.订单.支付状态", op: "eq", value: "已支付"},
                { field: "作废", op: "ne", value: true },
            ]]
        })
        if (result && r) {
            setPaidUsers(extractDayValues(result, "count", r))
        }
    }


    const [paidAmounts, setPaidAmounts] = useState();
    async function loadPayAmounts() {
        const [result, error] = await facade.aggregate({
            entityName: "充值记录",
            aggregate: [{ field: "相关申请.金额", func: "SUM", aliasName: "sum", }],
            group: timeGroup,
            condition: [[
                timeCondition,
                { field: "相关申请.订单.支付状态", op: "eq", value: "已支付"},
                { field: "作废", op: "ne", value: true },
            ]]
        });
        const [r, e] = await facade.aggregate({
            entityName: "充值记录",
            aggregate: [{ field: "相关申请.金额", func: "SUM", aliasName: "sum", }],
            condition: [[
                ...last7Conditions,
                { field: "相关申请.订单.支付状态", op: "eq", value: "已支付"},
                { field: "作废", op: "ne", value: true },
            ]]
        })
        if (result && r) {
            setPaidAmounts(extractDayValues(result, "sum", r))
        }
    }


    // 新增用户
    const [newUsers, setNewUsers] = useState();
    async function loadNewUsers() {
        const [result, error] = await facade.aggregate({
            entityName: "用户",
            aggregate: [{ field: "id", func: "COUNT_DISTINCT", aliasName: "count", }],
            group: timeGroup,
            condition: [[
                timeCondition,
            ]]
        });
        const [r, e] = await facade.aggregate({
            entityName: "用户",
            aggregate: [{ field: "id", func: "COUNT_DISTINCT", aliasName: "count", }],
            condition: [[
                ...last7Conditions,
            ]]
        })
        if (result && r) {
            setNewUsers(extractDayValues(result, "count", r))
        }
    }


    const [newPaidUsers, setNewPaidUsers] = useState();
    async function loadNewPaidUsers() {
        const [result, error] = await facade.aggregate({
            entityName: "账户余额",
            aggregate: [{ field: "id", func: "COUNT_DISTINCT", aliasName: "count", }],
            group: timeGroup,
            condition: [[
                timeCondition,
            ]]
        });
        const [r, e] = await facade.aggregate({
            entityName: "账户余额",
            aggregate: [{ field: "id", func: "COUNT_DISTINCT", aliasName: "count", }],
            condition: [[
                ...last7Conditions,
            ]]
        })
        if (result && r) {
            setNewPaidUsers(extractDayValues(result, "count", r))
        }
    }


    function loadData() {
        loadAttemps();
        loadAttemptedUsers();
        loadNotFreeAttempts();
        loadPaidAttempts();
        loadSpends();
        loadSpendUsers();
        loadSpendAmounts();
        loadPayUsers();
        loadPayAmounts();
        loadNewUsers();
        loadNewPaidUsers();
    }

    useEffect(() => {
        loadData()
    }, []);



    const renderRow = (label, values, className) => {
        if (!values) { return null }
        return (
            <div className={classNames("w-full flex py-2", className)}>
                <div className="w-1/5 flex px-4  whitespace-pre font-size-12 flex items-center">
                    {label}
                </div>
                <div className="w-4/5 flex text-right whitespace-pre">
                    {values.map((v, i) => {
                        return (
                            <div key={i} className="pl-2 pr-3" style={{
                                width: ((1 / values.length) * 100) + "%"
                            }}>
                                {v}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    return (
        <>
            <Helmet>
                <title>文档翻译 | 趋势</title>
            </Helmet>
            <div className="self-center max-w-2xl w-full py-4 font-size-13 relative">
                <div className="hidden sm:flex absolute top-[25px] -left-[2rem]">
                    {iconButton(<i className='bx bx-refresh'></i>, _ => {
                        console.log(">>> refresh");
                        loadData();
                    })}
                </div>
                {
                    renderRow(
                        <div className="flex gap-1 items-center">
                            数据项
                        </div>,
                        [
                            ...(Array(DAYS).fill(0).map((_, i) => {
                                return (
                                    "" + dayjs().subtract(i, "day").format("MM.DD") + ""
                                )
                            })),
                            "前七天"
                        ],
                        "text-[var(--gray11)] !py-4 font-size-12"
                    )
                }
                {renderRow("尝试次数", attempts)}
                {renderRow("需支付数", notFreeAttempts)}
                {renderRow("已支付数", paidAttempts)}
                {renderRow("消费次数", spends)}
                <div className="py-2"></div>
                {renderRow("消费积分", spendAmounts)}
                {renderRow("充值金额", paidAmounts)}
                <div className="py-2"></div>
                {renderRow("尝试人数", attemptedUsers)}
                {renderRow("消费人数", spendUsers)}
                {renderRow("充值人数", paidUsers)}
                <div className="py-2"></div>
                {renderRow("新增用户", newUsers)}
                {renderRow("新增付费", newPaidUsers)}
            </div>
        </>
    )
}
