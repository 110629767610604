
import React from 'react'

import createBlockTypeButton from './util/createBlockTypeButton';

export default createBlockTypeButton({
    blockType: 'h3',
    children: (
        "H3"
    ),
});
