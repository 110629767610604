// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Lang_eval$BwaxMobile = require("../../lang_eval.bs.js");
var Runtime_common$BwaxMobile = require("../runtime_common.bs.js");

var module_name = "CmdM";

function fail(name) {
  throw [
        Lang_eval$BwaxMobile.Eval_exn,
        module_name + ("." + (name + ": Invalid Arguments"))
      ];
}

function pack_executable(arity, name) {
  return /* tuple */[
          name,
          Runtime_common$BwaxMobile.pack_func(arity, (function (args) {
                  return /* tuple */[
                          /* V_executable */Block.__(14, [
                              name,
                              args,
                              /* [] */0
                            ]),
                          Runtime_common$BwaxMobile.nt
                        ];
                }))
        ];
}

var externals_vals_000 = /* tuple */[
  "andThen",
  Runtime_common$BwaxMobile.pack_func(2, (function (param) {
          if (param) {
            var match = param[1];
            if (match) {
              var match$1 = match[0][0];
              if (typeof match$1 === "number" || !(match$1.tag === /* V_executable */14 && !match[1])) {
                return fail("andThen");
              } else {
                return /* tuple */[
                        /* V_executable */Block.__(14, [
                            match$1[0],
                            match$1[1],
                            Pervasives.$at(match$1[2], /* :: */[
                                  param[0],
                                  /* [] */0
                                ])
                          ]),
                        Runtime_common$BwaxMobile.nt
                      ];
              }
            } else {
              return fail("andThen");
            }
          } else {
            return fail("andThen");
          }
        }))
];

var externals_vals_001 = /* :: */[
  pack_executable(1, "return"),
  /* :: */[
    pack_executable(1, "fail"),
    /* :: */[
      /* tuple */[
        "done",
        Runtime_common$BwaxMobile.pack_func(3, (function (param) {
                if (param) {
                  var match = param[1];
                  if (match) {
                    var match$1 = match[1];
                    if (match$1 && !match$1[1]) {
                      return Runtime_common$BwaxMobile.pack_cmd("CmdM_done", /* :: */[
                                  param[0],
                                  /* :: */[
                                    match[0],
                                    /* :: */[
                                      match$1[0],
                                      /* [] */0
                                    ]
                                  ]
                                ]);
                    } else {
                      return fail("done");
                    }
                  } else {
                    return fail("done");
                  }
                } else {
                  return fail("done");
                }
              }))
      ],
      /* :: */[
        /* tuple */[
          "end",
          Runtime_common$BwaxMobile.pack_func(2, (function (param) {
                  if (param) {
                    var match = param[1];
                    if (match && !match[1]) {
                      return Runtime_common$BwaxMobile.pack_cmd("CmdM_end", /* :: */[
                                  param[0],
                                  /* :: */[
                                    match[0],
                                    /* [] */0
                                  ]
                                ]);
                    } else {
                      return fail("end");
                    }
                  } else {
                    return fail("end");
                  }
                }))
        ],
        /* :: */[
          pack_executable(1, "sequence"),
          /* :: */[
            pack_executable(1, "wrap"),
            /* :: */[
              pack_executable(1, "wrap2"),
              /* [] */0
            ]
          ]
        ]
      ]
    ]
  ]
];

var externals_vals = /* :: */[
  externals_vals_000,
  externals_vals_001
];

function commands(context, param, on_trunk_msgs, _on_branch_msgs) {
  return false;
}

var command_handlers = /* :: */[
  commands,
  /* [] */0
];

var externals_000 = module_name;

var externals = /* tuple */[
  externals_000,
  externals_vals
];

var src = "\n\nmodule CmdM;\n\nandThen : (y -> CmdM x z) -> CmdM x y -> CmdM x z = external;\n\n-- A more general name for andThen\n\nflatMap f m: (y -> CmdM x z) -> CmdM x y -> CmdM x z = \n  andThen f m;\n\n\nreturn: y -> CmdM x y = external;\nfail: x -> CmdM x y = external;\n\ndone: (x -> msg) -> (y -> msg) -> CmdM x y -> Cmd msg = external;\n\nend: (x -> msg) -> CmdM x y -> Cmd msg = external;\n\nsequence: List (CmdM x y) -> CmdM x (List y) = external;\n\n-- Convert normal Cmd to CmdM\n-- Handle value only\nwrap: ((y -> msg) -> Cmd msg) -> CmdM x y = external;\n\n-- Handle both value and error\nwrap2: ((y -> msg) -> (x -> msg) -> Cmd msg) -> CmdM x y = external;\n\n\n";

exports.module_name = module_name;
exports.src = src;
exports.fail = fail;
exports.pack_executable = pack_executable;
exports.externals_vals = externals_vals;
exports.commands = commands;
exports.command_handlers = command_handlers;
exports.externals = externals;
/* externals_vals Not a pure module */
