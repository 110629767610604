
// This file is generated. Don't modify it.
import React from 'react'
export default function QRIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M3,7.5 L7.5,7.5 L7.5,3 L3,3 L3,7.5 Z M3.81818359,3.81818359 L6.68181641,3.81818359 L6.68181641,6.68181641 L3.81818359,6.68181641 L3.81818359,3.81818359 Z M4.63613281,4.61970703 L5.91113281,4.61970703 L5.91113281,5.84698242 L4.63613281,5.84698242 L4.63613281,4.61970703 Z M8.325,3 L8.325,7.5 L13,7.5 L13,3 L8.325,3 Z M12.15,6.68181641 L9.175,6.68181641 L9.175,3.81818359 L12.15,3.81818359 L12.15,6.68181641 Z M10.0180664,4.52830078 L11.2930664,4.52830078 L11.2930664,5.80330078 L10.0180664,5.80330078 L10.0180664,4.52830078 Z M3,13 L7.675,13 L7.675,8.325 L3,8.325 L3,13 Z M3.85,9.175 L6.825,9.175 L6.825,12.15 L3.85,12.15 L3.85,9.175 Z M4.69831055,10.0884375 L5.97331055,10.0884375 L5.97331055,11.3157129 L4.69831055,11.3157129 L4.69831055,10.0884375 Z M12.15,10.545459 L10.875,10.545459 L10.875,8.5 L8.325,8.5 L8.325,13 L9.175,13 L9.175,9.31818359 L10.025,9.31818359 L10.025,11.3636426 L13,11.3636426 L13,8.5 L12.15,8.50000977 L12.15,10.5454687 L12.15,10.545459 Z M10,12 L11.2857129,12 L11.2857129,13 L10,13 L10,12 Z M11.7142871,12 L13,12 L13,13 L11.7142871,13 L11.7142871,12 Z" id="QRIcon-path-1"></path>
            </defs>
            <g id="Style" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Iconology" transform="translate(-336.000000, -124.000000)">
                    <g id="Icon" transform="translate(48.000000, 124.000000)">
                        <g id="Icon/QR" transform="translate(288.000000, 0.000000)">
                            <g id="Group-3">
                                <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                                <mask id="QRIcon-mask-2" fill="white">
                                    <use href="#QRIcon-path-1"></use>
                                </mask>
                                <use id="Mask" fill={color} fillRule="nonzero" href="#QRIcon-path-1"></use>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
