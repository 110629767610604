// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Plate$BwaxMobile = require("../../../../bwax-js/ml/plate.bs.js");
var BaseUI$BwaxMobile = require("../../BaseUI.bs.js");
var OnePageLayout001Js = require("./OnePageLayout001.js");

var make = OnePageLayout001Js.default;

var OnePageLayout001_bridge = {
  make: make
};

function OnePageLayout001$Comp(Props) {
  var pc = Props.pc;
  var theme = Props.theme;
  var match = Props.rules;
  var rules = match !== undefined ? match : /* [] */0;
  var welcome = Props.welcome;
  var sections = Props.sections;
  var footer = Props.footer;
  var buildNavBar = Props.buildNavBar;
  var onScrolledToSection = Props.onScrolledToSection;
  var wc = Curry._4(welcome, theme, /* box */4898315, /* [] */0, "welcome");
  var secs = Curry._1(Plate$BwaxMobile.List.to_array, Plate$BwaxMobile.List.indexed_map((function (index, param) {
              return /* tuple */[
                      Curry._4(param[0], theme, /* box */4898315, /* [] */0, "nav" + String(index)),
                      Curry._4(param[1], theme, /* box */4898315, /* [] */0, "section" + String(index))
                    ];
            }), sections));
  var ft = Curry._4(footer, theme, /* box */4898315, /* [] */0, "footer");
  var build = function (items) {
    var wrap = function (item, param, param$1, param$2, param$3) {
      return item;
    };
    return Curry._5(buildNavBar, Plate$BwaxMobile.List.map(wrap, Curry._1(Plate$BwaxMobile.List.from_array, items)), theme, /* box */4898315, /* [] */0, "navbar");
  };
  var inner = function (param, param$1, param$2, param$3) {
    return React.createElement(make, {
                welcome: wc,
                sections: secs,
                footer: ft,
                buildNavBar: build,
                onScrolledToSection: onScrolledToSection
              });
  };
  return BaseUI$BwaxMobile.box(rules, inner, theme, pc, /* [] */0, "nokey");
}

var Comp = {
  make: OnePageLayout001$Comp
};

function create(key, rules, theme, pc, welcome, sections, footer, buildNavBar, onScrolledToSection) {
  return React.createElement(OnePageLayout001$Comp, {
              pc: pc,
              theme: theme,
              rules: rules,
              welcome: welcome,
              sections: sections,
              footer: footer,
              buildNavBar: buildNavBar,
              onScrolledToSection: onScrolledToSection,
              key: key
            });
}

exports.OnePageLayout001_bridge = OnePageLayout001_bridge;
exports.Comp = Comp;
exports.create = create;
/* make Not a pure module */
