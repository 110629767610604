
// This file is generated. Don't modify it.
import React from 'react'
export default function HeartIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M10.6271953,2.42416089 C9.59409601,2.42416089 8.63633565,2.9441345 8.00000586,3.79929317 C7.37064126,2.94407587 6.40597435,2.42416089 5.37286332,2.42416089 C3.51202052,2.42416089 2,4.07978859 2,6.1117241 C2,7.3226585 2.54044705,8.17788752 2.97829241,8.86200039 C4.24398681,10.8529421 7.43217882,13.3226644 7.56893807,13.4253247 C7.69135077,13.5229783 7.84335523,13.5760602 7.99994723,13.5758384 C8.15044925,13.5758384 8.30096299,13.5279381 8.43094467,13.4253247 C8.56783291,13.3226644 11.7491066,10.8460356 13.0216372,8.86200039 C13.4595529,8.1778289 14,7.32267023 14,6.1117241 C14.0000586,4.07978859 12.4880967,2.42416089 10.627207,2.42416089 L10.6271953,2.42416089 Z" id="HeartIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Filling/Heart">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="HeartIcon-mask-2" fill="white">
                            <use href="#HeartIcon-path-1"></use>
                        </mask>
                        <use id="Path" fill={color} fillRule="nonzero" href="#HeartIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
