import React from 'react'

import { Transforms } from 'slate'

import { MdDelete } from 'react-icons/md'

export default function RemoveButton(props) {

    const { editor, updateSelectedNode, updateToolbarButtons } = props

    function removeImage() {
        Transforms.removeNodes(editor)
        updateSelectedNode(null)
        if(updateToolbarButtons) {
            updateToolbarButtons(null)
        }
        
    }

    return (
        <button className="editor-button" onClick={() => removeImage()}>
            <MdDelete style={{ fontSize: 14 }}/>
        </button>
    )
}
