

import React, { useRef, useState } from 'react'

import useResizeObserver from '@react-hook/resize-observer'

import './Grid.less';

// 
export default function Grid({ items = [], minWidth = 240, gap = 16, renderItem, maxRows, renderMore }) {

    const containerRef = useRef();

    const [ columnNum, setColumnNum ] = useState();
    const columnNumRef = useRef();
    columnNumRef.current = columnNum;

    useResizeObserver(containerRef, entry => {
        
        const contentWidth = entry.contentRect.width;

        // calculate the columns:
        //  x * minWidth + (x - 1) * gap < contentWidth
        // 这里假定 gap 和 minWidth 都是 number, TODO 以后在优化

        let x = 1;
        while((x + 1) * minWidth + x * gap < contentWidth) {
            // try next
            x = x + 1
        }
        if(x != columnNumRef.current) {
            setColumnNum(x);
        }
    });

    function renderRows () {
        const calcualtedRowNum = Math.floor(items.length / columnNum) + (items.length % columnNum != 0 ? 1 : 0);

        const rowNum = maxRows && maxRows <= calcualtedRowNum ? maxRows : calcualtedRowNum;

        const shouldRenderMore = rowNum * columnNum < items.length && renderMore;

        return Array(rowNum).fill(0).map((_, rowIndex) => {
            const itemsToRendered = Array(columnNum).fill(0).map((_, columnIndex) => {
                return items[rowIndex * columnNum + columnIndex] || "__placeholder__"
            });

            return (
                <div className="grid-row" key={rowIndex + "/" + columnNum}>
                    { itemsToRendered.map((item, index) => {
                        const isLastOne = index == columnNum - 1 && rowIndex == rowNum -  1;

                        return (
                            <div className="grid-cell" key={item.id || index} style={{
                                width: (100 / columnNum) + "%"
                            }}>
                                { isLastOne && shouldRenderMore ? renderMore() : (item == "__placeholder__" ? null : renderItem(item)) }
                            </div>
                        )
                    })}
                </div>
            )
        })

    }

    const paddingSize = Math.round(gap * 10 / 2) / 10;

    return (
        <div ref={containerRef} className="lc-grid" style={{
            "--padding-size": typeof(gap) == "number" ? paddingSize + "px" : paddingSize
        }}>
            { columnNum ? renderRows() : null }
        </div>
    )
}
