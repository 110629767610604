import React from 'react'

class InternIcon extends React.Component {

    render() {
        return (

            <svg width="1em" height="1em" viewBox="0 0 16 16">
                <defs>
                    <linearGradient x1="100%" y1="100%" x2="0%" y2="0%" id="linearGradient-1">
                        <stop stopColor="#3C3D3D" offset="0%"></stop>
                        <stop stopColor="#141414" offset="100%"></stop>
                    </linearGradient>
                    <linearGradient x1="100%" y1="100%" x2="0%" y2="0%" id="linearGradient-2">
                        <stop stopColor="#FFCF99" offset="0%"></stop>
                        <stop stopColor="#FFE2C2" offset="100%"></stop>
                    </linearGradient>
                </defs>
                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Icon/VIP-rights/实习" fillRule="nonzero">
                        <g>
                            <circle id="Oval" fill="url(#linearGradient-1)" cx="8" cy="8" r="8"></circle>
                            <path d="M5.61143019,6.65982664 L7.12979516,7.34164875 L7.12979516,9.32812556 L6.37608045,9.32812556 L6.37608045,11.6125739 L4.78689901,11.6125739 L4.78689901,9.32812556 L4.03318429,9.32812556 L4.03318429,7.34164875 L5.55154927,6.65982664 L4.27792832,5.92450125 L4.27792832,4.41927819 L5.58148973,3.66666667 L6.88505113,4.41927819 L6.88505113,5.92450125 L5.61143019,6.65982664 Z M6.70989506,4.65501628 L4.73461922,4.65501628 L4.55226486,4.44374008 L4.37819961,4.54286519 L4.64183797,4.85366396 L6.70989506,4.85366396 L6.70989506,4.65501628 Z M5.42371805,7.63962027 L5.42371805,9.03015403 L5.82101341,9.03015403 L5.82101341,7.63962027 L5.42371805,7.63962027 Z M8.59049144,6.65953299 L10.1095104,7.34164875 L10.1095104,8.65838371 L8.98279513,9.32802227 L8.98279513,11.6125739 L7.76661421,11.6125739 L7.76661421,9.32812556 L7.60884253,9.32812556 L7.60884253,7.07404041 L8.53061052,6.6601203 L7.25648095,5.92450125 L7.25648095,4.41927819 L8.56004236,3.66666667 L9.86360376,4.41927819 L9.86360376,5.92450125 L8.59049144,6.65953299 Z M8.40343326,7.63962027 L8.40343326,8.63285867 L8.80072862,8.63285867 L8.80072862,7.63962027 L8.40343326,7.63962027 Z M9.68844769,4.65501628 L7.71317185,4.65501628 L7.53081749,4.44374008 L7.35675224,4.54286519 L7.6203906,4.85366396 L9.68844769,4.85366396 L9.68844769,4.65501628 Z M12.6334824,9.47955571 L12.6334824,11.3820741 L10.9858531,12.3333333 L9.33822382,11.3820741 L9.33822382,9.47955571 L10.9858531,8.5282965 L12.6334824,9.47955571 Z M11.3831485,10.271702 L10.9858531,10.0233924 L10.5885577,10.271702 L10.5885577,10.7683212 L10.9858531,11.0166308 L11.3831485,10.7683212 L11.3831485,10.271702 Z" id="Combined-Shape" fill="url(#linearGradient-2)"></path>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export default InternIcon
