
import React, { useContext } from 'react'
import { Link as L } from 'react-router-dom'

import ExecutionContext from 'bwax-ui/page/ExecutionContext'
import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'

import queryString from 'query-string';

import parse from 'url-parse';

export default function Link(props) {

    const ec = useContext(ExecutionContext) || {};

    const dlc = useContext(DataLoaderContext);

    const { to, to_, children, className, style, openWithNewTab } = props

    const t = removeAbsolutePrefixIfPossible(to || to_, dlc);

    const newWindowProps = openWithNewTab ? { target: "_blank" } : {}

    if (ec && ec.designMode) {
        return (
            <a className={className} style={style} onClick={e => {
                e.preventDefault();
                ec.routeTo(wrapRK(t), false);
            }} href={t} {...newWindowProps}>{children}</a>
        )
    } else {
        if (t && t.startsWith('http')) {
            return (
                <a className={className} onClick={e => {
                    if (typeof (window) !== 'undefined') {
                        e.preventDefault();
                        const url = wrapRK(t);
                        if(openWithNewTab) {
                            window.open(url, "_blank");
                        } else {                            
                            window.location.href = url;
                        }
                    }
                }} style={style} href={t} {...newWindowProps}>
                    {children}
                </a>
            )
        } else {
            const processedTenantCode = wrapTenantCode(t, dlc);
            return <L className={className} style={style} to={processedTenantCode} {...newWindowProps}>{children}</L>
        }
    }


}


// 如果 URL 是绝对路径但在同一个 host，则移除前面的绝对路径和 sandbox
// https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/934

// 但现在要考虑 tenantCode

export function removeAbsolutePrefixIfPossible(url, dlc) {
    if(url.startsWith("http")){
        const { hostname , pathname, query, hash } = parse(url)
        if(pathname.startsWith("/__")) {
            // 特殊的一系列url，比如 __redirect__，需要调用服务器的逻辑
            return url
        }

        const mobileHosts = dlc.userenv ? (dlc.userenv.mobileHosts || []) : [];
        if(mobileHosts.indexOf(hostname) !== -1) {
            function removeSandbox(path) {
                const sandboxPrefix = "/sandbox"
                if(dlc.sandbox && path.startsWith(sandboxPrefix)) {
                    return path.substring(sandboxPrefix.length)
                } else {
                    return path
                }
            }
            const path = removeSandbox(pathname + query + hash);

            // check if in the same tenant
            if(dlc.tenantCode) {
                const tenantPrefix = "/-/" + dlc.tenantCode;
                const same = path.startsWith(tenantPrefix);
                if(same) {
                    return path.substring(tenantPrefix.length)
                } else{
                    return url
                }
            } else {

                if(path.match(/\/-\/([^\/]+)\//)) {
                    // the url is for some tenant but current url is in platform
                    return url 
                } else {
                    // not for any tenant
                    return path
                }
            }

        } else {
            // 不是同一个域
            return url 
        }
    } else {
        // 不是绝对路径
        return url 
    }
}

// wrap rk
// http://git.qunfengshe.com/qunfengshe/bwax-app-admin/issues/548
export function wrapRK(url) {
    if (url && url.startsWith('http') && url.indexOf("m.qunfengshe.com") != -1) {
        if (typeof (sessionStorage) !== 'undefined') {
            const parentKey = sessionStorage.getItem("_rk_");
            if (parentKey) {
                return queryString.stringifyUrl({ url, query: { "rk": parentKey } })
            }
        }
    }
    return url
}


// ?_tenant_=ind-5211
// 如果是相对路径，有 tenantCode，并且 tenantCodeMode 等于 "param"，那么应该在路径后加上参数
export function wrapTenantCode(url, dlc) {
    const { tenantCode, tenantCodeMode } = dlc;
    if(tenantCode && tenantCodeMode == "param" && !url.startsWith("http")) {
        return queryString.stringifyUrl({url, query: { "_tenant_": tenantCode }})
    } else {
        return url
    }
    
}