
// This file is generated. Don't modify it.
import React from 'react'
export default function PositionIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M9.19912934,3.75 L9.52218605,2.49999972 L6.47952607,2.49999972 L6.80257902,3.75 L9.19912934,3.75 Z M9.76012496,4.75 L6.24041626,4.75 C6.01600833,4.74984097 5.82194904,4.61700748 5.7739258,4.4306875 L5.14397251,1.9931875 C5.11301648,1.87308511 5.14771945,1.74752819 5.23835567,1.6517049 C5.32899188,1.55588161 5.46580894,1.50010249 5.61046296,1.5 L10.3900783,1.5 C10.5348544,1.50041195 10.6716733,1.55635775 10.7624086,1.65224733 C10.8531439,1.74813692 10.8880959,1.87371958 10.8575246,1.994 L10.2275713,4.4315 C10.1781186,4.61728265 9.98422544,4.74939403 9.76012496,4.75 Z M11.6675019,10.8083039 L9.59231719,6.56249999 L6.40726812,6.56249996 L4.33208343,10.8083039 L7.99979265,13.3248983 L11.6675019,10.8083039 Z M8.00027061,14.5 C7.86624828,14.4998261 7.73839303,14.4521163 7.647535,14.368375 L3.1252836,11.2654375 C3.02097912,11.1682085 2.97826549,11.0341823 3.01057283,10.9055 L5.46516265,5.8834375 C5.51248491,5.6961741 5.70726492,5.56244281 5.93260903,5.5625 L10.0679322,5.5625 C10.2929178,5.56282542 10.4871759,5.69647172 10.5344226,5.8834375 L12.9890125,10.9055 C13.022145,11.0342005 12.9793293,11.1685468 12.8743017,11.2654375 L8.3520503,14.368375 C8.26169627,14.4522396 8.13405891,14.4999975 8.00027061,14.5 Z" id="PositionIcon-path-1"></path>
            </defs>
            <g id="Style" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Iconology" transform="translate(-384.000000, -284.000000)">
                    <g id="Icon/Personal" transform="translate(48.000000, 284.000000)">
                        <g id="Icon/Personal/Position" transform="translate(336.000000, 0.000000)">
                            <g id="Group-3">
                                <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                                <mask id="PositionIcon-mask-2" fill="white">
                                    <use href="#PositionIcon-path-1"></use>
                                </mask>
                                <use id="Mask" fill={color} fillRule="nonzero" href="#PositionIcon-path-1"></use>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
