

export default function traverseSlateValue(value, f) {
    if(!value) { return value }

    if(Array.isArray(value)) {
        return value.map(v => traverseSlateValue(v, f))
    }

    const { children, ...props } = f(value);
    
    return {
        ...props,
        ...(children ? { children : children.map(c => traverseSlateValue(c, f)) } : {}),
    }
}

export function cleanSlateValue(value) {
    const f = o => {
        return Object.keys(o).reduce((acc, k) => {
            if(k.startsWith("__")) {
                return acc;
            } else {
                return { ...acc, [k]: o[k] }
            }
        }, {})
    }

    return traverseSlateValue(value, f);
}