
// This file is generated. Don't modify it.
import React from 'react'
export default function AlipayIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M7.73673661,11.5559509 C8.63519643,11.127125 9.51620536,10.1528214 9.51620536,10.1528214 L13.9119286,12.2365893 C13.6326741,13.2525982 12.7047366,14 11.6,14 L4.4,14 C3.07452232,14 2,12.9254777 2,11.6 L2,4.4 C2,3.07452232 3.07452232,2 4.4,2 L11.6,2 C12.9254777,2 14,3.07452232 14,4.4 L14,10.3207009 L10.1900268,9.07054464 C10.1900268,9.07054464 10.4887679,8.63488839 10.8069152,7.78065179 C11.1253839,6.92638839 11.1707991,6.45674107 11.1707991,6.45674107 L8.65851339,6.43630357 L8.65851339,5.59910268 L11.7011161,5.57869196 L11.7011161,4.98648661 L8.6585,4.98648661 L8.6585,3.63877679 L7.16782143,3.63877679 L7.16782143,4.9865 L4.32954018,4.9865 L4.32954018,5.57870536 L7.16782143,5.55826786 L7.16782143,6.45675446 L4.89110268,6.45675446 L4.89110268,6.92640179 L9.57738393,6.92640179 C9.57738393,6.92640179 9.52642411,7.30702679 9.34689286,7.78066518 C9.16722768,8.25437054 8.9823125,8.66870536 8.9823125,8.66870536 C8.9823125,8.66870536 6.78165179,7.91707143 5.62153571,7.91707143 C4.46182143,7.91707143 3.05174107,8.37195982 2.91524107,9.69110268 C2.77899554,11.0101518 3.57187946,11.7245938 4.68916518,11.9881384 C5.8064375,12.2515625 6.83816964,11.9847634 7.73673661,11.5559509 Z M5.13363393,8.64112946 C6.53219643,8.55209375 8.42135268,9.60801339 8.42135268,9.60801339 C8.42135268,9.60801339 7.02605804,11.2656473 5.38239286,11.2656473 C3.73833929,11.2656473 3.39291071,10.4783214 3.39291071,9.91199107 C3.39291071,9.34566071 3.73466964,8.73017857 5.13363393,8.64112946 Z" id="AlipayIcon-path-1"></path>
            </defs>
            <g id="Style" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Iconology" transform="translate(-216.000000, -252.000000)">
                    <g id="Icon/Social" transform="translate(48.000000, 252.000000)">
                        <g id="Icon/Alipay" transform="translate(168.000000, 0.000000)">
                            <g id="Group-3">
                                <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                                <mask id="AlipayIcon-mask-2" fill="white">
                                    <use href="#AlipayIcon-path-1"></use>
                                </mask>
                                <use id="Mask" fill={color} href="#AlipayIcon-path-1"></use>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
