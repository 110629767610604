

import { runDataQuery } from 'bwax/query/runClientQuery'

export default async function countToken({ text, dlc }) {

    if (!text || text.trim().length == 0) {
        // 如果字符串长度都小于 token 数，就不用 cut 了
        return text
    }

    const runQuery = runDataQuery(dlc);

    const queryText = `
        query($text:String!) {
            openAITokens {
                countText(text: $text){
                    textToken
                }
            }      
        }
    `

    const result = await runQuery(queryText)({ text });

    const { errors, data } = JSON.parse(result);

    if (data && data.openAITokens && data.openAITokens.countText) {
        return data.openAITokens.countText.textToken
    } else {
        return 0
    }

}