import loadable from "@loadable/component"

import addPrefix from 'bwax-ui/addPrefix'

const VisitCompanyIcon = loadable(() => import ('./VisitCompanyIcon'));
const CourseIcon = loadable(() => import ('./CourseIcon'));
const DiscussionIcon = loadable(() => import ('./DiscussionIcon'));
const HealthIcon = loadable(() => import ('./HealthIcon'));
const DiscountsIcon = loadable(() => import ( './DiscountsIcon'));
const InternIcon = loadable(() => import ('./InternIcon'));
const InvestIcon = loadable(() => import ('./InvestIcon'));

export default addPrefix('viprights', {
    VisitCompanyIcon,
    DiscountsIcon,
    CourseIcon,
    HealthIcon,
    DiscussionIcon,
    InternIcon,
    InvestIcon,
})