

import React from 'react'

import Checkbox from 'bwax-ui/components/inputs/Checkbox';

import './SearchItems.less';


export default function SearchItemsCommon({ actionButton, items, renderItem, isItemSelected, selectItem, unselectItem }) {

    function renderItemLine(item, index) {

        const isChecked = isItemSelected(item)

        return (
            <div key={item.id} className="selectable-item" onClick={_ => {
                if (isChecked) {
                    return unselectItem(item)
                } else {
                    return selectItem(item)
                }
            }}>
                <Checkbox color="violet" checked={isChecked}
                    onChange={checked => {
                        if (isChecked && !checked) {
                            return unselectItem(item)
                        } else if (!isChecked && checked) {
                            return selectItem(item)
                        }

                    }}
                />
                {renderItem(item, index)}
            </div>
        )
    }

    function x16(arr) {
        // const x2 = [...arr, ...arr];
        // const x4 = [...x2, ...x2];
        // const x8 = [...x4, ...x4];
        // const x16 = [...x8, ...x8];
        // return x16

        return arr
    }

    return (
        <>
           { actionButton || null }
            <div className="lc-search-items">
                {x16(items || []).map(renderItemLine)}
            </div>
        </>

    )
}
