

import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet-async'

import Link from 'bwax-ui/page/Link';


import TranslationTotalStat from './stat/TranslationTotalStat'
import TranslationGoogleAdsStat from './stat/TranslationGoogleAdsStat'

import TranslationTrends from './stat/TranslationTrends'
import TranslationUserTrends from "./stat/TranslationUserTrends"

import TranslationGoogleAdsUserTrends from './stat/TranslationGoogleAdsUserTrends'

import TranslationReconciliation from "./stat/TranslationReconciliation"


import TranslationStatTemp from "./stat/TranslationStatTemp"

export default function TranslationStat({ data, facade }) {
    const { category } = data;

    const specifiedStats = {
        "total": TranslationTotalStat,
        "trends": TranslationTrends,
        "user-trends": TranslationUserTrends,
        "ga": TranslationGoogleAdsStat,
        "ga-user-trends": TranslationGoogleAdsUserTrends,
        "reconcilliation": TranslationReconciliation,
        "temp-check": TranslationStatTemp,
    }
    const Stat = specifiedStats[category];

    if (Stat) {
        return (
            <Stat {...{ facade }} />
        )
    }

    function renderLink(category, label) {
        return (
            <Link className="" key={category} to={"/admin/stat/" + category}>{label}</Link>
        )
    }
    return (
        <div className="py-6 px-6 flex flex-col gap-4 font-size-14">
            {renderLink("total", "汇总 >>>")}
            {renderLink("trends", "趋势 >>>")}
            {renderLink("user-trends", "用户趋势 >>>")}
            {renderLink("ga", "GA 汇总 >>>")}
            {renderLink("ga-user-trends", "GA 用户趋势 >>>")}
            {renderLink("reconcilliation", "对账 >>>")}
            {/* {renderLink("temp-check", "临时查询 >>>")} */}
        </div>
    )
}





