
import React from 'react'

import { contentToReact } from 'bwax-ui/auxiliary/richtext/renderer'

export default function RichText({ value, className }) {

    if (value && JSON.stringify(value) !== '{}') {
        const content = contentToReact(value)
        return (
            <div className={"rich-text" + (className ? " " + className : "")}>
                {content}
            </div>
        )
    } else {
        return null
    }
}
