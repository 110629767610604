
import React from 'react'

const UsageQuotaContext = React.createContext();

export default UsageQuotaContext

export const UsageQuotaContextProvider = props => {

    const { 
        remainingQuota, quota, usedQuota, totalUsed, isTopTier, currentPlanId, currentPlanExpiryDate, reloadUsageQuota, viewEnv, facade,
        children 
    } = props

    const isOutOfQuota = remainingQuota !== undefined && remainingQuota <= 0;

    return (
        <UsageQuotaContext.Provider value={{
            remainingQuota, quota, usedQuota, totalUsed, isTopTier, isOutOfQuota, currentPlanId, currentPlanExpiryDate, reloadUsageQuota, viewEnv, facade,
        }}>
            { children }
        </UsageQuotaContext.Provider>
    );
}
