


import React, { useEffect, useState } from 'react'
import ToggleGroup from 'bwax-ui/components/inputs/ToggleGroup';

import { useTrack } from 'bwax-ui/track';

// config the temperature in user option

// 严谨，平衡，创意

// precise, balanced, creative
// 0.2, 0.7, 1

export default function TemperatureInput({ value: givenValue, onChange }) {

    const track = useTrack();
    
    const [ localValue, setLocalValue ] = useState(givenValue);

    useEffect(() => {
        if(givenValue !== localValue) {
            setLocalValue(givenValue);
        }
    }, [ givenValue]);

    const itemSettings = {
        precise: {
            label: "更严谨",
            color: "cyan",
            temperature: 0.2,
            range: [undefined, 0.5],
        },
        balanced: {
            label: "平衡",
            color: "indigo",
            temperature: 0.7,
            range: [0.5, 0.71],
        },
        creative: {
            label: "更有创意",
            color: "violet",
            temperature: 1,
            range: [0.71, undefined]
        }
    }


    const items = Object.keys(itemSettings).map(value => {
        const { label, color } = itemSettings[value];
        return { value, label, color }
    })

    const getValue = () => {
        const temperature = (localValue || {}).temperature || 0.7;
        for(let key in itemSettings) {
            const { range } = itemSettings[key];
            const [ start, end ] = range;
            const met = (start === undefined || start <= temperature) && (end === undefined || temperature < end);
            if(met){
                return key
            }
        }
    }

    const toggleValue = getValue();

    return (
        <ToggleGroup {...{
            className: "font-size-13",
            items,
            value: toggleValue,
            onChange: v => {

                track("ui_temperature_change", { value: v });

                const { temperature } = itemSettings[v] || { temperature: 0.7 };

                const newValue = {
                    ...(localValue || {}),
                    temperature
                }

                setLocalValue(newValue);
                onChange(newValue)
            }
        }} />
    )
}
