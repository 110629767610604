
// This file is generated. Don't modify it.
import React from 'react'
export default function DropDownIcon (props) {

    const { color } = props
    
    return (
                <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M4.85355339,2.51040764 L11.1464466,8.80330086 C11.3417088,8.998563 11.3417088,9.31514549 11.1464466,9.51040764 C11.0526784,9.60417583 10.9255015,9.65685425 10.7928932,9.65685425 L4.5,9.65685425 C4.22385763,9.65685425 4,9.43299662 4,9.15685425 L4,2.86396103 C4,2.58781866 4.22385763,2.36396103 4.5,2.36396103 C4.63260824,2.36396103 4.7597852,2.41663945 4.85355339,2.51040764 Z" id="DropDownIcon-path-1"></path>
            </defs>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Arrow/Dropdown-menu">
                    <g>
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="DropDownIcon-mask-2" fill="white">
                        </mask>
                        <use id="Rectangle" fill={color} fillRule="nonzero" transform="translate(8.000000, 5.656854) rotate(-45.000000) translate(-8.000000, -5.656854) " href="#DropDownIcon-path-1"></use>
                        <g id="Color/UI/Text" mask="url(#DropDownIcon-mask-2)" fill={color} fillRule="nonzero">
                            <rect id="light-gray" x="0" y="0" width="16" height="16"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        
    )
}
