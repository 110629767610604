
import langEntryBase from 'bwax/ml/lang/lang_entry_base.bs'

const revision = 3;  // 递增

export function tag (rec) {
    function doTag(r) {
        if (Array.isArray(r)) {
            // let na = r.map(doTag);
            let na = [];
            for(let i in r) {
                na[i] = doTag(r[i]);
            }
            // 不能这样用 for-loop，否则会不一致 （2021-01-08 Van: 为啥）
            // 上面这种形式可以，na.push(doTag(r[i]))，不行
            if (r.tag !== undefined) {
                return { a: na, t: r.tag };
            } else {
                return na;
            }
        } else if (r && typeof(r) == 'object') {
            // 这种可能性出现的比较少
            return Object.keys(r).reduce((acc, k) => {
                return { ...acc, [k]: doTag(r[k]) }
            }, {})

        } else {
            return r;
        }
    }
    const v = doTag(rec);
    return v
}

export function untag (v) {

    function doUntag(v) {
        if (Array.isArray(v)) {
            // return v.map(doUntag);
            let ua = [];
            for(let i in v) {
                ua[i] = doUntag(v[i]);
            }
            return ua;
        } else if (typeof (v) == "object") {
            if (!v) {
                // 通常不会来这里，因为下面已经处理了 null 了
                return undefined
            } else {
                const { a, t } = v
                if(Array.isArray(a) && t !== undefined) {
                    let oa = [];
                    for(let i in a) {
                        // Array 里的 null 改成 undefined， 因为他们基本上就是 ml 里的 None
                        let e = a[i];
                        oa.push(e === null ? undefined : doUntag(a[i]))
                    }
                    oa.tag = t;
                    return oa
                } else {
                    // 这种可能性出现的比较少
                    const uv = Object.keys(v).reduce((acc, k) => {
                        return { 
                            ...acc, 
                            [k]: v[k] === null ? v[k] : doUntag(v[k]) // 对象里的 null 保持原样
                        }
                    }, {})
                    return uv
                }
            }
        } else {
            return v
        }
    }
    const rec = doUntag(v);
    return rec;

}

export function serialize(rec) {
    let v = tag(rec);
    const rv = { rev: revision, v };
    return JSON.stringify(rv);
}

export function deserialize(str) {

    const rv = JSON.parse(str);
    let v = rv;  
    if (rv.rev) {
        // { rev, v }  
        // 有 revision 信息
        // console.log("有 revision 信息", rv.rev);
        v = rv.v;
    } else {
        // console.log("没有 revision 信息");
    }
    return untag(v)
}


export function pack ({
    name, 
    defs,     
    dts,
    entity_dict,
    data_type_dict,
}) {
    // rev 3
    const to_array = langEntryBase.list_to_array;

    const compiled = [
        name, 

        to_array(defs),
        to_array(dts), 
        to_array(entity_dict), 
        to_array(data_type_dict), 
    ];

    return serialize(compiled);

};

export function packV4 ({
    // http://git.qunfengshe.com/qunfengshe/bwax-app-admin/issues/818
    // Compiled 不再包含 entity_dict 和 data_type_dict
    name,
    defs,
    dts,
    entityNames,
    externalNames,
}) {

    const to_array = langEntryBase.list_to_array;
    const compiled = [
        name, 
        to_array(defs),
        to_array(dts), 
        entityNames,
        externalNames,
        "4", // 用于区分新旧版本的，因为旧版本的 element 数目也是五个，所以要加个 revision 在这里
    ];

    const v = tag(compiled);
    const rv = { rev: 4, v };
    return JSON.stringify(rv);

};


export function unpack (str) {

    const to_list = langEntryBase.array_to_list;

    const rv = JSON.parse(str);
    let v = rv;  
    let rev = 0;
    if (rv.rev) {
        // { rev, v }  
        // 有 revision 信息
        // console.log("有 revision 信息", rv.rev);
        v = rv.v;
        rev = rv.rev;
    } else {
        // console.log("没有 revision 信息");
    }
    if (rev >= 4) {

        let [
            name,
            defs,
            dts,
            entityNames,
            externalNames,
            vNum,

        ] = untag (v);

        return [
            name,             
            to_list(defs),
            to_list(dts),
            entityNames,
            externalNames,
            vNum,
        ]

    } else if (rev == 3) {
        // 要进行 array to list

        // console.log("array to list please.")
        // console.log("R3");

        let [
            name, 
            defs,     
            dts,
            entity_dict,
            data_type_dict,
        ] = untag(v);

        return [
            name,             
            to_list(defs),
            to_list(dts),
            to_list(entity_dict),
            to_list(data_type_dict)
        ]

    } else {
        return untag(v)
    }

    
}
