
// This file is generated. Don't modify it.
import React from 'react'
export default function RiskAmountIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="0.75em" height="1em" viewBox="0 0 12 16">
            <defs>
                <path d="M9.14793657,6.99235442 L9.78667171,2.22283202 C9.79455636,2.13394525 9.74060656,2.05040802 9.65307426,2.01596635 C9.56554197,1.98152468 9.46436923,2.00402526 9.40253729,2.07168518 L4.04252217,8.53741097 C3.99434894,8.59900892 3.98648745,8.68063619 4.02210899,8.74936448 C4.05773054,8.81809277 4.13095971,8.86258604 4.21225598,8.86489579 L6.73146309,8.86489579 C6.76914969,8.86455696 6.80512457,8.87966315 6.83000319,8.9062737 C6.8548818,8.93288426 6.8661969,8.96836017 6.86099679,9.00344705 L6.21332829,13.777168 C6.20544364,13.8660547 6.25939344,13.949592 6.34692574,13.9840337 C6.43445803,14.0184753 6.53563077,13.9959747 6.59746271,13.9283148 L11.9574778,7.46258903 C12.0056511,7.40099108 12.0135126,7.31936381 11.977891,7.25063552 C11.9422695,7.18190723 11.8690403,7.13741396 11.787744,7.13510421 L9.27747027,7.13510421 C9.2388714,7.13564832 9.20202569,7.11998201 9.17697427,7.09237463 C9.15192284,7.06476726 9.14127651,7.02809599 9.14793657,6.99235442 Z" id="RiskAmountIcon-path-1"></path>
                <filter x="-34.8%" y="-24.4%" width="169.7%" height="148.8%" filterUnits="objectBoundingBox" id="filter-2">
                    <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                </filter>
            </defs>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Progress-rate/默认风控线" transform="translate(-2.000000, 0.000000)" fillRule="nonzero">
                    <g>
                        <rect id="bg" x="0" y="0" width="16" height="16"></rect>
                        <g id="Shape">
                            <use fill="black" fillOpacity="1" filter="url(#filter-2)" href="#RiskAmountIcon-path-1"></use>
                            <use fill={color} href="#RiskAmountIcon-path-1"></use>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        
    )
}
