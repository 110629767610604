

import React, { useEffect, useState, useRef } from 'react'

import TextArea from 'bwax-ui/components/inputs/TextArea';

import { MagnifyingGlassIcon } from '@radix-ui/react-icons';

import Tabs from 'bwax-ui/components/Tabs';
import TextInput from 'bwax-ui/components/inputs/TextInput';

import Button, { Pressable, iconButton } from 'bwax-ui/components/Button';

import ScrollHelper from 'bwax-ui/ScrollHelper';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';

import RichText from 'bwax-ui/basic/RichText';

import { toast } from 'bwax-ui/components/Toast';

import { useTrack } from 'bwax-ui/track';

const notTranslatable = str => {
    const r = /^[\[\]1234567890\:\.\s##\t\$%\\"\{\}✓✗!&,<>]+$/;
    const match = str.match(r);
    return match && str === match[0];
};

export default function TranslateEdit({ task, facade, submitEdit }) {

    const [keyword, setKeyword] = useState("");

    const track = useTrack();

    // 
    const [taskData, setTaskData] = useState();

    const [lines, setLines] = useState();

    const [editedValues, setEditedValues] = useState({});

    async function saveEdit(editedValues) {
        await facade.update({
            entityName: "文档翻译-任务", id: task.id, formData: { "已修订的翻译缓存": editedValues }, fieldPaths: ["翻译缓存", "已修订的翻译缓存", "已应用的翻译缓存修订"]
        })
    }


    const [helpInfo, setHelpInfo] = useState();
    useEffect(() => {
        (async () => {
            const [result, error] = await facade.findOne({
                entityName: "帮助信息", condition: [[{ field: "名称", op: "eq", value: "译文修订" }]],
                fieldPaths: ["内容"]
            })
            if (result && result.内容) {
                setHelpInfo(result.内容)
            }
        })();
    }, []);

    useEffect(() => {
        // 方便测试，直接改掉服务器端的
        if (taskData && !isEqual(editedValues, taskData.已修订的翻译缓存 || {})) {
            saveEdit(editedValues)
        }

    }, [editedValues]);

    async function loadTaskData() {
        const [taskData, error] = await facade.findById(task.id, {
            entityName: "文档翻译-任务", fieldPaths: ["翻译缓存", "已修订的翻译缓存", "已应用的翻译缓存修订"]
        }, { forceRefreshing: true })

        if (taskData) {
            setTaskData(taskData);

            const translateCache = taskData.翻译缓存 || {};

            setLines(
                Object.keys(translateCache).map((k) => [k, translateCache[k]]).filter(([k, v]) => {
                    // 有些固定的比如 1,2,3,4,5,6,7,8,9 可以移除
                    if (!isNaN(k) && k == v) {
                        return false
                    }
                    if (k == "##TranslationModel##") {
                        return false
                    }
                    if (notTranslatable(k)) {
                        return k != v
                    }

                    return true
                }).map(([k, v], index) => [index, k, v])
            )
            const lastEditedValues = taskData.已修订的翻译缓存 || {};
            // 可能因为换模型、或者启用OCR之后，原文结构的变化导致之前的修订已经不适用了。
            const omittedStaled = Object.keys(lastEditedValues).reduce((acc, key) => {
                if (!translateCache[key]) {
                    return acc
                } else {
                    return {
                        ...acc, [key]: lastEditedValues[key]
                    }
                }
            }, {});

            setEditedValues(omittedStaled)
        }
    }

    useEffect(() => {
        loadTaskData()
    }, [task.id])


    function inside(v, p) {
        return p.toLowerCase().indexOf(v.toLowerCase()) !== -1
    }
    const filteredLines = lines && lines.filter(([index, k, v]) => {
        if (!keyword && !keyword.trim()) {
            return true
        } else {
            // return k.indexOf(keyword) !== -1 || v.indexOf(keyword) !== -1 || (index + "").indexOf(keyword) !== -1
            return inside(keyword, k) || inside(keyword, v) || inside(keyword, index + "");
        }
    });


    const listRef = useRef();

    const INITIAL_LINE_COUNT = 40;
    const [lineCountToShow, setLineCountToShow] = useState(INITIAL_LINE_COUNT);

    function showMore() {
        setLineCountToShow(prev => prev + 20);
    }
    const showMoreRef = useRef();
    showMoreRef.current = showMore;

    const [currentTab, setCurrentTab] = useState();

    useEffect(() => {
        if (listRef.current) {
            const scrollHelper = new ScrollHelper(listRef.current, {
                onScrolledToBottom: _ => {
                    showMoreRef.current && showMoreRef.current();
                }
            });
            return () => {
                scrollHelper.cleanUp();
            }
        }
    }, [currentTab]);

    useEffect(() => {
        if (!lineCountToShow !== INITIAL_LINE_COUNT)
            setLineCountToShow(INITIAL_LINE_COUNT)
    }, [keyword]);

    const [toEdit, setToEdit] = useState();
    const [editing, setEditing] = useState("");

    const inputRef = useRef();


    const printStr = str => {
        const j = JSON.stringify(str);
        return j.substring(1, j.length - 1);
    }
    function renderLine(line, className, showEdited) {
        const [index, k, v] = line;

        return (
            <div className={classNames("flex gap-1.5 py-2 pl-3 pr-5 rounded", className, {
                "": showEdited && editedValues[k]
            })}>
                <div className="text-[var(--gray10)] font-size-13 min-w-[2rem] max-w-[2rem] text-right pt-[1px]">#{index + 1}</div>
                <div className="flex flex-col gap-2 grow">
                    <div className="">
                        {printStr(k)}
                    </div>
                    <div className="text-[var(--gray11)] ">
                        {printStr(v)}
                    </div>
                    {showEdited && editedValues[k] ? (
                        <div className="px-3 py-2 rounded bg-[var(--grass3)] -mx-3 mb-1 ">
                            {printStr(editedValues[k])}
                        </div>
                    ) : null}
                </div>
            </div>
        )
    }

    function renderEdit(withDelete) {
        if (toEdit) {

            const [index, k, v] = toEdit;
            const isDisabled = !(editing && editing.trim()) || (editedValues && editedValues[k] && (editing == printStr(editedValues[k]))) || (editing == printStr(v))
            return (
                <div className="flex flex-col gap-4">
                    {renderLine(toEdit)}
                    <div className="pl-10 pr-4 flex flex-col gap-2">
                        <TextArea minRows={4} styled={true} autoFocus color={"violet"} placeholder="输入您修改的译文" value={editing} onChange={setEditing} ref={inputRef}
                            onKeyDown={e => {
                                if (e.key == "Enter") {
                                    e.preventDefault();
                                    // 不让换行
                                }
                            }
                            }
                        />
                        <div className="px-1 text-[var(--gray11)]">{"\\n 代表换行，但只有当原文译文中 \\n 数量相同时才生效；尽可能保留 \\t 等特殊字符"}</div>
                        <div className="flex gap-2 justify-between">
                            <div>
                                {withDelete ?
                                    iconButton(<i className="bx bx-trash"></i>, _ => {
                                        setToEdit();
                                        setEditing("");
                                        setEditedValues(Object.keys(editedValues).reduce((acc, key) => {
                                            return key == k ? acc : { ...acc, [key]: editedValues[key] }
                                        }, {}))
                                    }) :
                                    (
                                        <Button onPress={_ => {
                                            setEditing(printStr(v));
                                            inputRef.current && inputRef.current.focus()
                                        }}>复制原译文</Button>
                                    )}
                            </div>
                            <div className="flex gap-2">
                                <Button onPress={_ => {
                                    setToEdit()
                                    setEditing("")
                                }}>取消</Button>
                                <Button color={"grass"} isDisabled={isDisabled} onPress={_ => {

                                    const json = (function (raw) {
                                        try {
                                            return JSON.parse(raw);
                                        } catch (err) {
                                            toast({ title: "内容出错了" })
                                            return false;
                                        }
                                    })(`"${editing}"`);

                                    if(json) {
                                        setEditedValues(prev => {
                                            return {
                                                ...(prev || {}),
                                                [k]: json
                                            }
                                        })
                                        setToEdit()
                                        setEditing("")
                                    } 

                                }}>确定</Button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return null
    }


    function renderView() {
        return (
            <div className="h-full flex flex-col gap-2">
                <div className="flex px-3 gap-2 items-center">
                    <TextInput placeholder={"输入关键字搜索"} value={keyword} onChange={setKeyword} aria-label={"keyword"} className="grow"
                        prefix={<MagnifyingGlassIcon />}
                    />
                    {lines ? (
                        <div className="text-[var(--gray11)] font-size-13">
                            {keyword && keyword.trim() ? (
                                `${filteredLines.length}/${lines.length}`
                            ) : `共 ${lines.length} 条记录`}
                        </div>
                    ) : null}
                </div>
                {
                    renderEdit()
                }
                <div className={classNames(
                    "flex flex-col gap-1 grow min-h-0 overflow-auto pl-1 pr-2 py-2", {
                    "invisible": toEdit
                })
                } ref={listRef}>
                    {filteredLines ? filteredLines.slice(0, lineCountToShow).map(([index, k, v]) => {
                        return (
                            <Pressable key={index} onPress={_ => {
                                track("translate_press_line_to_edit", { place: "original_list" })
                                setToEdit([index, k, v]);
                                setEditing((editedValues[k] ? printStr(editedValues[k]) : printStr(v)));

                            }}>
                                {renderLine([index, k, v], "cursor-pointer hover:bg-[var(--gray2)]", true)}
                            </Pressable>
                        )
                    }) : null}
                </div>
            </div>
        )
    }

    function rendeRecords() {
        return (
            <div className="h-full flex flex-col gap-2">
                <div className="flex px-8 gap-2 items-center">
                    <div className="text-[var(--gray11)] font-size-13">
                        {`共 ${Object.keys(editedValues).length} 处修订`}
                    </div>
                </div>
                {
                    renderEdit(true)
                }
                <div className={classNames(
                    "flex flex-col gap-1 grow min-h-0 overflow-auto pl-1 pr-2 py-2", {
                    "invisible": toEdit
                })
                }>
                    {Object.keys(editedValues).map(k => {

                        const line = lines.find(l => l[1] == k);
                        if (!line) {
                            return null
                        }
                        const [index, _, v] = line;

                        return (
                            <Pressable key={index} onPress={_ => {
                                track("translate_press_line_to_edit", { place: "edited_list" })
                                setToEdit([index, k, v]);
                                setEditing(printStr(editedValues[k]))
                            }}>
                                {renderLine([index, k, v], "cursor-pointer hover:bg-[var(--gray2)]", true)}
                            </Pressable>
                        )
                    })}
                </div>
            </div>
        )
    }

    function renderHelp() {
        return (
            helpInfo ? (
                <div className="px-4">
                    <RichText params={{ value: helpInfo }}></RichText>
                </div>
            ) : null
        )
    }


    const editedCount = Object.keys(editedValues || {}).length;
    const tabs = [
        { label: "译文", key: "view", content: renderView() },
        {
            label: (
                <div className="flex items-center">
                    已修订{editedCount > 0 ? <span className="bg-[var(--mauve5)] ml-1 px-1 py-0.5 rounded-full inline-flex text-[12px]">{editedCount}</span> : null}
                </div>
            ),
            key: "records", content: rendeRecords()
        },
        { label: "说明", key: "help", content: renderHelp() },
    ]

    return (
        <div className="sm:h-[85vh] flex flex-col pb-6">
            <Tabs tabContentClassName="!px-0 !py-4" style={{ height: "calc(100% - 33px)" }} tabs={tabs} defaultTab={"view"} onTabChange={setCurrentTab} />
            <div className="flex justify-end px-4">
                <Button color="violet" isDisabled={!taskData || isEqual(editedValues, taskData.已应用的翻译缓存修订 || {})} onPress={_ => {
                    track("translate_submit_edit_translation");
                    submitEdit(task)

                }}>{"重新翻译"}</Button>
            </div>
        </div>

    )
}
