
import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet-async'

import numeral from 'numeral';

/**
访问人数：在 PayViewStat 那里 count， 有访问者的 count 访问者 + 没有访问者的 count device
点击“我要使用”的人数：在 PayViewStat 那里筛选 (/u/task 或者 /logon）后 count
尝试了翻译的人数
购买支付完整翻译的人数
复购人数
总金额
 */

export default function TranslationTotalStat({ facade }) {

    const [viewerCount, setViewerCount] = useState();
    const [deviceCount, setDeviceCount] = useState();

    const [deviceTriedCount, setDeviceTriedCount] = useState();

    // 
    async function countViewer() {
        const [result, error] = await facade.aggregate({
            entityName: "页面统计-页面访问",

            aggregate: [{
                field: "访问者.id",
                func: "COUNT_DISTINCT",
                aliasName: "count",
            }],
            condition: [[{
                field: "访问者.id", op: "ne", value: null
            }]]
        })
        if (!error) {
            // setFileCounts(result);
            setViewerCount(result[0].count)
        }
    }

    // 暂时用 device 而不是 anonymous device.
    async function countDevice() {
        const [result, error] = await facade.aggregate({
            entityName: "页面统计-页面访问",

            aggregate: [{
                field: "DeviceID",
                func: "COUNT_DISTINCT",
                aliasName: "count",
            }],
            // condition: [[{
            //     // field: "访问者.id", op: "eq", value: null
            // }]]
        })
        if (!error) {
            setDeviceCount(result[0].count)
        }
    }

    async function countDeviceTried() {
        const [result, error] = await facade.aggregate({
            entityName: "页面统计-页面访问",

            aggregate: [{
                field: "访问者.id",
                func: "COUNT_DISTINCT",
                aliasName: "count",
            }],
            condition: [
                [{ field: "路径", op: "like", value: "/u/task%" }],
                [{ field: "路径", op: "like", value: "%/logon%" }]
            ]
        })
        if (!error) {
            setDeviceTriedCount(result[0].count)
        }
    }

    const [userUploadedCount, setUserUploadedCount] = useState();

    async function countUserUploaded() {
        const [result, error] = await facade.aggregate({
            entityName: "文档翻译-任务",

            aggregate: [{
                field: "创建者.id",
                func: "COUNT_DISTINCT",
                aliasName: "count",
            }],
            // condition: [
            //     [{ field: "路径", op: "like", value: "/u/task%" }],
            // ]
        })
        if (!error) {
            setUserUploadedCount(result[0].count)
        }
    }

    const [userPaidCount, setUserPaidCount] = useState();

    async function countUserPaid() {
        const [result, error] = await facade.aggregate({
            entityName: "文档翻译-任务",
            aggregate: [{
                field: "创建者.id",
                func: "COUNT_DISTINCT",
                aliasName: "count",
            }],
            condition: [[
                { field: "已支付", op: "eq", value: true },
                { field: "价格", op: "gt", value: 0 },
            ]]
        })
        if (!error) {
            setUserPaidCount(result[0].count)
        }
    }


    const [userRepaidCount, setUserRepaidCount] = useState();

    async function countUserRepaid() {
        const [result, error] = await facade.aggregate({
            entityName: "文档翻译-任务",
            aggregate: [{
                field: "id",
                func: "COUNT_DISTINCT",
                aliasName: "count",
            }],
            group: [{
                field: "创建者.id",
                aliasName: "userId"
            }],
            condition: [[
                { field: "已支付", op: "eq", value: true },
                { field: "价格", op: "gt", value: 0 },
            ]]
        })
        if (!error) {
            setUserRepaidCount(result.filter(r => r.count > 1).length);
        }
    }

    const [totalAmount, setTotalAmount] = useState();

    async function countTotalAmount() {
        const [result, error] = await facade.aggregate({
            entityName: "充值记录",
            aggregate: [{ field: "相关申请.金额", func: "SUM", aliasName: "sum", }],
            // group: timeGroup,
            condition: [[
                // timeCondition,
                { field: "相关申请.订单.支付状态", op: "eq", value: "已支付"},
                { field: "作废", op: "ne", value: true }
            ]]
        });
        if (!error) {
            // console.log(">> total amount", result);
            setTotalAmount(result[0].sum)
        }
    }

    const [spendAmount, setSpendAmount] = useState();
    async function loadSpendAmount() {
        const [result, e] = await facade.aggregate({
            entityName: "消费记录",
            aggregate: [{ field: "数量", func: "SUM", aliasName: "sum", }],
            condition: [[
            ]]
        })
        if (result) {
            setSpendAmount(result[0].sum)
        }
    }

    const [spendTimes, setSpendTimes] = useState();
    async function loadSpendTimes() {
        const [result, e] = await facade.aggregate({
            entityName: "消费记录",
            aggregate: [{ field: "id", func: "COUNT_DISTINCT", aliasName: "count", }],
            condition: [[
            ]]
        })
        if (result) {
            setSpendTimes(result[0].count)
        }
    }

    useEffect(() => {
        countDevice();
        countDeviceTried();
        countUserUploaded();
        countUserPaid();
        countUserRepaid();

        countTotalAmount();
        loadSpendAmount();
        loadSpendTimes();

    }, []);

    function renderItem(label, value) {
        return (
            <div key={label} className="">
                <span className="text[var(--gray(11))] font-size-12 mr-3">
                    {label}
                </span>
                <span className="font-size-14">
                    {value || "-"}
                </span>
            </div>
        )
    }

    return (
        <>
            <Helmet>
                <title>文档翻译 | 汇总</title>
            </Helmet>
            <div className="flex flex-col px-6 py-6 gap-2">
                {renderItem("访问过的人数（≈)", deviceCount)}
                {renderItem("尝试过进入“翻译页面”的人数（≈)", deviceTriedCount)}
                {renderItem("尝试翻译的人数", userUploadedCount)}
                {renderItem("支付过的人数", userPaidCount)}
                {renderItem("支付过两次以上的人数", userRepaidCount)}
                {renderItem("充值总金额", totalAmount ? "￥" + numeral(totalAmount).format("0,0") : null)}
                {renderItem("消费积分", spendAmount)}
                {renderItem("消费次数", spendTimes)}
                {renderItem("次均消费", spendAmount && spendTimes ? numeral(spendAmount / spendTimes).format("0.00") : undefined)}
                {renderItem("人均消费", spendAmount && userPaidCount ? numeral(spendAmount / userPaidCount).format("0.00") : undefined)}
                {renderItem("人均充值", totalAmount && userPaidCount ? "￥" + numeral(totalAmount / userPaidCount).format("0.00") : undefined)}
                {renderItem("转化次数", userPaidCount && spendTimes ? userUploadedCount + spendTimes : undefined)}
                {renderItem("转化价值", userUploadedCount && spendTimes && totalAmount ? "￥" + numeral(totalAmount / (userUploadedCount + spendTimes)).format("0.00") : undefined)}
            </div>
        </>

    )
}
