

import React from 'react'

import lightOrDark from 'bwax-ui/color/lightOrDark'

import getImageURL from 'bwax-ui/getImageURL'

import classNames from 'classnames';

import './Avatar.less';

// const defaultColors = [
//     '#A62A21',
//     '#7e3794',
//     '#0B51C1',
//     '#3A6024',
//     '#A81563',
//     '#B3003C'
// ];


export const colors = [
    "tomato",
    "red",
    "crimson",
    "pink",
    "plum",
    "purple",
    "violet",
    "indigo",
    "blue",
    "cyan",
    "teal",
    "green",
    "grass",
    "orange",
    "brown",
    "amber",
    "yellow",
];

export default function Avatar({ size = 48, avatar, nickName, style, className }) { 

    // default size is for wider screen
    const largersize = size + 2; // or use ratio

    const cn = classNames("lc-avatar", className);
    if(avatar) {
        return (
            <div className={cn} style={{
                backgroundImage: `url(${getImageURL(avatar, "thumbnail")})`,
                "--size-if-narrow":  largersize + "px",
                "--size-if-wide": size + "px",
                ...style
            }} />
        )
    } else {
        const name = (nickName || "User");
        const colorName = colors[stringAsciiPRNG(name, colors.length)];
        const color = `var(--${colorName}4)`

        // const isLight = lightOrDark(color) == "light"
        const isLight = true;

        // 最多两个字符
        const abbr = name.split(" ").map(n => n.substring(0, 1)).join("").substring(0, 2).toUpperCase();
    
        return (
            <div className={cn} style={{
                "--size-if-narrow":  largersize + "px",
                "--size-if-wide": size + "px",
                backgroundColor: color,
  
                // fontWeight: "bold",
                ...(isLight ? { color: `var(--${colorName}11)`} : { color: "--lc-color-inversed"} ),
                ...style
            }}>
                <span style={{ fontSize: "0.5em" }}>{abbr}</span>
            </div>
        )
    }

    
}


// 参考 https://github.com/ambassify/react-avatar/blob/9e9c0a0d4a5f14b93933087e00fa84c101f16a0b/src/utils.js#L59
// https://en.wikipedia.org/wiki/Linear_congruential_generator
export function stringAsciiPRNG(value, m) {
    // Xn+1 = (a * Xn + c) % m
    // 0 < a < m
    // 0 <= c < m
    // 0 <= X0 < m

    const charCodes = [...value].map(letter => letter.charCodeAt(0));
    const len = charCodes.length;

    const a = (len % (m - 1)) + 1;
    const c = charCodes.reduce((current, next) => current + next) % m;

    let random = charCodes[0] % m;
    for (let i = 0; i < len; i++)
        random = ((a * random) + c) % m;

    return random;
}