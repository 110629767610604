

import React from 'react'

import getImageURL from 'bwax/util/getImageURL';

export default function ContactUs({ supportQrCode }) {
    return (
        <div className="flex flex-col items-center h-[70vh]">
            <img src={getImageURL(supportQrCode, "medium")} className="max-h-[70vh]">
            </img>
        </div>
    )
}
