


import React, { useState, useContext, useRef, useEffect } from 'react';

import SlateEditor from 'bwax-ui/auxiliary/richtext_slate/editor/SlateEditor';
import buttonComponents from 'bwax-ui/auxiliary/richtext_slate/editor/buttons';

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'
import { runDataQuery_a } from 'bwax/query/runClientQuery';
import UploadFile from 'bwax-ui/legacy/page/actions/UploadFile';
import { Helmet } from 'react-helmet-async';
import RichTextRenderer from 'bwax-ui/auxiliary/richtext_slate/RichTextRenderer';
import throttle from 'lodash/throttle'

import './TestRichEditor.less';

// 暂时引入它，
import '../auxiliary/richtext/plugins/inlineToolbar/inline-toolbar.less'

const testBlockHeight = 200

function getLocalItem (key) {
    if(typeof(localStorage) !== 'undefined') {
        return JSON.parse(localStorage.getItem(key)) || [
            {
                type: 'paragraph',
                children: [{ text: '' }],
            },
        ]
    } else {
        return null
    }
}

const key = 'test-editor-slate-value-3'

export default function TestEditorAI(props) {

    const { sessionToken, sandbox, tenantCode } = useContext(DataLoaderContext)
    const queryRunner = runDataQuery_a({ sessionToken, sandbox, tenantCode })

    const [ slateValue, setSlateValue ] = useState(getLocalItem(key));
    const [containerContentHeight, setContainerContentHeight] = useState();

    const containerRef = useRef()

    useEffect(() => {
        const height = containerRef.current.offsetHeight;
        setContainerContentHeight(height);

        // document.body.style["overflow"] = "hidden";
    }, [])


    // console.log(">>> slate value", slateValue);

    function changeSlateValue(value) {
        setSlateValue(value)

        if(typeof(localStorage) !== 'undefined') {
            return localStorage.setItem(key, JSON.stringify(value));
        }
    }

    async function doUpload(file, done) {
        const result = await UploadFile({ queryRunner })({
            file,
            isPublic: true,
            uploadFor: "test-richeditor"
        })
    
        if (!result) {
            /// TODO error handling
            message.error("图片上传出错")
        } else {
            done(null, result.url)
        }
    }
    
    async function uploadVideo(file, onUploadProgress, cancelRequest, done, onError) {
        // console.log("uploadVideo file >>> ", file)
        try {
            const result = await UploadFile({ queryRunner })({
                file,
                isPublic: true,
                uploadFor: "test-richeditor",
                onUploadProgress,
                cancelRequest
            })
            if (!result) {
                //TODO: backend error handling
                message.error("视频上传出错")
            } else {
                done(null, result.url)
            }
        } catch (error) {
            console.log("error >>> ", error)
            onError && onError(error)
        }
    }

    const editorRef = useRef();

    function getVisualViewPortHeight () {
        return window.visualViewport.height
    }
    function getVisualViewPortOffsettop () {
        return  window.visualViewport.offsetTop
    }
    const [ refreshed, setRefreshed ] = useState();


    useEffect(() => {
        if (typeof (window) !== 'undefined' && typeof (document) !== 'undefined') {

            function handleScroll(e) {
                setRefreshed((new Date()).getTime())
            }
            
            // const throttleHandler = throttle(handleScroll, 16)

            window.visualViewport.addEventListener('scroll', handleScroll)
            containerRef.current.addEventListener('scroll', handleScroll)

            return () => {
                window.visualViewport.removeEventListener('scroll', handleScroll)
                containerRef.current.removeEventListener('scroll', handleScroll)
            }
        }
    }, [])

    const testInfo = [
        { label: "vvh", value: getVisualViewPortHeight() },
        { label: "vt", value: getVisualViewPortOffsettop() },
        { label: "vh", value: window.innerHeight},
        { label: "cst", value: containerRef.current?.scrollTop || 0}
    ]

    const viewportOffset = (window.innerHeight - window.visualViewport.height)

    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width,initial-scale=1.0,maximum-scale=1.0,user-scalable=no"/>
			</Helmet>
            <div className="test-editor test-editor-ai lc-base" ref={containerRef} style={{ 
                '--test-editor-area-min-height': `${containerContentHeight - testBlockHeight - 72}px`
            }}>
                { (!slateValue) ? null : 
                    <SlateEditor version={2} value={slateValue} uploadImage={doUpload}
                        uploadVideo={uploadVideo} onChange={changeSlateValue}
                        aiEnabled={true}
                        // noToolbar={true}
                        buttonComponents={buttonComponents}
                        placeholder={'请输入正文'}
                        scrollContainerRef={containerRef}
                        showColorButton={true}
                    /> 
                }
                <div style={{ overflow: 'scroll', marginBottom: 48 }}>
                    <RichTextRenderer value={slateValue} version={2} />
                </div>
            </div>
            {/* <div style={{ 
                position: 'fixed', 
                bottom: 48 + viewportOffset,
                // top: 16,
                right: 16, padding: 8,
                backgroundColor: 'var(--gray2)', display: "flex", flexDirection: "column", gap: 16 }}
                onClick={_ => {
                    containerRef.current.scrollTop = 0;
                }}
            >
                {
                    testInfo.map(({ label, value}) => {
                        return (
                            <div style={{ display: "flex", gap: 8, fontSize: 14}} key={label}>
                                <div>{ label }</div>
                                <div>{ value } </div>
                            </div>
                        )
                    })
                }
                <div style={{ display: "none"}}>{refreshed || null}</div>
            </div> */}
        </>
        
    )
}