

import React from 'react';


import BarLoader from 'react-spinners/BarLoader';
import BeatLoader from 'react-spinners/BeatLoader';
import BounceLoader from 'react-spinners/BounceLoader';
import CircleLoader from 'react-spinners/CircleLoader';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import ClipLoader from 'react-spinners/ClipLoader';
import ClockLoader from 'react-spinners/ClockLoader';
import DotLoader from 'react-spinners/DotLoader';
import FadeLoader from 'react-spinners/FadeLoader';
import GridLoader from 'react-spinners/GridLoader';
import HashLoader from 'react-spinners/HashLoader';
import MoonLoader from 'react-spinners/MoonLoader';
import PacmanLoader from 'react-spinners/PacmanLoader';
import PropagateLoader from 'react-spinners/PropagateLoader';
import PulseLoader from 'react-spinners/PulseLoader';
import PuffLoader from 'react-spinners/PuffLoader';
import RingLoader from 'react-spinners/RingLoader';
import RiseLoader from 'react-spinners/RiseLoader';
import RotateLoader from 'react-spinners/RotateLoader';
import ScaleLoader from 'react-spinners/ScaleLoader';
import SkewLoader from 'react-spinners/SkewLoader';
import SquareLoader from 'react-spinners/SquareLoader';
import SyncLoader from 'react-spinners/SyncLoader';


const loaders = {
    "bar": BarLoader,
    "beat": BeatLoader,
    "bounce": BounceLoader,
    "circle": CircleLoader,
    "climbingBox": ClimbingBoxLoader,
    "clip": ClipLoader,
    "clock": ClockLoader,
    "dot": DotLoader,
    "fade": FadeLoader,
    "grid": GridLoader,
    "hash": HashLoader,
    "moon": MoonLoader,
    "pacman": PacmanLoader,
    "propagate": PropagateLoader,
    "pulse": PulseLoader,
    "puff": PuffLoader,
    "ring": RingLoader,
    "rise": RiseLoader,
    "rotate": RotateLoader,
    "scale": ScaleLoader,
    "skew": SkewLoader,
    "square": SquareLoader,
    "sync": SyncLoader,
}

export default function SpinnerBridge (props) {

    const { name, config, color, loading } = props;

    const Loader = loaders[name];

    if(Loader) {

        return (
            <Loader 
                color={color}
                loading={loading}
                {...(config || {})}
            />
        )
    } else {
        return null
    }

}
