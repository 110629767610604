
import React, { useEffect, useState } from 'react'

import { MdOutlineClose } from "react-icons/md"

import './SandboxIndicator.less'



export default function SandboxIndicator () {

    const [ visible, setVisible ]  = useState(false);

    useEffect(() => {
        // 一秒后才出现
        setTimeout(() => {
            setVisible(true)
        }, 1000)

        // 十五秒后后自动关闭（如果没有被手动关闭的话）
        setTimeout(() => {
            setVisible(false)
        }, 15 * 1000)

    }, [])

    if(visible) {
        return (
            <div className={"sandbox-indicator"}>
               <div className="sandbox-indicator-panel">
                    <div className="close-button" onClick={() => setVisible(false)}><MdOutlineClose /></div>
                    <p>您现在访问的是沙箱环境，并非正式网站</p>
                    <p>页面上的数据仅用于测试</p>
                    
               </div>
            </div>
        )
    } else {
        return null
    }

}

