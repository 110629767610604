

// 要控制大小：
// 不同类型的 KEY 有不同优先级
// 1. global_xxx 全局数据，可以考虑写入 server rendered document, 较短的时间内定期更新
// 2. global_runtime_xxx 
//          通常是根据编译后的页面得到的运行环境，不写入 server rendered document
// 3. usr_<sessionToken+deviceId>_data_xxx    
//          用户应用数据，可以考虑写入 server rendered document，这一部分数据要根据该用户的操作 invalidate
//          另外这一部分数据也要定期 invalidate 以确保数据的最新
//          这个 cache 要跟 facade 使用同一份 cache
// 4. usr_<sessionToken+deviceId>_runtime_xxx
//          用户相关数据，通常是用户执行页面后得到的值，这一部分数据应该在很短时间内 invalidate，
//          不写入 server rendered document

import invariant from 'invariant';

// cache type
export const GLOBAL = "GLOBAL";                 // 主要是 defintion 里的内容 
export const GLOBAL_DATA = "GLOBAL_DATA";       // 像 base settings 那种，admin 可以改的
export const GLOBAL_RUNTIME = "GLOBAL_RUNTIME";

export const USER_DURABLE_RUNTIME = "USER_DURABLE_RUNTIME";  // 用于 facade 这种跟用户有关，但是内容不太变化的

export const USER_DATA = "USER_DATA";
export const USER_RUNTIME = "USER_RUNTIME";
export const USER_TRANSIENT_DATA = "USER_TRANSIENT_DATA"; // 一秒钟；


// build cache key:
export class CacheKey {
    constructor({ cacheType, dataKey, uid }) {
        // 这里不用 tenantCode 了，因为 DataCache instance 已经根据 tenantCode 分开了
        this.cacheType = cacheType;
        this.dataKey = dataKey;
        this.uid = uid
    }
    toString() {
        const { cacheType, dataKey, uid } = this;
        if(cacheType == GLOBAL || cacheType == GLOBAL_RUNTIME) {
            return cacheType + "::" + dataKey;   
        } else {
            return cacheType + "::" + uid + "::" + dataKey;
        }
    }
}


export function buildCacheKey(cacheType, dataKey, dlc) {
    const { sessionToken, deviceId } = dlc;
    const uid = sessionToken ? "SK::" + sessionToken : "DI::" + deviceId
    return new CacheKey({cacheType, dataKey, uid});
}


// 定期刷新
// http://git.qunfengshe.com/qunfengshe/bwax-app-admin/issues/854
// 1. Global 和 Global Runtime - 每五分钟 invalidate (要不要自动更新）
// 2. Global Data - 每十五 invalidate（要不要自动更新）
// 3. User Runtime 和 User Data： 每五秒 invalidate ；任何用户的操作，都会 invalidate 掉它自己的 cache
// 4. 以上，如果服务器端的 released 版本变化，则全部 invalidate


function EMPTY_CACHE () {
    return {
        [GLOBAL]: {},
        [GLOBAL_RUNTIME]: {},
        [GLOBAL_DATA]: {},

        [USER_DURABLE_RUNTIME]: {},
        [USER_DATA]: {},
        [USER_RUNTIME]: {},
        [USER_TRANSIENT_DATA]: {},
    }
};

// 用于 invalidation:
const lifes = {
    [GLOBAL]: 5 * 60,
    [GLOBAL_RUNTIME]: 5 * 60,
    [GLOBAL_DATA]: 15,

    [USER_DURABLE_RUNTIME]: 5 * 60, // 用于跟用户相关，但是又不怎么改变的，比如 Facade

    [USER_DATA]: 10,
    [USER_RUNTIME]: 5,  
    [USER_TRANSIENT_DATA]: 1,          
}
function invalidateCache(cache, life, now) {
    for(let key in cache) {
        const entry = cache[key];
        if(entry) {
            const [_, addedAt ] = entry;
            if(now - addedAt > life * 1000) {
                delete cache[key];
            }
        } else {
            delete cache[key];
        }
    }
}

function combineKey(dlc) {
    const { domainKey, tenantCode, sandbox, isDesignMode } = dlc;
    const combinedKey = domainKey + (tenantCode ? "-" + tenantCode : "") +
        (isDesignMode ? "::design" : (sandbox ? "::sandbox" : "")) ;
    return combinedKey;
}

export default class DataCache {

    constructor(data, dlc) {
        // key -> [ value, addedAt ]
        this.data = {
            ...EMPTY_CACHE(),
            ...(data || {})
        }

        this.dlc = dlc;

        this.hash = undefined;

        // 定期刷新
        // http://git.qunfengshe.com/qunfengshe/bwax-app-admin/issues/854
        // 1. Global 和 Global Runtime - 每五分钟 invalidate (要不要自动更新）
        // 2. Global Data - 每十五秒 invalidate（要不要自动更新）
        // 3. User Runtime 和 User Data： 每五秒 invalidate ；任何用户的操作，都会 invalidate 掉它自己的 cache
        // 4. 以上，如果服务器端的 released 版本变化，则全部 invalidate

        // 1. 2. 3 每五秒执行一次的 timer 来完成；
        // 放在最外面，固定执行，见 DATA_CACHES


        // 4. 通过外部的调用来完成。 在 mobile 的 server 中，
        //    每次收到 request 时，主动查询一下 definition 的 hash，并传入 DataCache 以更新
        //    见下面的 updateReleaseVersion
    }

    invalidate() {
        const now = Date.now();
        for(let cacheType in this.data) {
            if([GLOBAL, GLOBAL_RUNTIME, GLOBAL_DATA].indexOf(cacheType) !== -1) {
                // GLOBAL 
                const cache = this.data[cacheType];
                invalidateCache(cache, lifes[cacheType], now);
            } else {
                // USER specific
                const userCaches = this.data[cacheType];
                for(let uid in userCaches) {
                    const cache = userCaches[uid];
                    invalidateCache(cache, lifes[cacheType], now);
                }
            }
        }
    }


    getSerializableContent() {
        const serializableKeys = [GLOBAL, GLOBAL_DATA, USER_DATA, USER_TRANSIENT_DATA ];
        return Object.keys(this.data).reduce((acc, name) => {
            if(serializableKeys.indexOf(name) !== -1) {
                return { [name]: this.data[name], ...acc }
            } else {
                return acc 
            }
        }, {})
    }

    updateReleaseVersion(hash) {
        // 
        if(this.hash !== undefined && this.hash != hash) {
            // hash 产生了变化；
            // 全部变掉
            this.data = {
                ...EMPTY_CACHE()
                // ...(data || {})
            }
        }
        this.hash = hash;
    }


    extractCache(cacheKey) {
        invariant(cacheKey && cacheKey instanceof CacheKey, "Invalid CacheKey: " + cacheKey)

        const { cacheType, dataKey, uid } = cacheKey;
        const cache = this.data[cacheType];
        invariant(cache, "Unsuppport cache type: " + cacheType);

        if(cacheType == GLOBAL || cacheType == GLOBAL_RUNTIME || cacheType == GLOBAL_DATA ) {
            return { cache, dataKey }
        } else {
            // user specific
            if(cache[uid] === undefined) {
                cache[uid] = {}
            }
            return { cache: cache[uid], dataKey }
        }
    }


    // 
    get(cacheKey) {

        const { cache, dataKey } = this.extractCache(cacheKey);
        const v = cache[dataKey] ? cache[dataKey][0] : undefined;

        return v
    }

    set(cacheKey, value) {
        const { cache, dataKey } = this.extractCache(cacheKey);
        cache[dataKey] = [value, Date.now()];
    }

    remove(cacheKey) {
        const { cache, dataKey } = this.extractCache(cacheKey);
        // 还是应该设置一个 invalidate 的标记啥的？
        delete cache[dataKey]
    }

    clearCache(cacheKey) {
        const { cache } = this.extractCache(cacheKey);
        for(let key in cache) {
            delete cache[key]
        }
    }
    
    clearAll() {
        this.data = EMPTY_CACHE();
    }

}

// These are all usr_<sessionToken+deviceId>_data_xxx
export function setupQueryCache( dataCache, dlc ) {

    const buildKey = key => buildCacheKey(USER_DATA, key, dlc);

    return {
        getData: (key) => {
            // if(!appData) {
            //     return undefined;
            // }
            // const d = appData[key];
            // const cacheInvalidatedAt = appData.__cacheInvalidatedAt__ || 0;
            // if(d && typeof(d) == 'object' && d.data && d.loadedAt > cacheInvalidatedAt) {
            //     return d.data
            // } else {
            //     return undefined
            // }

            // TODO 还要不要考虑 cacheInvalidatedAt ?

            const cacheKey = buildKey(key);
            return dataCache.get(cacheKey);

        },

        setData: (key, value) => {
            dataCache.set(buildKey(key), value);

        },
    
        setLoading: (key, promise) => {
            // if(!appData){ 
            //     return 
            // }
            // appData[key] = promise;

            // 似乎没有需要做的

        },

        removeData: (key) => {
            dataCache.remove(buildKey(key));
        },
    
        clearCache: () => {
            dataCache.clearCache(buildKey("DUMMY"));
        }
    }

}


// TODO 从 document 里面取出服务端带来的缓存 - GLOBAL

const DATA_CACHES = {
    // ....
};
export function getInstance (dlc) {
    // 根据 domainKey 和 sandbox 来区分不同的 GLOBAL；
    const combinedKey = combineKey(dlc);

    if(!DATA_CACHES[combinedKey]) {
        // 初始化
        // 从 document 里面取出服务端带来的缓存
        const base = (typeof(document) !== "undefined") ? document.data && document.data.dataCache : {}

        DATA_CACHES[combinedKey] = new DataCache(base, dlc);
    }

    return DATA_CACHES[combinedKey];
}

setInterval(() => {
    Object.values(DATA_CACHES).forEach(c => {
        c.invalidate();
    })
}, 1000)

