

import React, { useEffect, useState, useRef } from 'react'

import ClampedText from 'bwax-ui/components/ClampedText';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { Pressable } from 'bwax-ui/components/Button';

import { useTrack } from 'bwax-ui/track';

import ScrollHelper from 'bwax-ui/ScrollHelper';

// import { ModalTrigger } from 'bwax-ui/components/Modal';
// import Modal from 'bwax-ui/components/Modal';

// 不知道为什么这里 import 了就会导致 modal 定位有问题
// 我怀疑是因为 Layout_inbot 把这个 NotificationNav 分别用在 drawer 和原来布局的位置有关。

// 依赖于 Entity - "消息"
export default function NotificationNav({ className, label, icon, collapsedMode, facade, currentUserId, ModalTrigger }) {

    const track = useTrack();

    const [notifcations, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState();

    const [hasMore, setHasMore] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);

    const fieldPaths = ["标题", "内容", "已读", "创建时间"];

    const entityName = "消息";
    async function loadNotificaitons(offset = 0) {

        setLoadingMore(true);

        const [result, error] = await facade.listAll({
            entityName,
            condition: [[{ field: "接收者.id", op: "eq", value: currentUserId }]],
            sort: [{ field: "创建时间", order: "DESC" }],
            pageSize: 8,
            offset,
            fieldPaths,
        });
        setLoadingMore(false);
        if (!error) {
            if (result) {
                const hasMore = result.pageSize + offset < result.count;
                setHasMore(hasMore);
                setNotifications(prev => {
                    // 要排除掉已经存在的，然后在 append 到后面
                    const existing = prev || [];
                    const toAppend = result.data.filter(s => !existing.some(e => e.id === s.id));
                    return [...existing, ...toAppend];
                })
            }
        }
    }

    const loadMore = () => {
        if (hasMore) {
            loadNotificaitons(notifcations.length);
        }
    }

    const listRef = useRef();
    const [listRefState, setListRefState] = useState();

    const loadMoreRef = useRef();
    loadMoreRef.current = loadMore;

    useEffect(() => {
        if (listRef.current) {
            const scrollHelper = new ScrollHelper(listRef.current, {
                onScrolledToBottom: _ => {
                    loadMoreRef.current && loadMoreRef.current();
                }
            });
            return () => {
                scrollHelper.cleanUp();
            }
        }
    }, [listRefState]);



    async function loadUnreadCount() {
        const [result, error] = await facade.count({
            entityName,
            condition: [[{ field: "接收者.id", op: "eq", value: currentUserId }, { field: "已读", op: "ne", value: true }]],
        });
        if (!error) {
            setUnreadCount(result);
        }
    }

    useEffect(() => {
        // 
        loadNotificaitons();
        loadUnreadCount();

    }, []);

    async function markRead(id) {
        const [result, error] = await facade.customMutation({
            entityName,
            interfaceName: "标记为已读",
            id,
            outputFieldPaths: [
                [entityName, fieldPaths]
            ]
        });

        if (!error) {
            setNotifications(prev => (
                prev.map(n => n.id == id ? result : n)
            ));
            loadUnreadCount();
        }
        return
    }

    function renderNotification(notification, index) {
        const length = notifcations.length;
        const isUnread = !notification.已读;

        const inner = (
            <div className={
                classNames("pr-4 pl-6 py-3 gap-2 flex flex-col rounded", {
                    "cursor-pointer": isUnread
                    // "hover:bg-[var(--gray3)]": isUnread
                })
            }>
                <div className="font-size-15 font-medium relative">
                    {notification.标题}
                    {isUnread ? <div className="absolute rounded-[50%] bg-[var(--tomato9)] h-1.5 w-1.5 left-[-1em] top-[0.6em]" /> : null}
                </div>
                <div className="text-[var(--gray11)] flex flex-col gap-1.5">
                    <div className="whitespace-pre-line">{notification.内容}</div>
                    <div className="font-size-11 opacity-70">{dayjs(notification.创建时间).format("MM-DD HH:mm")}</div>
                </div>
            </div>
        )

        return (
            <div key={notification.id} className={
                classNames("py-1.5", {
                    "border-b": index < length - 1,
                })
            }>
                {isUnread ? (
                    <Pressable onPress={_ => {
                        track("notification_mark_read");
                        markRead(notification.id);
                    }}>
                        {inner}
                    </Pressable>
                ) : inner
                }
            </div>

        )
    }

    function renderNotificaitonList() {

        const emptyState = (
            <div className="px-4 py-4 h-72 flex flex-col items-center justify-center gap-4">
                <i className='bx bxs-paper-plane text-6xl text-[var(--gray7)]'></i>
                <span className="text-[var(--gray9)]">没有消息</span>
            </div>
        )

        return (
            <div className="px-4 pb-6 flex flex-col h-full">
                {notifcations && notifcations.length == 0 ? emptyState : null}
                {notifcations && notifcations.length > 0 ? notifcations.map(renderNotification) : null}
                {loadingMore ? (
                    Array(3).fill(0).map((_, index) => {
                        return (
                            <div key={"l" + index} className="rounded h-[90px] min-h-[90px] bg-[var(--gray2)] my-2 mx-4">
                            </div>
                        )
                    })
                ) : null}
            </div>
        )
    }

    const inner = (
        <div className={classNames("flex gap-2 items-center w-full py-2 hover:bg-[var(--sand4)] rounded", {
            "justify-center": collapsedMode,
            "px-4": !collapsedMode,
            // "bg-[var(--sand3)] font-medium": isActive
        })}>
            <div className='flex w-8 flex justify-center relative'>
                {icon}
                {unreadCount > 0 ?
                    <div className="absolute rounded-[50%] bg-[var(--tomato9)] h-1.5 w-1.5 right-[2px] top-[-1px]" /> : null
                }
            </div>
            {collapsedMode ? null :
                (
                    <div className="flex items-center grow">
                    <ClampedText className="title" tipClassName="lc-inbot-tooltip">
                        {label}
                    </ClampedText>
                    </div>

                )
            }
        </div>
    )

    if (!notifcations) {
        return inner;
    }

    return (
        <ModalTrigger onBeforePress={_ => {
            track("layout_click_nav", { nav: "notifications" })
        }} content={close => renderNotificaitonList()} modalClassName={"sm:max-w-lg"} contentRef={r => {
            listRef.current = r;
            if (r && r !== listRefState) {
                setListRefState(r)
            }
        }}>
            {inner}
        </ModalTrigger>
    )
}
