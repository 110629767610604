import React, { useContext, useState, useRef } from 'react'

import AiInputUI from './AiInputUI'

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext';

import { executePrompt } from 'bwax-ui/openai/OpenAIClient';
import cutText from 'bwax-ui/openai/cutText';
import countToken from 'bwax-ui/openai/countToken';
import { trimLanguageSign } from './AiInputMenu';

import UsageQuotaContext from 'bwax-ui/ml/widget/ports/inbot/UsageQuotaContext';

export default function AIInputStreamImpl(props) {

    const { options = {} } = props;
    const { knowledgeOptions = {} } = options;

    const { remainingQuota, reloadUsageQuota } = useContext(UsageQuotaContext) || {};

    const dlc = useContext(DataLoaderContext);

    const [isResponding, setIsResponding] = useState(false);
    const [respondingText, setRespondingText] = useState("");

    const [respondingStepMsg, setRespondingStepMsg] = useState("")

    const respondingTextRef = useRef();
    respondingTextRef.current = respondingText;
    
    const transformRespondingTextRef = useRef();

    const stopRef = useRef();
    
    async function startGenerate({ operation, userPrompt, contents, triggeringCommand }) {
        setIsResponding(true);

        function onData(s, isEnd) {
            const newResponseText = respondingTextRef.current + s;

            if (isEnd) {

                setRespondingText(newResponseText);
                setRespondingStepMsg("");

                setTimeout(() => {
                    setIsResponding(false);
                    setRespondingText('')


                    if (reloadUsageQuota) {
                        setTimeout(() => {
                            reloadUsageQuota();
                        }, 800)
                    }

                }, 16)


            } else {
                setRespondingText(newResponseText);
                respondingTextRef.current = newResponseText;
            }
        }

        function onStep(stepStatus) {
            setRespondingStepMsg(stepStatus.msg);

        }

        transformRespondingTextRef.current = await generateContent({  
            operation, userPrompt, triggeringCommand, contents, 
            onData, onStep, dlc, bindStopper: stop => stopRef.current = stop,
            knowledgeOptions,
        })
    }

    function resolveRespondingText() {
        return transformRespondingTextRef.current && respondingText ? transformRespondingTextRef.current(respondingText) : respondingText
    }

    return (
        <AiInputUI {...props} isResponding={isResponding} 
            respondingText={resolveRespondingText()} respondingStepMsg={respondingStepMsg}
            startGenerate={startGenerate}
            stopGeneration={_ => {
                if(stopRef.current){
                    stopRef.current()
                }
            }}
            {...{ remainingQuota, reloadUsageQuota }}
        />
    )
}



async function generateContent({ operation, userPrompt, contents: rawContents, triggeringCommand, onData, onStep, dlc, bindStopper, knowledgeOptions  }) {
    // 

    const { selectedContent, precedingContent : rawPrecedingContent, previousRespondingText, allContent } = rawContents;


    async function buildPromptInput(userPrompt, operation) {
        if(!operation) {
            if(!userPrompt) {
                return
            }
            if(selectedContent) {
                return {
                    promptTemplateName: "处理文字",
                    promptVariables: {
                        "原文": selectedContent || "无",
                        "提示": userPrompt,
                    }
                }

            } else {

                if(rawPrecedingContent) {
                    const precedingContent = await cutText({ text: rawPrecedingContent, dlc, tokenNum: 2000 });
                    return {
                        promptTemplateName: "续写",
                        promptVariables: {
                            "前文": precedingContent || "无",
                            "提示": userPrompt,
                        }
                    }
                } else {
                    return {
                        promptTemplateName: "开始写",
                        promptVariables: {
                            "提示": userPrompt,
                        }
                    }

                }


            }

        } else {

            const { 
                name, prompt, using, isRewrite, knowledgeOff = false, isRetryForRelacement, buildTemplateInput, transformRespondingText,

                expectedOutputTokenNum, expectedOutputTokenRatio,

                useFullPrecedingContent,
            
            } = operation;

            const precedingContent = useFullPrecedingContent ? rawPrecedingContent : (await cutText({ text: rawPrecedingContent, dlc, tokenNum: 2000 }));
            const contents = { ...rawContents, precedingContent };

            const maxTokenNum = await (async () => {
                if(expectedOutputTokenNum) {
                    return expectedOutputTokenNum;
                } else if(expectedOutputTokenRatio && selectedContent) {
                    const selectedNum = await countToken({ text: selectedContent, dlc });
                    return selectedNum ? Math.round(selectedNum * expectedOutputTokenRatio) : undefined;
                } 
                // udnefined
                return undefined
            })(); 



            if(name == "try-again") {
                // 用最开始的 command
                if(!triggeringCommand) {
                    return 
                }
                const { userPrompt, operation: op } =  triggeringCommand;
                if(op && op.name == "try-again") {
                    // 避免死循环，一般不会来这里
                    return
                }
                return buildPromptInput(userPrompt, op);

            }

            if(buildTemplateInput) {
                // build input for specific template:
                return [
                    buildTemplateInput({ previousRespondingText, selectedContent, precedingContent, allContent  }),
                    knowledgeOff,
                    transformRespondingText,
                    maxTokenNum
                ]
            }

            const actualPrompt = userPrompt || prompt;
            if(!actualPrompt) {
                return 
            }

            if(isRewrite) {
                const givenText = contents[using] || (isRetryForRelacement ? previousRespondingText : selectedContent);
                return [
                    {
                        promptTemplateName: "改写",
                        promptVariables: {
                            "标题": "无",
                            "原文": givenText || "无",
                            "提示": actualPrompt,
                        }
                    },
                    knowledgeOff,
                    transformRespondingText,
                    maxTokenNum,
                ]

            } else {
                const previousText = contents[using] || (isRetryForRelacement ? previousRespondingText : precedingContent);
                return [
                    {
                        promptTemplateName: "续写",
                        promptVariables: {
                            "标题": "无",
                            "前文": previousText || "无",
                            "提示": actualPrompt,
                        }
                    },
                    knowledgeOff,
                    transformRespondingText,
                    maxTokenNum,
                ]
                
            }


        }

    }

    const promptInputResult = await buildPromptInput(userPrompt, operation);

    const [ promptInput, knowledgeOff, transformRespondingText, maxTokenNum ] = (() => {
        if(promptInputResult) {
            if(Array.isArray(promptInputResult)) {
                return promptInputResult
            } else {
                return [ promptInputResult, false ]
            }
        }
        return []
    })();


    if(promptInput) {
        executePrompt({
            onData, dlc, onStep, bindStopper, 
            knowledgeOptions: knowledgeOff ? { isEnabled: false} : knowledgeOptions,
            maxTokenNum,
            ...promptInput
    
        })
    } else {
        setTimeout(() => {
            onData("<不知道的命令>", true)
        }, 200)
    }

    return transformRespondingText || trimLanguageSign;

}



