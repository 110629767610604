// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ClientEnv = require("bwax/clientEnv");
var Json$BwaxMobile = require("../../../bwax-js/ml/utils/json.bs.js");
var Plate$BwaxMobile = require("../../../bwax-js/ml/plate.bs.js");
var WxApi$BwaxMobile = require("../../re/legacy/binding/WxApi.bs.js");
var Backend$BwaxMobile = require("../../../bwax-js/ml/backend.bs.js");
var DomUtils$BwaxMobile = require("../../re/utils/DomUtils.bs.js");
var UrlQuery$BwaxMobile = require("../../re/legacy/binding/UrlQuery.bs.js");

var qWxMpJsConfig = "\n  query ($jsApiList: [String]!, $url: String!) {\n    wxMpJsConfig( jsApiList: $jsApiList, url: $url)\n  }\n";

function queryWxMpJsConfig(query_runner, url, jsApiList) {
  return Curry._3(query_runner, qWxMpJsConfig, Js_dict.fromList(/* :: */[
                    /* tuple */[
                      "url",
                      url
                    ],
                    /* :: */[
                      /* tuple */[
                        "jsApiList",
                        jsApiList
                      ],
                      /* [] */0
                    ]
                  ]), undefined).then((function (text) {
                var result = Json$BwaxMobile.parse(text);
                var partial_arg = /* :: */[
                  "data",
                  /* :: */[
                    "wxMpJsConfig",
                    /* [] */0
                  ]
                ];
                return Promise.resolve(Plate$BwaxMobile.$$Option.and_then(Js_json.decodeObject, Plate$BwaxMobile.$$Option.and_then((function (param) {
                                      return Json$BwaxMobile.get_by_path(partial_arg, param);
                                    }), result)));
              }));
}

var mut_createWxOrder = "\n  mutation ($input: CreateWxOrderInput!) {\n    createWxOrder(input: $input) {\n      wxOrder {\n        codeUrl\n        mwebUrl\n        jsPayArgs\n      }\n    }\n  }\n";

function createWxOrder(query_runner, orderId, tradeType) {
  var handle_result = function (text) {
    var result = Json$BwaxMobile.parse(text);
    var partial_arg = /* :: */[
      "data",
      /* :: */[
        "createWxOrder",
        /* :: */[
          "wxOrder",
          /* :: */[
            "codeUrl",
            /* [] */0
          ]
        ]
      ]
    ];
    var codeUrl = Plate$BwaxMobile.$$Option.and_then(Js_json.decodeString, Plate$BwaxMobile.$$Option.and_then((function (param) {
                return Json$BwaxMobile.get_by_path(partial_arg, param);
              }), result));
    var partial_arg$1 = /* :: */[
      "data",
      /* :: */[
        "createWxOrder",
        /* :: */[
          "wxOrder",
          /* :: */[
            "mwebUrl",
            /* [] */0
          ]
        ]
      ]
    ];
    var mwebUrl = Plate$BwaxMobile.$$Option.and_then(Js_json.decodeString, Plate$BwaxMobile.$$Option.and_then((function (param) {
                return Json$BwaxMobile.get_by_path(partial_arg$1, param);
              }), result));
    var partial_arg$2 = /* :: */[
      "data",
      /* :: */[
        "createWxOrder",
        /* :: */[
          "wxOrder",
          /* :: */[
            "jsPayArgs",
            /* [] */0
          ]
        ]
      ]
    ];
    var jsPayArgs = Plate$BwaxMobile.$$Option.and_then((function (param) {
            return Json$BwaxMobile.get_by_path(partial_arg$2, param);
          }), result);
    return Promise.resolve(/* record */[
                /* codeUrl */codeUrl,
                /* mwebUrl */mwebUrl,
                /* jsPayArgs */jsPayArgs
              ]);
  };
  return Curry._3(query_runner, mut_createWxOrder, Js_dict.fromList(/* :: */[
                    /* tuple */[
                      "input",
                      {
                        clientMutationId: new Date().getTime().toString(),
                        orderId: orderId,
                        tradeType: tradeType
                      }
                    ],
                    /* [] */0
                  ]), undefined).then(handle_result);
}

function initiate_pay(query_runner, orderId, onPayInitiated, onError) {
  var handle_error = function (error) {
    Backend$BwaxMobile.add_log(query_runner, /* Error */3, /* :: */[
          error,
          /* :: */[
            orderId,
            /* [] */0
          ]
        ]);
    return Curry._1(onError, error);
  };
  if (ClientEnv.isWeChat() && ClientEnv.isMobile()) {
    WxApi$BwaxMobile.ready((function (param) {
            createWxOrder(query_runner, orderId, "JSAPI").then((function (param) {
                    var jsPayArgs = param[/* jsPayArgs */2];
                    if (jsPayArgs !== undefined) {
                      WxApi$BwaxMobile.chooseWXPay(Caml_option.valFromOption(jsPayArgs), (function (param) {
                              return /* () */0;
                            }));
                    } else {
                      handle_error("微信支付: 未能获取支付参数");
                    }
                    return Promise.resolve(/* () */0);
                  }));
            return /* () */0;
          }));
    WxApi$BwaxMobile.error((function (param) {
            return handle_error("微信 API 初始化失败\"");
          }));
    if (UrlQuery$BwaxMobile.href !== undefined) {
      queryWxMpJsConfig(query_runner, UrlQuery$BwaxMobile.href, /* array */["chooseWXPay"]).then((function (c) {
              if (c !== undefined) {
                Curry._1(onPayInitiated, undefined);
                WxApi$BwaxMobile.config(Caml_option.valFromOption(c), false);
              } else {
                handle_error("微信 API 配置失败: 未能获取配置信息");
              }
              return Promise.resolve(/* () */0);
            }));
      return /* () */0;
    } else {
      return handle_error("微信 API 配置失败: 未获取当前地址");
    }
  } else if (ClientEnv.isMobile()) {
    createWxOrder(query_runner, orderId, "MWEB").then((function (param) {
            var mwebUrl = param[/* mwebUrl */1];
            if (DomUtils$BwaxMobile.$$window !== undefined) {
              if (mwebUrl !== undefined) {
                Curry._1(onPayInitiated, undefined);
                var tmp = Caml_option.valFromOption(DomUtils$BwaxMobile.$$window).location;
                tmp.href = mwebUrl;
              } else {
                handle_error("微信支付: 未能获取支付 mweb 地址");
              }
            }
            return Promise.resolve(/* () */0);
          }));
    return /* () */0;
  } else {
    createWxOrder(query_runner, orderId, "NATIVE").then((function (param) {
            var codeUrl = param[/* codeUrl */0];
            if (codeUrl !== undefined) {
              Curry._1(onPayInitiated, codeUrl);
            } else {
              handle_error("微信支付: 未能获取支付二维码");
            }
            return Promise.resolve(/* () */0);
          }));
    return /* () */0;
  }
}

exports.qWxMpJsConfig = qWxMpJsConfig;
exports.queryWxMpJsConfig = queryWxMpJsConfig;
exports.mut_createWxOrder = mut_createWxOrder;
exports.createWxOrder = createWxOrder;
exports.initiate_pay = initiate_pay;
/* bwax/clientEnv Not a pure module */
