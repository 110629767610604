
import React, { useMemo, useState, useCallback, useEffect, useRef } from 'react'

// Import the Slate editor factory.
import { createEditor, Transforms, Range, Editor, Node, Text, Element as SlateElement, Path } from 'slate'

import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import useDebounce from 'bwax-ui/legacy/page/hooks/useDebounce'

import { getElement, isNotSupportElement } from './elements';

import './SlateEditor.less'

import InlineToolBar from './InlineToolBar'
import AIInputStream from './AIInput/AIInputStream'

// Import the Slate components and React plugin.
import { Slate, Editable, withReact, ReactEditor } from 'slate-react'
import { withHistory, HistoryEditor } from 'slate-history'
import handleKeydown, { getCurrentBlock } from './handleKeydown';
import { cleanSlateValue } from '../traverseSlateValue';
import { isMobile } from 'bwax/clientEnv'
import BrickToolbar from './BrickToolbar/BrickToolbar'
import Modal from 'bwax-ui/components/Modal'
import { liftMediaNodes } from 'bwax-ui/markdown/convertMarkdown'
import insertDataF from './insertDataF'

import { useTrack } from 'bwax-ui/track';
import { toggleBlockType } from './buttons/util/createBlockTypeButton'
import HyperlinkInput from './components/HyperlinkInput'

import classNames from 'classnames';

const topButtons = ["h2", "h3", "block-quote",
    "code",
    "numbered-list", "bulleted-list",
    "hr",
    'seperator', "image", "video", "audio", 'seperator', "undo", "redo"
]

const defaultInlineButtons = [
    "bold", "italic", "underline", "sup", "sub", "color", "seperator",
    "align-left", "align-center", "align-right", "align-justify", "seperator", "format-clear", "format-brush", "link"
]

const markdownShortCuts = {
    '#': 'h1',
    '##': 'h2',
    '###': 'h3',
    '####': 'h4',
    '#####': 'h5',
    '*': 'bulleted-list',
    '-': 'bulleted-list',
    '+': 'bulleted-list',
    '>': 'block-quote',
}

export const blockTypes = ["h1", "h2", "h3", "h4", "h5", "h6", "block-quote", "numbered-list", "bulleted-list"]
export function isList (blockType) {
    const listTypes = ["numbered-list", "bulleted-list"]

    return listTypes.includes(blockType)
}

export function isBlockType (editor) {
    if(editor.selection) {
        const [ancestorNode, ancestorPath] = Editor.node(editor, editor.selection.focus.path.slice(0, 1))
        return [...blockTypes, 'list-item'].some(t => t === ancestorNode.type)
    }

    return false
    
}

const withMediaAndInline = editor => {

    const { isVoid, isInline } = editor 
    editor.isVoid = element => {
        const voidNodes = ["image", "video", "audio", "hr"]
        // console.log("element: ", element);
        return element.type && (voidNodes.some(t => element.type === t) || isNotSupportElement(element)) ? true : 
            isVoid(element)
    }

    editor.isInline = element => {
        return (element.type === 'link') || isInline(element)
    }
    
    return editor
}

export default function SlateEditor({
    value, uploadImage, uploadVideo, onChange, style, className, aiEnabled, aiOptions, buttonComponents = {},
    noToolbar = false,
    placeholder,
    scrollContainerRef,
    bindFocus = _ => { },

    showBrickToolbar = false,    

    version,
    customBlockPlaceholder, // 如果没有传入，则不显示 block placeholder
    showInlineBlockButton = false,
    showColorButton = false
}) {

    const track = useTrack();

    const [editing, setEditing] = useState(initSlateValue(value) || [
        {
            type: 'paragraph',
            children: [{ text: '' }],
        },
    ])

    const brickDOMRefs = useRef([])

    const brickDOMTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'figure', 'blockquote']
    const querySelectorAllStr = brickDOMTags.reduce((acc, t) => `${acc}, ${t}[data-slate-node='element']`, "h1[data-slate-node='element']")
    const editAreaPaddingTop = 8

    const debouncedEditing = useDebounce(editing, 1000);

    function updateBrickDOMRefs () {
        if(showBrickToolbar && editAreaContainerRef.current && typeof(window) !== 'undefined') {
            setTimeout(() => {
                // setTimeout 是为了避免一开始获取到的 video 高度不正确
                const brickDOMElements = editAreaContainerRef.current.querySelectorAll(querySelectorAllStr)

                const brickDOMElementsObj = Array.from(brickDOMElements).reduce((acc, element) => {
                    const offsetTop = element.getBoundingClientRect().top - editAreaContainerRef.current.getBoundingClientRect().top
                    const currentElementObj = {
                        element, 
                        position: {
                            offsetTop: offsetTop - editAreaPaddingTop,
                            height: element.offsetHeight
                        }
                    }
                    return [...acc, currentElementObj ]
                }, []);
                brickDOMRefs.current = brickDOMElementsObj
            }, 4)
        }
    }

    useEffect(() => {
        updateBrickDOMRefs()
    }, [debouncedEditing])

    const withMarkdownAndPaste = editor => {
        const { insertText } = editor
    
        editor.insertText = text => {
    
            const { selection } = editor
    
            if(selection && Range.isCollapsed(selection)) {
                const { anchor, focus } = selection
                const block = Editor.above(editor, {
                    match: n => SlateElement.isElement(n) && Editor.isBlock(editor, n),
                })
                const path = block ? block[1] : []
                const start = Editor.start(editor, path)
                const range = { anchor, focus: start }
                const beforeText = Editor.string(editor, range) + text.slice(0, -1)
                const [parentNode, parentPath] = Editor.parent(editor, focus.path)
                if((beforeText === '' && markdownShortCuts[text]) || text.match(/\*|_/)) {
                    Transforms.insertText(editor, text)
                } else if (parentNode.type === 'link' && Editor.isEnd(editor, focus, parentPath)) {
                    // 在 link 的末尾输入的内容不再保持 link 格式（内容插入到下一个文本节点中）
                    insertText(text) // 先插入内容，不然会额外 delete 掉链接中的字符
                    Transforms.delete(editor, { at: { anchor: focus, focus: editor.selection.focus } })
                    Transforms.move(editor, { unit: 'offset' })
                    insertText(text)
                } else {
                    const [currentBlockNode, _] = getCurrentBlock(editor, 
                        parentNode.type === 'link' ? Path.parent(parentPath) : parentPath
                    )

                    if(currentBlockNode.type === 'block-quote') {
                        // 如果在 block-quote 中输入，则不转换 markdown，直接输入
                        insertText(text)
                    } else {
                        const beforeTextInCurrentElement = Editor.string(editor, { ...selection, anchor: { ...anchor, offset: 0 }})
                        const matchBoldStarResult = beforeTextInCurrentElement.match(/\*\*/g)
                        const matchBoldUnderlineResult = beforeTextInCurrentElement.match(/__/g)
        
                        function insertAndDeleteBeforeText () {
                            HistoryEditor.withoutMerging(editor, () => {
                                insertText(text)
                                // Transforms.select(editor, { anchor: editor.selection.anchor, focus: start })
        
                                if (!Range.isCollapsed(range)) {
                                    Transforms.delete(editor, { at: { anchor: editor.selection.anchor, focus: start } })
                                }
                            })
                        }

                        if(text === ' ') {
                            const blockType = beforeText.match(/^\d+\.$/) ? 'numbered-list' : markdownShortCuts[beforeText]
        
                            function changeBlockType () {
                                insertAndDeleteBeforeText()
                                
                                toggleBlockType(editor, blockType)
                                updateBrickDOMRefs()
                            }
        
                            if (blockType && blockType !== currentBlockNode.type && !(blockType === 'numbered-list' && currentBlockNode.type.match(/^h\d$/))) {
                                const prevBlock = Editor.previous(editor, { at: focus.path.slice(0, 1) });
                                if(blockType === 'numbered-list' && prevBlock && prevBlock[0].type === 'numbered-list') {
                                    const prevListLength = prevBlock[0].children.length
                                    const beforeTextMatchResult = beforeText.match(/^(\d+)\.$/)
                                    if(beforeTextMatchResult && parseInt(beforeTextMatchResult[1]) === prevListLength + 1) {
                                        changeBlockType()
                                    } else {
                                        insertText(text)
                                    }
                                } else {
                                    changeBlockType()
                                }
                            } else if ((matchBoldStarResult && matchBoldStarResult.length >= 2 && beforeTextInCurrentElement.endsWith('**')) ||
                                (matchBoldUnderlineResult && matchBoldUnderlineResult.length >= 2 && beforeTextInCurrentElement.endsWith('__'))
                            ) {
                                // 只匹配和转换最后两对 bold shortcut 之间的内容
                                const allMatchedShortcut = beforeTextInCurrentElement.endsWith('**') ? [...beforeTextInCurrentElement.matchAll(/\*\*/g)] : 
                                    [...beforeTextInCurrentElement.matchAll(/__/g)]
                                
                                const prefixShortcut = allMatchedShortcut[allMatchedShortcut.length - 2]
                                const suffixShortcut = allMatchedShortcut[allMatchedShortcut.length - 1]
        
                                const boldRange = { anchor: { ...anchor, offset: prefixShortcut.index + 2 }, focus: {...focus, offset: suffixShortcut.index } }
        
                                const strInBoldRange = Editor.string(editor, boldRange)
                                const strArray = Array.from(strInBoldRange)
                                const strSameAsShortcut = beforeTextInCurrentElement.endsWith('**') ? strArray.every(s => s === '*') : strArray.every(s => s === '_')
                                if(strArray.length > 0 && !(strInBoldRange.startsWith(' ') || strInBoldRange.endsWith(' ') || strSameAsShortcut)) {
                                    // 首尾没有空格，且内容与 shortcut 不一致，才转换
        
                                    // 删除后缀
                                    Transforms.delete(editor, { at: { anchor: { ...anchor, offset: suffixShortcut.index }, focus: { ...focus, offset: suffixShortcut.index + 2 } }})
                                    // 删除后缀
                                    Transforms.delete(editor, { at: { anchor: { ...anchor, offset: prefixShortcut.index }, focus: { ...focus, offset: prefixShortcut.index + 2 } }})
        
                                    function isBold(location) {
                                        const [match] = Editor.nodes(editor, {
                                            at: location,
                                            match: n => n.bold === true,
                                        })
                                
                                        return !!match
                                    }
            
                                    const boldRangeAfterDelete = { anchor: { ...anchor, offset: prefixShortcut.index }, focus: {...focus, offset: suffixShortcut.index - 2 } }
                                    Transforms.setNodes(editor,
                                        { bold: isBold(boldRangeAfterDelete) ? null : true },
                                        { at: boldRangeAfterDelete, match: Text.isText, split: true }
                                    )
                                } else {
                                    insertText(text)
                                }
                            } else {
                                insertText(text)
                            }
        
                        } else if (text === '-') {
                            
                            // 后面那个不是单个短横线，是 iOS 输入法输入两个短横线之后格式化成的破折号
                            if(beforeText === '--' || beforeText === '—') {
                                insertAndDeleteBeforeText()
        
                                function insertHr (position) {
                                    Transforms.insertNodes(editor, 
                                        { type: 'hr', children: [{ text: "" }] }, 
                                        { at: position}
                                    )
                                }
                                
                                const { type } = currentBlockNode
                                if(type.match(/^h\d$/) || type === 'block-quote') {
                                    Transforms.setNodes(editor, { type: 'paragraph'})
                                } else if (isList(type)) {
                                    toggleBlockType(editor, type)
                                }
        
                                insertHr(editor.selection.focus.path.slice(0, 1))
                                updateBrickDOMRefs()
        
                            } else {
                                insertText(text)
                            }
                            
                        } else {
                            insertText(text)
                        }
                    }
                    
                }
            } else {
                insertText(text)
            }
        }

        insertDataF(editor, uploadImage)
    
        return editor
    }

    const editor = useMemo(() => withMarkdownAndPaste(withMediaAndInline(withReact(withHistory(createEditor())))), [])
    function initSlateValue (slateValue) {
        const convertedLineSeparatorValue = slateValue.map((node) => {
            if(Node.string(node) === '\n') {
                // 把第一级 paragraph 中的 \n 换成空字符串
                return {
                    ...node,
                    children: [{ text: '' }]
                }
            } else return node
        })

        const liftedMediaValue = convertedLineSeparatorValue.reduce((acc, node) => {
            return [...acc, ...liftMediaNodes(node)]
        }, [])

        return liftedMediaValue
    }

    if (bindFocus) {
        bindFocus(() => {
            if (!ReactEditor.isFocused(editor)) {
                ReactEditor.focus(editor);
                if(editor.selection) {
                    // 如果有 selection，则恢复光标位置，否则把光标置于 editor 开始处
                    Transforms.select(editor, editor.selection)
                } else {
                    Transforms.select(editor, Editor.start(editor, []))
                }
            }
        })
    }

    const inlineButtonsWithBlockButton = showInlineBlockButton ? ["blockType", "seperator", ...defaultInlineButtons] : defaultInlineButtons
    const inlineButtons = aiEnabled ? ['ai', 'seperator', ...inlineButtonsWithBlockButton] : inlineButtonsWithBlockButton
    const [supplementClassNames, setSupplementClassNames] = useState([])
    const [selectedNode, setSelectedNode] = useState(null)
    const [toolbarButtons, setToolbarButtons] = useState(inlineButtons)
    const [keepInlineToolbar, setKeepInlineToolbar] = useState(false)
    const [blockquotes, setBlockquotes] = useState([])
    const [aiInputInfo, setAiInputInfo] = useState({ show: false }) // action: activeBySpace, activeBySelect, replace, finishContinueWriting
    const [isCompositionEditing, setIsCompositionEditing] = useState(false) // 监听拼音输入
    const [isFocused, setIsFocused] = useState(ReactEditor.isFocused(editor))
    const [mobileToolbarBottom, setMobileToolbarBottom] = useState(0)
    const [brickToolbarInfo, setBrickToolbarInfo] = useState({ show: false })
    const [highlightRange, setHighlightRange] = useState(null)
    const [linkInputModalInfo, setLinkInputModalInfo] = useState({ open: false })
    const [addingBlockType, setAddingBlockType] = useState(null)
    const [captionInputVisible, setCaptionInputVisible] = useState(false)
    const [isLinkEditing, setIsLinkEditing] = useState(false)
    const [isEditFromLinkComp, setIsEditFromLinkComp] = useState(false)

    const editAreaContainerRef = useRef();
    const mobileToolbarRef = useRef()

    const scrollContainerTopRef = useRef(0)
    const brickToolbarInfoRef = useRef();
    brickToolbarInfoRef.current = brickToolbarInfo;

    useEffect(() => {
        if(isMobile()) {
            if (scrollContainerRef && scrollContainerRef.current) {
                scrollContainerTopRef.current = scrollContainerRef.current.getBoundingClientRect().top
            }
            if (typeof (window) !== 'undefined' && window.visualViewport) {
                setTimeout(() => {
                    setMobileToolbarBottom(window.innerHeight - window.visualViewport.height)
                }, 160)
                
    
                function handleScroll() {
                    if (mobileToolbarRef.current && mobileToolbarRef.current.style['position'] !== 'absolute') {
                        setMobileToolbarBottom(window.innerHeight - window.visualViewport.height)
                    }
                }
    
                function handleResize() {
                    if (window.visualViewport.height < window.innerHeight) {
                        const domSelection = window.getSelection()
                        const domRange = domSelection.getRangeAt(0)
                        const rect = domRange.getBoundingClientRect()
    
                        if (mobileToolbarRef.current && rect.top > window.visualViewport.height - mobileToolbarRef.current.offsetHeight) {
                            // focus 的地方靠近键盘，会被 toolbar 挡住，此时需要 window 额外滚下一段距离
                            const originalPageYOffset = window.pageYOffset
                            window.scrollTo({ top: originalPageYOffset + mobileToolbarRef.current.offsetHeight })
                        }
                    }
                }
    
                window.visualViewport.addEventListener('scroll', handleScroll)
                window.visualViewport.addEventListener('resize', handleResize)
                if (scrollContainerRef && scrollContainerRef.current) {
                    scrollContainerRef.current.addEventListener('scroll', handleScroll)
                }
    
                return () => {
                    window.visualViewport.removeEventListener('scroll', handleScroll)
                    window.visualViewport.removeEventListener('resize', handleResize)
                    if (scrollContainerRef && scrollContainerRef.current) {
                        scrollContainerRef.current.removeEventListener('scroll', handleScroll)
                    }
                }
            }
        }
        
    }, [])

    useEffect(() => {
        if (aiInputInfo.show && editAreaContainerRef.current && mobileToolbarRef.current && scrollContainerRef && editor.selection) {
            setTimeout(() => {
                editAreaContainerRef.current.style.paddingBottom = `calc(1.5rem + ${mobileToolbarRef.current.offsetHeight}px)`

            }, 16)
        }

    }, [aiInputInfo.action])

    useEffect(() => {
        setBlockquotes(editor.children.filter(n => n.type === 'block-quote'))
    }, [])

    function collectContinuousBulletedList(value, startIndex) {
        let offset = -1
        let convertedFirstBulletedList = { type: "bulleted-list", children: [] }

        for (let index = startIndex; index < value.length; index++) {
            const currentNode = value[index]
            if (currentNode.type === "bulleted-list") {
                convertedFirstBulletedList = {
                    ...convertedFirstBulletedList,
                    children: [...convertedFirstBulletedList.children, ...currentNode.children]
                }
                offset += 1
            } else {
                break
            }

        }

        return {
            convertedFirstBulletedList,
            offset
        }
    }

    function reviseValue(editedValue) {
        /**
         * 这里做一些结构上的整理，比如：
         * 1、合并连续的 bulleted-list
         * 2、删除一些冗余结构，例如复制过来的空文本及样式，以及从 css 属性中抽取编辑器中可编辑的样式(eg: bold)。
         */

        // merge continuous bulleted-list
        const mergedContinuousBulletedListValue = []
        for (let index = 0; index < editedValue.length; index++) {
            const currentValue = editedValue[index];
            if (currentValue.type !== "bulleted-list" || index === editedValue.length - 1) {
                mergedContinuousBulletedListValue.push(currentValue)
            } else if (currentValue.type === "bulleted-list" && editedValue[index + 1].type === "bulleted-list") {
                // 有连续的 bulleted-list
                const { convertedFirstBulletedList, offset } = collectContinuousBulletedList(editedValue, index)
                mergedContinuousBulletedListValue.push(convertedFirstBulletedList)
                index += offset
            } else {
                mergedContinuousBulletedListValue.push(currentValue)
            }
        }

        const editableInlineStyles = ["color", "fontWeight", "textDecoration", "fontStyle"]

        function refactTextStyle(value) {
            const settledStyleChildren = value.children.map(child => {
                if (child.text === "") {
                    // 如果是文本节点，且文本内容为空，删除所有样式
                    return {
                        text: child.text
                    }
                } else if (child.css && Object.keys(child.css).length > 0) {
                    const { color, bold, underline, italic } = child
                    const { fontWeight, textDecoration, fontStyle } = child.css
                    const isBold = bold || !!(fontWeight === "bold" || fontWeight > 400)
                    const isUnderline = underline || !!(textDecoration === "underline")
                    const isItalic = italic || !!(fontStyle === "italic")

                    const shouldRemoveCss = Object.keys(child.css).filter(key => {
                        return editableInlineStyles.includes(key)
                    }).filter(editableKey => {
                        switch (editableKey) {
                            case "color":
                                return true
                            case "fontWeight":
                                return child.css[editableKey] === "bold" || child.css[editableKey] > 400
                            case "textDecoration":
                                return child.css[editableKey] === "underline"
                            case "fontStyle":
                                return child.css[editableKey] === "italic"
                            default:
                                return false;
                        }
                    })
                    return {
                        ...child,
                        color: color || child.css.color, bold: isBold, underline: isUnderline, italic: isItalic,
                        css: omit(child.css, shouldRemoveCss)
                    }
                } else if (child.type === "link") {
                    return refactTextStyle(child)
                } else {
                    return child
                }

            })

            return {
                ...value,
                children: settledStyleChildren
            }
        }

        const settledStyleValue = mergedContinuousBulletedListValue.map(value => {

            if (value.type === "paragraph" || value.type === "block-quote") {
                return refactTextStyle(value)
            } else if (value.type === "bulleted-list" || value.type === "numbered-list") {

                return {
                    ...value,
                    children: value.children.map(listItem => {
                        return {
                            ...listItem,
                            children: listItem.children ? listItem.children.map(child => {
                                if (child.type === "paragraph") {
                                    return refactTextStyle(child)
                                } else {
                                    return child
                                }
                            }) : listItem.children
                        }
                    })
                }
            } else if (value.type === "code") {
                return {
                    ...value,
                    children: value.children.map(child => {
                        if (child.type === "paragraph") {
                            return refactTextStyle(child)
                        } else {
                            return child
                        }
                    })
                }
            } else {
                return value
            }
        })

        return settledStyleValue

    }

    useEffect(() => {

        /**
         * https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1049
         * Slate 的 value 属性只有初始化的作用，并不会随着外部的改动（例如“撤销”）而改变 editor 的内容。
         * 外部改动可以通过替换 editor.children 修改 editor 内容，同时删除光标位置(by 威豪)。
         */

        if (!isEqual(initSlateValue(value), cleanSlateValue(editing))) {
            setEditing(value);

            const children = [...editor.children]

            children.forEach((node) => editor.apply({ type: 'remove_node', path: [0], node }))

            value.forEach((node, i) => editor.apply({ type: 'insert_node', path: [i], node: node }))

            Transforms.select(editor, [value.length - 1, 0])
            Transforms.collapse(editor, { edge: 'end' })
            // Transforms.deselect(editor);
        }

        setBlockquotes(value.filter(n => n.type === 'block-quote'))

    }, [value]);


    const lastHoveredPathRef = useRef();
    const selectedNodeRef = useRef(selectedNode)

    function changeSelectedNode (node) {
        setSelectedNode(node)
        selectedNodeRef.current = node
    }

    const overrideContentSetterRef = useRef(null)

    const renderElement = useCallback(props => {
        const Element = getElement();

        return (
            <Element editor={editor} 
                updateSelectedNode={changeSelectedNode}
                updateToolbarButtons={buttons => setToolbarButtons((buttons || inlineButtons))}
                keepInlineToolbar={keepInlineToolbar} blockquotes={blockquotes} aiEnabled={aiEnabled}
                isCompositionEditing={isCompositionEditing} aiInputInfo={aiInputInfo} 
                isEditorFocused={isFocused}

                brickToolbarInfo={brickToolbarInfo} 
                customBlockPlaceholder={customBlockPlaceholder}
                captionInputVisible={captionInputVisible}
                setCaptionInputVisible={setCaptionInputVisible}
                setKeepInlineToolbar={setKeepInlineToolbar}
                overrideContentSetterRef={overrideContentSetterRef}
                setIsLinkEditing={v => setIsLinkEditing(v)}
                setHighlightRange={setHighlightRange}
                setIsEditFromLinkComp={setIsEditFromLinkComp}

                {...props}
            />
        )

    }, [keepInlineToolbar, blockquotes, isCompositionEditing, aiInputInfo, isFocused, brickToolbarInfo,
            captionInputVisible
    ])

    const Leaf = ({ attributes, children, leaf }) => {
        const { css } = leaf

        if (leaf.bold) {
            children = <strong>{children}</strong>
        }

        if (leaf.code) {
            children = <code>{children}</code>
        }

        if (leaf.italic) {
            children = <em>{children}</em>
        }

        if (leaf.underline) {
            children = <u>{children}</u>
        }

        // if (leaf.strikethrough) {
        //     children = <s>{children}</s>
        // }
        if (leaf.sub) {
            children = <sub>{children}</sub>
        }
        if (leaf.sup) {
            children = <sup>{children}</sup>
        }

        return (
            <span {...attributes}
                className={`${leaf.__highlight__ ? 'richtext-highlight-text' : ''}`}
                style={{
                    ...css,
                    color: leaf.color ? leaf.color : css && css.color ? css.color : null,
                    background: leaf.background ? leaf.background : css && css.background ? css.background : null
                }}>{children}</span>
        )

    }

    const renderLeaf = useCallback(props => {
        return <Leaf {...props}/>
    }, [])


    const buttons = (givenButtons) => {
        return givenButtons.map(c => {
            return typeof(c) === 'string' ? buttonComponents[c] : c
        }).filter(x => !!x);
    }

    function updateSupplementClassNames(className, enable) {
        const newClassNames = enable ? (
            supplementClassNames.indexOf(className) === -1 ?
                [...supplementClassNames, className] : supplementClassNames
        ) : supplementClassNames.filter(c => c !== className)

        setSupplementClassNames(newClassNames)

    }

    function renderTopToolbarButtons() {
        const mobileTopButtons = ['plus', ...topButtons].reduce((acc, current) => {
            if(['image', 'video', 'audio'].includes(current)) {
                return acc
            } else if (current !== 'seperator' || (current === 'seperator' && acc[acc.length - 1] !== 'seperator')) {
                return [...acc, current]
            } else return acc
        }, [])
        const mobileTopButtonsWithAI = ['ai', 'seperator', ...mobileTopButtons]
        const actualTopButtons = isMobile() && aiEnabled ? mobileTopButtonsWithAI : (isMobile () ? mobileTopButtons : topButtons)

        return buttons(actualTopButtons).map((Button, i) => (
            <div key={i} onMouseDown={e => e.preventDefault()}>
                <Button key={i} editor={editor} uploadVideo={uploadVideo} uploadImage={uploadImage} setAiInputInfo={setAiInputInfo} 
                    setHighlightRange={setHighlightRange}
                />
            </div>

        ))
    }

    function renderMobileToolbarButtons() {
        return !editor.selection ? null : Range.isCollapsed(editor.selection) && !selectedNodeRef.current ?
            renderTopToolbarButtons() :
            buttons(toolbarButtons).map((Button, i) => {
                return (
                    <div key={i} onMouseDown={e => e.preventDefault()}>
                        {
                            typeof(Button) === 'function' ? (
                                <Button editor={editor} setAiInputInfo={setAiInputInfo} 
                                    setHighlightRange={setHighlightRange}
                                    linkInputModalInfo={linkInputModalInfo}
                                    setLinkInputModalInfo={setLinkInputModalInfo}
                                    updateSelectedNode={changeSelectedNode}
                                    mobileToolbarRef={mobileToolbarRef}
                                    setCaptionInputVisible={setCaptionInputVisible}
                                    isLinkEditing={isLinkEditing}
                                />
                            ) : Button
                        }
                        
                    </div>
                )
            })
    }

    const highlightDecorator = ([node, path]) => {
        if(highlightRange && Text.isText(node)) {
            const intersection = Range.intersection(highlightRange, Editor.range(editor, path))

            if (intersection == null) {
                return []
            }

            const range = {
                __highlight__: true,
                ...intersection
            }

            return [range]
        }
        return []
    }

    function handleEditAreaMouseMove (e) {
        
        if(showBrickToolbar && !brickToolbarInfoRef.current.isOpened && !aiInputInfo.show && !selectedNodeRef.current && !keepInlineToolbar && !addingBlockType) {
            const editAreaRect = e.currentTarget.getBoundingClientRect()
            const { clientY } = e
            const relativeY = clientY - editAreaRect.top - editAreaPaddingTop // 相对 edit area 顶部的鼠标 Y 坐标
            const hoveredElementObj = brickDOMRefs.current.find(dom => {
                const { offsetTop, height } = dom.position
                return offsetTop <= relativeY && offsetTop + height >= relativeY 
            })
            if(hoveredElementObj) {
                const { element, position } = hoveredElementObj
                const { offsetTop } = position
                const slateNode = ReactEditor.toSlateNode(editor, element)
                const slatePath = ReactEditor.findPath(editor, slateNode)


                if(Node.has(editor, slatePath)) {
                    // is a valid path
                    const [textContainerNode, textContainerPath] = Editor.node(editor, slatePath)
                    let currentBlockType = textContainerNode.type
                    if(textContainerNode.type === 'paragraph' && textContainerPath.length >= 3) {
                        // 在 list 中
                        const [currentList, _] = Array.from(
                            Node.ancestors(editor, slatePath, { reverse: true })
                        ).find(ancestor => {
                            const [ancestorNode, _] = ancestor
                            return isList(ancestorNode.type)
                        })
                        currentBlockType = currentList.type
                    }
                    const lineHeight = typeof(window) !== 'undefined' ? window.getComputedStyle(element).getPropertyValue('line-height') : '21px' // default
                    const lineHeightValue = lineHeight ? parseInt(lineHeight, 10) : 21 
                    const offset = lineHeightValue / 2 - 11.5 // 根据 lineHeight 和 brickToolbarIcon 大小增加一点偏移，跟文字对齐
                    const top = offsetTop + offset;
        
                    lastHoveredPathRef.current = slatePath; 

                    setTimeout(() => {
                        if(brickToolbarInfoRef.current.isOpened) {
                            return;
                        }

                        if(isEqual(lastHoveredPathRef.current, slatePath)) {

                            const newBrickToolbarInfo = { show: true, top, slatePath, currentBlockType }   
                                                                                 
                            if(!isEqual(brickToolbarInfoRef.current, newBrickToolbarInfo)) {
                                // console.log(">>> update it", brickToolbarInfoRef.current, newBrickToolbarInfo);
                                setBrickToolbarInfo(newBrickToolbarInfo)
                            }
                        }                            
                    }, 64)  // 为了不让 brick icon 跟随的那么快，比如原来在 line a，后来鼠标经过 line b，去到 line c:  brick icon 就只从 a 到 c
                }
                
            }
        }
    }

    function shouldRenderPlaceholder () {
        const firstNode = editor.children[0]
        if(firstNode) {
            if(customBlockPlaceholder && (customBlockPlaceholder[firstNode.type]) || blockTypes.some(t => t === firstNode.type)) {
                return false
            } else return true
        } else {
            // 一开始不显示任何 placholder
            return false
        }
        
    }

    return (
        <>
            <div className={"slate-editor" + (className ? " " + className : "")} style={{
                '--richtext-editor-ai-placeholder': "\"按空格键，让 AI 帮你写\""
            }}>
                <div className={`slate-edit-area ${supplementClassNames.join(" ")}`} onMouseMove={e => {
                    // 考虑 throttle.
                    handleEditAreaMouseMove(e)

                }}>
                    <Slate
                        editor={editor}
                        value={editing}
                        onChange={newValue => {
                            const revisedValue = reviseValue(newValue)
                            setEditing(revisedValue);
                            if (onChange) {
                                const cleanedValue = cleanSlateValue(revisedValue);
                                if (!isEqual(value, cleanedValue)) {
                                    onChange(cleanedValue)

                                    if(brickToolbarInfo.show) {
                                        setBrickToolbarInfo({ show: false })
                                    }
                                }
                            }
                        }}
                    >
                        {noToolbar ? null : (
                            <div className="top-toolbar">
                                {renderTopToolbarButtons()}
                            </div>
                        )}
                        <div ref={editAreaContainerRef} className={classNames(
                            "editor-area-container",
                            { "with-brick-toolbar": showBrickToolbar }
                        )}>

                            { showBrickToolbar && !isMobile() ? (
                                <BrickToolbar editor={editor} aiInputInfo={aiInputInfo}
                                    brickToolbarInfoRef={brickToolbarInfoRef} 
                                    changeBrickToolbarInfo={info => {
                                        // console.log(">>> update", info);
                                        setBrickToolbarInfo(info)                                                                             
                                    }}
                                    setAiInputInfo={setAiInputInfo}
                                    uploadImage={uploadImage}
                                    uploadVideo={uploadVideo}
                                    containerDOM={editAreaContainerRef.current}
                                    setHighlightRange={setHighlightRange}
                                    updateBrickDOMRefs={() => updateBrickDOMRefs()}
                                    addingBlockType={addingBlockType}
                                    setAddingBlockType={setAddingBlockType}
                                />
                                ) : null
                            }
                            {
                                !isMobile() && !aiInputInfo.show && !isLinkEditing ? (
                                    <InlineToolBar
                                        originalEditor={editor}
                                        selectedNode={selectedNode}
                                        toolbarButtons={toolbarButtons}
                                        editAreaContainer={editAreaContainerRef.current}
                                        keepInlineToolbar={keepInlineToolbar}
                                        setKeepInlineToolbar={k => setKeepInlineToolbar(k)}
                                        updateSelectedNode={node => setSelectedNode(node)}
                                        updateToolbarButtons={buttons => setToolbarButtons((buttons || inlineButtons))}
                                        updateSupplementClassNames={(className, enable) => updateSupplementClassNames(className, enable)}
                                        setAiInputInfo={setAiInputInfo}
                                        setBrickToolbarInfo={setBrickToolbarInfo}
                                        buttonComponents={buttonComponents}
                                        setHighlightRange={setHighlightRange}
                                        setCaptionInputVisible={setCaptionInputVisible}
                                        showColorButton={showColorButton}
                                        isLinkEditing={isLinkEditing}
                                        setIsLinkEditing={setIsLinkEditing}
                                    />
                                ) : null
                            }
                            {
                                !isMobile() && isEditFromLinkComp ? (
                                    <HyperlinkInput editor={editor} 
                                        editAreaContainer={editAreaContainerRef.current}
                                        highlightRange={highlightRange}
                                        setHighlightRange={setHighlightRange}
                                        isLinkEditing={isLinkEditing}
                                        setIsLinkEditing={setIsLinkEditing}
                                        isEditFromLinkComp={isEditFromLinkComp}
                                        setIsEditFromLinkComp={setIsEditFromLinkComp}
                                    />
                                ) : null
                            }

                            <Editable
                                renderElement={renderElement}
                                className={classNames("rich-text", { "rich-text-v2": version == 2})}
                                placeholder={isFocused && aiEnabled ? '' : (shouldRenderPlaceholder() ? placeholder : '')}
                                renderLeaf={renderLeaf}
                                onKeyDown={event => handleKeydown(event, editor, aiEnabled, setAiInputInfo, isCompositionEditing, track, updateBrickDOMRefs)}
                                onCompositionStart={(e) => {
                                    e.preventDefault() // 避免在 link 末尾输入中文时光标错位的问题
                                    setIsCompositionEditing(true)
                                    if(brickToolbarInfo.show) {
                                        setBrickToolbarInfo({ show: false })
                                    }
                                }}
                                onCompositionEnd={() => {
                                    // 加 setTimeout 是为了解决 Safari 上 onCompositionEnd 调用得比其他 keydown 事件更快的问题
                                    setTimeout(() => {
                                        setIsCompositionEditing(false)
                                    }, 16);
                                }}
                                onBlur={() => setIsFocused(false)}
                                onFocus={() => setIsFocused(true)}
                                decorate={highlightDecorator}
                            />
                            {
                                aiInputInfo.show && !isMobile() ?
                                    <AIInputStream editor={editor} aiInputInfo={aiInputInfo}
                                        setAiInputInfo={setAiInputInfo}
                                        containerDOM={editAreaContainerRef.current}
                                        options={aiOptions}
                                        setHighlightRange={setHighlightRange}
                                        scrollContainerRef={scrollContainerRef}
                                        changeBrickToolbarInfo={info => setBrickToolbarInfo(info)}
                                    /> : null
                            }
                        </div>
                    </Slate>
                </div>
            </div>
            {
                (isFocused || aiInputInfo.show) && isMobile() ? (
                    <div className={`mobile-toolbar-container toolbar-content ${aiInputInfo.show ? 'ai-input' : ''}`}
                        style={{ bottom: mobileToolbarBottom }} ref={mobileToolbarRef}
                    >
                        {
                            aiInputInfo.show ?
                                <AIInputStream editor={editor} aiInputInfo={aiInputInfo}
                                    setAiInputInfo={setAiInputInfo}
                                    containerDOM={editAreaContainerRef.current}
                                    options={aiOptions}
                                    mobileToolbarRef={mobileToolbarRef}
                                    scrollContainerRef={scrollContainerRef}
                                    scrollContainerTop={scrollContainerTopRef.current}
                                    setHighlightRange={setHighlightRange}
                                    
                                /> : renderMobileToolbarButtons()
                        }
                    </div>

                ) : null
            }
            {
                isMobile() && linkInputModalInfo.open ? (
                    <Modal isDismissable className="editor-mobile-link-input-modal" isOpen={linkInputModalInfo.open} onOpenChange={open => {
                        if(!open) {
                            setLinkInputModalInfo({ open: false })
                            setHighlightRange(null)
                            setIsLinkEditing(false)
                        }
                    }}>
                        {linkInputModalInfo.component}
                    </Modal>
                ) : null
            }
        </>
    )

}

