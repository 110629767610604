// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Lang_eval$BwaxMobile = require("../../../../bwax-js/ml/lang/lang_eval.bs.js");
var Widget_html_common$BwaxMobile = require("./widget_html_common.bs.js");

var module_name = "Html.Attributes";

var src = "\nmodule " + (String(module_name) + ";\n\nimport Html exposing (Html, Attribute);\n\nstyle: String -> String -> Attribute msg = external;\n\nattribute: String -> String -> Attribute msg = external;\n\nboolAttribute: String -> Bool -> Attribute msg = external;\n\nintAttribute: String -> Int -> Attribute msg = external;\n\n-- supper common attributes:\nid = attribute \"id\";\n\nkey = attribute \"key\";\n\nclassName = attribute \"className\";\n\nclassList classes: List ( String, Bool ) -> Attribute msg =\n  classes \n    |> List.filter (\\(_, b) -> b)\n    |> List.map (\\(c, _) -> c)\n    |> String.join \" \"\n    |> className\n;\n\n\n\n\n");

function fail(name) {
  throw [
        Lang_eval$BwaxMobile.Eval_exn,
        module_name + ("." + (name + ": Invalid Arguments"))
      ];
}

var externals_vals_000 = /* tuple */[
  "style",
  Widget_html_common$BwaxMobile.pack_attribute_func(2, (function (param) {
          if (param) {
            var match = param[0][0];
            if (typeof match === "number" || match.tag !== /* V_literal */5) {
              return fail("style");
            } else {
              var match$1 = match[0];
              if (typeof match$1 === "number" || match$1.tag !== /* String */2) {
                return fail("style");
              } else {
                var match$2 = param[1];
                if (match$2) {
                  var match$3 = match$2[0][0];
                  if (typeof match$3 === "number" || match$3.tag !== /* V_literal */5) {
                    return fail("style");
                  } else {
                    var match$4 = match$3[0];
                    if (typeof match$4 === "number" || !(match$4.tag === /* String */2 && !match$2[1])) {
                      return fail("style");
                    } else {
                      return /* Attribute_style */Block.__(3, [
                                match$1[0],
                                match$4[0]
                              ]);
                    }
                  }
                } else {
                  return fail("style");
                }
              }
            }
          } else {
            return fail("style");
          }
        }))
];

var externals_vals_001 = /* :: */[
  /* tuple */[
    "attribute",
    Widget_html_common$BwaxMobile.pack_attribute_func(2, (function (param) {
            if (param) {
              var match = param[0][0];
              if (typeof match === "number" || match.tag !== /* V_literal */5) {
                return fail("attribute");
              } else {
                var match$1 = match[0];
                if (typeof match$1 === "number" || match$1.tag !== /* String */2) {
                  return fail("attribute");
                } else {
                  var match$2 = param[1];
                  if (match$2) {
                    var match$3 = match$2[0][0];
                    if (typeof match$3 === "number" || match$3.tag !== /* V_literal */5) {
                      return fail("attribute");
                    } else {
                      var match$4 = match$3[0];
                      if (typeof match$4 === "number" || !(match$4.tag === /* String */2 && !match$2[1])) {
                        return fail("attribute");
                      } else {
                        return /* Attribute_string */Block.__(0, [
                                  match$1[0],
                                  match$4[0]
                                ]);
                      }
                    }
                  } else {
                    return fail("attribute");
                  }
                }
              }
            } else {
              return fail("attribute");
            }
          }))
  ],
  /* :: */[
    /* tuple */[
      "boolAttribute",
      Widget_html_common$BwaxMobile.pack_attribute_func(2, (function (param) {
              if (param) {
                var match = param[0][0];
                if (typeof match === "number" || match.tag !== /* V_literal */5) {
                  return fail("boolAttribute");
                } else {
                  var match$1 = match[0];
                  if (typeof match$1 === "number" || match$1.tag !== /* String */2) {
                    return fail("boolAttribute");
                  } else {
                    var match$2 = param[1];
                    if (match$2) {
                      var match$3 = match$2[0][0];
                      if (typeof match$3 === "number" || match$3.tag !== /* V_literal */5) {
                        return fail("boolAttribute");
                      } else {
                        var match$4 = match$3[0];
                        if (typeof match$4 === "number" || !(match$4.tag === /* Bool */4 && !match$2[1])) {
                          return fail("boolAttribute");
                        } else {
                          return /* Attribute_bool */Block.__(2, [
                                    match$1[0],
                                    match$4[0]
                                  ]);
                        }
                      }
                    } else {
                      return fail("boolAttribute");
                    }
                  }
                }
              } else {
                return fail("boolAttribute");
              }
            }))
    ],
    /* :: */[
      /* tuple */[
        "intAttribute",
        Widget_html_common$BwaxMobile.pack_attribute_func(2, (function (param) {
                if (param) {
                  var match = param[0][0];
                  if (typeof match === "number" || match.tag !== /* V_literal */5) {
                    return fail("intAttribute");
                  } else {
                    var match$1 = match[0];
                    if (typeof match$1 === "number" || match$1.tag !== /* String */2) {
                      return fail("intAttribute");
                    } else {
                      var match$2 = param[1];
                      if (match$2) {
                        var match$3 = match$2[0][0];
                        if (typeof match$3 === "number" || match$3.tag !== /* V_literal */5) {
                          return fail("intAttribute");
                        } else {
                          var match$4 = match$3[0];
                          if (typeof match$4 === "number" || match$4.tag || match$2[1]) {
                            return fail("intAttribute");
                          } else {
                            return /* Attribute_int */Block.__(1, [
                                      match$1[0],
                                      match$4[0]
                                    ]);
                          }
                        }
                      } else {
                        return fail("intAttribute");
                      }
                    }
                  }
                } else {
                  return fail("intAttribute");
                }
              }))
      ],
      /* [] */0
    ]
  ]
];

var externals_vals = /* :: */[
  externals_vals_000,
  externals_vals_001
];

var externals_000 = module_name;

var externals = /* tuple */[
  externals_000,
  externals_vals
];

exports.module_name = module_name;
exports.src = src;
exports.fail = fail;
exports.externals_vals = externals_vals;
exports.externals = externals;
/* src Not a pure module */
