


import React from 'react'
import * as ReactDOM from 'react-dom/client';

import * as Toast from '@radix-ui/react-toast';

import './Toast.less';

export default function ToastComp({ title, undoAction, duration = 2000 }) {

    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setOpen(true);
        }, 100)
        return () => clearTimeout(timer);
    }, []);

    return (
        <Toast.Provider swipeDirection="right" duration={duration}>
            <Toast.Root
                className="toast-root lc-base"
                open={open}
                onOpenChange={setOpen}
            >
                <Toast.Title className="">
                    {title}
                </Toast.Title>
                {undoAction ? (
                    <Toast.Action className="" asChild altText="撤销">
                        <button className="undo-btn whitespace-nowrap" onClick={undoAction}>
                            撤销
                        </button>
                    </Toast.Action>
                ) : null}
            </Toast.Root>
            <Toast.Viewport className="lc-toast-viewport" />
        </Toast.Provider>
    )
}

export function toast(arg) { 

    const { title, undoAction, duration = 2000 } = (() => {
        if(typeof(arg) == "string") {
            return { title: arg }
        } else {
            return arg
        }
    })();

    const toastElem = document.createElement('div');

    document.body.appendChild(toastElem);

    const root = ReactDOM.createRoot(toastElem);
    root.render(<ToastComp {...{ title, undoAction, duration }} />);

    setTimeout(() => {
        document.body.removeChild(toastElem);
    }, duration + 200);

}
