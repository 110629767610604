// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Char = require("bs-platform/lib/js/char.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Lang_eval$BwaxMobile = require("../../lang_eval.bs.js");
var Runtime_common$BwaxMobile = require("../runtime_common.bs.js");

var module_name = "Char";

function fail(name) {
  throw [
        Lang_eval$BwaxMobile.Eval_exn,
        module_name + ("." + (name + ": Invalid Arguments"))
      ];
}

function src(param) {
  return "\nmodule " + (String(module_name) + ";\n\n-- Conversion\n\n-- Convert to the corresponding Unicode code point.\ntoCode: Char -> Int = external;\n\n-- Convert a Unicode code point to a character.\nfromCode: Int -> Char = external;\n\n\n-- Convert to upper case.\ntoUpper: Char -> Char = external;\n\n-- Convert to lower case.\ntoLower: Char -> Char = external;\n\n\n-- Query \n\n-- Detect upper case ASCII characters.\nisUpper: Char -> Bool = external;\n\n-- Detect lower case ASCII characters.\nisLower : Char -> Bool = external;\n\n-- Detect upper case or lower case ASCII characters.\nisAlpha : Char -> Bool = external;\n\n-- Detect upper case, lower case, or number ASCII characters.\nisAlphaNum: Char -> Bool = external;\n\n\n-- Detect digits 0123456789\nisDigit: Char -> Bool = external;\n\n-- Detect octal digits 01234567\nisOctDigit: Char -> Bool = external;\n\n-- Detect hexadecimal digits 0123456789abcdefABCDEF\nisHexDigit: Char -> Bool = external;\n\n\n");
}

function char_to_int(name, proc) {
  var f = Runtime_common$BwaxMobile.pack_func(1, (function (a) {
          if (a) {
            var match = a[0][0];
            if (typeof match !== "number" && match.tag === /* V_literal */5) {
              var match$1 = match[0];
              if (typeof match$1 !== "number" && match$1.tag === /* Char */3 && !a[1]) {
                return Runtime_common$BwaxMobile.pack_int(Curry._1(proc, match$1[0]));
              }
              
            }
            
          }
          console.log(a);
          return fail(name);
        }));
  return /* tuple */[
          name,
          f
        ];
}

function int_to_char(name, proc) {
  var f = Runtime_common$BwaxMobile.pack_func(1, (function (param) {
          if (param) {
            var match = param[0][0];
            if (typeof match === "number" || match.tag !== /* V_literal */5) {
              return fail(name);
            } else {
              var match$1 = match[0];
              if (typeof match$1 === "number" || match$1.tag || param[1]) {
                return fail(name);
              } else {
                return Runtime_common$BwaxMobile.pack_char(Curry._1(proc, match$1[0]));
              }
            }
          } else {
            return fail(name);
          }
        }));
  return /* tuple */[
          name,
          f
        ];
}

function char_to_char(name, proc) {
  var f = Runtime_common$BwaxMobile.pack_func(1, (function (param) {
          if (param) {
            var match = param[0][0];
            if (typeof match === "number" || match.tag !== /* V_literal */5) {
              return fail(name);
            } else {
              var match$1 = match[0];
              if (typeof match$1 === "number" || !(match$1.tag === /* Char */3 && !param[1])) {
                return fail(name);
              } else {
                return Runtime_common$BwaxMobile.pack_char(Curry._1(proc, match$1[0]));
              }
            }
          } else {
            return fail(name);
          }
        }));
  return /* tuple */[
          name,
          f
        ];
}

function char_to_bool(name, proc) {
  var f = Runtime_common$BwaxMobile.pack_func(1, (function (param) {
          if (param) {
            var match = param[0][0];
            if (typeof match === "number" || match.tag !== /* V_literal */5) {
              return fail(name);
            } else {
              var match$1 = match[0];
              if (typeof match$1 === "number" || !(match$1.tag === /* Char */3 && !param[1])) {
                return fail(name);
              } else {
                return Runtime_common$BwaxMobile.pack_bool(Curry._1(proc, match$1[0]));
              }
            }
          } else {
            return fail(name);
          }
        }));
  return /* tuple */[
          name,
          f
        ];
}

function is_upper(c) {
  if (c >= /* "A" */65) {
    return c <= /* "Z" */90;
  } else {
    return false;
  }
}

function is_lower(c) {
  if (c >= /* "a" */97) {
    return c <= /* "z" */122;
  } else {
    return false;
  }
}

function is_alpha(c) {
  if (is_upper(c)) {
    return true;
  } else {
    return is_lower(c);
  }
}

function is_digit(c) {
  if (c >= /* "0" */48) {
    return c <= /* "9" */57;
  } else {
    return false;
  }
}

function is_oct_digit(c) {
  if (c >= /* "0" */48) {
    return c <= /* "7" */55;
  } else {
    return false;
  }
}

function is_hex_digit(c) {
  if (c >= /* "0" */48 && c <= /* "9" */57 || c >= /* "a" */97 && c <= /* "f" */102) {
    return true;
  } else if (c >= /* "A" */65) {
    return c <= /* "F" */70;
  } else {
    return false;
  }
}

function is_alpha_num(c) {
  if (is_alpha(c)) {
    return true;
  } else {
    return is_digit(c);
  }
}

var externals_vals_000 = char_to_int("toCode", (function (prim) {
        return prim;
      }));

var externals_vals_001 = /* :: */[
  int_to_char("fromCode", Pervasives.char_of_int),
  /* :: */[
    char_to_char("toUpper", Char.uppercase),
    /* :: */[
      char_to_char("toLower", Char.lowercase),
      /* :: */[
        char_to_bool("isUpper", is_upper),
        /* :: */[
          char_to_bool("isLower", is_lower),
          /* :: */[
            char_to_bool("isAlpha", is_alpha),
            /* :: */[
              char_to_bool("isAlphaNum", is_alpha_num),
              /* :: */[
                char_to_bool("isDigit", is_digit),
                /* :: */[
                  char_to_bool("isOctDigit", is_oct_digit),
                  /* :: */[
                    char_to_bool("isHexDigit", is_hex_digit),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]
  ]
];

var externals_vals = /* :: */[
  externals_vals_000,
  externals_vals_001
];

var externals_000 = "Char";

var externals = /* tuple */[
  externals_000,
  externals_vals
];

var code_A = /* "A" */65;

var code_F = /* "F" */70;

var code_Z = /* "Z" */90;

var code_a = /* "a" */97;

var code_f = /* "f" */102;

var code_z = /* "z" */122;

var code_0 = /* "0" */48;

var code_7 = /* "7" */55;

var code_9 = /* "9" */57;

exports.module_name = module_name;
exports.fail = fail;
exports.src = src;
exports.char_to_int = char_to_int;
exports.int_to_char = int_to_char;
exports.char_to_char = char_to_char;
exports.char_to_bool = char_to_bool;
exports.code_A = code_A;
exports.code_F = code_F;
exports.code_Z = code_Z;
exports.code_a = code_a;
exports.code_f = code_f;
exports.code_z = code_z;
exports.code_0 = code_0;
exports.code_7 = code_7;
exports.code_9 = code_9;
exports.is_upper = is_upper;
exports.is_lower = is_lower;
exports.is_alpha = is_alpha;
exports.is_digit = is_digit;
exports.is_oct_digit = is_oct_digit;
exports.is_hex_digit = is_hex_digit;
exports.is_alpha_num = is_alpha_num;
exports.externals_vals = externals_vals;
exports.externals = externals;
/* externals_vals Not a pure module */
