// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var GetImageURL = require("bwax-ui/getImageURL");
var Lang_eval$BwaxMobile = require("../../../../bwax-js/ml/lang/lang_eval.bs.js");
var Runtime_common$BwaxMobile = require("../../../../bwax-js/ml/lang/mod/runtime_common.bs.js");

var module_name = "Image";

function fail(name) {
  throw [
        Lang_eval$BwaxMobile.Eval_exn,
        module_name + ("." + (name + ": Invalid Arguments"))
      ];
}

function func(args) {
  var name;
  var j;
  if (args) {
    var match = args[0][0];
    if (typeof match === "number" || match.tag !== /* V_tagged */6) {
      return fail("url");
    } else {
      var match$1 = args[1];
      if (match$1) {
        var match$2 = match$1[0][0];
        var name$1 = match[0];
        if (typeof match$2 === "number") {
          return fail("url");
        } else {
          switch (match$2.tag | 0) {
            case /* V_tagged */6 :
                if (match$2[0] === "Just") {
                  var match$3 = match$2[1];
                  if (match$3) {
                    var match$4 = match$3[0][0];
                    if (typeof match$4 === "number" || !(match$4.tag === /* V_tagged_raw */8 && match$4[0] === "Image" && !(match$3[1] || match$1[1]))) {
                      return fail("url");
                    } else {
                      name = name$1;
                      j = match$4[1];
                    }
                  } else {
                    return fail("url");
                  }
                } else {
                  return fail("url");
                }
                break;
            case /* V_tagged_raw */8 :
                if (match$2[0] === "Image" && !match$1[1]) {
                  name = name$1;
                  j = match$2[1];
                } else {
                  return fail("url");
                }
                break;
            default:
              return fail("url");
          }
        }
      } else {
        return fail("url");
      }
    }
  } else {
    return fail("url");
  }
  var url = GetImageURL.default(j, name);
  return /* tuple */[
          /* V_literal */Block.__(5, [/* String */Block.__(2, [url])]),
          /* No_term */0
        ];
}

function func$1(args) {
  if (args) {
    var match = args[0][0];
    if (typeof match === "number" || match.tag !== /* V_tagged */6) {
      return fail("processURL");
    } else {
      var match$1 = args[1];
      if (match$1) {
        var match$2 = match$1[0][0];
        if (typeof match$2 === "number" || match$2.tag !== /* V_literal */5) {
          return fail("processURL");
        } else {
          var match$3 = match$2[0];
          if (typeof match$3 === "number" || !(match$3.tag === /* String */2 && !match$1[1])) {
            return fail("processURL");
          } else {
            var url = GetImageURL.default(match$3[0], match[0]);
            return /* tuple */[
                    /* V_literal */Block.__(5, [/* String */Block.__(2, [url])]),
                    /* No_term */0
                  ];
          }
        }
      } else {
        return fail("processURL");
      }
    }
  } else {
    return fail("processURL");
  }
}

var externals_vals_000 = /* tuple */[
  "url",
  Runtime_common$BwaxMobile.pack_func(2, func)
];

var externals_vals_001 = /* :: */[
  /* tuple */[
    "processURL",
    Runtime_common$BwaxMobile.pack_func(2, func$1)
  ],
  /* [] */0
];

var externals_vals = /* :: */[
  externals_vals_000,
  externals_vals_001
];

var externals_000 = module_name;

var externals = /* tuple */[
  externals_000,
  externals_vals
];

var src = "\nmodule Image;\n\nurl: Size -> Image -> String = external;\n\nprocessURL: Size -> String -> String = external;\n\n";

exports.module_name = module_name;
exports.src = src;
exports.fail = fail;
exports.externals_vals = externals_vals;
exports.externals = externals;
/* externals_vals Not a pure module */
