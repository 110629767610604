import React from 'react'

import { Helmet } from 'react-helmet-async'

import getImageURL from 'bwax-ui/getImageURL';

import NotFoundImage from 'Client/images/error_404.png'

import PopoverTrigger from 'bwax-ui/components/PopoverTrigger';

import Layout_inbot_old from "./Layout_inbot_old";

const supportQrCode = "https://bw-dt.oss-cn-shenzhen.aliyuncs.com/public/dt-bwax/design-resources/support-qrcode.jpg";



export default function Layout_inbot(props) {

    if(props.data.isWhitelisted) {
        return (
            <Layout_inbot_old {...props} />
        )
    }


    const ImageURL = NotFoundImage.replace("./", "/");

    return (
        <div className="not-found-page">
            <Helmet>
                <title>知识助手 | 未找到页面</title>
                <meta name="viewport" content="width=device-width,initial-scale=1" />
            </Helmet>
            <div className="content">
                <span className="top-placeholder" />
                <div className="flex flex-col items-center gap-12">
                    <img src={ImageURL} className="logo-icon h-[152px]" />
                    <div className="font-size-13 text-[--gray10] flex flex-col items-start gap-2">
                        <div>知识助手已停止服务 :(</div>
                        <div>如果您要取回自己的笔记、文档等数据，</div>
                        <div>退款，或者有任何其他问题，请<PopoverTrigger content={(
                            <div className="p-2 rounded">
                                <img className="w-[15rem] min-h-[20rem]" src={getImageURL(supportQrCode, "medium")}></img>
                            </div>
                        )}>
                            <span className="underline">联系客服</span>
                        </PopoverTrigger>
                        </div>
                </div>
            </div>
        </div>
        </div >
    )
}
