

import React, { useState } from 'react'

import getFieldInput from './getFieldInput';

import { getFieldLabel, getConstraints } from './fieldSettings';

import EditingState from 'bwax-ui/re/legacy/EditingState.bs'

import Button from 'bwax-ui/components/Button';

import './RecordFormForEntity.less';

export default function RecordFormForEntity({
    entity, facade, confirmText, fieldItems, initialValues = {},

    hiddenFields = [],
    onBeforeConfirm = _ => {},
    onConfirm, color, isReadyToConfirm,
    cancelText = "取消",
    onCancel,
    isFieldApplicable = _ => true,
}) {

    const entityName = entity.name;

    const fieldsToUse = (fieldItems ? (fieldItems.map(fi => {
        const f = entity.fields.find(f => f.name == fi.name);
        return f ? { ...f, config: fi } : f
    })) : entity.fields).filter(
        f => f && isFieldApplicable(f)
    )

    const allKeys = fieldsToUse.map(f => f.name)

    const foldValue = valueName => (acc, fi) => fi[valueName] !== undefined ? { ...acc, [fi.name]: fi[valueName] } : acc;
    const fixedValues = fieldItems ? fieldItems.reduce(foldValue("fixedValue"), {}) : {};
    const defaultValues = fieldItems ? fieldItems.reduce(foldValue("defaultValue"), {}) : {};

    // --- 初始化 Editing
    const {
        errors,
        validated,
        editing,
        lastSaved,
        dirtyValues,
        updateEditing,
        markSaved,
        reset,
        rollback,
        clearEditing,
    } = EditingState.useEditingStateAsJs(allKeys, initialValues, fixedValues, defaultValues, [], "no-key", false);

    const items = fieldsToUse.map((f, index) => {
        const { config = {} } = f;

        if (config.hidden) {
            return null;
        }
        if(hiddenFields.indexOf(f.name) !== -1) {
            return null
        }

        const input = config.input || getFieldInput(f, entityName, { facade, constraints: getConstraints({ entityName, field: f, formData: validated, }) });
        const name = f.name;
        const value = fixedValues[name] || editing[name];
        const onChange = v => {
            if (v !== value) {
                updateEditing({ [name]: v })
            }
        }
        const label = config.label || getFieldLabel(f, entityName) || f.name;

        return (
            <div className="field-item" key={index}>
                <div className="label font-size-14">{label}</div>
                <div className="input">
                    {input ? input({ value, onChange, placeholder: config.placeholder, label, autoFocus: index == 0 }) : null}
                </div>
            </div>
        )
    })

    const involvedEditKeys = fieldsToUse.filter(
        /// TODO 关于 fixedValue 的处理，应该做一个更好的抽象：
        /// 目前，fixedValue 在如下四个地方皆有处理：
        ///     1. 外部检测 dirtyValue
        ///     2. useEditingValue
        f => fixedValues[f.name] === undefined
    ).map(f => f.name)


    // all the require field must be there:
    const requiredFields = fieldsToUse.filter(
        f => f.required
    )

    const [loading, setLoading] = useState(false);

    const [error, setError] = useState("");

    const doConfirm = async () => {
        setLoading(true);
        await onConfirm({ validated, fixedValues, dirtyValues });
        setLoading(false);
    }

    return (
        <div className="lc-record-form" data-color={color}>
            <div className="field-list">
                {items}
            </div>
            <div className="action-area">
                {error ? <div className="error font-size-13">{error}</div> : null}
                <div className="flex gap-4">
                    <Button isDisabled={!isReadyToConfirm({
                        requiredFields, involvedEditKeys, validated, dirtyValues, defaultValues,
                    })} onPress={_ => {
                        if(onBeforeConfirm) {
                            onBeforeConfirm()
                        }

                        doConfirm()
                    }} isLoading={loading}
                        color="grass" appearance="primary"
                    >
                        {confirmText}
                    </Button>
                    {
                        onCancel ? (
                            <Button onPress={_ => {
                                onCancel();
                            }} isDisabled={loading}
                            >
                                {cancelText}
                            </Button>
                        ) : null
                    }
                </div>
            </div>
        </div>
    )
}



