
// This file is generated. Don't modify it.
import React from 'react'
export default function PaymentCodeIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M12.4888781,11.6770935 L12.4888781,2.96190246 L3.51112186,2.96190246 L3.51112186,11.6770935 L8,12.9973606 L12.4888781,11.6770935 Z M2.5492194,11.3941899 L2.5492194,2.96190246 C2.5492194,2.43066894 2.97988834,2 3.51112186,2 L12.4888781,2 C13.0201117,2 13.4507806,2.43066894 13.4507806,2.96190246 L13.4507806,11.3941899 L13.7764806,11.2983904 L14.0478997,12.2212095 L8,14 L1.95210029,12.2212095 L2.22351943,11.2983904 L2.5492194,11.3941899 Z M7.51904877,8.09204891 L7.51904877,7.4507806 L6.07619508,7.4507806 L6.07619508,6.48887814 L7.31982868,6.48887814 L6.05674358,5.22579304 L6.73691489,4.54562172 L8,5.80870683 L9.26308511,4.54562172 L9.94325642,5.22579304 L8.68017132,6.48887814 L9.92380492,6.48887814 L9.92380492,7.4507806 L8.48095123,7.4507806 L8.48095123,8.09204891 L9.92380492,8.09204891 L9.92380492,9.05395137 L8.48095123,9.05395137 L8.48095123,10.336488 L7.51904877,10.336488 L7.51904877,9.05395137 L6.07619508,9.05395137 L6.07619508,8.09204891 L7.51904877,8.09204891 Z" id="PaymentCodeIcon-path-1"></path>
            </defs>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Payment-Code">
                    <g>
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="PaymentCodeIcon-mask-2" fill="white">
                            <use href="#PaymentCodeIcon-path-1"></use>
                        </mask>
                        <use id="Shape" fill={color} fillRule="nonzero" href="#PaymentCodeIcon-path-1"></use>
                        <g id="Color/UI/Text" mask="url(#PaymentCodeIcon-mask-2)" fill={color} fillRule="nonzero">
                            <rect id="light-gray" x="0" y="0" width="16" height="16"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
