


import React from 'react'
import omit from 'lodash/omit'

import { Editor, Transforms, Range, Text, Path } from 'slate'
import { ReactEditor } from 'slate-react'

import { isMobile } from 'bwax/clientEnv'

import { BsStars } from 'react-icons/bs';

import { useTrack } from 'bwax-ui/track';

export default function AIButton(props) {

    const { editor, setAiInputInfo, bindOverrideContent, setHighlightRange } = props
    const { selection } = editor 

    const track = useTrack();

    function openAiInput () {
        if(!isMobile()) {
            bindOverrideContent({
                component: null,
                persistent: false
            })
        }
        
        setHighlightRange(selection)

        if(typeof(window) !== 'undefined' && selection) {
            const domSelection = window.getSelection()
            const domRange = domSelection.getRangeAt(0)
            const rect = domRange.getBoundingClientRect()

            track("note_ai_trigger", { method: "inline-toolbar"})

            // Editor.string(editor, selection) 可能返回空字符串
            if(!Range.isCollapsed(selection) ) {
                // 有选中内容
                setAiInputInfo({ 
                    show: true, 
                    position: {
                        ...omit(rect, ['width', 'height'] ), top: rect.top + rect.height + 4 // 距离选中文本 4px
                    },
                    action: 'activeBySelect' 
                })
            } else {
                // 移动端在没有选中内容时点击 ai button
                const [currentNode, currentPath] = Editor.node(
                    editor,
                    selection.focus.path
                );
                if(currentNode.text === '') {
                    setAiInputInfo({
                        show: true,
                        position: {
                            ...omit(rect, ['width', 'height'] )
                        },
                        action: 'activeBySpace'
                    })
                } else {
                    // 如果当前 node 有内容，则选中当前 node
                    Transforms.select(editor, Path.parent(selection.focus.path))
                    const [focusedNode, focusedPath] = Editor.node(editor, editor.selection)
                    const domNode = ReactEditor.toDOMNode(editor, focusedNode)
                    const rect = domNode.getBoundingClientRect();

                    setAiInputInfo({
                        show: true,
                        action: 'activeBySelect',
                        position: { ...rect, top: rect.top + rect.height + 4 }
                    })
                }
                
            }
            
        }
    }

    return (
        <button className={"editor-button gap-1.5"} style={{ 
            fontWeight: "bold",
            color: "var(--violet10)"
        }} onClick={() => openAiInput()}>
           <BsStars className="mt-[-1px]" /> AI
        </button>
    )
}
