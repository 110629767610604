
// This file is generated. Don't modify it.
import React from 'react'
export default function AttentionIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M9.54784869,5.42962077 C9.53198627,5.42892365 9.51603427,5.42857143 9.5,5.42857143 C8.90826634,5.42857143 8.42857143,5.90826634 8.42857143,6.5 C8.42857143,7.09173366 8.90826634,7.57142857 9.5,7.57142857 C10.0917337,7.57142857 10.5714286,7.09173366 10.5714286,6.5 C10.5714286,6.48396573 10.5710764,6.46801373 10.5703792,6.45215131 C10.8430918,6.90405034 11,7.43370123 11,8 C11,9.65685425 9.65685425,11 8,11 C6.34314575,11 5,9.65685425 5,8 C5,6.34314575 6.34314575,5 8,5 C8.56629877,5 9.09594966,5.15690825 9.54784869,5.42962077 Z M8,13 C10.7614237,13 13,10.7614237 13,8 C13,5.23857625 10.7614237,3 8,3 C5.23857625,3 3,5.23857625 3,8 C3,10.7614237 5.23857625,13 8,13 Z M8,14 C4.6862915,14 2,11.3137085 2,8 C2,4.6862915 4.6862915,2 8,2 C11.3137085,2 14,4.6862915 14,8 C14,11.3137085 11.3137085,14 8,14 Z" id="AttentionIcon-path-1"></path>
            </defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Personal/Attention">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="AttentionIcon-mask-2" fill="white">
                            <use href="#AttentionIcon-path-1"></use>
                        </mask>
                        <use id="Icon/Personal/Attention" fill={color} fillRule="nonzero" href="#AttentionIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
