

import React, { useEffect, useState, useRef, useContext } from 'react'

import { sendMessageToStream } from 'bwax-ui/openai/OpenAIClient';

import ChatUI from './ChatUI.js'
import ChatConfigPanel from './ChatConfigPanel.js';

import UsageQuotaContext from 'bwax-ui/ml/widget/ports/inbot/UsageQuotaContext';

import { userMessage, responseMessage, newTopicMessage } from './ChatbotMessageList.js';

import { toast } from 'bwax-ui/components/Toast';

const defaultKnowledgeOptions = {
    isEnabled: false, isLimited: false, scope: []
}

export default function ChatStream(props) {

    const { 
        facade, viewEnv, 
        
        // additional,
        currentSessionId, 
        currentSession,
        updateSessionAndList,

        refreshSession,

        botAvatarUrl, userAvatar, userNickName,
        userId,
        instructionPrompt,
        instructionPromptForLimitedKnowledge,

        maxInputCount,

        bindSendMessageToSession,


        // 
        showConfig = true,
        directToKnowledge = false,

        getPossibleQuestions = _ => [],
        possibleQuestionsExpandByDefault,

        className,

        renderInputBox,

        emptyView,
        inputBoxClassName,

        imageEnabled,

        onPersonaUpdate = _ => {},

    
    } = props

    const { reloadUsageQuota, remainingQuota } = useContext(UsageQuotaContext) || {};

    const [existingMessages, setExistingMessages] = useState([]); /// { sessionId: [] }
    const [newMessages, setNewMessages] = useState({});  /// { sessionId: [] }

    const [respondingSessions, setRespondingSessions] = useState([]);  // [ sessionId ]
    const [respondingTexts, setRespondingTexts] = useState({});  /// { sessionId: <string> }
    const [respondingSteps, setRespondingSteps] = useState({});  // { sessionId: [ { step: <>, done: true/false, text: string } ] }
    const [respondingKnowledges, setRespondingKnowledges] = useState({}); // { sessionId: [ <knowREf> ]}

    const [respondingWebLinks, setRespondingWebLinks] = useState({}); //  // { sessionId: [ <weblink> ]}

    const [respondingTasks, setRespondingTasks] = useState({});  // { sessionId: <taskId> }
    
    // // 当没有 current session 时，使用的 userOptions;
    // const [tempUserOptions, setTempUserOptions] = useState(defaultKnowledgeOptions);


    const respondingSessionsRef = useRef();
    respondingSessionsRef.current = respondingSessions;

    const respondingTextsRef = useRef();
    respondingTextsRef.current = respondingTexts;

    const respondingStepsRef = useRef();
    respondingStepsRef.current = respondingSteps;

    const respondingKnowledgesRef = useRef();
    respondingKnowledgesRef.current = respondingKnowledges;

    const respondingTasksRef = useRef();
    respondingTasksRef.current = respondingTasks;


    const respondingWebLinksRef = useRef();
    respondingWebLinksRef.current = respondingWebLinks;

    const stoppersRef = useRef({}); // { sessionId: <func> } 

    const [persona, setPersona] = useState(currentSession && currentSession.persona);
    useEffect(() => {
        setPersona(currentSession && currentSession.persona);        
    }, [ currentSession]);
    const welcomeMessage = persona && persona.欢迎消息 ? responseMessage({ content: persona.欢迎消息, time: currentSession ? new Date(currentSession.创建时间 || currentSession.createdAt) : new Date()}) : null;


    function appendRespondingWebLinks(webLinks, sessionId) {
        setRespondingWebLinks(prev => {
            const existing = prev[sessionId] || [];
            return { ...prev, [sessionId]: [...existing, ...webLinks] }
        })
    }

    function clearRespondingWebLinks(sessionId) {
        setRespondingWebLinks(prev => {
            return { ...prev, [sessionId]: [] }
        })
    }

    function updateRespondingKnowledges(knowledges, sessionId) {
        setRespondingKnowledges(prev => {
            return { ...prev, [sessionId]: knowledges }
        })
    }

    function updateRespondingTasks(task, sessionId) {

        const existing = respondingTasksRef.current;
        const newOne = { ...existing, [sessionId]: task };

        respondingTasksRef.current = newOne;
        setRespondingTasks(newOne)
    }


    function clearRespondingSteps(sessionId) {
        setRespondingSteps(prev => {
            return { ...prev, [sessionId]: [] }
        })
    }

    function updateRespondingStep(stepStatus, sessionId) {
        setRespondingSteps(prev => {
            const steps = prev[sessionId] || [];
            const newSteps = steps.some(s => s.step == stepStatus.step) ? (
                steps.map(s => s.step == stepStatus.step ? stepStatus : s)
            ) : (
                [...steps, stepStatus]
            )
            return { ...prev, [sessionId]: newSteps }
        })
    }

    const [hasLoadedMessages, setHasLoadedMessages] = useState(false);

    async function loadMessages(sessionId) {

        const [result, error] = await facade.list({
            entityName: "OpenAI-会话消息",
            condition: [{
                field: "会话", op: "eq", value: sessionId,
            }],
            sort: [
                { field: "创建时间", order: "DESC" }
            ],
            pageSize: 1000,
            fieldPaths: [
                ...["用户发送消息", "智能回复消息", "发送时间", "回复时间", "相关知识", "chatModel", "函数调用", "图片", "任务.id", "任务.类别"].map(f => "对话." + f),
                "类型", "创建时间"
            ]
        }, { forceRefreshing: true });

        setHasLoadedMessages(true);
        
        if (!error) {

            const messages = result.flatMap(message => {

                if (message.类型 == "新对话") {
                    return [newTopicMessage({ time: new Date(message.创建时间), content: welcomeMessage && welcomeMessage.content })]
                } else if (message.类型 == "对话") {
                    const r = message.对话;
                    const webLinks = ((r.函数调用 || []).find(f => f.name == "OnlineSearch") || {}).result;
                    return [
                        r.智能回复消息 || r.任务 ? 
                            responseMessage({ 
                                content: r.智能回复消息, time: new Date(r.回复时间), relatedKnowledges: r.相关知识, chatModel: r.chatModel, webLinks,
                                task: r.任务,
                            }) : 
                            null,
                        
                        userMessage({content: r.用户发送消息, time: new Date(r.发送时间), images: r.图片 } ),
                    ].filter(m => !!m)
                }
                return []
            });
            setExistingMessages(prev => ({
                ...prev,
                [sessionId]: messages
            }));
        }
    }


    async function updateSessionUserOptions(id, userOptions) {
        return updateSessionAndList(id, { 用户选项: userOptions })
    }

    async function updateSessionContentOptions(id, contentOptions) {
        return updateSessionAndList(id, { 内容生成选项: contentOptions })
    }

    async function updateSessionChatModel(id, chatModel) {
        return updateSessionAndList(id, { chatModel })
    }

 

    useEffect(() => {
        if (currentSession && !existingMessages[currentSession.id] && !newMessages[currentSession.id] && currentSession.id) {
            loadMessages(currentSession.id);
        }
    }, [ currentSession && currentSession.id]);



    function getUserOptions(session) {
        if(session) {
            return session.userOptions || (session.persona ? session.persona.用户选项 : defaultKnowledgeOptions)
        } else {
            return undefined;
        }
    }

    function getKnowledgeOptions(session) {
        const userOptions = getUserOptions(session);
        if(userOptions) {
            if(userOptions.scope) {
                return userOptions
            } else {
                return userOptions.knowledgeOptions || defaultKnowledgeOptions
            }
        } else {
            return defaultKnowledgeOptions
        }
    }


    function getContentOptions(session) {
        if(session) {
            return session.contentOptions || (session.persona ? session.persona.内容生成选项 : undefined)
        } else {
            return undefined;
        }
    }


    async function sendMessageToSession(message, images, session) {

        if(reloadUsageQuota) {
            const remainingQuota = await reloadUsageQuota();
            
            if(remainingQuota !== undefined && remainingQuota < 0) {
                toast({ title: "您已用完今天的限额"});
                return;
            }
        }

        const sessionId = session.id;
        setNewMessages(prev => {
            return {
                ...prev,
                [sessionId]: [userMessage({content: message, time: new Date(), images}), ...(prev[sessionId] || [])]
            }
        })
        setRespondingSessions(prev => (
            prev.indexOf(sessionId) === -1 ? [...prev, sessionId] : []
        ));

        function onData(s, isEnd) {

            // console.log(">>> on data", isEnd);

            const respondingTexts = respondingTextsRef.current;
            const newResponseText = (respondingTexts[sessionId] || "") + s;
            function updateRespondingText(text) {
                const newRespondingTexts = {
                    ...respondingTexts,
                    [sessionId]: text
                }
                setRespondingTexts(newRespondingTexts);
                respondingTextsRef.current = newRespondingTexts;
            }


            // if(respondingRef.current == sessionId) {
            if (isEnd) {
                setRespondingSessions(prev => prev.filter(x => x != sessionId));

                const task = respondingTasksRef.current[sessionId];
                if(task) {
                    setNewMessages(prev => {
                        return {
                            ...prev,
                            [sessionId]: [
                                responseMessage({ 
                                    content: "", time: new Date(), chatModel: session.chatModel, // webLinks,relatedKnowledges: knowledges, 
                                    task
                                }), 
                                ...(prev[sessionId] || [])
                            ]
                        }
                    });
                } else if (newResponseText) {
                    const knowledges = respondingKnowledgesRef.current[sessionId] || [];
                    const webLinks = respondingWebLinksRef.current[sessionId] || [];

                    setNewMessages(prev => {
                        return {
                            ...prev,
                            [sessionId]: [
                                responseMessage({ 
                                    content: newResponseText, time: new Date(), relatedKnowledges: knowledges, chatModel: session.chatModel, webLinks,
                                }), 
                                ...(prev[sessionId] || [])
                            ]
                        }
                    });
                }

                updateRespondingText("");
                updateRespondingKnowledges([], sessionId);
                updateRespondingTasks(null, sessionId)

                clearRespondingWebLinks(sessionId);
                clearRespondingSteps(sessionId);

                if (reloadUsageQuota) {
                    setTimeout(() => {
                        reloadUsageQuota();
                    }, 800)
                }

                refreshSession(sessionId);


            } else {
                updateRespondingText(newResponseText)
            }
            // }
        }

        function onStep(obj) {

            const { step, status, refKnowledgeList, functionCall } = obj;

            const taskFunctions = [
                "CreateSDXLImage",
                "CreateDallEImage",
            ]

            if (taskFunctions.indexOf(step) !== -1 && status == "Done" && functionCall && functionCall.result) {
                // console.log(">>> ", functionCall.result);
                updateRespondingTasks(functionCall.result, sessionId);
                return
            }
            
           
            if (step == "SearchKnowledge" && status == "Done" && refKnowledgeList) {
                updateRespondingKnowledges(refKnowledgeList, sessionId);
            }
            if (step == "OnlineSearch" && status == "Done" && functionCall && functionCall.result) {
                appendRespondingWebLinks(functionCall.result, sessionId)
            }

            updateRespondingStep(obj, sessionId);
        }

        sendMessageToStream({
            message, sessionId, 
            instructionPrompt: session.persona ? undefined : instructionPrompt,
            instructionPromptForLimitedKnowledge: session.persona ? undefined : instructionPromptForLimitedKnowledge,

            onData, onStep, dlc: facade.dlc,
            knowledgeOptions: getKnowledgeOptions(session),
            contentOptions: getContentOptions(session),

            chatModel: session.chatModel,

            // reloadUsageQuota,

            images,

            directToKnowledge,

            bindStopper: stop => {
                stoppersRef.current[sessionId] = stop;
            }
        })
    }

    useEffect(() => {
        if(bindSendMessageToSession) {
            bindSendMessageToSession(sendMessageToSession)
        }
        return () => {
            if(bindSendMessageToSession) {
                bindSendMessageToSession(null)
            }
        }
    }, []);


    async function sendMessage(message, images) {
        if (currentSession) {
            // 直接向他发送消息
            sendMessageToSession(message, images, currentSession);

        } else {
            // 这里不 create session
        }
    }



    function getExitingMessages() {
        if (currentSessionId) {
            return existingMessages[currentSessionId] || [];
        }
        return []
    }

    function getNewMessages() {
        if (currentSessionId) {
            return newMessages[currentSessionId] || [];
        }
        return []
    }


    function getLatestMessage(sessionId) {

        if(newMessages[sessionId] && newMessages[sessionId].length > 0) {
            return newMessages[sessionId][0]
        } else {
            return existingMessages[sessionId] ? existingMessages[sessionId][0]: null
        }
    }

    async function sendNewTopic() {

        const sessionId = currentSessionId;

        const latestMessage = getLatestMessage(sessionId);
        if(latestMessage.messageType == "newTopic") {
            console.log(">>> the latest is newTopic, no need to send newTopic")
            return;
        }

        const [result, error] = await facade.add({
            entityName: "OpenAI-会话消息",
            formData: {
                类型: "新对话",
                会话: sessionId
            },
            fieldPaths: [
                ...["用户发送消息", "智能回复消息", "发送时间", "回复时间", "相关知识", "chatModel",  "函数调用"].map(f => "对话." + f),
                "类型"
            ]
        });
        if (!error && result) {

            setNewMessages(prev => {
                return {
                    ...prev,
                    [sessionId]: [ newTopicMessage({ time: new Date(), content: welcomeMessage && welcomeMessage.content }), ...(prev[sessionId] || [])]
                }
            })
        }
    }


    const configPanel = (
        <ChatConfigPanel {...{
            loading: !currentSession,

            persona: currentSession && currentSession.persona,

            onPersonaUpdate,


            userOptions: getUserOptions(currentSession),
            updateUserOptions: newOptions => {
                if (currentSessionId) {
                    updateSessionUserOptions(currentSessionId, newOptions);
                    // update temp?
                } 
            },

            contentOptions: getContentOptions(currentSession),
            updateContentOptions: newOptions => {
                if(currentSessionId) {
                    updateSessionContentOptions(currentSessionId, newOptions)
                }
            },

            chatModel: currentSession && currentSession.chatModel,
            updateChatModel: chatModel => {
                if(currentSessionId) {
                    updateSessionChatModel(currentSessionId, chatModel)
                }
            },


            facade, currentUserId: userId,
        }} />
    )

    
    const allMessages = [...(getNewMessages()), ...(getExitingMessages()),  ...(welcomeMessage ? [welcomeMessage] : []),].reverse();

    const mainContent = currentSessionId ? (
        <ChatUI {...{
            history: allMessages,  hasLoadedMessages,
            botAvatarUrl, userAvatar, userNickName, 
            
            currentSession,

            responding: currentSessionId ? respondingSessions.indexOf(currentSessionId) !== -1 : false,
            respondingText: currentSessionId ? (respondingTexts[currentSessionId] || "") : "",

            // responding: true,
            // respondingText: "HEllo WORL",

            respondingSteps: currentSessionId ? respondingSteps[currentSessionId] : [],
            respondingKnowledges: currentSessionId ? respondingKnowledges[currentSessionId] : [],
            respondingWebLinks: currentSessionId ? respondingWebLinks[currentSessionId] : [],
            respondingTask: currentSessionId ? respondingTasks[currentSessionId] : null,

            sendMessage,

            // chat model
            chatModel: currentSession && currentSession.chatModel,
            updateChatModel: chatModel => {
                if(currentSessionId) {
                    updateSessionChatModel(currentSessionId, chatModel)
                }
            },

            stopResponding: () => {
                const stop = stoppersRef.current[currentSessionId];
                if (stop) {
                    stop();
                }
            },

            maxInputCount,


            configPanel: showConfig ? configPanel: null,

            viewEnv, facade,

            remainingQuota,

            sendNewTopic,

            className,

            possibleQuestions: currentSession ? getPossibleQuestions(currentSession, allMessages) : [], 
            possibleQuestionsExpandByDefault,

            renderInputBox,

            emptyView,
            inputBoxClassName,

            imageEnabled,


        }} /> 
    ) : null;

    return mainContent
}

