// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Plate$BwaxMobile = require("../../../../bwax-js/ml/plate.bs.js");
var Lang_eval$BwaxMobile = require("../../../../bwax-js/ml/lang/lang_eval.bs.js");
var Widget_html_common$BwaxMobile = require("./widget_html_common.bs.js");

var module_name = "Html";

var src = "\nmodule " + (String(module_name) + ";\n\ntype Html msg = opaque;\n\ntype Attribute msg = opaque;\n\ntext: String -> Html msg = external;\n\nnull: Html msg = external;\n\n-- General way to create HTML nodes.\nnode: String -> List (Attribute msg) -> List (Html msg) -> Html msg = external;\n\nsingleNode name attributes: String -> List (Attribute msg) -> Html msg = \n  node name attributes []\n;\n\n-- Headers:\nh1 = node \"h1\";\nh2 = node \"h2\";\nh3 = node \"h3\";\nh4 = node \"h4\";\nh5 = node \"h5\";\nh6 = node \"h6\";\n\n-- Grouping content:\ndiv = node \"div\";\np = node \"p\";\npre = node \"pre\";\nblockquote = node \"blockquote\";\n\nhr = singleNode \"hr\";\n\n-- Text: \nspan = node \"span\";\na = node \"a\";\ncode = node \"code\";\nem = node \"em\";\nstrong = node \"strong\";\ni = node \"i\";\nb = node \"b\";\nu = node \"u\";\nsub = node \"sub\";\nsup = node \"sup\";\n\nbr = singleNode \"br\";\n\n-- Lists:\nol = node \"ol\";\nul = node \"ul\";\nli = node \"li\";\ndl = node \"dl\";\ndt = node \"dt\";\ndd = node \"dd\";\n\n-- Embedded content\nimg = singleNode \"img\";\n\niframe = node \"iframe\";\ncanvas = node \"canvas\";\nmath = node \"math\";\n\n\n-- Inputs:\nform = node \"form\";\ninput = node \"input\";\ntextarea = node \"textarea\";\nbutton = node \"button\";\nselect = node \"select\";\noption = node \"option\";\n\n-- Sections:\nsection = node \"section\";\nnav = node \"nav\";\narticle = node \"article\";\naside = node \"aside\";\nheader = node \"header\";\nfooter = node \"footer\";\naddress = node \"address\";\nmain_ = node \"main\";\n\n-- Figures: \nfigure = node \"figure\";\nfigcaption = node \"figcaption\";\n\n-- Tables:\ntable = node \"table\";\ncaption = node \"caption\";\ncolgroup = node \"colgroup\";\ncol = node \"col\";\ntbody = node \"tbody\";\nthead = node \"thead\";\ntfoot = node \"tfoot\";\nth = node \"th\";\ntr = node \"tr\";\ntd = node \"td\";\n\n\n\n");

function fail(name) {
  throw [
        Lang_eval$BwaxMobile.Eval_exn,
        module_name + ("." + (name + ": Invalid Arguments"))
      ];
}

var externals_vals_000 = /* tuple */[
  "text",
  Widget_html_common$BwaxMobile.pack_html_func(1, (function (param) {
          if (param) {
            var match = param[0][0];
            if (typeof match === "number" || match.tag !== /* V_literal */5) {
              return fail("text");
            } else {
              var match$1 = match[0];
              if (typeof match$1 === "number" || !(match$1.tag === /* String */2 && !param[1])) {
                return fail("text");
              } else {
                return /* Html_text */Block.__(0, [match$1[0]]);
              }
            }
          } else {
            return fail("text");
          }
        }))
];

var externals_vals_001 = /* :: */[
  /* tuple */[
    "null",
    Widget_html_common$BwaxMobile.html_to_value(/* Html_null */0)
  ],
  /* :: */[
    /* tuple */[
      "node",
      Widget_html_common$BwaxMobile.pack_html_func(3, (function (param) {
              if (param) {
                var match = param[0][0];
                if (typeof match === "number" || match.tag !== /* V_literal */5) {
                  return fail("text");
                } else {
                  var match$1 = match[0];
                  if (typeof match$1 === "number" || match$1.tag !== /* String */2) {
                    return fail("text");
                  } else {
                    var match$2 = param[1];
                    if (match$2) {
                      var match$3 = match$2[0][0];
                      if (typeof match$3 === "number" || match$3.tag !== /* V_list */1) {
                        return fail("text");
                      } else {
                        var match$4 = match$2[1];
                        if (match$4) {
                          var match$5 = match$4[0][0];
                          if (typeof match$5 === "number" || !(match$5.tag === /* V_list */1 && !match$4[1])) {
                            return fail("text");
                          } else {
                            var attributes = Plate$BwaxMobile.List.keep_map((function (param) {
                                    var match = param[0];
                                    if (typeof match === "number" || !(match.tag === /* V_tagged_raw */8 && match[0] === "Html_Attribute")) {
                                      return ;
                                    } else {
                                      return match[1];
                                    }
                                  }), match$3[0]);
                            var children = Plate$BwaxMobile.List.keep_map((function (param) {
                                    var match = param[0];
                                    if (typeof match === "number" || !(match.tag === /* V_tagged_raw */8 && match[0] === "Html")) {
                                      return ;
                                    } else {
                                      return match[1];
                                    }
                                  }), match$5[0]);
                            return /* Html_node */Block.__(1, [
                                      match$1[0],
                                      attributes,
                                      children
                                    ]);
                          }
                        } else {
                          return fail("text");
                        }
                      }
                    } else {
                      return fail("text");
                    }
                  }
                }
              } else {
                return fail("text");
              }
            }))
    ],
    /* [] */0
  ]
];

var externals_vals = /* :: */[
  externals_vals_000,
  externals_vals_001
];

var externals_000 = module_name;

var externals = /* tuple */[
  externals_000,
  externals_vals
];

exports.module_name = module_name;
exports.src = src;
exports.fail = fail;
exports.externals_vals = externals_vals;
exports.externals = externals;
/* src Not a pure module */
