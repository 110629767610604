
import React, { useEffect } from 'react'
import { Editor, Transforms, Element as SlateElement, Range, Path, Node } from 'slate'
import { MdLink } from "react-icons/md";
import { isMobile } from 'bwax/clientEnv'
import { isInLink } from '../utils/ListHelper';
import { HyperlinkInputComp } from '../components/HyperlinkInput';

const preventBubblingUp = (event) => {
    event.preventDefault();
    event.stopPropagation()
}

const isLinkActive = editor => {
    const [link] = Editor.nodes(editor, {
        match: n =>
            !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link',
    })
    return !!link
}

export function endInput ({ url, targetBlank, editor }) {
    const { selection } = editor
    if (selection) {
        // const currentNode = editor.children[selection.anchor.path[0]] // Editor.node(editor, editor.selection)
        const [parentNode, parentPath] = Editor.parent(
            editor,
            selection.focus?.path
        );

        let link = {}

        if (editor.isVoid(parentNode)) {
            link = {
                type: 'link',
                url,
                targetBlank,
                children: [parentNode]
            }

            Transforms.removeNodes(editor)

            Transforms.insertNodes(editor, link, {
                at: parentPath
            });

        } else {
            const { focus } = selection
            if(!Editor.isEnd(editor, focus, focus.path) && isInLink(editor)) {
                Transforms.splitNodes(editor, { at: selection.focus })
                const newFocus = editor.selection.focus
                const [focusNodeAfterSplit, __] = Editor.parent(editor, newFocus)
                const parentPath = Path.parent(newFocus.path)
                Transforms.mergeNodes(editor, {
                    at: focusNodeAfterSplit.type === 'link' ? Path.parent(parentPath) : parentPath
                })
                Transforms.setPoint(editor, Editor.before(editor, editor.selection.focus), { edge: 'focus' })
            }
            Transforms.unwrapNodes(editor, {
                match: (n, _) => {
                    return !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link'
                },
                split: true
            })

            if(url.trim()) {
                Transforms.wrapNodes(editor, {
                    type: 'link', url, targetBlank, children: []
                }, { split: true })
            }
        }
    }
}

export default function HyperLinkButton(props) {

    const { editor, bindOverrideContent, keepInlineToolbar, selectedNode, setHighlightRange,
        setLinkInputModalInfo, isLinkEditing
    } = props
    const { selection } = editor

    useEffect(() => {
        if(selection && keepInlineToolbar && Range.isCollapsed(selection)) {
            // 点击别的地方，取消编辑
            if(!isMobile()) {
                bindOverrideContent({
                    component: null,
                    persistent: false
                })
            }

            if(!selectedNode) {
                setHighlightRange(null)
            }
        }
    })

    const className = isLinkActive(editor) ? " active" : ""

    const component = (
        <HyperlinkInputComp {...props} selectedNode={selectedNode} 
            onEndInput={({ url, targetBlank }) => endInput({url, targetBlank, editor})} 
        />
    )

    useEffect(() => {
        if(isLinkEditing && isMobile()) {
            setLinkInputModalInfo({ open: true, component })
        }
    }, [isLinkEditing])

    return (
        <button className={"editor-button" + className}
            onClick={e => {
                if(!isMobile()) {
                    preventBubblingUp(e)
                    bindOverrideContent({
                        component,
                        persistent: true
                    })
                } else {
                    // 移动端弹出 Modal 编辑
                    setLinkInputModalInfo({ open: true, component })
                }
            }} >
                <MdLink style={{ fontSize: 14 }}/>
        </button>
    )
}
