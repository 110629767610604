require("isomorphic-fetch");

import getTokenCookieName from 'bwax/query/getTokenCookieName';
import Cookies from 'js-cookie'


// for the chat stream
import { defaultAvailableFunctions } from "bwax-ui/ml/widget/ports/inbot/components/input/FunctionToggle";

async function readChunks(reader) {
    const readResult = await reader.read();
    if (readResult.done) {
        return -1;
    } else {
        return readResult.value;
    }
}


function prepareHeaders(dlc) {
    const { sandbox, tenantCode, sessionToken } = dlc;
    const sessionTokenName = 'X-SessionToken' + (tenantCode ? "-" + tenantCode : "");
    const sessionTokenCookieName = getTokenCookieName({ sandbox, tenantCode });

    const st = sessionToken || Cookies.get(sessionTokenCookieName)
    const deviceId = Cookies.get('X-DeviceId')
    return {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "x-no-compression": true,
        ...(st ? { [sessionTokenName]: st } : {}),
        ...(deviceId ? { "X-DeviceId": deviceId } : {})
    }
}

async function processResponse({response, onData, onStep = _ => {}, bindStopper }) {


    const reader = response.body.getReader();

    if(bindStopper) {
        bindStopper(() => {
            // 
            reader.cancel();
            onData("", true)
        })
    }

    while (true) {
        let leftOver = null;
        const chunk = await readChunks(reader);
        if (chunk == -1) {
            break;

        } else {

            function processText(text) {
                return text.split("\n").filter(x => x.trim()).map(x => x.replace("data: ", ""));
            }

            const resultText = new TextDecoder().decode(chunk);
            if(resultText == "Not Enough Quota") {
                return onData("[用量余额不足]", true)
            }

            const texts = processText(resultText);

            function handleDataOrMsg(contentTexts) {
                function handleContent(obj) {

                    if (obj.delta) {
                        if(obj.delta.content) {
                            return onData(obj.delta.content, false)
                        }                        
                    } else if(obj.error) {
                        return onData(obj.error, true);

                    } else {
                        // 可能是其他类型
                        if(obj.step) {
                            return onStep(obj);
                        }
                    }
                }

                contentTexts.forEach((t, index) => {
                    if(t == "[DONE]") {
                        onData("", true)
                    } else {
                        try {
                            const obj = JSON.parse(t);
                            return handleContent(obj);
                        } catch (exception) {
                            // 尝试与之前剩下的一半结合。
                            if (leftOver && index == 0) {
                                try {
                                    const obj = JSON.parse(leftOver + t);
                                    handleContent(obj);
                                    leftOver = null;
                                    return r;
                                } catch (exception) {
                                    leftOver = null;
                                    return []
                                }
                            } else {
                                leftOver = t;
                            }
                        }
                    }


                });
            }

            handleDataOrMsg(texts);

        }
    }
}


export function executePrompt({ 
    promptTemplateName, promptVariables, knowledgeOptions, maxTokenNum,
    bindStopper, onData, onStep, dlc 
}) {

    const { isEnabled = false, isLimited = false, scope = [] } = knowledgeOptions || {};

    const { sandbox, tenantCode } = dlc;
    const url = (sandbox ? "/sandbox" : "") + (tenantCode ? `/-/${tenantCode}` : "") + "/-op-prompt-exec";

    // const url = "/gql/s";

    fetch(url, {
        method: "POST",
        headers: prepareHeaders(dlc),
        body: JSON.stringify({
            promptTemplateName, 
            promptVariables,
            knowledgeSearch: isEnabled,
            // 暂时只用 record id 
            knowledgeCategoryKeys: (isEnabled && isLimited) ? scope.map(r => r.recordId) : undefined,

            options: {
                temperature: 1.0,
                "max_tokens": maxTokenNum,
            }
        })
    }).then(async response => {

        

        await processResponse({ response, onData, onStep, bindStopper })
    })

}


export async function sendMessageToStream({ 
    message, instructionPrompt, instructionPromptForLimitedKnowledge,
    sessionId, knowledgeOptions, contentOptions, chatModel, directToKnowledge,
    bindStopper, dlc, onData, onStep,
    images,

    reload

}) {

    const { sandbox, tenantCode } = dlc;
    const url = (sandbox ? "/sandbox" : "") + (tenantCode ? `/-/${tenantCode}` : "") + "/-op-chat-stream";

    const { isEnabled = false, isLimited = false, scope = [] } = knowledgeOptions || {};

    function buildOptions () {
        if(images && images.length > 0) {
            const { availableFunctions, ...other } = contentOptions || {};
            return other;
        }
        
        if(defaultAvailableFunctions && defaultAvailableFunctions.length > 0) {
            return {
                availableFunctions: defaultAvailableFunctions,
                ...(contentOptions || {})
            }
        } else {
            return contentOptions
        }

    }
    const options = buildOptions();

    const knowledgeCategoryKeys = (isEnabled && isLimited) ? scope.map(r => r.recordId) : undefined;
    const knowledgeSearch = isEnabled;

    fetch(url, {
        method: "POST",
        headers: prepareHeaders(dlc),
        body: JSON.stringify({
            reqMessage: message,
            // reqMsgTemplate: template,

            // 不用
            // instructionPrompt: isEnabled ? instructionPromptForLimitedKnowledge : instructionPrompt,

            chatModel,
            
            knowledgeSearch,
            // 暂时只用 record id 
            knowledgeCategoryKeys,

            directToKnowledge,

            reqImages: images && images.length > 0 ? images.map(({url, ...other}) => other) : undefined,
            
            options,
            
            sessionId
        })
    }).then(async response => {
        await processResponse({ response, onData, onStep, bindStopper })
    })

}



// https://www.loginradius.com/blog/engineering/guest-post/http-streaming-with-nodejs-and-fetch-api/
// function readChunks(reader) {
//     return {
//         async*[Symbol.asyncIterator]() {
//             let readResult = await reader.read();
//             while (!readResult.done) {
//                 yield readResult.value;
//                 readResult = await reader.read();
//             }
//         },
//     };
// }


// for await (const chunk of readChunks(reader)) {
//     // console.log("receved chunk", chunk);
//     // console.log(`received chunk of size ${chunk.length}`);

//     const tn = performance.now();
//     // console.log(">>", tn - t1);
//     t1 = tn;

//     // console.log(">> ", new TextDecoder().decode(chunk));

//     function processText(text) {            
//         return text.split("\n").filter(x => x.trim()).map(x => x.replace("data: ", ""));
//     }

//     const texts = processText(new TextDecoder().decode(chunk));

//     function getData (contentTexts) {
//         const s = contentTexts.map(t => JSON.parse(t).delta.content).join("");
//         return s
//     }

//     if(texts.some (t => t == "[DONE]")) {
//         const contentTexts = texts.slice(0, texts.length - 1);
//         onData(getData(contentTexts), true);
//     } else {
//         onData(getData(texts), false);
//     }

// }