import React, { useState, useContext, useEffect } from 'react'

import classNames from 'classnames';

import SelectFileButton from 'bwax-ui/components/inputs/SelectFileButton';
import { UploadIcon } from '@radix-ui/react-icons';

import { getFileIcon } from "bwax-ui/components/FileIcon";

import ClipLoader from 'react-spinners/ClipLoader';

import './UploadKnowledgeDocumentButton.less';
import UploadFile from 'bwax-ui/actions/UploadFile';
import Dialog from 'bwax-ui/components/Dialog';

import Modal from "bwax-ui/components/Modal";

import Button, { Pressable } from "bwax-ui/components/Button";

import OutOfQuotaTip from 'bwax-ui/components/OutOfQuotaTip';

import UsageQuotaContext from '../../UsageQuotaContext';

import { useTrack } from 'bwax-ui/track';

// 依赖于 OpenAI-知识文档 实体

export default function UploadKnowledgeDocumentButton({ style, onUploaded, children, fieldPaths, folderId, }) {
    // 
    const track = useTrack();

    const { isOutOfQuota, reloadUsageQuota, facade } = useContext(UsageQuotaContext) || {};

    const [selectedFiles, setSelectedFiles] = useState();

    // "pending", number, "uploaded", "adding", "done",  { error: "error msg"};

    // 暂时只使用了: "pending", "removed", "adding", "done", { error: "error msg"};
    const [fileStatus, setFileStatus] = useState({});

    function updateSelectedFiles(files) {
        if (files) {
            setSelectedFiles(files);
            setFileStatus(files.reduce((acc, _, index) => ({ ...acc, [index]: "pending" }), {}));

        } else {
            setSelectedFiles();
            setFileStatus({})
        }
    }
    function updateFileStatus(index, status) {
        setFileStatus(prev => ({ ...prev, [index]: status }))
    }


    async function uploadDocument(file, index) {
        // 1. upload document and add to knowledge base:

        updateFileStatus(index, "adding");
        const [attachment, errors] = await UploadFile({
            file, uploadFor: "知识文档", onUploadProgress: evt => {
                // const { loaded, total } = evt;
                // const ctsPercent = parseInt(loaded / total) * 50;
                // console.log(">>> ", evt, ctsPercent);
                // updateFileStatus(index, evt.total)
            }
        })(facade.dlc);

        if (errors || !attachment) {
            // setError(errors || "上传失败");
            // setUploading(false);
            updateFileStatus(index, { error: "上传失败" })
            return
        }

        // add knowledge document.

        const [doc, error] = await facade.add({
            entityName: "OpenAI-知识文档",
            formData: {
                文档: attachment,
                标题: attachment.title,
                加入知识库: true,
                文件夹: folderId,
            },
            fieldPaths: fieldPaths || ["标题", "文档", "深入分析", "加入知识库", "文件夹.名称"]
        });

        if (error) {
            updateFileStatus(index, { error: error || "添加文档失败" });
            return
        }
        if (reloadUsageQuota) {
            setTimeout(() => {
                reloadUsageQuota()
            }, 2000)
        }
        updateFileStatus(index, "done");        
        return doc;
    }

    

    const [outOfQuotaTipShown, setOutOfQuotaTipShown] = useState();

    useEffect(() => {
        if(!isOutOfQuota) {
            setOutOfQuotaTipShown(false)
        }
    }, [ isOutOfQuota ]);

    function renderOutOfQuotaDialog() {
        return (
            <Dialog open={outOfQuotaTipShown}
                onOpenChange={open => setOutOfQuotaTipShown(open)}
                content={<OutOfQuotaTip />}
            />
        )
    }

    function renderStatus(index) {
        const status = fileStatus[index];

        // return (
        //     <ClipLoader color="var(--gray8)" size={14} />
        // )

        if (!status) {
            return null
        }
        // 暂时只支持 adding 和 done 
        if (status == "adding") {
            return (
                <ClipLoader color="var(--gray10)" size={14} />
            )
        } else if (status == "done") {
            return (
                <i className='bx bxs-check-circle text-[var(--grass9)]' ></i>
            )
        } else if (status == "pending") {
            return (
                <Pressable onPress={_ => updateFileStatus(index, "removed")}>
                    <i className='bx bx-trash cursor-pointer text-[var(--gray11)]'></i>
                </Pressable>

            )
        }
    }

    const isUploading = Object.keys(fileStatus).some(k => fileStatus[k] == "adding");
    const hasFileToAdd = selectedFiles && selectedFiles.some((f, index) => fileStatus[index] == "pending");

    const isFinished = Object.keys(fileStatus).some(k => fileStatus[k] == "done") && !hasFileToAdd

    function renderFileList() {
        if (!selectedFiles) return <div />;

        // const files = [  ...selectedFiles, ...selectedFiles, ...selectedFiles, ...selectedFiles, ...selectedFiles, ]
        const files = [...selectedFiles];

        return (
            <>
                <div className="px-4 py-6 flex flex-col gap-1 sm:px-8 grow overflow-auto">
                    {files.map((f, index) => {
                        if (fileStatus[index] == "removed") {
                            return null;
                        }

                        const isDone = fileStatus[index] == "done";
                        return (
                            <div key={index} className={
                                classNames(
                                    "flex gap-2 items-start py-3 sm:py-2 px-3 rounded",
                                    {
                                        "bg-[var(--grass4)]": isDone,
                                        "hover:bg-[var(--gray4)]": !isDone && !isUploading,
                                    }
                                )
                            }>
                                <div className="flex gap-2 items-start grow">
                                    <div className="font-size-16 pt-0.5">{getFileIcon(f)}</div>
                                    {f.name}
                                </div>
                                <div className="pt-0.25">
                                    {renderStatus(index)}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="flex justify-end px-4 pt-2 pb-6 sm:px-8">
                    {
                        !isFinished ? (
                            <Button appearance="primary" color="grass" isLoading={isUploading} isDisabled={!hasFileToAdd} onPress={async _ => {
                                
                                track("document_start_uploading")

                                let documents = [];
                                for(let i = 0; i < selectedFiles.length; i++) {
                                    if (fileStatus[i] == "removed") {
                                        // do nothing
                                    } else {    
                                        const doc = await uploadDocument(selectedFiles[i], i);
                                        if(doc) {
                                           documents.push(doc)
                                        }
                                    }                                    
                                }
                                onUploaded(documents);
                            }}>
                                开始上传
                            </Button>
                        ) : (
                            <Button onPress={_ => {
                                updateSelectedFiles()
                            }}>
                                关闭
                            </Button>
                        )
                    }
                </div>
            </>
        )
    }

    return (
        <>
            <SelectFileButton size="small" style={style}
                multiple
                accept="application/msword, text/plain, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                onSelectFile={files => {
                    updateSelectedFiles(files);
                }}
                onBeforeSelect={e => {

                    track("document_start_to_unload")

                    if (isOutOfQuota) {
                        setOutOfQuotaTipShown(true);
                        e.preventDefault();
                    }
                }}
            >
                {children ? children : (
                    <>
                        <UploadIcon style={{
                            width: 14,
                            marginRight: "0.5rem"
                        }}></UploadIcon>
                        上传文档
                    </>
                )}
            </SelectFileButton>
            {selectedFiles ?
                <Modal isOpen={selectedFiles} className="max-w-2xl"
                    onOpenChange={open => {
                        if (!open) {
                            updateSelectedFiles()
                        }
                    }}
                >
                    {renderFileList()}
                </Modal> : null
            }
            {renderOutOfQuotaDialog()}
        </>
    )
}


