import React from 'react'

class Discounts extends React.Component {

    render() {
        return (
            <svg width="56px" height="55px" viewBox="0 0 56 55" >
                <defs>
                    <linearGradient x1="100%" y1="100%" x2="0%" y2="0%" id="linearGradient-1">
                        <stop stopColor="#3C3D3D" offset="0%"></stop>
                        <stop stopColor="#141414" offset="100%"></stop>
                    </linearGradient>
                    <linearGradient x1="20.995742%" y1="-14.6128221%" x2="80.0991965%" y2="100%" id="linearGradient-2">
                        <stop stopColor="#FFE9C9" offset="0%"></stop>
                        <stop stopColor="#FFCF99" offset="100%"></stop>
                    </linearGradient>
                </defs>
                <g id="会员体系" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="会员套餐" transform="translate(-70.000000, -881.000000)" fillRule="nonzero">
                        <g id="会员特权" transform="translate(0.000000, 556.000000)">
                            <g id="优惠体验" transform="translate(16.000000, 312.000000)">
                                <g id="icon5" transform="translate(54.000000, 12.000000)">
                                    <circle id="Oval" fill="url(#linearGradient-1)" cx="27.6249516" cy="28.4331188" r="27.4285714"></circle>
                                    <path d="M16.4536598,18.7718654 L16.4536598,19.7306694 L19.8853382,22.1938422 L19.8853382,37.8478756 L16.2163709,40.4978313 L17.0486921,40.7293771 L20.3968892,38.4098095 L23.3788814,42.247909 L24.6413921,42.5788151 L21.0200039,37.9690169 L21.0281953,37.9690169 L21.0281953,22.4416333 L24.8134775,21.3279398 L24.8134775,43.2902617 L15.6936763,41.0103114 L15.6936763,18.2108083 L16.4536598,18.7718654 Z M32.137392,18.5088846 L32.137392,14.0417533 L30.9945349,14.0417533 L30.9945349,18.8320667 L20.5755593,21.7783905 L15.6936763,18.2108083 L31.8955095,13.575976 L37.4292026,16.916307 L36.4964081,17.2762228 L32.137392,18.5088846 Z M24.7042178,20.5170602 L39.5562268,16.0583472 L39.5562268,38.6441539 L24.8134775,43.2902617 L24.6413921,20.5336233 L24.7042178,20.5170602 Z M34.8116047,21.2507421 L34.8116047,28.8520186 L32.6510891,31.3079128 L30.4338174,28.8480763 L30.4338174,22.6056484 L29.8867383,22.6056484 L29.2938422,22.6056484 L29.2938422,29.2860277 L32.6614439,33.0220378 L35.9515799,29.2820854 L35.9515799,21.2507421 L34.8116047,21.2507421 Z" id="Combined-Shape" fill="url(#linearGradient-2)"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export default Discounts
