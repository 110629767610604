
// This file is generated. Don't modify it.
import React from 'react'
export default function MyRecoIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M8.01368937,9.90918622 L6.03239704,11.2876927 C5.94172699,11.3507774 5.81708413,11.3284151 5.75399942,11.2377451 C5.71906567,11.1875357 5.70902854,11.124101 5.72675487,11.0655593 L6.42622557,8.75553795 L4.50356228,7.29751829 C4.41555001,7.23077566 4.39830757,7.10532203 4.46505021,7.01730976 C4.50200652,6.96857617 4.55923015,6.93943141 4.62037925,6.93819862 L7.03178827,6.88958373 L7.82548662,4.60908276 C7.86179362,4.50476335 7.97579385,4.4496284 8.08011326,4.4859354 C8.1378181,4.50601881 8.18317722,4.55137792 8.20326063,4.60908276 L8.99695898,6.88958373 L11.4065434,6.93874765 C11.5169774,6.94100089 11.6046752,7.03235192 11.602422,7.14278588 C11.6011747,7.20391763 11.5720318,7.26112033 11.5233118,7.29806634 L9.60046892,8.7562222 L10.3003897,11.0653533 C10.3324308,11.1710609 10.2727122,11.2827283 10.1670046,11.3147694 C10.1084488,11.3325182 10.04499,11.3224866 9.99476413,11.2875414 L8.01368937,9.90918622 Z M3,13 L13,13 L13,3 L6,3 C4.34314575,3 3,4.34314575 3,6 L3,13 Z M6,2 L14,2 L14,14 L2,14 L2,6 C2,3.790861 3.790861,2 6,2 Z" id="MyRecoIcon-path-1"></path>
            </defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Personal/My-Recommendation">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="MyRecoIcon-mask-2" fill="white">
                            <use href="#MyRecoIcon-path-1"></use>
                        </mask>
                        <use id="Icon/Personal/My-Recommendation" fill={color} fillRule="nonzero" href="#MyRecoIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
