

import { Transforms, Path } from 'slate'
import { isInLink } from './ListHelper'

export function insertImage(f, uploadImage, editor) {

    const reader = new FileReader()
    // const Url = typeof (window) !== 'undefined' ? (window.URL || window.webkitURL) : null

    reader.onload = (evt) => {

        if (uploadImage) {

            uploadImage(f, (err, url) => {
                const image = {
                    type: "image",
                    url,
                    children: [{ text: ""}],
                    align: 'center',
                    width: "100%",
                }

                Transforms.insertNodes(editor, image)
                Transforms.mergeNodes(editor)
            })
        }

    }
    reader.readAsDataURL(f)
}