

import React, { useState, useEffect } from 'react'

import * as Progress from '@radix-ui/react-progress';

import './ProgressBar.less'

import classNames from 'classnames';
import numeral from 'numeral';

const ProgressBar = React.forwardRef(({ progress: givenProgress = 40, color, doneColor, style, className, type = "line", showNumber = true, barClassName }, ref) => {

    const progress = givenProgress < 0 ? 0 : (givenProgress > 100 ? 100 : givenProgress);

    const done = givenProgress >= 100;

    const fm = n => numeral(n).format("0,0")

    if(type == "circle") {
        return (
            <div className={classNames("lc-progress-circle", className, { "show-number": showNumber })} data-color={done && doneColor ? doneColor : color} data-done={done && !!doneColor} style={style} ref={ref}
            >
                <div className="circle" style={{
                    "--progress-value": fm(progress) + "%",
                    "--progress-value-str": "\"" + fm(progress) + "%\""
                }}>
                    
                </div>
            </div>
        )
    }

    return (
        <div className={classNames("lc-progress-bar", className, )} style={style} data-color={done && doneColor ? doneColor : color} data-done={done && !!doneColor} ref={ref}>
            <Progress.Root value={progress} className={classNames("ProgressRoot", barClassName)}>
                <Progress.Indicator
                    className="ProgressIndicator"
                    style={{ transform: `translateX(-${100 - progress}%)`}}
                />
            </Progress.Root>
        </div>
    );
});

export default ProgressBar;
