

import React, { useRef } from 'react'

import MenuAndMain from './components/MenuAndMain'

import "./TaskManagement.less"
import Grid from 'bwax-ui/components/Grid';


import TaskCard, { UpcomingTask } from './components/TaskCard';
import TaskJobList from './components/TaskJobList';

import taskSettings from './components/taskSettings';

export default function TaskManagement({ data, slots, events, facade, viewEnv }) {

    const {
        menuDrawerVisible,
        currentUserId,

        taskName,
        jobId,

    } = data;

    const {
        closeMenuDrawer,
    } = events;

    const { wizard } = slots

    const tasks = [
        ...taskSettings,
        { entityName: "__upcoming__" },
    ].map(task => {
        return { id: task.entityName, ...task };
    })

    const addJobRef = useRef({});

    function renderMenu() {
        return (
            <TaskJobList facade={facade} currentUserId={currentUserId}
                bindAddJob={(id, addJob) => addJobRef.current[id] = addJob}
                viewEnv={viewEnv}
            />
        )
    }

    function renderMainContent() {
        return (
            <div className="lc-task-management">
                <div className="task-wizard">
                    {/* 这里放一些引导功能 */}
                    {wizard}
                </div>
                <div className="task-gallery">
                    <Grid items={tasks} minWidth={260} renderItem={item => {
                        if (item.id == "__upcoming__") {
                            return <UpcomingTask />
                        }

                        const isAddingOrViewing = item.name === taskName;

                        return (
                            <TaskCard {...item} key={item.id} facade={facade}
                                {...{
                                    onJobAdded: taskJob => {
                                        Object.values(addJobRef.current).forEach(r => r(taskJob));
                                    },
                                    viewEnv,

                                    isAddingOrViewing,
                                    jobId: isAddingOrViewing ? jobId : undefined,
                                    
                                }}
                            />
                        )
                    }} />
                </div>
            </div>
        )
    }

    return (
        <MenuAndMain {...{
            menuDrawerVisible, closeMenuDrawer, renderMenu, mainContent: renderMainContent(),
            resizeKey: "task-management-0",

            menuPosition: "right", defaultMenuWidth: 320,
        }} />
    )
}
