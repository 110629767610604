import React, { useEffect, useRef } from 'react'

import '../../../../node_modules/video.js/dist/video-js.css'

import './styles/video.less'

const defaultVideoOptions = {
    preload: 'auto',
    language: 'zh-cn',
    controls: true,
    aspectRatio: "16:9",
    controlBar: {
        volumePanel: { inline: false }
    }
}

// 暂时没有实现好：
export function Video (props) {

    const { options, className = "", children } = props;

    const playerRef = useRef(null);

    const videoNodeRef = useRef(null);

    useEffect(() => {

        if(videoNodeRef.current && !playerRef.current) {
            import("video.js").then((videojs) => {
                const player = videojs.default(
                    videoNodeRef.current,
                    {
                        ...defaultVideoOptions,
                        ...options
                    },
                    function onPlayerReady() {
                        // console.log('player is ready')
                    });
                playerRef.current = player;
    
            });
        }
        return () => {
            if(playerRef.current) {
                playerRef.current.dispose();
            }
        }
    }, [ videoNodeRef.current ])

    return (
        <video
            style={{ width: "100%", height: "100%" }}
            ref={videoNodeRef}
            className={className + " video-js vjs-default-skin"} >
            { children }
        </video>
    )

}



export default class VideoOld extends React.Component {

    static defaultProps = {
        options: {}
    }

    componentDidMount() {

        import("video.js").then((videojs) => {
            this.player = videojs.default(
                this.videoNode,
                {
                    ...defaultVideoOptions,
                    ...this.props.options
                },
                function onPlayerReady() {
                    // console.log('player is ready')
                })
        })
    }

    componentWillUnmount() {
        // destroy player on unmount
        if (this.player) {
            try {
                this.player.dispose()
            } catch (error) {
                console.error(error);
            }
            
        }
    }


    render() {
        const { className } = this.props;
        return (
            <video
                style={{ width: "100%", height: "100%" }}
                ref={node => this.videoNode = node}
                className={className + " video-js vjs-default-skin"} >
                {this.props.children}
            </video>
        )
    }
}

// export default Video