
/// only used to standardize the loader key

import useDataLoader from 'bwax-ui/legacy/store/useDataLoader'
import loadCurrentUser from './loadCurrentUser'

export default function useCurrentUser(props = {}) {

    const { queryTarget = "data" } = props;
    const key = 'Auth::CurrentUser::' + queryTarget; 
    const { getData, forceLoad } = useDataLoader({ [key]: loadCurrentUser(props) })
    const [ currentUser ] = getData(key)

    async function forceReload (newProps = {}) {
        let merged = {
            ...props,
            ...newProps
        }
        let dataDict = await forceLoad({ [key]: loadCurrentUser(merged) })
        return dataDict[key]
    }

    return { currentUser, forceReload }
}
