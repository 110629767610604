

import React, { useContext, useEffect, useRef, useState } from 'react'

import dayjs from 'dayjs';
import Button, { Pressable } from 'bwax-ui/components/Button'

import ClampedText from 'bwax-ui/components/ClampedText';

import KnowledgeItemChat from './KnowledgeItemChat';

import { TbTextCaption } from 'react-icons/tb';

import TemplateExecution from 'bwax-ui/components/openai/TemplateExecution'

import Modal from 'bwax-ui/components/Modal'
import Link from 'bwax-ui/page/Link'

import { markdownToRichText } from 'bwax-ui/markdown/convertMarkdown';
import RichTextRenderer from 'bwax-ui/auxiliary/richtext_slate/RichTextRenderer';

// import { useTrack } from 'bwax-ui/track';

// 
export default function BookmarkDetail(props) {

    const {
        bookmark, facade, updateBookmark, routeTo, domainEnv, viewEnv,

        botAvatarUrl, userAvatar, userNickName, currentUserId,

    } = props;

    const [pageContents, stePageContents] = useState({});

    async function getContent() {
        if (pageContents[bookmark.id]) {
            return pageContents[bookmark.id];
        }
        const [result, error] = await facade.findById(bookmark.id, {
            entityName: "OpenAI-网页收藏", fieldPaths: ["内容"]
        })

        if (error || !result) {
            // TODO error handling
            return null
        }
        const richText = markdownToRichText(result.内容)

        stePageContents(prev => ({ ...prev, [bookmark.id]: [result.内容, richText] }));
        return result.内容
    }


    useEffect(() => {
        getContent();
    }, [bookmark.id]);



    function renderSummary() {
        return (
            <div className="flex gap-2 pt-2 pb-3 pr-1 flex-col">
                <div className="flex gap-1">
                    <div className="grow font-size-16 font-medium">
                        {/* <ClampedText text={a.标题 || "无标题"} tipEnabled={false} /> */}
                        {bookmark.标题}
                    </div>
                    <div className="font-size-12 text-[var(--gray10)] pt-0.5">{dayjs(bookmark.创建时间).format("MM.DD")}</div>
                </div>
                {/* <div className="font-size-12 text-[var(--gray10)]">{bookmark.网址}</div> */}
                <Link className="text-[var(--gray10)] hover:underline hover:text-[var(--gray12)] font-size-12 cursor-pointer break-all"
                    openWithNewTab={true} to={bookmark.网址}
                >
                    <i className='bx bx-link-alt translate-y-px mr-1' />
                    {bookmark.网址}
                </Link>
            </div>
        )
    }


    function renderView() {

        const pageContent = pageContents[bookmark.id];

        if (pageContent) {

            const [_, richText] = pageContent
            return (
                <div className="grow w-full shadow-md overflow-auto px-2 py-2 ">
                    <RichTextRenderer value={richText} version={2} />
                </div>
            )
        }


        return (
            <div className="bg-[var(--gray2)] grow w-full shadow-md flex flex-col justify-center items-center">
                <div className="flex flex-col gap-4 items-center">
                    <i className='bx bx-library text-5xl text-[var(--gray8)]'></i>
                    <div className="text-[var(--gray10)]">这类文件暂时不支持预览</div>
                </div>
            </div>
        )

    };

    function renderChat(className) {
        return (
            <KnowledgeItemChat {...{
                item: bookmark,
                updateItem: updateBookmark,

                scene: "bookmark-chat::" + bookmark.id,
                entityName: "OpenAI-网页收藏",

                facade, viewEnv,
                botAvatarUrl, userAvatar, userNickName, currentUserId,

                className,

                summaryTip: "请帮忙总结内容和提取要点",
            }} />
        )
    }

    return (
        <>
            <div className="hidden sm:flex h-full w-full font-size-14">
                <div className="w-1/2 grow pl-6 pr-2 py-2 flex flex-col gap-2">
                    {renderSummary()}
                    {renderView()}
                </div>
                <div className="w-1/2 grow pl-2 pr-4 pt-6 pb-2">
                    {renderChat("border-[1px] rounded !border-[var(--gray5)]")}
                </div>
            </div>
            <div className="flex flex-col sm:hidden h-full">
                <div className="w-full px-4">
                    {renderSummary()}
                </div>
                <div className="w-full grow pt-2 basis-0 min-h-0">
                    {renderChat()}
                </div>
            </div>
        </>
    )
}







function BookmarkDetail_old(props) {

    const { bookmark, remainingQuota, facade, updateBookmark, routeTo, domainEnv } = props;

    const [pageContents, stePageContents] = useState({});

    const track = useTrack();


    function sectionText(label, text, component) {
        return (
            <div className="flex flex-col gap-1.5">
                <div className="font-size-13">{label}</div>
                {text ? (
                    <div className="text-[var(--gray11)] whitespace-pre-line">
                        {text}
                    </div>
                ) : component}
            </div>
        )
    }

    const [contentToGenerateSummary, setContentToGenerateSummary] = useState();

    const [isGenerating, setIsGenerating] = useState(false);
    // 

    async function getContent() {
        if (pageContents[bookmark.id]) {
            return pageContents[bookmark.id];
        }
        const [result, error] = await facade.findById(bookmark.id, {
            entityName: "OpenAI-网页收藏", fieldPaths: ["内容"]
        })

        if (error || !result) {
            // TODO error handling
            return null
        }
        stePageContents(prev => ({ ...prev, [bookmark.id]: result.内容 }));
        return result.内容
    }

    async function generateSummary() {
        // 先查询内容, 调用 OpenAI 接口生成总结，
        setIsGenerating(true);
        const content = await getContent();
        if (!content) {
            setIsGenerating(false)
        }
        setContentToGenerateSummary({
            templateName: "总结",
            variables: {
                "原文": content
            },
            maxTokenNum: 1200
        })
    }

    const [isCreatingNote, setIsCreatingNote] = useState(false);

    const [createdNote, setCreatedNote] = useState();


    async function createNoteFromContent() {

        track("bookmark_create_note")

        setIsCreatingNote(true);
        const content = await getContent();
        if (!content) {
            setIsCreatingNote(false)
        }
        // create a note an popup a window.

        function serialize(slateValue) {
            return [
                { contentType: "Slate", version: 1 },
                slateValue
            ].map(JSON.stringify).join("\n");

        }

        const richText = markdownToRichText(content);

        const value = serialize([
            { type: "paragraph", children: [{ text: "" + bookmark.网址 }] },
            { type: "paragraph", children: [{ text: "" }] },
            ...richText
        ]);
        const title = bookmark.标题 || "网页内容"


        // 生成笔记
        const [result, error] = await facade.customMutation({
            entityName: "笔记",
            interfaceName: "添加",
            args: [title, value],
            outputFieldPaths: [
                ["笔记", []]
            ]
        });
        setIsCreatingNote(false);
        if (error) {
            // error handling
            console.error(error);
        }

        if (result) {
            setCreatedNote(result);
        }

    }


    const isQuotaEnough = remainingQuota && remainingQuota > (bookmark.内容词符数 <= 4000 ? bookmark.内容词符数 : bookmark.内容词符数 * 2);

    const generateNotAllowed = isGenerating || (bookmark.内容词符数 && bookmark.内容词符数 > 14000) || (!isQuotaEnough);

    return (
        <>
            <div className="pt-4 pb-8 flex flex-col gap-6 h-full">
                <div className="flex flex-col gap-6 grow px-6 sm:px-8 overflow-auto">
                    <div className="flex flex-col gap-2">
                        <h2 className="font-size-16 font-medium">
                            {bookmark.标题 || "未提取出标题的网页"}
                        </h2>
                        {bookmark.加入知识库 ? (
                            <div className="px-1.5 rounded py-0.5 bg-[var(--grass4)] text-[var(--grass11)] self-start font-size-12">
                                <i className='bx bx-check mr-1' /> 已加入知识库
                            </div>
                        ) : null}
                        <Link className="text-[var(--gray10)] hover:underline hover:text-[var(--gray12)] cursor-pointer break-all"
                            openWithNewTab={true} to={bookmark.网址}
                        >
                            <i className='bx bx-link-alt translate-y-px mr-1' />
                            {bookmark.网址}
                        </Link>

                    </div>
                    {bookmark.摘要 ? sectionText("网页自带的摘要:", bookmark.摘要) : null}
                    {
                        sectionText(
                            bookmark.总结 ? (
                                <>
                                    <span>AI 生成的总结</span>
                                    {generateNotAllowed ? null : (
                                        <Pressable onPress={_ => {
                                            track("bookmark_regenerate_summary")
                                            generateSummary()
                                        }}><i className='bx bx-refresh ml-1 font-size-14 text-[var(--gray10)] translate-y-px cursor-pointer'></i></Pressable>
                                    )}

                                </>
                            ) : "AI 生成的总结",
                            bookmark.总结,
                            (
                                <Button className="self-start !pl-5 !pr-6" isDisabled={generateNotAllowed}
                                    onPress={_ => {
                                        track("bookmark_generate_summary")
                                        generateSummary()
                                    }}
                                >
                                    {/* <div className="flex py-4 px-10 rounded gap-1 items-center bg-[var(--gray3)] self-start cursor-pointer hover:bg-[var(--gray4)]"> */}
                                    <div className="flex gap-1 items-center">
                                        <TbTextCaption />
                                        生成总结
                                    </div>
                                    {/* </div> */}
                                </Button>
                            )
                        )
                    }
                </div>
                <div className="flex justify-end gap-2 px-6 sm:px-8 ">
                    <Button isLoading={isCreatingNote} onPress={_ => {
                        createNoteFromContent()
                    }}>提取网页内容到笔记</Button>
                </div>
            </div>
            {!!contentToGenerateSummary ? (
                <Modal isOpen={true} className="max-w-xl" onOpenChange={isOpen => {
                    if (!isOpen) {
                        setContentToGenerateSummary()
                        setIsGenerating(false)
                    }
                }}>
                    <div className="w-full">
                        <div className="px-4 sm:px-6 flex items-center gap-1.5 font-medium"><TbTextCaption />生成网页的总结</div>
                        <TemplateExecution
                            {...contentToGenerateSummary}
                            onConfirm={async summary => {
                                track("bookmark_save_summary")

                                setContentToGenerateSummary()
                                await updateBookmark(bookmark.id, { 总结: summary })
                                setIsGenerating(false)
                            }}
                            confirmText={"保存"}
                        />
                    </div>
                </Modal>
            ) : null}
            {!!createdNote ? (
                <Modal isOpen={true} isMain={false} className="max-w-md" onOpenChange={isOpen => {
                    if (!isOpen) {
                        setCreatedNote()
                    }
                }}>
                    <div className="w-full flex flex-col gap-6 px-6 py-6">
                        <div className="flex gap-1 items-center">
                            <i className='bx bxs-check-circle text-[var(--grass9)] font-size-16'></i>
                            笔记已经生成
                        </div>
                        <div className="flex gap-3">
                            <Button onPress={_ => {
                                track("bookmark_jump_to_created_note")
                                routeTo("/note/" + createdNote.id);
                            }}>跳转至笔记页面</Button>
                            <Button onPress={_ => {
                                // const url = webEnv.isSandbox ? "/sandbox" + tryUrl : tryUrl;
                                // window.open(url);

                                track("bookmark_window_open_created_note")

                                window.open(domainEnv.urlPrefix + "/note/" + createdNote.id);


                            }}>新窗口打开笔记</Button>
                        </div>
                    </div>
                </Modal>
            ) : null}
        </>
    )

}


