// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Plate$BwaxMobile = require("../plate.bs.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Lang_eval$BwaxMobile = require("../lang/lang_eval.bs.js");
var Query_builder$BwaxMobile = require("./query_builder.bs.js");
var Defs_aggregate$BwaxMobile = require("./defs_aggregate.bs.js");
var Runtime_common$BwaxMobile = require("../lang/mod/runtime_common.bs.js");
var Lang_value_dict$BwaxMobile = require("../lang/lang_value_dict.bs.js");
var Base_query_types$BwaxMobile = require("../base_query_types.bs.js");
var Lang_typing_base$BwaxMobile = require("../lang/lang_typing_base.bs.js");
var Lang_typing_print$BwaxMobile = require("../lang/lang_typing_print.bs.js");
var StringHelper = require("bwax/ml/lang/mod/builtin/StringHelper");

function to_interface_input_value(entity_dict, data_type_dict, _maybe_interface_value_type, _value) {
  while(true) {
    var value = _value;
    var maybe_interface_value_type = _maybe_interface_value_type;
    var iter = function (param, param$1) {
      return to_interface_input_value(entity_dict, data_type_dict, param, param$1);
    };
    if (maybe_interface_value_type !== undefined) {
      var match = maybe_interface_value_type;
      var exit = 0;
      if (typeof match !== "number") {
        switch (match.tag | 0) {
          case /* Intf_list */0 :
              var match$1 = value[0];
              if (typeof match$1 !== "number" && match$1.tag === /* V_list */1) {
                var partial_arg = match[0];
                return Curry._1(Plate$BwaxMobile.List.to_array, Plate$BwaxMobile.List.map((function(partial_arg){
                              return function (param) {
                                return iter(partial_arg, param);
                              }
                              }(partial_arg)), match$1[0]));
              }
              break;
          case /* Intf_maybe */1 :
              var match$2 = value[0];
              if (typeof match$2 !== "number" && match$2.tag === /* V_tagged */6) {
                switch (match$2[0]) {
                  case "Just" :
                      var match$3 = match$2[1];
                      if (match$3 && !match$3[1]) {
                        _value = match$3[0];
                        _maybe_interface_value_type = match[0];
                        continue ;
                      }
                      break;
                  case "Nothing" :
                      return null;
                  default:
                    
                }
              }
              break;
          case /* Intf_sorting */8 :
              return Base_query_types$BwaxMobile.pack_sorting_value(Base_query_types$BwaxMobile.to_sorting(value));
          case /* Intf_adding */9 :
          case /* Intf_updating */10 :
              exit = 2;
              break;
          default:
            
        }
      }
      if (exit === 2) {
        var entity = Base_query_types$BwaxMobile.get_entity(entity_dict, match[0]);
        var match$4 = value[0];
        var exit$1 = 0;
        if (typeof match$4 === "number") {
          exit$1 = 3;
        } else {
          switch (match$4.tag | 0) {
            case /* V_record */2 :
                var al = Plate$BwaxMobile.List.map((function (param) {
                        return /* tuple */[
                                param[0],
                                iter(undefined, param[1])
                              ];
                      }), match$4[0]);
                var fields = entity[/* fields */2];
                return Js_dict.fromList(Plate$BwaxMobile.List.map((function(fields){
                              return function (param) {
                                return Query_builder$BwaxMobile.build_field_input(entity_dict, data_type_dict, fields, false, param);
                              }
                              }(fields)), al));
            case /* V_tagged_raw */8 :
                if (match$4[0] === "EntityInput") {
                  return match$4[1];
                } else {
                  exit$1 = 3;
                }
                break;
            default:
              exit$1 = 3;
          }
        }
        if (exit$1 === 3) {
          throw [
                Runtime_common$BwaxMobile.Runtime_exn,
                "Entity input must be a record or tagged raw: " + Lang_eval$BwaxMobile.string_of_value(value)
              ];
        }
        
      }
      
    }
    var value_to_input = (function(maybe_interface_value_type){
    return function value_to_input(value) {
      var match = value[0];
      if (typeof match !== "number") {
        switch (match.tag | 0) {
          case /* V_tuple */0 :
              return Curry._1(Plate$BwaxMobile.List.to_array, Plate$BwaxMobile.List.map(value_to_input, match[0]));
          case /* V_list */1 :
              return Curry._1(Plate$BwaxMobile.List.to_array, Plate$BwaxMobile.List.map((function (param) {
                                return iter(maybe_interface_value_type, param);
                              }), match[0]));
          case /* V_record */2 :
              return Js_dict.fromList(Plate$BwaxMobile.List.map((function (param) {
                                return /* tuple */[
                                        param[0],
                                        iter(undefined, param[1])
                                      ];
                              }), match[0]));
          case /* V_literal */5 :
              var match$1 = match[0];
              if (typeof match$1 === "number") {
                switch (match$1) {
                  case /* NaN */0 :
                  case /* Infinity */1 :
                  case /* NegInfinity */2 :
                      break;
                  
                }
              } else {
                switch (match$1.tag | 0) {
                  case /* Int */0 :
                      return match$1[0];
                  case /* Char */3 :
                      return Plate$BwaxMobile.Str.from_char(match$1[0]);
                  default:
                    return match$1[0];
                }
              }
              break;
          case /* V_tagged */6 :
              switch (match[0]) {
                case "Just" :
                    var match$2 = match[1];
                    if (match$2 && !match$2[1]) {
                      return iter(maybe_interface_value_type, match$2[0]);
                    }
                    break;
                case "Nothing" :
                    return null;
                default:
                  
              }
              break;
          case /* V_tagged_raw */8 :
              switch (match[0]) {
                case "Dict" :
                    return Js_dict.fromList(Plate$BwaxMobile.List.keep_map((function (param) {
                                      var v = param[1];
                                      return Plate$BwaxMobile.$$Option.map((function (k) {
                                                    return /* tuple */[
                                                            k,
                                                            value_to_input(v)
                                                          ];
                                                  }), Lang_eval$BwaxMobile.as_string(param[0]));
                                    }), Lang_value_dict$BwaxMobile.to_list(match[1])));
                case "File" :
                case "Image" :
                    return StringHelper.removeIdAndUrl(match[1]);
                case "Color" :
                case "JSON" :
                    return match[1];
                default:
                  
              }
              break;
          case /* V_tagged_number */9 :
              if (match[0] === "Date") {
                return new Date(match[1]).toJSON();
              }
              break;
          case /* V_tagged_string */10 :
              if (match[0] === "Color") {
                return match[1];
              }
              break;
          default:
            
        }
      }
      var match$3 = Base_query_types$BwaxMobile.to_criteria_value(value);
      if (match$3 !== undefined) {
        return Base_query_types$BwaxMobile.pack_criteria_value(match$3);
      } else {
        throw [
              Runtime_common$BwaxMobile.Runtime_exn,
              "Not supported input value: " + Lang_eval$BwaxMobile.string_of_value(value)
            ];
      }
    }
    }(maybe_interface_value_type));
    return value_to_input(value);
  };
}

function to_interface_inputs(entity_dict, data_type_dict, input_types, params) {
  var input_count = Plate$BwaxMobile.List.length(input_types);
  var input_params;
  if (input_count === 0) {
    input_params = /* [] */0;
  } else if (input_count === 1) {
    input_params = Plate$BwaxMobile.List.clean(/* :: */[
          Plate$BwaxMobile.List.head(params),
          /* [] */0
        ]);
  } else {
    var match = Plate$BwaxMobile.List.head(params);
    if (match !== undefined) {
      var match$1 = match[0];
      if (typeof match$1 === "number") {
        throw [
              Runtime_common$BwaxMobile.Runtime_exn,
              "Incorrect interface input"
            ];
      } else if (match$1.tag) {
        throw [
              Runtime_common$BwaxMobile.Runtime_exn,
              "Incorrect interface input"
            ];
      } else {
        input_params = match$1[0];
      }
    } else {
      throw [
            Runtime_common$BwaxMobile.Runtime_exn,
            "Incorrect interface input"
          ];
    }
  }
  if (Plate$BwaxMobile.List.length(input_params) !== input_count) {
    var actual_count = Plate$BwaxMobile.List.length(input_params);
    throw [
          Runtime_common$BwaxMobile.Runtime_exn,
          "Incorrect interface input count: " + (String(actual_count) + (" vs " + (String(input_count) + " ")))
        ];
  }
  return Plate$BwaxMobile.List.map((function (param) {
                var ivt = param[0];
                return /* tuple */[
                        ivt,
                        to_interface_input_value(entity_dict, data_type_dict, ivt, param[1])
                      ];
              }), Plate$BwaxMobile.List.combine(input_types, input_params));
}

function value_to_mutation_input(entity_dict, data_type_dict, params) {
  var match = params[0];
  if (typeof match !== "number") {
    switch (match.tag | 0) {
      case /* V_record */2 :
          var al = Plate$BwaxMobile.List.map((function (param) {
                  return /* tuple */[
                          param[0],
                          to_interface_input_value(entity_dict, data_type_dict, undefined, param[1])
                        ];
                }), match[0]);
          return /* MI_record */Block.__(0, [al]);
      case /* V_tagged_raw */8 :
          if (match[0] === "EntityInput") {
            return /* MI_raw */Block.__(1, [match[1]]);
          }
          break;
      default:
        
    }
  }
  throw [
        Runtime_common$BwaxMobile.Runtime_exn,
        "Input must be a record or tagged raw: " + Lang_eval$BwaxMobile.string_of_value(params)
      ];
}

function to_selection(binds) {
  var build = function (param) {
    var name = param[0];
    var resolve_inner = function (_t) {
      while(true) {
        var t = _t;
        var st = Lang_typing_base$BwaxMobile.shorten(t);
        if (typeof st === "number" || st.tag) {
          return st;
        } else {
          var match = st[1];
          if (match && !match[1]) {
            _t = match[0];
            continue ;
          } else {
            return st;
          }
        }
      };
    };
    var inner_t = resolve_inner(param[1]);
    if (typeof inner_t === "number") {
      return /* tuple */[
              name,
              /* No_selection */0
            ];
    } else {
      switch (inner_t.tag | 0) {
        case /* Term_record */1 :
            return /* tuple */[
                    name,
                    to_selection(Plate$BwaxMobile.List.map((function (param) {
                                return /* tuple */[
                                        param[0],
                                        param[1]
                                      ];
                              }), inner_t[0]))
                  ];
        case /* Var */2 :
            var match = inner_t[0][/* var_type */0];
            if (typeof match === "number") {
              return /* tuple */[
                      name,
                      /* No_selection */0
                    ];
            } else {
              switch (match.tag | 0) {
                case /* Recordvar */0 :
                    return /* tuple */[
                            name,
                            to_selection(match[0])
                          ];
                case /* Record_readonly */1 :
                case /* Record_readonly_of */2 :
                    return /* tuple */[
                            name,
                            to_selection(match[1])
                          ];
                default:
                  return /* tuple */[
                          name,
                          /* No_selection */0
                        ];
              }
            }
        default:
          return /* tuple */[
                  name,
                  /* No_selection */0
                ];
      }
    }
  };
  return /* Selections */Block.__(0, [Plate$BwaxMobile.List.map(build, binds)]);
}

function print_selection(selection) {
  if (typeof selection === "number") {
    return "";
  } else if (selection.tag) {
    return selection[0];
  } else {
    var x = Plate$BwaxMobile.Str.join(" ", Plate$BwaxMobile.List.map((function (param) {
                return param[0] + print_selection(param[1]);
              }), selection[0]));
    return "{ " + (x + " }");
  }
}

function perform_single_query(context, query, handle_result) {
  var query_context = Query_builder$BwaxMobile.runtime_to_query_context(context);
  return Query_builder$BwaxMobile.resolve_and_run_queries(query_context, /* :: */[
              query,
              /* [] */0
            ], /* record */[
              /* force_refreshing */false,
              /* process_select_value */true,
              /* process_composite_data */true,
              /* additional_query_params : [] */0
            ], (function (param) {
                if (param.tag) {
                  return Curry._1(handle_result, /* Error */Block.__(1, [param[0]]));
                } else {
                  var match = param[0];
                  if (match) {
                    if (match[1]) {
                      return Curry._1(handle_result, /* Error */Block.__(1, [[
                                      Runtime_common$BwaxMobile.Runtime_exn,
                                      "Incorrect result list"
                                    ]]));
                    } else {
                      return Curry._1(handle_result, /* Ok */Block.__(0, [match[0]]));
                    }
                  } else {
                    return Curry._1(handle_result, /* Error */Block.__(1, [[
                                    Runtime_common$BwaxMobile.Runtime_exn,
                                    "Incorrect result list"
                                  ]]));
                  }
                }
              }));
}

function perform_batch_query(context, queries, handle_result) {
  var query_context = Query_builder$BwaxMobile.runtime_to_query_context(context);
  return Query_builder$BwaxMobile.resolve_and_run_queries(query_context, queries, /* record */[
              /* force_refreshing */false,
              /* process_select_value */true,
              /* process_composite_data */true,
              /* additional_query_params : [] */0
            ], handle_result);
}

function apply_result(data_tagger, error_tagger, result) {
  var match = result[0];
  if (match !== undefined) {
    if (result[1] === undefined) {
      return Lang_eval$BwaxMobile.apply_value(undefined, data_tagger, match, Runtime_common$BwaxMobile.nt);
    }
    
  } else if (result[1] === undefined) {
    console.log("Warning: in this case we treat the data as Nothing: mainly for Q_get_current_user");
    return Lang_eval$BwaxMobile.apply_value(undefined, data_tagger, Runtime_common$BwaxMobile.pack_nothing, Runtime_common$BwaxMobile.nt);
  }
  return Lang_eval$BwaxMobile.apply_value(undefined, error_tagger, result[1], Runtime_common$BwaxMobile.nt);
}

function get_binds_from(t) {
  var s = Lang_typing_base$BwaxMobile.shorten(t);
  if (typeof s !== "number" && s.tag === /* Var */2) {
    var match = s[0][/* var_type */0];
    if (typeof match !== "number" && match.tag === /* Record_readonly */1) {
      return /* tuple */[
              match[0],
              match[1]
            ];
    }
    
  }
  throw [
        Runtime_common$BwaxMobile.Runtime_exn,
        "I can only get binds from record readonly var, not " + Lang_typing_print$BwaxMobile.string_of_term(/* [] */0, t)
      ];
}

function get_binds_with_alias(dts, data_tagger_t, alias_name) {
  var _a = Plate$BwaxMobile.List.assoc(alias_name, dts);
  if (_a !== undefined) {
    var match = _a;
    if (typeof match !== "number" && match.tag === /* Alias */1) {
      var match$1 = match[0];
      if (!match$1[0]) {
        return get_binds_from(match$1[1]);
      }
      
    }
    
  }
  throw [
        Runtime_common$BwaxMobile.Runtime_exn,
        "I can only get binds from readonly record alias, \nnot from " + Lang_typing_print$BwaxMobile.string_of_term(dts, data_tagger_t)
      ];
}

function get_binds(dts, data_tagger) {
  var data_tagger_t = data_tagger[1];
  var raise_error = function (param) {
    throw [
          Runtime_common$BwaxMobile.Runtime_exn,
          "Bad data tagger:  " + Lang_typing_print$BwaxMobile.string_of_term(dts, data_tagger_t)
        ];
  };
  var match = Lang_typing_base$BwaxMobile.shorten(data_tagger_t);
  if (typeof match === "number" || match.tag || match[0] !== "Arrow") {
    return raise_error(/* () */0);
  } else {
    var match$1 = match[1];
    if (match$1) {
      var match$2 = match$1[1];
      if (match$2 && !match$2[1]) {
        var _t = match$1[0];
        while(true) {
          var t = _t;
          if (typeof t === "number") {
            return raise_error(/* () */0);
          } else {
            switch (t.tag | 0) {
              case /* Term */0 :
                  var match$3 = t[1];
                  if (match$3) {
                    var v = match$3[0];
                    if (typeof v !== "number" && !v.tag && !v[1]) {
                      if (match$3[1]) {
                        return raise_error(/* () */0);
                      } else {
                        return get_binds_with_alias(dts, data_tagger_t, v[0]);
                      }
                    }
                    if (match$3[1]) {
                      return raise_error(/* () */0);
                    } else {
                      _t = v;
                      continue ;
                    }
                  } else {
                    return raise_error(/* () */0);
                  }
                  break;
              case /* Var */2 :
                  var match$4 = t[0][/* var_type */0];
                  if (typeof match$4 === "number" || match$4.tag !== /* Record_readonly */1) {
                    return raise_error(/* () */0);
                  } else {
                    return /* tuple */[
                            match$4[0],
                            match$4[1]
                          ];
                  }
              default:
                return raise_error(/* () */0);
            }
          }
        };
      } else {
        return raise_error(/* () */0);
      }
    } else {
      return raise_error(/* () */0);
    }
  }
}

function get_direct_bind(dts, data_tagger) {
  var data_tagger_t = data_tagger[1];
  var raise_error = function (param) {
    throw [
          Runtime_common$BwaxMobile.Runtime_exn,
          "Bad data tagger " + Lang_typing_print$BwaxMobile.string_of_term(dts, data_tagger_t)
        ];
  };
  var match = Lang_typing_base$BwaxMobile.shorten(data_tagger_t);
  if (typeof match === "number" || match.tag || match[0] !== "Arrow") {
    return raise_error(/* () */0);
  } else {
    var match$1 = match[1];
    if (match$1) {
      var match$2 = match$1[1];
      if (match$2 && !match$2[1]) {
        var _t = match$1[0];
        while(true) {
          var t = _t;
          if (typeof t === "number") {
            return raise_error(/* () */0);
          } else {
            switch (t.tag | 0) {
              case /* Term */0 :
                  var match$3 = t[1];
                  if (match$3) {
                    if (match$3[1]) {
                      return raise_error(/* () */0);
                    } else {
                      _t = match$3[0];
                      continue ;
                    }
                  } else {
                    return get_binds_with_alias(dts, data_tagger_t, t[0]);
                  }
              case /* Var */2 :
                  var match$4 = t[0][/* var_type */0];
                  if (typeof match$4 === "number" || match$4.tag !== /* Record_readonly */1) {
                    return raise_error(/* () */0);
                  } else {
                    return /* tuple */[
                            match$4[0],
                            match$4[1]
                          ];
                  }
              default:
                return raise_error(/* () */0);
            }
          }
        };
      } else {
        return raise_error(/* () */0);
      }
    } else {
      return raise_error(/* () */0);
    }
  }
}

function get_all_binds(dts, data_tagger) {
  var data_tagger_t = data_tagger[1];
  var get_bind_from_t = function (_pt) {
    while(true) {
      var pt = _pt;
      if (typeof pt === "number") {
        return ;
      } else {
        switch (pt.tag | 0) {
          case /* Term */0 :
              var match = pt[1];
              if (match) {
                if (match[1]) {
                  return ;
                } else {
                  _pt = match[0];
                  continue ;
                }
              } else {
                var _a = Plate$BwaxMobile.List.assoc(pt[0], dts);
                if (_a !== undefined) {
                  var match$1 = _a;
                  if (typeof match$1 === "number" || match$1.tag !== /* Alias */1) {
                    return ;
                  } else {
                    var match$2 = match$1[0];
                    if (match$2[0]) {
                      return ;
                    } else {
                      var s = Lang_typing_base$BwaxMobile.shorten(match$2[1]);
                      if (typeof s === "number" || s.tag !== /* Var */2) {
                        return ;
                      } else {
                        var match$3 = s[0][/* var_type */0];
                        if (typeof match$3 === "number" || match$3.tag !== /* Record_readonly */1) {
                          return ;
                        } else {
                          return /* tuple */[
                                  match$3[0],
                                  match$3[1]
                                ];
                        }
                      }
                    }
                  }
                } else {
                  return ;
                }
              }
          case /* Var */2 :
              var match$4 = pt[0][/* var_type */0];
              if (typeof match$4 === "number" || match$4.tag !== /* Record_readonly */1) {
                return ;
              } else {
                return /* tuple */[
                        match$4[0],
                        match$4[1]
                      ];
              }
          default:
            return ;
        }
      }
    };
  };
  if (typeof data_tagger_t !== "number" && !data_tagger_t.tag && data_tagger_t[0] === "Arrow") {
    var match = data_tagger_t[1];
    if (match) {
      var match$1 = match[1];
      if (match$1 && !match$1[1]) {
        var pt = match[0];
        if (typeof pt === "number" || pt.tag || pt[0] !== "Tuple") {
          return /* :: */[
                  get_bind_from_t(pt),
                  /* [] */0
                ];
        } else {
          return Plate$BwaxMobile.List.map(get_bind_from_t, pt[1]);
        }
      }
      
    }
    
  }
  throw [
        Runtime_common$BwaxMobile.Runtime_exn,
        "Invalid data tagger: " + Lang_typing_print$BwaxMobile.string_of_term(dts, data_tagger_t)
      ];
}

function if_term_matters(t) {
  return false;
}

function get_entity_by_tname(entity_dict, tname) {
  var by_tname = function (param) {
    return tname === Base_query_types$BwaxMobile.tname_of(param[0]);
  };
  var match = Plate$BwaxMobile.List.find(by_tname, entity_dict);
  if (match !== undefined) {
    return match[1];
  } else {
    throw [
          Runtime_common$BwaxMobile.Runtime_exn,
          "I can't find entity for " + tname
        ];
  }
}

function get_query_from_cmd(context, param) {
  var dts = context[/* dts */2];
  var data_type_dict = context[/* data_type_dict */1];
  var entity_dict = context[/* entity_dict */0];
  var cmd = param[0];
  if (typeof cmd === "number" || cmd.tag !== /* V_tagged */6) {
    return ;
  } else {
    switch (cmd[0]) {
      case "Cmd_Query_aggregate" :
          var match = cmd[1];
          if (match) {
            var match$1 = match[0][0];
            if (typeof match$1 === "number" || match$1.tag !== /* V_literal */5) {
              return ;
            } else {
              var match$2 = match$1[0];
              if (typeof match$2 === "number" || match$2.tag !== /* String */2) {
                return ;
              } else {
                var match$3 = match[1];
                if (match$3) {
                  var match$4 = match$3[0][0];
                  if (typeof match$4 === "number" || match$4.tag !== /* V_record */2) {
                    return ;
                  } else {
                    var match$5 = match$3[1];
                    if (match$5) {
                      var match$6 = match$5[1];
                      if (match$6 && !match$6[1]) {
                        var error_tagger = match$6[0];
                        var data_tagger = match$5[0];
                        var entity = get_entity_by_tname(entity_dict, match$2[0]);
                        var query = Defs_aggregate$BwaxMobile.params_to_query_option(entity, match$4[0]);
                        var apply_it = function (r) {
                          return apply_result(data_tagger, error_tagger, r);
                        };
                        return /* tuple */[
                                query,
                                apply_it
                              ];
                      } else {
                        return ;
                      }
                    } else {
                      return ;
                    }
                  }
                } else {
                  return ;
                }
              }
            }
          } else {
            return ;
          }
      case "Cmd_Query_count" :
          var match$7 = cmd[1];
          if (match$7) {
            var match$8 = match$7[0][0];
            if (typeof match$8 === "number" || match$8.tag !== /* V_literal */5) {
              return ;
            } else {
              var match$9 = match$8[0];
              if (typeof match$9 === "number" || match$9.tag !== /* String */2) {
                return ;
              } else {
                var match$10 = match$7[1];
                if (match$10) {
                  var match$11 = match$10[1];
                  if (match$11) {
                    var match$12 = match$11[1];
                    if (match$12 && !match$12[1]) {
                      var error_tagger$1 = match$12[0];
                      var data_tagger$1 = match$11[0];
                      var match$13 = Base_query_types$BwaxMobile.to_query_param(match$10[0]);
                      var entity$1 = get_entity_by_tname(entity_dict, match$9[0]);
                      var query_001 = /* tuple */[
                        match$13[0],
                        match$13[2]
                      ];
                      var query$1 = /* Q_count */Block.__(3, [
                          entity$1,
                          query_001
                        ]);
                      var apply_it$1 = function (r) {
                        return apply_result(data_tagger$1, error_tagger$1, r);
                      };
                      return /* tuple */[
                              query$1,
                              apply_it$1
                            ];
                    } else {
                      return ;
                    }
                  } else {
                    return ;
                  }
                } else {
                  return ;
                }
              }
            }
          } else {
            return ;
          }
      case "Cmd_Query_custom_query" :
          var match$14 = cmd[1];
          if (match$14) {
            var match$15 = match$14[0][0];
            if (typeof match$15 === "number" || match$15.tag !== /* V_literal */5) {
              return ;
            } else {
              var match$16 = match$15[0];
              if (typeof match$16 === "number" || match$16.tag !== /* String */2) {
                return ;
              } else {
                var match$17 = match$14[1];
                if (match$17) {
                  var match$18 = match$17[0][0];
                  if (typeof match$18 === "number" || match$18.tag !== /* V_literal */5) {
                    return ;
                  } else {
                    var match$19 = match$18[0];
                    if (typeof match$19 === "number" || match$19.tag !== /* String */2) {
                      return ;
                    } else {
                      var match$20 = match$17[1];
                      if (match$20) {
                        var match$21 = match$20[0][0];
                        if (typeof match$21 === "number" || match$21.tag !== /* V_list */1) {
                          return ;
                        } else {
                          var match$22 = match$20[1];
                          if (match$22) {
                            var match$23 = match$22[1];
                            if (match$23 && !match$23[1]) {
                              var error_tagger$2 = match$23[0];
                              var data_tagger$2 = match$22[0];
                              var params = match$21[0];
                              var interface_name = match$19[0];
                              var entity_name = match$16[0];
                              var all_binds = get_all_binds(dts, data_tagger$2);
                              var to_selection$1 = function (param) {
                                var entity = get_entity_by_tname(entity_dict, param[0]);
                                var selection = to_selection(param[1]);
                                return /* tuple */[
                                        entity,
                                        selection
                                      ];
                              };
                              var partial_arg = Plate$BwaxMobile.$$Option.map;
                              var all_selections = Plate$BwaxMobile.List.map((function (param) {
                                      return partial_arg(to_selection$1, param);
                                    }), all_binds);
                              var entity$2 = Base_query_types$BwaxMobile.get_entity(entity_dict, entity_name);
                              var match$24 = Plate$BwaxMobile.List.find((function (i) {
                                      return i[/* name */0] === interface_name;
                                    }), entity$2[/* interfaces */4]);
                              var $$interface;
                              if (match$24 !== undefined) {
                                $$interface = match$24;
                              } else {
                                throw [
                                      Runtime_common$BwaxMobile.Runtime_exn,
                                      "I cannot find interface " + (interface_name + (" in entity " + entity_name))
                                    ];
                              }
                              var inputs = to_interface_inputs(entity_dict, data_type_dict, $$interface[/* input_types */3], params);
                              var match$25 = Plate$BwaxMobile.List.head(Plate$BwaxMobile.List.rev(params));
                              var maybe_id = $$interface[/* is_targeting_record */5] && match$25 !== undefined ? Caml_option.some(to_interface_input_value(entity_dict, data_type_dict, /* Intf_string */3, match$25)) : undefined;
                              var outputs = Plate$BwaxMobile.List.combine($$interface[/* output_types */4], all_selections);
                              var query$2 = /* Q_custom_query */Block.__(6, [
                                  $$interface,
                                  maybe_id,
                                  inputs,
                                  outputs
                                ]);
                              var apply_it$2 = function (r) {
                                return apply_result(data_tagger$2, error_tagger$2, r);
                              };
                              return /* tuple */[
                                      query$2,
                                      apply_it$2
                                    ];
                            } else {
                              return ;
                            }
                          } else {
                            return ;
                          }
                        }
                      } else {
                        return ;
                      }
                    }
                  }
                } else {
                  return ;
                }
              }
            }
          } else {
            return ;
          }
      case "Cmd_Query_findOne" :
          var match$26 = cmd[1];
          if (match$26) {
            var match$27 = match$26[1];
            if (match$27) {
              var match$28 = match$27[1];
              if (match$28 && !match$28[1]) {
                var error_tagger$3 = match$28[0];
                var data_tagger$3 = match$27[0];
                var match$29 = get_binds(dts, data_tagger$3);
                var match$30 = Base_query_types$BwaxMobile.to_query_param(match$26[0]);
                var entity$3 = get_entity_by_tname(entity_dict, match$29[0]);
                var selection = to_selection(match$29[1]);
                var query_001$1 = /* tuple */[
                  match$30[0],
                  match$30[1],
                  match$30[2]
                ];
                var query$3 = /* Q_find_one */Block.__(0, [
                    entity$3,
                    query_001$1,
                    selection
                  ]);
                var apply_it$3 = function (r) {
                  return apply_result(data_tagger$3, error_tagger$3, r);
                };
                return /* tuple */[
                        query$3,
                        apply_it$3
                      ];
              } else {
                return ;
              }
            } else {
              return ;
            }
          } else {
            return ;
          }
      case "Cmd_Query_get_current_user" :
          var match$31 = cmd[1];
          if (match$31) {
            var match$32 = match$31[1];
            if (match$32 && !match$32[1]) {
              var error_tagger$4 = match$32[0];
              var data_tagger$4 = match$31[0];
              var match$33 = get_binds(dts, data_tagger$4);
              var selection$1 = to_selection(match$33[1]);
              var query$4 = /* Q_get_current_user */Block.__(5, [selection$1]);
              var apply_it$4 = function (r) {
                return apply_result(data_tagger$4, error_tagger$4, r);
              };
              return /* tuple */[
                      query$4,
                      apply_it$4
                    ];
            } else {
              return ;
            }
          } else {
            return ;
          }
      case "Cmd_Query_list" :
          var match$34 = cmd[1];
          if (match$34) {
            var match$35 = match$34[1];
            if (match$35) {
              var match$36 = match$35[1];
              if (match$36 && !match$36[1]) {
                var error_tagger$5 = match$36[0];
                var data_tagger$5 = match$35[0];
                var match$37 = get_binds(dts, data_tagger$5);
                var match$38 = Base_query_types$BwaxMobile.to_query_param(match$34[0]);
                var selection$2 = to_selection(match$37[1]);
                var entity$4 = get_entity_by_tname(entity_dict, match$37[0]);
                var query_001$2 = /* tuple */[
                  match$38[0],
                  match$38[1],
                  match$38[2],
                  match$38[3]
                ];
                var query$5 = /* Q_list */Block.__(1, [
                    entity$4,
                    query_001$2,
                    selection$2
                  ]);
                var apply_it$5 = function (r) {
                  return apply_result(data_tagger$5, error_tagger$5, r);
                };
                return /* tuple */[
                        query$5,
                        apply_it$5
                      ];
              } else {
                return ;
              }
            } else {
              return ;
            }
          } else {
            return ;
          }
      case "Cmd_Query_listAll" :
          var match$39 = cmd[1];
          if (match$39) {
            var match$40 = match$39[1];
            if (match$40) {
              var match$41 = match$40[1];
              if (match$41 && !match$41[1]) {
                var error_tagger$6 = match$41[0];
                var data_tagger$6 = match$40[0];
                var match$42 = get_binds(dts, data_tagger$6);
                var match$43 = Base_query_types$BwaxMobile.to_query_param(match$39[0]);
                var selection$3 = to_selection(match$42[1]);
                var entity$5 = get_entity_by_tname(entity_dict, match$42[0]);
                var query_001$3 = /* tuple */[
                  match$43[0],
                  match$43[1],
                  match$43[2],
                  match$43[3]
                ];
                var query$6 = /* Q_listAll */Block.__(2, [
                    entity$5,
                    query_001$3,
                    selection$3
                  ]);
                var apply_it$6 = function (r) {
                  return apply_result(data_tagger$6, error_tagger$6, r);
                };
                return /* tuple */[
                        query$6,
                        apply_it$6
                      ];
              } else {
                return ;
              }
            } else {
              return ;
            }
          } else {
            return ;
          }
      default:
        return ;
    }
  }
}

function handle_query_cmd(context, param, on_trunk_msgs, _on_branch_msgs) {
  var match = get_query_from_cmd(context, /* tuple */[
        param[0],
        param[1]
      ]);
  if (match !== undefined) {
    var match$1 = match;
    var apply_it = match$1[1];
    perform_single_query(context, match$1[0], (function (param) {
            if (param.tag) {
              return Curry._1(on_trunk_msgs, /* Error */Block.__(1, [param[0]]));
            } else {
              try {
                var msg = Curry._1(apply_it, param[0]);
                return Curry._1(on_trunk_msgs, /* Ok */Block.__(0, [/* :: */[
                                msg,
                                /* [] */0
                              ]]));
              }
              catch (raw_ex){
                var ex = Caml_js_exceptions.internalToOCamlException(raw_ex);
                return Curry._1(on_trunk_msgs, /* Error */Block.__(1, [ex]));
              }
            }
          }));
    return true;
  } else {
    return false;
  }
}

function handle_cud_cmd(context, param, on_trunk_msgs, _on_branch_msgs) {
  var dts = context[/* dts */2];
  var cmd = param[0];
  var handle_result = function (apply_it, param) {
    if (param.tag) {
      return Curry._1(on_trunk_msgs, /* Error */Block.__(1, [param[0]]));
    } else {
      try {
        var msg = Curry._1(apply_it, param[0]);
        return Curry._1(on_trunk_msgs, /* Ok */Block.__(0, [/* :: */[
                        msg,
                        /* [] */0
                      ]]));
      }
      catch (raw_ex){
        var ex = Caml_js_exceptions.internalToOCamlException(raw_ex);
        return Curry._1(on_trunk_msgs, /* Error */Block.__(1, [ex]));
      }
    }
  };
  var data_type_dict = context[/* data_type_dict */1];
  var entity_dict = context[/* entity_dict */0];
  var query_context = Query_builder$BwaxMobile.runtime_to_query_context(context);
  if (typeof cmd === "number" || cmd.tag !== /* V_tagged */6) {
    return false;
  } else {
    switch (cmd[0]) {
      case "Cmd_Query_add" :
          var match = cmd[1];
          if (match) {
            var match$1 = match[1];
            if (match$1) {
              var match$2 = match$1[1];
              if (match$2 && !match$2[1]) {
                var error_tagger = match$2[0];
                var data_tagger = match$1[0];
                try {
                  var match$3 = get_binds(dts, data_tagger);
                  var add_param = value_to_mutation_input(entity_dict, data_type_dict, match[0]);
                  var selection = to_selection(match$3[1]);
                  var entity = get_entity_by_tname(entity_dict, match$3[0]);
                  var mutation = /* M_add */Block.__(0, [
                      entity,
                      add_param,
                      selection
                    ]);
                  var apply_it = function (r) {
                    return apply_result(data_tagger, error_tagger, r);
                  };
                  Query_builder$BwaxMobile.resolve_and_run_mutation(query_context, mutation, Query_builder$BwaxMobile.default_query_runner_option, (function (param) {
                          return handle_result(apply_it, param);
                        }));
                }
                catch (raw_e){
                  var e = Caml_js_exceptions.internalToOCamlException(raw_e);
                  Curry._1(on_trunk_msgs, /* Error */Block.__(1, [e]));
                }
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          } else {
            return false;
          }
      case "Cmd_Query_custom_operation" :
          var match$4 = cmd[1];
          if (match$4) {
            var match$5 = match$4[0][0];
            if (typeof match$5 === "number" || match$5.tag !== /* V_literal */5) {
              return false;
            } else {
              var match$6 = match$5[0];
              if (typeof match$6 === "number" || match$6.tag !== /* String */2) {
                return false;
              } else {
                var match$7 = match$4[1];
                if (match$7) {
                  var match$8 = match$7[0][0];
                  if (typeof match$8 === "number" || match$8.tag !== /* V_literal */5) {
                    return false;
                  } else {
                    var match$9 = match$8[0];
                    if (typeof match$9 === "number" || match$9.tag !== /* String */2) {
                      return false;
                    } else {
                      var match$10 = match$7[1];
                      if (match$10) {
                        var match$11 = match$10[0][0];
                        if (typeof match$11 === "number" || match$11.tag !== /* V_list */1) {
                          return false;
                        } else {
                          var match$12 = match$10[1];
                          if (match$12) {
                            var match$13 = match$12[1];
                            if (match$13 && !match$13[1]) {
                              var error_tagger$1 = match$13[0];
                              var data_tagger$1 = match$12[0];
                              var params = match$11[0];
                              var interface_name = match$9[0];
                              var entity_name = match$6[0];
                              try {
                                var all_binds = get_all_binds(dts, data_tagger$1);
                                var to_selection$1 = function (param) {
                                  var entity = get_entity_by_tname(entity_dict, param[0]);
                                  var selection = to_selection(param[1]);
                                  return /* tuple */[
                                          entity,
                                          selection
                                        ];
                                };
                                var partial_arg = Plate$BwaxMobile.$$Option.map;
                                var all_selections = Plate$BwaxMobile.List.map((function (param) {
                                        return partial_arg(to_selection$1, param);
                                      }), all_binds);
                                var entity$1 = Base_query_types$BwaxMobile.get_entity(entity_dict, entity_name);
                                var match$14 = Plate$BwaxMobile.List.find((function (i) {
                                        return i[/* name */0] === interface_name;
                                      }), entity$1[/* interfaces */4]);
                                var $$interface;
                                if (match$14 !== undefined) {
                                  $$interface = match$14;
                                } else {
                                  throw [
                                        Runtime_common$BwaxMobile.Runtime_exn,
                                        "I cannot find interface " + (interface_name + (" in entity " + entity_name))
                                      ];
                                }
                                var inputs = to_interface_inputs(entity_dict, data_type_dict, $$interface[/* input_types */3], params);
                                var outputs = Plate$BwaxMobile.List.combine($$interface[/* output_types */4], all_selections);
                                var match$15 = Plate$BwaxMobile.List.head(Plate$BwaxMobile.List.rev(params));
                                var maybe_id = $$interface[/* is_targeting_record */5] && match$15 !== undefined ? Caml_option.some(to_interface_input_value(entity_dict, data_type_dict, /* Intf_string */3, match$15)) : undefined;
                                var mutation$1 = /* M_custom_operation */Block.__(4, [
                                    $$interface,
                                    maybe_id,
                                    inputs,
                                    outputs
                                  ]);
                                var apply_it$1 = function (r) {
                                  return apply_result(data_tagger$1, error_tagger$1, r);
                                };
                                Query_builder$BwaxMobile.resolve_and_run_mutation(query_context, mutation$1, Query_builder$BwaxMobile.default_query_runner_option, (function (param) {
                                        return handle_result(apply_it$1, param);
                                      }));
                              }
                              catch (raw_e$1){
                                var e$1 = Caml_js_exceptions.internalToOCamlException(raw_e$1);
                                Curry._1(on_trunk_msgs, /* Error */Block.__(1, [e$1]));
                              }
                              return true;
                            } else {
                              return false;
                            }
                          } else {
                            return false;
                          }
                        }
                      } else {
                        return false;
                      }
                    }
                  }
                } else {
                  return false;
                }
              }
            }
          } else {
            return false;
          }
      case "Cmd_Query_delete" :
          var match$16 = cmd[1];
          if (match$16) {
            var match$17 = match$16[0][0];
            if (typeof match$17 === "number" || match$17.tag !== /* V_literal */5) {
              return false;
            } else {
              var match$18 = match$17[0];
              if (typeof match$18 === "number" || match$18.tag !== /* String */2) {
                return false;
              } else {
                var match$19 = match$16[1];
                if (match$19) {
                  var match$20 = match$19[0][0];
                  if (typeof match$20 === "number" || match$20.tag !== /* V_literal */5) {
                    return false;
                  } else {
                    var match$21 = match$20[0];
                    if (typeof match$21 === "number" || match$21.tag !== /* String */2) {
                      return false;
                    } else {
                      var match$22 = match$19[1];
                      if (match$22) {
                        var match$23 = match$22[1];
                        if (match$23 && !match$23[1]) {
                          var error_tagger$2 = match$23[0];
                          var data_tagger$2 = match$22[0];
                          try {
                            var entity$2 = get_entity_by_tname(entity_dict, match$18[0]);
                            var mutation_001 = match$21[0];
                            var mutation$2 = /* M_delete */Block.__(2, [
                                entity$2,
                                mutation_001
                              ]);
                            var apply_it$2 = function (r) {
                              return apply_result(data_tagger$2, error_tagger$2, r);
                            };
                            Query_builder$BwaxMobile.resolve_and_run_mutation(query_context, mutation$2, Query_builder$BwaxMobile.default_query_runner_option, (function (param) {
                                    return handle_result(apply_it$2, param);
                                  }));
                          }
                          catch (raw_e$2){
                            var e$2 = Caml_js_exceptions.internalToOCamlException(raw_e$2);
                            Curry._1(on_trunk_msgs, /* Error */Block.__(1, [e$2]));
                          }
                          return true;
                        } else {
                          return false;
                        }
                      } else {
                        return false;
                      }
                    }
                  }
                } else {
                  return false;
                }
              }
            }
          } else {
            return false;
          }
      case "Cmd_Query_remat" :
          var match$24 = cmd[1];
          if (match$24) {
            var match$25 = match$24[0][0];
            if (typeof match$25 === "number" || match$25.tag !== /* V_literal */5) {
              return false;
            } else {
              var match$26 = match$25[0];
              if (typeof match$26 === "number" || match$26.tag !== /* String */2) {
                return false;
              } else {
                var match$27 = match$24[1];
                if (match$27) {
                  var match$28 = match$27[0][0];
                  if (typeof match$28 === "number" || match$28.tag !== /* V_record */2) {
                    return false;
                  } else {
                    var match$29 = match$27[1];
                    if (match$29) {
                      var match$30 = match$29[1];
                      if (match$30 && !match$30[1]) {
                        var error_tagger$3 = match$30[0];
                        var data_tagger$3 = match$29[0];
                        var params$1 = match$28[0];
                        try {
                          var criteria = Plate$BwaxMobile.$$Option.map(Base_query_types$BwaxMobile.to_criteria, Plate$BwaxMobile.List.assoc("criteria", params$1));
                          var fields = Plate$BwaxMobile.List.assoc_keys(Plate$BwaxMobile.List.filter((function (param) {
                                        var match = param[1][0];
                                        if (typeof match === "number" || match.tag !== /* V_literal */5) {
                                          return false;
                                        } else {
                                          var match$1 = match[0];
                                          if (typeof match$1 === "number" || !(match$1.tag === /* Bool */4 && match$1[0])) {
                                            return false;
                                          } else {
                                            return true;
                                          }
                                        }
                                      }))(Plate$BwaxMobile.$$Option.with_default(/* [] */0, Plate$BwaxMobile.$$Option.map(Runtime_common$BwaxMobile.extract_record, Plate$BwaxMobile.List.assoc("remat", params$1)))));
                          var entity$3 = get_entity_by_tname(entity_dict, match$26[0]);
                          var mutation_001$1 = /* tuple */[
                            criteria,
                            fields
                          ];
                          var mutation$3 = /* M_remat */Block.__(3, [
                              entity$3,
                              mutation_001$1
                            ]);
                          var apply_it$3 = function (result) {
                            var match = result[0];
                            if (match !== undefined && result[1] === undefined) {
                              return Lang_eval$BwaxMobile.apply_value(undefined, data_tagger$3, match, Runtime_common$BwaxMobile.nt);
                            }
                            var match$1 = result[1];
                            if (match$1 !== undefined) {
                              return Lang_eval$BwaxMobile.apply_value(undefined, error_tagger$3, match$1, Runtime_common$BwaxMobile.nt);
                            } else {
                              throw [
                                    Runtime_common$BwaxMobile.Runtime_exn,
                                    "No error and no data"
                                  ];
                            }
                          };
                          Query_builder$BwaxMobile.resolve_and_run_mutation(query_context, mutation$3, Query_builder$BwaxMobile.default_query_runner_option, (function (param) {
                                  return handle_result(apply_it$3, param);
                                }));
                        }
                        catch (raw_e$3){
                          var e$3 = Caml_js_exceptions.internalToOCamlException(raw_e$3);
                          Curry._1(on_trunk_msgs, /* Error */Block.__(1, [e$3]));
                        }
                        return true;
                      } else {
                        return false;
                      }
                    } else {
                      return false;
                    }
                  }
                } else {
                  return false;
                }
              }
            }
          } else {
            return false;
          }
      case "Cmd_Query_update" :
          var match$31 = cmd[1];
          if (match$31) {
            var match$32 = match$31[1];
            if (match$32) {
              var match$33 = match$32[0][0];
              if (typeof match$33 === "number" || match$33.tag !== /* V_literal */5) {
                return false;
              } else {
                var match$34 = match$33[0];
                if (typeof match$34 === "number" || match$34.tag !== /* String */2) {
                  return false;
                } else {
                  var match$35 = match$32[1];
                  if (match$35) {
                    var match$36 = match$35[1];
                    if (match$36 && !match$36[1]) {
                      var error_tagger$4 = match$36[0];
                      var data_tagger$4 = match$35[0];
                      try {
                        var match$37 = get_binds(dts, data_tagger$4);
                        var add_param$1 = value_to_mutation_input(entity_dict, data_type_dict, match$31[0]);
                        var selection$1 = to_selection(match$37[1]);
                        var entity$4 = get_entity_by_tname(entity_dict, match$37[0]);
                        var mutation_001$2 = /* tuple */[
                          add_param$1,
                          match$34[0]
                        ];
                        var mutation$4 = /* M_update */Block.__(1, [
                            entity$4,
                            mutation_001$2,
                            selection$1
                          ]);
                        var apply_it$4 = function (r) {
                          return apply_result(data_tagger$4, error_tagger$4, r);
                        };
                        Query_builder$BwaxMobile.resolve_and_run_mutation(query_context, mutation$4, Query_builder$BwaxMobile.default_query_runner_option, (function (param) {
                                return handle_result(apply_it$4, param);
                              }));
                      }
                      catch (raw_e$4){
                        var e$4 = Caml_js_exceptions.internalToOCamlException(raw_e$4);
                        Curry._1(on_trunk_msgs, /* Error */Block.__(1, [e$4]));
                      }
                      return true;
                    } else {
                      return false;
                    }
                  } else {
                    return false;
                  }
                }
              }
            } else {
              return false;
            }
          } else {
            return false;
          }
      default:
        return false;
    }
  }
}

var command_handlers_001 = /* :: */[
  handle_cud_cmd,
  /* [] */0
];

var command_handlers = /* :: */[
  handle_query_cmd,
  command_handlers_001
];

exports.to_interface_input_value = to_interface_input_value;
exports.to_interface_inputs = to_interface_inputs;
exports.value_to_mutation_input = value_to_mutation_input;
exports.to_selection = to_selection;
exports.print_selection = print_selection;
exports.perform_single_query = perform_single_query;
exports.perform_batch_query = perform_batch_query;
exports.apply_result = apply_result;
exports.get_binds_from = get_binds_from;
exports.get_binds_with_alias = get_binds_with_alias;
exports.get_binds = get_binds;
exports.get_direct_bind = get_direct_bind;
exports.get_all_binds = get_all_binds;
exports.if_term_matters = if_term_matters;
exports.get_entity_by_tname = get_entity_by_tname;
exports.get_query_from_cmd = get_query_from_cmd;
exports.handle_query_cmd = handle_query_cmd;
exports.handle_cud_cmd = handle_cud_cmd;
exports.command_handlers = command_handlers;
/* Plate-BwaxMobile Not a pure module */
