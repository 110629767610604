

import React, { useState, useEffect } from 'react'

import sha1 from 'crypto-js/sha1';
import hex from 'crypto-js/enc-hex';
import Button, { Pressable } from 'bwax-ui/components/Button';

import Loading from 'bwax-ui/components/Loading';
import { copy } from "bwax-ui/ml/FrontEndHelper";
import { toast } from 'bwax-ui/components/Toast';


export async function loadReferralCode({ facade, currentUser}) {
    const entityName = "邀请码";
    const [result, error] = await facade.findOne({
        entityName, condition: [[{ field: "用户.id", op: "eq", value: currentUser.id }]],
        fieldPaths: ["内容"]
    });
    if (!error) {
        if (result == null) {            
            // // 使用  currentUserId 和 currentUser nickname 进行 sha1 得到 hex 格式
            const fullCode = sha1("u::" + currentUser.id + "::" + currentUser.nickName).toString(hex);

            const [result, error] = await facade.customMutation({
                entityName,
                interfaceName: "生成",
                args: [fullCode],
                outputFieldPaths: [
                    [entityName, ["内容"]]
                ]
            });
            if (!error) {
                return result;
            }
        } else {
            return result;
        }
    }
}

export default function TranslationInvitation(props) {

    const { currentUser, facade, viewEnv, preference, reloadPreference,  } = props;

    const [referralCode, setReferralCode] = useState();

    useEffect(() => {
        (async () => {
            const referralCode = await loadReferralCode({ facade, currentUser, setReferralCode });
            setReferralCode(referralCode);
        })();

        if (!(preference && preference.hasReadReferralCode) && reloadPreference) {
            (async () => {
                const [_, error] = await savePreference({ name: "hasReadReferralCode", value: true, facade })
                if (!error) {
                    reloadPreference();
                }
            })();
        };

    }, []);

    function doCopy(url, name) {
        copy(url)
        toast({ title: "已复制" + name, duration: 2000 });
    }

    const host = "translate.simplifyai.cn";
    const url = referralCode ? `https://${host}${viewEnv.domainEnv.isSandbox ? "/sandbox" : ""}/?ur=${referralCode.内容.toLowerCase()}` : ""

    return (
        <div className="px-6 sm:px-2 py-4 flex flex-col gap-4">
            <i className='bx bx-share-alt text-5xl text-[var(--violet10)]'></i>
            {referralCode ?
                (
                    <div className="py-2 gap-5 flex flex-col ">
                        <div className="flex flex-col gap-5">
                            <div>
                                邀请朋友使用，当 ta 第一次消费积分的时候，您将获得 <span className="m-1 font-medium font-size-16 text-[var(--violet11)]">10</span> 积分🎁
                            </div>
                            <div className="break-all">
                                请让 ta 通过 <span className="text-[var(--violet11)]">{url}</span>  <Pressable onPress={_ => doCopy(url, "邀请链接")}>
                                    <span className="px-1 py-1 translate-y-[1px] cursor-pointer">
                                        <i className='bx bx-copy-alt'></i>
                                    </span>
                                </Pressable> 注册使用，系统会自动记录你们之间的邀请关系🍺
                            </div>
                            <div className="break-all">
                                您也可以分享翻译结果给朋友，如果 ta 进入您分享的页面后成功注册，系统也会记录你们之间的邀请关系🎖
                            </div>
                        </div>
                        <div className="py-2 flex gap-2">
                            <Button onPress={_ => doCopy(url, "邀请链接")} className="gap-2" size={"large"} color="violet">
                                <i className='bx bx-copy font-size-12'></i>
                                复制邀请链接
                            </Button>
                            <Button onPress={_ => doCopy(getAdText(url), "示例文案")} className="gap-2" size={"large"}>
                                <i className='bx bx-copy font-size-12'></i>
                                复制示例文案
                            </Button>
                        </div>
                    </div>
                )
                : (
                    <div className="h-[174px] w-full flex justify-center items-center">
                        <Loading />
                    </div>
                )
            }
        </div>
    )
}

function getAdText(url) {
    return `横向比较，这可能是市面上效果最好的文档翻译了。基于大语言模型，准确通顺，保留原文档格式，媲美人工翻译+精心编辑的效果。

使用地址：${url}

有PDF/Word/PPT/Excel/ePub电子书/电影字幕等文档翻译需求的可以试试。附图是一些翻译例子，点击上面链接可以看到更多`
}


async function savePreference({ name, value, facade }) {

    const [result, error] = await facade.customMutation({
        entityName: "个人使用偏好",
        interfaceName: "保存",
        args: [name, value],
        outputFieldPaths: []
    });
    if (error) {
        // error handling
        console.error(error);
    }

    return [result, error];

}
