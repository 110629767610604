
const dateFormat = require('dateformat')

const dayjs = require('dayjs');

const weekYearExt = require('dayjs/plugin/weekYear')
const weekOfYear = require('dayjs/plugin/weekOfYear')

dayjs.extend(weekYearExt);
dayjs.extend(weekOfYear);


const quarterOfYear = require('dayjs/plugin/quarterOfYear')
dayjs.extend(quarterOfYear)

const dayOfYear = require('dayjs/plugin/dayOfYear')
dayjs.extend(dayOfYear)


// export function format (format, value) {
//     return dateFormat(value, format)
// }


export function format(format, date) {
    return dayjs(date).format(format)
}

export function parse(format, string) {
    return dayjs(string, format).toDate();
}

export function parseISO(string) {
    console.log(string);
    console.log(dayjs(string));
    console.log(dayjs(string).toDate())
    return dayjs(string).toDate();
}


// -- Extract --
// -- The calendar year
// year: Date -> Int = external;
export function year(date) {
    return dayjs(date).year()
}


// -- The week numbering year.
// weekYear: Date -> Int = external;
export function weekYear(date) {
    return dayjs(date).weekYear()
}


// -- Month as 0 - 11
// month: Date -> Int = external;
export function month(date) {
    return dayjs(date).month()
}


// -- The date of the month (1 - 31)
// date: Date -> Int = external;
export function date(d) {
    return dayjs(d).date()
}


// -- The ISO week number of the year (1 - 53)
// weekNumber: Date -> Int = external;
export function weekNumber(d) {
    return dayjs(d).week();
}

// -- The week day 0 - 6
// weekDay: Date -> Int = external;
export function weekDay(d) {
    return dayjs(d).day();
}


// -- The day of the year (1 - 366)
// ordinalDay: Date -> Int = external;
export function ordinalDay(d) {
    return dayjs(d).dayOfYear();
}


// -- The quarter (1 - 4)
// quarter: Date -> Int = external;
export function quarter(d) {
    return dayjs(d).quarter();
}

// --- Operations

// type Unit = Day | Date | Week | Month | Quarter | Year | Hour | Minute | Second | Millisecond;

// -- Get specific part of the date = external;
// get: Unit -> Date -> Int = external;
export function get(unit, date) {
    // Week 和 Quarter 要专门处理
    const d = dayjs(date);
    if (unit == "Week") {
        return d.week()
    } else if (unit == "Quarter") {
        return d.quarter()
    } else {
        return d.get(unit)
    }
}


// -- Set specific part of the date
// set: Unit -> Int -> Date -> Date = external;
export function set(unit, v, date) {
    // Week 和 Quarter 要专门处理
    const d = dayjs(date);
    if (unit == "Week") {
        return d.week(v).toDate()
    } else if (unit == "Quarter") {
        return d.quarter(v).toDate()
    } else {
        return d.set(unit, v).toDate();
    }
}


// -- Add some unit of time to the date.
// add: Unit -> Int -> Date -> Date = external;
export function add(unit, v, date) {
    // Date 需要专门处理，因为 dayjs 在 Add 操作只支持 day
    const d = dayjs(date);
    if (unit == "Date") {
        return d.add(v, "Day").toDate();
    } else {
        return d.add(v, unit).toDate();
    }
}

// -- Subtract some unit of time for the date
// subtract: Unit -> Int -> Date -> Date = external;
export function subtract(unit, v, date) {
    // Date 需要专门处理，因为 dayjs 在 Subtract 操作只支持 day
    const d = dayjs(date);
    if (unit == "Date") {
        return d.subtract(v, "Day").toDate();
    } else {
        return d.subtract(v, unit).toDate();
    }
}



// -- Get the difference, as a number of whole units, between two dates.
// diff: Unit -> Date -> Date -> Int = external;
export function diff(unit, first, second) {
    // Date 需要专门处理，因为 dayjs 在 Subtract 操作只支持 day
    if (unit == "Date") {
        return dayjs(second).diff(dayjs(first), "Day");
    } else {
        return dayjs(second).diff(dayjs(first), unit);
    }
}


// -- Get the difference, as a accurate float number of units, between two dates.
// diffFloat: Unit -> Date -> Date -> Float = external;
export function diffFloat(unit, first, second) {
    // Date 需要专门处理，因为 dayjs 在 Subtract 操作只支持 day
    if (unit == "Date") {
        return dayjs(second).diff(dayjs(first), "Day", true);
    } else {
        return dayjs(second).diff(dayjs(first), unit, true);
    }
}



// -- Round up a date to the beginning of the closest interval. 
// -- The resulting date will be greater than or equal to the one provided.
// ceiling: Unit -> Date -> Date = external;
export function ceiling (unit, date) {
    // 如果是 Millisecond，直接返回原值
    const d = dayjs(date);
    if(unit == "Millisecond") {
        return date
    } else {
        return d.endOf(unit).toDate()
    }
}


// -- Round down a date to the beginning of the closest interval. 
// -- The resulting date will be less than or equal to the one provided.
// floor: Unit -> Date -> Date = external;
export function floor (unit, date) {
    // 如果是 Millisecond，直接返回原值
    const d = dayjs(date);
    if(unit == "Millisecond") {
        return date
    } else {
        return d.startOf(unit).toDate()
    }
}


// -- Queries
// -- Check if the second date is before the first date.
// isBefore: Date -> Date -> Bool = external;
export function isBefore (first, second) {
    return dayjs(second).isBefore(dayjs(first));
}


// -- Check if the second date is after the first date.
// isAfter: Date -> Date -> Bool = external;
export function isAfter (first, second) {
    return dayjs(second).isAfter(dayjs(first));
}


// -- Check if the second date is the same as the first date.
// isSame: Date -> Date -> Bool = external;
export function isSame (first, second) {
    return dayjs(second).isSame(dayjs(first));
}


