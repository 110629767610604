

import React, { useRef, useState } from 'react'

import Button, { Pressable } from '../Button';

export default function SelectFileButton({
    children, accept, className, style, onSelectFile, onBeforeSelect, isUploading,

    asButton = true,
    multiple = false,

    color, size, appearance,

}) {

    const inputRef = useRef();

    function fileSelected(e) {

        const files = e.target.files;

        if (files.length > 0) {
            if (multiple) {
                onSelectFile([...files])
            } else {
                const file = files[0];
                onSelectFile(file);
            }

        }

    }

    function renderHidden() {
        return (
            <input
                ref={inputRef}
                type="file"
                multiple={multiple}
                accept={accept}
                onChange={fileSelected}
                onClick={e => {
                    // https://stackoverflow.com/questions/39484895/how-to-allow-input-type-file-to-select-the-same-file-in-react-component
                    if (onBeforeSelect) {
                        onBeforeSelect(e)
                    }
                    e.target.value = null
                }}
                style={{
                    display: "none"
                }}
            />
        )
    }


    if (asButton) {
        return (
            <Button 
                style={style} className={className} color={color} size={size} appearance={appearance}
                isLoading={isUploading}                 
                onPress={() => {
                if (inputRef.current && !isUploading) {
                    inputRef.current.click()
                }
            }}>
                {children}
                {renderHidden()}
            </Button>
        )
    } else {

        return (
            <Pressable onPress={() => {
                if (inputRef.current) {
                    inputRef.current.click()
                }
            }}>
                <div style={style} className={className} data-color={color}>
                    {children}
                    {renderHidden()}
                </div>
            </Pressable>
        )
    }


}
