
/// https://www.markdownguide.org/basic-syntax

import { unified } from 'unified'
import remarkParse from 'remark-parse'
import remarkGfm from 'remark-gfm'

function parseMarkdown(md) {
    return unified()
    .use(remarkParse)
    .use(remarkGfm)
    .parse(md);
}

export function liftMediaNodes (node) {
    // 如果转换出来的 paragraph.children 中有 media 元素，要分割成多个 paragraph + media
    const mediaNodeTypes = ['image', 'video', 'audio']
    const paragraphHasImageChild = node.type === 'paragraph' && node.children && node.children.some(c => mediaNodeTypes.some(t => t === c.type))
    const liftedMediaNodes = paragraphHasImageChild ? node.children.reduce((acc, child) => {
        if(acc.length > 0 && acc[acc.length - 1].type === 'paragraph' && !mediaNodeTypes.some(t => t === child.type)) {
            const lastParagraphChildren = acc[acc.length - 1].children
            return acc.toSpliced(acc.length - 1, 1, {
                type: 'paragraph',
                children: [...lastParagraphChildren, child]
            })
        } else if (mediaNodeTypes.some(t => t === child.type)) {
            return [...acc, child]
        } else {
            return [...acc, { type: 'paragraph', children: [child]} ]
        }
    }, []) : [node]

    return liftedMediaNodes
}

export function markdownToRichText(md) {
    const tree = parseMarkdown(md);
    function convertBlock(b, attributes = {}) {

        const { children } = b;
        // if paragraph's children is only one Image, lift up        
        if(b.type == "paragraph" && children && children.length == 1 && children[0].type == "image") {
            return convertBlock(children[0]);
        }

        const node = {
            "heading": _ => {
                const type = "h" + b.depth;
                return {
                    type,
                    children: children.flatMap(convertBlock)
                }
            },
            "paragraph": _ => {
                return {
                    type: b.type,
                    children: children.flatMap(convertBlock)
                }
            },
            "text": _ => {
                // style?
                const { value } = b
                return { text: value,  ...attributes }
            },
            "strong": _ => {
                // style?
                return children.flatMap(v => convertBlock(v, { ...attributes,  bold: true }))
            },
            "emphasis": _ => {
                // style?
                return children.flatMap(v => convertBlock(v, { ...attributes,  italic: true }))
            },

            "link": _ => {
                // style?
                const { url } = b
                return {
                    type: b.type,
                    url,
                    children: children.flatMap(convertBlock),
                }
            },
            "break": _ => { 
                return { type: "break" }
            },
            "image": _ => {
                const { alt, url } = b;
                return { type: "image", alt, url }
            },

            "list": _ => {
                return {
                    type: b.ordered ? "numbered-list" : "bulleted-list",
                    children: children.flatMap(convertBlock),
                }
            },
            "listItem": _ => {
                return {
                    type: "list-item",
                    children: children.flatMap(convertBlock),
                }
            },
            "blockquote": _ => {
                // block-quote 里面不应该有 paragraph
                return {
                    type: "block-quote",
                    children: (children[0].children || [{ text: '' }]).flatMap(convertBlock),
                }
            },
            "code": _ => {
                // console.log(">>> bc ", b);
                return {
                    type: "code",
                    value: b.value,
                }
            },
            "inlineCode": _ => {
                // console.log(">>> bc ", b);
                return {
                    type: "inline-code",
                    value: b.value, 
                }
            },

            "thematicBreak": _ => {
                return { type: "hr" }
            },

            // table structure in struction is like： https://github.com/ianstormtaylor/slate/blob/main/site/examples/tables.tsx
            "table": _ => {                
                return {
                    type: "table",
                    children: children.flatMap(convertBlock)
                }
            },
            "tableRow": _ => {
                return {
                    type: "table-row",
                    children: children.flatMap(convertBlock)
                }
            },
            "tableCell": _ => {
                return {
                    type: "table-cell",
                    children: children.flatMap(convertBlock)
                }
            },
            "html": _ => {
                return {
                    type: "html-tag",
                    value: b.value
                }
            }

        }[b.type];  

        const resultNodes = node ? (() => {
            const n = node();
            if(Array.isArray(n)) {
                return n
            } else {
                return n ? liftMediaNodes(n) : []
            }

        })() : (() => {
            console.log("Not impl:", b);
            return [{ type: "not-impl:" + b.type }]
        })();

        // add leaf
        const v = resultNodes.map(n => {
            if(n.text === undefined && n.children === undefined || (n.children && n.children.length === 0)) {
                return {
                    ...n,
                    children: [{ text: ""} ]
                }
            } else {
                return n
            }
        })

        return v;

    }

    return tree.children.flatMap(convertBlock);

}

