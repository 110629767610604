// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var JsonUtils$BwaxMobile = require("../../re/utils/JsonUtils.bs.js");

var mutRequestAuthSession = "\n    mutation RequestAuthSession($input: RequestAuthSessionInput!) {\n      requestAuthSession(input: $input) {\n        authSession {\n          id\n          token\n        }\n      }\n    }\n  ";

function requestSessionToken(queryRunner, code) {
  return Curry._3(queryRunner, mutRequestAuthSession, Js_dict.fromList(/* :: */[
                    /* tuple */[
                      "input",
                      {
                        clientMutationId: new Date(),
                        code: code
                      }
                    ],
                    /* [] */0
                  ]), undefined).then((function (text) {
                var result = JsonUtils$BwaxMobile.parse(text);
                var sessionToken = JsonUtils$BwaxMobile.valueOfPath(result, /* :: */[
                      "data",
                      /* :: */[
                        "requestAuthSession",
                        /* :: */[
                          "authSession",
                          /* :: */[
                            "token",
                            /* [] */0
                          ]
                        ]
                      ]
                    ], Js_json.decodeString);
                if (sessionToken !== undefined) {
                  return Promise.resolve(sessionToken);
                } else {
                  return Promise.reject(Js_exn.raiseError("Cannot request token"));
                }
              }));
}

exports.mutRequestAuthSession = mutRequestAuthSession;
exports.requestSessionToken = requestSessionToken;
/* JsonUtils-BwaxMobile Not a pure module */
