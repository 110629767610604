import React from 'react'

class InvestIcon extends React.Component {

    render() {
        return (

            <svg width="1em" height="1em" viewBox="0 0 16 16">
                <defs>
                    <path d="M9.25956216,5.05327148 L4.54768066,5.05327148 L4.54768066,4.736 L4.54768066,0.340369396 C2.26493288,0.504214285 0.464,2.40795242 0.464,4.73246582 C0.464,7.16454158 2.43545842,9.136 4.86753418,9.136 C7.19172438,9.136 9.09525632,7.33556788 9.25956216,5.05327148 Z M4.864,-8.8817842e-15 L4.864,4.736 L9.6,4.736 C9.6,7.3516928 7.4796928,9.472 4.864,9.472 C2.2483072,9.472 0.128,7.3516928 0.128,4.736 C0.128,2.1203072 2.2483072,-8.8817842e-15 4.864,-8.8817842e-15 Z M7.68001078,0.256 C6.76102015,0.256 6.016,1.00101148 6.016,1.91998922 C6.016,2.83898274 6.76101437,3.584 7.68001078,3.584 C8.59899246,3.584 9.344,2.83898668 9.344,1.91998922 C9.344,1.00101043 8.59898957,0.256 7.68001078,0.256 Z M7.68001078,-3.73034936e-14 C8.74037447,-3.73034936e-14 9.6,0.859625532 9.6,1.91998922 C9.6,2.98037447 8.74037447,3.84 7.68001078,3.84 C6.61962553,3.84 5.76,2.98036369 5.76,1.91998922 C5.76,0.859625532 6.61963631,-3.73034936e-14 7.68001078,-3.73034936e-14 Z M8.30305378,1.79614829 L8.30305378,2.01062383 L7.86539077,2.01062383 L7.86539077,2.20393737 L8.30305378,2.20393737 L8.30305378,2.41838176 L7.86539077,2.41838176 L7.86539077,2.90171234 L7.46674974,2.90171234 L7.46674974,2.41838176 L7.03185917,2.41838176 L7.03185917,2.20393737 L7.46674974,2.20393737 L7.46674974,2.01062383 L7.03185917,2.01062383 L7.03185917,1.79614829 L7.36639117,1.79614829 L6.912,0.938287661 L7.36639117,0.938298045 L7.56151148,1.42161824 C7.61167518,1.54245348 7.64235905,1.64510684 7.6785982,1.75085455 L7.68418464,1.75085455 C7.72042379,1.65119169 7.75666294,1.53637901 7.80404381,1.41857581 L8.00196771,0.938287661 L8.448,0.938287661 L7.971315,1.79616906 L8.30306417,1.79616906 L8.30305378,1.79614829 Z" id="InvestmentIcon-path-1"></path>
                    <path d="M8,15.6 C12.1973641,15.6 15.6,12.1973641 15.6,8 C15.6,3.8026359 12.1973641,0.4 8,0.4 C3.8026359,0.4 0.4,3.8026359 0.4,8 C0.4,12.1973641 3.8026359,15.6 8,15.6 Z M8,16 C3.581722,16 0,12.418278 0,8 C0,3.581722 3.581722,0 8,0 C12.418278,0 16,3.581722 16,8 C16,12.418278 12.418278,16 8,16 Z" id="InvestmentIcon-path-3"></path>
                </defs>
                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Icon/How-it-works/投资">
                        <g>
                            <rect id="bg" fillRule="nonzero" x="0" y="0" width="16" height="16"></rect>
                            <g id="Group" transform="translate(3.200000, 3.200000)">
                                <mask id="InvestmentIcon-mask-2" fill="white">
                                    <use href="#InvestmentIcon-path-1"></use>
                                </mask>
                                <use id="Mask" fill="#173652" fillRule="nonzero" href="#InvestmentIcon-path-1"></use>
                            </g>
                            <mask id="InvestmentIcon-mask-4" fill="white">
                                <use href="#InvestmentIcon-path-3"></use>
                            </mask>
                            <use id="Mask" fill="#979797" fillRule="nonzero" href="#InvestmentIcon-path-3"></use>
                            <rect id="Rectangle" fill="#173652" fillRule="nonzero" mask="url(#InvestmentIcon-mask-4)" x="0" y="0" width="16" height="16"></rect>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export default InvestIcon
