


import React from 'react'

import * as Popover from '@radix-ui/react-popover';
import { Cross2Icon } from '@radix-ui/react-icons';

import './Popover.less'

export default function Popover_component({ onTriggerPress = _ => {}, children, content, withPortal = true }) {

    const c = (
        <Popover.Content className="lc-popover lc-base" sideOffset={5}>
            { content }
        <Popover.Close className="PopoverClose" aria-label="Close">
            <Cross2Icon />
        </Popover.Close>
        <Popover.Arrow className="PopoverArrow" />
    </Popover.Content>
    )

    return (
        <Popover.Root>
            <Popover.Trigger asChild onClick={e => {
                onTriggerPress()
            }}>
                { children }
            </Popover.Trigger>
            { withPortal ?
                (
                    <Popover.Portal>
                        { c }
                    </Popover.Portal>
                ) : c
            }

        </Popover.Root>
    )
}
