// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ClientEnv = require("bwax/clientEnv");
var Json$BwaxMobile = require("../../../bwax-js/ml/utils/json.bs.js");
var Plate$BwaxMobile = require("../../../bwax-js/ml/plate.bs.js");
var WxApi$BwaxMobile = require("../../re/legacy/binding/WxApi.bs.js");
var DomUtils$BwaxMobile = require("../../re/utils/DomUtils.bs.js");
var UrlQuery$BwaxMobile = require("../../re/legacy/binding/UrlQuery.bs.js");

var to_json_obj = Js_dict.fromList;

var qWxMpJsConfig = "\n  query ($jsApiList: [String]!, $url: String!) {\n    wxMpJsConfig( jsApiList: $jsApiList, url: $url)\n  }\n";

function queryWxMpJsConfig(query_runner, url, jsApiList) {
  return Curry._3(query_runner, qWxMpJsConfig, Js_dict.fromList(/* :: */[
                    /* tuple */[
                      "url",
                      url
                    ],
                    /* :: */[
                      /* tuple */[
                        "jsApiList",
                        jsApiList
                      ],
                      /* [] */0
                    ]
                  ]), undefined).then((function (text) {
                var wxMpJsConfig = Plate$BwaxMobile.$$Option.and_then((function (j) {
                        return Json$BwaxMobile.get_by_path(/* :: */[
                                    "data",
                                    /* :: */[
                                      "wxMpJsConfig",
                                      /* [] */0
                                    ]
                                  ], j);
                      }), Json$BwaxMobile.parse(text));
                return Promise.resolve(Plate$BwaxMobile.$$Option.and_then(Js_json.decodeObject, wxMpJsConfig));
              }));
}

var mutCreateWxPayOrder = "\n  mutation CreatePayEntityWxOrder($input: CreatePayEntityWxOrderInput!) {\n    createPayEntityWxOrder(input: $input) {\n      authCode\n      order {\n        id\n        mwebUrl\n        codeUrl\n        jsPayArgs\n      }\n    }\n  }\n";

function tradeTypeToString(tradeType) {
  switch (tradeType) {
    case /* NATIVE */0 :
        return "NATIVE";
    case /* JSAPI */1 :
        return "JSAPI";
    case /* MWEB */2 :
        return "MWEB";
    
  }
}

function now_str(param) {
  return new Date().getMilliseconds().toString();
}

function get_str_by_path(path, json) {
  return Plate$BwaxMobile.$$Option.and_then(Js_json.decodeString, Plate$BwaxMobile.$$Option.and_then((function (param) {
                    return Json$BwaxMobile.get_by_path(path, param);
                  }), json));
}

function createWxPayOrder(query_runner, body, totalFee, tradeType, forRecordId, forRecordField) {
  return Curry._3(query_runner, mutCreateWxPayOrder, Js_dict.fromList(/* :: */[
                    /* tuple */[
                      "input",
                      Js_dict.fromList(/* :: */[
                            /* tuple */[
                              "clientMutationId",
                              new Date().getMilliseconds().toString()
                            ],
                            /* :: */[
                              /* tuple */[
                                "body",
                                body
                              ],
                              /* :: */[
                                /* tuple */[
                                  "totalFee",
                                  totalFee
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "tradeType",
                                    tradeTypeToString(tradeType)
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "forRecord",
                                      Js_dict.fromList(/* :: */[
                                            /* tuple */[
                                              "id",
                                              forRecordId
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "field",
                                                forRecordField
                                              ],
                                              /* [] */0
                                            ]
                                          ])
                                    ],
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ])
                    ],
                    /* [] */0
                  ]), undefined).then((function (text) {
                var result = Json$BwaxMobile.parse(text);
                var authCode = get_str_by_path(/* :: */[
                      "data",
                      /* :: */[
                        "createPayEntityWxOrder",
                        /* :: */[
                          "authCode",
                          /* [] */0
                        ]
                      ]
                    ], result);
                var mwebUrl = get_str_by_path(/* :: */[
                      "data",
                      /* :: */[
                        "createPayEntityWxOrder",
                        /* :: */[
                          "order",
                          /* :: */[
                            "mwebUrl",
                            /* [] */0
                          ]
                        ]
                      ]
                    ], result);
                var codeUrl = get_str_by_path(/* :: */[
                      "data",
                      /* :: */[
                        "createPayEntityWxOrder",
                        /* :: */[
                          "order",
                          /* :: */[
                            "codeUrl",
                            /* [] */0
                          ]
                        ]
                      ]
                    ], result);
                var partial_arg = /* :: */[
                  "data",
                  /* :: */[
                    "createPayEntityWxOrder",
                    /* :: */[
                      "order",
                      /* :: */[
                        "jsPayArgs",
                        /* [] */0
                      ]
                    ]
                  ]
                ];
                var jsPayArgs = Plate$BwaxMobile.$$Option.and_then((function (param) {
                        return Json$BwaxMobile.get_by_path(partial_arg, param);
                      }), result);
                var id = get_str_by_path(/* :: */[
                      "data",
                      /* :: */[
                        "createPayEntityWxOrder",
                        /* :: */[
                          "order",
                          /* :: */[
                            "id",
                            /* [] */0
                          ]
                        ]
                      ]
                    ], result);
                var errorText = get_str_by_path(/* :: */[
                      "errors",
                      /* :: */[
                        "0",
                        /* :: */[
                          "message",
                          /* [] */0
                        ]
                      ]
                    ], result);
                return Promise.resolve(/* tuple */[
                            id,
                            /* record */[
                              /* authCode */authCode,
                              /* mwebUrl */mwebUrl,
                              /* codeUrl */codeUrl,
                              /* jsPayArgs */jsPayArgs
                            ],
                            errorText
                          ]);
              }));
}

function noop(param) {
  return /* () */0;
}

var paymentOrderIdStorageKey = "WxPay_adhoc_order_id";

var authCodeStorageKey = "WxPay_auth_code";

function saveLocal(paymentOrderId, authCode) {
  DomUtils$BwaxMobile.saveToLocalStorage(paymentOrderIdStorageKey, paymentOrderId);
  if (authCode !== undefined) {
    return DomUtils$BwaxMobile.saveToLocalStorage(authCodeStorageKey, authCode);
  } else {
    return /* () */0;
  }
}

function loadFromLocal(param) {
  var maybePaymentOrderId = DomUtils$BwaxMobile.getFromLocalStorage(paymentOrderIdStorageKey);
  return Plate$BwaxMobile.$$Option.map((function (poId) {
                var maybeAuthCode = DomUtils$BwaxMobile.getFromLocalStorage(authCodeStorageKey);
                return /* tuple */[
                        poId,
                        maybeAuthCode
                      ];
              }), maybePaymentOrderId);
}

function clearLocal(param) {
  DomUtils$BwaxMobile.removeFromLocalStorage(paymentOrderIdStorageKey);
  return DomUtils$BwaxMobile.removeFromLocalStorage(authCodeStorageKey);
}

var mutRefreshWxPayOrder = "\n  mutation RefreshPayEntityWxOrder($input: RefreshPayEntityWxOrderInput!) {\n    refreshPayEntityWxOrder(input: $input) {\n      order {\n        id\n        tradeState\n      }\n    }\n  }\n";

function refreshWxPay(query_runner, orderId) {
  return Curry._3(query_runner, mutRefreshWxPayOrder, Js_dict.fromList(/* :: */[
                    /* tuple */[
                      "input",
                      Js_dict.fromList(/* :: */[
                            /* tuple */[
                              "clientMutationId",
                              new Date().getMilliseconds().toString()
                            ],
                            /* :: */[
                              /* tuple */[
                                "orderId",
                                orderId
                              ],
                              /* [] */0
                            ]
                          ])
                    ],
                    /* [] */0
                  ]), undefined).then((function (text) {
                var result = Json$BwaxMobile.parse(text);
                return Promise.resolve(get_str_by_path(/* :: */[
                                "data",
                                /* :: */[
                                  "refreshPayEntityWxOrder",
                                  /* :: */[
                                    "order",
                                    /* :: */[
                                      "tradeState",
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ], result));
              }));
}

var refreshingIdStorageKey = "refreshingId";

function clearRefreshingId(param) {
  return DomUtils$BwaxMobile.removeFromLocalStorage(refreshingIdStorageKey);
}

function keepRefreshingWxPay(query_runner, orderId, onDone) {
  setTimeout((function (param) {
          refreshWxPay(query_runner, orderId).then((function (maybeState) {
                  console.log("trade state", maybeState);
                  var exit = 0;
                  if (maybeState !== undefined) {
                    var tradeState = maybeState;
                    switch (tradeState) {
                      case "CLOSED" :
                      case "PAYERROR" :
                      case "REFUND" :
                      case "REVOKED" :
                      case "SUCCESS" :
                          Curry._1(onDone, tradeState);
                          break;
                      default:
                        exit = 1;
                    }
                  } else {
                    exit = 1;
                  }
                  if (exit === 1) {
                    var currentRefreshingId = DomUtils$BwaxMobile.getFromLocalStorage(refreshingIdStorageKey);
                    if (currentRefreshingId !== undefined) {
                      if (currentRefreshingId === orderId) {
                        keepRefreshingWxPay(query_runner, orderId, onDone);
                      } else {
                        console.log("Stop this refreshing thread", orderId, maybeState);
                      }
                    } else {
                      console.log("Stop this refreshing thread", orderId, maybeState);
                    }
                  }
                  return Promise.resolve(/* () */0);
                }));
          return /* () */0;
        }), 2000);
  return /* () */0;
}

function createRefresher(query_runner, id, authCode, onFinished) {
  DomUtils$BwaxMobile.saveToLocalStorage(refreshingIdStorageKey, id);
  return keepRefreshingWxPay(query_runner, id, (function (tradeState) {
                clearLocal(/* () */0);
                return Curry._2(onFinished, tradeState, authCode);
              }));
}

function check_local_refresh(query_runner, onRefreshing, onFinished, onError) {
  var loaded = loadFromLocal(/* () */0);
  if (loaded !== undefined) {
    var match = loaded;
    createRefresher(query_runner, match[0], match[1], onFinished);
    return Curry._1(onRefreshing, true);
  } else {
    return Curry._1(onRefreshing, false);
  }
}

function clear_local_refresh(param) {
  clearLocal(/* () */0);
  return DomUtils$BwaxMobile.removeFromLocalStorage(refreshingIdStorageKey);
}

function initiate_adhoc_pay(query_runner, params, onPayInitiated, onFinished, onError) {
  var forRecordField = params[/* field_name */1];
  var body = params[/* title */0];
  var totalFee = params[/* cent */3];
  var forRecordId = params[/* adhoc_order_id */2];
  var func = function (param, param$1, param$2) {
    return createWxPayOrder(query_runner, body, totalFee, param, param$1, param$2);
  };
  if (ClientEnv.isWeChat()) {
    console.log("WX JS Pay");
    WxApi$BwaxMobile.ready((function (param) {
            func(/* JSAPI */1, forRecordId, forRecordField).then((function (param) {
                    var error = param[2];
                    var setting = param[1];
                    var maybeOrderId = param[0];
                    if (error !== undefined) {
                      Curry._1(onError, error);
                    } else {
                      var match = setting[/* authCode */0];
                      var match$1 = setting[/* jsPayArgs */3];
                      var exit = 0;
                      if (maybeOrderId !== undefined && match$1 !== undefined) {
                        var id = maybeOrderId;
                        if (DomUtils$BwaxMobile.$$window !== undefined) {
                          Curry._1(onPayInitiated, undefined);
                          WxApi$BwaxMobile.chooseWXPay(Caml_option.valFromOption(match$1), noop);
                          saveLocal(id, match);
                          createRefresher(query_runner, id, match, onFinished);
                        }
                        
                      } else {
                        exit = 1;
                      }
                      if (exit === 1 && Curry._1(Plate$BwaxMobile.$$Option.is_none, setting[/* jsPayArgs */3])) {
                        console.log("Warn: no jsPayArgs after JSAPI pay initiate");
                      }
                      
                    }
                    return Promise.resolve(/* () */0);
                  }));
            return /* () */0;
          }));
    WxApi$BwaxMobile.error((function (param) {
            return Curry._1(onError, "微信 API 初始化失败\"");
          }));
    if (UrlQuery$BwaxMobile.href !== undefined) {
      queryWxMpJsConfig(query_runner, UrlQuery$BwaxMobile.href, /* array */["chooseWXPay"]).then((function (c) {
              if (c !== undefined) {
                WxApi$BwaxMobile.config(Caml_option.valFromOption(c), false);
              } else {
                Curry._1(onError, "微信 API 配置失败: 未能获取配置信息");
              }
              return Promise.resolve(/* () */0);
            }));
    } else {
      Curry._1(onError, "微信 API 配置失败: 未获取当前地址");
    }
    return Promise.resolve(undefined);
  } else if (ClientEnv.isMobile()) {
    console.log("WX H5 Pay");
    return func(/* MWEB */2, forRecordId, forRecordField).then((function (param) {
                  var error = param[2];
                  var setting = param[1];
                  var maybeOrderId = param[0];
                  if (error !== undefined) {
                    Curry._1(onError, error);
                  } else {
                    var match = setting[/* authCode */0];
                    var match$1 = setting[/* mwebUrl */1];
                    var exit = 0;
                    if (maybeOrderId !== undefined && match$1 !== undefined) {
                      var id = maybeOrderId;
                      if (DomUtils$BwaxMobile.$$window !== undefined) {
                        Curry._1(onPayInitiated, undefined);
                        var tmp = Caml_option.valFromOption(DomUtils$BwaxMobile.$$window).location;
                        tmp.href = match$1;
                        saveLocal(id, match);
                        createRefresher(query_runner, id, match, onFinished);
                      }
                      
                    } else {
                      exit = 1;
                    }
                    if (exit === 1 && Curry._1(Plate$BwaxMobile.$$Option.is_none, setting[/* mwebUrl */1])) {
                      console.log("Warn: no mweb url after H5 pay initiate");
                    }
                    
                  }
                  return Promise.resolve(undefined);
                }));
  } else {
    console.log("Wx Native Pay");
    return func(/* NATIVE */0, forRecordId, forRecordField).then((function (param) {
                  var error = param[2];
                  var setting = param[1];
                  var maybeOrderId = param[0];
                  if (error !== undefined) {
                    Curry._1(onError, error);
                  } else {
                    var match = setting[/* authCode */0];
                    var match$1 = setting[/* codeUrl */2];
                    var exit = 0;
                    if (maybeOrderId !== undefined && match$1 !== undefined) {
                      var id = maybeOrderId;
                      Curry._1(onPayInitiated, match$1);
                      saveLocal(id, match);
                      createRefresher(query_runner, id, match, onFinished);
                    } else {
                      exit = 1;
                    }
                    if (exit === 1 && Curry._1(Plate$BwaxMobile.$$Option.is_none, setting[/* codeUrl */2])) {
                      console.log("Warn: no code url after native pay initiate");
                    }
                    
                  }
                  return Promise.resolve(undefined);
                }));
  }
}

exports.to_json_obj = to_json_obj;
exports.qWxMpJsConfig = qWxMpJsConfig;
exports.queryWxMpJsConfig = queryWxMpJsConfig;
exports.mutCreateWxPayOrder = mutCreateWxPayOrder;
exports.tradeTypeToString = tradeTypeToString;
exports.now_str = now_str;
exports.get_str_by_path = get_str_by_path;
exports.createWxPayOrder = createWxPayOrder;
exports.noop = noop;
exports.paymentOrderIdStorageKey = paymentOrderIdStorageKey;
exports.authCodeStorageKey = authCodeStorageKey;
exports.saveLocal = saveLocal;
exports.loadFromLocal = loadFromLocal;
exports.clearLocal = clearLocal;
exports.mutRefreshWxPayOrder = mutRefreshWxPayOrder;
exports.refreshWxPay = refreshWxPay;
exports.refreshingIdStorageKey = refreshingIdStorageKey;
exports.clearRefreshingId = clearRefreshingId;
exports.keepRefreshingWxPay = keepRefreshingWxPay;
exports.createRefresher = createRefresher;
exports.check_local_refresh = check_local_refresh;
exports.clear_local_refresh = clear_local_refresh;
exports.initiate_adhoc_pay = initiate_adhoc_pay;
/* bwax/clientEnv Not a pure module */
