
import React, { useEffect, useRef, useState } from 'react'

import { Editor, Range, Transforms, Text, Path, Point } from 'slate'
import { ReactEditor } from 'slate-react'

import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md'

import TextareaAutosize from '@mui/base/TextareaAutosize';
import Modal from '@mui/base/Modal';

import Button from 'bwax-ui/components/Button'

import { BeatLoader } from 'react-spinners';

import omit from 'lodash/omit'

import { insertMarkdownTextToEditor } from '../handleMarkdownText'
import { markdownToRichText } from 'bwax-ui/markdown/convertMarkdown';

import RichTextRenderer from '../../RichTextRenderer'
import convertSlateToMarkdown from '../convertSlateToMarkdown'

import './AIInput.less'

import AiInputMenu from './AiInputMenu';
import OutOfQuotaTip from 'bwax-ui/components/OutOfQuotaTip';
import { isIOS, isMobile } from 'bwax/clientEnv'

import { useTrack } from 'bwax-ui/track';

export default function AiInputUI({
    editor, aiInputInfo, setAiInputInfo, mobileToolbarRef, scrollContainerRef, scrollContainerTop, isResponding, startGenerate, stopGeneration = () => { }, 
    respondingText, respondingStepMsg, containerDOM,

    remainingQuota, reloadUsageQuota, setHighlightRange, changeBrickToolbarInfo

}) {

    const track = useTrack();

    const { selection } = editor
    const { show, position, action, triggerByBrickToolbar } = aiInputInfo

    const [prompt, setPrompt] = useState('')
    const [startAnchor, setStartAnchor] = useState(null)
    const [nextStartAnchor, setNextStartAnchor] = useState(null)
    const [previousRespondingText, setPreviousRespondingText] = useState('')
    const [respondedArr, setRespondedArr] = useState([])
    const [currentRespondedIndex, setCurrentRespondedIndex] = useState(0)
    const [isChangedByRespondedIndex, setIsChangedByRespondedIndex] = useState(false)
    const [responding, setResponding] = useState(false);
    const [discardTipVisible, setDiscardTipVisible] = useState(false)
    const [isContinueWriting, setIsContinueWriting] = useState(false)
    const [discardTipSelection, setDiscardTipSelection] = useState(null)
    const [hasNextBlock, setHasNextBlock] = useState(false)
    const [cancelSelection, setCancelSelection] = useState(null)
    const [isCompositionEditing, setIsCompositionEditing] = useState(false) // 监听拼音输入

    const [selectedContent, setSelectedContent] = useState(null);
    const [precedingContent, setPreviousContent] = useState();
    const [allContent, setAllContent] = useState();

    const [triggeringCommand, setTriggeringCommand] = useState();

    function startGeneratingContent({ userPrompt, operation }) {
        if (isChangedByRespondedIndex) {
            setIsChangedByRespondedIndex(false)
        }
        if (action === "activeBySpace" || action === "activeBySelect") {
            // save the initial prompt, operation.
            setTriggeringCommand({ userPrompt, operation })
        }
        startGenerate({
            userPrompt,
            operation,
            contents: {
                selectedContent,
                precedingContent,
                allContent,
                previousRespondingText,
            },
            aiInputAction: action,

            triggeringCommand,
        })
    }

    useEffect(() => {
        const allContent = convertSlateToMarkdown(Editor.fragment(editor, {
            anchor: Editor.start(editor, []),
            focus: Editor.end(editor, [])
        }));
        setAllContent(allContent);

        if (selection) {
            if (!Range.isCollapsed(selection) || triggerByBrickToolbar) {
                // 有选中内容，或者是从 brickToolbar 打开（brickToolbar 点开是 activeBySelect）
                const content = convertSlateToMarkdown(Editor.fragment(editor, selection));
                setSelectedContent(content)
            }

            let position = null
            if (Range.isBackward(selection)) {
                position = selection.focus
            } else {
                position = selection.anchor
            }

            const precedingContent = convertSlateToMarkdown(Editor.fragment(editor, {
                anchor: Editor.start(editor, []),
                focus: position
            }));
            setPreviousContent(precedingContent);

            const afterBlockPath = Editor.after(editor, selection.focus.path.slice(0, 1))
            setHasNextBlock(!!afterBlockPath)
        }

    }, [])

    const respondingWithSelectedContent = !!((selectedContent || selectedContent === '') && 
        previousRespondingText && (action === 'finishWritingForReplacement' || action === 'activeBySelect'))

    function hideAiInput() {
        setHighlightRange(null)
        setAiInputInfo({ show: false })

        if(triggerByBrickToolbar && changeBrickToolbarInfo) {
            changeBrickToolbarInfo({ show: false })
        }
    }

    const respondingRef = useRef(responding)
    const discardTipSelectionRef = useRef(discardTipSelection)
    const actionRef = useRef(action)

    useEffect(() => {
        if (typeof (window) !== 'undefined') {

            function handleMouseDown(e) {
                // e.preventDefault()
                const clickedElement = e.target

                function isAiInputElement(element) {
                    if (!element) {
                        return false;
                    }

                    const className = element.getAttribute('class')
                    if (element.nodeName === 'TEXTAREA' ||
                        (className && (className.includes('ai-operation-menu') || className.includes('generated-and-input-container')))
                    ) {
                        return true
                    }
                    return isAiInputElement(element.parentElement);
                };

                if (show && !isAiInputElement(clickedElement)) {
                    if (respondingRef.current || respondingWithSelectedContent || actionRef.current.startsWith('finishWriting')) {
                        setDiscardTipVisible(true)
                        setIsChangedByRespondedIndex(false)
                        // 续写不保存 discardTipSelection
                        if (respondingWithSelectedContent && !discardTipSelectionRef.current) {
                            setDiscardTipSelection(editor.selection)
                            discardTipSelectionRef.current = editor.selection
                        }
                    } else {
                        hideAiInput()
                    }
                }

            }

            window.addEventListener('mousedown', handleMouseDown)

            return () => {
                window.removeEventListener('mousedown', handleMouseDown)
            }
        }
    }, []);

    const isOutOfQuota = (() => {
        return remainingQuota !== undefined && remainingQuota <= 0
    })();



    const readlyToGenerate = prompt && prompt.trim().length > 1;

    function startGenerateByPrompt () {
        const [ancestorNode, ancestorPath] = Editor.node(editor, selection.anchor.path.slice(0, 1))
        const [parentNode, parentPath] = Editor.parent(editor, selection.anchor.path)
        // 如果在 list 中的最后一个 list-item 中调出输入框，则在 list 下方新增一个空 paragraph，在这个 paragraph 中生成
        // 如果不在最后一个 list-item 中调出，则在该 list-item 的下方新增一个 node，原本的 list 被分割
        if (action === 'activeBySpace' && (ancestorNode.type === "bulleted-list" || ancestorNode.type === "numbered-list")) {
            if (parentPath[1] !== ancestorNode.children.length - 1) {
                Transforms.splitNodes(editor, { at: [parentPath[0], parentPath[1] + 1] })
            }
            Transforms.insertNodes(editor, { type: 'paragraph', children: [{ text: '' }] }, { at: [selection.anchor.path[0] + 1], select: true })
            setStartAnchor(editor.selection.anchor)

        } else {
            setStartAnchor(selection.anchor)
        }

        if (readlyToGenerate) {
            startGeneratingContent({
                userPrompt: prompt,
            })
            setResponding(true)
            respondingRef.current = true
            if (prompt) {
                setPrompt('')
            }
        } else {
            // TODO 弹出 toast，解释说字数不够，还是显示某种 disabled 的状态？
        }
    }


    function handleKeyDown(e) {
        if (!prompt && e.key === 'Backspace' && !isCompositionEditing) {
            e.preventDefault()
            hideAiInput()
            ReactEditor.focus(editor)

        } else if (e.key === 'Enter' && !isCompositionEditing && !isMobile() && 
            !(e.shiftKey || e.altKey || e.metaKey || e.ctrlKey)  ///
        ) {
            track("note_ai_start", { method: "enter-key" })

            e.preventDefault()
            startGenerateByPrompt()
        }
    }

    const replaceStartPosition = useRef(null)

    function deleteGeneratedContent() {
        Transforms.removeNodes(editor, {
            at: {
                anchor: { offset: 0, path: startAnchor.path.slice(0, 1) },
                focus: editor.selection.focus
            },
            match: (n, p) => {
                return (p.length === 1 && p[0] >= startAnchor.path[0] && p[0] <= editor.selection.focus.path[0])
            },
        })

        Transforms.insertNodes(editor,
            { type: 'paragraph', children: [{ text: '' }] },
            { at: startAnchor.path.slice(0, 1), select: true }
        )
    }


    const prevScrollContainerPosition = scrollContainerRef ? scrollContainerRef.current.style['position'] : ''

    function resetMobileToolbarPosition () {
        if(isMobile() && mobileToolbarRef && scrollContainerRef && typeof(window) !== 'undefined') {
            scrollContainerRef.current.style['position'] = prevScrollContainerPosition
            mobileToolbarRef.current.style['position'] = '' // 去掉行内的 position 样式，恢复成默认的 fixed
            mobileToolbarRef.current.style['top'] = ``
            setTimeout(() => {
                mobileToolbarRef.current.style['bottom'] = `${window.innerHeight - window.visualViewport.height}px`
            }, 160)
            
        }
    }

    function toggleGeneratedHighlight (isHighlighted) {
        // console.log("toggle generated hightlight: ", startAnchor, editor.selection, value)
        if(isHighlighted) {
            setHighlightRange({ anchor: startAnchor, focus: editor.selection.focus })
        } else {
            setHighlightRange(null)
        }
    }

    function selectOperation(operation) {

        setPrompt('')
        if (isChangedByRespondedIndex) {
            setIsChangedByRespondedIndex(false)
        }
        // const markdownValues = getMarkdownValues(previousRespondingText, editor)
        const markdownValues = markdownToRichText(previousRespondingText)
        const { name, isRetryForRelacement, isRewrite, isContinue, parentName, } = operation

        track("note_ai_menu_action", { "menu_item": parentName ? parentName + "_" + name : name })

        if (action === 'activeBySpace' || action === 'activeBySelect') {

            // 按 space 点开输入框，点击 menu 选项
            setStartAnchor(selection.anchor)
            startGeneratingContent({
                userPrompt: prompt,
                operation,
            })
            setResponding(true)
            respondingRef.current = true
            if (prompt) {
                setPrompt('')
            }


        } else if (action === 'finishWriting') {

            if (name === 'done' || name == 'close') {
                hideAiInput()

            } else if (isRewrite) {
                // 删除续写内容，重新生成
                deleteGeneratedContent()

                // 重置输入框的位置
                setTimeout(() => {
                    const [startAnchorNode, startAnchorPath] = Editor.node(editor, startAnchor)
                    const domNode = ReactEditor.toDOMNode(editor, startAnchorNode)
                    const rect = domNode.getBoundingClientRect();

                    setAiInputInfo(prev => {
                        return {
                            ...prev,
                            position: { ...omit(rect, ['width', 'height']), top: rect.top + rect.height + 4 }
                        }
                    })
                }, 16)

                // rewrite 时，不显示 ai placeholder
                setAiInputInfo(prev => {
                    return {
                        ...prev,
                        responding: true
                    }
                })

                startGeneratingContent({
                    userPrompt: prompt,
                    operation,
                })
                setResponding(true)
                respondingRef.current = true

            } else if (isContinue) {

                // Continue writing
                toggleGeneratedHighlight(false)
                setIsContinueWriting(true)
                setNextStartAnchor(editor.selection.focus)

                startGeneratingContent({
                    userPrompt: prompt,
                    operation,
                })
                setResponding(true)
                respondingRef.current = true
            } else if (name == "discard") {
                hideAiInput()

                deleteGeneratedContent()
                ReactEditor.focus(editor)

                resetMobileToolbarPosition()
            }

        } else if (action == "finishWritingForReplacement") {
            if (name === 'replace-selection') {

                hideAiInput()

                if (discardTipSelection) {
                    Transforms.select(editor, discardTipSelection)
                }

                const { selection } = editor
                let position = null
                if (Range.isBackward(selection)) {
                    // 反向选择
                    position = selection.anchor
                } else {
                    position = selection.focus
                }
                const currentPath = position.path

                const [parentNode, parentPath] = Editor.parent(editor, currentPath);
                const isEdge = currentPath[currentPath.length - 1] === parentNode.children.length - 1

                Editor.deleteFragment(editor)
                if (currentPath.length === 4) {
                    // 在 list-item 中
                    const [first, ...others] = [...markdownValues]
                    function insertFirst() {
                        replaceStartPosition.current = editor.selection

                        if (first.type === "numbered-list" || first.type === "bulleted-list") {
                            const currentPath = editor.selection.focus.path
                            Transforms.insertNodes(editor, first.children, { at: [currentPath[0], currentPath[1] + 1], select: true })
                            const text = Editor.string(editor, currentPath)
                            if (!text) {
                                Transforms.removeNodes(editor, { at: currentPath.slice(0, 2) })
                            }
                        } else {
                            Transforms.insertFragment(editor, first.children)
                        }
                    }

                    if (markdownValues.length > 1) {
                        // 如果生成内容有多个 block，则分割原本的 list
                        insertFirst()
                        const currentPath = editor.selection.focus.path
                        Transforms.insertNodes(editor,
                            { type: 'list-item', children: [{ type: 'paragraph', children: [{ text: '' }] }] },
                            { at: [currentPath[0], currentPath[1] + 1], select: true }
                        )

                        Transforms.unwrapNodes(editor, {
                            match: n => n.type === "numbered-list" || n.type === "bulleted-list",
                            split: true,
                        })

                        const [parentNode, parentPath] = Editor.parent(
                            editor,
                            editor.selection.focus.path
                        );

                        const singleListItem = editor.children.find(n => n.type === "list-item")
                        if (singleListItem) {
                            const singleListItemIndex = editor.children.findIndex(n => n.type === "list-item")
                            const splitIndex = parentPath[parentPath.length - 1]

                            Transforms.liftNodes(editor, {
                                at: Editor.range(editor, { at: [singleListItemIndex] }),
                                match: n => singleListItem.children.findIndex(c => c === n) >= splitIndex
                            })
                        }

                        Transforms.insertFragment(editor, others)

                    } else {
                        insertFirst()
                    }
                } else {
                    if (!isEdge) {
                        // 如果没有选中边界, 则先分割选中区域的前后文本。
                        Transforms.splitNodes(editor, { at: editor.selection.focus })
                        Transforms.move(editor, { reverse: true })
                        replaceStartPosition.current = editor.selection
                        insertMarkdownTextToEditor(editor, previousRespondingText)
                    } else {
                        replaceStartPosition.current = editor.selection
                        insertMarkdownTextToEditor(editor, previousRespondingText)
                    }
                }

                setTimeout(() => {
                    if (replaceStartPosition && typeof (window) !== 'undefined') {
                        const newSelectionRange = {
                            anchor: replaceStartPosition.current.anchor,
                            focus: editor.selection.focus
                        }
                        Transforms.select(editor, newSelectionRange)
                        const domRange = ReactEditor.toDOMRange(editor, newSelectionRange)
                        const selection = window.getSelection()
                        selection.removeAllRanges();
                        selection.addRange(domRange)
                    }
                }, 16)

            } else if (isRetryForRelacement) {

                setResponding(true)
                respondingRef.current = true

                if (isContinue) {

                    setIsContinueWriting(true)

                    startGeneratingContent({
                        userPrompt: prompt,
                        operation,
                    })

                } else {
                    startGeneratingContent({
                        userPrompt: prompt,
                        operation,
                    })
                }

            } else if (name === 'insert-below') {
                // 如果在 list 中，则新增 list-item，否则在当前 node 下方增加新的一段(notion)
                const range = discardTipSelection ? discardTipSelection : selection
                let position = null
                if (Range.isBackward(range)) {
                    // 反向选择
                    position = range.anchor
                } else {
                    position = range.focus
                }
                const [ancestorNode, ancestorPath] = Editor.node(editor, position.path.slice(0, 1))
                const [first, ...others] = [...markdownValues]

                hideAiInput()

                if ((ancestorNode.type === 'numbered-list' && first.type === 'numbered-list') ||
                    (ancestorNode.type === 'bulleted-list' && first.type === 'bulleted-list')
                ) {
                    first.children.map(item => {
                        Transforms.insertNodes(editor, item, { at: [ancestorPath[0], ancestorNode.children.length], select: true })
                    })
                    if (others.length > 0) {
                        Transforms.insertNodes(editor, others, { at: [ancestorPath[0] + 1], select: true })
                    }

                } else {

                    Transforms.insertNodes( 
                        editor, 
                        [ 
                            {
                                type: 'paragraph',
                                children: [{ text: '' }],
                            },
                            ...markdownValues
                        ], // 加一个 空的 Paragraph
                        { at: [ancestorPath[0] + 1], select: true }
                    )
                }

                setTimeout(() => {
                    if (typeof (window) !== 'undefined') {
                        const newSelectionRange = {
                            anchor: { offset: 0, path: [ancestorPath[0] + 1] },
                            focus: editor.selection.focus
                        }
                        Transforms.select(editor, newSelectionRange)
                        const domRange = ReactEditor.toDOMRange(editor, newSelectionRange)

                        const selection = window.getSelection()
                        selection.removeAllRanges();
                        selection.addRange(domRange)
                    }
                }, 16)
            } else {
                // discard
                hideAiInput()
            }

            if(isMobile()) {
                resetMobileToolbarPosition()
            }
        }
    }

    const inputRef = useRef();


    // 
    const currentScrollBaseRef = useRef(containerDOM && containerDOM.scrollTop);
    const scrollOffsetRef = useRef(0);
    const containerDOMTopRef = useRef(0)

    function handleScroll(evt) {
        // 如果 scroll，那么就保存当前的 offset
        const offset = containerDOM.scrollTop - currentScrollBaseRef.current;
        scrollOffsetRef.current = offset;

    }
    useEffect(() => {
        // 如果外部有滚动条，滚动时 containDOM.rect.top 会改变，AI 输入框会移位，这里将一开始的 top 保存起来
        containerDOMTopRef.current = containerDOM.getBoundingClientRect().top
        containerDOM.addEventListener("scroll", handleScroll)
        return () => {
            containerDOM.removeEventListener("scroll", handleScroll);
        }

    }, [containerDOM]);

    const bottomStatusBarHeight = !isMobile() ? 32 : 0

    function scrollToShowInput () {
        const elBottom = inputRef.current.getBoundingClientRect().bottom

        function containerDOMscrollDown () {
            const beyondContainerDOMHeight = elBottom - (containerDOM.offsetHeight + containerDOMTopRef.current) 
            if (beyondContainerDOMHeight > 0) {
                containerDOM.scrollTo({ top: containerDOM.scrollTop + beyondContainerDOMHeight + bottomStatusBarHeight, behavior: "smooth" });
            }
        }

        if(scrollContainerRef) {
            const { scrollTop, offsetHeight, scrollHeight } = scrollContainerRef.current
            const beyondScrollContainerHeight = elBottom - scrollContainerRef.current.getBoundingClientRect().bottom

            if (beyondScrollContainerHeight > 0 && scrollTop + offsetHeight + 1 < scrollHeight) {
                scrollContainerRef.current.scrollTo({ top: scrollTop + beyondScrollContainerHeight + bottomStatusBarHeight, behavior: "smooth" });
            } else {
                containerDOMscrollDown()
            }
        } else {
            containerDOMscrollDown()
        }
    }

    useEffect(() => {
        // 当点击出 AI 输入框时，如果 menu 超出 containerDOM，则 containerDOM 向下滚动
        
        if (show && inputRef.current && containerDOM) {
            setTimeout(() => {
                scrollToShowInput()
            }, 16)
        }
    }, [show, action])

    const generatedDisplayRef = useRef(null)

    function updatecontainerDOMTopRef() {
        const containerDOMTop = containerDOM.getBoundingClientRect().top
        if (containerDOMTopRef.current !== containerDOMTop) {
            // 如果生成过程中滚动了 containerDOM 的外部容器，这里更新一下 top，避免输入框错位
            containerDOMTopRef.current = containerDOMTop
        }
    }

    const scrolledUpwardRef = useRef(false)

    useEffect(() => {
        if (respondingText) {
            if (selectedContent || selectedContent === '') {
                // 如果有选中文本，则在 generated-display 中显示(renderer)
                if (isContinueWriting) {
                    const newResponseText = respondedArr[currentRespondedIndex] + respondingText
                    setPreviousRespondingText(newResponseText)
                } else {
                    setPreviousRespondingText(respondingText)
                }

            } else {
                // 直接写入编辑器
                if (respondingText !== previousRespondingText) {
                    const anchor = nextStartAnchor ? nextStartAnchor : startAnchor
                    if (previousRespondingText) {
                        // 如果有 previous，则先删掉
                        if (cancelSelection && !Editor.before(editor, editor.selection)) {
                            // 如果有 cancelSelction(点出了 discard tip 再取消)，editor 的光标会跑到最上面，此时要先恢复光标
                            Transforms.select(editor, cancelSelection)
                        }

                        if (isContinueWriting) {
                            Transforms.select(editor, { anchor, focus: editor.selection.focus })
                            Editor.deleteFragment(editor)
                        } else {
                            Transforms.removeNodes(editor, {
                                at: {
                                    anchor: { offset: 0, path: anchor.path.slice(0, 1) },
                                    focus: editor.selection.focus
                                },
                                match: (n, p) => {
                                    return (p.length === 1 && p[0] >= anchor.path[0] && p[0] <= editor.selection.focus.path[0])
                                },
                                hanging: true
                            })

                            Transforms.insertNodes(editor,
                                { type: 'paragraph', children: [{ text: '' }] },
                                editor.selection ? (
                                    editor.selection.focus.path[0] < anchor.path[0] ? {
                                        at: anchor.path.slice(0, 1), select: true
                                    } : {
                                        at: editor.selection.focus.path.slice(0, 1), select: !hasNextBlock ? false : true
                                    }
                                ) : {
                                    at: anchor.path.slice(0, 1), select: true
                                }
                            )

                        }
                    }

                    insertMarkdownTextToEditor(editor, respondingText)

                    if (isContinueWriting) {
                        const newResponseText = respondedArr[currentRespondedIndex] + respondingText
                        setPreviousRespondingText(newResponseText)
                    } else {
                        setPreviousRespondingText(respondingText)
                    }

                    // 保持输入框显示

                    setTimeout(() => {
                        if (typeof (document) !== 'undefined' && typeof(window) !== 'undefined') {
                            const [focusedNode, focusedPath] = Editor.node(editor, editor.selection)
                            const domNode = ReactEditor.toDOMNode(editor, focusedNode)
                            const rect = domNode.getBoundingClientRect();
                            let changedTop = rect.top + rect.height + 4 + scrollOffsetRef.current 
                            if(isMobile()) {

                                const outerScrollOffset = window.pageYOffset + (scrollContainerRef ? scrollContainerRef.current.scrollTop : 0)
                                changedTop += outerScrollOffset
                            }

                            setAiInputInfo(prev => {
                                return {
                                    ...prev,
                                    position: { ...omit(rect, ['width', 'height']), top: changedTop }
                                }
                            })

                            currentScrollBaseRef.current = containerDOM.scrollTop;
                            scrollOffsetRef.current = 0;

                            if(mobileToolbarRef && mobileToolbarRef.current) {
                                mobileToolbarRef.current.style['top'] = `${changedTop - scrollContainerTop}px`
                            }
                        }

                    }, 16)
                }
            }
        } else if (previousRespondingText) {
            setIsContinueWriting(false)
            setTimeout(() => {
                setResponding(false);
                respondingRef.current = false
            }, 32)

            const newResponed = [...respondedArr, previousRespondingText]
            setRespondedArr(newResponed)
            setCurrentRespondedIndex(newResponed.length - 1)
            if (nextStartAnchor) {
                setNextStartAnchor(null)
            }

            if (cancelSelection) {
                setCancelSelection(null)
            }

            if(scrolledUpwardRef.current) {
                scrolledUpwardRef.current = false
            }

            if (action === 'activeBySpace' || action === 'finishWriting') {
                updatecontainerDOMTopRef()

                if (action === 'activeBySpace') {
                    setAiInputInfo(prev => {
                        return {
                            ...prev,
                            action: 'finishWriting'
                        }
                    })
                    actionRef.current = 'finishWriting'
                }
                // console.log("finish generating");
                toggleGeneratedHighlight(true)

            } else if (action === 'activeBySelect') {
                setAiInputInfo(prev => {
                    return {
                        ...prev,
                        action: 'finishWritingForReplacement'
                    }
                })
                actionRef.current = 'finishWritingForReplacement'
            }

        }

        if (generatedDisplayRef.current && (action === 'activeBySelect' || action === 'finishWritingForReplacement')) {
            // responding 时保持 display 滚动到在最底端
            const { scrollHeight } = generatedDisplayRef.current
            generatedDisplayRef.current.scrollTop = scrollHeight
        }

        if(!scrolledUpwardRef.current && inputRef.current && containerDOM) {
            // 如果没有主动 scroll 上去，当生成内容超出底部时，自动向下滚动
            scrollToShowInput()
        }

    }, [respondingText])

    function getTop() {
        if(position) {
            if (containerDOM) {
                const rectTop = containerDOM.getBoundingClientRect().top
                const originalTop = inputRef.current ? inputRef.current.style.top : undefined
                const originalTopValue = originalTop ? parseFloat(originalTop.match(/^(-?\d.*)px$/)[1]) : 0
                const containerDOMTop = action !== 'activeBySpace' && action !== 'finishWriting' && containerDOMTopRef.current ?
                    containerDOMTopRef.current : rectTop
    
                // 直接写入完成的瞬间如果页面处于滚动中，有可能造成输入框错位，这是由于 updatecontainerDOMTopRef 时的 containerDOM.rect.top 没有计算正确导致的
                // 为了避免滚动导致的错位，这里直接返回最后一次正确的 top 值
                return ((action === 'activeBySpace' && responding) || action === 'finishWriting') && !isResponding && !isChangedByRespondedIndex ?
                    originalTopValue : position.top - containerDOMTop + containerDOM.scrollTop - scrollOffsetRef.current
            }
            return position.top
        }
        
    }

    const absoluteTopOffsetRef = useRef(0)

    useEffect(() => {
        // for mobile
        // 这里保存输入框显示时的外部滚动距离，避免显示之后再滚动导致的偏差
        if(isMobile() && scrollContainerRef && typeof(window) !== 'undefined') {
            absoluteTopOffsetRef.current = scrollContainerRef.current.scrollTop + window.pageYOffset
        }
    }, [])

    useEffect(() => {
        if (responding) {
            if (action === "finishWriting") {
                updatecontainerDOMTopRef()
            }

            // responding 时，mobile toolbar position 改为 absolute，贴着选中区域
            if(mobileToolbarRef && scrollContainerRef && isMobile()) {
                if(prevScrollContainerPosition !== 'static') {
                    scrollContainerRef.current.style['position'] = 'relative'
                }
                const absoluteTop = position.top + absoluteTopOffsetRef.current
                mobileToolbarRef.current.style['position'] = 'absolute'
                mobileToolbarRef.current.style['top'] = `${absoluteTop - scrollContainerTop}px`
                mobileToolbarRef.current.style['bottom'] = ''
            }

            return
        }
        const actualTop = getTop();

        if (containerDOM && inputRef.current && !isMobile()) {
            const elHeight = inputRef.current.getBoundingClientRect().height
            const diff = actualTop - (containerDOM.scrollTop + containerDOM.getBoundingClientRect().height) + elHeight + 16;

            if (diff > 0) {
                containerDOM.scrollTo({ top: containerDOM.scrollTop + diff, behavior: "smooth" });
            }

        } else if (isMobile() && scrollContainerRef && textareaAutosizeRef.current && typeof(window) !== 'undefined') {
            // 如果输入框超出了页面范围，则 window 滚动下来
            const { top, bottom } = textareaAutosizeRef.current.getBoundingClientRect()
            if(top > window.innerHeight) {
                const originalScrollTop = scrollContainerRef.current.scrollTop
                scrollContainerRef.current.scrollTo({ top: originalScrollTop + bottom - window.innerHeight, behavior: "smooth"})
            }
        }

    }, [responding]);

    const lastScrollTopRef = useRef(0)

    useEffect(() => {
        // responding 时，监听 scrollContainer 的滚动事件

        function handleContainerScroll(e) {
            const { scrollTop, offsetHeight, scrollHeight } = e.target
            if(scrollTop + offsetHeight + 1 < scrollHeight && scrollTop < lastScrollTopRef.current) {
                // 主动 scroll 上去了
                scrolledUpwardRef.current = true
            } 
            
            lastScrollTopRef.current = e.target.scrollTop
        }

        if(responding) {
            if(scrollContainerRef && scrollContainerRef.current) {
                scrollContainerRef.current.addEventListener('scroll', handleContainerScroll)
                lastScrollTopRef.current = scrollContainerRef.current.scrollTop
            } else {
                containerDOM.addEventListener('scroll', handleContainerScroll)
                lastScrollTopRef.current = containerDOM.scrollTop
            }
        }

        return () => {
            if(scrollContainerRef && scrollContainerRef.current) {
                scrollContainerRef.current.removeEventListener('scroll', handleContainerScroll)
            } else {
                containerDOM.removeEventListener('scroll', handleContainerScroll)
            }
        }

    }, [responding])

    function renderGeneratedDisplay() {

        // const markdownValues = getMarkdownValues(previousRespondingText, editor)
        const markdownValues = markdownToRichText(previousRespondingText)

        return (
            <div className='generated-display' ref={generatedDisplayRef}>
                <RichTextRenderer value={markdownValues} version={2} />
            </div>

        )
    }

    function changeCurentRespondedText(direction) {
        if ((currentRespondedIndex === 0 && direction === 'prev') ||
            (currentRespondedIndex === respondedArr.length - 1 && direction === 'next')
        ) {
            return
        } else {
            if (!isChangedByRespondedIndex) {
                setIsChangedByRespondedIndex(true)
            }

            let newText = ''
            if (direction === 'prev') {
                const previousIndex = currentRespondedIndex - 1
                newText = respondedArr[previousIndex]
                setPreviousRespondingText(newText)
                setCurrentRespondedIndex(previousIndex)
            } else {
                const nextIndex = currentRespondedIndex + 1
                newText = respondedArr[nextIndex]
                setPreviousRespondingText(newText)
                setCurrentRespondedIndex(nextIndex)
            }

            if (action === 'finishWriting') {

                updatecontainerDOMTopRef()
                // 替换掉之前写入的内容
                deleteGeneratedContent()

                insertMarkdownTextToEditor(editor, newText)
                toggleGeneratedHighlight(true)
                setTimeout(() => {
                    const [focusedNode, focusedPath] = Editor.node(editor, editor.selection)
                    const domNode = ReactEditor.toDOMNode(editor, focusedNode)
                    const rect = domNode.getBoundingClientRect();

                    setAiInputInfo(prev => {
                        return {
                            ...prev,
                            position: { ...omit(rect, ['width', 'height']), top: rect.top + rect.height + 4 + scrollOffsetRef.current }
                        }
                    })
                }, 16)
            }
        }
    }


    /**
     * 在替换时，有 previousRespondingText 时输入 prompt，不显示 operation menu，只根据 prompt 重新生成（参考 notion）
     */


    const textareaAutosizeRef = useRef()

    function renderMenu () {
        return (
            <AiInputMenu {...{
                action, selectOperation,
                
                isOutOfQuota,
                contents: {
                    selectedContent,
                    allContent,
                    precedingContent,
                    previousRespondingText,
                },
                containerDOM,
                textareaAutosizeRef,
                editor,
                mobileToolbarRef
            }} />
        )
    }

    function handleTextareaFocus () {
        resetMobileToolbarPosition()
    }

    function btnStop () {
        track("note_ai_stop", { method: "button-click"})

        stopGeneration();
        setResponding(false);
        respondingRef.current = false
        toggleGeneratedHighlight(true)
    }

    function btnStart() {
        track("note_ai_start", { method: "button-click"})

        if(action === 'activeBySpace') {
            startGenerateByPrompt() // 与 enter 触发生成的逻辑一致
        } else {
            startGeneratingContent({
                userPrompt: prompt,
            });
            setResponding(true)
            respondingRef.current = true
            if (prompt) {
                setPrompt('')
            }
        }
    }

    return (
        <div className='lc-ai-input-menus' style={{ top: getTop(), left: 0 }} ref={inputRef}>
            { !responding && !prompt && isMobile() ? renderMenu() : null }
            <div className='generated-and-input-container'>
                {
                    respondingWithSelectedContent ? (
                        renderGeneratedDisplay()
                    ) : null
                }
                <div className='input-and-index-select'>
                    {(() => {

                        if(isOutOfQuota) {
                            return <OutOfQuotaTip style={{ padding: 4 }} />
                        }


                        function renderInputArea () {
                            if(isResponding) {
                                return (
                                    <div className="in-progress-bar">
                                        <span>{ respondingText && respondingText.trim().length > 0 ? "正在生成内容" : (respondingStepMsg || "")}</span>
                                        <BeatLoader size={6} color="#7B7D7D"/>
                                    </div>
                                )
                            } else {
                                return (
                                    <TextareaAutosize
                                        ref={textareaAutosizeRef}
                                        value={prompt} 
                                        autoFocus={isMobile() ? false : true}
                                        aria-label="empty textarea"
                                        placeholder={(selectedContent ? "你想让 AI 怎么调整这段文字..." : '你想要 AI 写什么...')}
                                        disabled={isResponding}
                                        onChange={e => setPrompt(e.target.value)}
                                        onKeyDown={e => handleKeyDown(e)}
                                        onCompositionStart={() => setIsCompositionEditing(true)}
                                        onCompositionEnd={() => {
                                            setTimeout(() => {
                                                setIsCompositionEditing(false)
                                            }, 16);
                                        }}
                                        onFocus={handleTextareaFocus}
                                    />
                                )
                            }
                        }

                        const stopFProps = isIOS() ? {
                            onPress: () => btnStop()
                        } : {
                            onClick: () => btnStop()
                        }

                        const startFProps = isIOS() ? {
                            onPress: () => btnStart()
                        } : {
                            onClick: () => btnStart()
                        }

                        return (
                            <>
                                { renderInputArea() }
                                {
                                    responding ? (
                                        <Button style={{ padding: "0 0.75rem", marginTop: "2px" }} size="small" {...stopFProps}>
                                            <i className='bx bx-pause'></i>
                                        </Button>
                                    ) : (
                                        <Button color={"violet"} style={{ padding: "0 0.75rem", marginTop: "2px" }} isDisabled={!readlyToGenerate} 
                                            size="small" {...startFProps}>
                                            <i className='bx bx-play'></i>
                                        </Button>
                                    )
                                }
                            </>
                        )
                    })()}
                    {
                        respondedArr.length > 1 ? (
                            <div className='responded-index-container'>
                                <div className={`index-box ${currentRespondedIndex === 0 ? 'disabled' : ''}`}
                                    onClick={() => changeCurentRespondedText('prev')}
                                >
                                    <MdArrowBackIosNew />
                                </div>
                                <span>{`${currentRespondedIndex + 1} of ${respondedArr.length} `}</span>
                                <div className={`index-box ${currentRespondedIndex === respondedArr.length - 1 ? 'disabled' : ''}`}
                                    onClick={() => changeCurentRespondedText('next')}
                                >
                                    <MdArrowForwardIos />
                                </div>
                            </div>
                        ) : null
                    }
                </div>

            </div>
            { !responding && !prompt && !isMobile() ? renderMenu() : null }
            <Modal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                className="lc-discard-modal lc-base"
                open={discardTipVisible}
                onClose={() => {
                    setDiscardTipVisible(false)
                    ReactEditor.focus(editor) // 点击 backdrop 时手动 focus editor，否则会 focus 到第一行
                }}
                slots={{ backdrop: Backdrop }}
            >
                <div className='discard-tip-box'>
                    <p>放弃 AI 生成的内容?</p>
                    <Button appearance="outline" onPress={() => {
                        track("note_ai_discard");

                        if (isResponding) {
                            stopGeneration()
                        }
                        hideAiInput()
                        setDiscardTipVisible(false)
                        if (action === 'activeBySpace' || action === 'finishWriting') {
                            const focus = cancelSelection ? cancelSelection.focus : editor.selection.focus
                            Transforms.removeNodes(editor, {
                                at: {
                                    anchor: { offset: 0, path: startAnchor.path.slice(0, 1) },
                                    focus
                                },
                                match: (n, p) => {
                                    return (p.length === 1 && p[0] >= startAnchor.path[0] && p[0] <= focus.path[0])
                                },
                            })
                            Transforms.insertNodes(editor,
                                { type: 'paragraph', children: [{ text: '' }] },
                                { at: startAnchor.path.slice(0, 1), select: true }
                            )
                        }

                        if(isMobile()) {
                            ReactEditor.focus(editor)
                            resetMobileToolbarPosition()
                        }
                    }}>是的</Button>
                    <Button onPress={() => {
                        track("note_ai_not_discard");
                        setDiscardTipVisible(false)
                        if ((action === 'activeBySpace' || action === 'finishWriting') && !cancelSelection) {
                            // 续写取消时，保存当前的光标位置
                            setCancelSelection(editor.selection)
                        }

                        if(isMobile()) {
                            ReactEditor.focus(editor)
                            resetMobileToolbarPosition()
                        }
                    }}>不放弃</Button>
                </div>
            </Modal>
        </div>

    )
}

const Backdrop = React.forwardRef((props, ref) => {
    const { open, className, ...other } = props;
    return (
        <div
            className={`discard-tip-modal-backdrop ${className}`}
            ref={ref}
            {...omit(other, ['ownerState'])}
        />
    );
});