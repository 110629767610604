
import React from 'react'

import Loading from 'bwax-ui/components/Loading';

import RecordFormForEntity from './RecordFormForEntity';
import RenderWithData from 'bwax-ui/store/RenderWithData';

export default function AddRecordForm(props) {

    const { facade, entityName } = props

    async function loadData() {
        await facade.prepare([entityName]);
        return "done"
    }

    return (
        <RenderWithData {...{
            ...props,
            loadData,
            dataKey: "AddRecordForm::" + entityName,
            Component: AddRecordFormInner,
            noSuspense: true,
            // fallback: (
            //     <div style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", minHeight: "8rem" }}>
            //     <Loading />
            // </div>
            // )
        }} />
    )

}


function AddRecordFormInner({ 
    facade, entityName, fieldPaths, onAdded, fieldItems, hiddenFields, color, initialValues, confirmText, onBeforeAdd = _ => {}
}) {

    const entity = facade.entities.find(e => e.name == entityName);

    // useEffect(() => {
    //     (async () => {
    //         await facade.prepare([entityName]);
    //         setEntity(facade.entities.find(e => e.name == entityName) || null)
    //     })();
    // }, [entityName, facade])

    async function addRecord({ validated, fixedValues }) {
        const [record, error] = await facade.add({
            entityName,
            formData: {
                ...validated,
                ...fixedValues,
            },
            fieldPaths,
        });
        if(error) {
            setError(error);
        }
        onAdded(record)
    };

    function renderContent() {

        return (
            <RecordFormForEntity entity={entity} facade={facade} {...{  
                fieldPaths, onAdded, fieldItems, initialValues, hiddenFields, 

                onBeforeConfirm: onBeforeAdd,
                onConfirm: ({ validated, fixedValues }) => {
                    addRecord({ validated, fixedValues })
                },
                color,
                confirmText: confirmText || "添加",

                isFieldApplicable: f => f.initializable,
                isReadyToConfirm: ({requiredFields, validated }) => {
                    const requiredEditKeys = requiredFields.map(f => f.name);
                    const isMissingRequiredFields = requiredEditKeys.some(name => {
                        // 
                        const field = requiredFields.find(f => f.name == name);
                        if(field && (field.type == "ShortText" || field.type == "Text")) {
                            return !(validated[name] && validated[name].trim().length > 0)
                        }

                        return validated[name] === undefined
                    });
                    return !isMissingRequiredFields;
                }

            }} />
        )
    }

    return renderContent()
}
