// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function toString(color) {
  if (color >= 17853691) {
    if (color >= 615275721) {
      if (color >= 848052599) {
        if (color >= 937840486) {
          if (color >= 970337770) {
            if (color >= 1019794363) {
              if (color >= 1056451157) {
                if (color >= 1057865995) {
                  return "howitworks::PostInvestment";
                } else {
                  return "Location";
                }
              } else if (color >= 1033902412) {
                return "vipservices::Discussion";
              } else {
                return "TimeWireFrame";
              }
            } else if (color >= 991147123) {
              return "Finish";
            } else {
              return "Word";
            }
          } else if (color !== 941132226) {
            if (color >= 964957014) {
              if (color >= 966255463) {
                return "RiskAmount";
              } else {
                return "vipservices::VisitCompany";
              }
            } else if (color >= 948354667) {
              return "User";
            } else {
              return "Heart";
            }
          } else {
            return "YiDian";
          }
        } else if (color >= 859442101) {
          if (color !== 891311836) {
            if (color >= 914591791) {
              if (color >= 936769581) {
                return "Time";
              } else {
                return "Risk";
              }
            } else if (color >= 892562298) {
              return "Plus";
            } else {
              return "More";
            }
          } else {
            return "Evaluation";
          }
        } else if (color !== 848053274) {
          if (color >= 856161164) {
            if (color >= 857339527) {
              return "HelpWireFrame";
            } else {
              return "RadioChecked";
            }
          } else if (color >= 848349195) {
            return "Lock";
          } else {
            return "Like";
          }
        } else {
          return "Link";
        }
      } else if (color >= 736958243) {
        if (color >= 781813793) {
          if (color !== 793051890) {
            if (color >= 830604864) {
              if (color >= 843340294) {
                return "Wechat";
              } else {
                return "vipservices::Health";
              }
            } else if (color >= 803495649) {
              return "Help";
            } else {
              return "Fold";
            }
          } else {
            return "Grip";
          }
        } else if (color !== 770176554) {
          if (color >= 777019061) {
            if (color >= 781515865) {
              return "Find";
            } else {
              return "FinishWireFrame";
            }
          } else if (color >= 774171342) {
            return "Folder";
          } else {
            return "Bell";
          }
        } else {
          return "Edit";
        }
      } else if (color >= 640930430) {
        if (color !== 645803780) {
          if (color >= 699070434) {
            if (color >= 729248459) {
              return "MyReco";
            } else {
              return "Increase";
            }
          } else if (color >= 656679901) {
            return "DeleteWireFrame";
          } else {
            return "Collection";
          }
        } else {
          return "CheckboxChecked";
        }
      } else if (color !== 620683239) {
        if (color >= 624138838) {
          if (color >= 635869326) {
            return "Reporting";
          } else {
            return "Loading2";
          }
        } else if (color >= 623289936) {
          return "ArrowLeft";
        } else {
          return "Position";
        }
      } else {
        return "viprights::Discounts";
      }
    } else if (color >= 285799930) {
      if (color >= 476041533) {
        if (color >= 527250507) {
          if (color !== 535074475) {
            if (color >= 599023455) {
              if (color >= 614123103) {
                return "howitworks::Financing";
              } else {
                return "ProfileWireFrame";
              }
            } else if (color >= 539422080) {
              return "viprights::Invest";
            } else {
              return "Delete";
            }
          } else {
            return "ArrowDown";
          }
        } else if (color !== 482944330) {
          if (color >= 517242717) {
            if (color >= 520040085) {
              return "CheckboxIndeterm";
            } else {
              return "viprights::Intern";
            }
          } else if (color >= 514305566) {
            return "EditWireFrame";
          } else {
            return "TouTiao";
          }
        } else {
          return "howitworks::Investigation";
        }
      } else if (color >= 359501950) {
        if (color !== 369301438) {
          if (color >= 405471965) {
            if (color >= 415037852) {
              return "cb::Health";
            } else {
              return "Leaderboard";
            }
          } else if (color >= 395056008) {
            return "Search";
          } else {
            return "Decrease";
          }
        } else {
          return "KuaiBao";
        }
      } else if (color !== 285847652) {
        if (color >= 295974658) {
          if (color >= 335148934) {
            return "Souhu";
          } else {
            return "WechatPay";
          }
        } else if (color >= 290958458) {
          return "PhoneWireFrame";
        } else {
          return "ArrowGuideLeft";
        }
      } else {
        return "ArrowUp";
      }
    } else if (color >= 169727495) {
      if (color >= 218632497) {
        if (color !== 237368369) {
          if (color >= 258811397) {
            if (color >= 260605998) {
              return "HomePage";
            } else {
              return "BellWireFrame";
            }
          } else if (color >= 256529599) {
            return "Share";
          } else {
            return "LikeWireFrame";
          }
        } else {
          return "SignOut";
        }
      } else if (color !== 172169431) {
        if (color >= 183353552) {
          if (color >= 197584469) {
            return "ArrowGuideDown";
          } else {
            return "EyeOn";
          }
        } else if (color >= 178314035) {
          return "PaymentCode";
        } else {
          return "UnFolder";
        }
      } else {
        return "Excel";
      }
    } else if (color >= 94326179) {
      if (color !== 95560584) {
        if (color >= 124931584) {
          if (color >= 146342424) {
            return "SettingWireFrame";
          } else {
            return "howitworks::RiskEvaluate";
          }
        } else if (color >= 106380200) {
          return "Error";
        } else {
          return "Success";
        }
      } else {
        return "Browser";
      }
    } else if (color !== 31318324) {
      if (color >= 50834029) {
        if (color >= 64021186) {
          return "HeadTeacher";
        } else {
          return "Empty";
        }
      } else if (color >= 48004564) {
        return "Label";
      } else {
        return "Course";
      }
    } else {
      return "LabelWireFrame";
    }
  } else if (color >= -510763666) {
    if (color >= -185005712) {
      if (color >= 18145) {
        if (color >= 3993554) {
          if (color >= 4199867) {
            if (color >= 16242303) {
              if (color >= 16974618) {
                return "UnFold";
              } else {
                return "vipservices::Course";
              }
            } else if (color >= 4293053) {
              return "VIP";
            } else {
              return "Tel";
            }
          } else if (color >= 4000072) {
            return "Pay";
          } else {
            return "PDF";
          }
        } else if (color !== 3308326) {
          if (color >= 3901504) {
            if (color >= 3951439) {
              return "Off";
            } else {
              return "New";
            }
          } else if (color >= 3605357) {
            return "Hot";
          } else {
            return "QR";
          }
        } else {
          return "Buy";
        }
      } else if (color >= -104095107) {
        if (color !== -56508237) {
          if (color >= -33251353) {
            if (color >= 17727) {
              return "On";
            } else {
              return "Copyright";
            }
          } else if (color >= -44814809) {
            return "viprights::Health";
          } else {
            return "viprights::VisitCompany";
          }
        } else {
          return "viprights::Discussion";
        }
      } else if (color !== -169822322) {
        if (color >= -145831712) {
          if (color >= -106078275) {
            return "DefaultFile";
          } else {
            return "ErrorWireFrame";
          }
        } else if (color >= -146742163) {
          return "howitworks::Invest";
        } else {
          return "Setting";
        }
      } else {
        return "Nickname";
      }
    } else if (color >= -333399355) {
      if (color >= -244427632) {
        if (color !== -234193541) {
          if (color >= -217526818) {
            if (color >= -203710662) {
              return "ReportingWireFrame";
            } else {
              return "Edition";
            }
          } else if (color >= -231353558) {
            return "Alipay";
          } else {
            return "howitworks::Submit";
          }
        } else {
          return "Preference";
        }
      } else if (color !== -331350686) {
        if (color >= -303320221) {
          if (color >= -299704632) {
            return "RecoPlan";
          } else {
            return "howitworks::Audit";
          }
        } else if (color >= -328727512) {
          return "Verified";
        } else {
          return "Camera";
        }
      } else {
        return "RadioNormal";
      }
    } else if (color >= -405753535) {
      if (color !== -399324709) {
        if (color >= -338800802) {
          if (color >= -335084144) {
            return "Change";
          } else {
            return "howitworks::Communication";
          }
        } else if (color >= -380348567) {
          return "CommentWireFrame";
        } else {
          return "RiskAmountWireFrame";
        }
      } else {
        return "cb::Course";
      }
    } else if (color !== -506655690) {
      if (color >= -447413890) {
        if (color >= -409668748) {
          return "cb::Building";
        } else {
          return "cb::Worker";
        }
      } else if (color >= -499471966) {
        return "BuyWireFrame";
      } else {
        return "vipservices::Discounts";
      }
    } else {
      return "SalesData";
    }
  } else if (color >= -754821258) {
    if (color >= -644245463) {
      if (color >= -596662454) {
        if (color !== -588893664) {
          if (color >= -571874401) {
            if (color >= -544153366) {
              return "CheckboxNormal";
            } else {
              return "howitworks::Replay";
            }
          } else if (color >= -578166465) {
            return "Colon";
          } else {
            return "CollectionWireFrame";
          }
        } else {
          return "howitworks::Discussion";
        }
      } else if (color !== -636859038) {
        if (color >= -621869304) {
          if (color >= -611285096) {
            return "Close";
          } else {
            return "Weibo";
          }
        } else if (color >= -628608622) {
          return "TimeFilling";
        } else {
          return "TargetAmount";
        }
      } else {
        return "HeartWireFrame";
      }
    } else if (color >= -719244466) {
      if (color !== -703661335) {
        if (color >= -688046722) {
          if (color >= -656729378) {
            return "Calendar";
          } else {
            return "Attention";
          }
        } else if (color >= -700189092) {
          return "Loading";
        } else {
          return "Phone";
        }
      } else {
        return "Number";
      }
    } else if (color !== -754676888) {
      if (color >= -742920845) {
        if (color >= -732641895) {
          return "vipservices::Invest";
        } else {
          return "ArrowRight";
        }
      } else if (color >= -745965442) {
        return "Industry";
      } else {
        return "vipservices::Intern";
      }
    } else {
      return "cb::Shopping";
    }
  } else if (color >= -841264503) {
    if (color >= -807805785) {
      if (color !== -794231115) {
        if (color >= -787300934) {
          if (color >= -760661150) {
            return "howitworks::Vote";
          } else {
            return "Introduction";
          }
        } else if (color >= -793101027) {
          return "Company";
        } else {
          return "EyeClose";
        }
      } else {
        return "howitworks::Sign";
      }
    } else if (color !== -838467024) {
      if (color >= -826170817) {
        if (color >= -816205605) {
          return "howitworks::Register";
        } else {
          return "Comment";
        }
      } else if (color >= -838467023) {
        return "Broadcast2";
      } else {
        return "ArrowGuideRight";
      }
    } else {
      return "Broadcast1";
    }
  } else if (color >= -895622770) {
    if (color !== -878455021) {
      if (color >= -859177370) {
        if (color >= -852297376) {
          return "Safari";
        } else {
          return "viprights::Course";
        }
      } else if (color >= -871444429) {
        return "Investment";
      } else {
        return "ArrowGuideUp";
      }
    } else {
      return "cb::Explore";
    }
  } else if (color !== -1058128351) {
    if (color >= -981117655) {
      if (color >= -934387602) {
        return "Order";
      } else {
        return "PersonalProfile";
      }
    } else if (color >= -1044751279) {
      return "DropDown";
    } else {
      return "Profile";
    }
  } else {
    return "Upload";
  }
}

function toIconType(str) {
  switch (str) {
    case "Alipay" :
        return /* Alipay */-231353558;
    case "ArrowDown" :
        return /* ArrowDown */535074475;
    case "ArrowGuideDown" :
        return /* ArrowGuideDown */197584469;
    case "ArrowGuideLeft" :
        return /* ArrowGuideLeft */285799930;
    case "ArrowGuideRight" :
        return /* ArrowGuideRight */-841264503;
    case "ArrowGuideUp" :
        return /* ArrowGuideUp */-895622770;
    case "ArrowLeft" :
        return /* ArrowLeft */623289936;
    case "ArrowRight" :
        return /* ArrowRight */-742920845;
    case "ArrowUp" :
        return /* ArrowUp */285847652;
    case "Attention" :
        return /* Attention */-688046722;
    case "Bell" :
        return /* Bell */736958243;
    case "BellWireFrame" :
        return /* BellWireFrame */258811397;
    case "Broadcast1" :
        return /* Broadcast1 */-838467024;
    case "Broadcast2" :
        return /* Broadcast2 */-838467023;
    case "Browser" :
        return /* Browser */95560584;
    case "Buy" :
        return /* Buy */3308326;
    case "BuyWireFrame" :
        return /* BuyWireFrame */-499471966;
    case "Calendar" :
        return /* Calendar */-656729378;
    case "Camera" :
        return /* Camera */-333399355;
    case "Change" :
        return /* Change */-335084144;
    case "CheckboxChecked" :
        return /* CheckboxChecked */645803780;
    case "CheckboxIndeterm" :
        return /* CheckboxIndeterm */520040085;
    case "CheckboxNormal" :
        return /* CheckboxNormal */-544153366;
    case "Close" :
        return /* Close */-611285096;
    case "Collection" :
        return /* Collection */640930430;
    case "CollectionWireFrame" :
        return /* CollectionWireFrame */-596662454;
    case "Colon" :
        return /* Colon */-578166465;
    case "Comment" :
        return /* Comment */-826170817;
    case "CommentWireFrame" :
        return /* CommentWireFrame */-380348567;
    case "Company" :
        return /* Company */-793101027;
    case "Copyright" :
        return /* Copyright */-33251353;
    case "Course" :
        return /* Course */17853691;
    case "Decrease" :
        return /* Decrease */359501950;
    case "DefaultFile" :
        return /* DefaultFile */-106078275;
    case "Delete" :
        return /* Delete */527250507;
    case "DeleteWireFrame" :
        return /* DeleteWireFrame */656679901;
    case "DropDown" :
        return /* DropDown */-1044751279;
    case "Edit" :
        return /* Edit */770176554;
    case "EditWireFrame" :
        return /* EditWireFrame */514305566;
    case "Edition" :
        return /* Edition */-217526818;
    case "Empty" :
        return /* Empty */50834029;
    case "Error" :
        return /* Error */106380200;
    case "ErrorWireFrame" :
        return /* ErrorWireFrame */-145831712;
    case "Evaluation" :
        return /* Evaluation */891311836;
    case "Excel" :
        return /* Excel */172169431;
    case "EyeClose" :
        return /* EyeClose */-807805785;
    case "EyeOn" :
        return /* EyeOn */183353552;
    case "Find" :
        return /* Find */781515865;
    case "Finish" :
        return /* Finish */991147123;
    case "FinishWireFrame" :
        return /* FinishWireFrame */777019061;
    case "Fold" :
        return /* Fold */781813793;
    case "Folder" :
        return /* Folder */774171342;
    case "Grip" :
        return /* Grip */793051890;
    case "HeadTeacher" :
        return /* HeadTeacher */64021186;
    case "Heart" :
        return /* Heart */937840486;
    case "HeartWireFrame" :
        return /* HeartWireFrame */-636859038;
    case "Help" :
        return /* Help */803495649;
    case "HelpWireFrame" :
        return /* HelpWireFrame */857339527;
    case "HomePage" :
        return /* HomePage */260605998;
    case "Hot" :
        return /* Hot */3605357;
    case "Increase" :
        return /* Increase */699070434;
    case "Industry" :
        return /* Industry */-745965442;
    case "Introduction" :
        return /* Introduction */-787300934;
    case "Investment" :
        return /* Investment */-871444429;
    case "KuaiBao" :
        return /* KuaiBao */369301438;
    case "Label" :
        return /* Label */48004564;
    case "LabelWireFrame" :
        return /* LabelWireFrame */31318324;
    case "Leaderboard" :
        return /* Leaderboard */405471965;
    case "Like" :
        return /* Like */848052599;
    case "LikeWireFrame" :
        return /* LikeWireFrame */218632497;
    case "Link" :
        return /* Link */848053274;
    case "Loading" :
        return /* Loading */-700189092;
    case "Loading2" :
        return /* Loading2 */624138838;
    case "Location" :
        return /* Location */1056451157;
    case "Lock" :
        return /* Lock */848349195;
    case "More" :
        return /* More */859442101;
    case "MyReco" :
        return /* MyReco */729248459;
    case "New" :
        return /* New */3901504;
    case "Nickname" :
        return /* Nickname */-169822322;
    case "Number" :
        return /* Number */-703661335;
    case "Off" :
        return /* Off */3951439;
    case "On" :
        return /* On */17727;
    case "Order" :
        return /* Order */-934387602;
    case "PDF" :
        return /* PDF */3993554;
    case "Pay" :
        return /* Pay */4000072;
    case "PaymentCode" :
        return /* PaymentCode */178314035;
    case "PersonalProfile" :
        return /* PersonalProfile */-981117655;
    case "Phone" :
        return /* Phone */-719244466;
    case "PhoneWireFrame" :
        return /* PhoneWireFrame */290958458;
    case "Plus" :
        return /* Plus */892562298;
    case "Position" :
        return /* Position */615275721;
    case "Preference" :
        return /* Preference */-234193541;
    case "Profile" :
        return /* Profile */-1063620343;
    case "ProfileWireFrame" :
        return /* ProfileWireFrame */599023455;
    case "QR" :
        return /* QR */18145;
    case "RadioChecked" :
        return /* RadioChecked */856161164;
    case "RadioNormal" :
        return /* RadioNormal */-331350686;
    case "RecoPlan" :
        return /* RecoPlan */-299704632;
    case "Reporting" :
        return /* Reporting */635869326;
    case "ReportingWireFrame" :
        return /* ReportingWireFrame */-203710662;
    case "Risk" :
        return /* Risk */914591791;
    case "RiskAmount" :
        return /* RiskAmount */966255463;
    case "RiskAmountWireFrame" :
        return /* RiskAmountWireFrame */-405753535;
    case "Safari" :
        return /* Safari */-852297376;
    case "SalesData" :
        return /* SalesData */-506655690;
    case "Search" :
        return /* Search */395056008;
    case "Setting" :
        return /* Setting */-185005712;
    case "SettingWireFrame" :
        return /* SettingWireFrame */146342424;
    case "Share" :
        return /* Share */256529599;
    case "SignOut" :
        return /* SignOut */237368369;
    case "Souhu" :
        return /* Souhu */335148934;
    case "Success" :
        return /* Success */94326179;
    case "TargetAmount" :
        return /* TargetAmount */-644245463;
    case "Tel" :
        return /* Tel */4199867;
    case "Time" :
        return /* Time */936769581;
    case "TimeFilling" :
        return /* TimeFilling */-628608622;
    case "TimeWireFrame" :
        return /* TimeWireFrame */1019794363;
    case "TouTiao" :
        return /* TouTiao */476041533;
    case "UnFold" :
        return /* UnFold */16974618;
    case "UnFolder" :
        return /* UnFolder */169727495;
    case "Upload" :
        return /* Upload */-1058128351;
    case "User" :
        return /* User */948354667;
    case "VIP" :
        return /* VIP */4293053;
    case "Verified" :
        return /* Verified */-328727512;
    case "Wechat" :
        return /* Wechat */843340294;
    case "WechatPay" :
        return /* WechatPay */295974658;
    case "Weibo" :
        return /* Weibo */-621869304;
    case "Word" :
        return /* Word */970337770;
    case "YiDian" :
        return /* YiDian */941132226;
    case "cb::Building" :
        return /* cb_Building */-409668748;
    case "cb::Course" :
        return /* cb_Course */-399324709;
    case "cb::Explore" :
        return /* cb_Explore */-878455021;
    case "cb::Health" :
        return /* cb_Health */415037852;
    case "cb::Shopping" :
        return /* cb_Shopping */-754676888;
    case "cb::Worker" :
        return /* cb_Worker */-447413890;
    case "howitworks::Audit" :
        return /* howitworks_Audit */-303320221;
    case "howitworks::Communication" :
        return /* howitworks_Communication */-338800802;
    case "howitworks::Discussion" :
        return /* howitworks_Discussion */-588893664;
    case "howitworks::Financing" :
        return /* howitworks_Financing */614123103;
    case "howitworks::Invest" :
        return /* howitworks_Invest */-146742163;
    case "howitworks::Investigation" :
        return /* howitworks_Investigation */482944330;
    case "howitworks::PostInvestment" :
        return /* howitworks_PostInvestment */1057865995;
    case "howitworks::Register" :
        return /* howitworks_Register */-816205605;
    case "howitworks::Replay" :
        return /* howitworks_Replay */-571874401;
    case "howitworks::RiskEvaluate" :
        return /* howitworks_RiskEvaluate */124931584;
    case "howitworks::Sign" :
        return /* howitworks_Sign */-794231115;
    case "howitworks::Submit" :
        return /* howitworks_Submit */-244427632;
    case "howitworks::Vote" :
        return /* howitworks_Vote */-760661150;
    case "viprights::Course" :
        return /* viprights_Course */-859177370;
    case "viprights::Discounts" :
        return /* viprights_Discounts */620683239;
    case "viprights::Discussion" :
        return /* viprights_Discussion */-56508237;
    case "viprights::Health" :
        return /* viprights_Health */-44814809;
    case "viprights::Intern" :
        return /* viprights_Intern */517242717;
    case "viprights::Invest" :
        return /* viprights_Invest */539422080;
    case "viprights::VisitCompany" :
        return /* viprights_VisitCompany */-104095107;
    case "vipservices::Course" :
        return /* vipservices_Course */16242303;
    case "vipservices::Discounts" :
        return /* vipservices_Discounts */-510763666;
    case "vipservices::Discussion" :
        return /* vipservices_Discussion */1033902412;
    case "vipservices::Health" :
        return /* vipservices_Health */830604864;
    case "vipservices::Intern" :
        return /* vipservices_Intern */-754821258;
    case "vipservices::Invest" :
        return /* vipservices_Invest */-732641895;
    case "vipservices::VisitCompany" :
        return /* vipservices_VisitCompany */964957014;
    default:
      return ;
  }
}

exports.toString = toString;
exports.toIconType = toIconType;
/* No side effect */
