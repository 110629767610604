

import React, { useState, useEffect, useRef } from 'react'

import { useTextField } from 'react-aria'

import classNames from 'classnames';

import './TextInput.less'

const TextInput = React.forwardRef((props, ref) => {

    const inputRef = useRef(null);

    const { 
        prefix, suffix, onFocus, onBlur, className, color, styled, 
        // maxLength,
        value = "", onChange,
        ...otherProps 
    } = props;

    const [ focused, setFocused ] = useState(false);

    useEffect(() => {
        if(props.autoFocus && inputRef.current) {
            setTimeout(() => {
                inputRef.current.focus()
            }, 1)
        }
    }, [inputRef.current])

    // appearance:  outline, 

    const [ localValue, setLocalValue ] = useState(value);

    const [ isComposing, setIsComposing ] = useState(false);

    useEffect(() => {
        if(value != localValue) {
            setLocalValue(value)
        }
    }, [value])

    function doChange(v) {
        setLocalValue(v)
        if(!isComposing) {
            onChange(v)
        }
    }
    useEffect(() => {
        if(!isComposing) {
            onChange(localValue)
        }
    }, [isComposing]);


    const { labelProps, inputProps, descriptionProps, errorMessageProps } = useTextField({
        value: localValue, 
        onChange: (v, a) => {
            // if(maxLength && v.length > maxLength) {
            //     doChange(v.substring(0, maxLength))
            // } else {
                doChange(v);
            // }           
        },
        onFocus: e => {
            if(onFocus) { onFocus(e) }
            setFocused(true)
        },
        onBlur: e => {
            if(onBlur) { onBlur(e) }
            setFocused(false)
        },       
        "aria-label": "输入框",
        ...otherProps
    }, inputRef);

    const icon = (e, className) => (
        <div className={classNames("adornment", className)}>
            { e }
        </div>
    )

    return (
        <div className={classNames("lc-text-input", className, styled ? "styled" : null)} data-focused={focused} data-color={color}>
            { prefix ? icon(prefix, "prefix") : null }
            <input {...inputProps} ref={r => {
                inputRef.current = r;
                if(ref) {
                    if(typeof(ref) == "function") {
                        ref(r)
                    } else {
                        ref.current = r;
                    }
                }
            }} 
                onCompositionStart={_ => {
                    setIsComposing(true)
                }}
                onCompositionEnd={_ => {
                    setTimeout(() => {
                        setIsComposing(false)
                    }, 16);
                }}
            />
            { suffix ? icon(suffix, "suffix") : null }
        </div>
    )
})


export default TextInput;