
// This file is generated. Don't modify it.
import React from 'react'
export default function InvestmentIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M8,13 C10.7614237,13 13,10.7614237 13,8 C13,5.23857625 10.7614237,3 8,3 C5.23857625,3 3,5.23857625 3,8 C3,10.7614237 5.23857625,13 8,13 Z M8,14 C4.6862915,14 2,11.3137085 2,8 C2,4.6862915 4.6862915,2 8,2 C11.3137085,2 14,4.6862915 14,8 C14,11.3137085 11.3137085,14 8,14 Z M10.6736344,9.02870687 C10.6736344,9.06745528 10.6552215,9.10114955 10.6250912,9.12136611 L8.09582085,10.9610731 C8.06736447,10.9829744 8.03221247,10.9947674 7.99538656,10.9947674 C7.95856065,10.9947674 7.92340865,10.9812897 7.89495226,10.9610731 L5.36568191,9.12136611 C5.33722553,9.10114955 5.31713867,9.06745528 5.31713867,9.02870687 C5.31713867,8.98995847 5.33555163,8.9562642 5.36568191,8.93604764 L7.89495226,7.09802532 C7.92340865,7.07612405 7.95856065,7.06433105 7.99538656,7.06433105 C8.03221247,7.06433105 8.06736447,7.07780876 8.09582085,7.09802532 L10.6250912,8.93773235 C10.6552215,8.9562642 10.6736344,8.99164318 10.6736344,9.02870687 Z M9.646433,7.83424234 L8.17973934,6.77351095 C8.12835644,6.73700647 8.06488345,6.71267015 7.99838794,6.71267015 C7.93189242,6.71267015 7.86841943,6.73396443 7.81703653,6.77351095 L6.3508352,7.83896232 L5.36568191,7.12305082 C5.33555163,7.10283426 5.31713867,7.06745528 5.31713867,7.03039159 C5.31713867,6.99164318 5.33555163,6.95794891 5.36568191,6.93773235 L7.89495226,5.09802532 C7.92340865,5.07612405 7.95856065,5.06433105 7.99538656,5.06433105 C8.03221247,5.06433105 8.06736447,5.07780876 8.09582085,5.09802532 L10.6250912,6.93773235 C10.6535476,6.95794891 10.6736344,6.99164318 10.6736344,7.03039159 C10.6736344,7.06914 10.6552215,7.10283426 10.6250912,7.12305082 L9.646433,7.83424234 Z" id="InvestmentIcon-path-1"></path>
            </defs>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Personal/Investment">
                    <g>
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="InvestmentIcon-mask-2" fill="white">
                            <use href="#InvestmentIcon-path-1"></use>
                        </mask>
                        <use id="Mask" fill={color} fillRule="nonzero" href="#InvestmentIcon-path-1"></use>
                        <g id="Color/UI/Text" mask="url(#InvestmentIcon-mask-2)" fill={color} fillRule="nonzero">
                            <rect id="light-gray" x="0" y="0" width="16" height="16"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
