import fragment_helper from 'bwax/ml/utils/fragment_helper.bs';

import { unpack } from 'bwax/lang/LangHelper'

import lang_entry_slim from 'bwax/ml/lang/lang_entry_slim.bs'

import lang_entry_base from 'bwax/ml/lang/lang_entry_base.bs'

import invariant from 'invariant';

export function getDependedFragmentNames(externalNames, fragments) {
    const dependencies = fragments.map(f => {
        if (f.code && f.code.previewData) {

            const { externalNames } = f.code.previewData
            const fragmentsUsed = fragment_helper.get_fragment_names_as_js(externalNames || []);
            return [f.name, fragmentsUsed];

        } else {
            return null
        }
    }).filter(x => !!x);

    const this_key = "__this__";

    // 自己 dependency
    const fragments_used = fragment_helper.get_fragment_names_as_js(externalNames);

    // 所以能使用到的 fragment 的 dependencies 加上自己的 dependencies
    const full_deps = [...dependencies, [this_key, fragments_used]]

    // 2.根据相互依赖关系，创建一个 graph，相互关系作为 edge， A 使用了 B 使用则 A -> B
    let graph = fragment_helper.build_dependency_graph(full_deps);
    let depended_fragment_names = fragment_helper.explore_graph_as_js(graph, this_key).filter(n => n !== this_key);

    return depended_fragment_names;
}

export function sortFragments(fragments) {
    const fragmentSettings = (fragments || []).filter(f => {
        return !!f.code 
    }).map(f => {
        let name = f.name;
        let fragmentData = f;
        const { externalNames } = f.code.previewData || {}
        const fragmentsUsed = fragment_helper.get_fragment_names_as_js(externalNames || []);

        return [name, fragmentData, fragmentsUsed]
    })
    let fragmentGraph = fragment_helper.build_fragment_graph(fragmentSettings);

    // 2. 进行拓扑排序 （linearization）
    let sorted = fragment_helper.top_sort_as_js(fragmentGraph);

    return sorted
}


// return the fragments’ compiled code.
export function getDependedFragments(previewData, fragments) {

    if (previewData && previewData.externalNames) {
        const depended_fragment_names = getDependedFragmentNames(previewData.externalNames, fragments);

        let shouldInclude = f => {
            return (depended_fragment_names.indexOf(f.name.replace("Fr_", "")) !== -1)
        }
        return sortFragments(fragments).filter(shouldInclude).map(f => f.code && f.code.compiled);
    } else {
        return []
    }

}


export function combineDependedFragments(depended_fragments, entity_dict, data_type_dict, dts) {

    // const t0 = performance.now();
    // 拆分 fragments 的关键数据
    const tuples = depended_fragments.map(f => {

        const [n, defs, dts, entity_dict, data_type_dict] = (() => {
            if(f.length == 5) {
                // OLD version
                return f
            } else {
                const [ n, defs, dts ] = f;
                // 新版本的 entity_dict 和 data_type_dict 另外处理：
                return [ n, defs, dts, 0, 0 ]
            }
        })();

        // return dts, tyenv, module
        return [
            dts,
            [n, defs],
            [entity_dict, data_type_dict]
        ];


    }).filter(x => !!x);

    // const t1 = performance.now();
    // console.log("Deserialize fragment uses", t1 - t0, "ms");

    const additional_dtss = tuples.map(t => t[0]).filter(x => !!x);
    const additional_modules = tuples.map(t => t[1]).filter(x => !!x);
    const fragment_relevants = tuples.map(t => t[2]).filter(x => !!x);

    // 1. 聚合 entity_dict
    // 2. 聚合 data_type_dict
    let relevants_of_depended =
        lang_entry_base.array_to_list(
            [[entity_dict, data_type_dict], ...fragment_relevants]
        );

    let [merged_entity_dict, merged_data_type_dict] = lang_entry_slim.merge_entities_and_data_types(relevants_of_depended)

    // 3. 聚合 dts

    let involved_dts =
        lang_entry_slim.merge_dts_list(
            0,
            lang_entry_base.array_to_list([
                ...additional_dtss,
                dts
            ])
        )


    // 4. 聚合 module
    let depended_modules = lang_entry_base.array_to_list(additional_modules);

    return {
        entity_dict: merged_entity_dict,
        data_type_dict: merged_data_type_dict,
        dts: involved_dts,
        // modules: null,
        modules: depended_modules
    }
}


// return merged entity_dict, data_type_dict, dts, and (ast) modules.
export function combineFragments(fragments, externalNames, entity_dict, data_type_dict, dts) {
    const fs = getDependedFragments({ externalNames }, fragments);

    const depended_fragments = fs.map(unpack);

    return combineDependedFragments(depended_fragments, entity_dict, data_type_dict, dts);
}

//// for data interface:
export function toDataInterfaceParams(paramValues, predata, paramTypes) {

    // 转换成 String, Int, Float, Record，(JSON)
    if (paramValues.length !== paramTypes.length) {
        // TODO throw error?
        return null
    }
    const paramArr = paramValues.map((v, i) => {
        const t = paramTypes[i];
        const value = lang_entry_slim.json_to_value_for_type(t, v);
        return value
    })

    // console.log(paramValues, paramArr);

    // 如果 paramArr 的数量为 0, 则只使用 predata
    // 如果 paramArr 的数量为 1, 则 paramArr[0] 和 predata
    // 如果 paramArr 的数量为 2，则用 tuple 把 paramArr 装起来再加上 predata
    function getFinalParams() {
        if (paramArr.length === 0) {
            return [predata]
        } else if (paramArr.length === 1) {
            return [paramArr[0], predata]
        } else {
            const param = lang_entry_slim.pack_tuple(lang_entry_base.array_to_list(paramArr))
            return [param, predata]
        }
    }

    return lang_entry_base.array_to_list(getFinalParams());

}

export function resultValueToJs(returned, returnTypeMetas, {
    entity_dict, data_type_dict
}) {
    // 这个主要是要注意把 Entity 类型转成 { id }
    const [values, error] = lang_entry_slim.result_value_to_arr(returned);

    function processValueForEntity(values) {

        if (values === undefined) {
            return values
        } 
        // values must be array
        // const valueArr = returnTypeMetas.length <= 1 ? [ values ] : values

        return values.map((originalValue, i) => {

            if(!returnTypeMetas || !returnTypeMetas[i]) {
                // 没有 type meta
                return lang_entry_slim.value_to_js(originalValue);
            }

            const returnTypeMeta = returnTypeMetas[i];

            const value = lang_entry_slim.value_to_js_by_data_type(
                data_type_dict, returnTypeMeta.type, originalValue
            );

            if (returnTypeMeta.type && returnTypeMeta.type.startsWith("Entity_")) {
                if (!value) {
                    return value
                }
                // 
                if (returnTypeMeta.paged) {
                    return {
                        ...value,
                        data: value.data.map(v => ({ id: v.id }))
                    }
                } else if (returnTypeMeta.multivalued) {

                    return value.map(v => ({ id: v.id }))
                } else {
                    return { id: value.id }
                }
            } else {
                return value
            }
        })
    }
    return [processValueForEntity(values), error]
}

export function toDataInterfaceReturnValue(
    entity_dict, data_type_dict, returned, returnTypeMetas
) {
    return resultValueToJs(returned, returnTypeMetas, { entity_dict, data_type_dict })
}


// Deprecated： 用上面这个
export function toEventHandlerReturnValue(returned, returnTypeMetas, { entity_dict, data_type_dict }) {
    if(lang_entry_slim.is_a_result_value(returned)) {
        return resultValueToJs(returned, returnTypeMetas, { entity_dict, data_type_dict}) 
    } else {
        return [undefined, lang_entry_slim.value_to_js(returned)]
    }
}



function isUserEntity(entityName) {
    return entityName == "用户" || entityName == "验证用户";
}


// Prepare the dependency data 
// It returns [ preparedValue, error ]
// preparedValue 是可以直接传入 bwax runtime 作为参数的
export async function prepareData(deps, {
    targetEntityName, targetRecordId,  // optional
    getRecord, getUser,                 // required
    entity_dict, data_type_dict, domainEnv, // required
    webEnv, //
}) {
    // get dependency
    // 每个 dep 可能是一个跟 entity 有关的 selection
    // 对于 entity 来说，目前只有两种可能，要么就是 User，要么就是当前环境的目标 Entity
    //      （如果当前环境的目标 Entity 也是“用户”，导致有两个“用户”的 dep，约定前面那个是目标记录，后面的是当前用户)
    // 也可能是一个单独的类型，不如 Cmd.DomainEnv, Date 等
    // 需要进行相应的 data preparation


    // 在 deps 里面，最多只能有两个 recordDeps
    // 1. 如果 target entityName == "用户", 则在里面可能有一到两个 "用户" 的 recordDeps；
    //      1.1 如果只有一个，则根据情况确定是“当前用户" 还是“目标记录”
    //          a. 比如在虚拟字段中优先为目标记录
    //          b. 事件处理中肯定是目标记录（事件处理不支持用户）
    //          c. 自定义接口中也是优先认为是”目标记录”
    //          d. 其他没有“目标记录”的环境，则默认为“当前用户”
    //          技术上来说，只要传入了 targetRecordId，就优先为“目标记录”
                   
    //      1.2 如果有两个，则第一个是“目标记录“，第二个是”当前用户“
    // 2. 如果 target entityName 不是 ”用户”， 
    //      2.1 当有两个 recordDeps 时，其中一个必须是 target entityName 的，而另一个是用户的
    //      2.2 如果只有一个 recordDeps，那么这个可能是 target entityName 的，也可能是用户的
    //      它们一个代表目标记录，另一个代表当前用户，用 entityName 来分辨

    // 代码需先对以上的规则检验，再尝试准备记录

    // 除了 recordDeps，还有用类型名字指定的 dataDeps： 
    // Date 为执行 init 时的当前时间， Cmd.DomainEnv 为 domain 的 env。 UI.WebEnv 为 web 的 env
    
    if(!deps || deps.length === 0) {
        return [ lang_entry_slim.pack_list(0) /* empty list */, undefined]
    }

    // determine the target record and current user
    const recordDepCount = deps.filter(d => d && d.tag == "Record").length;

    // the predata is an [] of expr_val now
    let predata = [];
    let error = undefined;

    let metRecordDepCount = 0
    for (let d of deps) {
        if(!d) {
            predata.push(lang_entry_slim.nothing)
        } else if (d.tag == "Record") {
            const { entityName, dep, required } = d;
            const transform = required ? lang_entry_slim.transform_record : lang_entry_slim.transform_optional_record

            // 准备目标记录
            async function prepareTargetRecord() {
                if (entityName == targetEntityName) {
                    if (targetRecordId) {
                        const recordData = await getRecord(entityName, targetRecordId, dep);
                        // make it expr_val:
                        if(required && !recordData) {
                            error = "未找到目标记录:" + entityName + "[" + targetRecordId + "]"
                            return
                        }
                        const recordValue = transform(entity_dict, data_type_dict, entityName, recordData);
                        
                        predata.push(recordValue)
                    } else {
                        error = "没有目标记录ID: " + entityName
                    }
                } else {
                    error = "依赖的实体与目标实体不一致: " + entityName + " vs " + targetEntityName
                }
            }

            // 准备当前用户
            async function prepareCurrentUser () {
                const userData = await getUser(dep);
                // make it expr_val:
                if(required && !userData) {
                    const [_tree, _paths, _selectionText, actualDeps] = dep;
                    if(!actualDeps) {
                        // 这种情况，通常是因为旧的代码在不能使用用户的环境下声明了 PR_用户 (不加 Maybe），但是没有使用它：
                        // 因此这里就提供一个空用户给它，保证执行顺利
                        const userValue = transform(entity_dict, data_type_dict, entityName, {});
                        predata.push(userValue)        
                    } else {
                        error = "ERROR: 未找到必需的当前用户"
                    }
                } else if(!required && !userData) {
                    // 
                    // 只有当 dep 确实有依赖，才报”未找到当前用户”的错：
                    if(!lang_entry_slim.is_empty_record_dep(dep)){
                        error = "INFO: 未找到当前用户"
                    }                    
                    // 
                    predata.push(lang_entry_slim.nothing)

                } else {

                    const userValue = transform(entity_dict, data_type_dict, entityName, userData);
                    predata.push(userValue)

                }

            }

            if(isUserEntity(targetEntityName)) {
                // entityName must be user entity
                if(entityName !== targetEntityName) {
                    error = "依赖的实体与目标实体不一致: " + entityName + " vs " + targetEntityName
                } else {
                    if(recordDepCount > 1) {
                        // 如果有两个 recordDep, 则第一个是目标记录，第二个是当前用户
                        // 更一般地，如果有多个 recordDep，则前面的都是目标记录，最后一个是当前用户
                        if(metRecordDepCount < recordDepCount - 1) {
                            // 目标记录
                            await prepareTargetRecord()
                        } else {
                            // 当前用户
                            await prepareCurrentUser()
                        }
                    } else {
                        // 如果只有一个 recordDep， 则可能是当前用户也可能是目标记录，
                        // 如果传入了 targetRecordId，则优先为“目标记录”
                        if(targetRecordId) {
                            await prepareTargetRecord()
                        } else {
                            await prepareCurrentUser()
                        }
                    }
                }
            } else {
                // 
                if(isUserEntity(entityName)) {
                    // 当前记录
                    await prepareCurrentUser()
                } else {
                    // 当前记录
                    await prepareTargetRecord()
                }
            }

        } else {
            const { dataTypeName } = d;
            // Only support Date and Cmd.DomainEnv for now:

            if (dataTypeName == "Date") {
                // give it the current date:
                predata.push(lang_entry_slim.pack_date(Date.now()));
            } else if (dataTypeName == "Cmd.DomainEnv" || dataTypeName == "DomainEnv") {
                predata.push(lang_entry_slim.pack_domain_env(domainEnv));

            } else if ((dataTypeName == "UI.WebEnv" || dataTypeName == "UI.WebEnv") && webEnv) {
                predata.push(lang_entry_slim.pack_web_env(webEnv));

            } else if (dataTypeName == "Random.Random" || dataTypeName == "Random.Random") {
                predata.push(lang_entry_slim.pack_random(Math.random()));

            } else {
                error = "不支持的依赖数据: " + dataTypeName
            }

        }
    }
    if (predata.length == deps.length) {
        // 准备完备
        const predataValue = predata.length > 1 ? lang_entry_slim.pack_tuple_from_array(predata) : predata[0];
        return [ predataValue, error ]
    } else {
        // 表示没有准备完： 通常是因为 record 没有选择
        return [ undefined, error ]
    }

}


// 如果 target entity name 是用户, 那么至少要有两个 recordDeps，而且最后一个 recordDeps 有实际的依赖值
// 如果 target entity name 不是用户，那么检查是不是其中有一个 recordDeps 的 entityName 为 用户，且有依赖值
function isInvolvingCurrentUser(deps, targetEntityName) {
    const recordDeps = deps.filter(d => d && d.tag == "Record" && isUserEntity(d.entityName));

    if(isUserEntity(targetEntityName) && recordDeps.length > 1) {
        const lastOne = recordDeps[recordDeps.length - 1];
        const [_tree, _paths, _selectionText, theDeps] = lastOne.dep;
        // 最后一个 recordDeps 必须有实际的依赖值
        return !!theDeps
    }
    if(!isUserEntity(targetEntityName)) {
        // 任意一个用户的 record dep 有依赖值
        return recordDeps.some(d => {
            const [_tree, _paths, _selectionText, theDeps] = d.dep;
            return !!theDeps
        })
    }
    return false
}

function isInvolvingDataType(deps, dataTypes) {
    return deps.some(d => {
        if(d && d.tag == "Data") {
            return dataTypes.indexOf(d.dataTypeName) !== -1
        } else {
            return false
        }
    })
};

// 用于虚拟字段，检测是否可以缓存
export function isInvolvingDynamicData(moduleAst, {
    targetEntityName,
    entity_dict, data_type_dict, dts, 
}) {
    const deps = lang_entry_slim.determine_init_dependencies_as_js(
        entity_dict, data_type_dict, dts, moduleAst
    );
    return isInvolvingCurrentUser(deps, targetEntityName) 
        || isInvolvingDataType(deps, ["Date", "Random", "Random.Random"])
}

// 用于自定义接口，检测是否是针对记录的查询或者操作
export function isInvolvingTargetRecord(moduleAst, {
    targetEntityName,
    entity_dict, data_type_dict, dts, 
}) {
    const deps = lang_entry_slim.determine_init_dependencies_as_js(
        entity_dict, data_type_dict, dts, moduleAst
    );
    // 如果是 entity name 等于用户则需要两个对应的 recordDeps
    // 否则只要有一个就行
    if(targetEntityName) {
        const recordDeps = deps.filter(
            d => d && d.tag == "Record" && d.entityName == targetEntityName
        );
        if(isUserEntity(targetEntityName)) {
            return recordDeps.length > 1
        } else {
            return recordDeps.length > 0
        }
    } else {
        // 如果没有传入任何 target entity name，我假设”当前用户”肯定是被依赖的，
        // 那么，
        const recordDeps = deps.filter(
            d => d && d.tag == "Record"
        )
        return recordDeps.length > 1
    }
}




export function resolveDependencies(moduleAst,  {
    entity_dict, data_type_dict, dts
}) {
    const deps = lang_entry_slim.determine_init_dependencies_as_js(
        entity_dict, data_type_dict, dts, moduleAst
    );
    return deps
};

export function getTargetRecordDependency(deps, { targetEntityName }) {
    // 一般是在 virtual field 里面返回，找到第一个 entity name 相同的 recordDep
    const d = deps.find(d => d && d.tag == "Record" && d.entityName == targetEntityName);
    return d ? d.dep : undefined
};


export async function resolveDependenciesAndPrepareData(moduleAst, {
    targetEntityName, targetRecordId,               // optional
    getRecord, getUser,                             // required
    entity_dict, data_type_dict, dts, domainEnv,    // required
    webEnv, 
}){
    const deps = lang_entry_slim.determine_init_dependencies_as_js(
        entity_dict, data_type_dict, dts, moduleAst
    );

    const [predata, error] = await prepareData(deps, {
        targetEntityName, targetRecordId,
        getRecord, getUser,
        entity_dict, data_type_dict, domainEnv, webEnv,
    });

    return [predata, error]
}


// 以下两个是为了兼容旧的 Event Handler 
// eventType: RecordUpdated RecordAdded RecordDeleted

export function resolveEventHandlerDependencies(moduleAst,  {
    entity_dict, data_type_dict, dts, eventType
}) {
    if(eventType == "RecordAdded") {
        // 与新版本兼容
        const deps = lang_entry_slim.determine_init_dependencies_as_js(
            entity_dict, data_type_dict, dts, moduleAst
        );
        return deps

    } else if(eventType == "RecordUpdated") {

        // init (updatedRecord, updates): (PR_食品, Updates_of_食品) -> (Model, Cmd Msg) 

        // NEW VERSION:
        // init updates (record, domainEnv): Updates_of_食品 -> (R_食品, Cmd.DomainEnv) -> (Model, Cmd Msg)

        if(lang_entry_slim.is_old_event_handler(moduleAst)) { 
            const dep = lang_entry_slim.determine_updated_event_dependencies(
                entity_dict, data_type_dict, dts, moduleAst
            );
            return [dep]

        } else {            
            const deps = lang_entry_slim.determine_init_dependencies_as_js(
                entity_dict, data_type_dict, dts, moduleAst
            );
            return deps
        }

    } else {
        // init deletedRecord: Raw_明星 -> (Model, Cmd Msg)
        // NEW VERSION
        // init deletedRecord (now, domainEnv): Raw_明星 -> (Date, Cmd.DomainEnv) -> (Model, Cmd Msg)
        if(lang_entry_slim.is_old_event_handler(moduleAst)) { 
            return []
        } else {            
            const deps = lang_entry_slim.determine_init_dependencies_as_js(
                entity_dict, data_type_dict, dts, moduleAst
            );
            return deps
        }
    }

};

function packupParams (paramArr, predata) {
    function getFinalParams() {
        if (paramArr.length === 0) {
            return [predata]
        } else if (paramArr.length === 1) {
            return [paramArr[0], predata]
        } else {
            const param = lang_entry_slim.pack_tuple(lang_entry_base.array_to_list(paramArr))
            return [param, predata]
        }
    }
    return lang_entry_base.array_to_list(getFinalParams());
}

export function toEventHandlerParams (eventType, moduleAst, params, predata) {
    if(eventType == "RecordAdded") {
        // 与新版本兼容
        return packupParams(params, predata);

    } else if(eventType == "RecordUpdated") {
        // init (updatedRecord, updates): (PR_食品, Updates_of_食品) -> (Model, Cmd Msg) 
        // NEW VERSION:
        // init updates (record, domainEnv): Updates_of_食品 -> (R_食品, Cmd.DomainEnv) -> (Model, Cmd Msg)
        if(lang_entry_slim.is_old_event_handler(moduleAst)) { 
            // supposing the params is [updates]
            // and the predata one element - updateRecord
            invariant(params.length == 1, "RecordUpdate event has one param");
            const updates = params[0];

            return lang_entry_base.array_to_list([lang_entry_slim.pack_tuple_from_array([predata, updates])])

        } else {
            return packupParams(params, predata);
        }

    } else {
        // RecordDeleted
        // init deletedRecord: Raw_明星 -> (Model, Cmd Msg)
        // NEW VERSION
        // init deletedRecord (now, domainEnv): Raw_明星 -> (Date, Cmd.DomainEnv) -> (Model, Cmd Msg)

        if(lang_entry_slim.is_old_event_handler(moduleAst)) {
            // supposing the params is [updates]
            // and the predata is nothing
            invariant(params.length == 1, "RecordUpdate event has one param");
            const deleted = params[0];
            return lang_entry_base.array_to_list([deleted])
        } else {
            return packupParams(params, predata);
        }
    }
};
