
// Import the Slate editor factory.
import { Editor, Element as SlateElement, Transforms, Text, Node, Path, Range } from 'slate'
import omit from 'lodash/omit'
import { isMobile } from 'bwax/clientEnv'

import { removeFirstItemContentFromList, removeOtherItemContentFromList, isInLink } from './utils/ListHelper'
import { isBlockType, isList, blockTypes } from './SlateEditor'
import { inlineStyles } from './buttons/FormatClearButton'

export function getCurrentBlock (editor, path, targetType) {
    // const [currentNode, _] = Editor.node(editor, path)
    // const [parentNode, parentPath] = Editor.parent(editor, path)
    const ancestors = Array.from(Node.ancestors(editor, path, { reverse: true }))
    const currentBlock = ancestors.length > 1 ? 
        ancestors.find(ancestor => {
            const [ancestorNode, _] = ancestor
            return targetType ? ancestorNode.type === targetType : 
                (!Editor.isEditor(ancestorNode) && SlateElement.isElement(ancestorNode) && (
                    blockTypes.some(t => t === ancestorNode.type) || 
                    (ancestors.length === 2 && ancestorNode.type === 'list-item') // 单个 list-item 的情况
                ))
        }) : 
        Editor.node(editor, path)

    return currentBlock
}

export function getCurrentBlockType (editor, path) {
    const [currentBlockNode, _] = getCurrentBlock(editor, path)
    return currentBlockNode.type
}

export default function (event, editor, aiEnabled, setAiInputInfo, isCompositionEditing, track, updateBrickDOMRefs) {

    const { selection } = editor
    if(!selection || isCompositionEditing) {
        return
    }
    const { focus } = selection
    const atomicTypes = ["image", "video", "audio"]
    const [parentNode, parentPath] = Editor.parent(
        editor,
        isInLink(editor) ? Path.parent(focus.path) : focus.path
    );

    const textContent = Editor.string(editor, parentPath)
    const [textContainerNode, textContainerPath] = Editor.node(editor, parentPath) // paragraph/quote/heading

    const { ctrlKey, metaKey, shiftKey, key, code } = event
    // add bold / italic / underline
    if (ctrlKey || metaKey) {
        function isActiveFormat(style) {
            const [match] = Editor.nodes(editor, {
                match: n => n[style] === true,
            })
    
            return !!match
        }
        switch (key) {
            case 'b': {
                event.preventDefault()
                Transforms.setNodes(
                    editor,
                    { bold: isActiveFormat("bold") ? null : true },
                    { match: Text.isText, split: true }
                )
                break
            }

            case 'i': {
                event.preventDefault()
                Transforms.setNodes(
                    editor,
                    { italic: isActiveFormat("italic") ? null : true },
                    { match: Text.isText, split: true }
                )
                break
            }

            case 'u': {
                event.preventDefault()
                Transforms.setNodes(
                    editor,
                    { underline: isActiveFormat("underline") ? null : true },
                    { match: Text.isText, split: true }
                )
                break
            }

            case 'z': {
                // undo 时更新
                updateBrickDOMRefs()
            }
        }
    }

    // 自定义回车和退格方法
    function isActiveType(type) {
        const [match] = Array.from(
            Editor.nodes(editor, {
                at: Editor.unhangRange(editor, selection),
                match: n => {
                    return !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === type
                }

            })
        )

        return !!match
    }

    if (key === 'Enter') {
        if (selection) {
            let isEnd = Editor.isEnd(editor, focus, textContainerPath)
            const nextPoint = Editor.after(editor, parentPath)
            
            if(isInLink(editor)) {
                const [linkNode, linkPath] = Editor.node(editor, Path.parent(focus.path))
                if(linkPath[linkPath.length - 1] !== textContainerNode.children.length - 1) {
                    // link 不是 paragraph 中的最后一个 child, 若前面的内容是空文本，则光标是在句尾
                    // link 可能有多个 text child，不能用 focus.offset 判断
                    const afterText = Editor.string(editor, {
                        ...selection,
                        focus: Editor.point(editor, Path.parent(linkPath), { edge: 'end' })
                    })
                    isEnd = Editor.isEnd(editor, focus, linkPath) && !afterText
                } else {
                    // link 是 paragraph 中的最后一个 child，则光标是在句尾
                    isEnd = Editor.isEnd(editor, focus, linkPath)
                }
            }

            if(isActiveType('list-item')) {
                if(parentPath.length === 2) {
                    // 如果在单独的 list-item 中按 enter，转成普通的 paragraph 
                    event.preventDefault()
                    Transforms.unwrapNodes(editor, { at: Path.parent(parentPath)})
                } else if (parentPath.length === 3) {
                    if(isEnd) {
                        const listItemStr = Editor.string(editor, parentPath.slice(0, 2))
                        const listItemChildren = Array.from(Node.children(editor, parentPath.slice(0, 2)))
                        if(listItemStr === '' && listItemChildren.length === 1) {
                            // 当前 list-item 内容为空，则改为 paragraph，原本的 list 被分割
                            event.preventDefault()
                            removeOtherItemContentFromList(editor, false)
                        } else if(!nextPoint || nextPoint.path[0] !== parentPath[0] || nextPoint.path[1] === parentPath[1] + 1) {
                            // is last child of current list-item
                            // if not last child, add a paragraph (default)
                            event.preventDefault()
                            const newLine = { type: 'list-item', children: [{ type: "paragraph", children: [{ text: "" }] }] }
                            Transforms.insertNodes(editor, newLine, { at: [parentPath[0], parentPath[1] + 1], select: true })
                        }
                    } else {
                        const [listItemNode, listItemPath] = parentPath.length === 3 ? 
                            Editor.node(editor, parentPath.slice(0, 2)) : Editor.node(editor, parentPath.slice(0, 4))
                        if(listItemNode.children.length === 1) {
                            // 如果这个 li 没有换行，则新增一个 li(原本的内容分段)
                            event.preventDefault()
                            Transforms.splitNodes(editor, { always: true })
                            const [newParagraphNode, newParagraphPath] = Editor.parent(editor, editor.selection.focus.path);
                            Transforms.removeNodes(editor)
                            Transforms.insertNodes(editor, {type: 'list-item', children: [newParagraphNode] }, 
                                { at: [parentPath[0], parentPath[1] + 1] }
                            )
                            Transforms.move(editor)
                        }
                    }
                } else {
                    // 在嵌套的 list 中
                    const [containerNodeOfParentNode, containerPathOfParentNode] = Editor.node(editor, Path.parent(parentPath))
                    const ancestors = Array.from(Node.ancestors(editor, parentPath, { reverse: true }))
                    const [currentList, currentListPath] = ancestors.find(ancestor => {
                        const [ancestorNode, _] = ancestor
                        return isList(ancestorNode.type)
                    })
                    const [__, currentListItemPath] = ancestors.find(ancestor => {
                        const [ancestorNode, _] = ancestor
                        return ancestorNode.type === 'list-item'
                    })

                    if(parentPath[parentPath.length - 1] === 0 && containerNodeOfParentNode.type === 'nestWrapper' && 
                        containerPathOfParentNode[containerPathOfParentNode.length - 1] === 0
                    ) {
                        event.preventDefault()
                        // 如果是第一个 nestWrapper 中的第一个 paragraph，enter 后新增一个 li，原本 li 之后的所有嵌套的内容都移入新增的 li 中
                        Transforms.splitNodes(editor, { always: true })
                        const [_, newParagraphPath] = Editor.parent(editor, editor.selection.focus.path);
                        const currentAndAfterNodes = Array.from(Editor.nodes(editor, {
                            at: containerPathOfParentNode,
                            match: (_, p) => p.length === newParagraphPath.length && Path.compare(p, newParagraphPath) >= 0,
                            mode: 'highest'
                        })).map(nodeEntry => {
                            const [node, _] = nodeEntry
                            return node
                        })

                        const newListItem = {
                            type: 'list-item',
                            children: [{ type: 'nestWrapper', children: currentAndAfterNodes }]
                        }
                        Transforms.insertNodes(editor, newListItem, { 
                            at: [...currentListPath, currentListItemPath[currentListPath.length] + 1]
                        })
                        Transforms.removeNodes(editor, {
                            at: containerPathOfParentNode,
                            match: (_, p) => p.length === newParagraphPath.length && Path.compare(p, newParagraphPath) >= 0
                        })
                        Transforms.move(editor) // 光标下移

                        Transforms.unwrapNodes(editor, {
                            at: containerPathOfParentNode,
                            match: (n, _) => n.type === 'nestWrapper'
                        })
                    } else if(isEnd) {
                        if(!textContent) {
                            if(currentListItemPath[currentListItemPath.length - 1] === currentList.children.length - 1 &&
                                containerNodeOfParentNode.type === 'list-item'
                            ) {
                                // 是最后一个空 list-item，enter 减少缩进
                                event.preventDefault()
                                shiftTabF(editor)
                            } else if (containerNodeOfParentNode.type === 'list-item') {
                                // 如果不是最后一个空 list-item，转为普通的 paragraph，原本的 list 被分割
                                event.preventDefault()
                                Transforms.unwrapNodes(editor, { 
                                    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && isList(n.type), 
                                    split: true 
                                })
                                Transforms.unwrapNodes(editor, { 
                                    at: Path.parent(currentListPath),  
                                    match: (n, p) => p.length === currentListPath.length && n.type === 'list-item'
                                })
                            }
                            
                        } else if(parentPath[parentPath.length - 1] === containerNodeOfParentNode.children.length - 1) {
                            // 如果是 parentNode 的最后一个 paragraph，增加一个 list-item, 否则增加一个 paragraph(default)
                            event.preventDefault()
                            Transforms.insertNodes(editor, { 
                                type: 'list-item', children: [{ type: 'paragraph', children: [{ text: ''}] }] 
                            }, {
                                at: [...currentListPath, currentListItemPath[currentListItemPath.length - 1] + 1],
                                select: true
                            })
                        }
                    } else {
                        if(containerNodeOfParentNode.type === 'list-item' && 
                            parentPath[parentPath.length - 1] === containerNodeOfParentNode.children.length - 1
                        ) {
                            // 如果是 list-item 中唯一的一个 paragraph，则原本的 li 分成两个
                            event.preventDefault()
                            Transforms.splitNodes(editor, { always: true })
                            const { focus } = editor.selection
                            Transforms.wrapNodes(editor, { type: 'list-item', children: [] }, { at: Path.parent(focus.path) })
                            Transforms.liftNodes(editor, { at: Path.parent(focus.path) })
                        }
                    }
                    
                }
            } else if (isEnd) {
                // event.preventDefault()
                let newLine = { type: 'paragraph', children: [{ text: "" }] }
                if (parentNode.type === 'paragraph' && !isActiveType("block-quote") ||
                    atomicTypes.some(t => t === parentNode.type) || parentNode.type === "hr") {
                    // enter in common paragraph or atomic
                    event.preventDefault()
                    if (isActiveType("link")) {
                        Transforms.insertNodes(editor, newLine, { at: [parentPath[0] + 1], select: true })
                    } else {
                        Transforms.insertNodes(editor, newLine)
                    }
                } else if (parentNode.type.match(/^h\d$/)) {
                    // heading 处按 enter, 新增一个普通的 paragraph 而不是 heading(默认行为)
                    event.preventDefault()
                    Transforms.insertNodes(editor, newLine, { at: [parentPath[0] + 1], select: true })
                }
            }
        }
    } else if (key === "Backspace") {
        let isStart = Editor.isStart(editor, selection.focus, textContainerPath)
        if(isInLink(editor) && selection.focus.offset === 0) {
            const [linkNode, linkPath] = Editor.node(editor, Path.parent(focus.path))
            if(linkPath[linkPath.length - 1] !== 0) {
                // link 不是 paragraph 中的第一个 child, 若前面的内容是空文本，则光标是在句首
                const beforeText = Editor.string(editor, {
                    anchor: Editor.point(editor, Path.parent(linkPath), { edge: 'start' }),
                    focus: { ...selection.focus, offset: 0 }
                })
                isStart = !beforeText
            } else {
                // link 是 paragraph 中的第一个 child，则光标是在句首
                isStart = true
            }
        }

        function removeEmptyTextMarks () {
            // 退格之后光标所在的元素如果是空文本，则把样式删掉
            const { selection } = editor
            const [focusedNodeAfterDelete, _] = Editor.node(editor, selection)
            if(Node.string(focusedNodeAfterDelete) === '') {
                // console.log(">>> delete style props", focusedNodeAfterDelete);
                Transforms.unsetNodes(editor, inlineStyles, { at: selection.focus.path})
            }
        }

        if(Range.isCollapsed(selection)) {
            if ((textContent === "" || isStart) && parentNode.type.match(/^h\d$/)) {
                event.preventDefault()
                Transforms.setNodes(editor, { type: "paragraph" })
            } else if (isActiveType("list-item") && isStart) {
                event.preventDefault()

                if(parentPath.length <= 3) {
                    function unwrapSingleListItemNode () {
                        // 处理单独出现的 list-item，转化为普通的 paragraph
                        Transforms.unwrapNodes(editor, { at: Path.parent(parentPath)})
                    }
    
                    if (textContent !== "") {
                        if(parentPath.length > 2) {
                            if (parentPath[1] > 0 && parentPath[parentPath.length - 1] === 0) {
                                // 对于不是第一项，第一次点击 backspace，去掉符号，内容合并到上一个 list-item 中
                                const previousPoint = Editor.before(editor, parentPath)
                                const { path } = previousPoint
                                Transforms.moveNodes(editor, {
                                    at: parentPath.slice(0, 2),
                                    match: (n, p) => p.length === parentPath.length,
                                    to: [...path.slice(0, parentPath.length - 1), path[path.length - 2] + 1]
                                })
                                Transforms.removeNodes(editor, { at: parentPath.slice(0, parentPath.length - 1) })
                            } else if (parentPath[1] > 0 && parentPath[parentPath.length - 1] > 0) {
                                // 第二次点击 backspace，将当前位置及之后的内容移出 list，原来的 list 被分割
                                removeOtherItemContentFromList(editor, true)
                            } else if (parentPath[1] === 0 && parentPath[parentPath.length - 1] === 0) {
                                removeOtherItemContentFromList(editor, false)
                            } else if (parentPath[1] === 0 && parentPath[parentPath.length - 1] > 0) {
                                removeFirstItemContentFromList(editor)
                            }
                        } else {
                            unwrapSingleListItemNode()
                        }
                        
                    } else {
                        if(parentPath.length === 3 ) {
                            const [currentList, currentListPath] = Editor.node(editor, parentPath.slice(0, 1))
                            const [currentListItem, currentListItemPath] = Editor.node(editor, parentPath.slice(0, 2))
    
                            if(parentPath[1] === currentList.children.length - 1 && currentListItem.children.length === 1) {
                                // 是当前 list 的最后一个 list-item，且只有一个 paragraph，则将 paragraph 移出 list（相当于另起一行）
                                // 如果只有一个 list-item，同时删除这个 list
                                Transforms.moveNodes(editor, {
                                    at: parentPath, to: [parentPath[0] + 1]
                                })
                                Transforms.removeNodes(editor, { at: parentPath.slice(0, 2)})
                                if(currentList.children.length === 1) {
                                    Transforms.removeNodes(editor, { at: parentPath.slice(0, 1) })
                                }
                            } else if(parentPath[parentPath.length - 1] === 0 && parentPath[1] > 0 && currentListItem.children.length > 1) {
                                // 若是 list-item 中的第一项 paragaph，且当前 list-item 不是 list 中第一个 list-item
                                // 将当前 list-item 内容合并到上一个 list-item 中,同时删除当前 list-item
                                const previousPoint = Editor.before(editor, parentPath)
                                const { path } = previousPoint
                                Transforms.moveNodes(editor, {
                                    at: parentPath.slice(0, 2),
                                    match: (n, p) => p.length === parentPath.length,
                                    to: [parentPath[0], parentPath[1] - 1, path[2] + 1]
                                })
                                Transforms.removeNodes(editor,{ at: parentPath.slice(0, 2) })
                    
                            } else if (parentPath[parentPath.length - 1] === 0 && parentPath[1] === 0) {
                                // 将当前 list-item 内容移出 list
                                Transforms.liftNodes(editor, { 
                                    at: parentPath.slice(0, 2),
                                    match: (n, p) => p.length === parentPath.length
                                })
                            } else {
                                Transforms.mergeNodes(editor)
                            }
                        } else if (parentPath.length === 2) {
                            unwrapSingleListItemNode()
                        }
                    } 
                } else {
                    // 在嵌套 list/paragraph 中
                    const ancestors = Array.from(Node.ancestors(editor, parentPath, { reverse: true }))
                    const [_, currentListItemPath] = ancestors.find(ancestor => {
                        const [ancestorNode, _] = ancestor
                        return ancestorNode.type === 'list-item'
                    })
                    const [nestWrapper, nestWrapperPath] = ancestors.find(ancestor => {
                        const [ancestorNode, _] = ancestor
                        return ancestorNode.type === 'nestWrapper'
                    })
                    const [currentList, currentListPath] = Editor.node(editor, Path.parent(currentListItemPath))
                    if(nestWrapperPath.length > currentListItemPath.length) {
                        // 如果 nestWrapperPath 较长，则 parentNode 是 nestWrapper 中的 paragraph
                        if(nestWrapperPath[nestWrapperPath.length - 1] === 0) {
                            if(parentPath[parentPath.length - 1] === 0) {
                                // paragraph 在 nestWrapper 中的第一项
                                Transforms.unwrapNodes(editor, {
                                    at: Path.parent(nestWrapperPath)
                                })
                                Transforms.wrapNodes(editor, { type: currentList.type, children: [] }, {
                                    at: Path.parent(Path.parent(nestWrapperPath)),
                                    match: (n, p) => {
                                        return n.type === 'list-item' && Path.isChild(p, Path.parent(Path.parent(nestWrapperPath)))
                                    }
                                })
                                Transforms.unwrapNodes(editor, {
                                    at: Path.parent(Path.parent(nestWrapperPath))
                                })
                            } else {
                                // console.log("current paragraph is not first child");
                                Transforms.mergeNodes(editor)
                                const [currentNestWrapper, currentNestWrapperPath] = Editor.node(editor, Path.parent(parentPath))
                                if(currentNestWrapper.children.length === 1) {
                                    // 如果只有一个 child 了，就不需要 nestWrapper 了
                                    Transforms.unwrapNodes(editor, { at: currentNestWrapperPath })
                                }

                                // 如果删掉此 paragraph 后有连续的 list，应该合并这些 list
                                if(Node.has(editor, parentPath)) {
                                    const [nodeAtParentPathAfterDelete, _] = Editor.node(editor, parentPath)

                                    const prev = Editor.previous(editor, { at: parentPath })
                                    if(prev[0].type === nodeAtParentPathAfterDelete.type) {
                                        Transforms.mergeNodes(editor, { at: parentPath })
                                    }
                                }
                                
                            }
                            
                        } else {
                            // 不是第一项 nestWrapper，先 unwrap 此 nestWrapper，第一项 paragraph 并入前一个 node
                            
                            Transforms.unwrapNodes(editor, { at: nestWrapperPath })
                            Transforms.mergeNodes(editor)

                            // 判断上一级的 nestWrapper 下有没有连续同类型的 list，有的话就合并
                            const continuouslistsOptions = { 
                                at: Path.parent(nestWrapperPath),
                                match: (n, p) => {
                                    if(Path.isChild(p, Path.parent(nestWrapperPath))) {
                                        const nextNode = Editor.next(editor, { at: p })
                                        const previousNode = Editor.previous(editor, { at: p })
                                        return isList(n.type) && (
                                            (nextNode && nextNode[0].type === n.type) || 
                                            (previousNode && previousNode[0].type === n.type)
                                        )
                                    }
                                    
                                }
                            }
                            const continuouslists = Array.from(Editor.nodes(editor, continuouslistsOptions ))
                            if(continuouslists.length > 0) {
                                const listType = continuouslists[0][0].type
                                Transforms.unwrapNodes(editor, continuouslistsOptions)
                                Transforms.wrapNodes(editor, { type: listType, children: [] }, {
                                    at: Path.parent(nestWrapperPath),
                                    match: (n, _) => n.type === 'list-item'
                                })

                            }
                            
                        }
                        
                    } else {
                        // 否则是 list-item 中的 paragraph
                        Transforms.unwrapNodes(editor, {
                            match: (n, _) => {
                                return !Editor.isEditor(n) && SlateElement.isElement(n) && isList(n.type)
                            },
                            split: true,
                        })
                        Transforms.unwrapNodes(editor, { at: Path.parent(Path.parent(editor.selection.focus.path)) })

                    }
                }
                
            } else if (textContent === "" && parentNode.type === "paragraph" && !isActiveType("list-item")) {
                const prev = Editor.previous(editor, { at: parentPath });
                if(!prev) {
                    return 
                }
                const [prevNode] = prev
                if ([...atomicTypes, "link"].some(t => t === prevNode.type)) {
                    event.preventDefault()
                    Transforms.removeNodes(editor, { at: parentPath })
                    if(selection.focus.path[0] < editor.children.length - 1) {
                        Transforms.move(editor, { reverse: true })
                    }
                }
            } else if (parentNode.type === "block-quote") {
                // 如果前后都不是 block-quote 之间，则修改 type 为 paragraph，否则使用默认的退格行为
                const prevBlock = Editor.previous(editor, { at: parentPath.slice(0, 1) });
                const nextBlock = Editor.next(editor, { at: parentPath.slice(0, 1)})
                if((textContent === "" || isStart) && 
                    !(prevBlock && nextBlock && prevBlock[0].type === 'block-quote' && nextBlock[0].type === 'block-quote')) {
                    event.preventDefault()
                    Transforms.setNodes(editor, { type: "paragraph" })
                }
            } else if (atomicTypes.some(t => t === parentNode.type)) {
                // focus 时退格删除
                event.preventDefault()
                Transforms.removeNodes(editor)
                if(editor.children.length === 0) {
                    // 如果只有一个 atomic 元素，删除后要增加一个空文本
                    Transforms.insertNodes(editor, { type: 'paragraph', children: [{ text: '' }] })
                }
            }

            setTimeout(() => {
                removeEmptyTextMarks()
            })
        } else {
            event.preventDefault()
            Editor.deleteFragment(editor)

            const { focus } = editor.selection
            const [currentNestWrapper, currentNestWrapperPath] = Editor.node(editor, Path.parent(Path.parent(focus.path)))
            if(currentNestWrapper.type === 'nestWrapper' && currentNestWrapper.children.length === 1) {
                Transforms.unwrapNodes(editor, { at: currentNestWrapperPath })
            }

            removeEmptyTextMarks()
        }
    } else if (key === "ArrowDown") {
        // 当选中内容在最后一项，且是图片时，按“向下”键，新起一行
        const [activeNode, activePath] = Editor.node(editor, selection, { depth: 1 })
        if(selection.focus.path[0] === editor.children.length - 1) {
            if(activeNode.type === "image") {
                Transforms.insertNodes(editor, { type: "paragraph", children: [{ text: "" }] })
            } else if (activeNode.type === "link") {
                Transforms.insertNodes(editor, { type: "paragraph", children: [{ text: "" }] }, {
                    at: [editor.children.length]
                })
                Transforms.move(editor)
            }
        }
        
    } else if (aiEnabled && code === "Space" && textContent === '' && typeof(window) !== 'undefined' && 
        !isBlockType(editor) && !isMobile()
    ) {
        // 句首按 space 点开 ai 辅助菜单
        event.preventDefault()
        const domSelection = window.getSelection()
        const domRange = domSelection.getRangeAt(0)
        const rect = domRange.getBoundingClientRect()


        track("note_ai_trigger", { method: "space-key"})
        
        setAiInputInfo({ show: true, position: { ...omit(rect, ['width', 'height'] )}, action: 'activeBySpace' })
    } else if (key === 'Tab' && isActiveType('list-item')) {
        event.preventDefault()
        const [parentNodeOfParentPath, parentPathOfParentPath] = Editor.node(editor, Path.parent(parentPath))
        /**
         * TODO: 需要考虑在 nestWrapper 中的 paragraph 处增加/减少缩进的结构
         */
        if((parentPath[parentPath.length - 1] !== 0) ||
            (parentNodeOfParentPath.type === 'nestWrapper' && parentPathOfParentPath[parentPathOfParentPath.length - 1] !== 0)
        ) {
            // console.log("prevent tab temporarily");
            return
        }

        if(shiftKey) {
            shiftTabF(editor)
        } else {
            tabF(editor)
        }
    } else if (Range.isCollapsed(selection) && !shiftKey && !metaKey && !ctrlKey) {
        if (key === 'ArrowRight') {
            event.preventDefault()
            Transforms.move(editor, { unit: 'offset' })
        } else if (key === 'ArrowLeft') {
            event.preventDefault()
            Transforms.move(editor, { unit: 'offset', reverse: true })
        }
    } 
}

export function tabF (editor) {
    const { selection } = editor
    const listTypes = ['numbered-list', 'bulleted-list']
    const [parentNode, parentPath] = Editor.parent(
        editor,
        selection.focus?.path
    );
    const [currentNode, currentPath] = Editor.node(
        editor,
        selection.focus?.path
    );
    const ancestors = Array.from(Node.ancestors(editor, parentPath, { reverse: true }))
    const [_, currentListItemPath] = ancestors.find(ancestor => {
        const [ancestorNode, _] = ancestor
        return ancestorNode.type === 'list-item'
    })
    const [currentList, currentListPath] = Editor.node(editor, Path.parent(currentListItemPath))

    // 如果是在不是第一项的 list-item 中按 tab，设置嵌套 list.
    // if(isActiveType('list-item')) {
        function removeEmptyListItem () {
            // if(currentListItemPath[currentListItemPath.length - 1] === currentList.children.length - 1) {
                Transforms.removeNodes(editor, { at: currentListItemPath })
            // 
        }

        if(currentListItemPath[currentListItemPath.length - 1] !== 0) {
            const previousPoint = Editor.before(editor, parentPath)
            const { path } = previousPoint
            const [previousListItem, previousListItemPath] = Editor.previous(editor, { at: currentListItemPath } )

            if(path.length - currentPath.length >= 1 && path.length > 4) {
                // previousPoint 在嵌套的 list 中

                const { children } = previousListItem
                let moveOffset = 0
                const nestWrapperChildren = Array.from(Node.children(editor, [...previousListItemPath, children.length - 1] ))
                const [lastNodeInNestWrapper, lastPathInNestWrapper] = nestWrapperChildren[nestWrapperChildren.length - 1] // list/paragraph
                moveOffset = lastNodeInNestWrapper.children.length
                const targetPath = [...lastPathInNestWrapper, moveOffset]

                if(path.length - currentPath.length <= 3) {
                    // previousPoint 与当前 list-item 的层级之间只差一级
                    if(isList(lastNodeInNestWrapper.type) && lastNodeInNestWrapper.type === currentList.type) {
                        // 如果 nestWrapper 的最后一个 node 是 list，直接把当前的 list-item 移动到该 list 中
                        
                        const [outerParent, outerParentPath] = Editor.parent(editor, parentPath)
                        if(outerParent.type === 'list-item') {
                            Transforms.moveNodes(editor, {
                                at: currentListItemPath,
                                to: targetPath
                            })
                        } else {
                            // outerParent 是 nestWrapper
                            const [nextNode, nextPath] = Editor.node(editor, Path.next(parentPath))
                            Transforms.wrapNodes(editor, { type: 'list-item', children: [] }, {
                                at: parentPath
                            })

                            if(isList(nextNode.type)) {
                                // 如果 paragraph 同级别下一项是 list，将 paragraph 和 list 移到上一个 list 末尾
                                Transforms.unwrapNodes(editor, { at: nextPath })
                                Transforms.moveNodes(editor, {
                                    at: Path.parent(nextPath),
                                    match: (n, p) => p.length === nextPath.length && n.type === 'list-item',
                                    to: targetPath
                                })

                            } else {
                                // 当前的 nestWrapper 移动到上一个 list-item 的最后一个 nestWrapper 中
                                const newTargetPath = [...Path.parent(lastPathInNestWrapper), moveOffset]
                                Transforms.moveNodes(editor, {
                                    at: outerParentPath,
                                    to: newTargetPath
                                })
                                Transforms.unwrapNodes(editor, { at: newTargetPath })
                                Transforms.moveNodes(editor, {
                                    at: newTargetPath,
                                    to: targetPath
                                })
                                
                            }

                            removeEmptyListItem()                                
                        }
                        
                    } else {
                        // 如果不是 list，则在 previousListItem 的最后添加一个 list
                        Transforms.wrapNodes(editor, { type: 'list-item', children: [] }, {
                            at: parentPath
                        })

                        if(Node.has(editor, Path.next(parentPath))) {
                            const [nextNode, nextPath] = Editor.node(editor, Path.next(parentPath))
                            if(isList(nextNode.type)) {
                                Transforms.moveNodes(editor, {
                                    at: parentPath,
                                    to: [...nextPath, 0]
                                })
                            } else {
                                Transforms.wrapNodes(editor, { type: currentList.type, children: [] }, { at: parentPath })
                            }
                        } else {
                            // 当前的 list-item 下的内容移动到上一个 list-item 中
                            Transforms.wrapNodes(editor, { type: currentList.type, children: [] }, { at: parentPath })
                            
                        }

                        const prevListItemhChildren = Array.from(Node.children(editor, previousListItemPath ))
                        const [lastNWInPrevListItem, lastNWPathInPrevListItem] = prevListItemhChildren[prevListItemhChildren.length - 1]
                        const targetPath = [...lastNWPathInPrevListItem, lastNWInPrevListItem.children.length]

                        if(Node.has(editor, Path.next(parentPath))) {
                            Transforms.moveNodes(editor, {
                                at: Path.parent(parentPath),
                                to: targetPath
                            })
                            Transforms.unwrapNodes(editor, { at: targetPath })
                        } else {
                            Transforms.moveNodes(editor, {
                                at: parentPath,
                                to: targetPath
                            })
                        }

                        removeEmptyListItem()
                    }
                } else {
                    const [lastNodeInPrevListItem, lastNodePathInPrevListItem] = Editor.node(editor, 
                        [...previousListItemPath, previousListItem.children.length - 1]
                    ) // nestWrapper
                    
                    if(isList(lastNodeInNestWrapper.type) && currentList.type === lastNodeInNestWrapper.type) {
                        // 前一个 nestWrapper 的最后一项是相同类型的 list, 则当前 list-item 下的内容移动至该 list 末尾

                        if(Node.has(editor, Path.next(parentPath))) {
                            // 有嵌套的内容
                            Transforms.wrapNodes(editor, { type: 'list-item', children: [] }, {
                                at: parentPath
                            })
                            const [nextNode, nextPath] = Editor.node(editor, Path.next(parentPath))
                            if(isList(nextNode.type)) {
                                Transforms.unwrapNodes(editor, { at: nextPath })
                                Transforms.moveNodes(editor, {
                                    at: Path.parent(nextPath),
                                    match: (n, p) => p.length === nextPath.length && n.type === 'list-item',
                                    to: targetPath
                                })
                            } else {
                                const newTargetPath = [...lastNodePathInPrevListItem, lastNodeInPrevListItem.children.length]
                                Transforms.moveNodes(editor, {
                                    at: Path.parent(parentPath),
                                    to: newTargetPath
                                })
                                Transforms.unwrapNodes(editor, { at: newTargetPath })
                                Transforms.moveNodes(editor, {
                                    at: newTargetPath, 
                                    to: targetPath
                                })
                            }
                            removeEmptyListItem()
                        } else {
                            Transforms.moveNodes(editor, {
                                at: currentListItemPath,
                                to: [...lastPathInNestWrapper, lastNodeInNestWrapper.children.length]
                            })
                        }
                        
                    } else {
                        Transforms.wrapNodes(editor, { type: 'list-item', children: [] }, {
                            at: parentPath
                        })
                        const newTargetPath = [...lastNodePathInPrevListItem, lastNodeInPrevListItem.children.length]
                        Transforms.moveNodes(editor, {
                            at: Path.parent(parentPath),
                            to: newTargetPath
                        })
                        Transforms.unwrapNodes(editor, { at: newTargetPath })
                        Transforms.wrapNodes(editor, { type: currentList.type, children: [] }, { at: newTargetPath })
                        
                    }
                }
            } else {
                Transforms.wrapNodes(editor, { type: 'list-item', children: []}, { 
                    at: Path.parent(parentPath),
                    match: (n, p) => {
                        return p.length === parentPath.length && Path.compare(p, parentPath) >= 0 && 
                            !isList(n.type)
                    }
                })

                function wrapParagraphWithList () {
                    // 新生成的 list 和上一个 paragraph 合并成一个整体(nestWrapper)
                    const { focus } = editor.selection
                    const ancestors = Array.from(Node.ancestors(editor, focus.path, { reverse: true }))
                    const [_, currentListPath] = ancestors.find(ancestor => {
                        const [ancestorNode, _] = ancestor
                        return isList(ancestorNode.type)
                    })
                    const previousParagraphPoint = Editor.before(editor, currentListPath)
                    const outerListItemPath = Path.parent(Path.parent(previousParagraphPoint.path))
                    Transforms.wrapNodes(editor, { type: 'nestWrapper', children: [] }, {
                        at: outerListItemPath,
                        match: (_, p) => {
                            return Path.compare(p, previousParagraphPoint.path) >= 0
                        }
                    })
                }

                function wrapToListAndMove () {
                    Transforms.wrapNodes(editor, { type: currentList.type, children: []}, { 
                        at: parentPath 
                    })
                    Transforms.moveNodes(editor, {
                        at: parentPath,
                        to: [...previousListItemPath, previousListItem.children.length]
                    })

                    Transforms.removeNodes(editor, { at: currentListItemPath })
                    wrapParagraphWithList()
                }

                if(Node.has(editor, Path.next(parentPath))) {
                    const [nextNode, nextPath] = Editor.node(editor, Path.next(parentPath))
                    if(isList(nextNode.type)) {
                        // 如果当前 paragraph 的同级别下一项是 list，则当前 paragraph wrap 成 list-item 插入下一项 list 的首位

                        Transforms.moveNodes(editor, {
                            at: parentPath,
                            to: [...nextPath, 0]
                        })
                        Transforms.moveNodes(editor, {
                            at: parentPath,
                            to: [...previousListItemPath, previousListItem.children.length]
                        })
                        wrapParagraphWithList()
                        Transforms.removeNodes(editor, { at: currentListItemPath })

                        if(path.length === currentPath.length) {
                            // previousPoint 是相差一级的 paragraph, 将新生成的 list 移到 previousPoint 之后（与 previousPoint 同级）
                            const ancestors = Array.from(Node.ancestors(editor, editor.selection.focus.path, { reverse: true }))
                            const [_, newListPath] = ancestors.find(ancestor => {
                                const [ancestorNode, _] = ancestor
                                return isList(ancestorNode.type)
                            })

                            const previousNodePath = Path.parent(path)
                            Transforms.moveNodes(editor, {
                                at: newListPath,
                                to: [...Path.parent(previousNodePath), previousNodePath[previousNodePath.length - 1] + 1]
                            })
                        } 
                        // else {
                        //     console.log("second wrap");
                        //     wrapParagraphWithList()
                        // }
                    } else {
                        console.log("next node is paragraph");
                        // 当前 paragraph 同级别的下一项是 paragraph，则当前 paragraph wrap 成 list 移到 previousPoint 之后（与 previousPoint 同级）
                    }
                } else {
                    wrapToListAndMove()
                }

                
            }
            // console.log("after change: ", editor.children);
        }
        // } else {
        //     // 如果上一个 node 是 paragraph/list，可以比上一个 node 再缩进一级，如果上一个 node 是 quote，则缩进层级与 quote 相同
        //     const prev = Editor.previous(editor, { at: parentPath });
        //     if(!(prev && prev[0].type.match(/^h\d$/))) {
        //         function getPrevNodeIndent () {
        //             if(prev) {
        //                 if(isList(prev[0].type)) {
        //                     const listItems = prev[0].children
        //                     const lastListItem = listItems[listItems.length - 1]
        //                     const p = lastListItem.children[0]
        
        //                     return p.indent ? p.indent : 0
        //                 } else if (prev[0].indent) {
        //                     return prev[0].indent
        //                 } else {
        //                     return 0
        //                 }
                        
        //             } else {
        //                 return 0
        //             }
                    
        //         }
        //         const prevNodeIndent = getPrevNodeIndent()
        //         const originalIndent = parentNode.indent
        //         if(event.shiftKey) {
        //             // 如果是 shift + tab，则减少缩进
        //             if(originalIndent && originalIndent > 0) {
        //                 Transforms.setNodes(editor, { indent: originalIndent - 1 })
        //             }
        //         } else {
        //             const maximumIndent = prev && (prev[0].type === 'block-quote' || prev[0].type.match(/^h\d$/)) ? 
        //                 prevNodeIndent : prevNodeIndent + 1
        //             Transforms.setNodes(editor, { 
        //                 indent: (!!originalIndent || originalIndent === 0) && originalIndent + 1 <= maximumIndent ? 
        //                     originalIndent + 1 : originalIndent
        //             })
        //         }
        //         console.log("after set indent: ", editor.children);
        //     }
        // }
}

export function shiftTabF (editor) {
    const { selection } = editor
    const listTypes = ['numbered-list', 'bulleted-list']
    const [parentNode, parentPath] = Editor.parent(
        editor,
        selection.focus?.path
    );
    const ancestors = Array.from(Node.ancestors(editor, parentPath, { reverse: true }))
    const [_, currentListItemPath] = ancestors.find(ancestor => {
        const [ancestorNode, _] = ancestor
        return ancestorNode.type === 'list-item'
    })
    const [currentList, currentListPath] = Editor.node(editor, Path.parent(currentListItemPath))

    const listAncestors = ancestors.filter(ancestor => {
        const [ancestorNode, _] = ancestor
        return isList(ancestorNode.type)
    })

    const isNested = listAncestors.length > 1

    if(isNested) {
        // 当前 paragraph 和之后的内容 wrap 成一个 list-item 添加到上一级 list 对应位置
        const originalFocusOffset = editor.selection.focus.offset
        // const [currentList, currentListPath] = listAncestors[0]
        const [__, outerListPath] = listAncestors[1]
        const afterNodes = Array.from(Editor.nodes(editor, {
            at: Path.parent(currentListPath),
            match: (_, p) => Path.compare(p, parentPath) === 1,
            mode: 'highest'
        })).reduce((acc, nodeEntry, index) => {
            const [node, path] = nodeEntry
            if(node.type === 'list-item') {
                if(acc.length > 0) {
                    const prev = acc[acc.length - 1]
                    if(isList(prev.type)) {
                        return acc.toSpliced(acc.length - 1, 1, { 
                            type: currentList.type, children: [...prev.children, node] 
                        })
                    } else {
                        return [...acc, { type: currentList.type, children: [node] }]
                    }
                } else {
                    return [...acc, { type: currentList.type, children: [node] }]
                }
            } else {
                return [...acc, node]
            }
        }, [])

        const newListItem = {
            type: 'list-item',
            children: afterNodes.length > 0 ? [{
                type: 'nestWrapper',
                children: [parentNode, ...afterNodes]
            }] : [parentNode]
        }
        // console.log("newListItem: ", newListItem, 'currentListPath: ', currentListPath, outerListPath, editor.children, currentListItemPath);
        Transforms.insertNodes(editor, newListItem, { at: [...outerListPath, currentListPath[outerListPath.length] + 1] } )
        Transforms.removeNodes(editor, {
            at: currentListPath,
            match: (_, p) => {
                return p.length === currentListItemPath.length && Path.compare(p, currentListItemPath) >= 0
            },
        })
        if(currentListItemPath[currentListItemPath.length - 1] === 0) {
            // 如果是第一个 list-item, removeNodes 之后会剩一个空 list，要删掉
            Transforms.removeNodes(editor, { at: currentListPath })
            const remainingChildrenOfNestWrapper = Array.from(Node.children(editor, Path.parent(currentListPath)))
            if(remainingChildrenOfNestWrapper.length === 1) {
                // 如果只剩下一个 child，则去掉 nestWrapper
                Transforms.unwrapNodes(editor, { at: Path.parent(currentListPath)})
            }
        }
        Transforms.move(editor, { distance: originalFocusOffset + 1 }) // removeNodes 之后光标会上移，恢复光标位置
    }
}