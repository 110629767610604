

import React, { useEffect, useState } from 'react'

import SearchItemsCommon from './SearchItemsCommon'

export default function SearchFolders({ keyword, onSelectChange, selected, facade }) {

    // 1. support pagination,
    // 2. a list of things, with checkboxes
    // 3. when checked / unchecked, call the onSelectChange({ name: true/false })

    //  { entityName, recordId, knowledgeCategoryKey }

    const [folders, setFolders] = useState();

    const entityName = "OpenAI-文件夹";

    async function loadData() {
        const [result, error] = await facade.list({
            entityName,
            condition: [[
                // { field: "加入知识库", op: "eq", value: true }
            ]],
            sort: [
                { field: "显示权重", order: "DESC" }
            ],
            pageSize: 1000,
            fieldPaths: ["名称"],
        })
        if (!error) {
            setFolders(result);
        }
    }

    useEffect(() => {
        loadData()
    }, [keyword]);


    function renderItem(doc) {
        return (
            <div className="item-line">
                <i className='bx bx-folder'></i>
                <div className="item-title">{doc.名称}</div>
            </div>
        )
    }



    return (
        <SearchItemsCommon {...{
            items: folders,

            // actionButton: uploadDocumentButton(),

            renderItem,
            isItemSelected: doc => selected.some(r => r.recordId == doc.id),
            selectItem: doc => {
                onSelectChange([{ entityName, recordId: doc.id }, ...selected])
            },
            unselectItem: doc => {
                onSelectChange(selected.filter(r => r.recordId !== doc.id))
            }



        }} />
    )
}
