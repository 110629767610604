// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Dict$BwaxMobile = require("../dict.bs.js");
var Plate$BwaxMobile = require("../plate.bs.js");

function unexpect(str) {
  throw [
        Plate$BwaxMobile.Unexpected,
        str
      ];
}

var Type_exn = Caml_exceptions.create("Lang_typing_base-BwaxMobile.Type_exn");

function fail_typing(i, msg) {
  throw [
        Type_exn,
        msg,
        i,
        0
      ];
}

function term(name, sons) {
  return /* Term */Block.__(0, [
            name,
            sons
          ]);
}

function full_tname(maybe_module, name) {
  if (maybe_module !== undefined) {
    return maybe_module + ("." + name);
  } else {
    return name;
  }
}

function ti_of_name(dts, maybe_module, name) {
  if (maybe_module !== undefined) {
    var prefixed = maybe_module + ("." + name);
    var match = Plate$BwaxMobile.List.assoc(prefixed, dts);
    if (match !== undefined) {
      return Caml_option.some(Caml_option.valFromOption(match));
    } else {
      return Plate$BwaxMobile.List.assoc(name, dts);
    }
  } else {
    return Plate$BwaxMobile.List.assoc(name, dts);
  }
}

function term_name(dts, maybe_module, name) {
  var prefixed = full_tname(maybe_module, name);
  var match = Plate$BwaxMobile.List.assoc(prefixed, dts);
  if (match !== undefined) {
    return prefixed;
  } else {
    return name;
  }
}

function term_of_name(dts, maybe_module, name, sons) {
  var tname = term_name(dts, maybe_module, name);
  return /* Term */Block.__(0, [
            tname,
            sons
          ]);
}

function get_vars(t) {
  var append = Plate$BwaxMobile.List.append;
  var fold_vars = function (start, _t) {
    while(true) {
      var t = _t;
      var iter_assoc = function (param) {
        return Curry._1(append, fold_vars(start, param[1]));
      };
      var iter_assoc_tri = function (param) {
        return Curry._1(append, fold_vars(start, param[1]));
      };
      var iter = function (t) {
        return Curry._1(append, fold_vars(start, t));
      };
      if (typeof t === "number") {
        return /* [] */0;
      } else {
        switch (t.tag | 0) {
          case /* Term */0 :
              return Plate$BwaxMobile.List.foldr(iter, t[1], start);
          case /* Term_record */1 :
              return Plate$BwaxMobile.List.foldr(iter_assoc_tri, t[0], start);
          case /* Var */2 :
              var v = t[0];
              var match = v[/* var_type */0];
              var exit = 0;
              if (typeof match !== "number") {
                switch (match.tag | 0) {
                  case /* Recordvar */0 :
                      var match$1 = v[/* t */2];
                      if (typeof match$1 === "number" && match$1 !== 0) {
                        return /* :: */[
                                v,
                                Plate$BwaxMobile.List.foldr(iter_assoc, match[0], start)
                              ];
                      }
                      break;
                  case /* Record_readonly */1 :
                      var match$2 = v[/* t */2];
                      if (typeof match$2 === "number" && match$2 !== 0) {
                        return /* :: */[
                                v,
                                Plate$BwaxMobile.List.foldr(iter_assoc, match[1], start)
                              ];
                      }
                      break;
                  case /* Record_readonly_of */2 :
                      var match$3 = v[/* t */2];
                      if (typeof match$3 === "number" && match$3 !== 0) {
                        var fst = Plate$BwaxMobile.List.foldr(iter_assoc_tri, match[0], start);
                        return /* :: */[
                                v,
                                Plate$BwaxMobile.List.foldr(iter_assoc, match[1], fst)
                              ];
                      }
                      break;
                  case /* Record_limited */3 :
                      var match$4 = v[/* t */2];
                      if (typeof match$4 === "number" && match$4 !== 0) {
                        return /* :: */[
                                v,
                                Plate$BwaxMobile.List.foldr(iter, match[0], start)
                              ];
                      }
                      break;
                  case /* Record_writeonly_of */5 :
                      var match$5 = v[/* t */2];
                      if (typeof match$5 === "number" && match$5 !== 0) {
                        return /* :: */[
                                v,
                                Plate$BwaxMobile.List.foldr(iter_assoc_tri, match[0], start)
                              ];
                      }
                      break;
                  case /* Record_writeonly */4 :
                  case /* Record_writeonly_optional */7 :
                      exit = 2;
                      break;
                  default:
                    
                }
              }
              if (exit === 2) {
                var match$6 = v[/* t */2];
                if (typeof match$6 === "number" && match$6 !== 0) {
                  return /* :: */[
                          v,
                          Plate$BwaxMobile.List.foldr(iter, match[1], start)
                        ];
                }
                
              }
              var t$1 = v[/* t */2];
              if (typeof t$1 === "number") {
                if (t$1 !== 0) {
                  return /* :: */[
                          v,
                          /* [] */0
                        ];
                } else {
                  _t = t$1;
                  continue ;
                }
              } else {
                _t = t$1;
                continue ;
              }
              break;
          
        }
      }
    };
  };
  return fold_vars(/* [] */0, t);
}

function vars_of_tyenv(env) {
  return Dict$BwaxMobile.$$String.foldl((function (acc, param, param$1) {
                var match = param$1[0];
                return Pervasives.$at(acc, Plate$BwaxMobile.List.substract(get_vars(match[1]), match[0]));
              }), /* [] */0, env);
}

function generalize(env, t) {
  var gvars = Plate$BwaxMobile.List.unique(Plate$BwaxMobile.List.substract(get_vars(t), vars_of_tyenv(env)));
  return /* Forall */[
          gvars,
          t
        ];
}

function not_generalize(t) {
  return /* Forall */[
          /* [] */0,
          t
        ];
}

function shorten(t) {
  while(true) {
    if (typeof t === "number" || t.tag !== /* Var */2) {
      return t;
    } else {
      var tv2 = t[0];
      var tv = tv2[/* t */2];
      if (typeof tv === "number") {
        if (tv === /* Unknown */1) {
          return t;
        } else {
          return tv;
        }
      } else if (tv.tag === /* Var */2) {
        var tv1 = tv[0];
        var match = tv1[/* t */2];
        if (typeof match === "number") {
          if (match !== 0) {
            return tv;
          } else {
            tv2[/* t */2] = tv1[/* t */2];
            continue ;
          }
        } else {
          tv2[/* t */2] = tv1[/* t */2];
          continue ;
        }
      } else {
        return tv;
      }
    }
  };
}

function completely_shrink(_t) {
  while(true) {
    var t = _t;
    var partial_arg = Plate$BwaxMobile.List.assoc_map;
    var shrink_al = (function(partial_arg){
    return function shrink_al(param) {
      return partial_arg(completely_shrink, param);
    }
    }(partial_arg));
    var partial_arg$1 = Plate$BwaxMobile.List.map;
    var shrink_l = (function(partial_arg$1){
    return function shrink_l(param) {
      return partial_arg$1(completely_shrink, param);
    }
    }(partial_arg$1));
    if (typeof t === "number") {
      if (t === /* No_term */0) {
        return /* No_term */0;
      } else {
        return /* Unknown */1;
      }
    } else {
      switch (t.tag | 0) {
        case /* Term */0 :
            return /* Term */Block.__(0, [
                      t[0],
                      Plate$BwaxMobile.List.map(completely_shrink, t[1])
                    ]);
        case /* Term_record */1 :
            return /* Term_record */Block.__(1, [Plate$BwaxMobile.List.map((function (param) {
                              return /* tuple */[
                                      param[0],
                                      completely_shrink(param[1]),
                                      param[2]
                                    ];
                            }), t[0])]);
        case /* Var */2 :
            var tv2 = t[0];
            var match = tv2[/* var_type */0];
            if (typeof match !== "number") {
              switch (match.tag | 0) {
                case /* Recordvar */0 :
                    var match$1 = tv2[/* t */2];
                    if (typeof match$1 === "number" && match$1 !== 0) {
                      var var_type = /* Recordvar */Block.__(0, [Curry._1(shrink_al, match[0])]);
                      return /* Var */Block.__(2, [/* record */[
                                  /* var_type */var_type,
                                  /* n */tv2[/* n */1],
                                  /* t : Unknown */1
                                ]]);
                    }
                    break;
                case /* Record_readonly */1 :
                    var match$2 = tv2[/* t */2];
                    if (typeof match$2 === "number" && match$2 !== 0) {
                      var var_type_000 = match[0];
                      var var_type_001 = Curry._1(shrink_al, match[1]);
                      var var_type$1 = /* Record_readonly */Block.__(1, [
                          var_type_000,
                          var_type_001
                        ]);
                      return /* Var */Block.__(2, [/* record */[
                                  /* var_type */var_type$1,
                                  /* n */tv2[/* n */1],
                                  /* t : Unknown */1
                                ]]);
                    }
                    break;
                case /* Record_readonly_of */2 :
                    var match$3 = tv2[/* t */2];
                    if (typeof match$3 === "number" && match$3 !== 0) {
                      var var_type_000$1 = Plate$BwaxMobile.List.map((function (param) {
                              return /* tuple */[
                                      param[0],
                                      completely_shrink(param[1]),
                                      param[2]
                                    ];
                            }), match[0]);
                      var var_type_001$1 = Curry._1(shrink_al, match[1]);
                      var var_type$2 = /* Record_readonly_of */Block.__(2, [
                          var_type_000$1,
                          var_type_001$1
                        ]);
                      return /* Var */Block.__(2, [/* record */[
                                  /* var_type */var_type$2,
                                  /* n */tv2[/* n */1],
                                  /* t : Unknown */1
                                ]]);
                    }
                    break;
                case /* Record_limited */3 :
                    var match$4 = tv2[/* t */2];
                    if (typeof match$4 === "number" && match$4 !== 0) {
                      var var_type$3 = /* Record_limited */Block.__(3, [Curry._1(shrink_l, match[0])]);
                      return /* Var */Block.__(2, [/* record */[
                                  /* var_type */var_type$3,
                                  /* n */tv2[/* n */1],
                                  /* t : Unknown */1
                                ]]);
                    }
                    break;
                case /* Record_writeonly */4 :
                    var match$5 = tv2[/* t */2];
                    if (typeof match$5 === "number" && match$5 !== 0) {
                      var var_type_000$2 = match[0];
                      var var_type_001$2 = Curry._1(shrink_l, match[1]);
                      var var_type$4 = /* Record_writeonly */Block.__(4, [
                          var_type_000$2,
                          var_type_001$2
                        ]);
                      return /* Var */Block.__(2, [/* record */[
                                  /* var_type */var_type$4,
                                  /* n */tv2[/* n */1],
                                  /* t : Unknown */1
                                ]]);
                    }
                    break;
                case /* Record_writeonly_of */5 :
                    var match$6 = tv2[/* t */2];
                    if (typeof match$6 === "number" && match$6 !== 0) {
                      var var_type$5 = /* Record_writeonly_of */Block.__(5, [Plate$BwaxMobile.List.map((function (param) {
                                  return /* tuple */[
                                          param[0],
                                          completely_shrink(param[1]),
                                          param[2]
                                        ];
                                }), match[0])]);
                      return /* Var */Block.__(2, [/* record */[
                                  /* var_type */var_type$5,
                                  /* n */tv2[/* n */1],
                                  /* t : Unknown */1
                                ]]);
                    }
                    break;
                case /* Record_writeonly_optional */7 :
                    var match$7 = tv2[/* t */2];
                    if (typeof match$7 === "number" && match$7 !== 0) {
                      var var_type_000$3 = match[0];
                      var var_type_001$3 = Curry._1(shrink_l, match[1]);
                      var var_type$6 = /* Record_writeonly_optional */Block.__(7, [
                          var_type_000$3,
                          var_type_001$3
                        ]);
                      return /* Var */Block.__(2, [/* record */[
                                  /* var_type */var_type$6,
                                  /* n */tv2[/* n */1],
                                  /* t : Unknown */1
                                ]]);
                    }
                    break;
                case /* Opaqued */8 :
                    var match$8 = tv2[/* t */2];
                    if (typeof match$8 === "number" && match$8 !== 0) {
                      var var_type$7 = /* Opaqued */Block.__(8, [Curry._1(shrink_l, match[0])]);
                      return /* Var */Block.__(2, [/* record */[
                                  /* var_type */var_type$7,
                                  /* n */tv2[/* n */1],
                                  /* t : Unknown */1
                                ]]);
                    }
                    break;
                default:
                  
              }
            }
            var tv = tv2[/* t */2];
            if (typeof tv === "number") {
              if (tv === /* Unknown */1) {
                return t;
              } else {
                _t = tv;
                continue ;
              }
            } else if (tv.tag === /* Var */2) {
              var tv1 = tv[0];
              var match$9 = tv1[/* t */2];
              if (typeof match$9 === "number") {
                if (match$9 !== 0) {
                  return tv;
                } else {
                  tv2[/* t */2] = tv1[/* t */2];
                  continue ;
                }
              } else {
                tv2[/* t */2] = tv1[/* t */2];
                continue ;
              }
            } else {
              _t = tv;
              continue ;
            }
            break;
        
      }
    }
  };
}

function completely_shrink_var($$var) {
  var t = /* Var */Block.__(2, [$$var]);
  var match = completely_shrink(t);
  if (typeof match === "number") {
    throw [
          Plate$BwaxMobile.Unexpected,
          "Can't be here"
        ];
  } else if (match.tag === /* Var */2) {
    return match[0];
  } else {
    throw [
          Plate$BwaxMobile.Unexpected,
          "Can't be here"
        ];
  }
}

var int_name = "Int";

var float_name = "Float";

var char_name = "Char";

var string_name = "String";

var bool_name = "Bool";

var list_name = "List";

var unit_name = "Unit";

var arrow_name = "Arrow";

var tuple_name = "Tuple";

var definition_name = "Definition";

function native_dt(name) {
  return /* tuple */[
          name,
          /* Just */Block.__(0, [
              generalize(Dict$BwaxMobile.$$String.empty(/* () */0), /* Term */Block.__(0, [
                      name,
                      /* [] */0
                    ])),
              /* Native */0
            ])
        ];
}

var builtin_dts = Plate$BwaxMobile.List.rev(/* :: */[
      native_dt(int_name),
      /* :: */[
        native_dt(float_name),
        /* :: */[
          native_dt(char_name),
          /* :: */[
            native_dt(string_name),
            /* :: */[
              native_dt(bool_name),
              /* :: */[
                /* tuple */[
                  list_name,
                  /* Just */Block.__(0, [
                      generalize(Dict$BwaxMobile.$$String.empty(/* () */0), /* Term */Block.__(0, [
                              list_name,
                              /* :: */[
                                /* Var */Block.__(2, [/* record */[
                                      /* var_type : Any */0,
                                      /* n */0,
                                      /* t : Unknown */1
                                    ]]),
                                /* [] */0
                              ]
                            ])),
                      /* Native */0
                    ])
                ],
                /* :: */[
                  /* tuple */[
                    unit_name,
                    /* Special */0
                  ],
                  /* :: */[
                    /* tuple */[
                      arrow_name,
                      /* Special */0
                    ],
                    /* :: */[
                      /* tuple */[
                        tuple_name,
                        /* Special */0
                      ],
                      /* :: */[
                        /* tuple */[
                          definition_name,
                          /* Special */0
                        ],
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

function var_of(var_type, n) {
  return /* Var */Block.__(2, [/* record */[
              /* var_type */var_type,
              /* n */n,
              /* t : Unknown */1
            ]]);
}

function any(n) {
  return var_of(/* Any */0, n);
}

function number(n) {
  return var_of(/* Number */1, n);
}

function appendable(n) {
  return var_of(/* Appendable */2, n);
}

function comparable(n) {
  return var_of(/* Comparable */3, n);
}

function compappend(n) {
  return var_of(/* Compappend */4, n);
}

function recordvar(n, binds) {
  return var_of(/* Recordvar */Block.__(0, [binds]), n);
}

function record_writeonly_of(n, binds) {
  return var_of(/* Record_writeonly_of */Block.__(5, [binds]), n);
}

function record_writeonly(n, name, ts) {
  return var_of(/* Record_writeonly */Block.__(4, [
                name,
                ts
              ]), n);
}

function record_writeonly_optional(n, name, ts) {
  return var_of(/* Record_writeonly_optional */Block.__(7, [
                name,
                ts
              ]), n);
}

var $$int = /* Term */Block.__(0, [
    int_name,
    /* [] */0
  ]);

var $$float = /* Term */Block.__(0, [
    float_name,
    /* [] */0
  ]);

var $$char = /* Term */Block.__(0, [
    char_name,
    /* [] */0
  ]);

var string = /* Term */Block.__(0, [
    string_name,
    /* [] */0
  ]);

var bool = /* Term */Block.__(0, [
    bool_name,
    /* [] */0
  ]);

var unit = /* Term */Block.__(0, [
    unit_name,
    /* [] */0
  ]);

function tuple(ts) {
  return /* Term */Block.__(0, [
            tuple_name,
            ts
          ]);
}

function list(t) {
  return /* Term */Block.__(0, [
            list_name,
            /* :: */[
              t,
              /* [] */0
            ]
          ]);
}

function arrow(a, r) {
  return /* Term */Block.__(0, [
            arrow_name,
            /* :: */[
              a,
              /* :: */[
                r,
                /* [] */0
              ]
            ]
          ]);
}

function arrow_chain(args, r) {
  return Plate$BwaxMobile.List.foldr(arrow, args, r);
}

function record(binds) {
  return /* Term_record */Block.__(1, [binds]);
}

function definition(t) {
  return /* Term */Block.__(0, [
            definition_name,
            /* :: */[
              t,
              /* [] */0
            ]
          ]);
}

function is_tuple(t) {
  if (typeof t === "number" || t.tag) {
    return false;
  } else {
    return t[0] === tuple_name;
  }
}

function is_arrow(t) {
  if (typeof t === "number" || t.tag) {
    return false;
  } else {
    return t[0] === arrow_name;
  }
}

function is_record(t) {
  if (typeof t === "number") {
    return false;
  } else {
    switch (t.tag | 0) {
      case /* Term_record */1 :
          return true;
      case /* Var */2 :
          var tmp = t[0][/* var_type */0];
          if (typeof tmp === "number") {
            switch (tmp) {
              case /* Any */0 :
              case /* Number */1 :
              case /* Appendable */2 :
              case /* Comparable */3 :
              case /* Compappend */4 :
                  return false;
              
            }
          } else {
            switch (tmp.tag | 0) {
              case /* Record_limited */3 :
              case /* Opaqued */8 :
                  return false;
              default:
                return true;
            }
          }
      default:
        return false;
    }
  }
}

function has_child(t) {
  if (typeof t === "number" || t.tag) {
    return false;
  } else {
    return Plate$BwaxMobile.List.length(t[1]) > 0;
  }
}

function make_counter(param) {
  var c = /* record */[/* contents */-1];
  return (function (param) {
      var n = c[0] + 1 | 0;
      c[0] = n;
      return c[0];
    });
}

function simplify_vars(scheme) {
  var v_next_int = make_counter(/* () */0);
  var number_next_int = make_counter(/* () */0);
  var appendable_next_int = make_counter(/* () */0);
  var comparable_next_int = make_counter(/* () */0);
  var compappend_next_int = make_counter(/* () */0);
  var recordvar_next_int = make_counter(/* () */0);
  var record_readonly_next_int = make_counter(/* () */0);
  var record_readonly_of_next_int = make_counter(/* () */0);
  var record_limited_next_int = make_counter(/* () */0);
  var record_writeonly_next_int = make_counter(/* () */0);
  var record_writeonly_optional_next_int = make_counter(/* () */0);
  var record_writeonly_of_next_int = make_counter(/* () */0);
  var any_record_readonly_next_int = make_counter(/* () */0);
  var opaqued_next_int = make_counter(/* () */0);
  var next_int_for_var = function (v) {
    if (typeof v === "number") {
      switch (v) {
        case /* Any */0 :
            return Curry._1(v_next_int, /* () */0);
        case /* Number */1 :
            return Curry._1(number_next_int, /* () */0);
        case /* Appendable */2 :
            return Curry._1(appendable_next_int, /* () */0);
        case /* Comparable */3 :
            return Curry._1(comparable_next_int, /* () */0);
        case /* Compappend */4 :
            return Curry._1(compappend_next_int, /* () */0);
        
      }
    } else {
      switch (v.tag | 0) {
        case /* Recordvar */0 :
            return Curry._1(recordvar_next_int, /* () */0);
        case /* Record_readonly */1 :
            return Curry._1(record_readonly_next_int, /* () */0);
        case /* Record_readonly_of */2 :
            return Curry._1(record_readonly_of_next_int, /* () */0);
        case /* Record_limited */3 :
            return Curry._1(record_limited_next_int, /* () */0);
        case /* Record_writeonly */4 :
            return Curry._1(record_writeonly_next_int, /* () */0);
        case /* Record_writeonly_of */5 :
            return Curry._1(record_writeonly_of_next_int, /* () */0);
        case /* Any_record_readonly */6 :
            return Curry._1(any_record_readonly_next_int, /* () */0);
        case /* Record_writeonly_optional */7 :
            return Curry._1(record_writeonly_optional_next_int, /* () */0);
        case /* Opaqued */8 :
            return Curry._1(opaqued_next_int, /* () */0);
        
      }
    }
  };
  var dict_ref = /* record */[/* contents : [] */0];
  var simplify = function ($$var) {
    var v = $$var[/* var_type */0];
    var i = $$var[/* n */1];
    var dict = dict_ref[0];
    var match = Plate$BwaxMobile.List.assoc(/* tuple */[
          v,
          i
        ], dict);
    if (match !== undefined) {
      return match;
    } else {
      var new_var = /* record */[
        /* var_type */v,
        /* n */next_int_for_var(v),
        /* t */$$var[/* t */2]
      ];
      dict_ref[0] = /* :: */[
        /* tuple */[
          /* tuple */[
            v,
            i
          ],
          new_var
        ],
        dict
      ];
      return new_var;
    }
  };
  var proc = function (term) {
    var fold_assoc_tri = function (al) {
      var f = function (acc_al, param) {
        var t = proc(param[1]);
        return Pervasives.$at(acc_al, /* :: */[
                    /* tuple */[
                      param[0],
                      t,
                      param[2]
                    ],
                    /* [] */0
                  ]);
      };
      return Plate$BwaxMobile.List.foldl(f, /* [] */0, al);
    };
    var fold = function (l) {
      var f = function (acc_l, e) {
        var t = proc(e);
        return Pervasives.$at(acc_l, /* :: */[
                    t,
                    /* [] */0
                  ]);
      };
      return Plate$BwaxMobile.List.foldl(f, /* [] */0, l);
    };
    if (typeof term === "number") {
      if (term === /* No_term */0) {
        return /* No_term */0;
      } else {
        return /* Unknown */1;
      }
    } else {
      switch (term.tag | 0) {
        case /* Term */0 :
            var s_l = fold(term[1]);
            return /* Term */Block.__(0, [
                      term[0],
                      s_l
                    ]);
        case /* Term_record */1 :
            var s_al = fold_assoc_tri(term[0]);
            return /* Term_record */Block.__(1, [s_al]);
        case /* Var */2 :
            return /* Var */Block.__(2, [simplify(term[0])]);
        
      }
    }
  };
  return /* Forall */[
          Plate$BwaxMobile.List.map(simplify, scheme[0]),
          proc(shorten(scheme[1]))
        ];
}

function clone_term(t) {
  var shrinked = completely_shrink(t);
  if (typeof shrinked === "number") {
    if (shrinked === /* No_term */0) {
      return /* No_term */0;
    } else {
      return /* Unknown */1;
    }
  } else {
    switch (shrinked.tag | 0) {
      case /* Term */0 :
          return /* Term */Block.__(0, [
                    shrinked[0],
                    Plate$BwaxMobile.List.map(clone_term, shrinked[1])
                  ]);
      case /* Term_record */1 :
          return /* Term_record */Block.__(1, [Plate$BwaxMobile.List.map((function (param) {
                            return /* tuple */[
                                    param[0],
                                    clone_term(param[1]),
                                    param[2]
                                  ];
                          }), shrinked[0])]);
      case /* Var */2 :
          return /* Var */Block.__(2, [clone_var(shrinked[0])]);
      
    }
  }
}

function clone_var(v) {
  var var_type = v[/* var_type */0];
  var map_tri = function (l) {
    return Plate$BwaxMobile.List.map((function (param) {
                  return /* tuple */[
                          param[0],
                          clone_term(param[1]),
                          param[2]
                        ];
                }), l);
  };
  var n_var_type;
  if (typeof var_type === "number") {
    switch (var_type) {
      case /* Any */0 :
          n_var_type = /* Any */0;
          break;
      case /* Number */1 :
          n_var_type = /* Number */1;
          break;
      case /* Appendable */2 :
          n_var_type = /* Appendable */2;
          break;
      case /* Comparable */3 :
          n_var_type = /* Comparable */3;
          break;
      case /* Compappend */4 :
          n_var_type = /* Compappend */4;
          break;
      
    }
  } else {
    switch (var_type.tag | 0) {
      case /* Recordvar */0 :
          n_var_type = /* Recordvar */Block.__(0, [Plate$BwaxMobile.List.assoc_map(clone_term, var_type[0])]);
          break;
      case /* Record_readonly */1 :
          n_var_type = /* Record_readonly */Block.__(1, [
              var_type[0],
              Plate$BwaxMobile.List.assoc_map(clone_term, var_type[1])
            ]);
          break;
      case /* Record_readonly_of */2 :
          n_var_type = /* Record_readonly_of */Block.__(2, [
              map_tri(var_type[0]),
              Plate$BwaxMobile.List.assoc_map(clone_term, var_type[1])
            ]);
          break;
      case /* Record_limited */3 :
          n_var_type = /* Record_limited */Block.__(3, [Plate$BwaxMobile.List.map(clone_term, var_type[0])]);
          break;
      case /* Record_writeonly */4 :
          n_var_type = /* Record_writeonly */Block.__(4, [
              var_type[0],
              Plate$BwaxMobile.List.map(clone_term, var_type[1])
            ]);
          break;
      case /* Record_writeonly_of */5 :
          n_var_type = /* Record_writeonly_of */Block.__(5, [map_tri(var_type[0])]);
          break;
      case /* Any_record_readonly */6 :
          n_var_type = /* Any_record_readonly */Block.__(6, [
              clone_term(var_type[0]),
              Plate$BwaxMobile.List.assoc_map(clone_term, var_type[1])
            ]);
          break;
      case /* Record_writeonly_optional */7 :
          n_var_type = /* Record_writeonly_optional */Block.__(7, [
              var_type[0],
              Plate$BwaxMobile.List.map(clone_term, var_type[1])
            ]);
          break;
      case /* Opaqued */8 :
          n_var_type = /* Opaqued */Block.__(8, [Plate$BwaxMobile.List.map(clone_term, var_type[0])]);
          break;
      
    }
  }
  return /* record */[
          /* var_type */n_var_type,
          /* n */v[/* n */1],
          /* t : Unknown */1
        ];
}

function clone_scheme(param) {
  return /* Forall */[
          Plate$BwaxMobile.List.map(clone_var, Plate$BwaxMobile.List.map(completely_shrink_var, param[0])),
          clone_term(param[1])
        ];
}

function clone_type_info(param) {
  if (typeof param === "number") {
    if (param === /* Special */0) {
      return /* Special */0;
    } else {
      return /* Dummy */1;
    }
  } else {
    switch (param.tag | 0) {
      case /* Just */0 :
          return /* Just */Block.__(0, [
                    clone_scheme(param[0]),
                    param[1]
                  ]);
      case /* Alias */1 :
          return /* Alias */Block.__(1, [clone_scheme(param[0])]);
      case /* Opaque */2 :
          return /* Opaque */Block.__(2, [param[0]]);
      
    }
  }
}

function clone_tenv(tenv) {
  return Dict$BwaxMobile.$$String.map((function (param, param$1) {
                return /* tuple */[
                        clone_scheme(param$1[0]),
                        param$1[1],
                        param$1[2]
                      ];
              }), tenv);
}

function clone_dts(dts) {
  return Plate$BwaxMobile.List.assoc_map(clone_type_info, dts);
}

function name_term(name) {
  return /* Term */Block.__(0, [
            name,
            /* [] */0
          ]);
}

function wrap_term(name, t) {
  return /* Term */Block.__(0, [
            name,
            /* :: */[
              t,
              /* [] */0
            ]
          ]);
}

function wrap_term_2(name, a, b) {
  return /* Term */Block.__(0, [
            name,
            /* :: */[
              a,
              /* :: */[
                b,
                /* [] */0
              ]
            ]
          ]);
}

function record_readonly_term(next_int, tname) {
  var var_type = /* Record_readonly */Block.__(1, [
      tname,
      /* [] */0
    ]);
  var idx = Curry._1(next_int, /* () */0);
  return var_of(var_type, idx);
}

function record_writeonly_term(next_int, tname) {
  return var_of(/* Record_writeonly */Block.__(4, [
                tname,
                /* [] */0
              ]), Curry._1(next_int, /* () */0));
}

function get_all_argument_types(t) {
  var _acc = /* [] */0;
  var _t = t;
  while(true) {
    var t$1 = _t;
    var acc = _acc;
    var match = shorten(t$1);
    if (typeof match === "number" || match.tag || match[0] !== "Arrow") {
      return Plate$BwaxMobile.List.rev(acc);
    } else {
      var match$1 = match[1];
      if (match$1) {
        var match$2 = match$1[1];
        if (match$2 && !match$2[1]) {
          _t = match$2[0];
          _acc = /* :: */[
            match$1[0],
            acc
          ];
          continue ;
        } else {
          return Plate$BwaxMobile.List.rev(acc);
        }
      } else {
        return Plate$BwaxMobile.List.rev(acc);
      }
    }
  };
}

function get_last_argument_and_return_type(_last_argument, _t) {
  while(true) {
    var t = _t;
    var last_argument = _last_argument;
    var match = shorten(t);
    if (typeof match === "number" || match.tag || match[0] !== "Arrow") {
      return /* tuple */[
              last_argument,
              t
            ];
    } else {
      var match$1 = match[1];
      if (match$1) {
        var match$2 = match$1[1];
        if (match$2 && !match$2[1]) {
          _t = match$2[0];
          _last_argument = match$1[0];
          continue ;
        } else {
          return /* tuple */[
                  last_argument,
                  t
                ];
        }
      } else {
        return /* tuple */[
                last_argument,
                t
              ];
      }
    }
  };
}

function get_final_return_type(t) {
  var t$1 = shorten(t);
  if (typeof t$1 === "number" || t$1.tag || t$1[0] !== "Arrow") {
    return t$1;
  } else {
    var match = t$1[1];
    if (match) {
      var match$1 = match[1];
      if (match$1 && !match$1[1]) {
        return Plate$BwaxMobile.snd(get_last_argument_and_return_type(match[0], match$1[0]));
      } else {
        return t$1;
      }
    } else {
      return t$1;
    }
  }
}

exports.unexpect = unexpect;
exports.Type_exn = Type_exn;
exports.fail_typing = fail_typing;
exports.term = term;
exports.full_tname = full_tname;
exports.ti_of_name = ti_of_name;
exports.term_name = term_name;
exports.term_of_name = term_of_name;
exports.get_vars = get_vars;
exports.vars_of_tyenv = vars_of_tyenv;
exports.generalize = generalize;
exports.not_generalize = not_generalize;
exports.shorten = shorten;
exports.completely_shrink = completely_shrink;
exports.completely_shrink_var = completely_shrink_var;
exports.int_name = int_name;
exports.float_name = float_name;
exports.char_name = char_name;
exports.string_name = string_name;
exports.bool_name = bool_name;
exports.list_name = list_name;
exports.unit_name = unit_name;
exports.arrow_name = arrow_name;
exports.tuple_name = tuple_name;
exports.definition_name = definition_name;
exports.native_dt = native_dt;
exports.builtin_dts = builtin_dts;
exports.var_of = var_of;
exports.any = any;
exports.number = number;
exports.appendable = appendable;
exports.comparable = comparable;
exports.compappend = compappend;
exports.recordvar = recordvar;
exports.record_writeonly_of = record_writeonly_of;
exports.record_writeonly = record_writeonly;
exports.record_writeonly_optional = record_writeonly_optional;
exports.$$int = $$int;
exports.$$float = $$float;
exports.$$char = $$char;
exports.string = string;
exports.bool = bool;
exports.unit = unit;
exports.tuple = tuple;
exports.list = list;
exports.arrow = arrow;
exports.arrow_chain = arrow_chain;
exports.record = record;
exports.definition = definition;
exports.is_tuple = is_tuple;
exports.is_arrow = is_arrow;
exports.is_record = is_record;
exports.has_child = has_child;
exports.make_counter = make_counter;
exports.simplify_vars = simplify_vars;
exports.clone_term = clone_term;
exports.clone_var = clone_var;
exports.clone_scheme = clone_scheme;
exports.clone_type_info = clone_type_info;
exports.clone_tenv = clone_tenv;
exports.clone_dts = clone_dts;
exports.name_term = name_term;
exports.wrap_term = wrap_term;
exports.wrap_term_2 = wrap_term_2;
exports.record_readonly_term = record_readonly_term;
exports.record_writeonly_term = record_writeonly_term;
exports.get_all_argument_types = get_all_argument_types;
exports.get_last_argument_and_return_type = get_last_argument_and_return_type;
exports.get_final_return_type = get_final_return_type;
/* builtin_dts Not a pure module */
