

import React, { useEffect, useState } from 'react'

import SearchItemsCommon from './SearchItemsCommon';

import { AiOutlineFileDone } from 'react-icons/ai'

// 这个 Component 依赖自定义实体 “笔记”
export default function SearchNotes({ keyword, onSelectChange, selected, facade, currentUserId, }) {

    // 1. support pagination,
    // 2. a list of things, with checkboxes
    // 3. when checked / unchecked, call the onSelectChange({ name: true/false })

    //  { entityName, recordId, knowledgeCategoryKey }

    const [ documents, setDocuments ] = useState();

    const entityName = "笔记";

    // 要划分当前用户？

    async function loadData () {

        const [result, error] = await facade.list({
            entityName,
            search: keyword && keyword.trim().length > 0 ? { keyword, fields: ["标题"] } : undefined,
            condition: [[
                { field: "已加入知识库", op: "eq", value: true },
                { field: "创建者.id", op: "eq", value: currentUserId }
            ]],
            sort: [
                { field: "显示权重", order: "DESC" },
                { field: "创建时间", order: "DESC" }
            ],
            pageSize: 1000,
            fieldPaths: ["标题", "已加入知识库"],
        });
    
        if(!error) {
            setDocuments(result)
        }

    };

    useEffect(() => {      
        loadData()
    }, [keyword]);


    function renderItem(doc) {
        return (
            <div className="item-line">
                {/* <i className='bx bx-file'></i> */}
                <AiOutlineFileDone />
                <div className="item-title">{doc.标题}</div>
            </div>
        )
    }

    return (
        <SearchItemsCommon {...{
            items: documents,
            renderItem, 
            isItemSelected: doc => selected.some(r => r.recordId == doc.id ),
            selectItem : doc => {
                onSelectChange([ { entityName, recordId: doc.id }, ...selected ])
            },
            unselectItem: doc => {
                onSelectChange(selected.filter(r => r.recordId !== doc.id))
            }
        }} />
    )
}
