
// This file is generated. Don't modify it.
import React from 'react'
export default function EyeCloseIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M7.10088269,8.36668393 L6.83020765,9.96363526 C6.79969009,10.1794536 6.61534435,10.3401326 6.39738033,10.3408952 L6.39738033,10.3409078 C6.37114242,10.3438048 6.34466533,10.3438048 6.31842742,10.3409078 C6.19719074,10.3243093 6.08852052,10.2574983 6.01898364,10.1568084 C5.94944677,10.0561184 5.92545241,9.93083 5.95286652,9.81157268 L6.21704516,8.25293804 C5.72120794,8.16713462 5.23499663,8.04500934 4.76642873,7.8865999 L3.34130079,9.33196171 C3.25926933,9.41660765 3.14626376,9.46413616 3.02839192,9.46356665 C2.91896993,9.45812136 2.81516159,9.41348105 2.73594069,9.33780496 C2.56489381,9.16653492 2.56489381,8.88908794 2.73594069,8.7178179 L3.89660001,7.54066935 C3.25979868,7.24663274 2.67000164,6.87763911 2.15103823,6.43380055 C1.96691111,6.2722842 1.94858147,5.9920848 2.11009782,5.80795769 C2.27161417,5.62383057 2.55181357,5.60550092 2.73594069,5.76701728 C5.44400163,8.10660199 10.5676974,8.10660199 13.2640593,5.76701728 C13.4481864,5.60550093 13.7283858,5.62383058 13.8899022,5.80795769 C14.0514185,5.99208481 14.0330889,6.2722842 13.8489618,6.43380055 C13.3338168,6.87437345 12.7488766,7.24119876 12.1174486,7.53416683 L13.2845421,8.71783046 C13.455589,8.8891005 13.455589,9.16654748 13.2845421,9.33781752 C13.2001271,9.41816812 13.0881622,9.46316628 12.9716207,9.46357922 L12.9716081,9.46356665 C12.8547477,9.46334526 12.7429425,9.41587937 12.6616146,9.33196171 L11.2357669,7.88585732 C10.7665222,8.04462775 10.2795664,8.16700063 9.78295465,8.25293807 L10.0471335,9.81158525 C10.0683877,9.92607134 10.0429355,10.0443017 9.97645493,10.1399005 C9.90997437,10.2354994 9.80799661,10.300513 9.69327164,10.3204376 C9.67063362,10.3296267 9.64711828,10.3364855 9.62308988,10.3409078 C9.41096022,10.3398718 9.22996319,10.1871954 9.19319047,9.97827478 L8.91973643,8.36490416 C8.61440358,8.3915629 8.30723146,8.40489646 8,8.40489646 C7.69968339,8.40489646 7.39942347,8.39215634 7.10088269,8.36668393 Z" id="EyeCloseIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Wireframe/Eye-Close">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="EyeCloseIcon-mask-2" fill="white">
                            <use href="#EyeCloseIcon-path-1"></use>
                        </mask>
                        <use id="Combined-Shape" fill={color} href="#EyeCloseIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
