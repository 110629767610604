
// This file is generated. Don't modify it.
import React from 'react'
export default function PDFIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 15 16">
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Documents/Pdf" transform="translate(-1.000000, 0.000000)">
                    <g>
                        <g id="word-copy" transform="translate(1.200000, 0.000000)">
                            <path d="M0.614479638,0 C0.31040724,0 0.00633484163,0.3 0.00633484163,0.6 L0.00633484163,15.4 C0.00633484163,15.7 0.31040724,16 0.614479638,16 L13.3855204,16 C13.6895928,16 13.9936652,15.7 13.9936652,15.4 L13.9936652,3.5 L10.4461538,0 L0.614479638,0 Z" id="PDFIcon-Path" fill={"#FF0000"}></path>
                            <path d="M10.4461538,0 L10.4461538,2.88 C10.4461538,3.18 10.7502262,3.5 11.0745701,3.5 L13.9936652,3.5 L10.4461538,0 Z" id="PDFIcon-Path" fill={"#FFA2A2"}></path>
                        </g>
                        <text id="PDF" fontFamily="Arial-BoldMT, Arial" fontSize="6.2" fontWeight="bold" fill="#FFFFFF">
                            <tspan x="2" y="11.2">PDF</tspan>
                        </text>
                    </g>
                </g>
            </g>
        </svg>
        
    )
}
