

import React, { useEffect, useState } from 'react'

import Tabs from 'bwax-ui/components/Tabs';
import Button, { iconButton } from 'bwax-ui/components/Button';
import numeral from 'numeral';
import dayjs from 'dayjs';
import classNames from 'classnames';

import AddRecordForm from 'bwax-ui/components/records/AddRecordForm'
import EditRecordForm from 'bwax-ui/components/records/EditRecordForm';

import Modal from 'bwax-ui/components/Modal'

import { useTrack } from 'bwax-ui/track';

import { toast } from 'bwax-ui/components/Toast';

import ClampedText from 'bwax-ui/components/ClampedText';

import { downloadURL } from 'bwax-ui/ml/FrontEndHelper';

export default function Receipt_translate({ data, facade }) {

    const { currentUserId } = data;

    function renderCreateReceipt() {
        return (
            <CreateReceipt {...{ facade, currentUserId }} />
        )
    }

    function renderPastRecepts() {
        return (
            <ReceiptApplicationList {...{ facade, currentUserId }}></ReceiptApplicationList>
        )
    }


    const tabs = [
        { label: "开发票", key: "create-receipt", content: renderCreateReceipt() },
        { label: "发票记录", key: "past-receipts", content: renderPastRecepts() },
    ]


    return (
        <div className={"self-center max-w-3xl w-full h-full px-2 pt-[64px] sm:pt-2 pb-2 "}>
            <Tabs tabs={tabs} className="h-full" tabContentClassName="!px-2 !sm:px-3 !pt-3 !pb-2" defaultTab={"create-receipt"} />
        </div>
    )
}


const fm = n => numeral(n).format("0,0.00");

function CreateReceipt({ facade, currentUserId }) {

    const track = useTrack();

    // 找到所有的充值记录
    const [paymentRecords, setPaymentRecords] = useState();

    async function loadPaymentRecords() {
        const [result, error] = await facade.list({
            entityName: "充值记录",
            condition: [[{ field: "用户.id", op: "eq", value: currentUserId }, { field: "相关申请.订单.支付状态", op: "eq", value: "已支付" } ]],
            sort: [{ field: "创建时间", order: "DESC" }],
            pageSize: 1000, fieldPaths: ["相关申请.金额", "相关申请.订单.标题", "相关申请.订单.订单号", "相关申请.订单.支付状态", "数量", "创建时间", "发票申请"]
        });
        if (!error && result) {
            setPaymentRecords(result.filter(r => r.相关申请 && r.相关申请.订单));
        }
    };

    useEffect(() => {
        loadPaymentRecords()
    }, []);

    const remainingAmount = (paymentRecords || []).filter(
        r => !r.发票申请 && r.相关申请 && r.相关申请.订单.支付状态 == "已支付"
    ).map(r => r.相关申请.金额).reduce((acc, i) => acc + i, 0);

    function renderSummary() {
        if (!paymentRecords) {
            return null
        }


        return (
            <div className="text-[var(--gray11)]">
                <span>您有￥{fm(remainingAmount)} 金额发票可开</span>
            </div>
        )
    }

    const [lastApplication, setLastApplication] = useState();
    async function loadLastApplication() {
        const [result, error] = await facade.findOne({ 
            entityName: "发票申请", sort: [{ field: "创建时间", order: "DESC" }], 
            condition: [[{ field: "创建者.id", op: "eq", value: currentUserId }]],
            fieldPaths: ["公司名", "统一信用代码", "联系电话"] 
        });
        if (!error && result) {
            setLastApplication(result);
        }
    }

    useEffect(() => {
        loadLastApplication();
    }, []);

    const [isApplicationShown, setIsApplicationShown] = useState(false);


    function renderAddForm(closeModal) {

        const { id: _, ...formToCopy } = lastApplication || {};

        return (
            <div className='px-6 pt-2 pb-6 flex flex-col gap-6' data-color={"violet"}>
                <div className="flex gap-2 items-center font-medium">
                    <i className='bx bx-receipt text-[var(--gray11)]'></i>
                    <span>申请发票</span>
                </div>
                <div className="flex items-center gap-1">
                    <span className="">
                        金额
                    </span>
                    <span className="font-medium font-size-16">
                        ￥{fm(remainingAmount)}
                    </span>
                </div>
                <AddRecordForm {...{
                    facade, entityName: "发票申请",
                    onBeforeAdd: _ => {
                        track("translate_receipt_request")
                    },
                    onAdded: r => {
                        // 
                        closeModal();                        
                        toast({ title: "您的发票申请已提交" })

                        setTimeout(() => {
                            loadPaymentRecords();
                        }, 600)

                    },
                    hiddenFields: ["充值记录"],

                    initialValues: {
                        "充值记录": (paymentRecords || []).filter(r => !r.发票申请 && r.相关申请 && r.相关申请.订单.支付状态 == "已支付").map(r => r.id),
                        ...formToCopy
                    },

                    fieldPaths: [],
                    confirmText: "提交申请"
                }} />
                <div className="text-[var(--gray10)] font-size-12 flex flex-col gap-3">
                    <div>* 电子发票，5 个工作日内开出</div>
                </div>
            </div>
        )
    }


    function renderPaymentRecord(r) {

        const hasApplied = r.发票申请;

        return (
            <div key={r.id} className={classNames("flex gap-2 justify-between py-2 pl-1 pr-4", {
                "opacity-40": hasApplied,
            })}>
                <div className="flex gap-3 items-center">
                    <div className="whitespace-pre text-[var(--gray11)] font-size-13 rounded  leading-none">
                        {dayjs(r.创建时间).format("YY.MM.DD")}
                    </div>
                    <div className="font-size-13">购买 {r.数量} 积分</div>
                    <div className="whitespace-pre text-[var(--gray11)] font-size-12 px-1.5 py-1 bg-[var(--gray2)] rounded  leading-none">
                        ￥{fm(r.相关申请.金额)}
                    </div>
                </div>
                {hasApplied ? <div className="font-size-12 flex gap-1 items-center">已申请<i className="bx bx-check-circle text-[var(--gray11)] font-size-13"></i></div> : <div />}
            </div>
        )
    }

    return (
        <>
            <div className="w-full h-full flex flex-col">
                <div className="flex justify-between sm:flex-row gap-3 flex-wrap py-2">
                    <div className="flex gap-2 items-center px-1">
                        {/* <Button>全选</Button> */}
                        {renderSummary()}
                    </div>
                    <div>
                        <Button color="violet" isDisabled={remainingAmount == 0} onPress={_ => {
                            setIsApplicationShown(true);
                        }}>申请发票</Button>
                    </div>
                </div>
                <div className="py-2 grow overflow-auto flex flex-col gap-1">
                    {paymentRecords ? paymentRecords.map(renderPaymentRecord) : null}
                </div>
            </div>
            <Modal isOpen={isApplicationShown}
                className={"max-w-3xl"}
                //   isLarge={isLarge}
                contentClassName="h-full"
                onOpenChange={open => {
                    setIsApplicationShown(open);
                }}>
                {closeModal => {
                    return renderAddForm(closeModal);
                }}
            </Modal>
        </>
    )

}


function ReceiptApplicationList({ facade, currentUserId }) {

    const track = useTrack();

    const [editingApplication, setEditingApplication] = useState();

    const [applications, setApplications] = useState()

    const fieldPaths = ["公司名", "统一信用代码", "联系电话", "金额", "状态", "创建时间", "发票", "待处理问题.问题", "待处理问题.已反馈"];
    async function loadApplications() {
        const [result, error] = await facade.list({
            entityName: "发票申请",
            condition: [[{ field: "创建者.id", op: "eq", value: currentUserId }]],
            sort: [{ field: "创建时间", order: "DESC" }],
            pageSize: 1000,
            fieldPaths
        })
        if (!error && result) {
            setApplications(result);
        }
    }
    useEffect(() => {
        loadApplications()
    }, []);

    function renderStatus(status) {
        // 等待确认, 正在开, 已开具, 需要处理

        const color = {
            "等待确认": "brown",
            "正在处理": "blue",
            "已开发票": "green",
            "需要修改": "yellow"
        }[status];

        return (
            <div data-color={color} className={classNames("whitespace-pre font-size-12 px-2 py-1 text-[var(--color-11)] bg-[var(--color-3)] rounded")}>
                {status}
            </div>
        )

    }

    function renderApplication(r) {
        return (
            <div key={r.id} className={classNames("flex gap-2 justify-between py-2 pl-1 pr-2", {
                // "opacity-40": hasApplied,
            })}>
                <div className="flex gap-3 items-center">
                    <div className="whitespace-pre text-[var(--gray11)] font-size-13 rounded  leading-none">
                        {dayjs(r.创建时间).format("YY.MM.DD")}
                    </div>
                    <ClampedText >{r.公司名}</ClampedText>
                    <div className="whitespace-pre text-[var(--gray11)] font-size-12 px-1 bg-[var(--gray2)] rounded">
                        ￥{fm(r.金额)}
                    </div>
                </div>
                <div className="flex gap-1 items-center">
                    {r.状态 == "需要修改" || r.状态 == "等待确认" ?
                        iconButton(<i className="bx bx-edit font-size-13" />, _ => {
                            setEditingApplication(r)
                        }) : null
                    }
                    {r.状态 == "已开发票" ?
                        iconButton(<i className="bx bx-download font-size-13" />, _ => {
                            // setEditingApplication(r)
                            track("translate_receipt_download")
                            downloadURL(r.发票.url)
                        }) : null
                    }
                    {renderStatus(r.状态)}
                </div>
            </div>
        )
    }

    function onUpdated(r) {
        setApplications(prev => prev.map(p => p.id == r.id ? r : p));
    }

    async function updateFeedbackIfNecessary(record) {
        if(record.待处理问题 && !record.待处理问题.已反馈) {
            await facade.update({ id: record.待处理问题.id, entityName: "发票申请待处理问题", formData: { 已反馈: true } });
            const [r, e] = await facade.findById(record.id, { entityName: "发票申请", fieldPaths});
            if(r) {
                onUpdated(r);
            }
        } else {
            onUpdated(record);
        }
    }

    function renderEditForm(record, closeModal) {
        return (
            <div className='px-6 pt-2 pb-6 flex flex-col gap-6' data-color={"violet"}>
                <div className="flex gap-2 items-center font-medium">
                    <i className='bx bx-receipt text-[var(--gray11)]'></i>
                    <span>修改发票信息</span>
                </div>
                <div className="flex items-center gap-1">
                    <span className="">
                        金额
                    </span>
                    <span className="font-medium font-size-16">
                        ￥{fm(record.金额)}
                    </span>
                </div>
                { record.待处理问题 && !record.待处理问题.已反馈 ? (
                    <div className="bg-[var(--yellow3)] text-[var(--yellow12)] px-6 py-4 rounded-md mx-2 font-size-13">
                        { record.待处理问题.问题 }
                    </div>
                ) : null}
                <EditRecordForm {...{
                    color: "violet",
                    facade, entityName: "发票申请",
                    // 
                    record,
                    fieldItems: [
                        { name: "公司名", },
                        { name: "统一信用代码", },
                        { name: "联系电话" },
                    ],
                    onUpdated: r => {

                        updateFeedbackIfNecessary(r);

                        toast({ title: "已修改信息" });
                        closeModal()
                    },
                    onCancel: _ => { closeModal() },
                    fieldPaths,
                }} />
            </div>
        )
    }

    return (
        <>
            <div className="w-full h-full flex flex-col">
                <div className="flex justify-between sm:flex-row gap-3 flex-wrap py-2 px-1">
                    <div className="text-[var(--gray11)]">
                        您的发票申请，共 {applications ? applications.length : "-"} 条记录
                    </div>
                </div>
                <div className="py-2 grow overflow-auto flex flex-col gap-1">
                    {applications ? applications.map(renderApplication) : null}
                </div>
            </div>
            {editingApplication ? (
                <Modal isOpen={editingApplication}
                    className={"max-w-3xl"}
                    //   isLarge={isLarge}
                    contentClassName="h-full"
                    onOpenChange={open => {
                        if (!open) {
                            setEditingApplication()
                        }
                    }}>
                    {closeModal => {
                        return renderEditForm(editingApplication, closeModal);
                    }}
                </Modal>) : null}
        </>
    )

}