
import React, { useState, useEffect, useRef } from 'react'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import './HideContentSection.less'

export default function Section(props) {
    const { data, slots } = props

    const { openLabel, closeLabel, maxHeight = 400 } = data
    const [ hideContent, setHideContent ] = useState(false)
    const [ contentTooHeight, setContentTooHeight ] = useState(false)
    const contentRef = useRef(null)

    useEffect(() => {
        if(contentRef && contentRef.current.offsetHeight > maxHeight) {
            setHideContent(true)
            setContentTooHeight(true)
        }
    }, [])

    const renderContent = () => {
        return slots ? (
            Object.values(slots).map((s, index) => {
                return <div key={index}>{s}</div>
            })
        ) : null
    }

    const renderLabel = () => {
        return (
            <div className='section-toggle-show-more' onClick={() => setHideContent(!hideContent)}>
                <span>{hideContent ? (openLabel || '查看更多') : (closeLabel || '收起')}</span>
                {hideContent ? <MdKeyboardArrowDown style={{ fontSize: 24 }}/> : <MdKeyboardArrowUp style={{ fontSize: 24 }}/> }
            </div>
        )
    }

    return (
        <div className='section-container'>
            <div className='section-content' ref={contentRef} style={hideContent ? { height: maxHeight, overflow: 'hidden' } : {}}>
                { renderContent() }
            </div>
           { contentTooHeight ? renderLabel() : null }
        </div>
        
    )

}