import React, { useEffect, useRef, useState } from 'react'

import SwiperCore, { Virtual, EffectFade, EffectCube, EffectCoverflow, EffectFlip, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css'
import debounce from 'lodash/debounce'

import './SwiperWithBreakpoints.less'
// import ArrowDownIcon from './components/ArrowDownIcon';

const default_breakpoints = {
    414: {
        slidesPerView: 2
    },
    540: {
        slidesPerView: 3
    },
    671: {
        slidesPerView: 4
    },
    840: {
        slidesPerView: 6
    }
}

export default function SwiperWithBreakpoints(props) {

    const { data, slots } = props

    const { breakpointsList } = data

    const [ activeIndex, setActiveIndex ] = useState(0)
    const [ reachEnd, setReachEnd ] = useState(false)

    // console.log("activeIndex: ", activeIndex);

    function convertBreakpoints() {
        if(breakpointsList) {
            let convertedBreakpoints = {}
            breakpointsList.map(b => {
                convertedBreakpoints = {
                    ...convertedBreakpoints,
                    [b.breakpoint]: {
                        slidesPerView: b.slidesPerView
                    }
                }
            })

            return convertedBreakpoints
        } else {
            return default_breakpoints
        }
    }

    const swiperRef = useRef(null)

    function updateActiveIndex (index) {
        const swiper = swiperRef.current;
        if(swiper) {
            swiper.slideTo(index)
        }
        if(reachEnd) {
            setReachEnd(false)
        }
    }

    return (
        <div className='bp-swiper-container'>
            <Swiper
                slidesPerView={1}
                watchSlidesVisibility={true}
                breakpoints={convertBreakpoints()}
                onSwiper={swiper => swiperRef.current = swiper}
                onReachEnd={() => setReachEnd(true)}
                onActiveIndexChange={swiper => {
                    setActiveIndex(swiper.activeIndex)
                    if(swiper.activeIndex < swiper.previousIndex) {
                        setReachEnd(false)
                    }
                }}
            >
                {
                    Object.values(slots).map((slideEl, index) => {

                        return (
                            <SwiperSlide key={index}
                            >
                                <div className='bp-swiper-slide'>
                                    {slideEl}
                                </div>
                            </SwiperSlide>
                        )
                    })
                }
                {
                    activeIndex > 0 ? (
                        <div className="swiper-button-prev bp-slide-prev" onClick={() => updateActiveIndex(activeIndex - 1)}></div>
                    ) : null
                    
                }
                {   !reachEnd ? (
                        <div className="swiper-button-next bp-slide-next" onClick={() => updateActiveIndex(activeIndex + 1)}>
                        </div>
                    ) : null
                    
                }
                
            </Swiper>
        </div>
    )
}
