
export function hashCode(str) {
    // see https://stackoverflow.com/questions/6122571/simple-non-secure-hash-function-for-javascript
    const s = typeof (str) === 'string' ? str : JSON.stringify(str)
    let hash = 0;
    if (s.length == 0) {
        return hash;
    }
    for (let i = 0; i < s.length; i++) {
        let char = s.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
}


export function mapObj(o, func) {
    if (!o) return o
    return Object.keys(o).reduce((acc, key) => {
        return { ...acc, [key]: func(o[key], key) }
    }, {})
}

export function filterObj(o, func) {
    if (!o) return o
    return Object.keys(o).reduce((acc, key) => {
        if (func(o[key], key)) {
            return {
                ...acc, [key]: o[key]
            }
        } else {
            return acc
        }
    }, {})
}


export function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
}



//// list functions
export function replaceAt(array, value, index) {
    return [
        ...array.slice(0, index),
        value,
        ...array.slice(index + 1)
    ]
}

export function uniq(arr, isEqual) {
    if (!isEqual) {
        return [...(new Set(arr))]
    }
    /// 使用 isEqual
    let ret = []
    arr.forEach(e => {
        if (ret.some(r => isEqual(r, e))) {
            // do nothing
        }
        ret.push(e)
    })
    return ret
}

export function splitToChunks(arr, chunkSize, { fillWith } = {}) {
    let chunks = []
    for (let i = 0; i < arr.length; i += chunkSize) {
        const candidate = arr.slice(i, i + chunkSize)
        if (fillWith !== undefined && chunkSize > candidate.length) {
            /// fill the trunk
            for (let j = 0; j <= chunkSize - candidate.length; j++) {
                candidate.push(fillWith)
            }
        }

        chunks.push(candidate)
    }


    return chunks
}


export function stuffIn(array, value, index) {
    return [
        ...array.slice(0, index),
        value,
        ...array.slice(index)
    ]
}
/// list functions end



export function parseJSON(str) {
    try {
        return JSON.parse(str)
    } catch (e) {
        console.warn("Error parsing JSON", str)
        return {}
    }
}

/// array substract
export function substract(a, b, isEqual) {
    return a.filter(v => {
        const existedInB = b.some(u => isEqual ? isEqual(v, u) : v === u)
        return !existedInB
    })
}


// console.log(replaceAt([1,2,3,4,5,6,7], 100, 0))
// console.log(replaceAt([1,2,3,4,5,6,7], 100, 1))
// console.log(replaceAt([1,2,3,4,5,6,7], 100, 2))
// console.log(replaceAt([1,2,3,4,5,6,7], 100, 3))
// console.log(replaceAt([1,2,3,4,5,6,7], 100, 4))
// console.log(replaceAt([1,2,3,4,5,6,7], 100, 5))
// console.log(replaceAt([1,2,3,4,5,6,7], 100, 6))
// console.log(replaceAt([1,2,3,4,5,6,7], 100, 7))


// const tree = { a: [1,2,3, 4], b : { c: 1, d: [1,2,3,4]}}



export function extractExpressions(text, visit) {

    const pattern = /\$\{(.+?)\}/m;
    let trimed = text
    let ret = []

    let matched = pattern.exec(trimed)
    let offset = 0
    while (matched) {
        const start = offset + matched.index
        const end = offset + matched.index + matched[0].length

        ret.push(visit(matched[1], start, end))

        trimed = trimed.substring(matched.index + matched[0].length)
        
        offset = end

        matched = pattern.exec(trimed)

    }

    return ret
}

// let t = "${o}v${k}v${当前记录.邀请.活动.id}\">点击查看排行榜</a>"

// console.log(t.slice(0, 5))

// extractExpressions(t, (exp, start, end) => {
//     console.log("visited", exp, start, end)
// })


//// 





//// taken from https://davidwalsh.name/javascript-debounce-function

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

