import tinycolor from "tinycolor2";

// this is copied from https://awik.io/determine-color-bright-dark-using-javascript/
export default function lightOrDark(originalColor) {

    if(!originalColor) {
        return 'light'
    }

    // Variables for red, green, blue values
    let r, g, b, hsp;
    let color = originalColor;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

        // If HEX --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

        r = color[1];
        g = color[2];
        b = color[3];
    }
    else {
        if(!color.match(/^#/)) {
            // name color
            color = tinycolor(color).toHexString();
        }

        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        color = +("0x" + color.slice(1).replace(
            color.length < 5 && /./g, '$&$&'));

        r = color >> 16;
        g = color >> 8 & 255;
        b = color & 255;
    } 

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
    );

    
    // Using the HSP value, determine whether the color is light or dark
    //if (hsp > 127.5) {
    if(hsp > 180) { /// Van: 这个数是我暂时拍脑袋写上去的，估计要做一些测试 TODO
        return 'light';
    }
    else {

        return 'dark';
    }
}