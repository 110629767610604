
// This file is generated. Don't modify it.
import React from 'react'
export default function EditWireFrameIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M10.3829686,4.18204588 L11.8348574,5.62653233 L12.5814556,4.89757609 L11.1258966,3.42509084 L10.3829686,4.18204588 Z M9.68248916,4.89575086 L3,11.7044105 L3,13 L4.28293951,13 L11.1192721,6.32520847 L9.68248916,4.89575086 Z M8.97356114,4.19043728 L8.97235135,4.18923366 L9.14945421,4.01122322 L11.1233521,2.0000567 L14,4.91015625 L12.3590036,6.5123763 L11.8358889,7.03817164 L11.8282584,7.03058003 L4.69016687,14 L2,14 L2,11.2956645 L8.97356114,4.19043728 Z" id="EditWireFrameIcon-path-1"></path>
            </defs>
            <g id="Style" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Iconology" transform="translate(-432.000000, -156.000000)">
                    <g id="Icon" transform="translate(48.000000, 124.000000)">
                        <g id="Icon/Wireframe/Edit" transform="translate(384.000000, 32.000000)">
                            <g id="Group-3">
                                <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                                <mask id="EditWireFrameIcon-mask-2" fill="white">
                                    <use href="#EditWireFrameIcon-path-1"></use>
                                </mask>
                                <use id="Mask" fill={color} fillRule="nonzero" href="#EditWireFrameIcon-path-1"></use>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
