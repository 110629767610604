
import { HSVtoRGB, RGBtoHex } from './hsv'

import tinycolor from 'tinycolor2'


/// 在这里我们定义所有的颜色, 以及相关的辅助函数
export const levels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

/// [352, 8, 48, 152, 168, 208, 288] 
export const baseColorSetting = [
    ['红', 352, 'red',      'R'],
    ['橙', 8,   'orange',   'O'],
    ['黄', 48,  'yellow',    'Y'],
    ['绿', 152, 'green',    'G'],
    ['青', 168, 'cyan',     'C'],
    ['蓝', 208, 'blue',     'B'],
    ['紫', 288, 'purple',   'P'],
    ['蜜', 32,  'honey',    'H'],
]

export const baseColors = baseColorSetting.map(([name, hue, enName, prefix]) => {
    const hsv = { h: hue, s: 72, v: 100 }
    // const rgb = HSVtoRGB(hsv.h / 360, hsv.s / 100, hsv.v / 100) // {r, g, b}
    // const rgbaColor = 
    return {
        name, enName, prefix, hsv, code: prefix + '50'
    }
})

/// 这是主要的一个辅助函数，它默认返回 # 开头的十六进制颜色，
    // colorName could be
    /// 1) 红
    /// 2) Red, red, RED
    /// 3) R30
    /// 4) #FAFAE0
    /// 5) rgba()  or rgb()
export function lookupColor(dict, baseColors, colorName, type = 'HEX') {
    if(typeof(colorName) !== 'string') {
        return undefined
    }
    if(colorName.startsWith('#') || colorName.startsWith('rgb')){
        return colorName  // 它本身就是个合法的颜色定义
    }
    const hsv = lookupColorHSV(dict, baseColors, colorName) 
    if(hsv !== undefined) {
        return printColor(hsv, type)
    }
    // if(colorName === 'white') {
    //     if(type === 'HEX') {
    //         return '#FFFFFF'
    //     } else {
    //         return 'rgba(255, 255, 255, 1)'
    //     }
    // }
   
    // else try tinycolor
    const c = tinycolor(colorName);
    if(c.getFormat()) {
        return type == "HEX" ? c.toHexString() : c.toRgbString() // TODO 这里没有考虑其他类型了
    }

    // console.warn("Unknown color", colorName)

    return colorName
}


export function lookupColorHSV(dict, baseColors, colorName) {
    /// 看它是不是 base color 的名字或者英文名
    // const foundByName = baseColors.find(bc => bc.name === colorName)
    // if(foundByName) {
    //     return foundByName.hsv
    // }
    // const foundByEnName = baseColors.find(bc => bc.enName === colorName.toLowerCase())
    // if(foundByEnName) {
    //     return foundByEnName.hsv
    // }

    //  2022-11-03 不再从这里去，以免跟 HTML Color Names 冲突
    // https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1092


    /// 看 color dict 里面有没有
    const color = dict[colorName]
    return color
}


function printColor(color, t) {
    const type = t.toUpperCase()
    const { h, s, v } = color
    const { r, g, b } = HSVtoRGB(h / 360, s / 100, v / 100)
    if(type === 'RGB' || type === 'RGBA') {
        return `rgba(${r}, ${g}, ${b}, 1)`
    } else {
        // default is "HEX"
        return `#${RGBtoHex({r, g, b})}`
    }
}


//  生成不同级别的颜色
function deriveColor(h, level) {
    if(level == 5) {
        return {
            h, s: 72, v: 100,
        }
    } else if(level < 5) {
        return {
            h,
            s: 8 + (level - 1) * 16,
            v: 100,
        }
    } else {
        return {
            h, 
            s: 72,
            v: 80 - (level - 6) * 16
        }
    }
}

// neutral 色的生成逻辑不一样
function deriveNeutralColor(h, level) {
    const s = 2
    if(level === 1) {
        return {
            h, s, v: 100
        }
    } else if(level === 10) {
        return {
            h, s, v: 0
        }
    } else if(level < 6) {
        return {
            h, s, v: 96 - (level - 2) * 8
        }
    } else {
        return {
            h, s, v: 56 - (level - 6) * 16
        }
    }
}


export default class Palette {
    constructor({neutralHue = 208} = {}) {
        this.neutralHue = neutralHue
    }

    getBaseColors() {
        return [
            ...baseColors, {
                name: "灰",
                enName: 'neutral',
                hsv: {h: this.neutralHue, s: 2, v: 56},   /// N60
                prefix: 'N',
                code: 'N60',
            }
        ]
    }

    getColor(colorName, type) {
        ////
        const colors = baseColors.map(({prefix, hsv}) => {
            return levels.map( l => ({
                code: prefix + l + '0',
                hsv: deriveColor(hsv.h, l)
            }))
        })
        const neutralColors = levels.map(l => ({
            code: `N${l}0`,
            hsv: deriveNeutralColor(this.neutralHue, l)
        }))

        const allColors = [ ...colors, neutralColors ]

        /// 先 flatten 在 reduce
        const dict = allColors.reduce(
            (acc, subList) => [...acc, ...subList], []   // 先 flatten
        ).reduce(
            (acc, {code, hsv}) => ({...acc, [code]: hsv}), {} // 转成 dict
        )

        return lookupColor(dict, this.getBaseColors(), colorName, type)
    }


}

/// handy function
const plt = new Palette()
export function color(name) {
    return plt.getColor(name)
}


// helpers to brighten, lighten, darken, desaturate colors
// passed-in string, return HEX string.
export function brighten(colorName, rate) {
    return tinycolor(color(colorName)).brighten(rate).toString();
}

export function lighten(colorName, rate) {
    return tinycolor(color(colorName)).lighten(rate).toString();
}

export function darken(colorName, rate) {
    return tinycolor(color(colorName)).darken(rate).toString();
}

export function desaturate(colorName, rate) {
    return tinycolor(color(colorName)).desaturate(rate).toString();
}



