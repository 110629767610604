
import React from 'react'

import QrCodeComp from '../lazy/QrCode'

export default function QrCode (props) {

    return (
        <QrCodeComp {...props} />
    )
}