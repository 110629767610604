// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Plate$BwaxMobile = require("../../../bwax-js/ml/plate.bs.js");
var BaseUI$BwaxMobile = require("../../re/BaseUI.bs.js");
var Widget_impl_input$BwaxMobile = require("./impl/widget_impl_input.bs.js");
var Widget_impl_data_ui$BwaxMobile = require("./impl/widget_impl_data_ui.bs.js");
var Widget_impl_misc_ui$BwaxMobile = require("./impl/widget_impl_misc_ui.bs.js");
var Widget_impl_styled_ui$BwaxMobile = require("./impl/widget_impl_styled_ui.bs.js");
var Widget_impl_custom_page_component$BwaxMobile = require("./impl/widget_impl_custom_page_component.bs.js");

var renderers_000 = /* tuple */[
  Widget_impl_input$BwaxMobile.module_name,
  Widget_impl_input$BwaxMobile.render_element
];

var renderers_001 = /* :: */[
  /* tuple */[
    Widget_impl_data_ui$BwaxMobile.module_name,
    Widget_impl_data_ui$BwaxMobile.render_element
  ],
  /* :: */[
    /* tuple */[
      Widget_impl_misc_ui$BwaxMobile.module_name,
      Widget_impl_misc_ui$BwaxMobile.render_element
    ],
    /* :: */[
      /* tuple */[
        Widget_impl_styled_ui$BwaxMobile.module_name,
        Widget_impl_styled_ui$BwaxMobile.render_element
      ],
      /* :: */[
        /* tuple */[
          Widget_impl_custom_page_component$BwaxMobile.module_name,
          Widget_impl_custom_page_component$BwaxMobile.render_element
        ],
        /* [] */0
      ]
    ]
  ]
];

var renderers = /* :: */[
  renderers_000,
  renderers_001
];

function render_element(doRender, onMsg, get_instance_id, facade, viewEnv, convertRules, name, rawContent) {
  var match = Plate$BwaxMobile.List.assoc(name, renderers);
  if (match !== undefined) {
    return Curry._7(match, doRender, onMsg, get_instance_id, facade, viewEnv, convertRules, rawContent);
  } else {
    var partial_arg = "No renderer for " + name;
    return (function (param, param$1, param$2, param$3) {
        return BaseUI$BwaxMobile.text(/* [] */0, partial_arg, param, param$1, param$2, param$3);
      });
  }
}

exports.renderers = renderers;
exports.render_element = render_element;
/* Plate-BwaxMobile Not a pure module */
