import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';

import ReactDOM from 'react-dom/client';

import './ConfirmDialog.less';

function ConfirmDialog({ onOk, onCancel, title, message, cancelText, okText }) {

    return (
        <div className="bw-confirm-dialog">
            <div className="dialog-mask" onClick={_ => onCancel() }>

            </div>
            <div className="dialog-container">
                {title ? (
                    <div className="dialog-head">
                        {title}
                    </div>
                ) : null}
                { message ? (
                    <div className="dialog-body">
                        {message}
                    </div>
                ): null}
                <div className="dialog-footer">
                    <button className="cancel" onClick={_ => onCancel()}>
                        { cancelText || "取消" }
                    </button>
                    <button onClick={_ => onOk()}>
                        { okText || "确定" }
                    </button>
                </div>
            </div>
        </div>
    )
}


// confirm 

export function confirm({
    onCancel, onOk, title, message, cancelText, okText, onMsg
}) {

    // const result = window.confirm("Press a button!");

    const rootEle = document.getElementById("app");

    const dialogEle = document.createElement("div");

    const dismiss = _ => rootEle.removeChild(dialogEle)

    const dialog = (
        <ConfirmDialog {...{
            title, message, cancelText, okText,
            onOk: _ => {
                if (onOk) onMsg(onOk);
                dismiss();
            },
            onCancel: _ => {
                if (onCancel) onMsg(onCancel);
                dismiss();
            }
        }} />
    );
    ReactDOM.createRoot(dialogEle).render(dialog);

    rootEle.appendChild(dialogEle);


    // if(result) {
    //     if(onOk) onMsg(onOk);
    // } else {
    //     if(onCancel) onMsg(onCancel);
    // }


}



