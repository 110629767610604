

import React from 'react'

import Avatar from 'bwax-ui/components/Avatar';

import { BeatLoader } from 'react-spinners';
import RelatedKnowledges from '../RelatedKnowledges';
import RelatedWebLinks from '../RelatedWebLinks';

import './ChatbotMessageList.less'

import Button from 'bwax-ui/components/Button'

import classNames from 'classnames';

import RichTextRenderer from 'bwax-ui/auxiliary/richtext_slate/RichTextRenderer';

import { markdownToRichText } from 'bwax-ui/markdown/convertMarkdown';

import { copy } from "bwax-ui/ml/FrontEndHelper";
import { toast } from 'bwax-ui/components/Toast';

import dayjs from 'dayjs';

import getImageURL from 'bwax/util/getImageURL';
import ChatMessageTask from './ChatMessageTask';


function makeTask(givenTask){

    if(givenTask) {
        return {
            id: givenTask.id, category: givenTask.类别 || givenTask.category
        }
    }
    return givenTask
}

export function userMessage({ content, time, relatedKnowledges, chatModel, images, task: givenTask }) {
    return { messageType: "userInput", content, time, relatedKnowledges, chatModel, images, task: makeTask(givenTask), }
}

export function responseMessage({ content, time, relatedKnowledges, chatModel, webLinks, images, task: givenTask }) {
    return { messageType: "response", content, time, relatedKnowledges, chatModel, webLinks, images, task: makeTask(givenTask), }
}

export function newTopicMessage({ time, content }) {
    return { messageType: "newTopic", time, content }
}


export default function ChatbotMessageList(props) {

    const {
        style,
        messages, responding, hasLoadedMessages,
        messageListRef,
        respondingText,
        respondingSteps,

        shouldShowKnowledges = true,
        userAvatar, botAvatarUrl, userNickName,

        onBotAvatarClick,

        stopResponding,

        respondingKnowledges,
        respondingWebLinks,
        respondingTask,

        currentSession,
        persona,

        emptyView,
        facade,

        possibleQuestionsPanel,

    } = props;

    const currentPersona = persona || (currentSession && currentSession.persona);
    const avatarSize = 28;

    function renderBotAvatar() {
        const inner = (
            currentPersona ?
                <Avatar avatar={currentPersona.头像} nickName={currentPersona.名称} size={avatarSize} /> :
                <Avatar avatar={botAvatarUrl} size={avatarSize} />
        )
        if (onBotAvatarClick) {
            return (
                <div className="cursor-pointer" onClick={onBotAvatarClick}>
                    {inner}
                </div>
            )
        } else {
            return inner
        }
    }

    function renderBotName() {
        if (!currentPersona) {
            return null
        }
        if (onBotAvatarClick) {
            return <div className="persona-name font-size-12 cursor-pointer" onClick={onBotAvatarClick}>{currentPersona.名称}</div>
        } else {
            return <div className="persona-name font-size-12">{currentPersona.名称}</div>
        }
    }

    if (!(messages && messages.length > 0) && hasLoadedMessages && !responding && emptyView) {
        return (
            <div className="grow w-full flex flex-col items-center">
                {emptyView}
            </div>
        )
    }

    return (
        <div className='lc-history-message-container flex flex-col' style={style} ref={messageListRef}>
            {
                [
                    ...messages,
                    ...(responding ? [{ messageType: "responding", chatModel: currentSession.chatModel }] : [])
                ].map((m, index) => {
                    const { messageType, content, relatedKnowledges, chatModel, webLinks, time, images, task } = m;
                    const messageContentClassName = classNames('message-content', chatModel == "gpt-4" ? "message-content-enhanced" : null);

                    function renderContent(content) {
                        if (!content) {
                            return null
                        }
                        // return content;
                        const markdownValues = markdownToRichText(content);
                        return <RichTextRenderer value={markdownValues} version={2} />
                    }

                    function renderImages() {
                        if (images && images.length > 0) {
                            return (
                                <div className="flex flex-col gap-1 py-1 items-end">
                                    {
                                        images.map((i, index) => {
                                            return (
                                                <img key={index} className="w-52 rounded" src={getImageURL(i.url, "small")} />
                                            )
                                        })
                                    }
                                </div>
                            )
                        }
                        return null;
                    }


                    function renderAvatar() {
                        const avatar = messageType == "userInput" ? (
                            <Avatar avatar={userAvatar} nickName={userNickName} size={avatarSize} />
                        ) : (
                            renderBotAvatar()
                        )
                        return (
                            <div key={'avatar'} className='message-avatar' >
                                {avatar}
                            </div>
                        )
                    }

                    function renderAvatarPlaceholder() {
                        return <div key={"placeholder"} className={"message-avatar-placeholder"}></div>
                    }

                    function renderActionBar() {

                        const simpleButton = (icon, onPress) => {
                            return (
                                <div className="rounded w-[1.5rem] h-[1.5rem] hover:bg-[var(--gray3)] active:bg-[var(--gray4)] flex justify-center items-center text-[14px] hover:text-[var(--gray11)] text-[var(--gray10)] cursor-pointer"
                                    onClick={_ => onPress()}
                                >
                                    {icon}
                                </div>
                            )
                        }

                        return (
                            <div className="action-bar gap-2 px-1">
                                {simpleButton(<i className="bx bx-copy-alt" />, _ => {
                                    // console.log("copy")
                                    copy(content);
                                    toast({ title: "已复制文本", duration: 1500 })
                                })}
                            </div>
                        )
                    };


                    function renderResponse() {
                        const messageContent = task ? (
                            <ChatMessageTask className={messageContentClassName} taskId={task.id} taskCategory={task.category} facade={facade} />
                        ) : (
                            <div className={messageContentClassName}>
                                {renderImages()}
                                {renderContent(content)}
                                {shouldShowKnowledges ? <RelatedKnowledges value={relatedKnowledges} /> : null}
                                {shouldShowKnowledges ? <RelatedWebLinks value={webLinks} /> : null}
                                {renderActionBar()}
                            </div>
                        )

                        function renderTime() {
                            // const 
                            const today = dayjs();
                            const messageTime = dayjs(time);

                            const isSameYear = today.year() == messageTime.year();
                            const isSameMonth = isSameYear && today.month() == messageTime.month();
                            const isSameDay = isSameMonth && today.date() == messageTime.date();

                            const template = isSameDay ? "HH:mm" : (isSameYear ? "MM-DD HH:mm" : "YY-MM-DD HH:mm");

                            return (
                                <div className="font-size-11 px-2 text-[var(--gray10)]">
                                    {messageTime.format(template)}
                                </div>
                            )

                        }

                        return (
                            <>
                                {renderAvatar()}
                                <div className="message-content-box">
                                    {currentPersona ? renderBotName() : null}
                                    {messageContent}
                                    <div className="px-3 flex justify-between gap-2">
                                        {renderTime()}
                                    </div>
                                </div>
                                {renderAvatarPlaceholder()}
                            </>
                        )
                    }

                    function renderUserInput() {
                        return (
                            <>
                                {renderAvatarPlaceholder()}
                                <div className='message-content'>
                                    {renderImages()}
                                    {renderContent(content)}
                                    {renderActionBar()}
                                </div>
                                {renderAvatar()}
                            </>
                        )
                    }

                    function renderRespondingContent() {
                        if (respondingText || respondingTask) {
                            return (
                                <div className="responding-with-text">
                                    {renderContent(respondingText)}
                                    {/* 使用 RichText 再加上 LoadingCursor 比较麻烦 */}
                                    {/* <LoadingCursor /> */}
                                </div>
                            )
                        }

                        if (respondingSteps && respondingSteps.length > 0) {
                            return (
                                <div className="responding-steps">
                                    {respondingSteps.map(s => {
                                        return (
                                            <div data-status={s.status} key={s.step}>
                                                {/* <i className='bx bx-radio-circle'></i> */}
                                                {s.msg}
                                                {s.status == "Done" ? <i className='bx bx-check'></i> : <BeatLoader size={5} color={"#7B7D7D"} />}
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                        }

                        return (
                            <BeatLoader size={6} color={"#7B7D7D"} />
                        )
                    }

                    function renderResponding() {
                        return (
                            <>
                                {renderAvatar()}
                                <div className="message-content-box">
                                    {renderBotName()}
                                    {
                                        respondingTask ? (
                                            <ChatMessageTask className={messageContentClassName} taskId={respondingTask.id} taskCategory={respondingTask.category} facade={facade} />
                                        ) : (
                                            <div className={messageContentClassName}>
                                                {renderRespondingContent()}
                                                {shouldShowKnowledges ? <RelatedKnowledges value={respondingKnowledges} /> : null}
                                                {shouldShowKnowledges ? <RelatedWebLinks value={respondingWebLinks} /> : null}
                                            </div>
                                        )
                                    }
                                    <Button size="mini" appearance="outline" onPress={_ => {
                                        stopResponding();
                                    }}>停止</Button>
                                </div>
                                {renderAvatarPlaceholder()}
                            </>
                        )
                    }

                    const messageItemMap = {
                        response: _ => (
                            <div key={index} className={'message response'}>
                                {renderResponse()}
                            </div>
                        ),

                        userInput: _ => (
                            <div key={index} className={'message userInput'}>
                                {renderUserInput()}
                            </div>
                        ),

                        responding: _ => (
                            <div key={index} className={'message response'}>
                                {renderResponding()}
                            </div>
                        ),

                        newTopic: _ => {
                            const seperatorLine = <div className="w-3/12 h-px bg-[var(--mauve6)]"></div>;
                            return (
                                <React.Fragment key={index}>
                                    <div className="flex gap-4 justify-center items-center font-size-11 text-[var(--mauve9)] opacity-80 pt-14 pb-10">
                                        {seperatorLine}
                                        <div>新话题</div>
                                        {seperatorLine}
                                    </div>
                                    {content ? (
                                        <div className={'message response'}>
                                            {renderResponse()}
                                        </div>
                                    ) : null}
                                </React.Fragment>
                            )
                        }
                    }

                    const render = messageItemMap[messageType];

                    return render ? render() : null
                })
            }
            {possibleQuestionsPanel ? (
                <div className="grow flex flex-col justify-end items-center px-6 pt-4">
                    {possibleQuestionsPanel}
                </div>
            ) : null}
        </div>
    )
}




