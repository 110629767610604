import React from 'react';
// import createInlineStyleButton from './util/createInlineStyleButton';
import InlineStyleButton from '../util/InlineStyleButton';

import { MdSubscript } from 'react-icons/md'

export default function SubscriptButton(props) {
    const { editor } = props

    return InlineStyleButton({
        editor,
        style: 'sub',
        children: (
            <MdSubscript style={{ fontSize: 16 }} />
        ),
    })
}
// export default createInlineStyleButton({
//   style: 'sub',
//   children: (
//     <i className="fa fa-subscript" aria-hidden="true"></i>
//   ),
// });
