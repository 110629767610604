
import React, { useEffect, useRef, useState, useContext } from 'react'

import "./Layout_inbot.less";

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'

import classNames from 'classnames';

import { logout } from 'bwax-ui/ml/FrontEndHelper';
import Avatar from 'bwax-ui/components/Avatar';

import ClampedText from 'bwax-ui/components/ClampedText';

import ProgressBar from 'bwax-ui/components/ProgressBar';
import Popover from 'bwax-ui/components/Popover';

import Link from "bwax-ui/page/Link"

import DropdownMenu from 'bwax-ui/components/DropdownMenu';

import NotificationNav from './components/NotificationNav';

import UsageQuotaContext, { UsageQuotaContextProvider } from './UsageQuotaContext';
import UsagePanel from './components/UsagePanel';

import FeedbackList from './components/help/FeedbackList';
import ContactUs from './components/help/ContactUs';
import DevLog from './components/help/DevLog';

import isEqual from 'lodash/isEqual'

import Modal, { ModalTrigger } from 'bwax-ui/components/Modal';

import { useTrack } from 'bwax-ui/track';
import ReferralCodePage from './ReferralCodePage';

import getImageURL from 'bwax/util/getImageURL';

import LayoutBase from 'bwax-ui/components/LayoutBase';
import { ChatContextProvider } from './ChatContext';

import Cookies from 'js-cookie';

const defaultNavLabels = {
    "chat": "对话",
    "note": "笔记",
    "docs": "文档",
    "bookmarks": "网页",

    "table": "数据表",
    "task": "任务",

    "knowledgeMgmt": "设置",

    "referral": "推荐码",
    "notifications": "消息",
    "help": "帮助",
}

export default function Layout_inbot(props) {

    const { data = {}, slots, events, facade, viewEnv } = props
    const track = useTrack();

    const { routeTo } = viewEnv;

    const {
        navLabels: givenNavLabels = {},
        navLinks = {},
        title, currentUser, currentURLPath,

        taskCount,

        botAvatarUrl,

        logo, siteName, siteURL = "/",

        supportQrCode,

        remainingQuota, quota, usedQuota, totalUsed,
        isTopTier, currentPlanId, currentPlanExpiryDate,

        preference,
    } = data;

    const { menuButtonsLeft, menuButtonsRight, mainContent } = slots;

    const { navChanged, reloadPreference } = events;

    const navLabels = {
        ...defaultNavLabels,
        ...givenNavLabels,
    }

    // 暂时固定
    const topMenuItems = [
        {
            key: "chat",
            icon: <i className='bx bx-message-square-dots'></i> // <ChatBubbleIcon />
        },
        {
            key: "note",
            icon: <i className='bx bx-edit'></i> // <Pencil2Icon />
        },
        {
            key: "docs",
            icon: <i className='bx bx-box'></i> // <Pencil2Icon />
        },
        {
            key: "bookmarks",
            icon: <i className='bx bx-link'></i> // <Pencil2Icon />
        },
        taskCount > 0 ? (
            {
                key: "task",
                icon: <i className='bx bx-task'></i> //<Share1Icon />
            }
        ) : null

    ].filter(x => !!x);



    // 反馈有更新
    const [hasFeedbackReplied, setHasFeedbackReplied] = useState();
    const loadFeedbackUpdates = async () => {
        const [result, error] = await facade.findOne({
            entityName: "反馈更新标记", fieldPaths: ["有更新"],
            condition: [[{ field: "用户.id", op: "eq", value: currentUser.id }]]
        }, { forceRefreshing: true });
        if (!error && result) {
            setHasFeedbackReplied(result.有更新);
        }
    };
    useEffect(() => {
        loadFeedbackUpdates();
    }, []);


    const dlc = useContext(DataLoaderContext);

    const [theme, setTheme] = useState(_ => {
        return dlc.userenv.lcTheme || Cookies.get("--lc-theme") || "light"
    })

    function changeTheme(t) {
        if(theme == t) {
            return;
        }
        track("layout_change_theme", { theme: t} )

        setTheme(t);
        Cookies.set("--lc-theme", t);
        if(typeof(document) !== "undefined" && document.updateTheme) {
            document.updateTheme(t);
        }
    }

    const bottomMenuItems = [{
        key: "referral",
        icon: <i className='bx bx-gift'></i>,

        hasUpdates: !(preference && preference.hasReadReferralCode)
    }, {
        key: "notifications",
        icon: <i className='bx bx-bell'></i>, // <Pencil2Icon />
    }, {
        key: "help",
        icon: <i className='bx bx-help-circle'></i>,
        hasUpdates: hasFeedbackReplied,
    }, {
        key: "theme",
        icon: ({
            "light": <i className='bx bx-sun'></i>,
            "dark": <i className='bx bxs-moon'></i>,
            "system": <i className='bx bx-desktop'></i>
        })[theme],
        label: ({
            "light": "亮色模式",
            "dark": "暗黑模式",
            "system": "跟随系统",
        })[theme],

        subItems: [
            {
                label: "亮色模式",
                icon: <i className='bx bx-sun'></i>,
                onSelect: _ => {
                    changeTheme("light")
                }
            },
            {
                label: "暗黑模式",
                icon: <i className='bx bxs-moon'></i>,
                onSelect: _ => {
                    changeTheme("dark")
                }
            },
            {
                label: "跟随系统",
                icon: <i className='bx bx-desktop'></i>,
                onSelect: _ => {
                    changeTheme("system")
                }
            }
        ]
    }];



    const [referralCodeOpen, setReferralCodeOpen] = useState(false);

    function renderReferralCodeModal() {
        return !referralCodeOpen ? null : (
            <Modal className="max-w-lg px-6 pt-4 pb-8" isMain={false} isOpen={true} onOpenChange={open => {
                if (!open) {
                    // 
                    if (!(preference && preference.hasReadReferralCode)) {
                        (async () => {
                            const [_, error] = await savePreference({ name: "hasReadReferralCode", value: true, facade })
                            if (!error) {
                                reloadPreference();
                            }
                        })();
                    };

                }
                setReferralCodeOpen(open);
            }}>
                <ReferralCodePage {...{
                    facade, viewEnv,
                    data: { currentUserId: currentUser.id },
                }} />
            </Modal>
        )
    }

    const [helpModalOpen, setHelpModalOpen] = useState();

    const HelpComponent = {
        "feedback": FeedbackList,
        "contact": ContactUs,
        "dev_log": DevLog,
    }[helpModalOpen || "---"]

    function renderHelpModal() {
        return !helpModalOpen || !HelpComponent ? null : (
            <Modal className="max-w-xl px-6 pt-4 pb-8" isMain={true} isOpen={true} contentClassName={"h-full"} onOpenChange={open => {
                if (!open) {
                    setHelpModalOpen();
                }
            }}>
                {
                    closeModal => (
                        <HelpComponent {...{
                            currentUserId: currentUser.id, facade, closeModal, supportQrCode,

                            // 可能会需要的：
                            loadFeedbackUpdates,
                        }} />
                    )
                }
            </Modal>
        )
    }

    // 关于 nav 的配置
    const navComponents = {
        "notifications": NotificationNav,
    }

    const navSubItems = {
        "help": [

            ...(supportQrCode ? [
                { label: "联系我们", name: "contact", icon: <i className='bx bx-user'></i> }
            ] : []),

            {
                label: "如何使用",
                icon: <i className='bx bx-help-circle'></i>,
                onSelect: () => {
                    track("layout_click_help", { nav: "how_to_use" })
                    window.open("https://simplifyai.cn/learn")
                }
            }
        ].map(item => {
            if (item.onSelect) {
                return item
            } else {
                return {
                    ...item,
                    onSelect: () => {
                        track("layout_click_help", { nav: item.name });
                        setHelpModalOpen(item.name)
                    }
                }
            }
        }),

    }

    const meItems = [
        {
            label: "登出", name: "logout",
            icon: <i className='bx bx-exit'></i>,
            onSelect: () => {
                track("layout_logout");
                const dlc = facade.dlc;
                const { tenantCode, sandbox } = dlc;

                logout(tenantCode, sandbox);
                setTimeout(_ => {
                    // routeTo("/");
                    window.location.href = "/"
                }, 1000)  

            }
        }
    ]

    const navPressed = {
        // "help": () => {
        //     window.open("https://simplifyai.cn/learn")
        // },
        "referral": () => {
            setReferralCodeOpen(true);
        },
    }


    function renderItem({
        key, icon, label, url, Component, onPress, subItems, collapsedMode, hasUpdates, className, isInDrawer
    }) {

        if (!url && !Component && !onPress && !subItems) {
            return null
        }

        if (Component) {
            return (
                <Component key={key} {...{
                    label, icon, facade, currentUserId: currentUser.id,
                    collapsedMode,
                    ModalTrigger,
                }} />
            )
        }
        const isActive = currentURLPath.startsWith(url);
        const isIn = currentURLPath == url; // 要考虑 query params 吗？

        const inner = (
            <div key={key} className={
                classNames("flex gap-2 items-center w-full py-2 hover:bg-[var(--sand4)] rounded cursor-pointer", className, {
                    "justify-center": collapsedMode,
                    "px-4": !collapsedMode,
                    "bg-[var(--sand3)] font-medium": isActive
                })
            } onClick={_ => {
                track("layout_click_nav", { nav: key })
                if (subItems) {
                    return
                } else if (onPress) {
                    onPress();
                } else if (!isIn) {
                    navChanged(url)
                }
            }}>
                <div className="flex w-8 justify-center relative">
                    {icon}
                    {hasUpdates ?
                        <div className="absolute rounded-[50%] bg-[var(--tomato9)] h-1.5 w-1.5 right-[2px] top-[-1px]" /> : null
                    }
                </div>
                {collapsedMode ? null : (
                    <div className="flex gap-1 items-center grow">
                        <ClampedText tipEnabled={false}>{label}</ClampedText>
                        {subItems ? <i className='bx bx-chevron-right'></i> : null}
                    </div>

                )}
            </div>
        )

        if (subItems) {
            return (
                <DropdownMenu items={subItems} key={key} placement={isInDrawer ? "top" : "right top"}>
                    {inner}
                </DropdownMenu>
            )
        } else {
            return inner;
        }
    }

    function renderMenu(items, collapsedMode, isInDrawer) {
        return (
            <div className="flex flex-col px-1 gap-0.5">
                {items.map(item => {
                    const { key, icon, hasUpdates, className, label: givenLabel, subItems: givenSubItems } = item;

                    const label = navLabels[key] || givenLabel;

                    const url = navLinks[key];
                    const Component = navComponents[key];
                    const onPress = navPressed[key];
                    const subItems = navSubItems[key] || givenSubItems;

                    return renderItem({
                        key, label, icon, hasUpdates, className,
                        url, Component, onPress, subItems, collapsedMode,
                        isInDrawer,
                    })

                })}
            </div>
        )
    }

    function renderQuotaUsage(isInDrawer, collapsedMode) {
        return (<UsageBar {...{ isInDrawer, collapsedMode }} />)
    }

    const avatar = <Avatar nickName={currentUser.nickName || "User"} avatar={currentUser.avatar} size={22} />; Avatar

    // side menu - only show on wide screen

    function renderSideMenu({ collapsed, isInDrawer }) {
        const collapsedMode = !isInDrawer && collapsed;

        function renderTitle() {
            return (
                <Link to={siteURL} openWithNewTab={true} className={
                    classNames("flex gap-1 items-center w-full py-2", {
                        "justify-center": collapsedMode,
                        "px-3.5": !collapsedMode,
                    })
                }>
                    <img className="h-7 mr-1" src={getImageURL(logo, "thumbnail")} />
                    {collapsedMode ? null : (
                        <ClampedText className="font-medium font-size-15" tipEnabled={false}>{siteName}</ClampedText>
                    )}
                </Link>
            )
        }

        return (
            <div className="h-full flex flex-col justify-between py-1 bg-[var(--sand2)] overflow-auto">
                <div className="px-1 flex flex-col gap-2">
                    {/* {renderTitle()} */}
                    <div className="flex flex-col gap-1 pt-2 pb-1 px-1">
                        {/* {renderItem(<i className="bx bx-wallet" />, "余额: ￥10")} */}
                        {renderItem({
                            icon: avatar, label: currentUser.nickName || "用户", subItems: meItems,
                            isInDrawer, collapsedMode,
                        })}
                    </div>
                    {/* 问答、笔记等 */}
                    {renderMenu(topMenuItems, collapsedMode, isInDrawer)}
                </div>
                <div className="p-1">
                    {renderMenu(bottomMenuItems, collapsedMode, isInDrawer)}                    
                    {renderQuotaUsage(isInDrawer, collapsedMode)}
                </div>
            </div>
        )
    }


    return (
        <UsageQuotaRefresher initialValue={{
            remainingQuota, quota, usedQuota, totalUsed, isTopTier, currentPlanId, currentPlanExpiryDate,
        }} facade={facade} viewEnv={viewEnv}>
            <ChatContextProvider {...{ userNickName: currentUser.nickName, userAvatar: currentUser.avatar, botAvatarUrl }}>
                <LayoutBase {...{
                    mainContent, menuButtonsRight,
                    renderSideMenu,
                }} />
                {renderReferralCodeModal()}
                {renderHelpModal()}
            </ChatContextProvider>
        </UsageQuotaRefresher >
    )
}



async function savePreference({ name, value, facade }) {

    const [result, error] = await facade.customMutation({
        entityName: "个人使用偏好",
        interfaceName: "保存",
        args: [name, value],
        outputFieldPaths: []
    });
    if (error) {
        // error handling
        console.error(error);
    }

    return [result, error];

}


function UsageBar({ isInDrawer, collapsedMode }) {

    const track = useTrack();

    const { usedQuota, quota, reloadUsageQuota, facade, viewEnv } = useContext(UsageQuotaContext) || {};

    if (quota && usedQuota !== undefined) {
        const content = (
            <UsagePanel {...{ reloadUsageQuota, facade, viewEnv }} />
        )

        return (
            <Popover content={content} withPortal={!isInDrawer} onTriggerPress={_ => {
                track("layout_click_quota_usage")
            }}>
                <button style={{
                    backgroundColor: "transparent", width: "100%", cursor: "pointer",
                    padding: collapsedMode ? "0.25rem 0" : "0.25rem 0 0.5rem 0",
                    display: "flex", justifyContent: "center",
                }}>
                    <ProgressBar color={"violet"} doneColor="yellow" style={{ padding: "0 0.75rem" }} progress={(usedQuota) * 100 / quota}
                        {...(
                            collapsedMode ? {
                                type: "circle", style: { width: "2rem", height: "2rem" }
                            } : {}
                        )}
                    />
                </button>
            </Popover>
        )
    }
    return null
}


function UsageQuotaRefresher({ initialValue, facade, viewEnv, children }) {

    // 
    const [usageQuota, setUsageQuota] = useState(initialValue)

    const usageQuotaRef = useRef();
    usageQuotaRef.current = usageQuota;


    async function loadUsageQuota() {
        const [result, error] = await getQuotaUsage(facade);

        if (result) {
            const usageQuota = {
                remainingQuota: result.当日用量余额,
                quota: result.当前套餐?.每日用量 || 0,
                usedQuota: result.当日用量,
                isTopTier: result.当前套餐?.最高级别 || false,
                currentPlanId: result.当前套餐?.id,
                currentPlanExpiryDate: result.当前权益?.到期日,
                totalUsed: result.总计用量,
            }
            if (!isEqual(usageQuotaRef.current, usageQuota)) {
                setUsageQuota(usageQuota)
            }
            return usageQuota.remainingQuota;
        }
    }

    useEffect(() => {
        const interval = viewEnv.domainEnv.isSandbox ? 60000 : 5000;

        const timerId = setInterval(() => {
            loadUsageQuota()
        }, interval);

        return () => {
            clearInterval(timerId);
        }

    }, []);

    function reloadUsageQuota() {
        return loadUsageQuota();
    }

    return (
        <UsageQuotaContextProvider {...{
            ...usageQuota,
            reloadUsageQuota,

            viewEnv, facade
        }}>
            {children}
        </UsageQuotaContextProvider>
    )

}




async function getQuotaUsage(facade) {

    const [result, error] = await facade.findOne({
        entityName: "助手设定",
        fieldPaths: ["当日用量余额", "当日用量", "总计用量", "当前套餐.每日用量", "当前套餐.最高级别", "当前权益.到期日"]
    }, { forceRefreshing: true })
    return [result, error];

}