
import React, { Suspense } from 'react';

const Swiper =  React.lazy(() => import ('./Swiper'));

export default function SwiperLazy (props) {
    return (
        <Suspense fallback={null}>
            <Swiper {...props} />
        </Suspense>
    )
}