// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var AdhocPortJs = require("./AdhocPort.js");
var Nivo$BwaxMobile = require("../../re/auxiliary/Nivo.bs.js");
var Plate$BwaxMobile = require("../../../bwax-js/ml/plate.bs.js");
var BaseUI$BwaxMobile = require("../../re/BaseUI.bs.js");
var AdhocUI$BwaxMobile = require("../../re/auxiliary/AdhocUI.bs.js");
var Spinner$BwaxMobile = require("../../re/auxiliary/Spinner.bs.js");
var IconTypes$BwaxMobile = require("../../re/IconTypes.bs.js");
var DefaultTheme = require("bwax-ui/defaultTheme");
var ColorTypes$BwaxMobile = require("../../re/ColorTypes.bs.js");
var Element_delegate$BwaxMobile = require("./element_delegate.bs.js");

var make = AdhocPortJs.default;

var AdhocPort = {
  make: make
};

function ElementRenderer(Props) {
  var element = Props.element;
  var onMsg = Props.onMsg;
  var getInstanceID = Props.getInstanceID;
  var facade = Props.facade;
  var viewEnv = Props.viewEnv;
  var theme = Props.theme;
  var fontFaces = Props.fontFaces;
  var pageID = Props.pageID;
  var wrapperClass = Props.wrapperClass;
  var onlyCompositRule = function (rule) {
    if (typeof rule === "number") {
      if (rule >= -196635760) {
        if (rule >= 531263392) {
          if (rule >= 894466173) {
            if (rule >= 1019979362) {
              if (rule !== 1028960125 && rule !== 1063496351 && rule >= 1019979363) {
                return ;
              } else {
                return rule;
              }
            } else if (rule !== 939907157 && rule !== 977475101) {
              return ;
            } else {
              return rule;
            }
          } else if (rule >= 776515164) {
            if (rule !== 790228983) {
              if (rule >= 819475373) {
                if (rule >= 894466172) {
                  return rule;
                } else {
                  return ;
                }
              } else if (rule >= 819475371) {
                return rule;
              } else {
                return ;
              }
            } else {
              return rule;
            }
          } else if (rule !== 561722444 && rule >= 531263393 && rule < 776515163) {
            return ;
          } else {
            return rule;
          }
        } else if (rule >= 201352960) {
          if (rule >= 258527478) {
            if (rule !== 345286304) {
              if (rule >= 434500835) {
                if (rule >= 434500837) {
                  return ;
                } else {
                  return rule;
                }
              } else if (rule >= 258527479) {
                return ;
              } else {
                return rule;
              }
            } else {
              return rule;
            }
          } else if (rule !== 222199711 && rule !== 233447657) {
            return ;
          } else {
            return rule;
          }
        } else if (rule >= -15048642) {
          if (rule !== 45513431 && rule !== 79394430 && rule < 201352959) {
            return ;
          } else {
            return rule;
          }
        } else if (rule !== -160399838 && rule >= -196635759 && rule < -15048643) {
          return ;
        } else {
          return rule;
        }
      } else if (rule >= -731000468) {
        if (rule >= -583097680) {
          if (rule >= -346313767) {
            if (rule !== -345586204 && rule !== -305581231 && rule >= -346313766) {
              return ;
            } else {
              return rule;
            }
          } else if (rule !== -550118877 && rule !== -378612657) {
            return ;
          } else {
            return rule;
          }
        } else if (rule >= -689852594) {
          if (rule !== -680157294 && rule !== -626416848 && rule < -583097681) {
            return ;
          } else {
            return rule;
          }
        } else if (rule !== -701337526 && rule >= -731000467 && rule < -689852595) {
          return ;
        } else {
          return rule;
        }
      } else if (rule >= -937384256) {
        if (rule >= -786317123) {
          if (rule !== -777089820 && rule !== -772109033 && rule >= -786317122) {
            return ;
          } else {
            return rule;
          }
        } else if (rule !== -908095280 && rule !== -862584982) {
          return ;
        } else {
          return rule;
        }
      } else if (rule >= -965797017) {
        if (rule !== -949692403 && rule >= -965797016 && rule < -937384257) {
          return ;
        } else {
          return rule;
        }
      } else if (rule !== -1016061555 && rule !== -1005136686) {
        return ;
      } else {
        return rule;
      }
    } else {
      var variant = rule[0];
      if (variant >= -34049893) {
        if (variant >= 222776981) {
          if (variant >= 528541485) {
            if (variant >= 869835982) {
              if (variant >= 973471044) {
                if (variant !== 980250670 && variant >= 973471045) {
                  return ;
                } else {
                  return rule;
                }
              } else if (variant !== 904663632 && variant >= 869835983) {
                return ;
              } else {
                return rule;
              }
            } else if (variant !== 728032700 && variant !== 762661873 && variant !== 836180395) {
              return ;
            } else {
              return rule;
            }
          } else if (variant >= 338336313) {
            if (variant >= 416026681) {
              if (variant !== 447212619 && variant < 528541484) {
                return ;
              } else {
                return rule;
              }
            } else if (variant !== 373703110 && variant < 416026680) {
              return ;
            } else {
              return rule;
            }
          } else if (variant >= 263400905) {
            if (variant !== 297481091 && variant < 338336312) {
              return ;
            } else {
              return rule;
            }
          } else if (variant >= 222776982 && variant < 263400904) {
            return ;
          } else {
            return rule;
          }
        } else if (variant >= 14553548) {
          if (variant >= 81613653) {
            if (variant >= 98978424) {
              if (variant !== 128270816 && variant >= 98978425) {
                return ;
              } else {
                return rule;
              }
            } else if (variant !== 87367608 && variant >= 81613654) {
              return ;
            } else {
              return rule;
            }
          } else if (variant !== 21151858 && variant !== 38537191 && variant !== 76111832) {
            return ;
          } else {
            return rule;
          }
        } else if (variant >= 3787088) {
          if (variant >= 5504142) {
            if (variant !== 10615156 && variant < 14553547) {
              return ;
            } else {
              return rule;
            }
          } else if (variant !== 5343647 && variant < 5504141) {
            return ;
          } else {
            return rule;
          }
        } else if (variant >= -15087828) {
          if (variant !== 23515 && variant < 3787087) {
            return ;
          } else {
            return rule;
          }
        } else if (variant >= -34049892 && variant < -15087829) {
          return ;
        } else {
          return rule;
        }
      } else if (variant >= -690668329) {
        if (variant >= -481689225) {
          if (variant >= -218331858) {
            if (variant >= -61360200) {
              if (variant !== -37494765 && variant >= -61360199) {
                return ;
              } else {
                return rule;
              }
            } else if (variant !== -192935742 && variant >= -218331857) {
              return ;
            } else {
              return rule;
            }
          } else if (variant !== -345678389 && variant !== -303792878 && variant !== -297763065) {
            return ;
          } else {
            return rule;
          }
        } else if (variant >= -611055846) {
          if (variant >= -586247092) {
            if (variant !== -560194903 && variant < -481689226) {
              return ;
            } else {
              return rule;
            }
          } else if (variant !== -599944541 && variant < -586247093) {
            return ;
          } else {
            return rule;
          }
        } else if (variant >= -690257541) {
          if (variant !== -688651525 && variant < -611055847) {
            return ;
          } else {
            return rule;
          }
        } else if (variant >= -690668328 && variant < -690257542) {
          return ;
        } else {
          return rule;
        }
      } else if (variant >= -899500537) {
        if (variant >= -816909298) {
          if (variant >= -797091375) {
            if (variant !== -710172856 && variant >= -797091374) {
              return ;
            } else {
              return rule;
            }
          } else if (variant !== -812030052 && variant >= -816909297) {
            return ;
          } else {
            return rule;
          }
        } else if (variant !== -892786690 && variant !== -891056903 && variant !== -884574711) {
          return ;
        } else {
          return rule;
        }
      } else if (variant >= -993221457) {
        if (variant >= -979470620) {
          if (variant !== -958002182 && variant < -899500538) {
            return ;
          } else {
            return rule;
          }
        } else if (variant >= -993221456 && variant < -979470621) {
          return ;
        } else {
          return rule;
        }
      } else if (variant !== -1056300615 && variant !== -1046863084 && variant !== -1041690463) {
        return ;
      } else {
        return rule;
      }
    }
  };
  var onlyBasicRule = function (rule) {
    if (typeof rule === "number") {
      if (rule >= -196635760) {
        if (rule >= 531263392) {
          if (rule >= 894466173) {
            if (rule >= 1019979362) {
              if (rule !== 1028960125 && rule !== 1063496351 && rule >= 1019979363) {
                return ;
              }
              
            } else if (rule !== 939907157 && rule !== 977475101) {
              return ;
            }
            
          } else if (rule >= 776515164) {
            if (rule !== 790228983) {
              if (rule >= 819475373) {
                if (rule < 894466172) {
                  return ;
                }
                
              } else if (rule < 819475371) {
                return ;
              }
              
            }
            
          } else if (rule !== 561722444 && rule >= 531263393 && rule < 776515163) {
            return ;
          }
          
        } else if (rule >= 201352960) {
          if (rule >= 258527478) {
            if (rule !== 345286304) {
              if (rule >= 434500835) {
                if (rule >= 434500837) {
                  return ;
                }
                
              } else if (rule >= 258527479) {
                return ;
              }
              
            }
            
          } else if (rule !== 222199711 && rule !== 233447657) {
            return ;
          }
          
        } else if (rule >= -15048642) {
          if (rule !== 45513431 && rule !== 79394430 && rule < 201352959) {
            return ;
          }
          
        } else if (rule !== -160399838 && rule >= -196635759 && rule < -15048643) {
          return ;
        }
        
      } else if (rule >= -731000468) {
        if (rule >= -583097680) {
          if (rule >= -346313767) {
            if (rule !== -345586204 && rule !== -305581231 && rule >= -346313766) {
              return ;
            }
            
          } else if (rule !== -550118877 && rule !== -378612657) {
            return ;
          }
          
        } else if (rule >= -689852594) {
          if (rule !== -680157294 && rule !== -626416848 && rule < -583097681) {
            return ;
          }
          
        } else if (rule !== -701337526 && rule >= -731000467 && rule < -689852595) {
          return ;
        }
        
      } else if (rule >= -937384256) {
        if (rule >= -786317123) {
          if (rule !== -777089820 && rule !== -772109033 && rule >= -786317122) {
            return ;
          }
          
        } else if (rule !== -908095280 && rule !== -862584982) {
          return ;
        }
        
      } else if (rule >= -965797017) {
        if (rule !== -949692403 && rule >= -965797016 && rule < -937384257) {
          return ;
        }
        
      } else if (rule !== -1016061555 && rule !== -1005136686) {
        return ;
      }
      
    } else {
      var variant = rule[0];
      if (variant >= -34049893) {
        if (variant >= 222776981) {
          if (variant >= 528541485) {
            if (variant >= 869835982) {
              if (variant >= 973471044) {
                if (variant !== 980250670 && variant >= 973471045) {
                  return ;
                }
                
              } else if (variant !== 904663632 && variant >= 869835983) {
                return ;
              }
              
            } else if (variant !== 728032700 && variant !== 762661873 && variant !== 836180395) {
              return ;
            }
            
          } else if (variant >= 338336313) {
            if (variant >= 416026681) {
              if (variant !== 447212619 && variant < 528541484) {
                return ;
              }
              
            } else if (variant !== 373703110 && variant < 416026680) {
              return ;
            }
            
          } else if (variant >= 263400905) {
            if (variant !== 297481091 && variant < 338336312) {
              return ;
            }
            
          } else if (variant >= 222776982 && variant < 263400904) {
            return ;
          }
          
        } else if (variant >= 14553548) {
          if (variant >= 81613653) {
            if (variant >= 98978424) {
              if (variant !== 128270816 && variant >= 98978425) {
                return ;
              }
              
            } else if (variant !== 87367608 && variant >= 81613654) {
              return ;
            }
            
          } else if (variant !== 21151858 && variant !== 38537191 && variant !== 76111832) {
            return ;
          }
          
        } else if (variant >= 3787088) {
          if (variant >= 5504142) {
            if (variant !== 10615156 && variant < 14553547) {
              return ;
            }
            
          } else if (variant !== 5343647 && variant < 5504141) {
            return ;
          }
          
        } else if (variant >= -15087828) {
          if (variant !== 23515 && variant < 3787087) {
            return ;
          }
          
        } else if (variant >= -34049892 && variant < -15087829) {
          return ;
        }
        
      } else if (variant >= -690668329) {
        if (variant >= -481689225) {
          if (variant >= -218331858) {
            if (variant >= -61360200) {
              if (variant !== -37494765 && variant >= -61360199) {
                return ;
              }
              
            } else if (variant !== -192935742 && variant >= -218331857) {
              return ;
            }
            
          } else if (variant !== -345678389 && variant !== -303792878 && variant !== -297763065) {
            return ;
          }
          
        } else if (variant >= -611055846) {
          if (variant >= -586247092) {
            if (variant !== -560194903 && variant < -481689226) {
              return ;
            }
            
          } else if (variant !== -599944541 && variant < -586247093) {
            return ;
          }
          
        } else if (variant >= -690257541) {
          if (variant !== -688651525 && variant < -611055847) {
            return ;
          }
          
        } else if (variant >= -690668328 && variant < -690257542) {
          return ;
        }
        
      } else if (variant >= -899500537) {
        if (variant >= -816909298) {
          if (variant >= -797091375) {
            if (variant !== -710172856 && variant >= -797091374) {
              return ;
            }
            
          } else if (variant !== -812030052 && variant >= -816909297) {
            return ;
          }
          
        } else if (variant !== -892786690 && variant !== -891056903 && variant !== -884574711) {
          return ;
        }
        
      } else if (variant >= -993221457) {
        if (variant >= -979470620) {
          if (variant !== -958002182 && variant < -899500538) {
            return ;
          }
          
        } else if (variant >= -993221456 && variant < -979470621) {
          return ;
        }
        
      } else if (variant !== -1056300615 && variant !== -1046863084 && variant !== -1041690463) {
        return ;
      }
      
    }
    if (typeof rule === "number") {
      if (rule >= -196635760) {
        if (rule >= 531263392) {
          if (rule >= 894466173) {
            if (rule >= 1019979362) {
              if (rule !== 1028960125 && rule !== 1063496351 && rule >= 1019979363) {
                return ;
              } else {
                return rule;
              }
            } else if (rule !== 939907157 && rule !== 977475101) {
              return ;
            } else {
              return rule;
            }
          } else if (rule >= 776515164) {
            if (rule !== 790228983) {
              if (rule >= 819475373) {
                if (rule >= 894466172) {
                  return rule;
                } else {
                  return ;
                }
              } else if (rule >= 819475371) {
                return rule;
              } else {
                return ;
              }
            } else {
              return rule;
            }
          } else if (rule !== 561722444 && rule >= 531263393 && rule < 776515163) {
            return ;
          } else {
            return rule;
          }
        } else if (rule >= 201352960) {
          if (rule >= 258527478) {
            if (rule !== 345286304) {
              if (rule >= 434500835) {
                if (rule >= 434500837) {
                  return ;
                } else {
                  return rule;
                }
              } else if (rule >= 258527479) {
                return ;
              } else {
                return rule;
              }
            } else {
              return rule;
            }
          } else if (rule !== 222199711 && rule !== 233447657) {
            return ;
          } else {
            return rule;
          }
        } else if (rule >= -15048642) {
          if (rule !== 45513431 && rule !== 79394430 && rule < 201352959) {
            return ;
          } else {
            return rule;
          }
        } else if (rule !== -160399838 && rule >= -196635759 && rule < -15048643) {
          return ;
        } else {
          return rule;
        }
      } else if (rule >= -731000468) {
        if (rule >= -583097680) {
          if (rule >= -346313767) {
            if (rule !== -345586204 && rule !== -305581231 && rule >= -346313766) {
              return ;
            } else {
              return rule;
            }
          } else if (rule !== -550118877 && rule !== -378612657) {
            return ;
          } else {
            return rule;
          }
        } else if (rule >= -689852594) {
          if (rule !== -680157294 && rule !== -626416848 && rule < -583097681) {
            return ;
          } else {
            return rule;
          }
        } else if (rule !== -701337526 && rule >= -731000467 && rule < -689852595) {
          return ;
        } else {
          return rule;
        }
      } else if (rule >= -937384256) {
        if (rule >= -786317123) {
          if (rule !== -777089820 && rule !== -772109033 && rule >= -786317122) {
            return ;
          } else {
            return rule;
          }
        } else if (rule !== -908095280 && rule !== -862584982) {
          return ;
        } else {
          return rule;
        }
      } else if (rule >= -965797017) {
        if (rule !== -949692403 && rule >= -965797016 && rule < -937384257) {
          return ;
        } else {
          return rule;
        }
      } else if (rule !== -1016061555 && rule !== -1005136686) {
        return ;
      } else {
        return rule;
      }
    } else {
      var variant$1 = rule[0];
      if (variant$1 >= -61360199) {
        if (variant$1 >= 98978425) {
          if (variant$1 >= 447212620) {
            if (variant$1 >= 869835982) {
              if (variant$1 >= 973471044) {
                if (variant$1 !== 980250670 && variant$1 >= 973471045) {
                  return ;
                } else {
                  return rule;
                }
              } else if (variant$1 !== 904663632 && variant$1 >= 869835983) {
                return ;
              } else {
                return rule;
              }
            } else if (variant$1 !== 528541484 && variant$1 !== 762661873 && variant$1 !== 836180395) {
              return ;
            } else {
              return rule;
            }
          } else if (variant$1 >= 297481091) {
            if (variant$1 >= 338336313) {
              if (variant$1 !== 416026680 && variant$1 < 447212619) {
                return ;
              } else {
                return rule;
              }
            } else if (variant$1 >= 297481092 && variant$1 < 338336312) {
              return ;
            } else {
              return rule;
            }
          } else if (variant$1 !== 128270816 && variant$1 !== 222776981 && variant$1 !== 263400904) {
            return ;
          } else {
            return rule;
          }
        } else if (variant$1 >= 5504142) {
          if (variant$1 >= 38537191) {
            if (variant$1 >= 81613654) {
              if (variant$1 !== 87367608 && variant$1 < 98978424) {
                return ;
              } else {
                return rule;
              }
            } else if (variant$1 >= 38537192 && variant$1 < 81613653) {
              return ;
            } else {
              return rule;
            }
          } else if (variant$1 !== 10615156 && variant$1 !== 14553547 && variant$1 !== 21151858) {
            return ;
          } else {
            return rule;
          }
        } else if (variant$1 >= 23515) {
          if (variant$1 >= 3787088) {
            if (variant$1 !== 5343647 && variant$1 < 5504141) {
              return ;
            } else {
              return rule;
            }
          } else if (variant$1 >= 23516 && variant$1 < 3787087) {
            return ;
          } else {
            return rule;
          }
        } else if (variant$1 !== -37494765 && variant$1 !== -34049893 && variant$1 !== -15087829) {
          return ;
        } else {
          return rule;
        }
      } else if (variant$1 >= -710172855) {
        if (variant$1 >= -560194902) {
          if (variant$1 >= -297763065) {
            if (variant$1 >= -218331857) {
              if (variant$1 !== -192935742 && variant$1 < -61360200) {
                return ;
              } else {
                return rule;
              }
            } else if (variant$1 >= -297763064 && variant$1 < -218331858) {
              return ;
            } else {
              return rule;
            }
          } else if (variant$1 !== -481689226 && variant$1 !== -345678389 && variant$1 !== -303792878) {
            return ;
          } else {
            return rule;
          }
        } else if (variant$1 >= -611055847) {
          if (variant$1 >= -599944540) {
            if (variant$1 !== -586247093 && variant$1 < -560194903) {
              return ;
            } else {
              return rule;
            }
          } else if (variant$1 >= -611055846 && variant$1 < -599944541) {
            return ;
          } else {
            return rule;
          }
        } else if (variant$1 !== -690668329 && variant$1 !== -690257542 && variant$1 !== -688651525) {
          return ;
        } else {
          return rule;
        }
      } else if (variant$1 >= -899500537) {
        if (variant$1 >= -816909298) {
          if (variant$1 >= -812030051) {
            if (variant$1 !== -797091375 && variant$1 < -710172856) {
              return ;
            } else {
              return rule;
            }
          } else if (variant$1 >= -816909297 && variant$1 < -812030052) {
            return ;
          } else {
            return rule;
          }
        } else if (variant$1 !== -892786690 && variant$1 !== -891056903 && variant$1 !== -884574711) {
          return ;
        } else {
          return rule;
        }
      } else if (variant$1 >= -993221457) {
        if (variant$1 >= -979470620) {
          if (variant$1 !== -958002182 && variant$1 < -899500538) {
            return ;
          } else {
            return rule;
          }
        } else if (variant$1 >= -993221456 && variant$1 < -979470621) {
          return ;
        } else {
          return rule;
        }
      } else if (variant$1 !== -1056300615 && variant$1 !== -1046863084 && variant$1 !== -1041690463) {
        return ;
      } else {
        return rule;
      }
    }
  };
  var doConvertRules = function (onMsg, mrs) {
    var render = function (param, param$1, param$2, param$3, param$4) {
      return doRender(onMsg, param, param$1, param$2, param$3, param$4);
    };
    return Plate$BwaxMobile.List.map((function (mr) {
                  if (typeof mr === "number") {
                    return mr;
                  } else {
                    var variant = mr[0];
                    if (variant >= -258206108) {
                      if (variant >= 774323965) {
                        if (variant >= 973471044) {
                          if (variant !== 977896441) {
                            if (variant >= 1049777564) {
                              var brs = Plate$BwaxMobile.List.keep_map(onlyBasicRule, doConvertRules(onMsg, mr[1]));
                              return /* `hover */[
                                      728032700,
                                      brs
                                    ];
                            } else {
                              return mr;
                            }
                          } else {
                            var msg = mr[1];
                            return /* `onLongPress */[
                                    -710172856,
                                    (function (param) {
                                        return Curry._1(onMsg, msg);
                                      })
                                  ];
                          }
                        } else if (variant >= 811186865) {
                          if (variant >= 911571576) {
                            var crs = Plate$BwaxMobile.List.keep_map(onlyCompositRule, doConvertRules(onMsg, mr[1]));
                            return /* `ifNarrow */[
                                    -593248680,
                                    crs
                                  ];
                          } else {
                            return mr;
                          }
                        } else {
                          var el = mr[1];
                          return /* `above */[
                                  452579101,
                                  (function (param, param$1, param$2, param$3) {
                                      return render(el, param, param$1, param$2, param$3);
                                    })
                                ];
                        }
                      } else if (variant !== 516850918) {
                        if (variant !== 616437424) {
                          if (variant >= -258206107) {
                            return mr;
                          } else {
                            var el$1 = mr[1];
                            return /* `inFront */[
                                    592113284,
                                    (function (param, param$1, param$2, param$3) {
                                        return render(el$1, param, param$1, param$2, param$3);
                                      })
                                  ];
                          }
                        } else {
                          var crs$1 = Plate$BwaxMobile.List.keep_map(onlyCompositRule, doConvertRules(onMsg, mr[1]));
                          return /* `ifWide */[
                                  -265706864,
                                  crs$1
                                ];
                        }
                      } else {
                        var el$2 = mr[1];
                        return /* `onLeft */[
                                -365293370,
                                (function (param, param$1, param$2, param$3) {
                                    return render(el$2, param, param$1, param$2, param$3);
                                  })
                              ];
                      }
                    } else if (variant >= -816909298) {
                      if (variant !== -811273115) {
                        if (variant !== -803953446) {
                          if (variant !== -401070567) {
                            return mr;
                          } else {
                            var brs$1 = Plate$BwaxMobile.List.keep_map(onlyBasicRule, doConvertRules(onMsg, mr[1]));
                            return /* `focus */[
                                    76111832,
                                    brs$1
                                  ];
                          }
                        } else {
                          var el$3 = mr[1];
                          return /* `behind */[
                                  461385914,
                                  (function (param, param$1, param$2, param$3) {
                                      return render(el$3, param, param$1, param$2, param$3);
                                    })
                                ];
                        }
                      } else {
                        var brs$2 = Plate$BwaxMobile.List.keep_map(onlyBasicRule, doConvertRules(onMsg, mr[1]));
                        return /* `active */[
                                373703110,
                                brs$2
                              ];
                      }
                    } else if (variant !== -1055847624) {
                      if (variant !== -1014551919) {
                        if (variant >= -856501731) {
                          var el$4 = mr[1];
                          return /* `onRight */[
                                  -6182339,
                                  (function (param, param$1, param$2, param$3) {
                                      return render(el$4, param, param$1, param$2, param$3);
                                    })
                                ];
                        } else {
                          return mr;
                        }
                      } else {
                        var el$5 = mr[1];
                        return /* `below */[
                                811186865,
                                (function (param, param$1, param$2, param$3) {
                                    return render(el$5, param, param$1, param$2, param$3);
                                  })
                              ];
                      }
                    } else {
                      var msg$1 = mr[1];
                      return /* `onClick */[
                              -560194903,
                              (function (param) {
                                  return Curry._1(onMsg, msg$1);
                                })
                            ];
                    }
                  }
                }), mrs);
  };
  var doRender = function (onMsg, element, theme, container, prs, key) {
    var render = function (param, param$1, param$2, param$3, param$4) {
      return doRender(onMsg, param, param$1, param$2, param$3, param$4);
    };
    var convertRules = function (param) {
      return doConvertRules(onMsg, param);
    };
    var target;
    if (typeof element === "number") {
      target = BaseUI$BwaxMobile.empty;
    } else {
      switch (element.tag | 0) {
        case /* Lifted */0 :
            var el = element[1];
            var transform = element[0];
            var f = function (msg) {
              return Curry._1(onMsg, Curry._1(transform, msg));
            };
            target = (function (param, param$1, param$2, param$3) {
                return doRender(f, el, param, param$1, param$2, param$3);
              });
            break;
        case /* Rendered */1 :
            target = element[0];
            break;
        case /* Head */2 :
            var partial_arg = element[0];
            target = (function (param, param$1, param$2, param$3) {
                return BaseUI$BwaxMobile.head(partial_arg, param, param$1, param$2, param$3);
              });
            break;
        case /* Box */3 :
            var content = element[1];
            var partial_arg$1 = function (param, param$1, param$2, param$3) {
              return render(content, param, param$1, param$2, param$3);
            };
            var partial_arg$2 = doConvertRules(onMsg, element[0]);
            target = (function (param, param$1, param$2, param$3) {
                return BaseUI$BwaxMobile.box(partial_arg$2, partial_arg$1, param, param$1, param$2, param$3);
              });
            break;
        case /* Column */4 :
            var partial_arg$3 = Plate$BwaxMobile.List.map(render, element[1]);
            var partial_arg$4 = doConvertRules(onMsg, element[0]);
            target = (function (param, param$1, param$2, param$3) {
                return BaseUI$BwaxMobile.column(partial_arg$4, partial_arg$3, param, param$1, param$2, param$3);
              });
            break;
        case /* Row */5 :
            var partial_arg$5 = Plate$BwaxMobile.List.map(render, element[1]);
            var partial_arg$6 = doConvertRules(onMsg, element[0]);
            target = (function (param, param$1, param$2, param$3) {
                return BaseUI$BwaxMobile.row(partial_arg$6, partial_arg$5, param, param$1, param$2, param$3);
              });
            break;
        case /* WrappedRow */6 :
            var partial_arg$7 = Plate$BwaxMobile.List.map(render, element[1]);
            var partial_arg$8 = doConvertRules(onMsg, element[0]);
            target = (function (param, param$1, param$2, param$3) {
                return BaseUI$BwaxMobile.wrappedRow(partial_arg$8, partial_arg$7, param, param$1, param$2, param$3);
              });
            break;
        case /* Grid */7 :
            var maybeSpan = element[1];
            var partial_arg$9 = Plate$BwaxMobile.List.map(render, element[2]);
            var partial_arg$10 = doConvertRules(onMsg, element[0]);
            target = (function (param, param$1, param$2, param$3) {
                return BaseUI$BwaxMobile.grid(partial_arg$10, maybeSpan, partial_arg$9, param, param$1, param$2, param$3);
              });
            break;
        case /* Paragraph */8 :
            var partial_arg$11 = Plate$BwaxMobile.List.map(render, element[1]);
            var partial_arg$12 = doConvertRules(onMsg, element[0]);
            target = (function (param, param$1, param$2, param$3) {
                return BaseUI$BwaxMobile.paragraph(partial_arg$12, partial_arg$11, param, param$1, param$2, param$3);
              });
            break;
        case /* Page */9 :
            var fontFaceStyles = Plate$BwaxMobile.List.keep_map((function (param) {
                    var k = param[0];
                    return Plate$BwaxMobile.$$Option.map((function (url) {
                                  return Css.fontFace(k, /* :: */[
                                              /* `url */[
                                                5843823,
                                                url
                                              ],
                                              /* [] */0
                                            ], undefined, undefined, /* () */0);
                                }), Js_json.decodeString(param[1]));
                  }), Plate$BwaxMobile.$$Option.with_default(/* [] */0, Plate$BwaxMobile.$$Option.map(Plate$BwaxMobile.List.from_array, Plate$BwaxMobile.$$Option.map(Js_dict.entries, Plate$BwaxMobile.$$Option.and_then(Js_json.decodeObject, fontFaces)))));
            var pageStyles = Plate$BwaxMobile.List.concat(/* :: */[
                  BaseUI$BwaxMobile.basePageStyles,
                  /* :: */[
                    fontFaceStyles,
                    /* [] */0
                  ]
                ]);
            var nearbys = Plate$BwaxMobile.List.map((function (v) {
                    switch (v.tag | 0) {
                      case /* InFront */0 :
                          var el = v[0];
                          return /* `inFront */[
                                  592113284,
                                  (function (param, param$1, param$2, param$3) {
                                      return render(el, param, param$1, param$2, param$3);
                                    })
                                ];
                      case /* Behind */1 :
                          var el$1 = v[0];
                          return /* `behind */[
                                  461385914,
                                  (function (param, param$1, param$2, param$3) {
                                      return render(el$1, param, param$1, param$2, param$3);
                                    })
                                ];
                      case /* OnLeft */2 :
                          var el$2 = v[0];
                          return /* `onLeft */[
                                  -365293370,
                                  (function (param, param$1, param$2, param$3) {
                                      return render(el$2, param, param$1, param$2, param$3);
                                    })
                                ];
                      case /* OnRight */3 :
                          var el$3 = v[0];
                          return /* `onRight */[
                                  -6182339,
                                  (function (param, param$1, param$2, param$3) {
                                      return render(el$3, param, param$1, param$2, param$3);
                                    })
                                ];
                      case /* Above */4 :
                          var el$4 = v[0];
                          return /* `above */[
                                  452579101,
                                  (function (param, param$1, param$2, param$3) {
                                      return render(el$4, param, param$1, param$2, param$3);
                                    })
                                ];
                      case /* Below */5 :
                          var el$5 = v[0];
                          return /* `below */[
                                  811186865,
                                  (function (param, param$1, param$2, param$3) {
                                      return render(el$5, param, param$1, param$2, param$3);
                                    })
                                ];
                      
                    }
                  }), element[2]);
            var partial_arg$13 = Plate$BwaxMobile.List.map(render, element[1]);
            var partial_arg$14 = doConvertRules(onMsg, element[0]);
            target = (function (param, param$1, param$2, param$3) {
                return BaseUI$BwaxMobile.pageWithStyles(pageStyles, partial_arg$14, partial_arg$13, nearbys, param, param$1, param$2, param$3);
              });
            break;
        case /* Button */10 :
            var el$1 = element[2];
            var match = element[1];
            var maybeMsg = match[0];
            var onPress = function (param) {
              if (maybeMsg !== undefined) {
                return Curry._1(onMsg, maybeMsg);
              } else {
                return /* () */0;
              }
            };
            var partial_arg$15 = function (param, param$1, param$2, param$3) {
              return render(el$1, param, param$1, param$2, param$3);
            };
            var partial_arg_001 = /* disabled */match[1];
            var partial_arg_003 = /* mode */match[2];
            var partial_arg$16 = /* record */[
              /* onPress */onPress,
              partial_arg_001,
              /* unstyled */false,
              partial_arg_003
            ];
            var partial_arg$17 = doConvertRules(onMsg, element[0]);
            target = (function (param, param$1, param$2, param$3) {
                return BaseUI$BwaxMobile.button2(partial_arg$17, partial_arg$16, partial_arg$15, param, param$1, param$2, param$3);
              });
            break;
        case /* Link */11 :
            var el$2 = element[2];
            var match$1 = element[1];
            var partial_arg$18 = function (param, param$1, param$2, param$3) {
              return render(el$2, param, param$1, param$2, param$3);
            };
            var partial_arg_000 = /* to_ */match$1[0];
            var partial_arg_001$1 = /* openWithNewTab */match$1[1];
            var partial_arg$19 = /* record */[
              partial_arg_000,
              partial_arg_001$1
            ];
            var partial_arg$20 = doConvertRules(onMsg, element[0]);
            target = (function (param, param$1, param$2, param$3) {
                return BaseUI$BwaxMobile.link(partial_arg$20, partial_arg$19, partial_arg$18, param, param$1, param$2, param$3);
              });
            break;
        case /* Text */12 :
            var str = element[1];
            var partial_arg$21 = doConvertRules(onMsg, element[0]);
            target = (function (param, param$1, param$2, param$3) {
                return BaseUI$BwaxMobile.text(partial_arg$21, str, param, param$1, param$2, param$3);
              });
            break;
        case /* Qrcode */13 :
            var size = element[2];
            var str$1 = element[1];
            var partial_arg$22 = doConvertRules(onMsg, element[0]);
            target = (function (param, param$1, param$2, param$3) {
                return BaseUI$BwaxMobile.qrcode(partial_arg$22, str$1, size, param, param$1, param$2, param$3);
              });
            break;
        case /* Image */14 :
            var partial_arg_000$1 = /* src */element[1];
            var partial_arg_001$2 = /* description */element[2];
            var partial_arg$23 = /* record */[
              partial_arg_000$1,
              partial_arg_001$2
            ];
            var partial_arg$24 = doConvertRules(onMsg, element[0]);
            target = (function (param, param$1, param$2, param$3) {
                return BaseUI$BwaxMobile.image(partial_arg$24, partial_arg$23, param, param$1, param$2, param$3);
              });
            break;
        case /* Icon */15 :
            var str$2 = element[1];
            var rules = doConvertRules(onMsg, element[0]);
            var iconType = IconTypes$BwaxMobile.toIconType(str$2);
            if (iconType !== undefined) {
              var ic = iconType;
              var partial_arg$25 = element[2];
              var partial_arg$26 = function (param, param$1, param$2, param$3) {
                return BaseUI$BwaxMobile.icon(undefined, ic, partial_arg$25, param, param$1, param$2, param$3);
              };
              target = (function (param, param$1, param$2, param$3) {
                  return BaseUI$BwaxMobile.box(rules, partial_arg$26, param, param$1, param$2, param$3);
                });
            } else {
              console.log("Warning: the icon type is not recognized", str$2);
              target = BaseUI$BwaxMobile.empty;
            }
            break;
        case /* Video */16 :
            var partial_arg_000$2 = /* src */element[1];
            var partial_arg_001$3 = /* poster */element[2];
            var partial_arg$27 = /* record */[
              partial_arg_000$2,
              partial_arg_001$3,
              /* videoType */undefined
            ];
            var partial_arg$28 = doConvertRules(onMsg, element[0]);
            target = (function (param, param$1, param$2, param$3) {
                return BaseUI$BwaxMobile.video(partial_arg$28, partial_arg$27, param, param$1, param$2, param$3);
              });
            break;
        case /* Richtext */17 :
            var str$3 = element[1];
            var partial_arg$29 = doConvertRules(onMsg, element[0]);
            target = (function (param, param$1, param$2, param$3) {
                return BaseUI$BwaxMobile.richtext(partial_arg$29, str$3, param, param$1, param$2, param$3);
              });
            break;
        case /* PDFView */18 :
            var json = element[1];
            var partial_arg$30 = doConvertRules(onMsg, element[0]);
            target = (function (param, param$1, param$2, param$3) {
                return BaseUI$BwaxMobile.pdfView(partial_arg$30, json, param, param$1, param$2, param$3);
              });
            break;
        case /* Nivo */19 :
            var height = element[3];
            var width = element[2];
            var name = element[1];
            var mrs = element[0];
            var configValue = Plate$BwaxMobile.List.assoc_map((function (v) {
                    if (v.tag) {
                      var tagger = v[0];
                      return (function (j) {
                          return Curry._1(onMsg, Curry._1(tagger, j));
                        });
                    } else {
                      return v[0];
                    }
                  }), element[4]);
            target = (function (param, pc, param$1, key) {
                return React.createElement(Nivo$BwaxMobile.make, {
                            pc: pc,
                            rules: doConvertRules(onMsg, mrs),
                            theme: theme,
                            name: name,
                            width: width,
                            height: height,
                            config: configValue,
                            key: key
                          });
              });
            break;
        case /* Spinner */20 :
            var loading = element[4];
            var color = element[3];
            var config = element[2];
            var name$1 = element[1];
            var mrs$1 = element[0];
            target = (function (param, pc, param$1, key) {
                return React.createElement(Spinner$BwaxMobile.make, {
                            pc: pc,
                            rules: doConvertRules(onMsg, mrs$1),
                            theme: theme,
                            name: name$1,
                            config: config,
                            color: DefaultTheme.themeColor(theme, ColorTypes$BwaxMobile.toString(color)),
                            loading: loading,
                            key: key
                          });
              });
            break;
        case /* Adhoc_port */21 :
            var taggers = element[4];
            var slots = element[3];
            var data = element[2];
            var mrs$2 = element[1];
            var name$2 = element[0];
            target = (function (param, pc, param$1, key) {
                var inner = function (param, param$1, param$2, param$3) {
                  return React.createElement(make, {
                              name: name$2,
                              data: data,
                              slots: Js_dict.fromList(Plate$BwaxMobile.List.map((function (param) {
                                          var k = param[0];
                                          var reactEl = render(param[1], theme, /* box */4898315, /* [] */0, k);
                                          return /* tuple */[
                                                  k,
                                                  reactEl
                                                ];
                                        }), slots)),
                              events: Js_dict.fromList(Plate$BwaxMobile.List.assoc_map((function (tagger) {
                                          return (function (json) {
                                              return Curry._1(onMsg, Curry._1(tagger, json));
                                            });
                                        }), taggers)),
                              facade: facade,
                              pageID: pageID,
                              viewEnv: viewEnv
                            });
                };
                var rules = doConvertRules(onMsg, mrs$2);
                return BaseUI$BwaxMobile.box(rules, inner, theme, pc, /* [] */0, key);
              });
            break;
        case /* Adhoc */22 :
            var taggers$1 = element[3];
            var config$1 = element[2];
            var name$3 = element[1];
            var mrs$3 = element[0];
            target = (function (param, pc, param$1, key) {
                return React.createElement(AdhocUI$BwaxMobile.make, {
                            pc: pc,
                            rules: doConvertRules(onMsg, mrs$3),
                            theme: theme,
                            name: name$3,
                            config: config$1,
                            callbacks: Js_dict.fromList(Plate$BwaxMobile.List.assoc_map((function (tagger) {
                                        return (function (js) {
                                            return Curry._1(onMsg, Curry._1(tagger, js));
                                          });
                                      }), taggers$1)),
                            key: key
                          });
              });
            break;
        case /* Delegated */23 :
            target = Element_delegate$BwaxMobile.render_element(render, onMsg, getInstanceID, facade, viewEnv, convertRules, element[0], element[1]);
            break;
        
      }
    }
    return Curry._4(target, theme, container, prs, key);
  };
  if (wrapperClass !== undefined) {
    var inner = function (param, param$1, param$2, param$3) {
      return doRender(onMsg, element, param, param$1, param$2, param$3);
    };
    return BaseUI$BwaxMobile.boxWithClassName(wrapperClass, /* [] */0, inner, theme, /* column */-963948842, /* [] */0, "nk");
  } else {
    return doRender(onMsg, element, theme, /* column */-963948842, /* [] */0, "nk");
  }
}

var make$1 = ElementRenderer;

exports.AdhocPort = AdhocPort;
exports.make = make$1;
/* make Not a pure module */
