
// This file is generated. Don't modify it.
import React from 'react'
export default function IncreaseIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M8,14 C4.6862915,14 2,11.3137085 2,8 C2,4.6862915 4.6862915,2 8,2 C11.3137085,2 14,4.6862915 14,8 C14,11.3137085 11.3137085,14 8,14 Z M7.5,7.5 L5,7.5 L5,8.5 L7.5,8.5 L7.5,11 L8.5,11 L8.5,8.5 L11,8.5 L11,7.5 L8.5,7.5 L8.5,5 L7.5,5 L7.5,7.5 Z" id="IncreaseIcon-path-1"></path>
            </defs>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Increase">
                    <g>
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="IncreaseIcon-mask-2" fill="white">
                            <use href="#IncreaseIcon-path-1"></use>
                        </mask>
                        <use id="Combined-Shape" fill={color} fillRule="nonzero" href="#IncreaseIcon-path-1"></use>
                        <g id="Color/UI/Text" mask="url(#IncreaseIcon-mask-2)" fill={color} fillRule="nonzero">
                            <rect id="light-gray" x="0" y="0" width="16" height="16"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
