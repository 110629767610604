

import React, { useContext, useState, useRef, useEffect } from 'react'

import TextArea from 'bwax-ui/components/inputs/TextArea';
import Checkbox from 'bwax-ui/components/inputs/Checkbox';
import ToggleGroup from 'bwax-ui/components/inputs/ToggleGroup';

import Button, { Pressable } from 'bwax-ui/components/Button';

import EditingState from 'bwax-ui/re/legacy/EditingState.bs'

import UsageQuotaContext from 'bwax-ui/ml/widget/ports/inbot/UsageQuotaContext';

import OutOfQuotaTip from 'bwax-ui/components/OutOfQuotaTip';

import numeral from 'numeral'
import classNames from 'classnames'

import getImageURL from 'bwax/util/getImageURL';
import Loading from 'bwax-ui/components/Loading';

import * as AspectRatio from '@radix-ui/react-aspect-ratio';

export default function Txt2ImgTask(props) {

    const { 
        facade, entityName, fieldPaths, 
        
        onAdded, onBeforeAdd,   // for newly added

        taskJob, shouldRefreshedAtFirst, setRefreshedJob,  // for click to view / or edit  -- they are all taskJob

        closeModal,
    
    } = props;

    const editKeys = ["提示", "反向提示", "优化提示", "图片数量", "宽度", "高度"];
    const allKeys = [ ...editKeys, "生成图", "实际提示"];

    const [ currentJob, _setCurrentJob ] = useState(); // current Txt2Img job.


    function setCurrentJob(j) {
        console.trace(j);
        _setCurrentJob(j);
    }


    async function loadJob (taskJobId) {
        const [ job, error ] = await facade.findOne({ 
            entityName, 
            condition: [[{ field: "任务.id", op: "eq", value: taskJobId }]],
            fieldPaths: [
                ...allKeys,
                ...fieldPaths,
            ]
        });
        // TODO error handling:
        setCurrentJob(job)
    }

    useEffect(() => {
        // load current job
        if(taskJob) {
            loadJob(taskJob.id);
        }
        
    }, [ taskJob && taskJob.id ]);

    if(taskJob) {
        if(currentJob === undefined) { 
            return (
                <div className="w-full h-full flex justify-center items-center">
                    <Loading />
                </div>
            )
        }
        if(currentJob == null) {
            return (
                <div className="w-full h-full flex justify-center items-center">
                    <div className="text-[var(--gray10)]">没有找到指定的任务</div>
                </div>
            )
        }
    }

    // --- 用于初始化 Editing
    const initialValues = currentJob ? Object.keys(currentJob).reduce((acc, k) => {
        if(editKeys.indexOf(k) !== -1) {
            return { ...acc, [k]: currentJob[k] }
        } else {
            return acc
        }
    }, {}) :
    {
        优化提示: true,
        图片数量: 1,
        宽度: "512",
        高度: "512",
    };

    return (
        <Txt2ImgTaskView {...{
            facade, entityName, fieldPaths,

            editKeys, allKeys, initialValues,

            currentJob, 
            
            setCurrentJob: job => {
                setCurrentJob(job);

                const taskJob = job.任务;

                onAdded && onAdded(job);

                setRefreshedJob && setRefreshedJob(taskJob)
            },

            closeModal,
        }} />
    )

}

function Txt2ImgTaskView({ 
    facade, entityName, initialValues, fieldPaths, editKeys, allKeys,

    currentJob, setCurrentJob,

    closeModal,

}) {

    
    const { remainingQuota, reloadUsageQuota } = useContext(UsageQuotaContext) || {};


    async function refreshJob (jobId) {
        const [ job, error ] = await facade.findById(jobId, { 
            entityName, 
            fieldPaths: [
                ...allKeys,
                ...fieldPaths,
            ]
        }, { forceRefreshing: true });
        
        setCurrentJob(job)

    }

    useEffect(() => {
        if(!currentJob) {
            return;
        }
        
        if (currentJob.任务.状态 == "Waiting" || currentJob.任务.状态 == "Processing") {

            console.log("?> set Timmer", currentJob.任务.状态)

            const timerId = setInterval(() => {
                console.log(">>>> int", currentJob.任务.状态);
                refreshJob(currentJob.id)
            }, 1000);
            return () => {
                console.log(">>> cleare", timerId);
                clearInterval(timerId)
            }
        }
        if (currentJob.状态 == "Completed" && reloadUsageQuota) {
            setTimeout(() => {
                reloadUsageQuota();
            }, 800)
        }

    }, [currentJob && currentJob.任务 && currentJob.任务.状态 ]);


    const fixedValues = {};
    const defaultValues = {};

    const {
        errors,
        validated,
        editing,
        lastSaved,
        dirtyValues,
        updateEditing,
        markSaved,
        reset,
        rollback,
        clearEditing,
    } = EditingState.useEditingStateAsJs(editKeys, initialValues, fixedValues, defaultValues, [], "no-key", false);


    function renderSection(name, description, input) {
        return (
            <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-1">
                    <div className="font-size-14 font-medium">{name}</div>
                    {description ? <div className="text-[var(--gray11)]  font-size-12">{description}</div> : null}
                </div>
                <div>{input}</div>
            </div>
        )
    }

    function renderTextArea(fieldName, placeholder, autoFocus = false) {
        return (
            <TextArea {...{
                value: editing[fieldName] || "", onChange: v => updateEditing({ [fieldName]: v }), placeholder, autoFocus,
                styled: true, color: "violet", minRows: 3,

                isDisabled: !!currentJob
            }} />
        )
    }

    function renderToggle(items, fieldName) {
        return (
            <ToggleGroup className="!shadow font-size-13" items={items} value={editing[fieldName]} onChange={v => updateEditing({ [fieldName]: v })} isDisabled={!!currentJob} />
        )
    }

    const estimatedCostPerImage = (() => {
        const w = parseInt(editing["宽度"]);
        const h = parseInt(editing["高度"]);

        const longer = Math.max(w, h);
        if (longer >= 1024) {
            return 7200
        } else if (longer >= 768) {
            return 3600
        } else {
            return 800
        }
    })();

    function fmk(num) {
        return numeral(num).format("0,0.0a")
    }

    const estimatedCost = estimatedCostPerImage * editing["图片数量"] + (editing["优化提示"] ? 500 : 0);

    const isQuotaSufficient = estimatedCost <= remainingQuota;

    const isReadyToGenerate = ["提示", "图片数量", "宽度", "高度"].every(fieldName => {
        return validated[fieldName] !== undefined && validated !== null && validated !== ""
    });


    const [ isAdding, setIsAdding ] = useState(false);

    async function doStartGeneate(currentJob) {

        const [ taskJob, error ] = await facade.update({
            entityName: "OpenAI-任务", id: currentJob.任务.id, 
            formData: { 状态: "Waiting" }, 
            fieldPaths: fieldPaths.map(p => p.replace("任务.", ""))
        })
    
        return [ taskJob, error ];

    }

    async function startGenerate() {
        // 
        const [ taskJob, error ] = await doStartGeneate(currentJob);

        // 2. setCurrentJob
        if(!error) {
            setCurrentJob({
                ...currentJob,
                任务: taskJob
            })
        } else {
            setCurrentJob(currentJob)
        }        

    }


    async function generateImage() {
        // create a job, and start executing:

        setIsAdding(true);
        await facade.prepare([entityName]);

        const formData = {
            模型: "SDXL-1.0",
            ...validated
        }

        const [ job, error ] = await facade.add({ 
            entityName, 
            formData: formData,
            fieldPaths: [
                ...allKeys,
                ...fieldPaths,
            ]
        });
        if(error) {
            // TODO error handling:
            setIsAdding(false)
        } else {

            // start
            console.log(">>>>", job);

            // 1. start generate;

            const [ taskJob, error ] = await doStartGeneate(job);

            // 2. set CurrentJob
            if(!error) {
                setCurrentJob({
                    ...job,
                    任务: taskJob
                })
            } else {
                setCurrentJob(job)
            }
            setIsAdding(false);

        }

    }

    function renderImages() {
        const num = editing["图片数量"];
        const w = parseInt(editing["宽度"]);
        const h = parseInt(editing["高度"]);

        const placeholderLabel = currentJob && currentJob.任务.状态 === "Processing" ? <Loading /> :  "等待生成图片";

        return Array(num).fill(0).map((_, index) => {

            const image = currentJob && currentJob.生成图 ? currentJob.生成图[index] : 0;

            const renderPlacholder = label => (
                <div className="w-full h-full bg-[var(--gray2)] flex justify-center items-center text-[var(--gray9)] rounded">
                    {label}
                </div>
            )

            const renderImage = image => {
                return (
                    <img src={getImageURL(image, "large")} className="w-full h-full" />
                )
            }

            return (
                <div style={{ width: w * 0.5 }} key={index}>
                    <AspectRatio.Root ratio={w / h}>                        
                        { image ? renderImage(image) : renderPlacholder(placeholderLabel)}
                    </AspectRatio.Root>
                </div>
            )
        });
    }
    

    const hasUsed = currentJob && ["Waiting", "Processing", "Completed"].indexOf(currentJob.任务["状态"]) !== -1 

    return (
        <div className="w-full sm:h-full flex flex-col sm:flex-row px-6 pt-2 pb-4 gap-1" data-color={"violet"}>
            <div className="flex flex-col gap-3 sm:w-[256px] sm:min-w-[256px] w-full">
                <div className="flex flex-col gap-2">
                    <div className="flex gap-2 items-center font-size-20 font-medium px-1">
                        <i className='bx bx-image-add'></i>
                        <span className="font-size-16">生成图片</span>
                    </div>
                    <div className="text-[var(--gray11)]">
                        使用 Stable Diffusion XL 模型
                    </div>
                </div>
                <div className="flex flex-col gap-6 sm:grow sm:basis-0 sm:min-h-0 overflow-auto pt-2 pb-4 px-1">
                    {renderSection(
                        "提示词",
                        editing["优化提示"] ? "你想 AI 生成什么样的图片（可使用中文）" : "你想 AI 生成什么样的图片 (只支持英文)",
                        renderTextArea("提示", "输入你想要 AI 生成的图片内容", true)
                    )}
                    <div className="flex gap-2">
                        <div className="pt-0.5">
                            <Checkbox checked={editing["优化提示"]} onChange={v => updateEditing({ "优化提示": v })} isDisabled={!!currentJob}/>
                        </div>
                        <div className="flex flex-col gap-1">
                            <div>使用 GPT 优化提示词</div>
                            {editing["优化提示"] ? <div className="text-[var(--gray11)] font-size-12">消耗 0.5k 用量</div> : null}
                        </div>
                    </div>
                    {editing["优化提示"] ? null : (
                        renderSection("反向提示词", "你不想图片中出现的东西 (英文，非必填)", renderTextArea("反向提示", "输入你不想图片中出现的东西"))
                    )}
                    {renderSection(
                        "图片数量",
                        null, // "根据提示词生成的图片数量",
                        renderToggle([1, 2, 3, 4].map(value => ({ label: value, value })), "图片数量")
                    )}
                    {renderSection(
                        "图片尺寸",
                        null, // "不同大小的图片用量不一样",
                        (() => {
                            const renderInputRow = fieldName =>
                            (
                                <div className="flex gap-2 items-center gap-3">
                                    <div>{fieldName}</div>
                                    <div className="grow">
                                        {renderToggle(["512", "768", "1024"].map(value => ({ label: value, value })), fieldName)}
                                    </div>
                                </div>
                            )
                            return (
                                <div className="flex flex-col gap-4">
                                    {renderInputRow("宽度")}
                                    {renderInputRow("高度")}
                                </div>
                            )
                        })()
                    )}
                    {
                        currentJob && currentJob.实际提示 ? (
                            <div className="px-4 py-4">
                                { currentJob.实际提示 }
                            </div>
                        ) : null
                    }
                </div>
                <div className="flex flex-col gap-2">
                    <div className="text-[var(--gray11)] font-size-12 flex gap-3">                        
                        { hasUsed ? <span>消耗用量: {fmk(currentJob.任务.用量)}</span> : <span>需要用量: {fmk(estimatedCost)}</span> }
                    </div>
                    {
                        currentJob ? (
                            <>
                                {
                                    {
                                        "Init": (
                                            <Button appearance="primary" color="" size="large" isLoading={isAdding} isDisabled={!isReadyToGenerate} onPress={_ => {
                                                startGenerate()
                                            }}>
                                                开始生成
                                            </Button>
                                        ),
                                        "Processing": (
                                            <Button color="gray" size="large" isDisabled={true} onPress={_ => {
                                            }}>
                                                正在生成图片
                                            </Button>
                                        ),
                                        "Waiting": (
                                            <Button color="gray" size="large" isDisabled={true} onPress={_ => {
                                            }}>
                                                等待生成图片
                                            </Button>
                                        )

                                    }[currentJob.任务.状态] || null        
                                }
                            <Button color="gray" size="large" isLoading={isAdding} onPress={_ => {
                                closeModal()
                            }}>
                                关闭窗口
                            </Button>
                            </>
                            
                        ) : (
                            <Button appearance="primary" color="violet" size="large" isLoading={isAdding} isDisabled={!isReadyToGenerate} onPress={_ => {
                                generateImage()
                            }}>
                                生成图片
                            </Button>
                        ) 
                    }
                    {isQuotaSufficient ? null : <OutOfQuotaTip className="!px-0 !py-0" label="您今天的用量余额已不足" />}
                </div>
            </div>
            <div className="grow px-6 py-4 overflow-auto">
                <div className="flex flex-wrap gap-4 h-fit">
                    {renderImages()}
                </div>
            </div>
            <MenuAutoPop direction="rtl" />
        </div>
    )
}



// 
function MenuAutoPop(props) {

    // direction: ltr, rtl (other)
    const { direction = "ltr" } = props;

    const [menuPopped, setMenuPopped] = useState(false);

    const shouldHideMenuRef = useRef(false);
    const shouldPopMenuRef = useRef(false);

    const justHiddenRef = useRef(false);

    return (
        <div className="w-6 h-full relative">
            <Pressable onPress={_ => {
                // setMenuCollapsed(false)
            }}>
                <div
                    onMouseEnter={_ => {
                        shouldPopMenuRef.current = true
                        setTimeout(() => {
                            if (shouldPopMenuRef.current) {
                                setMenuPopped(true)
                                shouldPopMenuRef.current = false;
                                justHiddenRef.current = false;
                            }
                        }, 300)

                        // prevent other "leave" from hidding menu
                        shouldHideMenuRef.current = false

                        // remove the "just-being-hidden" mark
                        justHiddenRef.current = false;

                    }}
                    onMouseLeave={_ => {
                        shouldHideMenuRef.current = true;

                        // cancel the quick exit
                        shouldPopMenuRef.current = false;

                        setTimeout(() => {
                            if (shouldHideMenuRef.current) {
                                setMenuPopped(false);
                                shouldHideMenuRef.current = false;
                                justHiddenRef.current = true;
                            }
                        }, 500)
                    }}
                    className={classNames(
                        "py-2 px-2 font-size-20 text-[var(--gray11)] hover:bg-[var(--gray2)] active:bg-[var(--gray3)]",
                        "rounded cursor-pointer flex items-center absolute",
                        {
                            "top-0.5 -left-1": direction == "ltr",
                            "top-0.5 -right-1": direction == "rtl"
                        }
                    )}>
                    <i className='bx bx-history'></i>
                </div>
            </Pressable>
        </div>
    )
}

