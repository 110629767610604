

import React, { useState, useRef, useEffect } from 'react'

import MenuAndMain from './components/MenuAndMain'

import Button from 'bwax-ui/components/Button';
import TextInput from 'bwax-ui/components/inputs/TextInput';
import PopoverTrigger from 'bwax-ui/components/PopoverTrigger';

import classNames from 'classnames';

import { toast } from 'bwax-ui/components/Toast';
import { Pressable } from 'bwax-ui/components/Button';
import DocumentList from './DocumentList';

import ClampedText from 'bwax-ui/components/ClampedText';

import { useTrack } from 'bwax-ui/track';

const folderFieldPaths = ["名称"];

export default function DocumentManagement({ data, slots, events, facade, viewEnv }) {

    const { routeTo } = viewEnv;

    const {
        menuDrawerVisible,
        currentUserId,

        folderId: currentFolderId,

        userAvatar, userNickName, botAvatarUrl,

    } = data;

    const {
        closeMenuDrawer,
    } = events;

    const [folders, setFolders] = useState();
    const [fileCounts, setFileCounts] = useState();

    const entityName = "OpenAI-文件夹";
    async function loadFolders() {
        const [result, error] = await facade.list({
            entityName,
            condition: [[
                // { field: "加入知识库", op: "eq", value: true }
            ]],
            sort: [
                { field: "创建时间", order: "DESC" }
            ],
            pageSize: 1000,
            fieldPaths: folderFieldPaths,
        })
        if (!error) {
            setFolders(result);
        }
    }

    async function countFiles() {
        const [result, error] = await facade.aggregate({
            entityName: "OpenAI-知识文档",
            aggregate: [{
                field: "id",
                func: "COUNT",
                aliasName: "count",
            }],
            group: [{
                field: "文件夹.id",
                aliasName: "folderId"
            }]
        })
        if (!error) {
            setFileCounts(result);
        }
    }

    useState(() => {
        // load all the folder
        loadFolders();
        // use aggregate to check file count in each folders;
        countFiles();

    }, [])

    const currentFolder = currentFolderId && folders ? folders.find(f => f.id == currentFolderId) : undefined;

    function renderMenu() {
        return (
            <DocumentFolderList {...{
                currentFolderId, facade, routeTo, closeMenuDrawer, folders, fileCounts, setFolders,
            }} />
        )
    }

    async function updateFolderName (id, name) {
        const [ result, error ] = await facade.update({
            entityName, formData: { 名称: name }, id,
            fieldPaths: folderFieldPaths,
        })
        if(!error) {
            setFolders(prev => {
                return prev.map(f => f.id == id ? result : f);
            })
        }
        return [ result, error ]
    }

    async function deleteFolder (id) {
        const [ result, error ] = await facade.delete({
            entityName, id
        });
        if(!error) {

            const nextFolderId = (() => {
                for(let i = 0; i < folders.length; i++ ) {
                    const p = folders[i];
                    if(p.id == id && folders[i + 1]) {
                        return folders[i + 1].id
                    }
                }
            })();

            setFolders(prev => {
                return prev.filter(f => f.id != id);
            });

            setTimeout(() => {
                if(nextFolderId) {
                    routeTo("/docs/" + nextFolderId)
                } else {
                    routeTo("/docs")                            
                }
            }, 64)

        }
        return [ result, error ]
    }

    function renderMainContent() {
        return (
            <div className="pt-4 self-center w-full flex flex-col h-full">
                <DocumentList {...{
                    data: { 
                        folderId: currentFolderId, currentFolderName: currentFolder && currentFolder.名称, 
                        folders,
                        currentUserId,

                        userAvatar, userNickName, botAvatarUrl,

                    },
                    events: {
                        onAdded: r => {
                            countFiles()
                        },
                        onDeleted: r => {
                            countFiles()
                        },
                        updateFolderName,
                        deleteFolder,

                        countFiles,
                    },
                    facade,
                    viewEnv
                }} />
            </div>
        )
    }

    return (
        <MenuAndMain {...{
            menuDrawerVisible, closeMenuDrawer, mainContent: renderMainContent(),
            resizeKey: "document-management",

            defaultMenuWidth: 240,

            mainContentClassName: "overflow-auto",

            menu: renderMenu(),
        }} />
    )
}



function DocumentFolderList({ currentFolderId, facade, routeTo, folders, fileCounts, closeMenuDrawer, setFolders }) {

    const track = useTrack();

    function getCount(folderId) {
        if (fileCounts === undefined) {
            return null
        }

        if (folderId) {
            const found = fileCounts.find(c => c.folderId == folderId) || { count: 0 };
            return found.count
        } else {
            return fileCounts.reduce((acc, f) => acc + f.count, 0);
        }
    }

    function renderList() {
        if (folders === undefined) {
            return null
        }

        return (
            <div className="flex flex-col py-2 px-2 gap-0.5">
                {
                    ["all", ...folders].map((f, index) => {
                        const label = f === "all" ? "全部文档" : f.名称;

                        const count = getCount(f.id);
                        const isCurrentFolder = f.id ? currentFolderId === f.id : (!currentFolderId);

                        const onPress = _ => {
                            if (f.id) {
                                routeTo("/docs/" + f.id)
                            } else {
                                routeTo("/docs")
                            }
                            if(closeMenuDrawer) closeMenuDrawer();
                        }

                        return (
                            <Pressable key={f.id || "all"} onPress={onPress}>
                                <div className={classNames(
                                    "flex flex-row py-2.5 pl-3 pr-4 rounded justify-between gap-4 items-center relative sm:py-2",
                                    "hover:bg-[var(--gray3)]",
                                    isCurrentFolder ? "bg-[var(--gray2)]" : "hover:cursor-pointer",
                                )}>
                                    <div className="flex flex-row justify-between gap-4 items-center grow">
                                        <div className="flex gap-2 items-center">
                                            {isCurrentFolder ? <i className='bx bx-folder-open' /> : <i className='bx bx-folder'></i>}
                                            <ClampedText text={label} tipEnabled={false} />
                                        </div>
                                        <div className="font-size-12 text-[var(--gray10)]">{count}</div>
                                    </div>
                                    {/* {f.id ? (
                                        <DropdownMenu items={getMenuItems(f)}>
                                            <div className="text-[var(--gray11)] absolute h-full flex items-center" style={{
                                                right: 4
                                            }}>
                                                <i className='bx bx-dots-vertical-rounded'></i>
                                            </div>
                                            
                                        </DropdownMenu>
                                    ) : null
                                    } */}

                                </div>
                            </Pressable>
                        )
                    })
                }
            </div>
        )
    }

    return (
        <div className="flex flex-col py-4 h-full overflow-auto">
            <div className="mx-2 flex gap-2">
                {/* <TextInput color="violet" styled={true} className="h-8 grow rounded" placeholder="搜索文档"/> */}
                <PopoverTrigger content={({ close, overlayProps }) => {                    
                    return (
                        <AddFolderForm {...{
                            facade,
                            onAdded: r => {
                                track("document_folder_created")
                                setFolders(prev => {
                                    return [r, ...(prev || [])]
                                })
                                close();
                                routeTo("/docs/" + r.id)
                            }
                        }} />
                    )
                }}>
                    <Button className="grow">+</Button>
                </PopoverTrigger>
            </div>
            {renderList()}
        </div>
    )
}


function AddFolderForm({ onAdded, facade }) {
    const [name, setName] = useState();
    const entityName = "OpenAI-文件夹";
    const [loading, setLoading] = useState(false);

    const track = useTrack();


    useEffect(() => {
        track("document_create_new_folder")
    }, [])

    async function addFolder(name) {
        setLoading(true);
        const [result, error] = await facade.add({
            entityName,
            formData: {
                名称: name
            },
            fieldPaths: folderFieldPaths
        });
        setLoading(false);
        if (!error) {
            toast("文件夹已添加")
            onAdded(result);
        }
    }

    const isSubmittable = (name && name.trim().length > 0);

    return (
        <div className="flex flex-col px-4 pt-6 pb-4 font-size-14 gap-4" onKeyDown={e => {
            if (e.key == "Enter" && isSubmittable) {
                addFolder(name)
            }
        }}>
            <TextInput styled={true} className="grow" placeholder="输入文件夹名" autoFocus
                value={name || ""}
                onChange={setName}
            />
            <Button isDisabled={!isSubmittable} isLoading={loading}
                size="small" color="grass" appearance="primary"
                className="self-end"
                onPress={_ => {
                    addFolder(name)
                }}
            >
                确定
            </Button>
        </div>
    )
}