
import React, { Suspense } from 'react'

import { isQQPlayURL, compatibleWithQQURL } from 'bwax-ui/auxiliary/richtext/plugins/media/mediaTypes'
import { ratioInnerStyle, ratioOuterStyle, iframeStyle, ratioLOCALOuterStyle } from '../components/styles'
const VideoComp = React.lazy(() => import('bwax-ui/auxiliary/video/Video'));

export const defaultLOCALVideoOptions = {
    preload: 'auto',
    language: 'zh-cn',
    controls: true,
    aspectRatio: "16:9",
    controlBar: {
        volumePanel: { inline: false }
    }
}


export function renderVideo (url) {
    if (isQQPlayURL(url)) {
        return (
            <iframe
                className="video_iframe"
                style={{
                    ...ratioInnerStyle,
                    ...iframeStyle
                }}
                src={compatibleWithQQURL(url)}
                frameBorder={0}
                allowFullScreen={true} />
        )
    } else {
        return (
            <Suspense fallback={null}>
                <VideoComp
                    options={{
                        ...defaultLOCALVideoOptions,
                        poster: url + '?x-oss-process=video/snapshot,t_10000,m_fast,ar_auto'
                    }}>
                    <source src={url} type="video/mp4"></source>
                    <source src={url} type="video/ogg"></source>
                    <source src={url} type="video/webm"></source>
                    <p className="vjs-no-js">
                        观看该视频请先允许下载脚本JavaScript，然后保证你的浏览器
                        <a href="http://videojs.com/html5-video-support/" target="_blank">
                            支持html5视频
                        </a>
                    </p>
                </VideoComp>
            </Suspense>
        )
    }
}

export function renderAudio (url) {
    return (
        <audio style={{ verticalAlign: 'middle' }} controls >
            <source src={url} type="audio/mpeg" />
            <source src={url} type="audio/wav" />
            <source src={url} type="audio/ogg" />
            <p>暂不支持播放该音频，你可以<a href={url}>下载</a>这个音频文件。</p>
        </audio>
    )
}