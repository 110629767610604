
// This file is generated. Don't modify it.
import React from 'react'
export default function LoadingIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M13.9986543,7.99932713 C13.9986543,7.65750813 13.721431,7.37893911 13.3782662,7.37893911 C13.0351015,7.37893911 12.7578782,7.65616239 12.7578782,7.99932713 L12.7578782,8.00740159 C12.7524952,10.6275653 10.6208366,12.7578782 7.99932713,12.7578782 C5.37512616,12.7578782 3.24077605,10.6235281 3.24077605,7.99932713 C3.24077605,5.37512616 5.37512616,3.24077605 7.99932713,3.24077605 C8.34114613,3.2394303 8.61836941,2.96220702 8.61836941,2.62038802 C8.61836941,2.27856903 8.34114613,2 7.99932713,2 L7.99798138,2 L7.99663564,2 C4.69014242,2 2,4.69148817 2,7.99932713 C2,11.3085118 4.69148817,14 8.00067287,14 C11.3098576,14 14,11.3071661 13.9986543,7.99932713 Z" id="LoadingIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Loading">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="LoadingIcon-mask-2" fill="white">
                            <use href="#LoadingIcon-path-1"></use>
                        </mask>
                        <use id="Path" fill={color} href="#LoadingIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
