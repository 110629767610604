
import React, { useEffect } from 'react'

import { isWeChat } from 'bwax/clientEnv';

import './BackgroundVideo.less'


export default function BackgroundVideo(props) {
    const { data } = props;
    const { posterUrl, backgroundVideoSrc } = data

    useEffect(() => {
        if (typeof (wx) !== "undefined" && isWeChat()) {
            wx.ready(()=> {
                let video = document.getElementById("bg-video");
                video.play();
            });
        }
       
    }, [])

    return (
        <video id='bg-video' autoPlay muted loop webkit-playsinline="true"
            className="background-video"
            x-webkit-airplay="true"  
            playsInline={true}
            x5-video-player-type="h5" 
            x5-video-orientation="h5" 
            x5-video-player-fullscreen="true" 
            preload="auto"
            src={backgroundVideoSrc}
            // poster={posterUrl}
        >
            {/* <source src={backgroundVideoSrc} type="video/mp4"></source>
            <source src={backgroundVideoSrc} type="video/ogg"></source>
            <source src={backgroundVideoSrc} type="video/webm"></source> */}
            <p className="vjs-no-js">
                观看该视频请先允许下载脚本JavaScript，然后保证你的浏览器
                <a href="http://videojs.com/html5-video-support/" target="_blank">
                    支持html5视频
                </a>
            </p>
        </video>
    )
}