const defaultOptions = {
  loop: false,
  spacing: 0.05,
  maxSpreadZoom: 1.5,
  closeOnScroll: false,
  history: false, // 避免 URL 改动引起页面刷新

  //Buttons/elements
  arrowEl: false,
  shareEl: false,
  fullscreenEl: false,
  getDoubleTapZoom: function(isMouseClick, item) {
    if(window && window.devicePixelRatio && window.devicePixelRatio > 1) {
      if(item.initialZoomLevel < 1) {
        return 1 / window.devicePixelRatio
      }
    }
    if(isMouseClick) {
      return 1
    } else {
      return item.initialZoomLevel < 0.7 ? 1 : 1.2
    }
  }
}

export { defaultOptions }
