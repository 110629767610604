

import React, { useEffect, useRef } from 'react'

import Avatar from 'bwax-ui/components/Avatar';
import ClampedText from 'bwax-ui/components/ClampedText';

import ContextMenu from 'bwax-ui/components/ContextMenu';
import DropdownMenu from 'bwax-ui/components/DropdownMenuLegacy';

import { PlusIcon, DotsVerticalIcon } from '@radix-ui/react-icons'

import { createDialogForm } from 'bwax-ui/components/Dialog';

import ScrollHelper from 'bwax-ui/ScrollHelper';

import { toast } from 'bwax-ui/components/Toast';
import { Pressable } from 'bwax-ui/components/Button';

import PopoverTrigger from 'bwax-ui/components/PopoverTrigger';

import { useTrack } from 'bwax-ui/track';

import { MdOutlinePersonOutline } from "react-icons/md";


import "./ChatSessionList.less";

export default function ChatSessionList({
    usedInDrawer,
    sessions, newSession,
    currentSessionId, closeSessionListDrawer, changeSession,
    archiveSession, unArchiveSession, updateSessionTitle,

    // for 
    toggleMenuCollapsed = _ => { },

    loadMore,
    isLoadingMore,

    menuCollapsed = false,

    additionalPanel,

    renderPersonaList,


}) {
    const className = usedInDrawer ? (
        "narrow-only used-in-drawer"
    ) : "wide-only";

    const track = useTrack();

    const sessionListRef = useRef();
    const loadMoreRef = useRef();
    loadMoreRef.current = loadMore;

    useEffect(() => {
        if (sessionListRef.current) {

            const scrollHelper = new ScrollHelper(sessionListRef.current, {
                onScrolledToBottom: _ => {
                    loadMoreRef.current && loadMoreRef.current();
                }
            });
            return () => {
                scrollHelper.cleanUp();
            }
        }

    }, [sessionListRef.current])


    return (
        <div className={"lc-session-list-container" + (className ? " " + className : "")}>
            <div className="flex gap-1 pl-3 pr-3">
                {usedInDrawer || menuCollapsed ? null : (
                    <Pressable onPress={_ => {
                        track("chat_collapse_session_list")
                        toggleMenuCollapsed(true)
                    }}>
                        <div className="py-0.5 px-1.5 font-size-20 text-[var(--gray11)] hover:bg-[var(--gray3)] bg-[var(--gray2)] rounded cursor-pointer flex items-center">
                            <i className='bx bx-chevrons-left'></i>
                        </div>
                    </Pressable>
                )}
                <div className="new-session grow flex gap-2 pl-3 py-3 sm:py-2 px-2 items-center rounded" onClick={_ => {
                    if (usedInDrawer) {
                        closeSessionListDrawer()
                    }
                    newSession();
                }}>
                    <PlusIcon />
                    <ClampedText text={"新的会话"}></ClampedText>
                </div>
                {renderPersonaList ? (
                    <PopoverTrigger content={({close}) => renderPersonaList(close)} onTriggerPress={_ => {
                        track("chat_open_persona_list")
                    }}>
                        <div className="py-0.25 px-2.5 font-size-16 hover:bg-[var(--gray3)] bg-[var(--gray2)] rounded cursor-pointer flex items-center">
                            <MdOutlinePersonOutline />
                        </div>
                    </PopoverTrigger>
                ) : null}
            </div>
            {additionalPanel}
            <div className="session-list" ref={sessionListRef}>
                {
                    sessions.map(s => {
                        return (
                            <SessionTitle key={s.id} {...{
                                session: s, currentSessionId, usedInDrawer, closeSessionListDrawer, changeSession,
                                archiveSession, unArchiveSession, updateSessionTitle,
                            }} />
                        )
                    })
                }
                {isLoadingMore ? (
                    Array(!sessions || sessions.length === 0 ? 15 : 5).fill(0).map((_, index) => {
                        return (
                            <div className="flex gap-3 py-3 pr-6 pl-4 mx-2" key={index}>
                                <div className="h-[16px] w-[16px] bg-[var(--gray4)] rounded"></div>
                                <div className="h-[16px] grow bg-[var(--gray4)] rounded"></div>
                            </div>
                        )
                    })
                ) : null}
            </div>
        </div>
    )
}


// 
function SessionTitle({
    session: s, currentSessionId, usedInDrawer,
    changeSession, closeSessionListDrawer,
    archiveSession, unArchiveSession, updateSessionTitle,

}) {

    const items = [{
        label: "移除",
        icon: <i className='bx bx-trash'></i>,
        onSelect: async _ => {
            const [result, error] = await archiveSession(s.id);
            if (!error) {
                toast({ title: "会话已移入回收站", undoAction: async _ => { unArchiveSession(s.id) }, duration: 3000 });
            }

        }
    }, {
        label: "重命名", onSelect: _ => {
            // 
            createDialogForm({
                title: !s.title ? "设置会话标题" : "修改会话的标题",
                description: "",
                fields: [{
                    name: "title",
                    initialValue: s.title,
                }],
                showLabel: false,
                onDataCollected: async data => {
                    // console.log("collected data", data);
                    if (data.title !== undefined) {
                        const originalTitle = s.title;
                        const [result, error] = await updateSessionTitle(s.id, data.title);
                        if (!error) {
                            toast({
                                title: "会话标题已修改",
                                undoAction: async _ => { updateSessionTitle(s.id, originalTitle) },
                                duration: 3000
                            });
                        }
                    }

                }
            })

        },
        icon: <i className='bx bx-edit' ></i>
    }]

    return (
        <ContextMenu items={items} withPortal={!usedInDrawer}>
            <div className={"session-title" + (currentSessionId == s.id ? " active" : "")} onClick={e => {

                if (usedInDrawer) {
                    closeSessionListDrawer();
                    setTimeout(() => {
                        changeSession(s.id);
                    }, 200)
                } else {
                    changeSession(s.id)
                }

            }}>
                <div className="icon-and-title">
                    {/* <ChatBubbleIcon /> */}
                    {s.persona ?
                        (
                            <Avatar avatar={s.persona.头像} nickName={s.persona.名称} size={20} />
                        ) :
                        <i className='bx bx-message font-size-15 ml-0.5 mr-0.5'></i>
                    }

                    <ClampedText text={s.title || s.firstMessage} tipEnabled={false} />
                </div>
                <div className="op-icons" onClick={e => {
                    e.stopPropagation();
                }}>
                    <DropdownMenu items={items} withPortal={!usedInDrawer}>
                        <DotsVerticalIcon />
                    </DropdownMenu>
                </div>

            </div>
        </ContextMenu>
    )
}
