
// This file is generated. Don't modify it.
import React from 'react'
export default function LikeWireFrameIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M5.99526035,5.32672837 L5.99526035,12.5334897 L10.822842,12.5334897 C11.7626816,12.5334897 11.8386763,12.4869623 12.0602278,11.532751 L12.9517345,7.53634179 L12.957602,7.51153149 C13.1445782,6.76362704 12.6948894,6.09684718 12.1447312,6.11028868 L8.22107649,6.11028868 L8.94468327,4.66307509 C9.10584347,4.34075468 9.04975361,3.95213203 8.82522814,3.72627176 C8.63914368,3.55569435 8.41812225,3.46651027 8.20980512,3.46651026 C7.9742469,3.46651025 7.78082403,3.54116469 7.62576432,3.6962244 L5.99526035,5.32672837 Z M4.99526035,6.11028868 L3.32188921,6.11028868 C3.11489269,6.11028868 3,6.27198951 3,6.58588595 L3,12.4267918 C3,12.4893392 3.0441505,12.5334897 3.10669795,12.5334897 L4.99526035,12.5334897 L4.99526035,6.11028868 Z M10.0052119,4.66307509 C9.97606763,4.81935058 9.92070048,4.96842178 9.83911047,5.11028868 C10.2376236,5.11028868 10.5952209,5.11028868 10.9119025,5.11028868 L12.1447312,5.11028868 C13.3743956,5.07954708 14.2659023,6.40143629 13.9277445,7.75406712 L13.0362379,11.7504764 C12.636597,13.4720065 11.8373151,13.5334897 10.822842,13.5334897 L3.10669795,13.5334897 C2.49186575,13.5334897 2,13.041624 2,12.4267918 L2,6.58588595 C2,5.97105375 2.33815771,5.11028868 3.32188921,5.11028868 L4.79748648,5.11028868 L6.91865754,2.98911761 C7.28755686,2.6202183 7.748681,2.46651025 8.20980515,2.46651026 C8.67092929,2.46651027 9.13205344,2.65095992 9.50095275,2.98911761 C9.81780418,3.30596904 9.98991263,3.7042384 10.0270929,4.11031443 C10.0416499,4.32254605 10.0343562,4.5067996 10.0052119,4.66307509 Z" id="LikeWireFrameIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Wireframe/Like">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="LikeWireFrameIcon-mask-2" fill="white">
                            <use href="#LikeWireFrameIcon-path-1"></use>
                        </mask>
                        <use id="Combined-Shape" fill={color} fillRule="nonzero" href="#LikeWireFrameIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
