
// This file is generated. Don't modify it.
import React from 'react'
export default function OnIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1.75em" height="1em" viewBox="0 0 28 16">
            <defs>
                <rect id="OnIcon-path-1" x="2" y="2" width="24" height="12" rx="6"></rect>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/On">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="27" height="15"></rect>
                        <mask id="OnIcon-mask-2" fill="white">
                            <use href="#OnIcon-path-1"></use>
                        </mask>
                        <rect stroke={color} x="2.5" y="2.5" width="23" height="11" rx="5.5"></rect>
                        <g id="Color/Primary" mask="url(#OnIcon-mask-2)" fill={color}>
                            <g transform="translate(2.000000, 2.000000)" id="Primary">
                                <rect x="0" y="0" width="24" height="12"></rect>
                            </g>
                        </g>
                        <circle id="Oval-Copy" fill="#FFFFFF" mask="url(#OnIcon-mask-2)" cx="20" cy="8" r="5"></circle>
                    </g>
                </g>
            </g>
        </svg>
    )
}
