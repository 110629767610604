

import React, { useEffect, useState } from 'react'

import sha1 from 'crypto-js/sha1';
import hex from 'crypto-js/enc-hex';

import { Pressable } from 'bwax-ui/components/Button';

import { copy } from "bwax-ui/ml/FrontEndHelper";
import { toast } from 'bwax-ui/components/Toast';

// 这个 entity 依赖：“推荐码”，“会员赠送设置"
export default function ReferralCodePage(props) {

    const { data, events, slots, facade, viewEnv } = props;
    
    const { tenantCode } = viewEnv.domainEnv;
    const { currentUserId } = data;

    // 进入这个页面自动生成推荐码

    const [ promotion, setPromotion ] = useState();
    const [ referralCode, setReferralCode ] = useState();

    async function loadPromption () {
        const [ result, error ] = await facade.findOne({ entityName : "会员赠送设置", fieldPaths: ["推荐用户赠送天数", "新用户赠送天数"]});
        if(!error){
            setPromotion(result)
        }
    }

    async function loadReferralCode() {
        const [ result, error ] = await facade.findOne({ 
            entityName: "推荐码", condition: [[{ field: "用户.id", op: "eq", value: currentUserId }]],
            fieldPaths: ["内容"]
        });
        if(!error) {
            setReferralCode(result);

            if(result == null) {
                // 使用 tenantCode + currentUserId 进行 sha1 得到 hex 格式
                const fullCode = sha1(tenantCode + ":" + currentUserId).toString(hex);
                
                const [ result, error ] = await facade.customMutation({
                    entityName: "推荐码",
                    interfaceName: "生成",
                    args: [ fullCode ],
                    outputFieldPaths: [
                        ["推荐码", ["内容"]]
                    ]
                });
                if(!error) {
                    setReferralCode(result);
                }
            }
        }
    }

    useEffect(() => {
        loadPromption();
        loadReferralCode();
    }, []);

    function copyReferalCode() {
        if(!referralCode) {
            return
        }
        copy(referralCode.内容)
        toast({ title: "已复制推荐码", duration: 2000 });
    }

    return (
        <div className="px-2 py-4 flex flex-col gap-3">
            <i className='bx bx-share-alt text-6xl opacity-40'></i>
            { referralCode && promotion ? 
                (
                    <div className="py-2 gap-5 flex flex-col">
                        <Pressable onPress={copyReferalCode}>
                            <div className="text-4xl text-[var(--gray10)] self-start cursor-pointer">{ referralCode.内容 }</div>
                        </Pressable>
                        <div className="font-medium font-size-16 flex items-center">
                            <span>快把你的推荐码分享给朋友</span>
                            <Pressable onPress={copyReferalCode}>
                                <div className="px-2 py-1 cursor-pointer">
                                    <i className='bx bx-copy-alt'></i>
                                </div>                                
                            </Pressable>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div>
                                每个使用推荐码注册的新用户，将获得 <span className="m-1 font-medium font-size-16">{promotion.新用户赠送天数}</span> 天的会员权益
                            </div>
                            <div>
                                同时，你也将获得额外的
                                <span className="m-1 font-medium font-size-16">{promotion.推荐用户赠送天数}</span>
                                天会员权益；如果你已经是高级会员，那么你的高级会员也会相应延长
                                <span className="m-1 font-medium font-size-16">{promotion.推荐用户赠送天数}</span>天
                            </div>
                        </div>
                    </div>
                )            
                : null
            }
        </div>
    )
}
