
import React from 'react'

import { Helmet } from 'react-helmet-async'

import ClipLoader from "react-spinners/ClipLoader";

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: undefined, envKey: props.envKey };
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        this.setState({ error: error });
        // You can also log the error to an error reporting service
        // console.log(error, info);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.envKey && nextProps.envKey !== prevState.envKey) {
            return {
                error: undefined, envKey: nextProps.envKey 
            }
        } else {
            // KEY doesn't change
            return prevState
        }
    }


    render() {

        // 暂时不显示 ERROR：
        if (this.state.error) {
            // You can render any custom fallback UI
            let error = this.state.error;

            function printError() {
                if (error.stack) {
                    console.error(error);
                    return error.stack;
                } else if (Array.isArray(error)) {
                    // bucklescript error
                    const [_, msg] = error;
                    return msg
                } else {
                    return error;
                }
            }

            // https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1129
            // 暂时改成 Loading
            // return (
            //     <>
            //         <Helmet>
            //             <meta name="viewport" content="width=device-width,initial-scale=1" />
            //         </Helmet>
            //         <div style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            //             <ClipLoader color="#8C8D8F" />
            //         </div>
            //     </>
            // )


            return (
                <>
                    <Helmet>
                        <meta name="viewport" content="width=device-width,initial-scale=1" />
                    </Helmet>
                    <div style={{ padding: 16, width: "100%" }}>
                        <h4>页面出了点问题，请联系管理员或者稍后刷新页面</h4>
                        <div style={{ padding: "16px 0", width: "100%", fontSize: 12 }}>
                            { (typeof(document) !== 'undefined') ?
                                document.location.hostname + document.location.pathname : 
                                "-"
                            }
                        </div>
                        <div style={{ padding: "8px 0", width: "100%", fontSize: 12 }}>
                            {/* <pre style={{ width: "100%", overflow: "auto" }}> */}
                                {printError()}
                            {/* </pre> */}
                        </div>
                    </div>
                </>
            );
        }
        return this.props.children;
    }
}