
import throttle from "lodash/throttle";

export default class WindowScrollHelper {

    // options includes: 
    // { topThreshold, bottomThreshold, onScrolledToTop, onScrolledToBottom }
    constructor(options) {
        // add a listener 
        this.lastScrollStatus = undefined;
        this.options = options;

        this.__throttledScrollHandler = throttle(this.__handleWindowScroll, 32);
        if (typeof (window) !== 'undefined') {
            window.addEventListener('scroll', this.__throttledScrollHandler)
        }
    }

    cleanUp() {
        if (typeof (window) !== 'undefined') {
            window.removeEventListener('scroll', this.__throttledScrollHandler)
        }
    }

    __handleWindowScroll = () => {
        // TODO need throttle
        const lastScrollStatus = this.lastScrollStatus;
        const scrollStatus = getWindowScrollStatus(lastScrollStatus);
        const { 
            topThreshold = 64, bottomThreshold = 64,
            onScrolledToTop = _ => {
                // console.log("Scolled to top")
            },
            onScrolledToBottom = _ => {
                // console.log("Scrolled to bottom")
            },
            onScrolled = _ => {},
        } = this.options;

        const isScrolledToTop = status => {
            return (status.y < topThreshold && status.direction === "up");
        }
        

        const isScrolledToBottom = status => {
            const { y, scrollHeight, clientHeight, direction} = status;
            return direction === "down" && (
                scrollHeight - (y + clientHeight) < bottomThreshold
            )
        }

        if(isScrolledToTop(scrollStatus) && !isScrolledToTop(lastScrollStatus)) {
            onScrolledToTop();
        }

        if(isScrolledToBottom(scrollStatus) && !isScrolledToBottom(lastScrollStatus)) {
            onScrolledToBottom();
        }


        onScrolled && onScrolled(scrollStatus);


        this.lastScrollStatus = scrollStatus;

    }

}


export function getWindowScrollStatus(lastScrollStatus) {

    if (typeof document !== undefined && typeof window !== undefined) {
        const doc = document.documentElement
        const left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0)
        const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
        //console.log('lastScrollStatus : ', lastScrollStatus, ', top : ', top);

        // console.log("D", doc.scrollTop, doc.clientTop, doc.clientHeight, doc.scrollHeight);

        const direction = lastScrollStatus && typeof lastScrollStatus.y === 'number' ?
            top > lastScrollStatus.y ? 'down' :
                top < lastScrollStatus.y ? 'up' :
                    lastScrollStatus.direction : null

        return { 
            x: left, y: top, direction, 
            scrollHeight: doc.scrollHeight, clientHeight: doc.clientHeight,
        }
    }
    return {  }
}

export function scrollToNode (nodeId, marginTop) {
    if (typeof (window) !== 'undefined') {

        const retryLimit = 5;
        let retryCount = 0;
        const delay = 500;
        // 
        function doScroll () {
            const node = document.querySelector(`#${nodeId}`)                          
            if(node) {
                // console.log(">>", node, node.offsetTop, marginTop, node.offsetTop - marginTop)
                setTimeout(() => {
                    // 加 setTimeout 是为了解决 select 选择第二页不跳转的问题。
                    window.scrollTo(0, node.offsetTop - marginTop)
                }, 2);
            } else {

                // 500ms 后再试
                if(retryCount <= retryLimit) {
                    // 结束
                    retryCount += 1;

                    setTimeout(() => {
                        doScroll()
                    }, delay)
                } 
            }
        }

        doScroll();

    }
}