
// This file is generated. Don't modify it.
import React from 'react'
export default function ReportingIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M8.53594771,9.53594771 L8.53594771,7.33986928 L7.46405229,7.33986928 L7.46405229,9.53594771 L8.53594771,9.53594771 Z M8.53594771,11.7058824 L8.53594771,10.6078431 L7.46405229,10.6078431 L7.46405229,11.7058824 L8.53594771,11.7058824 Z M2,13.3398693 L8,3 L14,13.3398693 L2,13.3398693 Z" id="ReportingIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Filling/Reporting">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="ReportingIcon-mask-2" fill="white">
                            <use href="#ReportingIcon-path-1"></use>
                        </mask>
                        <use id="report_problem---material" fill={color} href="#ReportingIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
