
import React, { useEffect, useState, useRef } from 'react'

import useStateWithLocalCache from 'bwax-ui/hooks/useStateWithLocalCache';
import RichTextRenderer from 'bwax-ui/auxiliary/richtext_slate/RichTextRenderer'
import useDebounce from 'bwax-ui/legacy/page/hooks/useDebounce';

import { markdownToRichText } from 'bwax-ui/markdown/convertMarkdown';


import convertSlateToMarkdown from '../auxiliary/richtext_slate/editor/convertSlateToMarkdown';

import SlateEditor from 'bwax-ui/auxiliary/richtext_slate/editor/SlateEditor';
import buttonComponents from 'bwax-ui/auxiliary/richtext_slate/editor/buttons';



export default function TestMarkdown() {

    const [text, setText] = useStateWithLocalCache("test-markdown", "");

    const [value, setValue] = useState([]);

    const [outputMd, setOutputMd ] = useState("");

    const debouncedText = useDebounce(text, 700);

    useEffect(() => {
        const value = markdownToRichText(debouncedText);

        setValue(value);

        const outputMd = convertSlateToMarkdown(value);
        setOutputMd(outputMd);

    }, [debouncedText]);


    const containerRef = useRef();

    async function doUpload(file, done) {
        const result = await UploadFile({ queryRunner })({
            file,
            isPublic: true,
            uploadFor: "test-richeditor"
        })
    
        if (!result) {
            /// TODO error handling
            message.error("图片上传出错")
        } else {
            done(null, result.url)
        }
    }
    
    async function uploadVideo(file, onUploadProgress, cancelRequest, done, onError) {
        // console.log("uploadVideo file >>> ", file)
        try {
            const result = await UploadFile({ queryRunner })({
                file,
                isPublic: true,
                uploadFor: "test-richeditor",
                onUploadProgress,
                cancelRequest
            })
            if (!result) {
                //TODO: backend error handling
                message.error("视频上传出错")
            } else {
                done(null, result.url)
            }
        } catch (error) {
            console.log("error >>> ", error)
            onError && onError(error)
        }
    }





    return (
        <div className="w-full h-full flex font-size-14">
            <div className="w-1/2 whitespace-pre-line overflow-auto">
                <textarea className="w-full h-full px-4 py-4 bg-[var(--gray2)]" value={text} onChange={e => setText(e.target.value)}>
                </textarea>
            </div>
            <div className="w-1/2 px-4 py-4 overflow-auto">
                <RichTextRenderer value={value} version={2} />
            </div>
            {/* <div className="w-1/3 px-4 py-4 overflow-auto" ref={containerRef}>
                {value ? <SlateEditor value={value} uploadImage={doUpload}
                    uploadVideo={uploadVideo} onChange={setValue}
                    // aiEnabled={true}
                    noToolbar={true}
                    buttonComponents={buttonComponents}
                    placeholder={'请输入正文'}
                    scrollContainerRef={containerRef}
                    version={2}
                />: null }
            </div> */}
            {/* <div className="w-1/4 px-4 py-4 overflow-auto">
                <div className="w-full whitespace-pre-line overflow-auto">
                    { outputMd }
                </div>
            </div> */}
        </div>
    )
}
