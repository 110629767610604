
import React from 'react'

import createBlockTypeButton from './util/createBlockTypeButton';

import { MdFormatQuote } from 'react-icons/md'

export default createBlockTypeButton({
    blockType: 'block-quote',
    children: (
        <MdFormatQuote style={{ fontSize: 14 }}/>
    ),
});
