
import { useState, useEffect } from 'react'

import throttle from 'lodash/throttle'


export function getViewportWidth() {
    if(typeof(document) === 'undefined') {
        return 0 // TODO 在 server-render 时应该默认多少？
    }
    const viewportWidth = Math.max(
        document.documentElement.clientWidth,
        window.innerWidth || 0
    )
    return viewportWidth
}

export default function useViewportWidth() {

    /// states starts  ======
    const [ viewportWidth, setViewportWidth ] = useState(getViewportWidth())
    /// states ends =========

    useEffect(() => {
        const handleResizeEvent = () => {
            setViewportWidth(getViewportWidth())
        }
        handleResizeEvent()

        const throttled = throttle(handleResizeEvent, 1000)

        window.addEventListener('resize', throttled)
        return () => {
            window.removeEventListener('resize', throttled)
        }
    }, [])

    return viewportWidth
}