// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json$BwaxMobile = require("../utils/json.bs.js");
var Plate$BwaxMobile = require("../plate.bs.js");
var Lang_eval$BwaxMobile = require("../lang/lang_eval.bs.js");
var Query_env$BwaxMobile = require("./query_env.bs.js");
var Lang_mod_json$BwaxMobile = require("../lang/mod/builtin/lang_mod_json.bs.js");
var Query_builder$BwaxMobile = require("./query_builder.bs.js");
var Query_commands$BwaxMobile = require("./query_commands.bs.js");
var Base_query_types$BwaxMobile = require("../base_query_types.bs.js");

function make_query_config(value) {
  var to_param_list = function (params) {
    var match = params[0];
    if (typeof match === "number") {
      if (match === /* V_unit */0) {
        return /* [] */0;
      } else {
        return /* :: */[
                params,
                /* [] */0
              ];
      }
    } else if (match.tag) {
      return /* :: */[
              params,
              /* [] */0
            ];
    } else {
      return match[0];
    }
  };
  var match = value[0];
  if (typeof match === "number") {
    throw [
          Plate$BwaxMobile.Unexpected,
          "Not a query config"
        ];
  } else if (match.tag === /* V_tagged */6) {
    if (match[0] === "__QueryConfig__") {
      var match$1 = match[1];
      if (match$1) {
        var match$2 = match$1[0][0];
        if (typeof match$2 === "number") {
          throw [
                Plate$BwaxMobile.Unexpected,
                "Not a query config"
              ];
        } else if (match$2.tag === /* V_literal */5) {
          var match$3 = match$2[0];
          if (typeof match$3 === "number") {
            throw [
                  Plate$BwaxMobile.Unexpected,
                  "Not a query config"
                ];
          } else if (match$3.tag === /* String */2) {
            var match$4 = match$1[1];
            if (match$4) {
              var params = match$4[0];
              var match$5 = params[0];
              var entity_name = match$3[0];
              if (match$4[1]) {
                if (typeof match$5 === "number") {
                  throw [
                        Plate$BwaxMobile.Unexpected,
                        "Not a query config"
                      ];
                } else if (match$5.tag === /* V_literal */5) {
                  var match$6 = match$5[0];
                  if (typeof match$6 === "number") {
                    throw [
                          Plate$BwaxMobile.Unexpected,
                          "Not a query config"
                        ];
                  } else if (match$6.tag === /* String */2) {
                    var match$7 = match$4[1];
                    var match$8 = match$7[1];
                    var params$1 = match$7[0];
                    var interface_name = match$6[0];
                    if (match$8) {
                      if (match$8[1]) {
                        throw [
                              Plate$BwaxMobile.Unexpected,
                              "Not a query config"
                            ];
                      }
                      return /* tuple */[
                              entity_name,
                              /* Custom_query */Block.__(1, [
                                  interface_name,
                                  to_param_list(params$1),
                                  Lang_eval$BwaxMobile.as_string(match$8[0])
                                ])
                            ];
                    } else {
                      return /* tuple */[
                              entity_name,
                              /* Custom_query */Block.__(1, [
                                  interface_name,
                                  to_param_list(params$1),
                                  undefined
                                ])
                            ];
                    }
                  } else {
                    throw [
                          Plate$BwaxMobile.Unexpected,
                          "Not a query config"
                        ];
                  }
                } else {
                  throw [
                        Plate$BwaxMobile.Unexpected,
                        "Not a query config"
                      ];
                }
              } else {
                var match$9 = Base_query_types$BwaxMobile.to_query_param(params);
                return /* tuple */[
                        entity_name,
                        /* Standard_query */Block.__(0, [
                            match$9[0],
                            match$9[1],
                            match$9[2]
                          ])
                      ];
              }
            } else {
              throw [
                    Plate$BwaxMobile.Unexpected,
                    "Not a query config"
                  ];
            }
          } else {
            throw [
                  Plate$BwaxMobile.Unexpected,
                  "Not a query config"
                ];
          }
        } else {
          throw [
                Plate$BwaxMobile.Unexpected,
                "Not a query config"
              ];
        }
      } else {
        throw [
              Plate$BwaxMobile.Unexpected,
              "Not a query config"
            ];
      }
    } else {
      throw [
            Plate$BwaxMobile.Unexpected,
            "Not a query config"
          ];
    }
  } else {
    throw [
          Plate$BwaxMobile.Unexpected,
          "Not a query config"
        ];
  }
}

function is_empty_condition_or_sorting(j) {
  var match = Js_json.classify(j);
  if (typeof match === "number") {
    if (match === /* JSONNull */2) {
      return true;
    } else {
      return false;
    }
  } else if (match.tag === /* JSONArray */3) {
    return match[0].length === 0;
  } else {
    return false;
  }
}

function to_raw_criteria_or_sorting(proc, mj) {
  return Plate$BwaxMobile.$$Option.and_then((function (j) {
                if (is_empty_condition_or_sorting(j)) {
                  return ;
                } else {
                  return Caml_option.some(Curry._1(proc, j));
                }
              }), mj);
}

function to_raw_criteria(mj) {
  return to_raw_criteria_or_sorting((function (j) {
                return /* C_raw */Block.__(2, [j]);
              }), mj);
}

function to_raw_sorting(mj) {
  return to_raw_criteria_or_sorting((function (j) {
                return /* S_raw */Block.__(1, [j]);
              }), mj);
}

function to_query_context(obj) {
  var entity_dict = obj.entity_dict;
  var data_type_dict = obj.data_type_dict;
  var query_runner = obj.queryRunner;
  var match = obj.queryCache;
  var cache;
  if (match !== undefined) {
    var c = Caml_option.valFromOption(match);
    cache = /* record */[
      /* getData */c.getData,
      /* setData */c.setData,
      /* setLoading */c.setLoading,
      /* removeData */c.removeData,
      /* clearCache */c.clearCache
    ];
  } else {
    cache = undefined;
  }
  return /* record */[
          /* entity_dict */entity_dict,
          /* data_type_dict */data_type_dict,
          /* query_runner */query_runner,
          /* cache */cache
        ];
}

var partial_arg = /* record */[
  /* keeping_native */false,
  /* nothing_to_null */true,
  /* processors : [] */0
];

function partial_arg$1(param) {
  return Lang_mod_json$BwaxMobile.any_value_to_json(partial_arg, param);
}

var partial_arg$2 = Plate$BwaxMobile.$$Option.and_then;

function to_js(param) {
  return partial_arg$2(partial_arg$1, param);
}

function is_custom_query(query_config) {
  if (query_config[1].tag) {
    return true;
  } else {
    return false;
  }
}

function interface_params_from_js(entity_dict, data_type_dict, input_types, js_values) {
  var original_args = Curry._1(Plate$BwaxMobile.List.from_array, js_values);
  var diff = Plate$BwaxMobile.List.length(input_types) - Plate$BwaxMobile.List.length(original_args) | 0;
  var args = diff > 0 ? Pervasives.$at(original_args, Plate$BwaxMobile.List.fill(diff, null)) : original_args;
  var pairs = Plate$BwaxMobile.List.combine(input_types, args);
  var params = Plate$BwaxMobile.List.keep_map((function (param) {
          return Query_builder$BwaxMobile.transform_interface_value(entity_dict, data_type_dict, param[0], undefined, Query_builder$BwaxMobile.default_transform_option, param[1]);
        }), pairs);
  if (Plate$BwaxMobile.List.length(params) > 1) {
    return /* :: */[
            /* tuple */[
              /* V_tuple */Block.__(0, [params]),
              /* No_term */0
            ],
            /* [] */0
          ];
  } else {
    return params;
  }
}

function get_output_selections(entity_dict, output_types, outputSelections) {
  var given_output_selections = Plate$BwaxMobile.List.map((function (param) {
          if (param !== undefined) {
            var match = param;
            var entity = Base_query_types$BwaxMobile.get_entity(entity_dict, match[0]);
            return /* tuple */[
                    entity,
                    /* Raw_selection */Block.__(1, [match[1]])
                  ];
          }
          
        }), Curry._1(Plate$BwaxMobile.List.from_array, outputSelections));
  if (Plate$BwaxMobile.List.length(given_output_selections) < Plate$BwaxMobile.List.length(output_types)) {
    var diff = Plate$BwaxMobile.List.length(output_types) - Plate$BwaxMobile.List.length(given_output_selections) | 0;
    return Pervasives.$at(given_output_selections, Plate$BwaxMobile.List.fill(diff, undefined));
  } else {
    return given_output_selections;
  }
}

function to_standard_query(entity_dict, data_type_dict, queryObj) {
  var match = queryObj.query_config;
  var match$1;
  if (match !== undefined) {
    var match$2 = match;
    var match$3 = match$2[1];
    if (match$3.tag) {
      throw [
            Plate$BwaxMobile.Unexpected,
            "This is not a standard query"
          ];
    } else {
      match$1 = /* tuple */[
        match$2[0],
        match$3[0],
        match$3[1],
        match$3[2]
      ];
    }
  } else {
    match$1 = /* tuple */[
      queryObj.entityName,
      undefined,
      undefined,
      undefined
    ];
  }
  var base_msc = match$1[3];
  var base_mc = match$1[1];
  var entity = Base_query_types$BwaxMobile.get_entity(entity_dict, match$1[0]);
  var mj = queryObj.condition;
  var additional_mc = to_raw_criteria_or_sorting((function (j) {
          return /* C_raw */Block.__(2, [j]);
        }), mj);
  var mj$1 = queryObj.sort;
  var additional_ms = to_raw_criteria_or_sorting((function (j) {
          return /* S_raw */Block.__(1, [j]);
        }), mj$1);
  var given_msc = Plate$BwaxMobile.$$Option.and_then((function (j) {
          var keyword = Json$BwaxMobile.get_value("keyword", Js_json.decodeString, j);
          return Plate$BwaxMobile.$$Option.map((function (k) {
                        var fields = Plate$BwaxMobile.List.keep_map((function (o) {
                                var field = Json$BwaxMobile.get_value("field", Js_json.decodeString, o);
                                var weight = Json$BwaxMobile.get_value("weight", Js_json.decodeNumber, o);
                                if (field !== undefined && weight !== undefined) {
                                  return /* tuple */[
                                          field,
                                          weight
                                        ];
                                }
                                
                              }), Plate$BwaxMobile.$$Option.with_default(/* [] */0, Plate$BwaxMobile.$$Option.map(Plate$BwaxMobile.List.from_array, Json$BwaxMobile.get_value("fields", Js_json.decodeArray, j))));
                        return /* tuple */[
                                k,
                                fields
                              ];
                      }), keyword);
        }), queryObj.search);
  var final_msc = Plate$BwaxMobile.$$Option.otherwise((function (param) {
          return base_msc;
        }), given_msc);
  var merged_criteria;
  if (base_mc !== undefined) {
    var c = base_mc;
    merged_criteria = additional_mc !== undefined ? Base_query_types$BwaxMobile.and_criteria(c, additional_mc) : c;
  } else {
    merged_criteria = additional_mc !== undefined ? additional_mc : undefined;
  }
  var merged_sorting = Base_query_types$BwaxMobile.join_sorting(additional_ms, match$1[2]);
  var match$4 = queryObj.selection;
  var selection = match$4 !== undefined ? /* Raw_selection */Block.__(1, [match$4]) : /* No_selection */0;
  var get_pagination = function (param) {
    return /* record */[
            /* page_size */Plate$BwaxMobile.$$Option.with_default(20, queryObj.pageSize),
            /* offset */queryObj.offset
          ];
  };
  var match$5 = queryObj.queryType;
  switch (match$5) {
    case "count" :
        return /* Q_count */Block.__(3, [
                  entity,
                  /* tuple */[
                    merged_criteria,
                    final_msc
                  ]
                ]);
    case "findOne" :
        return /* Q_find_one */Block.__(0, [
                  entity,
                  /* tuple */[
                    merged_criteria,
                    merged_sorting,
                    final_msc
                  ],
                  selection
                ]);
    case "list" :
        return /* Q_list */Block.__(1, [
                  entity,
                  /* tuple */[
                    merged_criteria,
                    merged_sorting,
                    final_msc,
                    get_pagination(/* () */0)
                  ],
                  selection
                ]);
    case "listAll" :
        return /* Q_listAll */Block.__(2, [
                  entity,
                  /* tuple */[
                    merged_criteria,
                    merged_sorting,
                    final_msc,
                    get_pagination(/* () */0)
                  ],
                  selection
                ]);
    default:
      throw [
            Plate$BwaxMobile.Unexpected,
            "Not a standard query"
          ];
  }
}

function to_custom_query(entity_dict, data_type_dict, queryObj) {
  var match = queryObj.query_config;
  if (match !== undefined) {
    var match$1 = match;
    var match$2 = match$1[1];
    if (match$2.tag) {
      var maybe_id = match$2[2];
      var params = match$2[1];
      var interface_name = match$2[0];
      var entity = Base_query_types$BwaxMobile.get_entity(entity_dict, match$1[0]);
      var match$3 = Plate$BwaxMobile.List.find((function (i) {
              return i[/* name */0] === interface_name;
            }), entity[/* interfaces */4]);
      var $$interface;
      if (match$3 !== undefined) {
        $$interface = match$3;
      } else {
        throw [
              Plate$BwaxMobile.Unexpected,
              "I cannot find interface " + (interface_name + (" in entity " + entity[/* name */0]))
            ];
      }
      var output_types = $$interface[/* output_types */4];
      var input_types = $$interface[/* input_types */3];
      var pagination_input_type = Plate$BwaxMobile.List.find(Query_env$BwaxMobile.is_pagination_input, input_types);
      var input_params;
      if (pagination_input_type !== undefined) {
        var pagination_input_index = Plate$BwaxMobile.List.index_of(Query_env$BwaxMobile.is_pagination_input, input_types);
        var json = {
          pageSize: Plate$BwaxMobile.$$Option.with_default(20, queryObj.pageSize),
          offset: queryObj.offset
        };
        var mv = Query_builder$BwaxMobile.transform_interface_value(entity_dict, data_type_dict, pagination_input_type, undefined, Query_builder$BwaxMobile.default_transform_option, json);
        if (mv !== undefined) {
          input_params = Plate$BwaxMobile.List.stuff_in_at(pagination_input_index, mv, params);
        } else {
          throw [
                Plate$BwaxMobile.Unexpected,
                "Cannot transform interface value"
              ];
        }
      } else {
        input_params = params;
      }
      var inputs = Query_commands$BwaxMobile.to_interface_inputs(entity_dict, data_type_dict, input_types, input_params);
      var output_selections = get_output_selections(entity_dict, output_types, queryObj.outputSelections);
      var outputs = Plate$BwaxMobile.List.combine(output_types, output_selections);
      return /* Q_custom_query */Block.__(6, [
                $$interface,
                Plate$BwaxMobile.$$Option.otherwise((function (param) {
                        return Plate$BwaxMobile.$$Option.map((function (prim) {
                                      return prim;
                                    }), maybe_id);
                      }), queryObj.id),
                inputs,
                outputs
              ]);
    } else {
      throw [
            Plate$BwaxMobile.Unexpected,
            "This is not a custom query"
          ];
    }
  } else {
    var entity_name = queryObj.entityName;
    var entity$1 = Base_query_types$BwaxMobile.get_entity(entity_dict, entity_name);
    var interfaceName = queryObj.interfaceName;
    var match$4 = Plate$BwaxMobile.List.find((function (i) {
            return i[/* name */0] === interfaceName;
          }), entity$1[/* interfaces */4]);
    var $$interface$1;
    if (match$4 !== undefined) {
      $$interface$1 = match$4;
    } else {
      throw [
            Plate$BwaxMobile.Unexpected,
            "I cannot find interface " + (interfaceName + (" in entity " + entity$1[/* name */0]))
          ];
    }
    var output_types$1 = $$interface$1[/* output_types */4];
    var input_types$1 = $$interface$1[/* input_types */3];
    var args = queryObj.args;
    var inputs$1 = Query_commands$BwaxMobile.to_interface_inputs(entity_dict, data_type_dict, input_types$1, interface_params_from_js(entity_dict, data_type_dict, input_types$1, args));
    var output_selections$1 = get_output_selections(entity_dict, output_types$1, queryObj.outputSelections);
    var outputs$1 = Plate$BwaxMobile.List.combine(output_types$1, output_selections$1);
    return /* Q_custom_query */Block.__(6, [
              $$interface$1,
              queryObj.id,
              inputs$1,
              outputs$1
            ]);
  }
}

function to_query(entity_dict, data_type_dict, queryObj) {
  var s = queryObj.queryType;
  switch (s) {
    case "aggregate" :
        var match = queryObj.aggregate_config;
        if (match !== undefined) {
          var match$1 = match;
          var match$2 = match$1[1];
          var entity = Base_query_types$BwaxMobile.get_entity(entity_dict, match$1[0]);
          return /* Q_aggregate */Block.__(4, [
                    entity,
                    /* tuple */[
                      match$2[0],
                      match$2[1],
                      match$2[2],
                      match$2[3],
                      match$2[4],
                      match$2[5],
                      match$2[6]
                    ]
                  ]);
        } else {
          var match$3 = queryObj.aggregate;
          if (match$3 !== undefined) {
            var entity_name = queryObj.entityName;
            var mj = queryObj.condition;
            var m_criteria = to_raw_criteria_or_sorting((function (j) {
                    return /* C_raw */Block.__(2, [j]);
                  }), mj);
            var mj$1 = queryObj.sort;
            var m_sort = to_raw_criteria_or_sorting((function (j) {
                    return /* S_raw */Block.__(1, [j]);
                  }), mj$1);
            var m_aggregate = Plate$BwaxMobile.$$Option.map(Base_query_types$BwaxMobile.js_to_aggregate_list, queryObj.aggregate);
            var m_group = Plate$BwaxMobile.$$Option.map(Base_query_types$BwaxMobile.js_to_grouping_list, queryObj.group);
            var m_limit = queryObj.limit;
            var m_offset = queryObj.offset;
            var entity$1 = Base_query_types$BwaxMobile.get_entity(entity_dict, entity_name);
            return /* Q_aggregate */Block.__(4, [
                      entity$1,
                      /* tuple */[
                        m_criteria,
                        m_group,
                        m_aggregate,
                        undefined,
                        m_sort,
                        m_limit,
                        m_offset
                      ]
                    ]);
          } else {
            throw [
                  Plate$BwaxMobile.Unexpected,
                  "No aggregate config"
                ];
          }
        }
    case "custom" :
        return to_custom_query(entity_dict, data_type_dict, queryObj);
    case "count" :
    case "findOne" :
    case "list" :
    case "listAll" :
        return to_standard_query(entity_dict, data_type_dict, queryObj);
    default:
      throw [
            Plate$BwaxMobile.Unexpected,
            "Not implemented query type: " + s
          ];
  }
}

function additional_query_params_from_js(js) {
  return Plate$BwaxMobile.List.keep_map((function (param) {
                var k = param[0];
                return Plate$BwaxMobile.$$Option.map((function (s) {
                              return /* tuple */[
                                      k,
                                      s
                                    ];
                            }), Js_json.decodeString(param[1]));
              }), Plate$BwaxMobile.$$Option.with_default(/* [] */0, Plate$BwaxMobile.$$Option.map(Plate$BwaxMobile.List.from_array, Plate$BwaxMobile.$$Option.map(Js_dict.entries, Plate$BwaxMobile.$$Option.and_then(Js_json.decodeObject, js)))));
}

function run_queries(context, queryObjs, queryOpts, cont) {
  var force_refreshing = Plate$BwaxMobile.$$Option.with_default(false, queryOpts.forceRefreshing);
  var process_select_value = Plate$BwaxMobile.$$Option.with_default(true, queryOpts.processSelectValue);
  var process_composite_data = Plate$BwaxMobile.$$Option.with_default(true, queryOpts.processCompositeData);
  var additional_query_params = additional_query_params_from_js(queryOpts.additionalQueryParams);
  var data_type_dict = context[/* data_type_dict */1];
  var entity_dict = context[/* entity_dict */0];
  var queries = Plate$BwaxMobile.List.map((function (param) {
          return to_query(entity_dict, data_type_dict, param);
        }), Curry._1(Plate$BwaxMobile.List.from_array, queryObjs));
  return Query_builder$BwaxMobile.resolve_and_run_queries(context, queries, /* record */[
              /* force_refreshing */force_refreshing,
              /* process_select_value */process_select_value,
              /* process_composite_data */process_composite_data,
              /* additional_query_params */additional_query_params
            ], (function (result) {
                if (result.tag) {
                  return Curry._2(cont, undefined, result[0]);
                } else {
                  var rs = Curry._1(Plate$BwaxMobile.List.to_array, Plate$BwaxMobile.List.map((function (param) {
                              return /* tuple */[
                                      to_js(param[0]),
                                      to_js(param[1])
                                    ];
                            }), result[0]));
                  return Curry._2(cont, rs, undefined);
                }
              }));
}

function run_add_mutation(context, entity_name, formData, selectionText, queryOpts, cont) {
  var data_type_dict = context[/* data_type_dict */1];
  var entity_dict = context[/* entity_dict */0];
  var entity = Base_query_types$BwaxMobile.get_entity(entity_dict, entity_name);
  var input_record = Query_builder$BwaxMobile.js_to_input_record(entity_dict, data_type_dict, entity_name, formData);
  var input = Query_commands$BwaxMobile.value_to_mutation_input(entity_dict, data_type_dict, input_record);
  var mut_002 = /* Raw_selection */Block.__(1, [selectionText]);
  var mut = /* M_add */Block.__(0, [
      entity,
      input,
      mut_002
    ]);
  var process_select_value = Plate$BwaxMobile.$$Option.with_default(true, queryOpts.processSelectValue);
  var process_composite_data = Plate$BwaxMobile.$$Option.with_default(true, queryOpts.processCompositeData);
  var additional_query_params = additional_query_params_from_js(queryOpts.additionalQueryParams);
  return Query_builder$BwaxMobile.resolve_and_run_mutation(context, mut, /* record */[
              /* force_refreshing */true,
              /* process_select_value */process_select_value,
              /* process_composite_data */process_composite_data,
              /* additional_query_params */additional_query_params
            ], (function (result) {
                if (result.tag) {
                  return Curry._2(cont, undefined, result[0]);
                } else {
                  var match = result[0];
                  return Curry._2(cont, /* tuple */[
                              to_js(match[0]),
                              to_js(match[1])
                            ], undefined);
                }
              }));
}

function run_update_mutation(context, entity_name, formData, id, selectionText, queryOpts, cont) {
  var data_type_dict = context[/* data_type_dict */1];
  var entity_dict = context[/* entity_dict */0];
  var entity = Base_query_types$BwaxMobile.get_entity(entity_dict, entity_name);
  var input_record = Query_builder$BwaxMobile.js_to_input_record(entity_dict, data_type_dict, entity_name, formData);
  var input = Query_commands$BwaxMobile.value_to_mutation_input(entity_dict, data_type_dict, input_record);
  var mut_001 = /* tuple */[
    input,
    id
  ];
  var mut_002 = /* Raw_selection */Block.__(1, [selectionText]);
  var mut = /* M_update */Block.__(1, [
      entity,
      mut_001,
      mut_002
    ]);
  var process_select_value = Plate$BwaxMobile.$$Option.with_default(true, queryOpts.processSelectValue);
  var process_composite_data = Plate$BwaxMobile.$$Option.with_default(true, queryOpts.processCompositeData);
  var additional_query_params = additional_query_params_from_js(queryOpts.additionalQueryParams);
  return Query_builder$BwaxMobile.resolve_and_run_mutation(context, mut, /* record */[
              /* force_refreshing */true,
              /* process_select_value */process_select_value,
              /* process_composite_data */process_composite_data,
              /* additional_query_params */additional_query_params
            ], (function (result) {
                if (result.tag) {
                  return Curry._2(cont, undefined, result[0]);
                } else {
                  var match = result[0];
                  return Curry._2(cont, /* tuple */[
                              to_js(match[0]),
                              to_js(match[1])
                            ], undefined);
                }
              }));
}

function run_delete_mutation(context, entity_name, id, queryOpts, cont) {
  var entity = Base_query_types$BwaxMobile.get_entity(context[/* entity_dict */0], entity_name);
  var mut = /* M_delete */Block.__(2, [
      entity,
      id
    ]);
  var process_select_value = Plate$BwaxMobile.$$Option.with_default(true, queryOpts.processSelectValue);
  var process_composite_data = Plate$BwaxMobile.$$Option.with_default(true, queryOpts.processCompositeData);
  var additional_query_params = additional_query_params_from_js(queryOpts.additionalQueryParams);
  return Query_builder$BwaxMobile.resolve_and_run_mutation(context, mut, /* record */[
              /* force_refreshing */true,
              /* process_select_value */process_select_value,
              /* process_composite_data */process_composite_data,
              /* additional_query_params */additional_query_params
            ], (function (result) {
                if (result.tag) {
                  return Curry._2(cont, undefined, result[0]);
                } else {
                  var match = result[0];
                  return Curry._2(cont, /* tuple */[
                              to_js(match[0]),
                              to_js(match[1])
                            ], undefined);
                }
              }));
}

function run_custom_mutation(context, entity_name, interfaceName, maybeId, args, outputSelections, queryOpts, cont) {
  var data_type_dict = context[/* data_type_dict */1];
  var entity_dict = context[/* entity_dict */0];
  var entity = Base_query_types$BwaxMobile.get_entity(entity_dict, entity_name);
  var match = Plate$BwaxMobile.List.find((function (i) {
          return i[/* name */0] === interfaceName;
        }), entity[/* interfaces */4]);
  var $$interface;
  if (match !== undefined) {
    $$interface = match;
  } else {
    throw [
          Plate$BwaxMobile.Unexpected,
          "I cannot find interface " + (interfaceName + (" in entity " + entity[/* name */0]))
        ];
  }
  var output_types = $$interface[/* output_types */4];
  var input_types = $$interface[/* input_types */3];
  var inputs = Query_commands$BwaxMobile.to_interface_inputs(entity_dict, data_type_dict, input_types, interface_params_from_js(entity_dict, data_type_dict, input_types, args));
  var output_selections = get_output_selections(entity_dict, output_types, outputSelections);
  var outputs = Plate$BwaxMobile.List.combine(output_types, output_selections);
  var mut = /* M_custom_operation */Block.__(4, [
      $$interface,
      maybeId,
      inputs,
      outputs
    ]);
  var process_select_value = Plate$BwaxMobile.$$Option.with_default(true, queryOpts.processSelectValue);
  var process_composite_data = Plate$BwaxMobile.$$Option.with_default(true, queryOpts.processCompositeData);
  var additional_query_params = additional_query_params_from_js(queryOpts.additionalQueryParams);
  return Query_builder$BwaxMobile.resolve_and_run_mutation(context, mut, /* record */[
              /* force_refreshing */true,
              /* process_select_value */process_select_value,
              /* process_composite_data */process_composite_data,
              /* additional_query_params */additional_query_params
            ], (function (result) {
                if (result.tag) {
                  return Curry._2(cont, undefined, result[0]);
                } else {
                  var match = result[0];
                  return Curry._2(cont, /* tuple */[
                              to_js(match[0]),
                              to_js(match[1])
                            ], undefined);
                }
              }));
}

function get_query_vars(context, queryObj) {
  var query = to_query(context[/* entity_dict */0], context[/* data_type_dict */1], queryObj);
  return Js_dict.fromList(Query_builder$BwaxMobile.build_variables(/* :: */[
                  /* tuple */[
                    "q0",
                    query
                  ],
                  /* [] */0
                ]));
}

function pack_query_config(entity_name, m_condition, m_sort) {
  return /* tuple */[
          entity_name,
          to_raw_criteria_or_sorting((function (j) {
                  return /* C_raw */Block.__(2, [j]);
                }), m_condition),
          to_raw_criteria_or_sorting((function (j) {
                  return /* S_raw */Block.__(1, [j]);
                }), m_sort)
        ];
}

function build_query(entity_dict, data_type_dict, query_config, page_size, offset, additional_conditions, additional_sorting, selectionText) {
  var config = query_config[1];
  var match;
  if (config.tag) {
    throw [
          Plate$BwaxMobile.Unexpected,
          "Custom query not supported yet"
        ];
  } else {
    match = /* tuple */[
      config[0],
      config[1],
      config[2]
    ];
  }
  var mc = match[0];
  var entity = Base_query_types$BwaxMobile.get_entity(entity_dict, query_config[0]);
  var additional_criteria = is_empty_condition_or_sorting(additional_conditions) ? undefined : /* C_raw */Block.__(2, [additional_conditions]);
  var merged_criteria;
  if (mc !== undefined) {
    var c = mc;
    merged_criteria = additional_criteria !== undefined ? Base_query_types$BwaxMobile.and_criteria(c, additional_criteria) : c;
  } else {
    merged_criteria = additional_criteria !== undefined ? additional_criteria : undefined;
  }
  var merged_sorting = Base_query_types$BwaxMobile.join_sorting(is_empty_condition_or_sorting(additional_sorting) ? undefined : /* S_raw */Block.__(1, [additional_sorting]), match[1]);
  var q_001 = /* tuple */[
    merged_criteria,
    merged_sorting,
    match[2],
    /* record */[
      /* page_size */page_size,
      /* offset */offset
    ]
  ];
  var q_002 = /* Raw_selection */Block.__(1, [selectionText]);
  var q = /* Q_listAll */Block.__(2, [
      entity,
      q_001,
      q_002
    ]);
  var query_text = Query_builder$BwaxMobile.build_query_text(entity_dict, data_type_dict, /* [] */0, /* :: */[
        /* tuple */[
          "q0",
          q
        ],
        /* [] */0
      ]);
  var vars = Js_dict.fromList(Query_builder$BwaxMobile.build_variables(/* :: */[
            /* tuple */[
              "q0",
              q
            ],
            /* [] */0
          ]));
  return /* tuple */[
          query_text,
          vars
        ];
}

function build_aggregate_query(entity_dict, data_type_dict, aggregate_config) {
  var match = aggregate_config[1];
  var m_aggregate = match[2];
  var m_group = match[1];
  var entity = Base_query_types$BwaxMobile.get_entity(entity_dict, aggregate_config[0]);
  var q_001 = /* tuple */[
    match[0],
    m_group,
    m_aggregate,
    match[3],
    match[4],
    match[5],
    match[6]
  ];
  var q = /* Q_aggregate */Block.__(4, [
      entity,
      q_001
    ]);
  var query_text = Query_builder$BwaxMobile.build_query_text(entity_dict, data_type_dict, /* [] */0, /* :: */[
        /* tuple */[
          "q0",
          q
        ],
        /* [] */0
      ]);
  var vars = Js_dict.fromList(Query_builder$BwaxMobile.build_variables(/* :: */[
            /* tuple */[
              "q0",
              q
            ],
            /* [] */0
          ]));
  var collect_data_field = function (param) {
    var alias_name = param[3];
    var params = param[2];
    var func = param[1];
    var path = param[0];
    var get_field_type_name = function (entity_field) {
      return Query_env$BwaxMobile.dtype_to_type((function (name) {
                    return "#" + Base_query_types$BwaxMobile.tname_of(Base_query_types$BwaxMobile.get_entity_name(entity_dict, name));
                  }), Base_query_types$BwaxMobile.opaque_entity_type_name, (function (name) {
                    return "#" + Base_query_types$BwaxMobile.dtname_of(Base_query_types$BwaxMobile.get_data_type(data_type_dict, name));
                  }), (function (param) {
                    return Query_env$BwaxMobile.build_criteria_name(entity_dict, param);
                  }), entity_field[/* dtype */2]);
    };
    if (alias_name !== undefined) {
      var an = Caml_option.valFromOption(alias_name);
      var paths = Plate$BwaxMobile.Str.split(".", path);
      var resolve_field = function (_acc, _p, _entity) {
        while(true) {
          var entity = _entity;
          var p = _p;
          var acc = _acc;
          if (p) {
            var h = p[0];
            var all_fields = Pervasives.$at(entity[/* fields */2], entity[/* virtual_fields */3]);
            var found = Plate$BwaxMobile.List.find((function(h){
                return function (f) {
                  return f[/* name */0] === h;
                }
                }(h)), all_fields);
            if (found !== undefined) {
              var f = found;
              var match = f[/* dtype */2];
              if (typeof match === "number") {
                return get_field_type_name(f);
              } else {
                switch (match.tag | 0) {
                  case /* Entity_link */1 :
                      var linked_entity = Base_query_types$BwaxMobile.get_entity(entity_dict, match[0]);
                      _entity = linked_entity;
                      _p = p[1];
                      _acc = f;
                      continue ;
                  case /* Entity_link_opaque */2 :
                      console.log("WARNING: opaque type is not processed", f[/* name */0]);
                      return ;
                  default:
                    return get_field_type_name(f);
                }
              }
            } else if (h === "id") {
              return "id";
            } else {
              return ;
            }
          } else {
            return Plate$BwaxMobile.$$Option.map(get_field_type_name, acc);
          }
        };
      };
      var resolved = resolve_field(undefined, paths, entity);
      return Plate$BwaxMobile.$$Option.map((function (type_name) {
                    return /* tuple */[
                            an,
                            /* tuple */[
                              path,
                              type_name,
                              func,
                              params
                            ]
                          ];
                  }), resolved);
    }
    
  };
  var data_fields = Js_dict.fromList(Pervasives.$at(Plate$BwaxMobile.List.keep_map(collect_data_field, Plate$BwaxMobile.$$Option.with_default(/* [] */0, m_group)), Plate$BwaxMobile.List.keep_map(collect_data_field, Plate$BwaxMobile.$$Option.with_default(/* [] */0, m_aggregate))));
  return /* tuple */[
          query_text,
          vars,
          data_fields
        ];
}

exports.make_query_config = make_query_config;
exports.is_empty_condition_or_sorting = is_empty_condition_or_sorting;
exports.to_raw_criteria_or_sorting = to_raw_criteria_or_sorting;
exports.to_raw_criteria = to_raw_criteria;
exports.to_raw_sorting = to_raw_sorting;
exports.to_query_context = to_query_context;
exports.to_js = to_js;
exports.is_custom_query = is_custom_query;
exports.interface_params_from_js = interface_params_from_js;
exports.get_output_selections = get_output_selections;
exports.to_standard_query = to_standard_query;
exports.to_custom_query = to_custom_query;
exports.to_query = to_query;
exports.additional_query_params_from_js = additional_query_params_from_js;
exports.run_queries = run_queries;
exports.run_add_mutation = run_add_mutation;
exports.run_update_mutation = run_update_mutation;
exports.run_delete_mutation = run_delete_mutation;
exports.run_custom_mutation = run_custom_mutation;
exports.get_query_vars = get_query_vars;
exports.pack_query_config = pack_query_config;
exports.build_query = build_query;
exports.build_aggregate_query = build_aggregate_query;
/* Json-BwaxMobile Not a pure module */
