import React from 'react'

class HealthIcon extends React.Component {

    render() {
        return (
            <svg width="1em" height="1em" viewBox="0 0 14 12">
                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Icon/VIP-services/健康" transform="translate(-1.000000, -2.000000)" fillRule="nonzero">
                        <g transform="translate(-0.000000, 0.000000)">
                            <circle id="Oval" cx="8" cy="8" r="8"></circle>
                            <path d="M3.51370041,8.79326267 L4.67754066,8.79326267 L5.45292974,7.78551239 L6.60665258,9.79576864 L7.82386545,7.40993644 L8.90088569,10.9285805 L9.91755646,8.79326267 L12.2805468,8.79326267 L12.7254511,8.35687213 L9.63525192,8.35687213 L8.99124882,9.70947452 L7.92736841,6.2337585 L6.58361363,8.86762271 L5.5070604,6.99182744 L4.45675977,8.35687213 L3.06914687,8.35687213 L3.51370041,8.79326267 Z M3.51370041,8.79326267 L1.91847072,8.79326267 L1.91847072,8.35687213 L3.06914687,8.35687213 L1.5,6.81653834 L5.47547183,2.90792535 L7.89786695,5.28958102 L10.277021,2.95043924 L14.2524928,6.85905223 L12.7254511,8.35687213 L14.5,8.35687213 L14.5,8.79326267 L12.2805468,8.79326267 L7.89786695,13.0920746 L7.70367228,12.9062972 L3.51370041,8.79326267 Z M4.56715021,8.35687213 L4.39016064,8.44342905 L4.45675977,8.35687213 L4.56715021,8.35687213 Z" id="Combined-Shape" fill="#FFFFFF"></path>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export default HealthIcon
