

import React, { useEffect, useState, useRef } from 'react'

import ContextMenu from 'bwax-ui/components/ContextMenu';
import DropdownMenu from 'bwax-ui/components/DropdownMenu';

import classNames from 'classnames';

import { Pressable } from 'bwax-ui/components/Button';

import ScrollHelper from 'bwax-ui/ScrollHelper';

import './KnowledgeItemList.less';

export default function KnowledgeItemList({
    title,
    loadData, onData = _ => { },
    renderItem, getItemMenu = _ => [], onItemPress, 
    
    actionButtons, bindUpdateData,
    emptyTip = "目前还是空的",
    dataKey,

}) {

    const [data, setData] = useState();

    const [hasMore, setHasMore] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);

    const pageSize = 20;

    const listRef = useRef();

    const dataRef = useRef();
    dataRef.current = data;

    async function doLoadData(offset = 0) {
        setLoadingMore(true);
        const [ result, error] = await loadData({ offset, pageSize })  // keyword, pageIndex / offset, ...
        setLoadingMore(false);

        if (error) {
            //  error handling TODO
        } else if(result) {
            const hasMore = result.pageSize + offset < result.count;
            setHasMore(hasMore);

            const existing = dataRef.current || [];
            const toAppend = result.data.filter(s => !existing.some(e => e.id === s.id));
            const newData =  [ ...existing, ...toAppend ];

            setData(newData)
            onData(newData);

            dataRef.current = newData;

            if(hasMore) {
                setTimeout(() => {
                    if(listRef.current) {
                        const { scrollHeight, clientHeight } = listRef.current;
                        if(scrollHeight <= clientHeight && hasMore) {
                            doLoadData(offset + pageSize);
                        }
                    }                   
                }, 300);
            }

        }
    }



    const loadMore = () => {
        if(hasMore) {
            doLoadData(data.length);
        }
    }

    const loadMoreRef = useRef();
    loadMoreRef.current = loadMore;

    useEffect(() => {
        if(listRef.current) {
            const scrollHelper = new ScrollHelper(listRef.current, {
                onScrolledToBottom: _ => {
                    loadMoreRef.current && loadMoreRef.current();
                }
            });
            return () => {
                scrollHelper.cleanUp();
            }
        }
    }, [ listRef.current ]);


    useEffect(() => {
        setData();
        dataRef.current = undefined;
        doLoadData();
    }, [dataKey])


    if (bindUpdateData) {
        bindUpdateData((updater) => {
            const newData = updater(data);
            setData(newData);
            onData(newData)
        })
    }

    function renderData() {
        if (data === undefined) {
            return null
        } else if (data && data.length == []) {
            return (
                <div className="h-[75vh] flex flex-col items-center justify-center">
                    <i className='bx bxs-folder-open text-[9rem] text-[var(--gray6)]'></i>
                    <div className="text-[var(--gray9)]">{emptyTip}</div>
                </div>
            )
        } else if (data) {
            return data.map(item => {
                const itemMenu = getItemMenu(item);
                const itemLine = (
                    <div className={classNames("item-line", { "cursor-pointer" : !!onItemPress })} key={item.id}>
                        <div className="pr-2"> {renderItem(item)}</div>
                        {/* DropdownItem */}
                        <div className="op-icons" onClick={e => {
                            e.stopPropagation();
                        }}>
                            {
                                itemMenu && itemMenu.length > 0 ?
                                    <DropdownMenu items={itemMenu} withPortal={true}>
                                        <i className='bx bx-dots-vertical-rounded'></i>
                                    </DropdownMenu>
                                    : null
                            }
                        </div>
                    </div>
                );

                const withPressable = onItemPress ? (
                    <Pressable onPress={_ => onItemPress(item)} key={item.id}>
                        { itemLine }
                    </Pressable>
                ) : itemLine

                if (itemMenu && itemMenu.length > 0) {
                    return (
                        <ContextMenu items={itemMenu} withPortal={true} key={item.id}>
                            {withPressable}
                        </ContextMenu>
                    )
                } else {
                    return withPressable
                }

            })
        }
        return null
    }


    return (
        <div className="lc-knowledge-item-list max-w-4xl flex flex-col items-center self-center h-full">
            <div className="flex justify-between items-center w-full px-4 sm:px-6" >
                <div className="title flex items-center gap-2 pl-0.54">
                    { title || null }
                </div>
                <div className="action-button flex gap-3 items-center">
                    {actionButtons || null}
                </div>
            </div>
            <div className="grow w-full overflow-auto flex flex-col items-center px-4 sm:px-6 py-3" ref={listRef}>
                <div className="item-list max-w-2xl">
                    {renderData()}
                    { loadingMore ? (
                        Array(data && data.length > 0 ? 5 : 10 ).fill(0).map((_, index) => {
                            return (
                                <div className="flex gap-2 py-3 pl-2 pr-6" key={"l" + index}>
                                    <div className="bg-[var(--gray2)] h-4 w-4" />
                                    <div className="bg-[var(--gray2)] h-4 grow" />
                                </div>
                            )
                        })
                    ) : null }
                </div>
            </div>

        </div>
    )
}
