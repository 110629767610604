
require("isomorphic-fetch");

import React, { useEffect, useRef, useState } from 'react'

import { PaperPlaneIcon } from '@radix-ui/react-icons'

import { toast } from 'bwax-ui/components/Toast';

import UploadFile from 'bwax-ui/actions/UploadFile';

import { isIOS } from 'bwax/clientEnv'

import { LuSettings2, LuPaintbrush, LuPaperclip, LuImage } from "react-icons/lu";

import PopoverTrigger from 'bwax-ui/components/PopoverTrigger';
import Button, { Pressable } from 'bwax-ui/components/Button'

import './ChatUI.less'

import TextArea from 'bwax-ui/components/inputs/TextArea';

import OutOfQuotaTip from 'bwax-ui/components/OutOfQuotaTip';

import countWords from '../countWords';
import ChatbotMessageList from './ChatbotMessageList';

import { BeatLoader } from 'react-spinners';

import useResizeObserver from '@react-hook/resize-observer'

import { useTrack } from 'bwax-ui/track';

import classNames from 'classnames';

import Tooltip from 'bwax-ui/components/Tooltip';
import SelectFileButton from 'bwax-ui/components/inputs/SelectFileButton';

import getImageURL from 'bwax/util/getImageURL';


import ClampedText from 'bwax-ui/components/ClampedText';

export default function ChatUI(props) {

    const {
        history: messages, hasLoadedMessages,
        botAvatarUrl,
        userAvatar,
        userNickName,

        currentSession,

        responding, respondingText, respondingSteps, respondingKnowledges, respondingWebLinks, respondingTask,

        sendMessage,

        stopResponding = _ => { },

        maxInputCount = 900,

        configPanel,

        chatModel, updateChatModel,

        // usage quota:
        remainingQuota,

        viewEnv, facade,

        sendNewTopic,

        className,

        possibleQuestions,
        possibleQuestionsExpandByDefault,

        renderInputBox,

        emptyView,

        inputBoxClassName,

        imageEnabled = true,

    } = props;

    const track = useTrack();

    const messageListRef = useRef(null)
    const textAreaRef = useRef(null)


    useEffect(() => {
        if (messageListRef && messageListRef.current) {        
            setTimeout(() => {
                const { scrollHeight, offsetHeight } = messageListRef.current
                messageListRef.current.scrollTo({ top: scrollHeight, behavior: "instant"}) // 设置滚动条到最下方;
            }, 80)
            
        }
    }, [JSON.stringify(messages), respondingText, JSON.stringify(possibleQuestions)]);

    function handleFocus() {
        if (typeof (document) !== 'undefined' && typeof (window) !== 'undefined' && isIOS() && window.visualViewport) {
            setTimeout(() => {
                const keyboardHeight = document.body.scrollHeight - window.visualViewport.height
                window.scrollTo({ top: keyboardHeight })
            }, 160);
        }
    }


    const ref = useRef();
    const [sidePanelShown, setSidePanelShown] = useState(false);
    const sidePanelThreshold = 640;
    useResizeObserver(ref, ({ borderBoxSize }) => {
        const width = borderBoxSize[0].inlineSize;
        const shouldShow = width > sidePanelThreshold;
        if (shouldShow && !sidePanelShown) {
            setSidePanelShown(true)
        } else if (!shouldShow && sidePanelShown) {
            setSidePanelShown(false)
        }
    });

    const chatPanelRef = useRef();
    const [isChatPanelNarrow, setIsChatPanelNarrow] = useState(false);
    useResizeObserver(chatPanelRef, ({ borderBoxSize }) => {
        const width = borderBoxSize[0].inlineSize;
        const isNarrow = width < 480;
        if (isNarrow && !isChatPanelNarrow) {
            setIsChatPanelNarrow(true)
        } else if (!isNarrow && isChatPanelNarrow) {
            setIsChatPanelNarrow(false)
        }
    });    



    const setInputContentRef = useRef(null);

    const possibleQuestionsPanel = (
        <PossibleQuestionsPanel possibleQuestions={responding ? [] : possibleQuestions} isChatPanelNarrow={isChatPanelNarrow}
            onSelect={q => {
                track("chat_select_possible_question");
                setInputContentRef.current && setInputContentRef.current(q)
            }}
            onConfirm={q => {
                track("chat_select_possible_question_and_send");
                sendMessage(q)
            }}
        />
    )


    function renderMessages() {
        return (
            <ChatbotMessageList style={{
                flexGrow: 1,
            }} {...{
                currentSession,
                messages, responding, hasLoadedMessages,
                messageListRef,
                respondingText, respondingSteps, respondingKnowledges, respondingWebLinks, respondingTask,

                stopResponding,

                botAvatarUrl,
                userAvatar,
                userNickName,

                emptyView,
                facade,

                possibleQuestionsPanel,
                possibleQuestions,

            }} />
        )
    }


    // min-width: 0;
    // flex-basis: 0%;

    const inputBoxParam = {
        sendMessage,
        responding, // 

        currentSession,

        maxInputCount,
        textAreaRef, onFocus: handleFocus,

        // usage
        remainingQuota,

        sidePanelShown,

        isMobile: viewEnv.webEnv.isMobile,

        configPanel,

        sendNewTopic,

        possibleQuestions,
        possibleQuestionsExpandByDefault,

        className: inputBoxClassName,

        imageEnabled,

        chatModel, updateChatModel,

        bindSetInputContent: setter => {
            setInputContentRef.current = setter;
        },

        facade,
    }

    return (
        <div className={classNames("w-full h-full flex", className)} ref={ref}>
            <div className="flex flex-col grow basis-0 min-w-0" ref={chatPanelRef}>
                {renderMessages()}
                {renderInputBox ? (
                    renderInputBox(inputBoxParam)
                ) : (
                    <InputBox {...inputBoxParam} />
                )}

            </div>
            {sidePanelShown && configPanel ?
                <div className="hidden md:flex min-w-[280px] max-w-[280px] bg-[var(--slate2)] px-6 py-6 flex flex-col gap-6 h-full overflow-auto">
                    {configPanel}
                </div> : null}
        </div>
    )
}


function InputBox({

    sendMessage,
    responding, // status outside

    currentSession,

    maxInputCount,
    textAreaRef,

    sidePanelShown,

    onFocus,

    remainingQuota,

    isMobile,

    configPanel,

    sendNewTopic,

    bindSetInputContent,

    className,
    imageEnabled,

    chatModel, updateChatModel,

    facade,

}) {

    const track = useTrack();

    const currentSessionId = currentSession && currentSession.id;
    const sid = currentSessionId || "-";

    useEffect(() => {
        if (textAreaRef.current) {
            textAreaRef.current.focus();
        }
    }, [currentSessionId]);

    const [editings, setEditings] = useState({
        // 每个 session id 保留一份
    });

    const editing = editings[sid] || "";
    const setEditing = v => setEditings(prev => ({ ...prev, [sid]: v }));

    if(bindSetInputContent) {
        bindSetInputContent(q => {
            setEditing(q)
        })
    }


    const wordCount = (() => {
        const trimmed = editing ? editing.trim() : "";
        if (trimmed) {
            return countWords(trimmed)
        } else {
            return 0
        }
    })();

    const tooManyWords = wordCount > maxInputCount;

    const isOutOfQuota = (() => {
        return remainingQuota !== undefined && remainingQuota <= 0;
    })();


    const sendButtonDisabled = !(editing && editing.trim().length !== 0) || tooManyWords || isOutOfQuota;

    const [isComposing, setIsComposing] = useState(false);

    const isGPT4 = chatModel == "gpt-4";

    const [images, setImages] = useState([]);
    const [imageUploading, setImageUploading] = useState(false);

    useEffect(() => {
        if (images && images.length > 0 && !isGPT4) {
            updateChatModel("gpt-4")
        }
    }, [images]);


    function send() {
        if (sendButtonDisabled || responding) {
            // do nothing
        } else {
            sendMessage(editing ? editing.trim() : editing, images);
            setEditing('')
            setImages([])
        }
    }

    function handleKeyDown(e) {
        if (!isMobile) {
            // at pc:
            if (e.key == "Enter") {
                // 
                if (e.shiftKey || e.altKey || e.metaKey || e.ctrlKey || isComposing) {
                    // 换行
                    // console.log(">>> ", e.shiftKey, e.altKey, e.metaKey, e);
                } else {
                    // 发送
                    e.preventDefault();
                    if(responding) {
                        return
                    }
                    if (editing && editing.trim().length !== 0) {
                        track("chat_send_to_chatbot", { method: "enter-key" });
                        send();
                    }
                }

            }
        }
    }

    const iconButtonContent = ({ tip, icon }) => {
        const iconButtonClassName = "flex items-center justify-center text-[var(--violet10)] font-size-18 cursor-pointer  w-8 h-8 rounded hover:bg-[var(--violet3)] "
        if (tip) {
            return (
                <div className="rounded w-8 h-8">
                    <Tooltip text={tip}>
                        <div className={iconButtonClassName}>
                            {icon}
                        </div>
                    </Tooltip>
                </div>
            )
        } else {
            return (
                <div className={iconButtonClassName}>
                    {icon}
                </div>
            )
        }
    }

    const iconButton = ({ icon, tip, onPress }) => {
        return (
            <Pressable onPress={onPress}>
                {iconButtonContent({ icon, tip })}
            </Pressable>
        )
    }

    const collapsedSettingGroup = configPanel ? (
        <PopoverTrigger onTriggerPress={_ => {
            track("chat_toggle_setting_panel")
        }} content={
            <div className="font-size-14 py-8 px-5">
                {configPanel}
            </div>
        }>
            {iconButtonContent({ icon: <LuSettings2 /> })}
        </PopoverTrigger>
    ) : null;

    const ref = useRef();

    const newTopicButton = sendNewTopic ? iconButton({
        tip: "忽略前面的信息，换个新话题", icon: <LuPaintbrush />,
        onPress: _ => {
            track("chat_new_topic");
            sendNewTopic();
        }
    }) : null;

    const fileUploadButton = iconButtonContent({ tip: "上传文件", icon: <LuPaperclip /> });

    async function onSelectFile(lf) {

        setImageUploading(true)
        const [attachment, errors] = await UploadFile({ file: lf, uploadFor: "ai-chat", isPublic: true })(facade.dlc);
        setImageUploading(false);
        if (errors || !attachment) {
            toast({ title: errors || "上传失败" });
            return
        }
        setImages(prev => [...prev, attachment]);
    }

    const imageUploadButton = (
        <SelectFileButton asButton={false} className="w-8 h-8 rounded" accept="image/*" onSelectFile={onSelectFile}>
            {iconButtonContent({ tip: isGPT4 ? "添加图片" : "添加图片，同时切换模型为 GPT-4", icon: <LuImage /> })}
        </SelectFileButton>
    )


    const inputArea = (
        <TextArea ref={textAreaRef} {...{
            maxRows: isMobile ? 6 : 8,
            minRows: isMobile ? 1 : 3,
            value: editing,
            placeholder: isOutOfQuota ? "" : ("输入你要问的问题" + (isMobile ? "" : " (Shift+Enter 换行)")),
            onChange: value => setEditing(value),
            onKeyDown: e => handleKeyDown(e),
            onFocus,
            onCompositionStart: () => {
                setIsComposing(true);
            },
            onCompositionEnd: () => {
                setTimeout(() => {
                    setIsComposing(false);
                }, 16);
            }
        }}
        />
    )

    const sendButton = (
        <Button color={"violet"} isDisabled={sendButtonDisabled || imageUploading} size="small" isLoading={responding} onPress={_ => {
            track("chat_send_to_chatbot", { method: "button-click" })
            send();
        }}>
            {/* 发送 */}
            <PaperPlaneIcon style={{
                transform: "rotate(-45deg)"
            }} />
        </Button>
    )

    const outOfQuotaTip = isOutOfQuota ? (
        <div className="w-full flex justify-between items-center">
            <OutOfQuotaTip />
        </div>
    ) : null;


    // const possibleQuestionsPanel = (
    //     <PossibleQuestionsPanel possibleQuestions={possibleQuestions} possibleQuestionsExpandByDefault={possibleQuestionsExpandByDefault}
    //         onSelect={q => {
    //             track("chat_select_possible_question");
    //             if (isOutOfQuota) {
    //                 // do nothing
    //             } else {
    //                 sendMessage(q);
    //             }
    //         }}
    //     />
    // )

    const buttonsAndInput = (
        <>
            <div className="flex justify-between gap-1 items-center">
                <div className="flex gap-1.5 px-1">
                    {!sidePanelShown && configPanel ? (
                        collapsedSettingGroup
                    ) : null}
                    {imageEnabled ? imageUploadButton : null}
                    {/* {fileUploadButton} */}
                    {newTopicButton}
                </div>
                <div className="py-0.5">
                    {sendButton}
                </div>
            </div>
            {(images && images.length > 0) || imageUploading ? (
                <div className="flex gap-2 overflow-y-auto px-2 py-1">
                    {images.map((a, index) => {
                        return (
                            <div key={index} className="rounded overflow-hidden relative">
                                <img className="h-[5rem] rounded" src={getImageURL(a.url, "small")} />
                                <div className="top-0 left-0 h-full w-full absolute bg-[rgba(0,0,0,0.2)] opacity-0 hover:opacity-90 flex justify-center items-center" onClick={_ => {
                                    setImages(prev => {
                                        return [...prev.slice(0, index), ...prev.slice(index + 1)]
                                    })
                                }}>
                                    <i className="bx bx-x text-gray-200 text-[1.5rem]" />
                                </div>
                            </div>
                        )
                    })}
                    {imageUploading ? (
                        <div className="h-[5rem] w-[6rem] rounded justify-center flex items-center bg-[var(--gray2)]">
                            <BeatLoader size={6} color={"var(--gray10)"} />
                        </div>
                    ) : null}
                </div>
            ) : null}
            <div className="flex gap-1 items-start">
                <div className="grow">
                    {inputArea}
                </div>
            </div>
            {outOfQuotaTip}
        </>
    )


    return isMobile ? (
        <div className={classNames("flex gap-1 w-full flex-col chatbot-user-input px-3 pt-3", className, { "!pb-3": isOutOfQuota, "pb-6": !isOutOfQuota })}>
            {/* {possibleQuestionsPanel} */}
            {buttonsAndInput}
        </div>
    ) : (
        <div className={classNames("flex gap-2 w-full flex-col chatbot-user-input px-3 py-3", className)} ref={ref}>
            {/* {possibleQuestionsPanel} */}
            {buttonsAndInput}
        </div>
    )
}

function PossibleQuestionsPanel({ possibleQuestions, onSelect, onConfirm, isChatPanelNarrow }) {

    if (!possibleQuestions || possibleQuestions.length === 0) {
        return null
    }

    const shouldSplit = !isChatPanelNarrow && possibleQuestions.length > 1

    return (
        <div className="flex flex-wrap justify-self-end py-4">
            {possibleQuestions.map((p, index) => {
                return (
                    <div className={classNames("px-1.5 py-2", { "w-[50%]": shouldSplit, "w-full": !shouldSplit })} key={index}>
                        <Pressable onPress={_ => onSelect(p)}>
                            <div className="possible-question rounded-lg border-[var(--gray4)] border-[1px] bg-[var(--gray1)] pl-4 pr-2 h-[42px] cursor-pointer flex items-center">
                                <ClampedText tipEnabled={true}>{p} </ClampedText>
                                {/* <Pressable onPress={_ => onConfirm(p)}>
                                    <div className="instant-send items-center justify-center text-[var(--gray9)] hover:text-[var(--violet10)] font-size-18 cursor-pointer  w-8 h-8 rounded hover:bg-[var(--violet3)] ">
                                        <i className='bx bx-up-arrow-circle' ></i>
                                    </div>
                                </Pressable> */}                                
                            </div>
                        </Pressable>
                    </div>
                )
            })}
        </div>
    )

}





