
// This file is generated. Don't modify it.
import React from 'react'
export default function HomePageIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M12.8707246,5.28718927 C13.1897971,5.52958541 13.3777778,5.90554676 13.3753043,6.30624241 L13.3753043,11.6340105 C13.3753043,12.9276144 12.32657,13.9788221 11.0304928,13.9788221 L4.34481159,13.9788221 C3.05120772,13.9788221 2,12.9300878 2,11.6340105 L2,6.30624241 C2,5.90554676 2.18550725,5.5295854 2.50457971,5.28718927 L6.14052174,2.52189459 C6.59810629,2.17314096 7.14226088,2.00000086 7.6888889,2.00000086 C8.24826495,1.99954094 8.79229293,2.18291139 9.23725604,2.52189459 L12.8707246,5.28718927 Z M12.3884058,11.6315371 L12.3884058,6.30624241 C12.3884058,6.21719894 12.3463575,6.13062889 12.274628,6.07621343 L8.638686,3.31091875 C8.36413528,3.1006772 8.03516909,2.98937285 7.6888889,2.98937285 C7.34260871,2.98937285 7.01364252,3.1006772 6.7390918,3.31091875 L3.10314977,6.07621343 C3.03142029,6.13062889 2.98937199,6.21719894 2.98937199,6.30624241 L2.98937199,11.6315371 C2.98937199,12.378513 3.59783575,12.9869767 4.34481159,12.9869767 L11.0329662,12.9869767 C11.779942,12.9869767 12.3884058,12.3785129 12.3884058,11.6315371 Z M7.68641546,7.51327623 C9.00475362,7.51327624 10.0732754,8.5842714 10.0732754,9.90013614 L10.0732754,11.5598076 C10.0732754,11.8318849 9.85066667,12.0544936 9.57858938,12.0544936 C9.30651209,12.0544936 9.08390339,11.8318849 9.08390339,11.5598076 L9.08390339,9.90013614 C9.08390339,9.12842599 8.45812561,8.50264821 7.68641546,8.50264821 C6.91470532,8.50264821 6.28892754,9.13089943 6.28892754,9.90013614 L6.28892754,11.5598076 C6.28892754,11.8318849 6.06631884,12.0544936 5.79424155,12.0544936 C5.52216426,12.0544936 5.29955557,11.8318849 5.29955557,11.5598076 L5.29955557,9.90013614 C5.29955557,8.5842714 6.37055072,7.51327623 7.68641546,7.51327623 Z" id="HomePageIcon-path-1"></path>
            </defs>
            <g id="Style" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Iconology" transform="translate(-336.000000, -284.000000)">
                    <g id="Icon/Personal" transform="translate(48.000000, 284.000000)">
                        <g id="Icon/Personal/Homepage" transform="translate(288.000000, 0.000000)">
                            <g id="Group-3">
                                <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                                <mask id="HomePageIcon-mask-2" fill="white">
                                    <use href="#HomePageIcon-path-1"></use>
                                </mask>
                                <use id="Mask" fill={color} fillRule="nonzero" href="#HomePageIcon-path-1"></use>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
