

import React from 'react';
import { ReactEditor } from 'slate-react'

import { Editor, Element as SlateElement, Transforms, Node, Path, Range } from 'slate'

import { isList, blockTypes } from '../../SlateEditor';
import { getCurrentBlock } from '../../handleKeydown';

function isFormatActive(editor, blockType) {
    const { selection } = editor
    if (!selection) return false

    const { focus } = selection    
    const [parentNode, parentPath] = Editor.parent(editor, focus.path)
    const currentBlock = getCurrentBlock(editor, 
        parentNode.type === 'link' ? Path.parent(parentPath) : parentPath
    )

    if(currentBlock) {
        const [currentBlockNode, _] = currentBlock
        return currentBlockNode.type === blockType
    } else return false
    
}

function toggleSingleBlockType (editor, blockType) {
    const isActive = isFormatActive(editor, blockType)
    const { selection } = editor
    const [parentNode, parentPath] = Editor.parent(
        editor,
        selection.focus?.path
    );

    const [currentBlockNode, currentBlockPath] = getCurrentBlock(editor, parentPath)
    
    if (isList(blockType)) {
        if (isActive) {
            // 如果本来是 list，则分割 list，提升 list-item 中的内容
            Transforms.unwrapNodes(editor, {
                match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && isList(n.type),
                split: true,
            })

            const [_, currentListItemPath] = Array.from(
                Node.ancestors(editor, editor.selection.focus.path, { reverse: true })
            ).find(ancestor => {
                const [ancestorNode, _] = ancestor
                return ancestorNode.type === 'list-item'
            })
            Transforms.unwrapNodes(editor, { at: currentListItemPath })
        } else {
            function wrapParagraphToList () {
                Transforms.wrapNodes(editor, { type: 'list-item' })
                Transforms.wrapNodes(editor, { type: blockType }, { 
                    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'list-item',
                })
            }

            const prevBlock = Editor.previous(editor, { at: currentBlockPath }); 
            
            if(prevBlock && !isList(currentBlockNode.type) && 
                ((prevBlock[0].type === 'numbered-list' && blockType === 'numbered-list') ||
                 (prevBlock[0].type === 'bulleted-list' && blockType === 'bulleted-list')
                )
            ) {
                // 如果被转换的文本位于列表的下方，并且转换目标是同类型的列表，则接在上一个列表的末端（新增 list-item）
                Transforms.wrapNodes(editor, { type: 'list-item' })
                Transforms.unwrapNodes(editor, { at: prevBlock[1] })
                const { focus } = editor.selection
                Transforms.wrapNodes(editor, { type: blockType, children: [] }, { 
                    at: { 
                        anchor: { offset: 0, path: prevBlock[1] }, 
                        focus: { offset: focus.offset, path: focus.path.slice(0, 1) } },
                    match: n => n.type === 'list-item'
                })
            } else if(currentBlockNode.type === "block-quote" || currentBlockNode.type.match(/^h\d$/)) {
                
                Transforms.setNodes(editor, { type: 'paragraph' })
                wrapParagraphToList()


            } else if (isList(currentBlockNode.type)) {
                // 当前 list-item 转换成 blocktype，原本的 list 被分割
                Transforms.unwrapNodes(editor, {
                    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && isList(n.type),
                    split: true,
                })
                const [singleListItemNode, singleListItemPath] = Array.from(
                    Node.ancestors(editor, editor.selection.focus.path, { reverse: true })
                ).find(ancestor => {
                    const [ancestorNode, _] = ancestor
                    return ancestorNode.type === 'list-item'
                })
                
                Editor.parent(editor, Path.parent(editor.selection.focus.path))
                const block = { type: blockType, children: [] }
                Transforms.wrapNodes(editor, block, { at: singleListItemPath })
            } else if (isFormatActive(editor, 'list-item') && parentPath.length === 2) {
                // 处理单个 list-item 的情况
                Transforms.unwrapNodes(editor, { at: Path.parent(parentPath)})
                wrapParagraphToList()
            } else {
                wrapParagraphToList()
            }
        }

    } else if (blockType === "block-quote" || blockType.match(/^h\d$/)) {
        if(isList(currentBlockNode.type)) {
            // 如果在 list 中，则分割 list，将对应的 list-item 转化为目标 blockType
            const [listItemNode, listItemPath] = Editor.parent(editor, parentPath)
            const { children } = listItemNode

            Transforms.unwrapNodes(editor, {
                match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && isList(n.type),
                split: true,
            })

            if (children.length > 1) {
                // list-item 中有多个 paragraph
                const splitIndex = parentPath[parentPath.length - 1] // focus 在第几个 paragraph
                const [singleListItemNode, singleListItemPath] = Editor.parent(editor, Path.parent(editor.selection.focus.path))
                const newListItemChildren = singleListItemNode.children.filter((c, idx) => idx < splitIndex)

                if(parentPath[1] === 0 && parentPath[parentPath.length - 1] > 0) {
                    // 在第一个 list-item 且不是第一个 paragraph 中，要把之前的 paragraphs wrap 成一个新的 list
                    Transforms.wrapNodes(editor, { type: currentBlockNode.type, children: [] }, { at: ancestorPath })
                    Transforms.liftNodes(editor, { 
                        at: Editor.range(editor, { at: singleListItemPath }),
                        match: n => singleListItemNode.children.findIndex(c => c === n) >= splitIndex
                    })

                } else {
                    // 把 newListItemChildren 重新插回 list 中
                    if(newListItemChildren.length > 0) {
                        Transforms.insertNodes(editor, {
                            type: "list-item",
                            children: newListItemChildren
                        }, { 
                            at: Path.parent(parentPath),
                        })
                    }

                    Transforms.liftNodes(editor, { 
                        at: Editor.range(editor, { at: singleListItemPath }),
                        match: n => singleListItemNode.children.findIndex(c => c === n) >= splitIndex
                    })

                    if(parentPath[parentPath.length - 1] !== 0) {
                        // 如果不是第一个 paragraph，则 removeNodes
                        Transforms.removeNodes(editor, { at: singleListItemPath })
                    }
                    // Transforms.setNodes(editor, { type: blockType })
                }

                Transforms.setNodes(editor, { type: blockType })
                
            } else {
                Transforms.liftNodes(editor) // paragraph
                Transforms.setNodes(editor, { type: blockType }, { select: true })
            }
            
        } else {
            const newProperties = {
                type: isActive ? 'paragraph' : blockType,
            }

            if (isFormatActive(editor, 'list-item') && parentPath.length === 2) {
                Transforms.unwrapNodes(editor, { at: Path.parent(parentPath)})
            } 
            
            Transforms.setNodes(editor, newProperties)
        }
    } else if (blockType === "hr") {
        // 增加一个 hr 节点和一个新的文本节点，并将光标移到新文本节点上
        Transforms.insertNodes(editor, [ 
            { type: 'hr', children: [{ text: "" }] },
            { type: "paragraph", children: [{ text: ""}] }
        ])
    } else if (blockType === 'list-item' && currentBlockNode.type === 'list-item') {
        Transforms.unwrapNodes(editor, { at: Path.parent(parentPath)})
    } else {
        const newProperties = {
            type: isActive ? 'paragraph' : blockType,
        }

        Transforms.setNodes(editor, newProperties)
        
    }
}

export function toggleBlockType (editor, blockType) {
    const { selection } = editor
    if(!selection) {
        return
    }

    const { anchor, focus } = selection
    const startPoint = Range.isBackward(selection) ? focus : anchor
    const endPoint = Range.isBackward(selection) ? anchor : focus

    const selectedFragments = Editor.fragment(editor, selection)
    const selectedNodes = Array.from(Editor.nodes(editor, { at: selection }))
    const selectedIsSingleLi = selectedFragments.length === 1 && selectedFragments[0].type === 'list-item'
    const selectedIsList = selectedFragments.length === 1 && isList(selectedFragments[0].type)
    const isSelectDifferentLevelsNodes = Math.abs(anchor.path.length - focus.path.length) > 1 &&
        !Path.equals(Path.parent(startPoint.path), Path.parent(endPoint.path))

    if(selectedIsList && isSelectDifferentLevelsNodes) {
        // 暂不处理在 list 中跨层级选中多个 nodes 的情况
        console.log("select multiple levels nodes");
        return
    }
    

    if((selectedFragments.length > 1 || (selectedIsList && !isSelectDifferentLevelsNodes)) && 
        !selectedIsSingleLi
    ) {
        // 选中了多个 block 或者选中一个或多个 list-item
        if(isList(blockType)) {
            const startBlockIndex = startPoint.path[0]
            const endBlockIndex = endPoint.path[0]

            if(selectedFragments.every(f => !isList(f.type))) {
                Transforms.setNodes(editor, { type: 'paragraph' })
                for (let i = startBlockIndex; i <= endBlockIndex; i++) {
                    Transforms.wrapNodes(editor, { type: 'list-item' }, {
                        at: [i]
                    })
                }

                const prevBlock = Editor.previous(editor, { at: startPoint.path.slice(0, 1) }); 
                if(prevBlock &&
                    ((prevBlock[0].type === 'numbered-list' && blockType === 'numbered-list') ||
                    (prevBlock[0].type === 'bulleted-list' && blockType === 'bulleted-list')
                    )
                ) {
                    // 如果被转换的文本位于列表的下方，并且转换目标是同类型的列表，则接在上一个列表的末端（新增 list-item）
                    Transforms.unwrapNodes(editor, { at: prevBlock[1] })
                    const endPointAfterUnwrap = Range.isBackward(editor.selection) ? editor.selection.anchor : editor.selection.focus
                    Transforms.wrapNodes(editor, { type: blockType }, { 
                        at: { 
                            anchor: { offset: 0, path: prevBlock[1] }, 
                            focus: { offset: endPointAfterUnwrap.offset, path: endPointAfterUnwrap.path.slice(0, 1) } 
                        },
                        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'list-item'
                    })
                } else {
                    Transforms.wrapNodes(editor, { type: blockType }, { 
                        match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'list-item',
                    })
                }
            } else {
                const [currentBlockNode, currentBlockPath] = getCurrentBlock(editor, Path.parent(startPoint.path))
                Transforms.unwrapNodes(editor, {
                    match: (n, p) => {
                        return !Editor.isEditor(n) && SlateElement.isElement(n) && isList(n.type) &&
                            p.length === currentBlockPath.length
                    },
                    split: currentBlockNode.type === blockType ? false : true
                })
                // 如果开始端的 node 是与 blockType 相同的 list type，则后续的内容都接在该 list 后面
                Transforms.setNodes(editor, { type: 'paragraph' }, {
                    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type !== 'list-item',
                })

                const { anchor, focus } = editor.selection
                const sp = Range.isBackward(editor.selection) ? focus : anchor // start point after unwrap
                const ep = Range.isBackward(editor.selection) ? anchor : focus // end point after unwrap
                for (let i = sp.path[currentBlockPath.length - 1]; i <= ep.path[currentBlockPath.length - 1]; i++) {
                    const targetPath = [...Path.parent(currentBlockPath), i]
                    const [blockNode, blockPath] = Editor.node(editor, targetPath)
                    if(blockNode.type !== 'list-item') {
                        if (isList(blockNode.type)) {
                            Transforms.unwrapNodes(editor, { at: targetPath })
                        } else {
                            Transforms.wrapNodes(editor, { type: 'list-item' }, {
                                at: targetPath
                            })
                        }
                    }
                }
                Transforms.wrapNodes(editor, { type: blockType }, {
                    match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'list-item',
                })
            }
        } else {
            //将这些 block 转换成对应的 blockType
            if(selectedFragments.some(f => isList(f.type))) {
                // 只 unwrap 选中内容中的最高级 list、list-item、nestWrapper
                const firstList = selectedNodes.find(nodeEntry => {
                    const [node, _] = nodeEntry
                    return isList(node.type)
                })
                const firstListItem = selectedNodes.find(nodeEntry => {
                    const [node, _] = nodeEntry
                    return node.type === 'list-item'
                })
                const firstNestWrapper = selectedNodes.find(nodeEntry => {
                    const [node, _] = nodeEntry
                    return node.type === 'nestWrapper'
                })
                const [_, firstListPath] = firstList
                const [__, firstListItemPath] = firstListItem
                if(firstNestWrapper) {
                    const [_, path] = firstNestWrapper
                    Transforms.unwrapNodes(editor, {
                        match: (n, p) => {
                            return p.length === path.length && !Editor.isEditor(n) && SlateElement.isElement(n) && 
                                n.type === 'nestWrapper'
                        },
                    })
                }
                Transforms.unwrapNodes(editor, {
                    match: (n, p) => {
                        return p.length === firstListItemPath.length && !Editor.isEditor(n) && SlateElement.isElement(n) && 
                            n.type === 'list-item'
                    },
                    split: true
                })

                Transforms.unwrapNodes(editor, {
                    match: (n, p) => {
                        return p.length === firstListPath.length && !Editor.isEditor(n) && SlateElement.isElement(n) && 
                            isList(n.type)
                    },
                    split: true
                })
            }

            Transforms.setNodes(editor, { type: blockType }, {  
                match: (n, p) => {
                    let isOuterParagraph = n.type === 'paragraph'
                    if(n.type === 'paragraph') {
                        const parent = Node.parent(editor, p)
                        isOuterParagraph = parent.type !== 'list-item' && parent.type !== 'nestWrapper'
                    }
                    return !Editor.isEditor(n) && SlateElement.isElement(n) && 
                        (n.type.match(/^h\d$/) || n.type === 'block-quote' || isOuterParagraph)
                }
            })
            
        }
    } else {
        toggleSingleBlockType(editor, blockType)
    }
    

    ReactEditor.focus(editor) // 恢复光标
}

export default ({ blockType, children }) => (

    
    function BlockTypeButton({ editor }) {

        return (
            <button className={`editor-button ${isFormatActive(editor, blockType) ? " active" : ""}`} 
                onClick={() => toggleBlockType(editor, blockType)}
            >
                {children}
            </button>
        );
    }
);
