
// This file is generated. Don't modify it.
import React from 'react'
export default function CloseIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M9.5,9.5 L9.5,3.5 C9.5,3.22385763 9.72385763,3 10,3 C10.2761424,3 10.5,3.22385763 10.5,3.5 L10.5,9.5 L16.5,9.5 C16.7761424,9.5 17,9.72385763 17,10 C17,10.2761424 16.7761424,10.5 16.5,10.5 L10.5,10.5 L10.5,16.5 C10.5,16.7761424 10.2761424,17 10,17 C9.72385763,17 9.5,16.7761424 9.5,16.5 L9.5,10.5 L3.5,10.5 C3.22385763,10.5 3,10.2761424 3,10 C3,9.72385763 3.22385763,9.5 3.5,9.5 L9.5,9.5 Z" id="CloseIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Wireframe/Close">
                    <g id="Group" transform="translate(-2.000000, -2.000000)">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="2.5" y="2.5" width="15" height="15"></rect>
                        <mask id="CloseIcon-mask-2" fill="white">
                            <use href="#CloseIcon-path-1"></use>
                        </mask>
                        <use id="Path" fill={color} fillRule="nonzero" transform="translate(10 -4) rotate(45)" href="#CloseIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
