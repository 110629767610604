

import React from 'react'

import Icon from 'bwax-ui/basic/Icon';

export default function FileIcon({ file }) {
    return getFileIcon(file)
}

export const commonMimeTypeMap = {
    'application/pdf': 'pdf',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'application/epub+zip': "epub",
    'application/epub': "epub",
}

export function getFileType(file) {

    const contentType = file.contentType || file.type;

    const mt = Object.keys(commonMimeTypeMap).find(k => contentType && contentType.startsWith(k));
    const fileType =  mt && commonMimeTypeMap[mt];
    if(fileType) {
        return fileType
    } else {
        // if(contentType == "application/octet-stream" || contentType == "text/plain") {
        // }

        const fileExts = ["srt", "txt", "po", "epub", "pdf", "docx", "xlsx", "pptx"];
        const ext = fileExts.find(e => file.title && file.title.endsWith(e));

        if(ext) {
            return ext
        }
    }
}

export function getFileIcon(file) {

    const iconNames = {
        "pdf": "PDF",
        "doc": "Word",
        "docx": "Word",
        "xls": "Excel",
        "xlsx": "Excel",
    }

    const iconName = iconNames[getFileType(file)] || "DefaultFile";

    return <Icon params={{ type: iconName }} />

}