

import React, { useEffect, useState } from 'react'

import Loading from 'bwax-ui/components/Loading';

import { getFieldLabel, getConstraints } from './fieldSettings';
import getFieldDisplay from './getFieldDisplay';

import RenderWithData from 'bwax-ui/store/RenderWithData';

import "./DisplayRecordForm.less";


export default function DisplayRecordForm(props) {

    const { facade, entityName, condition } = props

    function getFieldToUse(entity) {
        const specifiedFields = fieldToLists[entityName];
        return specifiedFields ? entity.fields.filter(f => specifiedFields.indexOf(f.name) != -1) : entity.fields
    }

    async function fetchData(option = {}) {
        const entity = facade.entities.find(e => e.name == entityName);        
        const fieldPaths = getFieldPaths(getFieldToUse(entity));        
        const [result, error] = await facade.findOne({ entityName, condition, fieldPaths }, option);
        // TODO error handling:
        return result || null;
    }

    async function loadData() {
        await facade.prepare([entityName]);
        return fetchData();
    }

    return (
        <RenderWithData {...{
            ...props, fetchData, getFieldToUse,

            loadData,            
            dataKey: "DisplayRecordForm::" + entityName,
            Component: DisplayRecordFormInner,
            noSuspense: true,
        }} />
    )

}

function DisplayRecordFormInner({ entityName, facade, bindReload, data, fetchData, getFieldToUse }) {

    const entity = facade.entities.find(e => e.name == entityName);

    const [refreshedData, setRefreshedData] = useState(data);

    async function reloadData(option = {}) {
        const result = await fetchData(option)
        setRefreshedData(result);
    }

    if(bindReload) {
        bindReload(() => reloadData({forceRefreshing: true}))
    }

    function renderData() {
        const fields = getFieldToUse(entity);
        return fields.map(f => {
            const display = getFieldDisplay(f, entityName, { facade });
            const value = refreshedData[f.name];
            return (
                <div className="field-item" key={f.key}>
                    <div className="label font-size-13">{getFieldLabel(f, entityName)}</div>
                    <div className="value">
                        { display ? display({ value }) : null}
                    </div>
                </div>
            )
        })
    }

    return (
        <div className="lc-display-record-form">
            {entity && refreshedData ? renderData() : <Loading style={{ minHeight: "6rem" }}></Loading>}
        </div>
    )
}


// field to list 
const fieldToLists = {
    "OpenAI-文档翻译任务": ["文档", "源语言", "目标语言", "翻译后文档", ],
    "OpenAI-文档格式转换任务": ["文档", "目标文档类型", "转换后文档", ],
    "OpenAI-文档文字识别任务": ["文档", "源语言", "识别后文档"],
}


const getFieldPaths = fields => {
    // 暂时很简单的实现：
    return fields.map(f => f.name);
}