
// This file is generated. Don't modify it.
import React from 'react'
export default function CommentWireFrameIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M6.20075593,11.6827534 L6.55259153,11.3962885 L6.99987142,11.4723882 C7.32722763,11.5280842 7.66208825,11.5559893 8,11.5559893 C10.7941475,11.5559893 13,9.68070851 13,7.45464997 C13,5.22894034 10.7945125,3.35464997 8,3.35464997 C5.20585254,3.35464997 3,5.22993072 3,7.45598926 C3,8.70218747 3.69346374,9.8713565 4.88823365,10.6551075 L5.45270855,11.0253946 L5.32031282,11.6873732 C5.28011432,11.8883657 5.2316259,12.0893015 5.17380349,12.288205 C5.5390456,12.1371882 5.88828476,11.9371678 6.20075593,11.6827534 Z M8,2.35464997 C11.3133929,2.35464997 14,4.63679283 14,7.45464997 C14,10.2725071 11.3133929,12.5559893 8,12.5559893 C7.60892857,12.5559893 7.21785714,12.5238464 6.83214286,12.4582214 C5.70044643,13.37965 4.33973214,13.5992928 3.69955357,13.6448285 C3.56160714,13.6555428 3.47857143,13.4988464 3.56026786,13.3876857 C4.03973214,12.7341143 4.25535714,11.9131321 4.33973214,11.4912571 C2.91875,10.5591143 2,9.10063211 2,7.45598926 C2,4.63813211 4.68660714,2.35464997 8,2.35464997 Z" id="CommentWireFrameIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Wireframe/Comment">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="CommentWireFrameIcon-mask-2" fill="white">
                            <use href="#CommentWireFrameIcon-path-1"></use>
                        </mask>
                        <use id="Path" fill={color} fillRule="nonzero" href="#CommentWireFrameIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
