
import React, { useState } from 'react';

import CommonPopup from './CommonPopup';

import './Tooltip.less'

export default function Tooltip (props) {

    const { 
        className,
        trigger = "hover",
        text, 
        title, // 为了兼容 antd tooltip
        content: givenContent,
        padding = 8,
        arrowSize = 4,
        arrowColor = "rgba(0, 0, 0, .75)",

        children,

        ...remaining 
    } = props;

    const content = givenContent ? givenContent :  <div className="bw-tooltip-text">{ text || title }</div>;

    if(!givenContent && !text && !title) {
        return children
    }

    return (
        <CommonPopup
            {...{
                className: "bw-tooltip font-size-14 lc-base" + (className ? " " + className: ""),
                trigger, content,
                padding,

                arrowSize, arrowColor,

                ...remaining
            }}
        >   
            { children }
        </CommonPopup>
    )
}

export function create (props) {
    return <Tooltip {...props} />
}