import React from 'react'

import SingleBlock from './SingleBlock'

import defaultBlockReact from './default/defaultBlockReact'

const NESTED_BLOCK_TYPES = [
    'unordered-list-item',
    'ordered-list-item'
]

function siblingBlockIsListItem(preBlock, nextBlock) {
    return preBlock && nextBlock &&
        preBlock.type === nextBlock.type &&
        preBlock.depth === nextBlock.depth &&
        NESTED_BLOCK_TYPES.indexOf(nextBlock.type) !== -1
}

export default ({
    styleToReact,
    entityToReact,
    blockToReact
}) => contentState => {

    const { blocks, entityMap } = contentState

    const groupListBlocks = blocks.reduce((acc, nextBlock) => {
        const preBlock = acc[acc.length - 1]
        if (Array.isArray(preBlock) && siblingBlockIsListItem(preBlock[preBlock.length - 1], nextBlock)) {
            return [
                ...acc.slice(0, acc.length - 1),
                [
                    ...preBlock,
                    nextBlock
                ]
            ]
        } else if (NESTED_BLOCK_TYPES.indexOf(nextBlock.type) !== -1) {
            return [
                ...acc,
                [nextBlock]
            ]
        } else {
            return [
                ...acc,
                nextBlock
            ]
        }
    }, [])

    // console.log("groupListBlocks >>> ", groupListBlocks)

    if (groupListBlocks.length > 0) {

        const commonSingleBlockProps = {
            entityMap,
            blockToReact,
            entityToReact,
            styleToReact
        }

        return (
            <div>
                {
                    groupListBlocks.map(block => {
                        const isListBlock = Array.isArray(block)
                        if (isListBlock) {
                            const blockObj = blockToReact(block[0]) || defaultBlockReact(block[0])
                            const nestElement = blockObj.nest
                            const children = block.map(b => {
                                return (
                                    <SingleBlock
                                        key={b.key}
                                        block={b}
                                        {...commonSingleBlockProps} />
                                )
                            })
                            return React.cloneElement(
                                (nestElement || <></>),
                                { key: block[0].key + "1" },
                                children
                            )
                        } else {
                            return (
                                <SingleBlock
                                    key={block.key}
                                    block={block}
                                    {...commonSingleBlockProps} />
                            )
                        }
                    })
                }
            </div>
        )
    } else {
        return null
    }
}