
import React, { useEffect, useRef, useState } from 'react'

import WindowScrollHelper, { scrollToNode } from '../impl/misc/WindowScrollHelper';
import { color as getColor } from 'bwax-ui';
import './ContentWithScrollFadePages.less'
import ArrowDownIcon from './components/ArrowDownIcon';

export default function ScrollFadePages(props) {

    const { data, slots, events } = props;

    const { isInHomePage } = data

    const { scrollFadePagesCol, otherContent } = slots

    const scrollFadePages = scrollFadePagesCol ? Object.values(scrollFadePagesCol.props.children) : []

    const [currentPageIndex, setCurrentPageIndex] = useState(0)
    const [arrowDownColor, setArrowDownColor] = useState(isInHomePage ? "#fff" : "#bbb")
    const holderRefs = useRef({})

    const setHolderRef = (holderIndex, r) => {
        const refs = holderRefs.current;
        holderRefs.current = { ...refs, [holderIndex]: r };
    };
    const fadePageHeight = `${ 100 / (Object.values(scrollFadePages).length + 1)}%`

    useEffect(() => {
        const scrollHelper = new WindowScrollHelper({
            // options
            topThreshold: window.innerHeight,
            bottomThreshold: 0,

            onScrolled: status => {
                const { y, direction, clientHeight } = status
                const currentHolder = holderRefs.current[currentPageIndex]

                if(isInHomePage) {
                    if(direction === "down" && y - clientHeight >= currentHolder.offsetTop && currentPageIndex <= scrollFadePages.length - 2 ) {
                        setCurrentPageIndex(currentPageIndex + 1)
                    } else if (direction === "up" && y < currentHolder.offsetTop && currentPageIndex >= 1) {
                        setCurrentPageIndex(currentPageIndex - 1)
                    }
                } else {
                    if(direction === "down" && y >= currentHolder.offsetTop && currentPageIndex <= scrollFadePages.length - 2 ) {
                        setCurrentPageIndex(currentPageIndex + 1)
                    } else if (direction === "up" && y < currentHolder.offsetTop && currentPageIndex >= 1) {
                        setCurrentPageIndex(currentPageIndex - 1)
                    }
                }
                
                
                if(y > 32 && arrowDownColor !== "#bbb" && isInHomePage) {
                    setArrowDownColor("#bbb")
                } else if(y < 32 && arrowDownColor !== "#fff" && isInHomePage) {
                    setArrowDownColor("#fff")
                }
            }
        });

        return () => {
            scrollHelper.cleanUp();
        }

    }, [ currentPageIndex, arrowDownColor ])

    function renderFadePages() {
        return scrollFadePages.map((s, index) => {
            return (
                <div id={`fade-page-${index}`} key={index} className={`fade-page-item ${index <= currentPageIndex ? 'show' : ''}`}>
                    {s}
                </div>
            )
        })
    }

    function renderFadePagesHolder() {
        return scrollFadePages.map((s, index) => {
            return (
                <div key={index} id={`fade-page-holder-${index}`} className={`fade-page-item-holder`} style={{ height: fadePageHeight }}
                    ref={r => setHolderRef(index, r)}
                ></div>
            )
        })
    }

    function gotoNextPage() {
        if(typeof (window) !== "undefined") {
            const deltaHeight = scrollFadePages.length > 2 && currentPageIndex === scrollFadePages.length - 2 || 
                scrollFadePages.length <= 2 && currentPageIndex > 0 ?
                // currentPageIndex < scrollFadePages.length - 2 || scrollFadePages.length <= 2 ? 
                window.innerHeight * 2 : window.innerHeight
            window.scrollTo({ top: window.pageYOffset + deltaHeight, behavior: "smooth" })
        }

    }

    function renderArrowDown() {
        return currentPageIndex === scrollFadePages.length - 1 && !otherContent ? null : (
            <div className='arrow-down' onClick={() => gotoNextPage()}>
                <ArrowDownIcon color={arrowDownColor}/>
            </div>
        )
    }

    const containerHeight = `${(Object.values(scrollFadePages).length + 1) * 100}%`

    return (
        <div className="scroll-fade-pages-container" style={{ height: containerHeight }}>
            <div className='scroll-fade-pages-box' style={{ height: fadePageHeight }}>
                { renderFadePages() }
            </div>
            { renderFadePagesHolder() }
            { renderArrowDown() }
            { otherContent ? otherContent : null }
        </div>
    )
}