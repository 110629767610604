
// This file is generated. Don't modify it.
import React from 'react'
export default function CheckboxCheckedIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M7.3665145,10.6334855 L12.1759333,5.82406666 C12.4423512,5.55764884 12.4459411,5.1268205 12.184,4.856 L12.184,4.856 C11.9280348,4.591358 11.5059992,4.58432407 11.2413572,4.84028929 C11.238443,4.84310794 11.2355546,4.84595311 11.2326923,4.84882446 L6.656,9.44 L4.75840811,7.52958654 C4.49948216,7.26891109 4.07826199,7.26749284 3.81758654,7.52641879 C3.81705681,7.52694497 3.81652796,7.52747204 3.816,7.528 L3.816,7.528 C3.55495533,7.78904467 3.5539387,8.21196709 3.81372534,8.47426375 L5.9489096,10.6300778 C6.33755188,11.0224751 6.97070954,11.0255191 7.36310682,10.6368768 C7.36424543,10.6357491 7.36538133,10.6346187 7.3665145,10.6334855 Z M2,2 L14,2 L14,14 L2,14 L2,2 Z" id="CheckboxCheckedIcon-path-1"></path>
            </defs>
            <g id="Style" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Iconology" transform="translate(-120.000000, -348.000000)">
                    <g id="Icon/Checkbox" transform="translate(48.000000, 348.000000)">
                        <g id="Icon/Checkbox/Checked" transform="translate(72.000000, 0.000000)">
                            <g id="Group-3">
                                <mask id="CheckboxCheckedIcon-mask-2" fill="white">
                                    <use href="#CheckboxCheckedIcon-path-1"></use>
                                </mask>
                                <use id="check_box---material" fill={color} href="#CheckboxCheckedIcon-path-1"></use>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
