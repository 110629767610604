
// This file is generated. Don't modify it.
import React from 'react'
export default function ProfileWireFrameIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M6.19018218,8.1371976 C5.27223664,7.54305896 4.6647919,6.51010004 4.6647919,5.3352648 C4.6647919,3.49328023 6.15801543,2.0000567 8,2.0000567 C9.84198457,2.0000567 11.3352081,3.49328023 11.3352081,5.3352648 C11.3352081,6.51010004 10.7277634,7.54305896 9.80981782,8.1371976 C12.1021641,8.89582665 13.7560976,11.0562732 13.7560976,13.6030167 C13.7560976,13.7364344 13.7515584,13.8687919 13.7426261,13.9999433 L12.7407377,13.9999433 C12.7553912,13.8478153 12.7628882,13.6935955 12.7628882,13.5376247 C12.7628882,10.9071542 10.6304705,8.77473646 8,8.77473646 C5.36952946,8.77473646 3.23711176,10.9071542 3.23711176,13.5376247 C3.23711176,13.6935955 3.24460884,13.8478153 3.25926229,13.9999433 L2.25737388,13.9999433 C2.24844159,13.8687919 2.24390244,13.7364344 2.24390244,13.6030167 C2.24390244,11.0562732 3.89783592,8.89582665 6.19018218,8.1371976 Z M8,7.6704729 C9.28969982,7.6704729 10.3352081,6.62496462 10.3352081,5.3352648 C10.3352081,4.04556498 9.28969982,3.0000567 8,3.0000567 C6.71030018,3.0000567 5.6647919,4.04556498 5.6647919,5.3352648 C5.6647919,6.62496462 6.71030018,7.6704729 8,7.6704729 Z" id="ProfileWireFrameIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Wireframe/Profile">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="ProfileWireFrameIcon-mask-2" fill="white">
                            <use href="#ProfileWireFrameIcon-path-1"></use>
                        </mask>
                        <use id="Combined-Shape" fill={color} fillRule="nonzero" href="#ProfileWireFrameIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
