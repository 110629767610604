
import React from 'react'

import { useRadioGroupState } from 'react-stately';
import { useRadio, useRadioGroup } from 'react-aria';

import classNames from 'classnames';

import './ToggleGroup.less';

// item: { label, value, isDisabled, color }
export default function ToggleGroup({ 
    label = "toggle value", value, onChange, defaultValue, items, color, className, isDisabled,
    tabIndex = "0"
}) {
    
    return (
        <ToggleGroup_comp {...{
            label,
            value,
            color,
            onChange,
            className,
            tabIndex,
            isDisabled,
        }}>
            {
                items.map(it => {
                    const { value, label, color } = it;
                    return (
                        <Item value={value} key={value} color={color} isDisabled={isDisabled}>{ label }</Item>
                    )
                })
            }
        </ToggleGroup_comp>
    )
}


const RadioContext = React.createContext(null);

export function ToggleGroup_comp(props) {
    const { children, label, description, errorMessage, validationState, color, className, tabIndex, isDisabled } = props;
    const state = useRadioGroupState(props);

    const { radioGroupProps, labelProps, descriptionProps, errorMessageProps } =
        useRadioGroup(props, state);

    return (
        <div className={classNames("lc-toggle-group shadow", className, { "cursor-not-allowed opacity-60": isDisabled })} data-color={color}  tabIndex={tabIndex} {...radioGroupProps}>
            {/* <span {...labelProps}>{label}</span> */}
            <RadioContext.Provider value={state}>
                {children}
            </RadioContext.Provider>
            {description && (
                <div {...descriptionProps} style={{ fontSize: 12 }}>{description}</div>
            )}
            {errorMessage && validationState === 'invalid' &&
                (
                    <div {...errorMessageProps} style={{ color: 'red', fontSize: 12 }}>
                        {errorMessage}
                    </div>
                )}
        </div>
    );
}

function Item(props) {
    const { children, color, isDisabled } = props;
    const state = React.useContext(RadioContext);
    const ref = React.useRef(null);
    const { inputProps } = useRadio(props, state, ref);

    const { checked } = inputProps

    return (
        <label className={"toggle-item " + (isDisabled ? "cursor-not-allowed" : "cursor-pointer")} data-checked={checked} data-color={color}>
            <input {...inputProps} ref={ref} />
            {children}
        </label>
    );
}