import React from 'react'

import Modal from 'bwax-ui/components/Modal'
import SelectFileButton from 'bwax-ui/components/inputs/SelectFileButton';
import { UploadIcon } from '@radix-ui/react-icons';
import { Transforms } from 'slate'

export default function UploadImageModal({ addBlockModalOpen, onOpenChange, uploadImage, hideModal, editor, slatePath }) {

    return (
        <Modal isDismissable className="max-w-lg pt-3 px-2 pb-9" isOpen={addBlockModalOpen} onOpenChange={open => onOpenChange(open)}>
            <SelectFileButton
                className={"max-w-full w-80 self-center"}
                onSelectFile={file => {
                    if(file) {
                        uploadImage(file, (err, url) => {
                            const image = {
                                type: "image",
                                url,
                                children: [{ text: ""}],
                                align: 'center',
                                width: "100%",
                            }
                            Transforms.insertNodes(editor, image, { at: [slatePath[0] + 1]})
                        })
                        hideModal()
                    }
                }}
            >
                <UploadIcon style={{
                    width: 14,
                    marginRight: "0.5rem"
                }}></UploadIcon>
                选择图片
            </SelectFileButton>
        </Modal>
    )
}
