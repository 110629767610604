

import React, { useEffect, useRef, useState } from 'react'

import useResizeObserver from '@react-hook/resize-observer'

import './KnowledgeScopeDialog.less'
import TextInput from 'bwax-ui/components/inputs/TextInput';
import Tabs from 'bwax-ui/components/Tabs';
import Checkbox from 'bwax-ui/components/inputs/Checkbox';


import { MagnifyingGlassIcon } from '@radix-ui/react-icons';

import useDebounce from 'bwax-ui/legacy/page/hooks/useDebounce'


import KnowledgeSourceItem from './KnowledgeSourceItem';

import SearchBookmarks from './SearchBookmarks';
import SearchDocuments from './SearchDocuments';
import SearchNotes from './SearchNotes';

import SearchFolders from './SearchFolders';

import Modal from 'bwax-ui/components/Modal';

export default function KnowledgeScopeDialog({ open, onOpenChange, facade, scope, onChange, currentUserId }) {

    const [ editingKeyword, setEditingKeyword ] = useState("");

    const [ selected, setSelected ] = useState(scope);

    useEffect(() => {
        if(selected !== scope) {
            setSelected(scope);
        }
    }, [scope]);

    const keyword = useDebounce(editingKeyword, 600);

    function renderDocuments() {
        return (
            <SearchDocuments {...{
                facade, keyword, onSelectChange: setSelected, selected, currentUserId,
            }}/>
        )
    }

    function renderFolders() {
        return (
            <SearchFolders {...{
                facade, keyword, onSelectChange: setSelected, selected, currentUserId,
            }}/>
        )
    }

    function renderPageBookmarks() {
        return (
            <SearchBookmarks {...{
                facade, keyword, onSelectChange: setSelected, selected, currentUserId
            }} />
        )
    }

    function renderNotes() {
        return (
            <SearchNotes {...{
                facade, keyword, onSelectChange: setSelected, selected, currentUserId,
            }} />
        )
    }

    function renderSelected() {
        return (
            <SelectionPanel {...{
                facade, selected, onSelectChange: setSelected, currentUserId
            }} />
        )
    }

    const tabs = [
        { label: "文件夹", key: "folders", content: renderFolders() },
        { label: "文档", key: "documents", content: renderDocuments() },        
        { label: "网页", key: "bookmarks", content: renderPageBookmarks() },
        { label: "笔记", key: "notes", content: renderNotes() },
        { label: (<div className="selected-tab">已选{ selected ? <span className="badge">{ selected.length }</span> : null }</div>), key: "selected", content: renderSelected() },
    ]

    const dialogRef = useRef();
    const searchInputRef = useRef()

    const [ diaglogContentHeight, setDialogContentHeight ] = useState();
    const [ searchInputHeight, setSearchInputHeight ] = useState();
    useResizeObserver(dialogRef, entry => {
        setDialogContentHeight(entry.contentBoxSize[0].blockSize)

    });

    useResizeObserver(searchInputRef, entry => {
        setSearchInputHeight(entry.borderBoxSize[0].blockSize)
    });

    const renderContent = () => {
        return (
            <>
                <div className="search-input" ref={searchInputRef}>
                    <TextInput placeholder={"输入关键字搜索"} value={editingKeyword} onChange={setEditingKeyword} aria-label={"keyword"}
                        prefix={<MagnifyingGlassIcon />}
                    />
                </div>
                <div className="tab-container" style={ diaglogContentHeight && searchInputHeight ? ({ height: diaglogContentHeight - searchInputHeight - 16 }) : {}}>
                    {/* tabs "文档", "网页", "已选" */}
                    <Tabs tabs={tabs} defaultTab={"folders"} />
                </div>
            </>
        )
    }

    return (
        <Modal isOpen={open} isMain={true} onOpenChange={open => {
            onOpenChange(open);
            if(!open) {
                // save the new scope when the dialog is closed.
                onChange(selected);
                setEditingKeyword("");
            }

        }} className="lc-knowledge-scope-dialog" modalRef={dialogRef}
        >
            {renderContent()}
        </Modal>
    )
}

function SelectionPanel ({ selected, facade, onSelectChange }) {

    const unselect = id => {
        return onSelectChange(selected.filter(s => s.recordId !== id))
    }

    return (
        <div className="selection-panel">
            { selected.map(source => {
                const { recordId } = source;
                return (
                    <div className={"selected-item"} key={recordId} onClick={_ => {
                        // unselect(recordId)
                    }}>
                        <Checkbox color="violet" checked={true} 
                            onChange={checked => {
                                if(!checked) {
                                    unselect(recordId);
                                }
                            }}
                        />
                        <KnowledgeSourceItem { ...{ facade, source } } />
                    </div>
                )
            })}
        </div>
    )
    

}
