
// This file is generated. Don't modify it.
import React from 'react'
export default function SuccessIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M1.9885542,8 L1.9885542,8 C2.18381635,7.80473785 2.50039884,7.80473785 2.69566098,8 L6.23119489,11.5355339 C6.42645703,11.7307961 6.42645703,12.0473785 6.23119489,12.2426407 L6.23119489,12.2426407 C6.03593274,12.4379028 5.71935025,12.4379028 5.52408811,12.2426407 L1.9885542,8.70710678 C1.79329206,8.51184464 1.79329206,8.19526215 1.9885542,8 Z M14.0114458,3.75735931 L14.0114458,3.75735931 C14.2067079,3.95262146 14.2067079,4.26920395 14.0114458,4.46446609 L6.2332712,12.2426407 C6.03800906,12.4379028 5.72142657,12.4379028 5.52616442,12.2426407 L5.52616442,12.2426407 C5.33090228,12.0473785 5.33090228,11.7307961 5.52616442,11.5355339 L13.304339,3.75735931 C13.4996012,3.56209717 13.8161837,3.56209717 14.0114458,3.75735931 Z" id="SuccessIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Wireframe/Success">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="SuccessIcon-mask-2" fill="white">
                            <use href="#SuccessIcon-path-1"></use>
                        </mask>
                        <use id="Combined-Shape" fill={color} fillRule="nonzero" href="#SuccessIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
