
import React, { useEffect, useState } from 'react'

import SearchItemsCommon from './SearchItemsCommon';


import AddPageBookmarkButton from './input/AddPageBookmarkButton';


export default function SearchBookmarks({ keyword, onSelectChange, selected, facade }) {

    // 1. support pagination,
    // 2. a list of things, with checkboxes
    // 3. when checked / unchecked, call the onSelectChange({ name: true/false })

    //  { entityName, recordId, knowledgeCategoryKey }

    const [data, setData] = useState();

    const entityName = "OpenAI-网页收藏";


    async function loadData() {

        const [result, error] = await facade.list({
            entityName,
            search: keyword && keyword.trim().length > 0 ? { keyword, fields: ["标题"] } : undefined,
            condition: [[
                { field: "加入知识库", op: "eq", value: true }
            ]],
            sort: [
                { field: "创建时间", order: "DESC" }
            ],
            pageSize: 1000,
            fieldPaths: ["标题", "标签", "加入知识库"],
        });

        if (!error) {
            setData(result)
        }

    };

    useEffect(() => {
        loadData()
    }, [keyword]);

    function addNewBookmarkButton() {
        return (
            <AddPageBookmarkButton style={{ marginBottom: "1rem" }} onAdded={pageBookmark => {
                // 
                // 1. add to list, 
                // 2. select it
                setData(prev => [ pageBookmark, ...prev ]);
                onSelectChange([{ entityName, recordId: pageBookmark.id }, ...selected])
            }} />
        )
    }


    function renderItem(doc) {
        return (
            <div className="item-line">
                <i className='bx bx-link-alt'></i>
                <div className="item-title">{doc.标题}</div>
            </div>
        )
    }

    return (
        <>
            <SearchItemsCommon {...{
                items: data,

                actionButton: addNewBookmarkButton(),

                renderItem,
                isItemSelected: doc => selected.some(r => r.recordId == doc.id),
                selectItem: doc => {
                    onSelectChange([{ entityName, recordId: doc.id }, ...selected])
                },
                unselectItem: doc => {
                    onSelectChange(selected.filter(r => r.recordId !== doc.id))
                }
            }} />
        </>
    )
}
