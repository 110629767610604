

import { runDataQuery } from 'bwax/query/runClientQuery';
import Cookies from 'js-cookie'
import getTokenCookieName from 'bwax/query/getTokenCookieName';

export default function loadCurrentUser() {
    return async ({ tenantCode, endpoints, sessionToken, sandbox }) => {        
        //// the entity
        const sessionTokenCookieName = getTokenCookieName({ sandbox, tenantCode })
        function getPlatformSessionToken() {
            if (tenantCode) {
                // 
                const platformTokenCookieName = getTokenCookieName({ sandbox })
                const token = Cookies.get(platformTokenCookieName);
                return token
    
            }
            return undefined // already inside platform
        }

        if(!sessionToken && !Cookies.get(sessionTokenCookieName) && !getPlatformSessionToken() ) {
            return null
        };

        let queryText = getUserQueryText()

        const result = await runDataQuery({ tenantCode, endpoints, sessionToken, sandbox })(queryText)()
        const { data, errors } = JSON.parse(result)

        if (errors) {
            // TODO better error handling
            return null
        } else {
            return data && data.me && data.me.authUser ? data.me.authUser : null
        }
    }
}


const getUserQueryText = () => {
    
    return `
    query {
        me {
            authUser {
                id
                uid
            }
        }
    }
  `
}
