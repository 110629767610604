
// given
import React, { useEffect, useRef, useState, useContext } from "react";

import Button from 'bwax-ui/components/Button';
import LoadingCursor from 'bwax-ui/components/LoadingCursor';

import { executePrompt } from 'bwax-ui/openai/OpenAIClient';

// 这个 import 有点奇怪 TODO：
import { trimLanguageSign } from "bwax-ui/auxiliary/richtext_slate/editor/AIInput/AiInputMenu";

import UsageQuotaContext from 'bwax-ui/ml/widget/ports/inbot/UsageQuotaContext';

export default function TemplateExecution({
    templateName, variables, knowledgeOptions,
    maxTokenNum,    
    onConfirm, confirmText = "确定",

    transformRespondingText = trimLanguageSign,

}) {
    // 一进来就开始，只有一次
    const [isResponding, setIsResponding] = useState();

    const stopRef = useRef();

    const [respondingText, setRespondingText] = useState("");
    const [respondingStepMsg, setRespondingStepMsg] = useState("")


    const { reloadUsageQuota, facade } = useContext(UsageQuotaContext) || {};

    const dlc = facade.dlc;

    const respondingTextRef = useRef();
    respondingTextRef.current = respondingText;

    function onData(s, isEnd) {
        const newResponseText = respondingTextRef.current + s;

        if (isEnd) {

            setRespondingText(newResponseText);
            setRespondingStepMsg("");

            setTimeout(() => {
                setIsResponding(false);
                if (reloadUsageQuota) {                   
                    reloadUsageQuota()
                }
            }, 800)


        } else {
            setRespondingText(newResponseText);
            respondingTextRef.current = newResponseText;
        }
    }

    function onStep(stepStatus) {
        setRespondingStepMsg(stepStatus.msg);
    }

    function startGeneration() {
        setIsResponding(true);
        setRespondingText("");
        executePrompt({
            promptTemplateName: templateName,
            promptVariables: variables,
            knowledgeOptions,
            maxTokenNum,
            bindStopper: stop => stopRef.current = stop,
            dlc,
            onData,
            onStep,
        })
    }

    useEffect(() => {
        startGeneration();
    }, [])


    function renderResponseText () {
        return transformRespondingText(respondingText);
    }

    return (
        <div className="px-4 sm:px-6 pt-4 pb-8 flex flex-col gap-4">
            <div className="whitespace-pre-line">
                { renderResponseText() }
                { isResponding ? <LoadingCursor /> : null }
            </div>
            <div className="flex gap-2">
                {onConfirm ? (
                    <Button color={"grass"} isDisabled={isResponding || !respondingText} 
                        onPress={_ => onConfirm(transformRespondingText(respondingText))}>{confirmText}</Button>
                ) : null}
                <Button onPress={_ => {
                    if(isResponding) {
                        if(stopRef.current) {
                            stopRef.current();
                        }
                    } else {
                        startGeneration()
                    }
                }}>{isResponding ? "停止" : "重新生成"}</Button>
            </div>
        </div>
    )
}
