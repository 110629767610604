

// a common sign-up box

import React, { useEffect, useState } from 'react'


import getImageURL from 'bwax/util/getImageURL';

import TextInput from 'bwax-ui/components/inputs/TextInput';

import { runDataQuery } from 'bwax/query/runClientQuery'

import WeChatLoginQrCode from 'bwax-ui/components/WeChatLoginQrCode'

import Button, { Pressable } from 'bwax-ui/components/Button';
import setTokenCookie from 'bwax-ui/setTokenCookie'


import { useTrack } from 'bwax-ui/track';

// 如果有用户，自动登入；
// 提供手机验证码和微信登入两种；

// 自动创建用户
// 显示用户协议和隐私政策

// 登录完后，返回 rt 参数指向的页面，或者 /

export default function LogOn({ data, events, facade, viewEnv }) {

    const { 
        logoPC, logoMobile, currentUser, mainColor = "violet", description, siteName = "文档翻译", showAutoRegisterTip = true,  
        supportPhone = true,
    } = data;
    const { onSuccess = _ => { } } = events;

    const track = useTrack();

    // method: phone-code, password, qrcode
    const [method, setMethod] = useState(
        "qrcode"
    );

    // const methods = [
    //     { name: "phone-code", label: "手机验证码" },
    //     { name: "qrcode", label: "微信二维码" }
    // ];

    return (
        <div className="w-full h-full flex flex-col items-center sm:justify-center sm:bg-[var(--mauve2)] pt-8 sm:pt-0 sm:pb-12 font-size-14">
            <div className="sm:shadow-xl rounded-lg flex flex-col py-12 px-6 sm:px-6 bg-[var(--lc-color-bg-1)] gap-6 w-full max-w-sm transition-all">
                <div className="flex flex-col gap-4 min-h-[60px] items-center">
                    <div className="flex gap-2 flex sm:flex items-center">
                        <img className="h-[38px]" src={getImageURL(logoPC, "medium")}>
                        </img>
                        <div className="font-medium font-size-20">
                            { siteName }
                        </div>
                    </div>
                    {/* {description ? <div className="text-[var(--gray11)] px-1.5 whitespace-pre-line leading-relaxed">
                        {description}
                    </div> : null} */}
                </div>
                <div className="flex flex-col gap-4 px-1.5">
                    { method == "phone-code" ? (
                        <PhoneLogin {...{ 
                            facade, currentUser, mainColor,
                            onSuccess: _ => {
                                console.log(">>> phone logged in");
                                onSuccess()
                            }
                        }} />
                    ) : (
                        <div className="flex flex-col items-center py-1 pb-2">
                            <WeChatLoginQrCode {...{ 
                                facade, currentUser, mainColor, 
                                size: 160,
                                loadingElement: (
                                    <div className="h-[198px] w-[160px]">
                                    </div>
                                ),
                                showCountDown: true,
                                onSuccess: _ => {
                                    console.log(">>> logged in");
                                    onSuccess()
                                }
                            }} />
                        </div>
                    )}
                </div>
                <div className="px-1.5 flex flex-col gap-4">
                    {method == "phone-code" ? (
                        <Button appearance="outline" color={mainColor} size="xlarge" className="w-full" onPress={_ => {
                            track("logon_switch_to_qrcode")
                            setMethod("qrcode")
                        }}>
                            使用微信二维码登录
                        </Button>
                    ) : (
                        supportPhone ? (
                            <Button appearance="outline" color={mainColor} size="xlarge" className="w-full" onPress={_ => {
                                track("logon_switch_to_phone")
                                setMethod("phone-code")
                            }}>
                                使用手机验证码登录
                            </Button>
                        ) : null)
                    }
                    { showAutoRegisterTip ? <div className="font-size-13 text-[var(--gray9)] pl-2 self-center">登录会自动注册账号</div> : null }
                </div>
            </div>
        </div>
    )
}


function PhoneLogin({ facade, currentUser, mainColor, onSuccess }) {


    const track = useTrack();

    const [phoneNumber, setPhoneNumber] = useState("");
    const [veriCode, setVeriCode] = useState("");

    const [veriCodeSentForPhone, setVeriCodeSentForPhone] = useState({});

    const [veriCodeCountDown, setVerCodeCountDown] = useState(0);

    useEffect(() => {
        if (veriCodeCountDown > 0) {
            setTimeout(() => setVerCodeCountDown(prev => prev - 1), 1000)
        }
    }, [veriCodeCountDown])

    const [agreementChecked, setAgreementChecked] = useState(true);

    const isValidPhoneNumber = phoneNumber && /^\d{11}$/.test(phoneNumber);
    const isValidCode = !!(veriCode && /^\d{6}$/.test(veriCode));

    const isVeriCodeSent = veriCodeSentForPhone[phoneNumber] || true;

    const [errorMessage, setErrorMessage] = useState();

    useEffect(() => {
        setErrorMessage();
    }, [phoneNumber, veriCode]);

    const [veriCodeSending, setVeriCodeSending] = useState(false)
    async function sendVeriCode() {
        if (veriCodeCountDown > 0 || !isValidPhoneNumber) return null;

        setVeriCodeSending(true);
        const [result, error] = await facade.add({
            entityName: "短信-发送验证码请求",
            formData: {
                手机号: phoneNumber
            },
            fieldPaths: []
        });
        setVeriCodeSending(false);

        if (!error && result) {
            setVeriCodeSentForPhone(prev => ({
                ...prev, [phoneNumber]: true
            }));
            setVerCodeCountDown(60);

        } else {
            // TODO error handling
        }
    }

    const [isSigningIn, setIsSigningIn] = useState(false)
    async function tryToRegisterOrSignIn() {
        // 没有当前用户：
        setIsSigningIn(true);
        const [result, error] = await mobileLogin({ dlc: facade.dlc, phoneNumber, verificationCode: veriCode, autoRegister: true });
        setIsSigningIn(false);

        if (!error && result) {
            const { errMessage, authSession } = result;

            if (errMessage) {
                setErrorMessage(errMessage);
            } else if (authSession) {
                const { authUser, token } = authSession;
                setTokenCookie(token, facade.dlc);
                onSuccess({
                    userId: authUser.id,
                    nickName: authUser.nickName,
                    phoneNumber,
                })
            }
            //
        } else {
            // 
            // TODO error handling
        }
    }

    function convertErrorMessage(errorMessage) {
        if (errorMessage == "验证码已过期" || errorMessage == "验证码未发送" || errorMessage == "短信验证码已过期" || errorMessage == "短信验证码未发送") {
            return "验证码不正确"
        } else {
            return errorMessage
        }

    }


    const [staticContentToShow, setStaticContentToShow] = useState();
    function renderModalLink(text, code) {
        return (
            <Pressable id={"link-to-" + code} onPress={_ => {
                // open modal
                track("signup_click_agreement_link", { "content_code": code });
                setStaticContentToShow(code);

            }}>
                <span className="text-[var(--violet11)] cursor-pointer hover:underline mx-0.5">{text}</span>
            </Pressable>
        )
    }

    return (
        <>
            {/* 手机号，验证码，同意协议 */}
            <div className="flex flex-col gap-4">
                <TextInput styled={true} color={mainColor} className={"!p-3"} value={phoneNumber} onChange={setPhoneNumber} placeholder="输入您的手机号" maxLength={11} autoFocus />
                <div className="flex gap-4 items-center">
                    <div className="grow">
                        <TextInput styled={true} color={mainColor} className={"!p-3"} value={veriCode} onChange={setVeriCode} placeholder="输入验证码" maxLength={6} />
                    </div>
                    <div className="h-full py-[0.5px]">
                        <Button className="!h-full !w-[108px] px-1" color={mainColor}
                            id="btn-send-vericode"
                            isDisabled={!isValidPhoneNumber || veriCodeCountDown > 0}
                            isLoading={veriCodeSending}
                            onPress={_ => {
                                track("signup_send_vericode");
                                sendVeriCode()
                            }}
                        >
                            {veriCodeCountDown > 0 ? `${veriCodeCountDown} 秒` : "获取验证码"}
                        </Button>
                    </div>
                </div>
                <div className="h-[22px] -mt-2">
                    {errorMessage ? (
                        <div className="text-[var(--tomato9)] px-2 font-size-13">{convertErrorMessage(errorMessage)}</div>
                    ) : null}
                </div>
            </div>
            <div className="flex flex-col gap-4 pb-1.5">
                {/* <div className="flex gap-1.5">
                    <div className="translate-y-1 sm:translate-y-[2px]">
                        <Checkbox color={mainColor} checked={agreementChecked} onChange={checked => {
                            setAgreementChecked(checked);
                        }}></Checkbox>
                    </div>
                    <div className="font-size-13">我已阅读和同意 {renderModalLink("服务协议", "terms-of-service")}和{renderModalLink("隐私政策", "privacy-policy")}</div>
                </div> */}
                <div className="flex flex-col gap-3">
                    <Button appearance="primary" color={mainColor} size="xlarge" className="w-full"
                        isLoading={isSigningIn}
                        isDisabled={!(isValidPhoneNumber && isValidCode && agreementChecked && isVeriCodeSent) || errorMessage}
                        id="btn-register-phone"
                        onPress={_ => {
                            // try to register      
                            track("signup_register_phone");

                            tryToRegisterOrSignIn()

                        }}
                    >登录账户</Button>
                </div>

            </div>
            {/* {renderMergeConfirmation()} */}
            {/* {staticContentToShow ? <StaticContentModal code={staticContentToShow} facade={facade} onClose={_ => setStaticContentToShow()} /> : null} */}
        </>
    )

}


function extractError(error) {
    if (Array.isArray(error) && error.length > 0 && error[0] && error[0].message) {
        return error[0].message
    } else {
        return error
    }
}

async function mobileLogin({ phoneNumber, verificationCode, autoRegister = true, dlc }) {

    const runQuery = runDataQuery(dlc);

    const queryText = `
      mutation ($input: MobileLoginInput!) {
        mobileLogin(input: $input) {
          errMessage
          authSession {
            token
            authUser {
              id
              nickName

            }
          }
        }
      }      
    `
    const result = await runQuery(queryText)({
        input: {
            phoneNumber,
            verificationCode,
            autoRegister,
            clientMutationId: `${Date.now()}`
        }
    });

    const { errors, data } = JSON.parse(result);

    // console.log(">>> data", data)


    return [data && data.mobileLogin, extractError(errors)];

}
