
// This file is generated. Don't modify it.
import React from 'react'
export default function CourseIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <rect id="CourseIcon-path-1" x="0" y="0" width="16" height="16"></rect>
                <path d="M11,3.41666667 L11,8.5 L9.00304251,7.5 L7,8.5 L7,3.41666667 L4,3.41666667 L4,12.5833333 L12,12.5833333 L12,3.41666667 L11,3.41666667 Z M4,2.5 L12,2.5 C12.5522847,2.5 13,2.91040565 13,3.41666667 L13,12.5833333 C13,13.0895944 12.5522847,13.5 12,13.5 L4,13.5 C3.44771525,13.5 3,13.0895944 3,12.5833333 L3,3.41666667 C3,2.91040565 3.44771525,2.5 4,2.5 Z M10,3.5 L8,3.5 L8,6.88306492 L9.00372283,6.3819658 L10,6.88086334 L10,3.5 Z" id="CourseIcon-path-3"></path>
            </defs>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Course">
                    <g>
                        <mask id="CourseIcon-mask-2" fill="white">
                            <use href="#CourseIcon-path-1"></use>
                        </mask>
                        <mask id="CourseIcon-mask-4" fill="white">
                            <use href="#CourseIcon-path-3"></use>
                        </mask>
                        <use id="Combined-Shape" fill={color} fillRule="nonzero" href="#CourseIcon-path-3"></use>
                        <g id="Color/UI/Text" mask="url(#CourseIcon-mask-4)" fill={color} fillRule="nonzero">
                            <rect id="light-gray" x="0" y="0" width="16" height="16"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
