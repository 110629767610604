// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var SwiperLazyJs = require("./SwiperLazy.js");
var Plate$BwaxMobile = require("../../../bwax-js/ml/plate.bs.js");
var BaseUI$BwaxMobile = require("../BaseUI.bs.js");

var make = SwiperLazyJs.default;

var SwiperLazy = {
  make: make
};

function Swiper$Comp(Props) {
  var pc = Props.pc;
  var match = Props.rules;
  var rules = match !== undefined ? match : /* [] */0;
  var theme = Props.theme;
  var activeIndex = Props.activeIndex;
  var onActiveIndexChange = Props.onActiveIndexChange;
  var slides = Props.slides;
  var options = Props.options;
  var inner = function (param, param$1, param$2, key) {
    return React.createElement(make, {
                activeIndex: activeIndex,
                onActiveIndexChange: onActiveIndexChange,
                slides: Curry._1(Plate$BwaxMobile.List.to_array, Plate$BwaxMobile.List.indexed_map((function (i, f, j) {
                            var key = String(i);
                            var el = Curry._1(f, j);
                            return Curry._4(el, theme, /* box */4898315, /* [] */0, key);
                          }), slides)),
                options: options,
                key: key
              });
  };
  var toCss = function (r) {
    return BaseUI$BwaxMobile.ruleToCss(r, theme, /* Item_box */3, pc, /* [] */0);
  };
  return BaseUI$BwaxMobile.renderEl("swiper-box", rules, "no-key", theme, pc, toCss, inner(theme, /* box */4898315, rules, "no-key"));
}

var Comp = {
  make: Swiper$Comp
};

function create(key, rules, theme, pc, activeIndex, onActiveIndexChange, slides, options) {
  return React.createElement(Swiper$Comp, {
              pc: pc,
              rules: rules,
              theme: theme,
              activeIndex: activeIndex,
              onActiveIndexChange: onActiveIndexChange,
              slides: slides,
              options: options,
              key: key
            });
}

exports.SwiperLazy = SwiperLazy;
exports.Comp = Comp;
exports.create = create;
/* make Not a pure module */
