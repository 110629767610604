
import React, { useEffect } from 'react';
import InlineStyleButton from '../util/InlineStyleButton';

import { MdFormatBold } from 'react-icons/md'

export default function BoldButton(props) {

    const { editor } = props

    return InlineStyleButton({
        editor,
        style: 'bold',
        children: (
            <MdFormatBold style={{ fontSize: 16 }}/>
        ),
    })
};
