

import React, { useRef, useState } from 'react'

import './MenuAndMain.less';

// // import component 👇
// import Drawer from 'react-modern-drawer'
// //import styles 👇
// import 'react-modern-drawer/dist/index.css'

import Drawer from 'bwax-ui/components/Drawer';
import useStateWithLocalCache from 'bwax-ui/hooks/useStateWithLocalCache';

import { Pressable } from 'bwax-ui/components/Button';

import classNames from 'classnames'
import useResizeObserver from '@react-hook/resize-observer';

export default function MenuAndMain(props) {

    // 通常使用在
    const {
        menuDrawerVisible, closeMenuDrawer, mainContent, resizeKey = "key", mainContentClassName, mainContentStyle, menuPosition = "left",

        defaultMenuWidth = 200,

        // menu or render menu
        renderMenu, menu,

        collapsedMenuIcon,

        // menuCollapsed = false,
    } = props;

    const [menuCollapsed, setMenuCollapsed] = useStateWithLocalCache("lc-menu-and-main::menu-collapsed::" + resizeKey, false);

    const [menuWidth, setMenuWidth] = useStateWithLocalCache("lc-menu-and-main::menu-width::" + resizeKey, defaultMenuWidth);

    const [resizerActive, setResizerActive] = useState(false);

    const minWidth = 100;
    const maxWidth = 600;

    function updateMenuWidth(e) {
        setMenuWidth(prev => {
            const newSize = menuPosition == "left" ? prev + e.movementX : prev - e.movementX;
            return newSize < minWidth ? minWidth : (newSize > maxWidth ? maxWidth : newSize);
        })
    }

    function getMenuWidth() {
        return menuWidth
    }



    const [containerLeft, setContainerLeft] = useState();
    const ref = useRef();
    useResizeObserver(ref, entry => {
        const left = entry.target.getBoundingClientRect().left;
        if(containerLeft !== left) {
            setContainerLeft(left);
        }
    });

    const [menuPopped, setMenuPopped] = useState(false);

    const shouldHideMenuRef = useRef(false);
    const shouldPopMenuRef = useRef(false);

    const justHiddenRef = useRef(false);

    function renderCollapsedMenu() {
        return (
            <div className="w-10 h-full relative wide-only">
                <Pressable onPress={_ => setMenuCollapsed(false)}>
                    <div
                        onMouseEnter={_ => {
                            shouldPopMenuRef.current = true
                            setTimeout(() => {
                                if(shouldPopMenuRef.current) {
                                    setMenuPopped(true)
                                    shouldPopMenuRef.current = false;
                                    justHiddenRef.current = false;
                                }
                            }, 200)

                            // prevent other "leave" from hidding menu
                            shouldHideMenuRef.current = false

                            // remove the "just-being-hidden" mark
                            justHiddenRef.current = false;

                        }}
                        onMouseLeave={_ => {
                            shouldHideMenuRef.current = true;

                            // cancel the quick exit
                            shouldPopMenuRef.current = false;

                            setTimeout(() => {
                                if(shouldHideMenuRef.current) {
                                    setMenuPopped(false);
                                    shouldHideMenuRef.current = false;
                                    justHiddenRef.current = true;
                                }
                            }, 300)
                        }}
                        className={classNames(
                            "py-2 px-2 font-size-20 text-[var(--gray11)] hover:bg-[var(--gray2)] active:bg-[var(--gray3)]",
                            "rounded cursor-pointer flex items-center absolute top-2 left-2",
                            // "bg-[var(--gray2)]"
                        )}>
                        {collapsedMenuIcon}
                    </div>
                </Pressable>
            </div>
        )
    }

    const menuPart = (
        <>
            {
                menuCollapsed ? renderCollapsedMenu() : null
            }
            <div
                className={classNames("menu-part wide-only", {
                    "absolute top-[3.125rem] bg-[var(--lc-color-bg-1)] shadow-lg h-[90vh] border-[0.5px] rounded duration-100": menuCollapsed,

                    "ease-out": menuCollapsed && menuPopped,
                    "ease-in": menuCollapsed && !menuPopped
                })} 
                onMouseEnter={_ => {
                    // if just trigger the leave from menu / icon, shouldn't enter again
                    if(justHiddenRef.current) {
                        justHiddenRef.current = false;
                        return 
                    }
                    setMenuPopped(true);

                    // prevent other "leave" from hidding menu
                    shouldHideMenuRef.current = false;
                }}
                onMouseLeave={_ => {
                    shouldHideMenuRef.current = true
                    setTimeout(() => {
                        if(shouldHideMenuRef.current) {
                            setMenuPopped(false);
                            shouldPopMenuRef.current = false;

                            // set the "just-hidden" mark
                            justHiddenRef.current = true;
                        }
                    }, 300)
                }}
                style={{
                    width: getMenuWidth(),
                    minWidth: getMenuWidth(),

                    left: menuPopped ? 8 : (containerLeft !== undefined ? 0 - containerLeft - getMenuWidth() - 300 : -99999)
                }}>
                {renderMenu ? renderMenu(false, { setMenuCollapsed, menuCollapsed }) : menu}
            </div>

            <Drawer
                open={menuDrawerVisible}
                onOpenChange={open => {
                    if (!open) {
                        closeMenuDrawer()
                    }
                }}
                position='right'
                style={{
                    width: "85vw"
                }}
            >
                {renderMenu ? renderMenu(true, { setMenuCollapsed, menuCollapsed }) : menu}
            </Drawer>
        </>
    )

    const resizeLine = menuCollapsed ? null : (
        <div className="resize-line wide-only"
            onMouseDown={() => {
                setResizerActive(true);
            }}
        />
    )


    const contentPart = (
        <div className={classNames("main-content", mainContentClassName)} style={mainContentStyle}>
            {mainContent}
        </div>
    )

    return (
        <div className="lc-layout-menu-and-main" ref={ref}
            data-menu={menuPosition}
            onMouseMove={e => {
                if (resizerActive) {
                    updateMenuWidth(e)
                }
            }}
            onMouseUp={() => {
                if (resizerActive) {
                    setResizerActive(false);
                }
            }}
        >
            {contentPart}
            {resizeLine}
            {menuPart}

            {/* {menuPosition == "left" ?
                <>
                    {menuPart}
                    {resizeLine}
                    {contentPart}
                </> :
                <>
                    {contentPart}
                    {resizeLine}
                    {menuPart}
                </>
            } */}
        </div>
    )
}
