// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var GetImageURL = require("bwax-ui/getImageURL");
var QrCode = require("bwax-ui/auxiliary/QrCode");

var Bridge = { };

function QrCode$1(Props) {
  var value = Props.value;
  var size = Props.size;
  var className = Props.className;
  var icon = Props.icon;
  var iconUrl = GetImageURL.default(icon, "small");
  return React.createElement(QrCode.default, {
              info: value,
              size: size,
              className: className,
              icon: iconUrl
            });
}

var make = QrCode$1;

exports.Bridge = Bridge;
exports.make = make;
/* react Not a pure module */
