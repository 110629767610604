
// This file is generated. Don't modify it.
import React from 'react'
export default function BellIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M11.9375,7.0625 C11.9375,7.50000219 11.9531248,7.90103984 11.984375,8.265625 C12.0156252,8.63021016 12.0468748,8.93749875 12.078125,9.1875 C12.1093752,9.43750125 12.1822911,9.68749875 12.296875,9.9375 C12.4114589,10.1875013 12.4843748,10.3697911 12.515625,10.484375 C12.5468752,10.5989589 12.6562491,10.7552073 12.84375,10.953125 C13.0312509,11.1510427 13.1354166,11.2656248 13.15625,11.296875 C13.1770834,11.3281252 13.317707,11.4479156 13.578125,11.65625 C13.838543,11.8645844 13.9791666,11.9791666 14,12 L2,12 C2.06250031,11.9374997 2.1770825,11.8437506 2.34375,11.71875 C2.5104175,11.5937494 2.61979141,11.5052086 2.671875,11.453125 L2.953125,11.171875 C3.08854234,11.0364577 3.18229141,10.9270838 3.234375,10.84375 C3.28645859,10.7604162 3.36458281,10.6406258 3.46875,10.484375 C3.57291719,10.3281242 3.64583312,10.1822923 3.6875,10.046875 C3.72916688,9.91145766 3.78124969,9.73437609 3.84375,9.515625 C3.90625031,9.29687391 3.94791656,9.07291781 3.96875,8.84375 C3.98958344,8.61458219 4.01041656,8.34895984 4.03125,8.046875 C4.05208344,7.74479016 4.0625,7.41666844 4.0625,7.0625 C4.0625,4.54165406 5.12498937,3.10416844 7.25,2.75 L7.25,2.6875 C7.25,2.49999906 7.32291594,2.33854234 7.46875,2.203125 C7.61458406,2.06770766 7.79166562,2 8,2 C8.20833438,2 8.38541594,2.06770766 8.53125,2.203125 C8.67708406,2.33854234 8.75,2.49999906 8.75,2.6875 L8.75,2.75 C10.8750106,3.06250156 11.9375,4.49998719 11.9375,7.0625 Z M9.09375,13.625 C8.78124844,13.8750013 8.41666875,14 8,14 C7.58333125,14 7.21875156,13.869793 6.90625,13.609375 C6.59374844,13.348957 6.41666688,13.0312519 6.375,12.65625 L9.625,12.65625 C9.58333312,13.0312519 9.40625156,13.3541653 9.09375,13.625 Z" id="BellIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Filling/Bell">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="BellIcon-mask-2" fill="white">
                            <use href="#BellIcon-path-1"></use>
                        </mask>
                        <use id="ion-ios-bell---Ionicons" fill={color} href="#BellIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
