import loadable from "@loadable/component"

import addPrefix from 'bwax-ui/addPrefix'

const AuditIcon = loadable(() => import ('./AuditIcon'));
const CommunicationIcon = loadable(() => import ('./CommunicationIcon'));
const FinancingIcon = loadable(() => import ('./FinancingIcon'));
const DiscussionIcon = loadable(() => import ('./DiscussionIcon'));
const InvestigationIcon = loadable(() => import ( './InvestigationIcon'));
const InvestIcon = loadable(() => import ('./InvestIcon'));
const PostInvestmentIcon = loadable(() => import ('./PostInvestmentIcon'));
const RegisterIcon = loadable(() => import ('./RegisterIcon'));
const ReplayIcon = loadable(() => import ('./ReplayIcon'));
const RiskEvaluateIcon = loadable(() => import ('./RiskEvaluateIcon'));
const SignIcon = loadable(() => import ('./SignIcon'));
const VoteIcon = loadable(() => import ('./VoteIcon'));
const SubmitIcon = loadable(() => import ('./SubmitIcon'));

export default addPrefix('howitworks', {
    AuditIcon,
    CommunicationIcon,
    FinancingIcon,
    DiscussionIcon,
    InvestigationIcon,
    InvestIcon,
    PostInvestmentIcon,
    RegisterIcon,
    ReplayIcon,
    RiskEvaluateIcon,
    SignIcon,
    VoteIcon,
    SubmitIcon
})