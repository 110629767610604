
// This file is generated. Don't modify it.
import React from 'react'
export default function HeartWireFrameIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M9.62908323,11.1080303 C10.7719666,10.0727909 11.6803027,9.101048 12.1793915,8.32290655 C12.7855848,7.37582892 13,6.86683831 13,6.11169524 C13.0000433,4.6121504 11.9139811,3.42416089 10.627207,3.42416089 C9.93112187,3.42416089 9.26268343,3.77751635 8.80226935,4.39626327 L7.99555471,5.48040081 L7.19459239,4.39200654 C6.74342371,3.77893225 6.07100189,3.42416089 5.37286332,3.42416089 C4.086116,3.42416089 3,4.6121779 3,6.1117241 C3,6.86683424 3.21442684,7.37588607 3.82219736,8.32550766 C4.31802645,9.10544857 5.22464915,10.0766086 6.36761968,11.1099975 C6.97413811,11.6583664 7.60825212,12.1863926 7.99983574,12.4947596 C8.36809583,12.2040467 9.01799307,11.6615641 9.62908323,11.1080303 Z M7.19774238,3.20232307 L7.44521702,3.20297832 C7.64971997,3.37687165 7.83621743,3.5767279 8.00000586,3.79929317 C8.16468343,3.57798427 8.35088839,3.37912395 8.55435541,3.20591504 L8.80541933,3.20657979 C8.7741689,3.16411489 8.74227512,3.12229965 8.7097579,3.08114617 C9.26357911,2.6622397 9.9281396,2.42416089 10.6271953,2.42416089 C12.4880967,2.42416089 14.0000586,4.07978859 14,6.1117241 C14,7.32267023 13.4595529,8.1778289 13.0216372,8.86200039 C11.7491066,10.8460356 8.56783291,13.3226644 8.43094467,13.4253247 C8.30096299,13.5279381 8.15044925,13.5758384 7.99994723,13.5758384 C7.84335523,13.5760602 7.69135077,13.5229783 7.56893807,13.4253247 C7.43217882,13.3226644 4.24398681,10.8529421 2.97829241,8.86200039 C2.54044705,8.17788752 2,7.3226585 2,6.1117241 C2,4.07978859 3.51202052,2.42416089 5.37286332,2.42416089 C6.07131189,2.42416089 6.73847728,2.66179414 7.29189957,3.08001482 C7.25993576,3.12015562 7.22854423,3.1609287 7.19774238,3.20232307 Z" id="HeartWireFrameIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Wireframe/Heart">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="HeartWireFrameIcon-mask-2" fill="white">
                            <use href="#HeartWireFrameIcon-path-1"></use>
                        </mask>
                        <use id="Path" fill={color} fillRule="nonzero" href="#HeartWireFrameIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
