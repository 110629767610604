
import React from 'react'

import createBlockTypeButton from './util/createBlockTypeButton';


export default createBlockTypeButton({
    blockType: 'h2',
    children: (
        "H2"
    ),
});
