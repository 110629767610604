
// This file is generated. Don't modify it.
import React from 'react'
export default function CommentIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M8,2.35464997 C4.68660714,2.35464997 2,4.63813211 2,7.45598926 C2,9.10063211 2.91875,10.5591143 4.33973214,11.4912571 C4.25535714,11.9131321 4.03973214,12.7341143 3.56026786,13.3876857 C3.47857143,13.4988464 3.56160714,13.6555428 3.69955357,13.6448285 C4.33973214,13.5992928 5.70044643,13.37965 6.83214286,12.4582214 C7.21785714,12.5238464 7.60892857,12.5559893 8,12.5559893 C11.3133929,12.5559893 14,10.2725071 14,7.45464997 C14,4.63679283 11.3133929,2.35464997 8,2.35464997 Z" id="CommentIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Filling/Comment">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="CommentIcon-mask-2" fill="white">
                            <use href="#CommentIcon-path-1"></use>
                        </mask>
                        <use id="Path" fill={color} href="#CommentIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
