
import React, { useEffect, useState, useRef, useContext } from 'react'

import UploadFile from 'bwax-ui/actions/UploadFile';

import { downloadURL } from 'bwax-ui/ml/FrontEndHelper';

import SelectFileButton from 'bwax-ui/components/inputs/SelectFileButton'
import SelectInput from 'bwax-ui/components/inputs/SelectInput';

import Button, { Pressable, iconDropdownMenu } from 'bwax-ui/components/Button';

import BeatLoader from 'react-spinners/BeatLoader';
import ScaleLoader from 'react-spinners/ScaleLoader';

import ProgressBar from 'bwax-ui/components/ProgressBar';

import Loading from 'bwax-ui/components/Loading'

import { toast } from 'bwax-ui/components/Toast';

import { addQueryParams, removeQueryParam } from 'bwax/ml/lang/mod/builtin/StringHelper';
import classNames from 'classnames';

import { getFileIcon, getFileType } from "bwax-ui/components/FileIcon";

import DualFileView, { PDFView } from './DualFileView';

import useScreenSize from 'bwax-ui/auxiliary/useScreenSize'

import BalanceContext from './BalanceContext';
import Pay_translate from './Pay_translate';

import Modal from 'bwax-ui/components/Modal'

import ClampedText from 'bwax-ui/components/ClampedText';

import TranslationListSimple from './TranslationListSimple'

import TranslationShareSetting from './TranslationShareSetting'

import qs from 'query-string';

import { useTrack } from 'bwax-ui/track';

import { viewSupported } from 'bwax-ui/components/FileView';
import getImageURL from 'bwax/util/getImageURL';

import handleReferral from './handleReferral'
import TranslationInvitation from './TranslationInvitation';

import TranslateEdit from './TranslateEdit'

import DropdownMenu from 'bwax-ui/components/DropdownMenu';

import RichText from 'bwax-ui/basic/RichText';

import Checkbox from 'bwax-ui/components/inputs/Checkbox';
import TranslationTaskFlags from './TranslationTaskFlags';

const fieldPaths = [
    "文档", "源语言", "源语言词数", "目标语言", "状态", "进度", "翻译后文档", "翻译后双语文档", "价格", "已支付", "创建者", "免费预览量", "模型", "术语表",
    "有缓存", "已应用的翻译缓存修订",
    "背景修复", "翻译图片", "已应用模型",
]


export default function Translate({ data, events, slots, facade, viewEnv }) {

    const { mainColor = "violet", taskId: givenTaskId, currentUser, mpQrCode, } = data;

    const { routeTo, webEnv } = viewEnv;

    const entityName = "文档翻译-任务";

    const [prepared, setPrepared] = useState(false);

    const screenSize = useScreenSize();

    async function prepare() {
        await facade.prepare([entityName]);
        setPrepared(true)
    }

    useEffect(() => {
        prepare();
        handleReferral({ currentUserId: currentUser.id, facade });
    }, []);

    const entity = facade.entities.find(e => e.name == entityName);

    const { balance, reloadBalance } = useContext(BalanceContext);

    const accept = (() => {
        const field = entity ? entity.fields.find(f => f.name == "文档") : undefined;
        if (!field || !field.options) {
            return ""
        } else {
            return field.options.accept;
        }
    })() //'.pdf,.docx,.pptx,.xlsx,.json,.txt,.epub'

    const [attachment, setAttachment] = useState(_ => {
        // const v = localStorage.getItem("translate::test::attachment");
        // if (v) {
        //     return JSON.parse(v)
        // }
    });

    const [isUploading, setIsUploading] = useState(false);

    const track = useTrack();

    const [uploadProgress, setUploadProgress] = useState(0);
    async function uploadFile(lf) {

        setIsUploading(true)

        const [attachment, errors] = await UploadFile({
            file: lf, uploadFor: "translate",
            onUploadProgress: evt => {
                const { loaded, total } = evt;
                setUploadProgress(loaded * 100 / total)
            }
        })(facade.dlc);
        setIsUploading(false);
        setUploadProgress(0)

        if (errors || !attachment) {
            // onError(errors || "上传失败");
            return
        }
        setAttachment(attachment)
        // localStorage.setItem("translate::test::attachment", JSON.stringify(attachment))
    }

    const [sourceLang, setSourceLang] = useState();
    const [targetLang, setTargetLang] = useState();

    function swapLang() {
        setSourceLang(targetLang);
        setTargetLang(sourceLang);
    }

    const [task, setTask] = useState();

    const [isAdding, setIsAdding] = useState(false);

    const [usingGlossary, setUsingGlossary] = useState();

    const [glossaries, setGlossaries] = useState([]);

    const [selectedGlossaryId, setSelectedGlossaryId] = useState();

    const [selectedGlossary, setSelectedGlossary] = useState();

    useEffect(() => {
        (async () => {
            const [result, error] = await facade.list({
                entityName: "术语表", condition: [[{ field: "创建者.id", op: "eq", value: currentUser.id }]],
                fieldPaths: ["名称"],
                sort: [{ field: "创建时间", order: "DESC" }],
                pageSize: 1000,
            });
            if (result) {
                setGlossaries(result);
                if(result.length > 0) {
                    setSelectedGlossaryId(result[0].id)
                }
            }
        })();
    }, []);

    useEffect(() => {
        if (selectedGlossaryId) {
            (async () => {
                const [result, error] = await facade.findById(selectedGlossaryId, {
                    entityName: "术语表", condition: [[{ field: "创建者.id", op: "eq", value: currentUser.id }]],
                    fieldPaths: ["名称", "内容"],
                });
                if (result) {
                    setGlossaries(prev => prev.map(p => p.id == result.id ? result : p))
                    setSelectedGlossary(result);
                }
            })();
        }
    }, [selectedGlossaryId]);



    async function addTranslation() {
        setIsAdding(true);
        const [result, error] = await facade.add({
            entityName,
            formData: {
                文档: attachment, 源语言: sourceLang, 目标语言: targetLang,
                术语表: usingGlossary && selectedGlossary ? selectedGlossary.内容 : undefined,
            },
            fieldPaths
        });
        setIsAdding(false);

        if (error || !result) {
            // error handing
            toast(error || "出错了");
            return
        }

        setTask(result);
        routeTo("/u/task/" + result.id);
    }

    const givenTaskIdRef = useRef();
    givenTaskIdRef.current = givenTaskId;

    async function loadTranslation(id, isInitial) {
        const [result, error] = await facade.findOne({
            entityName, fieldPaths
            , condition: [[
                { field: "id", op: "eq", value: id },
                { field: "已归档", op: "ne", value: true }
            ]]
        }, { forceRefreshing: true });

        if (givenTaskIdRef.current != id) {
            return // 
        }
        if (result) {
            setTask(result);
            if (isInitial) {
                setAttachment(result.文档);
            }
        } else {
            console.log(">>> no task",)
            toast("没找到指定的翻译文档");
            routeTo("/u/task");
        }

    }

    const [lastTranslation, setLastTranslation] = useState();
    async function loadLastTranslation() {
        const [result, error] = await facade.findOne(
            {
                entityName, fieldPaths: ["源语言", "目标语言"], sort: [{ field: "创建时间", order: "DESC" }],
                condition: [[{ field: "创建者.id", op: "eq", value: currentUser.id }]],
            },
            { forceRefreshing: true, processSelectValue: false }
        );
        if (!error && result) {
            setLastTranslation(result)
        }
    }

    useEffect(() => {
        if (givenTaskId) {
            // load task
            if (task && givenTaskId == task.id) {
                console.log(">> There's no need to load")
            } else {
                loadTranslation(givenTaskId, true)
            }
        } else {
            // clear the current translation

            loadLastTranslation();

            setTask();
            setAttachment();
            setSourceLang();
            setTargetLang();
        }
    }, [givenTaskId]);

    function renderSelectGlossary(isDisabled) {
        return (
            <SelectInput {...{
                items: [
                    ...glossaries.map(o => ({
                        value: o.id,
                        label: o.名称,
                        // isDisabled: disabledValues.indexOf(o.value) !== -1
                    })),
                    // { value: "createNew", label: "-- 添加术语表 --"} // 应该弹起 Modal 来添加，不要跳走
                ],
                placeholder: "选择术语表",
                style: { minWidth: "6rem" },
                selected: selectedGlossaryId,
                onSelect: v => {
                    if (v == "createNew") {
                        routeTo("/u/glossary")
                    } else {
                        setSelectedGlossaryId(v)
                    }
                },
                className: "grow"
            }} />
        )
    }



    const [isShowingPriceDesc, setIsShowingPriceDesc] = useState(false);
    function renderPriceDesc () {
        if (isShowingPriceDesc) {
            return (
                <Modal isDismissable className="px-4 max-w-3xl" isMain={true} isOpen={true} onOpenChange={open => {
                    if (!open) {                        
                        setIsShowingPriceDesc(false);
                    }
                }}>
                    {
                        closeModal => (
                            <HelpInfo {...{
                                task, facade, name: "价格说明",
                                title: "价格说明", icon: <i className="bx bx-wallet" />,
                            }} />
                        )
                    }
                </Modal>
            )
        }
        return null
    }


    function renderInputs() {

        if (!entity) {
            return null;
        }

        const sourceLangField = entity.fields.find(f => f.name == "源语言");
        const targetLangField = entity.fields.find(f => f.name == "目标语言");

        function renderSelect(field, value, onChange, placeholder = "请选择", disabledValues = []) {
            return (
                <SelectInput {...{
                    items: field.options.options.map(o => ({
                        value: o.value,
                        label: o.name,
                        isDisabled: disabledValues.indexOf(o.value) !== -1
                    })),
                    placeholder,
                    style: { minWidth: "8rem" },
                    selected: value,
                    onSelect: onChange,
                }} />
            )
        }


        if (isAdding) {
            return (
                <div className="px-4 flex flex-col gap-12 justify-center items-center text-[var(--gray10)]">
                    <ScaleLoader size={44} color="var(--violet8)"></ScaleLoader>
                    <div className="flex flex-col gap-2 items-center">
                        <div>正在分析文档</div>
                        <div>大概需要 10 秒到 60 秒</div>
                    </div>
                </div>
            )
        }


        return (
            <>
                <div className="px-4 flex flex-col gap-10 justify-center items-center">
                    <div className="flex flex-col gap-2 max-w-[17rem] w-full">
                        <div className="flex flex-col gap-2">
                            {/* <div className="flex flex-col gap-1 px-2">
                            <div className="text-[var(--slate12)]">被翻译的语言</div>
                            <div className="font-size-13 text-[var(--slate10)]">只有这种语言的文字会被翻译</div>
                        </div> */}
                            {renderSelect(sourceLangField, sourceLang, setSourceLang, "选择被翻译的语言")}
                        </div>
                        <div className="self-center cursor-pointer" onClick={_ => {
                            swapLang()
                        }} >
                            <i className='bx bx-transfer-alt text-xl bx-rotate-90 text-[var(--slate9)] opacity-70'></i>
                        </div>
                        <div className="flex flex-col gap-3">
                            {/* <div className="flex flex-col gap-1 px-2">
                            <div className="text-[var(--slate12)]">翻译成那种语言？</div>
                        </div> */}
                            {renderSelect(targetLangField, targetLang, setTargetLang, "选择目标语言")}
                        </div>
                        {
                            lastTranslation ? (
                                <div className="flex text-[var(--gray11)] font-size-12 pt-4 self-center items-center">
                                    <span className="">上一次选择:</span>
                                    <Pressable onPress={_ => {
                                        track("translate_select_last_lang_pair")
                                        setSourceLang(lastTranslation.源语言);
                                        setTargetLang(lastTranslation.目标语言);
                                    }}>
                                        <div className="text-[var(--blue11)] cursor-pointer rounded px-1 py-0.5">
                                            {displayLang("源语言", lastTranslation.源语言)} <i className='bx bx-right-arrow-alt translate-y-[2px]'></i> {displayLang("目标语言", lastTranslation.目标语言)}
                                        </div>
                                    </Pressable>
                                </div>
                            ) : null
                        }
                        {(glossaries && glossaries.length > 0) ? (
                            <div className="w-full flex items-center gap-3 pt-6 px-1">
                                <Checkbox color="violet" checked={usingGlossary}
                                    onChange={checked => {
                                        setUsingGlossary(checked)
                                    }}
                                />
                                {usingGlossary ? renderSelectGlossary(!usingGlossary) : (
                                    <div className="text-[var(--gray11)] h-[33px] flex items-center px-2">使用术语表</div>
                                )}
                            </div>
                        ) : null}
                    </div>

                    <div className="max-w-[17rem] w-full flex flex-col gap-6">
                        <Button appearance="primary" color={mainColor} className="w-full shadow-md" size="large"
                            isDisabled={!attachment || isUploading || !sourceLang || !targetLang || (selectedGlossaryId && !selectedGlossary)}
                            isLoading={isAdding} onPress={_ => {
                                // console.log(">>> start translation");
                                track("translate_start")
                                addTranslation();
                            }}>
                            开始翻译
                        </Button>
                        <div className={classNames("text-[var(--gray10)] font-size-12 self-center flex flex-col gap-1.5", {
                            "pb-[24px]": !usingGlossary
                        })}>
                            <div>* 免费翻译大约 1500 token</div>
                            <div>* 大约每 3000 token 花费 1 积分（≈￥1）</div>
                            {usingGlossary ? (
                                <>
                                    <div>* 使用术语表，价格<strike className="px-0.5">增加 30%</strike>保持不变</div>
                                    <div>* 翻译时，术语会被提供给大模型作为示例</div>
                                </>
                            ) : null}
                            <Pressable onPress={_ => {
                                track("translate_view_price_desc");
                                setIsShowingPriceDesc(true);
                            }}>
                                <div className="cursor-pointer py-0.5 px-2.5 text-[var(--blue11)] rounded self-start">
                                    查看价格说明
                                </div>
                            </Pressable>
                        </div>
                    </div>

                </div>

            </>

        )
    }

    function renderUploadPanel() {
        return (
            <div className="flex flex-col sm:h-full justify-center relative">
                <div className="px-12 flex flex-col gap-8">
                    <SelectFileButton {...{
                        accept,
                        asButton: false,
                        className: `h-[260px] w-full rounded flex flex-col items-center justify-center gap-2 border-dashed border-2 border-[var(--violet8)] bg-[var(--violet2)]`,
                        color: mainColor,

                        isUploading,
                        onSelectFile: file => {
                            track("translate_select_file");
                            uploadFile(file)
                        }
                    }}
                    >
                        {
                            isUploading ? (
                                <div className="flex flex-col gap-6 items-center w-full px-4">
                                    <BeatLoader size={8} color="var(--violet8)" />
                                    <ProgressBar barClassName="!h-1.5" progress={uploadProgress}></ProgressBar>
                                </div>
                            ) : (
                                <div className={`flex flex-col gap-2 text-[var(--${mainColor}11)] items-center`}>
                                    <i className={`bx bx-upload text-4xl text-[var(--violet9)]`}></i>
                                    <div>上传文档</div>
                                    <div className="opacity-60 font-size-12 px-4 break-all text-center">{accept}</div>
                                </div>
                            )
                        }
                    </SelectFileButton>
                    <div className="text-[var(--gray10)] font-size-12 self-center">
                        * 文件不能超过 500m
                    </div>
                </div>
                <div className="h-1/4"></div>
            </div>
        )
    }


    const leftScrollToRef = useRef();
    const rightScrollToRef = useRef();

    const pendingPayment = task && task.价格 > 0 && !task.已支付;

    function statusIn(...array) {
        return task && array.indexOf(task.状态) != -1;
    }

    const translatedFile = task && task.翻译后文档;

    const isGeneratingPreview = task && pendingPayment && statusIn("Waiting", "Processing") // 有 task、待支付、task.状态 == Waiting / Processing 没有文件，有进度
    const isPreviewGenerated = task && pendingPayment && statusIn("Completed") && translatedFile;  // 有 task、待支付、task.状态 == Completed、有文件

    const isTranslating = task && !pendingPayment && statusIn("Waiting", "Processing"); // 有 task, 已支付, task.状态 == Waiting / Processing, 有进度，没文件
    const isTranslated = task && !pendingPayment && statusIn("Completed") && translatedFile; // 有 task, 已支付, task.状态 == Completed, 有文件

    const hasFailed = task && statusIn("Terminated"); // 有 task, task.状态 == "Terminated"
    const isCancelled = task && statusIn("Cancelled"); // 有 task, task.状态 == "Cancelled"
    const isNotSupported = task && statusIn("NotSupported") // 有 task, task.状态 == "NotSupported"

    const isPendingFix = task && statusIn("PendingFix");

    const balanceRef = useRef();
    balanceRef.current = balance;

    const taskRef = useRef();
    taskRef.current = task;

    useEffect(() => {
        if (task && (isGeneratingPreview || isTranslating)) {
            setTimeout(() => {
                loadTranslation(task.id);
            }, 1000);
        }
    }, [task]);

    async function finishTranslation(task) {
        if (!isPreviewGenerated) {
            console.log(">>> not generated preview, shouldn't be here");
            return
        }

        // 这里要再 load 一下 task 并检查 balance
        const [r, e] = await facade.findById(task.id, { entityName: "文档翻译-任务", fieldPaths: ["价格", "模型"] }, { forceRefreshing: true });

        if (!r || r.价格 > balanceRef.current) {
            if (taskRef.current && taskRef.current.id == task.id) {
                setTask(prev => ({
                    ...prev, ...r
                }))
            }
            return
        }

        track("translate_finish");

        const [result, error] = await facade.customMutation({
            entityName: "消费记录",
            interfaceName: "执行任务",
            args: [task.id],
            outputFieldPaths: [
                ["文档翻译-任务", fieldPaths]
            ]
        });
        if (error) {
            toast({ title: error })
        } else if (result) {
            setTask(result);

            setTimeout(() => {
                reloadBalance();
            }, 1000);
        }
    };

    async function restartTask(task, additionalFormData = {}) {
        // 可以这里直接改吗？有权限隐患？
        const [result, error] = await facade.update({
            entityName: "文档翻译-任务", fieldPaths, formData: { ...additionalFormData, 状态: "Waiting" }, id: task.id
        })

        if (error) {
            toast({ title: error })
        } else if (result) {
            setTask(result);
            // setTimeout(() => {
            //     reloadBalance();
            // }, 1000);
        }
        return [result, error]
    }

    const [shouldPay, setShouldPay] = useState(false);

    async function tryFinishTranslation(task) {

        // 这里从新 load 一下 balance
        const balance = await reloadBalance();

        if (balance && balance >= task.价格) {
            // 直接
            finishTranslation(task);
        } else {
            // 弹起或者转入支付页面：
            if (webEnv.isWeChat) {

                const url = addQueryParams({
                    rt: webEnv.currentURL,
                    sp: task.价格 - balance,
                    price: task.价格,
                }, "/ext/pay/checkout");

                routeTo(url);


            } else {
                console.log(">>> 弹起支付页面")
                setShouldPay(true)
            }

        }
    }


    useEffect(() => {
        if (typeof (window) != "undefined") {
            const { search } = window.location;
            const params = qs.parse(search);

            const isReturnFromPayment = params["after-payment"] || params["out_trade_no"];

            if (isReturnFromPayment && task && task.已支付 == false && balance >= task.价格) {
                // 直接
                finishTranslation(task)
                // routeTo(removeQueryParam("after-payment", webEnv.currentURL));
            }
        }
    }, [task]);


    const displayLang = (fieldName, value) => {
        const field = entity && entity.fields.find(f => f.name == fieldName);
        if (field && field.options && field.options.options) {
            const o = field.options.options.find(o => o.value == value);
            return o ? o.name : value
        } else {
            return value
        }
    }


    const [isSettingShare, setIsSettingShare] = useState(false);

    const [isSharing, setIsSharing] = useState(false);
    useEffect(() => {
        if (task) {
            // reload the is sharing
            (async () => {
                //
                const entityName = "分享";
                const fieldPaths = [
                    "已暂停",
                ];
                const [existingShare, error] = await facade.findOne({
                    entityName, condition: [[{ field: "翻译.id", op: "eq", value: task.id }]], fieldPaths,
                });
                setIsSharing(existingShare && !existingShare.已暂停);
            })();
        }
    }, [task && task.id, isSettingShare]);


    function actionButtons() {
        return (
            <div className="flex gap-2">
                {task ? iconDropdownMenu(<i className='bx bx-download'></i>, [
                    {
                        label: "下载原文", onSelect: _ => {
                            track("translate_download_original_file", { method: "select" });
                            downloadURL(task.文档.url)
                        }
                    },
                    task.翻译后文档 ? {
                        label: "下载译文", onSelect: _ => {
                            track("translate_download_translated_file",  { method: "select" });
                            downloadURL(task.翻译后文档.url)
                        }
                    } : null,
                ], { placement: "bottom end" }) : null}

                {task && viewSupported(task.文档, task.源语言词数) && task.源语言 !== "日语" ?
                    iconButton(<i className='bx bx-share bx-flip-horizontal'></i>, () => {
                        setIsSettingShare(true);
                    }, isSharing)
                    : null}
                {task.翻译后双语文档 ? (
                    <Button size="small" onPress={() => {
                        track("translate_download_dualingo_file");
                        downloadURL(task.翻译后双语文档.url)
                    }}>
                        下载双语文档
                    </Button>
                ) : null}
            </div>
        )
    }




    function renderTranslationSummary(task) {

        return (
            <div className="flex gap-1.5 items-center break-keep">
                <div className="text-[var(--gray11)]">
                    {displayLang("源语言", task.源语言)} <i className='bx bx-right-arrow-alt translate-y-[2px]'></i> {displayLang("目标语言", task.目标语言)}
                </div>
                <TranslationTaskFlags {...{ task, facade }} />
            </div>

        )
    }

    function renderRecentTranslations(withEmpty) {
        return (
            <TranslationListSimple {...{
                currentUserId: currentUser.id, listSize: 3, facade, viewEnv,
                title: "您之前上传的文档",
                className: "max-w-[32rem] w-full",

                empty: withEmpty ? renderInputs() : null,
            }} />
        )
    }

    const [invitationShown, setInvitationShown] = useState(false);

    function renderFinishLabel(task) {
        const price = task.价格;
        const startingPrice = 7;
        if (price < startingPrice) {
            return (
                <span>
                    完整翻译 {price} 积分 <strike className="font-size-12">{startingPrice} 积分</strike>
                </span>
            )
        } else {
            return (
                <span>
                    完整翻译 {price} 积分
                </span>
            )
        }
    }

    const [isUpdatingModel, setIsUpdatingModel] = useState(false);
    async function updateTaskModel(task, model) {

        setIsUpdatingModel(true);
        const [result, error] = await facade.update({
            entityName: "文档翻译-任务", fieldPaths: ["模型", "价格"], formData: { 模型: model }, id: task.id
        });
        setIsUpdatingModel(false);

        if (error) {
            toast({ title: error })
        } else if (result) {
            setTask(prev => {
                if (prev && prev.id == result.id) {
                    return { ...prev, ...result }
                } else {
                    return prev
                }
            });
        }
    }

    function renderFullStart(task, { className, size = "large", configHeight, showConfig = true } = {}) {
        const usingGPT4 = task.模型 == "gpt-4";

        return (
            <div className={classNames("flex gap-2 items-start", className)}>
                {showConfig ? <Pressable onPress={_ => {
                    track("translate_toggle_gpt_4");
                    updateTaskModel(task, usingGPT4 ? "gpt-3.5-turbo" : "gpt-4")

                }}>
                    <div data-color={usingGPT4 ? "blue" : "gray"} className={classNames("shadow !px-2 flex gap-1 items-center rounded cursor-pointer font-size-12", {
                        "text-[var(--gray9)] bg-[var(--gray1)] hover:bg-[var(--gray3)]": !usingGPT4,
                        "text-[var(--blue11)] bg-[var(--blue2)] hover:bg-[var(--blue3)] ": usingGPT4,
                    })}
                        style={{
                            height: 39
                        }}>
                        GPT4
                        {usingGPT4 ? <i className='bx bx-check-circle' /> : <i className='bx bx-minus-circle'></i>}
                    </div>
                </Pressable> : null}

                <Button className="grow shadow-md !h-[40px]" size={size} color={usingGPT4 ? "blue" : "grass"} appearance={"primary"} isDisabled={isUpdatingModel} onPress={() => {
                    track("translate_try_finish")
                    tryFinishTranslation(task);
                }}>
                    {renderFinishLabel(task)}
                </Button>

            </div>
        )
    }


    const [isEditing, setIsEditing] = useState(false);

    function renderEditModal() {
        if (isEditing && task) {
            return (
                <Modal className="px-4 max-w-5xl" isMain={true} isOpen={true} onOpenChange={open => {
                    if (!open) {
                        setIsEditing(false);
                    }
                }}>
                    {
                        closeModal => (
                            <TranslateEdit {...{
                                task, facade,
                                title: "人工修订译文", icon: <i className="bx bx-edit" />,
                                submitEdit: async task => {
                                    const [r, e] = await restartTask(task);
                                    if (!e) {
                                        closeModal()
                                    }
                                }
                            }} />
                        )
                    }
                </Modal>
            )
        }
        return null
    }

    const [isToOCR, setIsToOCR] = useState(false);
    function renderToOCRModal() {
        if (isToOCR && task) {
            return (
                <Modal className="px-4 max-w-2xl" isMain={true} isOpen={true} onOpenChange={open => {
                    if (!open) {
                        setIsToOCR(false);
                    }
                }}>
                    {
                        closeModal => (
                            <TranslateOptionChange {...{
                                task, facade, name: "强制OCR",
                                buttonLabel: "启用 OCR 重新翻译",
                                title: "强制 OCR 识别", icon: <i className="bx bx-image" />,
                                confirmChange: async _ => {
                                    const [r, e] = await restartTask(task, { 翻译图片: true });
                                    if (!e) {
                                        closeModal()
                                    }
                                }
                            }} />
                        )
                    }
                </Modal>
            )
        }
        return null
    }

    const [isToFixBG, setIsToFixBg] = useState(false);
    function renderToFixBGModal() {
        if (isToFixBG && task) {
            return (
                <Modal className="px-4 max-w-2xl" isMain={true} isOpen={true} onOpenChange={open => {
                    if (!open) {
                        setIsToFixBg(false);
                    }
                }}>
                    {
                        closeModal => (
                            <TranslateOptionChange {...{
                                task, facade, name: "修复背景", buttonLabel: "尝试修复背景",
                                title: "修复PDF背景", icon: <i className="bx bx-wrench" />,
                                confirmChange: async _ => {
                                    const [r, e] = await restartTask(task, { 背景修复: true });
                                    if (!e) {
                                        closeModal()
                                    }
                                }
                            }} />
                        )
                    }
                </Modal>
            )
        }
        return null
    }

    const [isToCancelFixBG, setIsToCancelFixBg] = useState(false);
    function renderToCancelFixBGModal() {
        if (isToCancelFixBG && task) {
            return (
                <Modal className="px-4 max-w-2xl" isMain={true} isOpen={true} onOpenChange={open => {
                    if (!open) {
                        setIsToCancelFixBg(false);
                    }
                }}>
                    {
                        closeModal => (
                            <TranslateOptionChange {...{
                                task, facade, name: "取消背景修复", buttonLabel: "取消背景修复",
                                title: "取消背景修复", icon: <i className="bx bxs-wrench" />,
                                confirmChange: async _ => {
                                    const [r, e] = await restartTask(task, { 背景修复: false });
                                    if (!e) {
                                        closeModal()
                                    }
                                }
                            }} />
                        )
                    }
                </Modal>
            )
        }
        return null
    }


    function renderAdjustment(task, { className, size = "large", } = {}) {

        const fileType = getFileType(task.文档);

        const items = [
            fileType !== "pdf" ? null : (
                task.背景修复 ? {
                    label: "取消背景修复", icon: <i className="bx bxs-wrench" />,
                    onSelect: _ => {
                        track("translate_cancel_fix_bg")
                        setIsToCancelFixBg(true)
                    }
                } : {
                    label: "修复PDF背景", icon: <i className="bx bx-wrench" />,
                    onSelect: _ => {
                        track("translate_try_fix_bg")
                        setIsToFixBg(true)
                    }
                }
            ),
            task.翻译图片 || fileType !== "pdf" || task.已支付 ? null : {
                label: "强制OCR识别", icon: <i className="bx bx-image" />,
                onSelect: _ => {
                    track("translate_try_force_orc");
                    setIsToOCR(true)
                }
            },
            task.有缓存 ? {
                label: "人工修订译文", icon: <i className="bx bx-edit" />,
                onSelect: _ => {
                    track("translate_try_edit_translation");
                    setIsEditing(true)
                }
            } : null,
        ].filter(x => !!x);

        if (items.length == 0) {
            return null;
        }

        return (
            <div className={classNames("flex gap-2 items-start", className)}>
                <DropdownMenu placement="top" itemClassName="!py-3 !pl-5 !pr-8" items={items}>
                    <div className="grow shadow-md !h-[40px] cursor-pointer rounded bg-[var(--color-3)] hover:bg-[var(--color-4)] active:bg-[var(--color-5)] flex gap-2 justify-center items-center text-[var(--color-11)]"
                        size={size} data-color={"grass"}>
                        <i className="bx bx-cog" />
                        {"调整翻译选项"}
                    </div>
                </DropdownMenu>
            </div>
        )

    }


    function renderRight(showView, withEmpty) {

        function displayProgress(progress, tip) {

            const shouldShowQrCode = currentUser.isWeChatUser && false; // 暂时不用显示了，因为微信登录都先要关注
            return (
                <>
                    <div className="px-12 flex flex-col gap-12 justify-center items-center">
                        <ProgressBar color={"violet"} progress={progress}
                            {...({
                                type: "circle", style: { width: "9rem", height: "9rem", fontSize: "2.5rem" }
                            })}
                        />
                        <div className="text-[var(--gray10)] font-size-12 self-center max-w-lg flex flex-col gap-8 items-center">
                            <div className="flex flex-col gap-2">
                                <span>{tip}</span>
                                <span>* 您可以离开或关闭页面</span>
                                <span>* 之后可在历史页面找回翻译结果</span>
                                {currentUser.isWeChatUser ? <span>* 也可以在公众号获得通知</span> : null}
                            </div>
                            {shouldShowQrCode ? (
                                <div className="flex flex-col gap-2">
                                    <div>您可以扫描二维码关注公众号获得通知</div>
                                    <div className="h-[160px] self-center">
                                        <img className="h-full" src={getImageURL(mpQrCode, "small")} />
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {shouldShowQrCode ? <div className="hidden sm:block h-[10%]" /> : <div className="hidden sm:block h-1/4" />}
                </>
            )
        }

        function displayError(error, canRetry) {
            return (
                <div className="w-full h-full flex flex-col justify-center">
                    <div className="px-12 flex flex-col gap-12 justify-center items-center">
                        <i className='bx bx-error text-[6rem] text-[var(--gray8)]'></i>
                        <div className="text-[var(--gray10)] font-size-14 self-center max-w-lg">
                            {error || "出错了"}
                        </div>
                        {
                            canRetry ? (
                                <Button className="shadow-md" onPress={_ => {
                                    // 不用检测价格
                                    restartTask(task);
                                }} color={mainColor}>点击重试</Button>
                            ) : (
                                <Button onPress={_ => routeTo("/u/task")} color={"grass"} className="shadow-md">重新上传</Button>
                            )
                        }
                    </div>
                    <div className="hidden sm:block h-1/4"></div>
                </div>
            )
        }

        function displayViewOrSummary() {
            if (showView && viewSupported(task.翻译后文档, task.源语言词数)) {
                return (
                    <>
                        <PDFView file={translatedFile} onScrollTo={s => {
                            if (leftScrollToRef.current) {
                                leftScrollToRef.current(s)
                            }
                        }} bindScrollTo={scrollTo => rightScrollToRef.current = scrollTo} />
                        <div className="absolute bottom-24 w-full flex flex-col items-center gap-4">
                            {renderAdjustment(task, { className: "w-[17rem]" })}
                            {pendingPayment ? (
                                renderFullStart(task, { className: "w-[17rem]", size: "large" })
                            ) : null}
                        </div>
                    </>
                )
            } else {
                return (
                    <>
                        <div className="flex justify-center sm:grow items-center px-4">
                            <div className="max-w-[17rem] w-full flex flex-col gap-6">
                                <div className="w-full flex flex-col gap-3 px-1">
                                    {renderTranslationSummary(task)}
                                    {renderTitle(task.翻译后文档, false, "translated_file" )}
                                </div>

                                <div className="flex flex-col gap-8 py-2">
                                    <div className="flex flex-col gap-4">
                                        {task.翻译后双语文档 ? (
                                            <Button className="w-full shadow" size="large" onPress={() => {
                                                track("translate_download_dualingo_file");
                                                downloadURL(task.翻译后双语文档.url)
                                            }}>
                                                <i className='bx bx-download mr-2'></i>下载双语文档
                                            </Button>
                                        ) : null}
                                        {task && viewSupported(task.文档, task.源语言词数) && task.源语言 !== "日语" ? (
                                            <Button className="w-full shadow" size="large" onPress={() => {
                                                setIsSettingShare(true);
                                            }}>
                                                <i className='bx bx-share bx-flip-horizontal mr-2'></i>
                                                分享翻译内容
                                            </Button>
                                        ) : null}
                                    </div>
                                    {pendingPayment ? (
                                        <div className="w-full flex flex-col gap-5">
                                            {renderFullStart(task, { className: "w-full", size: "large", configHeight: 39 })}
                                            <Pressable onPress={_ => {
                                                track("translate_open_invitation")
                                                setInvitationShown(true)
                                            }
                                            }>
                                                <div className="text-[var(--indigo10)] font-size-12 self-center px-2 py-1 rounded">
                                                    {"邀请朋友获得积分 >>"}
                                                </div>
                                            </Pressable>

                                        </div>
                                    ) : null}

                                </div>
                            </div>
                        </div>
                        <div className="hidden sm:block h-1/4"></div>
                    </>
                )
            }
        }

        if (isGeneratingPreview) {
            const freeNum = task.免费预览量;
            return displayProgress(task.进度, `正在翻译前 ${freeNum} token，请稍等`)

        } else if (isTranslating) {
            return displayProgress(task ? task.进度 : 0, "正在翻译整个文档，时间可能会比较久，请稍等")

        } else if (isPreviewGenerated) {
            return displayViewOrSummary()

        } else if (isTranslated) {
            return displayViewOrSummary()

        } else if (hasFailed) {
            return displayError(task.错误信息, true)

        } else if (isCancelled) {
            return displayError("任务已取消")
        } else if (isNotSupported) {
            return displayError("文件不支持")
        } else if (isPendingFix) {
            return displayError("正在处理")
        } else if (attachment) {
            return (
                <>
                    {renderInputs()}
                    <div className="hidden sm:block h-1/4"></div>
                </>
            )
        } else {
            return (
                <>
                    {renderRecentTranslations(withEmpty)}
                    <div className="hidden sm:block h-1/4"></div>
                </>
            )
        }
    }

    function iconButton(icon, onPress, isActive, className) {
        return (
            <Pressable onPress={onPress}>
                <div className={classNames("rounded w-[2rem] h-[2rem] flex justify-center items-center text-[18px]", className, {
                    "hover:bg-[var(--gray3)] text-[var(--gray11)]": !isActive,
                    "hover:bg-[var(--violet4)] text-[var(--violet9)] bg-[var(--violet3)]": isActive,
                })}>
                    {icon}
                </div>
            </Pressable>

        )
    }

    function renderTitle(file, clamped, downloadKey) {
        return (
            <div className="flex gap-2">
                <div className="pt-0.5 font-size-16 mt-0.5">
                    {getFileIcon(file)}
                </div>
                <div className="break-all leading-relaxed">
                    {clamped ? <ClampedText tipEnabled={false}>{file.title}</ClampedText> : (
                        <span>
                            { file.title }
                            { downloadKey ? (
                                iconButton(<i className='bx bx-download'></i>, () => {
                                    track("translate_download_" + downloadKey, { method: "icon_after_title"});
                                    downloadURL(file.url)
                                }, false, "!inline-flex ml-2 translate-y-0.5")
                            ) : null }
                        </span>
                    )}
                </div>
            </div>
        )

    }

    function renderUploadedFile(file, showView) {

        // 假定它的词符数可能大于 10 万。
        if (showView && viewSupported(file, task ? task.源语言词数 : 100001)) {
            return (
                <div className="h-full flex flex-col">
                    <div className="h-[3rem] flex items-center pl-4 pr-4">
                        {renderTitle(file, true)}
                    </div>
                    <div className="relative grow">
                        <PDFView file={file} onScrollTo={s => {
                            if (rightScrollToRef.current) {
                                rightScrollToRef.current(s)
                            }

                        }} bindScrollTo={scrollTo => leftScrollToRef.current = scrollTo} />
                        {task ? null : (
                            <div className="absolute bottom-24 w-full flex justify-center">
                                <SelectFileButton className="max-w-[17rem] w-full shadow-md " size="large" color={"violet"} isUploading={isUploading} accept={accept} onSelectFile={file => {
                                    uploadFile(file)
                                }}>
                                    重新上传
                                </SelectFileButton>
                            </div>
                        )}
                    </div>
                </div>
            )
        } else {
            // mobile，或者不支持 view 的文件类型
            return (
                <div className="flex flex-col sm:h-full justify-center relative pt-4 pb-2 gap-6 sm:gap-24 self-center sm:pt-[4rem] max-w-[17rem] w-full">
                    <div className="flex flex-col gap-3 px-1">
                        <div className="text-[var(--gray11)]">
                            您上传的文档
                        </div>
                        {renderTitle(file, false, "original_file")}
                    </div>
                    {task ? null : (
                        <div className="flex justify-center w-full">
                            <SelectFileButton className="w-full " size="large" isUploading={isUploading} accept={accept} onSelectFile={file => {
                                uploadFile(file)
                            }}>
                                重新上传
                            </SelectFileButton>
                        </div>
                    )}
                    <div className="h-1/4 hidden sm:block"></div>
                </div>
            )
        }
    }


    function renderLeftAndRight() {
        return (
            <>
                <div className={classNames("w-1/2 h-full border-[var(--gray5)] flex flex-col", {
                    "border-r": !(isPreviewGenerated || isTranslated)
                })}>
                    {attachment ? renderUploadedFile(attachment, true) : renderUploadPanel()}
                </div>
                <div className="w-1/2 h-full flex flex-col">
                    {
                        (isPreviewGenerated || isTranslated) && viewSupported(task.翻译后文档, task.源语言词数) ? (
                            <>
                                <div className="h-[3rem] flex items-center justify-between gap-2 pl-3 pr-3">
                                    {renderTranslationSummary(task)}
                                    {actionButtons()}
                                </div>
                                <div className="relative grow">
                                    {renderRight(true, true)}
                                </div>
                            </>
                        ) : (
                            <div className="h-full flex flex-col justify-center relative">
                                {renderRight(true, true)}
                            </div>
                        )
                    }
                </div>
            </>
        )
    }

    const [isModalPreviewing, setIsModalPreviewing] = useState(false);

    function renderUpAndDown() {
        // 这是 Mobile 版本
        return (
            <>
                <div className={classNames("w-full border-[var(--gray5)] flex flex-col", {
                    // "border-r": !attachment || true
                    "pt-12 pb-6": !attachment,
                    "py-6": attachment,
                })}>
                    {attachment ? (
                        renderUploadedFile(attachment, false)
                    ) : renderUploadPanel()}
                </div>

                <div className="w-full flex flex-col py-6 gap-12 items-center">
                    <div className="w-full flex flex-col">
                        {
                            isPreviewGenerated || isTranslated ? (
                                <>
                                    <div className="relative grow">
                                        {renderRight(false)}
                                    </div>
                                </>
                            ) : (
                                <div className="flex flex-col justify-center relative">
                                    {renderRight(false)}
                                </div>
                            )
                        }
                    </div>
                    {task && viewSupported(task.翻译后文档, task.源语言词数) && (isPreviewGenerated || isTranslated) ? (
                        <div className="w-full max-w-[17rem]">
                            <Button className="w-full shadow-md gap-3 flex" size="xlarge" color={"violet"} onPress={_ => setIsModalPreviewing(true)}>
                                <i className='bx bx-book-open'></i>查看翻译结果
                            </Button>
                        </div>
                    ) : null}
                </div>

            </>
        )
    }


    return (
        <>
            {screenSize == "L" ? (
                <div className="w-full h-full pl-2 hidden sm:flex">
                    {(() => {
                        if (givenTaskId && task === undefined) {
                            return (
                                <div className="w-full h-full flex justify-center items-center">
                                    <Loading></Loading>
                                </div>
                            )
                        } else if (givenTaskId && task === null) {
                            return (
                                <div className="w-full h-full flex justify-center items-center">
                                    <div className="text-[var(--gray11)]">没找到数据</div>
                                </div>
                            )
                        } else if (givenTaskId && task && task.创建者.id !== currentUser.id) {
                            return (
                                <div className="w-full h-full flex justify-center items-center">
                                    <div className="text-[var(--gray11)]">这不是您的文件</div>
                                </div>
                            )
                        }
                        return renderLeftAndRight();
                    })()}
                </div>
            ) : (
                <div className="w-full h-full flex flex-col sm:hidden pt-12">
                    {(() => {
                        if (givenTaskId && task === undefined) {
                            return (
                                <div className="w-full h-full flex justify-center items-center">
                                    <Loading></Loading>
                                </div>
                            )
                        } else if (givenTaskId && task === null) {
                            return (
                                <div className="w-full h-full flex justify-center items-center">
                                    <div className="text-[var(--gray11)]">没找到数据</div>
                                </div>
                            )
                        } else if (givenTaskId && task && task.创建者.id !== currentUser.id) {
                            return (
                                <div className="w-full h-full flex justify-center items-center">
                                    <div className="text-[var(--gray11)]">这不是您的文件</div>
                                </div>
                            )
                        }
                        return renderUpAndDown();
                    })()}

                </div>
            )}

            {!shouldPay ? null : (
                <Modal className="max-w-xl px-2 pt-2 pb-2" isMain={true} isOpen={true} contentClassName={"h-full"} onOpenChange={open => {

                    if (!open) {
                        // check balance:
                        // 如果够的的话直接开始支付, 不够就等
                        (async () => {
                            const balance = await reloadBalance();
                            const task = taskRef.current;

                            balanceRef.current = balance;

                            if (task && task.已支付 == false && balance && balance >= task.价格) {
                                // 直接
                                finishTranslation(task);
                            }
                            setShouldPay();

                        })();
                    }
                }}>
                    {
                        closeModal => (
                            <Pay_translate {...{
                                data: { currentUserId: currentUser.id, currentUserNickName: currentUser.nickName, balance, price: task.价格 },
                                events: { reloadBalance },
                                facade, viewEnv,
                                closeModal

                            }} />
                        )
                    }
                </Modal>
            )}
            {isModalPreviewing && task ? (
                <Modal isDismissable className="!pt-6" isMain={true} isOpen={true} contentClassName={"h-full sm:h-[90vh]"} onOpenChange={open => {
                    if (!open) {
                        setIsModalPreviewing(false);
                    }
                }}>
                    {
                        closeModal => {

                            const fileIcon = getFileIcon(task.文档);

                            const title = (
                                <div className="flex gap-1 items-center">
                                    <div className="font-size-16 mr-1 ">
                                        {fileIcon}
                                    </div>
                                    <ClampedText tipEnabled={true}>{task.文档.title}</ClampedText>
                                </div>
                            )

                            const flags = (
                                <div className="flex gap-1 items-center">
                                    <TranslationTaskFlags {...{ task, facade, clickable: false }} />
                                </div>
                            )

                            return (
                                <div className="w-full h-full flex flex-col">
                                    <div className="flex gap-2 justify-between px-4 py-3 w-full">
                                        {title}
                                        <div className="flex gap-2">
                                            {flags}
                                        </div>
                                    </div>
                                    <div className="grow">
                                        <DualFileView
                                            leftFile={task.文档} rightFile={task.翻译后文档} maxPages={15}
                                            button={
                                                <>
                                                    {renderAdjustment(task, { className: "w-full" })}
                                                    {
                                                        pendingPayment ? (
                                                            renderFullStart(task, { className: "w-full", configHeight: 39 })
                                                        ) : null
                                                    }
                                                </>
                                            }
                                        />
                                    </div>
                                </div>
                            )

                        }
                    }
                </Modal>
            ) : null}
            {isSettingShare && task ? (
                <Modal className="pt-4 max-w-lg" isMain={false} isOpen={true} onOpenChange={open => {
                    if (!open) {
                        setIsSettingShare(false);
                    }
                }}>
                    {
                        closeModal => (
                            <TranslationShareSetting taskId={task.id} viewEnv={viewEnv} facade={facade} currentUser={currentUser} />
                        )
                    }
                </Modal>
            ) : null}
            {invitationShown ? (
                <Modal className="max-w-xl px-6 pt-4 pb-8" isMain={true} isOpen={true} contentClassName={"h-full"} onOpenChange={open => {
                    if (!open) {
                        setInvitationShown(false);
                    }
                }}>
                    {
                        closeModal => (
                            <TranslationInvitation {...{
                                facade, viewEnv,
                                currentUser,
                                // preference, reloadPreference,
                            }} />
                        )
                    }
                </Modal>
            ) : null}
            {renderEditModal()}
            {renderToFixBGModal()}
            {renderToOCRModal()}
            {renderToCancelFixBGModal()}
            {renderPriceDesc()}
        </>
    )

}



function TranslateOptionChange({ task, icon, title, name, buttonLabel, confirmChange, facade }) {

    const track = useTrack();
    const [helpInfo, setHelpInfo] = useState()
    useEffect(() => {
        (async () => {
            const [result, error] = await facade.findOne({
                entityName: "帮助信息", condition: [[{ field: "名称", op: "eq", value: name }]],
                fieldPaths: ["内容"]
            })
            if (result && result.内容) {
                setHelpInfo(result.内容)
            }
        })();
    }, []);

    return (
        <div className="h-full sm:h-[85vh] flex flex-col pt-4 pb-2">
            <div className="font-medium flex gap-2 items-center px-4 font-size-16">
                {icon}
                <div className="font-size-15">{title}</div>
            </div>
            <div className="!px-0 !py-4 overflow-auto grow">
                {
                    helpInfo ? (
                        <div className="px-4">
                            <RichText params={{ value: helpInfo }}></RichText>
                        </div>
                    ) : null
                }
            </div>
            <div className="flex justify-end px-4 py-3">
                <Button color="violet" onPress={_ => {
                    track("translate_confirm_change", { name });
                    confirmChange();

                }}>{buttonLabel}</Button>
            </div>
        </div>
    )
}


function HelpInfo({ icon, title, name, facade }) {

    const [helpInfo, setHelpInfo] = useState()
    useEffect(() => {
        (async () => {
            const [result, error] = await facade.findOne({
                entityName: "帮助信息", condition: [[{ field: "名称", op: "eq", value: name }]],
                fieldPaths: ["内容"]
            })
            if (result && result.内容) {
                setHelpInfo(result.内容)
            }
        })();
    }, []);

    return (
        <div className="h-full sm:h-[85vh] flex flex-col pt-4 pb-2">
            <div className="font-medium flex gap-2 items-center px-4 font-size-16">
                {icon}
                <div className="font-size-15">{title}</div>
            </div>
            <div className="!px-0 !py-4 overflow-auto grow">
                {
                    helpInfo ? (
                        <div className="px-4">
                            <RichText params={{ value: helpInfo }}></RichText>
                        </div>
                    ) : null
                }
            </div>
        </div>
    )
}