

import React from 'react'

import "./LoadingCursor.less";

export default function LoadingCursor() {
    return (
        <span className="lc-loading-cursor"></span>
    )
}
