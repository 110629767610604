

// simple translation list


import Link from 'bwax-ui/page/Link'

import React, { useState, useRef, useEffect } from 'react'


import ClampedText from 'bwax-ui/components/ClampedText';

import { Pressable } from "bwax-ui/components/Button";
import { getFileIcon } from "bwax-ui/components/FileIcon";
import dayjs from 'dayjs';

import classNames from 'classnames';

import { useTrack } from 'bwax-ui/track';

const fieldPaths = ["文档", "源语言", "目标语言", "状态", "进度", "翻译后文档", "源语言词数", "价格", "已支付", "创建时间", "免费预览量", "模型"]

export default function TranslationListSimple({ currentUserId, listSize, facade, viewEnv, title, className, empty = null }) {

    const track = useTrack();

    const { routeTo } = viewEnv;

    const [tasks, setTasks] = useState();

    const tasksRef = useRef();
    tasksRef.current = tasks;

    const [loadingMore, setLoadingMore] = useState(false);
    const [hasMore, setHasMore] = useState(false);


    function needReload(task) {
        function statusIn(...array) {
            return task && array.indexOf(task.状态) != -1;
        }
        return statusIn("Waiting", "Processing");
    }

    async function doLoadData() {

        setLoadingMore(true);
        const [result, error] = await facade.listAll({
            entityName: "文档翻译-任务", condition: [[
                { field: "创建者", op: "eq", value: currentUserId },
                { field: "已归档", op: "ne", value: true }
            ]], fieldPaths,
            sort: [
                { field: "创建时间", order: "DESC" }
            ],
            pageSize: listSize

        }, { forceRefreshing: true })  // keyword, pageIndex / offset, ...
        setLoadingMore(false);

        if (error) {
            //  error handling TODO
        } else if (result) {
            const hasMore = result.pageSize < result.count;
            setHasMore(hasMore);
            setTasks(result.data);

            // 需要 reload
            if (result.data && result.data.some(needReload)) {
                setTimeout(() => {
                    doLoadData()
                }, 2000);
            }
            //
        }
    }

    useEffect(() => {
        tasksRef.current = undefined;
        doLoadData();
    }, [])

    function renderTask(task, index) {

        const pendingPayment = task && task.价格 > 0 && !task.已支付;

        function statusIn(...array) {
            return task && array.indexOf(task.状态) != -1;
        }

        const translatedFile = task && task.翻译后文档;

        const isGeneratingPreview = task && pendingPayment && statusIn("Waiting", "Processing") // 有 task、待支付、task.状态 == Waiting / Processing 没有文件，有进度
        const isPreviewGenerated = task && pendingPayment && statusIn("Completed") && translatedFile;  // 有 task、待支付、task.状态 == Completed、有文件

        const isTranslating = task && !pendingPayment && statusIn("Waiting", "Processing"); // 有 task, 已支付, task.状态 == Waiting / Processing, 有进度，没文件
        const isTranslated = task && !pendingPayment && statusIn("Completed") && translatedFile; // 有 task, 已支付, task.状态 == Completed, 有文件

        const hasFailed = task && statusIn("Terminated"); // 有 task, task.状态 == "Terminated"
        const isCancelled = task && statusIn("Cancelled"); // 有 task, task.状态 == "Cancelled"
        const isNotSupported = task && statusIn("NotSupported") // 有 task, task.状态 == "NotSupported"
        const isPendingFix =  task && statusIn("PendingFix");

        const freeNum = task.免费预览量;

        function renderStatus(className) {
            const [label, color, icon] = (() => {
                if (isGeneratingPreview) {
                    return [`正在翻译前 ${freeNum} token`, "indigo"]
                } else if (isPreviewGenerated) {
                    return [`待支付 (${task.价格}积分)`, "amber"]
                } else if (isTranslating) {
                    return ["正在翻译全文", "violet"]
                } else if (isTranslated) {
                    return ["已翻译完成", "green", <i className='bx bx-check-circle'></i>]
                } else if (hasFailed) {
                    return ["已中断", "red"]
                } else if (isCancelled) {
                    return ["已取消", "gray"]
                } else if (isNotSupported) {
                    return ["不支持", "red"]
                } else if (isPendingFix) {
                    return ["等待处理", "slate"]
                }
                return []
            })();

            if (label) {
                return (
                    <div data-color={color} className={
                        classNames("inline-flex gap-1 items-center rounded-sm px-2 py-1.5 font-size-12 bg-[var(--color-3)] text-[var(--color-11)] leading-none", className)
                    }>
                        {label}{icon || null}
                    </div>
                )
            }
            return null
        }

        const fileIcon = getFileIcon(task.文档);

        function renderModel() {
            if (task.模型 == "gpt-4") {
                return (
                    <div className="whitespace-pre text-[var(--blue11)] font-size-11 px-2 py-1 bg-[var(--blue3)] rounded leading-none self-center">
                        GPT4
                    </div>
                )
            } else {
                return null
            }
        }

        return (
            <Pressable key={task.id} onPress={_ => {
                track("history_goto_translation", { status: task.状态, price: task.价格 })
                routeTo("/u/task/" + task.id)
            }}>
                <div className="flex py-3 sm:py-2 sm:px-5 px-4 rounded hover:bg-[var(--gray2)] cursor-pointer w-full">
                    <div className="hidden sm:flex gap-2 justify-between w-full items-center px-1">
                        <div className="flex gap-2">
                            <div className="flex gap-1">
                                <div className="pt-0.5 font-size-16 mr-2 mt-0.5">
                                    {fileIcon}
                                </div>
                                <div className="break-all leading-relaxed">
                                    <ClampedText tipEnabled={false}>{task.文档.title}</ClampedText>
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-1 ml-1">
                            {renderModel()}
                            {renderStatus("whitespace-pre")}
                            {/* <div className="whitespace-pre text-[var(--gray11)] font-size-12">                                
                                {dayjs(task.创建时间).format("MM.DD")}
                            </div> */}
                        </div>
                    </div>
                    <div className="flex flex-col sm:hidden gap-2 w-full px-2">
                        <div className="flex gap-2 justify-between">
                            <div className="flex gap-1">
                                <div className="pt-0.5 font-size-16 mr-2 mt-1">
                                    {fileIcon}
                                </div>
                                <div className="break-all leading-relaxed">
                                    {task.文档.title}
                                </div>
                            </div>
                            <div className="whitespace-pre text-[var(--gray11)] font-size-12">
                                {dayjs(task.创建时间).format("MM.DD")}
                            </div>
                        </div>
                        <div className="flex flex-wrap gap-2 pl-6 pr-2 ">
                            {renderStatus()}
                            {renderModel()}
                        </div>

                    </div>
                </div>

            </Pressable>
        )
    }


    if (tasks && tasks.length === 0) {
        return empty
    }

    return (
        <div className={classNames("flex flex-col self-center pb-2 pt-16 sm:pt-2", className)}>
            {title ? (
                <div className="text-[var(--gray11)] px-6 sm:px-9 py-3 flex justify-between">
                    <div>{title}</div>
                    {hasMore ? (
                        <Link to={"/u/history"} className="flex gap-1 items-center">
                            <div className="font-size-12">查看更多</div> <i className='bx bx-chevrons-right'></i>
                        </Link>
                    ) : null}
                </div>
            ) : null}
            <div className="grow flex py-2 flex-col gap-1 px-2 sm:px-4 overflow-auto">
                {
                    tasks ? tasks.map(renderTask) : null
                }
                {
                    loadingMore && !(tasks && tasks.length > 0) ? (
                        Array(listSize).fill(0).map((_, index) => {
                            return (
                                <React.Fragment key={"l" + index}>
                                    <div className="hidden sm:flex gap-2 sm:gap-16 py-3 px-3 sm:px-5 w-full item-center">
                                        <div className="flex gap-4 grow">
                                            <div className="bg-[var(--gray2)] h-4 w-4" />
                                            <div className="bg-[var(--gray2)] h-4 grow" />
                                        </div>
                                        <div className="bg-[var(--gray2)] h-4 w-10" />
                                    </div>
                                    <div className="flex flex-col sm:hidden gap-2 py-5 px-4 w-full item-center">
                                        <div className="flex gap-2 grow">
                                            <div className="bg-[var(--gray2)] h-4 w-4" />
                                            <div className="bg-[var(--gray2)] h-4 grow" />
                                        </div>
                                        <div className="flex justify-between pl-6">
                                            <div className="bg-[var(--gray2)] h-4 w-24 " />
                                            <div className="bg-[var(--gray2)] h-4 w-12 " />
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })
                    ) : null
                }
            </div>

        </div>
    )
}




