

import React, { useEffect } from 'react'

import ClampedText from 'bwax-ui/components/ClampedText';

import Button from "bwax-ui/components/Button";
import { getFileIcon } from "bwax-ui/components/FileIcon";

import DualFileView from './DualFileView';
import queryString from 'query-string'

export default function TranslationShare({ data, facade, viewEnv }) {

    const { title, doc, translatedDoc, isPaused, isNotFound } = data;
    const { routeTo } = viewEnv

    // 如果有 ur 则存在 loadStorage
    useEffect(() => {

        if (typeof (window) !== "undefined") {
            // TODO 页面级别的 params 应该预先存在 webEnv 里面
            const search = queryString.parse(window.location.search);
            if (search.ur) {
                localStorage.setItem("user-ref", search.ur);
            }
            if (search.cr) {
                localStorage.setItem("channel-ref", search.cr);
            }
        }
    }, []);

    return (
        <div className="w-full h-full flex flex-col">
            {isPaused || isNotFound ? (
                <div className="w-full h-full flex flex-col gap-8 items-center justify-center">
                    {
                        isNotFound ? <i className='bx bx-folder-open text-[4rem] text-[var(--gray8)]'></i>
                            : <i className='bx bx-lock-alt text-[4rem] text-[var(--gray8)]'></i>
                    }
                    <div className="text-[var(--gray10)]">
                        { isNotFound ? "未找到翻译文档，可能已被删除" : "已暂停分享" }
                    </div>
                    <Button color="violet" size="large" onPress={_ => {
                        routeTo("/")
                    }}>
                        我有文档要翻译
                    </Button>
                </div>
            ) : (
                <>
                    <div className="px-2 sm:px-4 py-3 flex gap-2 justify-between border-b">
                        <div className="flex gap-1 items-center">
                            <div className="font-size-16 mr-1 ">
                                {getFileIcon(doc)}
                            </div>
                            <ClampedText tipEnabled={true}>{title}</ClampedText>
                        </div>
                        <Button appearance="primary" color="grass" size="small" onPress={_ => {
                            routeTo("/")
                        }}>
                            我有文档要翻译
                        </Button>
                    </div>
                    <div className="grow">
                        <DualFileView
                            leftFile={doc} rightFile={translatedDoc}
                        />
                    </div>
                </>
            )}
        </div>
    )
}
