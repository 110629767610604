// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var ConfirmDialog = require("./ConfirmDialog");
var Plate$BwaxMobile = require("../../../bwax-js/ml/plate.bs.js");
var Lang_eval$BwaxMobile = require("../../../bwax-js/ml/lang/lang_eval.bs.js");
var Lang_runtime$BwaxMobile = require("../../../bwax-js/ml/lang/lang_runtime.bs.js");
var Runtime_common$BwaxMobile = require("../../../bwax-js/ml/lang/mod/runtime_common.bs.js");
var Page_entry_slim$BwaxMobile = require("../page_entry_slim.bs.js");

function confirm(prim) {
  ConfirmDialog.confirm(prim);
  return /* () */0;
}

function print_msgs(msgs) {
  return Curry._1(Plate$BwaxMobile.List.to_array, Plate$BwaxMobile.List.map(Lang_eval$BwaxMobile.string_of_value, msgs));
}

function make_domain_env(domainEnv) {
  return /* record */[
          /* mobileHost */domainEnv.mobileHost,
          /* isSandbox */domainEnv.isSandbox,
          /* protocol */domainEnv.protocol,
          /* tenantCode */domainEnv.tenantCode,
          /* urlPrefix */domainEnv.urlPrefix
        ];
}

function make_web_env(webEnv) {
  return /* record */[
          /* protocol */webEnv.protocol,
          /* host */webEnv.host,
          /* currentURL */webEnv.currentURL,
          /* currentURLPath */webEnv.currentURLPath,
          /* originalURL */webEnv.originalURL,
          /* isSandbox */webEnv.isSandbox,
          /* isIOS */webEnv.isIOS,
          /* isWeChat */webEnv.isWeChat,
          /* isMobile */webEnv.isMobile
        ];
}

function get_model(param) {
  return param[/* model */1];
}

function get_env(param) {
  return param[/* env */0];
}

function runInit(queryRunner, queryCache, domainEnv, webEnv, entity_dict, data_type_dict, base_env, dts, ast, route_to, initParams, maybePreparedData) {
  var command_handlers = Page_entry_slim$BwaxMobile.get_command_handlers(/* () */0);
  var match = Lang_eval$BwaxMobile.evaluate_defs(base_env, ast);
  var env = match[0];
  var domain_env = make_domain_env(domainEnv);
  var web_env = make_web_env(webEnv);
  var context_005 = /* query_cache */Runtime_common$BwaxMobile.to_query_cache(queryCache);
  var context = /* record */[
    /* entity_dict */entity_dict,
    /* data_type_dict */data_type_dict,
    /* dts */dts,
    /* env */env,
    /* query_runner */queryRunner,
    context_005,
    /* timers */undefined,
    /* domain_env */domain_env,
    /* route_to */route_to,
    /* web_env */web_env
  ];
  var all_args = maybePreparedData !== undefined ? /* :: */[
      initParams,
      /* :: */[
        maybePreparedData,
        /* [] */0
      ]
    ] : /* :: */[
      initParams,
      /* [] */0
    ];
  var args = Plate$BwaxMobile.List.filter((function (param) {
            var match = param[0];
            if (typeof match === "number") {
              return match !== 0;
            } else {
              return true;
            }
          }))(all_args);
  return new Promise((function (resolve, param) {
                return Lang_runtime$BwaxMobile.run_init_to_model(command_handlers, context, args, (function (result) {
                              if (result.tag) {
                                var e = result[0];
                                console.log("Un handled error", e);
                                return resolve(/* tuple */[
                                            undefined,
                                            e
                                          ]);
                              } else {
                                var match = result[0];
                                return resolve(/* tuple */[
                                            /* record */[
                                              /* env */env,
                                              /* model */match[0],
                                              /* commands */match[1],
                                              /* msgs */match[2]
                                            ],
                                            undefined
                                          ]);
                              }
                            }), (function (result) {
                              if (result.tag) {
                                return resolve(/* tuple */[
                                            undefined,
                                            result[0]
                                          ]);
                              } else {
                                console.log("Abandon with the branch msgs", print_msgs(result[0]));
                                return /* () */0;
                              }
                            }));
              }));
}

function context_maker(queryCache, query_runner, entity_dict, data_type_dict, dts, route_to, domainEnv, webEnv) {
  var domain_env = make_domain_env(domainEnv);
  var web_env = make_web_env(webEnv);
  return (function (env, timers) {
      return /* record */[
              /* entity_dict */entity_dict,
              /* data_type_dict */data_type_dict,
              /* dts */dts,
              /* env */env,
              /* query_runner */query_runner,
              /* query_cache */Runtime_common$BwaxMobile.to_query_cache(queryCache),
              /* timers */timers,
              /* domain_env */domain_env,
              /* route_to */route_to,
              /* web_env */web_env
            ];
    });
}

function make_cmd_msg_handler(context, modelRef, setState) {
  var command_handlers = Page_entry_slim$BwaxMobile.get_command_handlers(/* () */0);
  var onCmd = function (cmd) {
    return Lang_runtime$BwaxMobile.run_command(command_handlers, context, cmd, (function (result) {
                  if (result.tag) {
                    throw result[0];
                  } else {
                    var msgs = result[0];
                    var normalMsgs = Plate$BwaxMobile.List.filter((function (msg) {
                              var match = msg[0];
                              if (typeof match === "number" || match.tag !== /* V_decorated */13) {
                                return true;
                              } else {
                                Curry._1(match[0], match[1]);
                                return false;
                              }
                            }))(msgs);
                    if (Plate$BwaxMobile.List.length(normalMsgs) > 0) {
                      var model = modelRef.current;
                      var match = Plate$BwaxMobile.List.foldl((function (param, msg) {
                              var match = Lang_runtime$BwaxMobile.run_update(context, /* tuple */[
                                    msg,
                                    param[0]
                                  ]);
                              return /* tuple */[
                                      match[0],
                                      /* :: */[
                                        match[1],
                                        param[1]
                                      ]
                                    ];
                            }), /* tuple */[
                            model,
                            /* [] */0
                          ], msgs);
                      var cmds = match[1];
                      var m = match[0];
                      Plate$BwaxMobile.List.for_each((function (cmd) {
                              return setTimeout((function (param) {
                                            return onCmd(cmd);
                                          }), 5);
                            }), Plate$BwaxMobile.List.rev(cmds));
                      return Curry._1(setState, (function (param) {
                                    return /* record */[
                                            /* env */param[/* env */0],
                                            /* model */m,
                                            /* commands */Pervasives.$at(cmds, param[/* commands */2]),
                                            /* msgs */Pervasives.$at(Plate$BwaxMobile.List.rev(normalMsgs), param[/* msgs */3])
                                          ];
                                  }));
                    } else {
                      return 0;
                    }
                  }
                }));
  };
  var onMsg = function (msg) {
    var match = msg[0];
    if (typeof match !== "number") {
      switch (match.tag | 0) {
        case /* V_tagged */6 :
            if (match[0] === "__ui_confirm__") {
              var match$1 = match[1];
              if (match$1) {
                var match$2 = match$1[0][0];
                if (typeof match$2 !== "number" && match$2.tag === /* V_record */2 && !match$1[1]) {
                  var config = match$2[0];
                  var get_value = function (decoder, name) {
                    return Plate$BwaxMobile.$$Option.and_then(decoder, Plate$BwaxMobile.List.assoc(name, config));
                  };
                  var title = get_value(Lang_eval$BwaxMobile.as_string, "title");
                  var message = get_value(Lang_eval$BwaxMobile.as_string, "message");
                  var cancelText = get_value(Lang_eval$BwaxMobile.as_string, "cancelText");
                  var okText = get_value(Lang_eval$BwaxMobile.as_string, "okText");
                  var onCancel = get_value((function (x) {
                          return x;
                        }), "onCancel");
                  var onOk = get_value((function (x) {
                          return x;
                        }), "onOk");
                  ConfirmDialog.confirm({
                        title: title,
                        onCancel: onCancel,
                        onOk: onOk,
                        message: message,
                        cancelText: cancelText,
                        okText: okText,
                        onMsg: onMsg
                      });
                  return /* () */0;
                }
                
              }
              
            }
            break;
        case /* V_decorated */13 :
            Curry._1(match[0], match[1]);
            return /* () */0;
        default:
          
      }
    }
    var model = modelRef.current;
    var match$3 = Lang_runtime$BwaxMobile.run_update(context, /* tuple */[
          msg,
          model
        ]);
    var cmd = match$3[1];
    var m = match$3[0];
    setTimeout((function (param) {
            return onCmd(cmd);
          }), 5);
    return Curry._1(setState, (function (param) {
                  return /* record */[
                          /* env */param[/* env */0],
                          /* model */m,
                          /* commands : :: */[
                            cmd,
                            param[/* commands */2]
                          ],
                          /* msgs : :: */[
                            msg,
                            param[/* msgs */3]
                          ]
                        ];
                }));
  };
  return /* tuple */[
          onCmd,
          onMsg
        ];
}

function run_reInit(context, model, initParams, setState) {
  var command_handlers = Page_entry_slim$BwaxMobile.get_command_handlers(/* () */0);
  var match = initParams[0];
  if (typeof match === "number" && match === 0) {
    return /* () */0;
  }
  return Lang_runtime$BwaxMobile.run_reInit(command_handlers, context, model, initParams, (function (result) {
                if (result.tag) {
                  console.log("Un handled error", result[0]);
                  return /* () */0;
                } else {
                  var match = result[0];
                  var newMsgs = match[2];
                  var newCmds = match[1];
                  var m = match[0];
                  return Curry._1(setState, (function (param) {
                                return /* record */[
                                        /* env */param[/* env */0],
                                        /* model */m,
                                        /* commands */Pervasives.$at(newCmds, param[/* commands */2]),
                                        /* msgs */Pervasives.$at(newMsgs, param[/* msgs */3])
                                      ];
                              }));
                }
              }), (function (result) {
                if (result.tag) {
                  console.log("Un handled error", result[0]);
                  return /* () */0;
                } else {
                  console.log("Abandon with the branch msgs", print_msgs(result[0]));
                  return /* () */0;
                }
              }));
}

function run_ready(context, model, onCmd, setState) {
  var match = Lang_runtime$BwaxMobile.run_ready(context, model);
  var cmd = match[1];
  var m = match[0];
  setTimeout((function (param) {
          return Curry._1(onCmd, cmd);
        }), 1);
  return Curry._1(setState, (function (param) {
                return /* record */[
                        /* env */param[/* env */0],
                        /* model */m,
                        /* commands : :: */[
                          cmd,
                          param[/* commands */2]
                        ],
                        /* msgs */param[/* msgs */3]
                      ];
              }));
}

function run_dispose(context, model, onCmd) {
  var cmd = Lang_runtime$BwaxMobile.run_dispose(context, model);
  setTimeout((function (param) {
          return Curry._1(onCmd, cmd);
        }), 0);
  return /* () */0;
}

function init_timers(param) {
  return /* record */[/* contents : tuple */[
            /* [] */0,
            /* [] */0
          ]];
}

function clear_timers(maybe_timers) {
  if (maybe_timers !== undefined) {
    var match = maybe_timers[0];
    Plate$BwaxMobile.List.for_each((function (prim) {
            clearTimeout(prim);
            return /* () */0;
          }), match[0]);
    return Plate$BwaxMobile.List.for_each((function (prim) {
                  clearInterval(prim);
                  return /* () */0;
                }), match[1]);
  } else {
    return /* () */0;
  }
}

var has_decorated_value = Lang_eval$BwaxMobile.has_decorated_value;

var has_ready = Lang_runtime$BwaxMobile.has_ready;

var has_implemented_ready = Lang_runtime$BwaxMobile.has_implemented_ready;

var has_dispose = Lang_runtime$BwaxMobile.has_dispose;

var run_view = Lang_runtime$BwaxMobile.run_view_with_additional_params;

exports.confirm = confirm;
exports.has_decorated_value = has_decorated_value;
exports.print_msgs = print_msgs;
exports.make_domain_env = make_domain_env;
exports.make_web_env = make_web_env;
exports.get_model = get_model;
exports.get_env = get_env;
exports.runInit = runInit;
exports.context_maker = context_maker;
exports.make_cmd_msg_handler = make_cmd_msg_handler;
exports.run_reInit = run_reInit;
exports.has_ready = has_ready;
exports.has_implemented_ready = has_implemented_ready;
exports.has_dispose = has_dispose;
exports.run_ready = run_ready;
exports.run_dispose = run_dispose;
exports.run_view = run_view;
exports.init_timers = init_timers;
exports.clear_timers = clear_timers;
/* ./ConfirmDialog Not a pure module */
