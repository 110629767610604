
// This file is generated. Don't modify it.
import React from 'react'
export default function TimeWireFrameIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M7.998556,2.00422903 C11.3054047,2.00422903 13.995803,4.69162489 13.995803,7.99495857 C13.995803,11.2982923 11.3054047,13.9857613 7.998556,13.9857613 C4.69170727,13.9857613 2.00138222,11.2982923 2.00138222,7.99495857 C2.00138222,4.69162489 4.69170727,2.00422903 7.998556,2.00422903 Z M7.998556,13.1141042 C10.8248692,13.1141042 13.1241459,10.8176103 13.1241459,7.99488534 C13.1241459,5.17216038 10.8248692,2.87573969 7.998556,2.87573969 C5.17224277,2.87573969 2.87289287,5.17223361 2.87289287,7.99495857 C2.87289287,10.8176835 5.17224277,13.1141042 7.998556,13.1141042 Z M10.8341694,9.62388749 C10.9354899,9.67943748 11.0105858,9.77297034 11.0429296,9.88390055 C11.0752734,9.99483076 11.0622142,10.1140669 11.0066262,10.2153665 C10.9510477,10.316697 10.8574884,10.3917956 10.7465344,10.4241383 C10.6355804,10.456481 10.5163224,10.4434179 10.4150007,10.3878232 L7.81994794,8.96408486 C7.70924706,8.90342619 7.6304191,8.79760052 7.60399256,8.67416715 L7.60369964,8.6730687 C7.60099013,8.6603999 7.59886646,8.64758465 7.59732863,8.63469616 L7.5965231,8.62927714 C7.59527819,8.61763356 7.59454589,8.60591676 7.59425297,8.59419995 C7.59417974,8.5916369 7.59374036,8.58914708 7.59374036,8.5865108 L7.59366713,8.58109178 L7.59366713,8.57940749 L7.53193421,4.38317998 C7.53021249,4.267604 7.57448517,4.15608004 7.65500822,4.07315351 C7.73553128,3.99022699 7.84570527,3.94269432 7.96128191,3.94101702 L7.96779938,3.94101702 C8.20592926,3.94107101 8.39992354,4.13226087 8.40344486,4.37036472 L8.46158951,8.32215037 L10.8341694,9.62388749 Z" id="TimeWireFrameIcon-path-1"></path>
            </defs>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Wireframe/Time">
                    <g id="Icon/Reservation">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="TimeWireFrameIcon-mask-2" fill="white">
                            <use href="#TimeWireFrameIcon-path-1"></use>
                        </mask>
                        <use id="Combined-Shape" fill={color} fillRule="nonzero" href="#TimeWireFrameIcon-path-1"></use>
                        <g id="Color/UI/Text" mask="url(#TimeWireFrameIcon-mask-2)" fill={color} fillRule="nonzero">
                            <rect id="light-gray" x="0" y="0" width="16" height="16"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        
    )
}
