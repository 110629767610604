
import { runDataQuery, runDefinitionQuery } from 'bwax/query/runClientQuery';

const QueryText = `
query($entityName: String!, $fieldName: String!, $keyword: String, $condition:[[GetFieldUsedValuesConditionInput]], $offset: Float, $limit: Float, $appCode: String!) {
  getFieldUsedValues(entity: $entityName, field: $fieldName, keyword: $keyword, condition: $condition, offset: $offset, limit: $limit, appCode: $appCode ) {
      value count
  }
}
`

export default function getFieldUsedValues({ applicationCode = "DEFAULT", entityName, fieldName, keyword, condition, limit, offset, queryTarget = 'data' }) {

    return async env => {

        const runner = queryTarget == 'definition' ? runDefinitionQuery : runDataQuery;

        const result = await runner(env)(QueryText)({
            entityName, fieldName, keyword, condition, limit, offset,
            appCode: applicationCode
        })

        const { data, errors } = JSON.parse(result)

        if (errors) {
            // TODO better error handling
            return null
        } else {
            return data && data.getFieldUsedValues ? data.getFieldUsedValues : null
        }
    }
}