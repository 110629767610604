import React from 'react'

import defaultInlineReact from './default/defaultInlineReact'
import { characterRanges, groupCharsWithTags } from './utils'

const NEWLINE_CHARS = ['\n', '\r']

export default (props) => {

    const { text, startOffset, inlineStyleRanges, styleToReact } = props

    if (inlineStyleRanges.length === 0) {
        return text
    } else {

        const textArray = [...text]
        let styleCharacters = []

        for (let i = 0; i < textArray.length; i++) {
            styleCharacters[i] = {
                characters: textArray[i],
                tags: (characterRanges(startOffset + i, inlineStyleRanges)).map(n => n.style)
            }
        }

        const groupStyleCharacters = groupCharsWithTags(styleCharacters)

        return groupStyleCharacters.map((gss, idx) => {
            const customStyle = gss.tags.reduce((acc, next) => {
                return {
                    ...acc,
                    ...(styleToReact(next) || defaultInlineReact(next))
                }
            }, {})
            const newlineExisted = NEWLINE_CHARS.some(c => gss.characters.indexOf(c) !== -1)
            return (
                <span
                    key={idx}
                    style={{
                        ...(newlineExisted ? { whiteSpace: "pre-line" } : {}),
                        ...customStyle
                    }}>
                    {gss.characters}
                </span>
            )
        })
    }
}