
// This file is generated. Don't modify it.
import React from 'react'
export default function UnFoldIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M3.5,4.5 L12.5,4.5 C12.7761424,4.5 13,4.72385763 13,5 C13,5.27614237 12.7761424,5.5 12.5,5.5 L3.5,5.5 C3.22385763,5.5 3,5.27614237 3,5 C3,4.72385763 3.22385763,4.5 3.5,4.5 Z M3.5,7.5 L12.5,7.5 C12.7761424,7.5 13,7.72385763 13,8 C13,8.27614237 12.7761424,8.5 12.5,8.5 L3.5,8.5 C3.22385763,8.5 3,8.27614237 3,8 C3,7.72385763 3.22385763,7.5 3.5,7.5 Z M3.5,10.5 L12.5,10.5 C12.7761424,10.5 13,10.7238576 13,11 C13,11.2761424 12.7761424,11.5 12.5,11.5 L3.5,11.5 C3.22385763,11.5 3,11.2761424 3,11 C3,10.7238576 3.22385763,10.5 3.5,10.5 Z" id="UnFoldIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Catalog-Unfold">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="UnFoldIcon-mask-2" fill="white">
                            <use href="#UnFoldIcon-path-1"></use>
                        </mask>
                        <use id="Path" fill={color} fillRule="nonzero" transform="translate(16 0) rotate(90)" href="#UnFoldIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
