
import getTokenCookieName from 'bwax/query/getTokenCookieName';
import { runDataQuery, runDefinitionQuery } from 'bwax/query/runClientQuery';

import Cookies from 'js-cookie'

export default function loadCurrentUser({queryTarget = "data", withToken = false} = {}) {
    return async ({ endpoints, sessionToken, sandbox, tenantCode }) => {        
        //// the entity
        
        const sessionTokenCookieName = getTokenCookieName({ sandbox, tenantCode })


        function getPlatformSessionToken() {
            if (tenantCode) {
                // 
                const platformTokenCookieName = getTokenCookieName({ sandbox })
                const token = Cookies.get(platformTokenCookieName);
                return token
    
            }
            return undefined // already inside platform
        }

        if(!sessionToken && !Cookies.get(sessionTokenCookieName) && !getPlatformSessionToken() ) {
            return null
        };

        const queryText = getUserQueryText()
        const queryRunners = {
            'data': runDataQuery({ endpoints, sessionToken, sandbox, tenantCode }),
            'definition': runDefinitionQuery({ endpoints, sessionToken, sandbox, tenantCode })
        }

        const result = await queryRunners[queryTarget](queryText)()

        const { data, errors } = JSON.parse(result)

        if (errors) {
            // TODO better error handling
            return null
        } else {
            const user = data && data.me && data.me.authUser ? data.me.authUser : null;
            if(withToken) {
                const token = sessionToken || Cookies.get(sessionTokenCookieName);
                return [token, user]
            }
            return user
        }
    }
}

const getUserSelection = () => {

    return `
        id
        uid
        avatar {
            expireTime
            title
            size
            contentType
            url
            path
        }
        nickName
        systemRoles
        info
    `
}

const getUserQueryText = () => {
    return `
    query {
        me {
            authUser {
                ${getUserSelection()}
            }
        }
    }
  `
}
