import loadable from "@loadable/component"

import addPrefix from 'bwax-ui/addPrefix'

const BuildingIcon = loadable(() => import ('./BuildingIcon'));

const CourseIcon = loadable(() => import ('./CourseIcon'));

const ExploreIcon = loadable(() => import ('./ExploreIcon'));

const HealthIcon = loadable(() => import ('./HealthIcon'));
const ShoppingIcon = loadable(() => import ( './ShoppingIcon'));
const WorkerIcon = loadable(() => import ('./WorkerIcon'));

export default addPrefix('cb', {
    BuildingIcon,
    CourseIcon,
    ExploreIcon,
    HealthIcon,
    ShoppingIcon,
    WorkerIcon,
})