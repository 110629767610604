
import React, { useEffect, useState } from 'react'
import { Editor, Range, Transforms } from 'slate'

import { MdFormatPaint } from 'react-icons/md'

export default function FormatBrushButton(props) {

    const { editor, updateSupplementClassNames, toggleInlineToolbarVisible } = props

    const [brushingActivated, setBrushingActivated] = useState(false)
    const [brushMarks, setBrushMarks] = useState(null)
    const [brushSelection, setBrushSelection] = useState(null)

    const { selection } = editor

    useEffect(() => {

        function onMouseUp () {

            if(brushingActivated) {

                if(brushMarks && !Range.equals(brushSelection, selection)) {
                    const currentMarks = Editor.marks(editor)
                    // 先 remove 掉现有的样式
                    Object.keys(currentMarks).map(mark => {
                        Editor.removeMark(editor, mark)
                    })

                    Object.keys(brushMarks).map(mark => {
                        Editor.addMark(editor, mark, brushMarks[mark])
                    })

                }

                updateSupplementClassNames("brushing", false)
                toggleInlineToolbarVisible()
                setBrushingActivated(!brushingActivated)
            }
        }

        window.addEventListener('mouseup', onMouseUp)

        return () => {
            window.removeEventListener('mouseup', onMouseUp)
        }
    })

    
    function activateBrushing () {
        setBrushingActivated(!brushingActivated)
        updateSupplementClassNames("brushing", true)
        toggleInlineToolbarVisible()
        const marks = Editor.marks(editor)
        setBrushMarks(marks)
        setBrushSelection(selection)
    }

    return (
        <button className="editor-button" onClick={() => activateBrushing()}>
            <MdFormatPaint style={{ fontSize: 14 }} />
        </button>
    )
}
