

import numeral from 'numeral';
import React, { useEffect, useState } from 'react'


export default function TranslationReconciliation({ facade }) {

    const [ totalAmount, setTotalAmount ] = useState();

    const [ totalOrder, setTotalOrder ] = useState();

    const [ totalApp, setTotalApp ] = useState();

    async function countTotalAmount() {
        const [result, error] = await facade.aggregate({
            entityName: "充值记录",
            aggregate: [{ field: "相关申请.金额", func: "SUM", aliasName: "sum", }],
            // group: timeGroup,
            condition: [[
                // timeCondition,
                { field: "相关申请.订单.支付状态", op: "eq", value: "已支付"},
                { field: "作废", op: "ne", value: true }
            ]]
        });
        if (!error) {
            // console.log(">> total amount", result);
            setTotalAmount(result[0].sum)
        }
    }

    async function countTotalAppAmount() {
        const [result, error] = await facade.aggregate({
            entityName: "充值申请",
            aggregate: [{ field: "金额", func: "SUM", aliasName: "sum", }],
            // group: timeGroup,
            condition: [[
                // timeCondition,
                { field: "订单.支付状态", op: "eq", value: "已支付"},
                { field: "作废", op: "ne", value: true }
            ]]
        });
        if (!error) {
            // console.log(">> total amount", result);
            setTotalApp(result[0].sum)
        }
    }


    async function countTotalOrder() {
        const [result, error] = await facade.aggregate({
            entityName: "订单",
            aggregate: [{ field: "实付金额", func: "SUM", aliasName: "sum", }],
            // group: timeGroup,
            condition: [[
                // timeCondition,
                { field: "支付状态", op: "eq", value: "已支付"}
            ]]
        });
        if (!error) {
            // console.log(">> total amount", result);
            setTotalOrder(result[0].sum)
        }
    }

    useEffect(() => {
        countTotalAmount();
        countTotalAppAmount();
        countTotalOrder();
    }, []);

    async function countDuplicateRecords() {
        const [result, error] = await facade.aggregate({
            entityName: "订单",
            aggregate: [{ field: "实付金额", func: "SUM", aliasName: "sum", }],
            // group: timeGroup,
            condition: [[
                // timeCondition,
                { field: "支付状态", op: "eq", value: "已支付"}
            ]]
        });
        if (!error) {
            // console.log(">> total amount", result);
            setTotalOrder(result[0].sum)
        }
    }



    return (
        <div className="flex flex-col gap-2 px-4 py-4">
            <div className="flex gap-2">
                <div>充值总额</div>
                <div>{ totalAmount ? numeral(totalAmount).format("0,0.00") : "--" }</div>                
            </div>
            <div className="flex gap-2">
                <div>申请总额</div>
                <div>{ totalApp ? numeral(totalApp).format("0,0.00")  : "--" }</div>                
            </div>
            <div className="flex gap-2">
                <div>订单总额</div>
                <div>{ totalOrder ? numeral(totalOrder  / 100).format("0,0.00") : "--" }</div>                
            </div>
        </div>
    )
}
