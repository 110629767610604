

import React, { useState, useEffect } from 'react'

export default function TranslateEditView({ task, facade }) {

    const [taskData, setTaskData] = useState();

    const [lines, setLines] = useState();

    const [editedValues, setEditedValues] = useState({});

    async function loadTaskData() {
        const [taskData, error] = await facade.findById(task.id, {
            entityName: "文档翻译-任务", fieldPaths: ["翻译缓存", "已修订的翻译缓存", "已应用的翻译缓存修订"]
        }, { forceRefreshing: true })

        if (taskData) {
            setTaskData(taskData);

            const translateCache = taskData.翻译缓存 || {};

            setLines(
                Object.keys(translateCache).map((k) => [k, translateCache[k]]).filter(([k, v]) => {
                    // 有些固定的比如 1,2,3,4,5,6,7,8,9 可以移除
                    if (!isNaN(k) && k == v) {
                        return false
                    }
                    if (k == "##TranslationModel##") {
                        return false
                    }
                    // if (notTranslatable(k)) {
                    //     return k != v
                    // }

                    return true
                }).map(([k, v], index) => [index, k, v])
            )
            const lastEditedValues = taskData.已修订的翻译缓存 || {};
            // 可能因为换模型、或者启用OCR之后，原文结构的变化导致之前的修订已经不适用了。
            const omittedStaled = Object.keys(lastEditedValues).reduce((acc, key) => {
                if (!translateCache[key]) {
                    return acc
                } else {
                    return {
                        ...acc, [key]: lastEditedValues[key]
                    }
                }
            }, {});

            setEditedValues(omittedStaled)
        }
    }


    useEffect(() => {
        loadTaskData()
    }, [task.id])

    const editedLines = (lines || []).flatMap(([index, k, v]) => {
        if(editedValues[k]) {
            return [[index, k, v, editedValues[k]]];
        } else {
            return []
        }
    });

    const printStr = str => {
        const j = JSON.stringify(str);
        return j.substring(1, j.length - 1);
    }

    return (
        <div className="flex flex-col gap-2 px-4 py-2">
            { editedLines && editedLines.length > 0 ? (
                <div className="text-[var(--gray11)] py-2 px-4">
                    共 {editedLines.length} 条记录
                </div>
            ) : null }
            { editedLines.map(([index, k, v, ev]) => {
                return (
                    <div key={index} className={"flex gap-1.5 py-2 pl-3 pr-5 rounded"}>
                        <div className="text-[var(--gray10)] font-size-13 min-w-[2rem] max-w-[2rem] text-right pt-[1px]">#{index + 1}</div>
                        <div className="flex flex-col gap-2 grow">
                            <div className="">
                                {printStr(k)}
                            </div>
                            <div className="text-[var(--gray11)] ">
                                {printStr(v)}
                            </div>
                            {/* {showEdited && editedValues[k] ? ( */}
                                <div className="px-3 py-2 rounded bg-[var(--grass3)] -mx-3 mb-1 ">
                                    {printStr(ev)}
                                </div>
                            {/* ) : null} */}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
