import React from 'react'

import { preStyle, codeStyle } from './styles'

const convertToHTML = {
    blockToHTML: (block) => {
        if (block.type === 'code') {
            return (
                <pre style={preStyle}>
                    {block.text}
                </pre>
            )
        }
    }
}

const convertToReact = {
    blockToReact: (block) => {
        if (block.type === 'code') {
            return (
                <pre style={preStyle}>
                    {block.text}
                </pre>
            )
        }
    }
}

const convertFromHTML = {
    htmlToBlock: (nodeName, node) => {
        if (nodeName === 'pre') {
            return {
                type: 'code'
            }
        }
    }
}

export { convertToHTML, convertFromHTML, convertToReact }
