
import React, { useEffect, useState, useContext } from 'react';

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'

import { Helmet } from 'react-helmet-async';

export default function TestEnv(props) {

    // const { location } = props;

    const dlc = useContext(DataLoaderContext);

    // const { pathname, search } = location;

    const [ afterEffect, setAfterEffect ] = useState(false);

    const lines = [
        "window: " + typeof (window),
        "document: " + typeof (document),
        "after-rendered: " + (afterEffect),
        // "location pathname: " + pathname,
        // "location search: " + search,
    ];

    // console.log(dlc);

    useEffect(() => {
        setAfterEffect(true);
    }, [])

    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1"></meta>
            </Helmet>
            <div style={{ padding: "0.25rem 0"}}>
                {lines.map((l, i) => <div key={i} style={{ padding: "0.25rem 1rem" }}>{l}</div>)}
                <div style={{ padding: "0.25rem 1rem"}}>
                    { dlc.userenv.userAgent }
                </div>
            </div>
        </>
    )

}