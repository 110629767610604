// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Plate$BwaxMobile = require("../../../../bwax-js/ml/plate.bs.js");
var BaseUI$BwaxMobile = require("../../../re/BaseUI.bs.js");
var Button = require("bwax-ui/components/Button");
var Dialog = require("bwax-ui/components/Dialog");
var Widget_mod_styled_ui$BwaxMobile = require("../../mod/widget/widget_mod_styled_ui.bs.js");
var PopoverTrigger = require("bwax-ui/components/PopoverTrigger");

function to_el(react_el, param, param$1, param$2, param$3) {
  return react_el;
}

function render_element(doRender, onMsg, get_instance_id, facade, viewEnv, convertRules, rawContent) {
  switch (rawContent.tag | 0) {
    case /* Style_button */0 :
        var content = rawContent[2];
        var config = rawContent[1];
        var rules = Curry._1(convertRules, rawContent[0]);
        return (function (theme, pc, prs, key) {
            var children = Curry._5(doRender, content, theme, pc, prs, key);
            var onPress = Plate$BwaxMobile.$$Option.map((function (msg, param) {
                    return Curry._1(onMsg, msg);
                  }), config[/* onPress */0]);
            var toCss = function (r) {
              return BaseUI$BwaxMobile.ruleToCss(r, theme, /* Item_box */3, pc, prs);
            };
            var className = BaseUI$BwaxMobile.buildClassName("", rules, toCss);
            return Button.create({
                        children: children,
                        onPress: onPress,
                        isDisabled: config[/* isDisabled */1],
                        isLoading: config[/* isLoading */2],
                        size: config[/* size */5],
                        appearance: config[/* appearance */3],
                        color: config[/* color */4],
                        className: className,
                        key: key
                      });
          });
    case /* Style_dialog */1 :
        var content$1 = rawContent[2];
        var config$1 = rawContent[1];
        var rules$1 = Curry._1(convertRules, rawContent[0]);
        return (function (theme, pc, prs, key) {
            var children = Curry._5(doRender, content$1, theme, pc, prs, key);
            var match = config$1[/* onOpenChange */1];
            var onOpenChange;
            if (match !== undefined) {
              var f = match;
              onOpenChange = (function (isOpen) {
                  return Curry._1(onMsg, Curry._1(f, isOpen));
                });
            } else {
              onOpenChange = undefined;
            }
            var toCss = function (r) {
              return BaseUI$BwaxMobile.ruleToCss(r, theme, /* Item_box */3, pc, prs);
            };
            var className = BaseUI$BwaxMobile.buildClassName("", rules$1, toCss);
            return Dialog.create({
                        children: children,
                        isOpen: config$1[/* isOpen */0],
                        onOpenChange: onOpenChange,
                        isImportant: config$1[/* isImportant */2],
                        isMain: config$1[/* isMain */3],
                        className: className,
                        key: key
                      });
          });
    case /* Style_popover */2 :
        var child = rawContent[2];
        var config$2 = rawContent[1];
        var rules$2 = Curry._1(convertRules, rawContent[0]);
        return (function (theme, pc, prs, key) {
            var children = Curry._5(doRender, child, theme, pc, prs, key);
            var content = Curry._5(doRender, config$2[/* content */0], theme, pc, prs, key);
            var toCss = function (r) {
              return BaseUI$BwaxMobile.ruleToCss(r, theme, /* Item_box */3, pc, prs);
            };
            var className = BaseUI$BwaxMobile.buildClassName("", rules$2, toCss);
            return PopoverTrigger.create({
                        children: children,
                        content: content,
                        className: className,
                        key: key
                      });
          });
    
  }
}

var module_name = Widget_mod_styled_ui$BwaxMobile.module_name;

exports.module_name = module_name;
exports.to_el = to_el;
exports.render_element = render_element;
/* Plate-BwaxMobile Not a pure module */
