

import React, { useState, useEffect, useRef } from 'react'
import { Editor, Transforms, Element as SlateElement, Range, Path, Node, setPoint } from 'slate'
import Button from 'bwax-ui/components/Button'
import { MdClose } from "react-icons/md";
import { isMobile } from 'bwax/clientEnv'
import { ReactEditor } from 'slate-react';
import { RiDeleteBinLine } from 'react-icons/ri'
import Checkbox from 'bwax-ui/components/inputs/Checkbox';
import { getTopValue, getLeftValue } from '../InlineToolBar';
import { endInput } from '../buttons/HyperlinkButton';

const preventBubblingUp = (event) => {
    event.preventDefault();
    event.stopPropagation()
}

function getLinkInfo (editor, selection) {
    const selectedFragments = Editor.fragment(editor, selection) 
    if(selectedFragments.length === 1) {
        const selected = selectedFragments[0]
        const descendants = Array.from(Node.descendants(selected))
        const firstLink = descendants.find(descendant => {
            const [descendantNode, _] = descendant
            return descendantNode.type === 'link'
        })
        if(firstLink) {
            const [linkNode, _] = firstLink
            return {
                editing: linkNode.url,
                targetBlank: !!linkNode.targetBlank,
                linkNode: linkNode
            }
        }

        return null
    }

    return null
}

export function HyperlinkInputComp(props) {

    const { editor, onEndInput, bindOverrideContent, selectedNode, setHighlightRange, setLinkInputModalInfo, linkNode, cancelEdit } = props
    const [editing, setEditing] = useState("")
    const [targetBlank, setTargetBlank] = useState(false)
    const [isCompositionEditing, setIsCompositionEditing] = useState(false) // 监听拼音输入
    const [editingLinkNode, setEditingLinkNode] = useState(linkNode)

    useEffect(() => {
        if(!selectedNode && editor.selection) {
            const { selection } = editor
            setHighlightRange(selection)

            const { anchor, focus } = Editor.unhangRange(editor, selection)
            const startPoint = Range.isBackward(selection) ? focus : anchor
            let endPoint = Range.isBackward(selection) ? anchor : focus
            let actualSelection = {...selection}
            if(Editor.isStart(editor, endPoint, endPoint.path) && Path.isAfter(endPoint.path, startPoint.path)) {
                // selection 很容易选到下一个 text 的开始处，这里做一个转换
                endPoint = Editor.before(editor, endPoint)
                actualSelection = { anchor: startPoint, focus: endPoint }
                Transforms.select(editor, actualSelection)
                setHighlightRange(actualSelection)
            }

            const linkInfo = getLinkInfo(editor, actualSelection)
            if(linkInfo) {
                const { editing, targetBlank, linkNode } = linkInfo
                setEditing(editing)
                setTargetBlank(!!targetBlank)
                setEditingLinkNode(linkNode)
            }
        }
        
    }, [])

    function dismiss() {
        if(!isMobile() && bindOverrideContent) {
            bindOverrideContent({
                component: null,
                persistent: false
            })
        }
        
        if(!selectedNode) {
            setHighlightRange(null)
        }

        if(isMobile()) {
            setLinkInputModalInfo({ open: false })
        }

        ReactEditor.focus(editor)
    }

    function renderLinkEditLabel (label, className) {

        return (
            <div className={`px-3.5 my-2 text-[var(--gray10)] text-xs font-medium ${className ? className : ''}`}>
                {label}
            </div>
        )
    }

    function getLinkNodeText () {
        if(editingLinkNode) {
            return Node.string(editingLinkNode)
        }

        return ''
    }

    function changeLinkNodeText (value) {
        const newLinkNode = {
            ...editingLinkNode,
            children: [{ text: value }]
        }

        setEditingLinkNode(newLinkNode)

        if(value) {
            // 不允许设置空文本
            const originalRange = editor.selection
            Editor.insertText(editor, value)
            const newRange = {
                anchor: originalRange.anchor,
                focus: editor.selection.focus
            }
            Transforms.select(editor, newRange)
            setHighlightRange(newRange)
        }
        
    }

    function removeLink () {
        if(cancelEdit) {
            cancelEdit()
        }
        Transforms.unwrapNodes(editor, {
            match: (n, _) => {
                return !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link'
            },
        })
        dismiss()
    }

    function changeTargetBlank(value) {
        setTargetBlank(value)

        Transforms.setNodes(editor, { targetBlank: value }, 
            { at: Path.parent(editor.selection.focus.path) }
        )
    }

    return (
        <div className={editingLinkNode ? 'pt-4 sm:pt-1.5' : 'pt-4 sm:pt-0'}>
            { editingLinkNode ? renderLinkEditLabel('链接') : null }
            <div className={`editor-link-input-container items-end flex-col sm:flex-row sm:items-center ${editingLinkNode ? 'sm:px-3.5 gap-2' : ''}`}
                onClick={(e) => preventBubblingUp(e)}>
                <input className="border-y border-[var(--gray5)] sm:border-0 w-full sm:!h-[30px] !bg-[var(--gray2)] !text-sm px-3.5 py-2.5" 
                    autoFocus placeholder="请输入链接,按回车确认"
                    value={editing} onChange={e => setEditing(e.target.value)}
                    enterKeyHint='done'
                    onCompositionStart={() => {
                        setIsCompositionEditing(true)
                    }}
                    onCompositionEnd={() => {
                        setTimeout(() => {
                            setIsCompositionEditing(false)
                        }, 16);
                    }}
                    onKeyDown={e => {
                        if (editing.trim() && e.key === 'Enter' && !isCompositionEditing) {
                            e.preventDefault()
                            onEndInput({
                                url: editing,
                                targetBlank
                            })
                            if(!editingLinkNode) {
                                dismiss()
                            }
                        }
                    }}
                />
                <button className={editingLinkNode ? 'hidden' : 'editor-button'}>
                    <MdClose style={{ fontSize: 14 }} onClick={() => dismiss()} />
                </button>
                {
                    isMobile() ? (
                        <div className='flex mr-3.5 items-center gap-2 text-sm opacity-70'>
                            <Checkbox color="blue" checked={targetBlank} 
                                onChange={checked => changeTargetBlank(checked)}
                            />
                            新窗口打开
                        </div>
                    ) : (
                        <Button size="small" className={`editor-button hyperlink-target-blank-button ${targetBlank ? " active" : ""}`} 
                            onClick={() => changeTargetBlank(!targetBlank)}
                        >
                            新窗口打开
                        </Button>
                    )
                }
                
            </div>
            {
                editingLinkNode ? (
                    <>
                        {renderLinkEditLabel('文本', 'mt-4')}
                        <div className='sm:px-3.5 sm:pb-3.5 w-full'>
                            <input className="border-y border-[var(--gray5)] sm:!border-0  sm:!h-[30px] !bg-[var(--gray2)] !text-sm w-full px-3.5 py-2.5 sm:px-2 sm:py-1"
                                value={getLinkNodeText()} 
                                onChange={e => changeLinkNodeText(e.target.value)}
                            />
                        </div>
                        <div className='mt-5 sm:mt-0 py-1.5 border-y border-[var(--gray5)] sm:border-0 sm:shadow-[0_-1px_0px_0px_rgba(55,53,47,0.09)]'>
                            <div className='mx-1 text-sm px-2.5 min-h-[28px] flex items-center gap-2.5 cursor-pointer rounded hover:bg-[var(--gray2)]'
                                onClick={() => removeLink()}
                            >
                                <RiDeleteBinLine/>
                                删除链接
                            </div>
                        </div>
                        
                    </>
                ) : null
            }
        </div>
        
    )
}

export default function HyperlinkInput (props) {

    const { editor, editAreaContainer, isEditFromLinkComp, highlightRange, setHighlightRange,
        setIsEditFromLinkComp, setIsLinkEditing
    } = props

    const editorRect = editAreaContainer && editAreaContainer.getBoundingClientRect()

    const hyperlinkInputRef = useRef(null)
    const [editingLinkNode, setEditingLinkNode] = useState(null) 
    const [position, setPosition] = useState(null)

    const { selection } = editor

    function cancelEdit () {
        setEditingLinkNode(null)
        setPosition(null)
        setIsEditFromLinkComp(false)
        setIsLinkEditing(false)

        if(highlightRange) {
            Transforms.select(editor, highlightRange)
            const domRange = ReactEditor.toDOMRange(editor, highlightRange)
            const selection = window.getSelection()
            selection.removeAllRanges();
            selection.addRange(domRange)
            setHighlightRange(null)
        }
    }

    useEffect(() => {
        if(Range.isCollapsed(selection)) {
            // 点击别处,取消编辑
            cancelEdit()
            
        } else {
            if(isEditFromLinkComp) {
                const linkInfo = getLinkInfo(editor, selection)
                if(linkInfo) {
                    const { linkNode } = linkInfo
                    setEditingLinkNode(linkNode)
                }
            }
        }
        
    }, [isEditFromLinkComp, selection])

    useEffect(() => {
        const el = hyperlinkInputRef.current

        if (!el || typeof(window) === 'undefined' || typeof(document) === 'undefined') {
            return
        }

        const highlightText = document.getElementsByClassName('richtext-highlight-text')[0]
        if(highlightText) {
            const rect = highlightText.getBoundingClientRect()

            setPosition({
                top: getTopValue(editAreaContainer, el, rect),
                left: getLeftValue(editorRect, el, rect)
            })
    
        }

    }, [editingLinkNode])

    return editingLinkNode ? (
        <div ref={hyperlinkInputRef} style={position ? { top: position.top, left: position.left } : {}}
            className={`
                hyperlink-input absolute shadow-lg bg-white rounded translate-x-[-50%] z-[1] 
                border border-[var(--gray5)] ${!position ? 'invisible' : ``}
            `} 
        >   
            <HyperlinkInputComp {...props} linkNode={editingLinkNode} 
                onEndInput={({ url, targetBlank }) => endInput({url, targetBlank, editor})} 
                cancelEdit={() => cancelEdit()}
            />
        </div>
    ) : null
        
}