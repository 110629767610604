

import React from 'react'

const ExecutionContext = React.createContext();

export default ExecutionContext;

export const ExecutionContextProvider = props => {

    const { children, designMode, routeTo, webEnv, domainEnv } = props

    return (
        <ExecutionContext.Provider value={{
            designMode, routeTo, webEnv, domainEnv
        }}>
            { children }
        </ExecutionContext.Provider>
    );
}
