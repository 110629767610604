
import React from 'react'


import './RichText.less'

/// 改回了 nonlazy 
// http://git.qunfengshe.com/qunfengshe/bwax-app-admin/issues/541
import RichTextComp from 'bwax-ui/auxiliary/richtext/RichText'

// for slate
import RichTextRenderer from '../auxiliary/richtext_slate/RichTextRenderer'
import resolveContentType from '../auxiliary/richtext/resolveContentType'

export default function RichText({ params, className = "" }) {
    const { value } = params

    return (
        <MemoRichText value={value} className={className}  />
    )
}

const MemoRichText = React.memo(props => {
    const { value, className } = props

    if(!value) {
        return null
    }

    const { contentType, content, version } = resolveContentType(value);

    if(contentType === "DraftJS") {
        return <RichTextComp value={value} />
    } else if(contentType === "Slate") {
        const slateValue = JSON.parse(content);
        return <RichTextRenderer className={className}  value={slateValue} version={version} />

    } else {
        return "<Unknown Rich Text Type>"
    }


   

}, (prevProps, currentProps) => {
    return prevProps.value === currentProps.value && prevProps.className == currentProps.className;
})
