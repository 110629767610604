// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var AdhocUILazyJs = require("./AdhocUILazy.js");
var BaseUI$BwaxMobile = require("../BaseUI.bs.js");

var make = AdhocUILazyJs.default;

var AdhocUILazy = {
  make: make
};

function AdhocUI(Props) {
  var pc = Props.pc;
  var match = Props.rules;
  var rules = match !== undefined ? match : /* [] */0;
  var theme = Props.theme;
  var name = Props.name;
  var config = Props.config;
  var callbacks = Props.callbacks;
  var inner = function (param, param$1, param$2, param$3) {
    return React.createElement(make, {
                name: name,
                config: config,
                callbacks: callbacks
              });
  };
  return BaseUI$BwaxMobile.box(rules, inner, theme, pc, /* [] */0, "nokey");
}

var make$1 = AdhocUI;

exports.AdhocUILazy = AdhocUILazy;
exports.make = make$1;
/* make Not a pure module */
