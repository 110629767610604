import React from 'react'

// import { convertFromRaw } from 'draft-js'

import resolveContentType from './resolveContentType'
import convertToReactEle from './draftConvertReact/convertToReactEle'


import * as blockAlignment from './plugins/blockAlignment/converter'
import * as emptyBlock from './plugins/emptyBlock/converter'
import * as hyperlink from './plugins/hyperlink/converter'
import * as image from './plugins/image/converter'
import * as inlineStyle from './plugins/inlineStyle/converter'
import * as media from './plugins/media/converter'
import * as code from './plugins/code/converter'

import * as hr from './plugins/hr/converter'
import * as mention from './plugins/mention/converter'

export const defaultConverters = {
    emptyBlock,
    blockAlignment,
    hyperlink,
    image,
    inlineStyle,
    media,
    hr,
    code,
    mention
}


const draftToReact = (state, converters = []) => {

    const allConverters = Object.assign(defaultConverters, converters, {})
    //console.log(' allConverters : ',  allConverters);

    const ps = Object.keys(allConverters).map(key => allConverters[key])


    const rfcExporters = ps.map(p => {
        if (p.convertToReact) {
            return p.convertToReact
        } else {
            return null
        }
    }).filter(c => c !== null)

    const extensions = {
        styleToReact: (style) => {
            for (let i = 0; i < rfcExporters.length; i++) {
                const { styleToReact } = rfcExporters[i]
                if (styleToReact) {
                    const ret = styleToReact(style)
                    if (ret) {
                        return ret
                    }
                }
            }
            return null
        },
        entityToReact: (entity) => {
            for (let i = 0; i < rfcExporters.length; i++) {
                const { entityToReact } = rfcExporters[i]
                if (entityToReact) {
                    const ret = entityToReact(entity)
                    if (ret) {
                        return ret
                    }
                }
            }
            return null
        },
        blockToReact: (block) => {
            for (let i = 0; i < rfcExporters.length; i++) {
                const { blockToReact } = rfcExporters[i]
                if (blockToReact) {
                    const ret = blockToReact(block)
                    if (ret) {
                        return ret
                    }
                }
            }
            return null
        }
    }

    return convertToReactEle(extensions)(
        state.getCurrentContent ? state.getCurrentContent() : state
    )
}

export function contentToReact(str, converters) {

    // const _converter = initConverters(converters)

    if (!str && typeof str !== 'string') {
        return ""
    }

    const { contentType, version, content } = resolveContentType(str)

    if (contentType === 'HTML') {
        return <div dangerouslySetInnerHTML={{ __html: content }} />
    } else if (contentType === 'DraftJS') {
        // json parse handle
        try {
            return draftToReact(JSON.parse(content), converters);
        } catch (e) {
            console.log("Cannot convert JSON", content);
            console.error(e);
            return "出错了：无法转换富文本内容"
        }
    } else {
        return ""
    }
}

export function contentToPlain(str, converters) {

    if (!str && typeof (str) !== 'string') return ""

    const { contentType, version, content } = resolveContentType(str)

    if (contentType === "HTML") {
        // throw new Error("Unsupported")
        console.warn("contentToPlain: Unsupported type, simply return it")
        return content

    } else if (contentType === "DraftJS") {
        // also handle the version
        // return draftToHTML(convertFromRaw(JSON.parse(content)))
        // console.log(JSON.parse(content))

        try {

            const { blocks, entityMap } = JSON.parse(content)
            return blocks.reduce((acc, b, idx) => {

                const resolveText = block => {
                    const text = block.text
                    const length = text.length
                    const applyConverters = (entity, originalText) => {
                        if (converters) {
                            for (let i = 0; i < converters.length; i++) {
                                const c = converters[i]
                                if (c.convertToPlain && c.convertToPlain.entityToPlain) {
                                    const newText = c.convertToPlain.entityToPlain(entity, originalText)
                                    if (newText) {
                                        return newText
                                    }
                                }
                            }
                            return originalText
                        } else {
                            return originalText
                        }
                    }


                    const findNext = (acc, offset, entityRanges) => {
                        if (offset < length) {
                            const er = entityRanges.find(e => e.offset === offset)
                            if (er) {
                                const newAcc = acc +
                                    applyConverters(entityMap[er.key], text.substring(offset, offset + er.length))
                                return findNext(newAcc, offset + er.length, entityRanges)
                            } else {
                                return findNext(acc + text[offset], offset + 1, entityRanges)
                            }
                        } else {
                            return acc
                        }
                    }

                    if (block.entityRanges && block.entityRanges.length > 0) {
                        const entitRanges = b.entityRanges.sort((a, b) => a.offset - b.offset)
                        return findNext("", 0, entitRanges)
                    } else {
                        return block.text
                    }
                }


                return acc + resolveText(b) + (idx < blocks.length - 1 ? "\n" : "");
            }, "")


        } catch (e) {
            console.log("Cannot convert JSON", content);
            console.error(e);
            return "出错了：无法转换富文本内容"
        }

    } else if (contentType === "Markdown") {
        console.warn("contentToPlain: Unsupported type, simply return it")
        return content

    } else {
        console.warn("contentToPlain: Unsupported type, simply return it")
        return content
    }

}
