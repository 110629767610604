// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Plate$BwaxMobile = require("../../plate.bs.js");
var Lang_eval$BwaxMobile = require("../lang_eval.bs.js");
var Lang_value_dict$BwaxMobile = require("../lang_value_dict.bs.js");

function just(v) {
  return /* V_tagged */Block.__(6, [
            "Just",
            /* :: */[
              v,
              /* [] */0
            ]
          ]);
}

function invalid_args(name) {
  throw [
        Lang_eval$BwaxMobile.Eval_exn,
        name + ": Invalid Arguments"
      ];
}

var Runtime_exn = Caml_exceptions.create("Runtime_common-BwaxMobile.Runtime_exn");

function to_query_cache(queryCache) {
  if (queryCache !== undefined) {
    var c = Caml_option.valFromOption(queryCache);
    return /* record */[
            /* getData */c.getData,
            /* setData */c.setData,
            /* setLoading */c.setLoading,
            /* removeData */c.removeData,
            /* clearCache */c.clearCache
          ];
  }
  
}

function pack_func(arity, f) {
  return /* tuple */[
          /* V_native_fn */Block.__(4, [
              arity,
              f,
              /* [] */0
            ]),
          /* No_term */0
        ];
}

function pack_func_with_type(arity, f, t) {
  return /* tuple */[
          /* V_native_fn */Block.__(4, [
              arity,
              f,
              /* [] */0
            ]),
          t
        ];
}

function pack_int(i) {
  return /* tuple */[
          /* V_literal */Block.__(5, [/* Int */Block.__(0, [i])]),
          /* No_term */0
        ];
}

function pack_float(f) {
  return /* tuple */[
          /* V_literal */Block.__(5, [/* Float */Block.__(1, [f])]),
          /* No_term */0
        ];
}

function pack_string(s) {
  return /* tuple */[
          /* V_literal */Block.__(5, [/* String */Block.__(2, [s])]),
          /* No_term */0
        ];
}

function pack_bool(b) {
  return /* tuple */[
          /* V_literal */Block.__(5, [/* Bool */Block.__(4, [b])]),
          /* No_term */0
        ];
}

function pack_char(c) {
  return /* tuple */[
          /* V_literal */Block.__(5, [/* Char */Block.__(3, [c])]),
          /* No_term */0
        ];
}

function pack_raw(j) {
  return /* tuple */[
          /* V_raw */Block.__(7, [j]),
          /* No_term */0
        ];
}

function pack_list(l) {
  return /* tuple */[
          /* V_list */Block.__(1, [l]),
          /* No_term */0
        ];
}

function pack_tagged(tn, sons) {
  return /* tuple */[
          /* V_tagged */Block.__(6, [
              tn,
              sons
            ]),
          /* No_term */0
        ];
}

function pack_just(v) {
  return pack_tagged("Just", /* :: */[
              v,
              /* [] */0
            ]);
}

var pack_nothing_000 = /* V_tagged */Block.__(6, [
    "Nothing",
    /* [] */0
  ]);

var pack_nothing = /* tuple */[
  pack_nothing_000,
  /* No_term */0
];

function pack_tagged_number(name, f) {
  return /* tuple */[
          /* V_tagged_number */Block.__(9, [
              name,
              f
            ]),
          /* No_term */0
        ];
}

function pack_tagged_raw(name, j) {
  return /* tuple */[
          /* V_tagged_raw */Block.__(8, [
              name,
              j
            ]),
          /* No_term */0
        ];
}

function pack_tagged_string(name, s) {
  return /* tuple */[
          /* V_tagged_string */Block.__(10, [
              name,
              s
            ]),
          /* No_term */0
        ];
}

function pack_object(binds) {
  return /* tuple */[
          /* V_record */Block.__(2, [binds]),
          /* No_term */0
        ];
}

function pack_maybe(packer, mv) {
  if (mv !== undefined) {
    return pack_just(Curry._1(packer, Caml_option.valFromOption(mv)));
  } else {
    return pack_nothing;
  }
}

function pack_maybe_directly(mv) {
  return pack_maybe((function (v) {
                return v;
              }), mv);
}

function pack_tuple(vs) {
  return /* tuple */[
          /* V_tuple */Block.__(0, [vs]),
          /* No_term */0
        ];
}

function pack_json(j) {
  return pack_tagged_raw("JSON", j);
}

function pack_image(j) {
  return pack_tagged_raw("Image", j);
}

function pack_file(j) {
  return pack_tagged_raw("File", j);
}

function pack_date(f) {
  return pack_tagged_number("Date", f);
}

function pack_dict(dict) {
  return /* tuple */[
          /* V_tagged_raw */Block.__(8, [
              "Dict",
              dict
            ]),
          /* No_term */0
        ];
}

function pack_cmd(name, args) {
  return /* tuple */[
          /* V_tagged */Block.__(6, [
              name,
              args
            ]),
          /* No_term */0
        ];
}

function is_nothing(v) {
  var match = v[0];
  if (typeof match === "number" || !(match.tag === /* V_tagged */6 && match[0] === "Nothing" && !match[1])) {
    return false;
  } else {
    return true;
  }
}

function json_to_value(j) {
  var match = Js_json.classify(j);
  if (typeof match === "number") {
    switch (match) {
      case /* JSONFalse */0 :
          return /* tuple */[
                  /* V_literal */Block.__(5, [/* Bool */Block.__(4, [false])]),
                  /* No_term */0
                ];
      case /* JSONTrue */1 :
          return /* tuple */[
                  /* V_literal */Block.__(5, [/* Bool */Block.__(4, [true])]),
                  /* No_term */0
                ];
      case /* JSONNull */2 :
          return ;
      
    }
  } else {
    switch (match.tag | 0) {
      case /* JSONString */0 :
          return pack_string(match[0]);
      case /* JSONNumber */1 :
          return pack_float(match[0]);
      case /* JSONObject */2 :
          var binds = Plate$BwaxMobile.List.keep_map((function (param) {
                  var k = param[0];
                  return Plate$BwaxMobile.$$Option.map((function (v) {
                                return /* tuple */[
                                        k,
                                        v
                                      ];
                              }), param[1]);
                }), Plate$BwaxMobile.List.assoc_map(json_to_value, Curry._1(Plate$BwaxMobile.List.from_array, Js_dict.entries(match[0]))));
          return /* tuple */[
                  /* V_record */Block.__(2, [binds]),
                  /* No_term */0
                ];
      case /* JSONArray */3 :
          var v = Plate$BwaxMobile.List.keep_map(json_to_value, Curry._1(Plate$BwaxMobile.List.from_array, match[0]));
          return /* tuple */[
                  /* V_list */Block.__(1, [v]),
                  /* No_term */0
                ];
      
    }
  }
}

function fail_to_extract(v, name) {
  throw [
        Lang_eval$BwaxMobile.Eval_exn,
        "`" + (Lang_eval$BwaxMobile.string_of_value(v) + ("` is not a " + name))
      ];
}

function extract_string(v) {
  var match = v[0];
  if (typeof match === "number" || match.tag !== /* V_literal */5) {
    return fail_to_extract(v, "String");
  } else {
    var match$1 = match[0];
    if (typeof match$1 === "number" || match$1.tag !== /* String */2) {
      return fail_to_extract(v, "String");
    } else {
      return match$1[0];
    }
  }
}

function extract_int(v) {
  var match = v[0];
  if (typeof match === "number" || match.tag !== /* V_literal */5) {
    return fail_to_extract(v, "Int");
  } else {
    var match$1 = match[0];
    if (typeof match$1 === "number" || match$1.tag) {
      return fail_to_extract(v, "Int");
    } else {
      return match$1[0];
    }
  }
}

function extract_float(v) {
  var match = v[0];
  if (typeof match === "number" || match.tag !== /* V_literal */5) {
    return fail_to_extract(v, "Float");
  } else {
    var match$1 = match[0];
    if (typeof match$1 === "number" || match$1.tag !== /* Float */1) {
      return fail_to_extract(v, "Float");
    } else {
      return match$1[0];
    }
  }
}

function extract_char(v) {
  var match = v[0];
  if (typeof match === "number" || match.tag !== /* V_literal */5) {
    return fail_to_extract(v, "Char");
  } else {
    var match$1 = match[0];
    if (typeof match$1 === "number" || match$1.tag !== /* Char */3) {
      return fail_to_extract(v, "Char");
    } else {
      return match$1[0];
    }
  }
}

function extract_bool(v) {
  var match = v[0];
  if (typeof match === "number" || match.tag !== /* V_literal */5) {
    return fail_to_extract(v, "Bool");
  } else {
    var match$1 = match[0];
    if (typeof match$1 === "number" || match$1.tag !== /* Bool */4) {
      return fail_to_extract(v, "Bool");
    } else {
      return match$1[0];
    }
  }
}

function extract_list(v, extractor) {
  var match = v[0];
  if (typeof match === "number" || match.tag !== /* V_list */1) {
    return fail_to_extract(v, "List");
  } else {
    return Plate$BwaxMobile.List.map(extractor, match[0]);
  }
}

function extract_maybe(v, extractor) {
  var match = v[0];
  if (typeof match === "number" || match.tag !== /* V_tagged */6) {
    return fail_to_extract(v, "Maybe");
  } else {
    switch (match[0]) {
      case "Just" :
          var match$1 = match[1];
          if (match$1 && !match$1[1]) {
            return Caml_option.some(Curry._1(extractor, match$1[0]));
          } else {
            return fail_to_extract(v, "Maybe");
          }
      case "Nothing" :
          if (match[1]) {
            return fail_to_extract(v, "Maybe");
          } else {
            return ;
          }
      default:
        return fail_to_extract(v, "Maybe");
    }
  }
}

function extract_record(v) {
  var match = v[0];
  if (typeof match === "number" || match.tag !== /* V_record */2) {
    return fail_to_extract(v, "Record");
  } else {
    return match[0];
  }
}

function extract_json(v) {
  var match = v[0];
  if (typeof match === "number" || !(match.tag === /* V_tagged_raw */8 && match[0] === "JSON")) {
    return fail_to_extract(v, "JSON");
  } else {
    return match[1];
  }
}

function extract_date(v) {
  var match = v[0];
  if (typeof match === "number" || !(match.tag === /* V_tagged_number */9 && match[0] === "Date")) {
    return fail_to_extract(v, "Date");
  } else {
    return new Date(match[1]);
  }
}

function json_to_value_for_type(t, json) {
  var json_to_record = function (binds) {
    return Plate$BwaxMobile.$$Option.and_then((function (param) {
                  var binds$1 = binds;
                  var al = param;
                  var iter = function (acc, remaining) {
                    if (remaining) {
                      var rest = remaining[1];
                      var match = remaining[0];
                      var t = match[1];
                      var k = match[0];
                      var accrue = function (j) {
                        var v = json_to_value_for_type(t, j);
                        if (v !== undefined) {
                          var new_acc_000 = /* tuple */[
                            k,
                            v
                          ];
                          var new_acc = /* :: */[
                            new_acc_000,
                            acc
                          ];
                          return iter(new_acc, rest);
                        } else {
                          return /* tuple */[
                                  false,
                                  acc
                                ];
                        }
                      };
                      var match$1 = Plate$BwaxMobile.List.assoc(k, al);
                      if (match$1 !== undefined) {
                        return accrue(Caml_option.valFromOption(match$1));
                      } else if (typeof t === "number" || t.tag || t[0] !== "Maybe") {
                        return /* tuple */[
                                false,
                                acc
                              ];
                      } else {
                        return accrue(null);
                      }
                    } else {
                      return /* tuple */[
                              true,
                              acc
                            ];
                    }
                  };
                  var match = iter(/* [] */0, binds$1);
                  if (match[0]) {
                    return /* tuple */[
                            /* V_record */Block.__(2, [match[1]]),
                            /* No_term */0
                          ];
                  }
                  
                }), Plate$BwaxMobile.$$Option.map(Plate$BwaxMobile.List.from_array, Plate$BwaxMobile.$$Option.map(Js_dict.entries, Js_json.decodeObject(json))));
  };
  var exit = 0;
  if (typeof t === "number") {
    exit = 2;
  } else {
    switch (t.tag | 0) {
      case /* Term */0 :
          switch (t[0]) {
            case "Criteria" :
                return /* tuple */[
                        /* V_tagged_raw */Block.__(8, [
                            "Criteria",
                            json
                          ]),
                        /* No_term */0
                      ];
            case "Date" :
                if (t[1]) {
                  exit = 2;
                } else {
                  return Plate$BwaxMobile.$$Option.map(pack_date, Plate$BwaxMobile.$$Option.map((function (prim) {
                                    return prim.valueOf();
                                  }), Plate$BwaxMobile.$$Option.map((function (prim) {
                                        return new Date(prim);
                                      }), Js_json.decodeString(json))));
                }
                break;
            case "Dict" :
                var match = t[1];
                if (match) {
                  var match$1 = match[1];
                  if (match$1 && !match$1[1]) {
                    var vt = match$1[0];
                    var kt = match[0];
                    var partial_arg = Plate$BwaxMobile.List.from_array;
                    return pack_dict(Lang_value_dict$BwaxMobile.from_list(Plate$BwaxMobile.List.keep_map((function (param) {
                                          var v = param[1];
                                          return Plate$BwaxMobile.$$Option.and_then((function (k) {
                                                        return Plate$BwaxMobile.$$Option.map((function (v) {
                                                                      return /* tuple */[
                                                                              k,
                                                                              v
                                                                            ];
                                                                    }), json_to_value_for_type(vt, v));
                                                      }), json_to_value_for_type(kt, param[0]));
                                        }), Plate$BwaxMobile.$$Option.with_default(/* [] */0, Plate$BwaxMobile.$$Option.map((function (param) {
                                                  return Plate$BwaxMobile.$at$great(Js_dict.entries, partial_arg, param);
                                                }), Js_json.decodeObject(json))))));
                  } else {
                    exit = 2;
                  }
                } else {
                  exit = 2;
                }
                break;
            case "Float" :
                return Plate$BwaxMobile.$$Option.map(pack_float, Js_json.decodeNumber(json));
            case "Int" :
                exit = 1;
                break;
            case "JSON" :
                return pack_tagged_raw("JSON", json);
            case "List" :
                var match$2 = t[1];
                if (match$2 && !match$2[1]) {
                  var t$1 = match$2[0];
                  return Plate$BwaxMobile.$$Option.and_then((function (l) {
                                var vl = Plate$BwaxMobile.List.keep_map((function (param) {
                                        return json_to_value_for_type(t$1, param);
                                      }), l);
                                if (Plate$BwaxMobile.List.length(vl) < Plate$BwaxMobile.List.length(l)) {
                                  return ;
                                } else {
                                  return /* tuple */[
                                          /* V_list */Block.__(1, [vl]),
                                          /* No_term */0
                                        ];
                                }
                              }), Plate$BwaxMobile.$$Option.map(Plate$BwaxMobile.List.from_array, Js_json.decodeArray(json)));
                } else {
                  exit = 2;
                }
                break;
            case "Maybe" :
                var match$3 = t[1];
                if (match$3 && !match$3[1]) {
                  var t$2 = match$3[0];
                  var match$4 = Js_json.classify(json);
                  if (typeof match$4 === "number" && match$4 >= 2) {
                    return pack_nothing;
                  } else {
                    return Plate$BwaxMobile.$$Option.map(pack_just, json_to_value_for_type(t$2, json));
                  }
                } else {
                  exit = 2;
                }
                break;
            case "RichText" :
            case "String" :
                return Plate$BwaxMobile.$$Option.map(pack_string, Js_json.decodeString(json));
            case "Tuple" :
                var vs = Plate$BwaxMobile.$$Option.with_default(/* [] */0, Plate$BwaxMobile.$$Option.map(Plate$BwaxMobile.List.from_array, Js_json.decodeArray(json)));
                var pairs = Plate$BwaxMobile.List.zip(/* tuple */[
                      t[1],
                      vs
                    ]);
                var vs$1 = Plate$BwaxMobile.List.keep_map((function (param) {
                        return json_to_value_for_type(param[0], param[1]);
                      }), pairs);
                return /* tuple */[
                        /* V_tuple */Block.__(0, [vs$1]),
                        /* No_term */0
                      ];
            default:
              exit = 2;
          }
          break;
      case /* Term_record */1 :
          return json_to_record(t[0]);
      case /* Var */2 :
          var match$5 = t[0][/* var_type */0];
          var exit$1 = 0;
          if (typeof match$5 === "number") {
            exit = match$5 === /* Number */1 ? 1 : 2;
          } else {
            switch (match$5.tag | 0) {
              case /* Recordvar */0 :
                  return json_to_record(Plate$BwaxMobile.List.map((function (param) {
                                    return /* tuple */[
                                            param[0],
                                            param[1],
                                            true
                                          ];
                                  }), match$5[0]));
              case /* Record_writeonly */4 :
              case /* Record_writeonly_optional */7 :
                  exit$1 = 3;
                  break;
              default:
                exit = 2;
            }
          }
          if (exit$1 === 3) {
            if (Plate$BwaxMobile.Str.starts_with("Sorting_", match$5[0])) {
              return /* tuple */[
                      /* V_tagged_raw */Block.__(8, [
                          "Sorting",
                          json
                        ]),
                      /* No_term */0
                    ];
            } else {
              var target_tname = t[0][/* var_type */0][0];
              if (Plate$BwaxMobile.Str.starts_with("Adding_", target_tname) || Plate$BwaxMobile.Str.starts_with("Updating_", target_tname)) {
                return /* tuple */[
                        /* V_tagged_raw */Block.__(8, [
                            "EntityInput",
                            json
                          ]),
                        /* No_term */0
                      ];
              } else {
                exit = 2;
              }
            }
          }
          break;
      
    }
  }
  switch (exit) {
    case 1 :
        return Plate$BwaxMobile.$$Option.map(pack_int, Plate$BwaxMobile.$$Option.map((function (prim) {
                          return prim | 0;
                        }), Js_json.decodeNumber(json)));
    case 2 :
        return Plate$BwaxMobile.$$Option.otherwise((function (param) {
                      return pack_nothing;
                    }), json_to_value(json));
    
  }
}

var nt = /* No_term */0;

var nothing = /* V_tagged */Block.__(6, [
    "Nothing",
    /* [] */0
  ]);

exports.nt = nt;
exports.just = just;
exports.nothing = nothing;
exports.invalid_args = invalid_args;
exports.Runtime_exn = Runtime_exn;
exports.to_query_cache = to_query_cache;
exports.pack_func = pack_func;
exports.pack_func_with_type = pack_func_with_type;
exports.pack_int = pack_int;
exports.pack_float = pack_float;
exports.pack_string = pack_string;
exports.pack_bool = pack_bool;
exports.pack_char = pack_char;
exports.pack_raw = pack_raw;
exports.pack_list = pack_list;
exports.pack_tagged = pack_tagged;
exports.pack_just = pack_just;
exports.pack_nothing = pack_nothing;
exports.pack_tagged_number = pack_tagged_number;
exports.pack_tagged_raw = pack_tagged_raw;
exports.pack_tagged_string = pack_tagged_string;
exports.pack_object = pack_object;
exports.pack_maybe = pack_maybe;
exports.pack_maybe_directly = pack_maybe_directly;
exports.pack_tuple = pack_tuple;
exports.pack_json = pack_json;
exports.pack_image = pack_image;
exports.pack_file = pack_file;
exports.pack_date = pack_date;
exports.pack_dict = pack_dict;
exports.pack_cmd = pack_cmd;
exports.is_nothing = is_nothing;
exports.json_to_value = json_to_value;
exports.fail_to_extract = fail_to_extract;
exports.extract_string = extract_string;
exports.extract_int = extract_int;
exports.extract_float = extract_float;
exports.extract_char = extract_char;
exports.extract_bool = extract_bool;
exports.extract_list = extract_list;
exports.extract_maybe = extract_maybe;
exports.extract_record = extract_record;
exports.extract_json = extract_json;
exports.extract_date = extract_date;
exports.json_to_value_for_type = json_to_value_for_type;
/* Plate-BwaxMobile Not a pure module */
