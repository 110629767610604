
import React from 'react'

import { MdRedo } from 'react-icons/md'

export default function RedoButton(props) {

    const { editor } = props

    const { history } = editor

    // 过滤掉 history 中的 set_selection 操作，只改变光标不触发 undo/redo
    const disabled = history.redos.filter(ra => {
        if(ra.operations) {
            return ra.operations.some(r => r.type !== 'set_selection')
        }
    }).length === 0

    function redo () {
        if(disabled) {
            return 
        }
        
        editor.redo()
    }

    return (
        <button className="editor-button" style={disabled ? { cursor: "auto" }: {}} disabled={disabled} onClick={() => redo()}>
            <MdRedo />
        </button>
    )
}
