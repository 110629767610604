

import { useButton } from 'react-aria';
import React, { useEffect, useRef, useState } from 'react';

import { BeatLoader } from 'react-spinners';

import './Button.less'

import useResizeObserver from '@react-hook/resize-observer'

// import PopoverTrigger from 'bwax-ui/components/PopoverTrigger';
import DropdownMenu from 'bwax-ui/components/DropdownMenu';

const Button = React.forwardRef((props, upperRef) => {
    const ref = useRef();
    const {
        appearance = "default", color = "gray", size = "normal", isLoading, children, isDisabled, style = {}, className,
        autoFocus,

    } = props;
    const { buttonProps } = useButton({
        ...props,
        isDisabled: isLoading || isDisabled
    }, ref);

    const sizeRef = useRef();
    const [fontColor, setFontColor] = useState(); // used for the spinner

    // 如果 isLoading， 把 children 换成 spinner，但保持按钮原来的大小
    useResizeObserver(ref, entry => {
        const { borderBoxSize } = entry;
        if (!isLoading && borderBoxSize && borderBoxSize[0]) {
            sizeRef.current = { width: borderBoxSize[0].inlineSize, height: borderBoxSize[0].blockSize };
        }
    })
    useEffect(() => {
        if (ref.current && typeof (window) !== 'undefined') {
            const fontColor = window.getComputedStyle(ref.current)["color"];
            setFontColor(fontColor)
        }
    }, [ref.current])


    const getStyle = () => {
        if (isLoading && sizeRef.current) {
            return { ...style, ...sizeRef.current, padding: 0 }
        } else {
            return style
        }
    }

    const renderLoading = () => {

        const height = sizeRef.current ? sizeRef.current.height * 0.2 : 6;
        return (
            <BeatLoader color={fontColor} size={height} />
        )
    }

    return (
        <button autoFocus={autoFocus} ref={r => {
            if (upperRef) {
                upperRef.current = r
            };
            ref.current = r;
        }} style={getStyle()} className={"lc-button" + (className ? " " + className : "")}
            data-color={color} data-appearance={appearance} data-loading={isLoading} data-size={size}
            {...buttonProps}
        >
            {isLoading ? renderLoading() : children}
        </button>
    );
})


export const ButtonStyleDiv = React.forwardRef(({ className, style, children, size = "normal", appearance = "default", color = "gray"}, ref) => {
    return (
        <div ref={ref} style={style} className={"lc-button" + (className ? " " + className : "")}
            data-color={color} data-appearance={appearance} data-size={size}
        >
            {children}
        </div>
    );
})



// plain button
export const Pressable = React.forwardRef(({ className, style, children, asChild = true, tabIndex, ...otherProps }, ref) => {

    const { buttonProps } = useButton({
        ...otherProps,
    }, ref);

    if (asChild) {
        const isCustomElement = children && children.type && typeof (children.type) !== "string";
        return React.cloneElement(children, { ...(isCustomElement ? otherProps : buttonProps), ref, tabIndex: tabIndex || "0" });
    } else {
        <div className={className} style={style} {...buttonProps} tabIndex={tabIndex || "0"}>
            {chilren}
        </div>
    }

});

export const IconButton = React.forwardRef(({ color = "gray", style, children, ...otherProps }, ref) => {
    const { buttonProps } = useButton({
        ...otherProps,
    }, ref);

    return (
        <button ref={ref} className="lc-icon-button" data-color={color} style={style} {...buttonProps}>
            {children}
        </button>
    )
});


export function iconButton(icon, onPress) {
    return (
        <Pressable onPress={onPress}>
            <div className="rounded w-[2rem] h-[2rem] hover:bg-[var(--gray3)] flex justify-center items-center text-[18px] text-[var(--gray11)]">
                {icon}
            </div>
        </Pressable>

    )
}

export function iconDropdownMenu(icon, items = [], options) {
    if(items.length == 0) {
        return null
    }
    return (
        <DropdownMenu items={items.filter(x => !!x) } {...options}>
            <div className="rounded w-[2rem] h-[2rem] hover:bg-[var(--gray3)] flex justify-center items-center text-[18px] text-[var(--gray11)]">
                {icon}
            </div>
        </DropdownMenu>
    )
}


export function create(props) {
    return <Button {...props} />
}

export default Button;