
// This file is generated. Don't modify it.
import React from 'react'
export default function OrderIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M10.3898061,5 C10.726669,5 11.0031508,5.276 10.9999729,5.615 L10.9999729,10.385 C10.9999729,10.724 10.726669,11 10.3866282,11 L5.61334468,11 C5.2764818,11 5,10.724 5,10.385 L5,5.615 C5,5.276 5.2764818,5 5.61652264,5 L10.3898061,5 Z M9.87995492,6.74146341 C10.0392433,6.67247387 10.1516821,6.50940767 10.1423122,6.31811847 C10.1423122,6.06724739 9.94866755,5.86027875 9.71129665,5.86027875 C9.47392575,5.86027875 9.28028107,6.0641115 9.28028107,6.31811847 C9.28028107,6.3902439 9.29902088,6.45923345 9.32713059,6.51881533 C9.3458704,6.57839721 9.37398011,6.63484321 9.41770633,6.68188153 C9.28965098,7.40940767 8.69934703,7.93310105 8.00285084,7.93310105 C7.30635466,7.93310105 6.72229731,7.41567944 6.58799536,6.67874564 C6.63484488,6.62857143 6.66295459,6.56898955 6.6785711,6.50627178 C6.70355751,6.4466899 6.71917401,6.38397213 6.71917401,6.31498258 C6.71917401,6.0641115 6.52552933,5.85714286 6.28815844,5.85714286 C6.05078754,5.85714286 5.85714286,6.06097561 5.85714286,6.31498258 C5.85714286,6.50940767 5.9695817,6.67247387 6.12887007,6.74146341 C6.28503513,7.70731707 7.08147696,8.42857143 8.00597415,8.42857143 C8.92734803,8.42857143 9.72378986,7.71045296 9.87995492,6.74146341 Z M3,3 L3,13 L13,13 L13,3 L3,3 Z M3,2 L13,2 C13.5522847,2 14,2.44771525 14,3 L14,13 C14,13.5522847 13.5522847,14 13,14 L3,14 C2.44771525,14 2,13.5522847 2,13 L2,3 C2,2.44771525 2.44771525,2 3,2 Z" id="OrderIcon-path-1"></path>
            </defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Personal/Order">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="OrderIcon-mask-2" fill="white">
                            <use href="#OrderIcon-path-1"></use>
                        </mask>
                        <use id="Icon/Personal/Order" fill={color} fillRule="nonzero" href="#OrderIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
