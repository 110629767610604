// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Plate$BwaxMobile = require("../../../../bwax-js/ml/plate.bs.js");
var Lang_eval$BwaxMobile = require("../../../../bwax-js/ml/lang/lang_eval.bs.js");
var Widget_common$BwaxMobile = require("./widget_common.bs.js");
var Runtime_common$BwaxMobile = require("../../../../bwax-js/ml/lang/mod/runtime_common.bs.js");

var module_name = "StyledUI";

var src = "\n\n-- 提供一系列预设的样式，或者带有样式的组件\n\nmodule " + (String(module_name) + ";\n\nimport Element exposing (Element, Rule);\n\ntype Appearance = Primary | Default | Outline;\ntype Size = Mini | Small | Medium | Large | XLarge;\n\n--- Radix colors\ntype ColorScale = \n  | Tomato | Red | Crimson | Pink | Plum | Purple | Violet | Indigo | Blue | Cyan | Teal | Green | Grass | Orange | Brown \n  | Sky | Mint | Lime | Yellow | Amber \n  | Gray | Mauve | Slate | Sage | Olive | Sand \n;\n\ncolor: ColorScale -> Int -> Color = external;\n\nbutton: \n    List (Rule msg)\n  -> ~{ onPress: msg, isDisabled: Bool, isLoading: Bool, appearance: Appearance\n      , color: ColorScale, size: Size\n      }\n  ->  Element msg \n  ->  Element msg = external;\n\n\ndialog: \n    List (Rule msg)\n  -> ~{ open: Bool, onOpenChange: Bool -> msg\n      , isImportant: Bool, isMain: Bool\n      }\n  -> Element msg -- content\n  -> Element msg = external;\n\n\npopover: \n    List (Rule msg)\n  -> ~{ content: Element msg!\n      }\n  -> Element msg -- content\n  -> Element msg = external;\n\n");

function pack_element(module_element) {
  return /* Delegated */Block.__(23, [
            module_name,
            module_element
          ]);
}

function fail(name) {
  throw [
        Lang_eval$BwaxMobile.Eval_exn,
        module_name + ("." + (name + ": Invalid Arguments"))
      ];
}

var externals_vals_000 = /* tuple */[
  "button",
  Widget_common$BwaxMobile.pack_element_func(3, (function (param) {
          if (param) {
            var match = param[0][0];
            if (typeof match === "number" || match.tag !== /* V_list */1) {
              return Runtime_common$BwaxMobile.invalid_args("button");
            } else {
              var match$1 = param[1];
              if (match$1) {
                var match$2 = match$1[0][0];
                if (typeof match$2 === "number" || match$2.tag !== /* V_record */2) {
                  return Runtime_common$BwaxMobile.invalid_args("button");
                } else {
                  var match$3 = match$1[1];
                  if (match$3) {
                    var match$4 = match$3[0][0];
                    if (typeof match$4 === "number" || !(match$4.tag === /* V_raw */7 && !match$3[1])) {
                      return Runtime_common$BwaxMobile.invalid_args("button");
                    } else {
                      var config_v = match$2[0];
                      var rules = Widget_common$BwaxMobile.raw_to_rules(match[0]);
                      var get_bool = function (name) {
                        return Plate$BwaxMobile.$$Option.with_default(false, Plate$BwaxMobile.$$Option.and_then(Lang_eval$BwaxMobile.as_bool, Plate$BwaxMobile.List.assoc(name, config_v)));
                      };
                      var get_tag_name = function (name) {
                        return Plate$BwaxMobile.$$Option.and_then((function (param) {
                                      var match = param[0];
                                      if (typeof match === "number" || match.tag !== /* V_tagged */6) {
                                        return ;
                                      } else {
                                        return Plate$BwaxMobile.Str.to_lower(match[0]);
                                      }
                                    }), Plate$BwaxMobile.List.assoc(name, config_v));
                      };
                      var isDisabled = get_bool("isDisabled");
                      var isLoading = get_bool("isLoading");
                      var onPress = Plate$BwaxMobile.List.assoc("onPress", config_v);
                      var appearance = get_tag_name("appearance");
                      var size = get_tag_name("size");
                      var color = get_tag_name("color");
                      var modal_config = /* record */[
                        /* onPress */onPress,
                        /* isDisabled */isDisabled,
                        /* isLoading */isLoading,
                        /* appearance */appearance,
                        /* color */color,
                        /* size */size
                      ];
                      return /* Delegated */Block.__(23, [
                                module_name,
                                /* Style_button */Block.__(0, [
                                    rules,
                                    modal_config,
                                    match$4[0]
                                  ])
                              ]);
                    }
                  } else {
                    return Runtime_common$BwaxMobile.invalid_args("button");
                  }
                }
              } else {
                return Runtime_common$BwaxMobile.invalid_args("button");
              }
            }
          } else {
            return Runtime_common$BwaxMobile.invalid_args("button");
          }
        }))
];

var externals_vals_001 = /* :: */[
  /* tuple */[
    "dialog",
    Widget_common$BwaxMobile.pack_element_func(3, (function (param) {
            if (param) {
              var match = param[0][0];
              if (typeof match === "number" || match.tag !== /* V_list */1) {
                return Runtime_common$BwaxMobile.invalid_args("dialog");
              } else {
                var match$1 = param[1];
                if (match$1) {
                  var match$2 = match$1[0][0];
                  if (typeof match$2 === "number" || match$2.tag !== /* V_record */2) {
                    return Runtime_common$BwaxMobile.invalid_args("dialog");
                  } else {
                    var match$3 = match$1[1];
                    if (match$3) {
                      var match$4 = match$3[0][0];
                      if (typeof match$4 === "number" || !(match$4.tag === /* V_raw */7 && !match$3[1])) {
                        return Runtime_common$BwaxMobile.invalid_args("dialog");
                      } else {
                        var config_v = match$2[0];
                        var rules = Widget_common$BwaxMobile.raw_to_rules(match[0]);
                        var get_bool = function (name) {
                          return Plate$BwaxMobile.$$Option.with_default(false, Plate$BwaxMobile.$$Option.and_then(Lang_eval$BwaxMobile.as_bool, Plate$BwaxMobile.List.assoc(name, config_v)));
                        };
                        var isOpen = get_bool("open");
                        var isImportant = get_bool("isImportant");
                        var isMain = get_bool("isMain");
                        var match$5 = Plate$BwaxMobile.List.assoc("onOpenChange", config_v);
                        var onOpenChange;
                        if (match$5 !== undefined) {
                          var tagger = match$5;
                          onOpenChange = (function (isOpen) {
                              return Lang_eval$BwaxMobile.apply_value(undefined, tagger, Runtime_common$BwaxMobile.pack_bool(isOpen), Runtime_common$BwaxMobile.nt);
                            });
                        } else {
                          onOpenChange = undefined;
                        }
                        var config = /* record */[
                          /* isOpen */isOpen,
                          /* onOpenChange */onOpenChange,
                          /* isImportant */isImportant,
                          /* isMain */isMain
                        ];
                        return /* Delegated */Block.__(23, [
                                  module_name,
                                  /* Style_dialog */Block.__(1, [
                                      rules,
                                      config,
                                      match$4[0]
                                    ])
                                ]);
                      }
                    } else {
                      return Runtime_common$BwaxMobile.invalid_args("dialog");
                    }
                  }
                } else {
                  return Runtime_common$BwaxMobile.invalid_args("dialog");
                }
              }
            } else {
              return Runtime_common$BwaxMobile.invalid_args("dialog");
            }
          }))
  ],
  /* :: */[
    /* tuple */[
      "popover",
      Widget_common$BwaxMobile.pack_element_func(3, (function (param) {
              if (param) {
                var match = param[0][0];
                if (typeof match === "number" || match.tag !== /* V_list */1) {
                  return Runtime_common$BwaxMobile.invalid_args("button");
                } else {
                  var match$1 = param[1];
                  if (match$1) {
                    var match$2 = match$1[0][0];
                    if (typeof match$2 === "number" || match$2.tag !== /* V_record */2) {
                      return Runtime_common$BwaxMobile.invalid_args("button");
                    } else {
                      var match$3 = match$1[1];
                      if (match$3) {
                        var match$4 = match$3[0][0];
                        if (typeof match$4 === "number" || !(match$4.tag === /* V_raw */7 && !match$3[1])) {
                          return Runtime_common$BwaxMobile.invalid_args("button");
                        } else {
                          var config_v = match$2[0];
                          var rules = Widget_common$BwaxMobile.raw_to_rules(match[0]);
                          var get_element = function (name) {
                            return Plate$BwaxMobile.$$Option.and_then((function (param) {
                                          var match = param[0];
                                          if (typeof match === "number" || match.tag !== /* V_raw */7) {
                                            return ;
                                          } else {
                                            return match[0];
                                          }
                                        }), Plate$BwaxMobile.List.assoc(name, config_v));
                          };
                          var match$5 = get_element("content");
                          var content = match$5 !== undefined ? match$5 : Runtime_common$BwaxMobile.invalid_args("popover");
                          var config = /* record */[/* content */content];
                          return /* Delegated */Block.__(23, [
                                    module_name,
                                    /* Style_popover */Block.__(2, [
                                        rules,
                                        config,
                                        match$4[0]
                                      ])
                                  ]);
                        }
                      } else {
                        return Runtime_common$BwaxMobile.invalid_args("button");
                      }
                    }
                  } else {
                    return Runtime_common$BwaxMobile.invalid_args("button");
                  }
                }
              } else {
                return Runtime_common$BwaxMobile.invalid_args("button");
              }
            }))
    ],
    /* [] */0
  ]
];

var externals_vals = /* :: */[
  externals_vals_000,
  externals_vals_001
];

var externals_000 = module_name;

var externals = /* tuple */[
  externals_000,
  externals_vals
];

var command_handlers = /* [] */0;

exports.module_name = module_name;
exports.src = src;
exports.pack_element = pack_element;
exports.fail = fail;
exports.externals_vals = externals_vals;
exports.command_handlers = command_handlers;
exports.externals = externals;
/* src Not a pure module */
