
// This file is generated. Don't modify it.
import React from 'react'
export default function LikeIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M11.8947238,6.21013026 C12.0994573,6.21013026 12.2716055,6.23047495 12.4358059,6.27455511 C13.5530805,6.57386974 14.2181907,7.72573747 13.9186597,8.84201403 C13.6600825,9.80653507 12.3655254,13.0643066 12.3105274,13.2022224 C12.3051717,13.2156913 12.2989047,13.2287798 12.2917699,13.2413968 C12.0371847,13.6826553 11.567345,13.962984 11.0548801,13.9840621 C11.0356536,13.9880541 11.0155134,13.9901463 10.9948921,13.9901463 L3.20757749,13.9903868 C2.54685605,13.9903868 2.00953741,13.4531162 2.00953741,12.7923948 L2.00953741,8.00003006 C2.00953741,7.33930862 2.54685605,6.80198998 3.20758952,6.80198998 L4.70518471,6.80198998 C6.35665164,6.80198998 7.70033902,5.45827856 7.70033902,3.80677555 L7.70033902,3.50722044 C7.70033902,2.6816012 8.37233902,2.00961323 9.19793421,2.00961323 C10.0236136,2.00961323 10.6955294,2.68138477 10.6955294,3.50722044 C10.6955294,4.21807014 10.6662508,5.41002605 10.4922749,6.20451503 L11.8947238,6.21013026 Z M5,8 L5,13 L6,13 L6,8 L5,8 Z" id="LikeIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Wireframe/Like">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="LikeIcon-mask-2" fill="white">
                            <use href="#LikeIcon-path-1"></use>
                        </mask>
                        <use id="Combined-Shape" fill={color} fillRule="nonzero" href="#LikeIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
        
    )
}
