
import React, { useState, useEffect, useRef } from 'react'

import { addQueryParam, removeQueryParam } from 'bwax/ml/lang/mod/builtin/StringHelper';

import ScrollHelper from 'bwax-ui/ScrollHelper';
import TextInput from 'bwax-ui/components/inputs/TextInput';

import Button, { Pressable, iconButton, iconDropdownMenu } from "bwax-ui/components/Button";
import { getFileIcon, getFileType } from "bwax-ui/components/FileIcon";
import dayjs from 'dayjs';

import classNames from 'classnames';
import ClampedText from 'bwax-ui/components/ClampedText';

import Avatar from 'bwax-ui/components/Avatar';

import Modal from 'bwax-ui/components/Modal';
import DualFileView from './DualFileView';

import { viewSupported } from 'bwax-ui/components/FileView';

import { useTrack } from 'bwax-ui/track';

import getFieldUsedValues from 'bwax/query/getFieldUsedValues';

import { toast } from 'bwax-ui/components/Toast';

import { downloadURL } from 'bwax-ui/ml/FrontEndHelper';

import SelectInput from 'bwax-ui/components/inputs/SelectInput';

import TranslationTaskFlags from './TranslationTaskFlags';
import TranslationCacheView from './TranslationCacheView';

import TranslationAdminTags from './admin/TranslationAdminTags';

const fieldPaths = [
    "文档", "源语言", "目标语言", "状态", "进度", "翻译后文档", "源语言词数", "价格", "已支付", "创建时间", "免费预览量", "模型", "已归档",
    "创建者.头像", "创建者.昵称", "创建者.手机号用户.手机号", "创建者.渠道", "分享",
    "有缓存", "已应用的翻译缓存修订",
    "背景修复", "翻译图片", "已应用模型", "术语表",
    "后台标签",
]


export function getNickNameAndAvatar(user, size = 20) {
    const phoneNumber = user && user.手机号用户 ? user.手机号用户.手机号 : "";
    const nickName = user ? (user.昵称 || phoneNumber.slice(-6)) : "User";
    const avatar = user ? <Avatar nickName={nickName} avatar={user.头像} size={size} /> : null;
    return { nickName, avatar };
}





// load all translations
// when clicked on, open a dual file view

export default function AdminTranslationList({ data, facade, viewEnv }) {

    const { params } = data;

    const { taskId: givenTaskId, userId: targetUserId } = params;
    const { routeTo, webEnv } = viewEnv;
    const { currentURL } = webEnv;

    const [tasks, setTasks] = useState();

    const [hasMore, setHasMore] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);

    const [taskCount, setTaskCount] = useState();

    const pageSize = 20;

    const listRef = useRef();

    const tasksRef = useRef();
    tasksRef.current = tasks;

    const [keyword, setKeyword] = useState("");

    const [targetUser, setTargetUser] = useState(); // { id, 头像, 昵称, 手机号用户.手机号, 渠道 }

    const [selectedStatus, setSelectedStatus] = useState(null);

    const [selectedTag, setSelectedTag] = useState(null);


    const entityName = "文档翻译-任务";
    async function doLoadData(offset = 0, isRefresh) {

        setLoadingMore(true);
        const [result, error] = await facade.listAll({
            entityName, fieldPaths,
            condition:
                [
                    [
                        targetUserId ? { field: "创建者.id", op: "eq", value: targetUserId } : null,
                        selectedStatus ? { field: "状态", op: "eq", value: selectedStatus } : null,
                        selectedTag ? { field: "后台标签", op: "contains", value: selectedTag } : null,
                    ].filter(x => !!x)
                ]
            ,

            sort: [
                { field: "创建时间", order: "DESC" }
            ],
            search: keyword ? {
                keyword,
                fields: [
                    { field: "文档.title", weight: 100 }, { field: "创建者.昵称", weight: 50 }, { field: "创建者.手机号用户.手机号", weight: 50 },
                    // { field: "后台标签", weight: 1000 }
                ]
            } : undefined,
            offset, pageSize
        }, { forceRefreshing: isRefresh })  // keyword, pageIndex / offset, ...
        setLoadingMore(false);

        if (error) {
            //  error handling TODO
        } else if (result) {

            const hasMore = result.pageSize + offset < result.count;
            setHasMore(hasMore);
            setTaskCount(result.count);

            if (isRefresh) {
                setTasks(result.data);
                tasksRef.current = result.data;
            } else {
                const existing = tasksRef.current || [];
                const toAppend = result.data.filter(s => !existing.some(e => e.id === s.id));
                const newData = [...existing, ...toAppend];

                setTasks(newData);
                tasksRef.current = newData;
            }

            if (hasMore) {
                setTimeout(() => {
                    if (listRef.current) {
                        const { scrollHeight, clientHeight } = listRef.current;
                        if (scrollHeight <= clientHeight && hasMore) {
                            doLoadData(offset + pageSize);
                        }
                    }
                }, 300);
            }
        }
    }

    const loadMore = () => {
        if (hasMore) {
            doLoadData(tasks.length);
        }
    }

    const loadMoreRef = useRef();
    loadMoreRef.current = loadMore;

    useEffect(() => {
        if (listRef.current) {
            const scrollHelper = new ScrollHelper(listRef.current, {
                onScrolledToBottom: _ => {
                    // console.log("should load more", loadMoreRef);
                    loadMoreRef.current && loadMoreRef.current();
                }
            });
            return () => {
                scrollHelper.cleanUp();
            }
        }
    }, [listRef.current]);


    useEffect(() => {
        setTasks()
        tasksRef.current = undefined;
        doLoadData();
    }, [keyword, selectedStatus, selectedTag])


    useEffect(() => {
        if (targetUserId) {
            if (targetUser && targetUser.id == targetUserId) {
                // do nothing
            } else {
                (async () => {
                    const [result, error] = await facade.findById(targetUserId, {
                        entityName: "用户", fieldPaths: ["头像", "昵称", "手机号用户.手机号", "渠道"],
                    }, { forceRefreshing: true });
                    if (!error && result) {
                        setTargetUser(result)
                    }
                })();
            }
        } else {
            setTargetUser()
        }
        doLoadData(0, true);
    }, [targetUserId]);


    const [taskToView, setTaskToView] = useState();

    useEffect(() => {
        if (givenTaskId) {
            const existingTask = (tasks || []).find(t => t.id == givenTaskId);
            if (existingTask) {
                setTaskToView(existingTask)
            } else {
                (async () => {
                    const [result, error] = await facade.findById(givenTaskId, {
                        entityName, fieldPaths,
                    }, { forceRefreshing: true });
                    if (!error && result) {
                        setTaskToView(result)
                    }
                })();
            }

        } else {
            // setTaskToView();
        }
    }, [givenTaskId]);


    const displayLang = (fieldName, value) => {
        const entity = facade.entities.find(e => e.name == entityName);

        const field = entity && entity.fields.find(f => f.name == fieldName);
        if (field && field.options && field.options.options) {
            const o = field.options.options.find(o => o.value == value);
            return o ? o.name : value
        } else {
            return value
        }
    }

    function renderTranslationSummary(task) {
        return (
            <div className="text-[var(--gray11)] font-size-11">
                {displayLang("源语言", task.源语言)} <i className='bx bx-right-arrow-alt translate-y-[2px]'></i> {displayLang("目标语言", task.目标语言)}
            </div>
        )
    }


    const [usedTags, setUsedTags] = useState([]);
    async function loadUsedTags() {
        const values = await getFieldUsedValues({ entityName, fieldName: "后台标签" })(facade.dlc);
        if (values) {
            setUsedTags(values.map(v => v.value));
        }
    }

    useEffect(() => {
        loadUsedTags();
    }, []);

    const [toAddTag, setToAddTag] = useState();

    function renderAddTag() {
        if (toAddTag) {
            return (
                <Modal isDismissable className="pt-3 max-w-xl" isOpen={true} contentClassName={""} isMain={false} onOpenChange={open => {
                    if (!open) {
                        loadUsedTags();
                        setToAddTag();
                    }
                }}>
                    {
                        closeModal => {
                            return (
                                <TranslationAdminTags task={toAddTag} facade={facade} getNickNameAndAvatar={getNickNameAndAvatar}
                                    onChange={tags => {
                                        setTasks(prev => {
                                            return prev.map(p => p.id == toAddTag.id ? { ...p, "后台标签": tags } : p)
                                        })
                                        setToAddTag(prev => {
                                            return { ...prev, "后台标签": tags }
                                        })

                                    }}
                                />
                            )
                        }
                    }
                </Modal>
            )
        }
        return null
    }


    const [viewCache, setViewCache] = useState(); // a task

    function renderViewChache() {
        if (viewCache) {
            return (
                <Modal isDismissable className="pt-3 max-w-2xl" isOpen={true} contentClassName={"sm:h-[85vh]"} isMain={true} onOpenChange={open => {
                    if (!open) {
                        setViewCache();
                    }
                }}>
                    {
                        closeModal => {
                            return (
                                <TranslationCacheView {...{ task: viewCache, facade }} />
                            )
                        }
                    }
                </Modal>
            )
        }
        return null
    }

    function renderTask(task, index) {

        const pendingPayment = task && task.价格 > 0 && !task.已支付;

        function statusIn(...array) {
            return task && array.indexOf(task.状态) != -1;
        }

        const translatedFile = task && task.翻译后文档;

        const isGeneratingPreview = task && pendingPayment && statusIn("Waiting", "Processing") // 有 task、待支付、task.状态 == Waiting / Processing 没有文件，有进度
        const isPreviewGenerated = task && pendingPayment && statusIn("Completed") && translatedFile;  // 有 task、待支付、task.状态 == Completed、有文件

        const isTranslating = task && !pendingPayment && statusIn("Waiting", "Processing"); // 有 task, 已支付, task.状态 == Waiting / Processing, 有进度，没文件
        const isTranslated = task && !pendingPayment && statusIn("Completed") && translatedFile; // 有 task, 已支付, task.状态 == Completed, 有文件

        const hasFailed = task && statusIn("Terminated"); // 有 task, task.状态 == "Terminated"
        const isCancelled = task && statusIn("Cancelled"); // 有 task, task.状态 == "Cancelled"
        const isNotSupported = task && statusIn("NotSupported") // 有 task, task.状态 == "NotSupported"

        const isPendingFix = task && statusIn("PendingFix");

        const freeNum = task.免费预览量;

        function renderStatus(className) {
            const [label, color, icon] = (() => {
                if (isGeneratingPreview) {
                    return [`正在翻译前 ${freeNum} token ` + task.进度 + "%", "indigo"]
                } else if (isPreviewGenerated) {
                    return [`待支付 (${task.价格}积分)`, "amber"]
                } else if (isTranslating) {
                    return ["正在翻译全文 " + task.进度 + "%", "violet"]
                } else if (isTranslated) {
                    return ["已翻译完成", "green", <i className='bx bx-check-circle'></i>]
                } else if (hasFailed) {
                    return ["已中断", "red"]
                } else if (isCancelled) {
                    return ["已取消", "gray"]
                } else if (isNotSupported) {
                    return ["不支持", "red"]
                } else if (isPendingFix) {
                    return ["等待处理", "slate"]
                }
                return []
            })();

            if (label) {
                return (
                    <div data-color={color} className={
                        classNames("inline-flex gap-1 items-center rounded-sm px-2 py-1.5 font-size-12 bg-[var(--color-3)] text-[var(--color-11)] leading-none whitespace-pre", className)
                    }>
                        {label}{icon || null}
                    </div>
                )
            }
            return null
        }

        const fileIcon = getFileIcon(task.文档);
        const { nickName, avatar } = getNickNameAndAvatar(task.创建者);

        async function restartTask() {
            const [r, e] = await facade.update({
                entityName, fieldPaths, formData: { 状态: "Waiting" }, id: task.id
            })
            if (!e && r) {
                setTasks(prev => prev.map(p => p.id === r.id ? r : p));
            }
        }


        async function finishTranslation() {
            if (!isPreviewGenerated) {
                console.log(">>> not generated preview, shouldn't be here");
                return
            }

            // 这里要再 load 一下 task 并检查 balance
            const [r, e] = await facade.findById(task.id, { entityName: "文档翻译-任务", fieldPaths: ["价格", "模型"] }, { forceRefreshing: true });
            if (!r) {
                toast({ title: "出错了：未能读取最新的价格" })
                return;
            }

            const [accountBalance, _] = await facade.findOne({
                entityName: "账户余额", condition: [[{ field: "用户.id", op: "eq", value: task.创建者.id }]],
                fieldPaths: ["剩余数量"]
            }, { forceRefreshing: true })

            if (!accountBalance || accountBalance.剩余数量 < r.价格) {
                toast({ title: "账户余额不足" })
                return;
            }

            const [result, error] = await facade.customMutation({
                entityName: "消费记录",
                interfaceName: "执行任务",
                args: [task.id],
                outputFieldPaths: [
                    ["文档翻译-任务", fieldPaths]
                ]
            });

            if (error) {
                toast({ title: error })
            } else if (result) {
                setTasks(prev => {
                    return prev.map(p => p.id == r.id ? result : p);
                })
            }
        };


        const restartButton = task.已归档 ? null : (
            iconDropdownMenu(<i className='bx bx-dots-horizontal-rounded font-size-12'></i>, [
                isPreviewGenerated ? {
                    label: "完整翻译（消费积分）", icon: <i className='bx bx-navigation'></i>, onSelect: _ => {
                        // complete
                        finishTranslation()
                    }
                } : null,
                hasFailed || isTranslated || isPreviewGenerated ? {
                    label: "重新翻译", icon: <i className='bx bx-redo'></i>, onSelect: _ => {
                        restartTask()
                    }
                } : null,
                {
                    label: "下载原文", icon: <i className='bx bx-download'></i>, onSelect: _ => {
                        downloadURL(task.文档.url)
                    }
                },
                task.翻译后文档 ? {
                    label: "下载译文", icon: <i className='bx bx-cloud-download'></i>, onSelect: _ => {
                        downloadURL(task.翻译后文档.url)
                    }
                } : null,
                {
                    label: "管理标签", icon: <i className='bx bx-tag-alt'></i>, onSelect: _ => {
                        setToAddTag(task);
                    }
                },
                {
                    label: "查看缓存", icon: <i className='bx bx-food-menu'></i>, onSelect: _ => {
                        setViewCache(task)
                    }
                },
            ], { placement: "bottom end" })
        );

        function renderModel() {
            if (task.模型 == "gpt-4") {
                return (
                    <div className="whitespace-pre text-[var(--blue11)] font-size-11 px-2 py-1 bg-[var(--blue3)] rounded leading-none">
                        GPT4
                    </div>
                )
            } else {
                return null
            }
        }
        function renderSharingStatus() {
            if (task.分享) {
                return (
                    <div className="whitespace-pre text-[var(--cyan11)] font-size-11 px-2 py-1 bg-[var(--cyan3)] rounded leading-none flex gap-1 items-center">
                        分享中<i className="bx bx-share bx-flip-horizontal" />
                    </div>
                )
            } else {
                return null
            }
        }

        function renderFlags() {

            return (
                <TranslationTaskFlags {...{ task, facade, hideModel: true }} />
            )
        }


        return (
            <Pressable key={task.id} onPress={_ => {
                if (task.文档 && task.翻译后文档) {
                    routeTo(addQueryParam("taskId", task.id, currentURL))
                }
            }}>
                <div className="flex py-4 sm:py-3 sm:pl-3 sm:pr-5 pl-2 pr-3 rounded hover:bg-[var(--gray2)] cursor-pointer w-full" style={{
                    opacity: task.已归档 ? 0.4 : 1
                }}>
                    <div className="flex gap-2 flex-col w-full">
                        <div className="flex gap-2 justify-between w-full">
                            <div className="flex gap-1 items-center">
                                <div className="whitespace-pre text-[var(--gray11)] font-size-12 px-2 ">
                                    {index + 1}
                                </div>
                                <div className="font-size-16 mr-1 ">
                                    {fileIcon}
                                </div>
                                <ClampedText tipEnabled={true}>{task.文档.title}</ClampedText>
                            </div>
                            <Pressable onPress={_ => {
                                if (task.创建者) {
                                    routeTo(addQueryParam("userId", task.创建者.id, currentURL));
                                    setTargetUser(task.创建者);
                                }
                            }}>
                                <div className="flex items-center gap-2 px-2 py-1 rounded">
                                    {task.创建者.渠道 ? (
                                        <div className="whitespace-pre text-[var(--lc-color-bg-1)] font-size-11 px-1 bg-[var(--indigo9)] opacity-90 rounded">
                                            {task.创建者.渠道}
                                        </div>
                                    ) : null}
                                    <ClampedText className="font-size-12 text-[var(--gray11)] whitespace-nowrap max-w-[5rem] flex items-center" tipEnabled={false}>{nickName}</ClampedText>
                                    {avatar}
                                </div>
                            </Pressable>
                        </div>
                        <div className="hidden sm:flex gap-2 justify-between">
                            <div className="hidden sm:flex gap-2 items-center">
                                {renderStatus("ml-6")}
                                <div className="whitespace-pre text-[var(--gray11)] font-size-12 px-2 py-1.5 bg-[var(--gray2)] rounded  leading-none">
                                    ￥{task.价格}
                                </div>
                                {renderTranslationSummary(task)}
                                <div className="text-[var(--gray11)] font-size-12 whitespace-pre">
                                    {task.源语言词数}({task.免费预览量})
                                </div>
                                {renderModel()}
                                {renderFlags()}
                                {renderSharingStatus()}
                                {restartButton}
                            </div>
                            <div className="whitespace-pre text-[var(--gray11)] font-size-12 mt-1 nowrap">
                                {dayjs(task.创建时间).format("MM-DD HH:mm")}
                            </div>
                        </div>
                        <div className="sm:hidden flex flex-col gap-2 ml-8">
                            <div className="flex gap-2 items-center justify-between">
                                <div className="flex gap-2 items-center">
                                    {renderTranslationSummary(task)}
                                    <div className="text-[var(--gray11)] font-size-12 whitespace-pre">
                                        {task.源语言词数} ({task.免费预览量})
                                    </div>
                                </div>
                                <div className="whitespace-pre text-[var(--gray11)] font-size-12 mt-1 nowrap">
                                    {dayjs(task.创建时间).format("MM-DD HH:mm")}
                                </div>
                            </div>
                            <div className="flex gap-2 items-center flex-wrap">
                                {renderStatus("-ml-1")}
                                <div className="whitespace-pre text-[var(--gray11)] font-size-12 px-2 py-1.5 bg-[var(--gray2)] rounded  leading-none">
                                    ￥{task.价格}
                                </div>
                                {renderModel()}
                                {renderFlags()}
                                {renderSharingStatus()}
                                {restartButton}
                            </div>
                        </div>
                        {task.后台标签 && task.后台标签.length > 0 ? (
                            <div className="flex flex-wrap gap-2 px-6">
                                {task.后台标签.map(label => {
                                    // const color = label == "敏感" ? "plum" : "violet"
                                    const color = "brown";
                                    return (
                                        <div key={label} className={classNames("bg-[var(--color-3)] px-2 py-1 rounded text-[var(--color-11)] font-size-13", {
                                            // "cursor-pointer hover:bg-[var(--color-4)]": !!onPress,
                                        })} data-color={color}>
                                            {label}
                                        </div>
                                    )
                                })}
                            </div>
                        ) : null}
                    </div>
                </div>

            </Pressable>
        )
    }

    return (
        <div className="flex flex-col h-full w-full max-w-3xl self-center pb-2 pt-2" >
            {targetUser ? (
                (() => {
                    const { nickName, avatar } = getNickNameAndAvatar(targetUser, 24)
                    return (
                        <div className="flex flex-col">
                            <div className="flex px-4 sm:px-6 items-center gap-2 py-2 rounded">
                                {iconButton(<i className='bx bx-arrow-back'></i>, _ => { routeTo(removeQueryParam("userId", currentURL)) })}
                                {avatar}
                                <ClampedText className="font-size-14 text-[var(--gray11)] whitespace-nowrap grow !w-auto flex items-center" tipEnabled={false}>{nickName}</ClampedText>
                                {targetUser.渠道 ? (
                                    <div className="whitespace-pre text-[var(--lc-color-bg-1)] font-size-11 px-1 bg-[var(--indigo9)] opacity-90 rounded">
                                        {targetUser.渠道}
                                    </div>
                                ) : null}
                            </div>
                            <div className="pl-[3.5rem] sm:pl-16 pr-6 pb-4">
                                <UserSummary userId={targetUser.id} facade={facade} />
                            </div>
                        </div>

                    )
                })()
            ) : null}
            <div className="flex pt-2 pb-3 px-6 sm:px-8 gap-2 items-start sm:items-center sm:flex-row flex-col justify-between w-full">
                <div className="flex gap-1 items-center">
                    <TextInput styled={true} className="!py-1.5 !px-1" color="violet" placeholder="搜索"
                        onChange={v => setKeyword(v)} value={keyword}
                        prefix={<i className='bx bx-search'></i>}
                        suffix={keyword ? <i className='bx bx-x cursor-pointer' onClick={_ => setKeyword("")}></i> : null}
                    />
                    {taskCount ? (
                        <div className="whitespace-pre text-[var(--gray11)] font-size-12 px-2 ">
                            共{taskCount || null}条记录
                        </div>
                    ) : null}
                    {iconButton(<i className='bx bx-refresh'></i>, _ => {
                        // console.log(">>> refresh");
                        doLoadData(0, true);
                    })}
                </div>
                <div className="flex items-center gap-3">
                    <div className="flex items-center gap-2 flex-row-reverse sm:flex-row">
                        {selectedStatus ? (
                            <div className="w-6 h-6 cursor-pointer flex justify-center items-center text-[var(--gray10)] rounded hover:bg-[var(--gray3)]" onClick={_ => {
                                setSelectedStatus(null)
                            }}>
                                <i className='bx bx-x'></i>
                            </div>
                        ) : null}
                        <SelectInput {...{
                            items: [
                                "Processing",
                                "Waiting",
                                "Terminated",
                                "NotSupported",
                                "PendingFix",
                                "Cancelled",
                                "Completed",
                            ].map(o => ({
                                value: o,
                                label: o,
                            }))
                            ,
                            placeholder: "选择状态",
                            style: { minWidth: "8rem" },
                            selected: selectedStatus,
                            onSelect: v => {
                                setSelectedStatus(v)
                            },
                            className: "grow"
                        }} />
                    </div>

                    <div className="flex items-center gap-2 flex-row-reverse sm:flex-row">
                        {selectedTag ? (
                            <div className="w-6 h-6 cursor-pointer flex justify-center items-center text-[var(--gray10)] rounded hover:bg-[var(--gray3)]" onClick={_ => {
                                setSelectedTag(null)
                            }}>
                                <i className='bx bx-x'></i>
                            </div>
                        ) : null}
                        <SelectInput {...{
                            items: (usedTags || []).map(o => ({
                                value: o,
                                label: o,
                            }))
                            ,
                            placeholder: "选择标签",
                            style: { minWidth: "6rem" },
                            selected: selectedTag,
                            onSelect: v => {
                                setSelectedTag(v)
                            },
                            className: "grow"
                        }} />
                    </div>

                </div>
            </div>
            <div className="grow flex py-2 flex-col gap-1 pl-1 pr-2 sm:px-4 overflow-auto" ref={listRef}>
                {
                    tasks ? tasks.map(renderTask) : null
                }
                {
                    loadingMore ? (
                        Array(tasks && tasks.length > 0 ? 5 : 10).fill(0).map((_, index) => {
                            return (
                                <React.Fragment key={"l" + index}>
                                    <div className="hidden sm:flex gap-2 sm:gap-16 py-3 px-3 sm:px-5 w-full">
                                        <div className="flex gap-4 grow">
                                            <div className="bg-[var(--gray2)] h-4 w-4" />
                                            <div className="bg-[var(--gray2)] h-4 grow" />
                                        </div>
                                        <div className="bg-[var(--gray2)] h-4 w-10" />
                                    </div>
                                    <div className="flex flex-col sm:hidden gap-2 py-5 px-4 w-full">
                                        <div className="flex gap-2 grow">
                                            <div className="bg-[var(--gray2)] h-4 w-4" />
                                            <div className="bg-[var(--gray2)] h-4 grow" />
                                        </div>
                                        <div className="flex justify-between pl-6">
                                            <div className="bg-[var(--gray2)] h-4 w-24 " />
                                            <div className="bg-[var(--gray2)] h-4 w-12 " />
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })
                    ) : null
                }
            </div>
            {taskToView ? (
                <Modal isDismissable className="!pt-3" isMain={true} isOpen={true} isLarge={true} contentClassName={"h-full sm:h-[90vh]"} onOpenChange={open => {
                    if (!open) {
                        routeTo(removeQueryParam("taskId", currentURL))
                        setTaskToView();
                    }
                }}>
                    {
                        closeModal => {
                            const task = taskToView;
                            if (viewSupported(taskToView.文档)) {

                                const fileIcon = getFileIcon(task.文档);
                                const { nickName, avatar } = getNickNameAndAvatar(task.创建者);

                                const title = (
                                    <div className="flex gap-1 items-center">
                                        <div className="font-size-16 mr-1 ">
                                            {fileIcon}
                                        </div>
                                        <ClampedText tipEnabled={true}>{task.文档.title}</ClampedText>
                                    </div>
                                )

                                const flags = (
                                    <div className="flex gap-1 items-center">
                                        <TranslationTaskFlags {...{ task, facade }} />
                                    </div>
                                )

                                const actionsAndAvatar = (
                                    <div className="flex items-center gap-1">
                                        {iconDropdownMenu(<i className='bx bx-download'></i>, [
                                            {
                                                label: "下载原文", onSelect: _ => {
                                                    downloadURL(task.文档.url)
                                                }
                                            },
                                            {
                                                label: "下载译文", onSelect: _ => {
                                                    downloadURL(task.翻译后文档.url)
                                                }
                                            }
                                        ], { placement: "bottom end" })}
                                        <div className="flex items-center gap-1">
                                            <ClampedText className="font-size-12 text-[var(--gray11)] whitespace-nowrap max-w-[5rem] flex items-center" tipEnabled={false}>{nickName}</ClampedText>
                                            {avatar}
                                        </div>
                                    </div>
                                )

                                return (
                                    <div className="w-full h-full flex flex-col">
                                        <div className="flex gap-2 justify-between px-4 py-3 w-full">
                                            {title}
                                            <div className="flex gap-2">
                                                {flags}
                                                {actionsAndAvatar}
                                            </div>
                                        </div>
                                        {/* <div className="sm:hidden flex gap-2 justify-between px-4 py-3 w-full">
                                            { title }
                                            { actionsAndAvatar }
                                        </div> */}
                                        <div className="grow">
                                            <DualFileView
                                                leftFile={taskToView.文档} rightFile={taskToView.翻译后文档}
                                                maxPages={15}
                                            />
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className="flex gap-2 px-8 items-center">
                                        文件类型暂不支持预览
                                        {iconDropdownMenu(<i className='bx bx-download'></i>, [
                                            {
                                                label: "下载原文", onSelect: _ => {
                                                    downloadURL(task.文档.url)
                                                }
                                            },
                                            {
                                                label: "下载译文", onSelect: _ => {
                                                    downloadURL(task.翻译后文档.url)
                                                }
                                            }
                                        ], { placement: "bottom end" })}
                                    </div>
                                )
                            }
                        }
                    }
                </Modal>
            ) : null}
            {renderAddTag()}
            {renderViewChache()}
        </div>
    )
}



// 
function UserSummary({ userId, facade }) {

    const [balance, setBalance] = useState();
    const [paidAmount, setPaidAmount] = useState();
    const [referral, setReferral] = useState();

    const [vistHeaders, setVistHeaders] = useState();

    async function loadBalance(userId) {
        const [result, error] = await facade.findOne({ entityName: "账户余额", condition: [[{ field: "用户.id", op: "eq", value: userId }]], fieldPaths: ["剩余数量"] }, { forceRefreshing: true });
        if (!error) {
            setBalance(result ? result.剩余数量 : 0);
        }
    }
    async function loadPaidAmount(userId) {
        const [result, error] = await facade.aggregate(
            {
                entityName: "充值记录", condition: [[{ field: "用户.id", op: "eq", value: userId }]],
                aggregate: [{
                    field: "相关申请.金额",
                    func: "SUM",
                    aliasName: "sum",
                }],
            },
            { forceRefreshing: true }
        );
        if (!error && result) {
            //            
            setPaidAmount(result[0].sum);
        }
    }

    async function loadReferral() {
        const [result, error] = await facade.findOne({
            entityName: "邀请记录", condition: [[{ field: "被邀请者.id", op: "eq", value: userId }]],
            fieldPaths: ["邀请码.用户.头像", "邀请码.用户.昵称", "邀请码.用户.手机号用户.手机号"]
        }, { forceRefreshing: true });
        if (!error) {
            setReferral(result);
        }
    }

    async function loadHeaders() {
        const [result, error] = await facade.findById(userId, {
            entityName: "用户",
            fieldPaths: ["首次访问header"]
        });
        if (!error) {
            setVistHeaders(result.首次访问header);
        }
    }

    useEffect(() => {
        loadBalance(userId);
        loadPaidAmount(userId);

        loadReferral();

        loadHeaders();

    }, [userId]);

    function renderAvatarAndNickName(user) {
        const { nickName, avatar } = getNickNameAndAvatar(user, 16);

        return (
            <div className="flex items-center gap-2 px-1 rounded">
                <div>推荐者:</div>
                {avatar}
                <ClampedText className="font-size-12 text-[var(--gray11)] whitespace-nowrap grow !w-auto flex items-center" tipEnabled={false}>{nickName}</ClampedText>
            </div>
        )
    }

    const referer = vistHeaders ? (vistHeaders["x-referrer"] || vistHeaders["referer"]) : "";

    const [showUserAccessLog, setShowUserAccessLog] = useState(false);

    return (
        <div className="flex flex-col gap-2 text-[var(--gray11)] font-size-12 ">
            <div className="flex gap-2 items-center">
                <div>余额: <span className="">{balance !== undefined ? (balance || 0) : "-"}</span></div>
                <div>充值: <span className="">{paidAmount !== undefined && paidAmount !== null ? "￥" + paidAmount : "-"}</span></div>
                {referral ? renderAvatarAndNickName(referral.邀请码.用户) : null}
            </div>
            {referer ? (<ClampedText lines={3} className="break-all cursor-pointer" onClick={_ => setShowUserAccessLog(true)}>{referer}</ClampedText>) : null}
            {showUserAccessLog ? (
                <Modal isDismissable className="!pt-6 max-w-3xl" isMain={true} isOpen={true} isLarge={true} contentClassName={"h-full sm:h-[90vh]"} onOpenChange={open => {
                    if (!open) {
                        setShowUserAccessLog(false)
                    }
                }}>
                    <UserAccessLog userId={userId} facade={facade} />
                </Modal>
            ) : null}
        </div>
    )
}

function UserAccessLog({ userId, facade }) {

    const [deviceIds, setDeviceIds] = useState([]);

    const [visits, setVisits] = useState();

    const [hasMore, setHasMore] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);

    const listRef = useRef();

    // 找到用户所有的 Device
    // 用 device 来找用 access log
    async function loadDevices() {
        // 
        const [result, e] = await facade.aggregate({
            entityName: "页面统计-页面访问",
            aggregate: [{ field: "id", func: "COUNT", aliasName: "count", }],
            group: [
                { field: "DeviceID" }
            ],
            condition: [[
                { field: "访问者.id", op: "eq", value: userId }
            ]]
        });
        if (!result) {
            return
        }
        const deviceIds = result.map(r => r.DeviceID);

        setDeviceIds(deviceIds)
        return deviceIds
    }

    async function loadVisits(deviceIds, offset = 0) {

        setLoadingMore(true);

        const [result, e] = await facade.listAll({
            entityName: "页面统计-页面访问",
            condition: [[
                { field: "DeviceID", op: "in", value: deviceIds }
            ]],
            sort: [
                { field: "创建时间", order: "ASC" }
            ],
            fieldPaths: ["路径", "Headers", "创建时间", "DeviceID"],
            pageSize: 16,
            offset
        });
        setLoadingMore(false);

        if (result) {
            const hasMore = result.pageSize + offset < result.count;
            setHasMore(hasMore);

            setVisits(prev => {
                if (offset == 0) {
                    return result.data
                } else {
                    return [...prev, ...result.data]
                }
            });

        }
    }

    async function loadDeviceAndVisits() {
        const deviceIds = await loadDevices();
        if (deviceIds) {
            loadVisits(deviceIds);
        }
    }

    useEffect(() => {
        loadDeviceAndVisits();
    }, [userId]);

    const loadMore = () => {
        if (hasMore && !loadingMore) {
            loadVisits(deviceIds, visits.length);
        }
    }

    const loadMoreRef = useRef();
    loadMoreRef.current = loadMore;



    useEffect(() => {
        if (listRef.current) {
            const scrollHelper = new ScrollHelper(listRef.current, {
                onScrolledToBottom: _ => {
                    loadMoreRef.current && loadMoreRef.current();
                }
            });
            return () => {
                scrollHelper.cleanUp();
            }
        }
    }, [listRef.current]);


    return (
        <div className="flex flex-col gap-3 pl-3 pr-4 py-2 h-full overflow-auto" ref={listRef}>
            {(visits || []).map(v => {
                const vistHeaders = v.headers || {};
                return (
                    <div className="flex flex-col gap-0.5 px-2 py-1" key={v.id}>
                        <div className="break-all font-size-13">
                            {v.路径}
                        </div>
                        <div className="flex justify-between sm:flex-row flex-col">
                            <div className="text-[var(--gray10)] font-size-12">
                                {vistHeaders["x-referrer"] || vistHeaders["referer"]}
                            </div>
                            <div className="text-[var(--gray11)] font-size-12">
                                {dayjs(v.创建时间).format("MM-DD HH:mm:ss")}
                            </div>
                        </div>
                        {/* <div className="text-[var(--gray10)] font-size-12">
                            {v.deviceID}
                        </div> */}
                    </div>
                )
            })}
            {
                loadingMore ? (
                    Array(visits && visits.length > 0 ? 5 : 10).fill(0).map((_, index) => {
                        return (
                            <div className="flex flex-col gap-0.5 px-2 py-1" key={"l" + index}>
                                <div className="break-all font-size-13 bg-[var(--gray2)] h-4">
                                </div>
                                <div className="flex justify-between sm:flex-row flex-col">
                                    <div className="bg-[var(--gray2)] h-4 w-24">
                                    </div>
                                    <div className="bg-[var(--gray2)] h-4 w-12">
                                    </div>
                                </div>
                            </div>
                        )
                    })
                ) : null
            }
        </div>
    );

}
