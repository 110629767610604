
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet-async'

import ToggleGroup from 'bwax-ui/components/inputs/ToggleGroup';


/*

最近一段时间的

尝试人数、消费人数，新增用户，新增付费

*/

export default function TranslationUserTrends({ facade }) {

    // TODO 增加按周来看趋势的功能；
    // 
    const [days, setDays] = useState(30);

    const { timeCondition, timeGroup } = (() => {
        if (typeof (days) == "number") {
            const timeCondition = { field: "创建时间", op: "gte", value: dayjs(new Date).subtract(days - 1, "day").hour(0).minute(0).second(0).millisecond(0).toJSON() };
            const timeGroup = [{
                field: "创建时间", func: "DATE", aliasName: "date"
            }];
            return { timeCondition, timeGroup }
        }
        if (days == "周") {
            const timeCondition = { field: "创建时间", op: "gte", value: dayjs(new Date).subtract(365, "day").hour(0).minute(0).second(0).millisecond(0).toJSON() };
            const timeGroup = [{
                field: "创建时间", func: "YEARWEEK", aliasName: "week"
            }];
            return { timeCondition, timeGroup }
        }
        if (days == "月") {
            const timeCondition = { field: "创建时间", op: "gte", value: dayjs(new Date).subtract(365, "day").hour(0).minute(0).second(0).millisecond(0).toJSON() };
            const timeGroup = [{
                field: "创建时间", func: "MONTH", aliasName: "month"
            }];
            return { timeCondition, timeGroup }
        }
    })();


    const extractDayValues = (result, aliasName) => {

        if (typeof (days) == "number") {
            const dayValues = Array(days).fill(0).map((_, index) => {
                if (index !== 0) {
                    return dayjs().subtract(index, "day").format("YYYY-MM-DD");
                } else {
                    return dayjs().format("YYYY-MM-DD");
                }
            });

            function getValue(date) {
                const r = result.find(r => r.date == date);
                return r ? r[aliasName] : 0
            }
            // 全部加起来
            return dayValues.map(getValue)
        }
        if (days == "周") {

            const weekValues = Array(15).fill(0).map((_, index) => {
                if(result[result.length - 1 - index]) {
                    return result[result.length - 1 - index].week
                }
                return "-"
            });

            function getValue(week) {
                const r = result.find(r => r.week == week);
                return r ? r[aliasName] : 0
            }

            // 全部加起来
            return weekValues.map(getValue)
        }
        if (days == "月") {
            const monthValues = Array(5).fill(0).map((_, index) => {
                const m = dayjs().month() - index
                if (m < 0) {
                    return 12 + m
                } else {
                    return m
                }
            });

            function getValue(month) {
                const r = result.find(r => r.month == month + 1);
                return r ? r[aliasName] : 0
            }
            // 全部加起来
            return monthValues.map(getValue)
        }
    }


    // 尝试人数
    const [attemptedUsers, setAttemptedUsers] = useState();
    async function loadAttemptedUsers() {
        const [result, error] = await facade.aggregate({
            entityName: "文档翻译-任务",
            aggregate: [{ field: "创建者.id", func: "COUNT_DISTINCT", aliasName: "count" }],
            group: timeGroup,
            condition: [[
                timeCondition,
                { field: "创建者.渠道", op: "eq", value: "ga" }
            ]]
        })

        if (result) {
            setAttemptedUsers(extractDayValues(result, "count"))
        }
    }

    // 消费人数
    const [spendUsers, setSpendUsers] = useState();
    async function loadSpendUsers() {
        const [result, error] = await facade.aggregate({
            entityName: "消费记录",
            aggregate: [{ field: "用户.id", func: "COUNT_DISTINCT", aliasName: "count", }],
            group: timeGroup,
            condition: [[
                timeCondition,
                { field: "用户.渠道", op: "eq", value: "ga" }
                // { field: "价格", op: "gt", value: 0 },
                // { field: "已支付", op: "eq", value: true }
            ]]
        });

        if (result) {
            setSpendUsers(extractDayValues(result, "count"))
        }
    }

    // 消费积分
    const [spentAmounts, setSpentAmounts] = useState();
    async function loadSpentAmounts() {
        const [result, error] = await facade.aggregate({
            entityName: "消费记录",
            aggregate: [{ field: "数量", func: "SUM", aliasName: "sum", }],
            group: timeGroup,
            condition: [[
                timeCondition,
                { field: "用户.渠道", op: "eq", value: "ga" }
                // { field: "价格", op: "gt", value: 0 },
                // { field: "已支付", op: "eq", value: true }
            ]]
        });
        if (result) {
            setSpentAmounts(extractDayValues(result, "sum"))
        }
    }


    // 新增用户
    const [newUsers, setNewUsers] = useState();
    async function loadNewUsers() {
        const [result, error] = await facade.aggregate({
            entityName: "用户",
            aggregate: [{ field: "id", func: "COUNT_DISTINCT", aliasName: "count", }],
            group: timeGroup,
            condition: [[
                timeCondition,
                { field: "渠道", op: "eq", value: "ga" }
            ]]
        });
        if (result) {
            setNewUsers(extractDayValues(result, "count"))
        }
    }

    // 新增付费
    const [newPaidUsers, setNewPaidUsers] = useState();
    async function loadNewPaidUsers() {
        const [result, error] = await facade.aggregate({
            entityName: "账户余额",
            aggregate: [{ field: "id", func: "COUNT_DISTINCT", aliasName: "count", }],
            group: timeGroup,
            condition: [[
                timeCondition,
                { field: "用户.渠道", op: "eq", value: "ga" }
            ]]
        });
        if (result) {
            setNewPaidUsers(extractDayValues(result, "count"))
        }
    }



    // 充值金额
    const [paidAmounts, setPaidAmounts] = useState();
    async function loadPayAmounts() {
        const [result, error] = await facade.aggregate({
            entityName: "充值记录",
            aggregate: [{ field: "相关申请.金额", func: "SUM", aliasName: "sum", }],
            group: timeGroup,
            condition: [[
                timeCondition,
                { field: "相关申请.订单.支付状态", op: "eq", value: "已支付" },
                { field: "用户.渠道", op: "eq", value: "ga" },
                { field: "作废", op: "ne", value: true },
            ]]
        });
        if (result) {
            setPaidAmounts(extractDayValues(result, "sum"))
        }
    }


    useEffect(() => {
        loadAttemptedUsers();
        loadSpendUsers();
        loadNewUsers();
        loadNewPaidUsers();

        loadPayAmounts();
        loadSpentAmounts()

    }, [days]);


    const renderRow = (label, values, className) => {
        if (!values) { return null }
        return (
            <div className={classNames("w-full flex py-1.5", className)} key={label}>
                <div className="w-[13%] flex px-1 whitespace-pre">
                    {label}
                </div>
                <div className="w-[87%] flex text-right whitespace-pre">
                    {values.map((v, i) => {
                        return (
                            <div key={i} className="pl-1 pr-1" style={{
                                width: ((1 / values.length) * 100) + "%"
                            }}>
                                {v}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    return (
        <div className="self-center max-w-2xl w-full py-4 flex flex-col px-0 sm:px-4 h-full">
            <Helmet>
                <title>文档翻译 | 用户趋势</title>
            </Helmet>
            <div className="flex flex-col px-3 gap-1">
                {/* <TextInput className="w-[2.5rem] !py-1" styled={true} color={"violet"} value={days || ""} onChange={d => {
                    if (d) {
                        setDays(parseInt(d))
                    } else {
                        setDays(0)
                    }
                }}></TextInput> */}
                <div className="self-start">
                    <div className="whitespace-pre text-[var(--lc-color-bg-1)] font-size-11 px-1 bg-[var(--indigo9)] opacity-90 rounded">
                        ga
                    </div>
                </div>
                <div className="self-start">
                    <ToggleGroup color="violet" items={[
                        { label: "30", value: 30 },
                        { label: "60", value: 60 },
                        { label: "周", value: "周" },
                        { label: "月", value: "月" }
                    ]} value={days} onChange={setDays}>
                    </ToggleGroup>
                </div>
                {
                    renderRow(
                        days == "周" || days == "月" ? days : "日期",
                        ["尝试人数", "消费人数", "新增用户", "新增付费", "消费积分", "充值金额",
                        ],
                        "text-[var(--gray11)] !py-2 sm:text-[10px] text-[11px]"
                    )
                }
            </div>
            <div className="sm:text-[13px] text-[13px] grow flex flex-col overflow-auto px-3 text-right">
                {
                    (() => {
                        if (days == "周") {
                            return (
                                Array(15).fill(0).map((_, index) => {
                                    const w = dayjs().week() - index;
                                    const firstDay = dayjs().week(w).day(0).format("MM-DD");
                                    return renderRow(firstDay,
                                        [
                                            attemptedUsers ? attemptedUsers[index] : "-",
                                            spendUsers ? spendUsers[index] : "-",
                                            newUsers ? newUsers[index] : "-",
                                            newPaidUsers ? newPaidUsers[index] : "-",
                                            spentAmounts ? spentAmounts[index] : "-",
                                            paidAmounts ? paidAmounts[index] : "-",
                                        ]
                                    )
                                })
                            )
                        } else if (days == "月") {
                            return (
                                Array(5).fill(0).map((_, index) => {
                                    let m = dayjs().month() - index;
                                    m = m >= 0 ? m : 12 + m;
                                    return renderRow(m + 1,
                                        [
                                            attemptedUsers ? attemptedUsers[index] : "-",
                                            spendUsers ? spendUsers[index] : "-",
                                            newUsers ? newUsers[index] : "-",
                                            newPaidUsers ? newPaidUsers[index] : "-",
                                            spentAmounts ? spentAmounts[index] : "-",
                                            paidAmounts ? paidAmounts[index] : "-",
                                        ]
                                    )
                                })
                            )
                        } else {
                            return (
                                Array(days).fill(0).map((_, index) => {
                                    const d = (() => {
                                        if (index !== 0) {
                                            return dayjs().subtract(index, "day").format("MM-DD");
                                        } else {
                                            return dayjs().format("MM-DD");
                                        }
                                    })()
                                    return renderRow(d,
                                        [
                                            attemptedUsers ? attemptedUsers[index] : "-",
                                            spendUsers ? spendUsers[index] : "-",
                                            newUsers ? newUsers[index] : "-",
                                            newPaidUsers ? newPaidUsers[index] : "-",
                                            spentAmounts ? spentAmounts[index] : "-",
                                            paidAmounts ? paidAmounts[index] : "-",
                                        ]
                                    )
                                })
                            )
                        }

                    })()
                }
            </div>
        </div>
    )
}






