
import { toast } from 'bwax-ui/ml/FrontEndHelper';

import { isIOS, isWxWork, isWeChat } from 'bwax/clientEnv'

const getShareUrl = () => isIOS() && false ? window.originalHref : location.href

const getConfigUrl = () => getShareUrl().split('#')[0]


const batchedQuery = `
query ($wxApiList: [String]!, $wxWorkApiList: [String]!,  $url:String!) {
  wxMpJsConfig(jsApiList: $wxApiList, url: $url) 
  wxWorkAgentJsConfig(jsApiList: $wxWorkApiList, url: $url) 
}
`

const wxApiList = [
    "hideMenuItems", "hideAllNonBaseMenuItem",
    "showMenuItems", "showAllNonBaseMenuItem",
    'onMenuShareTimeline', 'onMenuShareAppMessage'
]
const wxWorkApiList = [
    "getCurExternalContact"
]

// configure wx work and then do something:
export function setupWxWork(queryRunner, succeed, fail) { 
    
    if (typeof (wx) !== "undefined" && (isWxWork())) {
        const url = getConfigUrl()
        queryRunner(batchedQuery, {
            url, wxApiList, wxWorkApiList
        }).then(result => {
            const data = JSON.parse(result).data
            if (data) {
                const { wxMpJsConfig, wxWorkAgentJsConfig } = data
                if (wxMpJsConfig && wxWorkAgentJsConfig) {
                    // 先配置 wx， 再配置 wx work
                    wx.config(wxMpJsConfig)
                    wx.ready(() => {
                        (async () => {
                            if(!wx.agentConfig) {
                                fail("Wx has no agentConfig");
                            } else {
                                wx.agentConfig({
                                    ...wxWorkAgentJsConfig,
                                    success: res => {
                                        // console.log("WXWORK CONFIG SUCCEED", res);
                                        succeed();
                                    },
                                    fail: res => {
                                        // console.log("WXWORK CONFIG FAILED", res);
                                        fail(res.err_msg);
                                    }
                                })
                            }

                        })()
                    })
                    wx.error(() => {
                        fail("Wx 配置失败")
                    })
                } else {
                    fail("No config returned")
                }
            } else {
                fail("No data")
            }
        })

    } else {
        fail("Not in Wx Work")
    }
}

export function getWxWorkExternalContactID(queryRunner) {
    return new Promise((resolve, reject) => {
        if(typeof(wx) !== 'undefined') {
            setupWxWork(
                queryRunner, 
                () => {
                    wx.invoke('getCurExternalContact', {}, function(res){
                        if(res.err_msg == "getCurExternalContact:ok"){
                            resolve(res.userId) ; //返回当前外部联系人userId
                        } else {
                            resolve(undefined) 
                        }
                    });
                }, 
                errorMesage => {
                    console.warn(errorMesage);

                    toast(errorMesage);                

                    resolve(undefined);
                }
            )
        } else {
            // None
            resolve(undefined);
        }
    })
}