

import React, { useRef, useState } from 'react';

import { Popover as PopoverComp, ArrowContainer } from 'react-tiny-popover'

export default function CommonPopup(props) {

    const {
        className, style = {},
        visible: givenVisible, trigger = "click",
        content,

        arrowSize, arrowColor, arrowStyle,

        children, 

        bindVisibleSetter, 

        ...remaining
    } = props;

    const [popoverVisible, setPopoverVisible] = useState(false);

    if(bindVisibleSetter) {
        bindVisibleSetter(visible => {
            setPopoverVisible(visible)
        });
    }

    const contentWrapperRef = useRef(null);


    const targetHovering = useRef(false);
    const contentHovering = useRef(false);
    // 
    const isGivenVisible = givenVisible !== undefined;
    const getTriggerProps = propSet => {

        const delay = 180;
        const triggerProps = {
            "hover": {
                target: {
                    onMouseEnter: () => {
                        targetHovering.current = true;
                        setTimeout(() => {
                            if (targetHovering.current) {
                                setPopoverVisible(true);
                            }
                        }, delay)

                    },
                    onMouseLeave: () => {
                        targetHovering.current = false;
                        setTimeout(() => {
                            if (!contentHovering.current && !targetHovering.current) {
                                setPopoverVisible(false);
                            }
                        }, delay)
                    }
                },
                container: {

                },
                contentWrapper: {
                    onMouseEnter: () => {
                        contentHovering.current = true;
                        setTimeout(() => {
                            if (contentHovering.current) {
                                setPopoverVisible(true);
                            }
                        }, delay)

                    },
                    onMouseLeave: () => {
                        contentHovering.current = false
                        setTimeout(() => {
                            if (!contentHovering.current && !targetHovering.current) {
                                setPopoverVisible(false);
                            }
                        }, delay)
                    }
                }
            },
            "click": {
                target: {
                    onClick: () => {
                        setPopoverVisible(true);
                    }
                },
                container: {
                    onClickOutside: () => {
                        setPopoverVisible(false);
                    }
                }
            },
            "focus": {

            }
        };
        return isGivenVisible ? {} : (triggerProps[trigger][propSet] || {})
    }

    // children 应该一个 element;
    const inner = React.cloneElement(children, getTriggerProps("target"));

    const containerProps = {
        isOpen: isGivenVisible ? givenVisible : popoverVisible,
        ...getTriggerProps("container")
    }

    const contentWrapperProps = {
        ...getTriggerProps("contentWrapper")
    }

    const contentWrapper = (
        <div className="content-wrapper" ref={contentWrapperRef} {
            ...contentWrapperProps
        }>
            {content}
        </div>
    )


    const getContent = () => {
        if (arrowSize) {
            return ({ position, childRect, popoverRect }) => {

                return (
                    <ArrowContainerWrapper // if you'd like an arrow, you can import the ArrowContainer!
                        {...{
                            position, childRect, popoverRect,
                            arrowSize, arrowStyle, arrowColor,
                        }}
                    >
                        {contentWrapper}
                    </ArrowContainerWrapper>
                )

            }

        } else {
            return contentWrapper
        }
    }

    // onClickOutside 要专门处理：
    const { onClickOutside, ...popoverProps } = {
        ...containerProps,
        ...remaining
    }

    return (
        <PopoverComp
            containerClassName={"bw-common-popup" + (className ? " " + className : "")}
            containerStyle={style}
            {...{
                content: getContent(),
                onClickOutside: e => {
                    if (contentWrapperRef.current && !isInsideContainer(contentWrapperRef.current, e.target)) {
                        onClickOutside && onClickOutside();
                    }
                    
                },
                ...popoverProps
            }}
        >
            {inner}
        </PopoverComp>
    )
}


function ArrowContainerWrapper({ position, childRect, popoverRect, arrowSize, arrowStyle, arrowColor, children }) {

    return (
        <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
            {...{
                position, childRect, popoverRect,
                arrowSize, arrowStyle, arrowColor,

                className: "arrow-container",
                arrowClassName: "arrow",

                style: {
                    // 为了解决第一次显示时箭头位置不对的问题：
                    opacity: popoverRect.left !== 0 ? ((arrowStyle || {})["opacity"] || 1) : 0
                }
            }}
        >
            {children}
        </ArrowContainer>
    )
}


// both are DOM node
function isInsideContainer(container, target) {
    if(container.contains(target)){
        return true
    }

    // 为了解决 react-tiny-select 的 option 一旦被点击传到 onClickOutside 时已经被 detached 的问题：

    // 也包括 resuite 的各种 picker
    let classNames = []
    let current = target;
    let counter = 0;
    while(current && counter < 100) {
        classNames.push(current.className);
        current = current.parentElement;
        counter += 1;
    }

    console.log("ClassNames", classNames);
    const ignored = ["bw-select__option", "rs-calendar", "rs-picker-menu",  "ant-btn", "anticon"];
    if(classNames.some(c => ignored.some(ig => c.indexOf && c.indexOf(ig) !== -1))) {
        return true
    }


    return false;
}