import React from 'react'

import StyleText from './StyleText'

import { characterRanges, groupCharsWithTags } from './utils'

export default (props) => {

    const { originalText, entityMap, inlineStyleRanges, entityRanges, entityToReact, styleToReact } = props

    if (entityRanges.length === 0) {
        return (
            <StyleText
                text={originalText}
                startOffset={0}
                styleToReact={styleToReact}
                inlineStyleRanges={inlineStyleRanges} />
        )
    } else {

        let entityCharacters = []
        const originalTextArray = [...originalText]
        for (let i = 0; i < originalTextArray.length; i++) {
            entityCharacters[i] = {
                characters: originalTextArray[i],
                offset: i,
                tags: (characterRanges(i, entityRanges)).map(n => n.key)
            }
        }

        const groupEntityCharacters = groupCharsWithTags(entityCharacters)

        return (
            <>
                {
                    groupEntityCharacters.map((ges, idx) => {

                        const entityKey = ges.tags[0]
                        const entity = entityKey !== undefined ? entityMap[entityKey] : null
                        const wrapElement = entity !== null ? (entityToReact(entity) || <></>) : <></>

                        return React.cloneElement(
                            wrapElement,
                            { key: idx },
                            <StyleText
                                text={ges.characters}
                                startOffset={ges.offset}
                                styleToReact={styleToReact}
                                inlineStyleRanges={inlineStyleRanges} />
                        )
                    })
                }
            </>
        )
    }
}