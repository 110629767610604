// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Plate$BwaxMobile = require("../../../../bwax-js/ml/plate.bs.js");
var Lang_eval$BwaxMobile = require("../../../../bwax-js/ml/lang/lang_eval.bs.js");
var Query_entry$BwaxMobile = require("../../../../bwax-js/ml/query/query_entry.bs.js");
var Widget_common$BwaxMobile = require("./widget_common.bs.js");
var Runtime_common$BwaxMobile = require("../../../../bwax-js/ml/lang/mod/runtime_common.bs.js");

var module_name = "DataUI";

var src = "\nmodule " + (String(module_name) + ";\n\nimport Element exposing (Rule, Element);\n\n\nwithRecord: \n    QueryConfig a\n  -> ~ { isLazy : Bool, loading: Element msg, notFound: Element msg ! } \n                                              -- 如果不是 lazy，loading element 通常不会显示\n  -> (# a -> Element msg)                     -- render\n  -> Element msg = external;\n\nwithRecordList: \n    QueryConfig a \n  -> ~{ isLazy : Bool, loading: Element msg \n      , pageSize: Int, offset: Int            -- paginiation settings，默认为 10 和 0\n      , repeat: Int  -- use for UI testing\n      }\n  -> (List (Element msg) -> Element msg)      -- list container\n  -> (# a -> Element msg)                     -- render\n  -> Element msg = external;\n\n\n-- 当页面滚动到底部时，自动尝试加载更多\n-- 应该要要有更好的抽象方式，暂时用这个\nrecordListWithPullUpLoadMore:\n    QueryConfig a \n  -> ~{ isLazy : Bool, loading: Element msg \n      , pageSize: Int \n      }\n  -> (List (Element msg) -> Element msg)\n  -> (# a -> Element msg)\n  -> Element msg = external;\n\n\n");

function pack_element(module_element) {
  return Widget_common$BwaxMobile.element_to_value(/* Delegated */Block.__(23, [
                module_name,
                module_element
              ]));
}

function fail(name) {
  throw [
        Lang_eval$BwaxMobile.Eval_exn,
        module_name + ("." + (name + ": Invalid Arguments"))
      ];
}

function get_lazy(config_v) {
  return Plate$BwaxMobile.$$Option.with_default(false, Plate$BwaxMobile.$$Option.and_then(Lang_eval$BwaxMobile.as_bool, Plate$BwaxMobile.List.assoc("isLazy", config_v)));
}

function get_element(name, config_v) {
  return Plate$BwaxMobile.$$Option.and_then((function (param) {
                var match = param[0];
                if (typeof match === "number" || match.tag !== /* V_raw */7) {
                  return ;
                } else {
                  return match[0];
                }
              }), Plate$BwaxMobile.List.assoc(name, config_v));
}

function get_pageSize(config_v) {
  return Plate$BwaxMobile.$$Option.with_default(10, Plate$BwaxMobile.$$Option.and_then(Lang_eval$BwaxMobile.as_int, Plate$BwaxMobile.List.assoc("pageSize", config_v)));
}

function get_offset(config_v) {
  return Plate$BwaxMobile.$$Option.with_default(0, Plate$BwaxMobile.$$Option.and_then(Lang_eval$BwaxMobile.as_int, Plate$BwaxMobile.List.assoc("offset", config_v)));
}

var externals_vals_000 = /* tuple */[
  "withRecord",
  Runtime_common$BwaxMobile.pack_func(3, (function (param) {
          if (param) {
            var match = param[1];
            if (match) {
              var match$1 = match[0][0];
              if (typeof match$1 === "number" || match$1.tag !== /* V_record */2) {
                return Runtime_common$BwaxMobile.invalid_args("withRecord");
              } else {
                var match$2 = match[1];
                if (match$2 && !match$2[1]) {
                  var config_v = match$1[0];
                  var query_config = Query_entry$BwaxMobile.make_query_config(param[0]);
                  var lazy_v = get_lazy(config_v);
                  var loading = get_element("loading", config_v);
                  var notFound = get_element("notFound", config_v);
                  return Widget_common$BwaxMobile.element_to_value(/* Delegated */Block.__(23, [
                                module_name,
                                /* With_record */Block.__(0, [
                                    query_config,
                                    /* tuple */[
                                      lazy_v,
                                      loading,
                                      notFound
                                    ],
                                    match$2[0]
                                  ])
                              ]));
                } else {
                  return Runtime_common$BwaxMobile.invalid_args("withRecord");
                }
              }
            } else {
              return Runtime_common$BwaxMobile.invalid_args("withRecord");
            }
          } else {
            return Runtime_common$BwaxMobile.invalid_args("withRecord");
          }
        }))
];

var externals_vals_001 = /* :: */[
  /* tuple */[
    "withRecordList",
    Runtime_common$BwaxMobile.pack_func(4, (function (param) {
            if (param) {
              var match = param[1];
              if (match) {
                var match$1 = match[0][0];
                if (typeof match$1 === "number" || match$1.tag !== /* V_record */2) {
                  return Runtime_common$BwaxMobile.invalid_args("withRecordList");
                } else {
                  var match$2 = match[1];
                  if (match$2) {
                    var match$3 = match$2[1];
                    if (match$3 && !match$3[1]) {
                      var config_v = match$1[0];
                      var query_config = Query_entry$BwaxMobile.make_query_config(param[0]);
                      var lazy_v = get_lazy(config_v);
                      var loading = get_element("loading", config_v);
                      var pageSize = get_pageSize(config_v);
                      var offset = get_offset(config_v);
                      var repeat = Plate$BwaxMobile.$$Option.and_then(Lang_eval$BwaxMobile.as_int, Plate$BwaxMobile.List.assoc("repeat", config_v));
                      return Widget_common$BwaxMobile.element_to_value(/* Delegated */Block.__(23, [
                                    module_name,
                                    /* With_record_list */Block.__(1, [
                                        query_config,
                                        /* tuple */[
                                          lazy_v,
                                          loading,
                                          pageSize,
                                          offset,
                                          repeat
                                        ],
                                        match$2[0],
                                        match$3[0]
                                      ])
                                  ]));
                    } else {
                      return Runtime_common$BwaxMobile.invalid_args("withRecordList");
                    }
                  } else {
                    return Runtime_common$BwaxMobile.invalid_args("withRecordList");
                  }
                }
              } else {
                return Runtime_common$BwaxMobile.invalid_args("withRecordList");
              }
            } else {
              return Runtime_common$BwaxMobile.invalid_args("withRecordList");
            }
          }))
  ],
  /* :: */[
    /* tuple */[
      "recordListWithPullUpLoadMore",
      Runtime_common$BwaxMobile.pack_func(4, (function (param) {
              if (param) {
                var match = param[1];
                if (match) {
                  var match$1 = match[0][0];
                  if (typeof match$1 === "number" || match$1.tag !== /* V_record */2) {
                    return Runtime_common$BwaxMobile.invalid_args("recordListWithPullUpLoadMore");
                  } else {
                    var match$2 = match[1];
                    if (match$2) {
                      var match$3 = match$2[1];
                      if (match$3 && !match$3[1]) {
                        var config_v = match$1[0];
                        var query_config = Query_entry$BwaxMobile.make_query_config(param[0]);
                        var lazy_v = get_lazy(config_v);
                        var loading = get_element("loading", config_v);
                        var pageSize = get_pageSize(config_v);
                        return Widget_common$BwaxMobile.element_to_value(/* Delegated */Block.__(23, [
                                      module_name,
                                      /* Record_list_with_pull_up_load_more */Block.__(2, [
                                          query_config,
                                          /* tuple */[
                                            lazy_v,
                                            loading,
                                            pageSize
                                          ],
                                          match$2[0],
                                          match$3[0]
                                        ])
                                    ]));
                      } else {
                        return Runtime_common$BwaxMobile.invalid_args("recordListWithPullUpLoadMore");
                      }
                    } else {
                      return Runtime_common$BwaxMobile.invalid_args("recordListWithPullUpLoadMore");
                    }
                  }
                } else {
                  return Runtime_common$BwaxMobile.invalid_args("recordListWithPullUpLoadMore");
                }
              } else {
                return Runtime_common$BwaxMobile.invalid_args("recordListWithPullUpLoadMore");
              }
            }))
    ],
    /* [] */0
  ]
];

var externals_vals = /* :: */[
  externals_vals_000,
  externals_vals_001
];

var externals_000 = module_name;

var externals = /* tuple */[
  externals_000,
  externals_vals
];

var lazyDefault = false;

var pageSizeDefault = 10;

var offsetDefault = 0;

var command_handlers = /* [] */0;

exports.module_name = module_name;
exports.src = src;
exports.pack_element = pack_element;
exports.fail = fail;
exports.lazyDefault = lazyDefault;
exports.pageSizeDefault = pageSizeDefault;
exports.offsetDefault = offsetDefault;
exports.get_lazy = get_lazy;
exports.get_element = get_element;
exports.get_pageSize = get_pageSize;
exports.get_offset = get_offset;
exports.externals_vals = externals_vals;
exports.command_handlers = command_handlers;
exports.externals = externals;
/* src Not a pure module */
