import React from "react"

export default {
    AlipayIcon: require ('./AlipayIcon').default,
    ArrowDownIcon: require ('./ArrowDownIcon').default,
    ArrowGuideDownIcon: require ('./ArrowGuideDownIcon').default,
    ArrowGuideLeftIcon: require ('./ArrowGuideLeftIcon').default,
    ArrowGuideRightIcon: require ('./ArrowGuideRightIcon').default,
    ArrowGuideUpIcon: require ('./ArrowGuideUpIcon').default,
    ArrowLeftIcon: require ('./ArrowLeftIcon').default,
    ArrowRightIcon: require ('./ArrowRightIcon').default,
    ArrowUpIcon: require ('./ArrowUpIcon').default,
    AttentionIcon: require ('./AttentionIcon').default,
    BellIcon: require ('./BellIcon').default,
    BellWireFrameIcon: require ('./BellWireFrameIcon').default,
    Broadcast1Icon: require ('./Broadcast1Icon').default,
    Broadcast2Icon: require ('./Broadcast2Icon').default,
    BrowserIcon: require ('./BrowserIcon').default,
    BuyIcon: require ('./BuyIcon').default,
    BuyWireFrameIcon: require ('./BuyWireFrameIcon').default,
    CalendarIcon: require ('./CalendarIcon').default,
    CameraIcon: require ('./CameraIcon').default,
    ChangeIcon: require ('./ChangeIcon').default,
    CheckboxCheckedIcon: require ('./CheckboxCheckedIcon').default,
    CheckboxIndetermIcon: require ('./CheckboxIndetermIcon').default,
    CheckboxNormalIcon: require ('./CheckboxNormalIcon').default,
    CloseIcon: require ('./CloseIcon').default,
    CollectionIcon: require ('./CollectionIcon').default,
    CollectionWireFrameIcon: require ('./CollectionWireFrameIcon').default,
    ColonIcon: require ('./ColonIcon').default,
    CommentIcon: require ('./CommentIcon').default,
    CommentWireFrameIcon: require ('./CommentWireFrameIcon').default,
    CompanyIcon: require ('./CompanyIcon').default,
    CopyrightIcon: require ('./CopyrightIcon').default,
    CourseIcon: require ('./CourseIcon').default,
    DecreaseIcon: require ('./DecreaseIcon').default,
    DefaultFileIcon: require ('./DefaultFileIcon').default,
    DeleteIcon: require ('./DeleteIcon').default,
    DeleteWireFrameIcon: require ('./DeleteWireFrameIcon').default,
    DropDownIcon: require ('./DropDownIcon').default,
    EditIcon: require ('./EditIcon').default,
    EditWireFrameIcon: require ('./EditWireFrameIcon').default,
    EditionIcon: require ('./EditionIcon').default,
    EmptyIcon: require ('./EmptyIcon').default,
    ErrorIcon: require ('./ErrorIcon').default,
    ErrorWireFrameIcon: require ('./ErrorWireFrameIcon').default,
    EvaluationIcon: require ('./EvaluationIcon').default,
    ExcelIcon: require ('./ExcelIcon').default,
    EyeCloseIcon: require ('./EyeCloseIcon').default,
    EyeOnIcon: require ('./EyeOnIcon').default,
    FindIcon: require ('./FindIcon').default,
    FinishIcon: require ('./FinishIcon').default,
    FinishWireFrameIcon: require ('./FinishWireFrameIcon').default,
    FoldIcon: require ('./FoldIcon').default,
    FolderIcon: require ('./FolderIcon').default,
    GripIcon: require ('./GripIcon').default,
    HeadTeacherIcon: require ('./HeadTeacherIcon').default,
    HeartIcon: require ('./HeartIcon').default,
    HeartWireFrameIcon: require ('./HeartWireFrameIcon').default,
    HelpIcon: require ('./HelpIcon').default,
    HelpWireFrameIcon: require ('./HelpWireFrameIcon').default,
    HomePageIcon: require ('./HomePageIcon').default,
    HotIcon: require ('./HotIcon').default,
    IncreaseIcon: require ('./IncreaseIcon').default,
    IndustryIcon: require ('./IndustryIcon').default,
    IntroductionIcon: require ('./IntroductionIcon').default,
    InvestmentIcon: require ('./InvestmentIcon').default,
    KuaiBaoIcon: require ('./KuaiBaoIcon').default,
    LabelIcon: require ('./LabelIcon').default,
    LabelWireFrameIcon: require ('./LabelWireFrameIcon').default,
    LeaderboardIcon: require ('./LeaderboardIcon').default,
    LikeIcon: require ('./LikeIcon').default,
    LikeWireFrameIcon: require ('./LikeWireFrameIcon').default,
    LinkIcon: require ('./LinkIcon').default,
    Loading2Icon: require ('./Loading2Icon').default,
    LoadingIcon: require ('./LoadingIcon').default,
    LocationIcon: require ('./LocationIcon').default,
    LockIcon: require ('./LockIcon').default,
    MoreIcon: require ('./MoreIcon').default,
    MyRecoIcon: require ('./MyRecoIcon').default,
    NewIcon: require ('./NewIcon').default,
    NicknameIcon: require ('./NicknameIcon').default,
    NumberIcon: require ('./NumberIcon').default,
    OffIcon: require ('./OffIcon').default,
    OnIcon: require ('./OnIcon').default,
    OrderIcon: require ('./OrderIcon').default,
    PDFIcon: require ('./PDFIcon').default,
    PayIcon: require ('./PayIcon').default,
    PaymentCodeIcon: require ('./PaymentCodeIcon').default,
    PersonalProfileIcon: require ('./PersonalProfileIcon').default,
    PhoneIcon: require ('./PhoneIcon').default,
    PhoneWireFrameIcon: require ('./PhoneWireFrameIcon').default,
    PlusIcon: require ('./PlusIcon').default,
    PositionIcon: require ('./PositionIcon').default,
    PreferenceIcon: require ('./PreferenceIcon').default,
    ProfileIcon: require ('./ProfileIcon').default,
    ProfileWireFrameIcon: require ('./ProfileWireFrameIcon').default,
    QRIcon: require ('./QRIcon').default,
    RadioCheckedIcon: require ('./RadioCheckedIcon').default,
    RadioNormalIcon: require ('./RadioNormalIcon').default,
    RecoPlanIcon: require ('./RecoPlanIcon').default,
    ReportingIcon: require ('./ReportingIcon').default,
    ReportingWireFrameIcon: require ('./ReportingWireFrameIcon').default,
    RiskAmountIcon: require ('./RiskAmountIcon').default,
    RiskAmountWireFrameIcon: require ('./RiskAmountWireFrameIcon').default,
    RiskIcon: require ('./RiskIcon').default,
    SafariIcon: require ('./SafariIcon').default,
    SalesDataIcon: require ('./SalesDataIcon').default,
    SearchIcon: require ('./SearchIcon').default,
    SettingIcon: require ('./SettingIcon').default,
    SettingWireFrameIcon: require ('./SettingWireFrameIcon').default,
    ShareIcon: require ('./ShareIcon').default,
    SignOutIcon: require ('./SignOutIcon').default,
    SouhuIcon: require ('./SouhuIcon').default,
    SuccessIcon: require ('./SuccessIcon').default,
    TargetAmountIcon: require ('./TargetAmountIcon').default,
    TelIcon: require ('./TelIcon').default,
    TimeFillingIcon: require ('./TimeFillingIcon').default,
    TimeIcon: require ('./TimeIcon').default,
    TimeWireFrameIcon: require ('./TimeWireFrameIcon').default,
    TouTiaoIcon: require ('./TouTiaoIcon').default,
    UnFoldIcon: require ('./UnFoldIcon').default,
    UnFolderIcon: require ('./UnFolderIcon').default,
    UploadIcon: require ('./UploadIcon').default,
    UserIcon: require ('./UserIcon').default,
    VIPIcon: require ('./VIPIcon').default,
    VerifiedIcon: require ('./VerifiedIcon').default,
    WechatIcon: require ('./WechatIcon').default,
    WechatPayIcon: require ('./WechatPayIcon').default,
    WeiboIcon: require ('./WeiboIcon').default,
    WordIcon: require ('./WordIcon').default,
    YiDianIcon: require ('./YiDianIcon').default,
}