// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Lang_eval$BwaxMobile = require("../../lang_eval.bs.js");
var Runtime_common$BwaxMobile = require("../runtime_common.bs.js");

function fail(name) {
  throw [
        Lang_eval$BwaxMobile.Eval_exn,
        name + ": Invalid Arguments"
      ];
}

function func(args) {
  if (args && !args[1]) {
    console.log(Lang_eval$BwaxMobile.string_of_value(args[0]));
    return /* tuple */[
            /* V_unit */0,
            /* No_term */0
          ];
  } else {
    return fail("log");
  }
}

function func$1(args) {
  if (args) {
    var match = args[1];
    if (match && !match[1]) {
      console.log(Lang_eval$BwaxMobile.string_of_value(args[0]), Lang_eval$BwaxMobile.string_of_value(match[0]));
      return /* tuple */[
              /* V_unit */0,
              /* No_term */0
            ];
    } else {
      return fail("log");
    }
  } else {
    return fail("log");
  }
}

function func$2(args) {
  if (args) {
    var match = args[1];
    if (match) {
      var match$1 = match[1];
      if (match$1 && !match$1[1]) {
        console.log(Lang_eval$BwaxMobile.string_of_value(args[0]), Lang_eval$BwaxMobile.string_of_value(match[0]), Lang_eval$BwaxMobile.string_of_value(match$1[0]));
        return /* tuple */[
                /* V_unit */0,
                /* No_term */0
              ];
      } else {
        return fail("log");
      }
    } else {
      return fail("log");
    }
  } else {
    return fail("log");
  }
}

function func$3(args) {
  if (args) {
    var match = args[1];
    if (match) {
      var match$1 = match[1];
      if (match$1) {
        var match$2 = match$1[1];
        if (match$2 && !match$2[1]) {
          console.log(Lang_eval$BwaxMobile.string_of_value(args[0]), Lang_eval$BwaxMobile.string_of_value(match[0]), Lang_eval$BwaxMobile.string_of_value(match$1[0]), Lang_eval$BwaxMobile.string_of_value(match$2[0]));
          return /* tuple */[
                  /* V_unit */0,
                  /* No_term */0
                ];
        } else {
          return fail("log");
        }
      } else {
        return fail("log");
      }
    } else {
      return fail("log");
    }
  } else {
    return fail("log");
  }
}

var externals_vals_000 = /* tuple */[
  "log",
  Runtime_common$BwaxMobile.pack_func(1, func)
];

var externals_vals_001 = /* :: */[
  /* tuple */[
    "log2",
    Runtime_common$BwaxMobile.pack_func(2, func$1)
  ],
  /* :: */[
    /* tuple */[
      "log3",
      Runtime_common$BwaxMobile.pack_func(3, func$2)
    ],
    /* :: */[
      /* tuple */[
        "log4",
        Runtime_common$BwaxMobile.pack_func(4, func$3)
      ],
      /* [] */0
    ]
  ]
];

var externals_vals = /* :: */[
  externals_vals_000,
  externals_vals_001
];

var externals = /* tuple */[
  undefined,
  externals_vals
];

var src = "\n\n-- \n\ntype Dict k v = opaque;\n\ntype Set t = opaque;\n\ntype Array t = opaque;\n\ntype Maybe a = Just a | Nothing;\n\ntype Date = opaque;\n\ntype JSON = opaque;\n\ntype RichText = opaque;\n\ntype File = opaque;\n\ntype Image = opaque;\n\n\ntype Result error value = Ok value | Err error;\n\n\ntype Color = opaque;\n\n--\ntype Order = LT | EQ | GT;\n\ncompare x y: comparable -> comparable -> Order =\n  if x < y then LT\n  else if x == y then EQ\n  else GT;\n\n-- for debug\nlog:  a -> () = external;\nlog2: a -> b -> () = external;\nlog3: a -> b -> c -> () = external;\nlog4: a -> b -> c  -> d -> () = external;\n\n-- Simply return the given argument\nidentity x = x;\n\n-- Create a function that always return the given argument.\nalways x _ = x;\n\nalwaysTrue _ = True;\n\nalwaysFalse _ = False;\n\nneverTrue _ = False;\n\n-- runtime\ntype Cmd a = Cmd_none;\nnone = Cmd_none;\n\ntype TimerID = opaque;\n\n-- Command for continuation\ntype CmdM x y = opaque;\n\n";

exports.src = src;
exports.fail = fail;
exports.externals_vals = externals_vals;
exports.externals = externals;
/* externals_vals Not a pure module */
