
import React from 'react';

import {
    MdArrowForwardIos,
    MdHelpOutline,
    MdBolt,
    MdStar,
    MdOutlineCircle,
    MdOutlineFileUpload,
    MdLibraryBooks,
    MdLock,
    MdEmojiEvents,
    MdOfflineBolt,
    MdOutlineRemove,
    MdOutlineAdd,
    MdCheck,
    MdCheckCircle,
    MdClose,
    MdBusiness,
    MdLocationOn,
    MdInfoOutline,
    MdCheckCircleOutline,
    MdKeyboardArrowRight,
    MdArrowBack,
    MdMenu,
    MdIosShare,
    MdFavorite,
    MdFavoriteBorder,
    MdThumbUp,
    MdThumbDown,
    MdThumbUpOffAlt,
    MdThumbDownOffAlt,
    MdOutlineAssignment,
    MdDelete,
    MdContentCopy,
} from "react-icons/md";


const MdIcons = {
    MdArrowForwardIos,
    MdHelpOutline,
    MdBolt,
    MdStar,
    MdOutlineCircle,
    MdOutlineFileUpload,
    MdLibraryBooks,
    MdLock,
    MdEmojiEvents,
    MdOfflineBolt,
    MdOutlineRemove,
    MdOutlineAdd,
    MdCheck,
    MdCheckCircle,
    MdClose,
    MdBusiness,
    MdLocationOn,
    MdInfoOutline,
    MdCheckCircleOutline,
    MdKeyboardArrowRight,
    MdArrowBack,
    MdMenu,
    MdIosShare,
    MdFavorite,
    MdFavoriteBorder,
    MdThumbUp,
    MdThumbDown,
    MdThumbUpOffAlt,
    MdThumbDownOffAlt,
    MdOutlineAssignment,
    MdDelete,
    MdContentCopy,
};

export default function MDIconBridge({ name }) {

    const Icon = MdIcons["Md" + name];

    if(Icon) {
        return <Icon />
    } else {
        return null
    }
}

export function create(name) {
    return <MDIconBridge {...{ name }} />
}