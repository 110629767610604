import React from 'react'

const convertToHTML = {
    entityToHTML: (entity, originalText) => {
        if (entity.type === 'HYPERLINK') {
            return <a href={entity.data.url}>{originalText}</a>;
        }
    }
}


const convertToReact = {
    entityToReact: (entity, originalText) => {
        //暂时不提供 originalText 来覆盖样式
        if (entity.type === 'HYPERLINK') {
            return <a href={entity.data.url}></a>;
        }
        return null
    }
}

export { convertToHTML, convertToReact }
