
import React, { useEffect, useState } from 'react';

import './ClampedText.less';

import Tooltip from 'bwax-ui/components/Tooltip';

import useResizeObserver from '@react-hook/resize-observer'

export default function ClampedText({ 
    className,  text: textInProp, style, lines = 1, onClick,
    tipEnabled = true, // boolean / 
    tipComponent: TipComp,
    tipClassName,
    tipTrigger,
    children,
}) {

    const text = children || textInProp || null;

    const ref = React.useRef(null);

    const [tipShown, setTipShown] = useState(false);

    const handleResize = el => {
        if (el) {
            if (el.scrollHeight > el.offsetHeight) {
                setTipShown(true)
            } else {
                // 这个没用，因为现在 ref 已经是 null 了： 要修改 Tooltip 的逻辑
                // setTipShown(false)
            }
        }
    }


    useResizeObserver(ref, entry => {
        if(ref.current) {
            handleResize(ref.current);
        }
    });

    // 在 Table 被 virtualized 之后，el 会变成 null，不知道为啥

    if(!text) {
        return null
    }

    const inner = (
        <div style={{
            ...style,
            WebkitLineClamp: lines,
        }} className={"lc-clamped-text" + (className ? " " + className : "")} ref={ref} onClick={onClick}>
            {text}
        </div>
    )

    
    return (
        tipShown && tipEnabled ? (
            TipComp ? 
                (
                    <TipComp text={ text } trigger={tipTrigger} className={tipClassName}>
                        { inner }
                    </TipComp>
                ) :
                (
                    <Tooltip text={text} className={tipClassName}> 
                        {inner}
                    </Tooltip>
                )
        ) : inner
    )
}
