
import React from 'react'

import element_helper from 'bwax-ui/ml/widget/element_helper.bs';

import Typewriter from 'typewriter-effect';

// type TypeWriterEffect msg = 
//     | TypeString (List (Rule msg)) String  -- only suport text style related rules.
//     | PasteString (List (Rule msg)) String
//     | PauseFor Int            -- milliseconds
//     | DeleteAll Int           -- speed, which means delay between deleting each character, the lower the qfaster
//     | DeleteChars Int         -- Number of characters to delete
//     | ChangeDelay Int         -- change typing speed
//     | ChangeDeleteSpeed Int

export default function TypeWriterEffect(props) {

    const { effects, config } = props;

    function enhanceString (rules, s) {
        const className = element_helper.rules_to_classname(element_helper.get_text_style_rules(rules));
        if(className) {
            return `<span class="${className}">${s}</span>`
        } else {
            return s
        }
    }

    const effectHanders = {
        "TypeString": (w, [rules, s]) => {
            return w.typeString(enhanceString(rules, s))
        },
        "PasteString": (w, [rules, s]) => {
            return w.pasteString(enhanceString(rules, s))
        },
        "PauseFor": (w, t) => w.pauseFor(t),
        "DeleteAll": (w, t) => w.deleteAll(t),
        "DeleteChars": (w, t) => w.deleteChars(t),
        "ChangeDelay": (w, t) => w.changeDelay(t),
        "ChangeDeleteSpeed": (w, t) => w.changeDeleteSpeed(t),
    };

    const cursorClassNameForEffect = {
        "TypeString": ([rules, s]) => {
            return element_helper.rules_to_classname(element_helper.get_text_size_rules(rules));
        },
        "PasteString": ([rules, s]) => {
            return element_helper.rules_to_classname(element_helper.get_text_size_rules(rules));
        },
    };

    function getCursorClassNameForEffect(effect) {
        const [name, opts] = effect;
        const f = cursorClassNameForEffect[name] || (_ => undefined);
        return f(opts)
    }

    function applyCursorClassName(effect, cursorEl, originalCursorClass) {
        const cursorClassName = getCursorClassNameForEffect(effect);
        if(cursorClassName !== undefined) {
            cursorEl.className = originalCursorClass + (cursorClassName ? " " + cursorClassName : "")
        }
    }

    function chainActions(typewriter, originalCursorClass) {
        let w = typewriter;
        if(effects.length == 0) {
            return w;
        }

        applyCursorClassName(effects[0], w.state.elements.cursor, originalCursorClass);
        
        for (let i = 0; i < effects.length; i++) {

            const [name, opts] = effects[i];

            const action = effectHanders[name] || (_ => w)

            w = action(w, opts).callFunction(state => {
                // handle cursor class Name if necessary
                if(i < effects.length - 1) {
                    applyCursorClassName(effects[i + 1], state.elements.cursor, originalCursorClass)
                }
            });
        }

        w.callFunction(state => {
            state.elements.cursor.style.display = "none";
        }).start();
    }

    return (
        <Typewriter options={config}
            onInit={(typewriter) => {
                const originalCursorClass = typewriter.state.elements.cursor.className;
                chainActions(typewriter, originalCursorClass);
            }}
        />
    )
}


export function create(props) {
    return (
        <TypeWriterEffect {...props} />
    )
}