import React from 'react'

class CourseIcon extends React.Component {

    render() {
        return (
            <svg width="1em" height="1em" viewBox="0 0 12 12">
                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Icon/VIP-services/课程" transform="translate(-2.000000, -2.000000)" fill="#FFFFFF" fillRule="nonzero">
                        <g id="Combined-Shape">
                            <path d="M12.5,10.7 L13.7,14 L2.3,14 L3.5,10.7 L3.5,5.3 L9.44272556,5.3 L8.71399713,5.80951539 L8.71399713,8.49502969 L11.3161069,10.0634867 L12.5,9.36508587 L12.5,10.7 Z M3.82720429,10.4918454 L3.82720429,10.7768454 L12.1307177,10.7768454 L12.1307177,10.4918454 L3.82720429,10.4918454 Z M8,6.8 L5.9,6.8 C5.73431458,6.8 5.6,6.93431458 5.6,7.1 L5.6,8.3 C5.6,8.46568542 5.73431458,8.6 5.9,8.6 L8,8.6 L8,6.8 Z M6.2,7.4 L8,7.4 L8,8 L6.2,8 L6.2,7.4 Z M7.7,12.2 C7.53431458,12.2 7.4,12.3343146 7.4,12.5 C7.4,12.6656854 7.53431458,12.8 7.7,12.8 L8.3,12.8 C8.46568542,12.8 8.6,12.6656854 8.6,12.5 C8.6,12.3343146 8.46568542,12.2 8.3,12.2 L7.7,12.2 Z M11.6,2.3 L11.6,3.2 C11.6,3.36568542 11.4656854,3.5 11.3,3.5 C11.1343146,3.5 11,3.36568542 11,3.2 L11,2.3 C11,2.13431458 11.1343146,2 11.3,2 C11.4656854,2 11.6,2.13431458 11.6,2.3 Z M13.4727922,3.2363961 L12.8363961,3.87279221 C12.7192388,3.98994949 12.5292893,3.98994949 12.412132,3.87279221 C12.2949747,3.75563492 12.2949747,3.56568542 12.412132,3.44852814 L13.0485281,2.81213203 C13.1656854,2.69497475 13.3556349,2.69497475 13.4727922,2.81213203 C13.5899495,2.92928932 13.5899495,3.11923882 13.4727922,3.2363961 Z M9.12720779,3.2363961 C9.01005051,3.11923882 9.01005051,2.92928932 9.12720779,2.81213203 C9.24436508,2.69497475 9.43431458,2.69497475 9.55147186,2.81213203 L10.187868,3.44852814 C10.3050253,3.56568542 10.3050253,3.75563492 10.187868,3.87279221 C10.0707107,3.98994949 9.88076118,3.98994949 9.7636039,3.87279221 L9.12720779,3.2363961 Z M13.528461,5.81339746 L13.528461,8.38660254 L11.3,9.67320508 L9.07153903,8.38660254 L9.07153903,5.81339746 L11.3,4.52679492 L13.528461,5.81339746 Z"></path>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export default CourseIcon
