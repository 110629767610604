
import React from 'react'

const BalanceContext = React.createContext();

export default BalanceContext

export const BalanceContextProvider = props => {

    const { 
        balance, reloadBalance,
        children 
    } = props

    return (
        <BalanceContext.Provider value={{ balance, reloadBalance }}>
            { children }
        </BalanceContext.Provider>
    );
}
