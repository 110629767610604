
// This file is generated. Don't modify it.
import React from 'react'
export default function RiskAmountWireFrameIcon (props) {

    const { color } = props
    
    return (
                <svg width="1em" height="1em" viewBox="0 0 16 24">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(-39.000000, -306.000000)" fill="#FFFFFF" fillRule="nonzero" stroke={color}>
                        <g transform="translate(21.000000, 224.000000)">
                            <g transform="translate(18.000000, 33.000000)">
                                <path d="M10.008889,59.1526498 L11.1266755,50.4085254 C11.1404736,50.2455663 11.0460615,50.0924147 10.89288,50.0292716 C10.7396984,49.9661286 10.5626462,50.0073796 10.4544403,50.1314228 L1.0744138,61.9852535 C0.990110654,62.098183 0.976353033,62.247833 1.03869073,62.3738349 C1.10102844,62.4998367 1.22917949,62.5814077 1.37144797,62.5856423 L5.7800604,62.5856423 C5.84601195,62.5850211 5.908968,62.6127158 5.95250557,62.6615018 C5.99604315,62.7102878 6.01584458,62.775327 6.00674438,62.8396529 L4.87332451,71.5914746 C4.85952638,71.7544337 4.95393852,71.9075853 5.10712004,71.9707284 C5.26030155,72.0338714 5.43735384,71.9926204 5.54555974,71.8685772 L14.9255862,60.0147465 C15.0098893,59.901817 15.023647,59.752167 14.9613093,59.6261651 C14.8989716,59.5001633 14.7708205,59.4185923 14.628552,59.4143577 L10.235573,59.4143577 C10.168025,59.4153553 10.103545,59.3866337 10.059705,59.3360202 C10.015865,59.2854066 9.99723388,59.218176 10.008889,59.1526498 Z" id="Shape-Copy-2"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        
    )
}
