
import React, { useState, useEffect } from 'react'

import './WxWorkMessage.less'
import Icon from 'bwax-ui/basic/Icon'
import Video from "bwax-ui/auxiliary/video/Video"
import Audio from './components/Audio'
import Attachment from './components/Attachment'
import Thumbnail from './components/Thumbnail'
import PhotoSwipe from './components/PhotoSwipe'

import Link from 'bwax-ui/page/Link'

export default function WxWorkMessage(props) {

    // console.log("WX WORK MESSAGE", props);
    const { data } = props
    const { messageType, files, info, text } = data
    const [ previewVisible, setPreviewVisible] = useState(false)
    const [ imageUrl, setImageUrl ] = useState(null)

    function getFileUrl () {
        const actualFile = files[0] ? files[0]['文件'] : {}
        const { url } = actualFile
        return url
    }

    function renderMessageContent() {

        if (messageType === 'text') {
            return text ? (
                <p className="content-text">
                    {text}
                </p>
            ) : null
        }

        if (messageType === 'image') {
            const url = getFileUrl()
            return (
                <Thumbnail
                    src={url}
                    onClick={() => {
                        setImageUrl(url)
                        setPreviewVisible(true)
                    }}
                />
            )
        }

        if (messageType === 'video') {
            const url = getFileUrl()
            return url ? (
                <Video
                    options={{
                        sources: [{
                            src: url,
                            type: 'video/mp4'
                        }]
                    }} 
                />
            ) : null
        }

        if (messageType === 'voice') {
            const url = getFileUrl()
            const { play_length } = info
            return url ? (
                <Audio
                    hiddenControl={true}
                    src={url}
                    duration={play_length} 
                />
            ) : null
        }

        if (messageType === 'file') {
            const actualFile = files[0] ? files[0]['文件'] : {}
            const { url } = actualFile
            const { filename } = info

            if (url) {
                const match = filename.match(/.(doc|docx|pdf|xls|xlsx|ppt|pptx)/)
                if (match) {
                    const type = match[1]
                    return (
                        <Attachment
                            doc={{ docUrl: url, type, title: filename }} />
                    )
                } else {
                    return <p>暂不支持该文档类型</p>
                }
            }
        }

        if (messageType === 'link') {
            const { description, image_url, link_url, title } = info
            return (
                <Link to={link_url} >
                    <div className="message-link">
                        <p className="link-title">{title}</p>
                        <div className="link-info">
                            <p className="link-desc">{description}</p>
                            {
                                image_url ?
                                    <img className="link-pic" src={image_url} /> :
                                    <div className="link-cot">
                                        <Icon params={{ size: 'large', type: 'Link', color: '#666' }}/>
                                    </div>
                            }
                        </div>
                    </div>
                </Link>
            )
        }

        return (
            <p>未知消息类型</p>
        )
    }

    return (
        <div className="wx-work-message" style={ messageType === 'video' ? { maxWidth: 400 } : {}}>
            {renderMessageContent()}
            {
                messageType === 'image' ?
                <PhotoSwipe
                    isOpen={previewVisible}
                    items={[{src: imageUrl, w: 0, h: 0}]}
                    options={{index: 0}}
                    onClose={() => setPreviewVisible(false)} /> : null
            }
        </div>
    )

}