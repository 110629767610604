
// This file is generated. Don't modify it.
import React from 'react'
export default function NicknameIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M14,8.05266439 L14,13 C14,13.5522847 13.5522847,14 13,14 L3,14 C2.44771525,14 2,13.5522847 2,13 L2,3 C2,2.44771525 2.44771525,2 3,2 L8,2 C8.27614237,2 8.5,2.22385763 8.5,2.5 C8.5,2.77614237 8.27614237,3 8,3 L3,3 L3,13 L13,13 L13,8 L13.0027405,8 C13.0027405,7.72385763 13.2265981,7.5 13.5027405,7.5 C13.7788829,7.5 14.0027405,7.72385763 14.0027405,8 C14.0027405,8.01778701 14.0018117,8.03535709 14,8.05266439 Z M14.0104076,2.35355339 C14.2056698,2.54881554 14.2056698,2.86539803 14.0104076,3.06066017 L7.64644661,9.4246212 C7.45118446,9.61988335 7.13460197,9.61988335 6.93933983,9.4246212 C6.74407768,9.22935906 6.74407768,8.91277657 6.93933983,8.71751442 L13.3033009,2.35355339 C13.498563,2.15829124 13.8151455,2.15829124 14.0104076,2.35355339 Z" id="NicknameIcon-path-1"></path>
            </defs>
            <g id="Style" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Iconology" transform="translate(-432.000000, -284.000000)">
                    <g id="Icon/Personal" transform="translate(48.000000, 284.000000)">
                        <g id="Icon/Personal/Nickname" transform="translate(384.000000, 0.000000)">
                            <g id="Group-3">
                                <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                                <mask id="NicknameIcon-mask-2" fill="white">
                                    <use href="#NicknameIcon-path-1"></use>
                                </mask>
                                <use id="Mask" fill={color} fillRule="nonzero" href="#NicknameIcon-path-1"></use>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
