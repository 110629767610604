import React from 'react'

class VoteIcon extends React.Component {

    render() {
        return (
            <svg width="1em" height="1em" viewBox="0 0 14 14">
                <defs>
                    <path d="M5.97333333,0.453333333 L5.97333333,6.42666667 L11.9466667,6.42666667 C11.9466667,9.72573867 9.27240533,12.4 5.97333333,12.4 C2.67426133,12.4 5.86197757e-14,9.72573867 5.86197757e-14,6.42666667 C5.86197757e-14,3.12759467 2.67426133,0.453333333 5.97333333,0.453333333 Z M9.89868008,0.112 C11.2182438,0.112 12.288,1.18175622 12.288,2.50131992 C12.288,3.82091045 11.2182438,4.89066667 9.89868008,4.89066667 C8.57908955,4.89066667 7.50933333,3.82089703 7.50933333,2.50131992 C7.50933333,1.18175622 8.57910297,0.112 9.89868008,0.112 Z" id="VoteIcon-path-1"></path>
                    <path d="M2.112,7.91466667 C2.112,11.2137439 4.78625613,13.888 8.08533333,13.888 C11.2698507,13.888 13.8721872,11.3962452 14.0490785,8.256 L7.744,8.256 L7.744,1.95092147 C4.60375475,2.12781277 2.112,4.73014926 2.112,7.91466667 Z M8.08533333,1.6 L8.08533333,7.91466667 L14.4,7.91466667 C14.4,11.4022571 11.5729237,14.2293333 8.08533333,14.2293333 C4.59774293,14.2293333 1.77066667,11.4022571 1.77066667,7.91466667 C1.77066667,4.42707627 4.59774293,1.6 8.08533333,1.6 Z M11.8400144,1.94133333 C10.6146935,1.94133333 9.62133333,2.93468198 9.62133333,4.15998562 C9.62133333,5.38531031 10.6146858,6.37866667 11.8400144,6.37866667 C13.0653233,6.37866667 14.0586667,5.38531558 14.0586667,4.15998562 C14.0586667,2.93468057 13.0653194,1.94133333 11.8400144,1.94133333 Z M11.8400144,1.6 C13.2538326,1.6 14.4,2.74616738 14.4,4.15998562 C14.4,5.57383262 13.2538326,6.72 11.8400144,6.72 C10.4261674,6.72 9.28,5.57381825 9.28,4.15998562 C9.28,2.74616738 10.4261818,1.6 11.8400144,1.6 Z M12.6707384,3.99486439 L12.6707384,4.28083178 L12.0871877,4.28083178 L12.0871877,4.53858316 L12.6707384,4.53858316 L12.6707384,4.82450902 L12.0871877,4.82450902 L12.0871877,5.46894979 L11.5556663,5.46894979 L11.5556663,4.82450902 L10.9758122,4.82450902 L10.9758122,4.53858316 L11.5556663,4.53858316 L11.5556663,4.28083178 L10.9758122,4.28083178 L10.9758122,3.99486439 L11.4218549,3.99486439 L10.816,2.85105021 L11.4218549,2.85106406 L11.6820153,3.49549098 C11.7489002,3.65660463 11.7898121,3.79347578 11.8381309,3.93447273 L11.8455795,3.93447273 C11.8938984,3.80158892 11.9422172,3.64850534 12.0053917,3.49143441 L12.2692903,2.85105021 L12.864,2.85105021 L12.22842,3.99489208 L12.6707522,3.99489208 L12.6707384,3.99486439 Z" id="VoteIcon-path-3"></path>
                </defs>
                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Icon/How-it-works/人民币投票" transform="translate(-1.000000, -1.000000)">
                        <g>
                            <rect id="bg" fillRule="nonzero" x="0" y="0" width="16" height="16"></rect>
                            <g id="Rectangle" transform="translate(1.600000, 2.000000)">
                                <mask id="VoteIcon-mask-2" fill="white">
                                    <use href="#VoteIcon-path-1"></use>
                                </mask>
                                <use id="Mask" fill="#F0F3F5" fillRule="nonzero" href="#VoteIcon-path-1"></use>
                                <rect fill="#F0F3F5" fillRule="nonzero" mask="url(#VoteIcon-mask-2)" x="-1.6" y="-2" width="16" height="16"></rect>
                            </g>
                            <mask id="VoteIcon-mask-4" fill="white">
                                <use href="#VoteIcon-path-3"></use>
                            </mask>
                            <use id="Mask" fill="#173652" fillRule="nonzero" href="#VoteIcon-path-3"></use>
                            <rect id="Rectangle" fill="#173652" fillRule="nonzero" mask="url(#VoteIcon-mask-4)" x="0" y="0" width="16" height="16"></rect>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export default VoteIcon
