import React from 'react'

class DiscountsIcon extends React.Component {

    render() {
        return (

            <svg width="1em" height="1em" viewBox="0 0 16 16">
                <defs>
                    <linearGradient x1="100%" y1="100%" x2="0%" y2="0%" id="linearGradient-1">
                        <stop stopColor="#3C3D3D" offset="0%"></stop>
                        <stop stopColor="#141414" offset="100%"></stop>
                    </linearGradient>
                    <linearGradient x1="100%" y1="100%" x2="0%" y2="0%" id="linearGradient-2">
                        <stop stopColor="#FFCF99" offset="0%"></stop>
                        <stop stopColor="#FFE2C2" offset="100%"></stop>
                    </linearGradient>
                </defs>
                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Icon/VIP-rights/产品" fillRule="nonzero">
                        <g>
                            <circle id="Oval" fill="url(#linearGradient-1)" cx="8" cy="8" r="8"></circle>
                            <path d="M4.79898409,5.14179408 L4.79898409,5.42144523 L5.7998903,6.13987064 L5.7998903,10.7056304 L4.72977485,11.4785341 L4.9725352,11.5460683 L5.94909268,10.8695278 L6.81884042,11.9889735 L7.1870727,12.0854877 L6.13083447,10.7409633 L6.13322363,10.7409633 L6.13322363,6.21214304 L7.23726428,5.88731578 L7.23726428,12.292993 L4.57732226,11.6280075 L4.57732226,4.97815243 L4.79898409,5.14179408 Z M9.373406,5.06509133 L9.373406,3.76217806 L9.04007267,3.76217806 L9.04007267,5.1593528 L6.0012048,6.01869722 L4.57732226,4.97815243 L9.30285695,3.62632632 L10.9168507,4.60058955 L10.6447857,4.70556499 L9.373406,5.06509133 Z M7.20539686,5.65080923 L11.5372328,4.35035126 L11.5372328,10.9378782 L7.23726428,12.292993 L7.1870727,5.65564014 L7.20539686,5.65080923 Z M10.1533847,5.86479977 L10.1533847,8.08183876 L9.52323431,8.79814123 L8.87653007,8.08068892 L8.87653007,6.25998079 L8.71696533,6.25998079 L8.54403732,6.25998079 L8.54403732,8.20842474 L9.52625448,9.29809437 L10.4858775,8.20727491 L10.4858775,5.86479977 L10.1533847,5.86479977 Z" id="Combined-Shape" fill="url(#linearGradient-2)"></path>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export default DiscountsIcon
