

import React from 'react'

import BackgroundVideo from "bwax-ui/ml/widget/ports/BackgroundVideo";

import { Helmet } from 'react-helmet-async';

export default function TestVideoBackground() {
    return (
        <>
        <Helmet>
            <title>测试视频背景</title>
        </Helmet>
        <BackgroundVideo data={{
            backgroundVideoSrc: "https://bw-dev.static.qunfengshe.com/public/cb/entitygeshou-fieldrongyu/72c8839e-7461-4c06-9dca-b046a5274335.mp4",
            posterUrl: "https://bw-dev.static.qunfengshe.com/public/cb-bwax/design-resources/5052f5a3-8cd9-4602-b94d-12a7837a6a13.jpg"
        }}></BackgroundVideo>
        <div style={{ height: "2rem", zIndex: 100, width: "10rem", backgroundColor: "white", position: "fixed"}}>

        </div>
        </>
    )
}
