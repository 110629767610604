
// This file is generated. Don't modify it.
import React from 'react'
export default function VIPIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M11.2,3.2 L4.8,3.2 C2.16,3.2 0,5.36 0,8 C0,10.64 2.16,12.8 4.8,12.8 L11.2,12.8 C13.84,12.8 16,10.64 16,8 C16,5.36 13.84,3.2 11.2,3.2 Z" id="VIPIcon-path-1"></path>
                <linearGradient x1="100%" y1="100%" x2="0%" y2="0%" id="VIPIcon-linearGradient-3">
                    <stop stopColor="#FFCF99" offset="0%"></stop>
                    <stop stopColor="#FFE2C2" offset="100%"></stop>
                </linearGradient>
            </defs>
            <g id="Style" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Iconology" transform="translate(-456.000000, -284.000000)">
                    <g id="Icon/Personal" transform="translate(48.000000, 284.000000)">
                        <g id="Icon/VIP" transform="translate(408.000000, 0.000000)">
                            <g id="Group-3">
                                <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                                <mask id="VIPIcon-mask-2" fill="white">
                                    <use href="#VIPIcon-path-1"></use>
                                </mask>
                                <use id="Mask" fill={color} href="#VIPIcon-path-1"></use>
                                <rect id="light-gray" fill="url(#VIPIcon-linearGradient-3)" fillRule="nonzero" mask="url(#VIPIcon-mask-2)" x="0" y="0" width="16" height="16"></rect>
                                <path d="M6.37624164,5.85839844 L7.29323383,5.85839844 L5.83717914,10.1416016 L4.84694476,10.1416016 L3.39381976,5.85839844 L4.31081195,5.85839844 L5.11647601,8.40722656 C5.16139811,8.55761794 5.20778437,8.73290916 5.25563617,8.93310547 C5.30348797,9.13330178 5.33327283,9.27246055 5.34499164,9.35058594 C5.36647612,9.17089754 5.43971758,8.85644756 5.5647182,8.40722656 L6.37624164,5.85839844 Z M7.74658203,10.1416016 L7.74658203,5.85839844 L8.65478516,5.85839844 L8.65478516,10.1416016 L7.74658203,10.1416016 Z M10.555399,7.87402344 L10.8542271,7.87402344 C11.1335254,7.87402344 11.3425077,7.81884821 11.4811802,7.70849609 C11.6198528,7.59814398 11.689188,7.43750105 11.689188,7.2265625 C11.689188,7.01367081 11.6310832,6.85644582 11.5148716,6.75488281 C11.3986601,6.6533198 11.216533,6.60253906 10.9684849,6.60253906 L10.555399,6.60253906 L10.555399,7.87402344 Z M12.6061802,7.19433594 C12.6061802,7.65527574 12.4621387,8.00781128 12.1740513,8.25195312 C11.885964,8.49609497 11.4763001,8.61816406 10.9450474,8.61816406 L10.555399,8.61816406 L10.555399,10.1416016 L9.64719586,10.1416016 L9.64719586,5.85839844 L11.0153599,5.85839844 C11.5348938,5.85839844 11.9299094,5.97021373 12.2004185,6.19384766 C12.4709277,6.41748159 12.6061802,6.75097435 12.6061802,7.19433594 Z" id="VIP" fill={"#3C3D3D"} mask="url(#VIPIcon-mask-2)"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
