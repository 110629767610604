

import React, { useEffect, useRef, useState } from 'react'

import getImageURL from 'bwax-ui/getImageURL'

import { renderVideo, renderAudio as renderAudioComp } from './editor/utils/MediaHelper'
import { isQQPlayURL } from 'bwax-ui/auxiliary/richtext/plugins/media/mediaTypes'
import { ratioOuterStyle, ratioLOCALOuterStyle } from './editor/components/styles'
import { isWeChat } from 'bwax/clientEnv'

// import Image from './Image'

import "../../basic/RichText.less"

import classNames from 'classnames'

function isList (nodeType) {
    const listTypes = ["numbered-list", "bulleted-list"]

    return listTypes.includes(nodeType)
}

export default function RichTextRenderer({ value, version, className }) {

    if (!value) {
        return (
            <div>no slate content</div>
        )
    }

    const rendererRef = useRef(null)

    function render() {
        return value.map((node, index) => {
            const listInfo = isList(node.type) ? {
                type: node.type,
                level: 0
            } : undefined
            return renderRichTextElement(node, index, { components, containerRef: rendererRef }, listInfo);
        })
    }

    useEffect(() => {
        if (isWeChat()) {
            let imgsArr = []
            const imgNodesArr = document.querySelectorAll('.rich-text img')
            imgNodesArr.forEach(img => {
                imgsArr.push(img.getAttribute('src'))
            })

            for (let i = 0; i < imgNodesArr.length; i++) {
                imgNodesArr[i].onclick = function () {
                    const currentImgUrl = imgNodesArr[i].getAttribute('src')
                    wx.previewImage({
                        current: currentImgUrl,
                        urls: imgsArr
                    })
                }
            }
        }
    }, [])

    return (
        <div className={classNames('rich-text', className, { "rich-text-v2": version == 2 })} ref={rendererRef}>
            {render()}
        </div>
    )
}


function renderRichTextElement(node, key, { components, containerRef }, listInfo) {

    // closure
    const renderElement = (node, key) => {
        const newListInfo = listInfo ? {
            ...listInfo,
            level: node.type === listInfo.type ? listInfo.level + 1 : listInfo.level
        } : undefined
        return renderRichTextElement(node, key, { components, containerRef }, newListInfo)
    }

    if (node.type == "link" || node.type == "break" || (node.text !== undefined)) {
        return <StyledText node={node} key={key} />
    }

    const Comp = components[node.type];


    if (Comp) {
        return <Comp node={node} key={key} containerRef={containerRef} renderElement={renderElement} listInfo={listInfo} />
    } else {
        return (
            <div key={key} style={{ padding: "0.5rem 0.5rem", backgroundColor: "var(--yellow4)" }}>{`<不支持的富文本内容 ${node.type}>`}</div>
        )
    }
}


const Paragraph = ({ node, renderElement }) => {
    const { children, align } = node


    return (
        <p style={align ? { textAlign: align } : {}} >
            {children.map((c, idx) => renderElement(c, idx))}
        </p>
    )
}

const ListItem = ({ node, renderElement }) => {
    const { children } = node

    return (
        <li>
            {
                children.map((child, index) => {
                    return renderElement(child, index)
                })
            }
        </li>
    )
}

const NestWrapper = ({ node, renderElement }) => {
    const { children } = node
    
    return (
        <div className='nest-wrapper'>
            { children.map((child, index) => {
                return renderElement(child, index)
            })}
        </div>
    )
}

const Image = ({ node, containerRef }) => {
    const { url, width, float, caption } = node

    const [actualWidth, setActualWidth] = useState(width)

    useEffect(() => {
        /**
         * https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1147
         * 如果 width 是 px，且比 renderer 宽度大，则设置为 100% (by 威豪 2022-12-16)
         */
        if (containerRef && containerRef.current && typeof (width) === "number" && width > containerRef.current.clientWidth) {
            setActualWidth('100%')
        } else {
            setActualWidth(width)
        }
    }, [containerRef, width])

    const alignStyle = float ? imageAlignStyle[float] : imageAlignStyle["center"]


    const imageWidth = actualWidth ? {
        width: actualWidth, maxWidth: "100%"
    } : {
        width: "100%", maxWidth: "32rem"  // 为了在 PC 端默认图片不要太大
    }

    const altProp = caption ? { alt: caption } : {}

    return (
        <figure style={{ ...alignStyle, ...imageWidth }}>
            <img src={processImageURL(url)} style={{ width: "100%", display: "block" }} {...altProp} />
            { 
                caption ? (
                    <div className="text-[var(--gray10)] text-center text-sm mt-1.5">
                        {caption}
                    </div>
                ) : null 
            }
        </figure>

    )
}

const Media = ({ node }) => {
    const { type, url, align } = node

    function renderAudio() {
        return (
            <figure className='editor-audio-block' style={{ textAlign: align || 'left', padding: "0.75rem" }}>
                {renderAudioComp(url)}
            </figure>
        )
    }

    const videoContainerStyle = isQQPlayURL(url) ? ratioOuterStyle : ratioLOCALOuterStyle

    return type === "video" ? (
        <figure style={videoContainerStyle}>
            {renderVideo(url)}
        </figure>
    ) : renderAudio()
}

const Blockquote = ({ node, renderElement }) => {
    const { children, align } = node

    const blockquoteRef = useRef(null)

    const [padding, setPadding] = useState('16px')
    const [quoteClassName, setQuoteClassName] = useState('')

    useEffect(() => {

        const { current } = blockquoteRef
        const { nextSibling, previousSibling } = current

        if (nextSibling && nextSibling.nodeName === "BLOCKQUOTE" && previousSibling && previousSibling.nodeName === "BLOCKQUOTE") {
            setPadding('0 16px')
            setQuoteClassName('middle-blockquote')
        } else if (nextSibling && nextSibling.nodeName === "BLOCKQUOTE") {
            setPadding('16px 16px 0')
            setQuoteClassName('first-blockquote')
        } else if (previousSibling && previousSibling.nodeName === "BLOCKQUOTE") {
            setPadding('0 16px 16px')
        } else {
            setPadding('16px')
            setQuoteClassName('single-blockquote')
        }
    })

    return (
        <blockquote ref={blockquoteRef} style={{ padding, textAlign: align }}
            className={quoteClassName}
        >
            {children.map((node, idx) => {
                return renderElement(node, idx)
            })}
        </blockquote>
    )
}

const heading = depth => ({ node, renderElement }) => {
    const { children, align } = node
    return React.createElement(
        "h" + depth,
        align ? { textAlign: align } : {},
        children.map(renderElement)
    )
};

function getListStyleType (node, listInfo, listType) {
    const { listStyleType } = node
    if(listStyleType) {
        return { listStyleType: listStyleType }
    } else if (listInfo) {
        const styleTypes = {
            'numbered-list': ['decimal', 'lower-alpha', 'lower-roman'],
            'bulleted-list': ['disc', 'circle', 'square']
        }
        return { listStyleType: styleTypes[listType][listInfo.level % 3] }
    } else return {}
    
}


const components = {
    "paragraph": Paragraph,

    "h1": heading(1),
    "h2": heading(2),
    "h3": heading(3),
    "h4": heading(4),
    "h5": heading(5),
    "h6": heading(6),

    "list-item": ListItem,
    "nestWrapper": NestWrapper,

    "numbered-list": ({ node, renderElement, listInfo }) => {
        const { children } = node
        return (
            <ol style={getListStyleType(node, listInfo, 'numbered-list')}>
                {children.map(renderElement)}
            </ol>
        )
    },
    "bulleted-list": ({ node, renderElement, listInfo }) => {
        const { children } = node
        return (
            <ul style={getListStyleType(node, listInfo, 'bulleted-list')}>
                {children.map(renderElement)}
            </ul>
        )
    },
    "block-quote": Blockquote,
    "code": ({ node }) => {
        const { value } = node
        return (
            <pre className="lc-source-code">
                { value}
            </pre>
        )
    },

    "inline-code": ({ node }) => {
        const { value } = node;
        return (
            <code className="lc-inline-code">
                { value }
            </code>
        )
    },

    "html-tag": ({ node }) => {
        const { value } = node;
        return value
    },


    "hr": _ => <hr />,
    "image": Image,
    "link": ({ node }) => {
        const { children, url, targetBlank } = node
        // 目前只有包含 image 的 link 会在第一级
        return (
            <a href={url} target={targetBlank ? '_blank' : '_self'}>
                {children.map(renderElement)}
            </a>
        )
    },

    "table": ({ node, renderElement }) => {
        const { children } = node;
        return (
            <div className="table-wrapper">
                <table>
                    <tbody>
                        {children.map(renderElement)}
                    </tbody>
                </table>
            </div>
        )
    },

    // table structure in struction is like： https://github.com/ianstormtaylor/slate/blob/main/site/examples/tables.tsx
    "table-row": ({ node, renderElement }) => (
        <tr>
            {node.children.map(renderElement)}
        </tr>
    ),

    "table-cell": ({ node, renderElement }) => {
        const { width } = node;
        // width 会设置在 cell 级别吗, 还是应该设置在 table 级别，使用 <colgroup> 标签？
        return (
            <td style={ width ? { minWidth: width, maxWidth: width } : {}}>
                {node.children.map(renderElement)}
            </td>
        )

    },


    "video": Media,
    "audio": Media
}




function StyledText({ node }) {

    function renderLeaf(leaf) {
        const { css } = leaf
        let leafElement = leaf.text !== "" ? (css ? <span style={css}>{leaf.text}</span> : leaf.text) : <br />

        if (leaf.bold) {
            leafElement = <strong>{leafElement}</strong>
        }

        if (leaf.code) {
            leafElement = <code>{leafElement}</code>
        }

        if (leaf.italic) {
            leafElement = <em>{leafElement}</em>
        }

        if (leaf.underline) {
            leafElement = <u>{leafElement}</u>
        }

        if (leaf.sub) {
            leafElement = <sub>{leafElement}</sub>
        }

        if (leaf.sup) {
            leafElement = <sup>{leafElement}</sup>
        }

        return leaf.color || leaf.background ? (
            <span style={{ color: leaf.color, background: leaf.background }}>{leafElement}</span>
        ) : leafElement
    }

    if (node.type && node.type === "link") {
        
        const { url } = node;
        const target = (() => {
            if(url.startsWith("http")){
                return "_blank"; // 绝对路径总是直接打开。 TODO 有可能要 confirm
            }
            return node.targetBlank ? '_blank' : '_self';
        })();

        return (
            <a href={node.url} target={target}>
                {
                    node.children.map((child, idx) => <React.Fragment key={idx}>{renderLeaf(child)}</React.Fragment>)
                }
            </a>
        )
    } else if (node.type == "break") {
        return <br />

    } else {
        return renderLeaf(node)
    }
}


/// for images
export const imageAlignStyle = {
    "center": { marginLeft: "auto", marginRight: "auto" },
    "left": { float: "left", marginRight: 4 },
    "right": { float: "right", marginLeft: 4 }
}


export function processImageURL(urls) {
    function processForDataBlob(url) {
        if (!url) {
            return url
        }
        const isObjectURL = url.match(/^blob:.+/) !== null
        if (isObjectURL) {
            return url
        } else {
            return getImageURL({ url })
        }
    }
    if (urls && Array.isArray(urls)) {
        return urls.map(processForDataBlob);
    } else {
        return processForDataBlob(urls)
    }

}
