
import { Editor, Transforms, Range } from 'slate'
import { markdownToRichText } from 'bwax-ui/markdown/convertMarkdown';

export function getMarkdownValues(text, editor) {

    const markdownValue = []

    const textArr = text.split('\n')
    const textArrWithContinuousList = []
    
    function getContinuousList (t, startIndex) {
        let offset = -1
        let collectedListText = []
    
        function isBulletedList (t) {
            return t.startsWith('- ')
        }

        function isNumberedList(t) {
            return t.match(/^\d+\. /)
        }

        for (let i = startIndex; i < textArr.length; i++) {
            // 去掉 list-item 之间的空格
            const currentText = textArr[i]
            if(isBulletedList(t)) {
                if(isBulletedList(currentText)) {
                    collectedListText.push(currentText)
                    offset += 1
                } else if (currentText === '' && i !== textArr.length - 1) {
                    const nextListItemIndex = textArr.findIndex((t, index) => index > i && isBulletedList(t))
                    if(nextListItemIndex !== -1 && textArr.slice(i, nextListItemIndex).every(t => t === '')) {
                        // 后面跟着若干个空格和一个 list
                        offset += (nextListItemIndex - i)
                        i += (nextListItemIndex - i - 1)
                    }
                } else {
                    break
                }
            } else if (isNumberedList(t)) {
                if(isNumberedList(currentText)) {
                    collectedListText.push(currentText)
                    offset += 1
                } else if (currentText === '' && i !== textArr.length - 1) {
                    const nextListItemIndex = textArr.findIndex((t, index) => index > i && isNumberedList(t))
                    if(nextListItemIndex !== -1 && textArr.slice(i, nextListItemIndex).every(t => t === '')) {
                        // 后面跟着若干个空格和一个 list
                        offset += (nextListItemIndex - i)
                        i += (nextListItemIndex - i - 1)
                    }
                } else {
                    break
                }
            } else {
                break
            }
            
        }

        return {
            collectedListText,
            offset
        }
    }

    for (let index = 0; index < textArr.length; index++) {
        if(textArr[index].startsWith("- ") || textArr[index].match(/^\d+\. /)) {
            const { collectedListText, offset } = getContinuousList(textArr[index], index)
            textArrWithContinuousList.push(collectedListText)
            index += offset
        } else {
            textArrWithContinuousList.push(textArr[index])
        }
        
    }


    function getBoldValue (text) {
        const boldValue = []

        if(text.match(/(\*\*)[^ ]/)) {
            const boldTextStar = text.match(/(\*\*)[^ ]/)
            if(boldTextStar.index !== 0) {
                boldValue.push({text: text.slice(0, boldTextStar.index)})
            }
            function getBoldValueInner (textCut) {
                const nextBoldStarIndex = textCut.search(/[^ ]\*\*/)
                if(nextBoldStarIndex >= 0) {
                    boldValue.push({ bold: true, text: textCut.slice(2, nextBoldStarIndex + 1) })

                    const nextBoldText = textCut.slice(nextBoldStarIndex + 3).match(/(\*\*)[^ ]/)
                    if(nextBoldStarIndex < textCut.length - 3 && nextBoldText) {
                        boldValue.push({ text: textCut.slice(nextBoldStarIndex + 3, nextBoldStarIndex + 3 + nextBoldText.index) })
                        getBoldValueInner(textCut.slice(nextBoldStarIndex + 3 + nextBoldText.index))
                    } else {
                        boldValue.push({ text: textCut.slice(nextBoldStarIndex + 3) })
                    }
                } else {
                    boldValue.push({ bold: true, text: textCut.slice(nextBoldStarIndex + 3) })
                }
            }
            getBoldValueInner(text.slice(boldTextStar.index))
        } else {
            boldValue.push({ text })
        }

        return boldValue
    }

    // console.log("textArrWithContinuousList: ", textArrWithContinuousList);
    

    function isAncestorBlockQuote () {
        if(!editor) {
            return false;
        }
        const { selection } = editor
        const [ancestorNode, ancestorPath] = Editor.node(editor, selection?.anchor.path.slice(0, 1));

        return ancestorNode && ancestorNode.type === "block-quote"
    }

    if(isAncestorBlockQuote()) {
        // 在 blockquote 中插入多段文字，需要转换成多段 blockquote
        textArr.forEach((text, index) => {
            const newBlockquoteValue = {
                type: 'block-quote', 
                children: getBoldValue(text)
            }
            markdownValue.push(newBlockquoteValue)
        })
    } else {
        
        for (let index = 0; index < textArrWithContinuousList.length; index++) {
            const t = textArrWithContinuousList[index];
            let newBlock = null
            if (Array.isArray(t)) {
                const isBulletedList = t[0].startsWith("- ") 
                newBlock = {
                    type: isBulletedList? "bulleted-list" : "numbered-list",
                    children: t.map(text => {
                        const numberedListStartIndex = text.indexOf(' ') + 1 // 第一个空格的 index
                        const listItemText = text.slice(isBulletedList ? 2 : numberedListStartIndex)
                        return {
                            type: "list-item",
                            children: [{ type: 'paragraph', children: getBoldValue(listItemText) }]
                        }
                    })
                }
            } else if(t.match(/^[ ]{0,3}>/)) {
                newBlock = {
                    type: 'block-quote',
                    children: [{ text: t.slice(t.indexOf(">") + 1) }]
                }
                
            } else if(t.startsWith("### ")) {
                newBlock = {
                    type: 'h3',
                    children: [{ text: t.slice(4) }]
                }

            } else if (t.startsWith("## ")) {
                newBlock = {
                    type: 'h2',
                    children: [{ text: t.slice(3) }]
                }
            } else if (t.match(/(\*\*)[^ ]/)) {
                // insertBoldText(index, t)
                newBlock = { type: "paragraph", children: getBoldValue(t) }
            } else {
                newBlock = { type: "paragraph", children: [{ text: t }] }
                
            }  

            markdownValue.push(newBlock)
        }
    }

    return markdownValue
}

export function insertMarkdownTextToEditor(editor, text) {

    const { selection } = editor

    if(selection) {
        const { anchor, focus } = selection
        let position = null
        if(Range.isBackward(selection)) {
            // 反向选择
            position = anchor
        } else {
            position = focus
        }
        
        const [parentNode, parentPath] = Editor.parent(
            editor,
            position.path
        );
        let currentNodeIndex = position.path[0]
        const [ancestorNode, ancestorPath] = Editor.node(editor, position.path.slice(0, 1))
        // const markdownValues = getMarkdownValues(text, editor)
        const markdownValues = markdownToRichText(text)

        function isList(node) {
            return node.type === "bulleted-list" || node.type === "numbered-list"
        }
    
        for (let index = 0; index < markdownValues.length; index++) {
            const currentValue = markdownValues[index]

            if(index === 0) {
                if(currentValue.type === "paragraph") {
                    Transforms.insertFragment(editor, currentValue.children)
                } else if(isList(currentValue)) {
                    if(parentNode.type === 'paragraph' && Editor.string(editor, selection) === '' && !isList(ancestorNode)) {
                        // 如果在一个空 paragraph 中，则删除这个 paragraph，并插入一个 list
                        Transforms.insertNodes(editor, currentValue, { at: [currentNodeIndex], select: true })
                        Transforms.removeNodes(editor, { at: [currentNodeIndex + 1]})
                    } else {
                        // 如果不在空 paragraph 中，直接加在下面
                        Transforms.insertNodes(editor, currentValue, { at: [currentNodeIndex + 1], select: true })
                        currentNodeIndex += 1
                    }
                } else {
                    Transforms.setNodes(editor, { type: currentValue.type })
                    Transforms.insertFragment(editor, currentValue.children)
                }
            } else {
                Transforms.insertNodes(editor, currentValue, { at: [currentNodeIndex + index], select: true })
            }
        }
    }
    
}