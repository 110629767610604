import React from 'react'

class DiscussionIcon extends React.Component {

    render() {
        return (
            <svg width="1em" height="1em" viewBox="0 0 10 12">
                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Icon/VIP-services/拆解" transform="translate(-3.000000, -2.000000)" fill="#FFFFFF" fillRule="nonzero">
                        <g transform="translate(-0.000000, 0.000000)" id="Combined-Shape">
                            <path d="M11.9365497,4.37001502 L11.9365497,9.11004507 L7.75692717,11.4800601 L3.57730462,9.11004507 L3.57730462,4.37001502 L7.75692717,2 L11.9365497,4.37001502 Z M4.95917788,5.15359305 L7.75692717,3.56715605 L10.5546765,5.15359305 L10.5546765,8.32646704 L7.75692717,9.91290404 L4.95917788,8.32646704 L4.95917788,5.15359305 Z M10.9575635,4.92513979 L7.75692717,3.11024953 L4.55629082,4.92513979 L4.55629082,8.5549203 L7.75692717,10.3698106 L10.9575635,8.5549203 L10.9575635,4.92513979 Z M8.76653285,10.6347349 L10.4797587,9.64560354 L12.4226954,13.0108686 L11.9632679,13.9902617 L10.7094695,14 L8.76653285,10.6347349 Z M9.51900576,11.5156808 L9.70686507,11.8287073 L11.041085,11.0654018 L10.8532257,10.7523752 L9.51900576,11.5156808 Z"></path>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export default DiscussionIcon
