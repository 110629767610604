// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Plate$BwaxMobile = require("../../../../bwax-js/ml/plate.bs.js");
var Lang_eval$BwaxMobile = require("../../../../bwax-js/ml/lang/lang_eval.bs.js");
var Runtime_common$BwaxMobile = require("../../../../bwax-js/ml/lang/mod/runtime_common.bs.js");

var Widget_exn = Caml_exceptions.create("Widget_common-BwaxMobile.Widget_exn");

function get_placeholder_text(placeholder) {
  if (placeholder.tag) {
    console.log("Placeholder element is not supported yet");
    return ;
  } else {
    return placeholder[0];
  }
}

function make_color(v) {
  var match = v[0];
  if (typeof match !== "number" && match.tag === /* V_tagged_string */10 && match[0] === "Color") {
    return /* `raw */[
            5690856,
            match[1]
          ];
  }
  console.log("make_color arg", v, Lang_eval$BwaxMobile.string_of_value(v));
  return Runtime_common$BwaxMobile.invalid_args("make_color");
}

function as_element(v) {
  var match = v[0];
  if (typeof match === "number" || match.tag !== /* V_raw */7) {
    return ;
  } else {
    return match[0];
  }
}

function element_to_value(v) {
  return /* tuple */[
          /* V_raw */Block.__(7, [v]),
          /* Term */Block.__(0, [
              "Element",
              /* :: */[
                Runtime_common$BwaxMobile.nt,
                /* [] */0
              ]
            ])
        ];
}

function pack_element_func(arity, f) {
  return Runtime_common$BwaxMobile.pack_func(arity, (function (args) {
                return element_to_value(Curry._1(f, args));
              }));
}

function raw_to_rules(rs) {
  return Plate$BwaxMobile.List.keep_map((function (param) {
                var match = param[0];
                if (typeof match === "number" || match.tag !== /* V_raw */7) {
                  return ;
                } else {
                  return match[0];
                }
              }), rs);
}

function force_float(value, fname) {
  var match = value[0];
  if (typeof match === "number" || match.tag !== /* V_literal */5) {
    return Runtime_common$BwaxMobile.invalid_args(fname);
  } else {
    var match$1 = match[0];
    if (typeof match$1 === "number") {
      return Runtime_common$BwaxMobile.invalid_args(fname);
    } else {
      switch (match$1.tag | 0) {
        case /* Int */0 :
            return match$1[0];
        case /* Float */1 :
            return match$1[0];
        default:
          return Runtime_common$BwaxMobile.invalid_args(fname);
      }
    }
  }
}

function float_to_raw(pack, name) {
  var func = function (args) {
    if (args) {
      var match = args[0][0];
      if (typeof match === "number" || match.tag !== /* V_literal */5) {
        return Runtime_common$BwaxMobile.invalid_args(name);
      } else {
        var match$1 = match[0];
        if (typeof match$1 === "number") {
          return Runtime_common$BwaxMobile.invalid_args(name);
        } else {
          switch (match$1.tag | 0) {
            case /* Int */0 :
                if (args[1]) {
                  return Runtime_common$BwaxMobile.invalid_args(name);
                } else {
                  return /* tuple */[
                          /* V_raw */Block.__(7, [Curry._1(pack, match$1[0])]),
                          Runtime_common$BwaxMobile.nt
                        ];
                }
            case /* Float */1 :
                if (args[1]) {
                  return Runtime_common$BwaxMobile.invalid_args(name);
                } else {
                  return /* tuple */[
                          /* V_raw */Block.__(7, [Curry._1(pack, match$1[0])]),
                          Runtime_common$BwaxMobile.nt
                        ];
                }
            default:
              return Runtime_common$BwaxMobile.invalid_args(name);
          }
        }
      }
    } else {
      return Runtime_common$BwaxMobile.invalid_args(name);
    }
  };
  return /* tuple */[
          /* V_native_fn */Block.__(4, [
              1,
              func,
              /* [] */0
            ]),
          Runtime_common$BwaxMobile.nt
        ];
}

function int_to_raw(pack, name) {
  var func = function (args) {
    if (args) {
      var match = args[0][0];
      if (typeof match === "number" || match.tag !== /* V_literal */5) {
        return Runtime_common$BwaxMobile.invalid_args(name);
      } else {
        var match$1 = match[0];
        if (typeof match$1 === "number" || match$1.tag || args[1]) {
          return Runtime_common$BwaxMobile.invalid_args(name);
        } else {
          return /* tuple */[
                  /* V_raw */Block.__(7, [Curry._1(pack, match$1[0])]),
                  Runtime_common$BwaxMobile.nt
                ];
        }
      }
    } else {
      return Runtime_common$BwaxMobile.invalid_args(name);
    }
  };
  return /* tuple */[
          /* V_native_fn */Block.__(4, [
              1,
              func,
              /* [] */0
            ]),
          Runtime_common$BwaxMobile.nt
        ];
}

function string_to_raw(pack, name) {
  var func = function (args) {
    if (args) {
      var match = args[0][0];
      if (typeof match === "number" || match.tag !== /* V_literal */5) {
        return Runtime_common$BwaxMobile.invalid_args(name);
      } else {
        var match$1 = match[0];
        if (typeof match$1 === "number" || !(match$1.tag === /* String */2 && !args[1])) {
          return Runtime_common$BwaxMobile.invalid_args(name);
        } else {
          return /* tuple */[
                  /* V_raw */Block.__(7, [Curry._1(pack, match$1[0])]),
                  Runtime_common$BwaxMobile.nt
                ];
        }
      }
    } else {
      return Runtime_common$BwaxMobile.invalid_args(name);
    }
  };
  return /* tuple */[
          /* V_native_fn */Block.__(4, [
              1,
              func,
              /* [] */0
            ]),
          Runtime_common$BwaxMobile.nt
        ];
}

function string_to_tagged_raw(tag_name, pack, name) {
  var func = function (args) {
    if (args) {
      var match = args[0][0];
      if (typeof match === "number" || match.tag !== /* V_literal */5) {
        return Runtime_common$BwaxMobile.invalid_args(name);
      } else {
        var match$1 = match[0];
        if (typeof match$1 === "number" || !(match$1.tag === /* String */2 && !args[1])) {
          return Runtime_common$BwaxMobile.invalid_args(name);
        } else {
          return /* tuple */[
                  /* V_tagged_raw */Block.__(8, [
                      tag_name,
                      Curry._1(pack, match$1[0])
                    ]),
                  Runtime_common$BwaxMobile.nt
                ];
        }
      }
    } else {
      return Runtime_common$BwaxMobile.invalid_args(name);
    }
  };
  return /* tuple */[
          /* V_native_fn */Block.__(4, [
              1,
              func,
              /* [] */0
            ]),
          Runtime_common$BwaxMobile.nt
        ];
}

function string_to_tagged_string(tag_name, name) {
  var func = function (args) {
    if (args) {
      var match = args[0][0];
      if (typeof match === "number" || match.tag !== /* V_literal */5) {
        return Runtime_common$BwaxMobile.invalid_args(name);
      } else {
        var match$1 = match[0];
        if (typeof match$1 === "number" || !(match$1.tag === /* String */2 && !args[1])) {
          return Runtime_common$BwaxMobile.invalid_args(name);
        } else {
          return /* tuple */[
                  /* V_tagged_string */Block.__(10, [
                      tag_name,
                      match$1[0]
                    ]),
                  Runtime_common$BwaxMobile.nt
                ];
        }
      }
    } else {
      return Runtime_common$BwaxMobile.invalid_args(name);
    }
  };
  return /* tuple */[
          /* V_native_fn */Block.__(4, [
              1,
              func,
              /* [] */0
            ]),
          Runtime_common$BwaxMobile.nt
        ];
}

function simply_raw(pack) {
  return /* tuple */[
          /* V_raw */Block.__(7, [Curry._1(pack, /* () */0)]),
          Runtime_common$BwaxMobile.nt
        ];
}

function value_to_raw(proc, name) {
  var func = function (args) {
    if (args && !args[1]) {
      return /* tuple */[
              /* V_raw */Block.__(7, [Curry._1(proc, args[0])]),
              /* No_term */0
            ];
    } else {
      return Runtime_common$BwaxMobile.invalid_args(name);
    }
  };
  return Runtime_common$BwaxMobile.pack_func(1, func);
}

function raw_to_raw(proc, name) {
  var func = function (args) {
    if (args) {
      var match = args[0][0];
      if (typeof match === "number" || !(match.tag === /* V_raw */7 && !args[1])) {
        return Runtime_common$BwaxMobile.invalid_args(name);
      } else {
        return /* tuple */[
                /* V_raw */Block.__(7, [Curry._1(proc, match[0])]),
                /* No_term */0
              ];
      }
    } else {
      return Runtime_common$BwaxMobile.invalid_args(name);
    }
  };
  return /* tuple */[
          /* V_native_fn */Block.__(4, [
              1,
              func,
              /* [] */0
            ]),
          /* No_term */0
        ];
}

function raw2_to_raw(pack, name) {
  var func = function (args) {
    if (args) {
      var match = args[0][0];
      if (typeof match === "number" || match.tag !== /* V_raw */7) {
        return Runtime_common$BwaxMobile.invalid_args(name);
      } else {
        var match$1 = args[1];
        if (match$1) {
          var match$2 = match$1[0][0];
          if (typeof match$2 === "number" || !(match$2.tag === /* V_raw */7 && !match$1[1])) {
            return Runtime_common$BwaxMobile.invalid_args(name);
          } else {
            return /* tuple */[
                    /* V_raw */Block.__(7, [Curry._2(pack, match[0], match$2[0])]),
                    /* No_term */0
                  ];
          }
        } else {
          return Runtime_common$BwaxMobile.invalid_args(name);
        }
      }
    } else {
      return Runtime_common$BwaxMobile.invalid_args(name);
    }
  };
  return /* tuple */[
          /* V_native_fn */Block.__(4, [
              2,
              func,
              /* [] */0
            ]),
          /* No_term */0
        ];
}

function value_to_float_to_raw(proc, name) {
  var func = function (args) {
    if (args) {
      var match = args[1];
      if (match) {
        var match$1 = match[0][0];
        if (typeof match$1 === "number" || match$1.tag !== /* V_literal */5) {
          return Runtime_common$BwaxMobile.invalid_args(name);
        } else {
          var match$2 = match$1[0];
          if (typeof match$2 === "number" || !(match$2.tag === /* Float */1 && !match[1])) {
            return Runtime_common$BwaxMobile.invalid_args(name);
          } else {
            return /* tuple */[
                    /* V_raw */Block.__(7, [Curry._2(proc, args[0], match$2[0])]),
                    /* No_term */0
                  ];
          }
        }
      } else {
        return Runtime_common$BwaxMobile.invalid_args(name);
      }
    } else {
      return Runtime_common$BwaxMobile.invalid_args(name);
    }
  };
  return Runtime_common$BwaxMobile.pack_func(2, func);
}

function raw_to_float_to_raw(proc, name) {
  var func = function (args) {
    if (args) {
      var match = args[0][0];
      if (typeof match === "number" || match.tag !== /* V_raw */7) {
        return Runtime_common$BwaxMobile.invalid_args(name);
      } else {
        var match$1 = args[1];
        if (match$1) {
          var match$2 = match$1[0][0];
          if (typeof match$2 === "number" || match$2.tag !== /* V_literal */5) {
            return Runtime_common$BwaxMobile.invalid_args(name);
          } else {
            var match$3 = match$2[0];
            if (typeof match$3 === "number" || !(match$3.tag === /* Float */1 && !match$1[1])) {
              return Runtime_common$BwaxMobile.invalid_args(name);
            } else {
              return /* tuple */[
                      /* V_raw */Block.__(7, [Curry._2(proc, match[0], match$3[0])]),
                      /* No_term */0
                    ];
            }
          }
        } else {
          return Runtime_common$BwaxMobile.invalid_args(name);
        }
      }
    } else {
      return Runtime_common$BwaxMobile.invalid_args(name);
    }
  };
  return /* tuple */[
          /* V_native_fn */Block.__(4, [
              2,
              func,
              /* [] */0
            ]),
          /* No_term */0
        ];
}

function rules_to_element_to_element(pack, name) {
  var func = function (args) {
    if (args) {
      var match = args[0][0];
      if (typeof match === "number" || match.tag !== /* V_list */1) {
        return Runtime_common$BwaxMobile.invalid_args(name);
      } else {
        var match$1 = args[1];
        if (match$1) {
          var match$2 = match$1[0][0];
          if (typeof match$2 === "number" || !(match$2.tag === /* V_raw */7 && !match$1[1])) {
            return Runtime_common$BwaxMobile.invalid_args(name);
          } else {
            var rules = raw_to_rules(match[0]);
            return element_to_value(Curry._2(pack, rules, match$2[0]));
          }
        } else {
          return Runtime_common$BwaxMobile.invalid_args(name);
        }
      }
    } else {
      return Runtime_common$BwaxMobile.invalid_args(name);
    }
  };
  return /* tuple */[
          /* V_native_fn */Block.__(4, [
              2,
              func,
              /* [] */0
            ]),
          /* No_term */0
        ];
}

function collect_raws(l) {
  return Plate$BwaxMobile.List.keep_map((function (param) {
                var match = param[0];
                if (typeof match === "number" || match.tag !== /* V_raw */7) {
                  return ;
                } else {
                  return Caml_option.some(match[0]);
                }
              }), l);
}

function collect_elements(l) {
  return Plate$BwaxMobile.List.map((function (prim) {
                return prim;
              }), collect_raws(l));
}

function collect_colors(l) {
  return Plate$BwaxMobile.List.map(make_color, l);
}

function to_nearby(v) {
  var match = v[0];
  if (typeof match === "number" || match.tag !== /* V_tagged */6) {
    return ;
  } else {
    switch (match[0]) {
      case "Above" :
      case "Behind" :
      case "Below" :
      case "InFront" :
      case "OnLeft" :
      case "OnRight" :
          break;
      default:
        return ;
    }
    var match$1 = match[1];
    if (match$1) {
      var match$2 = match$1[0][0];
      if (typeof match$2 === "number" || !(match$2.tag === /* V_raw */7 && !match$1[1])) {
        return ;
      } else {
        return /* InFront */Block.__(0, [match$2[0]]);
      }
    } else {
      return ;
    }
  }
}

function rules_to_elements_to_element(pack, name) {
  var func = function (args) {
    if (args) {
      var match = args[0][0];
      if (typeof match === "number" || match.tag !== /* V_list */1) {
        return Runtime_common$BwaxMobile.invalid_args(name);
      } else {
        var match$1 = args[1];
        if (match$1) {
          var match$2 = match$1[0][0];
          if (typeof match$2 === "number" || !(match$2.tag === /* V_list */1 && !match$1[1])) {
            return Runtime_common$BwaxMobile.invalid_args(name);
          } else {
            var rules = raw_to_rules(match[0]);
            var elements = collect_elements(match$2[0]);
            return element_to_value(Curry._2(pack, rules, elements));
          }
        } else {
          return Runtime_common$BwaxMobile.invalid_args(name);
        }
      }
    } else {
      return Runtime_common$BwaxMobile.invalid_args(name);
    }
  };
  return /* tuple */[
          /* V_native_fn */Block.__(4, [
              2,
              func,
              /* [] */0
            ]),
          /* No_term */0
        ];
}

function rules_to_elements_nearby_to_element(pack, name) {
  var func = function (args) {
    if (args) {
      var match = args[0][0];
      if (typeof match === "number" || match.tag !== /* V_list */1) {
        return Runtime_common$BwaxMobile.invalid_args(name);
      } else {
        var match$1 = args[1];
        if (match$1) {
          var match$2 = match$1[0][0];
          if (typeof match$2 === "number" || match$2.tag !== /* V_list */1) {
            return Runtime_common$BwaxMobile.invalid_args(name);
          } else {
            var match$3 = match$1[1];
            if (match$3) {
              var match$4 = match$3[0][0];
              if (typeof match$4 === "number" || !(match$4.tag === /* V_list */1 && !match$3[1])) {
                return Runtime_common$BwaxMobile.invalid_args(name);
              } else {
                var rules = raw_to_rules(match[0]);
                var els = collect_elements(match$2[0]);
                var nbs = Plate$BwaxMobile.List.keep_map(to_nearby, match$4[0]);
                return element_to_value(Curry._3(pack, rules, els, nbs));
              }
            } else {
              return Runtime_common$BwaxMobile.invalid_args(name);
            }
          }
        } else {
          return Runtime_common$BwaxMobile.invalid_args(name);
        }
      }
    } else {
      return Runtime_common$BwaxMobile.invalid_args(name);
    }
  };
  return /* tuple */[
          /* V_native_fn */Block.__(4, [
              3,
              func,
              /* [] */0
            ]),
          /* No_term */0
        ];
}

function length_each_to_raw(proc, name) {
  var func = function (args) {
    if (args) {
      var match = args[0][0];
      if (typeof match === "number" || !(match.tag === /* V_record */2 && !args[1])) {
        return Runtime_common$BwaxMobile.invalid_args(name);
      } else {
        var binds = match[0];
        var get = function (name) {
          return Plate$BwaxMobile.$$Option.and_then((function (param) {
                        var match = param[0];
                        if (typeof match === "number" || match.tag !== /* V_raw */7) {
                          return ;
                        } else {
                          return match[0];
                        }
                      }), Plate$BwaxMobile.List.assoc(name, binds));
        };
        var length_each_000 = /* top */get("top");
        var length_each_001 = /* right */get("right");
        var length_each_002 = /* bottom */get("bottom");
        var length_each_003 = /* left */get("left");
        var length_each = /* record */[
          length_each_000,
          length_each_001,
          length_each_002,
          length_each_003
        ];
        var raw = Curry._1(proc, length_each);
        return /* tuple */[
                /* V_raw */Block.__(7, [raw]),
                /* No_term */0
              ];
      }
    } else {
      return Runtime_common$BwaxMobile.invalid_args(name);
    }
  };
  return /* tuple */[
          /* V_native_fn */Block.__(4, [
              1,
              func,
              /* [] */0
            ]),
          /* No_term */0
        ];
}

function each_corner_to_raw(proc, name) {
  var func = function (args) {
    if (args) {
      var match = args[0][0];
      if (typeof match === "number" || !(match.tag === /* V_record */2 && !args[1])) {
        return Runtime_common$BwaxMobile.invalid_args(name);
      } else {
        var binds = match[0];
        var get = function (name) {
          return Plate$BwaxMobile.$$Option.with_default(/* zero */-789508312, Plate$BwaxMobile.$$Option.and_then((function (param) {
                            var match = param[0];
                            if (typeof match === "number" || match.tag !== /* V_raw */7) {
                              return ;
                            } else {
                              return match[0];
                            }
                          }), Plate$BwaxMobile.List.assoc(name, binds)));
        };
        var ec_000 = /* tl */get("tl");
        var ec_001 = /* tr */get("tr");
        var ec_002 = /* br */get("br");
        var ec_003 = /* bl */get("bl");
        var ec = /* record */[
          ec_000,
          ec_001,
          ec_002,
          ec_003
        ];
        var raw = Curry._1(proc, ec);
        return /* tuple */[
                /* V_raw */Block.__(7, [raw]),
                /* No_term */0
              ];
      }
    } else {
      return Runtime_common$BwaxMobile.invalid_args(name);
    }
  };
  return /* tuple */[
          /* V_native_fn */Block.__(4, [
              1,
              func,
              /* [] */0
            ]),
          /* No_term */0
        ];
}

function element_to_rule(proc, name) {
  var func = function (args) {
    if (args) {
      var match = args[0][0];
      if (typeof match === "number" || !(match.tag === /* V_raw */7 && !args[1])) {
        return Runtime_common$BwaxMobile.invalid_args(name);
      } else {
        var raw = Curry._1(proc, match[0]);
        return /* tuple */[
                /* V_raw */Block.__(7, [raw]),
                Runtime_common$BwaxMobile.nt
              ];
      }
    } else {
      return Runtime_common$BwaxMobile.invalid_args(name);
    }
  };
  return /* tuple */[
          /* V_native_fn */Block.__(4, [
              1,
              func,
              /* [] */0
            ]),
          Runtime_common$BwaxMobile.nt
        ];
}

function tagger_to_json_callback(proc_value, tagger) {
  var tmp = tagger[0];
  if (typeof tmp !== "number") {
    switch (tmp.tag | 0) {
      case /* V_closure */3 :
      case /* V_native_fn */4 :
          var tagger$1 = tagger;
          return (function (js) {
              var args = Plate$BwaxMobile.List.keep_map(proc_value, js);
              return Lang_eval$BwaxMobile.apply_value_args(undefined, tagger$1, args, Runtime_common$BwaxMobile.nt);
            });
      default:
        
    }
  }
  return (function (param) {
      return tagger;
    });
}

var no_type = /* No_term */0;

exports.no_type = no_type;
exports.Widget_exn = Widget_exn;
exports.get_placeholder_text = get_placeholder_text;
exports.make_color = make_color;
exports.as_element = as_element;
exports.element_to_value = element_to_value;
exports.pack_element_func = pack_element_func;
exports.raw_to_rules = raw_to_rules;
exports.force_float = force_float;
exports.float_to_raw = float_to_raw;
exports.int_to_raw = int_to_raw;
exports.string_to_raw = string_to_raw;
exports.string_to_tagged_raw = string_to_tagged_raw;
exports.string_to_tagged_string = string_to_tagged_string;
exports.simply_raw = simply_raw;
exports.value_to_raw = value_to_raw;
exports.raw_to_raw = raw_to_raw;
exports.raw2_to_raw = raw2_to_raw;
exports.value_to_float_to_raw = value_to_float_to_raw;
exports.raw_to_float_to_raw = raw_to_float_to_raw;
exports.rules_to_element_to_element = rules_to_element_to_element;
exports.collect_raws = collect_raws;
exports.collect_elements = collect_elements;
exports.collect_colors = collect_colors;
exports.to_nearby = to_nearby;
exports.rules_to_elements_to_element = rules_to_elements_to_element;
exports.rules_to_elements_nearby_to_element = rules_to_elements_nearby_to_element;
exports.length_each_to_raw = length_each_to_raw;
exports.each_corner_to_raw = each_corner_to_raw;
exports.element_to_rule = element_to_rule;
exports.tagger_to_json_callback = tagger_to_json_callback;
/* Plate-BwaxMobile Not a pure module */
