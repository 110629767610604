

import Link from 'bwax-ui/page/Link';
import React from 'react'

import classNames from "classnames"

export default function VipTabs_cb({ slots, viewEnv }) {

    const { mainContent } = slots;

    const { currentURLPath } = viewEnv.webEnv;

    const tabs = [
        { label: "蜜圈", url: "/vip/main" },
        { label: "投资", url: "/vip/invest" },
        { label: "项目初筛", url: "/vip/pipeline" },
    ]

    return (
        <div className="flex flex-col w-full py-10 gap-10">
            <div className="flex self-center">
                { tabs.map(({ label, url }, index) => {
                    const isActive = currentURLPath.startsWith(url);
                    return (
                        <Link className={classNames("px-8 py-3 !border-[var(--slate4)]", {
                            "bg-[var(--slate5)] cursor-default": isActive,
                            "bg-[var(--slate2)] text-[var(--slate11)]": !isActive,
                            "border-l-[1px]": index !== 0,
                            "rounded-l": index == 0,
                            "rounded-r": index == tabs.length - 1,
                        })} to={url} key={label}>
                            { label }
                        </Link>
                    )
                })}
            </div>
            { mainContent }
        </div>
    )
}
