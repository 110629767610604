
import React, { useContext, useRef, useState, useEffect } from 'react'

import KnowledgeItemList from './components/KnowledgeItemList';

import dayjs from 'dayjs';

import AddPageBookmarkButton from './components/input/AddPageBookmarkButton';
import Modal from 'bwax-ui/components/Modal'

import UsageQuotaContext from 'bwax-ui/ml/widget/ports/inbot/UsageQuotaContext';

import BookmarkDetail from './components/BookmarkDetail';
import { addQueryParam, removeQueryParam, getQueryParam } from 'bwax/ml/lang/mod/builtin/StringHelper';


import { useTrack } from 'bwax-ui/track';

export default function BookmarkList({ facade, viewEnv, data }) {

    const track = useTrack();

    const { reloadUsageQuota, remainingQuota } = useContext(UsageQuotaContext) || {};

    const { routeTo, webEnv, domainEnv } = viewEnv;

    const entityName = "OpenAI-网页收藏";

    const updateDataRef = useRef();

    const fieldPaths = ["标题", "标签", "摘要", "网址", "总结", "内容词符数", "加入知识库", "创建时间"];

    async function loadData({ keyword, pageSize, offset }) {
        return await facade.listAll({
            entityName,
            search: keyword && keyword.trim().length > 0 ? { keyword, fields: ["标题"] } : undefined,
            condition: [[
                // { field: "加入知识库", op: "eq", value: true }
            ]],
            sort: [
                { field: "创建时间", order: "DESC" }
            ],
            pageSize, offset,
            fieldPaths,
        });
    };


    function renderItem(item) {
        return (
            <>
                <div className="main-part" style={item.加入知识库 ? {} : { opacity: 0.5 }}>
                    <div className="icon pt-[1px]">
                        <i className='bx bx-link-alt'></i>
                    </div>
                    <div>
                        {item.标题 || "未提取出标题的网页"}
                    </div>
                </div>
                <div className="info-part">
                    {dayjs(item.创建时间).format("MM.DD")}
                </div>
            </>
        )
    }

    const isOutOfQuota = remainingQuota !== undefined && remainingQuota <= 0;


    const [detailToShow, setDetailToShow] = useState();
    
    useEffect(() => {
        // 只在最开始的时候根据 doc 加载
        // 
        const bId = getQueryParam("bId",  webEnv.currentURL);

        if(bId) {

            (async () => {
                const [ result, error ] = await facade.findById(bId, {
                    entityName,                    
                    fieldPaths,
                });
                if(error) {
                    console.error(error)
                } else if(result) {
                    setDetailToShow(result)
                }

            })()

        }

    }, []);

    async function updateItem(docId, formData) {
        const [result, error] = await facade.update({
            entityName,
            formData,
            id: docId,
            fieldPaths,
        });
        if (!error && result) {
            if(updateDataRef.current) {
                updateDataRef.current(prevData => {
                    return prevData.map(doc => doc.id == docId ? result : doc)
                })
            }
            if(detailToShow && detailToShow.id == result.id) {
                setDetailToShow(result);
            }
        }
    }

    async function deleteItem(docId) {
        const [result, error] = await facade.delete({
            entityName,
            id: docId,
        });
        if (!error && updateDataRef.current) {
            updateDataRef.current(prevData => {
                return prevData.filter(doc => doc.id != docId)
            })
        }
    }


    function getItemMenu(item) {
        return [
            {
                label: "加入知识库",
                icon: <i className='bx bx-folder-plus'></i>,
                onSelect: async _ => {
                    // 
                    updateItem(item.id, { 加入知识库: true });

                    setTimeout(() => {
                        reloadUsageQuota();
                    }, 2000)
                },
                hidden: item.加入知识库,
                isDisabled: isOutOfQuota,
            },
            {
                label: "从知识库移除",
                icon: <i className='bx bx-folder-minus'></i>,
                onSelect: async _ => {
                    // 
                    updateItem(item.id, { 加入知识库: false })
                },
                hidden: !item.加入知识库
            },
            {
                label: "删除链接",
                icon: <i className='bx bx-trash'></i>,
                onSelect: async _ => {
                    deleteItem(item.id);
                },
                isDisabled: item.加入知识库
            },
        ].filter(x => !x.hidden)
    };


    return (
        <>
            <KnowledgeItemList {...{
                loadData, renderItem, getItemMenu,

                title: (
                    <>
                        <i className='bx bx-bookmark font-size-15 pt-[2px]' />
                        <span>知识库的网页收藏夹</span>
                    </>
                ),

                onItemPress: item => {
                    track("bookmark_open_bookmark_detail");
                    routeTo(addQueryParam("bId", item.id, webEnv.currentURL));
                    setDetailToShow(item);
                },

                actionButtons: (
                    <AddPageBookmarkButton onAdded={item => {
                        // 
                        if (updateDataRef.current) {
                            updateDataRef.current(prevData => {
                                return [item, ...prevData]
                            })
                        }
                    }} fieldPaths={fieldPaths} />
                ),

                bindUpdateData: updateData => {
                    updateDataRef.current = updateData
                }
            }} />
            <Modal isDismissable isOpen={!!detailToShow} contentClassName="h-full" isLarge={true} onOpenChange={isOpen => {
                if (!isOpen) { 
                    routeTo(removeQueryParam("bId", webEnv.currentURL))
                    setDetailToShow() 
                }
            }}>
                {detailToShow ? (
                    <BookmarkDetail bookmark={detailToShow} updateBookmark={updateItem} facade={facade}
                        routeTo={routeTo}
                        viewEnv={viewEnv}
                        {...data}  
                    />
                ) : null}
            </Modal>
        </>
    )
}

