

import sha1Func from 'crypto-js/sha1';
import hexEnc from 'crypto-js/enc-hex';


export function sha1(v) {
    return sha1Func(v)
}

export function hex(v) {
    return v.toString(hexEnc);
}