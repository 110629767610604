
import React from 'react'

import { Transforms } from 'slate'

import { MdArrowRightAlt, MdLaptop } from 'react-icons/md'

const audioAlignIcons = {
    "left": <MdArrowRightAlt style={{ transform: "rotateZ(180deg)", fontSize: 16 }} />,
    "center": <MdLaptop style={{ fontSize: 14 }}/>,
    "right": <MdArrowRightAlt style={{ fontSize: 16 }} />
}

export default function AudioAlignButton({editor, align, iconClass }) {


    function alignAudio () {
        // Transforms.unsetNodes(
        //     editor,
        //     ["margin", "marginLeft", "marginRight", "float", "align"],
        //     { match: (node) => node.type === "image" }
        // )

        Transforms.setNodes(
            editor,
            { align },
            { match: (node) => node.type === "audio" }
        )
    }

    const audioAlignIcon = audioAlignIcons[align]

    return (
        <button className="editor-button" onClick={() => alignAudio()}>
            { audioAlignIcon }
        </button>
    )
}
