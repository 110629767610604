import React from 'react'

class Club extends React.Component {

    render() {
        return (
            <svg width="56px" height="56px" viewBox="0 0 56 56">
                <defs>
                    <linearGradient x1="100%" y1="100%" x2="0%" y2="0%" id="linearGradient-1">
                        <stop stopColor="#3C3D3D" offset="0%"></stop>
                        <stop stopColor="#141414" offset="100%"></stop>
                    </linearGradient>
                    <linearGradient x1="5.02629893%" y1="10.3465005%" x2="96.6715015%" y2="80.6854725%" id="linearGradient-2">
                        <stop stopColor="#FFE9C9" offset="0%"></stop>
                        <stop stopColor="#FFCF99" offset="100%"></stop>
                    </linearGradient>
                </defs>
                <g id="会员体系" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="会员套餐" transform="translate(-250.000000, -632.000000)" fillRule="nonzero">
                        <g id="会员特权" transform="translate(0.000000, 556.000000)">
                            <g id="健康俱乐部" transform="translate(196.000000, 64.000000)">
                                <g id="icon2" transform="translate(54.000000, 12.000000)">
                                    <circle id="Oval" fill="url(#linearGradient-1)" cx="28" cy="28" r="28"></circle>
                                    <path d="M15.5116989,30.1087554 L18.6972458,30.1087554 L20.8195631,27.3504424 L23.9774176,32.8527143 L27.3090507,26.3224535 L30.2569625,35.9533334 L33.0396918,30.1087554 L39.5074321,30.1087554 L40.7251796,28.914311 L32.2669962,28.914311 L30.5042954,32.6165187 L27.5923486,23.1031372 L23.9143577,30.3122863 L20.9677241,25.1780475 L18.0929465,28.914311 L14.2949115,28.914311 L15.5116989,30.1087554 Z M15.5116989,30.1087554 L11.1453961,30.1087554 L11.1453961,28.914311 L14.2949115,28.914311 L10,24.6982637 L20.881263,14 L27.5116001,20.5188292 L34.023582,14.1163648 L44.9048451,24.8146285 L40.7251796,28.914311 L45.582297,28.914311 L45.582297,30.1087554 L39.5074321,30.1087554 L27.5116001,41.8750327 L26.98007,41.3665413 L15.5116989,30.1087554 Z M18.3950961,28.914311 L17.910658,29.1512259 L18.0929465,28.914311 L18.3950961,28.914311 Z" id="Combined-Shape" fill="url(#linearGradient-2)"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export default Club
