// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var BwaxUi = require("bwax-ui");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Plate$BwaxMobile = require("../../bwax-js/ml/plate.bs.js");
var CssCompatibility = require("./CssCompatibility");
var ColorTypes$BwaxMobile = require("./ColorTypes.bs.js");
var StringUtils$BwaxMobile = require("../../bwax-js/re/utils/StringUtils.bs.js");

function supports(prim, prim$1) {
  return CssCompatibility.supports(prim, prim$1);
}

function hexToCssColor(hex) {
  return Css.hex(StringUtils$BwaxMobile.after(hex, 1));
}

function themeColorCss(theme, name) {
  var hex = BwaxUi.themeColor(theme, name);
  return Css.hex(StringUtils$BwaxMobile.after(hex, 1));
}

function ifWide(rules) {
  return Css.media("(min-width: 640px)", rules);
}

function ifNarrow(rules) {
  return Css.media("(max-width: 639px)", rules);
}

function grabFirst(candidates) {
  var head = Belt_List.head(candidates);
  var tail = Belt_List.tail(candidates);
  return Belt_Option.flatMap(head, (function (hd) {
                var tried = Curry._1(hd, /* () */0);
                var match = Belt_Option.isSome(tried);
                if (match) {
                  return tried;
                } else {
                  return Belt_Option.flatMap(tail, grabFirst);
                }
              }));
}

function toString(param) {
  if (typeof param === "number") {
    return "0";
  } else {
    var variant = param[0];
    if (variant >= 22632) {
      if (variant >= 26418) {
        if (variant >= 5691738) {
          if (variant >= 5745035) {
            return "calc(" + (Plate$BwaxMobile.Str.join(" + ", Plate$BwaxMobile.List.map(toString, param[1])) + ")");
          } else {
            return param[1].toString() + "rem";
          }
        } else if (variant >= 26433) {
          return param[1].toString() + "vw";
        } else {
          return param[1].toString() + "vh";
        }
      } else if (variant >= 25096) {
        return param[1].toString() + "px";
      } else {
        return param[1].toString() + "em";
      }
    } else if (variant !== -1040484748) {
      if (variant >= -833470756) {
        if (variant >= -119887163) {
          return param[1].toString() + "%";
        } else {
          return param[1].toString() + "vmin";
        }
      } else if (variant >= -833472530) {
        return param[1].toString() + "vmax";
      } else {
        var match = param[1];
        if (match[0] >= 5745024) {
          return "calc(" + (toString(match[1]) + (" - " + (toString(match[2]) + ")")));
        } else {
          return "calc(" + (toString(match[1]) + (" + " + (toString(match[2]) + ")")));
        }
      }
    } else {
      return param[1].toString() + "px";
    }
  }
}

function divBy(length, divider) {
  if (typeof length === "number") {
    return /* zero */-789508312;
  } else {
    var variant = length[0];
    if (variant >= 22632) {
      if (variant >= 26418) {
        if (variant >= 5691738) {
          if (variant >= 5745035) {
            return /* `sum */[
                    5745035,
                    Plate$BwaxMobile.List.map((function (e) {
                            return divBy(e, divider);
                          }), length[1])
                  ];
          } else {
            return /* `rem */[
                    5691738,
                    length[1] / divider
                  ];
          }
        } else if (variant >= 26433) {
          return /* `vw */[
                  26433,
                  length[1] / divider
                ];
        } else {
          return /* `vh */[
                  26418,
                  length[1] / divider
                ];
        }
      } else if (variant >= 25096) {
        return /* `px */[
                25096,
                length[1] / divider | 0
              ];
      } else {
        return /* `em */[
                22632,
                length[1] / divider
              ];
      }
    } else if (variant !== -1040484748) {
      if (variant >= -833470756) {
        if (variant >= -119887163) {
          return /* `percent */[
                  -119887163,
                  length[1] / divider
                ];
        } else {
          return /* `vmin */[
                  -833470756,
                  length[1] / divider
                ];
        }
      } else if (variant >= -833472530) {
        return /* `vmax */[
                -833472530,
                length[1] / divider
              ];
      } else {
        var match = length[1];
        if (match[0] >= 5745024) {
          return /* `calc */[
                  -1044768619,
                  /* tuple */[
                    /* sub */5745024,
                    divBy(match[1], divider),
                    divBy(match[2], divider)
                  ]
                ];
        } else {
          return /* `calc */[
                  -1044768619,
                  /* tuple */[
                    /* add */4846113,
                    divBy(match[1], divider),
                    divBy(match[2], divider)
                  ]
                ];
        }
      }
    } else {
      return /* `pxFloat */[
              -1040484748,
              length[1] / divider
            ];
    }
  }
}

function multiply(length, times) {
  if (typeof length === "number") {
    return /* zero */-789508312;
  } else {
    var variant = length[0];
    if (variant >= 22632) {
      if (variant >= 26418) {
        if (variant >= 5691738) {
          if (variant >= 5745035) {
            return /* `sum */[
                    5745035,
                    Plate$BwaxMobile.List.map((function (e) {
                            return multiply(e, times);
                          }), length[1])
                  ];
          } else {
            return /* `rem */[
                    5691738,
                    length[1] * times
                  ];
          }
        } else if (variant >= 26433) {
          return /* `vw */[
                  26433,
                  length[1] * times
                ];
        } else {
          return /* `vh */[
                  26418,
                  length[1] * times
                ];
        }
      } else if (variant >= 25096) {
        return /* `px */[
                25096,
                length[1] * times | 0
              ];
      } else {
        return /* `em */[
                22632,
                length[1] * times
              ];
      }
    } else if (variant !== -1040484748) {
      if (variant >= -833470756) {
        if (variant >= -119887163) {
          return /* `percent */[
                  -119887163,
                  length[1] * times
                ];
        } else {
          return /* `vmin */[
                  -833470756,
                  length[1] * times
                ];
        }
      } else if (variant >= -833472530) {
        return /* `vmax */[
                -833472530,
                length[1] * times
              ];
      } else {
        var match = length[1];
        if (match[0] >= 5745024) {
          return /* `calc */[
                  -1044768619,
                  /* tuple */[
                    /* sub */5745024,
                    multiply(match[1], times),
                    multiply(match[2], times)
                  ]
                ];
        } else {
          return /* `calc */[
                  -1044768619,
                  /* tuple */[
                    /* add */4846113,
                    multiply(match[1], times),
                    multiply(match[2], times)
                  ]
                ];
        }
      }
    } else {
      return /* `pxFloat */[
              -1040484748,
              length[1] * times
            ];
    }
  }
}

function negative(length) {
  if (typeof length === "number") {
    return /* zero */-789508312;
  } else {
    var variant = length[0];
    if (variant >= 22632) {
      if (variant >= 26418) {
        if (variant >= 5691738) {
          if (variant >= 5745035) {
            return /* `sum */[
                    5745035,
                    Plate$BwaxMobile.List.map(negative, length[1])
                  ];
          } else {
            return /* `rem */[
                    5691738,
                    0.0 - length[1]
                  ];
          }
        } else if (variant >= 26433) {
          return /* `vw */[
                  26433,
                  0.0 - length[1]
                ];
        } else {
          return /* `vh */[
                  26418,
                  0.0 - length[1]
                ];
        }
      } else if (variant >= 25096) {
        return /* `px */[
                25096,
                0.0 - length[1] | 0
              ];
      } else {
        return /* `em */[
                22632,
                0.0 - length[1]
              ];
      }
    } else if (variant !== -1040484748) {
      if (variant >= -833470756) {
        if (variant >= -119887163) {
          return /* `percent */[
                  -119887163,
                  0.0 - length[1]
                ];
        } else {
          return /* `vmin */[
                  -833470756,
                  0.0 - length[1]
                ];
        }
      } else if (variant >= -833472530) {
        return /* `vmax */[
                -833472530,
                0.0 - length[1]
              ];
      } else {
        var match = length[1];
        if (match[0] >= 5745024) {
          return /* `calc */[
                  -1044768619,
                  /* tuple */[
                    /* sub */5745024,
                    negative(match[1]),
                    negative(match[2])
                  ]
                ];
        } else {
          return /* `calc */[
                  -1044768619,
                  /* tuple */[
                    /* add */4846113,
                    negative(match[1]),
                    negative(match[2])
                  ]
                ];
        }
      }
    } else {
      return /* `pxFloat */[
              -1040484748,
              0.0 - length[1]
            ];
    }
  }
}

var Length = {
  toString: toString,
  divBy: divBy,
  multiply: multiply,
  negative: negative
};

function strToLength(str) {
  var withRem = (/(^\d+(\.\d+)?)rem$/);
  var withPx = (/^(\d+(\.\d+)?)px$/);
  var withPct = (/(^\d+(\.\d+)?)%$/);
  var isZero = (/^(0)$/);
  var testAndGet = function (str, pattern, buildValue) {
    return Belt_Option.flatMap(StringUtils$BwaxMobile.testAndGet(str, pattern, 1), buildValue);
  };
  var maybeRem = function (param) {
    return testAndGet(str, withRem, (function (x) {
                  return Belt_Option.map(StringUtils$BwaxMobile.toFloat(x), Css.rem);
                }));
  };
  var maybePx = function (param) {
    return testAndGet(str, withPx, (function (x) {
                  return Belt_Option.map(StringUtils$BwaxMobile.toInt(x), Css.px);
                }));
  };
  var maybePct = function (param) {
    return testAndGet(str, withPct, (function (x) {
                  return Belt_Option.map(StringUtils$BwaxMobile.toFloat(x), Css.pct);
                }));
  };
  var maybeZero = function (param) {
    return testAndGet(str, isZero, (function (param) {
                  return Css.rem(0.0);
                }));
  };
  return grabFirst(/* :: */[
              maybeRem,
              /* :: */[
                maybePx,
                /* :: */[
                  maybePct,
                  /* :: */[
                    maybeZero,
                    /* [] */0
                  ]
                ]
              ]
            ]);
}

function applyLength(name, l) {
  return Css.unsafe(name, toString(l));
}

function marginTop(param) {
  return Css.unsafe("marginTop", toString(param));
}

function marginBottom(param) {
  return Css.unsafe("marginBottom", toString(param));
}

function marginRight(param) {
  return Css.unsafe("marginRight", toString(param));
}

function marginLeft(param) {
  return Css.unsafe("marginLeft", toString(param));
}

function marginXY(xl, yl) {
  return Css.unsafe("margin", toString(yl) + (" " + toString(xl)));
}

function marginEach(t, r, b, l) {
  return Css.unsafe("margin", StringUtils$BwaxMobile.join(Belt_List.map(/* :: */[
                      t,
                      /* :: */[
                        r,
                        /* :: */[
                          b,
                          /* :: */[
                            l,
                            /* [] */0
                          ]
                        ]
                      ]
                    ], toString), " "));
}

function margin(param) {
  return Css.unsafe("margin", toString(param));
}

function padding(param) {
  return Css.unsafe("padding", toString(param));
}

function paddingLeft(param) {
  return Css.unsafe("paddingLeft", toString(param));
}

function paddingRight(param) {
  return Css.unsafe("paddingRight", toString(param));
}

function paddingTop(param) {
  return Css.unsafe("paddingTop", toString(param));
}

function paddingBottom(param) {
  return Css.unsafe("paddingBottom", toString(param));
}

function maxWidth(param) {
  return Css.unsafe("maxWidth", toString(param));
}

function minWidth(param) {
  return Css.unsafe("minWidth", toString(param));
}

function maxHeight(param) {
  return Css.unsafe("maxHeight", toString(param));
}

function minHeight(param) {
  return Css.unsafe("minHeight", toString(param));
}

function lineHeight(param) {
  return Css.unsafe("lineHeight", toString(param));
}

function borderWidth(param) {
  return Css.unsafe("borderWidth", toString(param));
}

function borderTopWidth(param) {
  return Css.unsafe("borderTopWidth", toString(param));
}

function borderRightWidth(param) {
  return Css.unsafe("borderRightWidth", toString(param));
}

function borderBottomWidth(param) {
  return Css.unsafe("borderBottomWidth", toString(param));
}

function borderLeftWidth(param) {
  return Css.unsafe("borderLeftWidth", toString(param));
}

function borderRadius(param) {
  return Css.unsafe("borderRadius", toString(param));
}

function borderTopLeftRadius(param) {
  return Css.unsafe("borderTopLeftRadius", toString(param));
}

function borderTopRightRadius(param) {
  return Css.unsafe("borderTopRightRadius", toString(param));
}

function borderBottomRightRadius(param) {
  return Css.unsafe("borderBottomRightRadius", toString(param));
}

function borderBottomLeftRadius(param) {
  return Css.unsafe("borderBottomLeftRadius", toString(param));
}

function fontSize(param) {
  return Css.unsafe("fontSize", toString(param));
}

function letterSpacing(param) {
  return Css.unsafe("letterSpacing", toString(param));
}

function wordSpacing(param) {
  return Css.unsafe("wordSpacing", toString(param));
}

function textIndent(param) {
  return Css.unsafe("textIndent", toString(param));
}

function width(param) {
  return Css.unsafe("width", toString(param));
}

function height(param) {
  return Css.unsafe("height", toString(param));
}

function top(param) {
  return Css.unsafe("top", toString(param));
}

function bottom(param) {
  return Css.unsafe("bottom", toString(param));
}

function right(param) {
  return Css.unsafe("right", toString(param));
}

function left(param) {
  return Css.unsafe("left", toString(param));
}

function padXY(xl, yl) {
  return Css.unsafe("padding", toString(yl) + (" " + toString(xl)));
}

function padEach(t, r, b, l) {
  return Css.unsafe("padding", StringUtils$BwaxMobile.join(Belt_List.map(/* :: */[
                      t,
                      /* :: */[
                        r,
                        /* :: */[
                          b,
                          /* :: */[
                            l,
                            /* [] */0
                          ]
                        ]
                      ]
                    ], toString), " "));
}

var LimitedCss = {
  applyLength: applyLength,
  marginTop: marginTop,
  marginBottom: marginBottom,
  marginRight: marginRight,
  marginLeft: marginLeft,
  marginXY: marginXY,
  marginEach: marginEach,
  margin: margin,
  padding: padding,
  paddingLeft: paddingLeft,
  paddingRight: paddingRight,
  paddingTop: paddingTop,
  paddingBottom: paddingBottom,
  maxWidth: maxWidth,
  minWidth: minWidth,
  maxHeight: maxHeight,
  minHeight: minHeight,
  lineHeight: lineHeight,
  borderWidth: borderWidth,
  borderTopWidth: borderTopWidth,
  borderRightWidth: borderRightWidth,
  borderBottomWidth: borderBottomWidth,
  borderLeftWidth: borderLeftWidth,
  borderRadius: borderRadius,
  borderTopLeftRadius: borderTopLeftRadius,
  borderTopRightRadius: borderTopRightRadius,
  borderBottomRightRadius: borderBottomRightRadius,
  borderBottomLeftRadius: borderBottomLeftRadius,
  fontSize: fontSize,
  letterSpacing: letterSpacing,
  wordSpacing: wordSpacing,
  textIndent: textIndent,
  width: width,
  height: height,
  top: top,
  bottom: bottom,
  right: right,
  left: left,
  padXY: padXY,
  padEach: padEach
};

var presetPX = /* :: */[
  4,
  /* :: */[
    8,
    /* :: */[
      12,
      /* :: */[
        16,
        /* :: */[
          20,
          /* :: */[
            24,
            /* :: */[
              32,
              /* [] */0
            ]
          ]
        ]
      ]
    ]
  ]
];

var presetREM = /* :: */[
  0.25,
  /* :: */[
    0.5,
    /* :: */[
      0.75,
      /* :: */[
        1.0,
        /* :: */[
          1.25,
          /* :: */[
            1.5,
            /* :: */[
              2.0,
              /* [] */0
            ]
          ]
        ]
      ]
    ]
  ]
];

function isSpecialSpacing(length) {
  if (typeof length === "number") {
    return false;
  } else {
    var variant = length[0];
    if (variant !== 25096) {
      if (variant !== 5691738) {
        return false;
      } else {
        var l = length[1];
        return Belt_List.some(presetREM, (function (r) {
                      return r === l;
                    }));
      }
    } else {
      var l$1 = length[1];
      return Belt_List.some(presetPX, (function (p) {
                    return p === l$1;
                  }));
    }
  }
}

function findSpacingLength(rules) {
  var spacingLengths = Belt_List.keepMap(rules, (function (r) {
          if (typeof r === "number" || r[0] !== 297481091) {
            return ;
          } else {
            return r[1];
          }
        }));
  return Belt_List.get(spacingLengths, Belt_List.length(spacingLengths) - 1 | 0);
}

function ruleToCss(rule, theme, current, pc, prs) {
  var getColor = function (color) {
    return BwaxUi.themeColor(theme, ColorTypes$BwaxMobile.toString(color));
  };
  var findSpacingLength = function (rules) {
    var spacingLengths = Belt_List.keepMap(rules, (function (r) {
            if (typeof r === "number" || r[0] !== 297481091) {
              return ;
            } else {
              return r[1];
            }
          }));
    return Belt_List.get(spacingLengths, Belt_List.length(spacingLengths) - 1 | 0);
  };
  var parentSpacing = findSpacingLength(prs);
  var getShadowInfo = function (actualShadow) {
    var offset = actualShadow[/* offset */0];
    var offsetX = offset[0].toString() + "px";
    var offsetY = offset[1].toString() + "px";
    var db = actualShadow[/* blur */2].toString() + "px";
    var ss = actualShadow[/* size */1].toString() + "px";
    var actualColor = BwaxUi.themeColor(theme, ColorTypes$BwaxMobile.toString(actualShadow[/* color */3]));
    return "" + (String(offsetX) + (" " + (String(offsetY) + (" " + (String(db) + (" " + (String(ss) + (" " + (String(actualColor) + "")))))))));
  };
  var layoutRuleToCss = function (rule) {
    if (typeof rule === "number") {
      if (rule >= -196635760) {
        if (rule >= 434500835) {
          if (rule !== 434500836) {
            if (rule >= 776515163) {
              if (rule >= 790228983) {
                if (pc !== -590209170 && pc < 5693978) {
                  return /* :: */[
                          Css.flexGrow(100000.0),
                          /* :: */[
                            Css.height(/* zero */-789508312),
                            /* :: */[
                              Css.overflowY(/* auto */-1065951377),
                              /* :: */[
                                Css.pointerEvents(/* auto */-1065951377),
                                /* [] */0
                              ]
                            ]
                          ]
                        ];
                }
                return /* :: */[
                        Css.height(Css.pct(100.0)),
                        /* :: */[
                          Css.maxHeight(Css.pct(100.0)),
                          /* :: */[
                            Css.minHeight(Css.pct(100.0)),
                            /* :: */[
                              Css.overflowY(/* auto */-1065951377),
                              /* :: */[
                                Css.pointerEvents(/* auto */-1065951377),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ];
              } else {
                return /* :: */[
                        Css.important(Css.position(Css.fixed)),
                        /* :: */[
                          Css.top(Css.px(0)),
                          /* :: */[
                            Css.bottom(Css.px(0)),
                            /* :: */[
                              Css.left(Css.px(0)),
                              /* :: */[
                                Css.right(Css.px(0)),
                                /* :: */[
                                  Css.zIndex(5000),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ]
                      ];
              }
            } else if (rule >= 531263392) {
              return /* :: */[
                      Css.important(Css.alignItems(/* end_ */-1021944796)),
                      /* [] */0
                    ];
            } else {
              return /* :: */[
                      Css.important(Css.alignSelf(/* center */98248149)),
                      /* [] */0
                    ];
            }
          } else if (pc !== 4898315) {
            if (pc >= 5693978) {
              return /* :: */[
                      Css.alignSelf(/* center */98248149),
                      /* [] */0
                    ];
            } else {
              return /* [] */0;
            }
          } else {
            return /* :: */[
                    Css.important(Css.marginTop(/* auto */-1065951377)),
                    /* :: */[
                      Css.important(Css.marginBottom(/* auto */-1065951377)),
                      /* [] */0
                    ]
                  ];
          }
        } else if (rule >= 79394430) {
          if (rule >= 345286304) {
            return /* :: */[
                    Css.important(Css.alignItems(/* baseline */287825029)),
                    /* [] */0
                  ];
          } else {
            return /* :: */[
                    Css.important(Css.justifyContent(/* center */98248149)),
                    /* [] */0
                  ];
          }
        } else if (rule >= 45513431) {
          if (pc !== -590209170 && pc < 5693978) {
            return /* :: */[
                    Css.important(Css.alignSelf(/* flexEnd */924268066)),
                    /* [] */0
                  ];
          } else {
            return /* [] */0;
          }
        } else if (pc !== -590209170 && pc >= 5693978) {
          return /* :: */[
                  Css.important(Css.alignSelf(/* flexEnd */924268066)),
                  /* :: */[
                    Css.marginTop(/* auto */-1065951377),
                    /* [] */0
                  ]
                ];
        } else {
          return /* [] */0;
        }
      } else if (rule >= -731000468) {
        if (rule !== -701337526) {
          if (rule >= -626416848) {
            if (rule >= -346313767) {
              return /* :: */[
                      Css.important(Css.justifyContent(/* start */67859554)),
                      /* [] */0
                    ];
            } else {
              return /* :: */[
                      Css.important(Css.alignItems(/* center */98248149)),
                      /* [] */0
                    ];
            }
          } else if (rule >= -680157294) {
            return /* :: */[
                    Css.important(Css.justifyContent(/* end_ */-1021944796)),
                    /* [] */0
                  ];
          } else if (pc !== -590209170 && pc < 5693978) {
            return /* :: */[
                    Css.important(Css.alignSelf(/* flexStart */662439529)),
                    /* [] */0
                  ];
          } else {
            return /* [] */0;
          }
        } else {
          return /* :: */[
                  Css.important(Css.alignItems(/* stretch */-162316795)),
                  /* [] */0
                ];
        }
      } else if (rule >= -908095280) {
        if (rule >= -862584982) {
          return /* :: */[
                  Css.important(Css.display(Css.none)),
                  /* [] */0
                ];
        } else if (pc !== -590209170 && pc >= 5693978) {
          return /* :: */[
                  Css.important(Css.alignSelf(/* flexStart */662439529)),
                  /* [] */0
                ];
        } else {
          return /* [] */0;
        }
      } else if (rule >= -965797017) {
        return /* :: */[
                Css.important(Css.alignItems(/* start */67859554)),
                /* [] */0
              ];
      } else {
        return /* :: */[
                Css.justifyContent(/* spaceBetween */516682146),
                /* [] */0
              ];
      }
    } else {
      var variant = rule[0];
      if (variant >= 10615156) {
        if (variant >= 416026680) {
          if (variant >= 836180395) {
            if (variant >= 973471044) {
              if (variant >= 980250670) {
                return /* :: */[
                        Css.important(Css.unsafe("margin", toString(rule[1]))),
                        /* [] */0
                      ];
              } else if (current >= 9) {
                console.log("warning: inputText doesn't support paddingTop");
                return /* [] */0;
              } else {
                return /* :: */[
                        Css.unsafe("paddingTop", toString(rule[1])),
                        /* [] */0
                      ];
              }
            } else if (variant >= 869835982) {
              return /* :: */[
                      Css.important(Css.unsafe("marginRight", toString(rule[1]))),
                      /* [] */0
                    ];
            } else {
              return /* :: */[
                      Css.important(Css.unsafe("maxHeight", toString(rule[1]))),
                      /* [] */0
                    ];
            }
          } else if (variant >= 447212619) {
            if (current >= 9) {
              console.log("warning: inputText doesn't support paddingRight");
              return /* [] */0;
            } else {
              return /* :: */[
                      Css.unsafe("paddingRight", toString(rule[1])),
                      /* [] */0
                    ];
            }
          } else {
            return /* :: */[
                    Css.zIndex(rule[1]),
                    /* [] */0
                  ];
          }
        } else if (variant >= 38537191) {
          if (variant >= 98978424) {
            if (variant >= 297481091) {
              var length = rule[1];
              switch (current) {
                case /* Item_textarea */0 :
                    var lengthStr = toString(length);
                    var lh = "calc(1em + " + (String(lengthStr) + ")");
                    var h = "calc(100% + " + (String(lengthStr) + ")");
                    var transform = "translate3d(0px, calc(-" + (String(lengthStr) + " / 2), 0px)");
                    return /* :: */[
                            Css.unsafe("lineHeight", lh),
                            /* :: */[
                              Css.unsafe("height", h),
                              /* :: */[
                                Css.unsafe("transform", transform),
                                /* [] */0
                              ]
                            ]
                          ];
                case /* Item_row */1 :
                    var match = isSpecialSpacing(length);
                    if (match) {
                      return /* [] */0;
                    } else {
                      var rules_000 = Css.selector("> :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden)", /* :: */[
                            Css.unsafe("marginLeft", toString(length)),
                            /* [] */0
                          ]);
                      var rules_001 = /* :: */[
                        Css.selector("> :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden)", /* :: */[
                              Css.unsafe("marginLeft", toString(length)),
                              /* [] */0
                            ]),
                        /* :: */[
                          Css.selector("> :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden)", /* :: */[
                                Css.unsafe("marginLeft", toString(length)),
                                /* [] */0
                              ]),
                          /* :: */[
                            Css.selector("> :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden)", /* :: */[
                                  Css.unsafe("marginLeft", toString(length)),
                                  /* [] */0
                                ]),
                            /* [] */0
                          ]
                        ]
                      ];
                      var rules = /* :: */[
                        rules_000,
                        rules_001
                      ];
                      var rules_000$1 = Css.selector("> :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden)", /* :: */[
                            Css.unsafe("marginLeft", toString(length)),
                            /* [] */0
                          ]);
                      var rules_001$1 = /* :: */[
                        Css.selector("> :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden)", /* :: */[
                              Css.unsafe("marginLeft", toString(length)),
                              /* [] */0
                            ]),
                        /* :: */[
                          Css.selector("> :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden)", /* :: */[
                                Css.unsafe("marginLeft", toString(length)),
                                /* [] */0
                              ]),
                          /* :: */[
                            Css.selector("> :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden)", /* :: */[
                                  Css.unsafe("marginLeft", toString(length)),
                                  /* [] */0
                                ]),
                            /* [] */0
                          ]
                        ]
                      ];
                      var rules$1 = /* :: */[
                        rules_000$1,
                        rules_001$1
                      ];
                      return /* :: */[
                              Css.media("(min-width: 640px)", rules),
                              /* :: */[
                                Css.media("(max-width: 639px)", rules$1),
                                /* [] */0
                              ]
                            ];
                    }
                case /* Item_column */2 :
                    var match$1 = isSpecialSpacing(length);
                    if (match$1) {
                      return /* [] */0;
                    } else {
                      var rules_000$2 = Css.selector("> :not(.wHdn):not(.nb):not(style):not(.hidden) + :not(.wHdn):not(.nb):not(style):not(.hidden)", /* :: */[
                            Css.unsafe("marginTop", toString(length)),
                            /* [] */0
                          ]);
                      var rules_001$2 = /* :: */[
                        Css.selector("> :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.wHdn):not(.nb):not(style):not(.hidden)", /* :: */[
                              Css.unsafe("marginTop", toString(length)),
                              /* [] */0
                            ]),
                        /* :: */[
                          Css.selector("> :not(.wHdn):not(.nb):not(style):not(.hidden) ~ .wHdn + :not(.wHdn):not(.nb):not(style):not(.hidden)", /* :: */[
                                Css.unsafe("marginTop", toString(length)),
                                /* [] */0
                              ]),
                          /* :: */[
                            Css.selector("> :not(.wHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.wHdn):not(.nb):not(style):not(.hidden)", /* :: */[
                                  Css.unsafe("marginTop", toString(length)),
                                  /* [] */0
                                ]),
                            /* [] */0
                          ]
                        ]
                      ];
                      var rules$2 = /* :: */[
                        rules_000$2,
                        rules_001$2
                      ];
                      var rules_000$3 = Css.selector("> :not(.nHdn):not(.nb):not(style):not(.hidden) + :not(.nHdn):not(.nb):not(style):not(.hidden)", /* :: */[
                            Css.unsafe("marginTop", toString(length)),
                            /* [] */0
                          ]);
                      var rules_001$3 = /* :: */[
                        Css.selector("> :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .hidden + :not(.nHdn):not(.nb):not(style):not(.hidden)", /* :: */[
                              Css.unsafe("marginTop", toString(length)),
                              /* [] */0
                            ]),
                        /* :: */[
                          Css.selector("> :not(.nHdn):not(.nb):not(style):not(.hidden) ~ .nHdn + :not(.nHdn):not(.nb):not(style):not(.hidden)", /* :: */[
                                Css.unsafe("marginTop", toString(length)),
                                /* [] */0
                              ]),
                          /* :: */[
                            Css.selector("> :not(.nHdn):not(.nb):not(style):not(.hidden) ~ style + :not(.nHdn):not(.nb):not(style):not(.hidden)", /* :: */[
                                  Css.unsafe("marginTop", toString(length)),
                                  /* [] */0
                                ]),
                            /* [] */0
                          ]
                        ]
                      ];
                      var rules$3 = /* :: */[
                        rules_000$3,
                        rules_001$3
                      ];
                      return /* :: */[
                              Css.media("(min-width: 640px)", rules$2),
                              /* :: */[
                                Css.media("(max-width: 639px)", rules$3),
                                /* [] */0
                              ]
                            ];
                    }
                case /* Item_paragraph */4 :
                    var lengthStr$1 = toString(length);
                    var lh$1 = "calc(1em + " + (String(lengthStr$1) + ")");
                    var m = "calc(0px - " + (String(lengthStr$1) + " / 2)");
                    return /* :: */[
                            Css.selector("&::before", /* :: */[
                                  Css.unsafe("content", ""),
                                  /* :: */[
                                    Css.width(/* zero */-789508312),
                                    /* :: */[
                                      Css.height(/* zero */-789508312),
                                      /* :: */[
                                        Css.display(/* block */888960333),
                                        /* :: */[
                                          Css.unsafe("marginBottom", m),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ]),
                            /* :: */[
                              Css.important(Css.unsafe("lineHeight", lh$1)),
                              /* :: */[
                                Css.selector("&::after", /* :: */[
                                      Css.unsafe("content", ""),
                                      /* :: */[
                                        Css.width(/* zero */-789508312),
                                        /* :: */[
                                          Css.height(/* zero */-789508312),
                                          /* :: */[
                                            Css.display(/* block */888960333),
                                            /* :: */[
                                              Css.unsafe("marginTop", m),
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]),
                                /* [] */0
                              ]
                            ]
                          ];
                case /* Item_wrappedRow */8 :
                    var l = negative(length);
                    var l$1 = negative(length);
                    return /* :: */[
                            Css.selector("> *", /* :: */[
                                  Css.unsafe("marginRight", toString(length)),
                                  /* :: */[
                                    Css.unsafe("marginBottom", toString(length)),
                                    /* [] */0
                                  ]
                                ]),
                            /* :: */[
                              Css.unsafe("marginBottom", toString(l)),
                              /* :: */[
                                Css.unsafe("marginRight", toString(l$1)),
                                /* [] */0
                              ]
                            ]
                          ];
                case /* Item_box */3 :
                case /* Item_text */5 :
                case /* Item_image */6 :
                case /* Item_richtext */7 :
                case /* Item_inputText */9 :
                    return /* [] */0;
                
              }
            } else if (current >= 9) {
              console.log("warning: inputText doesn't support paddingLeft");
              return /* [] */0;
            } else {
              return /* :: */[
                      Css.unsafe("paddingLeft", toString(rule[1])),
                      /* [] */0
                    ];
            }
          } else if (variant >= 81613653) {
            return /* :: */[
                    Css.important(Css.unsafe("marginLeft", toString(rule[1]))),
                    /* [] */0
                  ];
          } else {
            var l$2 = rule[1];
            if (typeof l$2 === "number") {
              if (l$2 < -789508312) {
                if (pc !== -590209170) {
                  if (pc >= 5693978) {
                    return /* :: */[
                            Css.unsafe("alignSelf", "stretch !important"),
                            /* :: */[
                              Css.height(Css.pct(100.0)),
                              /* :: */[
                                Css.maxHeight(Css.pct(100.0)),
                                /* :: */[
                                  Css.minHeight(Css.pct(100.0)),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ];
                  } else {
                    return /* :: */[
                            Css.flexGrow(100000.0),
                            /* :: */[
                              Css.maxHeight(Css.pct(100.0)),
                              /* [] */0
                            ]
                          ];
                  }
                } else {
                  var l$3 = Css.pct(100.0);
                  return /* :: */[
                          Css.unsafe("height", toString(l$3)),
                          /* [] */0
                        ];
                }
              }
              
            } else {
              var variant$1 = l$2[0];
              if (variant$1 !== -467389173) {
                if (variant$1 >= 658993316) {
                  if (pc !== -590209170) {
                    if (pc >= 5693978) {
                      return /* :: */[
                              Css.unsafe("alignSelf", "stretch !important"),
                              /* [] */0
                            ];
                    } else {
                      return /* :: */[
                              Css.flexGrow(l$2[1] * 100000.0),
                              /* [] */0
                            ];
                    }
                  } else {
                    var l$4 = Css.pct(100.0);
                    return /* :: */[
                            Css.unsafe("height", toString(l$4)),
                            /* [] */0
                          ];
                  }
                }
                
              } else {
                return /* :: */[
                        Css.height(/* zero */-789508312),
                        /* :: */[
                          Css.paddingBottom(Css.pct(l$2[1])),
                          /* [] */0
                        ]
                      ];
              }
            }
            return /* :: */[
                    Css.important(Css.unsafe("height", toString(l$2))),
                    /* :: */[
                      Css.important(Css.unsafe("maxHeight", toString(l$2))),
                      /* :: */[
                        Css.important(Css.unsafe("minHeight", toString(l$2))),
                        /* [] */0
                      ]
                    ]
                  ];
          }
        } else if (variant >= 21151858) {
          var match$2 = rule[1];
          if (current >= 9) {
            console.log("warning: inputText doesn't support paddingEach");
            return /* [] */0;
          } else {
            return /* :: */[
                    padEach(Plate$BwaxMobile.$$Option.with_default(/* zero */-789508312, match$2[/* top */0]), Plate$BwaxMobile.$$Option.with_default(/* zero */-789508312, match$2[/* right */1]), Plate$BwaxMobile.$$Option.with_default(/* zero */-789508312, match$2[/* bottom */2]), Plate$BwaxMobile.$$Option.with_default(/* zero */-789508312, match$2[/* left */3])),
                    /* [] */0
                  ];
          }
        } else {
          var match$3 = rule[1];
          return Plate$BwaxMobile.List.clean(/* :: */[
                      Caml_option.some(Css.important(Css.position(/* fixed */10615156))),
                      /* :: */[
                        Plate$BwaxMobile.$$Option.map(top, match$3[/* top */0]),
                        /* :: */[
                          Plate$BwaxMobile.$$Option.map(right, match$3[/* right */1]),
                          /* :: */[
                            Plate$BwaxMobile.$$Option.map(bottom, match$3[/* bottom */2]),
                            /* :: */[
                              Plate$BwaxMobile.$$Option.map(left, match$3[/* left */3]),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]);
        }
      } else if (variant >= -611055847) {
        if (variant >= -297763065) {
          if (variant >= -37494765) {
            if (variant >= 3787087) {
              var match$4 = rule[1];
              return /* :: */[
                      Css.important(marginEach(Plate$BwaxMobile.$$Option.with_default(/* zero */-789508312, match$4[/* top */0]), Plate$BwaxMobile.$$Option.with_default(/* zero */-789508312, match$4[/* right */1]), Plate$BwaxMobile.$$Option.with_default(/* zero */-789508312, match$4[/* bottom */2]), Plate$BwaxMobile.$$Option.with_default(/* zero */-789508312, match$4[/* left */3]))),
                      /* [] */0
                    ];
            } else {
              var r = rule[1];
              if (CssCompatibility.supports("aspect-ratio", r)) {
                return /* :: */[
                        Css.unsafe("aspectRatio", r.toString()),
                        /* [] */0
                      ];
              } else {
                return /* :: */[
                        Css.before(/* :: */[
                              Css.$$float(/* left */-944764921),
                              /* :: */[
                                Css.paddingTop(Css.pct(100.0 * (1 / r))),
                                /* :: */[
                                  Css.unsafe("content", ""),
                                  /* [] */0
                                ]
                              ]
                            ]),
                        /* :: */[
                          Css.after(/* :: */[
                                Css.display(/* block */888960333),
                                /* :: */[
                                  Css.unsafe("content", ""),
                                  /* :: */[
                                    Css.clear(/* both */-1055160191),
                                    /* [] */0
                                  ]
                                ]
                              ]),
                          /* [] */0
                        ]
                      ];
              }
            }
          } else if (variant >= -192935742) {
            return /* :: */[
                    Css.important(Css.unsafe("maxWidth", toString(rule[1]))),
                    /* [] */0
                  ];
          } else {
            return /* :: */[
                    Css.important(Css.unsafe("marginTop", toString(rule[1]))),
                    /* [] */0
                  ];
          }
        } else if (variant >= -303792878) {
          var match$5 = rule[1];
          var y = match$5[1];
          var x = match$5[0];
          if (current >= 9) {
            var yStr = toString(y);
            var lh$2 = "calc(1em + " + (String(yStr) + " * 2)");
            return /* :: */[
                    Css.unsafe("lineHeight", lh$2),
                    /* :: */[
                      Css.unsafe("height", lh$2),
                      /* :: */[
                        Css.unsafe("paddingLeft", toString(x)),
                        /* :: */[
                          Css.unsafe("paddingRight", toString(x)),
                          /* [] */0
                        ]
                      ]
                    ]
                  ];
          } else {
            return /* :: */[
                    padXY(x, y),
                    /* [] */0
                  ];
          }
        } else {
          return /* :: */[
                  Css.important(Css.unsafe("minHeight", toString(rule[1]))),
                  /* [] */0
                ];
        }
      } else if (variant >= -993221457) {
        if (variant >= -812030052) {
          if (variant >= -797091375) {
            var length$1 = rule[1];
            if (current !== 0) {
              if (current >= 9) {
                var lengthStr$2 = toString(length$1);
                var lh$3 = "calc(1em + " + (String(lengthStr$2) + " * 2)");
                return /* :: */[
                        Css.unsafe("lineHeight", lh$3),
                        /* :: */[
                          Css.unsafe("height", lh$3),
                          /* :: */[
                            Css.unsafe("paddingLeft", toString(length$1)),
                            /* :: */[
                              Css.unsafe("paddingRight", toString(length$1)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ];
              } else {
                return /* :: */[
                        Css.unsafe("padding", toString(length$1)),
                        /* [] */0
                      ];
              }
            } else {
              var lengthStr$3 = toString(length$1);
              return /* :: */[
                      Css.unsafe("margin", "-" + lengthStr$3),
                      /* :: */[
                        Css.unsafe("padding", toString(length$1)),
                        /* [] */0
                      ]
                    ];
            }
          } else if (current >= 9) {
            console.log("warning: inputText doesn't support paddingBottom");
            return /* [] */0;
          } else {
            return /* :: */[
                    Css.unsafe("paddingBottom", toString(rule[1])),
                    /* [] */0
                  ];
          }
        } else if (variant >= -899500538) {
          var l$5 = rule[1];
          if (typeof l$5 === "number") {
            if (l$5 < -789508312) {
              if (pc >= 5693978) {
                return /* :: */[
                        Css.flexGrow(100000.0),
                        /* [] */0
                      ];
              } else {
                var l$6 = Css.pct(100.0);
                return /* :: */[
                        Css.unsafe("width", toString(l$6)),
                        /* [] */0
                      ];
              }
            }
            
          } else if (l$5[0] >= 658993316) {
            if (pc >= 5693978) {
              return /* :: */[
                      Css.flexGrow(l$5[1] * 100000.0),
                      /* [] */0
                    ];
            } else {
              return /* :: */[
                      Css.width(Css.pct(100.0)),
                      /* [] */0
                    ];
            }
          }
          return /* :: */[
                  Css.important(Css.unsafe("width", toString(l$5))),
                  /* :: */[
                    Css.unsafe("maxWidth", toString(l$5)),
                    /* :: */[
                      Css.unsafe("minWidth", toString(l$5)),
                      /* [] */0
                    ]
                  ]
                ];
        } else {
          var match$6 = rule[1];
          return /* :: */[
                  Css.important(marginXY(match$6[0], match$6[1])),
                  /* [] */0
                ];
        }
      } else if (variant >= -1046863084) {
        return /* :: */[
                Css.important(Css.unsafe("minWidth", toString(rule[1]))),
                /* [] */0
              ];
      } else {
        return /* :: */[
                Css.important(Css.unsafe("marginBottom", toString(rule[1]))),
                /* [] */0
              ];
      }
    }
  };
  var textRuleToCss = function (rule) {
    if (typeof rule === "number") {
      if (rule >= 201352959) {
        if (rule !== 233447657) {
          if (rule >= 1028960125) {
            if (rule >= 1063496351) {
              return /* :: */[
                      Css.textDecorationLine(/* underline */131142924),
                      /* [] */0
                    ];
            } else {
              return /* :: */[
                      Css.fontStyle(/* italic */107228912),
                      /* [] */0
                    ];
            }
          } else if (rule >= 1019979362) {
            return /* :: */[
                    Css.textAlign(/* center */98248149),
                    /* [] */0
                  ];
          } else {
            return /* :: */[
                    Css.textAlign(/* left */-944764921),
                    /* [] */0
                  ];
          }
        } else {
          return /* :: */[
                  Css.fontWeight(/* light */-184774442),
                  /* [] */0
                ];
        }
      } else if (rule !== -937384257) {
        if (rule >= -550118877) {
          if (rule >= -345586204) {
            return /* :: */[
                    Css.textAlign(/* right */-379319332),
                    /* [] */0
                  ];
          } else {
            return /* :: */[
                    Css.textAlign(/* justify */62250832),
                    /* [] */0
                  ];
          }
        } else if (rule >= -772109033) {
          return /* :: */[
                  Css.important(Css.whiteSpace(/* preWrap */660870029)),
                  /* [] */0
                ];
        } else {
          return /* :: */[
                  Css.fontWeight(/* `num */[
                        5496390,
                        500
                      ]),
                  /* [] */0
                ];
        }
      } else {
        return /* :: */[
                Css.textDecorationLine(/* lineThrough */412155569),
                /* [] */0
              ];
      }
    } else {
      var variant = rule[0];
      if (variant >= -481689226) {
        if (variant >= 528541484) {
          if (variant >= 904663632) {
            return /* :: */[
                    Css.unsafe("letterSpacing", toString(rule[1])),
                    /* [] */0
                  ];
          } else {
            return /* :: */[
                    Css.unsafe("wordSpacing", toString(rule[1])),
                    /* [] */0
                  ];
          }
        } else if (variant >= 128270816) {
          return /* :: */[
                  Css.fontFamily(rule[1]),
                  /* [] */0
                ];
        } else {
          var actualColor = BwaxUi.themeColor(theme, ColorTypes$BwaxMobile.toString(rule[1]));
          return /* :: */[
                  Css.important(Css.unsafe("color", actualColor)),
                  /* [] */0
                ];
        }
      } else if (variant >= -816909298) {
        if (variant >= -688651525) {
          return /* :: */[
                  Css.unsafe("lineHeight", toString(rule[1])),
                  /* [] */0
                ];
        } else {
          return /* :: */[
                  Css.unsafe("fontSize", toString(rule[1])),
                  /* [] */0
                ];
        }
      } else if (variant >= -891056903) {
        return /* :: */[
                Css.unsafe("textIndent", toString(rule[1])),
                /* [] */0
              ];
      } else {
        var n = rule[1];
        if (current !== 5) {
          console.log("Warn: textLineClamp only works on text");
          return /* [] */0;
        } else if (parentSpacing !== undefined) {
          var str = toString(parentSpacing);
          var height = "calc((" + (String(str) + (" + 1em) * " + (String(n) + " - 1px)")));
          return /* :: */[
                  Css.unsafe("display", "-webkit-box!important"),
                  /* :: */[
                    Css.unsafe("WebkitBoxOrient", "vertical"),
                    /* :: */[
                      Css.unsafe("WebkitLineClamp", String(n)),
                      /* :: */[
                        Css.overflow(/* hidden */-862584982),
                        /* :: */[
                          Css.unsafe("height", height),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ];
        } else {
          console.log("Warn: textLineClamp only works when parent spacing is set");
          return /* [] */0;
        }
      }
    }
  };
  var borderRuleToCss = function (rule) {
    if (typeof rule === "number") {
      if (rule !== 222199711) {
        if (rule >= 258527478) {
          return /* :: */[
                  Css.borderStyle(/* dotted */841979626),
                  /* [] */0
                ];
        } else {
          return /* :: */[
                  Css.borderStyle(/* dashed */568403505),
                  /* [] */0
                ];
        }
      } else {
        return /* :: */[
                Css.borderStyle(/* solid */12956715),
                /* [] */0
              ];
      }
    } else {
      var variant = rule[0];
      if (variant >= -61360200) {
        if (variant !== -34049893) {
          if (variant !== 14553547) {
            if (variant !== 263400904) {
              var match = rule[1];
              var s = match[1];
              var db = (s * 2.0).toString() + "px";
              var ss = s.toString() + "px";
              var actualColor = BwaxUi.themeColor(theme, ColorTypes$BwaxMobile.toString(match[0]));
              if (rule[0] >= 338336312) {
                return /* :: */[
                        Css.unsafe("boxShadow", "0px 0px " + (String(db) + (" " + (String(ss) + (" " + (String(actualColor) + "")))))),
                        /* [] */0
                      ];
              } else {
                return /* :: */[
                        Css.unsafe("boxShadow", "inset 0px 0px " + (String(db) + (" " + (String(ss) + (" " + (String(actualColor) + "")))))),
                        /* [] */0
                      ];
              }
            } else {
              var joinShadows = $$String.concat(", ", Belt_List.map(rule[1], (function (shadow) {
                          var shadowInfo = getShadowInfo(shadow[0]);
                          var st = shadow[1] ? "inset" : "";
                          return "" + (String(st) + (" " + (String(shadowInfo) + "")));
                        })));
              return /* :: */[
                      Css.unsafe("boxShadow", joinShadows),
                      /* [] */0
                    ];
            }
          }
          
        } else {
          var match$1 = rule[1];
          return Plate$BwaxMobile.List.map(Css.important, Plate$BwaxMobile.List.clean(/* :: */[
                          Plate$BwaxMobile.$$Option.map(borderTopWidth, match$1[/* top */0]),
                          /* :: */[
                            Plate$BwaxMobile.$$Option.map(borderRightWidth, match$1[/* right */1]),
                            /* :: */[
                              Plate$BwaxMobile.$$Option.map(borderBottomWidth, match$1[/* bottom */2]),
                              /* :: */[
                                Plate$BwaxMobile.$$Option.map(borderLeftWidth, match$1[/* left */3]),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]));
        }
      } else if (variant !== -979470621) {
        if (variant >= -690257542) {
          if (variant < -586247093) {
            return /* :: */[
                    Css.important(Css.unsafe("borderWidth", toString(rule[1]))),
                    /* [] */0
                  ];
          }
          
        } else if (variant >= -690668329) {
          var actualColor$1 = BwaxUi.themeColor(theme, ColorTypes$BwaxMobile.toString(rule[1]));
          return /* :: */[
                  Css.unsafe("borderColor", actualColor$1),
                  /* [] */0
                ];
        } else {
          return /* :: */[
                  Css.unsafe("borderRadius", toString(rule[1])),
                  /* [] */0
                ];
        }
      } else {
        var match$2 = rule[1];
        return /* :: */[
                Css.unsafe("borderTopLeftRadius", toString(match$2[/* tl */0])),
                /* :: */[
                  Css.unsafe("borderTopRightRadius", toString(match$2[/* tr */1])),
                  /* :: */[
                    Css.unsafe("borderBottomRightRadius", toString(match$2[/* br */2])),
                    /* :: */[
                      Css.unsafe("borderBottomLeftRadius", toString(match$2[/* bl */3])),
                      /* [] */0
                    ]
                  ]
                ]
              ];
      }
      var shadowInfo = getShadowInfo(rule[1]);
      if (rule[0] >= 14553547) {
        return /* :: */[
                Css.unsafe("boxShadow", "inset " + (String(shadowInfo) + "")),
                /* [] */0
              ];
      } else {
        return /* :: */[
                Css.unsafe("boxShadow", shadowInfo),
                /* [] */0
              ];
      }
    }
  };
  var backgroundRuleToCss = function (rule) {
    var variant = rule[0];
    if (variant !== 5504141) {
      if (variant >= 222776981) {
        return /* :: */[
                Css.important(Css.unsafe("backgroundColor", BwaxUi.themeColor(theme, ColorTypes$BwaxMobile.toString(rule[1])))),
                /* [] */0
              ];
      } else {
        var match = rule[1];
        var joinedColors = $$String.concat(", ", Belt_List.map(match[1], getColor));
        var angleStr = match[0].toString();
        return /* :: */[
                Css.unsafe("backgroundImage", "linear-gradient(" + (angleStr + ("deg, " + (joinedColors + ")")))),
                /* [] */0
              ];
      }
    } else {
      return /* :: */[
              Css.backgroundImage(/* `url */[
                    5843823,
                    rule[1]
                  ]),
              /* :: */[
                Css.backgroundPosition(/* center */98248149),
                /* :: */[
                  Css.backgroundSize(/* cover */-899416265),
                  /* :: */[
                    Css.backgroundRepeat(/* noRepeat */-695430532),
                    /* [] */0
                  ]
                ]
              ]
            ];
    }
  };
  var gatherFilter = function (filter) {
    var variant = filter[0];
    if (variant >= 258528361) {
      if (variant >= 762315015) {
        if (variant >= 1017245550) {
          return "invert(" + (String(filter[1]) + ")");
        } else {
          return "saturate(" + (String(filter[1]) + ")");
        }
      } else if (variant >= 353348319) {
        return "grayscale(" + (String(filter[1]) + ")");
      } else {
        return "brightness(" + (String(filter[1]) + ")");
      }
    } else if (variant >= -27906470) {
      if (variant >= 77061091) {
        return "opacity(" + (String(filter[1]) + ")");
      } else {
        return "contrast(" + (String(filter[1]) + ")");
      }
    } else if (variant >= -54473281) {
      var lengthStr = toString(filter[1]);
      return "blur(" + (String(lengthStr) + ")");
    } else {
      return "sepia(" + (String(filter[1]) + ")");
    }
  };
  var miscRuleToCss = function (rule) {
    if (typeof rule === "number") {
      if (rule >= 819475372) {
        if (rule >= 939907157) {
          if (rule >= 977475101) {
            return /* :: */[
                    Css.userSelect(/* none */-922086728),
                    /* [] */0
                  ];
          } else {
            return /* :: */[
                    Css.cursor(/* notAllowed */939907157),
                    /* [] */0
                  ];
          }
        } else if (rule >= 894466172) {
          return /* [] */0;
        } else {
          return /* :: */[
                  Css.overflowY(/* auto */-1065951377),
                  /* :: */[
                    Css.pointerEvents(/* auto */-1065951377),
                    /* [] */0
                  ]
                ];
        }
      } else if (rule >= 561722444) {
        if (rule >= 819475371) {
          return /* :: */[
                  Css.overflowX(/* auto */-1065951377),
                  /* :: */[
                    Css.pointerEvents(/* auto */-1065951377),
                    /* [] */0
                  ]
                ];
        } else {
          return /* :: */[
                  Css.overflow(/* hidden */-862584982),
                  /* [] */0
                ];
        }
      } else if (rule >= -786317123) {
        return /* :: */[
                Css.cursor(/* pointer */-786317123),
                /* [] */0
              ];
      } else {
        return /* :: */[
                Css.overflow(/* auto */-1065951377),
                /* :: */[
                  Css.pointerEvents(/* auto */-1065951377),
                  /* [] */0
                ]
              ];
      }
    } else {
      var variant = rule[0];
      if (variant >= -15087829) {
        if (variant >= 87367608) {
          if (variant >= 762661873) {
            return /* [] */0;
          } else {
            var joined = Plate$BwaxMobile.Str.join(" ", Plate$BwaxMobile.List.map(gatherFilter, rule[1]));
            return /* :: */[
                    Css.unsafe("filter", joined),
                    /* [] */0
                  ];
          }
        } else if (variant >= 23515) {
          return /* [] */0;
        } else {
          var match = rule[1];
          return /* :: */[
                  Css.unsafe(match[0], match[1]),
                  /* [] */0
                ];
        }
      } else if (variant !== -599944541) {
        if (variant >= -345678389) {
          return /* :: */[
                  Css.opacity(rule[1]),
                  /* [] */0
                ];
        } else {
          return /* :: */[
                  Css.unsafe("transition", "all " + (String(rule[1]) + "ms")),
                  /* [] */0
                ];
        }
      } else {
        return /* [] */0;
      }
    }
  };
  var basicRuleToCss = function (rule) {
    if (typeof rule === "number") {
      if (rule >= -346313767) {
        if (rule >= 222199711) {
          if (rule >= 561722444) {
            if (rule >= 819475371) {
              if (rule >= 1019979362) {
                return textRuleToCss(rule);
              } else {
                return miscRuleToCss(rule);
              }
            } else if (rule >= 776515163) {
              return layoutRuleToCss(rule);
            } else {
              return miscRuleToCss(rule);
            }
          } else if (rule !== 233447657) {
            if (rule >= 345286304) {
              return layoutRuleToCss(rule);
            } else {
              return borderRuleToCss(rule);
            }
          } else {
            return textRuleToCss(rule);
          }
        } else if (rule >= -160399838) {
          if (rule >= 45513431) {
            if (rule >= 201352959) {
              return textRuleToCss(rule);
            } else {
              return layoutRuleToCss(rule);
            }
          } else if (rule >= -15048643) {
            return borderRuleToCss(rule);
          } else {
            return /* [] */0;
          }
        } else if (rule !== -345586204) {
          if (rule !== -305581231) {
            return layoutRuleToCss(rule);
          } else {
            return /* [] */0;
          }
        } else {
          return textRuleToCss(rule);
        }
      } else if (rule >= -777089820) {
        if (rule >= -680157294) {
          if (rule !== -550118877) {
            if (rule >= -626416847) {
              return /* [] */0;
            } else {
              return layoutRuleToCss(rule);
            }
          } else {
            return textRuleToCss(rule);
          }
        } else if (rule >= -731000468) {
          if (rule >= -689852595) {
            return /* [] */0;
          } else {
            return layoutRuleToCss(rule);
          }
        } else if (rule >= -772109033) {
          return textRuleToCss(rule);
        } else {
          return /* [] */0;
        }
      } else if (rule >= -949692403) {
        if (rule >= -908095280) {
          if (rule >= -786317123) {
            return miscRuleToCss(rule);
          } else {
            return layoutRuleToCss(rule);
          }
        } else if (rule >= -937384257) {
          return textRuleToCss(rule);
        } else {
          return miscRuleToCss(rule);
        }
      } else if (rule !== -1005136686) {
        return layoutRuleToCss(rule);
      } else {
        return textRuleToCss(rule);
      }
    } else {
      var variant = rule[0];
      if (variant >= -61360200) {
        if (variant >= 98978424) {
          if (variant >= 416026680) {
            if (variant !== 528541484) {
              if (variant !== 762661873) {
                if (variant !== 904663632) {
                  return layoutRuleToCss(rule);
                } else {
                  return textRuleToCss(rule);
                }
              } else {
                return miscRuleToCss(rule);
              }
            } else {
              return textRuleToCss(rule);
            }
          } else if (variant !== 128270816) {
            if (variant >= 263400904) {
              if (variant !== 297481091) {
                return borderRuleToCss(rule);
              } else {
                return layoutRuleToCss(rule);
              }
            } else if (variant >= 222776981) {
              return backgroundRuleToCss(rule);
            } else {
              return layoutRuleToCss(rule);
            }
          } else {
            return textRuleToCss(rule);
          }
        } else if (variant >= 5343647) {
          if (variant !== 14553547) {
            if (variant >= 5504142) {
              if (variant >= 87367608) {
                return miscRuleToCss(rule);
              } else {
                return layoutRuleToCss(rule);
              }
            } else if (variant >= 5504141) {
              return backgroundRuleToCss(rule);
            } else {
              return miscRuleToCss(rule);
            }
          } else {
            return borderRuleToCss(rule);
          }
        } else if (variant >= -15087829) {
          if (variant >= 3787087) {
            return layoutRuleToCss(rule);
          } else {
            return miscRuleToCss(rule);
          }
        } else if (variant !== -37494765) {
          return borderRuleToCss(rule);
        } else {
          return layoutRuleToCss(rule);
        }
      } else if (variant >= -710172856) {
        if (variant >= -560194903) {
          if (variant !== -481689226) {
            if (variant >= -303792878) {
              if (variant !== -218331858) {
                return layoutRuleToCss(rule);
              } else {
                return /* [] */0;
              }
            } else if (variant >= -345678389) {
              return miscRuleToCss(rule);
            }
            
          } else {
            return textRuleToCss(rule);
          }
        } else if (variant >= -688651525) {
          if (variant >= -599944541) {
            if (variant >= -586247093) {
              return borderRuleToCss(rule);
            } else {
              return miscRuleToCss(rule);
            }
          } else if (variant >= -611055847) {
            return layoutRuleToCss(rule);
          } else {
            return textRuleToCss(rule);
          }
        } else if (variant >= -690668329) {
          return borderRuleToCss(rule);
        }
        
      } else if (variant >= -899500538) {
        if (variant !== -884574711) {
          if (variant >= -892786689) {
            if (variant >= -812030052) {
              return layoutRuleToCss(rule);
            } else {
              return textRuleToCss(rule);
            }
          } else if (variant >= -892786690) {
            return backgroundRuleToCss(rule);
          } else {
            return layoutRuleToCss(rule);
          }
        } else {
          return miscRuleToCss(rule);
        }
      } else if (variant >= -979470621) {
        if (variant >= -958002182) {
          return textRuleToCss(rule);
        } else {
          return borderRuleToCss(rule);
        }
      } else if (variant !== -1041690463) {
        return layoutRuleToCss(rule);
      } else {
        return borderRuleToCss(rule);
      }
    }
    return /* :: */[
            Css.important(Css.pointerEvents(/* auto */-1065951377)),
            /* :: */[
              Css.cursor(/* pointer */-786317123),
              /* [] */0
            ]
          ];
  };
  var compositeRuleToCss = function (rule) {
    if (typeof rule === "number") {
      return basicRuleToCss(rule);
    } else {
      var variant = rule[0];
      if (variant !== 76111832) {
        if (variant !== 373703110) {
          if (variant !== 728032700) {
            return basicRuleToCss(rule);
          } else {
            var toCss = basicRuleToCss;
            return /* :: */[
                    Css.hover(Belt_List.flatten(Belt_List.map(rule[1], toCss))),
                    /* [] */0
                  ];
          }
        } else {
          var toCss$1 = basicRuleToCss;
          return /* :: */[
                  Css.enabled(/* :: */[
                        Css.active(Belt_List.flatten(Belt_List.map(rule[1], toCss$1))),
                        /* [] */0
                      ]),
                  /* [] */0
                ];
        }
      } else {
        var toCss$2 = basicRuleToCss;
        return /* :: */[
                Css.focus(Belt_List.flatten(Belt_List.map(rule[1], toCss$2))),
                /* [] */0
              ];
      }
    }
  };
  if (typeof rule === "number") {
    return Css.style(compositeRuleToCss(rule));
  } else {
    var variant = rule[0];
    if (variant >= -218331858) {
      if (variant >= 528541484) {
        if (variant !== 592113284 && variant !== 811186865) {
          return Css.style(compositeRuleToCss(rule));
        } else {
          return Css.style(/* [] */0);
        }
      } else if (variant !== -6182339 && variant < 452579101) {
        return Css.style(compositeRuleToCss(rule));
      } else {
        return Css.style(/* [] */0);
      }
    } else if (variant >= -586247093) {
      if (variant !== -365293370) {
        if (variant >= -265706864) {
          var rules = Belt_List.flatten(Belt_List.map(rule[1], compositeRuleToCss));
          return Css.style(/* :: */[
                      Css.media("(min-width: 640px)", rules),
                      /* [] */0
                    ]);
        } else {
          return Css.style(compositeRuleToCss(rule));
        }
      } else {
        return Css.style(/* [] */0);
      }
    } else if (variant !== -969682420) {
      if (variant >= -593248680) {
        var rules$1 = Belt_List.flatten(Belt_List.map(rule[1], compositeRuleToCss));
        return Css.style(/* :: */[
                    Css.media("(max-width: 639px)", rules$1),
                    /* [] */0
                  ]);
      } else {
        return Css.style(compositeRuleToCss(rule));
      }
    } else {
      var match = rule[1];
      return Css.fontFace(match[0], /* :: */[
                  /* `url */[
                    5843823,
                    match[1]
                  ],
                  /* [] */0
                ], undefined, undefined, /* () */0);
    }
  }
}

function rawToRule(raw) {
  var extractColor = function (params) {
    if (params && !params[1]) {
      return ColorTypes$BwaxMobile.toColor(params[0]);
    }
    
  };
  var toBasicRule = function (name, params) {
    switch (name) {
      case "alignBottom" :
          return /* alignBottom */-196635760;
      case "alignLeft" :
          return /* alignLeft */-731000468;
      case "alignRight" :
          return /* alignRight */45513431;
      case "alignTop" :
          return /* alignTop */-908095280;
      case "backgroundColor" :
          return Belt_Option.map(extractColor(params), (function (c) {
                        return /* `backgroundColor */[
                                222776981,
                                c
                              ];
                      }));
      case "backgroundImage" :
          if (params && !params[1]) {
            return /* `backgroundImage */[
                    5504141,
                    params[0]
                  ];
          } else {
            return ;
          }
      case "borderColor" :
          return Belt_Option.map(extractColor(params), (function (c) {
                        return /* `borderColor */[
                                -690668329,
                                c
                              ];
                      }));
      case "borderDashed" :
          return /* borderDashed */-15048643;
      case "borderDotted" :
          return /* borderDotted */258527478;
      case "borderRoundEach" :
          var match = Belt_List.map(params, strToLength);
          if (match) {
            var match$1 = match[0];
            if (match$1 !== undefined) {
              var match$2 = match[1];
              if (match$2) {
                var match$3 = match$2[0];
                if (match$3 !== undefined) {
                  var match$4 = match$2[1];
                  if (match$4) {
                    var match$5 = match$4[0];
                    if (match$5 !== undefined) {
                      var match$6 = match$4[1];
                      if (match$6) {
                        var match$7 = match$6[0];
                        if (match$7 !== undefined && !match$6[1]) {
                          return /* `borderRoundEach */[
                                  -979470621,
                                  /* record */[
                                    /* tl */match$1,
                                    /* tr */match$3,
                                    /* br */match$5,
                                    /* bl */match$7
                                  ]
                                ];
                        } else {
                          return ;
                        }
                      } else {
                        return ;
                      }
                    } else {
                      return ;
                    }
                  } else {
                    return ;
                  }
                } else {
                  return ;
                }
              } else {
                return ;
              }
            } else {
              return ;
            }
          } else {
            return ;
          }
      case "borderRounded" :
          if (params && !params[1]) {
            return Belt_Option.map(strToLength(params[0]), (function (length) {
                          return /* `borderRounded */[
                                  -1041690463,
                                  length
                                ];
                        }));
          } else {
            return ;
          }
      case "borderSolid" :
          return /* borderSolid */222199711;
      case "borderWidth" :
          if (params && !params[1]) {
            return Belt_Option.map(strToLength(params[0]), (function (length) {
                          return /* `borderWidth */[
                                  -690257542,
                                  length
                                ];
                        }));
          } else {
            return ;
          }
      case "borderWidthEach" :
          var match$8 = Belt_List.map(params, strToLength);
          if (match$8) {
            var match$9 = match$8[1];
            if (match$9) {
              var match$10 = match$9[1];
              if (match$10) {
                var match$11 = match$10[1];
                if (match$11 && !match$11[1]) {
                  return /* `borderWidthEach */[
                          -34049893,
                          /* record */[
                            /* top */match$8[0],
                            /* right */match$9[0],
                            /* bottom */match$10[0],
                            /* left */match$11[0]
                          ]
                        ];
                } else {
                  return ;
                }
              } else {
                return ;
              }
            } else {
              return ;
            }
          } else {
            return ;
          }
      case "boxGlow" :
          if (params) {
            var match$12 = params[1];
            var c = params[0];
            if (match$12) {
              var match$13 = ColorTypes$BwaxMobile.toColor(c);
              var match$14 = StringUtils$BwaxMobile.toFloat(match$12[0]);
              if (match$13 !== undefined && match$14 !== undefined) {
                return /* `boxGlow */[
                        338336312,
                        /* tuple */[
                          match$13,
                          match$14
                        ]
                      ];
              } else {
                return ;
              }
            } else {
              var match$15 = ColorTypes$BwaxMobile.toColor(c);
              if (match$15 !== undefined) {
                return /* `boxGlow */[
                        338336312,
                        /* tuple */[
                          match$15,
                          1.0
                        ]
                      ];
              } else {
                return ;
              }
            }
          } else {
            return ;
          }
      case "centerX" :
          return /* centerX */434500835;
      case "centerY" :
          return /* centerY */434500836;
      case "focusedOnLoad" :
          return /* focusedOnLoad */894466172;
      case "height" :
          if (params) {
            var len = params[0];
            if (params[1]) {
              switch (len) {
                case "fillPortion" :
                    var match$16 = params[1];
                    if (match$16[1]) {
                      return ;
                    } else {
                      var maybePortion = StringUtils$BwaxMobile.toInt(match$16[0]);
                      if (maybePortion !== undefined) {
                        return /* `height */[
                                38537191,
                                /* `fillPortion */[
                                  658993316,
                                  maybePortion
                                ]
                              ];
                      } else {
                        return ;
                      }
                    }
                case "ratio" :
                    var match$17 = params[1];
                    if (match$17[1]) {
                      return ;
                    } else {
                      return Belt_Option.map(StringUtils$BwaxMobile.toFloat(match$17[0]), (function (ratio) {
                                    return /* `height */[
                                            38537191,
                                            /* `ratio */[
                                              -467389173,
                                              ratio
                                            ]
                                          ];
                                  }));
                    }
                default:
                  return ;
              }
            } else if (len === "fill") {
              return /* `height */[
                      38537191,
                      /* fill */-1011102077
                    ];
            } else {
              return Belt_Option.map(strToLength(len), (function (length) {
                            return /* `height */[
                                    38537191,
                                    length
                                  ];
                          }));
            }
          } else {
            return ;
          }
      case "heightScrollFill" :
          return /* heightScrollFill */790228983;
      case "hidden" :
          return /* hidden */-862584982;
      case "maxWidth" :
          if (params && !params[1]) {
            return Belt_Option.map(strToLength(params[0]), (function (length) {
                          return /* `maxWidth */[
                                  -192935742,
                                  length
                                ];
                        }));
          } else {
            return ;
          }
      case "minWidth" :
          if (params && !params[1]) {
            return Belt_Option.map(strToLength(params[0]), (function (length) {
                          return /* `minWidth */[
                                  -1046863084,
                                  length
                                ];
                        }));
          } else {
            return ;
          }
      case "notAllowed" :
          return /* notAllowed */939907157;
      case "opacity" :
          if (params && !params[1]) {
            return Belt_Option.map(StringUtils$BwaxMobile.toFloat(params[0]), (function (f) {
                          return /* `opacity */[
                                  -345678389,
                                  f
                                ];
                        }));
          } else {
            return ;
          }
      case "padding" :
          if (params && !params[1]) {
            return Belt_Option.map(strToLength(params[0]), (function (length) {
                          return /* `padding */[
                                  -797091375,
                                  length
                                ];
                        }));
          } else {
            return ;
          }
      case "paddingEach" :
          var match$18 = Belt_List.map(params, strToLength);
          if (match$18) {
            var match$19 = match$18[1];
            if (match$19) {
              var match$20 = match$19[1];
              if (match$20) {
                var match$21 = match$20[1];
                if (match$21 && !match$21[1]) {
                  return /* `paddingEach */[
                          21151858,
                          /* record */[
                            /* top */match$18[0],
                            /* right */match$19[0],
                            /* bottom */match$20[0],
                            /* left */match$21[0]
                          ]
                        ];
                } else {
                  return ;
                }
              } else {
                return ;
              }
            } else {
              return ;
            }
          } else {
            return ;
          }
      case "paddingXY" :
          if (params) {
            var match$22 = params[1];
            if (match$22 && !match$22[1]) {
              var match$23 = strToLength(params[0]);
              var match$24 = strToLength(match$22[0]);
              if (match$23 !== undefined && match$24 !== undefined) {
                return /* `paddingXY */[
                        -303792878,
                        /* tuple */[
                          match$23,
                          match$24
                        ]
                      ];
              } else {
                return ;
              }
            } else {
              return ;
            }
          } else {
            return ;
          }
      case "pointer" :
          return /* pointer */-786317123;
      case "scroll" :
          return /* scroll */-949692403;
      case "scrollX" :
          return /* scrollX */819475371;
      case "scrollY" :
          return /* scrollY */819475372;
      case "spaceEvenly" :
          return /* spaceEvenly */-1016061555;
      case "spacing" :
          if (params && !params[1]) {
            return Belt_Option.map(strToLength(params[0]), (function (length) {
                          return /* `spacing */[
                                  297481091,
                                  length
                                ];
                        }));
          } else {
            return ;
          }
      case "textAlignLeft" :
          return /* textAlignLeft */201352959;
      case "textAlignRight" :
          return /* textAlignRight */-345586204;
      case "textBold" :
          return /* textBold */-1005136686;
      case "textCenter" :
          return /* textCenter */1019979362;
      case "textColor" :
          return Belt_Option.map(extractColor(params), (function (c) {
                        return /* `textColor */[
                                -481689226,
                                c
                              ];
                      }));
      case "textLight" :
          return /* textLight */233447657;
      case "textSize" :
          if (params && !params[1]) {
            return Belt_Option.map(strToLength(params[0]), (function (length) {
                          return /* `textSize */[
                                  -816909298,
                                  length
                                ];
                        }));
          } else {
            return ;
          }
      case "textStrike" :
          return /* textStrike */-937384257;
      case "textUnderline" :
          return /* textUnderline */1063496351;
      case "width" :
          if (params) {
            var len$1 = params[0];
            if (params[1]) {
              if (len$1 === "fillPortion") {
                var match$25 = params[1];
                if (match$25[1]) {
                  return ;
                } else {
                  var maybePortion$1 = StringUtils$BwaxMobile.toInt(match$25[0]);
                  if (maybePortion$1 !== undefined) {
                    return /* `width */[
                            -899500538,
                            /* `fillPortion */[
                              658993316,
                              maybePortion$1
                            ]
                          ];
                  } else {
                    return ;
                  }
                }
              } else {
                return ;
              }
            } else if (len$1 === "fill") {
              return /* `width */[
                      -899500538,
                      /* fill */-1011102077
                    ];
            } else {
              return Belt_Option.map(strToLength(len$1), (function (length) {
                            return /* `width */[
                                    -899500538,
                                    length
                                  ];
                          }));
            }
          } else {
            return ;
          }
      default:
        return ;
    }
  };
  var splitNameParams = function (l) {
    if (l) {
      return /* tuple */[
              l[0],
              l[1]
            ];
    }
    
  };
  var wrapInnerRules = function (params, toSomeRule, wrapper) {
    return Belt_Option.flatMap(splitNameParams(params), (function (param) {
                  return Belt_Option.map(Curry._2(toSomeRule, param[0], param[1]), (function (innerRule) {
                                return Curry._1(wrapper, /* :: */[
                                            innerRule,
                                            /* [] */0
                                          ]);
                              }));
                }));
  };
  var toCompositeRule = function (name, params) {
    if (name === "mouseOver") {
      var wrapper = function (irs) {
        return /* `hover */[
                728032700,
                irs
              ];
      };
      return wrapInnerRules(params, toBasicRule, wrapper);
    } else {
      return toBasicRule(name, params);
    }
  };
  var toRule = function (name, params) {
    switch (name) {
      case "narrowOnly" :
          var wrapper = function (irs) {
            return /* `ifNarrow */[
                    -593248680,
                    irs
                  ];
          };
          return wrapInnerRules(params, toCompositeRule, wrapper);
      case "wideOnly" :
          var wrapper$1 = function (irs) {
            return /* `ifWide */[
                    -265706864,
                    irs
                  ];
          };
          return wrapInnerRules(params, toCompositeRule, wrapper$1);
      default:
        return toCompositeRule(name, params);
    }
  };
  var ret = raw ? toRule(raw[0], raw[1]) : undefined;
  if (Belt_Option.isNone(ret)) {
    console.log(Belt_List.toArray(raw), "cannot be parsed");
  }
  return ret;
}

var longPressTimerRef = /* record */[/* contents */undefined];

function longPressStart(handler, e) {
  e.preventDefault();
  e.stopPropagation();
  var timerId = setTimeout((function (param) {
          return Curry._1(handler, /* () */0);
        }), 500);
  var preTimerId = longPressTimerRef[0];
  if (preTimerId !== undefined) {
    clearTimeout(Caml_option.valFromOption(preTimerId));
  }
  longPressTimerRef[0] = Caml_option.some(timerId);
  return /* () */0;
}

function longPressEnd(e) {
  e.preventDefault();
  e.stopPropagation();
  var preTimerId = longPressTimerRef[0];
  if (preTimerId !== undefined) {
    clearTimeout(Caml_option.valFromOption(preTimerId));
  }
  longPressTimerRef[0] = undefined;
  return /* () */0;
}

var getClassName = ( el => el.props.className );

function wrapForAlignment(el, rules, pc, key) {
  var eventHandlers = Plate$BwaxMobile.List.concat(Belt_List.keepMap(rules, (function (r) {
              if (typeof r === "number") {
                return ;
              } else {
                var variant = r[0];
                if (variant !== -710172856) {
                  if (variant !== -560194903) {
                    return ;
                  } else {
                    var handler = r[1];
                    return /* :: */[
                            /* tuple */[
                              "onClick",
                              (function (e) {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  return Curry._1(handler, /* () */0);
                                })
                            ],
                            /* [] */0
                          ];
                  }
                } else {
                  var handler$1 = r[1];
                  return /* :: */[
                          /* tuple */[
                            "onTouchStart",
                            (function (param) {
                                return longPressStart(handler$1, param);
                              })
                          ],
                          /* :: */[
                            /* tuple */[
                              "onTouchEnd",
                              longPressEnd
                            ],
                            /* :: */[
                              /* tuple */[
                                "onMouseDown",
                                (function (param) {
                                    return longPressStart(handler$1, param);
                                  })
                              ],
                              /* :: */[
                                /* tuple */[
                                  "onMouseUp",
                                  longPressEnd
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "onMouseLeave",
                                    longPressEnd
                                  ],
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ];
                }
              }
            })));
  var el_with_events;
  if (Belt_List.length(eventHandlers) > 0) {
    var props = Js_dict.fromList(eventHandlers);
    el_with_events = React.cloneElement(el, props);
  } else {
    el_with_events = el;
  }
  var hidden = Plate$BwaxMobile.List.find((function (r) {
          return r === -862584982;
        }), rules);
  var ifWideHidden = Plate$BwaxMobile.List.find((function (r) {
          if (typeof r === "number" || r[0] !== -265706864) {
            return false;
          } else {
            return Belt_List.getBy(r[1], (function (r) {
                          return r === /* hidden */-862584982;
                        })) !== undefined;
          }
        }), rules);
  var ifNarrowHidden = Plate$BwaxMobile.List.find((function (r) {
          if (typeof r === "number" || r[0] !== -593248680) {
            return false;
          } else {
            return Belt_List.getBy(r[1], (function (r) {
                          return r === /* hidden */-862584982;
                        })) !== undefined;
          }
        }), rules);
  var hiddenClassName = hidden !== undefined ? "hidden" : (
      ifWideHidden !== undefined ? "wHdn" : (
          ifNarrowHidden !== undefined ? "nHdn" : ""
        )
    );
  var handledHidden;
  if (hiddenClassName !== "") {
    var originalClassName = Curry._1(getClassName, el_with_events);
    var className = originalClassName + (" " + hiddenClassName);
    handledHidden = React.cloneElement(el_with_events, {
          className: className
        });
  } else {
    handledHidden = el_with_events;
  }
  var isWidthFill = Plate$BwaxMobile.List.find((function (r) {
          if (typeof r === "number" || r[0] !== -899500538) {
            return false;
          } else {
            var l = r[1];
            if (typeof l === "number") {
              return l === -1011102077;
            } else {
              return l[0] === 658993316;
            }
          }
        }), rules);
  var isHeightFill = Plate$BwaxMobile.List.find((function (r) {
          if (typeof r === "number" || r[0] !== 38537191) {
            return false;
          } else {
            var l = r[1];
            if (typeof l === "number") {
              return l === -1011102077;
            } else {
              var variant = l[0];
              if (variant !== -119887163) {
                return variant === 658993316;
              } else {
                return l[1] === 100.0;
              }
            }
          }
        }), rules);
  var ab = Belt_List.getBy(rules, (function (r) {
          return r === /* alignBottom */-196635760;
        }));
  var at = Belt_List.getBy(rules, (function (r) {
          return r === /* alignTop */-908095280;
        }));
  var rowExtraClassName = isHeightFill !== undefined || ab !== undefined || at !== undefined ? "" : " ccy";
  var match = Belt_List.getBy(rules, (function (r) {
          return r === /* centerX */434500835;
        }));
  var wrappedX = match !== undefined && !(isWidthFill !== undefined || pc !== 5693978) ? React.createElement("s", {
          key: key,
          className: "ctnr ccx" + rowExtraClassName
        }, handledHidden) : handledHidden;
  var match$1 = Belt_List.getBy(rules, (function (r) {
          return r === /* centerY */434500836;
        }));
  var wrappedY = match$1 !== undefined && !(isHeightFill !== undefined || pc !== -963948842) ? React.createElement("s", {
          key: key,
          className: "ctnr ccy"
        }, wrappedX) : wrappedX;
  var match$2 = Belt_List.getBy(rules, (function (r) {
          return r === /* alignRight */45513431;
        }));
  var wrappedAlignRight = match$2 !== undefined && !(isWidthFill !== undefined || pc !== 5693978) ? React.createElement("u", {
          key: key,
          className: "ctnr acr" + rowExtraClassName
        }, wrappedY) : wrappedY;
  var match$3 = Belt_List.getBy(rules, (function (r) {
          return r === /* alignBottom */-196635760;
        }));
  var wrappedAlignBottom;
  var exit = 0;
  if (match$3 !== undefined && !(isHeightFill !== undefined || pc !== -963948842 && pc !== 4898315)) {
    exit = 1;
  } else {
    wrappedAlignBottom = wrappedAlignRight;
  }
  if (exit === 1) {
    wrappedAlignBottom = React.createElement("u", {
          key: key,
          className: "ctnr acb"
        }, wrappedAlignRight);
  }
  var maybe_id = Plate$BwaxMobile.List.find((function (r) {
          if (typeof r === "number") {
            return false;
          } else {
            return r[0] === 23515;
          }
        }), rules);
  var handledId;
  if (maybe_id !== undefined) {
    var match$4 = maybe_id;
    handledId = typeof match$4 === "number" || match$4[0] !== 23515 ? wrappedAlignBottom : React.cloneElement(wrappedAlignBottom, {
            id: match$4[1]
          });
  } else {
    handledId = wrappedAlignBottom;
  }
  var maybe_key = Plate$BwaxMobile.List.find((function (r) {
          if (typeof r === "number") {
            return false;
          } else {
            return r[0] === 5343647;
          }
        }), rules);
  if (maybe_key !== undefined) {
    var match$5 = maybe_key;
    if (typeof match$5 === "number" || match$5[0] !== 5343647) {
      return handledId;
    } else {
      return React.cloneElement(handledId, {
                  key: match$5[1]
                });
    }
  } else {
    return handledId;
  }
}

function wrapForRegion(inner, className, rules, key) {
  var regionRules = Belt_List.keepMap(rules, (function (r) {
          if (typeof r === "number") {
            if (r >= -583097680) {
              if (r !== -378612657 && r !== -305581231 && r !== -160399838) {
                return ;
              } else {
                return r;
              }
            } else if (r !== -777089820 && r !== -689852595 && r < -583097681) {
              return ;
            } else {
              return r;
            }
          } else if (r[0] !== -218331858) {
            return ;
          } else {
            return r;
          }
        }));
  var lastRegionRule = Belt_List.get(regionRules, Belt_List.length(regionRules) - 1 | 0);
  if (lastRegionRule !== undefined) {
    var rr = lastRegionRule;
    var exit = 0;
    if (typeof rr === "number") {
      if (rr >= -583097680) {
        if (!(rr !== -378612657 && rr !== -305581231 && rr !== -160399838)) {
          exit = 2;
        }
        
      } else if (!(rr !== -777089820 && rr !== -689852595 && rr < -583097681)) {
        exit = 2;
      }
      
    } else if (rr[0] === -218331858) {
      exit = 2;
    }
    if (exit === 2) {
      if (typeof rr === "number") {
        if (rr >= -583097681) {
          if (rr >= -305581231) {
            if (rr >= -160399838) {
              return React.createElement("article", {
                          key: key,
                          className: className
                        }, inner);
            } else {
              return React.createElement("section", {
                          key: key,
                          className: className
                        }, inner);
            }
          } else if (rr >= -378612657) {
            return React.createElement("nav", {
                        key: key,
                        className: className
                      }, inner);
          } else {
            return React.createElement("footer", {
                        key: key,
                        className: className
                      }, inner);
          }
        } else if (rr >= -689852595) {
          return React.createElement("main", {
                      key: key,
                      className: className
                    }, inner);
        } else {
          return React.createElement("aside", {
                      key: key,
                      className: className
                    }, inner);
        }
      } else {
        switch (rr[1]) {
          case 1 :
              return React.createElement("h1", {
                          key: key,
                          className: className
                        }, inner);
          case 2 :
              return React.createElement("h2", {
                          key: key,
                          className: className
                        }, inner);
          case 3 :
              return React.createElement("h3", {
                          key: key,
                          className: className
                        }, inner);
          case 4 :
              return React.createElement("h4", {
                          key: key,
                          className: className
                        }, inner);
          case 5 :
              return React.createElement("h5", {
                          key: key,
                          className: className
                        }, inner);
          default:
            return React.createElement("h6", {
                        key: key,
                        className: className
                      }, inner);
        }
      }
    }
    
  }
  return React.createElement("div", {
              key: key,
              className: className
            }, inner);
}

exports.supports = supports;
exports.hexToCssColor = hexToCssColor;
exports.themeColorCss = themeColorCss;
exports.ifWide = ifWide;
exports.ifNarrow = ifNarrow;
exports.grabFirst = grabFirst;
exports.Length = Length;
exports.strToLength = strToLength;
exports.LimitedCss = LimitedCss;
exports.presetPX = presetPX;
exports.presetREM = presetREM;
exports.isSpecialSpacing = isSpecialSpacing;
exports.findSpacingLength = findSpacingLength;
exports.ruleToCss = ruleToCss;
exports.rawToRule = rawToRule;
exports.longPressTimerRef = longPressTimerRef;
exports.longPressStart = longPressStart;
exports.longPressEnd = longPressEnd;
exports.getClassName = getClassName;
exports.wrapForAlignment = wrapForAlignment;
exports.wrapForRegion = wrapForRegion;
/* getClassName Not a pure module */
