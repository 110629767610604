
// This file is generated. Don't modify it.
import React from 'react'
export default function ErrorWireFrameIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M8,13 C10.7614237,13 13,10.7614237 13,8 C13,5.23857625 10.7614237,3 8,3 C5.23857625,3 3,5.23857625 3,8 C3,10.7614237 5.23857625,13 8,13 Z M8,14 C4.6862915,14 2,11.3137085 2,8 C2,4.6862915 4.6862915,2 8,2 C11.3137085,2 14,4.6862915 14,8 C14,11.3137085 11.3137085,14 8,14 Z M8.59134615,8.59134615 L7.40865385,8.59134615 L7.40865385,4.98557692 L8.59134615,4.98557692 L8.59134615,8.59134615 Z M8.59134615,11.0144231 L7.40865385,11.0144231 L7.40865385,9.80288462 L8.59134615,9.80288462 L8.59134615,11.0144231 Z" id="ErrorWireFrameIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Wireframe/Error">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="ErrorWireFrameIcon-mask-2" fill="white">
                            <use href="#ErrorWireFrameIcon-path-1"></use>
                        </mask>
                        <use id="Combined-Shape" fill={color} href="#ErrorWireFrameIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
