

import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';

import React, { useState } from 'react';
import * as ReactDOM from 'react-dom/client';

import classNames from 'classnames'

import Button from 'bwax-ui/components/Button';

import "./Dialog.less";

const DialogComp = React.forwardRef(({
    container, children, open, onOpenChange, content, className, openByDefault = false,
    isImportant = false, isMain = false, style
}, ref) => {

    const [internalOpen, setInternalOpen] = useState(openByDefault);

    return (
        <Dialog.Root open={open !== undefined ? open : internalOpen} onOpenChange={open => {
            setInternalOpen(open);
            if (onOpenChange) { onOpenChange(open) }
        }}>
            {children ? (
                <Dialog.Trigger asChild>
                    {children}
                </Dialog.Trigger>
            ) : null}
            <Dialog.Portal container={container}>
                <Dialog.Overlay className="lc-dialog-overlay" />
                <Dialog.Content className={classNames("lc-dialog-content", "lc-base", className)} ref={ref}  data-main={isMain}  
                    style={style}
                    onPointerDownOutside={e => {
                        if (isImportant) {
                            e.preventDefault();
                        }

                    }}
                    onEscapeKeyDown={e => {
                        if (isImportant) {
                            e.preventDefault();
                        }
                    }}
                >  
                    <div className="content-wrapper">
                        { typeof(content) == "function" ? (
                            content(() => { 
                                setInternalOpen(false);
                                if(onOpenChange) { onOpenChange(false) };
                            })
                        )  : content }
                    </div>
                    <Dialog.Close asChild>
                        <button className="IconButton" aria-label="Close">
                            <Cross2Icon />
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    )
})

export default DialogComp;


export function DialogConfirm({
    content,
    error,
    isProgressing,
    isReady = true,
    cancelText = "取消",
    okText = "确定",

    okColor="grass",
    onCancel,
    onOk,

    style,

    container,

    open,
    onOpenChange,
    openByDefault,

}) {

    function renderContent() {
        return (
            <div className="lc-dialog-confirm">
                {content}
                {error ? (<div className="error-msg px-4">{error}</div>) : null}
                <div className="action-group">
                    <Button onPress={_ => onCancel && onCancel()} isDisabled={isProgressing}>{cancelText}</Button>
                    { onOk ? 
                        (
                            <Button color={okColor} appearance="primary" isLoading={isProgressing} autoFocus isDisabled={!isReady} onPress={_ => {
                                onOk();
                            }}>{okText}
                            </Button>
                        ) : null
                    }  
                </div>
            </div>
        )
    }

    return (
        <DialogComp
            open={open}
            isImportant={true}
            container={container}
            onOpenChange={onOpenChange}
            openByDefault={openByDefault}
            style={style}
            //  ref={dialogRef}
            content={renderContent()}
        />
    )

}


function DialogConfirmForImperative({ container, content, cancelText, okText, okColor, style, onConfirm }) {

    const [ open, setOpen ] = useState(true);

    return (
        <DialogConfirm {...{
            open,
            container, content, cancelText, okText, style, okColor,
            onOk: _ => {
                setOpen(false);
                onConfirm(true);
            },
            onCancel: _ => {
                setOpen(false);
                onConfirm(false);
            },
            onOpenChange: open => {
                setOpen(open)
                if(!open) {
                    onConfirm(false);
                }
            }
        }} />
    )
}

export function createDialogConfirm({
    cancelText = "取消",
    okText = "确定",
    okColor,
    content,
    onConfirm,
    style,
}) {

    const container = document.createElement('div');
    document.body.appendChild(container);

    const root = ReactDOM.createRoot(container);

    function clear() {
        setTimeout(() => {
            if (document.body.contains(container)) {
                document.body.removeChild(container);
            }
        }, 100);
    }
    
    root.render(<DialogConfirmForImperative {...{
        container, 
        content, 
        cancelText, okText,
        style,
        okColor,
        onConfirm: yes => {
            onConfirm(yes);
            clear();
        }
    }} />);

}


export function DialogForm({
    open, onOpenChange, onDataCollected,

    openByDefault,

    container,

    okText = "确定",
    fields = [], title, description,
    showLabel = true,
}) {

    const [editing, setEditing] = useState(fields.reduce((acc, f) => {
        const { name, initialValue } = f;
        if (initialValue !== undefined) {
            return { ...acc, [name]: initialValue }
        } else {
            return acc
        }
    }, {}));

    const canSubmit = fields.every(f => {
        // 简单地看看是不是有 dirtyValue
        const { name, initialValue } = f;
        return editing[name] !== undefined && editing[name] != initialValue
    })

    const [ loading, setLoading ] = useState(false);

    // form 可以抽象成单独的代码
    const content = (
        <>
            <Dialog.Title className="DialogTitle font-size-16">{title}</Dialog.Title>
            <Dialog.Description className="DialogDescription">
                {description}
            </Dialog.Description>
            {fields.map(field => {
                const { name, label, initialValue } = field
                return (
                    <fieldset className="Fieldset" key={name}>
                        {showLabel ?
                            (
                                <label className="Label" htmlFor={name}>
                                    {label || name}
                                </label>
                            ) : null
                        }
                        <input className="Input" type="text" value={editing[name] || ""} onChange={e => {
                            setEditing(prev => {
                                return {
                                    ...prev,
                                    [name]: e.target.value
                                }
                            })
                        }} />
                    </fieldset>
                )
            })}
            <div style={{ display: 'flex', marginTop: 18, justifyContent: 'flex-end' }}>
                <Button color="grass" appearance={"primary"} isDisabled={!canSubmit} isLoading={loading} onPress={async _ => {
                    setLoading(true);
                    await onDataCollected(editing);
                    setLoading(false);
                    onOpenChange(false);
                }}>{okText}</Button>
            </div>
        </>
    )

    return <DialogComp {...{ open, onOpenChange, content, container, openByDefault }} />

}


export function createDialogForm({
    okText = "确定",
    fields, title, description,
    showLabel = true,

    onDataCollected,
}) {

    const container = document.createElement('div');
    document.body.appendChild(container);

    const root = ReactDOM.createRoot(container);

    function clear() {
        if (document.body.contains(container)) {
            document.body.removeChild(container);
        }
    }

    root.render(<DialogForm {...{
        container, title, description, showLabel, okText, fields, onDataCollected,
        openByDefault: true,
        onOpenChange: open => {
            if (!open) {
                setTimeout(() => {
                    clear();
                }, 100)
            }
        }
    }} />);

}


// for bwax lang

export function create({ isOpen, open, children, ...rest }) {
    return <DialogComp {...{ open: isOpen !== undefined ? isOpen : open, content: children, ...rest }} />
}