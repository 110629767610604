
import React, { useState } from "react";

import { Pressable } from "bwax-ui/components/Button"
import Grid from "bwax-ui/components/Grid"
import ClampedText from 'bwax-ui/components/ClampedText';

import KnowledgeOptionsInput from '../KnowledgeOptionsInput';
import TemperatureInput from '../input/TemperatureInput';

import { useTrack } from 'bwax-ui/track';

import Avatar from 'bwax-ui/components/Avatar';

import { toast } from 'bwax-ui/components/Toast';
import FunctionToggle from "../input/FunctionToggle";

import { PersonaForm } from "./EditPersonaForm";

export default function AddPersonaForm({ facade, templates, currentUserId, fieldPaths, onAdded }) {
    const entityName = "OpenAI-会话角色";

    const track = useTrack();

    const [selectingFromTemplate, setSelectingFromTemplate] = useState(
        templates && templates.length > 0
        // false
    );

    const [initialValues, setInitialValues] = useState({});

    const allKeys = ["名称", "提示", "头像", "用户选项", "内容生成选项", "欢迎消息", "chatModel"]

    function selectTemplate(item) {
        if (item) {
            setInitialValues({
                名称: item.名称,
                头像: item.头像,
                提示: item.提示,
                欢迎消息: item.欢迎消息,
                用户选项: item.用户选项,
                chatModel: item.chatModel ? item.chatModel : undefined,
                内容生成选项: item.内容生成选项,

            })
        } else {
            // edit from scratch
            setInitialValues({})
        }
        setSelectingFromTemplate(false);
    }

    const allTemplates = ["__empty__", ...templates];

    const renderTemplateSelect = () => {
        return (
            <div className="template-select px-6">
                <div className="caption">
                    <div>让知识助手扮演角色，最重要的是给它恰当的提示</div>
                    <div>你可以从下面的模板中选取，或者用空白模板从头编辑</div>
                </div>
                <div className="template-list">
                    <Grid
                        items={allTemplates}
                        minWidth={170}
                        renderItem={item => {
                            if (item == "__empty__") {
                                return (
                                    <Pressable onPress={_ => selectTemplate()}>
                                        <div className="lc-persona-card-placeholder">
                                            <div className="font-size-12">空白模板</div>
                                        </div>
                                    </Pressable>
                                )
                            }

                            return (
                                <Pressable onPress={_ => selectTemplate(item)}>
                                    <div className="lc-persona-card">
                                        <div className="flex gap-1.5">
                                            <div className="">
                                                <Avatar avatar={item.头像} nickName={item.名称} size={22} />
                                            </div>
                                            <div className="title grow">
                                                {item.名称}
                                            </div>
                                        </div>
                                        <div className="caption font-size-12">
                                            <ClampedText text={item.提示} tipEnabled={false} lines={3} />
                                        </div>
                                    </div>
                                </Pressable>

                            )
                        }}
                    />
                </div>
            </div>
        )
    };

    const renderPersonaCreation = () => {
        return (
            <PersonaForm {...{
                allKeys,
                initialValues,
                onConfirm: async ({ validated }) => {

                    const [r, error] = await facade.add({
                        entityName,
                        formData: validated,
                        fieldPaths
                    });
                    if (error) {
                        toast({ title: error });
                    } else if (r) {
                        track("persona_new_one_added");
                        onAdded(r);
                        toast({ title: "已添加新的角色" });
                    }


                },
                confirmLabel: "添加",
                isReadyToConfirm: ({ allKeys, validated }) => {
                    const missingRequiredFields = ["名称", "提示"].some(key => {
                        return validated[key] === null || validated[key] === "" || validated[key] == undefined
                    })

                    return !missingRequiredFields;
                },
                facade, currentUserId,
            }} />

        )
    }


    return (
        <div className="lc-add-persona-form sm:h-[85vh] h-full">
            <div className="title font-size-18 px-6">
                <i className="bx bx-user-pin" />
                新建角色
            </div>
            {selectingFromTemplate ? renderTemplateSelect() : renderPersonaCreation()}
        </div>

    )
}

export function getFieldItems({ facade, currentUserId }) {

    return [
        { name: "名称", placeholder: "输入角色的名字" },
        // { name: "头像" },
        { name: "提示", placeholder: "告诉AI助手你想它扮演什么样的角色，应该怎么跟你对话" },
        { name: "欢迎消息", placeholder: "每次开启对话时，AI助手主动发给用户的第一条消息" },
        {
            name: "用户选项", label: "是否查找知识库）", input: ({ value, onChange }) => {
                return (
                    <div style={{
                        alignSelf: "flex-start"
                    }}>
                        <KnowledgeOptionsInput {...{ value, onChange, facade, currentUserId }} />
                    </div>
                )
            }
        },
        {
            name: "内容生成选项", label: "回答的创造性", input: ({ value, onChange }) => {
                return (
                    <div style={{
                        alignSelf: "flex-start"
                    }}>
                        <TemperatureInput {...{ value, onChange, }} />
                    </div>
                )
            }
        },
        {
            name: "内容生成选项", label: "是否联网查询", input: ({ value, onChange }) => {
                return (
                    <div style={{
                        alignSelf: "flex-start"
                    }}>
                        <FunctionToggle {...{ value, onChange, name: "OnlineSearch", label: "联网查询" }} />
                    </div>
                )
            }
        },
    ]
}
