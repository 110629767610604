

import React from 'react'

import { getFileIcon } from "bwax-ui/components/FileIcon";

import dayjs from 'dayjs';

import KnowledgeItemChat from './KnowledgeItemChat';
import FileView from 'bwax-ui/components/FileView';

export default function DocumentDetail({
    document, updateDocument,
    facade, viewEnv,

    botAvatarUrl, userAvatar, userNickName, currentUserId,

}) {

    function renderDocumentSummary() {
        return (
            <div className="flex gap-4 pt-2 pb-3 w-full">
                <div className="text-4xl">
                    {getFileIcon(document.文档)}
                </div>
                <div className="flex flex-col grow">
                    <div>{document.标题}</div>
                    <div className="font-size-12 text-[var(--gray10)]">{dayjs(document.创建时间).format("MM.DD")}</div>
                </div>
                <div className="flex flex-col">
                    {/* 这个按钮需要一个产品引导 */}
                    {/* <Button color="grass">进行深入分析</Button> */}
                </div>
            </div>
        )
    }


    function renderDocumentView() {
        return (
            <FileView file={document.文档} className="grow w-full shadow-md" facade={facade} />
        )

    };

    function renderChat(className) {
        return (
            <KnowledgeItemChat {...{
                item: document, 
                updateItem: updateDocument,

                scene: "doc-chat::" + document.id,
                entityName: "OpenAI-知识文档",

                facade, viewEnv,
                botAvatarUrl, userAvatar, userNickName, currentUserId,

                className,

                summaryTip: "请帮忙总结这份文档和提取要点",
            }} />
        )
    }

    return (
        <>
            <div className="hidden sm:flex h-full w-full font-size-14">
                <div className="w-1/2 grow pl-6 pr-2 py-2 flex flex-col">
                    {renderDocumentSummary()}
                    {renderDocumentView()}
                </div>
                <div className="w-1/2 grow pl-2 pr-4 pt-6 pb-2">
                    {renderChat("border-[1px] rounded !border-[var(--gray5)]")}
                </div>
            </div>
            <div className="flex flex-col sm:hidden h-full">
                <div className="w-full px-4">
                    {renderDocumentSummary()}
                </div>
                <div className="w-full grow pt-2 basis-0 min-h-0">
                    {renderChat()}
                </div>
            </div>
        </>
    )
}