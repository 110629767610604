// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json$BwaxMobile = require("../../../bwax-js/ml/utils/json.bs.js");
var Plate$BwaxMobile = require("../../../bwax-js/ml/plate.bs.js");

var mut_upload = "\n  mutation UploadAttachmentMutation(\n    $input: UploadAttachmentInput!\n  ) {\n    uploadAttachment(input: $input) {\n      addedAttachment {\n        node {\n          id\n          url\n          title\n          path\n          size\n          contentType\n        }\n      }\n    }\n  }\n";

function upload_file(query_runner, file, $$public, uploadFor, fileNamePreserved) {
  var handle_result = function (text) {
    var result = Json$BwaxMobile.parse(text);
    var partial_arg = /* :: */[
      "data",
      /* :: */[
        "uploadAttachment",
        /* :: */[
          "addedAttachment",
          /* :: */[
            "node",
            /* [] */0
          ]
        ]
      ]
    ];
    var file = Plate$BwaxMobile.$$Option.and_then((function (param) {
            return Json$BwaxMobile.get_by_path(partial_arg, param);
          }), result);
    return Promise.resolve(/* tuple */[
                file,
                undefined
              ]);
  };
  return Curry._3(query_runner, mut_upload, Js_dict.fromList(/* :: */[
                    /* tuple */[
                      "input",
                      {
                        clientMutationId: new Date().getTime().toString(),
                        public: $$public,
                        uploadFor: uploadFor,
                        fileNamePreserved: fileNamePreserved
                      }
                    ],
                    /* [] */0
                  ]), Caml_option.some(file)).then(handle_result);
}

exports.mut_upload = mut_upload;
exports.upload_file = upload_file;
/* Json-BwaxMobile Not a pure module */
