


import React, { useEffect, useRef, useState, useContext } from 'react'


import Loading from 'bwax-ui/components/Loading';
import Button from 'bwax-ui/components/Button';

import ChatStream from './chat/ChatStream';

// import { useTrack } from 'bwax-ui/track';

import UsageQuotaContext from 'bwax-ui/ml/widget/ports/inbot/UsageQuotaContext';

// import RichTextRenderer from 'bwax-ui/auxiliary/richtext_slate/RichTextRenderer';
// import { markdownToRichText } from 'bwax-ui/markdown/convertMarkdown';

const sessionFieldPaths = [
    "起始消息", "标题", "已归档", "用户选项", "内容生成选项", "chatModel",
    "会话角色.名称", "会话角色.用户选项", "会话角色.内容生成选项", "会话角色.欢迎消息",
    "修改时间",
]


export default function KnowledgeItemChat ({
    item, updateItem, 
    
    scene, entityName, summaryTip,

    facade, viewEnv,

    botAvatarUrl, userAvatar, userNickName, currentUserId,

    className
}) {

    const { reloadUsageQuota, remainingQuota } = useContext(UsageQuotaContext) || {};

    const [session, setSession] = useState();

    // 查询，如果没有就创建一个，还是说，等用户发送消息的时候再创建： 先为了简单起见，一开始就创建吧

    const userOptions = {
        possibleQuestionsEnabled: true,
        knowledgeOptions: {
            "isEnabled": true,
            "isLimited": true,
            "scope": [
                {
                    entityName,
                    "recordId": item.id,
                }
            ]
        }
    }

    async function createSession() {

        const [lastSceneSession, _] = await facade.findOne({
            entityName: "OpenAI-会话", sort: [{ field: "修改时间", order: "DESC" }], fieldPaths: ["内容生成选项", "chatModel", "场景"],
            condition: [
                [
                    {
                        field: "用户", op: "eq", value: currentUserId,
                    }, {
                        field: "场景", op: "startsWith", value: scene.split("::")[0]
                    },
                ]
            ],
        }, { forceRefreshing: true });
        
        const [createdSession, error] = await facade.add({
            entityName: "OpenAI-会话",
            // condition: [[{ field: "场景", op: "eq", value: scene}, { field: "创建者", op: "eq", value: currentUserId }]]
            formData: {
                场景: scene,
                // 有其他的默认项吗？
                用户: currentUserId,
                用户选项: userOptions,
                内容生成选项: lastSceneSession ? lastSceneSession.内容生成选项 : {
                    "temperature": 0.2,
                    "max_tokens": null,
                    "availableFunctions": []
                },
                chatModel: lastSceneSession && lastSceneSession.chatModel

            },
            fieldPaths: sessionFieldPaths,
        });

        setSession(createdSession)
    }

    useEffect(() => {
        // 直接用 scene
        (async () => {
            const [foundSession, error] = await facade.findOne({
                entityName: "OpenAI-会话",
                condition: [[
                    { field: "场景", op: "eq", value: scene },
                    { field: "用户", op: "eq", value: currentUserId },
                    { field: "已归档", op: "ne", value: true },
                ]],
                fieldPaths: sessionFieldPaths,
            });

            if (error) {
                // TODO handle error
                return
            }
            if (foundSession) {
                setSession(foundSession)

            } else {
                createSession()
            }
        })()

    }, [item.id]);

    async function updateSession(id, formData) {
        const [result, error] = await facade.update({
            entityName: "OpenAI-会话",
            formData,
            id,
            fieldPaths: sessionFieldPaths
        });
        return [result, error];
    }

    function makeSession(r) {
        return {
            id: r.id, firstMessage: r.起始消息, title: r.标题,

            userOptions, // r.用户选项,

            contentOptions: r.内容生成选项,
            chatModel: r.chatModel,
            persona: r.会话角色,
            updatedAt: r.修改时间,
        }
    }

    // document questions:
    const [documentQuestions, setDocumentQuestions] = useState();

    async function loadDocumentQuestions() {
        if(item.加入知识库) {
            const [result, error] = await facade.findById(item.id, {
                entityName,
                fieldPaths: ["可能的问题"],
            }, { forceRefreshing: true });
    
            if (!error && result) {
                setDocumentQuestions(result.可能的问题);
            }
        }

    };
    useEffect(() => {
        loadDocumentQuestions();
    }, [item.id, item.加入知识库]);


    async function refreshSession(sessionId) {
        const [result, error] = await facade.findById(sessionId, { entityName: "OpenAI-会话", fieldPaths: sessionFieldPaths },  { forceRefreshing: true });
        if (!error && result) {
            const newSession = makeSession(result);
            setSession(newSession)

        }
    }

    const [isAddingToKnowledge, setIsAddingToKnowledge] = useState(false);
    async function addToKnowledge() {
        setIsAddingToKnowledge(true)
        await updateItem(item.id, { 加入知识库: true });
        setIsAddingToKnowledge(false);
    }

    function renderAddToKnowledge() {
        return (
            <div className="py-12 px-12 flex flex-col shrink-0 items-center gap-4 border-t">
                <div>
                    <Button appearance="primary" color="grass" isLoading={isAddingToKnowledge} onPress={_ => {
                        addToKnowledge()
                    }}>加入知识库</Button>
                </div>
                <div className="text-[var(--gray11)] flex flex-col items-center gap-1">
                    <span>把文档加入知识库后</span>
                    <span>你可以向 AI 助手提相关问题</span>
                </div>
            </div>
        )
    }


    if (!session) {
        return (
            <div className="h-1/2 flex flex-col justify-center items-center">
                <Loading />
            </div>
        )
    } else {

        // const summaryTip = "请帮忙总结这份文档和提取要点";

        return (
            <ChatStream {...{
                facade, viewEnv,

                // additional,
                currentSessionId: session && session.id,
                currentSession: makeSession(session),
                updateSessionAndList: async (sessionId, formData) => {
                    const [result, error] = await updateSession(sessionId, formData);
                    if (!error) {
                        setSession(result)
                    }
                },

                refreshSession: sessionId => {
                    refreshSession(sessionId)
                },

                reloadUsageQuota,
                remainingQuota,

                botAvatarUrl, userAvatar, userNickName,
                userId: currentUserId,
                // instructionPrompt,

                maxInputCount: 1000,

                className,

                showConfig: false,
                directToKnowledge: true,

                imageEnabled: false,

                getPossibleQuestions: (session, messages) => {
                    const allQuestions = [
                        // ...sessionQuestions, 
                        summaryTip, ...(documentQuestions || [])
                    ].filter(x => !!x);

                    return allQuestions.filter(q => {
                        return !messages.find(m => m.content && m.content.trim() == q.trim());
                    }).slice(0, 4);
                },

                possibleQuestionsExpandByDefault: true,

                renderInputBox: !item.加入知识库 ? renderAddToKnowledge : null,


            }} />
        )

    }


}