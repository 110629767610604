import React from 'react'

export default (style) => {
    const styleMap = {
        'BOLD': {
            fontWeight: "bold"
        },
        'ITALIC': {
            fontStyle: "italic"
        },
        'UNDERLINE': {
            textDecoration: "underline"
        }
    }
    return styleMap[style]
}
