
import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom';
import { RiCheckLine, RiDeleteBinLine, RiArrowGoBackLine, RiTextWrap, RiEditLine, RiQuestionMark } from 'react-icons/ri'
import { MdOutlineShortText, MdTranslate, MdOutlineCoffee } from 'react-icons/md'
import { GrTextAlignFull } from 'react-icons/gr'
import { IoIosColorWand, IoIosArrowForward } from 'react-icons/io'
import { TbBlockquote, TbTextCaption } from 'react-icons/tb'
import { isIOS, isMobile } from 'bwax/clientEnv'

import './AiInputMenu.less';
import { ReactEditor } from 'slate-react'

export const trimLanguageSign = (respondingText) => {
    // it is like "ja|企業にとって"
    const index = respondingText.indexOf("|");
    if (index == -1) {
        if (respondingText.length > 4) {
            // 除了问题，就让他显示吧
            return respondingText
        } else {
            return ""
        }

    } else {
        if(index < 4) {
            return respondingText.substring(index + 1);
        } else {
            // 不是语言标识，就让他显示吧
            return respondingText
        }
        
    }
}

const sameLanguageRewriteOperation = ({ name, label, prompt, icon, expectedOutputTokenNum, expectedOutputTokenRatio }) => ({ isFutherRewrite = false } = {}) => {
    return {
        label, prompt, icon, name,
        
        expectedOutputTokenNum, expectedOutputTokenRatio,

        knowledgeOff: true, // 修饰、编辑时，基本都不用查找知识的

        buildTemplateInput: ({ selectedContent, previousRespondingText }) => {
            const givenText = isFutherRewrite ? previousRespondingText : selectedContent;
            return {
                promptTemplateName: "同语言改写",
                promptVariables: {
                    "原文": givenText,
                    "提示": prompt,
                }
            }
        },
        transformRespondingText: trimLanguageSign

    }
};

// const improveWriting = sameLanguageRewriteOperation({ icon: <IoIosColorWand />, label: '润色',  prompt: "Improve the quality and clarity of the piece of writing", expectedOutputTokenRatio: 1.5, });

// const makeLonger = sameLanguageRewriteOperation({ icon: <GrTextAlignFull />, label: '详细一些', prompt: "改写得详细一点", expectedOutputTokenRatio: 2.5, });

const improveWriting = ({ isFutherRewrite = false } = {}) => {
    return { 
        icon: <IoIosColorWand />, label: '润色', 
        name: "improve-writing",
        expectedOutputTokenRatio: 1.5, 

        knowledgeOff: true, // 修饰、编辑时，基本都不用查找知识的

        buildTemplateInput: ({ selectedContent, previousRespondingText }) => {
            const givenText = isFutherRewrite ? previousRespondingText : selectedContent;
            return {
                promptTemplateName: "润色",
                promptVariables: {
                    "原文": givenText,
                }
            }
        },
        transformRespondingText: trimLanguageSign
    } 
};


const makeLonger = ({ isFutherRewrite = false } = {}) => {
    return {
        label: "详细一些",  icon: <GrTextAlignFull />, 
        name: "make-longer",
        
        expectedOutputTokenRatio: 4,

        knowledgeOff: true, // 修饰、编辑时，基本都不用查找知识的

        buildTemplateInput: ({ selectedContent, previousRespondingText }) => {
            const givenText = isFutherRewrite ? previousRespondingText : selectedContent;
            return {
                promptTemplateName: "详写",
                promptVariables: {
                    "原文": givenText,
                }
            }
        },
        transformRespondingText: trimLanguageSign

    }
};


const makeShorter = sameLanguageRewriteOperation({ icon: <MdOutlineShortText />, label: '简洁一些', prompt: "改写得简洁一点", expectedOutputTokenRatio: 1,});


// 有两类 operations，一类是 triggering， 另一类是 following operations
const __triggeringOperations = {
    /// no selection
    "continue-writing": {
        icon: <RiEditLine />, label: '接着写',
        name: "continue-writing",

        buildTemplateInput: ({ precedingContent }) => {
            // const givenText = isFutherRewrite ? previousRespondingText : selectedContent;
            return {
                promptTemplateName: "接着写",
                promptVariables: {
                    "前文": precedingContent,
                }
            }
        },
        transformRespondingText: trimLanguageSign
    },

    // with selection
    "improve-writing": improveWriting(),

    "make-longer": makeLonger(),

    "make-shorter": makeShorter(),

    "change-tone": {
        isRewrite: true,

        knowledgeOff: true,

        templateName: "改变语气",

        icon: <MdOutlineCoffee />, label: "改变语气",

        using: "selectedContent",

        expectedOutputTokenRatio: 2, // 跟原文差不多

        subOperations: [
            ["正式", "formal"],
            ["专业", "professional"],
            ["自信", "confident"],
            ["风趣", "humorous"],
            ["轻松", "casual"],
            ["严肃", "serious"],
            ["强硬", "assertive"],
        ].map(([label, tone]) => {
            return {
                label, value: tone, name: tone,
                buildTemplateInput: ({ selectedContent }) => {
                    return {
                        promptTemplateName: "改变语气",
                        promptVariables: {
                            "原文": selectedContent,
                            "语气": tone,
                        }
                    }
                },
                transformRespondingText: trimLanguageSign
            }
        })
    },


    "translate": {
        isRewrite: true,
        icon: <MdTranslate />, label: '翻译',
        using: "selectedContent",

        knowledgeOff: true,

        expectedOutputTokenRatio: 3, // 虽然跟原文差不多，但是不同语言相差还是挺多的。

        subOperations: ["简体中文", "繁体中文", "英语", "日语", "韩语", "西班牙语", "法语", "德语", "意大利语", "阿拉伯语", "俄语", "泰语", "越南语"].map(lang => ({
            label: lang, value: lang, name: lang,
            prompt: `把原文翻译成${lang}`
        })),
        // subOperations: [{
        //     label: "英文", value: "english",

        //     prompt: "把原文翻译成英文"

        // }, {
        //     label: "中文", value: "chinese",

        //     prompt: "把原文翻译成中文",
        // }, {
        //     label: "日文", value: "japanese",

        //     prompt: "把原文翻译成日文"
        // }]
    },

    //
    "summarize": {
        isRewrite: true,

        knowledgeOff: true,

        using: "selectedContent",

        icon: <TbBlockquote />, label: '总结',

        subOperations: [{
            label: "30 字以内", value: "30", expectedOutputTokenNum: 45,
            name: "30",
            prompt: "总结原文，限制在 30 字以内"
        }, {
            label: "100 字以内", value: "100", expectedOutputTokenNum: 150,
            name: "100",
            prompt: "总结原文，限制在 100 字以内", 
        }, {
            label: "200 字以内", value: "200", expectedOutputTokenNum: 300,
            name: "200",
            prompt: "总结原文，限制在 200 字以内"
        }, {
            label: "300 字以内", value: "300", expectedOutputTokenNum: 450,
            name: "300",
            prompt: "总结原文，限制在 300 字以内"
        }]
    },

    "explain": {
        using: "selectedContent",

        icon: <RiQuestionMark />, label: '解释'
    },


    "summarize-all": {
        name: "summarize-all",
        icon: <TbTextCaption />, label: '总结全文',
        
        expectedOutputTokenNum: 600,

        knowledgeOff: true, // 修饰、编辑时，基本都不用查找知识的

        buildTemplateInput: ({ allContent }) => {
            // const givenText = isFutherRewrite ? previousRespondingText : selectedContent;
            return {
                promptTemplateName: "总结",
                promptVariables: {
                    "原文": allContent,
                }
            }
        },
        transformRespondingText: trimLanguageSign
    },

    "summarize-previous": {
        name: "summarize-previous",
        icon: <TbBlockquote />, label: '总结上文',
        
        expectedOutputTokenNum: 600,

        useFullPrecedingContent: true,
        knowledgeOff: true, // 修饰、编辑时，基本都不用查找知识的

        buildTemplateInput: ({ precedingContent }) => {

            // const givenText = isFutherRewrite ? previousRespondingText : selectedContent;
            return {
                promptTemplateName: "总结",
                promptVariables: {
                    "原文": precedingContent,
                }
            }
        },
        transformRespondingText: trimLanguageSign

    }



}
const triggeringOperations = Object.keys(__triggeringOperations).reduce((acc, name) => {
    return {
        ...acc, [name]: { name, ...__triggeringOperations[name] }
    }
}, {});


function getActionOperations(action, { contents = {} }) {

    if (!action) {
        return []
    }

    // Trigger 写作
    const editOperations = [{
        title: '调整选中的文本',
        operations: ["improve-writing", "make-longer", "make-shorter", "change-tone"]
    }]

    const writeWithAiOperations = contents.precedingContent ? [{
        title: 'AI 写作',
        operations: ["continue-writing"]
    }] : []

    const generateFromSelected = [{
        title: "从选中的文本生成内容",
        operations: ["summarize", "translate"]
    }]

    const generatedFromPageOperations = contents.allContent ? [{
        title: '从笔记中生成内容',
        operations: [
            "summarize-all", 
            // "summarize-previous"
        ]
    }] : []


    // 其实着不同的 “action” 可以通过查看 contents 里的 selectedContent 和  presonseRespongText 来判断：
    // activebySpace = !s + !p
    // finishWriting = !s + p
    // activeBySelect = s + !p
    // finishWritingForReplacement = !s + !p
    
    const actionOperations = {
        activeBySpace: [
            ...writeWithAiOperations,
            ...generatedFromPageOperations
        ],
        finishWriting: [
            {
                operations: [
                    {
                    icon: <RiCheckLine />, label: '完成', name: "done", isEnabledWhenOutOfQuota: true,
                }, {
                    icon: <RiEditLine />, label: '继续', name: "keep-going", isContinue: true, prompt: "接着写", using: "previousRespondingText"
                // }, {
                //     name: "make-longer", ...makeLonger({ isFutherRewrite: true }),  isRewrite: true
                // }, {
                //     name: "make-shorter", ...makeShorter({ isFutherRewrite: true }),  isRewrite: true
                }]
            }, {
                operations: [{
                    icon: <RiArrowGoBackLine />, label: '重新写', name: "try-again", isRewrite: true
                }, {
                    icon: <RiDeleteBinLine />, label: '放弃', name: "discard", isEnabledWhenOutOfQuota: true,
                }]
            }
        ],

        /////
        activeBySelect: [...editOperations, ...writeWithAiOperations, ...generateFromSelected],
        finishWritingForReplacement: [
            {
                operations: [{
                    icon: <RiCheckLine />, label: '替换选中的内容', name: "replace-selection"
                }, {
                    icon: <RiTextWrap />, label: '在选中内容下方添加', name: "insert-below"
                }, {
                    ...makeLonger({ isFutherRewrite: true }), label: '再详细一些', name: "make-longer",  isRetryForRelacement: true, isRewrite: true,
                }, {
                    ...makeShorter({ isFutherRewrite: true }), label: '再简洁一些', name: "make-shorter", isRetryForRelacement: true, isRewrite: true
                }
                ]
            }, {
                operations: [{
                    icon: <RiArrowGoBackLine />, label: '重新写', name: "try-again", isRetryForRelacement: true, isRewrite: true,
                }, {
                    icon: <RiDeleteBinLine />, label: '放弃', name: "discard"
                }]
            }
        ],
    }

    return actionOperations[action]
}


function isEmpty(string) {
    return !string || !(string.trim())
}

export default function AiInputMenu({ action, selectOperation, contents, containerDOM, isOutOfQuota, textareaAutosizeRef, editor, mobileToolbarRef }) {


    const [hoveredOperation, setHoveredOperation] = useState(null)
    const [hoveredPosition, setHoveredPosition] = useState(null)
    const [hoveredOperationIndex, setHoveredOperationIndex] = useState() // groupIndex, itemIndex
    const [subOperationsModalVisible, setSubOperationsModalVisible] = useState(false) // 移动端 subOperations 通过 modal 显示
    const menuRef = useRef()
    const subMenuRef = useRef()
    

    useEffect(() => {
        if(menuRef.current && subMenuRef.current) {
            const menuRect = menuRef.current.getBoundingClientRect()
            const subMenuRect = subMenuRef.current.getBoundingClientRect()
            const { height, bottom, top } = subMenuRect
            const containerDomTop = containerDOM.getBoundingClientRect().top
            const hoveredPositionCenter = hoveredPosition.bottom - hoveredPosition.height / 2 // hoveredPosition 中点
            const subMenuRectCenter = bottom - height / 2 // subMennu 中点

            const originalTop = subMenuRef.current.style.top
            const originalTopValue = originalTop ? parseFloat(originalTop.match(/^(-?\d.*)px$/)[1]) : 0

            if(bottom > containerDOM.offsetHeight + containerDomTop) {
                // subMenu 超出了 containerDOM
                const beyondHeight = bottom - (containerDOM.offsetHeight + containerDomTop)
                subMenuRef.current.style.top = `${originalTopValue - beyondHeight}px`
            } else {
                // 保持 subMenu 中点与 hoveredPosition 中点在同一水平线上，且 subMenu 不超过 containerDOM
                const topGap = top - menuRect.top
                const bottomGap = containerDomTop + containerDOM.offsetHeight - hoveredPosition.bottom // hoveredPosition 底部距离 containerDOM 底部的高度

                if(subMenuRectCenter < hoveredPositionCenter) {
                    // subMenu 偏上，将 subMenu 下移
                    const beyondHoveredHeightDown = bottomGap > height / 2 ? height / 2 : bottomGap
                    const moveDownHeight = hoveredPositionCenter - bottom + beyondHoveredHeightDown

                    subMenuRef.current.style.top = `${originalTopValue + moveDownHeight}px`
                } else {
                    // subMenu 偏下，将 subMenu 上移
                    const beyondHoveredHeightUp = topGap > height / 2 ? height / 2 : topGap
                    const moveDownHeight = top - hoveredPositionCenter + beyondHoveredHeightUp

                    subMenuRef.current.style.top = `${originalTopValue - moveDownHeight}px`
                }
                
            }
        }
    }, [hoveredOperation, hoveredPosition])

    const groups = getActionOperations(action, { contents }).map((group, groupIndex) => {
        const { title, operations } = group

        const items = operations.map((op, itemIndex) => {

            const operation = typeof (op) == "string" ? {                
                ...(triggeringOperations[op]),
                name: op,
            } : op
            
            const { 
                icon, label, using, subOperations, isEnabledWhenOutOfQuota 
            } = operation

            const isHovered = hoveredOperationIndex && groupIndex === hoveredOperationIndex.gIndex && itemIndex === hoveredOperationIndex.iIndex

            if (using && isEmpty(contents[using])) {
                return null
            }

            const isDisabled = isOutOfQuota && !isEnabledWhenOutOfQuota;

            return (
                <div key={operation.label} className={`operation font-size-14${isHovered ? 'hover' : '' }`}
                    data-disabled={isDisabled} 
                    onClick={() => {
                        // subOperations ? null : selectOperation(operation)
                        if(!subOperations && !isDisabled) {
                            selectOperation(operation)
                        }
                    }}
                    onMouseEnter={e => {
                        // mouse leave 时不隐藏 subMenu(notion)
                        setHoveredOperation(operation)
                        setHoveredOperationIndex({ gIndex: groupIndex, iIndex: itemIndex })
                        if(operation.subOperations) {
                            // 保存当前 operation 的位置信息
                            const rect = e.target.getBoundingClientRect()
                            setHoveredPosition(rect)
                        }

                        if(subOperations && isMobile()) {
                            setSubOperationsModalVisible(true)
                            textareaAutosizeRef.current.blur()
                            if(isIOS()) {
                                ReactEditor.blur(editor)
                            }

                            // mobile 中弹出二级菜单时，设置 mobile-toolbar 的 zIndex，覆盖页面的其他内容
                            if(mobileToolbarRef && mobileToolbarRef.current) {
                                mobileToolbarRef.current.style['zIndex'] = 1
                            }
                        }
                    }}
                >
                    {icon}
                    <span>{label}</span>
                    {subOperations ? <IoIosArrowForward /> : null}
                </div>
            )
        }).filter(x => !!x);

        if (items.length == 0) {
            return null
        }

        return (
            <div key={groupIndex} className="operations-group">
                <div className='ai-operations-container'>
                    {
                        title ? (
                            <p>{title}</p>
                        ) : null
                    }
                    {items}
                </div>
            </div>
        )
    }).filter(x => !!x);

    if (groups.length == 0) {
        return null
    }

    function resetMobileToolbarZIndex () {
        if(mobileToolbarRef && mobileToolbarRef.current) {
            mobileToolbarRef.current.style['zIndex'] = ''
        }
    }

    function renderSubMenuItems() {
        const { subOperations } = hoveredOperation;

        function selectSubOperation(subItem) {
            selectOperation({
                ...hoveredOperation,
                ...subItem,
                parentName: hoveredOperation.name,
            })
        }

        return subOperations.map((subItem, index) => {
            const { label, value, isEnabledWhenOutOfQuota } = subItem

            const isDisabled = isOutOfQuota && !isEnabledWhenOutOfQuota;

            return (
                <div key={index} className="sub-item upward-menu-item" data-disabled={isDisabled} onClick={() => {
                    if(!isDisabled) {
                        selectSubOperation(subItem)
                    }
                    
                    if(isMobile()) {
                        resetMobileToolbarZIndex()
                    }
                }}>
                    {label}
                </div>
            )
        })
    }

    function renderOperationSubMenu() {
        if (!hoveredOperation || !hoveredOperation.subOperations) {
            return null
        }

        return (
            <div className='ai-operation-sub-menu' ref={subMenuRef}>
                {renderSubMenuItems()}
            </div>
        )
    }

    function renderMobileSubMenu() {

        // 48 为 top-menu 的高度
        return subOperationsModalVisible ? (
            <div className={`editor-mobile-upward-menu ${subOperationsModalVisible ? 'show' : ''}`}
                style={isIOS() ? { marginTop: 48 } : {}}
            >
                <div className='header'>
                    <div style={{ color: "var(--blue9)" }} onClick={() => {
                        textareaAutosizeRef.current.focus()
                        setSubOperationsModalVisible(false)
                        resetMobileToolbarZIndex()
                    }}>Done</div>
                </div>
                <div className='items-container'>
                    {renderSubMenuItems()}
                </div>
            </div>
        ) : null
    }

    return (
        <div className={`ai-operation-menu-box`}>
            <div className='ai-operation-menu' ref={menuRef}>
                { groups }
            </div>
            {renderOperationSubMenu()}
            {renderMobileSubMenu()}
        </div>
    )
}
