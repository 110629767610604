import cb from './cb'
import viprights from './viprights'
import vipservices from './vipservices'
import howitworks from './howitworks'

export default {
    ...cb,
    ...viprights,
    ...vipservices,
    ...vipservices,
    ...howitworks
}

