
import throttle from "lodash/throttle";

export default class ScrollHelper {

    // options includes: 
    // { topThreshold, bottomThreshold, onScrolledToTop, onScrolledToBottom }
    constructor(element, options) {
        // add a listener 
        this.lastScrollStatus = undefined;
        this.options = options;
        this.element = element;

        this.__throttledScrollHandler = throttle(this.__handleWindowScroll, 32);

        element.addEventListener('scroll', this.__throttledScrollHandler)

    }

    cleanUp() {
        this.element.removeEventListener('scroll', this.__throttledScrollHandler)
    }

    __handleWindowScroll = () => {
        // TODO need throttle
        const lastScrollStatus = this.lastScrollStatus;
        const scrollStatus = getScrollStatus(this.element, lastScrollStatus);
        const { 
            topThreshold = 64, bottomThreshold = 64,
            onScrolledToTop = _ => {
                console.log("Scolled to top")
            },
            onScrolledToBottom = _ => {
                console.log("Scrolled to bottom")
            },
            onScrolled = _ => {},
        } = this.options;

        const isScrolledToTop = status => {
            return (status.y < topThreshold && status.direction === "up");
        }
        

        const isScrolledToBottom = status => {
            const { y, scrollHeight, clientHeight, direction} = status;
            return direction === "down" && (
                scrollHeight - (y + clientHeight) < bottomThreshold
            )
        }

        if(isScrolledToTop(scrollStatus) && !isScrolledToTop(lastScrollStatus)) {
            onScrolledToTop();
        }

        if(isScrolledToBottom(scrollStatus) && !isScrolledToBottom(lastScrollStatus)) {
            onScrolledToBottom();
        }


        onScrolled && onScrolled(scrollStatus);


        this.lastScrollStatus = scrollStatus;

    }

}


function getScrollStatus(element, lastScrollStatus) {

    const left = element.scrollLeft - (element.clientLeft || 0)
    const top = element.scrollTop - (element.clientTop || 0)
    //console.log('lastScrollStatus : ', lastScrollStatus, ', top : ', top);

    // console.log("D", doc.scrollTop, doc.clientTop, doc.clientHeight, doc.scrollHeight);

    const direction = lastScrollStatus && typeof lastScrollStatus.y === 'number' ?
        top > lastScrollStatus.y ? 'down' :
            top < lastScrollStatus.y ? 'up' :
                lastScrollStatus.direction : null

    return { 
        x: left, y: top, direction, 
        scrollHeight: element.scrollHeight, clientHeight: element.clientHeight,
    }
}
