
// This file is generated. Don't modify it.
import React from 'react'
export default function ArrowUpIcon (props) {

    const { color } = props
    
    return (
                <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M10.9748721,8 C10.9752019,8.12839171 10.926387,8.25688624 10.8284271,8.35484607 L5.87867966,13.3045935 C5.68341751,13.4998557 5.36683502,13.4998557 5.17157288,13.3045935 C4.97631073,13.1093314 4.97631073,12.7927489 5.17157288,12.5974868 L9.76905963,8 L5.17157288,3.40251324 C4.97631073,3.2072511 4.97631073,2.89066861 5.17157288,2.69540646 C5.36683502,2.50014431 5.68341751,2.50014431 5.87867966,2.69540646 L10.8284271,7.64515393 C10.926387,7.74311376 10.9752019,7.87160829 10.9748721,8 Z" id="ArrowUpIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Arrow/Up">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="ArrowUpIcon-mask-2" fill="white">
                            <use href="#ArrowUpIcon-path-1"></use>
                        </mask>
                        <use id="Path" fill={color} fillRule="nonzero" transform="translate(0 15) rotate(-90)" href="#ArrowUpIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
