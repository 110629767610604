
import React from 'react';

import SelectInput from 'bwax-ui/components/inputs/SelectInput';
import TextInput from 'bwax-ui/components/inputs/TextInput';

import FileInput from './FileInput';
import TextArea from 'bwax-ui/components/inputs/TextArea';

export default function getFieldInput (field, entityName, { facade, constraints }) {

    const inputs = {
        Select: ({ value, onChange }) => {
            const { options } = field.options;

            const disabledValues = constraints.flatMap(c => c.disabledValues ? c.disabledValues : []);

            return (
                <SelectInput {...{ 
                    items: options.map(o => ({
                        value: o.value,
                        label: o.name,
                        isDisabled: disabledValues.indexOf(o.value) !== -1
                    })),
                    placeholder: "请选择",
                    style: { minWidth: "8rem" },
                    selected: value || null,
                    onSelect: onChange,
                }} />
            )
        },
        File: ({ value, onChange }) => {

            return (
                <FileInput {...{
                    uploadFor: entityName,
                    accept: field.options && field.options.accept,
                    facade,
                    value, onChange,
                }} />
            )
        },
        ShortText: ({ value, onChange, placeholder, label, autoFocus }) => {
            return (
                <TextInput {...{
                    value: value || "", onChange, placeholder, label, autoFocus,
                }} />
            )
        },
        Text: ({ value, onChange, placeholder, autoFocus }) => {
            return (
                <TextArea {...{ value: value || "", onChange, placeholder, autoFocus }} />
            )
        }
    }

    const i = inputs[field.type];
    if(!i) {
        console.log(">>> cant find input for", field);
    }

    return i
    

}