

import React, { useContext, useState } from 'react'

import './OutOfQuotaTip.less'


import Dialog from 'bwax-ui/components/Dialog'

import SubscriptionPayment_inbot from 'bwax-ui/ml/widget/ports/inbot/SubscriptionPayment_inbot';

import { Pressable } from "bwax-ui/components/Button"

import UsageQuotaContext from 'bwax-ui/ml/widget/ports/inbot/UsageQuotaContext';

import { isWeChat } from 'bwax/clientEnv'

import { useTrack } from 'bwax-ui/track';
import classNames from 'classnames';

export default function OutOfQuotaTip({ style, label , showPayBtn = true, className }) {

    const { currentPlanId, currentPlanExpiryDate, reloadUsageQuota, viewEnv, facade } = useContext(UsageQuotaContext) || {};

    const { routeTo } = viewEnv;
    const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);

    const track = useTrack();

    function goToPayment() {
        track("pay_click_to_pay_from_tip");
        if (isWeChat()) {
            routeTo("/ext/pay/sub?_tenant_=" + facade.dlc.tenantCode + "&rt=" + encodeURIComponent(viewEnv.webEnv.currentURL));
        } else {
            setPaymentDialogOpen(true)
        }
    }

    return (
        <>
            <div className={classNames("lc-out-of-quota-tip", className)} style={style}>
                <i className='bx bx-info-circle'></i>
                <span>{ label || "您已用完今天的限额" }</span>
                    { showPayBtn ? (
                        <>
                            <span>，</span><Pressable onPress={goToPayment}><span className="pay-btn px-0.5 cursor-pointer">点击升级</span></Pressable>
                        </>
                    ) : null}                
            </div>
            <Dialog {...{
                open: paymentDialogOpen, onOpenChange: setPaymentDialogOpen, isMain: true,
                content: close => (
                    <SubscriptionPayment_inbot
                        facade={facade}
                        data={{ currentPlanId, currentPlanExpiryDate }}
                        events={{ reloadUsageQuota }}
                        viewEnv={viewEnv}
                        closeDialog={close}
                    />
                )
            }} />
        </>
    )
}
