

// Import the Slate editor factory.
import { Transforms, Editor, Node, Element as SlateElement, Path } from 'slate'

import { insertImage } from './utils/ImageHelper';

// Import the Slate components and React plugin.
import handlePastedText from './handlePastedText'
import { insertMarkdownTextToEditor } from './handleMarkdownText';
import { markdownToRichText } from 'bwax-ui/markdown/convertMarkdown';
import { liftMediaNodes } from 'bwax-ui/markdown/convertMarkdown'

import { isList } from './SlateEditor';
import { shiftTabF, getCurrentBlock } from './handleKeydown';

export default function (editor, uploadImage) {
    const { insertText, insertData } = editor

    editor.insertData = data => {
        const htmlText = data.getData('text/html')
        const commonText = data.getData('text')
        const isSlateContent = !!data.getData('application/x-slate-fragment') // 复制的内容是 slate content
        const plainText = data.getData('text/plain')
        const mediaTypes = ['image', 'video', 'audio']
        const { files } = data
        if (files && files.length > 0) {
            for (const file of files) {
                const [mime] = file.type.split('/')

                if (mime === 'image') {
                    insertImage(file, uploadImage, editor)
                }
            }
        } else {
            const { selection } = editor
            const { focus } = selection
            const [parentNode, parentPath] = Editor.parent(editor, focus.path)
            const [currentBlockNode, currentBlockPath] = getCurrentBlock(editor, 
                parentNode.type === 'link' ? Path.parent(parentPath) : parentPath
            )
                    
            function insertNewLine () {
                const currentBlockIndex = currentBlockPath[currentBlockPath.length - 1]
                Transforms.insertNodes(editor, 
                    { type: 'paragraph', children: [{ text: '' }] }, 
                    { 
                        at: currentBlockPath.toSpliced([currentBlockPath.length - 1], 1, currentBlockIndex + 1), 
                        select: true 
                    }
                )
            }

            function convertNodes (nodes) {
                const convertedNodes = nodes.map((node, index) => {
                    if(index === 0) {
                        if(isList(node.type)) {
                            // 添加一个 text node，此 text node 的内容是 list 中第一个 paragraph 的文本
                            const descendants = Array.from(Node.descendants(node, { reverse: true }))
                            const firstParagraph = descendants[descendants.length - 1]
                            const [firstParagraphNode, _] = firstParagraph
                            const firstParagraphContent = Node.string(firstParagraphNode)
                            const hasNestWrapper = descendants.some(descendant => {
                                const [node, _] = descendant
                                return node.type === 'nestWrapper'
                            })
                            if(hasNestWrapper) {
                                return [{ text: firstParagraphContent }, node]
                            } else {
                                const listItems = node.children
                                if(listItems.length > 1) {
                                    return [{ text: firstParagraphContent }, {...node, children: [...listItems.slice(1)]}]
                                } else {
                                    return [{ text: firstParagraphContent }]
                                }
                            }
                        } else {
                            return { text: Node.string(node) }
                        }
                        
                    } else {
                        return node
                    }
                }).flat()

                return convertedNodes
            }

            function insertNodes (nodes) {
                function defaultInsertF () {
                    if(isSlateContent) {
                        insertData(data)
                    } else {
                        Transforms.insertFragment(editor, nodes)
                    }
                }

                function deleteOriginalFirstParagraph (firstParagraphPath, nextPath) {

                    const originalParagraphPath = [...nextPath, ...firstParagraphPath]
                    Transforms.removeNodes(editor, { at: originalParagraphPath })
                    const parentOfParagraphAfterRemove = Node.parent(editor, originalParagraphPath)

                    if(parentOfParagraphAfterRemove.type === 'nestWrapper' &&
                        parentOfParagraphAfterRemove.children.length === 1
                    ) {
                        Transforms.unwrapNodes(editor, { at: Path.parent(originalParagraphPath) })
                    }
                }

                function removeEmptyListItem (targetPath) {
                    const [nodeAtTargetPath, _] = Editor.node(editor, targetPath)
                    if(Node.string(nodeAtTargetPath) === '' && nodeAtTargetPath.type === 'list-item' &&
                        nodeAtTargetPath.children[0].text === ''
                    ) {
                        Transforms.removeNodes(editor, { at: targetPath })
                    }
                }

                if(currentBlockNode.type.match(/^h\d$/)) {
                    // 如果当前 block 是 heading，且复制的第一项不是纯文本，或者复制了多段内容，新增一个空 block
                    if((!(nodes[0].text || nodes[0].type === 'paragraph') ||
                        mediaTypes.includes(nodes[0].type) || nodes.length > 1
                    )) {
                        insertNewLine()
                        defaultInsertF()
                    } else {
                        insertText(plainText)
                    }
                } else if(currentBlockNode.type === 'block-quote') {
                    if(mediaTypes.includes(nodes[0].type)) {
                        // 第一项是 media 元素，另起一行并插入内容
                        insertNewLine()
                        defaultInsertF()
                    } else {
                        const convertedNodes = convertNodes(nodes)
                        Transforms.insertFragment(editor, convertedNodes)
                        if(convertedNodes[1] && isList(convertedNodes[1].type)) {
                            // 如果 convertedNodes 的第二项是 list，要删除此 list 的第一个 paragraph
                            const [nextNode, nextPath] = Editor.next(editor, {
                                at: Path.parent(selection.focus.path)
                            })
                            const descendants = Array.from(Node.descendants(nextNode))
                            const firstNestWrapper = descendants.find(descendant => {
                                const [node, _] = descendant
                                return node.type === 'nestWrapper'
                            })
                            
                            if(isList(nextNode.type) && !!firstNestWrapper) {
                                // 删掉 nextList 中的第一项 paragraph
                                const descendantsOfNextNode = Array.from(Node.descendants(nextNode))
                                const [_, firstParagraphPath] = descendantsOfNextNode.find(descendant => {
                                    const [node, _] = descendant
                                    return node.type === 'paragraph'
                                })
                                deleteOriginalFirstParagraph(firstParagraphPath, nextPath)

                                const [__, liPathOfFirstParagraph] = descendantsOfNextNode.find(descendant => {
                                    const [node, path] = descendant
                                    return node.type === 'list-item' && firstParagraphPath.length - path.length <= 2
                                })

                                const originalLiPath = [...nextPath, ...liPathOfFirstParagraph]

                                for (let i = 0; i < liPathOfFirstParagraph.length; i++) {
                                    const targetPath = originalLiPath.slice(0, originalLiPath.length - i)
                                    Transforms.liftNodes(editor, {
                                        at: nextPath,
                                        match: (n, p) => p.length === targetPath.length + 1 && p.length > nextPath.length && 
                                            (isList(n.type) || n.type === 'list-item')
                                    })
                                    if(i === liPathOfFirstParagraph.length - 1) {
                                        removeEmptyListItem(targetPath)
                                    }
                                }
                            } else {
                                // 如果不是 list，则直接删除
                                Transforms.removeNodes(editor, { at: nextPath })
                            }
                            
                        }
                    }
                } else if(isList(currentBlockNode.type)) {
                    if(mediaTypes.includes(nodes[0].type)) {
                        // 如果第一项是 media，分割 currentBlockNode
                        const { path } = selection.focus
                        Transforms.insertNodes(editor, { type: 'paragraph', children: [{ text: '' }] }, {
                            at: [...path.slice(0, 2), path[2] + 1],
                            select: true
                        })
                        // lift 两次，将 paragraph 提升到第一级
                        Transforms.liftNodes(editor)
                        Transforms.liftNodes(editor)
                        
                        defaultInsertF()
                    } else {
                        const convertedNodes = convertNodes(nodes)
                        Transforms.insertFragment(editor, convertedNodes)
                        const [nodeNextOriginalParagraph, pathNextOriginalParagraph] = Editor.next(editor, {
                            at: Path.parent(selection.focus.path)
                        })

                        const [currentListItem, currentListItemPath] = getCurrentBlock(editor, selection.focus.path, 'list-item')

                        if(currentListItem.children.some(child => child.type === 'block-quote' || child.type.match(/^h\d$/) ||
                            mediaTypes.includes(child.type)
                        )) {
                            // 如果有不是 paragraph 的 node, 分割 currentBlockNode
                            Transforms.liftNodes(editor, {
                                at: currentListItemPath,
                                match: (_, p) => {
                                    return Path.isChild(p, currentListItemPath) && 
                                        Path.compare(p, Path.parent(selection.focus.path)) === 1
                                }
                            })
                            Transforms.liftNodes(editor, {
                                at: Path.parent(currentListItemPath),
                                match: (n, p) => {
                                    return SlateElement.isElement(n) && p.length === currentListItemPath.length && 
                                        n.type !== 'list-item'
                                }
                            })
                        } else if(currentListItem.children.some(child => child.type === 'list-item')) {
                            Transforms.wrapNodes(editor, { type: 'list-item' }, { match: n => n.type === "paragraph" })
                            if(convertedNodes[1] && isList(convertedNodes[1].type)) {
                                if(convertedNodes[1].type === currentBlockNode.type) {
                                    // 如果是与 currentBlockNode 相同类型的 list，则直接 lift list-item
                                    Transforms.liftNodes(editor, {
                                        at: currentListItemPath,
                                        match: n => n.type === 'list-item'
                                    })
                                } else {
                                    // 否则 wrap 成另一种 list，再 lift
                                    Transforms.wrapNodes(editor, { type: convertedNodes[1].type }, { 
                                        at: currentListItemPath,
                                        match: n => n.type === "list-item" 
                                    })
                                    Transforms.liftNodes(editor, {
                                        at: currentListItemPath,
                                        match: n => isList(n.type)
                                    })
                                    Transforms.liftNodes(editor, {
                                        at: Path.parent(currentListItemPath),
                                        match: n => isList(n.type)
                                    })
                                }
                            }
                        } else if (currentListItem.children.some(child => isList(child.type) || child.type === 'nestWrapper')) {

                            const descendants = Array.from(Node.descendants(nodeNextOriginalParagraph))
                            const firstNestWrapper = descendants.find(descendant => {
                                const [node, _] = descendant
                                return node.type === 'nestWrapper'
                            })

                            if(isList(nodeNextOriginalParagraph.type) && !!firstNestWrapper) {
                                const [_, firstParagraphPath] = descendants.find(descendant => {
                                    const [node, _] = descendant
                                    return node.type === 'paragraph'
                                })
                                deleteOriginalFirstParagraph(firstParagraphPath, pathNextOriginalParagraph)

                                const [liNodeOfFirstParagraphp, liPathOfFirstParagraph] = descendants.find(descendant => {
                                    const [node, path] = descendant
                                    return node.type === 'list-item' && firstParagraphPath.length - path.length <= 2
                                })
                                const originalLiPath = [...pathNextOriginalParagraph, ...liPathOfFirstParagraph]

                                //
                                for (let i = 0; i < liPathOfFirstParagraph.length; i++) {
                                    const targetPath = originalLiPath.slice(0, originalLiPath.length - i)
                                    Transforms.liftNodes(editor, {
                                        at: pathNextOriginalParagraph,
                                        match: (n, p) => p.length === targetPath.length + 1 && 
                                            p.length > pathNextOriginalParagraph.length && 
                                            (isList(n.type) || n.type === 'list-item')
                                    })
                                    if(i === liPathOfFirstParagraph.length - 1) {
                                        removeEmptyListItem(targetPath)
                                    }
                                }

                                // const listItemLevels = descendants.filter(descendant => {
                                //     const [node, path] = descendant
                                //     return node.type === 'list-item' && Path.isAncestor(path, firstParagraphPath)
                                // }).length

                                // for (let i = 0; i < liPathOfFirstParagraph.length; i++) {
                                //     const targetPath = originalLiPath.slice(0, originalLiPath.length - i)
                                //     Transforms.liftNodes(editor, {
                                //         at: targetPath,
                                //         match: (n, p) => n.type === 'list-item' && p.length > pathNextOriginalParagraph.length + 1 &&
                                //             p.length === targetPath.length + 1 
                                //     })
                                //     if(i === liPathOfFirstParagraph.length - 1) {
                                //         removeEmptyListItem(targetPath)
                                //     }
                                // }

                                // const [nextNodeAfterLift, nextPathAfterLift] = Editor.next(editor, {
                                //     at: Path.parent(selection.focus.path)
                                // })

                                // console.log("after lift :", nextNodeAfterLift, editor.children);

                            } else {
                                Transforms.liftNodes(editor, {
                                    at: pathNextOriginalParagraph,
                                    match: (n, p) => isList(n.type),
                                    split: true
                                })
                                const [currentList, currentListPath] = Array.from(
                                    Node.ancestors(editor, selection.focus.path, { reverse: true })
                                ).find(ancestor => {
                                    const [ancestorNode, _] = ancestor
                                    return isList(ancestorNode.type)
                                })

                                if (currentList.children.some(child => child.type === currentList.type)) {
                                    Transforms.unwrapNodes(editor, {
                                        at: currentListPath,
                                        match: (n, p) => p.length === currentListPath.length + 1 && n.type === currentList.type
                                    })
                                } else if (currentList.children.some(child => child.type !== currentList.type && isList(child.type))) {
                                    Transforms.liftNodes(editor, {
                                        at: currentListPath,
                                        match: (n, p) => p.length === currentListPath.length + 1 && isList(n.type)
                                    })
                                }
                            }

                        }
                    }
                    
                    
                } else {
                    defaultInsertF()
                }
            }

            if(isSlateContent) {
                const slateData = data.getData('application/x-slate-fragment')
                const decoded = decodeURIComponent(window.atob(slateData));
                const parsedNodes = JSON.parse(decoded);
                insertNodes(parsedNodes)
                // console.log("after insert slate content: ", editor.children);
                
            } else if (htmlText) {
                const fragments = handlePastedText(htmlText)
                const fragmentsCleaned = fragments.filter(slateNode => {
                    return slateNode.text !== "" && slateNode.text !== "\n"
                })
                const liftedMediaValue = fragmentsCleaned.reduce((acc, node) => {
                    return [...acc, ...liftMediaNodes(node)]
                }, [])
                insertNodes(liftedMediaValue)
                // console.log("after insert html content: ", editor.children);
            } else if (commonText) {
                if(currentBlockNode.type === 'block-quote') {
                    // 在 block quote 中粘贴 markdown 文本时保留 markdown，不转换格式
                    const textFragments = commonText.split('\n').map((t, index) => { 
                        if(index === 0) {
                            return { text: t }
                        } else {
                            return { type: 'block-quote', children: [{ text: t }] }
                        }
                        
                    })
                    Transforms.insertFragment(editor, textFragments)
                } else {
                    const markdownValues = markdownToRichText(commonText)
                    if(currentBlockNode.type.match(/^h\d$/) && 
                        (markdownValues[0].type !== 'paragraph' || markdownValues.length > 1)
                    ) {
                        insertNewLine()
                        insertMarkdownTextToEditor(editor, commonText)
                    } else if(isList(currentBlockNode.type)) {
                        if(markdownValues.length > 1 || (markdownValues.length === 1 && isList(markdownValues[0].type))) {
                            // 如果在 list 中粘贴，且复制的 markdown 有多个 block，或者仅有一个 list，则分割原本的 list
                            const { path } = selection.focus
                            Transforms.insertNodes(editor, { type: 'paragraph', children: [{ text: '' }] }, {
                                at: [...path.slice(0, 2), path[2] + 1],
                                select: true
                            })
                            Transforms.liftNodes(editor)
                            Transforms.liftNodes(editor)
                            insertMarkdownTextToEditor(editor, commonText)
                        } else if (markdownValues[0].type === 'paragraph') {
                            insertMarkdownTextToEditor(editor, commonText)
                        } else {
                            // is single quote/heading
                            insertText(commonText)
                        }
                    } else {
                        insertMarkdownTextToEditor(editor, commonText)
                    }
                }
                

                
            } else {
                insertData(data)
            }

        }
    }
}
