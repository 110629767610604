
// This file is generated. Don't modify it.
import React from 'react'
export default function NewIcon (props) {

    const { color } = props
    
    return (
                <svg width="1em" height="1em" viewBox="0 0 16 16">
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Notification/New">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <path d="M11.2,3.2 L4.8,3.2 C2.16,3.2 0,5.36 0,8 C0,10.64 2.16,12.8 4.8,12.8 L11.2,12.8 C13.84,12.8 16,10.64 16,8 C16,5.36 13.84,3.2 11.2,3.2 Z" id="Path" fill={color}></path>
                        <polygon id="Path" fill="#FFFFFF" fillRule="nonzero" points="5.84 9.76 5.12 9.76 3.6 7.36 3.6 9.76 2.88 9.76 2.88 6.16 3.6 6.16 5.12 8.56 5.12 6.16 5.84 6.16"></polygon>
                        <polygon id="Path" fill="#FFFFFF" fillRule="nonzero" points="8.88 9.76 6.48 9.76 6.48 6.16 8.88 6.16 8.88 6.72 7.28 6.72 7.28 7.6 8.64 7.6 8.64 8.16 7.28 8.16 7.28 9.2 8.88 9.2 8.88 9.76"></polygon>
                        <polygon id="Path" fill="#FFFFFF" fillRule="nonzero" points="12.72 9.76 12 9.76 11.28 7.36 10.56 9.76 9.84 9.76 8.96 6.16 9.76 6.16 10.24 8.64 10.88 6.16 11.52 6.16 12.16 8.64 12.64 6.16 13.44 6.16 12.72 9.76"></polygon>
                    </g>
                </g>
            </g>
        </svg>
    )
}
