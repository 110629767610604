
const QQ_PATTERNS = [
    [/^https?:\/\/v\.qq\.com\/x\/(cover\/\w*|page)\/(\w*)\.html$/, 2],
    [/^https?:\/\/v\.qq\.com\/iframe\/player\.html\?vid=(\w*)/, 1],
    [/^https?:\/\/v\.qq\.com\/iframe\/preview\.html\?vid=(\w*)/, 1]
]

export const mediaFileTypes = ['mp4', 'mov', 'ogg', 'mp3', 'wav']

const qqPlayUrlPattern = /^https?:\/\/v\.qq\.com\/(?:txp\/)?iframe\/player\.html\?vid=(\w*)/
const localVideoUrlPattern = /^https?:\/\/(?:.+)\.mp4|mov/

export function isQQPlayURL(url) {
    return url && typeof (url) === 'string' && url.match(qqPlayUrlPattern) !== null
}

export function compatibleWithQQURL(playURL) {
    return playURL.replace("https://v.qq.com/iframe/", "https://v.qq.com/txp/iframe/")
}

export function isVideo(url) {
    const videoPatterns = [qqPlayUrlPattern, localVideoUrlPattern]
    return url && typeof (url) === 'string' && videoPatterns.some(pattern => url.match(pattern) !== null)
}

export function isAudio(url) {
    const audioUrlPattern = /^https?:\/\/(?:.+)\.mp3|wav|ogg/
    return url && typeof (url) === 'string' && url.match(audioUrlPattern) !== null
}

export function resolveFromURL(url) {

    if (!url) {
        return null
    }

    // check if it is qq video
    const foundQQUrl = QQ_PATTERNS.find(p => {
        const matches = url.trim().match(p[0])
        return matches
    })

    if (foundQQUrl) {
        const vid = url.trim().match(foundQQUrl[0])[foundQQUrl[1]]
        return {
            src: url,
            playURL: `https://v.qq.com/txp/iframe/player.html?vid=${vid}&auto=0`
        }
    }

    return {
        src: url,
        playURL: url
    }

}
