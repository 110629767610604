// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Plate$BwaxMobile = require("../../../../bwax-js/ml/plate.bs.js");
var BaseUI$BwaxMobile = require("../../../re/BaseUI.bs.js");
var MiscUI$BwaxMobile = require("../../../re/MiscUI.bs.js");
var Widget_common$BwaxMobile = require("../../mod/widget/widget_common.bs.js");
var Widget_mod_input$BwaxMobile = require("../../mod/widget/widget_mod_input.bs.js");

function render_element(doRender, onMsg, get_instance_id, facade, viewEnv, convertRules, rawContent) {
  var make_onChange = function (onChange, mv) {
    if (onChange !== undefined && mv !== undefined) {
      return Curry._1(onMsg, Curry._1(onChange, Caml_option.valFromOption(mv)));
    } else {
      return /* () */0;
    }
  };
  switch (rawContent.tag | 0) {
    case /* Input_text */0 :
        var disabled = rawContent[5];
        var onChange = rawContent[1];
        var pldr = Plate$BwaxMobile.$$Option.and_then(Widget_common$BwaxMobile.get_placeholder_text, rawContent[3]);
        var partial_arg = Curry._1(convertRules, rawContent[0]);
        var partial_arg$1 = BaseUI$BwaxMobile.inputText(/* record */[/* maxLength */rawContent[4]], rawContent[2], (function (param) {
                return make_onChange(onChange, param);
              }), pldr);
        return (function (param, param$1, param$2, param$3) {
            return partial_arg$1(disabled, partial_arg, param, param$1, param$2, param$3);
          });
    case /* Input_multiline */1 :
        var disabled$1 = rawContent[5];
        var value = rawContent[2];
        var onChange$1 = rawContent[1];
        var pldr$1 = Plate$BwaxMobile.$$Option.and_then(Widget_common$BwaxMobile.get_placeholder_text, rawContent[3]);
        var partial_arg$2 = Curry._1(convertRules, rawContent[0]);
        var partial_arg$3 = /* record */[/* maxLength */rawContent[4]];
        return (function (param, param$1, param$2, param$3) {
            return BaseUI$BwaxMobile.inputTextMultiline(partial_arg$3, value, (function (param) {
                          return make_onChange(onChange$1, param);
                        }), pldr$1, disabled$1, partial_arg$2, param, param$1, param$2, param$3);
          });
    case /* Input_int */2 :
        var disabled$2 = rawContent[4];
        var onChange$2 = rawContent[1];
        var pldr$2 = Plate$BwaxMobile.$$Option.and_then(Widget_common$BwaxMobile.get_placeholder_text, rawContent[3]);
        var doChange = function (v) {
          return Curry._1(onMsg, Curry._1(onChange$2, v));
        };
        var partial_arg$4 = Curry._1(convertRules, rawContent[0]);
        var partial_arg$5 = BaseUI$BwaxMobile.inputNumber(rawContent[2], doChange, pldr$2);
        return (function (param, param$1, param$2, param$3) {
            return partial_arg$5(disabled$2, partial_arg$4, param, param$1, param$2, param$3);
          });
    case /* Input_decimal */3 :
        var disabled$3 = rawContent[4];
        var onChange$3 = rawContent[1];
        var pldr$3 = Plate$BwaxMobile.$$Option.and_then(Widget_common$BwaxMobile.get_placeholder_text, rawContent[3]);
        var doChange$1 = function (v) {
          return Curry._1(onMsg, Curry._1(onChange$3, v));
        };
        var partial_arg$6 = Curry._1(convertRules, rawContent[0]);
        var partial_arg$7 = BaseUI$BwaxMobile.inputDecimal(rawContent[2], doChange$1, pldr$3);
        return (function (param, param$1, param$2, param$3) {
            return partial_arg$7(disabled$3, partial_arg$6, param, param$1, param$2, param$3);
          });
    case /* Input_radio */4 :
        var onChange$4 = rawContent[4];
        var doChange$2 = function (v) {
          return Curry._1(onMsg, Curry._1(onChange$4, v));
        };
        var config_000 = /* align */rawContent[7];
        var config_001 = /* selected */rawContent[3];
        var config_003 = /* options */rawContent[2];
        var config_004 = /* disabled */rawContent[5];
        var config_005 = /* color */rawContent[6];
        var config = /* record */[
          config_000,
          config_001,
          /* onChange */doChange$2,
          config_003,
          config_004,
          config_005
        ];
        var partial_arg$8 = Curry._1(convertRules, rawContent[1]);
        var partial_arg$9 = Curry._1(convertRules, rawContent[0]);
        return (function (param, param$1, param$2, param$3) {
            return BaseUI$BwaxMobile.inputRadio(partial_arg$9, partial_arg$8, config, param, param$1, param$2, param$3);
          });
    case /* Input_checkboxes */5 :
        var onChange$5 = rawContent[4];
        var doChange$3 = function (v) {
          return Curry._1(onMsg, Curry._1(onChange$5, v));
        };
        var config_000$1 = /* align */rawContent[7];
        var config_001$1 = /* selected */rawContent[3];
        var config_003$1 = /* options */rawContent[2];
        var config_004$1 = /* disabled */rawContent[5];
        var config_005$1 = /* color */rawContent[6];
        var config$1 = /* record */[
          config_000$1,
          config_001$1,
          /* onChange */doChange$3,
          config_003$1,
          config_004$1,
          config_005$1
        ];
        var partial_arg$10 = Curry._1(convertRules, rawContent[1]);
        var partial_arg$11 = Curry._1(convertRules, rawContent[0]);
        return (function (param, param$1, param$2, param$3) {
            return BaseUI$BwaxMobile.inputCheckboxes(partial_arg$11, partial_arg$10, config$1, param, param$1, param$2, param$3);
          });
    case /* Input_checkbox */6 :
        var onChange$6 = rawContent[2];
        var doChange$4 = function (v) {
          return Curry._1(onMsg, Curry._1(onChange$6, v));
        };
        var config_000$2 = /* checked */rawContent[1];
        var config_002 = /* disabled */rawContent[3];
        var config_003$2 = /* color */rawContent[4];
        var config$2 = /* record */[
          config_000$2,
          /* onChange */doChange$4,
          config_002,
          config_003$2
        ];
        var partial_arg$12 = Curry._1(convertRules, rawContent[0]);
        return (function (param, param$1, param$2, param$3) {
            return BaseUI$BwaxMobile.inputCheckbox(partial_arg$12, config$2, param, param$1, param$2, param$3);
          });
    case /* Input_select */7 :
        var disabled$4 = rawContent[11];
        var m_onChange = rawContent[10];
        var selected = rawContent[9];
        var options = rawContent[8];
        var optionFocused_mrs = rawContent[7];
        var singleValue_mrs = rawContent[5];
        var option_mrs = rawContent[4];
        var doChange$5 = function (mv) {
          if (m_onChange !== undefined) {
            return Curry._1(onMsg, Curry._1(m_onChange, mv));
          } else {
            return /* () */0;
          }
        };
        var pldr$4 = Plate$BwaxMobile.$$Option.and_then(Widget_common$BwaxMobile.get_placeholder_text, rawContent[12]);
        var partial_arg_000 = /* containerRules */Curry._1(convertRules, rawContent[0]);
        var partial_arg_001 = /* controlRules */Curry._1(convertRules, rawContent[1]);
        var partial_arg_002 = /* menuRules */Curry._1(convertRules, rawContent[2]);
        var partial_arg_003 = /* optionListRules */Curry._1(convertRules, rawContent[3]);
        var partial_arg_004 = function (label, value, selected, disabled) {
          return Curry._1(convertRules, Curry._4(option_mrs, label, value, selected, disabled));
        };
        var partial_arg_005 = function (label, value) {
          return Curry._1(convertRules, Curry._2(singleValue_mrs, label, value));
        };
        var partial_arg_006 = /* controlFocusedRules */Curry._1(convertRules, rawContent[6]);
        var partial_arg_007 = function (label, value, selected) {
          return Curry._1(convertRules, Curry._3(optionFocused_mrs, label, value, selected));
        };
        var partial_arg$13 = /* record */[
          partial_arg_000,
          partial_arg_001,
          partial_arg_002,
          partial_arg_003,
          partial_arg_004,
          partial_arg_005,
          partial_arg_006,
          partial_arg_007
        ];
        return (function (param, param$1, param$2, param$3) {
            return MiscUI$BwaxMobile.select(partial_arg$13, options, selected, doChange$5, disabled$4, pldr$4, param, param$1, param$2, param$3);
          });
    case /* Input_chooseFile */8 :
        var accept = rawContent[2];
        var onChoose = rawContent[1];
        var rules = Curry._1(convertRules, rawContent[0]);
        var doChoose = function (maybeFile) {
          return Curry._1(onMsg, Curry._1(onChoose, maybeFile));
        };
        var partial_arg$14 = Curry._1(doRender, rawContent[3]);
        return (function (param, param$1, param$2, param$3) {
            return MiscUI$BwaxMobile.chooseFile(rules, doChoose, accept, partial_arg$14, param, param$1, param$2, param$3);
          });
    
  }
}

var module_name = Widget_mod_input$BwaxMobile.module_name;

var get_placeholder_text = Widget_common$BwaxMobile.get_placeholder_text;

exports.module_name = module_name;
exports.get_placeholder_text = get_placeholder_text;
exports.render_element = render_element;
/* Plate-BwaxMobile Not a pure module */
