
import React, { useState, Suspense, useTransition } from 'react';

import { color as getColor } from 'bwax-ui';


const caches = {

};

export function waitForColor(name, s) {

    const p = new Promise(function (resolve, _) {
        setTimeout(() => {
            const color = getColor(name);
            const v = { name, color };
            caches[name] = v;
            console.log("Data ready", name, color);
            resolve(v);
        }, s)
        // }
    });
    

    const f = (() => {
        const v = caches[name];
        if(v) {
            return v;
        } else {
            throw p;
        }
    })

    return f;

}

export default function ColorPage(props) {

    return (
        <Inner {...props} />
    )

}


const COLORS = ["B60", "G60", "R60", "Y60", "H60", "O60"];

function Inner(props) {

    const { colorName, history } = props;

    const [ status, setStatus ] = useState(null);

    // const [ color, setColor ] = useState({ name : name.toUpperCase(), color: getColor(name.toUpperCase())});

    // const [ colorName, setColorName ] = useState(name);

    const [
        startTransition,
        isPending
      ] = useTransition({
        timeoutMs: 3000
      });

    // console.log(">>>>", color, isPending);

    function onClick() {
        // const { name: colorName } = color;
        const index = COLORS.indexOf(colorName);
        const nextIndex = (index + 1) % COLORS.length;

        const nextColorName = COLORS[nextIndex];
        setStatus("Going to " + nextColorName);
        // setTimeout(() => {
            history.push("/color/" + nextColorName)
            // startTransition(() => {
            //     // setColor(waitForColor(nextColorName, 1000));
            //     setColorName(nextColorName)
            // });            
            // setStatus(null);
        // }, 500)/

    }

    const color = waitForColor(colorName, 1000)();

    return (
        <Suspense fallback={null}>
            <div style={{
                backgroundColor: color.color, width: "100%", height: "100%",
                display: "flex", alignItems: "center", justifyContent: "center"
            }} onClick={onClick}>
                <div style={{ color: "white" }}>{status}</div>
            </div>
        </Suspense>
    )
}