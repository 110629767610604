
import React from 'react'

import createBlockTypeButton from './util/createBlockTypeButton';

import { MdFormatListBulleted } from 'react-icons/md'

export default createBlockTypeButton({
    blockType: 'bulleted-list',
    children: (
        <MdFormatListBulleted style={{ fontSize: 16 }} />
    ),
});
