

import React, { Suspense } from 'react'

const AuxVideo = React.lazy(() => import('bwax-ui/auxiliary/video/Video'));

export default function Video({ poster, src, className, videoType = 'mp4' }) {

    const options = {
        poster,
        sources: [{
            src,
            type: `video/${videoType.toLowerCase()}`
        }]
    }

    return (
        <div className={className}>
            <Suspense fallback={null}><AuxVideo
                options={{ ...options }}>
                <p className="vjs-no-js">
                    你的浏览器不
                    <a href="http://videojs.com/html5-video-support/" target="_blank">
                        支持html5视频
                    </a>
                </p>
            </AuxVideo>
            </Suspense>
        </div>
    )
}