
import { runDataQuery } from 'bwax/query/runClientQuery'

export default function loadBaseSettings() {
    return async (env) => {

        const result = await runDataQuery(env)(QueryText)()
        const { data, errors } = JSON.parse(result)
        /// TODO error handling:

        return data && data.loadBaseSetting ? data.loadBaseSetting : {}
    }
}

const QueryText = `
    query {
        loadBaseSetting {
            logo {
                    expireTime
                    title
                    path
                    size
                    contentType
                    url
                }
            name
            pageScripts
            adminPageScripts
        }
    }
`
