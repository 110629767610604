// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Widget_mod_ui$BwaxMobile = require("../mod/widget/widget_mod_ui.bs.js");
var Widget_mod_html$BwaxMobile = require("../mod/widget/widget_mod_html.bs.js");
var Widget_mod_nivo$BwaxMobile = require("../mod/widget/widget_mod_nivo.bs.js");
var Widget_mod_color$BwaxMobile = require("../mod/widget/widget_mod_color.bs.js");
var Widget_mod_image$BwaxMobile = require("../mod/widget/widget_mod_image.bs.js");
var Widget_mod_input$BwaxMobile = require("../mod/widget/widget_mod_input.bs.js");
var Widget_mod_wxpay$BwaxMobile = require("../mod/widget/widget_mod_wxpay.bs.js");
var Widget_mod_alipay$BwaxMobile = require("../mod/widget/widget_mod_alipay.bs.js");
var Widget_mod_wechat$BwaxMobile = require("../mod/widget/widget_mod_wechat.bs.js");
var Widget_mod_data_ui$BwaxMobile = require("../mod/widget/widget_mod_data_ui.bs.js");
var Widget_mod_element$BwaxMobile = require("../mod/widget/widget_mod_element.bs.js");
var Widget_mod_misc_ui$BwaxMobile = require("../mod/widget/widget_mod_misc_ui.bs.js");
var Widget_mod_spinner$BwaxMobile = require("../mod/widget/widget_mod_spinner.bs.js");
var Widget_mod_adhoc_ui$BwaxMobile = require("../mod/widget/widget_mod_adhoc_ui.bs.js");
var Widget_mod_styled_ui$BwaxMobile = require("../mod/widget/widget_mod_styled_ui.bs.js");
var Widget_mod_admin_page$BwaxMobile = require("../mod/widget/widget_mod_admin_page.bs.js");
var Widget_mod_local_file$BwaxMobile = require("../mod/widget/widget_mod_local_file.bs.js");
var Widget_mod_admin_chart$BwaxMobile = require("../mod/widget/widget_mod_admin_chart.bs.js");
var Widget_mod_html_events$BwaxMobile = require("../mod/widget/widget_mod_html_events.bs.js");
var Widget_mod_html_attributes$BwaxMobile = require("../mod/widget/widget_mod_html_attributes.bs.js");

var base_ui_src = "\n\n-- 只用于本地浏览器选择的文件\ntype LocalFile = opaque;\n\n\n-- Some common variants related to UI:\n\ntype Size = Thumbnail | Small | Medium | Large | XLarge | XXLarge;\n\ntype Side = Top | Right | Bottom | Left;\ntype Corner = TopLeft | TopRight | BottomRight | BottomLeft;\n\n\n\n";

var externals = /* tuple */[
  undefined,
  /* [] */0
];

function build_widget_base(param) {
  return /* tuple */[
          /* :: */[
            /* tuple */[
              "(base)",
              base_ui_src
            ],
            /* :: */[
              /* tuple */[
                "Element",
                Widget_mod_element$BwaxMobile.src
              ],
              /* :: */[
                /* tuple */[
                  "Input",
                  Widget_mod_input$BwaxMobile.src
                ],
                /* :: */[
                  /* tuple */[
                    "Image",
                    Widget_mod_image$BwaxMobile.src
                  ],
                  /* :: */[
                    /* tuple */[
                      "Color",
                      Widget_mod_color$BwaxMobile.src
                    ],
                    /* :: */[
                      /* tuple */[
                        "LocalFile",
                        Widget_mod_local_file$BwaxMobile.src
                      ],
                      /* :: */[
                        /* tuple */[
                          "UI",
                          Widget_mod_ui$BwaxMobile.src(/* () */0)
                        ],
                        /* :: */[
                          /* tuple */[
                            "WeChat",
                            Widget_mod_wechat$BwaxMobile.src
                          ],
                          /* :: */[
                            /* tuple */[
                              "AliPay",
                              Widget_mod_alipay$BwaxMobile.src
                            ],
                            /* :: */[
                              /* tuple */[
                                "WxPay",
                                Widget_mod_wxpay$BwaxMobile.src
                              ],
                              /* :: */[
                                /* tuple */[
                                  "Spinner",
                                  Widget_mod_spinner$BwaxMobile.src
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "Nivo",
                                    Widget_mod_nivo$BwaxMobile.src
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "DataUI",
                                      Widget_mod_data_ui$BwaxMobile.src
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        "MiscUI",
                                        Widget_mod_misc_ui$BwaxMobile.src
                                      ],
                                      /* :: */[
                                        /* tuple */[
                                          "StyledUI",
                                          Widget_mod_styled_ui$BwaxMobile.src
                                        ],
                                        /* :: */[
                                          /* tuple */[
                                            "AdhocUI",
                                            Widget_mod_adhoc_ui$BwaxMobile.src
                                          ],
                                          /* :: */[
                                            /* tuple */[
                                              "AdminPage",
                                              Widget_mod_admin_page$BwaxMobile.src
                                            ],
                                            /* :: */[
                                              /* tuple */[
                                                "AdminChart",
                                                Widget_mod_admin_chart$BwaxMobile.src
                                              ],
                                              /* :: */[
                                                /* tuple */[
                                                  "Html",
                                                  Widget_mod_html$BwaxMobile.src
                                                ],
                                                /* :: */[
                                                  /* tuple */[
                                                    "Html.Attributes",
                                                    Widget_mod_html_attributes$BwaxMobile.src
                                                  ],
                                                  /* :: */[
                                                    /* tuple */[
                                                      "Html.Events",
                                                      Widget_mod_html_events$BwaxMobile.src
                                                    ],
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ],
          /* :: */[
            externals,
            /* :: */[
              Widget_mod_element$BwaxMobile.externals,
              /* :: */[
                Widget_mod_input$BwaxMobile.externals,
                /* :: */[
                  Widget_mod_image$BwaxMobile.externals,
                  /* :: */[
                    Widget_mod_color$BwaxMobile.externals,
                    /* :: */[
                      Widget_mod_local_file$BwaxMobile.externals,
                      /* :: */[
                        Widget_mod_ui$BwaxMobile.externals,
                        /* :: */[
                          Widget_mod_wechat$BwaxMobile.externals,
                          /* :: */[
                            Widget_mod_alipay$BwaxMobile.externals,
                            /* :: */[
                              Widget_mod_wxpay$BwaxMobile.externals,
                              /* :: */[
                                Widget_mod_spinner$BwaxMobile.externals,
                                /* :: */[
                                  Widget_mod_nivo$BwaxMobile.externals,
                                  /* :: */[
                                    Widget_mod_data_ui$BwaxMobile.externals,
                                    /* :: */[
                                      Widget_mod_misc_ui$BwaxMobile.externals,
                                      /* :: */[
                                        Widget_mod_styled_ui$BwaxMobile.externals,
                                        /* :: */[
                                          Widget_mod_adhoc_ui$BwaxMobile.externals,
                                          /* :: */[
                                            Widget_mod_admin_page$BwaxMobile.externals,
                                            /* :: */[
                                              Widget_mod_admin_chart$BwaxMobile.externals,
                                              /* :: */[
                                                Widget_mod_html$BwaxMobile.externals,
                                                /* :: */[
                                                  Widget_mod_html_attributes$BwaxMobile.externals,
                                                  /* :: */[
                                                    Widget_mod_html_events$BwaxMobile.externals,
                                                    /* [] */0
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ];
}

var external_vals = /* [] */0;

exports.base_ui_src = base_ui_src;
exports.external_vals = external_vals;
exports.externals = externals;
exports.build_widget_base = build_widget_base;
/* Widget_mod_ui-BwaxMobile Not a pure module */
