

import React, { useState, useEffect, useRef } from 'react'

import useResizeObserver from '@react-hook/resize-observer'

import { sendMessageToStream } from 'bwax-ui/openai/OpenAIClient';

import countWords from './components/countWords';

import TextArea from 'bwax-ui/components/inputs/TextArea';
import Button, { Pressable } from 'bwax-ui/components/Button';
import Modal from 'bwax-ui/components/Modal';

import Avatar from 'bwax-ui/components/Avatar';
import WeChatLoginQrCode from 'bwax-ui/components/WeChatLoginQrCode';

import Link from 'bwax-ui/page/Link';

import getImageURL from 'bwax/util/getImageURL';

import { PaperPlaneIcon } from '@radix-ui/react-icons'
import ChatbotMessageList from './components/chat/ChatbotMessageList';

import { useTrack } from 'bwax-ui/track';

function userMessage(content, time) {
    return { messageType: "userInput", content, time }
}

function responseMessage(content, time, relatedKnowledges, chatModel) {
    return { messageType: "response", content, time, relatedKnowledges, chatModel }
}

function newTopicMessage(time, content) {
    return { messageType: "newTopic", time, content }
}

export default function PersonaSharingChatView(props) {

    const { data, events, slots, viewEnv, facade } = props;

    const { reloadUsageQuota } = events;

    const { isMobile } = viewEnv.webEnv;

    const {
        sharing, session,

        botAvatarUrl,
        userAvatar,
        userNickName,
        userId,

        logoUrl,

        remainingQuota, homeUrl
    } = data;

    // OpenAI-会话消息列表
    // const [ history, setHistory ] = useState([]);

    // Messages to render:  ("session messages");
    const [existingMessages, setExistingMessages] = useState();

    const [newMessages, setNewMessages] = useState([]);

    const [respondingText, setRespondingText] = useState();  /// string
    const [respondingSteps, setRespondingSteps] = useState();  // [ { step: <>, done: true/false, text: string } ]
    const [respondingKnowledges, setRespondingKnowledges] = useState(); // { [ <knowREf> ]}

    const [isResponding, setIsResponding] = useState(false);

    const respondingTextRef = useRef();
    respondingTextRef.current = respondingText;
    const respondingStepsRef = useRef();
    respondingStepsRef.current = respondingSteps;
    const respondingKnowledgesRef = useRef();
    respondingKnowledgesRef.current = respondingKnowledges;

    const persona = sharing.角色;
    const welcomeMessage = persona.欢迎消息 ? responseMessage(persona.欢迎消息, session ? new Date(session.创建时间) : new Date()) : null;

    // load session messages
    async function loadMessages() {
        if (!session) {
            return
        }
        const [result, error] = await facade.list({
            entityName: "OpenAI-会话消息",
            condition: [{
                field: "会话", op: "eq", value: session.id,
            }],
            sort: [
                { field: "创建时间", order: "DESC" }
            ],
            pageSize: 1000,
            fieldPaths: [
                ...["用户发送消息", "智能回复消息", "发送时间", "回复时间", "相关知识", "chatModel"].map(f => "对话." + f),
                "类型"
            ]
        }, { forceRefreshing: true });
        if (!error && result) {
            const messages = result.flatMap(message => {
                if (message.类型 == "新对话") {
                    return [newTopicMessage(new Date(message.发送时间), persona.欢迎消息)]
                } else if (message.类型 == "对话") {
                    const r = message.对话;
                    return [
                        r.智能回复消息 ? responseMessage(r.智能回复消息, new Date(r.回复时间), r.相关知识, r.chatModel) : null,
                        userMessage(r.用户发送消息, new Date(r.发送时间)),
                    ].filter(m => !!m)
                }
                return []
            })
            setExistingMessages(messages);
            setNewMessages([]);
        }
    }

    useEffect(() => {
        loadMessages();
    }, [session && session.id]);

    function updateRespondingStep(stepStatus) {
        setRespondingSteps(prev => {
            const steps = prev || [];
            const newSteps = steps.some(s => s.step == stepStatus.step) ? (
                steps.map(s => s.step == stepStatus.step ? stepStatus : s)
            ) : (
                [...steps, stepStatus]
            )
            return newSteps
        })
    }

    function getKnowledgeOptions() {
        const defaultKnowledgeOptions = {
            isEnabled: false, isLimited: false, scope: []
        }
        const userOptions = sharing.角色.用户选项;
        if(userOptions) {
            if(userOptions.scope) {
                return userOptions
            } else {
                return userOptions.knowledgeOptions || defaultKnowledgeOptions
            }
        } else {
            return defaultKnowledgeOptions
        }
    }

    function getContentOptions() {
        return sharing.角色.内容生成选项 || {
            temperature: 0.7
        }
    }

    function getChatModel() {
        return undefined /// default one
    }

    const stopperRef = useRef();

    function sendMessage(message) {
        if (!session) {
            return
        }
        const sessionId = session.id;
        setNewMessages(prev => {
            return [
                userMessage(message, new Date()),
                ...prev,
            ]
        })
        setIsResponding(true);

        function onData(s, isEnd) {

            const respondingText = respondingTextRef.current;
            const newResponseText = (respondingText || "") + s;
            function updateRespondingText(text) {
                setRespondingText(text);
                respondingTextRef.current = text;
            }
            if (isEnd) {
                setIsResponding(false);

                if (newResponseText) {
                    const knowledges = respondingKnowledgesRef.current || [];
                    setNewMessages(prev => {
                        return [
                            responseMessage(newResponseText, new Date(), knowledges, session.chatModel),
                            ...prev,
                        ]
                    });
                }
                updateRespondingText("");
                setRespondingKnowledges([])
                setRespondingSteps([]);
                if (reloadUsageQuota) {
                    setTimeout(() => {
                        reloadUsageQuota();
                    }, 800)
                }
            } else {
                updateRespondingText(newResponseText)
            }
        }

        function onStep(obj) {
            updateRespondingStep(obj);
            const { step, status, refKnowledgeList } = obj;
            if (step == "SearchKnowledge" && status == "Done" && refKnowledgeList) {
                setRespondingKnowledges(refKnowledgeList);
            }
        }

        sendMessageToStream({
            message, sessionId,
            onData, onStep, dlc: facade.dlc,
            knowledgeOptions: getKnowledgeOptions(),
            contentOptions: getContentOptions(),

            chatModel: getChatModel(),

            bindStopper: stop => {
                stopperRef.current = stop;
            }
        })
    }

    function getLatestMessage() {
        if(newMessages && newMessages.length > 0) {
            return newMessages[0]
        } else {
            return existingMessages ? existingMessages[0]: null
        }
    }

    async function sendNewTopic() {
        if (!session) {
            return
        }
        const latestMessage = getLatestMessage();
        if(latestMessage.messageType == "newTopic") {
            console.log(">>> the latest is newTopic, no need to send newTopic")
            return;
        }

        const [result, error] = await facade.add({
            entityName: "OpenAI-会话消息",
            formData: {
                类型: "新对话",
                会话: session.id
            },
            fieldPaths: [
                ...["用户发送消息", "智能回复消息", "发送时间", "回复时间", "相关知识", "chatModel"].map(f => "对话." + f),
                "类型"
            ]
        });
        if (!error && result) {
            setNewMessages(prev => {
                return [
                    newTopicMessage(new Date(), persona.欢迎消息),
                    ...prev,
                ]
            })
        }
    }

    const inputRef = useRef();

    const inputBoxRef = useRef();
    const messageListRef = useRef();


    const [inputHeight, setInputHeight] = useState(167);

    useResizeObserver(inputBoxRef, ({ borderBoxSize }) => {
        setInputHeight(borderBoxSize[0].blockSize)
    });

    useEffect(() => {
        if (messageListRef && messageListRef.current) {
            const { scrollHeight } = messageListRef.current
            messageListRef.current.scrollTop = scrollHeight // 设置滚动条到最下方
        }
    }, [newMessages.length, existingMessages, respondingText, respondingSteps, respondingKnowledges,]);

    function renderAd() {
        const logo = logoUrl ? (
            <img src={getImageURL(logoUrl, "thumbnail")} className="inline-block h-5 sm:translate-y-px" />
        ) : null

        return homeUrl ? (
            <div className="font-size-12 text-[var(--mauve12)]">            
                { logo }
                {/* <span className="mr-1 font-medium opacity-75">SimplifyAI</span> */}
                <Link className="text-[var(--violet11)] ml-2" to={homeUrl} openWithNewTab={true}>点击定制自己的 AI 知识助手</Link>
            </div>
        ) : null
    }

    const [isSharingInfoShown, setIsSharingInfoShown] = useState(
        !userId // 没登录
    );

    useEffect(() => {
        if(existingMessages !== undefined && existingMessages.length == 0) {
            // 还没有对话过
            setIsSharingInfoShown(true);
        }
    }, [ existingMessages ]);

    function renderSharingInfo() {
        const persona = sharing.角色;
        return (
            <Modal isOpen={isSharingInfoShown} onOpenChange={setIsSharingInfoShown} className="max-w-md" isMain={false} closeButton={false}>
                <div className="flex flex-col pt-4 pb-6 px-4 gap-4">
                    <div className="flex gap-4 px-2">
                        <Avatar avatar={persona.头像} nickName={persona.名称} size={52} />
                        <div className="flex flex-col grow gap-1">
                            <div className="font-medium font-size-16">{persona.名称}</div>
                            <div className="whitespace-pre-line text-[var(--gray11)] font-size-12">
                                来自 {(sharing.创建者 && sharing.创建者.昵称) || " SimplifyAI 用户"} 的分享
                            </div>
                        </div>
                    </div>
                    <div className="whitespace-pre-line text-[var(--gray11)] px-2 py-2">
                        {sharing.说明}
                    </div>
                    {
                        userId ? (
                            <div className="flex flex-col items-center pt-6 pb-4 gap-3">
                                <Button className="w-full max-w-xs" color="grass" size="large" onPress={_ => {
                                    if (!session) {
                                        // create new session
                                    }
                                    if(inputRef.current) {
                                        inputRef.current.focus();
                                    }
                                    setIsSharingInfoShown(false);
                                }}>{((existingMessages || []).length > 0 || newMessages.length > 0) ? "继续对话" : "开始对话"}</Button>
                            </div>
                        ) : (
                            <div className="flex flex-col items-center pt-6 pb-4 gap-4">
                                <div className="opacity-80">
                                    <WeChatLoginQrCode {...{
                                        size: 128, 
                                        onSuccess: _ => {
                                            if(typeof(location) !== "undefined") {
                                                location.reload();
                                            }
                                        }
                                    }} />
                                </div>
                                <div className="text-[var(--gray11)] font-size-13">
                                    扫描二维码微信登录后开始对话
                                </div>
                            </div>
                        )
                    }
                </div>
            </Modal>
        )
    }

    return (
        <div className="max-w-3xl w-full h-full flex flex-col self-center font-size-14 shadow">
            {/* message list */}
            {/* <div className="grow" style={{
                height: `calc(100% - ${inputHeight}px)`
            }}> */}
            <ChatbotMessageList style={{ height: `calc(100% - ${inputHeight}px)` }} {...{
                currentSession: session,
                persona: sharing.角色,
                messages: [...(newMessages || []), ...(existingMessages || []), ...(welcomeMessage ? [welcomeMessage] : [])].reverse(),

                responding: isResponding,
                messageListRef,

                respondingText, respondingSteps, respondingKnowledges,

                shouldShowKnowledges: false,

                stopResponding: _ => {
                    const stop = stopperRef.current;
                    if (stop) {
                        stop();
                    }
                },
                botAvatarUrl,
                userAvatar,
                userNickName,

                facade,

                onBotAvatarClick: _ => {
                    setIsSharingInfoShown(true);
                }

            }} />
            {/* </div> */}
            {/* input box */}
            <ChatInput ref={inputBoxRef} {...{
                remainingQuota, reloadUsageQuota,
                isMobile,
                sendMessage, sendNewTopic,
                inputRef,
                renderAd
            }} />
            {/* {renderPaused()} */}
            {renderSharingInfo()}
        </div>
    )
}


const ChatInput = React.forwardRef((props, ref) => {

    const track = useTrack();

    const {
        responding, maxInputCount = 900, remainingQuota,
        sendMessage, sendNewTopic,
        isMobile,
        inputRef,
        renderAd,
    } = props;

    const [editing, setEditing] = useState("");

    const isOutOfQuota = (() => {
        return remainingQuota !== undefined && remainingQuota <= 0;
    })();

    const wordCount = (() => {
        const trimmed = editing ? editing.trim() : "";
        if (trimmed) {
            return countWords(trimmed)
        } else {
            return 0
        }
    })();

    const tooManyWords = wordCount > maxInputCount;

    const isSendButtonDisabled = !(editing && editing.trim().length !== 0) || tooManyWords || isOutOfQuota;

    function send() {
        // 
        if (isSendButtonDisabled) {
            // do nothing
        } else {
            sendMessage(editing ? editing.trim() : editing);
            setEditing("");
        }
    }

    const [ isComposing, setIsComposing ] = useState(false);

    // 
    return (
        <div className="pt-2 pb-4 px-2 flex flex-col border-t border-[var(--gray4)]" ref={ref}>
            <div className="flex justify-between px-2">
                <div className="text-[var(--violet11)] flex gap-3 items-center font-size-15">
                    <Pressable onPress={_ => sendNewTopic()}>
                       <div className="flex justify-center items-center w-5 h-5 rounded">
                           <i className='bx bx-brush-alt cursor-pointer'></i>
                       </div>
                    </Pressable>
                </div>
                <div className="action-group">
                    <Button color={"violet"} isDisabled={isSendButtonDisabled} isLoading={responding} size="small"
                        onPress={_ => {
                            track("sharing_send_to_chatbot", { method: "button-click"} );
                            send();
                        }}
                    >
                        {/* 发送 */}
                        <PaperPlaneIcon style={{
                            transform: "rotate(-45deg)"
                        }} />
                    </Button>
                </div>
            </div>
            <TextArea ref={inputRef} className="!px-3" {...{
                maxRows: isMobile ? 6 : 8,
                minRows: isMobile ? 1 : 4,
                value: editing, 
                placeholder: "输入你要问的问题" + (isMobile ? "" : "（按 Shift+Enter 换行，Enter 发送消息）"),
                onChange: value => setEditing(value),

                onCompositionStart: () => {
                    setIsComposing(true);
                },

                onCompositionEnd: () => {
                    setTimeout(() => {
                        setIsComposing(false);
                    }, 16);
                },

                onKeyDown: e => {
                    if (!isMobile) {
                        // at pc:
                        if (e.key == "Enter") {
                            // 
                            if (e.shiftKey || e.altKey || e.metaKey || e.ctrlKey || isComposing) {
                                // 换行
                                // console.log(">>> ", e.shiftKey, e.altKey, e.metaKey, e);

                            } else {
                                // 发送
                                e.preventDefault();
                                track("sharing_send_to_chatbot", { method: "enter-key"} );
                                send();
                            }

                        }
                    }
                }
            }} />
            <div className="flex font-size-10 justify-between px-2">
                {isOutOfQuota ? (
                    <div className="py-2 font-size-13 text-[var(--yellow11)] flex items-center gap-2">
                        <i className='bx bx-info-circle opacity-90 font-size-15'></i>
                        已用完当天的限额
                    </div>
                ) : (
                    null
                    // <>
                    //     <div className="opacity-40">
                    //         {isMobile ? "" : "按 Enter 发送消息, 按 Shift+Enter 换行"}
                    //     </div>
                    //     <div className={(tooManyWords ? "text-[var(--red11)]" : "opacity-40")}>
                    //         {wordCount}/{maxInputCount}
                    //     </div>
                    // </>
                )}
            </div>
            { renderAd ? (
                <div className="pt-5 sm:pt-3 px-2 flex justify-center sm:justify-center">
                    { renderAd() }
                </div>
            ) : null}
        </div>
    )
})
