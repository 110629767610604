
import React from 'react'
import { Node } from 'slate'

export default function Blockquote(props) {

    const { editor, element, className, attributes, placeholderProps, path } = props
    let siblingClassName = ''
    let actualPlaceholderProps = {}
    
    if(path) {
        const parentNode = Node.parent(editor, path)
        const children = parentNode.children
        const currentNodeIndex = children.indexOf(element)

        function isFirst () {
            if(currentNodeIndex < children.length - 1) {
                if(currentNodeIndex > 0) {
                    return children[currentNodeIndex - 1].type !== "block-quote" && children[currentNodeIndex + 1].type === 'block-quote'
                } else {
                    return children[currentNodeIndex + 1].type === 'block-quote'
                }
                
            } else {
                return false
            }
        }
    
        function isMiddle () {
            if(currentNodeIndex > 0 && currentNodeIndex < children.length - 1) {
                return children[currentNodeIndex - 1].type === 'block-quote' && children[currentNodeIndex + 1].type === 'block-quote'
            } else {
                return false
            }
        }
    
        function isSingle() {
            if(children.length === 1) {
                return true
            } else {
                if(currentNodeIndex === 0 ) {
                    return children[currentNodeIndex + 1].type !== 'block-quote'
                } else if (currentNodeIndex === children.length - 1) {
                    return children[currentNodeIndex - 1].type !== 'block-quote'
                } else {
                    return children[currentNodeIndex - 1].type !== 'block-quote' && children[currentNodeIndex + 1].type !== 'block-quote'
                }
            }
        }
    
        actualPlaceholderProps = isFirst() ? placeholderProps : (isSingle() ? placeholderProps : {})
        siblingClassName = isFirst() ? 'first-blockquote' : (isMiddle() ? 'middle-blockquote' : (isSingle() ? 'single-blockquote' : ''))
    }

    

    return (
        <blockquote {...attributes} {...actualPlaceholderProps} style={{ textAlign: element.align }}
            className={`${className} ${placeholderProps?.className} ${siblingClassName}`}  
        >
            {props.children}
        </blockquote>
    )
}
