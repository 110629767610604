
// This file is generated. Don't modify it.
import React from 'react'
export default function LockIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M11,8 L5,8 L4,8 L4,13 L12,13 L12,8 L11,8 Z M5,7 L5,5 C5,3.34314575 6.34314575,2 8,2 C9.65685425,2 11,3.34314575 11,5 L11,7 L12,7 C12.5522847,7 13,7.44771525 13,8 L13,13 C13,13.5522847 12.5522847,14 12,14 L4,14 C3.44771525,14 3,13.5522847 3,13 L3,8 C3,7.44771525 3.44771525,7 4,7 L5,7 Z M6,7 L10,7 L10,5 C10,3.8954305 9.1045695,3 8,3 C6.8954305,3 6,3.8954305 6,5 L6,7 Z M8.5,10.6118304 L8.5,12.2497768 L7.49955357,12.2497768 L7.49955357,10.6118304 C7.20089286,10.4390625 7,10.1203125 7,9.75066964 C7,9.19888393 7.44732143,8.75022321 8.00044643,8.75022321 C8.55357143,8.75022321 9.00089286,9.19754464 9.00089286,9.75066964 C9.00089286,10.1189732 8.79866071,10.4377232 8.5,10.6118304 Z" id="LockIcon-path-1"></path>
            </defs>
            <g id="Style" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Iconology" transform="translate(-312.000000, -124.000000)">
                    <g id="Icon" transform="translate(48.000000, 124.000000)">
                        <g id="Icon/Lock" transform="translate(264.000000, 0.000000)">
                            <g id="Group-3">
                                <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                                <mask id="LockIcon-mask-2" fill="white">
                                    <use href="#LockIcon-path-1"></use>
                                </mask>
                                <use id="Combined-Shape" fill={color} fillRule="nonzero" href="#LockIcon-path-1"></use>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
