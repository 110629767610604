
import React, { useRef, useEffect, useState } from 'react'

import ScrollHelper from 'bwax-ui/ScrollHelper';

import Button, { Pressable, iconButton, iconDropdownMenu } from "bwax-ui/components/Button";

import { guid } from 'bwax/utils'

import { copy } from "bwax-ui/ml/FrontEndHelper";
import { toast } from 'bwax-ui/components/Toast';

import { getNickNameAndAvatar } from './AdminTranslationList';

import ClampedText from 'bwax-ui/components/ClampedText';

import { Helmet } from 'react-helmet-async'

export default function AdminTranslationRedeemCodes({ facade }) {

    //  TODO loadMore list 可以抽象成一个 components
    const [data, setData] = useState();

    const [hasMore, setHasMore] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);

    const [count, setCount] = useState();

    const pageSize = 20;

    const listRef = useRef();

    const dataRef = useRef();
    dataRef.current = data;

    const [keyword, setKeyword] = useState("");

    const entityName = "兑换码";
    const fieldPaths = ["内容", "兑换者.昵称", "兑换者.头像", "兑换者.手机号用户.手机号", "已发放", "赠送积分"];
    async function doLoadData(offset = 0, isRefresh) {
        setLoadingMore(true);
        const [result, error] = await facade.listAll({
            entityName, fieldPaths,
            sort: [
                { field: "创建时间", order: "DESC" }
            ],
            search: keyword ? {
                keyword,
                fields: [{ field: "内容", weight: 100 }, { field: "兑换者.昵称", weight: 50 }, { field: "兑换者.手机号用户.手机号", weight: 50 }]
            } : undefined,
            offset, pageSize
        }, { forceRefreshing: isRefresh })  // keyword, pageIndex / offset, ...
        setLoadingMore(false);

        if (error) {
            //  error handling TODO
        } else if (result) {

            const hasMore = result.pageSize + offset < result.count;
            setHasMore(hasMore);
            setCount(result.count);

            if (isRefresh) {
                setData(result.data);
                dataRef.current = result.data;
            } else {
                const existing = dataRef.current || [];
                const toAppend = result.data.filter(s => !existing.some(e => e.id === s.id));
                const newData = [...existing, ...toAppend];

                setData(newData);
                dataRef.current = newData;
            }

            if (hasMore) {
                setTimeout(() => {
                    if (listRef.current) {
                        const { scrollHeight, clientHeight } = listRef.current;
                        if (scrollHeight <= clientHeight && hasMore) {
                            doLoadData(offset + pageSize);
                        }
                    }
                }, 300);
            }
        }
    }

    const loadMore = () => {
        if (hasMore) {
            doLoadData(tasks.length);
        }
    }

    const loadMoreRef = useRef();
    loadMoreRef.current = loadMore;

    useEffect(() => {
        if (listRef.current) {
            const scrollHelper = new ScrollHelper(listRef.current, {
                onScrolledToBottom: _ => {
                    // console.log("should load more", loadMoreRef);
                    loadMoreRef.current && loadMoreRef.current();
                }
            });
            return () => {
                scrollHelper.cleanUp();
            }
        }
    }, [listRef.current]);

    // 
    async function createNew() {
        // 
        const code = guid().slice(0, 5);
        const [result, error] = await facade.add({ entityName, fieldPaths, formData: { 内容: code, 赠送积分: 5 } });
        if (!error && result) {
            setData(prev => [result, ...(prev || [])])
            setCount(count => (count || 0) + 1);
        }

    }


    async function updateRecord(id, formData) {
        // 
        const [result, error] = await facade.update({ id, entityName, fieldPaths, formData });
        if (!error && result) {
            setData(prev => (prev || []).map(p => p.id == id ? result : p))
            // setCount(count => (count || 0) + 1);
        }

    }



    useEffect(() => {
        setData()
        dataRef.current = undefined;
        doLoadData();
    }, [keyword])


    function renderItem(r) {

        function renderAvatar(user) {
            const { nickName, avatar } = getNickNameAndAvatar(user);
            return (
                <div className="flex items-center gap-2 px-2 py-1 rounded">
                    <ClampedText className="font-size-12 text-[var(--gray11)] whitespace-nowrap max-w-[5rem] flex items-center" tipEnabled={false}>{nickName}</ClampedText>
                    {avatar}
                </div>
            )
        }

        return (
            <div className="flex gap-2 justify-between items-center px-4 py-2" key={r.id}>
                <div className="flex gap-0.5 items-center">
                    {iconButton(<i className="bx bx-copy font-size-12" />, _ => {
                        copy(r.内容);
                        toast({ title: `兑换码 ${r.内容} 已复制` })
                    })}
                    <div className="w-[3rem]">{r.内容}</div>
                    <div className="whitespace-pre text-[var(--gray11)] font-size-12 px-1.5 bg-[var(--gray2)] rounded">
                        {r.赠送积分}
                    </div>
                </div>
                <div>
                    {r.兑换者 ? (
                        renderAvatar(r.兑换者)
                    ) : (
                        !r.已发放 ? (
                            <Button size="small" appearance="outline" onPress={_ => updateRecord(r.id, { 已发放: true })}>标记发放</Button>
                        ) : (
                            <Button size="small" color={"grass"} className="gap-1" onPress={_ => updateRecord(r.id, { 已发放: false })}>已发放<i className="bx bx-check-circle !text-[12px]" /></Button>
                        )
                    )}
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col h-full w-full max-w-xl self-center pb-2 pt-2">
            <Helmet>
                <title>文档翻译 | 兑换码列表</title>
            </Helmet>
            <div className="flex pt-2 pb-3 px-5 sm:px-8 gap-1 items-center">
                <Button className="" onPress={_ => createNew()}>
                    新增兑换码
                </Button>
                {count ? (
                    <div className="whitespace-pre text-[var(--gray11)] font-size-12 px-2 ">
                        共{count || null}条记录
                    </div>
                ) : null}
                {iconButton(<i className='bx bx-refresh'></i>, _ => {
                    // console.log(">>> refresh");
                    setData();
                    doLoadData(0, true);
                })}
            </div>
            <div className="grow flex py-2 flex-col gap-1 pl-3 pr-4 sm:px-4 overflow-auto" ref={listRef}>
                {
                    data ? data.map(renderItem) : null
                }
                {
                    loadingMore ? (
                        Array(data && data.length > 0 ? 5 : 10).fill(0).map((_, index) => {
                            return (
                                <React.Fragment key={"l" + index}>
                                    <div className="flex gap-2 sm:gap-16 py-2 px-4 sm:px-5 w-full">
                                        <div className="flex gap-4 grow">
                                            <div className="bg-[var(--gray2)] h-4 w-4" />
                                            <div className="bg-[var(--gray2)] h-4 grow" />
                                        </div>
                                        <div className="bg-[var(--gray2)] h-4 w-10" />
                                    </div>
                                </React.Fragment>
                            )
                        })
                    ) : null
                }
            </div>
        </div>
    )
}
