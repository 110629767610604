

import React, { useEffect, useContext } from 'react'

import DataLoaderContext, { DataLoaderProvider } from 'bwax-ui/store/DataLoaderContext'

import Cookies from 'js-cookie'

import { runDataQuery } from 'bwax/query/runClientQuery'

import NotFoundPage from './NotFoundPage'

import { getInstance, buildCacheKey, GLOBAL } from 'bwax/store/DataCache';

import qs from 'query-string';

import { Helmet } from 'react-helmet-async'
import RenderWithData from 'bwax-ui/store/RenderWithData'

import createRouteTo from 'bwax-ui/page/createRouteTo';
import PageEnvContainer from 'bwax-ui/page/PageEnvContainer';

import UrlPattern from "url-pattern";
import FacadeContainer from './FacadeContainer'

import getTokenCookieName from 'bwax/query/getTokenCookieName';

function PageRenderer_inner(props) {

    const { data: page, dlc, location, history, alternativeContext } = props;
    const { endpoints, sessionToken, sandbox, tenantCode } = dlc;

    const { pathname, search } = location;

    function getReferrer() {
        if (typeof (document) !== "undefined") {
            return document.referrer
        } else {
            return ""
        }
    }

    function getRK() {
        if (typeof (window) !== 'undefined') {
            return qs.parse(search)._rk_
        } else {
            return undefined
        }
    }

    function getRKHeaders() {
        if (typeof (window) !== 'undefined') {
            const rk = getRK() || sessionStorage.getItem("_rk_");

            if (rk) {
                return {
                    "X-RK": rk
                }
            }
        }
        return {}
    }

    const queryRunner = runDataQuery({
        endpoints,
        sessionToken,
        sandbox,
        tenantCode,
        headers: {
            "X-Referrer": getReferrer(),
            ...getRKHeaders(),
            "X-ServerName": dlc.userenv.serverName,
        }
    });

    const path = pathname + search;
    useEffect(() => {
        if (typeof (window) !== 'undefined') {
            addPageView(queryRunner, path)
        }
    }, [path])

    if (page == null) {
        return <NotFoundPage />
    }

    if (page && page.maintaining) {
        return (
            <>
                <Helmet>
                    <meta
                        name="viewport"
                        content="width=device-width,initial-scale=1,maximum-scale=1"
                    />
                    <title>系统维护中</title>
                </Helmet>
                <div style={{ padding: 16 }}>
                    系统维护中，请稍后再访问~
                </div>
            </>

        )
    }

    /// handle the dynamic page
    const { params, page: p, application, matchRedirects, urlPattern } = page

    // https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1241
    // 尝试跳转
    if(matchRedirects && matchRedirects.length > 0) {       
        const actualPathname = constructPattern(urlPattern).stringify(params);
        if(actualPathname !== pathname) {
            history.replace(actualPathname);
        }    
    }
    

    /// extract all the query search
    const baseData = {
        ...params,
        ...(qs.parse(search))   /// Query 参数有可能覆盖掉 Path 参数
    }



    /// 如下的代码最好是封装到一个专门的 Helper function 里，并做错误处理 Catch All
    if (p.compiled && !p.compiled.def) {
        //  this is a new page 
        const { userenv, sandbox, tenantCode } = dlc;
        const { host, protocol = "https" } = userenv;

        const domainEnv = {
            mobileHost: host, // userenv.mobileHosts[0],
            isSandbox: sandbox,
            protocol,
            tenantCode,
            urlPrefix: (userenv.protocol || "https:") + "//" + host
                + (sandbox ? "/sandbox" : "") + (tenantCode ? "/-/" + tenantCode.toLowerCase() : ""),
            
        }

        const currentURL = pathname + search; // hash 不支持
        const currentURLPath = pathname;

        const webEnv = {
            protocol,
            host, // userenv.mobileHosts[0],
            isSandbox: sandbox,
            isIOS: userenv.isIOS,
            isWeChat: userenv.isWeChat,
            isWxWork: userenv.isWxWork,
            isMobile: userenv.isMobile,
            currentURL,
            currentURLPath,
            originalURL: userenv.originalURL,
        }

        const route_to = createRouteTo(history, ["/ext/pay"], dlc);

        const viewEnv = {
            routeTo: route_to,
            webEnv,
            domainEnv
        }

        return (
            <FacadeContainer application={application} Comp={PageEnvContainer} {...{
                params: baseData,
                page: p,
                urlPattern,
                pathname,
                search,
                dlc,
                viewEnv,
                alternativeContext,
            }} >
            </FacadeContainer>
        );
    }

    return (
        <div style={{
            padding: "1rem"
        }}>
            Bwax 1.0 后，旧版本页面已经不再支持
        </div>
    )
}

const Mut_addPageStatEntityPageView = `
mutation ($input: AddPageStatEntityPageViewInput!) {
  addPageStatEntityPageView (input: $input) {
    clientMutationId
  }
}
`

function addPageView(queryRunner, path) {

    queryRunner(Mut_addPageStatEntityPageView)({
        input: {
            path,
            clientMutationId: "" + Date.now()
        }
    })
}


function constructPattern (urlPattern) {
    return new UrlPattern(urlPattern, {
        segmentValueCharset: '\\u4e00-\\u9fa5aa-zA-Z0-9-_~ %=+^*'
    });
}

function matchPattern (urlPattern, pathname) {
    const pattern = constructPattern(urlPattern);
    const params = pattern.match(pathname);
    return params;
}

export default function PageRenderer(props) {

    const { location, history } = props;

    const { pathname } = location;
    const dlc = useContext(DataLoaderContext);

    const { hostname } = dlc.userenv;
    
    const loadData = async () => {

        const dataCache = getInstance(dlc);

        // Global

        // cached paged
        const cacheKey = buildCacheKey(GLOBAL, "matchPagePatterns_" + hostname, dlc)
        const cachedPatterns = dataCache.get(cacheKey) || {};
        // <pattern> -> { page, maintaining, matchRedirects }

        // 1. 先用从 cache 里面的 pattern 尝试一下：
        const patterns = Object.keys(cachedPatterns);

        for (let urlPattern of patterns) {
            const params = matchPattern(urlPattern, pathname);

            if(params) {
                // 匹配成功;                
                const data = {
                    ...cachedPatterns[urlPattern],
                    params,
                    urlPattern
                }
                return data;
            }
        }

       

        const pageData = await matchPage(pathname, hostname)(dlc);        

        // set cache
        if(pageData) {
            const { page, maintaining, matchRedirects, urlPattern, params, application } = pageData;

            // console.log(">>> pathname", pathname, hostname, urlPattern);
            
            dataCache.set(cacheKey, { ...cachedPatterns, [urlPattern]: { page, maintaining, matchRedirects, application } })

            // 如果经过了跳转，更新 path
            if(pageData.matchRedirects && pageData.matchRedirects.length > 0) {
                const actualPathname = constructPattern(urlPattern).stringify(params);
                history.replace(actualPathname);
            }
        }
        return pageData;

    };

    // 在这个层面要重新从 cookie 里读 sessionToken，因为 LoginQrCode 或者其他 widget 可能会登录或者登出用户：
    // 而 sessionToken 是跟 dataCache 有关的，如果不及时变化，可能就会导致下面这个 issue
    // https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/955
    const sessionToken = (() => {
        const { tenantCode, sandbox } = dlc;
        const tokenCookieName = getTokenCookieName({ tenantCode, sandbox })

        return Cookies.get(tokenCookieName);
    })();

    const inner = dlc => (
        
        <RenderWithData {...{
            ...props,
            loadData,
            dlc,
            dataKey: "MatchPage_" + pathname + "@" + hostname,
            Component: PageRenderer_inner,
            
            noSuspense: true,

            // fallback: <PlainColor name="P60" />
        }} />
    )


    if(sessionToken !== dlc.sessionToken) { 
        // 只有当 dlc 真的改变了，才重新设置，避免无谓的 re-render 
        // https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1087
        const newDLC = { ...dlc, sessionToken: sessionToken || dlc.sessionToken };
        return (
            <DataLoaderProvider {...newDLC }>
                { inner(newDLC) }
            </DataLoaderProvider>            
        )
    } else {
        return inner(dlc);
    }

}


const QueryMatchPage = `
query($urlPath: String!, $domain: String) {
    definition {
      matchPage(urlPath: $urlPath, domain: $domain) {
        page {
          compiled
          
        }
        params
        maintaining
        urlPattern
        matchRedirects {
          urlPattern
          targetPattern
        }
        application { name code }
      }
    }
  }
`

function matchPage(urlPath, domain) {
    return async env => {
        const result = await runDataQuery(env)(QueryMatchPage)({
            urlPath, domain
        })
        const { errors, data } = JSON.parse(result);
        
        if(errors) {
            // TODO error handling
            return null
        }

        return data.definition.matchPage;
        // null indicates NOT FOUND

    }
}

