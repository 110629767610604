

import React, { useState } from 'react'

import Avatar from 'bwax-ui/components/Avatar';
import PopoverTrigger from 'bwax-ui/components/PopoverTrigger';
import Button from 'bwax-ui/components/Button';

import { toast } from 'bwax-ui/components/Toast';
import QrCode from 'bwax-ui/auxiliary/QrCode'

import EditRecordForm from 'bwax-ui/components/records/EditRecordForm';

import { useTrack } from 'bwax-ui/track';

export default function ViewPersonaSharing({ facade, persona, sharing, fieldPaths, onUpdated }) {

    const track = useTrack();
    
    async function updateSharing(formData) {
        const [ result, error ] = await facade.update({
            entityName: "角色分享", id: sharing.id, fieldPaths, formData
        });
        if(!error) {
            onUpdated(result);
        }
    }

    const fieldItems = [
        { name: "说明", placeholder: "向其他用户简单介绍这个知识助手扮演什么角色，掌握哪些专业知识" },
        { name: "欢迎消息", placeholder: "知识助手发给其他用户的第一条欢迎消息" },
    ]

    const [ isEditing, setIsEditing ] = useState();

    return (
        <div className="pt-0 px-8 pb-4">
            <div className="flex items-center font-size-18">
                {
                    isEditing ? (
                        <>
                            <i className="bx bx-edit text-2xl sm:text-[22px] mr-2" />
                            正在编辑分享信息
                        </>
                    ) : (
                        <>
                            <i className="bx bx-share-alt text-2xl sm:text-[22px] mr-2" />
                            { sharing.已暂停 ? "已暂停分享知识助手" : "正在分享知识助手" }
                        </>
                    )
                }
            </div>
            <div className="flex pt-8 pb-2 gap-4">
                <Avatar avatar={persona.头像} nickName={persona.名称} size={48} /> 
                <div className="flex flex-col grow gap-2">
                    <div className="font-medium font-size-16">{persona.名称}</div>
                    <div className="whitespace-pre-line text-[var(--gray11)]">
                        { persona.提示 }
                    </div>
                </div>
            </div>
            <div className="px-2 pt-4 pb-8 flex flex-col gap-6">
                { ["欢迎消息"].map(name => {
                        return (
                            <div className="flex flex-col gap-2" key={name}>
                                <div className="">{name}</div>
                                <div className="whitespace-pre-line text-[var(--gray11)]">
                                    { persona[name] || "-" }
                                </div>
                            </div>
                        )
                    })
                }
                { isEditing ? (
                    <EditRecordForm {...{
                        color: "violet",
                        facade, entityName: "角色分享",
                        // 
                        record: sharing,
                        fieldItems,
                        onUpdated: r => {
                            onUpdated(r);
                            toast({ title: "已修改分享信息" });       
                            setIsEditing(false) 
                        },
                        onCancel: _ => { setIsEditing(false) },
                        fieldPaths,
                    }} />
                ) : (
                    <>
                    { ["说明"].map(name => {
                        return (
                            <div className="flex flex-col gap-2" key={name}>
                                <div className="">{name}</div>
                                <div className="whitespace-pre-line text-[var(--gray11)]">
                                    { sharing[name] }
                                </div>
                            </div>
                        )
                    })}
                    <div className="flex flex-col gap-2">
                        <div>访问链接</div>
                        <div className="text-[var(--gray11)]">
                            <span className="">{ sharing.url }</span>
                            <PopoverTrigger placement="top" content={
                                <div className="px-6 py-6">
                                    <QrCode
                                        info={sharing.url}
                                        size={160}
                                        onGenerated={() => {
                                            // console.log("Generated", text)
                                        }}
                                    />
                                </div>
                            }>
                                <div className="cursor-pointer ml-2 inline-flex items-center justify-center h-5 w-5">
                                    {/* 如果是 微信则直接点击进入，否则显示二维码按钮 */}
                                    <i className='bx bx-qr'></i>
                                </div>
                            </PopoverTrigger>
                        </div>
                    </div>
                    <div className="flex gap-3">
                        {
                            sharing.已暂停 ? (
                                <Button color="grass" onPress={_ => {
                                    track("persona_resume_sharing");
                                    updateSharing({ 已暂停: false })
                                }}>继续分享</Button>
                            ) :(
                                <Button color="tomato" onPress={_ => {
                                    track("persona_pause_sharing");
                                    updateSharing({ 已暂停: true })
                                }}>暂停</Button>
                            ) 
                        }
                        <Button color="violet" onPress={_ => setIsEditing(true) }>编辑</Button>
                    </div>
                    </>
                )}
            </div>
        </div>
    )
}
