
import React from 'react';

import { useOverlayTrigger } from 'react-aria';
import { useOverlayTriggerState } from 'react-stately';


import { DismissButton, Overlay, usePopover } from 'react-aria';
import { Pressable } from './Button';

import './PopoverTrigger.less';
import classNames from 'classnames';

// import type {AriaPopoverProps} from 'react-aria';
// import type {OverlayTriggerState} from 'react-stately';

// interface PopoverProps extends Omit<AriaPopoverProps, 'popoverRef'> {
//   children: React.ReactNode;
//   state: OverlayTriggerState;
// }


/**

'bottom'
  | 'bottom left'
  | 'bottom right'
  | 'bottom start'
  | 'bottom end'
  | 'top'
  | 'top left'
  | 'top right'
  | 'top start'
  | 'top end'
  | 'left'
  | 'left top'
  | 'left bottom'
  | 'start'
  | 'start top'
  | 'start bottom'
  | 'right'
  | 'right top'
  | 'right bottom'
  | 'end'
  | 'end top'
  | 'end bottom'

 */

export function Popover({ className, offset = 8, withArrow = true, children, state, ...props }) {
    
    const popoverRef = React.useRef(null);

    const { popoverProps, underlayProps, arrowProps, placement } = usePopover({
        ...props, offset, popoverRef
    }, state);

    function renderContent() {
        if(typeof(children) == "function") {
            return children({ close: state.close, overlayProps: {} })
        } else {
            return children
        }
    }

    return (
        <Overlay>
            <div {...underlayProps} className="lc-popover-underlay" />
            <div
                {...popoverProps}
                style={{
                    ...popoverProps.style,
                    zIndex: 100,
                }}
                ref={popoverRef}
                className={classNames("lc-popover-2 lc-base", className)}
            >
                {/* Arrow 有问题，暂时注释掉 */}
                {/* {withArrow ? <svg {...arrowProps} className="arrow" data-placement={placement}>
                    <path d="M0 0,L6 6,L12 0" />
                </svg> : null} */}
                <DismissButton onDismiss={state.close} />
                    { renderContent() }
                <DismissButton onDismiss={state.close} />
            </div>
        </Overlay>
    );
}

export default function PopoverTrigger({ className, popoverClassName, content, onTriggerPress,  children, ...props }) {
    const ref = React.useRef(null);
    const state = useOverlayTriggerState(props);
    const { triggerProps, overlayProps } = useOverlayTrigger(
        { type: 'dialog' }, state, ref
    );

    const { onPress, ...otherTriggerProps } = triggerProps;

    function renderContent() {
        if(typeof(content) == "function") {
            return content({ close: state.close, overlayProps })
        } else {
            return React.cloneElement(content, overlayProps)
        }
    }

    return (
        <>
            <Pressable {...otherTriggerProps} onPress={_ => {
                if(onTriggerPress) { onTriggerPress() }
                onPress();
            }} ref={ref}>{children}</Pressable>
            {state.isOpen &&
                (
                    <Popover className={popoverClassName} {...props} triggerRef={ref} state={state}>
                        { renderContent }
                    </Popover>
                )}
        </>
    );
}


export function create(props) {
    
    const { children, className, content, key } = props;

    return (
        <PopoverTrigger content={content} className={className} key={key}>
            { children }
        </PopoverTrigger>
    )
}