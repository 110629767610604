
// This file is generated. Don't modify it.
import React from 'react'
export default function ShareIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M12.808458,5.8029016 C9.96455583,5.41179302 7.24729351,7.19070959 6.56273764,9.98597939 C6.50190114,10.2293254 6.25855513,10.3509984 6.01520913,10.2901619 C5.77186312,10.2293254 5.65019011,9.98597939 5.71102662,9.74263338 C6.41943926,6.63651639 9.37317842,4.60425121 12.4735863,4.87005489 L10.8821293,3.23312767 C10.6996198,3.05061817 10.6996198,2.74643566 10.8821293,2.56392615 C11.0646388,2.38141665 11.3688213,2.38141665 11.5513308,2.56392615 L13.6806084,4.69320372 C14.1064639,5.11905923 14.1064639,5.78826075 13.6806084,6.21411627 L11.5513308,8.34339383 C11.3688213,8.52590334 11.0646388,8.52590334 10.8821293,8.34339383 C10.6996198,8.16088433 10.6996198,7.85670182 10.8821293,7.67419231 L12.808458,5.8029016 Z M12.8897338,9.31677786 C12.8897338,9.07343186 13.0722433,8.83008585 13.3764259,8.83008585 C13.6806084,8.83008585 13.8631179,9.01259535 13.8631179,9.31677786 L13.8631179,12.2977665 C13.8631179,13.210314 13.1330798,13.940352 12.2205323,13.940352 L3.64258555,13.940352 C2.73003802,13.940352 2,13.210314 2,12.2977665 L2,4.38902121 C2,3.47647368 2.73003802,2.74643566 3.64258555,2.74643566 L7.90114068,2.74643566 C8.14448669,2.74643566 8.3878327,2.92894516 8.3878327,3.23312767 C8.3878327,3.53731018 8.14448669,3.59814669 7.90114068,3.59814669 L3.64258555,3.59814669 C3.21673004,3.59814669 2.91254753,3.9631657 2.91254753,4.38902121 L2.91254753,12.2977665 C2.91254753,12.723622 3.27756654,13.0278045 3.64258555,13.0278045 L12.1596958,13.0278045 C12.5855513,13.0278045 12.8897338,12.6627855 12.8897338,12.2977665 L12.8897338,9.31677786 Z" id="ShareIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Share">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="ShareIcon-mask-2" fill="white">
                            <use href="#ShareIcon-path-1"></use>
                        </mask>
                        <use id="Combined-Shape" fill={color} href="#ShareIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
        
    )
}
