
import React, { useEffect, useState, useRef } from 'react'

import TextArea from 'bwax-ui/components/inputs/TextArea'
import Button from 'bwax-ui/components/Button'
import classNames from 'classnames'

import renderTime from 'bwax-ui/renderTime'
import ScrollHelper from 'bwax-ui/ScrollHelper';


export default function FeedbackList({ facade, currentUserId, closeModal, loadFeedbackUpdates }) {


    const fieldPaths = ["内容", "回复.内容", "回复.创建时间",  "回复.创建者", "创建时间"];
    const entityName = "反馈意见";

    const [content, setContent] = useState("")

    const [isSubmitting, setIsSubmitting] = useState(false);
    const isReadyToSumbmit = content && content.length > 1;

    const [data, setData] = useState([]);

    const [hasMore, setHasMore] = useState([])
    const [isLoadingMore, setIsLoadingMore] = useState(false)

    // 
    async function loadData(offset = 0) {
        setIsLoadingMore(true);

        const [result, error] = await facade.listAll({
            entityName,
            condition: [[{ field: "创建者.id", op: "eq", value: currentUserId }]],
            sort: [{ field: "创建时间", order: "DESC" }],
            pageSize: 8,
            offset,
            fieldPaths,
        });
        setIsLoadingMore(false);
        if (!error) {
            if (result) {
                const hasMore = result.pageSize + offset < result.count;
                setHasMore(hasMore);
                setData(prev => {
                    // 要排除掉已经存在的，然后在 append 到后面
                    const existing = prev || [];
                    const toAppend = result.data.filter(s => !existing.some(e => e.id === s.id));
                    return [...existing, ...toAppend];
                })
            }
        }
    }

    async function markRead() {
        // 
        const entityName = "反馈更新标记";
        const [result, error] = await facade.findOne({
            entityName, fieldPaths: ["有更新"],
            condition: [[{ field: "用户.id", op: "eq", value: currentUserId }]]
        }, { forceRefreshing: true });
        if (!error && result && result.有更新) {
            await facade.update({ entityName, id: result.id, formData: { 有更新: false, 查看时间: (new Date()).toJSON() } });
            loadFeedbackUpdates();
        }
    }


    useEffect(() => {
        loadData()
        markRead()
    }, [])


    const loadMore = () => {
        if (hasMore) {
            loadData(data.length);
        }
    }

    const listRef = useRef()
    const loadMoreRef = useRef();
    loadMoreRef.current = loadMore;

    useEffect(() => {
        if (listRef.current) {
            const scrollHelper = new ScrollHelper(listRef.current, {
                onScrolledToBottom: _ => {
                    loadMoreRef.current && loadMoreRef.current();
                }
            });
            return () => {
                scrollHelper.cleanUp();
            }
        }
    }, [listRef.current]);


    ////////////


    async function submit() {
        if (!isReadyToSumbmit) return;

        setIsSubmitting(false);
        const [result, error] = await facade.add({ entityName, formData: { 内容: content }, fieldPaths });
        setIsSubmitting(false);
        if (error) {
            // TODO error handling
            return
        } else if (result) {
            setContent("");
            setData(prev => ([result, ...prev]));
        }
    }

    const [replyContents, setReplyContents] = useState({});
    const [isReplying, setIsReplying] = useState({});

    async function reply(feedbackId, content) {
        setIsReplying(prev => ({...prev, [feedbackId]: true}));
        const [result, error] = await facade.add({ entityName: "反馈意见回复", formData: { 内容: content, 相关意见: feedbackId }, fieldPaths: ["内容", "创建时间", "创建者"] });
        setIsReplying(prev => ({...prev, [feedbackId]: false}));
        if (error) {
            // TODO error handling
            return
        } else if (result) {
            setReplyContents(prev => ({...prev, [feedbackId]: ""}));
            setData(prev => {
                return prev.map(f => f.id == feedbackId ? ({ ...f, 回复: [...(f.回复 || []), result]}) : f)
            });
        }
    }

    function renderFeedback(feedback, index) {

        const editingReply = replyContents[feedback.id] || "";


        return (
            <div className={classNames("py-5 sm:py-4 flex flex-col gap-4", { "border-t": index !== 0 })} key={feedback.id}>
                <div className="flex flex-col gap-1">
                    <div className="grow opacity-80">{feedback.内容}</div>
                    <div className="text-[var(--gray11)]">{renderTime(feedback.创建时间)}</div>
                </div>
                {
                    feedback.回复 && feedback.回复.length > 0 ? (
                        <div className="flex flex-col gap-1 px-4 py-2 bg-[var(--gray1)] rounded">
                            <div className="text-[var(--gray10)] font-size-12">回复</div>
                            {feedback.回复.map(r => {
                                return (
                                    <div key={r.id} className="py-1 flex flex-col gap-1">
                                        <div className="grow opacity-80">
                                            <span className="text-[var(--gray11)]">{r.创建者 && r.创建者.id == currentUserId ? "你: " : ""}</span>
                                            {r.内容}
                                        </div>
                                        <div className="text-[var(--gray11)]">{renderTime(r.创建时间)}</div>
                                    </div>
                                )
                            })}
                            <div className="flex flex gap-2">
                                <TextArea minRows={2} styled={true} color="violet" className="grow" placeholder="输入你的回复" value={editingReply} onChange={content => {
                                    setReplyContents(prev => ({ ...prev, [feedback.id]: content }))
                                }} />
                                <div className="flex gap-3">
                                    <Button className="!px-3" color={"violet"} isDisabled={!(editingReply && editingReply.trim().length > 0)} isLoading={isReplying[feedback.id]} onPress={_ => {
                                        reply(feedback.id, editingReply)
                                    }}>回复</Button>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-4 h-full sm:h-[75vh]">
            <div className="font-medium gap-2 flex items-center px-5">
                <i className='bx bx-message-square-edit'></i>
                提交反馈
            </div>
            <div className="flex flex-col gap-4 px-5">
                <div className="flex flex-col gap-3">
                    <div className="text-[var(--gray11)]">
                        如果你在使用中遇到了问题，或者有什么建议，随时可以告诉我们
                    </div>
                    <TextArea styled={true} color="violet" placeholder="输入你的反馈意见" value={content} onChange={setContent} autoFocus />
                    <div className="flex gap-3">
                        <Button className="!px-7" color={"violet"} isDisabled={!isReadyToSumbmit} isLoading={isSubmitting} onPress={_ => {
                            submit();
                        }}>提交</Button>
                        {/* { closeModal ? (
                            <Button className="!px-6"  onPress={_ => {
                                closeModal();
                            }}>关闭</Button>
                        ): null} */}
                    </div>
                </div>
            </div>
            <div className="flex flex-col px-6 py-3 grow overflow-auto min-h-0" ref={listRef}>
                {data.map(renderFeedback)}
                {isLoadingMore ? (
                    Array(3).fill(0).map((_, index) => (
                        <div className="py-3 flex gap-4" key={"l" + index}>
                            <div className="grow h-[2.5rem] bg-[var(--gray2)]" />
                            <div className="h-[1rem] w-[2rem] bg-[var(--gray2)] mr-2" />
                        </div>
                    ))
                ) : null}
                {
                    !isLoadingMore && data.length == 0 ? (
                        <div className="h-full w-full flex flex-col gap-5 justify-center items-center text-[var(--gray9)]">
                            <i className='bx bx-coffee text-[var(--gray7)] text-[64px]'></i>
                            <div className="opacity-80">你还没有提交过反馈</div>
                        </div>
                    ) : null
                }
            </div>
        </div>
    )
}
