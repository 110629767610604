
import React, { useState, useRef, useEffect } from 'react'

import taskSettings, { renderStatus } from './taskSettings';

import "./TaskJobDetail.less"
import DisplayRecordForm from 'bwax-ui/components/records/DisplayRecordForm';
import numeral from 'numeral';

import Button from 'bwax-ui/components/Button';
import OutOfQuotaTip from 'bwax-ui/components/OutOfQuotaTip';

import { useTrack } from 'bwax-ui/track';

import { toast } from 'bwax-ui/components/Toast';

export const fieldPaths = ["类别", "标题", "状态", "进度", "取消", "预估用量", "用量", "创建时间", "已归档"];

export default function TaskJobDetail({ taskJob, facade, remainingQuota, setRefreshedJob, closeModal, shouldRefreshAtFirst }) {
    // 要根据对于的 task category 找到原来的 task

    const track = useTrack();

    const setting = taskSettings.find(t => t.entityName == taskJob.类别);

    const [updating, setUpdating] = useState();
    const reloadRef = useRef();
    

    async function updateJob(formData) {
        setUpdating(true)
        const [result, error] = await facade.update({ entityName: "OpenAI-任务", id: taskJob.id, formData, fieldPaths });
        setUpdating(false)
        if (!error && result) {
            setRefreshedJob(result)
        }
        return [result, error]
    }

    // 
    const firstRefreshSkippedRef = useRef(false); // 第一次状态监听不需要 reload
    useEffect(() => {
        if (!firstRefreshSkippedRef.current) {
            firstRefreshSkippedRef.current = true;
            if (shouldRefreshAtFirst) {
                // don't return
            } else {
                return
            }
        }
        if (taskJob.状态 == "Completed") {
            reloadRef.current && reloadRef.current();
        };

    }, [taskJob.状态]);


    function displayUsage(amount) {
        return !amount ? "-" : numeral(amount).format("0.0a")
    }

    const isQuotaSufficient = remainingQuota + 800 > (taskJob["预估用量"] || 0);

    function renderButton() {
        if (taskJob.状态 == "Init") {
            return (
                <div className="flex flex-col gap-1">
                    <Button appearance="primary" color="violet" isLoading={updating} isDisabled={!isQuotaSufficient} onPress={_ => {
                        // if(isQuotaSufficient) {                            
                        track("task_execute", { "task_category": taskJob.类别 })
                        updateJob({ 状态: "Waiting" })
                        // } else {
                        //     track("task_execute_insufficient", { "task_category": taskJob.类别 })
                        //     console.error("用量不足");
                        // }
                    }}>开始执行</Button>
                    {!isQuotaSufficient ? (
                        // <div className="error font-size-12 text-center">用量余额不足</div>
                        <OutOfQuotaTip label="当天用量余额不足" style={{ paddingBottom: 0 }} showPayBtn={false} />
                    ) : null}
                </div>
            )
        }
        if (taskJob.状态 == "Waiting" || taskJob.状态 == "Processing") {
            return (
                <div className="flex flex-col gap-1" style={{ alignItems: "flex-end" }}>
                    <Button onPress={_ => closeModal()}>关闭</Button>
                    <div className="caption font-size-12 text-center">任务在后台运行，关闭窗口不影响</div>
                </div>
            )
        }
        return <Button onPress={_ => closeModal()}>关闭</Button>
    }

    async function archiveJob() {
        const [result, error] = await updateJob({ 已归档: true });
        if (!error) {
            toast({ title: "已移入回收站", undoAction: async _ => { updateJob({ 已归档: false }) }, duration: 3000 });
            closeModal();
        } else {
            // TODO error handling.
        }
    }

    const hasUsed = ["Waiting", "Processing", "Completed"].indexOf(taskJob["状态"]) !== -1

    return (
        <div className="lc-task-job-detail flex flex-col gap-8 sm:gap-6">
            <div className="task-category-title font-size-16">
                <div className="icon">
                    {setting.icon}
                </div>
                <div className='title'>
                    {setting.label}
                </div>
            </div>
            <div className="flex justify-between gap-3">
                <div className="flex flex-col gap-2">
                    <div className="">
                        {taskJob.标题}
                    </div>
                    <div className="flex gap-4 text-[var(--gray11)] font-size-12">
                        <div>{ hasUsed ? "消耗用量" : "需要用量" }: {displayUsage(taskJob["预估用量"])}</div>
                        {/* <div>实际用量: {displayUsage(taskJob["用量"])}</div> */}
                    </div>
                </div>
                <div className="pt-1">
                    {renderStatus(taskJob.状态, taskJob.进度, 20)}
                </div>
            </div>
            <DisplayRecordForm {...{
                entityName: taskJob.类别,
                facade,
                condition: [[{ field: "任务.id", op: "eq", value: taskJob.id }]],
                bindReload: reload => reloadRef.current = reload,
            }} />

            <div className="flex justify-between">
                <div>
                    {(() => {
                        if (taskJob.状态 == "Waiting" || taskJob.状态 == "Processing") {
                            // 可以加个取消按钮
                            return null
                        } else {
                            return (
                                <Button color="red" onPress={_ => {
                                    archiveJob();
                                }}>移除</Button>
                            )
                        }
                    })()}
                </div>
                {renderButton()}
            </div>
        </div>
    )
}

