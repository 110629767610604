
import { useState, useRef, useEffect } from 'react'

import { getViewportWidth } from 'bwax-ui/legacy/page/instructions/useViewportWidth'

/// 用来封装对屏幕宽度范围的判断
export default function useScreenSize() {

    let THRESHOLD = 640;

    const [ screenSize, setScreenSize ] = useState(
        getViewportWidth() < THRESHOLD ? "S": "L"
    );
    const screenSizeRef = useRef(screenSize);
    screenSizeRef.current = screenSize;

    useEffect(() => {
        const handleResizeEvent = () => {
            let vw = getViewportWidth();
            if(vw < THRESHOLD && screenSizeRef.current == "L") {
                setScreenSize("S")
            } else if(vw >= THRESHOLD && screenSizeRef.current == "S") {
                setScreenSize("L")
            } else {
                /// do nothing
            }
        }
        handleResizeEvent()
        window.addEventListener('resize', handleResizeEvent)
        return () => {
            window.removeEventListener('resize', handleResizeEvent)
        }
    }, [])

    return screenSize
}