

// TODO list all the processor types:
const defaultProcessors = {
    'thumbnail': 'image/resize,m_lfit,w_120,limit_1/auto-orient,1/quality,q_100',
    'small': 'image/resize,m_lfit,w_320,limit_1/auto-orient,1/quality,q_100',
    'medium': 'image/resize,m_lfit,w_640,limit_1/auto-orient,1/quality,q_100',
    'large': 'image/resize,m_lfit,w_960,limit_1/auto-orient,1/quality,q_100',
    'xlarge': 'image/resize,m_lfit,w_1440,limit_1/auto-orient,1/quality,q_100',
    'xxlarge': 'image/resize,m_lfit,w_2560,limit_1/auto-orient,1/quality,q_100'
}

export default function getImageURL(image, p = 'xlarge') {

    if (!image) {
        return undefined
    }

    const url = typeof (image) === "string" ? image : image.url;

    if (!url) {
        return ""
    }
    /// TODO 是否应该改为检测 URL 是不是来自于 aliyuncs

    //私有图片不进行处理
    if (url.indexOf('Signature') !== -1) {
        return url  /// do not process
    }

    const processor = p.toLowerCase();

    if (processor) {

        // 另外如果有 "?x-oss-process" 要把原来的去掉
        const cleanedUrl = (() => {
            const index = url.indexOf("?x-oss-process=");
            if (index !== -1) {
                return url.substring(0, index);
            } else {
                return url
            }
        })();

        if (defaultProcessors[processor] !== undefined) {
            return cleanedUrl + '?x-oss-process=' + defaultProcessors[processor]
        }

        return cleanedUrl + '?x-oss-process=' + processor
    }

    return url
}