import React from 'react';
// import createInlineStyleButton from './util/createInlineStyleButton';
import InlineStyleButton from '../util/InlineStyleButton';

import { MdSuperscript } from 'react-icons/md'

export default function SuperscriptButton (props) {
    const { editor } = props

    return InlineStyleButton({
        editor,
        style: 'sup',
        children: (
            <MdSuperscript style={{ fontSize: 16 }} />
        ),
    })
}

// export default createInlineStyleButton({
//   style: 'sup',
//   children: (
//     <i className="fa fa-superscript" aria-hidden="true"></i>
//   ),
// });
