
import React from 'react'

import { Helmet } from 'react-helmet-async'

export default function TestVisibility() {
    return (
        <div>
                    <Helmet>
        <meta
            name="viewport"
            content="width=device-width,initial-scale=1,maximum-scale=1"
        />
        <title>系统维护中</title>
    </Helmet>
            <div>The one below is not visible</div>
            <div style={{ visibility: "hidden" }}>
                I'm invisible.
            </div>
        </div>
    )
}
