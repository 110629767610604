
import React, { useEffect, useState } from 'react'

import Loading from "bwax-ui/components/Loading";
import classNames from 'classnames';

import getImageURL from 'bwax/util/getImageURL';

import { Pressable } from "bwax-ui/components/Button";

import Modal from 'bwax-ui/components/Modal'

import BeatLoader from 'react-spinners/BeatLoader';

// condition: [[{ field: "任务.id", op: "eq", value: taskJob.id }]],

const components = {
    "OpenAI-生成图片任务": ImageTask,   /// DALLE-3
    "OpenAI-文本生成图片任务": ImageTask, /// SDXL
}

function renderLoading() {
    return <Loading />
}

export default function ChatMessageTask({ taskId, taskCategory, facade, className }) {

    function renderTask() {
        const Component = components[taskCategory]
        if (!Component) {
            return (
                <div className="text-[var(--gray11)]">
                    无法显示内容
                </div>
            )
        }

        return (
            <Component {...{ taskId, facade, entityName: taskCategory }} />
        )
    }

    return (
        <div className={classNames("max-w-full !min-w-[12rem] min-h-[8rem] flex items-center justify-center !p-0 overflow-hidden", className)}>
            {renderTask()}
        </div>
    )
}


// 
function ImageTask({ taskId, facade, entityName }) {
    
    const [taskData, setTaskData ] = useState();

    const [isFullView, setIsFullView] = useState(false);

    async function loadTaskData() {
        const [result, error] = await facade.findOne({
            entityName,
            condition: [[{ field: "任务.id", op: "eq", value: taskId }]],
            fieldPaths: ["状态", "进度", "提示", "生成图" ]
        }, { forceRefreshing: true })
        if (error) {
            setError(error)
        } else if (result) {
            setTaskData(result);
        }
    };

    useEffect(() => {
        if(taskData && (taskData.状态 == "Waiting" || taskData.状态 == "Processing")) {
            setTimeout(() => {
                loadTaskData()
            }, 1000)
        }
    }, [ taskData ]);


    useEffect(() => {
        loadTaskData();
    }, [taskId]);

    if(!taskData) {
        return (
            <div className="h-[18rem] w-[18rem]">
                { renderLoading() }
            </div>
        );
    } 


    const image = (() => {
        if(Array.isArray(taskData.生成图)){
            return taskData.生成图[0]
        }
        return taskData.生成图;
    })();

    if(image) {
        return (
            <>            
            <Pressable onPress={_ => {
                setIsFullView(true);
            }}>
                <img  className="h-[18rem] w-[18rem]" src={getImageURL(image, "small")}/>                
            </Pressable>
            { isFullView ? (
                <Modal isDismissable isOpen={true} isMain={false} isTransparent={true} onOpenChange={open => {
                    if(!open) {
                        setIsFullView(false)
                    }
                }}>
                    <div className=" flex items-center justify-center">
                        <img src={image.url}  className="max-h-[88vh] max-w-[100vw]" />
                        {/* <div className={"h-[84vh] w-[60vw]"}></div> */}
                    </div>
                </Modal>
            ) : null }
            </>
        )
    } 

    function renderPlaceholer () {
        if(taskData && taskData.状态 == "Terminated") {
            return (
                <div className="text-[var(--gray10)] font-size-12">无法生成图片</div>
            )
        }
        return (
            <>
                <BeatLoader size={8} color="var(--gray8)" />    
                <div className="text-[var(--gray10)] font-size-12">正在生成图片</div>
            </>
        )
    }

    return (
        <div className="flex flex-col gap-4 items-center justify-center h-[18rem] w-[18rem]">
            { renderPlaceholer() }
        </div>
    )



}