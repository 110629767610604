
import React, { useEffect, useRef, useState } from 'react'

import { useSelectState } from 'react-stately';
import { HiddenSelect, useSelect } from 'react-aria';

import ListBox, { ListBoxStateless } from '../ListBox';
import { Pressable } from '../Button';
import { Popover } from '../PopoverTrigger';
import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';

import classNames from 'classnames';

import './SelectInput.less';

import useResizeObserver from '@react-hook/resize-observer'


// item: { label, value, isDisabled }
export default function SelectInput(props) {

    const {
        items, ...otherProps
    } = props;

    function collectDisabledKeys(items) {
        return items.flatMap(item => {
            const { label, value, isDisabled, items } = item
            return items ? collectDisabledKeys(items) : (isDisabled ? [value] : [])
        })
    }
    const disabledKeys = collectDisabledKeys(items);

    function renderItem(item, index) {
        const { label, value, items } = item
        if (items) {
            // section
            return (
                <ListBox.Section title={item.label} key={index} aria-label={item.label}>
                    {items.map(renderItem)}
                </ListBox.Section>
            )
        } else {
            return (
                <ListBox.Item key={value} textValue={value}>{label}</ListBox.Item>
            )
        }
    }

    return (
        <Select_comp {...otherProps} disabledKeys={disabledKeys}> 
            {items.map(renderItem)}
        </Select_comp>
    )
}

function Select_comp(props) {

    const { 
        placeholder = "Please select", listBoxStyle, style, valueStyle, color, label = "select", 
        selected, onSelect, multivalued,  
        className, listBoxClassName,

        itemUnstyled,
        
        ...otherProps 
    } = props;


    // TODO 暂时只支持 Multivalued = false;

    // const onSelectionChange = selection => {
    //     if (multivalued) {
    //         onSelect([...selection]);
    //     } else {
    //         onSelect([...selection][0]);
    //     }
    // }

    // const selectionMode = multivalued ? "multiple" : "single";
    // const selectedKeys = multivalued ? selected : ([selected].filter(x => !!x))

    // Create state based on the incoming props
    const state = useSelectState({
        ...otherProps,
        onSelectionChange: selection => {
            onSelect(selection)
        },
        selectedKey: selected,
        // selectionMode
    });

    useEffect(() => {
        if(selected !== state.selectedKey) {
            state.setSelectedKey(selected)
        }
    }, [ selected ]);





    // Get props for child elements from useSelect
    const ref = useRef(null);
    const {
        labelProps,
        triggerProps,
        valueProps,
        menuProps
    } = useSelect({ 
        label, ...otherProps
    }, state, ref);

    const [ width, setWidth ] = useState();
    useResizeObserver(ref, entry => {
        if(entry && entry.borderBoxSize && entry.borderBoxSize[0]) {
            const width = entry.borderBoxSize[0].inlineSize;
            setWidth(width);
        }
    });

    return (
        <>
            <HiddenSelect
                isDisabled={props.isDisabled}
                state={state}
                triggerRef={ref}
                label={props.label}
                name={props.name}
            />
            <Pressable
                {...triggerProps}
                ref={ref}
                style={{ height: 30, fontSize: 14 }}
            >
                <div className={classNames("lc-select-input", className)} style={style}>
                    <div {...valueProps} style={valueStyle} className="current-value">
                        {state.selectedItem
                            ? state.selectedItem.rendered
                            : (placeholder ? <div className="placeholder">{ placeholder }</div> : null)}
                    </div>
                    <div aria-hidden="true" className="icon">
                        { state.isOpen ? <ChevronUpIcon /> : <ChevronDownIcon /> }
                    </div>
                </div>
            </Pressable>
            {state.isOpen ? 
                (
                    <Popover state={state} triggerRef={ref} placement="bottom start" className="lc-select-popover" withArrow={false} offset={4}>
                        <ListBoxStateless
                            {...menuProps}
                            state={state}
                            color={color}
                            className={listBoxClassName}
                            
                            style={{
                                ...listBoxStyle, 
                                width
                            }}
                            itemUnstyled={itemUnstyled}
                        />
                    </Popover>
                ) : null
            }
        </>
    )
}