// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Js_json = require("bs-platform/lib/js/js_json.js");
var Json$BwaxMobile = require("../../bwax-js/ml/utils/json.bs.js");
var FrontEndHelper = require("./FrontEndHelper");

function gotoWechatLogin(prim, prim$1) {
  FrontEndHelper.gotoWechatLogin(prim, prim$1);
  return /* () */0;
}

function gotoLoginUrlOrWechatLogin(prim, prim$1, prim$2, prim$3, prim$4) {
  FrontEndHelper.gotoLoginUrlOrWechatLogin(prim, prim$1, prim$2, prim$3, prim$4);
  return /* () */0;
}

function addInvisibleElement(prim) {
  FrontEndHelper.addInvisibleElement(prim);
  return /* () */0;
}

function copy(prim) {
  FrontEndHelper.copy(prim);
  return /* () */0;
}

function alert(prim) {
  FrontEndHelper.alert(prim);
  return /* () */0;
}

function logout(prim, prim$1) {
  FrontEndHelper.logout(prim, prim$1);
  return /* () */0;
}

function toast(prim, prim$1, prim$2) {
  FrontEndHelper.toast(prim, prim$1, prim$2);
  return /* () */0;
}

function scrollIntoView(prim) {
  FrontEndHelper.scrollIntoView(prim);
  return /* () */0;
}

function removeIdAndUrl(prim) {
  return FrontEndHelper.removeIdAndUrl(prim);
}

function isWeChat(prim) {
  return FrontEndHelper.isWeChat();
}

function isMobile(prim) {
  return FrontEndHelper.isMobile();
}

function isIOS(prim) {
  return FrontEndHelper.isIOS();
}

function currentURLPath(prim) {
  return FrontEndHelper.currentURLPath();
}

function currentURL(prim) {
  return FrontEndHelper.currentURL();
}

function downloadURL(prim, prim$1) {
  FrontEndHelper.downloadURL(prim, prim$1);
  return /* () */0;
}

function downloadFile(file, name) {
  var maybeURL = Json$BwaxMobile.get_value("url", Js_json.decodeString, file);
  if (maybeURL !== undefined) {
    FrontEndHelper.downloadURL(maybeURL, name);
    return /* () */0;
  } else {
    console.log("No URL");
    return /* () */0;
  }
}

exports.gotoWechatLogin = gotoWechatLogin;
exports.gotoLoginUrlOrWechatLogin = gotoLoginUrlOrWechatLogin;
exports.addInvisibleElement = addInvisibleElement;
exports.copy = copy;
exports.alert = alert;
exports.logout = logout;
exports.toast = toast;
exports.scrollIntoView = scrollIntoView;
exports.removeIdAndUrl = removeIdAndUrl;
exports.isWeChat = isWeChat;
exports.isMobile = isMobile;
exports.isIOS = isIOS;
exports.currentURLPath = currentURLPath;
exports.currentURL = currentURL;
exports.downloadURL = downloadURL;
exports.downloadFile = downloadFile;
/* Json-BwaxMobile Not a pure module */
