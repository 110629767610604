
import React, { useState, useEffect, useRef } from 'react'
import qrcode from 'qrcode'

export default function QrCode({ info, scale, icon, size, className }) {

    const [imgSrc, setImgSrc] = useState(null);

    const canvas = useRef(null);

    useEffect(() => {
        generateQrCode(canvas.current,
            {
                info, scale, icon, width: size,
                onGenerated: dataURL => setImgSrc(dataURL)
            })
    }, [info]);


    const qrcodeCanvasStyle = {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        opacity: 0,
        zIndex: -1,
    };

    return (
        <div className={"qr-code bg-white p-3 rounded" + (className ? " " + className : "")}>
            <canvas ref={canvas} style={qrcodeCanvasStyle} />
            {
                imgSrc ?
                    <img
                        src={imgSrc} style={{width:"100%", height: "100%"}}/> :
                    <div  style={{width:"100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}} >
                        二维码生成中...
                    </div>
            }
        </div>
    )
}

/// helper
const generateQrCodeWithIcon = (canvas, icon, qrCodeUrl, onGenerated) => {
    const ctx = canvas.getContext('2d')
    const qrCodeImg = new Image()
    qrCodeImg.crossOrigin = "Anonymous"
    qrCodeImg.src = qrCodeUrl
    qrCodeImg.onload = () => {
        const qrCodeWidth = qrCodeImg.width * 2
        const qrCodeHeight = qrCodeImg.height * 2
        canvas.width = qrCodeWidth
        canvas.height = qrCodeHeight
        ctx.drawImage(qrCodeImg, 0, 0, qrCodeWidth, qrCodeHeight)
        //draw logo image
        const iconImg = new Image()
        iconImg.crossOrigin = "Anonymous"
        iconImg.src = icon
        iconImg.onload = () => {
            const iconWidth = qrCodeImg.width / 3
            const iconHeight = qrCodeImg.height / 3
            const iconX = (qrCodeWidth - iconWidth) / 2
            const iconY = (qrCodeHeight - iconHeight) / 2
            ctx.drawImage(iconImg, iconX, iconY, iconWidth, iconHeight)
            onGenerated(canvas.toDataURL('image/png', 1))
        }
    }
}

const generateQrCode = (canvas, { info, scale, icon, onGenerated, width }) => {
    if (!info) {
        return
    }

    const options = { errorCorrectionLevel: 'L', margin: 0, width: width }

    if (canvas) {
        qrcode.toDataURL(
            canvas,
            info,
            options,
            (error, dataUrl) => {
                if (error) {
                    return console.log('Error =( ', error);
                } else {
                    if (icon) {
                        generateQrCodeWithIcon(canvas, icon, dataUrl, onGenerated)
                    } else {
                        onGenerated(dataUrl)
                    }
                }
            }
        )
    }
}
