import React from 'react'

import EntityText from './EntityText'

import defaultBlockReact from './default/defaultBlockReact'

// void tags reference: https://html.spec.whatwg.org/multipage/syntax.html#void-elements
const VOID_TAGS = [
    "area", 
    "base", 
    "br", 
    "col", 
    "embed", 
    "hr", 
    "img", 
    "input", 
    "link", 
    "meta", 
    "param", 
    "source", 
    "track", 
    "wbr"
]

export default (props) => {

    const { block, blockToReact, styleToReact, entityToReact, entityMap } = props
    const { entityRanges, inlineStyleRanges, text } = block

    const blockObj = blockToReact(block) || defaultBlockReact(block)

    const blockElement = (() => {
        if(React.isValidElement(blockObj)) {
            return blockObj
        } else if(Object.prototype.toString.call(blockObj) === '[object Object]') {
            return blockObj.element
        } else {
            return null
        }
    })()

    if (blockElement === null) {
        return null
    } else {
        const { children } = blockElement.props
        if (children === undefined && VOID_TAGS.indexOf(blockElement.type) === -1) {
            return (
                React.cloneElement(
                    blockElement,
                    {},
                    <EntityText
                        originalText={text}
                        entityMap={entityMap}
                        entityRanges={entityRanges}
                        entityToReact={entityToReact}
                        styleToReact={styleToReact}
                        inlineStyleRanges={inlineStyleRanges} />
                )
            )
        } else {
            return blockElement
        }
    }
}
