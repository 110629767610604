export function characterRanges(offset, ranges) {
    return ranges.filter(r => {
        return offset >= r.offset && offset < r.offset + r.length
    })
}

export function groupCharsWithTags(arr) {
    return arr.reduce((acc, next) => {
        let pre = acc[acc.length - 1]
        if (pre && next && pre.tags.length === next.tags.length &&
            pre.tags.every(ps => next.tags.some(ns => ns === ps))
        ) {
            pre.characters = pre.characters + next.characters
            return [
                ...acc.slice(0, acc.length - 1),
                pre
            ]
        } else {
            return [
                ...acc,
                next
            ]
        }
    }, [])
}