
/// first it needs to query
import { runDataQuery } from 'bwax/query/runClientQuery'

import { isIOS } from 'bwax/clientEnv'
import getImageURL from 'bwax-ui/getImageURL';

import { addQueryParam } from 'bwax/ml/lang/mod/builtin/StringHelper';

import { Base64 } from 'js-base64';
import loadCurrentUser from './loadCurrentUserID';

import { getInstance, buildCacheKey, GLOBAL } from 'bwax/store/DataCache';

const Hashes = require('jshashes')

const SHA256 = new Hashes.SHA256;

const query = `
  query ($debug: Boolean, $jsApiList: [String]!, $url:String!) {
    wxMpJsConfig(debug: $debug, jsApiList: $jsApiList, url: $url) 
  }
`

const wxWorkQuery = `
  query ($jsApiList: [String]!, $url:String!) {
    wxWorkAgentJsConfig(jsApiList: $jsApiList, url: $url) 
  }
`


const getShareUrl = () => isIOS() && false ? window.originalHref : location.href

const getConfigUrl = () => getShareUrl().split('#')[0]


const currentUserIDCache = {};


function getDocumentTitle(){
    if(typeof(document) !== 'undefined') {
        return document.title;
    }
}

function getDocumentDescription () {
    if(typeof(document) !== 'undefined') {
        const el = document.querySelector('meta[name="description"]');
        if(el) {
            return el.content;
        }
    }
}

function getDocumnetIcon (name) {
    if(typeof(document) !== 'undefined') {
        const l = document.querySelectorAll(`link[rel="${name}"]`);
        // find the first one whose href is not "data:image/x-icon;,"
        for(let i = 0; i < l.length; i++){
            const url = l[i].href;
            if(url && url.substring(0, 4) === "http") {
                return url
            }
        }
    }
}

function getDocumentFavicon () {
    return getDocumnetIcon("shortcut icon");
}

function getDocumentWechatIcon () {
    return getDocumnetIcon("wechat-icon");
}

// 把 dlc 放在 document 中，
function getCachedDLC () {
    // 只是让那些不能访问当前 DLC 的计算体（比如 command）需要设置微信分享时，可以使用到之前的 DLC
    if(typeof('document') !== 'undefined') {
        return document.__dlc__;
    }
    // TODO 把 dlc 存在 document 安全吗？
}
function cacheDLC(dlc) {
    if(typeof('document') !== 'undefined') {
        return document.__dlc__ = dlc;
    }
}


export async function setupWechatShare(options, givenDLC) {

    const { title: givenTitle, imageURL: givenImage, desc: givenDesc, shareURL, hidden } = options;
    
    const dlc = (() => {
        if(givenDLC) {
            cacheDLC(givenDLC);
            return givenDLC
        } else {
            return getCachedDLC()
        }
    })();

    // 如果 title, desc, image 不存在 则自动使用 document 里面的 title / description 和 favicon;
    const title = givenTitle || getDocumentTitle();

    const image = givenImage || getDocumentWechatIcon() || getDocumentFavicon();
    const desc = givenDesc || getDocumentDescription();


    // 这里自己查询并保存 CurrentUserID 吧
    const currentUserID = await (async () => {
        const { sessionToken } = dlc;
        if(sessionToken && currentUserIDCache[sessionToken] !== undefined) {
            return currentUserIDCache[sessionToken]        
        } else {
            const result = await loadCurrentUser()(dlc);
            currentUserIDCache[sessionToken] = result;
            return result
        }
    })();

    const wechatShare = new WechatConfig({ debug: false, dlc });
    await wechatShare.setupIfNecessary({ 
        title, image, desc, shareURL, currentUserID, shareHidden: hidden 
    });

}


export default class WechatConfig {
    //// fetch the wechat config 
    constructor({ debug, dlc }) {
        ///
        this.jsApiList = [
            "hideMenuItems", "hideAllNonBaseMenuItem",
            "showMenuItems", "showAllNonBaseMenuItem",
            'onMenuShareTimeline', 'onMenuShareAppMessage'
        ]

        this.debug = debug
        this.dlc = dlc
    }

    async setupIfNecessary({ title, image, desc, shareURL, currentUserID, shareHidden }) {

        // 
        const dlc = this.dlc;
        const dataCache = getInstance(dlc);
        // Global
        // cached config
        const cacheKey = buildCacheKey(GLOBAL, "wechat_config_" + JSON.stringify({jsApiList: this.jsApiList, debug: this.debug}), dlc)

        const config = await (async () => {
            const cachedConfig = dataCache.get(cacheKey);
            if(cachedConfig) {
                // console.log(">> cached", cachedConfig)
                return cachedConfig
            } else {
                // console.log(">>> query config");
                const c = await this.getConfig(this.jsApiList, this.debug);
                dataCache.set(cacheKey, c)
                return c;
            } 
        })();
        ///

        const menuList = [
            'menuItem:share:appMessage',
            'menuItem:share:timeline',
            'menuItem:share:qq',
            'menuItem:share:weiboApp',
            'menuItem:share:facebook',
            'menuItem:share:wxwork',
            'menuItem:share:QZone',
            'menuItem:openWithQQBrowser',
            'menuItem:openWithSafari',
            'menuItem:copyUrl'
        ]

        if (config && typeof (wx) !== "undefined") {
            wx.config({ ...config, debug: this.debug })
            wx.ready(() => {
                if (!shareHidden) {
                    this.configShare({ title, image, desc, shareURL, currentUserID })
                    wx.showMenuItems({
                        menuList
                    })
                    wx.showAllNonBaseMenuItem();

                } else {
                    // don't allow sharing 
                    wx.hideMenuItems({
                        menuList
                    })
                    wx.hideAllNonBaseMenuItem();
                    //wx.hideOptionMenu()
                }

            })
        }

    }

    async getConfig(jsApiList, debug) {
        const url = getConfigUrl()
        
        const result = await runDataQuery(this.dlc)(query)({
            url, jsApiList, debug
        })
        const data = JSON.parse(result).data
        return data && data.wxMpJsConfig
    }

    async getWxWorkConfig(jsApiList) {
        const url = getConfigUrl()
        const result = await runDataQuery(this.dlc)(wxWorkQuery)({
            url, jsApiList
        })
        const data = JSON.parse(result).data
        return data && data.wxWorkAgentJsConfig
    }

    configShare({ title = "", image = "", desc = "", shareURL, currentUserID }) {

        const link = shareURL || getShareUrl();

        // if (image  || !title || !desc) {
        //     console.error("share param is missing", { title, image, desc }, "wechat share config failed")
        //     return
        // }
        function wrapLink() {
            if (currentUserID && currentUserID.uid) {

                function getParentKey() {
                    if (typeof (document) !== 'undefined') {
                        const parentKey = sessionStorage.getItem("_rk_");
                        if (parentKey) {
                            return SHA256.hex(parentKey).slice(0, 8);
                        } else {
                            return undefined
                        }
                    } else {
                        return undefined
                    }
                }

                // build ref key (rk), see http://git.qunfengshe.com/qunfengshe/bwax-app-admin/issues/483
                const uid = currentUserID.uid;
                const timestamp = Date.now();
                const parentKey = getParentKey();

                const rk = ["WX", uid, timestamp, parentKey].filter(x => !!x).join(":")

                const encoded = Base64.encode(rk);

                return addQueryParam("_rk_", encoded, link);

            } else {
                return link
            }
        }


        const imgUrl = getImageURL(image, 'thumbnail')

        wx.onMenuShareTimeline({
            title,
            link: wrapLink(),
            imgUrl,
            success: () => { },
            cancel: () => { }
        })

        wx.onMenuShareAppMessage({
            title,
            desc,
            link: wrapLink(),
            imgUrl,
            success: () => { },
            cancel: () => { }
        });
    }



}
