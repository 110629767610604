// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Plate$BwaxMobile = require("../plate.bs.js");
var Runtime_common$BwaxMobile = require("../lang/mod/runtime_common.bs.js");
var Base_query_types$BwaxMobile = require("../base_query_types.bs.js");

function no_need_remat(entity) {
  var count_of_materialized_fields = Plate$BwaxMobile.List.length(Plate$BwaxMobile.List.filter((function (f) {
                return f[/* materialized */10];
              }))(entity[/* virtual_fields */3]));
  return count_of_materialized_fields === 0;
}

function remat_field_srcs(entity) {
  var declare_field = function (f) {
    if (f[/* materialized */10]) {
      return Base_query_types$BwaxMobile.normalize_field_name(f[/* name */0]) + ": Bool";
    }
    
  };
  var lines = Plate$BwaxMobile.List.keep_map(declare_field, entity[/* virtual_fields */3]);
  var remat_fields = " { " + (Plate$BwaxMobile.Str.join(", ", lines) + " }");
  return /* :: */[
          "criteria: Criteria ~" + Base_query_types$BwaxMobile.criteria_tname(entity[/* name */0]),
          /* :: */[
            "\n              , remat: " + remat_fields,
            /* [] */0
          ]
        ];
}

function maybe_remat_func_declaration(entity) {
  if (no_need_remat(entity)) {
    return ;
  } else {
    var remat_params = Plate$BwaxMobile.Str.join("", remat_field_srcs(entity));
    return "-- 返回的是查询到的记录数.\nremat:      ~ { " + (String(remat_params) + " \n              }\n          -> (Int -> msg) -> (String -> msg) \n          -> Cmd msg = external\n");
  }
}

function build_remat(module_name, entity) {
  var func = function (args) {
    if (args) {
      var match = args[1];
      if (match) {
        var match$1 = match[1];
        if (match$1) {
          if (match$1[1]) {
            return Runtime_common$BwaxMobile.invalid_args(module_name + ".remat");
          } else {
            var tname = Plate$BwaxMobile.Str.replace("Query_", "E_", module_name);
            var cmd_val_001 = /* :: */[
              /* tuple */[
                /* V_literal */Block.__(5, [/* String */Block.__(2, [tname])]),
                /* No_term */0
              ],
              /* :: */[
                args[0],
                /* :: */[
                  match[0],
                  /* :: */[
                    match$1[0],
                    /* [] */0
                  ]
                ]
              ]
            ];
            var cmd_val = /* V_tagged */Block.__(6, [
                "Cmd_Query_remat",
                cmd_val_001
              ]);
            return /* tuple */[
                    cmd_val,
                    /* No_term */0
                  ];
          }
        } else {
          return Runtime_common$BwaxMobile.invalid_args(module_name + ".remat");
        }
      } else {
        return Runtime_common$BwaxMobile.invalid_args(module_name + ".remat");
      }
    } else {
      return Runtime_common$BwaxMobile.invalid_args(module_name + ".remat");
    }
  };
  var f_000 = /* V_native_fn */Block.__(4, [
      3,
      func,
      /* [] */0
    ]);
  var f = /* tuple */[
    f_000,
    /* No_term */0
  ];
  return /* tuple */[
          "remat",
          f
        ];
}

var build_remat_cmdm = /* tuple */[
  "remat",
  (function (entity) {
      var func = function (args) {
        var cmd_val_001 = /* :: */[
          Runtime_common$BwaxMobile.pack_string(entity[/* name */0]),
          args
        ];
        var cmd_val = /* V_executable */Block.__(14, [
            "CmdM_remat",
            cmd_val_001,
            /* [] */0
          ]);
        return /* tuple */[
                cmd_val,
                /* No_term */0
              ];
      };
      return Runtime_common$BwaxMobile.pack_func(1, func);
    })
];

exports.no_need_remat = no_need_remat;
exports.remat_field_srcs = remat_field_srcs;
exports.maybe_remat_func_declaration = maybe_remat_func_declaration;
exports.build_remat = build_remat;
exports.build_remat_cmdm = build_remat_cmdm;
/* Plate-BwaxMobile Not a pure module */
