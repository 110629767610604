
import Cookies from "js-cookie";

import getTokenCookieName from "bwax/query/getTokenCookieName";

// 关于 SSO 的逻辑，见
// https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1079


function getHostname (dlc) {
    const { userenv } = dlc || {};
    return userenv && userenv.hostname ? userenv.hostname : (
        typeof(document) !== "undefined" ? document.location.hostname : ""
    )
}

function getSsoDomain (hostname) {
    // TODO 应该做成一种机制，当 host 为特定域名时，就使用二级域名
    const ssoDomains = ["qunfengshe.com"];
    return ssoDomains.find(d => {
        return hostname.indexOf(d) !== -1
    })
}


export default function setTokenCookie(sessionToken, dlc) {

    const hostname = getHostname(dlc);
    const ssoDomain = getSsoDomain(hostname);

    const expires = 100;

    const sessionTokenName = getTokenCookieName(dlc);

    function setToken(domain) {
        Cookies.set(sessionTokenName, sessionToken, { expires, domain });
    }

    if(ssoDomain) {
        // 同时设置当前域名以及 sso 域名
        setToken();
        setToken(ssoDomain);
    } else {

        // 只设置当前域名
        setToken();

    }
}

export function removeTokenCookie(dlc) {


    const hostname = getHostname(dlc);
    const ssoDomain = getSsoDomain(hostname);

    const sessionTokenName = getTokenCookieName(dlc);
    
    function removeToken (domain)  {
        Cookies.remove(sessionTokenName, { domain })
    }

    if(ssoDomain) {
        removeToken();
        removeToken(ssoDomain)
    } else {

        removeToken();
    }

}


