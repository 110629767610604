

import React, { useState } from 'react'

import getImageURL from 'bwax-ui/getImageURL';

import Modal from 'bwax-ui/components/Modal';
import Button from "bwax-ui/components/Button";

import DualFileView from './DualFileView';

import { useTrack } from 'bwax-ui/track';

export default function Translate_sample({ data, events, slots }) {

    const { coverImage, leftFile, rightFile, imageAlt } = data;

    const [isViewing, setIsViewing] = useState(false);

    const track = useTrack();

    function renderImage () {
        if(coverImage) {
            return <img src={getImageURL(coverImage.url || coverImage, "large")} className="shadow-md min-h-[10rem] sm:min-h-[18rem] rounded" alt={imageAlt} />
        } else {
            return (
                <div className="w-full h-[20rem] shadow-md rounded">
                </div>
            )
        }
    } 

    return (
        <div className="relative w-full h-full">            
             { renderImage() }
            <div className="w-full h-full absolute top-0 left-0 bg-[var(--mauve8)] opacity-5 hover:opacity-10 duration-75 rounded cursor-pointer" 
                onClick={_ => {
                    // console.log("clicked");
                    track("translate_view_sample", { method: "background", ...(leftFile && leftFile.title ? { file_title: leftFile.title } : {}) })
                    setIsViewing(true)
                }}
            >
            </div>
            <div className="absolute bottom-16 w-full flex justify-center">
                <Button color="violet" className="!px-12 shadow-md" size={"large"} onPress={_ => {
                    track("translate_view_sample", { method: "button", ...(leftFile && leftFile.title ? { file_title: leftFile.title } : {}) })
                    setIsViewing(true);
                }}>
                    查看示例
                </Button>
            </div>
            {isViewing ? (
                <Modal isDismissable className="pt-4" isMain={true} isOpen={true} isLarge={true} contentClassName={"h-full sm:h-[90vh]"} onOpenChange={open => {
                    if (!open) {
                        setIsViewing(false);
                    }
                }}>
                    {
                        closeModal => (
                            <DualFileView
                                leftFile={leftFile} rightFile={rightFile}
                            />
                        )
                    }
                </Modal>
            ) : null}
        </div>

    )
}
