

const ratioOuterStyle = {
    width: "100%",
    paddingBottom: "56.52%", //
    position: "relative",
}

const ratioLOCALOuterStyle = {
    width: "100%",
    position: "relative",
}

const ratioInnerStyle = {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,

}

const iframeStyle = {
    zIndex: 1,
    display: "block",
    overflow: "hidden",
    width: "100%",
    height: "100%"
}

export {
    ratioInnerStyle,
    ratioOuterStyle,
    iframeStyle,
    ratioLOCALOuterStyle
}
