

import React from 'react'

import html_renderer from './html_renderer.bs';

import { Global, css } from '@emotion/react'

const NO_CHILD_TAGS = ["input"];

export default function HtmlRenderer({ value, onMsg, styles }) {

    // 如果 model 不变，其实这个不用重新 render

    // general element:

    function renderElement(name, props, children, index) {

        function handleKey(props) {
            return props.key === undefined && index !== undefined ? { ...props, key: index } : props;
        }

        function handleOnInput(props){
            if(props.onInput) {
                // on change to on input
                const { onInput, ...otherProps } = props;
                return {
                    ...otherProps,
                    onChange: e => {
                        e.stopPropagation();
                        onInput(e.target.value);
                    }
                }
            } else {
                return props;
            }
        }

        // pipe syntax is much better here.
        const actualProps = handleOnInput(handleKey(props))

        if(NO_CHILD_TAGS.indexOf(name) !== -1) {
            return React.createElement(name, actualProps)
        } else {
            return React.createElement(name, actualProps, children)
        }        
    }

    function renderNull () {
        return null
    }

    function renderText(text) {
        return text
    }

    function renderCustomElement(name, props, children) {
        return "custom element"
    }

    return (
        <>
            { styles && styles.manual ? 
                <Global styles={css(styles.manual)}>
                </Global> : null
            }
            { styles && styles.generated ? 
                <Global styles={css(styles.generated)}>
                </Global> : null
            }
            { html_renderer.render_html(renderElement, renderNull, renderText, renderCustomElement, onMsg, value) }
        </>
    )
}
