
import React, { useState } from 'react'

import './PortTest.less'

// Header (N Sections) Footer

export default function PortTest(props) {

    // 可以用这个作为测试，进行 memorize:
    console.log(props);

    const { data, slots, events } = props;

    const header = slots.Header || null;
    const footer = slots.Footer || null;

    const [size, setSize] = useState(data.initialSize || 10);

    let sections = [];
    for (let i = 0; i < size; i++) {
        sections.push((
            <div className="section" key={i} style={{
                height: 60, minHeight: 60, cursor: "pointer", width: "100%",
                display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"
            }} onClick={() => {
                const f = events.clickedSection;
                f && f(i + 1);
            }}>
                {i + 1}
            </div>
        ))
    }


    return (
        <div className="port-test">
            <div style={{ padding: "1rem 0" }}>
                {header}
            </div>
            {
                sections
            }
            <div style={{ padding: "1rem 0" }}>
                {footer}
            </div>
        </div>
    )
}