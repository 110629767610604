
// This file is generated. Don't modify it.
import React from 'react'
export default function LeaderboardIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <rect id="LeaderboardIcon-path-1" x="0" y="0" width="16" height="16"></rect>
                <path d="M12.0637988,2 L13.5637988,2 L13.5637988,13.9994023 L12.0637988,13.9994023 L12.0637988,2 Z M8.84360352,8.46740234 L10.3436035,8.46740234 L10.3436035,13.9994023 L8.84360352,13.9994023 L8.84360352,8.46740234 Z M5.59639648,5.13740234 L7.09639648,5.13740234 L7.09639648,13.9994023 L5.59639648,13.9994023 L5.59639648,5.13740234 Z M2.43620117,10.7791953 L3.93620117,10.7791953 L3.93620117,13.9993906 L2.43620117,13.9993906 L2.43620117,10.7791953 Z" id="LeaderboardIcon-path-3"></path>
            </defs>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Leaderboard">
                    <g>
                        <mask id="LeaderboardIcon-mask-2" fill="white">
                            <use href="#LeaderboardIcon-path-1"></use>
                        </mask>
                        <mask id="LeaderboardIcon-mask-4" fill="white">
                            <use href="#LeaderboardIcon-path-3"></use>
                        </mask>
                        <use id="Shape" fill={color} fillRule="nonzero" href="#LeaderboardIcon-path-3"></use>
                        <g id="Color/UI/Text" mask="url(#LeaderboardIcon-mask-4)" fill={color} fillRule="nonzero">
                            <rect id="light-gray" x="0" y="0" width="16" height="16"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
