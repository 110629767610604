
import React from 'react'

import createBlockTypeButton from './util/createBlockTypeButton';

import { MdCode } from 'react-icons/md'

export default createBlockTypeButton({
    blockType: 'code',
    children: (
        <MdCode  style={{ fontSize: 14 }}/>
    ),
});

// import React from 'react'
// import { useSlate } from 'slate-react'
// import { Editor, Element as SlateElement, Transforms } from 'slate'

// export default (props) => {
    
//     const editor = useSlate()
//     const { selection } = editor

//     function isFormatActive() {
//         // const marks = Editor.marks(editor)
//         // return marks ? marks['code'] === true : false
//         if(selection) {
//             const [parentNode, parentPath] = Editor.parent(
//                 editor,
//                 selection.focus?.path
//             );

//             console.log("code parentNode: ", parentNode);

//             return parentNode.type === "code" 
//         } else {
//             return false
//         }
//     }

//     function toggleCodeMark() {

//         const isActive = isFormatActive()

//         const newProperties = {
//             type: isActive ? "paragraph" : "code",
//         }

//         Transforms.setNodes(editor, newProperties)

//         if(!isActive) {
//             console.log("add paragraph");
//             Transforms.insertNodes(editor, 
//                 { type: "paragraph", children: [{ text: "" }] },
//                 { at: selection.focus?.path }
//             )
//         }
//     }

//     return (
//         <button className={`editor-button ${isFormatActive() ? " active" : ""}`}
//             onClick={() => toggleCodeMark()}>
//             <i className={"fa fa-code"} aria-hidden="true"></i>
//         </button>
//     )
// }
