
// This file is generated. Don't modify it.
import React from 'react'
export default function SearchIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M10.75,9.3125 L10.625,9.53125 L13.4712038,12.4038075 C13.7636432,12.6989547 13.7625465,13.1749535 13.46875,13.46875 L13.46875,13.46875 C13.1766977,13.7608023 12.7031873,13.7608023 12.411135,13.46875 C12.4103227,13.4679377 12.4095123,13.4671235 12.4087038,13.4663075 L9.5625,10.59375 L9.34375,10.75 C8.5104125,11.2708359 7.64583781,11.53125 6.75,11.53125 C5.43749344,11.53125 4.31771297,11.0625047 3.390625,10.125 C2.46353703,9.18749531 2,8.06250656 2,6.75 C2,5.43749344 2.46353703,4.31771297 3.390625,3.390625 C4.31771297,2.46353703 5.43749344,2 6.75,2 C8.06250656,2 9.18228703,2.46353703 10.109375,3.390625 C11.036463,4.31771297 11.5,5.43749344 11.5,6.75 C11.5,7.66667125 11.2500025,8.52082938 10.75,9.3125 Z M9.40625,4.09375 C8.67707969,3.36457969 7.79167188,3 6.75,3 C5.70832812,3 4.82292031,3.36457969 4.09375,4.09375 C3.36457969,4.82292031 3,5.70832813 3,6.75 C3,7.79167187 3.36457969,8.67707969 4.09375,9.40625 C4.82292031,10.1354203 5.70832812,10.5 6.75,10.5 C7.79167188,10.5 8.67707969,10.1354203 9.40625,9.40625 C10.1562537,8.65624625 10.53125,7.77083844 10.53125,6.75 C10.53125,5.72916156 10.1562537,4.84375375 9.40625,4.09375 Z" id="SearchIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Search">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="SearchIcon-mask-2" fill="white">
                            <use href="#SearchIcon-path-1"></use>
                        </mask>
                        <use id="ion-ios-search-strong---Ionicons" fill={color} href="#SearchIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
