
// This file is generated. Don't modify it.
import React from 'react'
export default function DeleteWireFrameIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M7.17504209,8 L5.52512627,6.35008418 C5.29732043,6.12227834 5.29732043,5.7529321 5.52512627,5.52512627 C5.7529321,5.29732043 6.12227834,5.29732043 6.35008418,5.52512627 L8,7.17504209 L9.64991582,5.52512627 C9.87772166,5.29732043 10.2470679,5.29732043 10.4748737,5.52512627 C10.7026796,5.7529321 10.7026796,6.12227834 10.4748737,6.35008418 L8.82495791,8 L10.4748737,9.64991582 C10.7026796,9.87772166 10.7026796,10.2470679 10.4748737,10.4748737 C10.2470679,10.7026796 9.87772166,10.7026796 9.64991582,10.4748737 L8,8.82495791 L6.35008418,10.4748737 C6.12227834,10.7026796 5.7529321,10.7026796 5.52512627,10.4748737 C5.29732043,10.2470679 5.29732043,9.87772166 5.52512627,9.64991582 L7.17504209,8 Z M8,13 C10.7614237,13 13,10.7614237 13,8 C13,5.23857625 10.7614237,3 8,3 C5.23857625,3 3,5.23857625 3,8 C3,10.7614237 5.23857625,13 8,13 Z M8,14 C4.6862915,14 2,11.3137085 2,8 C2,4.6862915 4.6862915,2 8,2 C11.3137085,2 14,4.6862915 14,8 C14,11.3137085 11.3137085,14 8,14 Z" id="DeleteWireFrameIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Wireframe/Delete">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="DeleteWireFrameIcon-mask-2" fill="white">
                            <use href="#DeleteWireFrameIcon-path-1"></use>
                        </mask>
                        <use id="Combined-Shape" fill={color} href="#DeleteWireFrameIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
