
import React from 'react'

import * as Portal from '@radix-ui/react-portal';

import './Drawer.less';

export default function Drawer({ open, onOpenChange, position = "right", className, children, style = {} }) {

    return (
        <Portal.Root className={"lc-drawer lc-base bw-ui"} data-state={open ? "open" : "closed"}>
            <div className="drawer-overlay" onClick={_ => {
                onOpenChange(false)
            }}>
            </div>
            <div className={"drawer-content" + (className ? " " + className : "")} style={style} data-position={position}>
                {children}
            </div>
        </Portal.Root>
    )
}
