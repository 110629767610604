import React from 'react'
export default function ArrowDownIcon (props) {

    const { color } = props
    
    return ( 
        <svg xmlns="http://www.w3.org/2000/svg" width="62" height="20" viewBox="0 0 62 20">
            <polyline fill="none" stroke={color} strokeWidth="2" points="930 1002 960 1019.321 990 1002" transform="translate(-929 -1001)"/>
        </svg>
    )
}
