
import React, { useState } from "react";

import { toast } from 'bwax-ui/components/Toast';

import EditingState from 'bwax-ui/re/legacy/EditingState.bs'

import TextInput from 'bwax-ui/components/inputs/TextInput';
import TextArea from 'bwax-ui/components/inputs/TextArea';
import Button from "bwax-ui/components/Button";
import ChatConfigPanel from "../chat/ChatConfigPanel";

import ImageInput from "bwax-ui/components/records/ImageInput"

export default function EditPersonaForm({ facade, currentUserId, fieldPaths, onUpdated, record, }) {

    const entityName = "OpenAI-会话角色";

    const { id, ...initialValues } = record;

    const allKeys = Object.keys(initialValues);

    async function updateRecord({ dirtyValues }) {
        const [r, error] = await facade.update({
            entityName,
            formData: {
                ...dirtyValues,
            },
            fieldPaths,
            id: record.id,
        });
        if (error) {
            toast({ title: error });
        } else {
            onUpdated(r)
        }
    }


    return (
        <div className="lc-add-persona-form !px-0 sm:h-[85vh] h-full">
            <div className="title font-size-18 px-6">
                <i className="bx bx-user-pin" />
                编辑角色
            </div>
            <PersonaForm {...{
                allKeys,
                initialValues,
                onConfirm: updateRecord,
                confirmLabel: "保存",
                isReadyToConfirm: ({ allKeys, dirtyValues }) => {
                    const hasDirtyValue = allKeys.some(key => {
                        return dirtyValues[key] !== undefined
                    })
                    const missingRequiredFields = ["名称", "提示"].some(key => {
                        return dirtyValues[key] === null || dirtyValues[key] === ""
                    })
                    return hasDirtyValue && !missingRequiredFields;
                },
                facade, currentUserId,
            }} />
        </div>
    )
}



export function PersonaForm({ allKeys, initialValues, onConfirm, confirmLabel, isReadyToConfirm, facade, currentUserId }) {

    // --- 初始化 Editing
    const {
        errors,
        validated,
        editing,
        lastSaved,
        dirtyValues,
        updateEditing,
        markSaved,
        reset,
        rollback,
        clearEditing,
    } = EditingState.useEditingStateAsJs(allKeys, initialValues, {}, {}, [], "no-key", false);

    const onFieldChange = fieldName => v => {
        const value = editing[fieldName]
        if (v !== value) {
            updateEditing({ [fieldName]: v })
        }
    }


    function renderSection(name, fieldName, renderInput) {

        const value = editing[fieldName]
        const onChange = onFieldChange(fieldName)

        return (
            <div className="flex flex-col gap-2">
                <div className="font-size-12 text-[var(--gray10)]">
                    {name}
                </div>
                {renderInput(value, onChange)}
            </div>
        )
    }


    const [isSubmitting, setIsSubmitting] = useState(false);

    async function doConfirm() {
        setIsSubmitting(true);
        await onConfirm({ validated, dirtyValues })
        setIsSubmitting(false);
    }

    return (
        <>
            <div className="flex flex-col sm:flex-row gap-3 sm:gap-5 w-full grow overflow-auto px-6" data-color={"violet"}>
                <div className="flex flex-col gap-3 sm:grow">
                    {renderSection("名称 *", "名称", (value, onChange) => {
                        return (
                            <TextInput value={value} placeholder="输入角色的名字" styled={true} onChange={onChange} autoFocus />
                        )
                    })}
                    {renderSection("提示 *", "提示", (value, onChange) => {
                        return (
                            <TextArea styled={true} minRows={4} value={value} placeholder={"告诉AI助手你想它扮演什么样的角色，应该怎么跟你对话、怎么做"} onChange={onChange} />
                        )
                    })}
                    {renderSection("欢迎消息", "欢迎消息", (value, onChange) => {
                        return (
                            <TextArea styled={true} minRows={2} value={value} placeholder={"每次开启对话时，AI助手主动发给用户的第一条消息"} onChange={onChange} />
                        )
                    })}
                    {renderSection("头像", "头像", (value, onChange) => {
                        return (
                            <ImageInput className="!h-[7rem] !w-[7rem]" value={value} placeholder="输入角色的名字" styled={true} onChange={onChange} facade={facade} />
                        )
                    })}
                </div>
                <div className="flex flex-col gap-3">
                    <ChatConfigPanel {...{
                        userOptions: editing["用户选项"],
                        updateUserOptions: onFieldChange("用户选项"),

                        contentOptions: editing["内容生成选项"],
                        updateContentOptions: onFieldChange("内容生成选项"),

                        chatModel: editing["chatModel"],
                        updateChatModel: onFieldChange("chatModel"),

                        facade, currentUserId,

                        // persona, 
                        // onPersonaUpdate = _ => { },

                        // personaTipShown = false,

                        showTestFunctions: false,

                    }} />
                </div>
            </div>
            <div className="flex gap-2 py-1 px-6">
                <Button color={"grass"} appearance="primary" className="!px-8" isDisabled={!isReadyToConfirm({ dirtyValues, validated, allKeys })} isLoading={isSubmitting}
                    onPress={_ => {
                        doConfirm();
                    }}>{confirmLabel}</Button>
            </div>
        </>
    )
}

