import React from 'react'

import './NavigationBar001.less'

export default function NavigationBar001(props) {


    const { data, slots, events } = props;

    console.log("props: ", props);
    const { navFolded } = data
    const { logoPC, logoMobile, navs, foldIcon, loginOrAvatar } = slots
    const { toggleNav } = events

    function handleClick () {
        if(toggleNav && !navFolded) {
            toggleNav()
        }
    }

    return (
        <div className={`navigationBar-container ${navFolded ? "" : "unfold"}`} onClick={() => handleClick()}>
            <div className='navigationBar-outer-box'>
                <div className='navigationBar-inner-box'>
                    {logoPC}
                    {logoMobile}
                    <div className='navs-box'>
                        { navs }
                    </div>
                    <div className='navigation-action'>
                        { foldIcon }
                        {loginOrAvatar}
                    </div>
                </div>
                <div className='navs-dropDown' style={{ display: navFolded ? "none" : "flex" }}>
                    { navs }
                </div>
            </div>
        </div>
    )
}