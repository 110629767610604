
import React, { useContext, useEffect } from 'react';

import { runDataQuery } from 'bwax/query/runClientQuery'

import RenderWithData from 'bwax-ui/store/RenderWithData'
import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'

import PageComponentEnvContainer from 'bwax-ui/page/PageComponentEnvContainer';

import NProgress from 'nprogress'


function Progress () {
    useEffect(() => {
        NProgress.start()
        return _ => {
            NProgress.done()
        }
    }, [])
    return null
}

export default function PageComponent(props) {

    const { name, isLazy, loading, facade, ...remaining } = props;

    const dlc = useContext(DataLoaderContext);

    const loadData = () => loadPageComponent(name, dlc);
    
    const loadingElement = loading || (
        <Progress />
    )

    return (
        <RenderWithData {...{
            ...remaining,
            facade, dlc, name,

            loadData,
            dataKey: "PageComponent_" + name,
            Component: PageComponentInner,

            // 因为这两个问题，注释掉了 noSuspense: true
            // https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/981
            // https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1003

            // 上面使用了 view 参数后，上面两个问题已经不再是问题了
            // 但这里仍然确实是要 Suspense，不然无法给 PageComponent 加 loading

            // 如果没有 specifed 的 loading，那么就不要 Suspense
            noSuspense: !loading,

            isLazy,
            fallback: loadingElement,

        }} />
    )
}


function PageComponentInner(props) {

    const { 
        data: pageComponent, componentKey, initParams, maybeViewParams, 
        facade, viewEnv, dlc, name, get_instance_id 
    } = props;

    const parentId = get_instance_id();

    if (!pageComponent) {
        return "Page Component Not Found" // 暂时用这个
    }

    return (
        <PageComponentEnvContainer {...{
            pageComponent,
            componentKey,
            initParams, maybeViewParams,
            facade, viewEnv,
            dlc,
            componentName: name,

            parentId,

        }} />
    )
}


// loadPageComponent;
export async function loadPageComponent(name, dlc) {

    const queryText = `query { definition { pageComponent (name: "${name}") { compiled } }} `;

    const resultText = await runDataQuery(dlc)(queryText)({});

    const result = JSON.parse(resultText);

    const pageComponent = result.data ? result.data.definition.pageComponent : null;

    return pageComponent;

}



export function create(props) {
    return <PageComponent {...props} />
}
