// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Lang_eval$BwaxMobile = require("../../../../bwax-js/ml/lang/lang_eval.bs.js");
var Runtime_common$BwaxMobile = require("../../../../bwax-js/ml/lang/mod/runtime_common.bs.js");
var Widget_html_common$BwaxMobile = require("./widget_html_common.bs.js");

var module_name = "Html.Events";

var src = "\nmodule " + (String(module_name) + ";\n\nimport Html exposing (Html, Attribute);\n\n-- Mouse events:\nonClick : msg -> Attribute msg = external;\n\n-- Form events:\nonInput: (String -> msg) -> Attribute msg = external;\n;\n\n");

function fail(name) {
  throw [
        Lang_eval$BwaxMobile.Eval_exn,
        module_name + ("." + (name + ": Invalid Arguments"))
      ];
}

function attribute_msg_func(name) {
  return /* tuple */[
          name,
          Widget_html_common$BwaxMobile.pack_attribute_func(1, (function (param) {
                  if (param && !param[1]) {
                    return /* Attribute_event_msg */Block.__(4, [
                              name,
                              param[0]
                            ]);
                  } else {
                    return fail(name);
                  }
                }))
        ];
}

function attribute_string_msg_func(name) {
  return /* tuple */[
          name,
          Widget_html_common$BwaxMobile.pack_attribute_func(1, (function (param) {
                  if (param && !param[1]) {
                    var f = param[0];
                    var handle_event = function (s) {
                      return Lang_eval$BwaxMobile.apply_value(undefined, f, /* tuple */[
                                  /* V_literal */Block.__(5, [/* String */Block.__(2, [s])]),
                                  Runtime_common$BwaxMobile.nt
                                ], Runtime_common$BwaxMobile.nt);
                    };
                    return /* Attribute_event_string_msg */Block.__(5, [
                              name,
                              handle_event
                            ]);
                  } else {
                    return fail(name);
                  }
                }))
        ];
}

var externals_vals_000 = attribute_msg_func("onClick");

var externals_vals_001 = /* :: */[
  attribute_string_msg_func("onInput"),
  /* [] */0
];

var externals_vals = /* :: */[
  externals_vals_000,
  externals_vals_001
];

var externals_000 = module_name;

var externals = /* tuple */[
  externals_000,
  externals_vals
];

exports.module_name = module_name;
exports.src = src;
exports.fail = fail;
exports.attribute_msg_func = attribute_msg_func;
exports.attribute_string_msg_func = attribute_string_msg_func;
exports.externals_vals = externals_vals;
exports.externals = externals;
/* src Not a pure module */
