
// This file is generated. Don't modify it.
import React from 'react'
export default function EvaluationIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M13.6247162,13.9879886 L2.37499084,13.9879886 C2.16757403,13.9879886 2,13.8204145 2,13.6129977 C2,13.4055809 2.16757403,13.2380069 2.37499084,13.2380069 L13.6247162,13.2380069 C13.832133,13.2380069 13.999707,13.4055809 13.999707,13.6129977 C13.999707,13.8204145 13.832133,13.9879886 13.6247162,13.9879886 Z M13.7618222,3.3792632 L12.6204438,2.23788482 C12.4610727,2.07968555 12.2536559,2 12.0450673,2 C11.8364786,2 11.6290618,2.07968555 11.4708625,2.23788482 L5.84248431,7.86626303 L4.28978785,11.1685262 C4.15619736,11.4544567 4.37884817,11.749762 4.65540392,11.749762 C4.71282439,11.749762 4.77141671,11.7368717 4.83118088,11.7099192 L8.13344401,10.1583946 L13.7618222,4.53001636 C14.0793926,4.21127414 14.0793926,3.69683357 13.7618222,3.3792632 Z M7.69517346,9.53497229 L5.37374576,10.6259613 L6.46473475,8.30453358 L10.5415883,4.22767999 L11.7720271,5.4581187 L7.69517346,9.53497229 Z M13.2309758,3.99916994 L12.3017016,4.92844413 L11.0712629,3.69800542 L12.0005371,2.76873123 C12.0052245,2.76404385 12.0192866,2.74998169 12.0450673,2.74998169 C12.0708479,2.74998169 12.08491,2.76404385 12.0895974,2.76873123 L13.2309758,3.91010962 C13.2356632,3.914797 13.2497253,3.92885916 13.2497253,3.95463978 C13.2497253,3.9804204 13.2356632,3.99448256 13.2309758,3.99916994 Z" id="EvaluationIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Evaluation">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="EvaluationIcon-mask-2" fill="white">
                            <use href="#EvaluationIcon-path-1"></use>
                        </mask>
                        <use id="Shape" fill={color} fillRule="nonzero" href="#EvaluationIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
        
    )
}
