
import React, { useContext } from 'react';

import RenderWithData from 'bwax-ui/store/RenderWithData';
import { USER_DATA } from 'bwax/store/DataCache';
import { hashCode } from 'bwax/utils';

export default function WithRecord (props) {

    const { query_config, get_depended_paths, toReactElement, isLazy, loading, notFound, facade } = props;
    const [ entityName, _ ] = query_config;

    const fieldPaths = get_depended_paths(facade.entity_dict, facade.data_type_dict, 0, entityName) || [];
    const dataKey = "WithRecord-" + hashCode(JSON.stringify({ query_config, fieldPaths })); // query_config + render_fn deps

    const loadData = async () => {

        const isCustomQuery = facade.isCustomQuery(query_config);

        async function doQuery () {
            if(isCustomQuery) {
                // do custom query
                const queryObj = {
                    query_config,
                    pageSize: 1,
                    offset: 0,
                    outputFieldPaths: [
                        [entityName, fieldPaths]
                    ]
                }
                const [result, error] = await facade.customQuery(queryObj, {});

                if(result && result.data && result.data.length > 0) {
                    return [ result.data[0], error ]
                } else {
                    return [ null, error ]
                }
            } else {
                const queryObj = {
                    entityName,
                    query_config, // facade 支持 query_config， 也支持 js 版本的 condition, sort
                    fieldPaths,
        
                };
                const [result, error] = await facade.findOne(queryObj, {}); 
        
                return [result, error]
            }
        }
        return await doQuery ();

    };

    return (
        <RenderWithData {...{
            loadData,
            cacheType: USER_DATA,
            dataKey,
            Component: Inner,
            noSuspense: true,
            
            isLazy, fallback: loading, notFound,
            // 
            toReactElement, facade, entityName, 
        }}

        />
    )

}

function Inner (props) {

    const { data, toReactElement, facade, entityName, notFound } = props;
    const [ record, error ] = data;

    if(record) {
        return toReactElement(facade.entity_dict, facade.data_type_dict, entityName, record);

    } else {
        if(error) {
            return "查找数据时出错了：" + error
        } else {
            if(notFound) {
                return notFound
            } else {
                return "未找到数据"
            }
        }        
    }

}



export function create(props) {
    return <WithRecord {...props} />
}


