import React, { useState, useRef, useEffect } from "react";

import "./OnePageLayout001.less";

export default function OnePageLayout001(props) {

	const { welcome, sections, footer, buildNavBar, onScrolledToSection } = props;

    const [navigatorBarFixed, setNavigatorBarFixed] = useState(false)
    const [navigatorBarHeight, setNavigatorBarHeight] = useState(null)
    const [sectionOffsetHeights, setSectionOffsetHeights] = useState([])
    const [scrolledToSectionIndex, setScrolledToSectionIndex] = useState(null)

    const containerRef = useRef(null)
    const navigatorBarRef = useRef(null)
    const contentRefs = sections.map(_ => useRef(null))

    useEffect(() => {
        if (navigatorBarRef && navigatorBarRef.current) {
            setNavigatorBarHeight(navigatorBarRef.current.offsetHeight)
        }
        setSectionOffsetHeights(contentRefs.map(cr => {
            if (cr.current) {
                return cr.current.offsetTop
            }
        }))
    }, [])

    const goSection = index => {
        const activeContent = contentRefs.find(cr => {
            if (cr.current) {
                return cr.current.id === index.toString()
            }
        })

        if (activeContent && activeContent.current) {

            activeContent.current.scrollIntoView({ behavior: 'smooth' })

        }

    }

    const renderNavBar = buildNavBar(
        sections.map(([navItem, _], index) => {
            return (
                <div
                    key={"nav-item-" + index}
                    className="nav-item"
                    onClick={() => goSection(index)}>
                    {navItem}
                </div>
            );
        })
    );


	const navBar = (
		<div className="nav-bar" ref={navigatorBarRef}>
			{renderNavBar}
            {
                navigatorBarFixed ? (
                    <div className="nav-bar fixed">
                        { renderNavBar}
                    </div>
                ) : null
            }
		</div>
	);

	const sectionDivs = sections.map(([_, section], index) => {
		return (
			<div key={"section" + index} id={index} ref={contentRefs[index]} className="section-div"
                style={{ paddingTop: navigatorBarHeight }}
            >
				{section}
			</div>
		);
	});

    function onScroll (e) {
        const { scrollTop } = e.target
        if(typeof (window) !== "undefined") {
            const naviScrolledToTop = scrollTop > window.innerHeight - navigatorBarHeight
            if(naviScrolledToTop && !navigatorBarFixed) {
                setNavigatorBarFixed(true)
            } else if (!naviScrolledToTop && navigatorBarFixed) {
                setNavigatorBarFixed(false)
            }
            ///

            if(scrollTop >= sectionOffsetHeights[0]) {
                const currentSectionIndex = sectionOffsetHeights.findIndex(height => height >= scrollTop + window.innerHeight / 2) !== -1 ? 
                    sectionOffsetHeights.findIndex(height => height >= scrollTop + window.innerHeight / 2) - 1 : sectionOffsetHeights.length - 1
                if(scrolledToSectionIndex !== currentSectionIndex) {
                    setScrolledToSectionIndex(currentSectionIndex)
                    onScrolledToSection(currentSectionIndex)
                }
            } else if(scrolledToSectionIndex !== null && scrolledToSectionIndex !== 0) {
                setScrolledToSectionIndex(0)
                onScrolledToSection(0)
            }
        }

    }

    // const throttledOnScroll = _.throttle(onScroll, 100);
    const throttledOnScroll = onScroll;


	return (
		<div className="one-page-layout-001" ref={containerRef} onScroll={e => throttledOnScroll(e)}>
            {welcome}
			{navBar}
			{sectionDivs}
			{footer}
		</div>
	);
}
