
// This file is generated. Don't modify it.
import React from 'react'
export default function ColonIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M7.98726115,3 C8.5477707,3 9.00636943,3.17834395 9.41401274,3.58598726 C9.79617834,3.96815287 10,4.4522293 10,5.01273885 C10,5.57324841 9.79617834,6.03184713 9.41401274,6.41401274 C9.00636943,6.79617834 8.5477707,7 7.98726115,7 C7.42675159,7 6.94267516,6.79617834 6.56050955,6.41401274 C6.17834395,6.03184713 6,5.57324841 6,5.01273885 C6,4.4522293 6.17834395,3.96815287 6.56050955,3.58598726 C6.94267516,3.17834395 7.42675159,3 7.98726115,3 Z M7.98726115,9 C8.5477707,9 9.00636943,9.17834395 9.41401274,9.58598726 C9.79617834,9.96815287 10,10.4522293 10,11.0127389 C10,11.5732484 9.79617834,12.0318471 9.41401274,12.4140127 C9.00636943,12.7961783 8.5477707,13 7.98726115,13 C7.42675159,13 6.94267516,12.7961783 6.56050955,12.4140127 C6.17834395,12.0318471 6,11.5732484 6,11.0127389 C6,10.4522293 6.17834395,9.96815287 6.56050955,9.58598726 C6.94267516,9.17834395 7.42675159,9 7.98726115,9 Z" id="ColonIcon-path-1"></path>
            </defs>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Colon">
                    <g>
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="ColonIcon-mask-2" fill="white">
                            <use href="#ColonIcon-path-1"></use>
                        </mask>
                        <use id="：" fill={color} href="#ColonIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
