

import React, { useEffect, useContext, useTransition, Suspense, useState } from 'react'

import { Switch, Route } from 'react-router'

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'

import './tailwind.css';

import '../styles/main.less'

import '../../lib/bwax-ui/baseStyle.less'

import qs from 'query-string';

import smoothscroll from 'smoothscroll-polyfill';

if (typeof (window) !== 'undefined') {
    // kick off the polyfill!
    smoothscroll.polyfill();
}

/// the pages:

import LoadingPage from './LoadingPage'

import ErrorBoundary from 'bwax-ui/ml/widget/ErrorBoundary'

import { Helmet } from 'react-helmet-async'

// test pdf reader
// import PDFReader from 'bwax-ui/auxiliary/pdfReader/PDFReader'

//tmp import
// import '../../lib/bwax-ui/auxiliary/pdfReader/PDFReader.less'
import RenderWithData from 'bwax-ui/store/RenderWithData';
import loadBaseSettings from '../../lib/bwax-ui/legacy/store/loaders/loadBaseSettings';
import ColorPage from './demo/ColorPage';

import PageTransitionRenderer from './PageTransitionRenderer'

import TestEnv from './TestEnv'
import SandboxIndicator from './SandboxIndicator';

import TestVideoBackground from './TestVideoBackground';

import TestVisibility from './TestVisibility';

import { GLOBAL_DATA } from 'bwax/store/DataCache';
import ConfirmToken from 'bwax-ui/auth/ConfirmToken';
import TestRichEditor from 'bwax-ui/tests/TestRichEditor';

import TestUI from 'bwax-ui/tests/TestUI'

import TestUIKit from 'bwax-ui/tests/TestUIKit'
import TestMarkdown from 'bwax-ui/tests/TestMarkdown'

//export default Demo;

/// extension routes
/// TODO 在未来的实现中，应该根据当前域的配置，只使用开启的 extension

import { SSRProvider } from 'react-aria';

//// routing configuration:
const fixedRoutes = {

    //test
    // '/pdf/reader': PDFReader

    "/test-video-background": TestVideoBackground,

    '/test-rich-editor': TestRichEditor,

    '/test-env': TestEnv,

    '/test-ui': TestUI,

    '/test-ui-kit': TestUIKit,
    '/test-markdown': TestMarkdown,

    '/test-visibility': TestVisibility,

    '/color/:name': props => {
        const { name } = props.match.params

        const [
            isPending,
            startTransition,
        ] = useTransition({
            timeoutMs: 3000
        });


        const [colorName, setColorName] = useState(name);

        useEffect(() => {
            startTransition(() => {
                if (colorName !== name) {
                    console.log("UPDATE COLOR NAME", name);
                    setColorName(name)
                }
            })
        }, [name]);
        // console.log("isPending", isPending, name);    
        return <ColorPage key={colorName} name={colorName} colorName={colorName} {...props} />
    },

    '/ext/auth/confirm-token/:token': ConfirmToken

}


function ClientApp(props) {

    //微信初始化配置在 iOS微信浏览器中使用
    if (typeof (window) !== 'undefined' && !window.originalHref) {
        // 只执行一次
        window.originalHref = location.href
    }

    const dlc = useContext(DataLoaderContext);
    const loadData = () => loadBaseSettings()(dlc);


    useEffect(() => {
        const { isMobile } = dlc.userenv;
        if(!isMobile && typeof (navigator) !== 'undefined') {
            const isMac = /Macintosh/.test(navigator.userAgent);
            if(!isMac) {
                const className = "maybe-win"
                document.body.className = document.body.className ? document.body.className + " " + className : className;
            }
        }   
    }, []);


    return (
        <>
            <RenderWithData
                Component={ClientAppWithData}
                loadData={loadData}
                dataKey="BaseSettings"
                cacheType={GLOBAL_DATA}
                fallback={<LoadingPage />}
                dlc={dlc}
                {...props}
            />
            {dlc.sandbox ? <SandboxIndicator /> : null}
        </>
    )
}

// export default TestSuspense;

export default ClientApp;


function ClientAppWithData({ data, dlc, alternativeContext }) {

    const baseSettings = data;

    // 这部分可以在 mobile 这里 进行 memory cache

    // console.log(qs.parse(location.search));
    function getRK() {
        if (typeof (window) !== 'undefined') {
            return qs.parse(location.search)._rk_
        } else {
            return undefined
        }
    }

    const rk = getRK();
    useEffect(() => {
        if (rk) {
            sessionStorage.setItem("_rk_", rk);
        }
    }, [rk])

    const pageScripts = ((baseSettings || {}).pageScripts) || [];

    /// extension routes:




    const routes = { ...fixedRoutes };

    function isValidHttpUrl(string) {
        let url;

        try {
            url = new URL(string);
        } catch (_) {
            return false;
        }

        return url.protocol === "http:" || url.protocol === "https:";
    }


    const BODY_NOSCRIPT = "--- body noscript ---";
    const bodyNoScripts = pageScripts.filter(s => s.startsWith(BODY_NOSCRIPT)).map(s => s.substring(BODY_NOSCRIPT.length + 1));


    return (
        <SSRProvider>
            { bodyNoScripts.map((s, i) => {
                return (
                    <noscript key={i} dangerouslySetInnerHTML={{ __html: s}}></noscript>
                )   
            })}
            <ErrorBoundary>
                <Helmet>
                    {
                        pageScripts.map((s, i) => {
                            if(isValidHttpUrl(s)) {
                                return <script key={i} async src={s}></script>
                            } if (s.startsWith("---")) {
                                return null
                            } else {
                                return <script key={i}>{s}</script>
                            }                            
                        })
                    }
                </Helmet>
                <Switch>
                    {/* The component definitions of routes */}
                    {
                        Object.keys(routes).map(path => {
                            return (
                                <Route path={path} key={path} render={props => {
                                    const Comp = routes[path]
                                    return (
                                        <>
                                            <Helmet>
                                                <meta name="viewport" content="width=device-width,initial-scale=1" />
                                            </Helmet>
                                            <Comp {...props} dlc={dlc} />
                                        </>
                                    )
                                }} />
                            )
                        })
                    }

                    <Route path="/" render={(props) => {
                        return <PageTransitionRenderer {...props} alternativeContext={alternativeContext} />
                    }} />
                </Switch>
            </ErrorBoundary>
        </SSRProvider>
    )
}




