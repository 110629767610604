// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ElementRenderer$BwaxMobile = require("./ElementRenderer.bs.js");

function ComponentRenderer(Props) {
  var value = Props.value;
  Props.dlc;
  var onMsg = Props.onMsg;
  var getInstanceID = Props.getInstanceID;
  var facade = Props.facade;
  var viewEnv = Props.viewEnv;
  var pageID = Props.pageID;
  if (value !== undefined) {
    var match = value[0];
    if (typeof match === "number" || match.tag !== /* V_raw */7) {
      return "Error: Not an element.";
    } else {
      return React.createElement(ElementRenderer$BwaxMobile.make, {
                  element: match[0],
                  onMsg: onMsg,
                  getInstanceID: getInstanceID,
                  facade: facade,
                  viewEnv: viewEnv,
                  fontFaces: undefined,
                  pageID: pageID
                });
    }
  } else {
    return "Error: Not an element.";
  }
}

var make = ComponentRenderer;

exports.make = make;
/* react Not a pure module */
