
import React from 'react';

import 'boxicons/css/boxicons.min.css';

export default function BoxIconBridge({ name, styleClasses, iconType }) {

    const prefixes = {
        Regular: "bx",
        Solid: "bxs",
        Logo: "bxl",
    }
    const className = [
        "bx",
        (prefixes[iconType] || "bx") + "-" + name,
        ...(styleClasses.map(c => "bx-" + c))
    ].join(" ");

    return (
        <i className={className} />
    )

}

export function create(styleClasses, iconType, name) {
    return <BoxIconBridge {...{ name, styleClasses, iconType }} />
}