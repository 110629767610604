
// This file is generated. Don't modify it.
import React from 'react'
export default function ArrowDownIcon (props) {

    const { color } = props
    
    return (
                <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M10.977707,8 C10.9780368,8.12839171 10.9292219,8.25688624 10.831262,8.35484607 L5.88151455,13.3045935 C5.68625241,13.4998557 5.36966992,13.4998557 5.17440777,13.3045935 C4.97914563,13.1093314 4.97914563,12.7927489 5.17440777,12.5974868 L9.77189453,8 L5.17440777,3.40251324 C4.97914563,3.2072511 4.97914563,2.89066861 5.17440777,2.69540646 C5.36966992,2.50014431 5.68625241,2.50014431 5.88151455,2.69540646 L10.831262,7.64515393 C10.9292219,7.74311376 10.9780368,7.87160829 10.977707,8 Z" id="ArrowDownIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Arrow/Down">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="ArrowDownIcon-mask-2" fill="white">
                            <use href="#ArrowDownIcon-path-1"></use>
                        </mask>
                       <use id="Path" fill={color} fillRule="nonzero" transform="translate(15 0) rotate(90)" href="#ArrowDownIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
