
// This file is generated. Don't modify it.
import React from 'react'
export default function SettingIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M9.38,2 C10.529732,2.28138657 11.5696332,2.89876264 12.3671429,3.77342857 C12.1490815,4.28662736 12.1810511,4.87189233 12.4537143,5.35828571 C12.7365714,5.86228571 13.2251429,6.16742857 13.748,6.22828571 C13.9117143,6.78971429 14,7.38285714 14,8 C14,8.61628571 13.9117143,9.21028571 13.748,9.77171429 C13.2251429,9.83171429 12.7365714,10.1377143 12.4537143,10.6417143 C12.181051,11.1284707 12.1493944,11.714117 12.368,12.2274286 C11.5701457,13.1019246 10.5299382,13.7190071 9.38,14 C9.06291563,13.5520673 8.54880176,13.2851113 8,13.2834286 C7.45119824,13.2851113 6.93708437,13.5520673 6.62,14 C5.47026798,13.7186134 4.43036677,13.1012374 3.63285714,12.2265714 C3.85091851,11.7133726 3.81894893,11.1281077 3.54628571,10.6417143 C3.26342857,10.1377143 2.77485714,9.83257143 2.252,9.77171429 C2.08439763,9.19608341 1.99954738,8.5995342 2,8 C2,7.38371429 2.08828571,6.78971429 2.252,6.22828571 C2.77485714,6.16828571 3.26342857,5.86228571 3.54628571,5.35828571 C3.82914286,4.85514286 3.84285714,4.268 3.632,3.77257143 C4.42985426,2.89807535 5.47006184,2.28099295 6.62,2 C6.932,2.43457143 7.43428571,2.71657143 8,2.71657143 C8.54880176,2.71488872 9.06291563,2.4479327 9.38,2 Z M8,5.42857143 C6.57983921,5.42857143 5.42857143,6.57983921 5.42857143,8 C5.42857143,9.42016079 6.57983921,10.5714286 8,10.5714286 C9.42016079,10.5714286 10.5714286,9.42016079 10.5714286,8 C10.5714286,6.57983921 9.42016079,5.42857143 8,5.42857143 Z M8,6.28571429 C7.05322614,6.28571429 6.28571429,7.05322614 6.28571429,8 C6.28571429,8.94677386 7.05322614,9.71428571 8,9.71428571 C8.94677386,9.71428571 9.71428571,8.94677386 9.71428571,8 C9.71428571,7.05322614 8.94677386,6.28571429 8,6.28571429 Z" id="SettingIcon-path-1"></path>
            </defs>
            <g id="Style" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Iconology" transform="translate(-408.000000, -188.000000)">
                    <g id="Icon" transform="translate(48.000000, 124.000000)">
                        <g id="Icon/Filling/Settings" transform="translate(360.000000, 64.000000)">
                            <g id="Group-3">
                                <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                                <mask id="SettingIcon-mask-2" fill="white">
                                    <use href="#SettingIcon-path-1"></use>
                                </mask>
                                <use id="Mask" fill={color} fillRule="nonzero" href="#SettingIcon-path-1"></use>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
