


import pageEntrySlim from 'bwax-ui/ml/page_entry_slim.bs'

import { unpack } from 'bwax/lang/LangHelper'

import { combineDependedFragments } from 'bwax/BwaxExecHelper';

import fragment_helper from 'bwax/ml/utils/fragment_helper.bs';

export default async function setupPageEnv(compiled, facade) {

    const unpacked = unpack(compiled);
    
    const [[n, defs, dts, entity_dict, data_type_dict ], unpackedFragments, externalNames ] = await (async () => {
        if(unpacked.length == 5) {
            // 旧版本直接从页面里面拿内容
            return [ unpacked, [], [] ]
        } else {
            // 新版本从 facade 里面拿：
            const [ n, defs, dts, entityNames, externalNames = [] ] = unpacked;
            
            const fragmentNames = fragment_helper.get_fragment_names_as_js(externalNames);

            const unpackedFragments = await facade.loadPageFragments(fragmentNames);
            
            // 应该 prepare 所有相关 fragments 的 entityNames，如果他们是
            const entityNamesForFragments = unpackedFragments.reduce((acc, f) => {
                if(f.length == 5) {
                    // 旧版本在 combineDependedFragments 里面处理
                    console.log("OLD VERSION FRAGMENT", f);
                    return acc
                } else {
                    const [ _n, _defs, _dts, entityNames ] = f;
                    return [ ...acc, ...entityNames ];
                }
            }, [])
            
            await facade.prepare([...entityNames, ...entityNamesForFragments]);
            return [[ n, defs, dts, facade.entity_dict, facade.data_type_dict ], unpackedFragments, externalNames ]

        }
    })();

    // 还是要 combined 的
    const {
        entity_dict: merged_entity_dict,
        data_type_dict: merged_data_type_dict,
        dts: merged_dts,
        modules: depended_modules
    } = combineDependedFragments(unpackedFragments, entity_dict, data_type_dict, dts)

    const env = pageEntrySlim.prepare_eval_env_with_base (
        facade.baseEnv, merged_entity_dict, merged_data_type_dict, [], [], depended_modules
    );

    return [ [n, defs], merged_dts, merged_entity_dict, merged_data_type_dict, env, externalNames ]
        
}