
import React, { Suspense } from 'react'

import { resolveFromURL, isQQPlayURL, compatibleWithQQURL } from './mediaTypes'
import { ratioInnerStyle, ratioOuterStyle, iframeStyle, ratioLOCALOuterStyle } from './styles'

// import { defaultLOCALVideoOptions } from './mediaPluginHelpers'
// copied here to avoid importing Draft.js
export const defaultLOCALVideoOptions = {
    preload: 'auto',
    language: 'zh-cn',
    controls: true,
    aspectRatio: "16:9",
    controlBar: {
        volumePanel: { inline: false }
    }
}



const Video = React.lazy(() => import('bwax-ui/auxiliary/video/Video'));

// import Video from 'bwax-ui/auxiliary/video/Video'

const convertFromHTML = {

    htmlToBlock: (nodeName, node) => {
        if (nodeName === "iframe" || nodeName === 'video') {
            const src = node.src || node.getAttribute('data-src')
            if (src) {
                const videoInfo = resolveFromURL(src.trim())
                if (videoInfo) {
                    return {
                        type: "atomic",
                        data: {
                            type: "VIDEO",
                            src: node.src,
                            ...videoInfo
                        }
                    }
                }
            }
        }
    }
}

const convertToHTML = {
    blockToHTML: (block) => {

        if (block.type === 'atomic' && block.data.type === 'VIDEO') {

            const { playURL } = block.data
            if (isQQPlayURL(playURL)) {
                return (
                    <figure style={ratioOuterStyle}>
                        <iframe className="video_iframe" style={{
                            ...ratioInnerStyle,
                            ...iframeStyle
                        }}
                            frameBorder={0} allowFullScreen={true}
                            src={playURL}
                        />
                    </figure>
                )
            } else {
                return (
                    <figure style={ratioLOCALOuterStyle}>
                        <video
                            style={{ width: "100%", height: "100%" }}
                            data-setup={JSON.stringify({
                                ...defaultLOCALVideoOptions,
                                poster: playURL + '?x-oss-process=video/snapshot,t_10000,m_fast,ar_auto'
                            }
                            )}
                            className="video-js vjs-default-skin" >
                            <source src={playURL} type="video/mp4"></source>
                            <source src={playURL} type="video/ogg"></source>
                            <source src={playURL} type="video/webm"></source>
                            <p className="vjs-no-js">
                                观看该视频请先允许下载脚本JavaScript，然后保证你的浏览器
                                <a href="http://videojs.com/html5-video-support/" target="_blank">
                                    支持html5视频
                                </a>
                            </p>
                        </video>
                    </figure>
                )
            }
        }
    }
}

const convertToReact = {
    blockToReact: (block) => {
        try {
        if (block.type === 'atomic') {
            const { playURL, type } = block.data

            if (type === "VIDEO") {
                if (isQQPlayURL(playURL)) {
                    return (
                        <figure style={ratioOuterStyle}>
                            <iframe className="video_iframe" style={{
                                ...ratioInnerStyle,
                                ...iframeStyle
                            }}
                                frameBorder={0} allowFullScreen={true}
                                src={compatibleWithQQURL(playURL)}
                            />
                        </figure>
                    )
                } else {
                    return (
                        <figure style={ratioLOCALOuterStyle}>
                            <Suspense fallback={null}>
                                <Video
                                    options={{
                                        ...defaultLOCALVideoOptions,
                                        poster: playURL + '?x-oss-process=video/snapshot,t_10000,m_fast,ar_auto'
                                    }}>
                                    <source src={playURL} type="video/mp4"></source>
                                    <source src={playURL} type="video/ogg"></source>
                                    <source src={playURL} type="video/webm"></source>
                                    <p className="vjs-no-js">
                                        观看该视频请先允许下载脚本JavaScript，然后保证你的浏览器
                                        <a href="http://videojs.com/html5-video-support/" target="_blank">
                                                    支持html5视频
                                        </a>
                                    </p>
                                </Video>
                            </Suspense>
                        </figure>
                    )
                }
            }

            if (type === "AUDIO") {
                const { align } = block.data
                return (
                    <figure style={{ textAlign: align || 'left' }}>
                        <audio controls>
                            <source src={playURL} type="audio/mpeg" />
                            <source src={playURL} type="audio/wav" />
                            <source src={playURL} type="audio/ogg" />
                            <p>暂不支持播放该音频，你可以<a href={playURL}>下载</a>这个音频文件。</p>
                        </audio>
                    </figure>
                )
            }
        }
        } catch (error) {
            console.error("MEDIA ERROR", error);
            return <div>MI</div>
        }
    }
}


export {
    convertFromHTML,
    convertToHTML,
    convertToReact
}
