
import React, { useRef } from 'react'

import Tooltip from 'bwax-ui/components/Tooltip';
import { ReactEditor } from 'slate-react';
import { Editor, Transforms } from 'slate';
import { isMobile } from 'bwax/clientEnv'

export default function Link({ editor, attributes, children, element, setIsLinkEditing, setHighlightRange, setIsEditFromLinkComp }) {
    const { url } = element
    const visibleSetterRef = useRef(null)

    const InlineChromiumBugfix = () => (
        <span contentEditable={false} style={{ fontSize: 0 }}>
          {String.fromCodePoint(160) /* Non-breaking space */}
        </span>
    )
    
    const c = (
        <a {...attributes} href={url} >
            <InlineChromiumBugfix />
            {children}
            <InlineChromiumBugfix />
        </a>
    )

    function close () {
        if(visibleSetterRef.current) {
            visibleSetterRef.current(false)
        }
    }

    function startEdit() {
        const range = Editor.range(editor, ReactEditor.findPath(editor, element))

        setHighlightRange(range)
        Transforms.select(editor, range)
        const domRange = ReactEditor.toDOMRange(editor, range)
        const selection = window.getSelection()
        selection.removeAllRanges();
        selection.addRange(domRange)

        setIsLinkEditing(true)
        setIsEditFromLinkComp(true)

        close()
    }

    const urlContent = (
        <div className='flex items-center gap-1.5'>
            {url}
            <div onClick={() => startEdit()} 
                className='whitespace-nowrap py-0.5 px-1.5 cursor-pointer rounded-[3px] bg-[var(--gray11)]'
            >
                Edit
            </div>
        </div>
    )

    return (
        <Tooltip
            placement="bottom"
            content={urlContent}
            getPopupContainer={triggleNode => triggleNode}
            bindVisibleSetter={setter => visibleSetterRef.current = setter}
        >
            { c }
        </Tooltip>

    )
}
