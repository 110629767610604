

import React, { useState, useEffect } from 'react'

import RecordFormForEntity from './RecordFormForEntity';

export default function EditRecordForm(props) {

    const { facade, entityName, fieldPaths, onUpdated, onCancel, fieldItems, color, record } = props

    const [entity, setEntity] = useState(facade.entities.find(e => e.name == entityName));

    const initialValues = record;

    useEffect(() => {
        (async () => {
            await facade.prepare([entityName]);
            setEntity(facade.entities.find(e => e.name == entityName) || null)
        })();
    }, [entityName, facade])

    async function updateRecord({ dirtyValues }) {
        
        const [r, error] = await facade.update({
            entityName,
            formData: {
                ...dirtyValues,
            },
            fieldPaths,
            id: record.id,
        });
        if(error) {
            setError(error);
        }
        onUpdated(r)

    };
    

    function renderContent() {
        if (!entity) {
            return (
                <div style={{ height: "100%", width: "100%", display: "flex", alignItems: "center", minHeight: "w0rem" }}>
                    <Loading />
                </div>

            )
        }

        return (
            <RecordFormForEntity entity={entity} facade={facade} {...{  
                fieldPaths, fieldItems, initialValues,
                onConfirm: ({ dirtyValues }) => {
                    updateRecord({ dirtyValues })
                },
                color,
                confirmText: "确定",
                onCancel,
                isFieldApplicable: f => f.updatable,
                isReadyToConfirm: ({involvedEditKeys, dirtyValues, defaultValues}) => {
                    const hasDirtyValue = involvedEditKeys.some(key => {
                        return dirtyValues[key] !== undefined && dirtyValues[key] !== defaultValues[key]
                    })
                    return hasDirtyValue;
                }
            }} />
        )
    }

    return renderContent();
}
