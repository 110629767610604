
import React from 'react'

import FileDisplay from './FileDisplay';
import numeral from 'numeral';

export default function getFieldDisplay(field, entityName, { facade, constraints }) {

    const display = {
        Select: ({ value }) => {
            const { options } = field.options;
            const op = options && options.find(o => o.value == value);
            return op ? op.name : value;

        },
        File: ({ value }) => {
            return (
                <FileDisplay value={value} />
            )
        },
        Integer: ({ value }) => {
            const fm = field.options && field.options.format;
            return numeral(value).format(fm || "0,0");
        },
        Number: ({ value }) => {
            const fm = field.options && field.options.format;
            return numeral(value).format(fm || "0,0.00");
        },
    }

    function asKilo ({value}) {
        const fm =  "0.0a";
        if(!value) return "-"
        return numeral(value).format(fm || "0.00a");
    }

    const specifiedDisplays = {
        "OpenAI-文档翻译任务": {
            "预估用量": asKilo,
            "用量": asKilo,
        }
    }

    return (specifiedDisplays[entityName] && specifiedDisplays[entityName][field.name]) || display[field.type];
}
