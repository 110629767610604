
import React from 'react';

// export const Cb5YearSlides = React.lazy(() => import ('./adhoc/Cb5YearSlides'));

// import Cb5YearSlides from './adhoc/Cb5YearSlides';

const components = {
    // "cb5YearSlides": Cb5YearSlides
};


export default function AdhocUILazy (props) {

    const { name, config, callbacks } = props;

    const processedCallbacks = Object.keys(callbacks).reduce((acc, key) => {
        function nf() {
            return callbacks[key](arguments);
        }
        return {
            ...acc,
            [key]: nf
        }
    }, {})

    const Comp = components[name];

    if(Comp) {
        return (
            <Comp config={config} callbacks={processedCallbacks} />
        )
    } else {
        return <div style={{ padding: "1rem"}}>没有对应的{name}组件</div>
    }

}
