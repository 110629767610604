


import React from 'react'

import numeral from 'numeral';

import ProgressBar from 'bwax-ui/components/ProgressBar';


import Txt2ImgTask from './task/Txt2ImgTask';

import { MdTranslate, MdOutlineLayersClear } from 'react-icons/md';
import { TbFileTextAi, TbFileOrientation, TbPaint } from 'react-icons/tb'

export default [
    { name: "doc-translate", entityName: "OpenAI-文档翻译任务", label: "文档翻译", desc: "翻译整个文档，保持原来的格式", tags: ["文档处理"], icon: <MdTranslate /> },
    { name: "text-recognise", entityName: "OpenAI-文档文字识别任务", label: "文字识别", desc: "识别扫描文档中的文字", tags: ["文档处理"], icon: <TbFileTextAi /> },
    { name: "doc-convert",  entityName: "OpenAI-文档格式转换任务", label: "文档格式转换", desc: "转换文档格式，比如把 PDF 转为 Word 文档", tags: ["文档处理"], icon: <TbFileOrientation /> },
    // { 
    //     name: "text-to-image",
    //     entityName: "OpenAI-文本生成图片任务", label: "生成图片", desc: "输入文字，让 AI 生成图片", tags: ["图片处理"], icon: <i className='bx bx-image-add'></i>,
    //     isLarge: true,

    //     component: Txt2ImgTask
    // },

    // { entityName: "OpenAI-图片背景重绘任务", label: "背景重绘", desc: "给你的产品生成不一样的背景", tags: ["图片处理"], icon: <TbPaint /> },
    // { entityName: "OpenAI-图片背景移除任务", label: "背景移除",  desc: "移除图片的背景", tags: ["图片处理"], icon: <MdOutlineLayersClear /> },
]




export const taskStatuses = {
    "Init": { 
        label: "未开始", color: "yellow",  
        component: ({ size }) => <i className='bx bx-play-circle text-[var(--amber10)]' style={{ fontSize: size + 2 }} ></i>
    },
    "Waiting": { 
        label: "等待开始", color: "orange", 
        component: ({ size }) => <i className='bx bx-pause-circle text-[var(--amber10)]' style={{ fontSize: size + 2 }} ></i>
    },
    "Processing": { 
        label: "进行中", color: "violet", 
    
        component: ({progress, size}) => {
            return (
                <ProgressBar 
                    progress={progress} type="circle" color={"violet"} 
                    style={{ height: size, width: size}} 
                    showNumber={size > 24}
                    doneColor="amber"
                />
            )
        }
    },
    "Completed": { 
        // label: "已完成", 
        color: "grass", icon: <i className='bx bx-check'></i>,

        component: ({ size }) => (
            <i className='bx bx-check-circle text-[var(--grass9)]' style={{ fontSize: size + 2 }} ></i>
        )

    },
    "Terminated": { 
        label: "已终止", color: "red", 
        component: ({ size }) => <i className='bx bx-stop-circle text-[var(--tomato9)]' style={{ fontSize: size + 2 }} ></i>
    },
    "Cancelled": { 
        label: "已取消", color: "gray",
        component: ({ size }) => <i className='bx bx-x-circle text-[var(--gray9)]' style={{ fontSize: size + 2 }} ></i>
    },
}


export function renderStatus(statusName, progress, size = 15) {

    const isProcessing = statusName == "Processing";
    const status = taskStatuses[statusName];

    const getLabel = () => {
        if (isProcessing) {
            // 返回百分比
            return numeral(progress || 0).format("0,0.0") + "%";
        } else {
            return status.label
        }
    }

    if(status.component) {
        const C = status.component;
        return <C progress={progress} size={size} />;
    }

    return (
        <div className="lc-job-status" data-color={status.color}>
            {getLabel()}
            <div className="font-size-10 flex">{status.icon || null}</div>
        </div>
    )
}