import React, { useState, useEffect } from 'react'

import useScreenSize from 'bwax-ui/auxiliary/useScreenSize'

export default function Thumbnail(props) {

    const { src } = props

    const [bgStyle, setBgStyle] = useState({
      backgroundImage: `url(${src})`
    })

    const screenSize = useScreenSize()

    useEffect(() => {
        const bgImg = new Image()
        const fixedLength = screenSize === "L" ? 300 : 200
        
        
        bgImg.onload = function () {
            // image 的初始宽高
            let imgWidth = bgImg.width
            let imgHeight = bgImg.height

            if (bgImg.width < bgImg.height) {
                imgHeight = fixedLength
                imgWidth = imgHeight * bgImg.width / bgImg.height
            } else {
                imgWidth = fixedLength
                imgHeight = imgWidth * bgImg.height / bgImg.width
            }

            const imgRatio = 3 / 1

            if (bgImg.height > bgImg.width && (bgImg.height / bgImg.width) > imgRatio) {
                setBgStyle({
                    ...bgStyle,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: fixedLength / imgRatio,
                    height: fixedLength
                })
            } else if (bgImg.width > bgImg.height && (bgImg.width / bgImg.height) > imgRatio) {
                setBgStyle({
                    ...bgStyle,
                    backgroundSize: bgImg.height > fixedLength ? "cover" : "auto",
                    backgroundPosition: "center",
                    width: fixedLength,
                    height: fixedLength / imgRatio
                })
            } else {
                setBgStyle({
                    ...bgStyle,
                    backgroundSize: `${imgWidth}px ${imgHeight}px`,
                    width: imgWidth,
                    height: imgHeight
                })
            }
        }
        bgImg.src = src
    }, [])

    function getThumbnailStyle () {
        const { style } = props
        return {
            ...bgStyle,
            ...style
        }
    }

    return (
        <div>
            <div style={getThumbnailStyle()} onClick={() => props.onClick()}>
            </div>
        </div>
    )
}
