
import React, { useState, useEffect } from 'react'

import WindowScrollHelper from '../impl/misc/WindowScrollHelper';
import RongChuanLogo from './components/RongChuanLogo';
import './RongChuan_Navigation.less'

import Link from 'bwax-ui/page/Link'

export default function RongChuan_Navigation(props) {

    const { data, slots, events } = props;
    
    const { showNav, iconColor = "#fff", isHomePage, homeUrl } = data
    const { toggleNav } = events

    // const [showNav, setShowNav] = useState(false)
    const [backgroundColor, setBackgroundColor] = useState(!isHomePage ? iconColor : showNav ? iconColor : "#fff")

    const [iconInBanner, setIconInBanner] = useState(true)

    useEffect(() => {   
        if(isHomePage) {
            if(showNav && backgroundColor === "#fff") {
                setBackgroundColor(iconColor)
            } else if (!showNav && iconInBanner) {
                setBackgroundColor("#fff")
            }
        }
    }, [ showNav ])

    useEffect(() => {
        if(isHomePage) {
            const scrollHelper = new WindowScrollHelper({
                // options
                topThreshold: window.innerHeight,
                bottomThreshold: 0,
    
                onScrolled: status => {
                    const { y, clientHeight } = status
    
                    if(y > clientHeight - 104 && backgroundColor !== iconColor) {
                        setBackgroundColor(iconColor)
                        setIconInBanner(false)
                    } else if (y < clientHeight - 104 && backgroundColor !== "#fff" && !showNav) {
                        setBackgroundColor("#fff")
                        setIconInBanner(true)
                    }
                }
            });
    
            return () => {
                scrollHelper.cleanUp();
            }
        }
    }, [ backgroundColor, showNav ])

    function toggle() {
        // setShowNav(!showNav)
        if(toggleNav) {
            toggleNav()
        }
    }


    return (
        <div className='rc-navigation-container'>
            <div className={`toggle-nav-icon-container ${showNav ? "show-nav" : ""}`} onClick={() => toggle()}>
                <div className='navicon-bar' style={{ backgroundColor }}></div>
                <div className='navicon-bar' style={{ backgroundColor }}></div>
                <div className='navicon-bar' style={{ backgroundColor }}></div>
            </div>
            <Link className='rongchuan-logo-box' to={homeUrl}>
                <RongChuanLogo color={backgroundColor}/>
            </Link>
        </div>
        
    )
}