

import React, { useEffect, useState, useRef } from 'react'

import './SubscriptionPayment_inbot.less'

import getImageURL from 'bwax-ui/getImageURL'
import numeral from 'numeral';
import dayjs from 'dayjs';

import Button from 'bwax-ui/components/Button'

import Dialog, { createDialogConfirm } from 'bwax-ui/components/Dialog'

import { runDataQuery } from 'bwax/query/runClientQuery';

import { toast } from 'bwax-ui/components/Toast';

import Wx_pay from "bwax-ui/ml/utils/wx_pay.bs"

import QrCode from 'bwax-ui/auxiliary/QrCode'

import { useTrack } from 'bwax-ui/track';

// 如果是在微信浏览器，则用直接跳转微信支付的方式支付；
// 如果实在手机浏览器，跳转到微信
// 否则，弹出二维码？

// 支付流程:
// 1. 创建订单
// 2. 调用 Wx_pay.initiate_pay
// 3. 然后好像就轮询订单状态就好了？

// 这个 component 相当依赖 inbot 这个域的各种实体

export default function SubscriptionPayment_inbot({ data = {}, events = {}, slots, facade, viewEnv, closeDialog }) {

    const {
        terms: givenTerms,
        currentPlanId,
        currentPlanExpiryDate,

        // 
        returnTo,

    } = data;

    const track = useTrack();

    const { reloadUsageQuota } = events;

    const { routeTo } = viewEnv;

    const [plans, setPlans] = useState();

    const [selectedPlan, setSelectedPlan] = useState();

    const [selectedTerm, setSelectedTerm] = useState("month"); // month, quater, year

    async function loadPlans() {
        const [data, error] = await facade.list({
            entityName: "套餐",
            sort: [{ field: "每日用量", order: "ASC" }],
            fieldPaths: ["名称", "说明", "价格", "免费体验", "每日用量", "文档容量", "可用任务模块", "icon"],
        })

        const plans = data.filter(x => !x.免费体验);

        setPlans(plans);
        if (plans.length > 0) {
            setSelectedPlan(plans[0])
        }

    }

    useEffect(() => {
        loadPlans()
    }, [])


    function printPrice(amount) {
        const n = numeral(amount).format(amount < 1 ? "0.00" : "0,0");
        return "￥" + n + "/月"
    }

    function printTotalAmount(amount) {
        const n = numeral(amount).format("0,0.00");
        return "￥" + n + ""
    }

    function printUsage(amount) {
        return numeral(amount).format("0,0");
    }

    function printPercentage(amount) {
        return numeral(amount * 100).format("0,0") + "%"
    }


    // 价格升至 59 和 139，同时季卡和年卡的优惠增至 -20% 和 -50%
    const terms = givenTerms || {
        "month": {
            label: "1 月",
            discount: 0,
            num: 1
        },
        "quater": {
            label: "3 月",
            discount: 0.2,
            num: 3
        },
        "year": {
            label: "1 年",
            discount: 0.5,
            num: 12
        }
    }

    // 不同套餐 选择    
    // 不同时长 选择
    // 勾选用户协议
    // 触发支付的按钮：

    // 创建
    // 创建订阅记录和支付订单



    const currentPlan = currentPlanId && (plans || []).find(p => p.id == currentPlanId);

    function printDate(d) {
        return dayjs(d).format("YYYY-MM-DD");
    }

    const DOWNGRADE = "downgrade";
    const UPGRADE = "upgrade";
    const EXTEND = "extend";
    const SUBSCRIBE = "subscribe"; //

    function getPurchaseType() {
        if (selectedPlan && currentPlan && selectedPlan.id !== currentPlan.id) {
            if (selectedPlan.每日用量 < currentPlan.每日用量) {
                return DOWNGRADE
            } else {
                return UPGRADE
            }
        } else if (currentPlan) {
            // 
            return EXTEND
        } else {
            return SUBSCRIBE
        }

    }

    const purchaseType = getPurchaseType();

    // for upgrade
    const diffMonth = (() => {
        if (selectedPlan && currentPlan && selectedPlan.id !== currentPlan.id) {
            const expiry = dayjs(currentPlanExpiryDate);
            return Math.round(expiry.diff(new Date(), 'month', true) * 100) / 100
        }
        return 0
    })();

    function getUpgradeDiscount(diffMonth) {
        let currentDiscount = 0;
        Object.keys(terms).forEach(k => {
            const { discount, num } = terms[k];
            if (diffMonth > num && discount >= currentDiscount) {
                currentDiscount = discount
            }
        });
        return currentDiscount;
    }

    function getUndiscountedAmount() {
        if (selectedPlan) {
            if ((purchaseType == EXTEND || purchaseType == SUBSCRIBE) && selectedTerm && terms[selectedTerm]) {
                const { num, discount } = terms[selectedTerm];
                return selectedPlan.价格 * num
            } else if (purchaseType == UPGRADE) {
                return (selectedPlan.价格 - currentPlan.价格) * diffMonth
            }
        }
        return 0;
    }

    function getDiscount() {
        if (selectedPlan) {
            if ((purchaseType == EXTEND || purchaseType == SUBSCRIBE) && selectedTerm && terms[selectedTerm]) {
                const { discount } = terms[selectedTerm];
                return discount
            } else if (purchaseType == UPGRADE) {
                // 这里有个情况需要说明：
                // 假设我买了一年的普通会员，第二天后，我就想升级高级会员，此时只能同期升级（但是全年减半的优惠就没了，只能用 -20% 的优惠）
                // 因此：我第二天升级高级会员所要付出的钱，是比直接买一年贵的。
                // 如果要按照原来一年的期限来优惠，算起来比较复杂，情况也比较多（因为当前用户的权益有可能部分是通过推荐赠送的），因此就用上面描述的逻辑好了。
                return getUpgradeDiscount(diffMonth)
            }
        }
        return 0;
    }

    function getNextExpiryDate() {
        if ((purchaseType == EXTEND || purchaseType == SUBSCRIBE) && selectedTerm && terms[selectedTerm]) {
            const { num } = terms[selectedTerm];
            return (dayjs(currentPlanExpiryDate || new Date()).add(num, 'month')).toDate()
        } else {
            return new Date(currentPlanExpiryDate);
        }
    }

    const nextExpiryDate = getNextExpiryDate();

    function getTotalAmount() {
        return getUndiscountedAmount() * (1 - getDiscount())
    }

    const discount = getDiscount();

    const [isPaying, setIsPaying] = useState();
    // 
    const [paymentCodeUrl, setPaymentCodeUrl] = useState();

    const [polling, setPolling] = useState(false);
    const pollingRef = useRef();
    pollingRef.current = polling;


    const [successAction, setSuccessAction] = useState();

    const [error, setError] = useState();

    async function tryToPay() {
        // 新的"订单"
        // 新的订阅记录，关联上面的订单

        // // 
        const title = (() => {
            if (purchaseType == EXTEND) {
                return selectedPlan.名称 + "续费 " + terms[selectedTerm].label
            } else if (purchaseType == SUBSCRIBE) {
                return selectedPlan.名称 + "订阅 " + terms[selectedTerm].label
            } else if (purchaseType == UPGRADE) {
                return `从${currentPlan.名称}升级至${selectedPlan.名称}，补差价时长 ${diffMonth} 月`
            }
        })();

        if (!title) {
            // 提示?
            return
        }

        setIsPaying(true);

        const totalAmount = getTotalAmount();

        const [order, error] = await facade.add({
            entityName: "订单",
            formData: {
                标题: title,
                金额: Math.round(totalAmount * 100),
            }
        })

        if (error || !order) {
            setIsPaying(false);
            // TODO 提示
            return
        }

        // 创建订阅记录
        const [sub, error2] = await facade.add({
            entityName: "订阅记录",
            formData: {
                类型: (
                    {
                        [EXTEND]: "续费",
                        [SUBSCRIBE]: "订阅",
                        [UPGRADE]: "升级",
                    }[purchaseType] || "订阅"
                ),
                标题: title,
                目标套餐: selectedPlan.id,
                初始套餐: currentPlan && currentPlan.id,
                金额: totalAmount,
                到期日: nextExpiryDate.toJSON(),
                支付订单: order.id
            }
        });

        if (error2 || !sub) {
            setIsPaying(false);
            // TODO 提示
            return
        }

        // 开始微信支付
        const queryRunner = (queryText, variables, file) => {
            return runDataQuery(facade.dlc)(queryText)(variables, file);
        }

        setPolling(true);
        pollingRef.current = true;

        Wx_pay.initiate_pay(
            queryRunner,
            order.id,
            codeUrl => {
                // 如果有 code， 基本上就是弹出
                setIsPaying(false);
                setPaymentCodeUrl(codeUrl)
            },
            error => {
                pollingRef.current = false;
                setIsPaying(false);
                setPolling(false);
            }
        )

        // 
        // start polling
        async function pollOrder() {
            const [polledOrder, error] = await facade.findById(order.id, {
                entityName: "订单",
                fieldPaths: ["支付状态", "实付金额"]
            }, { forceRefreshing: true });

            if (polledOrder && polledOrder.支付状态 == "已支付") {

                setPolling(false);
                pollingRef.current = false;

                setPaymentCodeUrl();

                if(polledOrder.实付金额 !== totalAmount * 100) {
                    toast({ title: "实付金额不符", duration: 4000 });

                    setError("实付金额不符，如有问题请联系管理员");
                    return

                }

                // 这个可以改成 pop confirm
                toast({ title: "已成功支付", duration: 2000 });


                setSuccessAction(purchaseType);

                if (reloadUsageQuota) {
                    reloadUsageQuota();
                    setTimeout(() => {
                        reloadUsageQuota();
                    }, 1000)
                }

            } else {

                if (pollingRef.current) {
                    setTimeout(() => {
                        pollOrder()
                    }, 500)
                }
            }

        }
        if (pollingRef.current) {
            setTimeout(() => {
                pollOrder()
            }, 500)
        }

    }



    function renderBillingLines() {
        if (selectedPlan) {
            const purchaseType = getPurchaseType();
            const label = purchaseType == UPGRADE && currentPlan ? `从${currentPlan.名称}升级` : (selectedTerm && purchaseType != DOWNGRADE ? terms[selectedTerm].label : "");

            function getTip() {
                if (purchaseType == DOWNGRADE) {
                    return "暂时不支持降低套餐"
                }
                if (currentPlan && purchaseType == EXTEND) {
                    return `你的${selectedPlan.名称}将延至 ${printDate(nextExpiryDate)}`
                }
                return `你将成为${selectedPlan.名称}, ${printDate(nextExpiryDate)} 到期`
            }

            function renderAmount() {
                return (
                    <>
                        {
                            discount > 0 ? <div style={{ textDecoration: "line-through" }}>{printTotalAmount(getUndiscountedAmount())}</div> : null
                        }
                        <div>
                            {printTotalAmount(getTotalAmount())}
                        </div>
                    </>
                )
            }

            return (
                <div className="billing-lines font-size-12">
                    <div className="billing-line">
                        <div>
                            {selectedPlan.名称} {label}
                        </div>
                        <div className="billing-amount">
                            {renderAmount()}
                        </div>
                    </div>
                    <div className="billing-line">
                        <div>{getTip()}</div>
                        <div></div>
                    </div>
                </div>
            )
        }
        return null
    }

    function getPurchaseTypeLabel(purchaseType) {
        if (purchaseType === EXTEND) {
            return "续费"
        } else if (purchaseType == UPGRADE) {
            return "升级"
        } else {
            return "订阅"
        }
    }

    const purchaseTypeLabel = getPurchaseTypeLabel(purchaseType);


    return (
        <>
            <div className="lc-subscription-payment h-full">
                <div className="flex flex-col grow overflow-auto gap-4">
                    {currentPlan ?
                        <div className="section">
                            <div className="instruction">当前套餐</div>
                            <div className="font-size-12">
                                <img src={getImageURL(currentPlan.icon, "thumbnail")} className="inline-block align-middle -translate-y-px" />
                                {currentPlan.名称}，
                                {printDate(currentPlanExpiryDate)} 到期
                            </div>
                        </div> : null
                    }
                    <div className="section">
                        <div className="instruction">选择套餐</div>
                        <div className="select-plan">
                            {
                                plans ? plans.map(plan => {
                                    return (
                                        <div className="plan-box" key={plan.id} data-selected={selectedPlan && selectedPlan.id == plan.id} onClick={_ => {
                                            track("pay_select_plan", { "plan_name": plan.名称 })
                                            setSelectedPlan(plan);
                                        }}>
                                            <div className="title-line">
                                                <div className="title">
                                                    <img src={getImageURL(plan.icon, "thumbnail")} />
                                                    {plan.名称}
                                                </div>
                                                <div className="price font-size-12">{printPrice(plan.价格)}</div>
                                            </div>
                                            <div className="detail font-size-12">
                                                {plan ? <div className="desc-line">{plan.说明}</div> : null}
                                                <div className="info-line">
                                                    每天用量限额: {printUsage(plan.每日用量)}
                                                </div>
                                                {/* <div className="info-line">
                                                文档存储空间: {plan.文档容量}G
                                            </div> */}
                                                {/* {plan.可用任务模块 ? <div className="info-line">可使用任务功能</div> : null} */}
                                            </div>
                                        </div>
                                    )
                                }) : null
                            }
                        </div>
                    </div>
                    <div className="section">
                        <div className="instruction">选择时长</div>
                        <div className="term-list">
                            {
                                purchaseType == EXTEND || purchaseType == SUBSCRIBE ? (
                                    Object.keys(terms).map(value => {
                                        const { label, discount } = terms[value]
                                        return (
                                            <div key={value} className="term" data-selected={selectedTerm && selectedTerm == value} onClick={_ => {
                                                track("pay_select_term", { "term_value": value })
                                                setSelectedTerm(value)
                                            }}>
                                                <span>{label}</span>
                                                {discount > 0 ? <span className="font-size-10"> (-{printPercentage(discount)})</span> : null}
                                            </div>
                                        )
                                    })
                                ) : (
                                    purchaseType == UPGRADE ? (
                                        <div className="term" data-selected={true} onClick={_ => { }}>
                                            <span>同期升级（{diffMonth}月）</span>
                                            {
                                                (() => {
                                                    const discount = getDiscount();
                                                    if (discount > 0) {
                                                        return <span className="font-size-10"> (-{printPercentage(discount)})</span>
                                                    }
                                                    return null
                                                })()
                                            }
                                        </div>
                                    ) : (
                                        <div className="term" data-selected={true} onClick={_ => { }} style={{ cursor: "not-allowed" }}>
                                            更改套餐
                                        </div>
                                    )
                                )
                            }
                        </div>
                    </div>
                    <div className="section billing-details">
                        <div className="instruction">订单详情</div>
                        {renderBillingLines()}
                    </div>
                    <div className="billing-total">
                        <div>合计</div>
                        <div className="font-size-20">{printTotalAmount(getTotalAmount())}</div>
                    </div>
                </div>
                {
                    (() => {
                        if(error) {
                            return (
                                <Button size="large" color="tomato"
                                        onPress={_ => {
                                            
                                        }}
                                    >
                                        { error }
                                    </Button>
                            )
                        }

                        if (polling) {
                            return (
                                <Button size="large" isDisabled={true}
                                    onPress={_ => {
                                        // // confirm 是不是没有支付
                                        // track("pay_stop_waiting")
                                        // createDialogConfirm({
                                        //     content: <div>系统仍在查询刚才的支付结果，你要重新支付吗？</div>,
                                        //     okText: "是的",
                                        //     cancelText: "不是",
                                        //     style: {
                                        //         width: "revert"
                                        //     },
                                        //     onConfirm: yes => {
                                        //         if (yes) {
                                        //             setPolling(false)
                                        //         }
                                        //     }
                                        // })
                                    }}
                                >
                                    正在支付中
                                </Button>
                            )
                        } else if (successAction) {

                            if (returnTo) {
                                return (
                                    <Button size="large" color="grass"
                                        onPress={_ => {
                                            track("pay_return_to_last")
                                            // 自动转成 path 的 tenant？
                                            function makePathTenant(url) {
                                                const { tenantCode, tenantCodeMode } = facade.dlc;
                                                if (tenantCodeMode == "param" || true) {
                                                    if (url.startsWith("/")) {
                                                        return "/-/" + tenantCode + url
                                                    }
                                                }
                                                return url
                                            }
                                            routeTo(makePathTenant(returnTo));
                                        }}
                                    >
                                        您已成功{getPurchaseTypeLabel(successAction)}，点击返回之前的页面
                                    </Button>
                                )
                            } else {

                                return (
                                    <Button size="large" color="grass" isDisabled={!closeDialog}
                                        onPress={_ => {
                                            if(closeDialog) {
                                                closeDialog()
                                            }
                                        }}
                                    >
                                        您已成功{getPurchaseTypeLabel(successAction)}{ closeDialog ? "，点击关闭" : ""}
                                    </Button>
                                )
                            }



                        } else {
                            return (
                                <Button isDisabled={purchaseType == DOWNGRADE} color={"yellow"} appearance="primary" size="large"
                                    isLoading={isPaying}
                                    onPress={_ => {
                                        track("pay_start_paying")
                                        tryToPay()
                                    }}
                                >
                                    {purchaseTypeLabel}
                                </Button>
                            )
                        }

                    })()
                }

            </div>
            <Dialog {...{
                open: !!paymentCodeUrl,
                style: {
                    width: "revert",
                    padding: "3.5rem 1rem 2rem",
                },
                onOpenChange: open => {
                    if (!open) setPaymentCodeUrl()
                },
                content: (
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1.25rem",
                        alignItems: "center"
                    }}>
                        <QrCode
                            info={paymentCodeUrl}
                            size={200}
                            onGenerated={() => {
                                // console.log("Generated", text)
                            }}
                        />
                        <div>扫描二维码支付</div>
                        <div className="text-[var(--gray11)] font-size-12">* 支付之后，请稍等一两秒，等待后台读取信息</div>
                    </div>
                )
            }} />
        </>
    )
}
