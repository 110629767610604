

import React from 'react'

import classNames from 'classnames';

import numeral from 'numeral'

import { CheckIcon } from '@radix-ui/react-icons';

import Button from 'bwax-ui/components/Button';

import { useTrack } from 'bwax-ui/track';

export default function Pricing_inbot({ data, slots, events, viewEnv }) {

    const track = useTrack();
    
    const { 
        plans, tryUrl = "/signup",
        teamPlanDesc = "多人协作版本，适合 2 个到 30 个成员的团队，共同建立知识库，让整合了团队知识的生成式 AI 赋能每个成员，提升沟通效率和团队创造力",
        enterprisePlanDesc = "独立部署版本，适合有需求的企业客户，专门配置的用户体系，与企业现有系统集成，建立企业知识中台，让生成式 AI 赋能每个业务员工",
    } = data;

    const { webEnv } = viewEnv;

    const additionalPlans = [
        {
            name: "团队版", 
            code: "team",
            desc: teamPlanDesc,
            color: "cyan",
            customDetail: (
                <>
                    <Button className="mt-7" isDisabled={true}>
                        即将推出
                    </Button>
                </>
            )
        },
        {
            name: "企业版", 
            code: "enterprise",
            desc: enterprisePlanDesc,
            color: "blue",
            customDetail: (
                <>
                    <Button className="mt-7" isDisabled={true}>
                        即将推出
                    </Button>
                </>
            )
        }
    ]

    function renderPlan(plan) {
        const {
            name, desc, price, color = "mauve", code,

            customDetail,
        } = plan;

        const colorLine = (
            <div className="w-full h-1" style={{
                backgroundColor: `var(--current-color)`,
            }}></div>
        )

        return (
            <div className="flex flex-col shadow rounded w-full  overflow-hidden bg-[var(--lc-color-bg-2)]" style={{
                "--current-color": `var(--${color}9)`
            }}>
                {colorLine}
                <div className="flex flex-col gap-4 w-full px-5 py-6">
                    <div className="flex flex-col gap-2 w-full">
                        <div className="text-lg font-medium">
                            {name}
                        </div>
                        <div className="font-size-13 text-[var(--gray11)] py-2">
                            {desc}
                        </div>
                        {price == undefined ? null : (
                            <div className="">
                                ￥<span className="font-medium text-3xl mx-1" style={{
                                    color: `var(--current-color)`
                                }}>{price}</span>/月
                            </div>
                        )}
                    </div>
                    {customDetail ? customDetail : (
                        <>
                            <div className="border-b-[1px] border-[var(--gray8)] my-3 mx-0.5" />
                            <ul className="py-1 text-[var(--gray11)] flex flex-col gap-2 font-size-12">
                                <li className="flex items-center gap-1.5">
                                    <CheckIcon />
                                    <div>
                                        所有功能都可以使用
                                    </div>
                                </li>
                                <li className="flex items-center gap-1.5">
                                    <CheckIcon />
                                    <div>
                                        每日用量 <span className="font-medium">{numeral(plan.usage).format("0.0a")} </span>
                                    </div>
                                </li>
                                <li className="flex items-center gap-1.5">
                                    <CheckIcon />
                                    <div>
                                        文档存储空间 <span className="font-medium">{plan.storage}G</span>
                                    </div>
                                </li>
                            </ul>
                            <Button color={color} onPress={_ => {
                                track("home_try_plan_" + plan.code, {});

                                const url = webEnv.isSandbox ? "/sandbox" + tryUrl : tryUrl;
                                window.open(url);

                            }}>{plan.isFree ? "立即注册" : "立即体验"}</Button>
                        </>
                    )}
                </div>
            </div>
        )
    }


    return (
        <div className="lc-pricing-inbot w-full flex flex-col gap-5 sm:gap-12 py-12">
            <div className="flex flex-col px-4 gap-5 sm:flex-row sm:gap-0 max-w-4xl self-center">
                {plans.map((plan, index) => {
                    // 基本上是三个吧
                    return (
                        <div key={index} className={
                            classNames(
                                "px-3 w-full",
                                { "sm:w-1/2": plans.length == 2, "sm:w-1/3": plans.length == 3, "sm:w-1/4": plans.length == 4 }

                            )
                        }>
                            {renderPlan(plan)}
                        </div>
                    )
                })}
            </div>
            <div className="flex flex-col px-4 gap-5 sm:flex-row sm:gap-0 max-w-2xl self-center ">
                {additionalPlans.map((plan, index) => {
                    // 基本上是三个吧
                    return (
                        <div key={index} className={
                            classNames(
                                "px-3 w-full",
                                { "sm:w-1/2": plans.length == 2, "sm:w-1/3": additionalPlans.length == 3, "sm:w-1/4": additionalPlans.length == 4 }

                            )
                        }>
                            {renderPlan(plan)}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
