
import React from 'react';
// import createInlineStyleButton from './util/createInlineStyleButton';
import InlineStyleButton from '../util/InlineStyleButton';

import { MdFormatUnderlined } from 'react-icons/md'

export default function UnderlineButton (props) {
    const { editor } = props
  
    return InlineStyleButton({
        editor,
        style: 'underline',
        children: (
            <MdFormatUnderlined style={{ fontSize: 16 }} />
        ),
    })
}

// export default createInlineStyleButton({
//   style: 'underline',
//   children: (
//     <i className={"fa fa-underline"} aria-hidden="true"></i>
//   ),
// });
