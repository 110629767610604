
import React from 'react'

const DEFAULT_BLOCK_HTML = {
  'unstyled': <p />,
  'paragraph': <p />,
  'header-one': <h1 />,
  'header-two': <h2 />,
  'header-three': <h3 />,
  'header-four': <h4 />,
  'header-five': <h5 />,
  'header-six': <h6 />,
  'blockquote': <blockquote />,
  'unordered-list-item': {
    element: <li />,
    nest: <ul />
  },
  'ordered-list-item': {
    element: <li />,
    nest: <ol />
  },
  'media': <figure />
}

const ALLIGNMENT_SUPPORT_TYPES = {
  'unstyled': <p />,
  'paragraph': <p />,
  'header-one': <h1 />,
  'header-two': <h2 />,
  'header-three': <h3 />,
  'header-four': <h4 />,
  'header-five': <h5 />,
  'header-six': <h6 />,
  'unordered-list-item': <li />,
  'ordered-list-item': <li />,
  'blockquote': <blockquote />
}


const SUPPORT_STYLES = [
  "textAlign",
  "marginTop",
  "marginBottom",
  "marginRight",
  "marginLeft",
]

const shouldStyleInclude = (k, v) => {
  return SUPPORT_STYLES.indexOf(k) !== -1 &&
    (v !== "" && v !== null && v !== undefined)
}

export {
  DEFAULT_BLOCK_HTML,
  ALLIGNMENT_SUPPORT_TYPES,
  SUPPORT_STYLES,
  shouldStyleInclude
}
