
// This file is generated. Don't modify it.
import React from 'react'
export default function FolderIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M7.99972936,2.8 C5.12793867,2.8 2.8,5.12808375 2.8,7.99972936 C2.8,10.8718437 5.12786613,13.2 7.99972936,13.2 C10.8717181,13.2 13.2,10.8717181 13.2,7.99972936 C13.2,5.1280112 10.8719888,2.8 7.99972936,2.8 Z M7.99972936,2 C11.3140872,2 14,4.68645405 14,7.99972936 C14,11.3135459 11.3135459,14 7.99972936,14 C4.68591277,14 2,11.3135459 2,7.99972936 C2,4.68645407 4.68591277,2 7.99972936,2 Z M7.43433061,7.74770235 L5.17274169,5.48611343 L6.21474317,5.48611343 L7.99679598,7.20045645 L9.75881665,5.48611343 L10.8272583,5.48611343 L8.56566279,7.74770895 L8.56685424,7.74885513 L8.00116881,8.31454056 L8,8.31337174 L7.99883119,8.31454056 L7.43314576,7.74885513 L7.43433061,7.74770235 Z M7.99679598,9.39980246 L9.75881665,7.68545944 L10.8272583,7.68545944 L8.56566279,9.94705497 L8.56685424,9.94820114 L8.00116881,10.5138866 L8,10.5127178 L7.99883119,10.5138866 L7.43314576,9.94820114 L7.43433061,9.94704836 L5.17274169,7.68545944 L6.21474317,7.68545944 L7.99679598,9.39980246 Z" id="FolderIcon-path-1"></path>
            </defs>
            <g id="Style" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Iconology" transform="translate(-528.000000, -124.000000)">
                    <g id="Icon" transform="translate(48.000000, 124.000000)">
                        <g id="Icon/Folder" transform="translate(480.000000, 0.000000)">
                            <g id="Group-3">
                                <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                                <mask id="FolderIcon-mask-2" fill="white">
                                    <use href="#FolderIcon-path-1"></use>
                                </mask>
                                <use id="Combined-Shape" fill={color} fillRule="nonzero" href="#FolderIcon-path-1"></use>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
