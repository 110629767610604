


export default async function handleReferral({ currentUserId, facade, }) {
    // https://translate.simplifyai.cn/sandbox/?ur=42a841


    async function getReferralCode(code) {
        const [result, error] = await facade.findOne({ entityName: "邀请码", condition: [[{ field: "内容", op: "eq", value: code.toUpperCase() }]], fieldPaths: ["用户.id"] })
        return result
    }

    async function getUserReferred() {
        const [result, error] = await facade.findOne({ entityName: "邀请记录", condition: [[{ field: "被邀请者.id", op: "eq", value: currentUserId }]] })
        return result
    }

    async function countVisits() {
        const [result, error] = await facade.customQuery({
            entityName: "邀请记录",
            interfaceName: "查看访问数"
        })
        return result || 0
    }


    // 1. 先看看 localStorage 有没有 user-ref
    if (typeof ("localStorage") == "undefined") {
        return
    }

    function clearChannelRef() {
        localStorage.removeItem("channel-ref");
    }

    function clearUserRef() {
        localStorage.removeItem("user-ref");
    }
    function clear() {
        localStorage.removeItem("user-ref");
        localStorage.removeItem("channel-ref");
    }

    const code = localStorage.getItem("user-ref");

    const channel = localStorage.getItem("channel-ref")

    async function determineReferralCode() {
        // for code
        if (!code) {
            return
        }

        // 2. 如果有，先看看这个 user-ref 是不是存在：
        const referralCode = await getReferralCode(code);
        if (!referralCode) {
            return
        }

        // 2. 如果是自己的，忽略，并且清理
        if (referralCode.用户.id == currentUserId) {
            console.log(">> is self");
            return
        }


        // 3. 如果存在而且不是自己，看看当前用户有没有被邀请过:
        const existing = await getUserReferred();
        if (existing) {
            return
        }
        return referralCode
    }


    const referralCode = await determineReferralCode();

    if(!channel && !referralCode) {
        clear();
        return
    }


    // 4. 如果没被邀请过，查查看当前用户是不是第一次（为了保险起见，前十七次都可以）访问 "/u/task"
    const visitCount = await countVisits();

    // console.log(">> visit count", visitCount);
    // 5. 如果是，则登记邀请记录
    if (visitCount >= 19) {
        clear();
        return
    }

    if(channel) {
        const [ result, error ] = await facade.update({ entityName: "用户", id: currentUserId, formData: { "渠道": channel } });
        if(!error) {
            // console.log(">>> clear channel ref");
            clearChannelRef()
        }
        // 
    }
    if(referralCode) {
        const [ result, error ] = await facade.customMutation({ entityName: "邀请记录", interfaceName: "确认邀请", args: [referralCode.id] });
        if (!error) {
            // 如果成功，则清理掉 localStorage 的 user-ref
            // console.log(">>> clear user ref");
            clearUserRef()
        }    
    }

}


