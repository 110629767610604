import React from 'react'

import { Item, Section, useListState } from 'react-stately';
import { mergeProps, useFocusRing, useListBox, useOption, useListBoxSection } from 'react-aria';

import classNames from 'classnames';

import "./ListBox.less";

export default function ListBox(props) {

    const {
        items, ...otherProps
    } = props;

    function collectDisabledKeys(items) {
        return items.flatMap(item => {
            const { label, value, isDisabled, items } = item
            return items ? collectDisabledKeys(items) : (isDisabled ? [value] : [])
        })
    }
    const disabledKeys = collectDisabledKeys(items);

    function renderItem(item, index) {
        const { label, value, items } = item
        if (items) {
            // section
            return (
                <Section title={item.label} key={index} aria-label={item.label}>
                    {items.map(renderItem)}
                </Section>
            )
        } else {
            return (
                <Item key={value}>{label}</Item>
            )
        }
    }

    return (
        <ListBox_comp {...otherProps} disabledKeys={disabledKeys}>
            {items.map(renderItem)}
        </ListBox_comp>
    )
}


function ListBox_comp(props) {
    // Create state based on the incoming props
    const {
        multivalued, onSelect, selected,
        ...otherProps
    } = props;

    const onSelectionChange = selection => {
        if (multivalued) {
            onSelect([...selection]);
        } else {
            onSelect([...selection][0]);
        }

    }
    const selectedKeys = multivalued ? selected : ([selected].filter(x => !!x))

    const state = useListState({
        ...otherProps,
        selectionMode: multivalued ? "multiple" : "single",
        onSelectionChange,
        selectedKeys
    });
    
    return (
        <ListBoxStateless {...props} state={state} />
    )
}



export function ListBoxStateless(props) {
    // Create state based on the incoming props
    const {
        color = "violet", style, optionStyle, sectionStyle, state, itemUnstyled = false,
        className,
        ...otherProps
    } = props;


    // Get props for the listbox element
    const ref = React.useRef(null);
    const { listBoxProps, labelProps, } = useListBox({
        ...otherProps,
    }, state, ref);


    return (
        <ul
            {...listBoxProps}
            className={classNames("lc-list-box font-size-14 lc-base", className)}
            ref={ref}
            data-color={color}
            style={{
                ...(style || {}),
            }}
        >
            {[...state.collection].map((item) => (
                item.type === 'section'
                    ? <ListBoxSection key={item.key} section={item} state={state} sectionStyle={sectionStyle} itemUnstyled={itemUnstyled} />
                    : <Option key={item.key} item={item} state={state} style={optionStyle} aria-label={item.key} itemUnstyled={itemUnstyled} />
            ))}
        </ul>
    );
}


ListBox.Item = Item;
ListBox.Section = Section;

function Option({ item, state, style, itemUnstyled }) {
    // Get props for the option element
    const ref = React.useRef(null);
    const allOptionProps = useOption(
        { key: item.key },
        state,
        ref
    );

    const { optionProps, isSelected, isDisabled, isFocused } = allOptionProps;

    // Determine whether we should show a keyboard
    // focus ring for accessibility
    const { isFocusVisible, focusProps } = useFocusRing();

    return (
        <li className={"list-box-item" + (itemUnstyled ? "-unstyled" : "")} 
            data-focused={isFocused}
            data-keyboard-focus={isFocusVisible}
            {...mergeProps(optionProps, focusProps)}
            ref={ref}
            style={style}
        >
            {item.rendered}
        </li>
    );
}

function ListBoxSection({ section, state, style, itemUnstyled }) {
    let { itemProps, headingProps, groupProps } = useListBoxSection({
        heading: section.rendered,
        'aria-label': section['aria-label']
    });

    // If the section is not the first, add a separator element to provide visual separation.
    // The heading is rendered inside an <li> element, which contains
    // a <ul> with the child items.
    return (
        <>
            {section.key !== state.collection.getFirstKey() &&
                <li role="presentation" className="section-seperator" />
            }
            <li {...itemProps} className="list-box-section">
                {section.rendered &&
                    (
                        <span
                            {...headingProps}
                            className="section-head"
                        >
                            {section.rendered}
                        </span>
                    )}
                <ul
                    {...groupProps}
                    style={style}
                >
                    {[...section.childNodes].map((node) => (
                        <Option
                            key={node.key}
                            item={node}
                            state={state}
                            itemUnstyled={itemUnstyled}
                        />
                    ))}
                </ul>
            </li>
        </>
    );
}