// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var JsCookie = require("js-cookie");

function set(name, value, cookieOptions) {
  JsCookie.set(name, value, {
        expires: cookieOptions[/* expires */0],
        path: cookieOptions[/* path */1],
        domain: cookieOptions[/* domain */2]
      });
  return /* () */0;
}

function get(name) {
  return JsCookie.get(name);
}

exports.set = set;
exports.get = get;
/* js-cookie Not a pure module */
