import React, { useRef, useEffect, useState } from 'react';
import Image from './components/Image'
import Link from './components/Link'
import Media from './components/Media'
import Blockquote from './components/Blockquote';

import { useSelected, useSlate, ReactEditor } from "slate-react";
import { Editor, Range, Node, Transforms, Path } from "slate";
import { isBlockType, isList } from './SlateEditor'

import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import useDebounce from 'bwax-ui/legacy/page/hooks/useDebounce'

import TextArea from "bwax-ui/components/inputs/TextArea";

import TextInput from 'bwax-ui/components/inputs/TextInput';


const getStyle = element => {
    const { align } = element
    return {
        textAlign: align ? align : "start",
    }
}

const getAiPlaceholderClassName = props => {
    const { aiEnabled, isCompositionEditing, aiInputInfo, isEditorFocused } = props
    const editor = useSlate()
    const isSelected = useSelected()
    const isSelectionCollapsed = editor.selection ? Range.isCollapsed(editor.selection) : false
    const isBlock = isBlockType(editor)

    if (editor.selection) {
        const [node, path] = Editor.node(editor, editor.selection, { depth: 1 })
        const str = Node.string(node)
        const isEmpty = str === "" || str === '\n'

        if (aiEnabled && isEditorFocused && isSelected && isEmpty && isSelectionCollapsed && !isCompositionEditing && !aiInputInfo.responding && !isBlock) {
            return 'show-ai-placeholder'
        }
    }

    return ''
}


const getAttrs = (props) => {
    const className = getAiPlaceholderClassName(props);
    const { brickOpenedAt } = props;

    return {
        className: classNames(
            className,
            brickOpenedAt ? '!bg-[var(--blue4)] outline-4 outline outline-[var(--blue4)]' : ''
        ),
        style: getStyle(props.element),
        ...props.attributes
    }
}

const getBlockPlaceholder = (props, blockType) => {
    const { customBlockPlaceholder, isCompositionEditing, element, brickOpenedAt } = props;
    const isSelected = useSelected()
    const str = Node.string(element)
    const isEmpty = str === "" || str === '\n'
    const placeholderProps = customBlockPlaceholder && isEmpty && !(isCompositionEditing && isSelected) ? {
        placeholder: customBlockPlaceholder[blockType],
        className: 'show-block-placeholder' + classNames(
            brickOpenedAt ? ' !bg-[var(--blue4)]' : ''
        )
    } : {}

    return placeholderProps
}


export const DefaultElement = props => {
    return (
        <p {...getAttrs(props, 'paragraph')}>
            {props.children}
        </p>
    )
}

const H6 = props => {
    return <h6 {...getAttrs(props, 'h6')} {...getBlockPlaceholder(props, 'h6')}>{props.children}</h6>
}

const H5 = props => {
    return <h5 {...getAttrs(props, 'h5')} {...getBlockPlaceholder(props, 'h5')}>{props.children}</h5>
}

const H4 = props => {
    return <h4 {...getAttrs(props, 'h4')} {...getBlockPlaceholder(props, 'h4')}>{props.children}</h4>
}

const H3 = props => {
    return <h3 {...getAttrs(props, 'h3')} {...getBlockPlaceholder(props, 'h3')}>{props.children}</h3>
}

const H2 = props => {
    return <h2 {...getAttrs(props, 'h2')} {...getBlockPlaceholder(props, 'h2')}>{props.children}</h2>
}

const H1 = props => {
    return <h1 {...getAttrs(props, 'h1')} {...getBlockPlaceholder(props, 'h1')}>{props.children}</h1>
}

function getListStyleType({ editor, element, path }, listType) {
    const { listStyleType } = element
    if (listStyleType) {
        // 设置了 listStyleType，显示对应的编号样式
        return { listStyleType: listStyleType }
    } else if (path) {
        // 如果没有设置，判断当前 list 的层级，根据层级显示不同的默认编号样式
        const styleTypes = {
            'numbered-list': ['decimal', 'lower-alpha', 'lower-roman'],
            'bulleted-list': ['disc', 'circle', 'square']
        }

        if (Path.levels(path).filter(p => p.length > 0).length === 1) {
            // 当前 path 在最上层
            return {}
        }
        if (Node.has(editor, path)) {
            const ancestors = Array.from(Node.ancestors(editor, path, { reverse: true }))

            const listAncestors = ancestors.filter(ancestor => {
                const [ancestorNode, _] = ancestor
                return ancestorNode.type === element.type
            })
            return { listStyleType: styleTypes[listType][listAncestors.length % 3] }
        }

    }

    return {}
}

const OrderedList = props => {
    return (
        <ol {...props.attributes} style={getListStyleType(props, 'numbered-list')}>
            {props.children}
        </ol>
    )
}

const UnorderedList = props => {
    return (
        <ul {...props.attributes} style={getListStyleType(props, 'bulleted-list')}>
            {props.children}
        </ul>
    )
}

const ListItem = props => {
    return (
        <li style={getStyle(props.element)} {...props.attributes}  {...getBlockPlaceholder(props, 'list-item')}>
            {props.children}
        </li>
    )
}


const BlockquoteComp = props => {
    const { brickOpenedAt } = props;
    const className = classNames(
        brickOpenedAt ? '!bg-[var(--blue4)]' : ''
    )

    return (
        <Blockquote {...props} className={className} placeholderProps={getBlockPlaceholder(props, 'block-quote')} />
    )
}

const Code = props => {
    console.log(">>>", props);

    const { element, editor, attributes, children, path } = props;

    return (
        <div {...attributes} contentEditable={false}>
            { children }
            <TextArea styled={true} value={element.value || ""} onFocus={_ => {
                Transforms.select(editor, path)
            }} onChange={value => {
                Transforms.setNodes(editor, { value })
            }} />
        </div>
    )
}

const HR = props => {
    const { attributes, children } = props
    return (
        <div contentEditable={false} {...attributes}>
            {children}
            <hr />
        </div>
    )
}

const ImageComp = props => {
    return (
        <Image {...props} />
    )
}

const LinkComp = props => {
    return (
        <Link {...props}>
            {props.children}
        </Link>
    )
}

const MediaComp = props => {
    return (
        <Media {...props}>
        </Media>
    )
}

const NotSupportElement = props => {
    const { children } = props
    return (
        <figure {...props.attributes} className='editor-not-support-element'>
            {children}
            <div>{`<不支持的富文本内容 ${props.element.type}>`}</div>
        </figure>
    )
}

const NestWrapper = props => {
    return (
        <div {...props.attributes} className='nest-wrapper'>
            {props.children}
        </div>
    )
}

// 
const elementMapping = {
    "h6": H6,
    "h5": H5,
    "h4": H4,
    "h3": H3,
    "h2": H2,
    "h1": H1,
    "numbered-list": OrderedList,
    "bulleted-list": UnorderedList,
    "list-item": ListItem,

    "block-quote": BlockquoteComp,
    "code": Code,
    "hr": HR,

    "image": ImageComp,
    "link": LinkComp,
    "video": MediaComp,
    "audio": MediaComp,
    "paragraph": DefaultElement,
    "nestWrapper": NestWrapper
}

export function isNotSupportElement(element) {
    return !elementMapping[element.type]
}

const ElementWrapper = props => {
    const { element, editor } = props
    const name = element.type;
    const Component = elementMapping[name] || NotSupportElement;

    const innerRef = useRef();
    const [path, setPath] = useState();
    const { attributes, ...otherProps } = props;
    const { ref, ...otherAttrs } = attributes;

    const debouncedChildren = useDebounce(editor.children, 1000);

    useEffect(() => {
        if (!innerRef.current) return;
        const { editor } = props;
        const slateNode = ReactEditor.toSlateNode(editor, innerRef.current);
        const slatePath = ReactEditor.findPath(editor, slateNode);
        setPath(slatePath);

    }, [innerRef.current, debouncedChildren])

    const { brickToolbarInfo } = props;

    const brickOpenedAt =
        brickToolbarInfo && brickToolbarInfo.show && brickToolbarInfo.isOpened
        && isEqual(brickToolbarInfo.slatePath, path)

    // console.log('opened: ', brickToolbarInfo.isOpened);

    return (
        <Component {...otherProps}
            path={path}
            brickOpenedAt={brickOpenedAt}
            attributes={{
                ref: r => {
                    if (ref) {
                        if (typeof (ref) == "function") {
                            ref(r)
                        } else {
                            ref.current = r;
                        }

                    }
                    innerRef.current = r;
                },
                ...otherAttrs
            }}
        />
    )
};


export function getElement(name) {

    return ElementWrapper

} 