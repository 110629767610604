import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet-async'

import ToggleGroup from 'bwax-ui/components/inputs/ToggleGroup';

export default function UserTrends_inbot({ facade }) {

    // 
    const [days, setDays] = useState(15);

    const { timeCondition, timeGroup } = (() => {
        if (days == 15 || days == 30) {
            const timeCondition = { field: "创建时间", op: "gte", value: dayjs(new Date).subtract(days - 1, "day").hour(0).minute(0).second(0).millisecond(0).toJSON() };
            const timeGroup = [{
                field: "创建时间", func: "DATE", aliasName: "date"
            }];
            return { timeCondition, timeGroup }
        }
        if (days == "周") {
            const timeCondition = { field: "创建时间", op: "gte", value: dayjs(new Date).subtract(365, "day").hour(0).minute(0).second(0).millisecond(0).toJSON() };
            const timeGroup = [{
                field: "创建时间", func: "YEARWEEK", aliasName: "week"
            }];
            return { timeCondition, timeGroup }
        }
        if (days == "月") {
            const timeCondition = { field: "创建时间", op: "gte", value: dayjs(new Date).subtract(365, "day").hour(0).minute(0).second(0).millisecond(0).toJSON() };
            const timeGroup = [{
                field: "创建时间", func: "MONTH", aliasName: "month"
            }];
            return { timeCondition, timeGroup }
        }
    })();


    const extractDayValues = (result, aliasName) => {

        if (days == 15 || days == 30) {
            const dayValues = Array(days).fill(0).map((_, index) => {
                if (index !== 0) {
                    return dayjs().subtract(index, "day").format("YYYY-MM-DD");
                } else {
                    return dayjs().format("YYYY-MM-DD");
                }
            });

            function getValue(date) {
                const r = result.find(r => r.date == date);
                return r ? r[aliasName] : 0
            }
            // 全部加起来
            return dayValues.map(getValue)
        }
        if (days == "周") {

            const weekValues = Array(15).fill(0).map((_, index) => {
                return result[result.length - 1 - index].week
            });

            function getValue(week) {
                const r = result.find(r => r.week == week);
                return r ? r[aliasName] : 0
            }

            // 全部加起来
            return weekValues.map(getValue)
        }
        if (days == "月") {
            const monthValues = Array(7).fill(0).map((_, index) => {
                const m = dayjs().month() - index
                if (m < 0) {
                    return 12 + m
                } else {
                    return m
                }
            });

            function getValue(month) {
                const r = result.find(r => r.month == month + 1);
                return r ? r[aliasName] : 0
            }
            // 全部加起来
            return monthValues.map(getValue)
        }
    }


    const [newTenants, setNewTenants] = useState();
    async function loadNewTenants() {
        const [result, error] = await facade.aggregate({
            entityName: "租户",
            aggregate: [{ field: "id", func: "COUNT_DISTINCT", aliasName: "count", }],
            group: timeGroup,
            condition: [[
                timeCondition,
            ]]
        });
        if (result) {
            setNewTenants(extractDayValues(result, "count"))
        }
    }

    const [newActiveTenants, setActiveTenants] = useState();
    async function loadActiveTenants() {
        const [result, error] = await facade.aggregate({
            entityName: "页面统计-页面访问",
            aggregate: [{ field: "租户.id", func: "COUNT_DISTINCT", aliasName: "count", }],
            group: timeGroup,
            condition: [[
                timeCondition,
            ]]
        });
        if (result) {
            setActiveTenants(extractDayValues(result, "count"))
        }
    }

    const [newPayments, setNewPayments] = useState();
    async function loadNewPayments() {
        const [result, error] = await facade.aggregate({
            entityName: "订阅记录",
            aggregate: [{ field: "id", func: "COUNT_DISTINCT", aliasName: "count", }],
            group: timeGroup,
            condition: [[
                { field: "支付订单.支付状态", op: "eq", value: "已支付" },
                { field: "金额", op: "gt", value: 1},
                { field: "已作废", op: "ne", value: true },
                timeCondition,
            ]]
        });
        if (result) {
            setNewPayments(extractDayValues(result, "count"))
        }
    }


    // 充值金额
    const [paidAmounts, setPaidAmounts] = useState();
    async function loadPayAmounts() {
        const [result, error] = await facade.aggregate({
            entityName: "订阅记录",
            aggregate: [{ field: "金额", func: "SUM", aliasName: "sum", }],
            group: timeGroup,
            condition: [[
                timeCondition,
                { field: "支付订单.支付状态", op: "eq", value: "已支付" },
                { field: "金额", op: "gt", value: 1},
                { field: "已作废", op: "ne", value: true },
            ]]
        });
        if (result) {
            setPaidAmounts(extractDayValues(result, "sum"))
        }
    }


    useEffect(() => {
        loadNewTenants();
        loadActiveTenants();
        loadNewPayments();
        loadPayAmounts();
    }, [days]);


    const renderRow = (label, values, className) => {
        if (!values) { return null }
        return (
            <div className={classNames("w-full flex py-2", className)} key={label}>
                <div className="w-1/6 flex px-1 whitespace-pre">
                    {label}
                </div>
                <div className="w-5/6 flex text-right whitespace-pre">
                    {values.map((v, i) => {
                        return (
                            <div key={i} className="pl-2 pr-3" style={{
                                width: ((1 / values.length) * 100) + "%"
                            }}>
                                {v}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }



    return (
        <div className="self-center max-w-2xl w-full py-4 flex flex-col px-8 h-full">
            <Helmet>
                <title>知识助手 | 用户趋势</title>
            </Helmet>
            <div className="flex flex-col">
                {/* <TextInput className="w-[2.5rem] !py-1" styled={true} color={"violet"} value={days || ""} onChange={d => {
                    if (d) {
                        setDays(parseInt(d))
                    } else {
                        setDays(0)
                    }
                }}></TextInput> */}
                <div className="self-start">
                    <ToggleGroup color="violet" items={[
                        { label: "15", value: 15 },
                        { label: "30", value: 30 },
                        { label: "周", value: "周" },
                        { label: "月", value: "月" }
                    ]} value={days} onChange={setDays}>
                    </ToggleGroup>
                </div>
                {
                    renderRow(
                        days == "周" || days == "月" ? days : "日期",
                        ["新增用户", "活跃用户", "付费次数", "付费金额",
                        ],
                        "text-[var(--gray11)] !py-4 font-size-10"
                    )
                }
            </div>
            <div className="font-size-13 grow flex flex-col overflow-auto">
                {
                    (() => {
                        if (days == "周") {
                            return (
                                Array(15).fill(0).map((_, index) => {
                                    const w = dayjs().week() - index;
                                    const firstDay = dayjs().week(w).day(0).format("MM-DD");
                                    return renderRow(firstDay,
                                        [
                                            newTenants ? newTenants[index] : "-",
                                            newActiveTenants ? newActiveTenants[index] : "-",
                                            newPayments ? newPayments[index] : "-",
                                            paidAmounts ? paidAmounts[index] : "-",
                                        ]
                                    )
                                })
                            )
                        } else if (days == "月") {
                            return (
                                Array(7).fill(0).map((_, index) => {
                                    let m = dayjs().month() - index;
                                    m = m >= 0 ? m : 12 + m;
                                    return renderRow(m + 1,
                                        [
                                            newTenants ? newTenants[index] : "-",
                                            newActiveTenants ? newActiveTenants[index] : "-",
                                            newPayments ? newPayments[index] : "-",
                                            paidAmounts ? paidAmounts[index] : "-",
                                        ]
                                    )
                                })
                            )
                        } else {
                            return (
                                Array(days).fill(0).map((_, index) => {
                                    const d = (() => {
                                        if (index !== 0) {
                                            return dayjs().subtract(index, "day").format("MM-DD");
                                        } else {
                                            return dayjs().format("MM-DD");
                                        }
                                    })()
                                    return renderRow(d,
                                        [
                                            newTenants ? newTenants[index] : "-",
                                            newActiveTenants ? newActiveTenants[index] : "-",
                                            newPayments ? newPayments[index] : "-",
                                            paidAmounts ? paidAmounts[index] : "-",
                                        ]
                                    )
                                })
                            )
                        }

                    })()
                }
            </div>
        </div>
    )
}
