
import { useContext } from 'react';

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'


import { runDataQuery } from 'bwax/query/runClientQuery'
import qs from 'query-string';

export default function track (event, params = {}) {
    if(typeof(gtag) !== "undefined") {
        gtag("event", event, params);
    }
}


// react hooks
export function useTrack() {

    const dlc = useContext(DataLoaderContext);


    function __track__(event, params) {
        
        track(event, params);


        // track at local database

        // if(dlc.sandbox) {
        //     // don't track
        //     return;
        // }

        function getReferrer() {
            if (typeof (document) !== "undefined") {
                return document.referrer
            } else {
                return ""
            }
        }
    
        function getRK() {
            if (typeof (window) !== 'undefined') {
                const search = window.location.search;
                return qs.parse(search)._rk_
            } else {
                return undefined
            }
        }
    
        function getRKHeaders() {
            if (typeof (window) !== 'undefined') {
                const rk = getRK() || sessionStorage.getItem("_rk_");
                if (rk) {
                    return {
                        "X-RK": rk
                    }
                }
            }
            return {}
        }

        function getPath () {
            if (typeof (window) !== 'undefined') {
                const reg = /^(\/sandbox(\/-\/[^\/]+)?)\/.+/;

                const pathname = window.location.pathname;
                const m = pathname.match(reg);

                if(m) {
                    const prefix = m[1];
                    return pathname.substring(prefix.length);
                } else {
                    return pathname;
                }

            }
            return ""
        };

        try {
            const path = getPath();
            const queryRunner = runDataQuery({
                ...dlc,
                headers: {
                    "X-Referrer": getReferrer(),
                    ...getRKHeaders()
                }
            });
    
    
            queryRunner(Mut_addEvent)({
                input: {
                    path,
                    name: event,
                    params,
                    clientMutationId: "" + Date.now()
                }
            })

        } catch (error) {
            console.error(error);
        }
    

    }

    return __track__

}


const Mut_addEvent = `
mutation ($input: AddPageStatEntityUserEventInput!) {
  addPageStatEntityUserEvent (input: $input) {
    clientMutationId
  }
}
`