import React from 'react'

class DiscountsIcon extends React.Component {

    render() {
        return (
            <svg width="1em" height="1em" viewBox="0 0 10 12">
                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Icon/VIP-services/产品" transform="translate(-3.000000, -2.000000)" fill="#FFFFFF" fillRule="nonzero">
                        <g transform="translate(-0.000000, 0.000000)" id="Combined-Shape">
                            <path d="M3.48851677,4.09833997 L3.48851677,4.48554925 L4.8743869,5.48029213 L4.8743869,11.8021133 L3.39268859,12.8722877 L3.7288183,12.9657966 L5.08097481,12.0290481 L6.28524091,13.5790499 L6.795101,13.712685 L5.3326173,11.8510357 L5.33592536,11.8510357 L5.33592536,5.5803616 L6.86459703,5.13060078 L6.86459703,14 L3.18160039,13.0792508 L3.18160039,3.87175922 L3.48851677,4.09833997 Z M9.82233172,3.99213616 L9.82233172,2.1881024 L9.36079326,2.1881024 L9.36079326,4.12265204 L5.15313006,5.31251355 L3.18160039,3.87175922 L9.72464842,2 L11.9594091,3.34897985 L11.5827036,3.49433046 L9.82233172,3.99213616 Z M6.82047291,4.80313018 L12.8183996,3.00249607 L12.8183996,12.1236872 L6.86459703,14 L6.795101,4.80981912 L6.82047291,4.80313018 Z M10.9023023,5.09942477 L10.9023023,8.16917106 L10.0297863,9.16097449 L9.13434967,8.16757898 L9.13434967,5.64659849 L8.91341387,5.64659849 L8.67397509,5.64659849 L8.67397509,8.34444396 L10.0339681,9.8532173 L11.3626768,8.34285188 L11.3626768,5.09942477 L10.9023023,5.09942477 Z"></path>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export default DiscountsIcon
