

import React from 'react'
import { TbTextCaption } from "react-icons/tb";

export default function CaptionButton({ setCaptionInputVisible }) {

    function showCaptionInput () {
        setCaptionInputVisible(true)
    }

    return (
        <button className="editor-button" onClick={() => showCaptionInput()}>
            <TbTextCaption style={{ fontSize: 14 }}/>
        </button>
    )
}
