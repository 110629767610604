// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var $$String = require("bs-platform/lib/js/string.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Dict$BwaxMobile = require("../dict.bs.js");
var Json$BwaxMobile = require("../utils/json.bs.js");
var Plate$BwaxMobile = require("../plate.bs.js");

function string_of_literal(l) {
  if (typeof l === "number") {
    switch (l) {
      case /* NaN */0 :
          return "NaN";
      case /* Infinity */1 :
          return "Infinity";
      case /* NegInfinity */2 :
          return "-Infinity";
      
    }
  } else {
    switch (l.tag | 0) {
      case /* Int */0 :
          return String(l[0]);
      case /* Float */1 :
          return l[0].toString();
      case /* String */2 :
          return "\"" + (l[0] + "\"");
      case /* Char */3 :
          return "'" + ($$String.make(1, l[0]) + "'");
      case /* Bool */4 :
          if (l[0]) {
            return "True";
          } else {
            return "False";
          }
      
    }
  }
}

function string_of_tuple(strs) {
  return "(" + (Plate$BwaxMobile.Str.join(", ", strs) + ")");
}

function string_of_list(strs) {
  return "[" + (Plate$BwaxMobile.Str.join(", ", strs) + "]");
}

function string_of_value(param) {
  var va = param[0];
  if (typeof va === "number") {
    switch (va) {
      case /* V_unit */0 :
          return "()";
      case /* V_todo */1 :
          return "#todo";
      case /* V_external */2 :
          return "#external";
      
    }
  } else {
    switch (va.tag | 0) {
      case /* V_tuple */0 :
          return string_of_tuple(Plate$BwaxMobile.List.map(string_of_value, va[0]));
      case /* V_list */1 :
          return string_of_list(Plate$BwaxMobile.List.map(string_of_value, va[0]));
      case /* V_record */2 :
          return "{ " + (Plate$BwaxMobile.Str.join(", ", Plate$BwaxMobile.List.map((function (param) {
                              return param[0] + (" = " + string_of_value(param[1]));
                            }), va[0])) + " }");
      case /* V_closure */3 :
          var module_name = Plate$BwaxMobile.$$Option.with_default("", va[2]);
          return module_name + "#closure";
      case /* V_native_fn */4 :
          return "#native_function";
      case /* V_literal */5 :
          return string_of_literal(va[0]);
      case /* V_tagged */6 :
          var vals = va[1];
          var name = va[0];
          if (Plate$BwaxMobile.List.length(vals) === 0) {
            return name;
          } else {
            return name + (" " + Plate$BwaxMobile.Str.join(" ", Plate$BwaxMobile.List.map(string_of_value, vals)));
          }
      case /* V_raw */7 :
          return "#raw(" + (JSON.stringify(va[0]) + ")");
      case /* V_tagged_raw */8 :
          return "#" + (va[0] + ("(" + (JSON.stringify(va[1]) + ")")));
      case /* V_tagged_number */9 :
          return "#" + (va[0] + ("(" + (va[1].toString() + ")")));
      case /* V_tagged_string */10 :
          return "#" + (va[0] + ("(" + (va[1] + ")")));
      case /* V_sum_type */11 :
          return "#variants(" + (Plate$BwaxMobile.Str.join("|", va[0]) + ")");
      case /* V_array */12 :
          return "[| " + (Plate$BwaxMobile.Str.join(", ", Plate$BwaxMobile.List.map(string_of_value, Curry._1(Plate$BwaxMobile.List.from_array, va[0]))) + " |]");
      case /* V_decorated */13 :
          return "#decorated(" + (string_of_value(va[1]) + ")");
      case /* V_executable */14 :
          return "#executable::" + va[0];
      
    }
  }
}

function string_of_pattern(param) {
  var ptn = param[0];
  if (typeof ptn === "number") {
    switch (ptn) {
      case /* Ptn_wildcard */0 :
          return "_";
      case /* Ptn_unit */1 :
          return "()";
      case /* Ptn_nil_list */2 :
          return "[]";
      
    }
  } else {
    switch (ptn.tag | 0) {
      case /* Ptn_var */0 :
          return ptn[0];
      case /* Ptn_constructor */1 :
          var br = function (p) {
            return "(" + (string_of_pattern(p) + ")");
          };
          return ptn[0] + (" " + Plate$BwaxMobile.Str.join(" ", Plate$BwaxMobile.List.map(br, ptn[1])));
      case /* Ptn_tuple */2 :
          return "(" + (Plate$BwaxMobile.Str.join(", ", Plate$BwaxMobile.List.map(string_of_pattern, ptn[0])) + ")");
      case /* Ptn_literal */3 :
          return string_of_literal(ptn[0]);
      case /* Ptn_cons_list */4 :
          return string_of_pattern(ptn[0]) + (" :: " + string_of_pattern(ptn[1]));
      case /* Ptn_record */5 :
          return "{ " + (Plate$BwaxMobile.Str.join(", ", ptn[0]) + " }");
      
    }
  }
}

function val_equal(_param, _param$1) {
  while(true) {
    var param = _param$1;
    var param$1 = _param;
    var r = param[0];
    var l = param$1[0];
    var combine_and_compare = function (lvs, rvs) {
      return Plate$BwaxMobile.List.every((function (param) {
                    return val_equal(param[0], param[1]);
                  }), Plate$BwaxMobile.List.combine(lvs, rvs));
    };
    var list_equal = function (lvs, rvs) {
      if (Plate$BwaxMobile.List.length(lvs) !== Plate$BwaxMobile.List.length(rvs)) {
        return false;
      } else {
        return combine_and_compare(lvs, rvs);
      }
    };
    if (typeof l !== "number") {
      switch (l.tag | 0) {
        case /* V_tuple */0 :
            if (typeof r === "number") {
              return Caml_obj.caml_equal(l, r);
            } else {
              switch (r.tag | 0) {
                case /* V_tuple */0 :
                    return list_equal(l[0], r[0]);
                case /* V_tagged */6 :
                    break;
                default:
                  return Caml_obj.caml_equal(l, r);
              }
            }
            break;
        case /* V_list */1 :
            if (typeof r === "number") {
              return Caml_obj.caml_equal(l, r);
            } else {
              switch (r.tag | 0) {
                case /* V_list */1 :
                    return list_equal(l[0], r[0]);
                case /* V_tagged */6 :
                    break;
                default:
                  return Caml_obj.caml_equal(l, r);
              }
            }
            break;
        case /* V_record */2 :
            var lfs = l[0];
            if (typeof r === "number") {
              return Caml_obj.caml_equal(l, r);
            } else {
              switch (r.tag | 0) {
                case /* V_record */2 :
                    var rfs = r[0];
                    if (Plate$BwaxMobile.List.length(lfs) !== Plate$BwaxMobile.List.length(rfs)) {
                      return false;
                    } else {
                      var l_sorted = Plate$BwaxMobile.List.sort_by_key(lfs);
                      var r_sorted = Plate$BwaxMobile.List.sort_by_key(rfs);
                      return Plate$BwaxMobile.List.every((function (param) {
                                    var match = param[1];
                                    var match$1 = param[0];
                                    if (match$1[0] === match[0]) {
                                      return val_equal(match$1[1], match[1]);
                                    } else {
                                      return false;
                                    }
                                  }), Plate$BwaxMobile.List.combine(l_sorted, r_sorted));
                    }
                case /* V_tagged */6 :
                    break;
                default:
                  return Caml_obj.caml_equal(l, r);
              }
            }
            break;
        case /* V_literal */5 :
            var match = l[0];
            if (typeof match !== "number") {
              switch (match.tag | 0) {
                case /* Int */0 :
                    if (typeof r === "number") {
                      return Caml_obj.caml_equal(l, r);
                    } else {
                      switch (r.tag | 0) {
                        case /* V_literal */5 :
                            var match$1 = r[0];
                            if (typeof match$1 === "number" || match$1.tag !== /* Float */1) {
                              return Caml_obj.caml_equal(l, r);
                            } else {
                              return match[0] === match$1[0];
                            }
                        case /* V_tagged */6 :
                            break;
                        default:
                          return Caml_obj.caml_equal(l, r);
                      }
                    }
                    break;
                case /* Float */1 :
                    if (typeof r === "number") {
                      return Caml_obj.caml_equal(l, r);
                    } else {
                      switch (r.tag | 0) {
                        case /* V_literal */5 :
                            var match$2 = r[0];
                            if (typeof match$2 === "number" || match$2.tag) {
                              return Caml_obj.caml_equal(l, r);
                            } else {
                              return match$2[0] === match[0];
                            }
                        case /* V_tagged */6 :
                            break;
                        default:
                          return Caml_obj.caml_equal(l, r);
                      }
                    }
                    break;
                case /* String */2 :
                    if (typeof r === "number") {
                      return Caml_obj.caml_equal(l, r);
                    } else {
                      switch (r.tag | 0) {
                        case /* V_literal */5 :
                            var match$3 = r[0];
                            if (typeof match$3 === "number" || match$3.tag !== /* String */2) {
                              return Caml_obj.caml_equal(l, r);
                            } else {
                              return match[0] === match$3[0];
                            }
                        case /* V_tagged */6 :
                            break;
                        default:
                          return Caml_obj.caml_equal(l, r);
                      }
                    }
                    break;
                case /* Bool */4 :
                    if (typeof r === "number") {
                      return Caml_obj.caml_equal(l, r);
                    } else {
                      switch (r.tag | 0) {
                        case /* V_literal */5 :
                            var match$4 = r[0];
                            if (typeof match$4 === "number" || match$4.tag !== /* Bool */4) {
                              return Caml_obj.caml_equal(l, r);
                            } else {
                              return match[0] === match$4[0];
                            }
                        case /* V_tagged */6 :
                            break;
                        default:
                          return Caml_obj.caml_equal(l, r);
                      }
                    }
                    break;
                default:
                  
              }
            }
            break;
        case /* V_tagged */6 :
            var ln = l[0];
            var exit = 0;
            if (typeof r === "number" || r.tag !== /* V_tagged */6) {
              exit = 2;
            } else if (ln === r[0]) {
              return combine_and_compare(l[1], r[1]);
            } else {
              return false;
            }
            if (exit === 2 && ln === "Just") {
              var match$5 = l[1];
              if (match$5 && !match$5[1]) {
                _param$1 = /* tuple */[
                  r,
                  param[1]
                ];
                _param = match$5[0];
                continue ;
              } else {
                return Caml_obj.caml_equal(l, r);
              }
            }
            break;
        case /* V_tagged_raw */8 :
            if (typeof r === "number") {
              return Caml_obj.caml_equal(l, r);
            } else {
              switch (r.tag | 0) {
                case /* V_tagged */6 :
                    break;
                case /* V_tagged_raw */8 :
                    if (l[0] === r[0]) {
                      return Caml_obj.caml_equal(l[1], r[1]);
                    } else {
                      return false;
                    }
                default:
                  return Caml_obj.caml_equal(l, r);
              }
            }
            break;
        case /* V_tagged_number */9 :
            if (typeof r === "number") {
              return Caml_obj.caml_equal(l, r);
            } else {
              switch (r.tag | 0) {
                case /* V_tagged */6 :
                    break;
                case /* V_tagged_number */9 :
                    if (l[0] === r[0]) {
                      return l[1] === r[1];
                    } else {
                      return false;
                    }
                default:
                  return Caml_obj.caml_equal(l, r);
              }
            }
            break;
        case /* V_tagged_string */10 :
            if (typeof r === "number") {
              return Caml_obj.caml_equal(l, r);
            } else {
              switch (r.tag | 0) {
                case /* V_tagged */6 :
                    break;
                case /* V_tagged_string */10 :
                    if (l[0] === r[0]) {
                      return l[1] === r[1];
                    } else {
                      return false;
                    }
                default:
                  return Caml_obj.caml_equal(l, r);
              }
            }
            break;
        case /* V_array */12 :
            if (typeof r === "number") {
              return Caml_obj.caml_equal(l, r);
            } else {
              switch (r.tag | 0) {
                case /* V_tagged */6 :
                    break;
                case /* V_array */12 :
                    return list_equal(Curry._1(Plate$BwaxMobile.List.from_array, l[0]), Curry._1(Plate$BwaxMobile.List.from_array, r[0]));
                default:
                  return Caml_obj.caml_equal(l, r);
              }
            }
            break;
        default:
          
      }
    }
    if (typeof r === "number" || !(r.tag === /* V_tagged */6 && r[0] === "Just")) {
      return Caml_obj.caml_equal(l, r);
    } else {
      var match$6 = r[1];
      if (match$6 && !match$6[1]) {
        _param$1 = match$6[0];
        _param = /* tuple */[
          l,
          param$1[1]
        ];
        continue ;
      } else {
        return Caml_obj.caml_equal(l, r);
      }
    }
  };
}

var Eval_exn = Caml_exceptions.create("Lang_eval-BwaxMobile.Eval_exn");

function compare_value(va, vb) {
  var b = vb[0];
  var a = va[0];
  var compare_list = function (l1, l2) {
    var combined = Plate$BwaxMobile.List.combine(l1, l2);
    var compared = Plate$BwaxMobile.List.foldl((function (acc, param) {
            if (acc !== 0) {
              return acc;
            } else {
              return compare_value(param[0], param[1]);
            }
          }), 0, combined);
    if (compared !== 0) {
      return compared;
    } else {
      return Caml_primitive.caml_int_compare(Plate$BwaxMobile.List.length(l1), Plate$BwaxMobile.List.length(l2));
    }
  };
  var exit = 0;
  var exit$1 = 0;
  if (typeof a === "number") {
    exit$1 = 3;
  } else {
    switch (a.tag | 0) {
      case /* V_tuple */0 :
          if (typeof b !== "number") {
            switch (b.tag | 0) {
              case /* V_tuple */0 :
                  return compare_list(a[0], b[0]);
              case /* V_literal */5 :
                  exit$1 = 3;
                  break;
              default:
                
            }
          }
          break;
      case /* V_list */1 :
          if (typeof b !== "number") {
            switch (b.tag | 0) {
              case /* V_list */1 :
                  return compare_list(a[0], b[0]);
              case /* V_literal */5 :
                  exit$1 = 3;
                  break;
              default:
                
            }
          }
          break;
      case /* V_literal */5 :
          var match = a[0];
          if (typeof match === "number") {
            switch (match) {
              case /* NaN */0 :
                  exit$1 = 3;
                  break;
              case /* Infinity */1 :
                  if (typeof b === "number" || b.tag !== /* V_literal */5) {
                    return 1;
                  } else {
                    var match$1 = b[0];
                    if (typeof match$1 === "number" && match$1 === 1) {
                      return 0;
                    } else {
                      return 1;
                    }
                  }
              case /* NegInfinity */2 :
                  if (typeof b === "number" || b.tag !== /* V_literal */5) {
                    exit = 2;
                  } else {
                    var match$2 = b[0];
                    if (typeof match$2 === "number") {
                      switch (match$2) {
                        case /* NaN */0 :
                            exit = 2;
                            break;
                        case /* Infinity */1 :
                            exit$1 = 3;
                            break;
                        case /* NegInfinity */2 :
                            return 0;
                        
                      }
                    } else {
                      exit = 2;
                    }
                  }
                  break;
              
            }
          } else {
            switch (match.tag | 0) {
              case /* Int */0 :
                  if (typeof b !== "number" && b.tag === /* V_literal */5) {
                    var match$3 = b[0];
                    var i = match[0];
                    if (typeof match$3 === "number") {
                      switch (match$3) {
                        case /* Infinity */1 :
                        case /* NegInfinity */2 :
                            exit$1 = 3;
                            break;
                        default:
                          
                      }
                    } else {
                      switch (match$3.tag | 0) {
                        case /* Int */0 :
                            return Caml_primitive.caml_int_compare(i, match$3[0]);
                        case /* Float */1 :
                            return Caml_primitive.caml_float_compare(i, match$3[0]);
                        default:
                          
                      }
                    }
                  }
                  break;
              case /* Float */1 :
                  if (typeof b !== "number" && b.tag === /* V_literal */5) {
                    var match$4 = b[0];
                    var f = match[0];
                    if (typeof match$4 === "number") {
                      switch (match$4) {
                        case /* Infinity */1 :
                        case /* NegInfinity */2 :
                            exit$1 = 3;
                            break;
                        default:
                          
                      }
                    } else {
                      switch (match$4.tag | 0) {
                        case /* Int */0 :
                            return Caml_primitive.caml_float_compare(f, match$4[0]);
                        case /* Float */1 :
                            return Caml_primitive.caml_float_compare(f, match$4[0]);
                        default:
                          
                      }
                    }
                  }
                  break;
              case /* String */2 :
                  if (typeof b !== "number" && b.tag === /* V_literal */5) {
                    var match$5 = b[0];
                    if (typeof match$5 === "number") {
                      switch (match$5) {
                        case /* Infinity */1 :
                        case /* NegInfinity */2 :
                            exit$1 = 3;
                            break;
                        default:
                          
                      }
                    } else if (match$5.tag === /* String */2) {
                      return Caml_primitive.caml_string_compare(match[0], match$5[0]);
                    }
                    
                  }
                  break;
              case /* Char */3 :
                  if (typeof b !== "number" && b.tag === /* V_literal */5) {
                    var match$6 = b[0];
                    if (typeof match$6 === "number") {
                      switch (match$6) {
                        case /* Infinity */1 :
                        case /* NegInfinity */2 :
                            exit$1 = 3;
                            break;
                        default:
                          
                      }
                    } else if (match$6.tag === /* Char */3) {
                      return Caml_primitive.caml_int_compare(match[0], match$6[0]);
                    }
                    
                  }
                  break;
              case /* Bool */4 :
                  exit$1 = 3;
                  break;
              
            }
          }
          break;
      default:
        exit$1 = 3;
    }
  }
  if (exit$1 === 3) {
    if (typeof b === "number" || b.tag !== /* V_literal */5) {
      exit = 2;
    } else {
      var match$7 = b[0];
      if (typeof match$7 === "number") {
        switch (match$7) {
          case /* NaN */0 :
              exit = 2;
              break;
          case /* Infinity */1 :
              return -1;
          case /* NegInfinity */2 :
              return 1;
          
        }
      } else {
        exit = 2;
      }
    }
  }
  if (exit === 2 && typeof a !== "number" && a.tag === /* V_literal */5) {
    var match$8 = a[0];
    if (typeof match$8 === "number" && match$8 >= 2) {
      return -1;
    }
    
  }
  throw [
        Eval_exn,
        "Cannot compare `" + (string_of_value(va) + ("` with `" + string_of_value(vb)))
      ];
}

function make_getter(name) {
  var f = function (args) {
    if (args) {
      var v = args[0];
      var match = v[0];
      if (typeof match !== "number" && match.tag === /* V_record */2 && !args[1]) {
        var match$1 = Plate$BwaxMobile.List.assoc(name, match[0]);
        if (match$1 !== undefined) {
          return match$1;
        } else {
          var str = string_of_value(v);
          throw [
                Eval_exn,
                "No such field " + (name + (" in record: " + str))
              ];
        }
      }
      
    }
    throw [
          Eval_exn,
          "Getter can only perform on record, rather than: [" + (Plate$BwaxMobile.Str.join("; ", Plate$BwaxMobile.List.map(string_of_value, args)) + "]")
        ];
  };
  return /* V_native_fn */Block.__(4, [
            1,
            f,
            /* [] */0
          ]);
}

function arith_func(param) {
  switch (param) {
    case /* Pow */0 :
        return /* tuple */[
                (function (l, r, packer) {
                    return Curry._1(packer, Math.pow(l, r));
                  }),
                (function (l, r, packer) {
                    return Curry._1(packer, Math.pow(l, r));
                  })
              ];
    case /* Mul */1 :
        return /* tuple */[
                (function (l, r, packer) {
                    return Curry._1(packer, Caml_int32.imul(l, r));
                  }),
                (function (l, r, packer) {
                    return Curry._1(packer, l * r);
                  })
              ];
    case /* Div */2 :
        return /* tuple */[
                (function (l, r, packer) {
                    if (r === 0) {
                      if (l === 0) {
                        return /* NaN */0;
                      } else if (l > 0) {
                        return /* Infinity */1;
                      } else {
                        return /* NegInfinity */2;
                      }
                    } else {
                      return Curry._1(packer, Caml_int32.div(l, r));
                    }
                  }),
                (function (l, r, packer) {
                    if (r === 0.0) {
                      if (l === 0) {
                        return /* NaN */0;
                      } else if (l > 0) {
                        return /* Infinity */1;
                      } else {
                        return /* NegInfinity */2;
                      }
                    } else {
                      return Curry._1(packer, l / r);
                    }
                  })
              ];
    case /* Mod */3 :
        return /* tuple */[
                (function (l, r, packer) {
                    return Curry._1(packer, Caml_int32.mod_(l, r));
                  }),
                (function (l, r, packer) {
                    return Curry._1(packer, Caml_int32.mod_(l | 0, r | 0));
                  })
              ];
    case /* Add */4 :
        return /* tuple */[
                (function (l, r, packer) {
                    return Curry._1(packer, l + r | 0);
                  }),
                (function (l, r, packer) {
                    return Curry._1(packer, l + r);
                  })
              ];
    case /* Sub */5 :
        return /* tuple */[
                (function (l, r, packer) {
                    return Curry._1(packer, l - r | 0);
                  }),
                (function (l, r, packer) {
                    return Curry._1(packer, l - r);
                  })
              ];
    
  }
}

function match_pattern(value, _param) {
  while(true) {
    var param = _param;
    var ptn = param[0];
    var combine_and_match = function (ptns, vals) {
      return Plate$BwaxMobile.List.foldl((function (acc, param) {
                    var v = param[1];
                    var p = param[0];
                    var f = function (matches) {
                      var match = match_pattern(v, p);
                      if (match) {
                        return /* Success */[Pervasives.$at(matches, match[0])];
                      } else {
                        return /* Failure */0;
                      }
                    };
                    var param$1 = acc;
                    if (param$1) {
                      return Curry._1(f, param$1[0]);
                    } else {
                      return /* Failure */0;
                    }
                  }), /* Success */[/* [] */0], Plate$BwaxMobile.List.combine(ptns, vals));
    };
    if (typeof ptn === "number") {
      switch (ptn) {
        case /* Ptn_wildcard */0 :
            return /* Success */[/* [] */0];
        case /* Ptn_unit */1 :
            var match = value[0];
            if (typeof match === "number" && match === 0) {
              return /* Success */[/* [] */0];
            } else {
              return /* Failure */0;
            }
        case /* Ptn_nil_list */2 :
            var match$1 = value[0];
            if (typeof match$1 === "number" || !(match$1.tag === /* V_list */1 && !match$1[0])) {
              return /* Failure */0;
            } else {
              return /* Success */[/* [] */0];
            }
        
      }
    } else {
      switch (ptn.tag | 0) {
        case /* Ptn_var */0 :
            return /* Success */[/* :: */[
                      /* tuple */[
                        ptn[0],
                        value
                      ],
                      /* [] */0
                    ]];
        case /* Ptn_constructor */1 :
            var name = ptn[0];
            var match$2 = value[0];
            if (typeof match$2 !== "number" && match$2.tag === /* V_tagged */6) {
              var tag_name = match$2[0];
              if (name === tag_name || Plate$BwaxMobile.Str.ends_with("." + tag_name, name)) {
                return combine_and_match(ptn[1], match$2[1]);
              } else {
                return /* Failure */0;
              }
            }
            if (name === "Just") {
              var match$3 = ptn[1];
              if (match$3 && !match$3[1]) {
                _param = match$3[0];
                continue ;
              } else {
                return /* Failure */0;
              }
            } else {
              return /* Failure */0;
            }
            break;
        case /* Ptn_tuple */2 :
            var match$4 = value[0];
            if (typeof match$4 === "number" || match$4.tag) {
              return /* Failure */0;
            } else {
              return combine_and_match(ptn[0], match$4[0]);
            }
        case /* Ptn_literal */3 :
            var match$5 = value[0];
            if (typeof match$5 === "number" || !(match$5.tag === /* V_literal */5 && val_equal(/* tuple */[
                      /* V_literal */Block.__(5, [ptn[0]]),
                      /* () */0
                    ], /* tuple */[
                      /* V_literal */Block.__(5, [match$5[0]]),
                      /* () */0
                    ]))) {
              return /* Failure */0;
            } else {
              return /* Success */[/* [] */0];
            }
        case /* Ptn_cons_list */4 :
            var match$6 = value[0];
            if (typeof match$6 === "number" || match$6.tag !== /* V_list */1) {
              return /* Failure */0;
            } else {
              var match$7 = match$6[0];
              if (match$7) {
                var ptns_000 = ptn[0];
                var ptns_001 = /* :: */[
                  ptn[1],
                  /* [] */0
                ];
                var ptns = /* :: */[
                  ptns_000,
                  ptns_001
                ];
                var vals_000 = match$7[0];
                var vals_001 = /* :: */[
                  /* tuple */[
                    /* V_list */Block.__(1, [match$7[1]]),
                    value[1]
                  ],
                  /* [] */0
                ];
                var vals = /* :: */[
                  vals_000,
                  vals_001
                ];
                return combine_and_match(ptns, vals);
              } else {
                return /* Failure */0;
              }
            }
        case /* Ptn_record */5 :
            var match$8 = value[0];
            if (typeof match$8 === "number" || match$8.tag !== /* V_record */2) {
              return /* Failure */0;
            } else {
              var binds = match$8[0];
              return Plate$BwaxMobile.List.foldr((function(binds){
                        return function (n, acc) {
                          if (acc) {
                            var match = Plate$BwaxMobile.List.assoc(n, binds);
                            if (match !== undefined) {
                              return /* Success */[/* :: */[
                                        /* tuple */[
                                          n,
                                          match
                                        ],
                                        acc[0]
                                      ]];
                            } else {
                              return /* Failure */0;
                            }
                          } else {
                            return /* Failure */0;
                          }
                        }
                        }(binds)), ptn[0], /* Success */[/* [] */0]);
            }
        
      }
    }
  };
}

function evaluate(_env, maybe_module, _expr) {
  while(true) {
    var expr = _expr;
    var env = _env;
    var info = expr[1];
    var alt = expr[0];
    var eval_it = (function(env){
    return function eval_it(param) {
      return evaluate(env, maybe_module, param);
    }
    }(env));
    var get_ref = function (name, env) {
      var get_from_holder = function (holder) {
        var match = holder[0];
        var v = match[0];
        var match$1 = v[0];
        if (typeof match$1 === "number" && match$1 === 1) {
          throw [
                Eval_exn,
                "" + (String(name) + " is not initialized")
              ];
        } else {
          return v;
        }
      };
      var not_found = function (param) {
        console.log("Error: No such variable `" + (String(name) + "`"), Dict$BwaxMobile.$$String.to_array(env), maybe_module);
        throw [
              Eval_exn,
              "No such variable `" + (String(name) + "`")
            ];
      };
      var match = Dict$BwaxMobile.$$String.get(name, env);
      if (match !== undefined) {
        return get_from_holder(match);
      } else if (maybe_module !== undefined) {
        var match$1 = Dict$BwaxMobile.$$String.get(maybe_module + ("." + name), env);
        if (match$1 !== undefined) {
          return get_from_holder(match$1);
        } else {
          return not_found(/* () */0);
        }
      } else {
        return not_found(/* () */0);
      }
    };
    if (typeof alt === "number") {
      if (alt === /* Unit */0) {
        return /* tuple */[
                /* V_unit */0,
                info
              ];
      } else {
        return /* tuple */[
                /* V_external */2,
                info
              ];
      }
    } else {
      switch (alt.tag | 0) {
        case /* Tuple */0 :
            var vals = Plate$BwaxMobile.List.map(eval_it, alt[0]);
            return /* tuple */[
                    /* V_tuple */Block.__(0, [vals]),
                    info
                  ];
        case /* Record */2 :
            var all = Plate$BwaxMobile.List.assoc_map(eval_it, alt[0]);
            return /* tuple */[
                    /* V_record */Block.__(2, [all]),
                    info
                  ];
        case /* Record_upd */3 :
            var name = alt[0];
            var v = get_ref(name, env);
            var match = v[0];
            var exit = 0;
            if (typeof match === "number" || match.tag !== /* V_record */2) {
              exit = 2;
            } else {
              var all$1 = Plate$BwaxMobile.List.assoc_map(eval_it, alt[1]);
              return /* tuple */[
                      /* V_record */Block.__(2, [Plate$BwaxMobile.List.assoc_merge(match[0], all$1)]),
                      info
                    ];
            }
            if (exit === 2) {
              throw [
                    Eval_exn,
                    "" + (String(name) + " is not a record")
                  ];
            }
            break;
        case /* Record_get */4 :
            var match$1 = make_getter(alt[1]);
            if (typeof match$1 === "number") {
              throw [
                    Eval_exn,
                    "Getter should be a fn"
                  ];
            } else if (match$1.tag === /* V_native_fn */4) {
              var arg = evaluate(env, maybe_module, alt[0]);
              return apply_native(match$1[0], match$1[1], match$1[2], arg, info);
            } else {
              throw [
                    Eval_exn,
                    "Getter should be a fn"
                  ];
            }
        case /* Getter */5 :
            return /* tuple */[
                    make_getter(alt[0]),
                    info
                  ];
        case /* Constructor */6 :
            var name$1 = alt[0];
            var match$2 = Dict$BwaxMobile.$$String.get(name$1, env);
            if (match$2 !== undefined) {
              return Plate$BwaxMobile.fst(match$2[0]);
            } else {
              var msg = "I can\'t find such variant: " + (String(name$1) + "");
              console.log("Env", Dict$BwaxMobile.$$String.to_array(env));
              throw [
                    Eval_exn,
                    msg
                  ];
            }
        case /* Apply */7 :
            return apply_expr(env, maybe_module, alt[0], alt[1], info);
        case /* Unop */8 :
            var v$1 = evaluate(env, maybe_module, alt[1]);
            var match$3 = v$1[0];
            if (typeof match$3 === "number") {
              throw [
                    Eval_exn,
                    "not a boolean"
                  ];
            } else if (match$3.tag === /* V_literal */5) {
              var match$4 = match$3[0];
              if (typeof match$4 === "number") {
                throw [
                      Eval_exn,
                      "not a boolean"
                    ];
              } else if (match$4.tag === /* Bool */4) {
                return /* tuple */[
                        /* V_literal */Block.__(5, [/* Bool */Block.__(4, [!match$4[0]])]),
                        info
                      ];
              } else {
                throw [
                      Eval_exn,
                      "not a boolean"
                    ];
              }
            } else {
              throw [
                    Eval_exn,
                    "not a boolean"
                  ];
            }
        case /* Binop */9 :
            var match$5 = alt[0];
            if (typeof match$5 === "number") {
              if (match$5 === /* Cons */0) {
                var lv = evaluate(env, maybe_module, alt[1]);
                var rv = evaluate(env, maybe_module, alt[2]);
                var match$6 = rv[0];
                if (typeof match$6 === "number") {
                  throw [
                        Eval_exn,
                        "Invalid argument for `::`"
                      ];
                } else if (match$6.tag === /* V_list */1) {
                  return /* tuple */[
                          /* V_list */Block.__(1, [/* :: */[
                                lv,
                                match$6[0]
                              ]]),
                          info
                        ];
                } else {
                  throw [
                        Eval_exn,
                        "Invalid argument for `::`"
                      ];
                }
              } else {
                var lv$1 = evaluate(env, maybe_module, alt[1]);
                var rv$1 = evaluate(env, maybe_module, alt[2]);
                var match$7 = lv$1[0];
                if (typeof match$7 === "number") {
                  throw [
                        Eval_exn,
                        "Invalid argument for `++`"
                      ];
                } else {
                  switch (match$7.tag | 0) {
                    case /* V_list */1 :
                        var match$8 = rv$1[0];
                        if (typeof match$8 === "number") {
                          throw [
                                Eval_exn,
                                "Invalid argument for `++`"
                              ];
                        } else if (match$8.tag === /* V_list */1) {
                          return /* tuple */[
                                  /* V_list */Block.__(1, [Pervasives.$at(match$7[0], match$8[0])]),
                                  info
                                ];
                        } else {
                          throw [
                                Eval_exn,
                                "Invalid argument for `++`"
                              ];
                        }
                    case /* V_literal */5 :
                        var match$9 = match$7[0];
                        if (typeof match$9 === "number") {
                          throw [
                                Eval_exn,
                                "Invalid argument for `++`"
                              ];
                        } else if (match$9.tag === /* String */2) {
                          var match$10 = rv$1[0];
                          if (typeof match$10 === "number") {
                            throw [
                                  Eval_exn,
                                  "Invalid argument for `++`"
                                ];
                          } else if (match$10.tag === /* V_literal */5) {
                            var match$11 = match$10[0];
                            if (typeof match$11 === "number") {
                              throw [
                                    Eval_exn,
                                    "Invalid argument for `++`"
                                  ];
                            } else if (match$11.tag === /* String */2) {
                              return /* tuple */[
                                      /* V_literal */Block.__(5, [/* String */Block.__(2, [match$9[0] + match$11[0]])]),
                                      info
                                    ];
                            } else {
                              throw [
                                    Eval_exn,
                                    "Invalid argument for `++`"
                                  ];
                            }
                          } else {
                            throw [
                                  Eval_exn,
                                  "Invalid argument for `++`"
                                ];
                          }
                        } else {
                          throw [
                                Eval_exn,
                                "Invalid argument for `++`"
                              ];
                        }
                    default:
                      throw [
                            Eval_exn,
                            "Invalid argument for `++`"
                          ];
                  }
                }
              }
            } else {
              switch (match$5.tag | 0) {
                case /* Arith */0 :
                    var op = match$5[0];
                    var match$12 = arith_func(op);
                    var f_op = match$12[1];
                    var i_op = match$12[0];
                    var as_int = function (result) {
                      return /* Int */Block.__(0, [result]);
                    };
                    var as_float = function (result) {
                      return /* Float */Block.__(1, [result]);
                    };
                    var fail = (function(info){
                    return function fail(v) {
                      throw [
                            Eval_exn,
                            "Arith: cannot execute on non-number, " + string_of_value(/* tuple */[
                                  /* V_literal */Block.__(5, [v]),
                                  info
                                ])
                          ];
                    }
                    }(info));
                    var is_zero = function (v) {
                      if (typeof v === "number") {
                        return fail(v);
                      } else {
                        switch (v.tag | 0) {
                          case /* Int */0 :
                              return v[0] === 0;
                          case /* Float */1 :
                              return v[0] === 0.0;
                          default:
                            return fail(v);
                        }
                      }
                    };
                    var is_one = function (v) {
                      if (typeof v === "number") {
                        return fail(v);
                      } else {
                        switch (v.tag | 0) {
                          case /* Int */0 :
                              var i = v[0];
                              if (i === 1) {
                                return true;
                              } else {
                                return i === -1;
                              }
                          case /* Float */1 :
                              var f = v[0];
                              if (f === 1.0) {
                                return true;
                              } else {
                                return f === -1.0;
                              }
                          default:
                            return fail(v);
                        }
                      }
                    };
                    var is_positive = function (v) {
                      if (typeof v === "number") {
                        return fail(v);
                      } else {
                        switch (v.tag | 0) {
                          case /* Int */0 :
                              return v[0] > 0;
                          case /* Float */1 :
                              return v[0] > 0.0;
                          default:
                            return fail(v);
                        }
                      }
                    };
                    var calc = (function(op,i_op,f_op){
                    return function calc(param) {
                      var r = param[0];
                      var exit = 0;
                      var exit$1 = 0;
                      var exit$2 = 0;
                      var exit$3 = 0;
                      if (typeof r === "number") {
                        switch (r) {
                          case /* NaN */0 :
                              return /* NaN */0;
                          case /* Infinity */1 :
                              var match = param[1];
                              if (typeof match === "number") {
                                switch (match) {
                                  case /* NaN */0 :
                                      exit$3 = 5;
                                      break;
                                  case /* Infinity */1 :
                                      if (op !== 4 && op >= 2) {
                                        return /* NaN */0;
                                      } else {
                                        return /* Infinity */1;
                                      }
                                  case /* NegInfinity */2 :
                                      switch (op) {
                                        case /* Pow */0 :
                                            return /* Int */Block.__(0, [0]);
                                        case /* Mul */1 :
                                            return /* NegInfinity */2;
                                        case /* Div */2 :
                                        case /* Mod */3 :
                                        case /* Add */4 :
                                            return /* NaN */0;
                                        case /* Sub */5 :
                                            return /* Infinity */1;
                                        
                                      }
                                  
                                }
                              } else {
                                exit$2 = 4;
                              }
                              break;
                          case /* NegInfinity */2 :
                              var match$1 = param[1];
                              if (typeof match$1 === "number") {
                                switch (match$1) {
                                  case /* NaN */0 :
                                      exit$3 = 5;
                                      break;
                                  case /* Infinity */1 :
                                      switch (op) {
                                        case /* Pow */0 :
                                            return /* Infinity */1;
                                        case /* Div */2 :
                                        case /* Mod */3 :
                                        case /* Add */4 :
                                            return /* NaN */0;
                                        case /* Mul */1 :
                                        case /* Sub */5 :
                                            return /* NegInfinity */2;
                                        
                                      }
                                  case /* NegInfinity */2 :
                                      if (op !== 4 && op >= 2) {
                                        return /* NaN */0;
                                      } else {
                                        return /* NegInfinity */2;
                                      }
                                  
                                }
                              } else {
                                exit = 2;
                              }
                              break;
                          
                        }
                      } else {
                        switch (r.tag | 0) {
                          case /* Int */0 :
                              var match$2 = param[1];
                              var l = r[0];
                              if (typeof match$2 === "number") {
                                switch (match$2) {
                                  case /* NaN */0 :
                                      exit$3 = 5;
                                      break;
                                  case /* Infinity */1 :
                                      exit$1 = 3;
                                      break;
                                  case /* NegInfinity */2 :
                                      break;
                                  
                                }
                              } else {
                                switch (match$2.tag | 0) {
                                  case /* Int */0 :
                                      return Curry._3(i_op, l, match$2[0], as_int);
                                  case /* Float */1 :
                                      return Curry._3(f_op, l, match$2[0], as_float);
                                  default:
                                    throw [
                                          Eval_exn,
                                          "Arith: cannot execute on non-number"
                                        ];
                                }
                              }
                              break;
                          case /* Float */1 :
                              var match$3 = param[1];
                              var l$1 = r[0];
                              if (typeof match$3 === "number") {
                                switch (match$3) {
                                  case /* NaN */0 :
                                      exit$3 = 5;
                                      break;
                                  case /* Infinity */1 :
                                      exit$1 = 3;
                                      break;
                                  case /* NegInfinity */2 :
                                      break;
                                  
                                }
                              } else {
                                switch (match$3.tag | 0) {
                                  case /* Int */0 :
                                      return Curry._3(f_op, l$1, match$3[0], as_float);
                                  case /* Float */1 :
                                      return Curry._3(f_op, l$1, match$3[0], as_float);
                                  default:
                                    throw [
                                          Eval_exn,
                                          "Arith: cannot execute on non-number"
                                        ];
                                }
                              }
                              break;
                          default:
                            exit$3 = 5;
                        }
                      }
                      if (exit$3 === 5) {
                        var match$4 = param[1];
                        if (typeof match$4 === "number" && match$4 === 0) {
                          return /* NaN */0;
                        } else {
                          exit$2 = 4;
                        }
                      }
                      if (exit$2 === 4) {
                        if (typeof r === "number") {
                          var r$1 = param[1];
                          switch (op) {
                            case /* Pow */0 :
                                if (is_zero(r$1)) {
                                  return /* Int */Block.__(0, [1]);
                                } else if (is_positive(r$1)) {
                                  return /* Infinity */1;
                                } else {
                                  return /* Int */Block.__(0, [0]);
                                }
                            case /* Mul */1 :
                                if (is_zero(r$1)) {
                                  return /* NaN */0;
                                } else if (is_positive(r$1)) {
                                  return /* Infinity */1;
                                } else {
                                  return /* NegInfinity */2;
                                }
                            case /* Div */2 :
                                if (is_zero(r$1) || is_positive(r$1)) {
                                  return /* Infinity */1;
                                } else {
                                  return /* NegInfinity */2;
                                }
                            case /* Mod */3 :
                                return /* NaN */0;
                            case /* Add */4 :
                            case /* Sub */5 :
                                return /* Infinity */1;
                            
                          }
                        } else {
                          exit$1 = 3;
                        }
                      }
                      if (exit$1 === 3) {
                        var match$5 = param[1];
                        if (typeof match$5 === "number" && match$5 < 2) {
                          switch (op) {
                            case /* Pow */0 :
                                if (is_zero(r)) {
                                  return /* Int */Block.__(0, [0]);
                                } else if (is_one(r)) {
                                  return /* NaN */0;
                                } else {
                                  return /* Infinity */1;
                                }
                            case /* Mul */1 :
                                if (is_zero(r)) {
                                  return /* NaN */0;
                                } else if (is_positive(r)) {
                                  return /* Infinity */1;
                                } else {
                                  return /* NegInfinity */2;
                                }
                            case /* Div */2 :
                                return /* Int */Block.__(0, [0]);
                            case /* Mod */3 :
                                return /* NaN */0;
                            case /* Add */4 :
                                return /* Infinity */1;
                            case /* Sub */5 :
                                return /* NegInfinity */2;
                            
                          }
                        } else {
                          exit = 2;
                        }
                      }
                      if (exit === 2 && typeof r === "number") {
                        var r$2 = param[1];
                        switch (op) {
                          case /* Pow */0 :
                              if (is_zero(r$2)) {
                                return /* Int */Block.__(0, [1]);
                              } else if (is_positive(r$2)) {
                                return /* Infinity */1;
                              } else {
                                return /* Int */Block.__(0, [0]);
                              }
                          case /* Mul */1 :
                              if (is_zero(r$2)) {
                                return /* NaN */0;
                              } else if (is_positive(r$2)) {
                                return /* NegInfinity */2;
                              } else {
                                return /* Infinity */1;
                              }
                          case /* Div */2 :
                              if (is_zero(r$2) || is_positive(r$2)) {
                                return /* NegInfinity */2;
                              } else {
                                return /* Infinity */1;
                              }
                          case /* Mod */3 :
                              return /* NaN */0;
                          case /* Add */4 :
                          case /* Sub */5 :
                              return /* NegInfinity */2;
                          
                        }
                      }
                      if (typeof param[1] === "number") {
                        switch (op) {
                          case /* Pow */0 :
                              if (is_zero(r)) {
                                return /* Infinity */1;
                              } else if (is_one(r)) {
                                return /* NaN */0;
                              } else {
                                return /* Int */Block.__(0, [0]);
                              }
                          case /* Mul */1 :
                              if (is_zero(r)) {
                                return /* NaN */0;
                              } else if (is_positive(r)) {
                                return /* NegInfinity */2;
                              } else {
                                return /* Infinity */1;
                              }
                          case /* Div */2 :
                              return /* Int */Block.__(0, [0]);
                          case /* Mod */3 :
                              return /* NaN */0;
                          case /* Add */4 :
                              return /* NegInfinity */2;
                          case /* Sub */5 :
                              return /* Infinity */1;
                          
                        }
                      } else {
                        throw [
                              Eval_exn,
                              "Arith: cannot execute on non-number"
                            ];
                      }
                    }
                    }(op,i_op,f_op));
                    var lv$2 = evaluate(env, maybe_module, alt[1]);
                    var rv$2 = evaluate(env, maybe_module, alt[2]);
                    var match$13 = lv$2[0];
                    if (typeof match$13 === "number") {
                      throw [
                            Eval_exn,
                            "Arith: cannot execute on non-number"
                          ];
                    } else if (match$13.tag === /* V_literal */5) {
                      var match$14 = rv$2[0];
                      if (typeof match$14 === "number") {
                        throw [
                              Eval_exn,
                              "Arith: cannot execute on non-number"
                            ];
                      } else if (match$14.tag === /* V_literal */5) {
                        return /* tuple */[
                                /* V_literal */Block.__(5, [calc(/* tuple */[
                                          match$13[0],
                                          match$14[0]
                                        ])]),
                                info
                              ];
                      } else {
                        throw [
                              Eval_exn,
                              "Arith: cannot execute on non-number"
                            ];
                      }
                    } else {
                      throw [
                            Eval_exn,
                            "Arith: cannot execute on non-number"
                          ];
                    }
                case /* Equality */1 :
                    var op$1 = match$5[0];
                    var f = (function(op$1){
                    return function f(x, y) {
                      if (op$1) {
                        return !val_equal(x, y);
                      } else {
                        return val_equal(x, y);
                      }
                    }
                    }(op$1));
                    var lv$3 = evaluate(env, maybe_module, alt[1]);
                    var rv$3 = evaluate(env, maybe_module, alt[2]);
                    return /* tuple */[
                            /* V_literal */Block.__(5, [/* Bool */Block.__(4, [f(lv$3, rv$3)])]),
                            info
                          ];
                case /* Compare */2 :
                    var op$2 = match$5[0];
                    var lv$4 = evaluate(env, maybe_module, alt[1]);
                    var rv$4 = evaluate(env, maybe_module, alt[2]);
                    var match$15 = lv$4[0];
                    var exit$1 = 0;
                    var exit$2 = 0;
                    if (typeof match$15 === "number" || match$15.tag !== /* V_literal */5) {
                      exit$2 = 3;
                    } else {
                      var match$16 = match$15[0];
                      if (typeof match$16 === "number" && match$16 === 0) {
                        return /* tuple */[
                                /* V_literal */Block.__(5, [/* Bool */Block.__(4, [false])]),
                                info
                              ];
                      } else {
                        exit$2 = 3;
                      }
                    }
                    if (exit$2 === 3) {
                      var match$17 = rv$4[0];
                      if (typeof match$17 === "number" || match$17.tag !== /* V_literal */5) {
                        exit$1 = 2;
                      } else {
                        var match$18 = match$17[0];
                        if (typeof match$18 === "number" && match$18 === 0) {
                          return /* tuple */[
                                  /* V_literal */Block.__(5, [/* Bool */Block.__(4, [false])]),
                                  info
                                ];
                        } else {
                          exit$1 = 2;
                        }
                      }
                    }
                    if (exit$1 === 2) {
                      var f$1 = (function(op$2){
                      return function f$1(x, y) {
                        switch (op$2) {
                          case /* Gt */0 :
                              return compare_value(x, y) > 0;
                          case /* Ge */1 :
                              return compare_value(x, y) >= 0;
                          case /* Le */2 :
                              return compare_value(x, y) <= 0;
                          case /* Lt */3 :
                              return compare_value(x, y) < 0;
                          
                        }
                      }
                      }(op$2));
                      var result = f$1(lv$4, rv$4);
                      return /* tuple */[
                              /* V_literal */Block.__(5, [/* Bool */Block.__(4, [result])]),
                              info
                            ];
                    }
                    break;
                case /* Logic */3 :
                    var op$3 = match$5[0];
                    var f$2 = (function(op$3){
                    return function f$2(x, y) {
                      switch (op$3) {
                        case /* And */0 :
                            if (x) {
                              return y;
                            } else {
                              return false;
                            }
                        case /* Or */1 :
                            if (x) {
                              return true;
                            } else {
                              return y;
                            }
                        case /* Xor */2 :
                            if (x || y) {
                              return !(x && y);
                            } else {
                              return false;
                            }
                        
                      }
                    }
                    }(op$3));
                    var lv$5 = evaluate(env, maybe_module, alt[1]);
                    var rv$5 = evaluate(env, maybe_module, alt[2]);
                    var match$19 = lv$5[0];
                    if (typeof match$19 === "number") {
                      throw [
                            Eval_exn,
                            "Not boolean operands"
                          ];
                    } else if (match$19.tag === /* V_literal */5) {
                      var match$20 = match$19[0];
                      if (typeof match$20 === "number") {
                        throw [
                              Eval_exn,
                              "Not boolean operands"
                            ];
                      } else if (match$20.tag === /* Bool */4) {
                        var match$21 = rv$5[0];
                        if (typeof match$21 === "number") {
                          throw [
                                Eval_exn,
                                "Not boolean operands"
                              ];
                        } else if (match$21.tag === /* V_literal */5) {
                          var match$22 = match$21[0];
                          if (typeof match$22 === "number") {
                            throw [
                                  Eval_exn,
                                  "Not boolean operands"
                                ];
                          } else if (match$22.tag === /* Bool */4) {
                            return /* tuple */[
                                    /* V_literal */Block.__(5, [/* Bool */Block.__(4, [f$2(match$20[0], match$22[0])])]),
                                    info
                                  ];
                          } else {
                            throw [
                                  Eval_exn,
                                  "Not boolean operands"
                                ];
                          }
                        } else {
                          throw [
                                Eval_exn,
                                "Not boolean operands"
                              ];
                        }
                      } else {
                        throw [
                              Eval_exn,
                              "Not boolean operands"
                            ];
                      }
                    } else {
                      throw [
                            Eval_exn,
                            "Not boolean operands"
                          ];
                    }
                case /* Custom */4 :
                    throw [
                          Eval_exn,
                          "not impl"
                        ];
                
              }
            }
            break;
        case /* If */10 :
            var v$2 = evaluate(env, maybe_module, alt[0]);
            var match$23 = v$2[0];
            var exit$3 = 0;
            if (typeof match$23 === "number" || match$23.tag !== /* V_literal */5) {
              exit$3 = 2;
            } else {
              var match$24 = match$23[0];
              if (typeof match$24 === "number" || match$24.tag !== /* Bool */4) {
                exit$3 = 2;
              } else if (match$24[0]) {
                _expr = alt[1];
                continue ;
              } else {
                _expr = alt[2];
                continue ;
              }
            }
            if (exit$3 === 2) {
              throw [
                    Eval_exn,
                    "`if` should be applied on a boolean value, not `" + (string_of_value(v$2) + "`")
                  ];
            }
            break;
        case /* Case */11 :
            var branches = alt[1];
            var v$3 = evaluate(env, maybe_module, alt[0]);
            var v$4 = v$3;
            var _param = branches;
            while(true) {
              var param = _param;
              if (param) {
                var match$25 = param[0];
                var match$26 = match_pattern(v$4, match$25[0]);
                if (match$26) {
                  var new_env = Dict$BwaxMobile.$$String.union(Dict$BwaxMobile.$$String.from_list(Plate$BwaxMobile.List.assoc_map((function (prim) {
                                  return /* record */[/* contents */prim];
                                }), Plate$BwaxMobile.List.assoc_map((function (v) {
                                      return /* tuple */[
                                              v,
                                              undefined
                                            ];
                                    }), match$26[0]))), env);
                  return evaluate(new_env, maybe_module, match$25[1]);
                } else {
                  _param = param[1];
                  continue ;
                }
              } else {
                var text_of_patterns = Plate$BwaxMobile.Str.join(";", Plate$BwaxMobile.List.map((function (param) {
                            return string_of_pattern(param[0]);
                          }), branches));
                throw [
                      Eval_exn,
                      "None of the pattern is matched: " + (text_of_patterns + (" for " + string_of_value(v$4)))
                    ];
              }
            };
        case /* Call_cc */12 :
            throw [
                  Eval_exn,
                  "Continuation is not support"
                ];
        case /* Lambda */13 :
            return /* tuple */[
                    /* V_closure */Block.__(3, [
                        alt[0],
                        alt[1],
                        maybe_module,
                        env
                      ]),
                    info
                  ];
        case /* Let */14 :
            var match$27 = evaluate_defs(env, /* tuple */[
                  undefined,
                  alt[0]
                ]);
            _expr = alt[1];
            _env = match$27[0];
            continue ;
        case /* Literal */15 :
            return /* tuple */[
                    /* V_literal */Block.__(5, [alt[0]]),
                    info
                  ];
        case /* Ref */16 :
            return get_ref(alt[0], env);
        case /* List */1 :
        case /* List_wo_record */17 :
            break;
        case /* Type_annoted_expr */18 :
            _expr = alt[0];
            continue ;
        default:
          throw [
                Eval_exn,
                "?. should be replaced in typing phase"
              ];
      }
    }
    var vals$1 = Plate$BwaxMobile.List.map(eval_it, alt[0]);
    return /* tuple */[
            /* V_list */Block.__(1, [vals$1]),
            info
          ];
  };
}

function apply_closure(maybe_module, params, body, c_env, arg, info) {
  var env_of_matching = function (ptn, v) {
    var match = match_pattern(v, ptn);
    if (match) {
      return Dict$BwaxMobile.$$String.from_list(Plate$BwaxMobile.List.assoc_map((function (prim) {
                        return /* record */[/* contents */prim];
                      }), Plate$BwaxMobile.List.assoc_map((function (v) {
                            return /* tuple */[
                                    v,
                                    undefined
                                  ];
                          }), match[0])));
    } else {
      throw [
            Eval_exn,
            "Match Error:" + string_of_pattern(ptn)
          ];
    }
  };
  if (params) {
    var tl = params[1];
    var p = params[0];
    if (tl) {
      var new_c_env = Dict$BwaxMobile.$$String.union(env_of_matching(p, arg), c_env);
      return /* tuple */[
              /* V_closure */Block.__(3, [
                  tl,
                  body,
                  maybe_module,
                  new_c_env
                ]),
              info
            ];
    } else {
      return evaluate(Dict$BwaxMobile.$$String.union(env_of_matching(p, arg), c_env), maybe_module, body);
    }
  } else {
    var match = arg[0];
    if (typeof match === "number") {
      if (match !== 0) {
        throw [
              Eval_exn,
              "cannot apply"
            ];
      }
      return evaluate(c_env, maybe_module, body);
    } else {
      throw [
            Eval_exn,
            "cannot apply"
          ];
    }
  }
}

function apply_native(arity, fn, applied_args, arg, info) {
  var args = Pervasives.$at(applied_args, /* :: */[
        arg,
        /* [] */0
      ]);
  if (Plate$BwaxMobile.List.length(args) >= arity) {
    return Curry._1(fn, args);
  } else {
    return /* tuple */[
            /* V_native_fn */Block.__(4, [
                arity,
                fn,
                args
              ]),
            info
          ];
  }
}

function apply_value(maybe_module, fn, arg, info) {
  var match = fn[0];
  if (typeof match === "number") {
    throw [
          Eval_exn,
          string_of_value(fn) + " is not a closure, native function or continuation"
        ];
  } else {
    switch (match.tag | 0) {
      case /* V_closure */3 :
          return apply_closure(match[2], match[0], match[1], match[3], arg, info);
      case /* V_native_fn */4 :
          return apply_native(match[0], match[1], match[2], arg, info);
      case /* V_decorated */13 :
          var match$1 = apply_value(maybe_module, match[1], arg, info);
          var t = match$1[1];
          return /* tuple */[
                  /* V_decorated */Block.__(13, [
                      match[0],
                      /* tuple */[
                        match$1[0],
                        t
                      ]
                    ]),
                  t
                ];
      default:
        throw [
              Eval_exn,
              string_of_value(fn) + " is not a closure, native function or continuation"
            ];
    }
  }
}

function apply_value_args(maybe_module, _fn, _args, info) {
  while(true) {
    var args = _args;
    var fn = _fn;
    if (args) {
      var tl = args[1];
      var h = args[0];
      if (tl) {
        var afn = apply_value(maybe_module, fn, h, info);
        _args = tl;
        _fn = afn;
        continue ;
      } else {
        return apply_value(maybe_module, fn, h, info);
      }
    } else {
      return apply_value(maybe_module, fn, /* tuple */[
                  /* V_unit */0,
                  info
                ], info);
    }
  };
}

function apply_expr(env, maybe_module, fn_e, arg_e, info) {
  var fn = evaluate(env, maybe_module, fn_e);
  var arg = evaluate(env, maybe_module, arg_e);
  return apply_value(maybe_module, fn, arg, info);
}

function make_constructor(tag_name, ants, info) {
  if (Plate$BwaxMobile.List.length(ants) === 0) {
    return /* tuple */[
            /* V_tagged */Block.__(6, [
                tag_name,
                /* [] */0
              ]),
            info
          ];
  } else {
    var arity = Plate$BwaxMobile.List.length(ants);
    var func = function (args) {
      if (Plate$BwaxMobile.List.length(args) !== arity) {
        throw [
              Eval_exn,
              "`" + (String(tag_name) + "`: Invalid Arguments")
            ];
      }
      return /* tuple */[
              /* V_tagged */Block.__(6, [
                  tag_name,
                  args
                ]),
              info
            ];
    };
    return /* tuple */[
            /* V_native_fn */Block.__(4, [
                arity,
                func,
                /* [] */0
              ]),
            info
          ];
  }
}

function evaluate_defs(base_env, param) {
  var defs = param[1];
  var maybe_module = param[0];
  var normalize_name = function (name) {
    if (maybe_module !== undefined) {
      return maybe_module + ("." + name);
    } else {
      return name;
    }
  };
  var placeholders = Dict$BwaxMobile.$$String.from_list(Plate$BwaxMobile.List.foldl((function (acc, param) {
              var info = param[1];
              var def = param[0];
              var put_if_absent = function (name, v) {
                var match = Dict$BwaxMobile.$$String.get(normalize_name(name), base_env);
                if (match !== undefined) {
                  return acc;
                } else {
                  return /* :: */[
                          /* tuple */[
                            name,
                            /* record */[/* contents : tuple */[
                                v,
                                undefined
                              ]]
                          ],
                          acc
                        ];
                }
              };
              switch (def.tag | 0) {
                case /* Def_fun */1 :
                    return put_if_absent(def[0], /* tuple */[
                                /* V_todo */1,
                                info
                              ]);
                case /* Def_type */2 :
                    return Plate$BwaxMobile.List.foldr((function (param, acc) {
                                  return put_if_absent(param[0], /* tuple */[
                                              /* V_todo */1,
                                              info
                                            ]);
                                }), def[2], acc);
                default:
                  return acc;
              }
            }), /* [] */0, defs));
  var proc = function (fresh_env, param) {
    var info = param[1];
    var def = param[0];
    var put_into_env = function (fe, name, v) {
      var match = Dict$BwaxMobile.$$String.get(name, fe);
      if (match !== undefined) {
        match[0] = v;
        return fe;
      } else {
        return Dict$BwaxMobile.$$String.insert(name, /* record */[/* contents */v], fe);
      }
    };
    var env_to_use = Dict$BwaxMobile.$$String.union(fresh_env, base_env);
    switch (def.tag | 0) {
      case /* Def_val */0 :
          var expr = def[1];
          var match = expr[0];
          var ptn = def[0];
          if (typeof match === "number" && match !== 0) {
            return fresh_env;
          }
          var v = evaluate(env_to_use, maybe_module, expr);
          var match$1 = match_pattern(v, ptn);
          if (match$1) {
            return Plate$BwaxMobile.List.foldl((function (acc, param) {
                          return put_into_env(acc, param[0], /* tuple */[
                                      param[1],
                                      undefined
                                    ]);
                        }), fresh_env, match$1[0]);
          } else {
            throw [
                  Eval_exn,
                  "Match Error:" + string_of_pattern(ptn)
                ];
          }
          break;
      case /* Def_fun */1 :
          var expr$1 = def[2];
          var match$2 = expr$1[0];
          if (typeof match$2 === "number" && match$2 !== 0) {
            return fresh_env;
          }
          var v_000 = def[1];
          var v$1 = /* V_closure */Block.__(3, [
              v_000,
              expr$1,
              maybe_module,
              env_to_use
            ]);
          return put_into_env(fresh_env, def[0], /* tuple */[
                      /* tuple */[
                        v$1,
                        info
                      ],
                      undefined
                    ]);
      case /* Def_type */2 :
          var match$3 = Plate$BwaxMobile.List.foldl((function (param, param$1) {
                  var name = param$1[0];
                  var constr = make_constructor(name, param$1[1], param$1[2]);
                  return /* tuple */[
                          put_into_env(param[0], name, /* tuple */[
                                constr,
                                undefined
                              ]),
                          /* :: */[
                            name,
                            param[1]
                          ]
                        ];
                }), /* tuple */[
                fresh_env,
                /* [] */0
              ], def[2]);
          return put_into_env(match$3[0], def[0], /* tuple */[
                      /* tuple */[
                        /* V_sum_type */Block.__(11, [match$3[1]]),
                        info
                      ],
                      undefined
                    ]);
      case /* Def_import */5 :
          var imp = def[0];
          var prefix_it = function (mod_name, n) {
            return mod_name + ("." + n);
          };
          var find_and_insert = function (full_name, name, current_env) {
            var match = Dict$BwaxMobile.$$String.get(full_name, base_env);
            if (match !== undefined) {
              var match$1 = match[0];
              return put_into_env(current_env, name, /* tuple */[
                          match$1[0],
                          full_name
                        ]);
            } else {
              return current_env;
            }
          };
          switch (imp.tag | 0) {
            case /* Import_exposing */0 :
                var mod_name = imp[0];
                return Plate$BwaxMobile.List.foldl((function (acc, exp) {
                              var match = exp[0];
                              if (match.tag) {
                                var match$1 = Dict$BwaxMobile.$$String.get(prefix_it(mod_name, match[0]), base_env);
                                if (match$1 !== undefined) {
                                  var match$2 = match$1[0];
                                  var match$3 = match$2[0][0];
                                  if (typeof match$3 === "number" || match$3.tag !== /* V_sum_type */11) {
                                    return acc;
                                  } else {
                                    return Plate$BwaxMobile.List.foldl((function (acc, name) {
                                                  return find_and_insert(prefix_it(mod_name, name), name, acc);
                                                }), acc, match$3[0]);
                                  }
                                } else {
                                  return acc;
                                }
                              } else {
                                var name = match[0];
                                return find_and_insert(prefix_it(mod_name, name), name, acc);
                              }
                            }), fresh_env, imp[1]);
            case /* Import_all */1 :
                var prefix = imp[0] + ".";
                var length = Plate$BwaxMobile.Str.length(prefix);
                return Plate$BwaxMobile.List.foldl((function (acc, param) {
                              var k = param[0];
                              var name = Plate$BwaxMobile.Str.drop_left(length, k);
                              return put_into_env(acc, name, /* tuple */[
                                          Plate$BwaxMobile.fst(param[1][0]),
                                          k
                                        ]);
                            }), fresh_env, Dict$BwaxMobile.$$String.to_list(Dict$BwaxMobile.$$String.filter((function (k, param) {
                                      return Plate$BwaxMobile.Str.starts_with(prefix, k);
                                    }), base_env)));
            case /* Import_as */2 :
                return find_and_insert(imp[0], imp[1], fresh_env);
            
          }
      default:
        return fresh_env;
    }
  };
  var _fresh_env = placeholders;
  var _remaining = defs;
  while(true) {
    var remaining = _remaining;
    var fresh_env = _fresh_env;
    if (remaining) {
      var nfe = proc(fresh_env, remaining[0]);
      _remaining = remaining[1];
      _fresh_env = nfe;
      continue ;
    } else {
      var prefixed_env;
      if (maybe_module !== undefined) {
        var name = maybe_module;
        prefixed_env = Dict$BwaxMobile.$$String.foldl((function(name){
            return function (acc, k, v) {
              return Dict$BwaxMobile.$$String.insert(name + ("." + k), v, acc);
            }
            }(name)), Dict$BwaxMobile.$$String.empty(/* () */0), fresh_env);
      } else {
        prefixed_env = fresh_env;
      }
      var combined_env = Dict$BwaxMobile.$$String.union(prefixed_env, base_env);
      return /* tuple */[
              combined_env,
              prefixed_env
            ];
    }
  };
}

function trav_expr(f, e) {
  var alt = e[0];
  var trav = function (param) {
    return trav_expr(f, param);
  };
  var n_alt;
  if (typeof alt === "number") {
    n_alt = alt === /* Unit */0 ? /* Unit */0 : /* External */1;
  } else {
    switch (alt.tag | 0) {
      case /* Tuple */0 :
          n_alt = /* Tuple */Block.__(0, [Plate$BwaxMobile.List.map(trav, alt[0])]);
          break;
      case /* List */1 :
          n_alt = /* List */Block.__(1, [Plate$BwaxMobile.List.map(trav, alt[0])]);
          break;
      case /* Record */2 :
          n_alt = /* Record */Block.__(2, [Plate$BwaxMobile.List.assoc_map(trav, alt[0])]);
          break;
      case /* Record_upd */3 :
          n_alt = /* Record_upd */Block.__(3, [
              alt[0],
              Plate$BwaxMobile.List.assoc_map(trav, alt[1])
            ]);
          break;
      case /* Record_get */4 :
          n_alt = /* Record_get */Block.__(4, [
              trav_expr(f, alt[0]),
              alt[1]
            ]);
          break;
      case /* Getter */5 :
          n_alt = /* Getter */Block.__(5, [alt[0]]);
          break;
      case /* Constructor */6 :
          n_alt = /* Constructor */Block.__(6, [alt[0]]);
          break;
      case /* Apply */7 :
          n_alt = /* Apply */Block.__(7, [
              trav_expr(f, alt[0]),
              trav_expr(f, alt[1])
            ]);
          break;
      case /* Unop */8 :
          n_alt = /* Unop */Block.__(8, [
              alt[0],
              trav_expr(f, alt[1])
            ]);
          break;
      case /* Binop */9 :
          n_alt = /* Binop */Block.__(9, [
              alt[0],
              trav_expr(f, alt[1]),
              trav_expr(f, alt[2])
            ]);
          break;
      case /* If */10 :
          n_alt = /* If */Block.__(10, [
              trav_expr(f, alt[0]),
              trav_expr(f, alt[1]),
              trav_expr(f, alt[2])
            ]);
          break;
      case /* Case */11 :
          var n_target = trav_expr(f, alt[0]);
          var n_branches = Plate$BwaxMobile.List.map((function (param) {
                  return /* tuple */[
                          trav_pattern(f, param[0]),
                          trav_expr(f, param[1])
                        ];
                }), alt[1]);
          n_alt = /* Case */Block.__(11, [
              n_target,
              n_branches
            ]);
          break;
      case /* Call_cc */12 :
          n_alt = /* Call_cc */Block.__(12, [
              alt[0],
              trav_expr(f, alt[1])
            ]);
          break;
      case /* Lambda */13 :
          var n_ptns = Plate$BwaxMobile.List.map((function (param) {
                  return trav_pattern(f, param);
                }), alt[0]);
          n_alt = /* Lambda */Block.__(13, [
              n_ptns,
              trav_expr(f, alt[1])
            ]);
          break;
      case /* Let */14 :
          n_alt = /* Let */Block.__(14, [
              Plate$BwaxMobile.List.map((function (param) {
                      return trav_def(f, param);
                    }), alt[0]),
              trav_expr(f, alt[1])
            ]);
          break;
      case /* Literal */15 :
          n_alt = /* Literal */Block.__(15, [alt[0]]);
          break;
      case /* Ref */16 :
          n_alt = /* Ref */Block.__(16, [alt[0]]);
          break;
      case /* List_wo_record */17 :
          n_alt = /* List_wo_record */Block.__(17, [Plate$BwaxMobile.List.map(trav, alt[0])]);
          break;
      case /* Type_annoted_expr */18 :
          n_alt = /* Type_annoted_expr */Block.__(18, [
              trav_expr(f, alt[0]),
              trav_annot_type(f, alt[1])
            ]);
          break;
      case /* Record_get_optional */19 :
          n_alt = /* Record_get_optional */Block.__(19, [
              trav_expr(f, alt[0]),
              alt[1]
            ]);
          break;
      case /* List_range */20 :
          n_alt = /* List_range */Block.__(20, [
              trav_expr(f, alt[0]),
              Plate$BwaxMobile.$$Option.map(trav, alt[1]),
              trav_expr(f, alt[2])
            ]);
          break;
      case /* List_comprehension */21 :
          n_alt = /* List_comprehension */Block.__(21, [
              trav_expr(f, alt[0]),
              Plate$BwaxMobile.List.map((function (param) {
                      return trav_action_stmt(f, param);
                    }), alt[1])
            ]);
          break;
      case /* Do_expr */22 :
          n_alt = /* Do_expr */Block.__(22, [Plate$BwaxMobile.List.map((function (param) {
                      return trav_action_stmt(f, param);
                    }), alt[0])]);
          break;
      
    }
  }
  var j = Curry._1(f, e[1]);
  return /* tuple */[
          n_alt,
          j
        ];
}

function trav_action_stmt(f, s) {
  switch (s.tag | 0) {
    case /* Value_assignment */0 :
    case /* Value_extract */1 :
        break;
    case /* One_expr */2 :
        return /* One_expr */Block.__(2, [trav_expr(f, s[0])]);
    
  }
  return /* Value_extract */Block.__(1, [
            trav_pattern(f, s[0]),
            trav_expr(f, s[1]),
            Curry._1(f, s[2])
          ]);
}

function trav_pattern(f, p) {
  var alt = p[0];
  var trav = function (param) {
    return trav_pattern(f, param);
  };
  var n_alt;
  if (typeof alt === "number") {
    switch (alt) {
      case /* Ptn_wildcard */0 :
          n_alt = /* Ptn_wildcard */0;
          break;
      case /* Ptn_unit */1 :
          n_alt = /* Ptn_unit */1;
          break;
      case /* Ptn_nil_list */2 :
          n_alt = /* Ptn_nil_list */2;
          break;
      
    }
  } else {
    switch (alt.tag | 0) {
      case /* Ptn_var */0 :
          n_alt = /* Ptn_var */Block.__(0, [alt[0]]);
          break;
      case /* Ptn_constructor */1 :
          n_alt = /* Ptn_constructor */Block.__(1, [
              alt[0],
              Plate$BwaxMobile.List.map(trav, alt[1])
            ]);
          break;
      case /* Ptn_tuple */2 :
          n_alt = /* Ptn_tuple */Block.__(2, [Plate$BwaxMobile.List.map(trav, alt[0])]);
          break;
      case /* Ptn_literal */3 :
          n_alt = /* Ptn_literal */Block.__(3, [alt[0]]);
          break;
      case /* Ptn_cons_list */4 :
          n_alt = /* Ptn_cons_list */Block.__(4, [
              trav_pattern(f, alt[0]),
              trav_pattern(f, alt[1])
            ]);
          break;
      case /* Ptn_record */5 :
          n_alt = /* Ptn_record */Block.__(5, [alt[0]]);
          break;
      
    }
  }
  var j = Curry._1(f, p[1]);
  return /* tuple */[
          n_alt,
          j
        ];
}

function trav_constr(f, constr) {
  var n_ants = Plate$BwaxMobile.List.map((function (param) {
          return trav_annot_type(f, param);
        }), constr[1]);
  var j = Curry._1(f, constr[2]);
  return /* tuple */[
          constr[0],
          n_ants,
          j
        ];
}

function trav_def(f, def) {
  var alt = def[0];
  var n_alt;
  switch (alt.tag | 0) {
    case /* Def_val */0 :
        n_alt = /* Def_val */Block.__(0, [
            trav_pattern(f, alt[0]),
            trav_expr(f, alt[1]),
            Plate$BwaxMobile.$$Option.map((function (param) {
                    return trav_annot_type(f, param);
                  }), alt[2])
          ]);
        break;
    case /* Def_fun */1 :
        n_alt = /* Def_fun */Block.__(1, [
            alt[0],
            Plate$BwaxMobile.List.map((function (param) {
                    return trav_pattern(f, param);
                  }), alt[1]),
            trav_expr(f, alt[2]),
            Plate$BwaxMobile.$$Option.map((function (param) {
                    return trav_annot_type(f, param);
                  }), alt[3])
          ]);
        break;
    case /* Def_type */2 :
        var n_constrs = Plate$BwaxMobile.List.map((function (param) {
                return trav_constr(f, param);
              }), alt[2]);
        n_alt = /* Def_type */Block.__(2, [
            alt[0],
            alt[1],
            n_constrs
          ]);
        break;
    case /* Def_type_alias */3 :
        n_alt = /* Def_type_alias */Block.__(3, [
            alt[0],
            alt[1],
            trav_annot_type(f, alt[2])
          ]);
        break;
    case /* Def_type_opaque */4 :
        n_alt = /* Def_type_opaque */Block.__(4, [
            alt[0],
            alt[1]
          ]);
        break;
    case /* Def_import */5 :
        var imp = alt[0];
        var new_imp;
        switch (imp.tag | 0) {
          case /* Import_exposing */0 :
              new_imp = /* Import_exposing */Block.__(0, [
                  imp[0],
                  Plate$BwaxMobile.List.map((function (param) {
                          return /* tuple */[
                                  param[0],
                                  Curry._1(f, param[1])
                                ];
                        }), imp[1])
                ]);
              break;
          case /* Import_all */1 :
              new_imp = /* Import_all */Block.__(1, [imp[0]]);
              break;
          case /* Import_as */2 :
              new_imp = /* Import_as */Block.__(2, [
                  imp[0],
                  imp[1]
                ]);
              break;
          
        }
        n_alt = /* Def_import */Block.__(5, [new_imp]);
        break;
    case /* Def_exposing */6 :
        n_alt = /* Def_exposing */Block.__(6, [Plate$BwaxMobile.List.map((function (param) {
                    return /* tuple */[
                            param[0],
                            Curry._1(f, param[1])
                          ];
                  }), alt[0])]);
        break;
    
  }
  var j = Curry._1(f, def[1]);
  return /* tuple */[
          n_alt,
          j
        ];
}

function trav_annot_type(f, ant) {
  var alt = ant[0];
  var trav = function (param) {
    return trav_annot_type(f, param);
  };
  var assoc_map_tri = function (l) {
    return Plate$BwaxMobile.List.map((function (param) {
                  return /* tuple */[
                          param[0],
                          trav_annot_type(f, param[1]),
                          param[2]
                        ];
                }), l);
  };
  var n_alt;
  if (typeof alt === "number") {
    n_alt = alt === /* Unit_type */0 ? /* Unit_type */0 : /* Opaqued_type */1;
  } else {
    switch (alt.tag | 0) {
      case /* Var_type */0 :
          n_alt = /* Var_type */Block.__(0, [alt[0]]);
          break;
      case /* Constr_type */1 :
          n_alt = /* Constr_type */Block.__(1, [
              alt[0],
              Plate$BwaxMobile.List.map(trav, alt[1])
            ]);
          break;
      case /* Tuple_type */2 :
          n_alt = /* Tuple_type */Block.__(2, [Plate$BwaxMobile.List.map(trav, alt[0])]);
          break;
      case /* Arrow_type */3 :
          n_alt = /* Arrow_type */Block.__(3, [
              trav_annot_type(f, alt[0]),
              trav_annot_type(f, alt[1])
            ]);
          break;
      case /* Record_type */4 :
          n_alt = /* Record_type */Block.__(4, [assoc_map_tri(alt[0])]);
          break;
      case /* Record_ext_type */5 :
          n_alt = /* Record_ext_type */Block.__(5, [Plate$BwaxMobile.List.assoc_map(trav, alt[0])]);
          break;
      case /* Ro_record_type */6 :
          n_alt = /* Ro_record_type */Block.__(6, [
              alt[0],
              Plate$BwaxMobile.List.map(trav, alt[1])
            ]);
          break;
      case /* Ro_record_of */7 :
          n_alt = /* Ro_record_of */Block.__(7, [assoc_map_tri(alt[0])]);
          break;
      case /* Wo_record_type */8 :
          n_alt = /* Wo_record_type */Block.__(8, [
              alt[0],
              Plate$BwaxMobile.List.map(trav, alt[1])
            ]);
          break;
      case /* Wo_record_of */9 :
          n_alt = /* Wo_record_of */Block.__(9, [assoc_map_tri(alt[0])]);
          break;
      case /* Ro_var_type */10 :
          n_alt = /* Ro_var_type */Block.__(10, [alt[0]]);
          break;
      case /* Wo_optional_record_type */11 :
          n_alt = /* Wo_optional_record_type */Block.__(11, [
              alt[0],
              Plate$BwaxMobile.List.map(trav, alt[1])
            ]);
          break;
      
    }
  }
  var j = Curry._1(f, ant[1]);
  return /* tuple */[
          n_alt,
          j
        ];
}

function string_of_unop(param) {
  return "not";
}

function string_of_arith_op(param) {
  switch (param) {
    case /* Pow */0 :
        return "^";
    case /* Mul */1 :
        return "*";
    case /* Div */2 :
        return "/";
    case /* Mod */3 :
        return "%";
    case /* Add */4 :
        return "+";
    case /* Sub */5 :
        return "-";
    
  }
}

function string_of_equality_op(param) {
  if (param) {
    return "!=";
  } else {
    return "==";
  }
}

function string_of_compare_op(param) {
  switch (param) {
    case /* Gt */0 :
        return ">";
    case /* Ge */1 :
        return ">=";
    case /* Le */2 :
        return "<=";
    case /* Lt */3 :
        return "<";
    
  }
}

function string_of_logic_op(param) {
  switch (param) {
    case /* And */0 :
        return "and";
    case /* Or */1 :
        return "or";
    case /* Xor */2 :
        return "xor";
    
  }
}

function string_of_binop(param) {
  if (typeof param === "number") {
    if (param === /* Cons */0) {
      return "::";
    } else {
      return "++";
    }
  } else {
    switch (param.tag | 0) {
      case /* Arith */0 :
          return string_of_arith_op(param[0]);
      case /* Equality */1 :
          if (param[0]) {
            return "!=";
          } else {
            return "==";
          }
      case /* Compare */2 :
          return string_of_compare_op(param[0]);
      case /* Logic */3 :
          return string_of_logic_op(param[0]);
      case /* Custom */4 :
          return param[0];
      
    }
  }
}

function string_of_exposed(param) {
  if (param.tag) {
    return param[0] + "(..)";
  } else {
    return param[0];
  }
}

function expr_to_js(f, e) {
  var alt = e[0];
  var iter = function (param) {
    return expr_to_js(f, param);
  };
  var map = function (l) {
    return Json$BwaxMobile.json_arr(Plate$BwaxMobile.List.map(iter, l));
  };
  var assoc_map = function (l) {
    return Json$BwaxMobile.json_object(Plate$BwaxMobile.List.assoc_map(iter, l));
  };
  var $$null$1 = null;
  var match;
  if (typeof alt === "number") {
    match = alt === /* Unit */0 ? /* tuple */[
        "Unit",
        "()",
        $$null$1
      ] : /* tuple */[
        "External",
        undefined,
        $$null$1
      ];
  } else {
    switch (alt.tag | 0) {
      case /* Tuple */0 :
          match = /* tuple */[
            "Tuple",
            undefined,
            map(alt[0])
          ];
          break;
      case /* List */1 :
          match = /* tuple */[
            "List",
            undefined,
            map(alt[0])
          ];
          break;
      case /* Record */2 :
          match = /* tuple */[
            "Record",
            undefined,
            assoc_map(alt[0])
          ];
          break;
      case /* Record_upd */3 :
          match = /* tuple */[
            "Record_upd",
            undefined,
            Json$BwaxMobile.json_arr(/* :: */[
                  Json$BwaxMobile.json_str(alt[0]),
                  /* :: */[
                    assoc_map(alt[1]),
                    /* [] */0
                  ]
                ])
          ];
          break;
      case /* Record_get */4 :
          match = /* tuple */[
            "Record_get",
            undefined,
            Json$BwaxMobile.json_arr(/* :: */[
                  expr_to_js(f, alt[0]),
                  /* :: */[
                    Json$BwaxMobile.json_str(alt[1]),
                    /* [] */0
                  ]
                ])
          ];
          break;
      case /* Getter */5 :
          match = /* tuple */[
            "Getter",
            alt[0],
            $$null$1
          ];
          break;
      case /* Constructor */6 :
          match = /* tuple */[
            "Constructor",
            alt[0],
            $$null$1
          ];
          break;
      case /* Apply */7 :
          match = /* tuple */[
            "Apply",
            undefined,
            map(/* :: */[
                  alt[0],
                  /* :: */[
                    alt[1],
                    /* [] */0
                  ]
                ])
          ];
          break;
      case /* Unop */8 :
          match = /* tuple */[
            "Unop",
            "not",
            expr_to_js(f, alt[1])
          ];
          break;
      case /* Binop */9 :
          match = /* tuple */[
            "Binop",
            string_of_binop(alt[0]),
            map(/* :: */[
                  alt[1],
                  /* :: */[
                    alt[2],
                    /* [] */0
                  ]
                ])
          ];
          break;
      case /* If */10 :
          match = /* tuple */[
            "If",
            undefined,
            map(/* :: */[
                  alt[0],
                  /* :: */[
                    alt[1],
                    /* :: */[
                      alt[2],
                      /* [] */0
                    ]
                  ]
                ])
          ];
          break;
      case /* Case */11 :
          var target_js = expr_to_js(f, alt[0]);
          var branches_js = Json$BwaxMobile.json_arr(Plate$BwaxMobile.List.map((function (param) {
                      return Json$BwaxMobile.json_arr(/* :: */[
                                  pattern_to_js(f, param[0]),
                                  /* :: */[
                                    expr_to_js(f, param[1]),
                                    /* [] */0
                                  ]
                                ]);
                    }), alt[1]));
          match = /* tuple */[
            "Case",
            undefined,
            Json$BwaxMobile.json_arr(/* :: */[
                  target_js,
                  /* :: */[
                    branches_js,
                    /* [] */0
                  ]
                ])
          ];
          break;
      case /* Call_cc */12 :
          match = /* tuple */[
            "Call_cc",
            alt[0],
            expr_to_js(f, alt[1])
          ];
          break;
      case /* Lambda */13 :
          var ptns_js = Json$BwaxMobile.json_arr(Plate$BwaxMobile.List.map((function (param) {
                      return pattern_to_js(f, param);
                    }), alt[0]));
          match = /* tuple */[
            "Lambda",
            undefined,
            Json$BwaxMobile.json_arr(/* :: */[
                  ptns_js,
                  /* :: */[
                    expr_to_js(f, alt[1]),
                    /* [] */0
                  ]
                ])
          ];
          break;
      case /* Let */14 :
          match = /* tuple */[
            "Let",
            undefined,
            Json$BwaxMobile.json_arr(/* :: */[
                  Json$BwaxMobile.json_arr(Plate$BwaxMobile.List.map((function (param) {
                              return def_to_js(f, param);
                            }), alt[0])),
                  /* :: */[
                    expr_to_js(f, alt[1]),
                    /* [] */0
                  ]
                ])
          ];
          break;
      case /* Literal */15 :
          match = /* tuple */[
            "Literal",
            string_of_literal(alt[0]),
            $$null$1
          ];
          break;
      case /* Ref */16 :
          match = /* tuple */[
            "Ref",
            alt[0],
            $$null$1
          ];
          break;
      case /* List_wo_record */17 :
          match = /* tuple */[
            "List_wo_record",
            undefined,
            map(alt[0])
          ];
          break;
      case /* Type_annoted_expr */18 :
          match = /* tuple */[
            "Type_annoted_expr",
            undefined,
            Json$BwaxMobile.json_arr(/* :: */[
                  expr_to_js(f, alt[0]),
                  /* :: */[
                    annot_type_to_js(f, alt[1]),
                    /* [] */0
                  ]
                ])
          ];
          break;
      case /* Record_get_optional */19 :
          match = /* tuple */[
            "Record_get_optional",
            undefined,
            Json$BwaxMobile.json_arr(/* :: */[
                  expr_to_js(f, alt[0]),
                  /* :: */[
                    Json$BwaxMobile.json_str(alt[1]),
                    /* [] */0
                  ]
                ])
          ];
          break;
      case /* List_range */20 :
          match = /* tuple */[
            "List_range",
            undefined,
            map(Plate$BwaxMobile.List.clean(/* :: */[
                      alt[0],
                      /* :: */[
                        alt[1],
                        /* :: */[
                          alt[2],
                          /* [] */0
                        ]
                      ]
                    ]))
          ];
          break;
      case /* List_comprehension */21 :
          match = /* tuple */[
            "List_comprehension",
            undefined,
            $$null$1
          ];
          break;
      case /* Do_expr */22 :
          match = /* tuple */[
            "Do_expr",
            undefined,
            $$null$1
          ];
          break;
      
    }
  }
  var meta = Curry._1(f, e[1]);
  return {
          __node: true,
          __type: "expr",
          name: match[0],
          value: match[1],
          meta: meta,
          children: match[2]
        };
}

function def_to_js(f, def) {
  var alt = def[0];
  var empty = null;
  var match;
  switch (alt.tag | 0) {
    case /* Def_val */0 :
        var ptn = alt[0];
        match = /* tuple */[
          "Def_val",
          string_of_pattern(ptn),
          Json$BwaxMobile.json_arr(Plate$BwaxMobile.List.clean(/* :: */[
                    Caml_option.some(pattern_to_js(f, ptn)),
                    /* :: */[
                      Caml_option.some(expr_to_js(f, alt[1])),
                      /* :: */[
                        Plate$BwaxMobile.$$Option.map((function (param) {
                                return annot_type_to_js(f, param);
                              }), alt[2]),
                        /* [] */0
                      ]
                    ]
                  ]))
        ];
        break;
    case /* Def_fun */1 :
        match = /* tuple */[
          "Def_fun",
          alt[0],
          Json$BwaxMobile.json_arr(Plate$BwaxMobile.List.clean(/* :: */[
                    Caml_option.some(Json$BwaxMobile.json_arr(Plate$BwaxMobile.List.map((function (param) {
                                    return pattern_to_js(f, param);
                                  }), alt[1]))),
                    /* :: */[
                      Caml_option.some(expr_to_js(f, alt[2])),
                      /* :: */[
                        Plate$BwaxMobile.$$Option.map((function (param) {
                                return annot_type_to_js(f, param);
                              }), alt[3]),
                        /* [] */0
                      ]
                    ]
                  ]))
        ];
        break;
    case /* Def_type */2 :
        match = /* tuple */[
          "Def_type",
          Plate$BwaxMobile.Str.join(" ", /* :: */[
                alt[0],
                alt[1]
              ]),
          Json$BwaxMobile.json_arr(Plate$BwaxMobile.List.map((function (param) {
                      return constr_to_js(f, param);
                    }), alt[2]))
        ];
        break;
    case /* Def_type_alias */3 :
        match = /* tuple */[
          "Def_type_alias",
          Plate$BwaxMobile.Str.join(" ", /* :: */[
                alt[0],
                alt[1]
              ]),
          annot_type_to_js(f, alt[2])
        ];
        break;
    case /* Def_type_opaque */4 :
        match = /* tuple */[
          "Def_type_opaque",
          Plate$BwaxMobile.Str.join(" ", /* :: */[
                alt[0],
                alt[1]
              ]),
          empty
        ];
        break;
    case /* Def_import */5 :
        var imp = alt[0];
        var imp_str;
        switch (imp.tag | 0) {
          case /* Import_exposing */0 :
              imp_str = "import " + (imp[0] + (" exposing ( " + (Plate$BwaxMobile.Str.join(", ", Plate$BwaxMobile.List.map((function (param) {
                                return Plate$BwaxMobile.$at$great(Plate$BwaxMobile.fst, string_of_exposed, param);
                              }), imp[1])) + ")")));
              break;
          case /* Import_all */1 :
              imp_str = "import " + (imp[0] + " exposing (..)");
              break;
          case /* Import_as */2 :
              imp_str = "import " + (imp[0] + (" as " + imp[1]));
              break;
          
        }
        match = /* tuple */[
          "Def_import",
          imp_str,
          empty
        ];
        break;
    case /* Def_exposing */6 :
        match = /* tuple */[
          "Def_exposing",
          " exposing ( " + (Plate$BwaxMobile.Str.join(", ", Plate$BwaxMobile.List.map((function (param) {
                        return Plate$BwaxMobile.$at$great(Plate$BwaxMobile.fst, string_of_exposed, param);
                      }), alt[0])) + ")"),
          empty
        ];
        break;
    
  }
  var meta = Curry._1(f, def[1]);
  return {
          __node: true,
          __type: "def",
          name: match[0],
          value: match[1],
          meta: meta,
          children: match[2]
        };
}

function pattern_to_js(f, p) {
  var alt = p[0];
  var iter = function (param) {
    return pattern_to_js(f, param);
  };
  var map = function (l) {
    return Json$BwaxMobile.json_arr(Plate$BwaxMobile.List.map(iter, l));
  };
  var $$null$1 = null;
  var match;
  if (typeof alt === "number") {
    switch (alt) {
      case /* Ptn_wildcard */0 :
          match = /* tuple */[
            "Ptn_wildcard",
            $$null$1
          ];
          break;
      case /* Ptn_unit */1 :
          match = /* tuple */[
            "Ptn_unit",
            $$null$1
          ];
          break;
      case /* Ptn_nil_list */2 :
          match = /* tuple */[
            "Ptn_nil_list",
            $$null$1
          ];
          break;
      
    }
  } else {
    switch (alt.tag | 0) {
      case /* Ptn_var */0 :
          match = /* tuple */[
            "Ptn_var",
            $$null$1
          ];
          break;
      case /* Ptn_constructor */1 :
          match = /* tuple */[
            "Ptn_constructor",
            map(alt[1])
          ];
          break;
      case /* Ptn_tuple */2 :
          match = /* tuple */[
            "Ptn_tuple",
            map(alt[0])
          ];
          break;
      case /* Ptn_literal */3 :
          match = /* tuple */[
            "Ptn_literal",
            $$null$1
          ];
          break;
      case /* Ptn_cons_list */4 :
          match = /* tuple */[
            "Ptn_cons_list",
            map(/* :: */[
                  alt[0],
                  /* :: */[
                    alt[1],
                    /* [] */0
                  ]
                ])
          ];
          break;
      case /* Ptn_record */5 :
          match = /* tuple */[
            "Ptn_record",
            $$null$1
          ];
          break;
      
    }
  }
  var value = string_of_pattern(p);
  var meta = Curry._1(f, p[1]);
  return {
          __node: true,
          __type: "pattern",
          name: match[0],
          value: value,
          meta: meta,
          children: match[1]
        };
}

function annot_type_to_js(f, ant) {
  var alt = ant[0];
  var iter = function (param) {
    return annot_type_to_js(f, param);
  };
  var map = function (l) {
    return Json$BwaxMobile.json_arr(Plate$BwaxMobile.List.map(iter, l));
  };
  var assoc_map = function (l) {
    return Json$BwaxMobile.json_object(Plate$BwaxMobile.List.assoc_map(iter, l));
  };
  var assoc_map_tri = function (l) {
    return Json$BwaxMobile.json_object(Plate$BwaxMobile.List.map((function (param) {
                      return /* tuple */[
                              param[0],
                              Json$BwaxMobile.json_arr(/* :: */[
                                    param[1],
                                    /* :: */[
                                      param[2],
                                      /* [] */0
                                    ]
                                  ])
                            ];
                    }), Plate$BwaxMobile.List.map((function (param) {
                          return /* tuple */[
                                  param[0],
                                  annot_type_to_js(f, param[1]),
                                  param[2]
                                ];
                        }), l)));
  };
  var $$null$1 = null;
  var match;
  if (typeof alt === "number") {
    match = alt === /* Unit_type */0 ? /* tuple */[
        "Unit_type",
        "()",
        $$null$1
      ] : /* tuple */[
        "Opaqued_type",
        "opaque",
        $$null$1
      ];
  } else {
    switch (alt.tag | 0) {
      case /* Var_type */0 :
          match = /* tuple */[
            "Var_type",
            alt[0],
            $$null$1
          ];
          break;
      case /* Constr_type */1 :
          match = /* tuple */[
            "Constr_type",
            alt[0],
            map(alt[1])
          ];
          break;
      case /* Tuple_type */2 :
          match = /* tuple */[
            "Tuple_type",
            undefined,
            map(alt[0])
          ];
          break;
      case /* Arrow_type */3 :
          match = /* tuple */[
            "Arrow_type",
            undefined,
            map(/* :: */[
                  alt[0],
                  /* :: */[
                    alt[1],
                    /* [] */0
                  ]
                ])
          ];
          break;
      case /* Record_type */4 :
          match = /* tuple */[
            "Record_type",
            undefined,
            assoc_map_tri(alt[0])
          ];
          break;
      case /* Record_ext_type */5 :
          match = /* tuple */[
            "Record_ext_type",
            undefined,
            assoc_map(alt[0])
          ];
          break;
      case /* Ro_record_type */6 :
          match = /* tuple */[
            "Ro_record_type",
            alt[0],
            map(alt[1])
          ];
          break;
      case /* Ro_record_of */7 :
          match = /* tuple */[
            "Ro_record_of",
            undefined,
            assoc_map_tri(alt[0])
          ];
          break;
      case /* Wo_record_type */8 :
          match = /* tuple */[
            "Wo_record_type",
            alt[0],
            map(alt[1])
          ];
          break;
      case /* Wo_record_of */9 :
          match = /* tuple */[
            "Wo_record_of",
            undefined,
            assoc_map_tri(alt[0])
          ];
          break;
      case /* Ro_var_type */10 :
          match = /* tuple */[
            "Ro_var_type",
            alt[0],
            $$null$1
          ];
          break;
      case /* Wo_optional_record_type */11 :
          match = /* tuple */[
            "Wo_optional_record_type",
            alt[0],
            map(alt[1])
          ];
          break;
      
    }
  }
  var meta = Curry._1(f, ant[1]);
  return {
          __node: true,
          __type: "type-annotation",
          name: match[0],
          value: match[1],
          meta: meta,
          children: match[2]
        };
}

function constr_to_js(f, constr) {
  var value = constr[0];
  var children = Json$BwaxMobile.json_arr(Plate$BwaxMobile.List.map((function (param) {
              return annot_type_to_js(f, param);
            }), constr[1]));
  var meta = Curry._1(f, constr[2]);
  return {
          __node: true,
          __type: "constructor",
          name: "constructor",
          value: value,
          meta: meta,
          children: children
        };
}

var is_defs_equal = Caml_obj.caml_equal;

function as_string(value) {
  var match = value[0];
  if (typeof match === "number" || match.tag !== /* V_literal */5) {
    return ;
  } else {
    var match$1 = match[0];
    if (typeof match$1 === "number" || match$1.tag !== /* String */2) {
      return ;
    } else {
      return match$1[0];
    }
  }
}

function as_bool(value) {
  var match = value[0];
  if (typeof match === "number" || match.tag !== /* V_literal */5) {
    return ;
  } else {
    var match$1 = match[0];
    if (typeof match$1 === "number" || match$1.tag !== /* Bool */4) {
      return ;
    } else {
      return match$1[0];
    }
  }
}

function as_int(value) {
  var match = value[0];
  if (typeof match === "number" || match.tag !== /* V_literal */5) {
    return ;
  } else {
    var match$1 = match[0];
    if (typeof match$1 === "number" || match$1.tag) {
      return ;
    } else {
      return match$1[0];
    }
  }
}

function as_float(value) {
  var match = value[0];
  if (typeof match === "number" || match.tag !== /* V_literal */5) {
    return ;
  } else {
    var match$1 = match[0];
    if (typeof match$1 === "number") {
      return NaN;
    } else {
      switch (match$1.tag | 0) {
        case /* Int */0 :
            return match$1[0];
        case /* Float */1 :
            return match$1[0];
        case /* String */2 :
        case /* Char */3 :
        case /* Bool */4 :
            return ;
        
      }
    }
  }
}

function as_option(v) {
  var match = v[0];
  if (typeof match === "number" || !(match.tag === /* V_tagged */6 && match[0] === "Just")) {
    return ;
  } else {
    var match$1 = match[1];
    if (match$1 && !match$1[1]) {
      return match$1[0];
    } else {
      return ;
    }
  }
}

function as_list(value) {
  var match = value[0];
  if (typeof match === "number" || match.tag !== /* V_list */1) {
    return ;
  } else {
    return match[0];
  }
}

function as_raw(value) {
  var match = value[0];
  if (typeof match === "number" || match.tag !== /* V_raw */7) {
    return ;
  } else {
    return Caml_option.some(match[0]);
  }
}

function extract_from_maybe(_v) {
  while(true) {
    var v = _v;
    var match = v[0];
    if (typeof match === "number" || match.tag !== /* V_tagged */6) {
      return v;
    } else {
      switch (match[0]) {
        case "Just" :
            var match$1 = match[1];
            if (match$1 && !match$1[1]) {
              _v = match$1[0];
              continue ;
            } else {
              return v;
            }
        case "Nothing" :
            if (match[1]) {
              return v;
            } else {
              return ;
            }
        default:
          return v;
      }
    }
  };
}

function get_field(name, value) {
  var match = value[0];
  if (typeof match === "number" || match.tag !== /* V_record */2) {
    return ;
  } else {
    return Plate$BwaxMobile.List.assoc(name, match[0]);
  }
}

function get_string(name, value) {
  return Plate$BwaxMobile.$$Option.and_then(as_string, Plate$BwaxMobile.$$Option.and_then(extract_from_maybe, Plate$BwaxMobile.$$Option.and_then((function (param) {
                        return get_field(name, param);
                      }), value)));
}

function get_bool(name, value) {
  return Plate$BwaxMobile.$$Option.and_then(as_bool, Plate$BwaxMobile.$$Option.and_then(extract_from_maybe, Plate$BwaxMobile.$$Option.and_then((function (param) {
                        return get_field(name, param);
                      }), value)));
}

function get_string_list(name, value) {
  var partial_arg = Plate$BwaxMobile.List.keep_map;
  return Plate$BwaxMobile.$$Option.map((function (param) {
                return partial_arg(as_string, param);
              }), Plate$BwaxMobile.$$Option.and_then(as_list, Plate$BwaxMobile.$$Option.and_then(extract_from_maybe, Plate$BwaxMobile.$$Option.and_then((function (param) {
                            return get_field(name, param);
                          }), value))));
}

function get_float(name, value) {
  return Plate$BwaxMobile.$$Option.and_then(as_float, Plate$BwaxMobile.$$Option.and_then(extract_from_maybe, Plate$BwaxMobile.$$Option.and_then((function (param) {
                        return get_field(name, param);
                      }), value)));
}

function get_int(name, value) {
  return Plate$BwaxMobile.$$Option.and_then(as_int, Plate$BwaxMobile.$$Option.and_then(extract_from_maybe, Plate$BwaxMobile.$$Option.and_then((function (param) {
                        return get_field(name, param);
                      }), value)));
}

function get_list(name, value) {
  return Plate$BwaxMobile.$$Option.and_then(as_list, Plate$BwaxMobile.$$Option.and_then(extract_from_maybe, Plate$BwaxMobile.$$Option.and_then((function (param) {
                        return get_field(name, param);
                      }), value)));
}

function is_decorated_value(v) {
  var tmp = v[0];
  if (typeof tmp === "number" || tmp.tag !== /* V_decorated */13) {
    return false;
  } else {
    return true;
  }
}

function has_decorated_value(value) {
  var proc_list = function (_vs) {
    while(true) {
      var vs = _vs;
      if (vs) {
        if (has_decorated_value(vs[0])) {
          return true;
        } else {
          _vs = vs[1];
          continue ;
        }
      } else {
        return false;
      }
    };
  };
  var match = value[0];
  if (typeof match === "number") {
    return false;
  } else {
    switch (match.tag | 0) {
      case /* V_tuple */0 :
      case /* V_list */1 :
          return proc_list(match[0]);
      case /* V_record */2 :
          return proc_list(Plate$BwaxMobile.List.map(Plate$BwaxMobile.snd, match[0]));
      case /* V_decorated */13 :
          return true;
      default:
        return false;
    }
  }
}

function build_monad_next(inf, m, n) {
  var flatMap_f_000 = /* Apply */Block.__(7, [
      /* tuple */[
        /* Ref */Block.__(16, ["flatMap"]),
        inf
      ],
      /* tuple */[
        /* Lambda */Block.__(13, [
            /* :: */[
              /* tuple */[
                /* Ptn_wildcard */0,
                inf
              ],
              /* [] */0
            ],
            n
          ]),
        inf
      ]
    ]);
  var flatMap_f = /* tuple */[
    flatMap_f_000,
    inf
  ];
  return /* tuple */[
          /* Apply */Block.__(7, [
              flatMap_f,
              m
            ]),
          inf
        ];
}

function build_monad_bind(inf, m, f) {
  var flatMap_f_000 = /* Apply */Block.__(7, [
      /* tuple */[
        /* Ref */Block.__(16, ["flatMap"]),
        inf
      ],
      f
    ]);
  var flatMap_f = /* tuple */[
    flatMap_f_000,
    inf
  ];
  return /* tuple */[
          /* Apply */Block.__(7, [
              flatMap_f,
              m
            ]),
          inf
        ];
}

function build_lambda(inf, ptn, expr) {
  return /* tuple */[
          /* Lambda */Block.__(13, [
              /* :: */[
                ptn,
                /* [] */0
              ],
              expr
            ]),
          inf
        ];
}

exports.string_of_literal = string_of_literal;
exports.string_of_tuple = string_of_tuple;
exports.string_of_list = string_of_list;
exports.string_of_value = string_of_value;
exports.string_of_pattern = string_of_pattern;
exports.val_equal = val_equal;
exports.Eval_exn = Eval_exn;
exports.compare_value = compare_value;
exports.make_getter = make_getter;
exports.arith_func = arith_func;
exports.match_pattern = match_pattern;
exports.evaluate = evaluate;
exports.apply_closure = apply_closure;
exports.apply_native = apply_native;
exports.apply_value = apply_value;
exports.apply_value_args = apply_value_args;
exports.apply_expr = apply_expr;
exports.make_constructor = make_constructor;
exports.evaluate_defs = evaluate_defs;
exports.trav_expr = trav_expr;
exports.trav_action_stmt = trav_action_stmt;
exports.trav_pattern = trav_pattern;
exports.trav_constr = trav_constr;
exports.trav_def = trav_def;
exports.trav_annot_type = trav_annot_type;
exports.string_of_unop = string_of_unop;
exports.string_of_arith_op = string_of_arith_op;
exports.string_of_equality_op = string_of_equality_op;
exports.string_of_compare_op = string_of_compare_op;
exports.string_of_logic_op = string_of_logic_op;
exports.string_of_binop = string_of_binop;
exports.string_of_exposed = string_of_exposed;
exports.expr_to_js = expr_to_js;
exports.def_to_js = def_to_js;
exports.pattern_to_js = pattern_to_js;
exports.annot_type_to_js = annot_type_to_js;
exports.constr_to_js = constr_to_js;
exports.is_defs_equal = is_defs_equal;
exports.as_string = as_string;
exports.as_bool = as_bool;
exports.as_int = as_int;
exports.as_float = as_float;
exports.as_option = as_option;
exports.as_list = as_list;
exports.as_raw = as_raw;
exports.extract_from_maybe = extract_from_maybe;
exports.get_field = get_field;
exports.get_string = get_string;
exports.get_bool = get_bool;
exports.get_string_list = get_string_list;
exports.get_float = get_float;
exports.get_int = get_int;
exports.get_list = get_list;
exports.is_decorated_value = is_decorated_value;
exports.has_decorated_value = has_decorated_value;
exports.build_monad_next = build_monad_next;
exports.build_monad_bind = build_monad_bind;
exports.build_lambda = build_lambda;
/* Dict-BwaxMobile Not a pure module */
