
import React, { Suspense, useContext, useEffect, useRef, useState, useTransition } from 'react';
import ReactDOM from 'react-dom';

import { getInstance, buildCacheKey, GLOBAL } from 'bwax/store/DataCache';

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'

// export { GLOBAL, GLOBAL_RUNTIME, USER, USER_RUNTIME } from 'bwax/store/DataCache';

import isEqual from 'lodash/isEqual';

export const DataPromiseContext = React.createContext();

export const DataPromiseProvider = props => {
    const { holder, children } = props
    return (
        <DataPromiseContext.Provider value={holder}>
            { children }
        </DataPromiseContext.Provider>
    );
}

export default function RenderWithData (props) {

    // Component, loadData, fallback
    const { isLazy, ...others } =  props;

    // 如果是 Lazy 则使用 useEffect 的实现方式； 否则，使用 promise / throwable 的方式
    if(isLazy) {
        return (
            <EffectMode {...others} />
        )
    } else {
        return (
            <PromiseMode {...others} />
        )
    }
}


async function forceLoadData(dataCache, loadData, cacheKey) {
    const data = await loadData();
    dataCache.set(cacheKey, data);
    return data
}


function EffectMode (props) {

    const { Component, loadData, fallback, cacheType = GLOBAL, dataKey, instanceKey: _unused, refreshingEnabled, ...others } = props;
    const dlc = useContext(DataLoaderContext);

    const cacheKey = buildCacheKey(cacheType, dataKey, dlc);
    const dataCache = getInstance(dlc);

    const [ data, setData ] = useState(undefined);

    // 用于 local refresh
    const [ refreshingAt, setRefreshingAt ] = useState(undefined);
    const [ refreshedAt, setRefreshedAt ] = useState(undefined);

    useEffect(() => {
        // 每次 cache key 变化，正常地找数据；
        const getData = (async () => {
            async function resolveData () {    
                const data = await loadData();
                dataCache.set(cacheKey, data);
                return data
            }
            const cached = dataCache.get(cacheKey);
            if(cached !== undefined) {
                return cached;        
            } else {
                return await resolveData();
            }
        });
        (async () => {
            const newData = await getData();
            if(data === newData ) {
                return 
            } else if(isEqual(data || {}, newData || {})) {
                return 
            } else {
                setData(newData);                 
            }
        })();
    }, [ cacheKey.toString() ]) 

    useEffect(() => {
        if(refreshingAt !== undefined) {
            // 每次 refresh，强制找到数据
            (async () => {
                const newData = await forceLoadData(dataCache, loadData, cacheKey);
                ReactDOM.unstable_batchedUpdates(() => {
                    setRefreshedAt(Date.now())
                    setData(newData);        
                });
            
            })();
        }
    }, [ refreshingAt ])     

    if(data === undefined) {
        return fallback || null
    };

    return (
        <Component {...{
            data,
            ...(refreshingEnabled ? { refreshedAt, refresh: () => { setRefreshingAt(Date.now()) } } : { }),
            ...others
        }} />
    )
}


function PromiseMode (props) {

    const { fallback = null, noSuspense, ...others } =  props;

    // const [ otherProps, setOtherProps ] = useState(others);

    // const [ _, startTransition ] = useTransition();

    // useEffect(() => {
    //     if(!noSuspense) {            
    //         const areSameProps = Object.keys(others).every(k => {
    //             return others[k] === otherProps[k];
    //             // console.log("K", k, others[k] === otherProps[k], others[k], otherProps[k]);
    //         });
    //         // console.log("Others", others, otherProps, others === otherProps, areSameProps);
    //         if(!areSameProps) {
    //             // console.log("Not the same props", others, otherProps);
    //             startTransition(() => {
    //                 setOtherProps(others);
    //             })   
    //         }
    //     }
    // }, [ others ])


    if(noSuspense) {
        return <WithData {...others } />
    }

    return (
        <Suspense fallback={fallback}>
            <WithData {...others } />
        </Suspense>
    )
}


function getData(dataCache, loadData, cacheKey, promiseHolder) {

    const stringKey = cacheKey.toString();
    const cached = dataCache.get(cacheKey);
    async function resolveData () {    

        let data;
        try {
            data = await loadData();            
        } catch(e) {
            data = { __error__: e }
        }
        dataCache.set(cacheKey, data);
        return data

    }

    if(cached !== undefined) {
        return [cached, undefined]

    } else {
        const promise = resolveData();
        if(promiseHolder){
            promise.then(_ => {
                delete promiseHolder[stringKey];
            })
            promiseHolder[stringKey] = promise;
        }
        return [undefined, promise]
    }
} 


function WithData ( { Component, loadData, cacheType = GLOBAL, dataKey, instanceKey, refreshingEnabled,  ...others }) {

    const promiseHolder = useContext(DataPromiseContext);

    const dlc = useContext(DataLoaderContext);
    const cacheKey = buildCacheKey(cacheType, dataKey, dlc);
    const dataCache = getInstance(dlc); 

    // 用于 local refresh
    const [ refreshingAt, setRefreshingAt ] = useState(undefined);
    const [ refreshedAt, setRefreshedAt ] = useState(undefined);

    const stringKey = cacheKey.toString();
    // 在前端 render 后，可以保存下来而不必重新查询（直到 remount，或者切换页面 - dataKey 变化)

    const localKey = instanceKey || stringKey;
        
    const localDataRef = useRef([localKey, undefined]);

    useEffect(() => {
        if(refreshingAt && refreshingEnabled ) {
            console.log("Refreshing", refreshingAt);
            (async () => {
                const data = await forceLoadData(dataCache, loadData, cacheKey);
                localDataRef.current = [localKey, data];
                setRefreshedAt(Date.now());
            })();
        }
    }, [ refreshingAt ]);

    function renderComponent (data) {
        return (
            <Component {...{
                data, 
                ...(refreshingEnabled ? { refreshedAt, refresh: () => { setRefreshingAt(Date.now()) } } : { }),
                ...others
            }}
            />
        )
    }

    if(localDataRef.current[0] === localKey && localDataRef.current[1] !== undefined) {
        return renderComponent(localDataRef.current[1]);
    }

    const [ data, loading ] = getData(dataCache, loadData, cacheKey, promiseHolder );

    if(data === undefined) {
        throw loading
    } else if(data && data.__error__) {
        return (
            <div className="p-4">
                Oops, Something went wrong.
            </div>
        )
        
    } else {
        localDataRef.current = [localKey, data];
        return renderComponent(data)
    };

    
}



