import React from 'react'

// import { mentionEntityType } from './mentionPluginHelper'

// copied here to avoid importing Draft.js
export const mentionEntityType = "MENTION"

const convertToReact = {
    entityToReact: (entity, originalText) => {
        //暂时不提供 originalText 来覆盖样式
        if (entity.type === mentionEntityType) {
            const { mention } = entity.data
            return <a target="_blank" href={`/entities/AuthEntityUser/records/${mention.key}`}></a>;
        }
        return null
    }
}

export { convertToReact }
