// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function toString(color) {
  if (typeof color === "number") {
    if (color >= 3891398) {
      if (color >= 4437302) {
        if (color >= 642505853) {
          if (color >= 867433699) {
            if (color >= 944645571) {
              if (color >= 989418936) {
                if (color >= 1058156409) {
                  return "divide";
                } else {
                  return "Y100";
                }
              } else if (color >= 971428514) {
                return "pressed";
              } else {
                return "success";
              }
            } else if (color >= 889612833) {
              if (color >= 911791967) {
                return "R100";
              } else {
                return "P100";
              }
            } else if (color >= 878523266) {
              return "O100";
            } else {
              return "N100";
            }
          } else if (color >= 745448462) {
            if (color >= 800896297) {
              if (color >= 803269440) {
                return "inverseLighterText";
              } else {
                return "H100";
              }
            } else if (color >= 789806730) {
              return "G100";
            } else {
              return "C100";
            }
          } else if (color >= 728032700) {
            if (color >= 734358895) {
              return "B100";
            } else {
              return "hover";
            }
          } else if (color >= 727634875) {
            return "H50";
          } else {
            return "inverseText";
          }
        } else if (color >= 5691729) {
          if (color >= 362795413) {
            if (color >= 455885158) {
              if (color >= 512905955) {
                return "danger";
              } else {
                return "containerBg";
              }
            } else if (color >= 434966211) {
              return "G50";
            } else {
              return "inverseBg";
            }
          } else if (color >= 164354652) {
            if (color >= 193755393) {
              return "highlightText";
            } else {
              return "warning";
            }
          } else if (color >= 58474434) {
            return "primary";
          } else {
            return "R50";
          }
        } else if (color >= 4437971) {
          if (color >= 4438417) {
            if (color >= 4438640) {
              return "Y90";
            } else {
              return "Y80";
            }
          } else if (color >= 4438194) {
            return "Y70";
          } else {
            return "Y60";
          }
        } else if (color !== 4437525) {
          if (color >= 4437748) {
            return "Y50";
          } else {
            return "Y30";
          }
        } else {
          return "Y40";
        }
      } else if (color >= 3990187) {
        if (color >= 4089422) {
          if (color >= 4090314) {
            if (color >= 4436856) {
              if (color >= 4437079) {
                return "Y20";
              } else {
                return "Y10";
              }
            } else if (color >= 4090537) {
              return "R90";
            } else {
              return "R80";
            }
          } else if (color >= 4089868) {
            if (color >= 4090091) {
              return "R70";
            } else {
              return "R60";
            }
          } else if (color >= 4089645) {
            return "R50";
          } else {
            return "R40";
          }
        } else if (color >= 3991079) {
          if (color >= 4088976) {
            if (color >= 4089199) {
              return "R30";
            } else {
              return "R20";
            }
          } else if (color >= 4088753) {
            return "R10";
          } else {
            return "P90";
          }
        } else if (color >= 3990633) {
          if (color >= 3990856) {
            return "P80";
          } else {
            return "P70";
          }
        } else if (color >= 3990410) {
          return "P60";
        } else {
          return "P50";
        }
      } else if (color >= 3940681) {
        if (color >= 3989295) {
          if (color >= 3989741) {
            if (color >= 3989964) {
              return "P40";
            } else {
              return "P30";
            }
          } else if (color >= 3989518) {
            return "P20";
          } else {
            return "P10";
          }
        } else if (color >= 3941127) {
          if (color >= 3941350) {
            return "O90";
          } else {
            return "O80";
          }
        } else if (color >= 3940904) {
          return "O70";
        } else {
          return "O60";
        }
      } else if (color >= 3939789) {
        if (color >= 3940235) {
          if (color >= 3940458) {
            return "O50";
          } else {
            return "O40";
          }
        } else if (color >= 3940012) {
          return "O30";
        } else {
          return "O20";
        }
      } else if (color !== 3891621) {
        if (color >= 3939566) {
          return "O10";
        } else {
          return "N80";
        }
      } else {
        return "N90";
      }
    } else if (color >= 3343487) {
      if (color >= 3591463) {
        if (color >= 3593247) {
          if (color >= 3890506) {
            if (color >= 3890952) {
              if (color >= 3891175) {
                return "N70";
              } else {
                return "N60";
              }
            } else if (color >= 3890729) {
              return "N50";
            } else {
              return "N40";
            }
          } else if (color >= 3890060) {
            if (color >= 3890283) {
              return "N30";
            } else {
              return "N20";
            }
          } else if (color >= 3889837) {
            return "N10";
          } else {
            return "H90";
          }
        } else if (color >= 3592355) {
          if (color >= 3592801) {
            if (color >= 3593024) {
              return "H80";
            } else {
              return "H70";
            }
          } else if (color >= 3592578) {
            return "H60";
          } else {
            return "H50";
          }
        } else if (color >= 3591909) {
          if (color >= 3592132) {
            return "H40";
          } else {
            return "H30";
          }
        } else if (color >= 3591686) {
          return "H20";
        } else {
          return "H10";
        }
      } else if (color >= 3541957) {
        if (color >= 3542849) {
          if (color >= 3543295) {
            if (color >= 3543518) {
              return "G90";
            } else {
              return "G80";
            }
          } else if (color >= 3543072) {
            return "G70";
          } else {
            return "G60";
          }
        } else if (color >= 3542403) {
          if (color >= 3542626) {
            return "G50";
          } else {
            return "G40";
          }
        } else if (color >= 3542180) {
          return "G30";
        } else {
          return "G20";
        }
      } else if (color >= 3344156) {
        if (color >= 3344602) {
          if (color >= 3541734) {
            return "G10";
          } else {
            return "C90";
          }
        } else if (color >= 3344379) {
          return "C80";
        } else {
          return "C70";
        }
      } else if (color !== 3343710) {
        if (color >= 3343933) {
          return "C60";
        } else {
          return "C40";
        }
      } else {
        return "C50";
      }
    } else if (color >= -242616772) {
      if (color >= 3293981) {
        if (color >= 3294873) {
          if (color >= 3343041) {
            if (color >= 3343264) {
              return "C30";
            } else {
              return "C20";
            }
          } else if (color >= 3342818) {
            return "C10";
          } else {
            return "B90";
          }
        } else if (color >= 3294427) {
          if (color >= 3294650) {
            return "B80";
          } else {
            return "B70";
          }
        } else if (color >= 3294204) {
          return "B60";
        } else {
          return "B50";
        }
      } else if (color >= 3293089) {
        if (color >= 3293535) {
          if (color >= 3293758) {
            return "B40";
          } else {
            return "B30";
          }
        } else if (color >= 3293312) {
          return "B20";
        } else {
          return "B10";
        }
      } else if (color >= -139500910) {
        if (color >= -66829956) {
          return "disabled";
        } else {
          return "tertiary";
        }
      } else if (color >= -215364664) {
        return "error";
      } else {
        return "P50";
      }
    } else if (color >= -944564230) {
      if (color >= -498798846) {
        if (color >= -399881517) {
          if (color >= -314154736) {
            return "lighterText";
          } else {
            return "inverseLightText";
          }
        } else if (color >= -432503308) {
          return "pageBg";
        } else {
          return "secondory";
        }
      } else if (color >= -856044371) {
        if (color >= -799236236) {
          return "Y50";
        } else {
          return "text";
        }
      } else if (color >= -910341463) {
        return "white";
      } else {
        return "link";
      }
    } else if (color >= -999567389) {
      if (color >= -977489170) {
        if (color >= -971527005) {
          return "lightText";
        } else {
          return "O50";
        }
      } else if (color >= -977586066) {
        return "info";
      } else {
        return "N60";
      }
    } else if (color !== -1055309158) {
      if (color >= -1043577565) {
        return "C50";
      } else {
        return "overlayBg";
      }
    } else {
      return "B50";
    }
  } else if (color[0] >= 5690856) {
    return color[1];
  } else {
    var match = color[1];
    return "rgba(" + (String(match[0]) + (", " + (String(match[1]) + (", " + (String(match[2]) + (", " + (String(match[3]) + ")")))))));
  }
}

function toColor(str) {
  switch (str) {
    case "B10" :
        return /* B10 */3293089;
    case "B100" :
        return /* B100 */734358895;
    case "B20" :
        return /* B20 */3293312;
    case "B30" :
        return /* B30 */3293535;
    case "B40" :
        return /* B40 */3293758;
    case "B50" :
        return /* B50 */3293981;
    case "B60" :
        return /* B60 */3294204;
    case "B70" :
        return /* B70 */3294427;
    case "B80" :
        return /* B80 */3294650;
    case "B90" :
        return /* B90 */3294873;
    case "C10" :
        return /* C10 */3342818;
    case "C100" :
        return /* C100 */745448462;
    case "C20" :
        return /* C20 */3343041;
    case "C30" :
        return /* C30 */3343264;
    case "C40" :
        return /* C40 */3343487;
    case "C50" :
        return /* C50 */3343710;
    case "C60" :
        return /* C60 */3343933;
    case "C70" :
        return /* C70 */3344156;
    case "C80" :
        return /* C80 */3344379;
    case "C90" :
        return /* C90 */3344602;
    case "G10" :
        return /* G10 */3541734;
    case "G100" :
        return /* G100 */789806730;
    case "G20" :
        return /* G20 */3541957;
    case "G30" :
        return /* G30 */3542180;
    case "G40" :
        return /* G40 */3542403;
    case "G50" :
        return /* G50 */3542626;
    case "G60" :
        return /* G60 */3542849;
    case "G70" :
        return /* G70 */3543072;
    case "G80" :
        return /* G80 */3543295;
    case "G90" :
        return /* G90 */3543518;
    case "H10" :
        return /* H10 */3591463;
    case "H100" :
        return /* H100 */800896297;
    case "H20" :
        return /* H20 */3591686;
    case "H30" :
        return /* H30 */3591909;
    case "H40" :
        return /* H40 */3592132;
    case "H50" :
        return /* H50 */3592355;
    case "H60" :
        return /* H60 */3592578;
    case "H70" :
        return /* H70 */3592801;
    case "H80" :
        return /* H80 */3593024;
    case "H90" :
        return /* H90 */3593247;
    case "N10" :
        return /* N10 */3889837;
    case "N100" :
        return /* N100 */867433699;
    case "N20" :
        return /* N20 */3890060;
    case "N30" :
        return /* N30 */3890283;
    case "N40" :
        return /* N40 */3890506;
    case "N50" :
        return /* N50 */3890729;
    case "N60" :
        return /* N60 */3890952;
    case "N70" :
        return /* N70 */3891175;
    case "N80" :
        return /* N80 */3891398;
    case "N90" :
        return /* N90 */3891621;
    case "O10" :
        return /* O10 */3939566;
    case "O100" :
        return /* O100 */878523266;
    case "O20" :
        return /* O20 */3939789;
    case "O30" :
        return /* O30 */3940012;
    case "O40" :
        return /* O40 */3940235;
    case "O50" :
        return /* O50 */3940458;
    case "O60" :
        return /* O60 */3940681;
    case "O70" :
        return /* O70 */3940904;
    case "O80" :
        return /* O80 */3941127;
    case "O90" :
        return /* O90 */3941350;
    case "P10" :
        return /* P10 */3989295;
    case "P100" :
        return /* P100 */889612833;
    case "P20" :
        return /* P20 */3989518;
    case "P30" :
        return /* P30 */3989741;
    case "P40" :
        return /* P40 */3989964;
    case "P50" :
        return /* P50 */3990187;
    case "P60" :
        return /* P60 */3990410;
    case "P70" :
        return /* P70 */3990633;
    case "P80" :
        return /* P80 */3990856;
    case "P90" :
        return /* P90 */3991079;
    case "R10" :
        return /* R10 */4088753;
    case "R100" :
        return /* R100 */911791967;
    case "R20" :
        return /* R20 */4088976;
    case "R30" :
        return /* R30 */4089199;
    case "R40" :
        return /* R40 */4089422;
    case "R50" :
        return /* R50 */4089645;
    case "R60" :
        return /* R60 */4089868;
    case "R70" :
        return /* R70 */4090091;
    case "R80" :
        return /* R80 */4090314;
    case "R90" :
        return /* R90 */4090537;
    case "Y10" :
        return /* Y10 */4436856;
    case "Y100" :
        return /* Y100 */989418936;
    case "Y20" :
        return /* Y20 */4437079;
    case "Y30" :
        return /* Y30 */4437302;
    case "Y40" :
        return /* Y40 */4437525;
    case "Y50" :
        return /* Y50 */4437748;
    case "Y60" :
        return /* Y60 */4437971;
    case "Y70" :
        return /* Y70 */4438194;
    case "Y80" :
        return /* Y80 */4438417;
    case "Y90" :
        return /* Y90 */4438640;
    case "blue" :
        return /* blue */-1055309158;
    case "containerBg" :
        return /* containerBg */455885158;
    case "cyan" :
        return /* cyan */-1043577565;
    case "danger" :
        return /* danger */512905955;
    case "disabled" :
        return /* disabled */-66829956;
    case "divide" :
        return /* divide */1058156409;
    case "error" :
        return /* error */-215364664;
    case "gray" :
        return /* gray */-999567389;
    case "green" :
        return /* green */434966211;
    case "highlightText" :
        return /* highlightText */193755393;
    case "honey" :
        return /* honey */727634875;
    case "hover" :
        return /* hover */728032700;
    case "info" :
        return /* info */-977586066;
    case "inverseBg" :
        return /* inverseBg */362795413;
    case "inverseLightText" :
        return /* inverseLightText */-399881517;
    case "inverseLighterText" :
        return /* inverseLighterText */803269440;
    case "inverseText" :
        return /* inverseText */642505853;
    case "lightText" :
        return /* lightText */-971527005;
    case "lighterText" :
        return /* lighterText */-314154736;
    case "link" :
        return /* link */-944564230;
    case "orange" :
        return /* orange */-977489170;
    case "overlayBg" :
        return /* overlayBg */-1068203659;
    case "pageBg" :
        return /* pageBg */-432503308;
    case "pressed" :
        return /* pressed */971428514;
    case "primary" :
        return /* primary */58474434;
    case "purple" :
        return /* purple */-242616772;
    case "red" :
        return /* red */5691729;
    case "secondory" :
        return /* secondory */-498798846;
    case "success" :
        return /* success */944645571;
    case "tertiary" :
        return /* tertiary */-139500910;
    case "text" :
        return /* text */-856044371;
    case "warning" :
        return /* warning */164354652;
    case "white" :
        return /* white */-910341463;
    case "yellow" :
        return /* yellow */-799236236;
    default:
      return /* `raw */[
              5690856,
              str
            ];
  }
}

exports.toString = toString;
exports.toColor = toColor;
/* No side effect */
