
// This file is generated. Don't modify it.
import React from 'react'
export default function PersonalProfileIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <circle id="PersonalProfileIcon-path-1" cx="8" cy="8" r="8"></circle>
                <path d="M6.73334338,8.38371992 C5.52977348,7.88604489 4.68292683,6.70045485 4.68292683,5.31707317 C4.68292683,3.48510424 6.16803107,2 8,2 C9.83196893,2 11.3170732,3.48510424 11.3170732,5.31707317 C11.3170732,6.70045485 10.4702265,7.88604489 9.26665662,8.38371992 C11.8362888,8.96076113 13.7560976,11.2561829 13.7560976,14 L2.24390244,14 C2.24390244,11.2561829 4.16371115,8.96076113 6.73334338,8.38371992 Z" id="PersonalProfileIcon-path-3"></path>
            </defs>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Personal/Profile">
                    <g>
                        <mask id="PersonalProfileIcon-mask-2" fill="white">
                            <use href="#PersonalProfileIcon-path-1"></use>
                        </mask>
                        <use id="Mask" fill={"#F0F3F5"} fillRule="nonzero" href="#PersonalProfileIcon-path-1"></use>
                        <g id="Icon/Filling/Profile" mask="url(#PersonalProfileIcon-mask-2)">
                            <g transform="translate(0.000000, 2.000000)">
                                <g>
                                    <mask id="PersonalProfileIcon-mask-4" fill="white">
                                        <use href="#PersonalProfileIcon-path-3"></use>
                                    </mask>
                                    <use id="Combined-Shape" fill={color} fillRule="nonzero" href="#PersonalProfileIcon-path-3"></use>
                                    <g id="Color/UI/Text" mask="url(#PersonalProfileIcon-mask-4)" fill={"#C8CACC"} fillRule="nonzero">
                                        <rect id="light-gray" x="0" y="0" width="16" height="16"></rect>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        
    )
}
