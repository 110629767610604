

import React, { useState, useEffect, useContext, useRef } from 'react'

import './TaskCard.less'

import Modal from 'bwax-ui/components/Modal'
import { Pressable } from 'bwax-ui/components/Button';

import AddRecordForm from 'bwax-ui/components/records/AddRecordForm'

import UsageQuotaContext from 'bwax-ui/ml/widget/ports/inbot/UsageQuotaContext';

import TaskJobDetail, { fieldPaths } from './TaskJobDetail'

import { useTrack } from 'bwax-ui/track';

export default function TaskCard(props) {

    const track = useTrack();

    const {
        name: taskName, entityName,

        isAddingOrViewing, jobId,

        label, desc, tags, icon, isLarge, component: Component,  ///  from taskSettings

        facade, onJobAdded = _ => { },

        viewEnv,

    } = props;

    const { webEnv, routeTo } = viewEnv;

    const { reloadUsageQuota, remainingQuota } = useContext(UsageQuotaContext) || {};

    const [addDialogOpen, setAddDialogOpen] = useState(isAddingOrViewing && !jobId);

    const [currentTaskJob, setCurrentTaskJob] = useState();
    const currentTaskJobRef = useRef();
    currentTaskJobRef.current = currentTaskJob;

    async function refreshJob(jobId) {
        const [result, error] = await facade.findById(jobId, { entityName: "OpenAI-任务", fieldPaths }, { forceRefreshing: true });
        if (!error && result) {
            if(currentTaskJobRef.current) {
                setCurrentTaskJob(result)
            }
        }
    }

    // refresh addedTask
    useEffect(() => {
        if (!currentTaskJob || Component) {
            // 有自定义 Component 的话，里面会自己 refresh，所以这里不再 refresh
            return
        }

        if (currentTaskJob.状态 == "Waiting" || currentTaskJob.状态 == "Processing") {
            setTimeout(() => {
                refreshJob(currentTaskJob.id)
            }, 1000);
        }
        if (currentTaskJob.状态 == "Waiting" && reloadUsageQuota) {
            setTimeout(() => {
                reloadUsageQuota();
            }, 800)
        }
        if (currentTaskJob.状态 == "Completed" && reloadUsageQuota) {
            setTimeout(() => {
                reloadUsageQuota();
            }, 800)
        }
    }, [currentTaskJob]);


    useEffect(() => {
        refreshJob(jobId);
    }, [jobId]);


    function renderAddDialog(closeModal) {

        if (Component) {
            return (
                <Component {...{
                    facade, entityName,

                    onBeforeAdd: _ => {
                        track("task_add_job", { "task_category": entityName });
                    },
                    onAdded: r => {
                        // TODO 转入 TaskJobDetail 模式，以允许用户开始执行
                        // 当 modal 关闭时，才把 TaskJob 添加到右侧列表中
                        if (r && r.任务) {
                            setCurrentTaskJob(r.任务)
                        }
                    },
                    fieldPaths: fieldPaths.map(p => "任务." + p),

                    closeModal,
                }} />
            )
        }

        return (
            <div className="lc-add-task-dialog">
                <div className="lc-task-title font-size-16">
                    <div className="icon">
                        {icon}
                    </div>
                    <div className='title'>
                        {label}
                    </div>
                </div>
                <div className="lc-task-desc font-size-13">
                    {desc}
                </div>
                <AddRecordForm {...{
                    facade, entityName,
                    onBeforeAdd: _ => {
                        track("task_add_job", { "task_category": entityName });
                    },
                    onAdded: r => {
                        // TODO 转入 TaskJobDetail 模式，以允许用户开始执行
                        // 当 modal 关闭时，才把 TaskJob 添加到右侧列表中
                        if (r && r.任务) {
                            setCurrentTaskJob(r.任务)
                        }
                    },
                    fieldPaths: fieldPaths.map(p => "任务." + p)
                }} />
            </div>
        )
    }

    function renderTaskJobDetail(closeModal) {

        if (Component) {
            return (
                <Component taskJob={currentTaskJob} facade={facade}
                    {...{ 
                        setRefreshedJob: setCurrentTaskJob, closeModal,
                        fieldPaths: fieldPaths.map(p => "任务." + p),
                        entityName,
                    }}
                />
            )
        }

        return (
            <TaskJobDetail taskJob={currentTaskJob} facade={facade}
                {...{ remainingQuota, reloadUsageQuota, setRefreshedJob: setCurrentTaskJob, closeModal }}
            />
        )
    }

    return (
        <>
            <Pressable onPress={_ => {
                track("task_start_to_use", { "task_category": entityName })
                setAddDialogOpen(true)
                routeTo("/task/" + taskName)

            }}>
                <div className="lc-task-card rounded">
                    <div className="lc-task-title">
                        <div className="icon">
                            {icon}
                        </div>
                        <div className='title'>
                            {label}
                        </div>
                    </div>
                    <div className="lc-task-desc font-size-13">
                        {desc}
                    </div>
                    <div className="task-tags font-size-12">
                        {(tags || []).map(t => {
                            return <div className="tag" key={t}>{t}</div>
                        })}
                    </div>
                </div>
            </Pressable>

            <Modal isOpen={addDialogOpen || currentTaskJob}
                className={isLarge ? "" : "max-w-3xl"}
                isLarge={isLarge}
                contentClassName="h-full"
                onOpenChange={open => {
                    if (!open && currentTaskJob && addDialogOpen) {
                        // tell upper level to add it to the job list.
                        onJobAdded(currentTaskJob)                                           
                    }
                    if(!open) {
                        routeTo("/task")
                    }
                    setCurrentTaskJob(null);
                    setAddDialogOpen(open)
                }}>
                {closeModal => {
                    if (currentTaskJob) {
                        return renderTaskJobDetail(closeModal)
                    }
                    return renderAddDialog(closeModal)
                }}
            </Modal>
        </>
    )
}


export function UpcomingTask(props) {
    return (
        <div className="lc-task-card upcoming">
            <div className="upcoming-tip">
                更多能力敬请期待
            </div>
        </div>
    )
}