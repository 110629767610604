
// This file is generated. Don't modify it.
import React from 'react'
export default function DeleteIcon (props) {

    const { color } = props
    
    return (
                
        
        
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M7.17504209,8 L5.52512627,6.35008418 C5.29732043,6.12227834 5.29732043,5.7529321 5.52512627,5.52512627 C5.7529321,5.29732043 6.12227834,5.29732043 6.35008418,5.52512627 L8,7.17504209 L9.64991582,5.52512627 C9.87772166,5.29732043 10.2470679,5.29732043 10.4748737,5.52512627 C10.7026796,5.7529321 10.7026796,6.12227834 10.4748737,6.35008418 L8.82495791,8 L10.4748737,9.64991582 C10.7026796,9.87772166 10.7026796,10.2470679 10.4748737,10.4748737 C10.2470679,10.7026796 9.87772166,10.7026796 9.64991582,10.4748737 L8,8.82495791 L6.35008418,10.4748737 C6.12227834,10.7026796 5.7529321,10.7026796 5.52512627,10.4748737 C5.29732043,10.2470679 5.29732043,9.87772166 5.52512627,9.64991582 L7.17504209,8 Z M8,2 C9.09615933,2 10.1105723,2.27403572 11.0432692,2.82211538 C11.9471199,3.34134875 12.6586513,4.0528801 13.1778846,4.95673077 C13.7259643,5.88942774 14,6.90384067 14,8 C14,9.09615933 13.7259643,10.1105723 13.1778846,11.0432692 C12.6586513,11.9471199 11.9471199,12.6586513 11.0432692,13.1778846 C10.1105723,13.7259643 9.09615933,14 8,14 C6.90384067,14 5.88942774,13.7259643 4.95673077,13.1778846 C4.0528801,12.6490358 3.34134875,11.9326968 2.82211538,11.0288462 C2.27403572,10.0961492 2,9.08654389 2,8 C2,6.91345611 2.27403572,5.90385082 2.82211538,4.97115385 C3.35096418,4.06730317 4.06730317,3.35096418 4.97115385,2.82211538 C5.90385082,2.27403572 6.91345611,2 8,2 Z" id="DeleteIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Filling/Delete">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="DeleteIcon-mask-2" fill="white">
                            <use href="#DeleteIcon-path-1"></use>
                        </mask>
                        <use id="cancel---material" fill={color} href="#DeleteIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
