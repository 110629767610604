
// This file is generated. Don't modify it.
import React from 'react'
export default function FinishIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M7.28744967,10.863449 C7.30708696,10.851626 7.32880439,10.8339355 7.35329862,10.8094412 L12.3030461,5.85969378 C12.4983082,5.66443163 12.4983082,5.34784914 12.3030461,5.152587 C12.1077839,4.95732485 11.7912015,4.95732485 11.5959393,5.152587 L6.99870707,9.74981923 L5.22990196,7.98101412 C5.03463982,7.78575198 4.71805733,7.78575198 4.52279518,7.98101412 C4.32753304,8.17627627 4.32753304,8.49285876 4.52279518,8.6881209 L6.64411553,10.8094412 C6.81931336,10.9846391 7.09217981,11.0026417 7.28744967,10.863449 Z M8.43540319,1.99998557 C9.53156251,1.99998557 10.5459754,2.27402129 11.4786724,2.82210096 C12.3825231,3.34133432 13.0940544,4.05286567 13.6132878,4.95671634 C14.1613675,5.88941331 14.4354032,6.90382625 14.4354032,7.99998557 C14.4354032,9.0961449 14.1613675,10.1105578 13.6132878,11.0432548 C13.0940544,11.9471055 12.3825231,12.6586368 11.4786724,13.1778702 C10.5459754,13.7259499 9.53156251,13.9999856 8.43540319,13.9999856 C7.33924386,13.9999856 6.32483093,13.7259499 5.39213396,13.1778702 C4.48828328,12.6490214 3.77675194,11.9326824 3.25751857,11.0288317 C2.70943891,10.0961348 2.43540319,9.08652947 2.43540319,7.99998557 C2.43540319,6.91344168 2.70943891,5.90383639 3.25751857,4.97113942 C3.78636737,4.06728875 4.50270636,3.35094976 5.40655703,2.82210096 C6.339254,2.27402129 7.34885929,1.99998557 8.43540319,1.99998557 Z" id="FinishIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Filling/Finish">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="FinishIcon-mask-2" fill="white">
                            <use href="#FinishIcon-path-1"></use>
                        </mask>
                        <use id="Combined-Shape" fill={color} href="#FinishIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
