

import React, { useEffect, useState, useRef } from 'react'

import useScreenSize from 'bwax-ui/auxiliary/useScreenSize'
import Button from 'bwax-ui/components/Button';

import { addQueryParam } from 'bwax/ml/lang/mod/builtin/StringHelper';

import throttle from "lodash/throttle";
import FileView from 'bwax-ui/components/FileView';
import { getFileType } from 'bwax-ui/components/FileIcon';

export default function DualFileView({ leftFile, rightFile, leftLabel = "原文", rightLabel = "译文", button, maxPages }) {

    const [showingLeft, setShowingLeft] = useState(false); // by default it is showing right:

    const leftScrollToRef = useRef();
    const rightScrollToRef = useRef();

    const screenSize = useScreenSize();

    function renderNarrow() {
        return (
            <div className="relative w-full h-full overflow-x-hidden">
                <div className="absolute top-0 left-0 w-full h-full duration-150" style={{
                    transform: showingLeft ? `translateX(0)` : "translateX(-100%)",
                }}>
                    <PDFView file={leftFile} maxPages={maxPages} onScrollTo={s => {
                        if (rightScrollToRef.current) {
                            // console.log(">> please right scroll to", s)
                            rightScrollToRef.current(s)
                        }
                    }} bindScrollTo={scrollTo => leftScrollToRef.current = scrollTo} withBackground={true} />
                </div>
                <div className="absolute top-0 left-0 w-full h-full duration-150" style={{
                    transform: !showingLeft ? `translateX(0)` : "translateX(100%)",
                }}>
                    <PDFView file={rightFile} maxPages={maxPages} onScrollTo={s => {
                        if (leftScrollToRef.current) {
                            // console.log(">> please left scroll to", s)
                            leftScrollToRef.current(s)
                        }                        
                    }} bindScrollTo={scrollTo => rightScrollToRef.current = scrollTo} withBackground={true} />
                </div>
                <div className="absolute bottom-16 w-full flex flex-col items-center">
                    <div className="flex flex-col gap-6 w-full max-w-[17rem] opacity-90">
                        {button}
                        <Button onPress={_ => setShowingLeft(prev => !prev)} color="violet" className="w-full shadow-md" >
                            切换至{showingLeft ? rightLabel : leftLabel}
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    function renderWide() {
        return (
            <div className="relative w-full h-full overflow-x-hidden flex">
                <div className="w-1/2 h-full flex flex-col">
                    <PDFView file={leftFile} onScrollTo={s => {
                        if (rightScrollToRef.current) {
                            // console.log(">> please right scroll to", s)
                            rightScrollToRef.current(s)
                        }
                    }} bindScrollTo={scrollTo => leftScrollToRef.current = scrollTo} withBackground={true} />
                </div>
                <div className="w-1/2 h-full flex flex-col relative">
                    <PDFView file={rightFile} onScrollTo={s => {
                        if (leftScrollToRef.current) {
                            // console.log(">> please left scroll to", s)
                            leftScrollToRef.current(s)
                        }
                    }} bindScrollTo={scrollTo => rightScrollToRef.current = scrollTo} withBackground={true} />
                    {button ? (
                        <div className="absolute bottom-16 w-full flex flex-col items-center">
                            <div className="flex flex-col gap-6 w-full max-w-[15rem] opacity-90">
                                {button}
                            </div>
                        </div>
                    ) : null}

                </div>
            </div>
        )
    }


    return screenSize == "L" ? renderWide() : renderNarrow();
}

export function PDFView({ file, onScrollTo, bindScrollTo, withBackground = false, maxPages }) {

    const ref = useRef()

    const unmounted = useRef(false);

    const [doc, setDoc] = useState();
    const docRef = useRef();  // 其实不用他了吧
    docRef.current = doc;

    const scrolledAtRef = useRef();

    const isPDF = getFileType(file) == "pdf";


    useEffect(() => {
        if (bindScrollTo) {
            bindScrollTo(({ scrollTop: s, isOnBody }) => {
                if (docRef.current) {

                    const scrollTop = (() => {
                        if(s < 1) {
                            // ratio
                            const height = Math.max(doc.documentElement.scrollHeight, doc.body.scrollHeight);
                            return Math.round(height * s)

                        } else {
                            return s
                        }
                    })();                    
                    if(scrolledAtRef.current !== scrollTop) {

                        const documentElement = docRef.current.documentElement;
                        const body = docRef.current.body;
    
                        if (scrollTop == 0) {
                            documentElement.scrollTop = 0;
                            body.scrollTop = 0;
                        } else {
                            if (isOnBody) {
                                body.scrollTop = scrollTop
                            } else {
                                documentElement.scrollTop = scrollTop
                            }
                        }
                    }


                }
            })
        }
    }, [doc]);

    const hasInitScrollSyncRef = useRef(false);
    function initScrollSync(iframe) {
        const doc = iframe.contentDocument;

        function onScroll() {
            if (!unmounted.current) {
                // staging 版本 scrollTop 的变化在 body; dev 版本 scrollTop 的变化则是在 documentElement
                // 暂时没有找到原因：

                const s = doc.documentElement.scrollTop || doc.body.scrollTop;
                scrolledAtRef.current = s;
                const isOnBody = doc.documentElement.scrollTop == 0 && doc.body.scrollTop > 0;

                // 使用比例来处理 scroll 同步
                const getScrollTop = () => {
                    if(isPDF) {
                        // PDF 使用绝对的 scroll 值，因为预览版可能只有前 15 页
                        return s
                    } else {
                        const height = Math.max(doc.documentElement.scrollHeight, doc.body.scrollHeight);
                        const ratio = s / height;
                        return ratio
                    }
                }


                onScrollTo({ scrollTop: getScrollTop(), isOnBody });
            }
        }
        // const throttledOnScroll = throttle(onScroll, 8);
        const throttledOnScroll = onScroll;

        doc.addEventListener("scroll", throttledOnScroll);
        // doc.body.addEventListener("scroll", throttledOnScroll);

        docRef.current = doc;
        setDoc(doc);

        hasInitScrollSyncRef.current = true;
    }


    useEffect(() => {
        const iframe = ref.current;
        if (iframe) {
            iframe.addEventListener("load", () => {
                if(!hasInitScrollSyncRef.current) {
                    initScrollSync(iframe)
                }
            });

            // 或者三秒钟后 同步
            setTimeout(() => {
                if(!hasInitScrollSyncRef.current) {
                    initScrollSync(iframe)
                }
            }, 3000);

            return () => {
                unmounted.current = true;
            }
        }
    }, []);

    const pdfUrl = addQueryParam("src", file.url, "/pdf2.html");

    return (
        // <iframe className="grow w-full h-full" src={pdfUrl} ref={ref} />
        <FileView className="grow w-full h-full" file={file} iframeRef={ref} maxPages={maxPages} />
    )
}


