
import { runDataQuery } from 'bwax/query/runClientQuery'


export default async function queryUser(dlc, selectionText) {
    const runQuery = runDataQuery({
        ...dlc,
        // sessionToken: "DUMMY" 
    })
    let queryText = `
        query { me { authUser ${selectionText} } } `

    const result = await runQuery(queryText)()
    const { errors, data } = JSON.parse(result)

    // TODO error handling

    if (data && data.me && data.me.authUser) {
        return data.me.authUser
    } else {
        return null
    }

}

