

import React, { useEffect, useRef, useState, useContext } from 'react'
import KnowledgeItemList from './components/KnowledgeItemList';

import Modal from 'bwax-ui/components/Modal'

import dayjs from 'dayjs';

import { getFileIcon } from "bwax-ui/components/FileIcon";

import UploadKnowledgeDocumentButton from './components/input/UploadKnowledgeDocumentButton';

import DropdownMenu from 'bwax-ui/components/DropdownMenu';
import { createDialogForm } from 'bwax-ui/components/Dialog';
import { toast } from 'bwax-ui/components/Toast';

import { addQueryParam, removeQueryParam, getQueryParam } from 'bwax/ml/lang/mod/builtin/StringHelper';

import { LuFolderInput } from 'react-icons/lu';

import DocumentDetail from './components/DocumentDetail';

import { useTrack } from 'bwax-ui/track';

import UsageQuotaContext from 'bwax-ui/ml/widget/ports/inbot/UsageQuotaContext';


export default function DocumentList({ data, events, facade, viewEnv }) {

    const track = useTrack();

    const {
        folderId, currentFolderName,
        folders, currentUserId,

        botAvatarUrl, userAvatar, userNickName,
    } = data;
    
    const {
        onAdded = _ => { }, onDeleted = _ => { },

        updateFolderName = _ => { },
        deleteFolder = _ => { },
        countFiles = _ => { },

    } = events;


    const { reloadUsageQuota, remainingQuota } = useContext(UsageQuotaContext) || {};

    const entityName = "OpenAI-知识文档";

    // const reloadDataRef = useRef();
    const updateDataRef = useRef();

    const fieldPaths = ["标题", "文档", "深入分析", "加入知识库", "创建时间", "文件夹.名称"];

    const [values, setValues] = useState();

    const { webEnv, routeTo, domainEnv } = viewEnv;


    async function loadData({ keyword, offset, pageSize }) {
        return await facade.listAll({
            entityName,
            search: keyword && keyword.trim().length > 0 ? { keyword, fields: ["标题"] } : undefined,
            condition: folderId ? [[
                // { field: "加入知识库", op: "eq", value: true }
                { field: "文件夹.id", op: "eq", value: folderId }
            ]] : [[]],
            sort: [
                { field: "创建时间", order: "DESC" }
            ],
            pageSize, offset, 
            fieldPaths,
        });
    };

    function renderItem(item) {
        return (
            <>
                <div className="main-part" style={item.加入知识库 ? {} : { opacity: 0.5 }}>
                    <div className="icon pt-0.5">
                        {getFileIcon(item.文档)}
                    </div>
                    <div>
                        {item.标题}
                        {(folderId || !item.文件夹) ? null : (
                            <div className="inline-block px-1 py-0.5 font-size-11 bg-[var(--gray2)] rounded ml-1.5">{item.文件夹.名称}</div>
                        )}
                    </div>
                </div>
                <div className="info-part">
                    {dayjs(item.创建时间).format("MM.DD")}
                </div>
            </>
        )
    }

    const isOutOfQuota = remainingQuota !== undefined && remainingQuota <= 0;

    const [detailToShow, setDetailToShow] = useState();

    useEffect(() => {

        // 只在最开始的时候根据 doc 加载
        // 
        const docId = getQueryParam("docId",  webEnv.currentURL);

        if(docId) {

            (async () => {
                const [ result, error ] = await facade.findById(docId, {
                    entityName,                    
                    fieldPaths,
                });
                if(error) {
                    console.error(error)
                } else if(result) {
                    setDetailToShow(result)
                }

            })()

        }

    }, []);


    async function updateDocument(docId, formData) {
        const [result, error] = await facade.update({
            entityName,
            formData,
            id: docId,
            fieldPaths,
        });
        if (!error && result && updateDataRef.current) {
            updateDataRef.current(prevData => {
                return prevData.map(doc => doc.id == docId ? result : doc)
            })
            if (detailToShow && detailToShow.id == result.id) {
                setDetailToShow(result);
            }
        }
    }

    async function deleteDocument(docId) {
        const [result, error] = await facade.delete({
            entityName,
            id: docId,
        });
        if (!error && updateDataRef.current) {
            updateDataRef.current(prevData => {
                return prevData.filter(doc => doc.id != docId)
            })
        }
        if (!error) {
            onDeleted(docId)
        }
    }

    async function moveDocument(docId, targetFolderId) {
        const [result, error] = await facade.update({
            entityName,
            formData: { 文件夹: targetFolderId },
            id: docId,
            fieldPaths,
        });
        if (!error && result) {
            if (folderId) {
                if (targetFolderId !== folderId) {
                    if (updateDataRef.current) {
                        updateDataRef.current(prevData => {
                            return prevData.filter(doc => doc.id != docId)
                        })
                    }
                } else {
                    // move in here; 

                    console.log("WARNING: shouldn't be here.")
                    // if (reloadDataRef.current) {
                    //     reloadDataRef.current();
                    // }
                }
            } else {
                // 所有文档的公共文件夹
                if (updateDataRef.current) {
                    updateDataRef.current(prevData => {
                        return prevData.map(doc => doc.id != docId ? doc : result)
                    })
                }

            }

            countFiles();
        }
        return [result, error];
    }

    function getItemMenu(item) {
        return [
            {
                label: "加入知识库",
                icon: <i className='bx bx-folder-plus'></i>,
                onSelect: async _ => {
                    // 
                    updateDocument(item.id, { 加入知识库: true });

                    setTimeout(() => {
                        reloadUsageQuota();
                    }, 2000)
                },
                hidden: item.加入知识库,
                isDisabled: isOutOfQuota,
            },
            {
                label: "从知识库移除",
                icon: <i className='bx bx-folder-minus'></i>,
                onSelect: async _ => {
                    // 
                    updateDocument(item.id, { 加入知识库: false })
                },
                hidden: !item.加入知识库
            },
            {
                label: "移至文件夹",
                icon: <LuFolderInput />,
                subItems: (folders || []).map(f => {
                    return {
                        label: f.名称,
                        isDisabled: item.文件夹 ? f.id == item.文件夹.id : false,

                        onSelect: async _ => {
                            const originalFolderId = item.文件夹 ? item.文件夹.id : null;
                            const [result, error] = await moveDocument(item.id, f.id);
                            if (!error) {
                                toast({
                                    title: "文档已移至" + f.名称,
                                    undoAction: async _ => moveDocument(item.id, originalFolderId),
                                    duration: 3000
                                });
                            }

                        }
                    }
                })
            },
            {
                label: "删除文档",
                icon: <i className='bx bx-trash'></i>,
                onSelect: async _ => {
                    deleteDocument(item.id);

                },
                isDisabled: item.加入知识库
            },
        ].filter(x => !x.hidden)
    };


    function renderFolderActionMenu() {
        if (!folderId) {
            return null
        }

        const menuItems = [{
            label: "重命名", onSelect: _ => {

                createDialogForm({
                    title: "修改文件夹名称",
                    description: "",
                    fields: [{
                        name: "名称",
                        initialValue: currentFolderName,
                    }],
                    showLabel: false,
                    onDataCollected: async data => {
                        // console.log("collected data", data);
                        if (data.名称 !== undefined) {
                            const originalTitle = currentFolderName;
                            const [result, error] = await updateFolderName(folderId, data.名称);
                            if (!error) {
                                toast({
                                    title: "文件夹名称已修改",
                                    undoAction: async _ => { updateFolderName(folderId, originalTitle) },
                                    duration: 3000
                                });
                            }
                        }

                    }
                })
            },
            icon: <i className='bx bx-edit' ></i>
        }, {
            label: "删除",
            icon: <i className='bx bx-trash'></i>,
            isDisabled: (values && values.length > 0),
            onSelect: async _ => {
                // const [result, error] = await archivePersona(item.id);
                // if (!error) {
                //     toast({ title: "角色已移入回收站", undoAction: async _ => { unArchivePersona(item.id) }, duration: 3000 });
                // }
                const [result, error] = await deleteFolder(folderId);
                if (!error) {
                    toast({ title: "文件夹已被删除", duration: 2000 });
                }

            }
        }]

        return (
            <DropdownMenu items={menuItems}>
                <i className='bx bx-dots-horizontal-rounded' />
            </DropdownMenu>
        )

    }

    return (
        <>
            <KnowledgeItemList {...{
                title: (
                    <>
                        <i className='bx bx-folder font-size-16' />
                        {folderId ? (currentFolderName || "") : "全部文档"}
                    </>
                ),
                loadData, renderItem, getItemMenu,

                onData: setValues,

                dataKey: folderId || "all",

                emptyTip: "文件夹里目前没有文档",

                onItemPress: item => {
                    // 
                    track("document_open_document_detail");
                    routeTo(addQueryParam("docId", item.id, webEnv.currentURL));
                    setDetailToShow(item)
                },

                actionButtons: (
                    <>
                        <UploadKnowledgeDocumentButton
                            folderId={folderId}
                            onUploaded={docs => {
                                // 1. add to documents,     
                                if (updateDataRef.current) {
                                    updateDataRef.current(prevData => {
                                        return [...docs, ...prevData]
                                    })
                                }
                                if (onAdded) {
                                    onAdded(docs);
                                }
                            }}
                            fieldPaths={fieldPaths}
                        />
                        {renderFolderActionMenu()}
                    </>
                ),

                // bindReloadData: reloadData => {
                //     reloadDataRef.current = reloadData
                // },
                bindUpdateData: updateData => {
                    updateDataRef.current = updateData
                }
            }} />
            <Modal isOpen={!!detailToShow} 
                // className="!h-[95vh] !max-h-[95vh] sm:!w-[95vw]" 
                isLarge={true}
                contentClassName="h-full" onOpenChange={isOpen => {
                if (!isOpen) {
                    setDetailToShow();
                    routeTo(removeQueryParam("docId", webEnv.currentURL))
                }
            }}>
                {detailToShow ? (
                    <DocumentDetail document={detailToShow} updateDocument={updateDocument} facade={facade}
                        currentUserId={currentUserId}
                        reloadUsageQuota={reloadUsageQuota}
                        
                        routeTo={routeTo}
                        viewEnv={viewEnv}
                        remainingQuota={remainingQuota}
                        {...{ botAvatarUrl, userAvatar, userNickName, }}
                    />
                ) : null}
            </Modal>
        </>
    )
}
