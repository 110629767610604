
export default function resolveContentType(str) {
    /// the split the content by the first line
    const lineBreakIndex = str.indexOf('\n')
    if (lineBreakIndex === -1) {
        return ({
            contentType: "HTML",
            content: str
        })
    } else {
        const firstLine = str.substring(0, lineBreakIndex)
        let meta = null
        try {
            meta = JSON.parse(firstLine)
        } catch (e) { }

        if (meta !== null) {
            return {
                ...meta,
                content: str.substring(lineBreakIndex + 1)
            }
        } else {
            return ({
                contentType: "HTML",
                content: str
            })
        }
    }
}
