import React from 'react'

import {
    DEFAULT_BLOCK_HTML,
    ALLIGNMENT_SUPPORT_TYPES,
    SUPPORT_STYLES,
    shouldStyleInclude
} from './blockAlignmentHelpers'

import { identity, pickBy } from 'bwax/list'

// renderer should be defined separately:
const convertFromHTML = {
    htmlToBlock: (nodeName, node) => {

        const typeName = Object.keys(ALLIGNMENT_SUPPORT_TYPES).find((t) => {
            return ALLIGNMENT_SUPPORT_TYPES[t].type === nodeName
        })

        if (typeName) {

            const styleForBlockData = pickBy(
                node.style, shouldStyleInclude
            )

            return {
                type: typeName,
                data: {
                    ...styleForBlockData
                }
            }

        }
    },
}
const convertToHTML = {
    blockToHTML: (block) => {

        if (ALLIGNMENT_SUPPORT_TYPES[block.type]) {

            const component = ALLIGNMENT_SUPPORT_TYPES[block.type]
            const style = pickBy(block.data, shouldStyleInclude)

            return React.cloneElement(component, {
                ...component.props,
                style
            })
        }
    }
}

const convertToReact = {

    blockToReact: (block) => {

        if (ALLIGNMENT_SUPPORT_TYPES[block.type]) {

            const component = ALLIGNMENT_SUPPORT_TYPES[block.type]
            const style = pickBy(block.data, shouldStyleInclude)

            if (Object.keys(style || {}).length === 0) {
                return null
            } else {
                const element = React.cloneElement(component, {
                    ...component.props,
                    style
                })

                if(block.type == "ordered-list-item") {
                    return {
                      element,
                      nest: <ol />
                    }
                  } else if(block.type == "unordered-list-item") {
            
                    return {
                      element,
                      nest: <ul />
                    }
            
                  } else {
                    return element
                  }
            }
        }
    }
}

export { convertFromHTML, convertToHTML, convertToReact }
