import React, { Suspense, useEffect, useRef, useState } from 'react'
import { ReactEditor, useSelected } from 'slate-react'
import { Transforms } from 'slate'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import isEqual from 'lodash/isEqual'

import { isQQPlayURL } from 'bwax-ui/auxiliary/richtext/plugins/media/mediaTypes'
import { ratioInnerStyle, ratioOuterStyle, iframeStyle, ratioLOCALOuterStyle } from './styles'

import { renderVideo, renderAudio as renderAudioComp } from '../utils/MediaHelper'

import { MdContentCopy, MdDelete } from 'react-icons/md'

const audioButtons = ["audioLeft", "audioCenter", "audioRight", "seperator", "remove", "seperator", "copy"]

export default function Media({ editor, keepInlineToolbar, updateSelectedNode, updateToolbarButtons, element, attributes, children, 
        brickOpenedAt
    }) {

    const { url } = element

    const selected = useSelected()
    const selectedStyle = selected ? {
        boxShadow: "0 0 2px rgb(0 0 0 / 30%), 0 0 4px #6ca9d9"
    } : {}

    const [lastActiveSelection, setLastActiveSelection] = useState(null)
    const mediaContainerRef = useRef()
    const { selection } = editor

    useEffect(() => {
        if(element.type === "audio") {
            if (selected) {
                updateSelectedNode(mediaContainerRef.current)
                updateToolbarButtons(audioButtons)
                setLastActiveSelection(selection)
            } else if (!keepInlineToolbar && (lastActiveSelection && !isEqual(selection, lastActiveSelection))) {
                updateSelectedNode(null)
                updateToolbarButtons(null)
            }
        }
        
    }, [selected])

    function createRemoveBlockButton() {
        return (
            <button className="editor-button" onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()

                Transforms.removeNodes(editor, {
                    at: ReactEditor.findPath(editor, element)
                })
            }}>
                <MdDelete style={{ fontSize: 14 }} />
            </button>
        )
    }

    function createCopyLinkButton() {
        return (
            <button className="editor-button">
                {
                    CopyToClipboard ?
                        <CopyToClipboard text={url} onCopy={() => alert('复制视频链接成功')}>
                            <MdContentCopy style={{ fontSize: 14 }} />
                        </CopyToClipboard> :
                        <MdContentCopy style={{ fontSize: 14 }} />
                }
            </button>
        )
    }

    function renderAudio() {
        const { align } = element
        const currentNodePath = editor.children.indexOf(element)

        function focusAudio() {
            Transforms.select(editor, [currentNodePath, 0])
        }

        return (
            <div className='editor-audio-block' style={{ padding: "0.75rem", display: 'flex', justifyContent: align || 'left',  }}
                onClick={() => focusAudio()}
            >
                { renderAudioComp(url) }
            </div>
        )
    }

    const videoContainerStyle = isQQPlayURL(url) ? { ...selectedStyle, ...ratioOuterStyle } : { ...selectedStyle, ...ratioLOCALOuterStyle }

    return (
        <figure {...attributes}>   
            {children}
            <div contentEditable={false} style={element.type === "video" ? videoContainerStyle : selectedStyle} 
                ref={mediaContainerRef}
            >
                { element.type === "video" ? renderVideo(url) : renderAudio()}
                {/* <div className="editor-video-block-scarf"/> */}
                {
                    element.type === "video" ? 
                        <div className="editor-video-block-tool">
                            {createRemoveBlockButton()}
                            <span className="seperator"></span>
                            {createCopyLinkButton()}
                        </div>
                    : null
                }
                {
                    brickOpenedAt && element.type === "video" ? (
                        <div className='editor-block-mask'/>
                    ) : null
                }
            </div>
        </figure>
    )
}
