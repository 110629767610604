

import React from 'react'

import ClipLoader from "react-spinners/ClipLoader"

import './Loading.less';

export default function Loading({style}) {
    return (
        <div className="lc-loading" style={style}>
            <ClipLoader size={24} color="var(--gray8)" />
        </div>
    )
}
