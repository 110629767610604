

import React, { useState } from 'react'
import { UploadIcon, QuestionMarkCircledIcon } from '@radix-ui/react-icons';
import { MdReport } from 'react-icons/md'

import SelectFileButton from 'bwax-ui/components/inputs/SelectFileButton';
import TextInput from 'bwax-ui/components/inputs/TextInput';
import Checkbox from 'bwax-ui/components/inputs/Checkbox';
import Tooltip from 'bwax-ui/components/Tooltip';
import { resolveFromURL } from 'bwax-ui/auxiliary/richtext/plugins/media/mediaTypes'
import Button from 'bwax-ui/components/Button'
import PopoverTrigger from 'bwax-ui/components/PopoverTrigger';
import Modal from 'bwax-ui/components/Modal'

export default function UploadMediaModalContent({ addBlockModalOpen, onOpenChange, mediaType, uploadMedia, hideModal, onConfirm, setModalCanBeCanceledOutside }) {

    const [mediaUrl, setMediaUrl] = useState('')
    const [uploadLocalVideo, setUploadLocalVideo] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [localMediaUrl, setLocalMediaUrl] = useState('')
    const [uploadFile, setUploadFile] = useState(null)
    const [progress, setProgress] = useState({ loaded: 0 })
    const [cancelUploadFn, setCancelUploadFn] = useState(null)

    const mediaTypeObj = {
        'video': '视频',
        'audio': '音频'
    }
    const mediaTypeStr = mediaTypeObj[mediaType]

    const mediaFileTypes = mediaType === 'video' ? ['mp4', 'mov'] : ['ogg', 'mp3', 'wav']

    const CANCEL_FUNCTION_MSG = '取消上传'

    function renderUploadProgress() {

        if (!uploadFile) {
            return null
        }

        const progressRate = progress.total ? progress.loaded / progress.total : 0
        const progressRatePercent = Math.round((progressRate * 100)) + "%"

        return (
            <div className="mt-5 bg-[#f7f7f7] p-2.5 flex flex-col gap-2.5">
                <div className="flex justify-between">
                    <div>
                        <a
                            target="_blank"
                            href={localMediaUrl || '#'} >
                            {uploadFile.name}
                            
                        </a>
                        {
                            cancelUploadFn ?
                                <PopoverTrigger content={({ close, overlayProps}) => {
                                    return (
                                        <div className='flex py-3 px-4 gap-3'>
                                            <div className='flex items-center gap-1.5 text-xs'>
                                                <MdReport style={{ color: `var(--violet11)`}}/>
                                                {`确定取消上传${mediaTypeStr}?`}
                                            </div>
                                            <div className='flex gap-1.5 self-end'>
                                                <Button size="small" style={{ fontSize: 12 }} onClick={() => close()}>
                                                    取消
                                                </Button>
                                                <Button size="small" appearance="primary" style={{ fontSize: 12 }} onClick={() => {
                                                    cancelUploadFn(CANCEL_FUNCTION_MSG)
                                                    close()
                                                }}>
                                                    确定
                                                </Button>
                                            </div>
                                        </div>
                                    )
                                }} placement="top">
                                    <span className="text-[#666] text-xs ml-2.5">取消上传</span>
                                </PopoverTrigger>
                            : null
                        }
                    </div>
                    {progressRatePercent}
                </div>
                <div>
                    <div className="w-full h-2 bg-white rounded">
                        <div
                            style={{ width: progressRatePercent }}
                            className="h-2 rounded bg-[var(--violet11)]"></div>
                    </div>
                </div>
                <div>
                    {
                        progress && progress.loaded !== progress.total ?
                            '上传中...' :
                            uploading && (progress.loaded === progress.total) ?
                                `${mediaTypeStr}转码中...`
                                : '上传完成'
                    }
                </div>
            </div>
        )
    }

    function confirm () {
        if (!mediaUrl.trim() && !uploadLocalVideo) {
            alert('播放地址不能为空')
            return
        }

        if (uploadLocalVideo && !localMediaUrl.trim()) {
            alert(`没有本地${mediaTypeStr}`)
            return
        }

        const mediaInfo = uploadLocalVideo ? resolveFromURL(localMediaUrl.trim()) : resolveFromURL(mediaUrl.trim())
        if(onConfirm && mediaInfo) {
            onConfirm(mediaInfo)
        }

        hideModal()
    }

    function cancelModal() {
        if(typeof(window) !== 'undefined') {
            if (uploading) {
                const result = window.confirm(`确定离开此页面？将取消上传${mediaTypeStr}！`)
                if (result) {
                    cancelUploadFn && cancelUploadFn(CANCEL_FUNCTION_MSG)
                    hideModal()
                }
            } else if (localMediaUrl) {
                const result = window.confirm(`确定离开此页面？将清空已上传的${mediaTypeStr}！`)
                if (result) {
                    hideModal()
                }
            } else {
                hideModal()
            }
        } else {
            hideModal()
        }
        
    }

    return (
        <Modal isDismissable className="max-w-2xl pt-3 px-8 pb-16" isOpen={addBlockModalOpen} onOpenChange={open => onOpenChange(open)}>
            <div className='flex justify-center flex-col gap-5'>
                <TextInput styled className="w-full" placeholder={`直接输入${mediaTypeStr}的播放地址`} aria-label={"mediaUrl"} 
                    value={mediaUrl} onChange={setMediaUrl}
                />
                <div className='flex self-start items-center gap-2'>
                    <Checkbox color="violet" checked={uploadLocalVideo} 
                        onChange={checked => setUploadLocalVideo(checked)}
                    />
                    {`上传本地${mediaTypeStr}`}
                    <Tooltip className="text-sm" text={`${mediaTypeStr}上传大小限制为100MB，当前支持上传的${mediaTypeStr}格式为${mediaFileTypes.join("、")}`}> 
                        <QuestionMarkCircledIcon/>
                    </Tooltip>
                </div>
                {
                    uploadLocalVideo ? (
                        <div>
                            <SelectFileButton
                                accept={mediaFileTypes.map(t => `.${t}`).join(",")}
                                disabled={uploading}
                                showUploadList={false}
                                onSelectFile={file => {
                                    if(file) {
                                        if (file.size > 100 * 1024 * 1024) {
                                            alert("文件大小不能超过100MB");
                                            return
                                        }
                                        setUploadFile(file)
                                        setUploading(true)
                                        setModalCanBeCanceledOutside(false)

                                        if(uploadMedia) {
                                            uploadMedia(
                                                file,
                                                (evt) => setProgress({
                                                    loaded: evt.loaded,
                                                    total: evt.total
                                                }),
                                                c => setCancelUploadFn(() => c),
                                                (errs, url) => { 
                                                    setUploading(false)
                                                    setCancelUploadFn(null)

                                                    if (errs) {
                                                        alert(`上传失败：${errs}`);
                                                        console.error(errs);
                                                        setModalCanBeCanceledOutside(true)
                                                    } else {
                                                        setLocalMediaUrl(url)
                                                        setModalCanBeCanceledOutside(false)
                                                    }
                                                },
                                                error => {
                                                    if (error && error.message) {
                                                        if (error.message.match(new RegExp(CANCEL_FUNCTION_MSG))) {
                                                            setUploadFile(null)
                                                            setProgress({ loaded: 0 })
                                                            setCancelUploadFn(null)
                                                            setUploading(false)
                                                        } else {
                                                            alert(error.message);
                                                            console.error(error.message);
                                                        }
                                                    }
                                                }
                                            )
                                        }
                                    }
                                }}
                            >
                                <UploadIcon style={{
                                    width: 14,
                                    marginRight: "0.5rem"
                                }}></UploadIcon>
                                {`点击上传${mediaTypeStr}`}
                            </SelectFileButton>
                            {renderUploadProgress()}
                        </div>
                    ) : null
                }
                <div className='flex gap-2.5 absolute bottom-3 right-4'>
                    <Button size="small" onClick={() => cancelModal()}>
                        取消
                    </Button>
                    <Button size="small" color="grass" appearance="primary" isDisabled={uploading} onClick={() => confirm()}>
                        确定
                    </Button>
                </div>
            </div>
        </Modal>
        
    )
}
