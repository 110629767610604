
import React from 'react'

import { Transforms } from 'slate'


import { imageAlignStyle } from '../../../RichTextRenderer'

import { MdLaptop, MdEast, MdWest } from 'react-icons/md'

const imageAlignIcons = {
    "left": <MdWest style={{ fontSize: 16 }} />,
    "center": <MdLaptop style={{ fontSize: 14 }}/>,
    "right": <MdEast style={{ fontSize: 16 }} />
}

export default function ImageAlignButton({editor, align }) {

    function alignImage () {

        Transforms.unsetNodes(
            editor,
            ["margin", "marginLeft", "marginRight", "float", "align"],
            { match: (node) => node.type === "image" }
        )

        let newProperty = imageAlignStyle[align]

        Transforms.setNodes(
            editor,
            newProperty,
            { match: (node) => node.type === "image" }
        )
    }

    const imageAlignIcon = imageAlignIcons[align]

    return (
        <button className="editor-button" onClick={() => alignImage()}>
           { imageAlignIcon }
        </button>
    )
}
