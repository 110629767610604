
// This file is generated. Don't modify it.
import React from 'react'
export default function FindIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M8,14 L3,14 C2.44771525,14 2,13.5522847 2,13 L2,3 C2,2.44771525 2.44771525,2 3,2 L13,2 C13.5522847,2 14,2.44771525 14,3 L14,8 C14,8.27614237 13.7761424,8.5 13.5,8.5 C13.2238576,8.5 13,8.27614237 13,8 L13,3 L3,3 L3,13 L8,13 C8.27614237,13 8.5,13.2238576 8.5,13.5 C8.5,13.7761424 8.27614237,14 8,14 Z M4.5,4 L8.5,4 C8.77614237,4 9,4.22385763 9,4.5 C9,4.77614237 8.77614237,5 8.5,5 L4.5,5 C4.22385763,5 4,4.77614237 4,4.5 C4,4.22385763 4.22385763,4 4.5,4 Z M4.5,6 L6.5,6 C6.77614237,6 7,6.22385763 7,6.5 C7,6.77614237 6.77614237,7 6.5,7 L4.5,7 C4.22385763,7 4,6.77614237 4,6.5 C4,6.22385763 4.22385763,6 4.5,6 Z M11.5852544,10.8795209 L13.8490795,13.143346 C14.0443417,13.3386082 14.0443417,13.6551907 13.8490795,13.8504528 C13.6538174,14.045715 13.3372349,14.045715 13.1419727,13.8504528 L10.8778687,11.5863488 C10.4828179,11.847774 10.0091861,12 9.5,12 C8.11928813,12 7,10.8807119 7,9.5 C7,8.11928813 8.11928813,7 9.5,7 C10.8807119,7 12,8.11928813 12,9.5 C12,10.0098962 11.8473491,10.4841389 11.5852544,10.8795209 Z M9.5,11 C10.3284271,11 11,10.3284271 11,9.5 C11,8.67157288 10.3284271,8 9.5,8 C8.67157288,8 8,8.67157288 8,9.5 C8,10.3284271 8.67157288,11 9.5,11 Z" id="FindIcon-path-1"></path>
            </defs>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Find">
                    <g>
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="FindIcon-mask-2" fill="white">
                            <use href="#FindIcon-path-1"></use>
                        </mask>
                        <use id="Mask" fill={color} fillRule="nonzero" href="#FindIcon-path-1"></use>
                        <g id="Color/UI/Text" mask="url(#FindIcon-mask-2)" fill={color} fillRule="nonzero">
                            <rect id="light-gray" x="0" y="0" width="16" height="16"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        
    )
}
