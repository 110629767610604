

import React, { useState, useEffect, useContext } from 'react'

import './UsagePanel.less';

import Button from 'bwax-ui/components/Button'
import Dialog from 'bwax-ui/components/Dialog'

import numeral from 'numeral'
import dayjs from 'dayjs';
import UsageQuotaContext from '../UsageQuotaContext';

import SubscriptionPayment_inbot from '../SubscriptionPayment_inbot';

import getImageURL from 'bwax-ui/getImageURL'

import { useTrack } from 'bwax-ui/track';

export default function UsagePanel({ viewEnv }) {

    const { totalUsed, usedQuota, quota, currentPlanId, currentPlanExpiryDate, facade, reloadUsageQuota, } = useContext(UsageQuotaContext) || {};

    const track = useTrack();
    
    const { routeTo } = viewEnv;

    function fm(num, decimals = 0) {
        const dec = Array(decimals).fill("0").join("");
        return numeral(num).format("0,0" + (decimals > 0 ? "." + dec : ""))
    }

    function fmk(num) {
        return numeral(num).format("0,0.0a")
    }

    const [currentPlan, setCurrentPlanPlan] = useState();

    async function loadPlan() {
        const [data, error] = await facade.findById(currentPlanId, {
            entityName: "套餐",
            fieldPaths: ["名称", "说明", "价格", "免费体验", "每日用量", "可用任务模块", "icon", "最高级别"],
        })
        setCurrentPlanPlan(data)
    }

    useEffect(() => {
        loadPlan()
    }, [currentPlanId])

    function printDate(d) {
        return dayjs(d).format("YYYY-MM-DD");
    }


    const isWeChat = facade.dlc.userenv ?  facade.dlc.userenv.isWeChat : false

    // if is wechat, go to specific url  "/ext/pay/sub"   -- 暂时固定吧
    // else: open up the subscription payment dialog 

    const [ paymentDialogOpen, setPaymentDialogOpen ] = useState(false);

    function goToPayment () {
        
        if(isWeChat) {
            routeTo("/ext/pay/sub?_tenant_=" + facade.dlc.tenantCode + "&rt=" + encodeURIComponent(viewEnv.webEnv.currentURL));
        } else {
            setPaymentDialogOpen(true)
        }
    }

    return (
        <div className={"lc-inbot-base lc-usage-panel"}>
            {
                currentPlan ? 
                    <div className="current-plan font-size-12">
                        { currentPlan.icon ? <img src={getImageURL(currentPlan.icon, "thumbnail")} /> : <div style={{ width : "1.25rem"}}/> }
                        {currentPlan.名称}
                        { currentPlanExpiryDate ? `，${printDate(currentPlanExpiryDate)} 到期` : null }
                    </div> : 
                    <div className="current-plan font-size-12" style={{ paddingLeft: "1.25rem"}}>
                        {printDate(currentPlanExpiryDate)} 到期
                    </div> 
            }
            <div className="info font-size-12">
                <div>每天用量限额 {fmk(quota)} </div>
                <div>今天已使用 {fmk(usedQuota, 2)} ({fm(usedQuota * 100 / quota, 1)}%)</div>
                <div>总共使用了 {fmk(totalUsed)}</div>
            </div>        
            <div style={{marginLeft: "1rem", marginTop: "1rem", fontWeight: 500}}>
                <Button color="yellow" size="mini" onPress={_ => {
                    track("pay_click_to_pay");
                    goToPayment() 
                }                    
                }>{ currentPlan && currentPlan.最高级别 ? "点击续费" : "点击升级" }</Button>
            </div>
            <Dialog {...{ 
                open: paymentDialogOpen, onOpenChange: setPaymentDialogOpen, isMain: true,
                content: close => (
                    <SubscriptionPayment_inbot 
                        facade={facade}
                        data={{ currentPlanId, currentPlanExpiryDate }}
                        events={{ reloadUsageQuota }}
                        viewEnv={viewEnv}
                        closeDialog={close}
                    />
                )
            }} />
        </div>
    )
}
