

import React, { useContext, useEffect, useState } from 'react'

import TemperatureInput from '../input/TemperatureInput';
import ChatModelInput from '../input/ChatModelInput';
import KnowledgeOptionsInput from '../KnowledgeOptionsInput';

import FunctionToggle from '../input/FunctionToggle';
import Loading from 'bwax-ui/components/Loading';
import Toggle from 'bwax-ui/components/inputs/Toggle';

import Avatar from 'bwax-ui/components/Avatar';

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'

import { Pressable } from 'bwax-ui/components/Button';

import "./ChatConfigPanel.less";

import { personaFieldPaths } from '../persona/PersonaGrid';
import EditPersonaForm from '../persona/EditPersonaForm';
import Modal from 'bwax-ui/components/Modal'

//

export default function ChatConfigPanel({
    userOptions, updateUserOptions,
    contentOptions, updateContentOptions,
    chatModel, updateChatModel,
    facade, currentUserId, loading = false,

    persona, onPersonaUpdate = _ => { },

    personaTipShown = false,

    showTestFunctions = true,
}) {

    const dlc = useContext(DataLoaderContext);

    const [customFunctions, setCustomFunctions] = useState([]);

    useEffect(() => {
        (async () => {
            const [result, error] = await facade.list({ entityName: "OpenAI-函数定义", fieldPaths: ["名称", "函数名"] });
            if (result) {
                setCustomFunctions(result);
            }
        })();
    }, []);



    function renderSection(title, content, caption) {
        return (
            <div className="flex flex-col gap-3.5 py-2">
                <div className="font-size-12 text-[var(--gray10)] px-2">{title}</div>
                <div className="flex flex-col gap-3.5">
                    {content}
                </div>
            </div>
        )
    }


    const possibleQuestionsEnabled = userOptions && userOptions.possibleQuestionsEnabled;
    const updatePossibleQuestionEnabled = v => {
        if (userOptions && userOptions.scope) {
            updateUserOptions({
                KnowledgeOptions: userOptions,
                possibleQuestionsEnabled: v
            })
        } else {
            updateUserOptions(({
                ...(userOptions || {}),
                possibleQuestionsEnabled: v
            }))
        }
    }


    const aiSettings = renderSection("内容设置", (
        <>
            <KnowledgeOptionsInput
                value={userOptions} onChange={options => updateUserOptions(options)} facade={facade} currentUserId={currentUserId}
            />
            <TemperatureInput
                value={contentOptions} onChange={options => updateContentOptions(options)}
            />
            <ChatModelInput value={chatModel} onChange={updateChatModel} />
            <Toggle label="建议问题" value={possibleQuestionsEnabled} onChange={updatePossibleQuestionEnabled} />
        </>
    ))

    // name, function name, only member
    const availableActions = [
        ["联网查询", "OnlineSearch"],
        ["DALLE-3 画图", "CreateDallEImage", "每张图片用量 20k"],
        ["SDXL 画图", "CreateSDXLImage", "每张图片用量 3k"]
    ]

    const mutualExclusiveGroups = [
        ["CreateDallEImage", "CreateSDXLImage"]
    ]

    const actionSetting = renderSection("可用动作", (
        <>
            {availableActions.map(([label, fnName, tip]) => {
                const mutualExclusiveGroup = mutualExclusiveGroups.find(g => g.indexOf(fnName) !== -1) || [];

                const fs = (contentOptions || {}).availableFunctions || [];
                const isEnabled = fs.indexOf(fnName) !== -1;
                return (
                    <div key={fnName} className="flex flex-col gap-2">
                        <FunctionToggle
                            key={fnName}
                            name={fnName}
                            label={label}
                            value={contentOptions} 
                            mutualExclusive={mutualExclusiveGroup.filter(n => n !== fnName)}
                            onChange={options => {
                                updateContentOptions(options)
                            }}
                        />
                        { isEnabled && tip ? <div className="text-[var(--gray10)] font-size-11 px-3">* {tip}</div> : null}
                    </div>
                )
            })}
        </>
    ))

    const testActions = customFunctions.filter(f => {
        return !availableActions.some(([_, fnName]) => fnName == f.函数名)
    }).map(f => {
        return [f.名称, f.函数名]
    })

    const testSetting = renderSection("测试动作", (
        <>
            {testActions.map(([label, fnName]) => {
                const mutualExclusiveGroup = mutualExclusiveGroups.find(g => g.indexOf(fnName) !== -1) || [];
                return (
                    <FunctionToggle
                        key={fnName}
                        name={fnName}
                        label={label}
                        value={contentOptions} 
                        mutualExclusive={mutualExclusiveGroup.filter(n => n !== fnName)}
                        onChange={options => {
                            updateContentOptions(options)
                        }}
                    />
                )
            })}
        </>
    ))

    const [editingPersona, setEditingPersona] = useState();
    const [isEditing, setIsEditing] = useState(false);

    function renderPersona() {
        if (!persona) {
            return null;
        }
        return (
            <Pressable onPress={async _ => {
                if (!editingPersona) {
                    const [result, error] = await facade.findById(persona.id, {
                        entityName: "OpenAI-会话角色", fieldPaths: personaFieldPaths,
                    })
                    if (result) {
                        setEditingPersona(result)
                    }
                }
                setIsEditing(true);
            }}>
                <div className="flex flex-col gap-5 items-center py-8 px-2 lc-persona-panel relative rounded cursor-pointer">
                    <Avatar avatar={persona.头像} nickName={persona.名称} size={64} />
                    <div className="px-4 text-center">
                        {persona.名称}
                    </div>
                </div>
            </Pressable>

        )
    }


    function renderEditPersonaDialog() {
        return (
            <Modal isOpen={isEditing && editingPersona} className="max-w-4xl" onOpenChange={open => {
                if (!open) {
                    // 
                    setIsEditing(false);
                }
            }}>
                {closeModal => {
                    return (
                        <EditPersonaForm {...{
                            facade, currentUserId, fieldPaths: personaFieldPaths,
                            record: editingPersona,
                            onUpdated: r => {
                                onPersonaUpdate(r);
                                setEditingPersona(r);

                                closeModal();
                            }
                        }} />
                    )
                }}
            </Modal>
        )
    }



    return (
        <div className="flex flex-col gap-4">
            {loading ? (
                <div className="h-1/2 flex flex-col justify-center items-center">
                    <Loading />
                </div>

            ) : (
                <>
                    {renderPersona()}
                    {aiSettings}
                    {actionSetting}
                    {dlc.sandbox && showTestFunctions ? testSetting : null }
                    {persona || personaTipShown ? (
                        <div className="font-size-12 text-[var(--gray10)] px-2">* 会话层面的设置不影响角色</div>
                    ) : null}
                    {renderEditPersonaDialog()}
                </>
            )}

        </div>
    )
}

