

import React, { useState } from 'react'

import "./LayoutBase.less";

import { HamburgerMenuIcon } from '@radix-ui/react-icons';
import useStateWithLocalCache from 'bwax-ui/hooks/useStateWithLocalCache';
import Drawer from 'bwax-ui/components/Drawer';

import { useTrack } from 'bwax-ui/track';


export default function LayoutBase({ 
    mainContent, menuLeft, menuButtonsRight,  facade, viewEnv,

    renderSideMenu, minMenuWidth = 100, maxMenuWidth = 500
}) {


    const track = useTrack();

    const [navMenuDrawerShown, setNavMenuDrawerShown] = useState(false);
    function toggleNavMenuDrawer() {
        setNavMenuDrawerShown(prev => !prev);
    }

    const [menuCollapsed, setMenuCollapsed] = useStateWithLocalCache("layout-translated::menu-collapsed", false);

    // side menu width, changeable
    const [menuWidth, setMenuWidth] = useStateWithLocalCache("lc-inbot::menu-width", 160);
    const [resizerActive, setResizerActive] = useState(false);


    function updateMenuWidth(e) {
        setMenuWidth(prev => {
            const newSize = prev + e.movementX;
            return newSize < minMenuWidth ? minMenuWidth : (newSize > maxMenuWidth ? maxMenuWidth : newSize);
        })
    }

    function getMenuWidth() {
        if (menuCollapsed) {
            return 48
        }
        return menuWidth
    }


    return (
        <div className="lc-layout-base h-full w-full font-size-14 flex"
            onMouseMove={e => {
                if (resizerActive) {
                    updateMenuWidth(e)
                }
            }}
            onMouseUp={() => {
                if (resizerActive) {
                    setResizerActive(false);
                }
            }}
        >
            <div className="side-menu wide-only" data-collapsed={menuCollapsed} style={{
                "--lc-layout-menu-width": getMenuWidth() + "px",
                width: "var(--lc-layout-menu-width)",
                minWidth: "var(--lc-layout-menu-width)",
            }}>
                {renderSideMenu({ collapsed: menuCollapsed })}
            </div>
            {/* <div className="side-menu narrow-only" data-collapsed={false} style={{
            }}>
                {renderSideMenu({ collapsed: false })}
            </div> */}
            <Drawer
                    open={navMenuDrawerShown}
                    onOpenChange={open => {
                        setNavMenuDrawerShown(open)
                    }}
                    className="lc-layout-base"
                    position='left'
                >
                    <div className="w-full h-full">
                        {renderSideMenu({ collapsed: false, isInDrawer: true })}
                    </div>
                </Drawer>
            <div className="resize-line wide-only" data-dragging={resizerActive} data-collapsed={menuCollapsed}
                onMouseDown={() => {
                    if (!menuCollapsed) {
                        setResizerActive(true);
                    }
                }}
            >
                <div className="collapse-button" onMouseDown={e => e.stopPropagation()}
                    onClick={_ => {
                        track("layout_toggle_menu_collapsed")
                        setMenuCollapsed(prev => !prev)
                    }}
                >
                    {menuCollapsed ? <i className='bx bx-chevrons-right' /> : <i className='bx bx-chevrons-left' />}
                </div>
            </div>
            <div className="main-area grow h-full" style={{
                "--menu-width": getMenuWidth() + "px"
            }}>
                <div className="h-full flex flex-col">
                    {mainContent}
                </div>
                <div className="top-menu narrow-only">
                    {/* 有个小问题，这个 top menu 似乎应该是属于 content 的，那就让 content 传东西进来吧 */}
                    <div className="flex gap-2">
                        <div className="menu-switcher narrow-only">
                            <HamburgerMenuIcon onClick={toggleNavMenuDrawer} />                            
                        </div>
                        {menuLeft}
                    </div>
                    <div className="right-group">
                        {menuButtonsRight}
                    </div>
                </div>
            </div>
        </div>
    )
}
