


import React from 'react'

const ChatContext = React.createContext();

export default ChatContext;

export const ChatContextProvider = props => {

    const { 
        botAvatarUrl, userAvatar, userNickName,
        children 
    } = props

    return (
        <ChatContext.Provider value={{
            botAvatarUrl, userAvatar, userNickName,
        }}>
            { children }
        </ChatContext.Provider>
    );
}
