
import React, { useContext, useState, useEffect, useRef } from 'react';

import useScreenSize from 'bwax-ui/auxiliary/useScreenSize';


import RenderWithData from 'bwax-ui/store/RenderWithData';
import { USER_DATA } from 'bwax/store/DataCache';
import { hashCode } from 'bwax/utils';
import { make as PaginationSelect } from './PaginationSelect.bs';

import WindowScrollHelper, { scrollToNode } from './WindowScrollHelper';

import useResizeObserver from '@react-hook/resize-observer'

import maxBy from 'lodash/maxBy';

import './PagedDataList.less';

export default function PagedDataList(props) {

    const { query_config, pageSize, isLazy, wrapWithContainer, facade, ...rest } = props;

    const initialPages = [0, 1]; // TODO 可能从 localStorage 取出：
    const screenSize = useScreenSize()
    const isLargeScreen = screenSize === "L"

    const [totalCount, setTotalCount] = useState(undefined);
    const [pageIndexes, setPageIndexes] = useState(initialPages)

    const [currentPageIndex, setCurrentPageIndex] = useState(initialPages[0]);
    const currentPageIndexRef = useRef(currentPageIndex);
    currentPageIndexRef.current = currentPageIndex;

    const [paginationFixed, setPaginationFixed] = useState(false);
    const paginationFixedRef = useRef(paginationFixed);
    paginationFixedRef.current = paginationFixed;

    const [fixedPaginationWidth, setFixedPaginationWidth] = useState("100%");

    const mounted = useRef(false);

    const topBarHeight = isLargeScreen ? 64 : 56; // TODO 这个由参数传进来？

    // 查询总数
    useEffect(() => {
        (async () => {
            const queryObj = { query_config };
            const [result, error] = await facade.count(queryObj, {});
            if (!error) {
                setTotalCount(result);
            }
        })();
        mounted.current = true
    }, []);

    const lastIndex = pageIndexes[pageIndexes.length - 1];

    const hasNextPageRef = useRef(false);
    hasNextPageRef.current = (lastIndex + 1) * pageSize < totalCount;

    const firstIndex = pageIndexes[0];
    const hasPreviousPageRef = useRef(firstIndex > 0);
    hasPreviousPageRef.current = firstIndex > 0;

    const pageRefs = useRef({});
    const setPageRef = (pageIndex, r) => {
        const refs = pageRefs.current;
        pageRefs.current = { ...refs, [pageIndex]: r };
    };

    const paginationRef = useRef(null);
    const paginationHeight = paginationRef.current ? paginationRef.current.getBoundingClientRect().height : 0;

    const boundaryTopRef = useRef(0);
    boundaryTopRef.current = topBarHeight + paginationHeight;

    useResizeObserver(paginationRef, entry => {
        const width = entry.borderBoxSize && entry.borderBoxSize[0] && entry.borderBoxSize[0].inlineSize;

        if(width && width !== fixedPaginationWidth || fixedPaginationWidth === undefined) {
            setFixedPaginationWidth(width);
        }
    });


    // 监听下拉刷新和上拉加载
    useEffect(() => {
        const scrollHelper = new WindowScrollHelper({
            // options
            topThreshold: 600,
            bottomThreshold: 300,

            onScrolledToTop: _ => {
                if(hasPreviousPageRef.current) {
                    setPageIndexes(prev => {
                        // 前面加一页;
                        const firstIndex = prev[0];
                        if(firstIndex > 0) {
                            return [firstIndex - 1, ...prev];
                        } else {
                            return prev
                        }                            
                    })
                }
            },
            onScrolledToBottom: _ => {
                if (hasNextPageRef.current) {
                    setPageIndexes(prev => {
                        // 加一页：
                        const lastIndex = prev[prev.length - 1];
                        return [...prev, lastIndex + 1];
                    })
                }
            },

            onScrolled: status => {
                if (paginationRef.current && paginationRef.current.getBoundingClientRect()) {

                    const paginationTop = paginationRef.current.getBoundingClientRect().top;
                    if (paginationTop <= topBarHeight && !paginationFixedRef.current) {
                        setPaginationFixed(true)
                    } else if (paginationTop > topBarHeight && paginationFixedRef.current &&  !hasPreviousPageRef.current) {
                        setPaginationFixed(false)
                    }
                }

                // status;
                // 根据 scroll status，以及所有的 page refs，计算出当前页，
                // 如果计算出来的当前页不等于 currentPageIndex，则更新 currentPageIndex;

                const rects = Object.keys(pageRefs.current).map(k => {
                    const r = pageRefs.current[k];
                    const rect = r ? r.getBoundingClientRect() : null;
                    return [k, rect]
                })

                const boundaryTop = boundaryTopRef.current;
                const boundaryBottom = status.clientHeight;

                const maxOne = maxBy(rects, ([pageIndex, rect]) => {
                    if (!rect) {
                        return 0
                    }
                    const top = rect.top;
                    const bottom = rect.bottom;

                     // 返回可见高度
                    function getInsideHeight() {                       
                        const bigNumber = 100000000;
                        if (top >= boundaryTop && bottom <= boundaryBottom) {
                            // 如果百分之百在里面，则返回一个极大数；
                            return bigNumber
                        } else if (bottom < boundaryTop || top > boundaryBottom) {
                            // 如果百分之百在外面，则返回 0
                            return 0
                        } else if (top >= boundaryTop && bottom > boundaryBottom) {
                            // 上半部分在里面
                            return boundaryBottom - top;

                        } else if (top < boundaryTop && bottom <= boundaryBottom) {
                            // 下半部分在里面
                            return bottom - boundaryTop
                        } else {
                            // 整个都包括了 返回极大数
                            return bigNumber
                        }
                    }
                    const insideHeight = getInsideHeight();
                    return insideHeight
                });

                if(maxOne) {
                    const candiate = parseInt(maxOne[0]);
                    if(candiate !== currentPageIndexRef.current) {
                        if(maxOne[1]) { 
                            setCurrentPageIndex(candiate);
                        }
                    }
                }

            }


        });
        return () => {
            scrollHelper.cleanUp();
        }

    }, [])

    const footer = hasNextPageRef.current ? null : 
        <div key="footer" className='page-list-footer'> 
            <span className='page-list-footer-line'></span> 
            <span>没有更多了</span>
            <span className='page-list-footer-line'></span>
        </div>;

    const pageCount = totalCount === undefined ? 0 : (
        Math.ceil(totalCount / pageSize)
    );

    function jumpToPage(pageIndex) {
        //
        if (pageIndex === undefined || pageIndex === null) {
            return
        }


        if (pageIndexes.indexOf(pageIndex) !== -1) {
            // 如果已经有，直接 scroll 过去
            console.log("Just jump to", pageIndex)

            if(pageIndex === pageIndexes[0] && pageIndex !== 0) {

                setPageIndexes([pageIndex - 1, ...pageIndexes ])

                setTimeout(() => {
                    scrollToNode("page-" + pageIndex, paginationHeight);
                }, 64)

            } else {
                scrollToNode("page-" + pageIndex, paginationHeight);
            }



        } else {
            // 如果还没有，加载它附近的三页，并 scrollTo 

            console.log("Load and jump to", pageIndex)

            if (pageIndex === 0) {
                setPageIndexes([0]);
            } else {
                setPageIndexes([pageIndex - 1, pageIndex]);
            }

            setTimeout(() => {
                scrollToNode("page-" + pageIndex, paginationHeight);
            }, 64)

        }
    }

    function renderPCPagination (pageArray) {
        const activePageRange = 3 // 当前部分显示多少页
        const marginPageRange = 2 // 首尾部分显示多少页
        
        const hasPrevious = currentPageIndex > 0
        const hasNext = currentPageIndex < pageCount - 1
        const currentPages = pageArray.filter(page => 
            page >= currentPageIndex - parseInt(activePageRange / 2) && 
            page <= currentPageIndex + parseInt(activePageRange / 2)
        )

        const firstPages = currentPages[0] > pageArray[marginPageRange - 1] ? 
            pageArray.filter(page => page < marginPageRange) : pageArray.filter(page => page < currentPages[0])
        const lastPages = currentPages[currentPages.length - 1] < pageCount - marginPageRange - 1 ?
            pageArray.filter(page => page > pageArray.length - 1 - marginPageRange) : 
            pageArray.filter(page => page > currentPages[currentPages.length - 1])

        function renderPagesBtn(pages) {
            return pages.map(page => {
                return (
                    <div key={page} className={`page-button ${page === currentPageIndex ? 'active' : ''}`}
                        onClick={() => jumpToPage(page)}
                    >
                        {page + 1}
                    </div>
                )
            })
        }

        return (
            <div className='pc-pagination'>
                <div className={`page-button ${hasPrevious ? '' : 'disabled'}`} 
                    onClick={() => jumpToPage(currentPageIndex - 1)}>上一页</div>
                { renderPagesBtn(firstPages) }
                {
                    firstPages[firstPages.length - 1] < currentPages[0] - 1 ? 
                        <div className='page-button'>...</div> 
                    : null
                }
                { renderPagesBtn(currentPages) }
                {
                    lastPages[0] > currentPages[currentPages.length - 1] + 1 ? 
                        <div className='page-button'>...</div> 
                    : null
                }
                { renderPagesBtn(lastPages) }
                <div className={`page-button ${ hasNext ? '' : 'disabled'}`}
                    onClick={() => jumpToPage(currentPageIndex + 1)}>下一页</div>
            </div>
        )
    }

    // const [pickedPage, setPickedPage] = useState(null);
    function renderPaginationBar() {
        const pageArray = Array.from({length: pageCount}, (p, index) => index) // 创建一个页码 array
        const options = pageArray.map(page => {
            return {
                label: `第${page + 1}页`,
                value: page,
                isDisabled: false
            }
        })
        return isLargeScreen ? (
            renderPCPagination(pageArray)
        ) : totalCount !== undefined ? (
            <div className='mobile-pagination-container'>
                <PaginationSelect {...{
                    options,
                    selected: { label: `第${currentPageIndex + 1}页`, value: currentPageIndex, isDisabled: false},
                    control: (
                        <div key="No_key" className='select-control'>
                            <span>{`${currentPageIndex + 1} / ${pageCount}`}</span>
                            <span style={{ marginLeft: 20 }}>换页</span>
                        </div>
                    ),
                    onChange: v => {
                        const { value } = v
                        jumpToPage(value)
                    }
                }}/>
            </div>
        ) : null
    }

    const pagination = (
        <div key="pagination" className="pagination" ref={paginationRef} style={
            paginationFixed ? { opacity: 0, pointerEvents: "none" } : {}
        }>
            {renderPaginationBar()}
        </div>
    );


    const fixedPagination = (
        <div key="pagination-fixed" className="pagination pagination-fixed" style={{
            position: "fixed", zIndex: 100, top: topBarHeight, width: fixedPaginationWidth
        }}>
            {renderPaginationBar()}
        </div>
    )

    const pageList = (
        <div className="page-list-wrapper" key="page-list">
            {pageIndexes.map(i => <DataPage key={i} {...{
                pageIndex: i, pageSize, query_config, facade,
                isLazy: mounted.current === false ? isLazy : true, // 只有一开始才有必要“不lazy"

                setRef: setPageRef,
                ...rest
            }} />)}
        </div>
    )
    const showHeader = !hasPreviousPageRef.current;
    return wrapWithContainer(showHeader, pagination, pageList, footer, paginationFixed ? fixedPagination : null);
}

// function DummyColorPage({ pageIndex, setRef }) {
//     const COLORS = ["B60", "G60", "R60", "Y60", "H60", "O60"];

//     const colorName = COLORS[pageIndex % COLORS.length];
//     const color = getColor(colorName);

//     const [height, _] = useState(_ => 600 + Math.round(1600 * Math.random()));

//     const page = (
//         <div id={`page-` + pageIndex} style={{
//             backgroundColor: color, width: "100%", height,
//             display: "flex", alignItems: "center", justifyContent: "center"
//         }} onClick={_ => { }}>
//             <div style={{ color: "white", fontSize: 20 }}>{pageIndex + 1}</div>
//         </div>
//     )

//     return React.cloneElement(page, { ref: r => setRef(pageIndex, r) });
// }

function DataPage(props) {

    const { facade, query_config, get_depended_paths, pageSize, pageIndex, isLazy, loading, ...rest } = props;

    const [entityName, _] = query_config;

    const fieldPaths = get_depended_paths(facade.entity_dict, facade.data_type_dict, 0, entityName) || [];
    const dataKey = "Paged_data_list-" + hashCode(JSON.stringify({ query_config, fieldPaths, pageSize, pageIndex })); // TODO 应该用当前路径之类的做 key

    const offset = pageSize * pageIndex;
    const loadData = async () => {
        const queryObj = {
            entityName,
            query_config, // facade 支持 query_config， 也支持 js 版本的 condition, sort
            fieldPaths,

            pageSize, offset

        };
        const [result, error] = await facade.list(queryObj, {});

        return [result, error]
    };
    return (
        <RenderWithData {...{
            loadData,
            cacheType: USER_DATA,
            dataKey,
            Component: DataPageInner,
            noSuspense: true,

            isLazy,
            fallback: loading,

            // 
            pageIndex,
            facade, entityName, ...rest
        }}
        />
    )
}

function DataPageInner(props) {
    const { data, entityName, toElement, makePage, facade, pageIndex, setRef } = props;
    const [result, error] = data;

    useEffect(() => {
        return () => {
            console.log("I'm off", pageIndex)
        }
    }, [])

    if (result) {
        const els = result.map(r => toElement(facade.entity_dict, facade.data_type_dict, entityName, r));
        const page = makePage("page-" + pageIndex, els);
        // clone and set ref;

        return React.cloneElement(page, { ref: r => setRef(pageIndex, r) });

    } else {
        return "Error"
    }
}

export function create(props) {
    return <PagedDataList {...props} />
}





