

import React, { useRef, useState } from 'react'

import * as Tabs from '@radix-ui/react-tabs';

import './Tabs.less'

import useResizeObserver from '@react-hook/resize-observer'
import classNames from 'classnames';

export default function Tabs_component({ tabs, defaultTab, className, tabContentClassName, style, onTabChange }) {


    const tabBarRef = useRef();
    const [ tabBarHeight, setTabBarHeight ] = useState();

    useResizeObserver(tabBarRef, entry => {
        setTabBarHeight(entry.borderBoxSize[0].blockSize)
    });


    return (
        <Tabs.Root className={classNames("lc-tabs", className)} defaultValue={defaultTab || (tabs && tabs.length > 0 ? (tabs[0].key || 0) : undefined)} 
            style={{
                ...(tabBarHeight ? { "--tab-bar-height" : tabBarHeight + "px" } : {}),
                ...(style || {}),
            }}
            onValueChange={value => {
                onTabChange && onTabChange(value)
            }}
            >
            <Tabs.List className="TabsList" ref={tabBarRef}>
                {
                    tabs.map(({ label, key }, index) => {
                        const tabValue = key || index;
                        return (
                            <Tabs.Trigger className="TabsTrigger" value={tabValue} key={tabValue}>
                                {label}
                            </Tabs.Trigger>
                        )
                    })
                }
            </Tabs.List>
            {
                tabs.map(({ content, key }, index) => {
                    const tabValue = key || index;
                    return (
                        <Tabs.Content className={classNames("TabsContent", tabContentClassName)} value={tabValue} key={tabValue}>
                            {content}
                        </Tabs.Content>
                    )
                })
            }
        </Tabs.Root>
    )
}
