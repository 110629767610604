
// This file is generated. Don't modify it.
import React from 'react'
export default function FinishWireFrameIcon (props) {

    const { color } = props
    
    return (
                <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M8,3 C7.09139142,3 6.25671977,3.22655373 5.47616624,3.68522748 C4.72043089,4.12741305 4.12741305,4.72043089 3.684278,5.47778549 C3.22655373,6.25671977 3,7.09139142 3,8 C3,8.90860858 3.22655373,9.74328023 3.68922093,10.5307217 C4.12152316,11.2832478 4.70815478,11.8738431 5.46336241,12.315722 C6.24193685,12.7732348 7.08136588,13 8,13 C8.91863412,13 9.75806315,12.7732348 10.5451448,12.3107791 C11.2955136,11.8797161 11.8797161,11.2955136 12.315722,10.5366376 C12.7732348,9.75806315 13,8.91863412 13,8 C13,7.08136588 12.7732348,6.24193685 12.3107791,5.45485523 C11.8797161,4.70448638 11.2955136,4.12028389 10.5366376,3.684278 C9.75806315,3.22676519 8.91863412,3 8,3 Z M8,2 C9.09615933,2 10.1105723,2.27403572 11.0432692,2.82211538 C11.9471199,3.34134875 12.6586512,4.0528801 13.1778846,4.95673077 C13.7259643,5.88942774 14,6.90384067 14,8 C14,9.09615933 13.7259643,10.1105723 13.1778846,11.0432692 C12.6586512,11.9471199 11.9471199,12.6586512 11.0432692,13.1778846 C10.1105723,13.7259643 9.09615933,14 8,14 C6.90384067,14 5.88942774,13.7259643 4.95673077,13.1778846 C4.0528801,12.6490358 3.34134875,11.9326968 2.82211538,11.0288462 C2.27403572,10.0961492 2,9.08654389 2,8 C2,6.91345611 2.27403572,5.90385082 2.82211538,4.97115385 C3.35096418,4.06730317 4.06730317,3.35096418 4.97115385,2.82211538 C5.90385082,2.27403572 6.91345611,2 8,2 Z M6.85204649,10.8634634 C6.65677663,11.0026561 6.38391018,10.9846535 6.20871234,10.8094557 L4.087392,8.68813533 C3.89212985,8.49287319 3.89212985,8.1762907 4.087392,7.98102855 C4.28265414,7.7857664 4.59923663,7.7857664 4.79449878,7.98102855 L6.56330389,9.74983366 L11.1605361,5.15260143 C11.3557983,4.95733928 11.6723808,4.95733928 11.8676429,5.15260143 C12.062905,5.34786357 12.062905,5.66444606 11.8676429,5.85970821 L6.91789544,10.8094557 C6.8934012,10.8339499 6.87168377,10.8516405 6.85204649,10.8634634 Z" id="FinishWireFrameIcon-path-1"></path>
            </defs>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Wireframe/Finish">
                    <g>
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="FinishWireFrameIcon-mask-2" fill="white">
                            <use href="#FinishWireFrameIcon-path-1"></use>
                        </mask>
                        <use id="Combined-Shape" fill={color} fillRule="nonzero" href="#FinishWireFrameIcon-path-1"></use>
                        <g id="Color/UI/Text" mask="url(#FinishWireFrameIcon-mask-2)" fill={color} fillRule="nonzero">
                            <rect id="light-gray" x="0" y="0" width="16" height="16"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        
    )
}
