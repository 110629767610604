

import React, { useState, useEffect } from 'react'

import { MagnifyingGlassIcon } from '@radix-ui/react-icons';

import Tabs from 'bwax-ui/components/Tabs';
import TextInput from 'bwax-ui/components/inputs/TextInput';


export default function TranslationCacheView({ task, facade }) {

    const [lines, setLines] = useState([]);
    const [keyword, setKeyword] = useState("");

    async function loadTaskData() {
        const [taskData, error] = await facade.findById(task.id, {
            entityName: "文档翻译-任务", fieldPaths: ["翻译缓存", "已应用的翻译缓存修订"]
        }, { forceRefreshing: true })

        if (taskData) {

            const translateCache = taskData.翻译缓存 || {};

            setLines(
                Object.keys(translateCache).map((k) => [k, translateCache[k]]).filter(([k, v]) => {
                    // 有些固定的比如 1,2,3,4,5,6,7,8,9 可以移除
                    if (!isNaN(k) && k == v) {
                        return false
                    }
                    if (k == "##TranslationModel##") {
                        return false
                    }
                    // if (notTranslatable(k)) {
                    //     return k != v
                    // }

                    return true
                }).map(([k, v], index) => [index, k, v])
            )

        }
    }


    useEffect(() => {
        loadTaskData()
    }, [task.id])


    const printStr = str => {
        if (!str) {
            return str;
        }
        const j = JSON.stringify(str);
        return j.substring(1, j.length - 1);
    }

    function inside(v, p) {
        return p.toLowerCase().indexOf(v.toLowerCase()) !== -1
    }

    const filteredLines = lines && lines.filter(([index, k, v]) => {
        if (!keyword && !keyword.trim()) {
            return true
        } else {
            // return k.indexOf(keyword) !== -1 || v.indexOf(keyword) !== -1 || (index + "").indexOf(keyword) !== -1
            return inside(keyword, k) || inside(keyword, v) || inside(keyword, index + "");
        }
    });


    return (
        <div className="flex flex-col gap-2 px-4 py-2">
            {/* { lines && lines.length > 0 ? (
                <div className="text-[var(--gray11)] py-2 px-4">
                    共 {lines.length} 条记录
                </div>
            ) : null } */}

            <div className="flex px-4 gap-2 items-center self-start">
                <TextInput placeholder={"输入关键字搜索"} value={keyword} onChange={setKeyword} aria-label={"keyword"} className="grow"
                    prefix={<MagnifyingGlassIcon />}
                />
                {lines ? (
                    <div className="text-[var(--gray11)] font-size-13">
                        {keyword && keyword.trim() ? (
                            `${filteredLines.length}/${lines.length}`
                        ) : `共 ${lines.length} 条记录`}
                    </div>
                ) : null}
            </div>

            {filteredLines.map(([index, k, v]) => {
                return (
                    <div key={index} className={"flex gap-1.5 py-2 pl-3 pr-5 rounded"}>
                        <div className="text-[var(--gray10)] font-size-13 min-w-[2rem] max-w-[2rem] text-right pt-[1px]">#{index + 1}</div>
                        <div className="flex flex-col gap-2 grow">
                            <div className="">
                                {printStr(k)}
                            </div>
                            <div className="text-[var(--gray11)] ">
                                {printStr(v)}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}
