

import React, { useEffect, useState, useContext } from 'react'

import ChatUI from './ChatUI';
import { defaultAvailableFunctions } from '../input/FunctionToggle';

import { personaFieldPaths } from '../persona/PersonaGrid';

import Avatar from 'bwax-ui/components/Avatar';
import Button, { Pressable } from 'bwax-ui/components/Button';

import UsageQuotaContext from 'bwax-ui/ml/widget/ports/inbot/UsageQuotaContext';

import Link from 'bwax-ui/page/Link';

import EditPersonaForm from '../persona/EditPersonaForm';
import Modal from 'bwax-ui/components/Modal'

import ChatConfigPanel from './ChatConfigPanel';
import classNames from 'classnames';

export default function ChatNewSession({
    viewEnv, facade, maxInputCount, currentUserId, scene, showConfig = true,
    botAvatarUrl, userAvatar, userNickName,

    personaId,

    startSession,

    onPersonaUpdate = _ => {}
}) {



    const { remainingQuota } = useContext(UsageQuotaContext) || {};
    // 
    const [userOptions, setUserOptions] = useState({
        possibleQuestionsEnabled: true // 这个默认开吗?
    });
    const [contentOptions, setContentOptions] = useState({
        availableFunctions: defaultAvailableFunctions
    });

    const [chatModel, setChatModel] = useState();

    const [loadingPersona, setLoadingPersona] = useState(false);
    const [persona, setPersona] = useState();


    function handlePersonaUpdate(persona) {
        setPersona(persona);
        setUserOptions(persona.用户选项);
        setContentOptions(persona.内容生成选项);
        setChatModel(persona.chatModel);

        onPersonaUpdate(persona);
    }


    useEffect(() => {
        if (!personaId) {
            setPersona();
            loadLastSession()
            return;
        }
        (async () => {
            setLoadingPersona(true);
            const [persona, error] = await facade.findById(personaId, {
                entityName: "OpenAI-会话角色",
                fieldPaths: personaFieldPaths,
            });
            setLoadingPersona(false);
            if (persona) {
                handlePersonaUpdate(persona)
            }
        })();

    }, [personaId]);


    // 前面的 用户选项、模型、内容生成选项 的初始值按照最近活跃的一个 session 来定：
    async function loadLastSession() {
        const [result, error] = await facade.findOne({
            entityName: "OpenAI-会话", sort: [{ field: "修改时间", order: "DESC" }], fieldPaths: ["用户选项", "内容生成选项", "chatModel"],
            condition: [
                [
                    {
                        field: "用户", op: "eq", value: currentUserId,
                    }, {
                        //     field: "已归档", op: "ne", value: true
                        // }, {
                        field: "场景", op: "eq", value: scene
                    },
                ]
            ],
        }, { forceRefreshing: true });

        if (result) {
            setUserOptions(result.用户选项);
            setContentOptions(result.内容生成选项);
            setChatModel(result.chatModel);
        }
    }


    const configPanel = (
        <ChatConfigPanel {...{
            loading: false,

            userOptions,
            updateUserOptions: newOptions => {
                setUserOptions(newOptions)
            },

            contentOptions,
            updateContentOptions: setContentOptions,

            chatModel,
            updateChatModel: setChatModel,

            facade, currentUserId,

            personaTipShown: !!persona,
        }} />
    )

    const { isMobile } = viewEnv.webEnv;

    const [isEditing, setIsEditing] = useState(false);

    function renderEditPersonaDialog() {
        return (
            <Modal isOpen={isEditing && persona} className="max-w-4xl" onOpenChange={open => {
                if (!open) {
                    // 
                    setIsEditing(false);
                }
            }}>
                {closeModal => {
                    return (
                        <EditPersonaForm {...{
                            facade, currentUserId, fieldPaths: personaFieldPaths,
                            record: persona,
                            onUpdated: r => {
                                handlePersonaUpdate(r)
                                closeModal();
                            }
                        }} />
                    )
                }}
            </Modal>
        )
    }

    const renderEmptyView = () => {
        if (loadingPersona) {
            return null
        }

        if (persona) {
            return (
                <div className={classNames("flex py-4 flex-col gap-6 items-center px-8", {
                    "pb-24": !isMobile,
                    "pt-40": isMobile
                })}>
                    <Pressable onPress={_ => {
                        setIsEditing(true)
                    }}>
                        <div className="flex flex-col gap-6 items-center rounded py-4 px-4 cursor-pointer">
                            <Avatar avatar={persona.头像} nickName={persona.名称} size={80} />
                            <div className="flex flex-col items-center gap-3">
                                <div className="font-medium font-size-15">{persona.名称}</div>
                            </div>
                        </div>
                    </Pressable>
                    {persona.欢迎消息 ? (
                        <div className="flex flex-col items-center gap-2 max-w-xs px-4 py-3 bg-[var(--slate2)] rounded-md">
                            {persona.欢迎消息}
                        </div>
                    ) : null}
                    <Link to="/chat/characters" className="ml-2 underline text-[var(--indigo10)] opacity-90 font-size-12">{"选择其他角色 >>"}</Link>
                    { renderEditPersonaDialog() }
                </div>
            )
        } else {
            return (
                <div className={classNames("flex py-4 flex-col gap-7 items-center px-8", {
                    "pb-24": !isMobile,
                    "pt-40": isMobile
                })}>
                    <i className='bx bx-message-square-dots text-[6rem] opacity-30' />
                    <div className="text-[var(--gray10)] flex flex-col items-center gap-2">
                        <div>你可以在这里直接跟知识助手对话</div>
                        <div>或者选择你创建的某个角色</div>
                    </div>
                    <Button className="!px-8" onPress={_ => {
                        viewEnv.routeTo("/chat/characters")
                    }}>选择角色</Button>
                </div>
            )
        }
    }


    return (
        <ChatUI {...{
            history: [],
            hasLoadedMessages: true,
            botAvatarUrl, userAvatar, userNickName,

            currentSession: null,

            responding: false,
            respondingText: "",

            // responding: true,
            // respondingText: "HEllo WORL",

            respondingSteps: [],
            respondingKnowledges: [],
            respondingWebLinks: [],

            sendMessage: (message, images) => {

                startSession({
                    message, images, userOptions, contentOptions, chatModel,
                    persona,
                });
            },

            // chat model
            chatModel,
            updateChatModel: chatModel => {
                setChatModel(chatModel)
            },

            stopResponding: () => {
                //
            },

            maxInputCount,

            configPanel: showConfig ? configPanel : null,
            viewEnv, facade,

            remainingQuota,

            emptyView: (
                <div className="grow flex justify-center items-center">
                    {renderEmptyView()}
                </div>
            ),
            // inputBoxClassName,

            imageEnabled: true,
            // imageEnabled: false,
        }} />
    )
}
