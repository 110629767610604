

import React from 'react'
import DocumentDetail from './inbot/components/DocumentDetail';

export default function DocumentChatTest({ data, events, slots, facade, viewEnv }) {


    const { currentUserId, userAvatar, userNickName, botAvatarUrl, remainingQuota, document, } = data;

    const { reloadUsageQuota } = events;

    return (
        <DocumentDetail {...{
            document, facade, reloadUsageQuota, viewEnv, remainingQuota,
            botAvatarUrl, userAvatar, userNickName, currentUserId,
        }} />
    )
}
