import React from 'react'

class SubmitIcon extends React.Component {

    render() {
        return (

            <svg width="1em" height="1em" viewBox="0 0 14 14">
                <defs>
                    <path d="M0.193312791,12.3903723 L0.193312791,2.21245997 L9.44620848,2.21245997 L9.44620848,12.3903723 L0.193312791,12.3903723 Z M0.130095273,1.72868084 L7.46684274,0.202269483 L7.44509937,1.72868084 L0.130095273,1.72868084 Z" id="Submit-path-1"></path>
                    <path d="M2.95809336,4.93547416 L2.95809336,3.27335026 L10.7831107,1.61122635 L10.7831107,3.5855676 L12.9128746,3.5855676 L12.9128746,14.3850695 L2.95884178,14.3850695 L2.95884178,4.93547416 Z M3.38648984,3.5855676 L10.4013579,3.5855676 L10.4208883,2.0523265 L3.38648984,3.5855676 Z M3.30941035,14.0742747 L12.562306,14.0742747 L12.562306,3.89636241 L3.30941035,3.89636241 L3.30941035,14.0742747 Z M5.45583212,9.02211606 L7.43266811,9.02211606 L7.43266811,9.77053958 L5.45583212,9.77053958 L5.45583212,9.02211606 Z M5.43986575,10.4718124 L9.56268146,10.4718124 L9.56268146,11.2202359 L5.43986575,11.2202359 L5.43986575,10.4718124 Z" id="Submit-path-3"></path>
                </defs>
                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Icon/How-it-works/提交page" transform="translate(-2.000000, -1.000000)">
                        <g>
                            <rect id="bg" fillRule="nonzero" x="0" y="0" width="16" height="16"></rect>
                            <g id="Rectangle" transform="translate(2.800000, 2.000000)">
                                <mask id="Submit-mask-2" fill="white">
                                    <use href="#Submit-path-1"></use>
                                </mask>
                                <use id="Mask" fill="#F0F3F5" fillRule="nonzero" href="#Submit-path-1"></use>
                                <rect fill="#F0F3F5" fillRule="nonzero" mask="url(#Submit-mask-2)" x="-2.8" y="-2" width="16" height="16"></rect>
                            </g>
                            <mask id="Submit-mask-4" fill="white">
                                <use href="#Submit-path-3"></use>
                            </mask>
                            <use id="Mask" fill="#173652" fillRule="nonzero" href="#Submit-path-3"></use>
                            <rect id="Rectangle" fill="#173652" fillRule="nonzero" mask="url(#Submit-mask-4)" x="0" y="0" width="16" height="16"></rect>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export default SubmitIcon