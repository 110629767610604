

import React, { useContext, useEffect, useState, useRef } from 'react'

import SlateEditor from 'bwax-ui/auxiliary/richtext_slate/editor/SlateEditor';
import buttonComponents from 'bwax-ui/auxiliary/richtext_slate/editor/buttons';

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'
import { runDataQuery_a } from 'bwax/query/runClientQuery';
import UploadFile from 'bwax-ui/legacy/page/actions/UploadFile';

import NoteSupportPanel from './NoteSupportPanel';

import { serializeV2 as serialize, deserializeV2 as deserialize } from 'bwax-ui/richtext/serializeRichText';

import TextareaAutosize from '@mui/base/TextareaAutosize';

import useResizeObserver from '@react-hook/resize-observer'

import Button, { Pressable } from 'bwax-ui/components/Button';

import { guid, } from 'bwax/utils'

import useDebounce from 'bwax-ui/legacy/page/hooks/useDebounce'

import './NoteEdit.less'
import KnowledgeOptionsInput from './components/KnowledgeOptionsInput';
import { DotsVerticalIcon } from '@radix-ui/react-icons';

import DropdownMenu from 'bwax-ui/components/DropdownMenuLegacy';

import convertSlateToMarkdown from 'bwax-ui/auxiliary/richtext_slate/editor/convertSlateToMarkdown'

import OutOfQuotaTip from 'bwax-ui/components/OutOfQuotaTip';

import Dialog from 'bwax-ui/components/Dialog';
import classNames from 'classnames';
import countWords from './components/countWords';

import { toast } from 'bwax-ui/components/Toast';

import { useTrack } from 'bwax-ui/track';

import UsageQuotaContext from 'bwax-ui/ml/widget/ports/inbot/UsageQuotaContext';

import useStateWithLocalCache from 'bwax-ui/hooks/useStateWithLocalCache';


export default function NoteEdit(props) {

    const { data, events, slots, facade, viewEnv } = props;

    const track = useTrack();

    const {
        currentNote, isSaving, isUpdatingKnowledge, titlePlaceholder, contentPlaceholder, currentUserId,

        isArticlePublisher = false,

    } = data;

    useEffect(() => {
        // 
        track("note_view_note")

    }, [currentNote && currentNote.id]);


    const {
        save,
        saveUserOptions,
        addToOrUpdateKnowledge,
        removeFromKnowledge,
        archiveNote,

    } = events;

    return (
        <NoteEditor
            key={currentNote.id}
            {...{
                currentNote, currentUserId,

                saveUserOptions,

                titlePlaceholder, contentPlaceholder,
                isSaving, save,

                isUpdatingKnowledge, addToOrUpdateKnowledge, removeFromKnowledge,

                archiveNote,
                // archiveNote

                facade, viewEnv,

                isArticlePublisher,
            }}
        />
    )
}

const emptyContent = [{
    type: 'paragraph',
    children: [{ text: '' }],
}]


const serializedEmptyContent = serialize(emptyContent);

// rich editor

function NoteEditor(props) {

    const track = useTrack();

    const contentKeysRef = useRef([]);

    const {
        currentNote, currentUserId,
        isSaving, save,
        titlePlaceholder, contentPlaceholder,

        saveUserOptions,

        isUpdatingKnowledge, addToOrUpdateKnowledge, removeFromKnowledge, archiveNote,

        facade, viewEnv,

        isArticlePublisher,
    } = props;

    const { remainingQuota, reloadUsageQuota } = useContext(UsageQuotaContext) || {};

    const {
        标题: title, 内容: content, 已加入知识库: isKnowledge,
        可以更新知识库: canUpdateKnowledge, 用户选项: userOptions, 子笔记数量: subNoteCount,

        内容保存标识: contentKey,
    } = currentNote;

    const { isMobile } = viewEnv.webEnv;

    const { sessionToken, sandbox, tenantCode } = useContext(DataLoaderContext)
    const queryRunner = runDataQuery_a({ sessionToken, sandbox, tenantCode })

    const [editingTitle, setEditingTitle] = useState(title || "");

    const [[editingContent, version], setEditingContent] = useState(_ => deserialize(content))

    const editingMarkdownRef = useRef();


    useEffect(() => {
        if (title != editingTitle) {
            setEditingTitle(title);
        }
    }, [title]);

    useEffect(() => {

        // console.log(">>>> c", contentKeysRef.current, contentKey);
        if (contentKeysRef.current.indexOf(contentKey) == -1) {
            const [value, version] = deserialize(content);
            const editingMarkdown = convertSlateToMarkdown(value);
            editingMarkdownRef.current = editingMarkdown;
            setEditingContent([value, version]);
        }
    }, [content]);


    useEffect(() => {
        const editingMarkdown = convertSlateToMarkdown(editingContent);
        editingMarkdownRef.current = editingMarkdown;
    }, [])


    const containerRef = useRef();
    const [containerContentSize, setContainerContentSize] = useState();

    const [isCompositionEditing, setIsCompositionEditing] = useState(false) // 监听拼音输入

    function isTitleDirty() {
        const notDirty = (!title && !(editingTitle && editingTitle.trim()) || (title == editingTitle));
        return !notDirty
    }

    function checkIfContentDirty() {
        if (content == null && serialize(editingContent) == serializedEmptyContent) {
            return false
        } else {
            return !(serialize(editingContent) == content)
        }
    }
    // 因为觉得 serialization 比较重，所以把 isDirty 的状态保存起来
    const isContentDirty = checkIfContentDirty();


    const titleInputRef = useRef();
    const [titleInputHeight, setTitleInputHeight] = useState();

    const focusRef = useRef();

    useResizeObserver(titleInputRef, entry => {
        const height = entry.target.getBoundingClientRect().height;
        setTitleInputHeight(height);
    });

    useResizeObserver(containerRef, entry => {

        const { contentRect } = entry;
        const height = contentRect.height;
        const width = contentRect.width;

        setContainerContentSize({ width, height });
    });


    function updateEditingTitle(editingTitle) {
        setEditingTitle(editingTitle)
    }


    function changeSlateValue(value) {
        setEditingContent([value, 2]);
        const editingMarkdown = convertSlateToMarkdown(value);
        editingMarkdownRef.current = editingMarkdown;
    }

    async function doUpload(file, done) {
        const result = await UploadFile({ queryRunner })({
            file,
            isPublic: true,
            uploadFor: "test-richeditor"
        })

        if (!result) {
            /// TODO error handling
            message.error("图片上传出错")
        } else {
            done(null, result.url)
        }
    }

    async function uploadVideo(file, onUploadProgress, cancelRequest, done, onError) {
        // console.log("uploadVideo file >>> ", file)
        try {
            const result = await UploadFile({ queryRunner })({
                file,
                isPublic: true,
                uploadFor: "test-richeditor",
                onUploadProgress,
                cancelRequest
            })
            if (!result) {
                //TODO: backend error handling
                message.error("视频上传出错")
            } else {
                done(null, result.url)
            }
        } catch (error) {
            console.log("error >>> ", error)
            onError && onError(error)
        }
    }

    function focus() {
        if (focusRef.current) { focusRef.current() }
    }

    const isDirty = isContentDirty || isTitleDirty();

    function tryToSave() {
        if (isDirty && !isSaving) {

            const contentKey = guid();
            contentKeysRef.current = [...contentKeysRef.current, contentKey];

            save({
                title: isTitleDirty() ? editingTitle : undefined,
                content: isContentDirty ? serialize(editingContent) : undefined,

                // markdown: 
                markdown: editingMarkdownRef.current,
                contentKey,
            })
        }
    }

    const debouncedEditingContent = useDebounce(editingContent, 1000);
    const debouncedEditingTitle = useDebounce(editingTitle, 1000);

    useEffect(() => {
        tryToSave()
    }, [debouncedEditingContent, debouncedEditingTitle])

    function renderMoreMenuIcon() {
        const items = [
            {
                label: "从知识库移除",
                icon: <i className='bx bx-folder-minus'></i>,
                onSelect: async _ => {
                    removeFromKnowledge()
                },
                hidden: !isKnowledge
            },
            {
                label: "删除笔记",
                icon: <i className='bx bx-trash'></i>,
                onSelect: _ => {
                    archiveNote()
                },
                isDisabled: isKnowledge || subNoteCount > 0
            },
            {
                label: "提交文章发布",
                icon: <i className='bx bx-send'></i>,
                onSelect: async _ => {

                    const [result, error] = await facade.add({
                        entityName: "文章发布申请",
                        formData: { 标题: editingTitle, 正文: serialize(editingContent) }
                    });
                    if (!error) {
                        toast({ title: "发布申请已提交", duration: 2000 });
                    }

                },
                hidden: !isArticlePublisher
            }


        ].filter(x => !x.hidden)

        return (
            <DropdownMenu items={items}>
                <div className="more-icon">
                    <DotsVerticalIcon />
                </div>
            </DropdownMenu>

        )
    }

    const isOutOfQuota = (() => {
        return remainingQuota !== undefined && remainingQuota <= 0
    })();

    const [outOfQuotaTipShown, setOutOfQuotaTipShown] = useState();

    function renderOutOfQuotaDialog() {
        return (
            <Dialog open={outOfQuotaTipShown}
                onOpenChange={open => setOutOfQuotaTipShown(open)}
                content={<OutOfQuotaTip />}
            />
        )
    }


    function doUpdateKnowledgeBase() {
        if (isOutOfQuota) {
            setOutOfQuotaTipShown(true)
        } else {
            addToOrUpdateKnowledge();
            setTimeout(() => {
                reloadUsageQuota()
            }, 2000)
        }

    }

    useEffect(() => {
        const editingMarkdown = editingMarkdownRef.current;
        if (!editingMarkdown) {
            setWordCount(0)
        } else {
            // TODO  要移除特殊字符
            // 暂时不准就不准吧
            setWordCount(countWords(editingMarkdown));
        }
    }, [(editingMarkdownRef.current || "").trim()]);
    const [wordCount, setWordCount] = useState(0);


    const defaultKnowledgeOptions = undefined;
    const knowledgeOptions = (() => {
        if (userOptions) {
            if (userOptions.scope) {
                return userOptions
            } else {
                return userOptions.knowledgeOptions || defaultKnowledgeOptions
            }
        } else {
            return defaultKnowledgeOptions
        }
    })();



    const minPanelWidth = 320;
    const maxPanelWidth = 480;

    const [panelWidth, setPanelWidth] = useStateWithLocalCache("lc-note-support-panel-width", 400);
    const [panelCollapsed, setPanelCollapsed] = useStateWithLocalCache("lc-note-support-panel-collapsed", true);

    const [resizerActive, setResizerActive] = useState(false);

    function updatePanelWidth(e) {
        setPanelWidth(prev => {
            const newSize = prev - e.movementX;
            return newSize < minPanelWidth ? minPanelWidth : (newSize > maxPanelWidth ? maxPanelWidth : newSize);
        })
    }


    function renderRightPanel() {
        return (
            <div className="hidden sm:flex" style={{
                width: panelCollapsed ? 36 : panelWidth,
                minWidth: panelCollapsed ? 36 : panelWidth
            }}>
                { panelCollapsed ? null : (
                    <div className="lc-note-panel-resizer" onMouseDown={() => {
                        setResizerActive(true);
                    }} />
                )}
                
                <NoteSupportPanel {...{
                    collapsed: panelCollapsed, setCollapsed: setPanelCollapsed,
                    noteId: currentNote.id, facade, viewEnv, currentUserId,
                }} />
            </div>

        )
    }

    return (
        <div className="flex w-full h-full"
            onMouseMove={e => {
                if (resizerActive) {
                    updatePanelWidth(e)
                }
            }}
            onMouseUp={() => {
                if (resizerActive) {
                    setResizerActive(false);
                }
            }}>
            <div className="lc-note-edit" ref={containerRef} style={{
                "--note-title-input-height": titleInputHeight + "px",
                "--note-edit-area-min-height": ((containerContentSize ? containerContentSize.height : 0) - titleInputHeight - 64) + "px", // 最后的 16 是 edit container
            }} onKeyDown={e => {

                // Ctrl-s 和 Meta-s 都是保存
                if (e.key == "s" && (e.ctrlKey || e.metaKey)) {
                    e.preventDefault();
                    tryToSave();
                }

            }}>
                <div className={classNames("note-edit-area", { "pb-4": !isMobile && false })}>
                    <TextareaAutosize className="note-title-input" placeholder={titlePlaceholder || '请输入标题'} ref={titleInputRef}
                        onKeyDown={e => {
                            if ((e.key == "Enter" || e.key === 'Tab') && !isCompositionEditing) {
                                e.preventDefault();
                                focus();
                            }
                        }}
                        value={editingTitle || ""}
                        onChange={e => {
                            updateEditingTitle(e.target.value);
                        }}
                        onCompositionStart={() => setIsCompositionEditing(true)}
                        onCompositionEnd={() => {
                            setTimeout(() => {
                                setIsCompositionEditing(false)
                            }, 16);
                            
                        }}
                    />
                    <SlateEditor value={editingContent} uploadImage={doUpload}
                        uploadVideo={uploadVideo} onChange={changeSlateValue}
                        placeholder={contentPlaceholder}

                        aiEnabled={true}
                        aiOptions={{
                            knowledgeOptions,
                        }}

                        buttonComponents={buttonComponents}
                        noToolbar={true}
                        scrollContainerRef={containerRef}
                        bindFocus={focus => {
                            focusRef.current = focus;
                        }}
                        showBrickToolbar={true}

                        version={2} // always 2
                        customBlockPlaceholder={{
                            'h1': "Heading 1",
                            'h2': "Heading 2",
                            'h3': "Heading 3",
                            'h4': "Heading 4",
                            'h5': "Heading 5",
                            'h6': "Heading 6",
                            'block-quote': "Quote",
                            'list-item': "List",
                        }}
                        showInlineBlockButton={true}
                        showColorButton={true}

                    />
                </div>
                <div className="note-edit-top-bar-cntr" style={{
                    width: containerContentSize && containerContentSize.width
                }}>
                    <div className="note-edit-top-bar max-w-4xl px-2 sm:pl-4 sm:pr-5 py-2 sm:py-2.5" style={{ width: containerContentSize && containerContentSize.width }}>
                        <div className="action-group">
                            <KnowledgeOptionsInput
                                value={userOptions} onChange={options => saveUserOptions(options)} facade={facade} currentUserId={currentUserId}
                            />
                        </div>
                        <div className="action-group">
                            <Button size="mini" isDisabled={!isDirty} isLoading={isSaving} onPress={_ => tryToSave()}>
                                {/* {isDirty ? "保存" : "已保存"} */}
                                <i className='bx bx-save text-[var(--gray11)]'></i>
                            </Button>
                            {(() => {
                                if (isKnowledge) {
                                    return (
                                        <Button size="mini" isDisabled={!canUpdateKnowledge || isSaving} isLoading={isUpdatingKnowledge} onPress={_ => {
                                            track("note_refresh_knowledge_base", { method: "right-button" })
                                            doUpdateKnowledgeBase()
                                        }} color={"violet"}>
                                            更新知识库
                                        </Button>
                                    )
                                } else {
                                    if (canUpdateKnowledge) {
                                        return (
                                            <Button size="mini" isDisabled={isUpdatingKnowledge} isLoading={isUpdatingKnowledge} onPress={_ => {
                                                track("note_add_to_knowledge_base", { method: "right-button" })

                                                doUpdateKnowledgeBase()
                                            }} color={"grass"}>
                                                加入知识库
                                            </Button>

                                        )
                                    }
                                }
                                return null;

                            })()}

                            {renderMoreMenuIcon()}

                        </div>
                    </div>
                </div>
                {isMobile ? null : (
                    // bottom status bar
                    <div className="absolute bottom-0 status-bar justify-center flex h-8" style={{
                        width: containerContentSize && containerContentSize.width
                    }}>
                        <div className="w-full justify-between flex max-w-4xl border-t-[0.5px] bg-[var(--lc-color-bg-1)] px-6 items-center">
                            <div></div>
                            <div className="text-[var(--gray10)] font-size-12">
                                {wordCount} 个字
                            </div>
                        </div>
                    </div>
                )}

                {renderOutOfQuotaDialog()}

            </div>

            {isMobile ? null : (
                renderRightPanel()
            )}
        </div>


    )
}


