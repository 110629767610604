import React from 'react';
import { Editor, Transforms, Text } from 'slate'


export default function InlineStyleButton ({ editor, style, children }) {

    function isFormatActive() {
        const [match] = Editor.nodes(editor, {
            match: n => n[style] === true,
        })

        return !!match
    }

    function toggleFormat() {
        const isActive = isFormatActive()
        Transforms.setNodes(
            editor,
            { [style]: isActive ? null : true },
            { match: Text.isText, split: true }
        )
    }

    return (
        <button className={`editor-button ${isFormatActive() ? " active" : ""}`} onClick={() => toggleFormat()}>
            {children}
        </button>
    )
}

