
import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom';
import { useSlate, useFocused } from 'slate-react'
import { Editor, Range, Transforms, Text } from 'slate'

const topToolbarHeight = 32
const triangleHeight = 4

export function getTopValue (editAreaContainer, el, rect, setArrowPlacement) {

    function getRelativeParent(element) {
        if (!element) {
            return document.body;
        }

        const position = window.getComputedStyle(element).getPropertyValue('position');
        if (position !== 'static') {
            return element;
        }

        return getRelativeParent(element.parentElement);
    };

    const parentElement = getRelativeParent(el.parentElement)

    if (parentElement) {
        const parentElementTop = parentElement.getBoundingClientRect().top
        // 不使用 editorPosition.editorRect.top 是因为当外部容器滚动时，editorRect 不会改变。
        if (rect.top - parentElementTop - topToolbarHeight > el.offsetHeight + triangleHeight) {
            if(setArrowPlacement) {
                setArrowPlacement("bottom")
            }
            return rect.top - parentElementTop + editAreaContainer.scrollTop - el.offsetHeight - 8 // 箭头距离选中区域有 8px 的间隔
        } else {
            if(setArrowPlacement) {
                setArrowPlacement("up")
            }
            return rect.bottom - parentElementTop + editAreaContainer.scrollTop + 8
        }
    } else {
        return rect.top - el.offsetHeight + editAreaContainer.scrollTop 
    }
}

export function getLeftValue(editorRect, el, rect) {
    if (editorRect) {
        if (rect.width < el.offsetWidth) {
            if (rect.left - editorRect.left + rect.width / 2 < el.offsetWidth / 2) {
                // 选择内容靠近 eidtor 左边
                return el.offsetWidth / 2 + 8
            } else if (editorRect.right - rect.right + rect.width / 2 < el.offsetWidth / 2) {
                // 选择内容靠近 editor 右边
                return editorRect.width - el.offsetWidth / 2 - 8
            } else {
                return (rect.left - editorRect.left) + rect.width / 2
            }
        } else {
            return (rect.left - editorRect.left) + rect.width / 2
        }
    } else {
        return el.offsetWidth / 2 + 8
    }
}

export default function InlineToolBar(props) {

    const { 
        originalEditor, selectedNode, toolbarButtons, keepInlineToolbar,
        setKeepInlineToolbar, editAreaContainer, buttonComponents,
        isLinkEditing, setIsLinkEditing

    } = props

    const editorRect = editAreaContainer && editAreaContainer.getBoundingClientRect()
    const inlineToolBarRef = useRef(null) 
    const buttonsGroupRef = useRef(null)

    const [overrideContent, setOverrideContent] = useState(null)
    const [arrowPlacement, setArrowPlacement] = useState("bottom")
    const [selectionRange, setSelectionRange] = useState(null)
    const [inlineToolbarVisible, setInlineToolbarVisible] = useState(true)

    const editor = useSlate()
    const inFocus = useFocused()

    useEffect(() => {
        const el = inlineToolBarRef.current
        const { selection } = editor

        if (!el || typeof(window) === 'undefined' || typeof(document) === 'undefined') {
            return
        }

        if ((!selectedNode && (!selection || Range.isCollapsed(selection) || Editor.string(editor, selection) === '')) || 
            (!inFocus && !overrideContent && !keepInlineToolbar)) {
            
            if(overrideContent) {
                setOverrideContent(null)
            }

            if(isLinkEditing) {
                setIsLinkEditing(false)
            }

            el.removeAttribute('style')

            // delete wrap style
            el.style.width = ""
            el.style.maxWidth = ""
            if(buttonsGroupRef.current) {
                buttonsGroupRef.current.style.flexWrap = ""
            }
            
            return
        }

        function updateToolBarStyle(rangeOrNode) {
            const rect = rangeOrNode.getBoundingClientRect()

            /**
             * https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1209
             * 有 overrideContent 时，不添加 width 和 flexWrap 属性，避免出现空白区域
             */

            if(!overrideContent) {
                if(buttonsGroupRef.current && editorRect && el.offsetWidth > editorRect.width * 0.9) {
                    // if toolbar is too long, wrap toolbar
                    el.style.width = "100%"
                    el.style.maxWidth = editorRect ? editorRect.width * 0.9 + 'px' : "90%"
                    buttonsGroupRef.current.style.flexWrap = "wrap"
                }
            } else {
                el.style.width = ""
                el.style.maxWidth = ""
                if(buttonsGroupRef.current) {
                    buttonsGroupRef.current.style.flexWrap = ""
                }
            }
            
            
            
            el.style.opacity = '1'
            el.style.top = `${getTopValue(editAreaContainer, el, rect, setArrowPlacement)}px`
            el.style.left = `${getLeftValue(editorRect, el, rect)}px`
            el.style.transform = `translate(-50%) scale(1)`
            el.style.visibility = inlineToolbarVisible ? "visible" : "hidden"
        }

        const domSelection = window.getSelection()
        if(domSelection.anchorNode) {
            const domRange = domSelection.getRangeAt(0)
            if(selectedNode) {
                updateToolBarStyle(selectedNode)
            } else if (keepInlineToolbar) {
                // 编辑超链接
                const highlightText = document.getElementsByClassName('richtext-highlight-text')[0]
                if(highlightText) {
                    updateToolBarStyle(highlightText)
                }
            } else if (domRange.collapsed && selectionRange && !selectionRange.collapsed) {
                // 选中区域被折叠(起点和终点位于同一个地方)
                updateToolBarStyle(selectionRange)
            } else if (!domRange.collapsed) {
                setSelectionRange(domRange)
                updateToolBarStyle(domRange)
            }
        }
    })

    function renderToolBarContent() {

        const childrenProps = {
            editor: originalEditor,
            keepInlineToolbar: keepInlineToolbar,
            selectedNode: selectedNode,
            bindOverrideContent: (oc) => {
                setOverrideContent(oc)
                setKeepInlineToolbar(oc ? oc.persistent : false)
            },
            toggleInlineToolbarVisible: () => setInlineToolbarVisible(!inlineToolbarVisible),
            setKeepInlineToolbar: setKeepInlineToolbar,
            ...props
        }

        function preventBubblingAndDefault(e) {
            e.stopPropagation()
            e.preventDefault()
        }

        const className = overrideContent && overrideContent.outerClassName ? ` ${overrideContent.outerClassName}` : ""

        const overrideContentComp = overrideContent ? overrideContent.component && typeof overrideContent.component === 'function' ?
            overrideContent.component() : overrideContent.component : null

        return (
            <div className={`inline-toolbar ${className} ${arrowPlacement}`} ref={inlineToolBarRef}>
                <div className={"toolbar-content"} style={{ width: "100%" }} onClick={e => preventBubblingAndDefault(e)}>
                    {
                        overrideContentComp ? overrideContentComp : (
                            <div className="editor-button-group" ref={buttonsGroupRef}>
                                {toolbarButtons.map((button, i) => {
                                    if (typeof (button) === 'string') {
                                        const Button = buttonComponents[button]
                                        if(!Button) {
                                            return null
                                        }
                                        return (
                                            <div key={i} onMouseDown={e => e.preventDefault()}>
                                                <Button {...childrenProps} />
                                            </div>
                                        )
                                    } else {
                                        return button
                                    }
                                })}
                            </div>
                        )
                    }

                </div>
            </div>
        )
    }


    if (typeof (document) !== "undefined") {

        const slateEditors = document.getElementsByClassName("editor-area-container")
        if (slateEditors && slateEditors.length > 0) {
            return ReactDOM.createPortal(
                renderToolBarContent(),
                slateEditors[0]
            );
        }

    } else {
        return null
    }

}

