import React from 'react'

class InvestIcon extends React.Component {

    render() {
        return (
            <svg width="1em" height="1em" viewBox="0 0 12 12">
                <defs>
                    <path d="M0.32548495,3.81671008 L5.78327759,0.144185433 C5.84468227,0.100464902 5.92053512,0.0769230769 6,0.0769230769 C6.07946488,0.0769230769 6.15531773,0.103828019 6.21672241,0.144185433 L11.6745151,3.81671008 C11.7359197,3.85706749 11.7792642,3.92432985 11.7792642,4.00168156 C11.7792642,4.07903327 11.7395318,4.14629562 11.6745151,4.18665304 L6.21672241,7.85581457 C6.15531773,7.8995351 6.07946488,7.92307692 6,7.92307692 C5.92053512,7.92307692 5.84468227,7.89617198 5.78327759,7.85581457 L0.32548495,4.18665304 C0.260468227,4.14629562 0.220735786,4.07567015 0.220735786,4.00168156 C0.220735786,3.92432985 0.260468227,3.85706749 0.32548495,3.81671008 Z" id="path-1"></path>
                </defs>
                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Icon/VIP-services/投资" transform="translate(-2.000000, -2.000000)">
                        <g id="Group">
                            <g transform="translate(2.000000, 2.000000)">
                                <path d="M11.7792642,7.99831844 C11.7792642,8.07567015 11.7395318,8.14293251 11.6745151,8.18328992 L6.21672241,11.8558146 C6.15531773,11.8995351 6.07946488,11.9230769 6,11.9230769 C5.92053512,11.9230769 5.84468227,11.896172 5.78327759,11.8558146 L0.32548495,8.18328992 C0.264080268,8.14293251 0.220735786,8.07567015 0.220735786,7.99831844 C0.220735786,7.92096673 0.260468227,7.85370438 0.32548495,7.81334696 L5.78327759,4.14418543 C5.84468227,4.1004649 5.92053512,4.07692308 6,4.07692308 C6.07946488,4.07692308 6.15531773,4.10382802 6.21672241,4.14418543 L11.6745151,7.81671008 C11.7395318,7.85370438 11.7792642,7.92432985 11.7792642,7.99831844 Z" id="Shape" fill="#FFFFFF" fillRule="nonzero"></path>
                                <mask id="mask-2" fill="white">
                                    <use href="#path-1"></use>
                                </mask>
                                <use id="Shape" fillOpacity="0.5" fill="#FFFFFF" fillRule="nonzero" href="#path-1"></use>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export default InvestIcon
