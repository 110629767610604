
import React, { useContext, useEffect } from 'react'

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'

import RenderWithData from 'bwax-ui/store/RenderWithData'

import { USER_DURABLE_RUNTIME } from 'bwax/store/DataCache';

import page_entry_slim from 'bwax-ui/ml/page_entry_slim.bs'

import pageRuntimeModules from 'bwax-ui/gen/page_runtime_modules.json';
import { untag } from 'bwax/lang/LangHelper'

const gmod = untag(pageRuntimeModules);

// 这个 element 的作用，只是通过 loadData，把 facade 设置进 FacadeFactoryContent 中去
export default function FacadeContainer (props) {

    const dlc = useContext(DataLoaderContext);
    const { application } = props;

    const loadData = async () => {
        try {
            const BwaxFacade = require("bwax/facade").default;
    
            const facade = new BwaxFacade({  
                // queryRunner, queryCache
                dlc,
                gmod, prepareEnv: page_entry_slim.prepare_eval_env,
                applicationCode: application.code
             });
                
            await facade.init(); 
    
            // prepare 一些肯定会用到的 entity
            await facade.prepare(["验证用户", "选项类别", "选项文本值", "数据记录评论", "数据更改日志"])


            return [ facade, undefined ];

        } catch (error) {
            return [ null, error ];
        }

    };

    return (
        <RenderWithData {...{
            ...props, 
            loadData,

            // facade 跟 dlc 相关，不能是 global runtime
            // 但其实 DLC 也不一定是跟用户相关的，facade 也不一定跟用户相关
            // 暂时先改成这样，之后再来重新梳理吧 
            
            cacheType: USER_DURABLE_RUNTIME,
            dataKey: "BWAX_FACADE_" + application.code,
            Component: FacadeContainerInner,
            // fallback: <PlainColor name="G60" />

            dlc,
            noSuspense: true,
        }} />
    )

}

export function FacadeContainerInner (props) {

    const { data, children, application, Comp, ...remaining } = props;
    const [ facade, error ] = data ;

    // 这里需要设置一下：
    // 这是因为在服务端中， facadeFactory 是在每个 request 都产生的，但 facade 有可能会被 globally cached
    // TODO 可以考虑优化
    // facadeFactory.setFacade(application.code, facade);
    // facadeFactory.setCurrentApplicationCode(application.code);

    // TODO 这样不行，这里设置了好像又会导致前端出问题。

    // 这里可能要改一下 Promise 方案，不能使用 RenderWithData 的标准方式

    if(error) {
        console.log(error);
        return "Error occurred during facade initialization";
    }
    
    return (
        <Comp {...{ facade, ...remaining}} />
    )
}

