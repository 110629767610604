
// This file is generated. Don't modify it.
import React from 'react'
export default function Broadcast1Icon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <rect id="Broadcast1Icon-path-1" x="0" y="0" width="16" height="16"></rect>
                <path d="M7.5,11.1777438 L7.5,9.52695032 C6.91740381,9.32103211 6.5,8.76541258 6.5,8.11230317 C6.5,7.28387605 7.17157288,6.61230317 8,6.61230317 C8.82842712,6.61230317 9.5,7.28387605 9.5,8.11230317 C9.5,8.76541258 9.08259619,9.32103211 8.5,9.52695032 L8.5,11.1750117 L10.6202225,13.2952342 L9.91311571,14.002341 L8.00136604,12.0905914 L6.08961638,14.002341 L5.3825096,13.2952342 L7.5,11.1777438 Z M4,7.99765898 C4,5.78851998 5.790861,3.99765898 8,3.99765898 C10.209139,3.99765898 12,5.78851998 12,7.99765898 L11,7.99765898 C11,6.34080473 9.65685425,4.99765898 8,4.99765898 C6.34314575,4.99765898 5,6.34080473 5,7.99765898 L4,7.99765898 Z M13,7.99765898 C13,5.23623523 10.7614237,2.99765898 8,2.99765898 C5.23857625,2.99765898 3,5.23623523 3,7.99765898 L2,7.99765898 C2,4.68395048 4.6862915,1.99765898 8,1.99765898 C11.3137085,1.99765898 14,4.68395048 14,7.99765898 L13,7.99765898 Z" id="Broadcast1Icon-path-3"></path>
            </defs>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Live-broadcast1">
                    <g>
                        <mask id="Broadcast1Icon-mask-2" fill="white">
                            <use href="#Broadcast1Icon-path-1"></use>
                        </mask>
                        <mask id="Broadcast1Icon-mask-4" fill="white">
                            <use href="#Broadcast1Icon-path-3"></use>
                        </mask>
                        <use id="Combined-Shape" fill={color} fillRule="nonzero" href="#Broadcast1Icon-path-3"></use>
                        <g id="Color/UI/Text" mask="url(#Broadcast1Icon-mask-4)" fill={color} fillRule="nonzero">
                            <rect id="light-gray" x="0" y="0" width="16" height="16"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
