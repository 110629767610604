

import React, { useState } from 'react'
import SelectFileButton from '../inputs/SelectFileButton'

import UploadFile from 'bwax-ui/actions/UploadFile';

import "./FileInput.less"
import FileIcon from '../FileIcon';

export default function FileInput({ value, onChange, onError = _ => { }, accept, uploadFor = "add-record" , facade, asButton = true, children }) {

    // 
    const [attachment, setAttachment] = useState(value);
    const [uploading, setUploading] = useState();

    async function onSelectFile(lf) {

        setUploading(true)

        const [attachment, errors] = await UploadFile({ file: lf, uploadFor })(facade.dlc);

        setUploading(false);
        if (errors || !attachment) {
            onError(errors || "上传失败");            
            return
        }

        setAttachment(attachment)
        onChange(attachment)
        
    }

    function renderChildren(attachment) {
        if(typeof(children) == "function") {
            return children(attachment)
        } else {
            return children
        }
    }

    return (
        <div className="lc-file-input">
            <SelectFileButton onSelectFile={onSelectFile} isUploading={uploading} accept={accept} asButton={asButton}>
                { children ? renderChildren(attachment) : (attachment ? "更换文件" : "点击上传") }
            </SelectFileButton>
            {attachment ? (
                <div className="lc-file-line">
                    <FileIcon file={attachment} />
                    { attachment.title }
                </div>
            ) : null}
        </div>
    )
}
