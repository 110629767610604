
import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import Icons from './icons/builtin/gen'
import CustomIcons from './icons/custom'

const AllIcons = {
    ...Icons,
    ...CustomIcons,
}

export default function Icon(props) {
    const { params = {} } = props
    const { size, color, type } = params

    const sizeMap = {
        small: 12,
        medium: 16,
        large: 24
    }

    const s = sizeMap[size]

    /// 如果没有指定合适的 size，那么就继承上级元素的 font size
    const style = {
        textAlign: "center",
        lineHeight: 0,
        ...(
            s !== undefined ? { fontSize: s } : {}
        )
    }
    const Glyph = typeof (type) === 'string' ? AllIcons[typeToName(type)] : null

    return (
        <Suspense fallback={null}>
            { Glyph ? 
                <i style={style}><Glyph color={color || '#D8D8D8'} /></i> : 
                null 
            }
        </Suspense>
    )
}

Icon.allTypes = () => {
    return Object.keys(AllIcons).map(nameToType)
}

function typeToName(type) {
    return type + 'Icon'
}

function nameToType(name) {
    return name.replace('Icon', '')
}


// function typeToName(type) {
//     const split = type.split('-')
//     const capitalized = split.map(str => {
//         return str.substring(0, 1).toUpperCase() + str.substring(1)
//     })
//     return capitalized.join('') + 'Icon'
// }
// export function nameToType(name) {
//     const words = getWords(name)
//     return words.map(w => w.toLowerCase()).filter(
//         w => w !== 'icon'
//     ).join('-')
// }



// function getWords(name) {
//     const p = /([A-Z][a-z]*)/g
//     let words = []
//     while (true) {
//         const matched = p.exec(name)
//         if (matched === null) {
//             break
//         }
//         words.push(matched[0])
//     }
//     return words
// }


Icon.propTypes = {
    params: PropTypes.shape({
        size: PropTypes.oneOfType([
            PropTypes.oneOf(['small', 'medium', 'large']),
            PropTypes.number
        ]),
        color: PropTypes.string,
        type: PropTypes.string,
    })
}
