

import { isMobile, isWeChat, isIOS, isWxWork } from 'bwax/clientEnv'


import getAuthURL, { getWxWorkAuthURL } from 'bwax-ui/auth/getAuthURL';

import { removeTokenCookie } from 'bwax-ui/setTokenCookie'

import { addQueryParam } from 'bwax/ml/lang/mod/builtin/StringHelper';



export function gotoWechatLogin({sandbox, tenantCode}) {
    // directly go to we chat login
    if (typeof (window) != "undefined") {
        document.__switching_to_login__ = true;
        window.location.replace(getAuthURL({ wxInfo: true }, {sandbox, tenantCode}))
    } 
}

export function gotoWxWorkLogin({sandbox, tenantCode}) {
    // directly go to we chat login
    if (typeof (window) != "undefined") {
        document.__switching_to_login__ = true;
        window.location.replace(getWxWorkAuthURL({sandbox, tenantCode}))
    } 
}


export function gotoWechatLoginIfPossible(sandbox, tenantCode) {
    if(isWeChat()) {
        gotoWechatLogin({sandbox, tenantCode});
        return true 
    } else if (isWxWork()) {
        gotoWxWorkLogin({sandbox, tenantCode});
        return true 
    } else {
        return false 
    }
}


export function gotoLoginUrlOrWechatLogin(loginUrl, routeTo, currentURL, sandbox, tenantCode) {
    // 如果有 loginUrl 而且不在微信，跳转到指定的 loginUrl
    // 否则，直接跳转微信登录
    if(typeof(window) != "undefined") {
        const handled = gotoWechatLoginIfPossible(sandbox, tenantCode);
        if(loginUrl && !handled) {
            // 如果当前的路径就是 loginUrl，则不能再跳转了
            const currentPathname = window.location.pathname;
            function isLoginUrl () {
                // prefix 应该从某个地方统一传过来
                const prefix = (sandbox ? "/sandbox" : "") + (tenantCode ? "/-/" + tenantCode : "");
                return currentPathname.startsWith(prefix + loginUrl);
            }
            if(isLoginUrl()) {
                console.log("already inside login page");
            } else {
                // const returnTo = encodeURIComponent(window.location.href)
                const returnTo = currentURL || window.location.href;
                routeTo(addQueryParam("rt", returnTo, loginUrl), true);
            }
        } else {    
            console.log("No login method");
        }
    }
}


export function addInvisibleElement (innerHTML) {
    console.log("Here we add invisible element", innerHTML);

    //  perform the copy text
    if (typeof (document) !== "undefined") {

        let ele = document.createElement('div');

        ele.style.position = 'absolute';
        ele.style.left = '-99999px';
        ele.innerHTML = innerHTML;

        document.body.appendChild(ele);

        // if there's any script inside the innerHTML, eval it
    
        // 假定里面只有一个
        let p = /<script>(.+)<\/script>/
        let m = p.exec(innerHTML)

        if(m) {
            console.log("Eval script", m)
            try { (0, eval)(m[1]) } catch (e) {} 
        }

    }

}


export function copy(text) {

    // console.log("Here we copy", text);

    //  perform the copy text
    if (typeof (document) !== "undefined") {

        let fakeElem = document.createElement('textarea');

        fakeElem.setAttribute('readonly', '');
        fakeElem.value = text;
        fakeElem.style.position = 'absolute';
        fakeElem.style.left = '-99999px';

        document.body.appendChild(fakeElem);
        fakeElem.select();
        document.execCommand("copy");

        document.body.removeChild(fakeElem)
    }
}

export function alert(v) {
    if (typeof (window) != "undefined") {
        window.alert(v);
    }
}

export function logout(tenantCode, sandbox) {
    // TODO 如果是 design mode，要做相应的处理
    removeTokenCookie({tenantCode, sandbox});
    // 也要把平台的 cookie 删掉（至少在 SimplifyAI 产品是这样）
    removeTokenCookie({ sandbox});
}


// 以后在加上 theme config 设定：诸如 显示位置， 最大数量 之类的

export function toast(text, type, duration = 2000) {

    //  type : SUCCESS, ERROR, INFO, WARNING, LOADING
    if (typeof (document) !== "undefined" && typeof (window) !== "undefined") {
        //  
        console.log("I will toast", text, "for", duration, "ms")

        let toastElem = document.createElement('div')

        toastElem.style.padding = "1rem"
        toastElem.style.position = "fixed"
        toastElem.style.top = "50%"
        toastElem.style.left = "50%"
        toastElem.style.backgroundColor = "#000"
        toastElem.style.opacity = "0.8"
        toastElem.style.borderRadius = "0.5rem"
        toastElem.style.transform = "translate(-50%, -50%)"
        toastElem.style.color = "#fff"
        toastElem.style.zIndex = "9999"
        toastElem.style.textAlign = "center";
        
        toastElem.classList.add("lc-base");
        

        toastElem.innerText = text
        document.body.appendChild(toastElem);

        setTimeout(() => {
            document.body.removeChild(toastElem);
        }, duration);

    }

}


// scrollIntoView

export function scrollIntoView(elementId) {

    if(typeof(document) != 'undefined') {

        let elmnt = document.getElementById(elementId);

        if (elmnt) {
            elmnt.scrollIntoView();
        } else {
            console.log("scrollIntoView: element of", elementId, "is not found.")
        }
    }
}



// 一下方法要考虑 server render 时怎么办
export { isMobile, isWeChat, isIOS } from 'bwax/clientEnv'


export function currentURLPath () {
    if(typeof(document) != 'undefined') {
        return document.location.pathname
    } else {
        // TODO 这个需要进一步完善
        return "/"
    }    
}

export function currentURL () {
    if(typeof(document) != 'undefined') {
        return document.location.href
    } else {
        // TODO 这个需要进一步完善
        return "/"
    }
}


/// download url
export function downloadURL (url, name) {
    if(typeof(document) != 'undefined') {
        let link = document.createElement('a')
        link.href = url
        if(name) {
            link.download = name
        }
        link.dispatchEvent(new MouseEvent('click'))
    }
}
