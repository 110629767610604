
import { Base64 } from 'js-base64';

import queryString from 'query-string'

import { hashCode as hc } from 'bwax/utils';

export function toBase64 (str) {
    return Base64.encode(str)
}

export function toBase64URI (str) {
    return Base64.encodeURI(str)
}

export function fromBase64 (b) {
    return Base64.decode(b)
}

export function hashCode(str) {
    return hc(str)
}


export function addQueryParam (name, param, url) {
    if(name && param) {
        return queryString.stringifyUrl({url, query: {[name]: param}})
    } else {
        return url
    }
}

export function addQueryParams (params, url) {
    return queryString.stringifyUrl({url, query: params})
}


export function removeQueryParam (name, path) {
    if(name) {
        let { url, query } = queryString.parseUrl(path);
        let { [name]: _, ...remaining } = query;

        return queryString.stringifyUrl({url, query:remaining});
    } else {
        return path
    }
}

export function getQueryParam (name, path) {
    if(name) {
        const { query } = queryString.parseUrl(path);
        return query[name]
    } else {
        return undefined
    }
}



export function isValidHttpURL (string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
    return url.protocol === "http:" || url.protocol === "https:";
}



// remove id and url
export function removeIdAndUrl (object = {}) {
    if (!object) {
        return object
    }
    const { id, url, ...remaining } = object;
    return remaining;

}