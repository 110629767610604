
import React from 'react'

// import { STYLE_CONFIG, resolveStyles } from './inlineStyleHelpers'

// copied from inlineStyleHelplers to avoid importing Draft.js
const STYLE_CONFIG = {
    color: "color-(.*)",
    backgroundColor: "backgroundColor-(.*)",
    // fontSize: /fontSize-(\d*(px|em|pt|%)?)/  // 不支持导入的字体大小
  }
  
  export {STYLE_CONFIG}
  
  export function resolveStyles(styles) {
    return styles.reduce((acc, style) => {
      for(let name in STYLE_CONFIG) {
        const match = style.match(STYLE_CONFIG[name])
        if(match) {
          // accumulate the style
          return {
            ...acc,
            [name]: match[1]
          }
        }
      }
      return acc
    }, {})
  
  }
// copied end.


const convertFromHTML = {
    htmlToStyle: (nodeName, node, currentStyle) => {
        // color part
        const convertStyle = Object.keys(STYLE_CONFIG).reduce((acc, styleName) => {
            if (node.style && node.style[styleName]) {
                return acc.add(styleName + "-" + node.style[styleName])
            }
            return acc
        }, currentStyle)

        if ((node.style && node.style['vertical-align'] === 'super') || nodeName === 'sup') {
            return convertStyle.add('SUPERSCRIPT');
        }

        if ((node.style && node.style['vertical-align'] === 'sub') || nodeName === 'sub') {
            return convertStyle.add('SUBSCRIPT');
        }

        return convertStyle
    }
}

const convertToHTML = {
    styleToHTML: (style) => {
        if (style === 'SUPERSCRIPT') {
            return {
                start: '<sup>',
                end: '</sup>'
            }
        }
        if (style === 'SUBSCRIPT') {
            return {
                start: '<sub>',
                end: '</sub>'
            }
        }
        // color part
        for (let name in STYLE_CONFIG) {
            const match = style.match(STYLE_CONFIG[name])
            if (match) {
                return <span style={{ [name]: match[1] }} />
            }
        }
    }
}

const convertToReact = {
    styleToReact: (style) => {
        if (style === 'SUPERSCRIPT') {
            return {
                verticalAlign: "super",
                fontSize: "75%",
                lineHeight: 0
            }
        }

        if (style === "SUBSCRIPT") {
            return {
                verticalAlign: "sub",
                fontSize: "75%",
                lineHeight: 0
            }
        }

        // color part
        for (let name in STYLE_CONFIG) {
            const match = style.match(STYLE_CONFIG[name])
            if (match) {
                return { [name]: match[1] }
            }
        }
    }
}

export {
    convertFromHTML,
    convertToHTML,
    convertToReact
}
