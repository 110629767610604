import React from 'react'

class SignIcon extends React.Component {

    render() {
        return (


            <svg width="1em" height="1em" viewBox="0 0 13 14">
                <defs>
                    <path d="M8.09974544,0.08 L9.38453822,1.40520529 L9.38453822,9.35988711 L9.38453822,9.67494075 L8.78089763,10.0761465 L8.19587778,10.7222133 L7.60608077,12.3152396 L3.05988667,12.3152396 C1.40303242,12.3152396 0.0598866691,10.9720938 0.0598866691,9.31523958 L0.0598866691,3.08 C0.0598866691,1.42314575 1.40303242,0.08 3.05988667,0.08 L8.09974544,0.08 Z" id="SignIcon-path-1"></path>
                    <path d="M11.65875,9.1481596 L11.65875,3.499375 L10.616875,3.499375 C10.3880181,3.499375 10.2025,3.31385687 10.2025,3.085 L10.2025,1.91375 L2.63375,1.91375 L2.63375,13.76625 L8.96266281,13.76625 L8.62559312,14.08 L2.734375,14.08 C2.50551812,14.08 2.32,13.8944819 2.32,13.665625 L2.32,2.014375 C2.32,1.78551812 2.50551812,1.6 2.734375,1.6 L10.199375,1.6 L11.9725,3.44 L11.9725,8.65280845 L11.65875,9.1481596 Z M11.762839,10.2243843 L13.6189122,12.0805984 L13.1809544,12.9345385 C13.1471567,13.0003712 13.0888144,13.0502633 13.0185339,13.0734341 L9.99015958,14.0717636 C9.98170751,14.0750036 9.97269198,14.0776801 9.96339471,14.0795114 L11.612674,12.430232 C11.798778,12.5182892 12.0212545,12.4594695 12.1395208,12.2909411 C12.2577872,12.1224128 12.2374451,11.8931929 12.0913487,11.7481254 C11.9452523,11.6030578 11.7158944,11.5843362 11.5482061,11.7037905 C11.3805177,11.8232449 11.3232717,12.0461316 11.4126419,12.2316085 L9.76293993,13.8815922 C9.76533469,13.8699002 9.76899725,13.8582082 9.77392762,13.8470796 L10.770285,10.8250443 C10.7934559,10.7547638 10.8433479,10.6964215 10.9091806,10.6626238 L11.7629799,10.2245252 L11.762839,10.2243843 Z M13.858669,11.9218404 L11.8989172,9.96208864 L11.9918899,9.77966493 C12.0328527,9.69937872 12.1097561,9.64353766 12.1987788,9.62943892 C12.2878015,9.61534017 12.3781962,9.64468583 12.4419623,9.70838586 L14.1123718,11.3786545 C14.1761906,11.4424199 14.2056128,11.5328912 14.1915096,11.6219977 C14.1774064,11.7111042 14.1214837,11.7880677 14.0410927,11.8290086 L13.858669,11.9219813 L13.858669,11.9218404 Z M6.989375,7.4515625 L6.989375,5.5121875 L7.463125,5.5121875 L7.463125,7.4515625 L9.2425,7.4515625 L9.2425,7.9253125 L7.463125,7.9253125 L7.463125,9.7046875 L6.989375,9.7046875 L6.989375,7.9253125 L5.21,7.9253125 L5.21,7.4515625 L6.989375,7.4515625 Z" id="SignIcon-path-3"></path>
                </defs>
                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Icon/How-it-works/签署" transform="translate(-2.000000, -1.000000)">
                        <g>
                            <rect id="bg" fillRule="nonzero" x="0" y="0" width="16" height="16"></rect>
                            <g id="Rectangle" transform="translate(2.000000, 2.000000)">
                                <mask id="SignIcon-mask-2" fill="white">
                                    <use href="#SignIcon-path-1"></use>
                                </mask>
                                <use id="Mask" fill="#F0F3F5" fillRule="nonzero" href="#SignIcon-path-1"></use>
                                <rect fill="#F0F3F5" fillRule="nonzero" mask="url(#SignIcon-mask-2)" x="-2" y="-2" width="16" height="16"></rect>
                            </g>
                            <mask id="SignIcon-mask-4" fill="white">
                                <use href="#SignIcon-path-3"></use>
                            </mask>
                            <use id="Mask" fill="#173652" fillRule="nonzero" href="#SignIcon-path-3"></use>
                            <rect id="Rectangle" fill="#173652" fillRule="nonzero" mask="url(#SignIcon-mask-4)" x="0" y="0" width="16" height="16"></rect>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export default SignIcon
