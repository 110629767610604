

import numeral from 'numeral'

export function parse (str) {
    const n = numeral(str);
    const v = n.value();
    if(v == null) {
        return NaN
    } else {
        return v
    };
}

export function format (fmt, num) {
    const n = numeral(num);
    return n.format(fmt);
}