


import React, { useState, useEffect, useRef } from 'react';

// import "./SwiperTest.less";

// Import Swiper React components
import SwiperCore, { Virtual, EffectFade, EffectCube, EffectCoverflow, EffectFlip, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
// import 'swiper/swiper.min.css'
// import 'swiper/components/effect-fade/effect-fade.min.css';
// import 'swiper/components/effect-coverflow/effect-coverflow.min.ss'; // 不加也有 coverflow 的效果
// import 'swiper/components/effect-flip/effect-flip.scss';
// import 'swiper/components/effect-cube/effect-cube.scss';

import 'swiper/swiper-bundle.min.css'

// install Virtual module
SwiperCore.use([Virtual, EffectFade, EffectCube, EffectCoverflow, EffectFlip, Autoplay]);

export default function ({ slides, onActiveIndexChange, activeIndex, options }) {

    const [ slided, setSlided ] = useState(activeIndex);
    const swiperRef = useRef(null);

    useEffect(() => {
        const swiper = swiperRef.current;
        if(activeIndex != slided && swiper) {
            swiper.slideTo(activeIndex)
        } else {
        }

    }, [ activeIndex ])

    return (
        <Swiper
            slidesPerView={1}
            watchSlidesVisibility={true}
            onActiveIndexChange={swiper => {
                setSlided(swiper.activeIndex);
                onActiveIndexChange && onActiveIndexChange(swiper.activeIndex)
            }}
            onSwiper={swiper => {
                swiperRef.current = swiper;
            }}
            {...{
                ...options,
                ...(options.noSwiping ? { noSwipingClass: "swiper-slide" } : {}),
                ...(options.effect === 'fade' ? { fadeEffect: { crossFade: true } } : {})
            }}
        // virtual
        >
            {slides.map((s, index) => (
                <SwiperSlide key={index}// virtualIndex={index}
                >
                    { s }
                </SwiperSlide>
            ))}
            {/* <div slot="container-start">Container Start</div>
                <div slot="container-end">Container End</div>
                <div slot="wrapper-start">Wrapper Start</div>
                <div slot="wrapper-end">Wrapper End</div> */}
        </Swiper>
    )
}

