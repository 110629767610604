
export async function loadRecordList({ query_config, entityName, fieldPaths, pageSize, offset, facade }) {


    const isCustomQuery = facade.isCustomQuery(query_config);
    async function doQuery() {
        if (isCustomQuery) {

            const queryObj = {
                query_config,
                pageSize,
                offset,
                outputFieldPaths: [
                    [entityName, fieldPaths]
                ]
            }
            const [result, error] = await facade.customQuery(queryObj, {});
            return [result, error];

        } else {
            const queryObj = {
                entityName,
                query_config, // facade 支持 query_config， 也支持 js 版本的 condition, sort
                fieldPaths,

                pageSize, offset

            };
            const [result, error] = await facade.listAll(queryObj, {});

            return [result, error]
        }
    }

    return await doQuery();
}
