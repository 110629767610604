
import React, { useState } from 'react'
import { Transforms, Editor } from 'slate'

import { MdKeyboardArrowDown, MdLoop } from 'react-icons/md'
import { CheckIcon } from '@radix-ui/react-icons';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { basicBlockTypes } from '../BrickToolbar/BrickToolbar'
import { toggleBlockType } from './util/createBlockTypeButton'
import { ReactEditor } from 'slate-react';
import { isIOS, isMobile } from 'bwax/clientEnv'

const blockTypesMap = {
    'paragraph': '文本',
    'h1': '标题1',
    'h2': '标题2',
    'h3': '标题3',
    'h4': '标题4',
    'h5': '标题5',
    'h6': '标题6',
    'block-quote': '引用',
    'numbered-list': '有序列表',
    'bulleted-list': '无序列表',
}

export default function BlockTypeButton(props) {

    const { editor, bindOverrideContent, setBrickToolbarInfo, mobileToolbarRef } = props

    const [blocksListOpen, setBlocksListOpen] = useState(false)

    const { selection } = editor
    if(!selection) {
        return null
    }

    const { anchor, focus } = selection

    const [highestNode, highestPath] = Editor.node(editor, focus.path, { depth: 1 })

    const blockTypes = basicBlockTypes.map(blockType => {
        const { subLabel, subIcon, type } = blockType
        return {
            ...blockType,
            label: subLabel,
            icon: subIcon,
            isBlockTypeActive: highestNode.type === type,
            onSelect: () => toggleBlockType(editor, type)
        }
    })

    const pcButton = (
        <DropdownMenu.Root modal={false} onOpenChange={open => {
            bindOverrideContent({ persistent: open })
            if(open) {
                setBrickToolbarInfo({ show: false })
            }
        }}>
            <DropdownMenu.Trigger asChild>
                <button className="editor-button break-keep gap-1 !shadow-none">
                    {blockTypesMap[highestNode.type]} <MdKeyboardArrowDown/>
                </button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
                <DropdownMenu.Content
                    className="lc-context-menu lc-base"
                    sideOffset={5}
                >
                    <DropdownMenu.Label className='font-medium text-xs pl-[11px] mb-2' style={{ color: "var(--gray9)"}}>
                        Turn into
                    </DropdownMenu.Label>
                    {blockTypes.map(({ label, onSelect, icon, isBlockTypeActive }, index) => {
                        return (
                            <DropdownMenu.Item key={index} className="context-menu-item" onSelect={() => onSelect()}>
                                <div className="flex mr-auto">
                                    <div className="icon" style={ typeof(icon) === 'string' ? { fontSize: 12 } : {}}>
                                        {icon || null}
                                    </div>
                                    <div className="label">{label}</div>
                                </div>
                                {
                                    isBlockTypeActive ? (
                                        <div ><CheckIcon/></div>
                                    ) : null
                                }
                            </DropdownMenu.Item>
                        )
                    })}
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
            
        </DropdownMenu.Root>
    )

    function changeMobileToolbarStyle () {
        if(mobileToolbarRef && mobileToolbarRef.current) {
            if(mobileToolbarRef.current.style['zIndex']) {
                mobileToolbarRef.current.style['zIndex'] = ''
                mobileToolbarRef.current.style['overflowX'] = 'scroll'
            } else {
                mobileToolbarRef.current.style['zIndex'] = 1
                // iOS 端，在 overflow scroll 容器内的 fixed 元素无法充满窗口
                mobileToolbarRef.current.style['overflowX'] = 'hidden' 
            }
        }
    }

    function showBlocksList () {
        ReactEditor.blur(editor)
        setBlocksListOpen(true)
        changeMobileToolbarStyle()
    }

    function renderBlocksList () {
        return basicBlockTypes.map((blockObj, index) => {
            const { subIcon, subLabel, type } = blockObj
            return (
                <div key={index} className='upward-menu-item' 
                    onClick={() => {
                        setBlocksListOpen(false)
                        toggleBlockType(editor, type)
                        changeMobileToolbarStyle()
                    }}
                >
                    <div className="icon" style={ typeof(subIcon) === 'string' ? { fontSize: 12 } : {}}>
                        {subIcon || null}
                    </div>
                    <div>{subLabel}</div>
                </div>
            )
        })
    }

    const mobileButton = (
        <>
             <button className="editor-button" onClick={() => showBlocksList()}>
                <MdLoop style={{ fontSize: 14 }}/>
            </button>
            {
                blocksListOpen ? (
                    <div className={`editor-mobile-upward-menu ${blocksListOpen ? 'show' : ''}`} 
                        style={isIOS() ? { marginTop: 48 } : {}}
                    >
                        
                        <div className='header'>
                            <div className='font-bold my-0 mx-auto'>Turn into</div>
                            <div className='absolute right-3.5' style={{ color: "var(--blue9)" }} 
                                onClick={() => {
                                    setBlocksListOpen(false)
                                    changeMobileToolbarStyle()
                                }
                            }>
                                Cancel
                            </div>
                        </div>
                        <div className='items-container'>
                            {renderBlocksList()}
                        </div>
                    </div>
                ) : null
            }
        </>
       
    )

    return !isMobile() ? pcButton : mobileButton
}
