// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Belt_MapInt = require("bs-platform/lib/js/belt_MapInt.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Belt_MapString = require("bs-platform/lib/js/belt_MapString.js");
var Plate$BwaxMobile = require("./plate.bs.js");

function empty(param) {
  return Belt_MapString.empty;
}

function singleton(k, v) {
  return Belt_MapString.set(Belt_MapString.empty, k, v);
}

function insert(k, v, dict) {
  return Belt_MapString.set(dict, k, v);
}

function update(k, f, dict) {
  return Belt_MapString.update(dict, k, f);
}

function remove(k, dict) {
  return Belt_MapString.remove(dict, k);
}

function remove_many(ks, dict) {
  return Belt_MapString.removeMany(dict, Curry._1(Plate$BwaxMobile.List.to_array, ks));
}

var is_empty = Belt_MapString.isEmpty;

function member(k, dict) {
  return Belt_MapString.has(dict, k);
}

function get(k, dict) {
  return Belt_MapString.get(dict, k);
}

var size = Belt_MapString.size;

function some(f, dict) {
  return Belt_MapString.some(dict, f);
}

function every(f, dict) {
  return Belt_MapString.every(dict, f);
}

function find(f, dict) {
  return Belt_MapString.findFirstBy(dict, f);
}

var minimum = Belt_MapString.minimum;

var maximum = Belt_MapString.maximum;

function is_equal(eq, dict1, dict2) {
  return Belt_MapString.eq(dict1, dict2, eq);
}

function keys(dict) {
  return Curry._1(Plate$BwaxMobile.List.from_array, Belt_MapString.keysToArray(dict));
}

function values(dict) {
  return Curry._1(Plate$BwaxMobile.List.from_array, Belt_MapString.valuesToArray(dict));
}

var to_list = Belt_MapString.toList;

function from_list(l) {
  return Belt_MapString.fromArray(Curry._1(Plate$BwaxMobile.List.to_array, l));
}

var to_array = Belt_MapString.toArray;

var from_array = Belt_MapString.fromArray;

function map(f, dict) {
  return Belt_MapString.mapWithKey(dict, f);
}

function foldl(f, acc, dict) {
  return Belt_MapString.reduce(dict, acc, f);
}

function keep_map(f, dict) {
  return Belt_MapString.reduce(dict, Belt_MapString.empty, (function (acc, k, a) {
                var match = Curry._2(f, k, a);
                if (match !== undefined) {
                  return Belt_MapString.set(acc, k, Caml_option.valFromOption(match));
                } else {
                  return acc;
                }
              }));
}

function filter(f, dict) {
  return Belt_MapString.keep(dict, f);
}

function partition(f, dict) {
  return Belt_MapString.partition(dict, f);
}

function union(first, second) {
  return Belt_MapString.reduce(first, second, Belt_MapString.set);
}

function merge(f, first, second) {
  return Belt_MapString.merge(first, second, f);
}

var $$String = {
  empty: empty,
  singleton: singleton,
  insert: insert,
  update: update,
  remove: remove,
  remove_many: remove_many,
  is_empty: is_empty,
  member: member,
  get: get,
  size: size,
  some: some,
  every: every,
  find: find,
  minimum: minimum,
  maximum: maximum,
  is_equal: is_equal,
  keys: keys,
  values: values,
  to_list: to_list,
  from_list: from_list,
  to_array: to_array,
  from_array: from_array,
  map: map,
  foldl: foldl,
  keep_map: keep_map,
  filter: filter,
  partition: partition,
  union: union,
  merge: merge
};

function empty$1(param) {
  return Belt_MapInt.empty;
}

function singleton$1(k, v) {
  return Belt_MapInt.set(Belt_MapInt.empty, k, v);
}

function insert$1(k, v, dict) {
  return Belt_MapInt.set(dict, k, v);
}

function update$1(k, f, dict) {
  return Belt_MapInt.update(dict, k, f);
}

function remove$1(k, dict) {
  return Belt_MapInt.remove(dict, k);
}

function remove_many$1(ks, dict) {
  return Belt_MapInt.removeMany(dict, Curry._1(Plate$BwaxMobile.List.to_array, ks));
}

var is_empty$1 = Belt_MapInt.isEmpty;

function member$1(k, dict) {
  return Belt_MapInt.has(dict, k);
}

function get$1(k, dict) {
  return Belt_MapInt.get(dict, k);
}

var size$1 = Belt_MapInt.size;

function some$1(f, dict) {
  return Belt_MapInt.some(dict, f);
}

function every$1(f, dict) {
  return Belt_MapInt.every(dict, f);
}

function find$1(f, dict) {
  return Belt_MapInt.findFirstBy(dict, f);
}

var minimum$1 = Belt_MapInt.minimum;

var maximum$1 = Belt_MapInt.maximum;

function is_equal$1(eq, dict1, dict2) {
  return Belt_MapInt.eq(dict1, dict2, eq);
}

function keys$1(dict) {
  return Curry._1(Plate$BwaxMobile.List.from_array, Belt_MapInt.keysToArray(dict));
}

function values$1(dict) {
  return Curry._1(Plate$BwaxMobile.List.from_array, Belt_MapInt.valuesToArray(dict));
}

var to_list$1 = Belt_MapInt.toList;

function from_list$1(l) {
  return Belt_MapInt.fromArray(Curry._1(Plate$BwaxMobile.List.to_array, l));
}

function map$1(f, dict) {
  return Belt_MapInt.mapWithKey(dict, f);
}

function foldl$1(f, acc, dict) {
  return Belt_MapInt.reduce(dict, acc, f);
}

function keep_map$1(f, dict) {
  return Belt_MapInt.reduce(dict, Belt_MapInt.empty, (function (acc, k, a) {
                var match = Curry._2(f, k, a);
                if (match !== undefined) {
                  return Belt_MapInt.set(acc, k, Caml_option.valFromOption(match));
                } else {
                  return acc;
                }
              }));
}

function filter$1(f, dict) {
  return Belt_MapInt.keep(dict, f);
}

function partition$1(f, dict) {
  return Belt_MapInt.partition(dict, f);
}

function union$1(first, second) {
  return Belt_MapInt.merge(first, second, (function (param, ma, mb) {
                if (ma !== undefined) {
                  return Caml_option.some(Caml_option.valFromOption(ma));
                } else if (mb !== undefined) {
                  return Caml_option.some(Caml_option.valFromOption(mb));
                } else {
                  return ;
                }
              }));
}

function merge$1(f, first, second) {
  return Belt_MapInt.merge(first, second, f);
}

var Int = {
  empty: empty$1,
  singleton: singleton$1,
  insert: insert$1,
  update: update$1,
  remove: remove$1,
  remove_many: remove_many$1,
  is_empty: is_empty$1,
  member: member$1,
  get: get$1,
  size: size$1,
  some: some$1,
  every: every$1,
  find: find$1,
  minimum: minimum$1,
  maximum: maximum$1,
  is_equal: is_equal$1,
  keys: keys$1,
  values: values$1,
  to_list: to_list$1,
  from_list: from_list$1,
  map: map$1,
  foldl: foldl$1,
  keep_map: keep_map$1,
  filter: filter$1,
  partition: partition$1,
  union: union$1,
  merge: merge$1
};

exports.$$String = $$String;
exports.Int = Int;
/* Plate-BwaxMobile Not a pure module */
