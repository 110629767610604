import React, { useState, useRef, useEffect } from 'react'

import '../WxWorkMessage.less';

export default function Audio(props) {

    const audioRef = useRef()

    const [status, setStatus] = useState('pause')
    const [init, setInit] = useState(false)

    useEffect(() => {
        initAudio()
    })

    function reset() {
        if (audioRef && audioRef.current) {
            setStatus('stop')
            audioRef.current.currentTime = 0
            audioRef.current.pause()
        }
    }

    function initAudio () {
        if (audioRef && audioRef.current && !init) {
            audioRef.current.onended = reset
            setInit(true)
        }
    }

    function play() {
        if (audioRef && audioRef.current) {
            setStatus('play')
            audioRef.current.play()
        }
    }

    function getAudioStyle() {
        const { duration } = props
        if (duration) {
            return {
                width: (duration / 60) * 100 + "%"
            }
        }
    }

    const { src, hiddenControl, duration } = props

    return (
        <div className="message-audio" onClick={() => {
            if (audioRef && audioRef.current) {
                if (audioRef.current.paused) {
                    play()
                } else {
                    reset()
                }
            }
        }}>
            {
                hiddenControl ?
                    <div className="trumpet-audio">
                        <div className="trumpet-cot" style={getAudioStyle()}>
                            <div className={`${status === 'play' ? 'audio-play' : 'trumpet'}`} />
                            <span className="triangle"></span>
                            <span className="triangle-white"></span>
                        </div>
                        {
                            duration ?
                                <span className="audio-duration">
                                    {Math.floor(duration)}"
                                </span> : null
                        }
                    </div> : null
            }
            <audio
                src={src}
                preload="auto"
                controls
                hidden={hiddenControl}
                ref={audioRef} 
            />
        </div>
    )
}
