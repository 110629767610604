

import React, { useEffect, useState, useId, useRef } from 'react'

import "./TaskJobList.less";

import TaskJobSummary from './TaskJobSummary';
import  { fieldPaths } from './TaskJobDetail';

import taskSettings from './taskSettings';

import ScrollHelper from 'bwax-ui/ScrollHelper';

// 依赖 OpenAI-任务 entity，以及其他一些相关的任务 entity。

export default function TaskJobList({ facade, currentUserId, bindAddJob, viewEnv }) {
    // 
    const [data, setData] = useState();

    const [hasMore, setHasMore] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);

    const entityName = "OpenAI-任务"

    const loadData = async (offset = 0) => {

        setLoadingMore(true);
        const [result, error] = await facade.listAll({
            entityName,
            condition: [[
                { field: "创建者.id", op: "eq", value: currentUserId },
                { field: "已归档", op: "ne", value: true },
                { field: "类别", op: "notIn", value: ["OpenAI-生成图片任务", "OpenAI-文本生成图片任务"]}
            ]],
            sort: [{ field: "创建时间", order: "DESC" }],
            fieldPaths,

            pageSize: 20,
            offset
        });
        setLoadingMore(false);

        console.log(">>>", result);

        if (error) {
            console.error(error);
            return 
        }
        if(result) {
            const hasMore = result.pageSize + offset < result.count;
            setHasMore(hasMore);

            setData(prev => {
                // 要排除掉已经存在的，然后在 append 到后面
                const existing = prev || [];
                const toAppend = result.data.filter(s => !existing.some(e => e.id === s.id));
                return [ ...existing, ...toAppend ];
            })
        }
        
    };

    useEffect(() => {
        loadData();
    }, []);


    const loadMore = () => {
        if(hasMore) {
            loadData(data.filter(s => !s.已归档).length);
        }
    }


    const listRef = useRef()
    const loadMoreRef = useRef();
    loadMoreRef.current = loadMore;


    useEffect(() => {
        if(listRef.current) {
            const scrollHelper = new ScrollHelper(listRef.current, {
                onScrolledToBottom: _ => {
                    loadMoreRef.current && loadMoreRef.current();
                }
            });
            return () => {
                scrollHelper.cleanUp();
            }
        }
    }, [ listRef.current ]);

    const id = useId();

    if(bindAddJob) {
        bindAddJob(id, taskJob => {
            setData(prev => {
                return [ taskJob, ...(prev || [])]
            })
        });
    }

    function renderEmptyState() {
        return (
            <div className="empty-state">
                <i className='bx bxs-paper-plane'></i>
                <div className="empty-state-tip">还没有任务记录</div>
            </div>
        )
    }

    function renderJob(taskJob) {

        const setting = taskSettings.find(t => t.entityName == taskJob.类别);

        if(!setting) {
            return null
        }

        return <TaskJobSummary taskJob={taskJob}  key={taskJob.id} facade={facade} viewEnv={viewEnv} onJobRefreshed={job => {
            setData(prev => {
                return (prev || []).map(p => p.id == job.id ? job : p)
            })
        }} />
    }

    function renderJobList() {
        // data must have value now
        return (
            <>
                <div className="job-list-head font-size-14">
                    我添加的任务
                </div>
                <div className="job-list" ref={listRef}>
                    {
                        data && data.filter(d => !d.已归档).length == 0 ? renderEmptyState() : null
                    }
                    {
                        data && data.length > 0 ? data.filter(d => !d.已归档).map(renderJob) : null
                    }
                    { loadingMore ? (
                        Array(!data ? 10 : 3).fill(0).map((_, index) => {
                            return (
                                <div key={"l" + index} className="rounded h-[60px] min-h-[60px] bg-[var(--gray1)]">
                                    
                                </div>
                            )
                        })
                    ) : null }
                    { !hasMore ? (
                        <div className="flex justify-center pt-0 pb-2 font-size-12 text-[var(--gray9)]">
                            ——————
                        </div>
                    ) : null }
                </div>
            </>
        )
    }

    return (
        <div className="lc-task-job-list">
            { renderJobList() }
        </div>
    )
}

// 自动加载
