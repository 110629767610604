
import React, { useEffect, useRef, useState, useId } from 'react'

import page_runtime_helper from 'bwax-ui/ml/widget/page_runtime_helper.bs';
import lang_entry_slim from 'bwax/ml/lang/lang_entry_slim.bs'

export default function PageRuntime(props) {

    const {
        pageID, init_result, 
        
        queryRunner, queryCache, domainEnv, webEnv, 
        entity_dict, data_type_dict, dts, ast,
        route_to, 
        renderView, 
        initParams, 
        preparedData,
        maybeViewParams,

        onPageChanged = _ => {},

    } = props;

    const id = useId();
    // console.log(">>> ID", id, pageID);

    // useEffect(() => {
    //     console.log(">>>> I'm mounted", id, pageID);
    //     return () => {
    //         console.log(">>> unmounting? ", id, pageID);
    //     }
    // }, [ id ]);


    // runtimeState is changed to [ pageId, state ]
    const [ runtimeState, setRuntimeState ] = useState([pageID, init_result]);
    const toRunReady = useRef(true); // always true at the begining

    // when params change if this is true, runInit
    const shouldReInitForNewParams = useRef(false);

    const shouldRePrepare = useRef(false);

    function updateRuntimeState(update) {
        // console.log(">> UPDATE runtime state", id, pageID);
        setRuntimeState(prev => {
            if(typeof(update) === 'function') {
                return [prev[0], update(prev[1])]
            } else {
                return [prev[0], update]
            }
        })
    }

    const timersRef = useRef(page_runtime_helper.init_timers());    
    const timers = timersRef.current;

    const modelRef = useRef();
    modelRef.current = page_runtime_helper.get_model(runtimeState[1]);

    const make_context = page_runtime_helper.context_maker(
        queryCache, queryRunner, entity_dict, data_type_dict, dts, route_to,
        domainEnv, webEnv
    );

    const env = page_runtime_helper.get_env(runtimeState[1]);
    const context = make_context(env, timers);

    const [onCmd, onMsg] = page_runtime_helper.make_cmd_msg_handler(context, modelRef, updateRuntimeState);

    // const [ isPending, startTransition ] = useTransition()

    function startUpdateRuntimeState(fn) {
        // startTransition(() => {
            updateRuntimeState(fn);
        // })
    }

    useEffect(() => {
        // when page ID for current model changed:
        onPageChanged();

    }, [ runtimeState[0] ]);

    useEffect(() => {
        if(pageID !== runtimeState[0]) {
            // 重新 设置 init result
            // console.log(">>> PAGE ID CHANGED", pageID, runtimeState);            

            setRuntimeState([pageID, init_result])
            toRunReady.current = true;

            shouldReInitForNewParams.current = false; 
            // TODO，这个其实不知道跟 initParams 的 useEffect 谁先
            // 但这个也不影响实际效果
            shouldRePrepare.current = false

        } else {
            // console.log("Page ID not changed,  nothing to do")
        }

    }, [pageID])

    useEffect(() => {
        if(toRunReady.current) {
            // console.log("RUN READY please")
            const context = make_context(env, timers);
            if(page_runtime_helper.has_implemented_ready(context)) {
                // https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1004
                setTimeout(() => {
                    // console.log("Ready?", pageID, isPending)
                    const model = modelRef.current;
                    page_runtime_helper.run_ready(context, model, onCmd, startUpdateRuntimeState);  
                }, 5)                      
            }
            toRunReady.current = false;

            return () => {
                page_runtime_helper.clear_timers(timers);
                const model = modelRef.current;
                if(page_runtime_helper.has_dispose(context)) {
                    page_runtime_helper.run_dispose(context, model, onCmd);
                }            
            }
        }

    }, [ toRunReady.current ])

    // init params 限定在可序列化、可比较的基本类型，因此可以用 string_of_value 来判断它的变化：
    // https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1102
    const initParamsString = lang_entry_slim.string_of_value(initParams);

    useEffect(() => {
        // console.log("?>> reInit", pageID, shouldReInitForNewParams.current, initParamsString);
        if(shouldReInitForNewParams.current) {
            // console.log("?>> reInit")
            const context = make_context(env, undefined);
            const model = modelRef.current;
            page_runtime_helper.run_reInit(context, model, initParams, startUpdateRuntimeState);                           

        } else {
            // console.log("First round", paramStr);
            shouldReInitForNewParams.current = true;

            // 如果 initParams 里面有 decorated value，则重新 initialize;
            // Decorated value 一般是用于 onMsg，闭包了上一层的 React instance 信息
            // 而基于未知的 React (promise mode）机制，在 runInit 得到的 React instance 版本，不是最终 mounted 的那个版本
            // 这导致了 page component 触发的 msg 不能成功返回给上一层

            // 2022.08.10 （正在从 SmartRenderer 重构过来）所以现在有什么问题吗？

            // 2022.10.06 Van: 因为 https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1029
            // 所以不需要再 runInit 了

            // if(page_runtime_helper.has_decorated_value(initParams)) {
            //     console.log("Run Init for decorated value", id);                    
            //     runInit()
            // }

        }
    }, [ pageID + "::" + initParamsString])

    return (
        <MemoView {...{
            context, model: modelRef.current, maybeViewParams, 
            onMsg, renderView, id,
            pageID, pageIdForCurrentModel: runtimeState[0],
            entity_dict, data_type_dict, dts, ast,
            domainEnv, webEnv,
        }} />
    )

}


// 
const MemoView = React.memo(function({
    context, model, maybeViewParams, 
    onMsg, renderView, id,
    pageID, pageIdForCurrentModel,
    entity_dict, data_type_dict, dts, ast,
    domainEnv, webEnv,
}){

    // console.log(" do render?", pageID, pageIdForCurrentModel);

    // https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1113
    // view 的第一个参数支持 webEnv, domainEnv 和本来的 model

    const deps = lang_entry_slim.determine_view_dependencies_as_js(entity_dict, data_type_dict, dts, ast)

    // prepare view's first parameter:
    function prepareFirstParam () {
        const params = deps.map(d => {
            const { dataTypeName } = d;
            // Only support UI.WebEnv and Cmd.DomainEnv, Model
            if (dataTypeName == "Cmd.DomainEnv" || dataTypeName == "DomainEnv") {
                return lang_entry_slim.pack_domain_env(domainEnv);

            } else if ((dataTypeName == "UI.WebEnv" || dataTypeName == "UI.WebEnv") && webEnv) {
                return lang_entry_slim.pack_web_env(webEnv);

            } else if (dataTypeName == "Model") {
                return model
            } else {
                // 不支持的 view 参数                
            }
        }).filter(x => !!x);
        
        if (params.length == deps.length) {
            // 准备完备
            return params.length > 1 ? lang_entry_slim.pack_tuple_from_array(params) : params[0];
        } else {
            // throw error?
        }
    }

    const value = page_runtime_helper.run_view(context, prepareFirstParam(), maybeViewParams);

    return renderView(
        value, 
        msg => {
            return onMsg(msg)
        }, 
        () => id + ":" + pageID, 
        model
    );


}, (_prevProps, nextProps) => {
    const { pageID, pageIdForCurrentModel } = nextProps;

    if(pageID !== pageIdForCurrentModel) {
        // 是页面在切换，返回之前的 rendered content 就好了
        // console.log("--- switching ---", pageID, pageIdForCurrentModel);
        return true
    } 
    
    return false
})

