
import React, { useEffect, useContext } from "react"

import RenderWithData from 'bwax-ui/store/RenderWithData'

import { make as ViewRenderer } from 'bwax-ui/ml/widget/ViewRenderer.bs'


import HtmlRenderer from 'bwax-ui/ml/widget/HtmlRenderer'
import html_renderer from 'bwax-ui/ml/widget/html_renderer.bs'

import { setupDataQueryRunner } from 'bwax/query/runClientQuery'

import { Helmet } from 'react-helmet-async'
import { 
    setupQueryCache, getInstance, buildCacheKey, 
    USER_DATA, USER_RUNTIME, USER_TRANSIENT_DATA
} from "bwax/store/DataCache";

import { resolveDependenciesAndPrepareData } from 'bwax/BwaxExecHelper';


import PageRuntime from 'bwax-ui/ml/widget/PageRuntime'
import page_runtime_helper from 'bwax-ui/ml/widget/page_runtime_helper.bs'

import page_helper from 'bwax-ui/ml/widget/page_helper.bs'

import { hashCode } from 'bwax/utils'
import { gotoLoginUrlOrWechatLogin, gotoWechatLoginIfPossible } from "bwax-ui/ml/FrontEndHelper";

import { tag, untag } from "bwax/lang/LangHelper";

import queryUser from "bwax/query/queyUser"

export default function PageContainer(props) {

    const {
        dlc, pageID,
        entity_dict, data_type_dict, base_env, dts, ast,
        params,
        viewEnv,
    } = props;

    const dataCache = getInstance(dlc);
    const queryRunner = setupDataQueryRunner(dlc);
    const queryCache = setupQueryCache(
        dataCache, dlc
    );

    const { routeTo, domainEnv, webEnv } = viewEnv;

    const loadData = async () => {

        try {           
            // 在这里先 prepare data 
            // 要判断它需不需要 params List (String, String)
            const [ preparedData, error ] = await (async () => {

                if(page_helper.need_prepared_data(ast)) {       
                    
                    async function prepareData () {
                        const getUser = async userDeps => {
                            const [_tree, paths, selectionText, _deps] = userDeps;

                            if (!paths || paths.length == 0) {
                                return {}
                            }
                            
                            const dataKey = buildCacheKey(USER_DATA, "GetCurrentUser_" + hashCode(selectionText), dlc);
                            let user = dataCache.get(dataKey);
                            if(!user) {
                                user = await queryUser(dlc, selectionText);
                                if(user) {
                                    dataCache.set(dataKey, user);
                                } 
                            }
    
                            return user;
                        }
                        const getRecord = () => { invariant(false, "Invalid get record here") };
    
                        const [predata, error] = await resolveDependenciesAndPrepareData(ast, {
                            getRecord, getUser,
                            entity_dict, data_type_dict, dts, domainEnv, webEnv
                        })
    
                        return [ predata, error ]
                    }

                    // 为了短暂地 cache NOW 和 RANDOM
                    // https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/793
                    
                    const preparedDataKey = buildCacheKey(USER_TRANSIENT_DATA, "PreparedPageData_" + pageID, dlc);
                    const cachedPreparedData = dataCache.get(preparedDataKey);
                    if(cachedPreparedData) {
                        const [ preparedData, error ] = cachedPreparedData;
                        return [ untag(preparedData), error ];
                    } else {
                        const [ preparedData, error ] = await prepareData();
                        if(preparedData) {
                            dataCache.set(preparedDataKey, [ tag(preparedData), error ]);
                        }
                        return [ preparedData, error ]
                    }

                } else {
                    return [ "__no_need__", undefined ]
                }
            })();
            
            if(!preparedData) {
                return [ undefined, error ];
            }

            const actualPreparedData =  preparedData ===  "__no_need__" ? undefined : preparedData;

            const [ result, initError ] =  await page_runtime_helper.runInit(
                queryRunner, queryCache,
                domainEnv, webEnv,
                entity_dict, data_type_dict,
                base_env, dts, ast,
                routeTo,
                page_helper.pack_page_params(params),
                actualPreparedData
            )
            return [ [ result, actualPreparedData ], error || initError ];

        } catch (error) {

            return [ undefined, error ]
        }
    }

    return (
        <RenderWithData {...{
            ...props,
            loadData,
            cacheType: USER_RUNTIME,

            // 要加上 data key 要加上 params https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/970
            dataKey: "PageInitModel_" + pageID + "_" + hashCode(JSON.stringify(params)),

            // 如果是同一个页面，参数变化，应该执行 reInit，不需要重新 init
            instanceKey: "PageInitModel_" + pageID,


            Component: Inner,
            queryCache, queryRunner,

            // fallback: <PlainColor name="R60" />,
            noSuspense: true
        }} />
    )
}


function Inner({
    data: v, params, facade, viewEnv, isPreview,
    pageID, dlc, entity_dict, data_type_dict, dts, ast,
    queryCache, queryRunner,
    styles,
    alternativeContext,
}) {

    const [resultData, error] = v;

    const missingRequiredUser = error => error == "ERROR: 未找到必需的当前用户";
    const missingUser = error => error == "INFO: 未找到当前用户";
    
    const { routeTo, domainEnv, webEnv } = viewEnv;

    useEffect(() => {
        if(missingRequiredUser(error)) {
            // preserve 一个 login 页面吧
            // 默认是要让 Design 前端来实现，如果没有实现，则 fallback 到一个内置页面（todo）
            gotoLoginUrlOrWechatLogin("/logon", routeTo, webEnv.currentURL, domainEnv.isSandbox, domainEnv.tenantCode);            

        } else if(missingUser(error)) {
            gotoWechatLoginIfPossible(domainEnv.isSandbox, domainEnv.tenantCode);

        }
    }, [ error ]);



    // 如果是 missing user 则继续显示：
    if (error && !missingUser(error)) {
        if(missingRequiredUser(error)){
            // 暂时啥都不显示
            return null
        }

        function printError() {
            if (error.stack) {
                return error.stack;
            } else if (Array.isArray(error)) {
                // bucklescript error
                const [_, msg] = error;
                return msg
            } else {
                return error;
            }
        }

        return (
            <>
                <Helmet>
                    <meta name="viewport" content="width=device-width,initial-scale=1" />
                </Helmet>
                <div style={{ padding: 16, width: "100%" }}>
                    <h3>页面初始化时出错了</h3>
                    <div style={{ padding: "16px 0", width: "100%" }}>
                        <pre style={{ width: "100%", overflow: "auto" }}>
                            {printError()}
                        </pre>
                    </div>
                </div>
            </>
        );
    }

    const [ r, preparedData ] = resultData;

    function onPageChanged () {
        if (typeof (window) !== "undefined" && window.scrollTo && !isPreview) {
            window.scrollTo(0, 0);
        }
    };

    const paramsStr = JSON.stringify(params);

    const rendered = (
        <PageRuntime
            pageID={pageID}
            domainEnv={domainEnv}
            queryCache={queryCache}
            queryRunner={queryRunner}
            webEnv={webEnv}
            onPageChanged={onPageChanged}
            initParams={page_helper.pack_page_params(params)}
            dlc={dlc}
            entity_dict={entity_dict}
            data_type_dict={data_type_dict}
            dts={dts}
            route_to={routeTo}
            ast={ast}
            init_result={r}
            preparedData={preparedData}
            renderView={(value, onMsg, getInstanceID, _) => {

                // 这是用于 SSR 时返回不同的 content type
                const stringValue = html_renderer.as_string(value);
                if(stringValue) {
                    if(alternativeContext) {
                        alternativeContext.alternativeResult = {
                            contentType: "text/plain",
                            value: stringValue
                        }
                    } 
                    return stringValue
                }

                // 

                if(html_renderer.is_html(value)) {
                    return (
                        <HtmlRenderer {...{
                            value, onMsg, styles,
                        }} />
                    )

                } else {
                    // use the old-school element renderern
                    return (
                        <ViewRenderer {...{ 
                            value, onMsg, dlc, getInstanceID, facade, viewEnv, pageID, paramsStr,
                        }} />
                    )
                }


            }}
        />
    )
    return rendered;
}
