

import React, { useState, useRef, useEffect } from 'react'
import classNames from 'classnames';

import './SortableTree.less';

import useResizeObserver from '@react-hook/resize-observer'

import {
    DndContext, useDroppable, useDraggable, DragOverlay,
    useSensor, useSensors,
    MouseSensor,
    TouchSensor,
    KeyboardSensor,
} from '@dnd-kit/core';

import { CaretRightIcon } from '@radix-ui/react-icons'
import useStateWithLocalCache from 'bwax-ui/hooks/useStateWithLocalCache';

export default function SortableTree({ 
    items, renderItem, gap = 0, color = "gray", onChange = _ => { },

    activeId, // data-active=true
    onItemPressed = _ => {},

    rootClassName,

    getSubItems = item => item.subItems, 
    loadSubItems = _ => [],
    hasSubItems = _ => true,

    getTopLevelItems = items => items,

    activationConstraint = {
        delay: 200, tolerance: 64
    },

    cacheKey,

    refreshKey,

}) {

    const [draggingId, setDraggingId] = useState(null);

    function getItem(id) {
        return items.find(item => item.id == id)
    }

    // all child noes: { <id>: [ <id> ] }
    const buildChildrenDict = items => {
        return items.reduce((acc, current) => {
            const { id } = current;
            const subItems = getSubItems(current);
            if(subItems) {
                return {
                    ...acc,
                    [id]: subItems.map(i => i.id),
                    ...buildChildrenDict(subItems)
                }
            } else {
                return acc
            }
        }, {});
    }
    const [childrenDict, setChildrenDict] = useState(_ => buildChildrenDict(items));
    const childrenDictRef = useRef();
    childrenDictRef.current = childrenDict;

    function getParentId(id) {
        return Object.keys(childrenDict).find(key => childrenDict[key].indexOf(id) !== -1);
    }
    // is subject an ancestor of object
    function isAncestor(objectId, subjectId) {
        const parentId = getParentId(objectId);
        if (parentId) {
            return parentId == subjectId || isAncestor(parentId, subjectId)
        } else {
            return false
        }
    }

    const [topLevelItemIds, setTopLevelItemIds] = useState(_ => getTopLevelItems(items).map(item => item.id));

    const [collapsed, setCollapsed] = useStateWithLocalCache(cacheKey, {});

    const hasNoSubItemYet = k => {
        const subItemIds = childrenDict[k];
        const noSubItemYet = subItemIds === undefined || (subItemIds.length == 0);
        return noSubItemYet
    }

    useEffect(() => {
        // 这个可以考虑移到外面去
        const idsToLoadSubItems = Object.keys(collapsed).filter(k => collapsed[k] === false).filter(k => {
            return hasNoSubItemYet(k);
        });

        if(idsToLoadSubItems.length > 0) {
            loadSubItems(idsToLoadSubItems);
        }

    }, [ collapsed ]);


    useEffect(() => {
        // make sure all ancestors "not-collapsed"
        if(activeId) {
            function iter(acc, currentId) {
                const parentId = getParentId(currentId);
                if(parentId) {
                    return iter({ ...acc, [parentId]: false}, parentId)
                } else {
                    return acc
                }
            }
            const collapsedState = iter({}, activeId);
            setCollapsed(prev => ({ ...prev, ...collapsedState }));
        }

    }, [ activeId ]);


    const ref = useRef();

    // <id> : <dom element>
    const itemElementsRef = useRef({});

    function loadElements() {
        if (!ref.current) {
            return;
        }
        const nodeList = Array.from(ref.current.querySelectorAll(".lc-sortable-tree-node"));
        const itemElements = nodeList.reduce((acc, node) => {
            const id = node.getAttribute("data-node-id");
            if (id) {
                return { ...acc, [id]: node }
            } else {
                return acc
            }
        }, {});
        itemElementsRef.current = itemElements;
    }

    const shouldRebuildRef = useRef(false);

    useEffect(() => {
        if(shouldRebuildRef.current) {
            setChildrenDict(buildChildrenDict(items));
            setTopLevelItemIds(getTopLevelItems(items).map(item => item.id));

        } else {
            // next time it should rebuild:
            shouldRebuildRef.current = true
        }

        setTimeout(() => {
            loadElements()
        }, 16);
    }, [refreshKey || JSON.stringify(items.map(item => ({ id: item.id })))]);


    useResizeObserver(ref, _ => {
        loadElements()
    })

    //  { parent: <id>, index: undefined / 0.. }
    const [hoveredTarget, setHoveredTarget] = useState();

    const hoveredTargetRef = useRef();
    hoveredTargetRef.current = hoveredTarget;

    const startingYRef = useRef(0);
    function handleDragStart(event) {
        setDraggingId(event.active.id);

        startingYRef.current = ref.current.getBoundingClientRect().y;
    }

    function resolveHoveredTarget(x, y, currentId) {

        const pointerRect = {
            left: x - 3,
            top: y - 3,
            right: x + 4,
            bottom: y + 4,
        }
        const offsetY = startingYRef.current - ref.current.getBoundingClientRect().y;

        const hovered = Object.keys(itemElementsRef.current).filter((key) => {
            const e = itemElementsRef.current[key];
            const rect = e.getBoundingClientRect();
            const area = {
                left: rect.left,
                right: rect.right,
                top: rect.top + offsetY,
                bottom: rect.bottom + offsetY,
            }
            return isRectInsideArea(pointerRect, area);
        })

        // cannot drop on itself or any child of it.
        const removeItself = hovered.filter(key => {
            return key !== currentId && !isAncestor(key, currentId)
        })

        // remove the parent whose child is also hovered:
        const removedOverlayedParent = removeItself.filter(key => {
            const childIds = childrenDict[key];
            if (childIds && childIds.some(k => removeItself.indexOf(k) !== -1)) {
                return false
            } else {
                return true
            }
        })

        // console.log("hovered", hovered, actualHovered);·
        const parent = removedOverlayedParent[0];

        // find the closest child
        const childIds = parent ? (childrenDict[parent] || []) : topLevelItemIds;

        const { closestChild: candidate, distance } = childIds.reduce((acc, childId) => {

            const e = itemElementsRef.current[childId];           

            if(!e) {
                // 应该是没展开
                return acc
            }

            const rect = e.getBoundingClientRect();
            const area = {
                left: rect.left,
                right: rect.right,
                top: rect.top + offsetY,
                bottom: rect.bottom + offsetY,
            }
            const distance = distanceToArea({ x, y }, area);

            if (acc.closestChild && acc.distance < distance) {
                return acc
            } else {
                return { closestChild: childId, distance }
            }
        }, { closestChild: undefined, distance: 99999 });

        const closestChild = distance < gap + 8 && candidate !== currentId ? candidate : undefined;

        const isBelow = closestChild ? (() => {
            const e = itemElementsRef.current[closestChild];
            const rect = e.getBoundingClientRect();
            const centerY = (rect.top + rect.bottom) / 2 + offsetY;
            return y > centerY
        })() : false

        return {
            parent,
            closestChild,
            isBelow,
            onSelf: candidate == currentId
        }

    }

    const onDragMove = event => {
        // console.log("")
        const { activatorEvent, delta, active } = event;
        

        function getClientXY() {
            if(activatorEvent.targetTouches) {
                const { clientX, clientY } = activatorEvent.targetTouches[0];
                return { clientX, clientY }
            } else {
                const { clientX, clientY } = activatorEvent;
                return { clientX, clientY }
            }
        }
        const { clientX, clientY } = getClientXY();

        const { x, y } = delta;
        // 
        const hoveredTarget = resolveHoveredTarget(clientX + x, clientY + y, active.id);

        if (JSON.stringify(hoveredTargetRef.current) !== JSON.stringify(hoveredTarget)) {
            // console.log(">>> hovered target", hoveredTarget);
            hoveredTargetRef.current = hoveredTarget;
            setHoveredTarget(hoveredTarget)
        }
    }

    const handleDragEnd = event => {
        // console.log("Drag end", event);
        // setDraggingId(event.active.id);

        const hoveredTarget = hoveredTargetRef.current;
        const draggingId = event.active.id;

        if (draggingId && hoveredTarget && !hoveredTarget.onSelf) {
            (() => {
                const currentParentId = getParentId(draggingId);

                let childrenDict = childrenDictRef.current;

                const { parent, closestChild, isBelow } = hoveredTarget;

                // 如果 currentParent == parent and if draggingId is already in the position
                if (currentParentId == parent) {
                    const childIds = parent ? childrenDict[parent] : topLevelItemIds;
                    const currentIndex = childIds.indexOf(draggingId);

                    const targetIndex = closestChild ? (() => {
                        const closestIndex = childIds.indexOf(closestChild);
                        return isBelow ? closestIndex + 1 : closestIndex
                    })() : 0;

                    if (currentIndex == targetIndex - 1) {
                        console.log("No need to change");
                        return
                    }
                }
                // 

               

                if (currentParentId) {
                    // remove it from parent's child
                    childrenDict = {
                        ...childrenDict, 
                        [currentParentId]: childrenDict[currentParentId].filter(x => x !== draggingId)
                    }

                } else {
                    // remove it from top level:
                    setTopLevelItemIds(prev => prev.filter(x => x !== draggingId))
                }

                const childIds = (parent ? childrenDict[parent] : topLevelItemIds) || [];
                const closestChildIndex = closestChild ? childIds.indexOf(closestChild) : -1;

                if (parent) {
                    childrenDict = {
                        ...childrenDict,
                        [parent]: closestChild ? insertBeforeOrAfter(closestChild, childIds, isBelow, draggingId) : [draggingId, ...childIds]
                    }

                    if(hasNoSubItemYet(parent)) {
                        setTimeout(() => {
                            loadSubItems([parent]);
                        }, 300)
                    }

                    setCollapsed(prev => ({ ...prev, [parent]: false }))

                } else {
                    setTopLevelItemIds(prev => {
                        return closestChild ? insertBeforeOrAfter(closestChild, prev, isBelow, draggingId) : [draggingId, ...prev]
                    })
                }
                if(currentParentId || parent) {
                    setChildrenDict(childrenDict);
                }


                setTimeout(() => {
                    loadElements()
                }, 16);

                // tell the outside world: TODO

                // { subjectId, originalParentId, newParentId, before, after }
                const change = {
                    subjectId: draggingId,
                    originalParentId: currentParentId,
                    newParentId: parent,

                    ...(
                        closestChildIndex == -1 ? {
                            before: childIds.length > 0 ? childIds[0] : undefined
                        } : {
                            before: isBelow ? childIds[closestChildIndex + 1] : closestChild,
                            after: isBelow ? closestChild : (closestChildIndex > 0 ? childIds[closestChildIndex - 1] : undefined)
                        }
                    )
                }

                onChange(change);

            })();
        }

        setDraggingId(null);
        setHoveredTarget();
    }


    const draggingItem = draggingId ? getItem(draggingId) : undefined;
    function getDepth(itemId) {
        function iter(currentId, acc) {
            const parentId = getParentId(currentId);
            if(!parentId) {
                return acc
            } else {
                return iter(parentId, acc + 1)
            }
        }
        return iter(itemId, 0)
    }


    const mouseSensor = useSensor(MouseSensor, {
        activationConstraint,
    });
    const touchSensor = useSensor(TouchSensor, {
        activationConstraint,
    });
    const keyboardSensor = useSensor(KeyboardSensor, {});
    const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

    const renderTreeNode = (item, depth) => {
        return (
            <SortableTreeNode
                item={item}
                subItems={childrenDict[item.id] ? childrenDict[item.id].map(id => getItem(id)).filter(x => !!x) : undefined}
                depth={depth}
                renderItem={renderItem}
                key={item.id}
                hoveredTarget={hoveredTarget}
                renderTreeNode={renderTreeNode}
                onItemPressed={onItemPressed}
                isActive={activeId == item.id}

                hasSubItems={hasSubItems(item)}

                collapsed={collapsed[item.id]}
                setCollapsed={collapsed => {
                    setCollapsed(prev => ({
                        ...prev,
                        [item.id]: collapsed
                    }));
                }}
            />
        )
    }

    return (
        <DndContext onDragEnd={handleDragEnd} onDragMove={onDragMove} onDragStart={handleDragStart} sensors={sensors}>
            <Droppable ref={ref}>
                {/* <div style={{ backgroundColor: "#eee", width: "100%", height: 400, padding: 16 }}></div> */}
                <div className={classNames("lc-sortable-tree-node-list", rootClassName)} style={{
                    "--lc-gap-half": (gap / 2) + "px",
                    "--lc-gap": gap + "px"
                }} data-color={color}>
                    {
                        topLevelItemIds.map(id => getItem(id)).filter(x => !!x).map(item => renderTreeNode(item, 0))
                    }
                </div>
            </Droppable>
            <DragOverlay className={"lc-sortable-tree-overlay"} dropAnimation={{
                duration: 64,
                easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
            }}
            >
                {draggingItem ? <div data-color={color}>{renderTreeNode(draggingItem, getDepth(draggingItem.id))}</div> : null}
            </DragOverlay>
        </DndContext>
    )
}



const Droppable = React.forwardRef((props, ref) => {
    const { isOver, setNodeRef } = useDroppable({
        id: 'lc-sortable-tree',
    });
    const style = isOver ? {
        // borderColor: "var(--green5)",
        // borderWidth: "1px",
        // borderStyle: "solid",
        // margin: -1                

    } : {}

    return (
        <div ref={r => {
            ref.current = r;
            setNodeRef(r)
        }} style={style} className="lc-sortable-tree">
            {props.children}
        </div>
    );
})


function SortableTreeNode(props) {
    const {
        item, subItems, isActive, onItemPressed,
        depth, renderItem, hoveredTarget, renderTreeNode,

        collapsed = true, setCollapsed,

        hasSubItems,

    } = props;
    const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
        id: item.id,
        // other
    });

    const style = isDragging ? {
        opacity: 0.4,
    } : {};

    // const [collapsed, setCollapsed] = useState(true);

    useEffect(() => {
        if(collapsed === false && !hasSubItems) {
            setCollapsed(true);
        }
    }, [hasSubItems]);

    const paddingLeft = depth * 12;

    const nodeRef = useRef();


    return (
        <div ref={ref => {
            setNodeRef(ref)
            nodeRef.current = ref;
        }} {...listeners} {...attributes} className={"lc-sortable-tree-node"}
            style={{
                ...style,
                // "--lc-indicator-width": "calc(100%-" + paddingLeft + "px)" 
                "--lc-padding-left": paddingLeft + "px"
            }}
            data-node-id={item.id}
            data-drag-over={hoveredTarget && hoveredTarget.parent == item.id && (hoveredTarget.closestChild === undefined)}
            data-top-indicator={hoveredTarget && hoveredTarget.closestChild == item.id && !hoveredTarget.isBelow}
            data-bottom-indicator={hoveredTarget && hoveredTarget.closestChild == item.id && hoveredTarget.isBelow}
            data-active={isActive}
        >
            <div className="node-head" onDrag={e => {
                // console.log(">> drag", e);
            }} onClick={e => {
                // console.log(">>> on item pressed", item.id);
                // To prevent the clicking on context menu from trigging onItemPressed
                if(nodeRef.current.contains(e.target)) {
                    onItemPressed(item)
                }

            }}>
                <div className="node-icon" data-collapsed={collapsed} data-enabled={hasSubItems || (subItems && subItems.length > 0)} onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    setCollapsed(!collapsed);
                    // if((subItems === undefined) || (hasSubItems && subItems.length == 0)) {
                    //     // triggle the loading of the sub items.
                    //     loadSubItems();
                    // } 
                }}>
                    { (hasSubItems ||  (subItems && subItems.length > 0)) ? <CaretRightIcon /> : null }
                </div>
                {renderItem(item)}
            </div>
            {subItems && subItems.length > 0 && !collapsed ?
                (
                    <div className="lc-sortable-tree-node-list">
                        {
                            subItems.map(it => (
                                renderTreeNode(it, depth + 1)
                            ))
                        }
                    </div>
                ) : null
            }
        </div>
    )
}


function insertBeforeOrAfter(objId, array, isAfter, subId) {

    const objIndex = array.indexOf(objId);
    const targetIndex = isAfter ? objIndex + 1 : objIndex;
    return [...array.slice(0, targetIndex), subId, ...array.slice(targetIndex)]


    // return array.reduce((acc, current) => {
    //     if(objId == current) {
    //         return [ ...acc, ...(isAfter ? [current, subId] : [subId, current]) ];
    //     } else {
    //         return [ ...acc, current, ]
    //     }
    // }, [])
}



export function isRectInsideArea(obj, area) {
    return (obj.top >= area.top && obj.bottom <= area.bottom) && (obj.left >= area.left && obj.right <= area.right)
}


export function isPointInsideArea({ x, y }, area) {
    return (y >= area.top && y <= area.bottom) && (x >= area.left && x <= area.right)
}


export function distanceToArea(point, area) {
    // distance from a point to and area

    const { x, y } = point;
    const { top, left, right, bottom } = area;
    // if it is inside the area, return 0;
    if ((top <= y && y <= bottom) && (left <= x && x <= right)) {
        return 0
    }

    // if the shortest path between them is perpendicular to one side of the area,
    // which means either y is bettwen top and bottom, or x is bettwen left and right, 
    // return the length of the shortest path
    if (top <= y && y <= bottom) {
        return x < left ? left - x : x - right
    } else if (left <= x && x <= right) {
        return y < top ? top - y : y - bottom
    }

    // otherwise, find the nearst corner, return the distance from the point to the corner
    function findNearestCorner() {
        if (y < top) {
            return x < left ? [left, top] : [right, top]
        } else {
            return x < left ? [left, bottom] : [right, bottom]
        }
    }
    const corner = findNearestCorner();
    return Math.sqrt((Math.pow(x - corner[0], 2) + Math.pow(y - corner[1], 2)))

}
