import React, { useContext } from 'react';

import RenderWithData from 'bwax-ui/store/RenderWithData';
import { USER_DATA } from 'bwax/store/DataCache';
import { hashCode } from 'bwax/utils';

import { loadRecordList } from './dataHelper';

export default function WithRecordList (props) {

    const { 
        query_config, get_depended_paths, wrapWithContainer, toElement, pageSize, offset, repeat, isLazy, loading,
        facade,
    } = props;

    const [ entityName, _ ] = query_config;

    const fieldPaths = get_depended_paths(facade.entity_dict, facade.data_type_dict, 0, entityName) || [];
    const dataKey = "WithRecordList-" + hashCode(JSON.stringify({ query_config, fieldPaths })); // query_config + render_fn deps

    const loadData = async () => {
        return await loadRecordList ({ query_config, entityName, pageSize, offset, fieldPaths, facade });
    };

    return (
        <RenderWithData {...{
            loadData,
            cacheType: USER_DATA,
            dataKey,
            Component: Inner,

            // https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1002 
            // 这里应该用 Suspense，但我目前没有理清什么时候应该用 Suspense 什么时候不应该用 （van, 2022-08-11）
            // noSuspense: true,  ///
            
            isLazy, fallback: loading, 

            repeat,
            
            // 
            toElement, wrapWithContainer, facade, entityName, 
        }}
        />
    )

}

function repeatList(l, n) {
    if(n <= 0) {
        return []
    } else if (n === 1) {
        return l
    } else {
        let r = [];
        for(let i = 0; i < n; i++) {
            r = r.concat(l)
        }
        return r
    }
}


function Inner (props) {

    const { data, toElement, wrapWithContainer, facade, entityName, repeat } = props;
    const [ result, error ] = data;

    if(result && result.data) {

        const data = repeat ? repeatList(result.data, repeat) : result.data;

        const els = data.map(r => toElement(facade.entity_dict, facade.data_type_dict, entityName, r));
        return wrapWithContainer(els);

    } else {
        return "Error"
    }


}



export function create(props) {
    return <WithRecordList {...props} />
}


