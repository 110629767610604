
import './baseStyle.less'

export { color } from './color/Palette'

export { themeColor } from './defaultTheme'


export const GLOBAL_FONT_SIZE = typeof(window) !== 'undefined' ? 
    parseFloat(window.getComputedStyle(document.documentElement).fontSize) : 16


export const getValueInPixel = (value) => {
    /// TODO support more type
    if(typeof(value) === 'number') {
        return value
    }

    const getters = [{
        pattern: /^(\d+)rem$/,
        getValue: v => v * GLOBAL_FONT_SIZE
    }, {
        pattern: /^(\d+)px$/,
        getValue: v => v
    }]

    const getter = getters.find(({ pattern }) => pattern.exec(value))
    if(getter) {
        const { pattern, getValue } = getter
        return getValue(pattern.exec(value)[1])
    }
    return 0
}
