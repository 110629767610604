
import React from 'react';

// const Inner = React.lazy(() => import ('../auxiliary/pdfReader/PDFReader'));

// 

export default function (props) {
    
    // https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1233
    // 改成用 iframe
    if(typeof(window) !== 'undefined') {
        
        const { src } = props;
        const { host, protocol } = window.location;

        const url = protocol + "//" + host + "/pdf.html?src=" + encodeURIComponent(src);
        return (
            <iframe src={url} style={{
                borderWidth: 0, width: "100%", height: "100%",
            }}
            
            />
        )
    } else {
        return null;
    }


    // return (
    //     <Suspense fallback={null}>
    //         <Inner {...props} />
    //     </Suspense>
    // )
}