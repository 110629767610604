
import React from 'react'

const  DataLoaderContext = React.createContext();

export default DataLoaderContext

export const DataLoaderProvider = props => {

    const { 
        appData, 
        domainKey,
        sessionToken, 
        sandbox, 
        isDesignMode = false,
        headers, 
        userenv, 
        deviceId,
        endpoints,
        tenantCode,
        tenantCodeMode,
        children 
    } = props

    return (
        <DataLoaderContext.Provider value={{
            appData, 
            domainKey,
            sessionToken, 
            deviceId,
            tenantCode,
            tenantCodeMode,
            endpoints,
            isDesignMode,
            sandbox, headers, userenv
        }}>
            { children }
        </DataLoaderContext.Provider>
    );
}
