
import invariant from "invariant";

/// build selection text 
export default function buildSelectionText(entity, paths, { allEntities, allDataTypes }) {

    // 1. build a tree first
    let tree = {};
    paths.forEach(path => {
        const ps = path.split(".");

        let parent = tree;
        ps.forEach(p => {
            // 如果不存在 p 则 insert
            let current = parent[p];
            if (!current) {
                parent[p] = {};
                current = parent[p];
            }
            parent = current;
        })
    })

    // 2 convert the tree to the actual selection
    function toSelection(tree, fields) {

        const selection = Object.keys(tree).reduce((acc, name) => {
            const prefix = acc.length > 0 ? acc + " " : acc;
            if (name == "id") {
                return prefix + "id"
            }

            const field = fields.find(f => f.name == name);

            if(!field) {
                console.log("未找到字段:", name, tree, fields, entity);
            }
            invariant(field, "未找到字段【" + name + "】");

            function getSelection() {

                const subTree = tree[name];

                // 如果是 Link 而且 subTree 里面没有 id ，则加入id
                if (field.type == "Link" && subTree["id"] == undefined) {
                    subTree["id"] = {}
                }

                if (Object.keys(subTree).length == 0) {

                    function expandDataType(field) {
                        // 2021-12-06 只要没有 sub select，则全部按展开
                        // if (true || field.type == "Image" || field.type == "File") {
                        const dataType = allDataTypes.find(dt => dt.key == field.type);

                        const allFields = [...(dataType.settings?.fields || []), ...(dataType.settings?.virtualFields || [])];

                        if (allFields.length > 0) {                            
                            const selection = allFields.map(f => {
                                const expanded = expandDataType(f);
                                return f.key + (expanded.length > 0 ? " " + expanded : "");
                            }).join(" ")                                                    
                            return "{ " + selection + " }"
                        } else if(field.type == "Link") {
                            return "{ id }"
                        } else {
                            return ""
                        }      
                    }
                    return expandDataType(field);

                } else {
                    function getFields() {
                        if (field.type == "Link") {
                            const nameOrKey = field.options.entity;
                            const linkedEntity = allEntities.find(e => e.name == nameOrKey || e.key == nameOrKey);
                            return [...linkedEntity.fields, ...linkedEntity.virtualFields];
                        } else {
                            const dataType = allDataTypes.find(dt => dt.key == field.type || dt.name == field.type);
                            return [...(dataType.settings?.fields || []), ...(dataType.settings?.virtualFields || [])];
                        }
                    }
                    return "{ " + toSelection(subTree, getFields()) + " }"
                }
            }
            return prefix + field.key + getSelection()
        }, "");

        return selection
    }

    const fields = [...entity.fields, ...entity.virtualFields];

    const selection = toSelection(tree, fields)

    return selection

}
