// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var RunClientQuery = require("bwax/query/runClientQuery");

var Endpoints = { };

function toEnv(dlc) {
  return {
          tenantCode: dlc.tenantCode,
          endpoints: dlc.endpoints,
          sessionToken: dlc.sessionToken,
          sandbox: dlc.sandbox,
          headers: dlc.headers,
          customQueryRunner: dlc.customQueryRunner
        };
}

function queryDefinition(env, query, variables, file, param) {
  return RunClientQuery.runDefinitionQuery_re(env, query, variables, file);
}

function queryData(env, query, variables, file, param) {
  return RunClientQuery.runDataQuery_re(env, query, variables, file);
}

exports.Endpoints = Endpoints;
exports.toEnv = toEnv;
exports.queryDefinition = queryDefinition;
exports.queryData = queryData;
/* bwax/query/runClientQuery Not a pure module */
