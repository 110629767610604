

import React, { useState } from 'react'

import SelectInput from 'bwax-ui/components/inputs/SelectInput';

import classNames from 'classnames';

import './KnowledgeOptionsInput.less'
import KnowledgeScopeDialog from './KnowledgeScopeDialog';
import { Pressable } from 'bwax-ui/components/Button';

import { useTrack } from 'bwax-ui/track';

export default function KnowledgeOptionsInput({ value: givenValue, onChange, facade, currentUserId }) {

    // { knowledgeOptions: { isEnabled, isLimited, scope: [ { entityName, recordId, knowledgeCategoryKey } ] } }
    // or { isEnabled, isLimited, scope: [ { entityName, recordId, knowledgeCategoryKey } ] }   -- 为了兼容之前的版本

    const track = useTrack();

    const { isEnabled = false, isLimited = false, scope = [] } = (() => {
        if(givenValue && givenValue.scope) {
            return givenValue
        } else if(givenValue && givenValue.knowledgeOptions) {
            return givenValue.knowledgeOptions
        } else {
            return { isEnabled: false, isLimited: false, scope: [] }; // default
        }
    })();

    const [dialogOpen, setDialogOpen] = useState(false);
    // const [dialogOpen, setDialogOpen] = useState(isLimited); // TODO only for testing


    function doChange (newValue) {
        if(givenValue && givenValue.scope) {
            onChange({
                knowledgeOptions: newValue
            })
        } else {
            onChange({
                ...(givenValue || {}),
                knowledgeOptions: newValue
            })
        }
    }

    function onSelect(v) {

        track("ui_knowledge_options_change", { value: v });
        
        if (v == "enabled") {
            doChange({ isEnabled: true, isLimited: false, scope });
        } else if (v == "limited") {
            doChange({ isEnabled: true, isLimited: true, scope });
            // 弹出 scope 编辑器

            setDialogOpen(true);

        } else if (v == "disabled") {
            doChange({ isEnabled: false, isLimited: false, scope });
        }
    }

    const items = [
        { value: "enabled", label: <div className='lc-knowlege-enabled'>使用知识库搜索</div> },
        {
            value: "limited",
            label: (
                <div className='lc-knowlege-limited'>指定知识范围<Pressable asChild onPress={e => {
                    // console.log("pressed", e);
                    // 弹出 scope 编辑器
                    setDialogOpen(true);
                }}><span className="badge">{scope.length}</span>
                </Pressable>
                </div>
            )
        },
        { value: "disabled", label: <div className='lc-knowlege-disabled'>不使用知识库</div> },
    ]

    const selected = isEnabled ? (isLimited ? "limited" : "enabled") : "disabled";

    return (
        <>
            <SelectInput 
                className={classNames("lc-knowledge-options-input", "lc-knowlege-" + selected, "font-size-13")} 
                listBoxClassName={"font-size-13"}
                listBoxStyle={{
                    // padding: 0;
                }}
                style={{
                    padding: "2px 0.5rem"
                }}
                itemUnstyled={true}
                items={items} onSelect={onSelect} selected={selected} onOpenChange={open => {
                    if(!open) {
                        // if(selected == "limited") {
                        //     setDialogOpen(true);
                        // }
                    }
                }}
            />
            <KnowledgeScopeDialog open={dialogOpen} onOpenChange={setDialogOpen} facade={facade} scope={scope} currentUserId={currentUserId} onChange={newScope => {
                doChange({ isEnabled, isLimited, scope: newScope });
            }} />
        </>

    )
}
