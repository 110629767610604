
import React from 'react';
// import createInlineStyleButton from './util/createInlineStyleButton';
import InlineStyleButton from '../util/InlineStyleButton';
import { MdFormatItalic } from 'react-icons/md'

export default function ItalicButton (props) {
    const { editor } = props
      
    return InlineStyleButton({
        editor,
        style: 'italic',
        children: (
            <MdFormatItalic style={{ fontSize: 16 }} />
        ),
    })
}
// export default createInlineStyleButton({
//   style: 'italic',
//   children: (
//     <i className={"fa fa-italic"} aria-hidden="true"></i>
//   ),
// });
