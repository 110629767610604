
// This file is generated. Don't modify it.
import React from 'react'
export default function RiskIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M12,8 L12,13 L13,13 L13,8 L12,8 Z M12,7 L13,7 C13.5522847,7 14,7.44771525 14,8 L14,13 C14,13.5522847 13.5522847,14 13,14 L11,14 L3,14 C2.44771525,14 2,13.5522847 2,13 L2,3 C2,2.44771525 2.44771525,2 3,2 L11,2 C11.5522847,2 12,2.44771525 12,3 L12,7 Z M11,13 L11,7 L11,3 L3,3 L3,13 L11,13 Z M5.01073507,8.66589994 L5.01073507,8.66628289 L6.33035182,5.20602258 C6.37170951,5.08233245 6.53714029,5 6.66121336,5 L8.02180488,5 C8.14587797,5 8.22859335,5.08233243 8.14587797,5.20602258 L7.28157876,7.2681631 C7.24022107,7.39185324 7.28157878,7.47418569 7.40565185,7.47418569 L8.97303181,7.51554338 C9.1384626,7.55690107 9.17982029,7.63923353 9.09710491,7.72194891 L6.5823274,11.3055164 C6.29282357,11.7593022 6.12739279,11.6765868 6.21049111,11.1404686 L6.62406803,9.11968575 C6.66504278,8.99599561 6.5823274,8.87268841 6.41727956,8.87268841 L5.18037819,8.87268841 C5.05209276,8.87268841 4.96937738,8.78997301 5.01073507,8.66589994 Z" id="RiskIcon-path-1"></path>
            </defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Personal/Risk">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="RiskIcon-mask-2" fill="white">
                            <use href="#RiskIcon-path-1"></use>
                        </mask>
                        <use id="Icon/Personal/Risk" fill={color} fillRule="nonzero" href="#RiskIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
