
// This file is generated. Don't modify it.
import React from 'react'
export default function BellWireFrameIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M13.3128033,11.6512258 C12.8497094,11.3261085 11.9980607,10.5727879 11.9980607,10.0526003 L11.9980607,7.51668539 C11.9980607,5.56915351 10.8046424,3.91819203 9.16795433,3.37342232 C9.17271215,3.34804731 9.18302074,3.32425824 9.18302074,3.2972973 C9.18302074,2.68353929 8.69455183,2 7.99356723,2 C7.29258263,2 6.80411372,2.68433225 6.80411372,3.2972973 C6.80411372,3.32425824 6.81442232,3.34804731 6.81918013,3.37342232 C5.1824921,3.91739906 3.98907374,5.56836054 3.98907374,7.51668539 L3.98907374,10.0518073 C3.98907374,10.5941981 3.06526485,11.3879601 2.61882329,11.6877024 C2.47338873,11.7846775 2.40862171,11.9654548 2.45938624,12.1327221 C2.51015078,12.2999895 2.66446781,12.4142762 2.83926868,12.414062 L6.05158613,12.414062 C6.24079977,13.3364114 7.05201049,13.9988947 7.99356723,14 C8.95147379,14 9.75157952,13.3180467 9.9363413,12.414062 L13.1637252,12.414062 C13.3524286,12.4128649 13.5141398,12.2788341 13.5503335,12.0936304 C13.5865271,11.9084266 13.4871726,11.7233768 13.3128033,11.6512258 Z M7.99356723,2.79376198 C8.16167666,2.79376198 8.32899312,2.99041829 8.37181345,3.20293399 C8.24731731,3.19024648 8.12123524,3.18231679 7.99356723,3.18231679 C7.86589922,3.18231679 7.73981715,3.19024648 7.61532101,3.20293399 C7.65814134,2.99041829 7.8254578,2.79376198 7.99356723,2.79376198 Z M7.99356723,13.207031 C7.49107915,13.2056572 7.04389031,12.8880515 6.87706687,12.414062 L9.11006759,12.414062 C8.94592301,12.874777 8.50979006,13.207031 7.99356723,13.207031 Z M3.93911669,11.621093 C4.36494105,11.1952686 4.78204275,10.6298817 4.78204275,10.0526003 L4.78204275,7.51668539 C4.78204275,5.56360272 6.22286744,3.9752858 7.99356723,3.9752858 C9.76506,3.9752858 11.2050917,5.56439569 11.2050917,7.51668539 L11.2050917,10.0518073 C11.2050917,10.6298817 11.6221934,11.1952686 12.0480178,11.621093 L3.93911669,11.621093 Z" id="BellWireFrameIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Wireframe/Bell">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="BellWireFrameIcon-mask-2" fill="white">
                            <use href="#BellWireFrameIcon-path-1"></use>
                        </mask>
                        <use id="Shape" fill={color} fillRule="nonzero" href="#BellWireFrameIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
