
import React, { useState, useEffect, useContext } from 'react'
import ReactDOM from 'react-dom'

import './ConfirmToken.less'

import getAuthURL from './getAuthURL'
import useCurrentUser from './useCurrentUser'

import Button from 'bwax-ui/components/Button';

import { runDataQuery } from 'bwax/query/runClientQuery'

import { Helmet } from 'react-helmet-async'

/**
 * 1）检查用户是否登录：
 *      a. 如果已登录，继续； 
 *      b. 如果还没有用户信息 (undefined），等待：显示”检查登录状态”
 *      c. 如果用户未登录（null），则跳转至登录页面
 *      b. TODO 在如何区分“未登录”，或者已经尝试过登录，但没有用户信息？
 * 2) 检查是否有 Token，如果没有 Token，显示信息：“没有需要处理的登录确认”
 * 3）显示确认按钮
 * 4）当用户点击确认按钮后，显示已完成信息：“您已完成登录确认，请查看电脑网页。”
 */

/// TODO 根据上面的流程 Refactor
export default function ConfirmToken (props) {

    const { match, dlc } = props
    const { token } = match.params

    const { tenantCode,  endpoints, sessionToken, sandbox } = dlc;

    const {currentUser} = useCurrentUser()

    /// states
    const [ status, setStatus ] = useState('正在检查登录状态...')
    const [ ready, setReady ] = useState(false)
    const [ finished, setFinished ] = useState(false)
    const [ loading, setLoading ] = useState(false);
    /// states end

    useEffect(() => {
        if(currentUser === undefined) {
            setStatus('正在检查登录状态...')
        } else if(currentUser === null) {
            // 如果用户未登录（null），则跳转至登录页面
            setStatus("准备登录...")
            window.location.replace(getAuthURL({ wxInfo: true }, { sandbox, tenantCode }))
        } else {
            if(token) {
                /// 有用户而且有 token
                ReactDOM.unstable_batchedUpdates(() => {
                    setReady(true)
                    setStatus('点击按钮，登录电脑页面')
                })
            } else {
                // 没有 token
                setStatus('请扫描登录二维码进入')
            }
        }
    }, [ currentUser ])

    async function confirmLoginToken(token) {

        setLoading(true)
        const runQuery = runDataQuery({ tenantCode, endpoints, sessionToken, sandbox })
        const result = await runQuery(MutationConfirmToken.text)({
            input: {
                token,
                clientMutationId: `${Date.now()}`
            }
        })
        const { errors } = JSON.parse(result)

        setLoading(false);

        if(errors) {
            /// TODO 暂时用 Alert
            alert(errors.map( e => e.message).join('; '))
        } else {
            ReactDOM.unstable_batchedUpdates(() => {
                setFinished(true)
                setStatus('您已完成登录确认，请查看电脑网页。如果仍未登录，重新扫描二维码。')
            })
        }
    }


    return (
        <div className='auth--confirm-token'>
            <Helmet>
                <title>登录确认</title>
                <meta
                name="viewport"
                content="width=device-width,initial-scale=1,maximum-scale=1"
            />
            </Helmet>
            <Button {...{
                isDisabled: !ready || finished,
                isLoading: loading,
                appearance: "primary",
                size: "large",
                color: "tomato",
                onPress: () => {
                    confirmLoginToken(token)
                }
            }}>
                确认登录
            </Button>
            <div className="tip">{ status }</div>
        </div>
    )
}

const MutationConfirmToken = {
    text: `
    mutation ConfirmLoginTokenMutation (
        $input: ConfirmLoginTokenInput!
      ) {
         confirmLoginToken(input: $input) {
           loginToken {
             id
             token
           }
         }
      }
    `
}
