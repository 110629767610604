
import React from 'react'

import { pickBy } from 'bwax/list'
import getImageURL from 'bwax-ui/getImageURL'

// import { imageStyle, imageBlockStyle, linkStyle, processImageURL } from './imagePluginHelpers'

const convertToHTML = {
    blockToHTML: (block) => {
        if (block.type === "atomic" && block.data.type === "IMAGE") {
            const blockStyle = imageBlockStyle(
                pickBy(block.data, ["width", "align"])
            )

            if (block.data.link) {
                return (
                    <figure style={blockStyle}>
                        <a href={block.data.link} style={linkStyle}>
                            <img src={block.data.src} style={imageStyle()} />
                        </a>
                    </figure>
                )
            } else {
                return (
                    <figure style={blockStyle}>
                        <img src={block.data.src} style={imageStyle()} />
                    </figure>
                )
            }

        }
    },
}

const convertToReact = {
    blockToReact: (block) => {
        try {
            if (block.type === "atomic" && block.data.type === "IMAGE") {
                const blockStyle = imageBlockStyle(
                    pickBy(block.data, ["width", "align"])
                )
                const { src, link } = block.data
                if (link) {
                    return (
                        <figure style={blockStyle}>
                            <a href={link} style={linkStyle}>
                                <img src={processImageURL(src)} style={imageStyle()} />
                            </a>
                        </figure>
                    )
                } else {
                    return (
                        <figure style={blockStyle}>
                            <img src={processImageURL(src)} style={imageStyle()} />
                        </figure>
                    )
                }
    
            }
        } catch (e) {
            console.error(e);
            return <div>ERROR IMAGE</div>
        }

    }
}

const convertFromHTML = {
    htmlToBlock: (nodeName, node) => {
        if (nodeName === 'img') {
            const resolveBlockData = () => {
                const parentNode = node.parentNode
                if (parentNode && parentNode.nodeName.toLowerCase() == "figure") {
                    // this is likely a HTML generated by us
                    const figureStyle = parentNode.style || {}

                    const blockData = {
                        align: figureStyle.float ? figureStyle.float : "center",
                        width: figureStyle.width
                    }
                    return blockData

                } else {
                    // this is not generated by user, we give it default value
                    return {
                        width: 100
                    }
                }
            }

            const blockData = resolveBlockData()

            /// 处理微信文章
            const dataW = node.getAttribute('data-w')
            if (dataW) {
                const w = parseFloat(dataW)
                if (!isNaN(w)) {
                    blockData.width = Math.min(dataW * 100 / 640.0, 100)
                    blockData.align = "center"
                }
            }

            return {
                type: "atomic",
                data: {
                    type: "IMAGE",
                    src: node.src || node.getAttribute('src') || node.getAttribute('data-src'),
                    ...blockData
                }
            }
        }
    }

}

export {
    convertToHTML,
    convertFromHTML,
    convertToReact
}



// copy from imagePluginHelper in order to avoid draft importing

export const linkStyle = {
    display: "inline-block",
    width: "100%"
}

export function imageBlockStyle({ align, width }) {
    let blockStyle = { padding: "2px 0" }
    if (align === 'center') {
        blockStyle = {
            ...blockStyle,
            margin: "8px auto"
        }
    } else if (align === 'left') {
        blockStyle = {
            ...blockStyle,
            marginRight: "4px",
            float: "left"
        }
    } else if (align === 'right') {
        blockStyle = {
            ...blockStyle,
            marginLeft: "4px",
            float: "right"
        }
    }

    if (width) {
        blockStyle.width = isNaN(width) ? width : (width + "%")
    }
    return blockStyle
}


export function imageStyle() {
    return {
        verticalAlign: "middle",
        width: "100%"
    }
}

export function processImageURL(urls) {
    function processForDataBlob(url) {
        if(!url) {
            return url
        }
        const isObjectURL = url.match(/^blob:.+/) !== null
        if (isObjectURL) {
            return url
        } else {
            return getImageURL({ url })
        }
    }
    if(urls && Array.isArray(urls)) {
        return urls.map(processForDataBlob);
    } else {
        return processForDataBlob(urls)
    }

}