
// This file is generated. Don't modify it.
import React from 'react'
export default function UploadIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M8.49633753,9.99912101 L8.49633753,12.2915324 C8.49633753,12.5669499 8.27248755,12.7907999 7.99589804,12.7907999 C7.71930853,12.7907999 7.49545854,12.5669499 7.49545854,12.2903604 L7.49545854,9.99736302 L6.84881336,10.6440082 C6.65426311,10.8397304 6.33665397,10.8397304 6.14210372,10.6440082 C5.94638148,10.448286 5.94638148,10.1318488 6.14210372,9.93729856 L7.6278176,8.45158469 C7.71923162,8.35242972 7.850253,8.29036039 7.99589804,8.29036039 C8.14458766,8.29036039 8.27803584,8.35505185 8.36965912,8.45785133 L9.84910636,9.93729856 C10.0448286,10.1330208 10.0448286,10.449458 9.84910636,10.6440082 C9.75065924,10.7424553 9.62291239,10.7905069 9.49516554,10.7905069 C9.36741869,10.7905069 9.23967184,10.7412833 9.14122473,10.6440082 L8.49633753,9.99912101 Z M10.999707,11.001172 C10.7231175,11.001172 10.4992675,10.777322 10.4992675,10.5007325 C10.4992675,10.224143 10.7231175,10.000293 10.999707,10.000293 C12.1025491,10.000293 13.000293,9.10254908 13.000293,7.999707 C13.000293,6.94960445 12.1787284,6.07412833 11.1309698,6.00615294 L10.8356285,5.9885731 L10.7102256,5.7225315 C10.2156461,4.67594492 9.15147964,3.999707 7.99941401,3.999707 C6.84734837,3.999707 5.78318195,4.67594492 5.2886024,5.7225315 L5.16319953,5.9885731 L4.86903018,6.00732493 C3.82009962,6.07530032 2.999707,6.95077644 2.999707,8.00087899 C2.999707,9.10372107 3.89745092,10.001465 5.000293,10.001465 C5.27688251,10.001465 5.50073249,10.225315 5.50073249,10.5019045 C5.50073249,10.778494 5.27688251,11.002344 5.000293,11.002344 C3.34544389,11.002344 2,9.65690009 2,8.00205098 C2,6.52417228 3.08409024,5.27717551 4.51626135,5.0439496 C5.21945502,3.79343686 6.55669499,3 7.99941401,3 C9.44213302,3 10.779373,3.79343686 11.4837386,5.04160563 C12.9159098,5.27483153 14,6.52300029 14,8.00087899 C14,9.65455611 12.6545561,11.001172 10.999707,11.001172 Z" id="UploadIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Upload">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="UploadIcon-mask-2" fill="white">
                            <use href="#UploadIcon-path-1"></use>
                        </mask>
                        <use id="Combined-Shape" fill={color} fillRule="nonzero" href="#UploadIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
