import React from 'react'

import { Helmet } from 'react-helmet-async'

import './NotFoundPage.less';

import NotFoundImage from '../images/error_404.png'

function NotFoundPage(props) {

	// esbuild 后， SSR 时，会在前面加个 .
	// TODO 暂时这么处理吧

	const ImageURL = NotFoundImage.replace("./", "/");

	return (
		<>
			<Helmet>
				<meta name="viewport" content="width=device-width,initial-scale=1" />
			</Helmet>
			<div className="not-found-page">
				<div className="content">
					<span className="top-placeholder" />
					<img src={ImageURL} className="logo-icon" />
				</div>
			</div>
		</>
	)

}


export default NotFoundPage