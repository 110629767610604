
import { color } from './color/Palette'

export const defaultThemeColors = {
    /**主要颜色 */
    primaryColor: 'O50',
    secondoryColor: 'B90',
    tertiaryColor: 'B60',

    dangerColor: 'R50',
    successColor: 'G70',
    errorColor: 'R60',
    warningColor: 'Y60',
    infoColor: 'O50',

    pageBgColor: 'N10',
    containerBgColor: 'white',
    overlayBgColor: "rgba(255,255,255,.97)", // white97%

    divideColor: 'N20',

    // textColor: 'N80',
    textColor: '#27282d',  // 

    lightTextColor: 'N60',
    lighterTextColor: 'N40',
    highlightTextColor: 'O50',

    linkColor: 'B60',

    hoverColor: "rgba(255,255,255,.20)", // white20%
    pressedColor: "rgba(0,0,0,.20)", // black20%

    disabledColor: 'N30',

    inverseTextColor: 'white',
    inverseLightTextColor: "rgba(255,255,255,.70)", // white70%
    inverseLighterTextColor: "rgba(255,255,255,.40)", // white40%

    inverseBgColor: 'B90',
}


const defaultTheme = {
    ...defaultThemeColors,
}


export function getThemeValue(theme, name) {
    if (defaultTheme[name] === undefined) {
        console.warn("The name", name, "is not defined in default theme.")
    }
    return theme && theme[name] ? theme[name] : defaultTheme[name]
}


export function themeColor(theme, prefix) {

    let name = `${prefix}Color`;
    let colorName = theme && theme[name] ? theme[name] : defaultTheme[name]
    return color(colorName || prefix)  // 
    // if there's no such name, then the prefix must itself be a color name 
}

export default defaultTheme