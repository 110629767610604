

export function supports(name, value) {
    if(typeof(CSS) !== "undefined" && typeof(CSS.supports) !== undefined) {
        return !!CSS.supports(name, value);
    } else {
        // server 端假定所有都支持
        // 这样的话，如果前端的 CSS 不支持，hydration 可能会报 mismatch 并在 client 端重新 render
        // if(typeof(document) === 'undefined') {
        //     return true
        // } else {
        //     return false
        // }     
        
        /**
         * https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1154
         * 不一定能够 rerender，返回 true 会出错，所以 server 端假定不支持（by 威豪 2022-12-30）
         */
        return false
    }
}