
// This file is generated. Don't modify it.
import React from 'react'
export default function WordIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 15 16">
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Documents/Word" transform="translate(-1.000000, 0.000000)">
                    <g>
                        <g id="word" transform="translate(1.200000, 0.000000)">
                            <path d="M0.614479638,0 C0.31040724,0 0.00633484163,0.3 0.00633484163,0.6 L0.00633484163,15.4 C0.00633484163,15.7 0.31040724,16 0.614479638,16 L13.3855204,16 C13.6895928,16 13.9936652,15.7 13.9936652,15.4 L13.9936652,3.5 L10.4461538,0 L0.614479638,0 Z" id="WordIcon-Path" fill={"#0E5DAA"}></path>
                            <path d="M10.4461538,0 L10.4461538,2.88 C10.4461538,3.18 10.7502262,3.5 11.0745701,3.5 L13.9936652,3.5 L10.4461538,0 Z" id="WordIcon-Path" fill={"#89C5FF"}></path>
                        </g>
                        <path d="M11.4376593,5.6 L10.5232814,9.84088518 C10.4713495,10.0660027 10.4399936,10.2953758 10.4296009,10.5261718 L10.4094455,10.5261718 C10.3926278,10.3034999 10.3586506,10.082458 10.3078164,9.86501499 L9.24269815,5.6 L7.81591663,5.6 L6.64519501,9.81278105 C6.57901673,10.0484587 6.53688202,10.2902339 6.5194361,10.5344043 L6.49104809,10.5344043 C6.4806922,10.2987436 6.45222017,10.0642289 6.40588406,9.83293654 L5.47078293,5.6 L4,5.6 L5.67886706,11.8453627 L7.22743313,11.8453627 L8.3249137,7.77367011 C8.38154264,7.54740687 8.4195206,7.31688062 8.43846576,7.08440917 L8.45464691,7.08440917 C8.46455123,7.31706339 8.49861058,7.54805811 8.55627601,7.77367011 L9.62962675,11.8453627 L11.1214168,11.8453627 L12.8,5.6 L11.4373754,5.6 L11.4376593,5.6 Z" id="WordIcon-Path" fill="#FFFFFF"></path>
                    </g>
                </g>
            </g>
        </svg>
        
    )
}
