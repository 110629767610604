

import React, { useEffect, useState } from 'react'

import { getFileType } from './FileIcon';

import classNames from 'classnames'
import { addQueryParam } from 'bwax/ml/lang/mod/builtin/StringHelper';

import Loading from 'bwax-ui/components/Loading';
import { toBase64URI } from 'bwax/ml/lang/mod/builtin/StringHelper';

export const viewSupported = (file, tokenCount) => {

    if(file && getFileType(file) == "docx") {
        // 词符数大于 10万 100000 并且文档大于 3m
        if(tokenCount && tokenCount > 100000 && file.size > 3000000) {
            return false
        }
        return true
    }

    const supportedTypes = ["pdf", "srt", "txt", "po", "epub"];
    return file && supportedTypes.indexOf(getFileType(file)) != -1;
}

export default function FileView({ file, className, facade, iframeRef, maxPages }) {

    // "grow w-full shadow-md"
    const fileType = getFileType(file);

    // const officeFileTypes = ["docx", "doc", "xlsx", "xls", "pptx", "ppt"];

    if (fileType == "pdf") {
        let url = addQueryParam("src", file.url, "/pdf2.html");
        if(maxPages) {
            url = addQueryParam("max_page", maxPages, url);
        }
        return (
            <iframe className={classNames(className)} src={url} ref={iframeRef} />
        )
    } 
    if(fileType == "docx") {
        return (
            <iframe className={classNames(className)} src={addQueryParam("src", file.url, "/docx-view.html")} ref={iframeRef} />
        )
    }

    if(fileType == "srt" || fileType == "txt" || fileType == "po") {
        return (
            <iframe className={classNames(className)} src={addQueryParam("src", file.url, "/txt-view.html")} ref={iframeRef} />
        )
    }

    if(fileType == "epub") {
        return (
            <iframe className={classNames(className)} src={addQueryParam("src", file.url, "/epub-view.html")} ref={iframeRef} />
        )
    }


    // if(officeFileTypes.indexOf(fileType) !== -1 && facade) {
    //     // other type
    //     return <AliyunOfficeFileView file={file} className={className} facade={facade} />
    // }
    return (
        <div className={classNames("bg-[var(--gray2)] flex flex-col justify-center items-center", className)}>
            <div className="flex flex-col gap-4 items-center">
                <i className='bx bx-library text-5xl text-[var(--gray8)]'></i>
                <div className="text-[var(--gray10)]">这类文件暂时不支持预览</div>
            </div>
        </div>
    )
}



function AliyunOfficeFileView({ file, className, facade }) {
    
    // 每次都创建 view record;
    const [ viewRecord, setViewRecord ] = useState();

    async function initViewRecord(file) {
        const [ result, error ] = await facade.add({ entityName: "阿里云-文档预览", formData: { 文档: file }, fieldPaths: ["webofficeConfig"] });
        if(!error && result) {
            setViewRecord(result);
        }
    }

    useEffect(() => { 
        initViewRecord(file);
    }, [ file ]);

    function renderViewRecord(viewRecord) {

        const { AccessToken, WebofficeURL } = viewRecord.webofficeConfig;


        return (
            <iframe className={classNames(className)} 
                src={addQueryParam("config", toBase64URI(JSON.stringify({ token: AccessToken, url: WebofficeURL })), "/ali-view.html")} />
        )
    }

    return (
        viewRecord === undefined ? (
            <div className={classNames("bg-[var(--gray2)] flex flex-col justify-center items-center", className)}>
            <Loading></Loading>
        </div>
        ) : (
            viewRecord === null ? (
                <div className={classNames("bg-[var(--gray2)] flex flex-col justify-center items-center", className)}>
                    <div className="text-[var(--gray10)]">出错了</div>
                </div>
            ) : renderViewRecord(viewRecord)
        )
    )
}

