
import React from 'react'
import { Editor, Transforms } from 'slate'

import { MdFormatClear } from 'react-icons/md'

export const inlineStyles = ["bold", "italic", "underline", "sup", "sub", "color", "css", 'color', 'background']

export default function FormatClearButton(props) {

    const { editor } = props

    function clearFormat () {
        inlineStyles.map(mark => {
            Editor.removeMark(editor, mark)
        })
    }

    return (
        <button className="editor-button" onClick={() => clearFormat()}>
            <MdFormatClear style={{ fontSize: 14 }} />
        </button>
    )
}
