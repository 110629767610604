

import getImageURL from 'bwax-ui/getImageURL';
import React from 'react'

import RichText from "bwax-ui/basic/RichText";
import Link from 'bwax-ui/page/Link';

import PopoverTrigger from 'bwax-ui/components/PopoverTrigger';

export default function FamiyOfficeHome_cb({ data }) {

    // main banner: 一个大图，中间靠左显示 群蜂家业的 slogan，以及一个按钮
    // 列出各个模块：名字、说明、小图

    const { intro, banner, modules, supportQrCode } = data;

    function renderBanner() {

        function em(text) {
            return (
                <span className="text-[19px] font-medium mx-1 text-[--teal5]">{text}</span>
            )
        }

        return (
            <div className="min-h-[100vh] pt-4 w-full flex gap-2 items-center justify-center bg-[#518db4] relative overflow-hidden">
                {/* <div className="absolute block sm:hidden top-[-80px] left-[-80px] w-[160px] h-[160px] rounded-full bg-white/10" /> */}
                <div className="absolute hidden sm:block top-[-150px] left-[-150px] w-[300px] h-[300px] rounded-full bg-white/10" />

                <div className="absolute block sm:hidden top-[-150px] right-[-180px] w-[300px] h-[300px] rounded-full bg-white/10" />
                <div className="absolute hidden sm:block top-[-500px] right-[-400px] w-[800px] h-[800px] rounded-full bg-white/10" />

                <div className="absolute block sm:hidden bottom-[-190px] left-[-140px] w-[280px] h-[280px] rounded-full bg-white/10" />

                <div className="absolute hidden sm:block bottom-[-1300px] left-[-800px] w-[1600px] h-[1600px] rounded-full bg-white/10" />

                <div className="flex items-center flex-col sm:flex-row  w-full max-w-[64rem] p-2 text-[17px] leading-relaxed gap-4">
                    <div className="w-full sm:w-1/3 flex flex-col items-center sm:items-start gap-16 py-20 sm:py-12 px-8 sm:px-4 text-[white] whitespace-pre-line">
                        <div>
                            由群蜂社发起，由各方面资深专业人士联合组建，基于{em("共创、共建、共治、共享")}理念的{em("共享家庭办公室")}
                        </div>
                        <PopoverTrigger content={
                            <div className="p-4 rounded-md w-[240px] h-[240px]">
                                <img src={getImageURL(supportQrCode, "small")} alt="客服企业微信二维码" />
                            </div>
                        }>
                            <div className="border-[1px] rounded-md px-12 sm:px-10 py-1.5 cursor-pointer border-[var(--gray9)] select-none hover:bg-white/20" onClick={_ => {

                            }}>
                                联系我们
                            </div>
                        </PopoverTrigger>

                    </div>
                    <div className="w-full sm:w-2/3 sm:pl-4 px-2 pb-4 sm:pb-0">
                        <img src={getImageURL(banner, "medium")} />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className=" w-full">
            {renderBanner()}
            {intro ? (
                <div className="flex justify-center w-full py-20 px-8">
                    <RichText className="text-[17px] leading-relaxed max-w-[40rem] " params={{ value: intro }}></RichText>
                </div>
            ) : null}
            {modules && modules.length > 0 ? (
                <div className="flex w-full py-24 bg-[var(--slate3)] justify-center">
                    <div className="w-full max-w-[50rem] flex flex-col gap-16">
                        {modules.map(m => (
                            <Link key={m.code} to={"/fo/" + m.code} className="flex flex-col sm:flex-row-reverse w-full gap-4 justify-between bg-[white] sm:rounded-md overflow-hidden hover:shadow-md">
                                {m.cover ? (
                                    <img src={m.cover.url} alt={m.desc} className="flex w-full sm:w-[400px]  mt-1overflow-hidden"></img>
                                ) : null}
                                <div className="flex flex-col gap-4 px-4 py-8">
                                    <h3 className="font-size-18 font-medium">{m.name}</h3>
                                    <div className="text-[16px]">{m.desc}</div>
                                </div>

                            </Link>
                        ))}
                    </div>
                </div>
            ) : null}
        </div>
    )
}
