

import React, { useState } from 'react'

import RelatedItems from './RelatedItems';

import { Pressable } from 'bwax-ui/components/Button';
import Modal from 'bwax-ui/components/Modal'

import Link from 'bwax-ui/page/Link'

export default function RelatedWebLinks({ value: givenValue }) {

    const value = (givenValue || []).filter(v => !!v.snippet);

    const [ detailToShown, setDetailToShown ] = useState();

    function renderItem(record, index) {
        return (
            <Pressable key={index} onPress={_ => setDetailToShown(record)}>
                <div className="knowledge-item cursor-pointer" key={index}>
                    { index + 1 }. <i className='bx bx-link-alt' /> {record.title}
                </div>
            </Pressable>
        )
    }

    return (
        <>
            <RelatedItems items={value} title="参考的网页" renderItem={renderItem} />
            { detailToShown ? (
                <Modal isOpen={true} className="max-w-lg" onOpenChange={open => {
                    if(!open) setDetailToShown(false)
                }} isDimissable isMain={false}>
                    <div className="px-6 py-6 flex flex-col gap-3">
                        <div className="font-medium"><i className='bx bx-link-alt mr-2' />{ detailToShown.title }</div>
                        <div className="px-2 flex flex-col gap-3">
                            <Link to={detailToShown.link} openWithNewTab={true} className="underline break-all text-[var(--gray10)]">{ detailToShown.link }</Link>
                            <div className="text-[var(--gray11)]">{ detailToShown.snippet } </div>
                        </div>
                    </div>
                </Modal>
            ) : null}
        </>
    )
}
