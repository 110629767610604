import React from 'react'

class InvestIcon extends React.Component {

    render() {
        return (
            <svg width="1em" height="1em" viewBox="0 0 16 16">
                <defs>
                    <linearGradient x1="100%" y1="100%" x2="0%" y2="0%" id="linearGradient-1">
                        <stop stopColor="#3C3D3D" offset="0%"></stop>
                        <stop stopColor="#141414" offset="100%"></stop>
                    </linearGradient>
                    <linearGradient x1="100%" y1="100%" x2="0%" y2="0%" id="linearGradient-2">
                        <stop stopColor="#FFCF99" offset="0%"></stop>
                        <stop stopColor="#FFE2C2" offset="100%"></stop>
                    </linearGradient>
                    <path d="M0.26038796,3.05336806 L4.62662207,0.115348346 C4.67574582,0.0803719213 4.73642809,0.0615384615 4.8,0.0615384615 C4.86357191,0.0615384615 4.92425418,0.0830624155 4.97337793,0.115348346 L9.33961204,3.05336806 C9.38873579,3.08565399 9.42341137,3.13946388 9.42341137,3.20134525 C9.42341137,3.26322661 9.39162542,3.3170365 9.33961204,3.34932243 L4.97337793,6.28465165 C4.92425418,6.31962808 4.86357191,6.33846154 4.8,6.33846154 C4.73642809,6.33846154 4.67574582,6.31693758 4.62662207,6.28465165 L0.26038796,3.34932243 C0.208374582,3.3170365 0.176588629,3.26053612 0.176588629,3.20134525 C0.176588629,3.13946388 0.208374582,3.08565399 0.26038796,3.05336806 Z" id="path-3"></path>
                </defs>
                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Icon/VIP-rights/投资">
                        <g>
                            <circle id="Oval" fill="url(#linearGradient-1)" fillRule="nonzero" cx="8" cy="8" r="8"></circle>
                            <g id="Group" transform="translate(3.200000, 3.200000)">
                                <path d="M9.42341137,6.39865475 C9.42341137,6.46053612 9.39162542,6.51434601 9.33961204,6.54663194 L4.97337793,9.48465165 C4.92425418,9.51962808 4.86357191,9.53846154 4.8,9.53846154 C4.73642809,9.53846154 4.67574582,9.51693758 4.62662207,9.48465165 L0.26038796,6.54663194 C0.211264214,6.51434601 0.176588629,6.46053612 0.176588629,6.39865475 C0.176588629,6.33677339 0.208374582,6.2829635 0.26038796,6.25067757 L4.62662207,3.31534835 C4.67574582,3.28037192 4.73642809,3.26153846 4.8,3.26153846 C4.86357191,3.26153846 4.92425418,3.28306242 4.97337793,3.31534835 L9.33961204,6.25336806 C9.39162542,6.2829635 9.42341137,6.33946388 9.42341137,6.39865475 Z" id="Shape" fill="url(#linearGradient-2)" fillRule="nonzero"></path>
                                <mask id="mask-4" fill="white">
                                    <use href="#path-3"></use>
                                </mask>
                                <use id="Shape" fillOpacity="0.7" fill="url(#linearGradient-2)" fillRule="nonzero" href="#path-3"></use>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export default InvestIcon
