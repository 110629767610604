// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Plate$BwaxMobile = require("../plate.bs.js");
var Query_env$BwaxMobile = require("./query_env.bs.js");
var Query_builder$BwaxMobile = require("./query_builder.bs.js");
var Defs_aggregate$BwaxMobile = require("./defs_aggregate.bs.js");
var Query_commands$BwaxMobile = require("./query_commands.bs.js");
var Runtime_common$BwaxMobile = require("../lang/mod/runtime_common.bs.js");
var Base_query_types$BwaxMobile = require("../base_query_types.bs.js");
var Record_dependency_resolver$BwaxMobile = require("../lang/record_dependency_resolver.bs.js");

function handle_query_cmd_m(context, _execute_command_m, _execute_cmd, param, maybe_binds, maybe_processor, on_result) {
  var data_type_dict = context[/* data_type_dict */1];
  var entity_dict = context[/* entity_dict */0];
  var config = param[1];
  var name = param[0];
  var handle_single_result = function (param) {
    if (param.tag) {
      throw param[0];
    } else {
      var match = param[0];
      var m_error = match[1];
      var m_value = match[0];
      return Curry._1(on_result, m_value !== undefined ? /* Return */Block.__(0, [m_value]) : (
                    m_error !== undefined ? /* Fail */Block.__(1, [m_error]) : /* Fail */Block.__(1, [/* tuple */[
                            /* V_literal */Block.__(5, [/* String */Block.__(2, ["Unknown error"])]),
                            Runtime_common$BwaxMobile.nt
                          ]])
                  ));
    }
  };
  var get_all_binds = function (ename) {
    if (maybe_processor !== undefined) {
      var deps = Record_dependency_resolver$BwaxMobile.determine_cmdm_processor_dependencies(context[/* entity_dict */0], context[/* data_type_dict */1], context[/* dts */2], maybe_processor);
      return Plate$BwaxMobile.List.map((function (maybe_dep) {
                    return Plate$BwaxMobile.$$Option.and_then((function (param) {
                                  if (param.tag) {
                                    return ;
                                  } else {
                                    return /* tuple */[
                                            param[0],
                                            param[1][3]
                                          ];
                                  }
                                }), maybe_dep);
                  }), deps);
    } else {
      return Plate$BwaxMobile.$$Option.with_default(/* [] */0, maybe_binds);
    }
  };
  var get_only_binds = function (ename) {
    return Plate$BwaxMobile.$$Option.with_default(/* [] */0, Plate$BwaxMobile.$$Option.map(Plate$BwaxMobile.snd, Plate$BwaxMobile.List.find((function (param) {
                          return param[0] === ename;
                        }), Plate$BwaxMobile.List.clean(get_all_binds(ename)))));
  };
  var get_all_selections = function (ename) {
    return Plate$BwaxMobile.List.map((function (maybe_binds) {
                  return Plate$BwaxMobile.$$Option.map((function (param) {
                                var entity = Base_query_types$BwaxMobile.get_entity(entity_dict, param[0]);
                                return /* tuple */[
                                        entity,
                                        Query_commands$BwaxMobile.to_selection(param[1])
                                      ];
                              }), maybe_binds);
                }), get_all_binds(ename));
  };
  var query_context = Query_builder$BwaxMobile.runtime_to_query_context(context);
  var perform_standard_mut = function (ename, pack_mutation) {
    var entity = Base_query_types$BwaxMobile.get_entity(entity_dict, ename);
    var selection = Query_commands$BwaxMobile.to_selection(get_only_binds(ename));
    var mutation = Curry._2(pack_mutation, entity, selection);
    Query_builder$BwaxMobile.resolve_and_run_mutation(query_context, mutation, Query_builder$BwaxMobile.default_query_runner_option, handle_single_result);
    return true;
  };
  var build_customer_interface_settings = function (entity_name, interface_name, params) {
    var all_selections = get_all_selections(entity_name);
    var entity = Base_query_types$BwaxMobile.get_entity(entity_dict, entity_name);
    var match = Plate$BwaxMobile.List.find((function (i) {
            return i[/* name */0] === interface_name;
          }), entity[/* interfaces */4]);
    var $$interface;
    if (match !== undefined) {
      $$interface = match;
    } else {
      throw [
            Runtime_common$BwaxMobile.Runtime_exn,
            "I cannot find interface " + (interface_name + (" in entity " + entity_name))
          ];
    }
    var inputs = Query_commands$BwaxMobile.to_interface_inputs(entity_dict, data_type_dict, $$interface[/* input_types */3], params);
    var outputs = Plate$BwaxMobile.List.combine($$interface[/* output_types */4], all_selections);
    var match$1 = Plate$BwaxMobile.List.head(Plate$BwaxMobile.List.rev(params));
    var maybe_id = $$interface[/* is_targeting_record */5] && match$1 !== undefined ? Caml_option.some(Query_commands$BwaxMobile.to_interface_input_value(entity_dict, data_type_dict, /* Intf_string */3, match$1)) : undefined;
    return /* tuple */[
            $$interface,
            maybe_id,
            inputs,
            outputs
          ];
  };
  if (config) {
    var match = config[0][0];
    if (typeof match === "number" || match.tag !== /* V_literal */5) {
      return false;
    } else {
      var match$1 = match[0];
      if (typeof match$1 === "number" || match$1.tag !== /* String */2) {
        return false;
      } else {
        var match$2 = config[1];
        if (match$2) {
          if (!match$2[1] && Plate$BwaxMobile.Str.starts_with("CmdM_standard_query", name)) {
            var ename = match$1[0];
            var params = match$2[0];
            var cmd_name = name;
            var entity = Base_query_types$BwaxMobile.get_entity(entity_dict, ename);
            var selection = Query_commands$BwaxMobile.to_selection(get_only_binds(ename));
            var query;
            switch (cmd_name) {
              case "CmdM_standard_query_count" :
                  var match$3 = Base_query_types$BwaxMobile.to_query_param(params);
                  query = /* Q_count */Block.__(3, [
                      entity,
                      /* tuple */[
                        match$3[0],
                        match$3[2]
                      ]
                    ]);
                  break;
              case "CmdM_standard_query_findByID" :
                  var match$4 = Base_query_types$BwaxMobile.to_query_param(Query_env$BwaxMobile.transform_to_id_criteria(params));
                  query = /* Q_find_one */Block.__(0, [
                      entity,
                      /* tuple */[
                        match$4[0],
                        match$4[1],
                        match$4[2]
                      ],
                      selection
                    ]);
                  break;
              case "CmdM_standard_query_findOne" :
                  var match$5 = Base_query_types$BwaxMobile.to_query_param(params);
                  query = /* Q_find_one */Block.__(0, [
                      entity,
                      /* tuple */[
                        match$5[0],
                        match$5[1],
                        match$5[2]
                      ],
                      selection
                    ]);
                  break;
              case "CmdM_standard_query_list" :
                  var match$6 = Base_query_types$BwaxMobile.to_query_param(params);
                  query = /* Q_list */Block.__(1, [
                      entity,
                      /* tuple */[
                        match$6[0],
                        match$6[1],
                        match$6[2],
                        match$6[3]
                      ],
                      selection
                    ]);
                  break;
              case "CmdM_standard_query_listAll" :
                  var match$7 = Base_query_types$BwaxMobile.to_query_param(params);
                  query = /* Q_listAll */Block.__(2, [
                      entity,
                      /* tuple */[
                        match$7[0],
                        match$7[1],
                        match$7[2],
                        match$7[3]
                      ],
                      selection
                    ]);
                  break;
              default:
                throw [
                      Runtime_common$BwaxMobile.Runtime_exn,
                      "Handle common query: shouldn't be here"
                    ];
            }
            Query_commands$BwaxMobile.perform_single_query(context, query, handle_single_result);
            return true;
          }
          
        } else {
          return false;
        }
      }
    }
  } else {
    return false;
  }
  switch (name) {
    case "CmdM_aggregate" :
        var match$8 = config[1];
        var match$9 = match$8[0][0];
        if (typeof match$9 === "number" || !(match$9.tag === /* V_record */2 && !match$8[1])) {
          return false;
        } else {
          var entity$1 = Base_query_types$BwaxMobile.get_entity(entity_dict, config[0][0][0][0]);
          var query$1 = Defs_aggregate$BwaxMobile.params_to_query_option(entity$1, match$9[0]);
          Query_commands$BwaxMobile.perform_single_query(context, query$1, handle_single_result);
          return true;
        }
    case "CmdM_custom_operation" :
        var match$10 = config[1];
        var match$11 = match$10[0][0];
        if (typeof match$11 === "number" || match$11.tag !== /* V_literal */5) {
          return false;
        } else {
          var match$12 = match$11[0];
          if (typeof match$12 === "number" || match$12.tag !== /* String */2) {
            return false;
          } else {
            var match$13 = build_customer_interface_settings(config[0][0][0][0], match$12[0], match$10[1]);
            var mutation_000 = match$13[0];
            var mutation_001 = match$13[1];
            var mutation_002 = match$13[2];
            var mutation_003 = match$13[3];
            var mutation = /* M_custom_operation */Block.__(4, [
                mutation_000,
                mutation_001,
                mutation_002,
                mutation_003
              ]);
            Query_builder$BwaxMobile.resolve_and_run_mutation(query_context, mutation, Query_builder$BwaxMobile.default_query_runner_option, handle_single_result);
            return true;
          }
        }
    case "CmdM_custom_query" :
        var match$14 = config[1];
        var match$15 = match$14[0][0];
        if (typeof match$15 === "number" || match$15.tag !== /* V_literal */5) {
          return false;
        } else {
          var match$16 = match$15[0];
          if (typeof match$16 === "number" || match$16.tag !== /* String */2) {
            return false;
          } else {
            var match$17 = build_customer_interface_settings(config[0][0][0][0], match$16[0], match$14[1]);
            var q_000 = match$17[0];
            var q_001 = match$17[1];
            var q_002 = match$17[2];
            var q_003 = match$17[3];
            var q = /* Q_custom_query */Block.__(6, [
                q_000,
                q_001,
                q_002,
                q_003
              ]);
            Query_commands$BwaxMobile.perform_single_query(context, q, handle_single_result);
            return true;
          }
        }
    case "CmdM_remat" :
        var match$18 = config[1];
        var match$19 = match$18[0][0];
        if (typeof match$19 === "number" || !(match$19.tag === /* V_record */2 && !match$18[1])) {
          return false;
        } else {
          var params$1 = match$19[0];
          var entity$2 = Base_query_types$BwaxMobile.get_entity(entity_dict, config[0][0][0][0]);
          var criteria = Plate$BwaxMobile.$$Option.map(Base_query_types$BwaxMobile.to_criteria, Plate$BwaxMobile.List.assoc("criteria", params$1));
          var fields = Plate$BwaxMobile.List.assoc_keys(Plate$BwaxMobile.List.filter((function (param) {
                        var match = param[1][0];
                        if (typeof match === "number" || match.tag !== /* V_literal */5) {
                          return false;
                        } else {
                          var match$1 = match[0];
                          if (typeof match$1 === "number" || !(match$1.tag === /* Bool */4 && match$1[0])) {
                            return false;
                          } else {
                            return true;
                          }
                        }
                      }))(Plate$BwaxMobile.$$Option.with_default(/* [] */0, Plate$BwaxMobile.$$Option.map(Base_query_types$BwaxMobile.extract_record, Plate$BwaxMobile.List.assoc("remat", params$1)))));
          var mutation_001$1 = /* tuple */[
            criteria,
            fields
          ];
          var mutation$1 = /* M_remat */Block.__(3, [
              entity$2,
              mutation_001$1
            ]);
          Query_builder$BwaxMobile.resolve_and_run_mutation(query_context, mutation$1, Query_builder$BwaxMobile.default_query_runner_option, handle_single_result);
          return true;
        }
    case "CmdM_standard_mut_add" :
        var match$20 = config[1];
        if (match$20[1]) {
          return false;
        } else {
          var params$2 = match$20[0];
          var pack_mutation = function (entity, selection) {
            var add_param = Query_commands$BwaxMobile.value_to_mutation_input(entity_dict, data_type_dict, params$2);
            return /* M_add */Block.__(0, [
                      entity,
                      add_param,
                      selection
                    ]);
          };
          return perform_standard_mut(config[0][0][0][0], pack_mutation);
        }
    case "CmdM_standard_mut_delete" :
        var match$21 = config[1];
        var match$22 = match$21[0][0];
        if (typeof match$22 === "number" || match$22.tag !== /* V_literal */5) {
          return false;
        } else {
          var match$23 = match$22[0];
          if (typeof match$23 === "number" || !(match$23.tag === /* String */2 && !match$21[1])) {
            return false;
          } else {
            var id = match$23[0];
            var pack_mutation$1 = function (entity, _selection) {
              return /* M_delete */Block.__(2, [
                        entity,
                        id
                      ]);
            };
            return perform_standard_mut(config[0][0][0][0], pack_mutation$1);
          }
        }
    case "CmdM_standard_mut_update" :
        var match$24 = config[1];
        var match$25 = match$24[1];
        if (match$25) {
          var match$26 = match$25[0][0];
          if (typeof match$26 === "number" || match$26.tag !== /* V_literal */5) {
            return false;
          } else {
            var match$27 = match$26[0];
            if (typeof match$27 === "number" || !(match$27.tag === /* String */2 && !match$25[1])) {
              return false;
            } else {
              var id$1 = match$27[0];
              var params$3 = match$24[0];
              var pack_mutation$2 = function (entity, selection) {
                var add_param = Query_commands$BwaxMobile.value_to_mutation_input(entity_dict, data_type_dict, params$3);
                return /* M_update */Block.__(1, [
                          entity,
                          /* tuple */[
                            add_param,
                            id$1
                          ],
                          selection
                        ]);
              };
              return perform_standard_mut(config[0][0][0][0], pack_mutation$2);
            }
          }
        } else {
          return false;
        }
    default:
      return false;
  }
}

var handlers = /* :: */[
  handle_query_cmd_m,
  /* [] */0
];

exports.handle_query_cmd_m = handle_query_cmd_m;
exports.handlers = handlers;
/* Plate-BwaxMobile Not a pure module */
