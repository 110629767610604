// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var $$Performance = require("./performance");

function now(prim) {
  return $$Performance.performanceNow();
}

exports.now = now;
/* ./performance Not a pure module */
