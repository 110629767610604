
// This file is generated. Don't modify it.
import React from 'react'
export default function LinkIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M12.3531143,7.91628626 C12.1494515,8.09944508 11.8389961,8.09452653 11.6412376,7.905008 C11.4434792,7.71548946 11.4253602,7.40552424 11.5996907,7.19425529 L12.5100775,6.24201154 C13.1897441,5.40169924 13.1028729,4.17864349 12.3112574,3.44283347 C11.521209,2.62139244 10.3544488,2.54814292 9.69520316,3.23878124 L7.15239842,5.90715659 C6.47273185,6.74746888 6.5596031,7.97052463 7.35121854,8.70633465 C7.55407974,8.91040648 7.55407974,9.23999012 7.35121854,9.44406195 C7.25297702,9.54311239 7.11924649,9.59882685 6.97973884,9.59882685 C6.84023118,9.59882685 6.70650066,9.54311239 6.60825913,9.44406195 C5.42978165,8.30501542 5.34263268,6.44507289 6.40943901,5.20082194 L8.95224375,2.54814292 C10.0457544,1.40754326 11.903153,1.50172121 13.0856095,2.75742726 C14.2304876,3.89485029 14.3101711,5.72074247 13.2687333,6.9535783 L12.3531143,7.91628626 Z M3.64688571,8.08371374 C3.85054851,7.90055492 4.16100389,7.90547347 4.35876236,8.094992 C4.55652083,8.28451054 4.57463981,8.59447576 4.40030933,8.80574471 L3.48992245,9.75798846 C2.81025588,10.5983008 2.89712713,11.8213565 3.68874258,12.5571665 C4.47879096,13.3786076 5.64555116,13.4518571 6.30479684,12.7612188 L8.84760158,10.0928434 C9.52726815,9.25253112 9.4403969,8.02947537 8.64878146,7.29366535 C8.44592026,7.08959352 8.44592026,6.76000988 8.64878146,6.55593805 C8.74702298,6.45688761 8.88075351,6.40117315 9.02026116,6.40117315 C9.15976882,6.40117315 9.29349934,6.45688761 9.39174087,6.55593805 C10.5702183,7.69498458 10.6573673,9.55492711 9.59056099,10.7991781 L7.04775625,13.4518571 C5.95424557,14.5924567 4.09684704,14.4982788 2.91439051,13.2425727 C1.76951242,12.1051497 1.68982885,10.2792575 2.73126672,9.0464217 L3.64688571,8.08371374 Z" id="LinkIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Link">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="LinkIcon-mask-2" fill="white">
                            <use href="#LinkIcon-path-1"></use>
                        </mask>
                        <use id="Combined-Shape" fill={color} href="#LinkIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
