

import React, { useEffect, useRef, useState } from 'react'

import PersonaGrid, { personaFieldPaths } from './PersonaGrid';

import AddPersonaForm from './AddPersonaForm';

import Modal from 'bwax-ui/components/Modal'
import Button, { Pressable } from "bwax-ui/components/Button"

import { useTrack } from 'bwax-ui/track';

export default function PersonaManagement({ onSelect, facade, currentUserId, viewEnv, onPersonaPin }) {

    const track = useTrack();

    const personaAddedRef = useRef();

    const [addDialogOpen, setAddDialogOpen] = useState(false);

    const [personaCount, setPersonaCount] = useState();
    const [hasPinned, setHasPinned] = useState(true)

    const [templates, setTemplates] = useState([]);
    const loadTemplates = async () => {
        const [result, error] = await facade.list({
            entityName: "会话角色模板",
            fieldPaths: ["名称", "提示", "内容生成选项", "欢迎消息", "用户选项", "chatModel", "头像"],
            sort: [{ field: "显示权重", order: "DESC" }, { field: "创建时间", order: "DESC" }],
        });
        if (!error) {
            setTemplates(result);
        }
    }

    useEffect(() => {
        loadTemplates();
    }, []);

    function renderPersonaList() {
        return (
            <PersonaGrid {...{
                onSelect: item => {
                    track("persona_select_to_chat");
                    // updateSelectedPersona(item)
                    viewEnv.routeTo("/chat?pid=" + item.id)

                },
                facade,
                bindPersonaAdded: personaAdded => personaAddedRef.current = personaAdded,
                currentUserId,
                onPin: onPersonaPin,
                onPersonaListUpdate: l => {
                    setPersonaCount(l.length);
                    setHasPinned(l.some(p => p.置顶))
                }
            }} />
        )
    }

    function renderAddPersonaDialog() {
        return (
            <Modal isOpen={addDialogOpen} className="max-w-4xl" onOpenChange={open => {
                if (!open) {
                    // 
                }
                setAddDialogOpen(open)
            }}>
                {closeModal => {
                    return (
                        <AddPersonaForm {...{
                            facade, templates, currentUserId, fieldPaths: personaFieldPaths,
                            onAdded: r => {
                                if (personaAddedRef.current) {
                                    personaAddedRef.current(r)
                                }
                                setAddDialogOpen(false);
                            }
                        }} />
                    )
                }}
            </Modal>
        )
    }

    return (
        <div className="px-8 py-8 flex flex-col gap-4 h-full overflow-auto">
            <Button className="self-start" onPress={_ => {
                track("persona_start_to_create")
                setAddDialogOpen(true)
            }} size="large">
                点击创建角色
            </Button>
            { personaCount ? 
                <div className="flex flex-col gap-1.5">
                    <div className="px-1 text-[var(--gray11)]">选择助手扮演的角色</div>
                    { hasPinned ? null :
                        <div className="px-1 text-[var(--gray11)] font-size-12">
                            * 置顶角色，可以把它固定在对话列表上方，以便快速选择 👉
                        </div>
                    }   
                </div>
            : null }
            {renderPersonaList()}
            {renderAddPersonaDialog()}
        </div>
    )
}
