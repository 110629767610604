
// This file is generated. Don't modify it.
import React from 'react'
export default function BrowserIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <g id="Style" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Iconology" transform="translate(-264.000000, -252.000000)" fillRule="nonzero">
                    <g id="Icon/Social" transform="translate(48.000000, 252.000000)">
                        <g id="Icon/Browser" transform="translate(216.000000, 0.000000)">
                            <g id="Group-3">
                                <rect id="16px-Bounding-Box" stroke="none" fill="none" x="0.5" y="0.5" width="15" height="15"></rect>
                                <g id="Group-8" transform="translate(2.000000, 2.000000)">
                                    <circle id="Oval" fill={"#1584EF"} cx="6" cy="6" r="6"></circle>
                                    <path d="M1.15075804,5.28712871 C1.15075804,7.10891089 1.51964728,8.35838292 2.25742574,9.0355448 C2.37692069,9.14522193 2.37692069,8.32682973 2.25742574,6.58036819 L1.15075804,5.28712871 Z" id="Path-2" fill="#FFFFFF"></path>
                                    <path d="M4.51253094,1.51218286 C5.61749691,1.17732828 6.59668936,1.12364635 7.45010829,1.35113707 C8.25243657,1.72277228 7.13613861,2.89470874 8.53855972,3.06235209 C10.2325572,3.25131498 10.4721535,3.84920328 10.4721535,4.0990099 C10.4721535,4.34881652 8.73893874,4.15412283 8.32715811,4.25146968 C7.91537748,4.34881652 7.13613861,5.3563549 7.13613861,5.64356436 C7.13613861,5.93077382 7.13613861,6.71287129 7.13613861,7.06930693 C7.13613861,7.42574257 8.69078744,8.65770445 9.00498917,8.85148515 C9.21445699,8.98067228 9.21445699,9.33710792 9.00498917,9.92079208 C8.04784189,10.4752475 7.19678218,10.7524752 6.45181002,10.7524752 C5.70683787,10.7524752 4.85929765,10.5940594 3.90918936,10.2772277 C5.0063815,8.31818533 5.59935798,7.2488784 5.68811881,7.06930693 C5.82126006,6.79994972 6.15628868,6.38225634 5.68811881,6.0961989 C5.21994895,5.81014146 4.26574103,6.11778017 3.90918936,5.81014146 C3.67148824,5.605049 3.3917466,5.19308765 3.06996442,4.57425743 C4.13826578,3.62376238 4.78055384,3.02970297 4.99682859,2.79207921 C5.21310334,2.55445545 5.05167079,2.12782333 4.51253094,1.51218286 Z" id="Path-3" fill="#FFFFFF"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
