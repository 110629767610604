import 'core-js/es'
import 'regenerator-runtime/runtime'

import "isomorphic-fetch";

import Cookies from 'js-cookie'

import { DataLoaderProvider } from 'bwax-ui/store/DataLoaderContext';

import React from 'react'
// import ReactDOM from 'react-dom'
import { createRoot, hydrateRoot } from 'react-dom/client';

import ClientApp from './ClientApp'

// import TestEnv from './TestEnv';

import { isMobile, isWeChat, isWxWork, isIOS } from 'bwax/clientEnv'

import { HelmetProvider } from 'react-helmet-async';

import { BrowserRouter } from 'react-router-dom'
import { DataPromiseProvider } from 'bwax-ui/store/RenderWithData'

import getTokenCookieName from 'bwax/query/getTokenCookieName';

import queryString from 'query-string'

const appData = document.data || {}

const pathname = window.location.pathname;
const isSandBox = pathname.startsWith('/sandbox');


const [ tenantCode, tenantCodeMode ] = (() => {
    // 可能是根据域名，也可能是根据 pathname，
    // 如果要根据域名的话，可能要在服务器端就确定好：

    // 这里根据 pathname 来
    const pattern = /^(\/sandbox)?\/-\/([^\/]+)/;
    const matched = pathname.match(pattern);
    if(matched && matched[2]) {
        return [ matched[2].toLowerCase(), "path"];
    }

    // 也有可能是根据参数 _tenant_ = xxx
    const params = queryString.parse(window.location.search);

    if(params._tenant_) {
        return [params._tenant_, "param"]
    }
    return []
})();


// 
function updateTheme(theme) {
    console.log(">>> ", document.body.classList )
    if(theme == "light") {        
        document.body.classList.remove("dark-theme");
    } else if(theme == "dark") {
        if(!document.body.classList.contains("dark-theme")) {
            document.body.classList.add("dark-theme");
        }
    } else if(theme == "system") {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            // dark mode
            updateTheme("dark")
        } else {
            updateTheme("light")
        }
    }
}

const storedTheme = Cookies.get("--lc-theme");
console.log(">>> stored theme", storedTheme);
if(storedTheme) {
    updateTheme(storedTheme)
}
document.updateTheme = updateTheme;


// const TargetApp = module.hot ? HotClient : ClientApp
const TargetApp = ClientApp;
// const TargetApp = TestEnv;

// / only used for DEV
// / TODO: need better solution
const isDev = Object.keys(document.userenv || {}).length === 0;
if (isDev) {
    console.log("No userenv yet, add testing mobileHosts, and others")

    const { protocol, host, pathname, search, hostname } = document.location

    document.userenv = {
        mobileHosts: [
            // "dev.cb.tikicrowd.com"
            // "dt.leancode.cn"
            "simplifyai.cn",

            // "translate.simplifyai.cn",
        ],

        // test cb
        // mobileHosts: ["cb.qunfengshe.com", "pinpin.qunfengshe.com"],

        // dataEndpoint: 'http://backend.simplifyai.cn/app/bwax-sandbox/graphql',

        isMobile: isMobile(),
        isWeChat: isWeChat(),
        isWxWork: isWxWork(),
        isIOS: isIOS(),
        currentUrl: document.location.href,

        deviceId: "frontend-test",

        userAgent: window.navigator.userAgent,

        domainKey: "dev", // 在 dev 状态下这个无所谓

        protocol,
        host,

        hostname,
        // hostname: "family.qunfengshe.com",

        // test

        originalURL: isSandBox ? pathname.replace("/sandbox", "") + search : pathname + search
    }
}

const sessionTokenName = getTokenCookieName({ tenantCode, sandbox: isSandBox })
const sessionToken = Cookies.get(sessionTokenName);

const basename = (isSandBox ? "/sandbox" : "") + (tenantCode && tenantCodeMode == "path" ? `/-/${tenantCode}` : "");

const targetApp = <TargetApp />;

const promiseHolder = {};

const app = (
    <HelmetProvider>
        <BrowserRouter {...(basename ? { basename } : {})}>
            <DataPromiseProvider holder={promiseHolder}>
                <DataLoaderProvider
                    appData={appData}
                    sandbox={isSandBox}
                    tenantCode={tenantCode}
                    tenantCodeMode={tenantCodeMode}
                    userenv={document.userenv}
                    sessionToken={sessionToken}
                    deviceId={document.userenv.deviceId}
                    domainKey={document.userenv.domainKey}
                >
                    {targetApp}
                </DataLoaderProvider>
            </DataPromiseProvider>
        </BrowserRouter>
    </HelmetProvider>
)

// TODO 如果是 dev，那么就直接 render，而不是 hydrate

const rootElement = document.getElementById("app");

if (isDev) {
    console.log("Dev, don't hydrate")
    const root = createRoot(rootElement);
    root.render(app);
} else {

    hydrateRoot(rootElement, app);

    // console.log("Hydrate the app")
    // root.render(app);
    // console.log("Render App");
    // ReactDOM.renner(app. document.getElementById('app'));
}