
import React, { useEffect, useRef, useState } from 'react'

// import type {MenuTriggerProps} from 'react-stately';
import { useMenuTrigger, useMenu, useMenuItem } from 'react-aria';
import { Item, useMenuTriggerState, useTreeState } from 'react-stately';

// Reuse the Popover, and Button from your component library. See below for details.
import { Popover } from 'bwax-ui/components/PopoverTrigger';
import { Pressable } from 'bwax-ui/components/Button';

import './DropdownMenu.less';
import classNames from 'classnames';

// interface MenuButtonProps<T> extends AriaMenuProps<T>, MenuTriggerProps {
//   label?: string;
// }

/**

'bottom'
  | 'bottom left'
  | 'bottom right'
  | 'bottom start'
  | 'bottom end'
  | 'top'
  | 'top left'
  | 'top right'
  | 'top start'
  | 'top end'
  | 'left'
  | 'left top'
  | 'left bottom'
  | 'start'
  | 'start top'
  | 'start bottom'
  | 'right'
  | 'right top'
  | 'right bottom'
  | 'end'
  | 'end top'
  | 'end bottom'

 */

export default function DropdownMenu({ items: givenItems, className, children, placement = "bottom start", itemClassName }) {

    const items = givenItems.filter(item => !!item);

    return (
        <DropdownMenuTrigger trigger={children} disabledKeys={[1]} placement={placement} className={className} onAction={index => {
            const item = items[index];
            if (item && item.onSelect) {
                item.onSelect();
            }
        }}>
            {items.map((item, index) => {
                const { label, icon, isDisabled, onSelect, subItems, hasUpdates } = item;

                return (
                    <Item key={index} isDisabled={isDisabled} onSelect={onSelect} subItems={subItems} textValue={label} className={itemClassName}>
                        {icon ? (
                            <div className={classNames("flex w-6 justify-center relative")}>
                                {icon}
                                {hasUpdates ?
                                    <div className="absolute rounded-[50%] bg-[var(--tomato9)] h-1.5 w-1.5 right-[-2px] top-[-1px]" /> : null
                                }
                            </div>
                        ) : null}
                        {label}
                    </Item>
                )
            })}
        </DropdownMenuTrigger>
    )
}

export function DropdownMenuTrigger(props) {
    // Create state based on the incoming props

    const {
        trigger, placement,
        isOpen, defaultOpen, onOpenChange,

        className,
    } = props;

    const state = useMenuTriggerState({ isOpen, defaultOpen, onOpenChange });

    // Get props for the button and menu elements
    const ref = useRef(null);
    const { menuTriggerProps, menuProps } = useMenuTrigger({}, state, ref);

    return (
        <>
            <Pressable
                {...menuTriggerProps}
                ref={ref}
                style={{ height: 30, fontSize: 14 }}
            >
                {trigger}
            </Pressable>
            {state.isOpen ?
                (
                    <Popover state={state} triggerRef={ref} placement={placement} withArrow={false}>
                        <Menu
                            {...props}
                            {...menuProps}
                            className={className}
                            close={state.close}
                        />
                    </Popover>
                ) : null
            }
        </>
    );
}



function Menu({ className = "font-size-13", sub = false, onFocusChange = _ => { }, close, ...props }) {
    // Create menu state based on the incoming props
    const state = useTreeState(props);

    // Get props for the menu element
    const ref = React.useRef(null);
    const { menuProps } = useMenu(props, state, ref);

    const [focusState, setFocusState] = useState({});

    const checkFocus = focusState => Object.keys(focusState).some(k => focusState[k]);

    const focusStateRef = useRef(focusState);
    focusStateRef.current = focusState;

    const onItemFocusChange = (key, isFocused) => {
        const isFocusedBefore = checkFocus(focusStateRef.current);

        const newFocusState = {
            ...focusStateRef.current,
            [key]: isFocused,
        }

        const isFocusedNow = checkFocus(newFocusState);

        focusStateRef.current = newFocusState;
        // if (isFocusedNow !== isFocusedBefore) {

        // }
        onFocusChange(isFocusedNow)
        setFocusState(newFocusState)
    };

    return (
        <ul
            {...menuProps}
            ref={ref}
            className={classNames(className, "lc-dropdown-menu")}
            style={{
            }}
        >
            {[...state.collection].map((item) => (
                item.type === 'section'
                    ? <MenuSection key={item.key} section={item} state={state} />
                    : <MenuItem key={item.key} item={item} state={state} onFocusChange={onItemFocusChange} close={close} />
            ))}
        </ul>
    );
}


function MenuItem({ item, state, onFocusChange = _ => { }, close }) {
    // Get props for the menu item element
    let ref = React.useRef(null);
    const allMenuItemProps = useMenuItem(
        {
            key: item.key,
            ...item.props,
        },
        state,
        ref
    );

    const { menuItemProps, isFocused, isSelected, isDisabled } = allMenuItemProps;
    const { subItems } = item.props;

    useEffect(() => {
        onFocusChange(item.key, isFocused);

        if (subItems && isFocused) {
            setShouldSubMenuOpen(true);
        }
    }, [isFocused]);

    const [isSubMenuFocused, setIsMenuFocused] = useState(false);

    const [shouldSubMenuOpen, setShouldSubMenuOpen] = useState(false);

    function onSubMenuFocusChange(isSubMenuFocused) {
        setIsMenuFocused(isSubMenuFocused);
    }

    const { onClick, onPointerUp, ...otherMenuItemProps } = menuItemProps;

    return (
        <>
            <li
                {...otherMenuItemProps}
                data-focused={isFocused || (isSubMenuFocused && shouldSubMenuOpen)}
                data-disabled={isDisabled}
                data-selected={isSelected}
                ref={ref}
                className={classNames("justify-between cursor-pointer", item.props.className)}
                onClick={e => {
                    if (subItems) {
                        // do nothing
                    } else {
                        onClick(e);
                    }

                }}
                onPointerUp={e => {
                    if (subItems) {
                        // do nothing
                    } else {
                        onPointerUp(e);
                    }
                }}
            >
                <div className="flex gap-2 items-center">
                    {item.rendered}
                </div>
                {subItems ?
                    <i className='bx bx-chevron-right'></i> : null
                }
            </li>
            {/* {} */}
            {subItems ?
                <SubMenu
                    isOpen={(isFocused || isSubMenuFocused) && shouldSubMenuOpen} triggerRef={ref} items={subItems} onFocusChange={onSubMenuFocusChange}
                    onOpenChange={open => {
                        if (!open) {
                            setShouldSubMenuOpen(false);
                        }

                    }}
                    close={_ => {
                        close();
                    }}
                /> : null
            }
        </>
    );
}

function SubMenu(props) {

    const {
        items, onFocusChange, close,
        isOpen, defaultOpen, onOpenChange, triggerRef,
    } = props;

    const state = useMenuTriggerState({ isOpen, defaultOpen, onOpenChange });

    return (
        state.isOpen ?
            <Popover state={state} triggerRef={triggerRef} placement={"end"} withArrow={false}>
                <Menu aria-label={"Sub Menu"} onFocusChange={onFocusChange} sub={true} onAction={index => {
                    const item = items[index];
                    if (item && item.onSelect) {
                        item.onSelect();
                        close();
                    }
                }}>
                    {items.map((item, index) => {
                        const { label, icon, isDisabled, onSelect, subItems } = item;
                        return (
                            <Item key={index}
                                isDisabled={isDisabled} onSelect={onSelect} subItems={subItems} textValue={label}>{icon} {label}</Item>
                        )
                    })}
                </Menu>
            </Popover>
            : null
    )
}