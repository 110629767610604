

import React from 'react'

import { CheckIcon } from '@radix-ui/react-icons';

import * as Checkbox from '@radix-ui/react-checkbox';
import classNames from 'classnames';

import './Checkbox.less'

export default function Checkbox_comp({ checked, onChange, color, isDisabled }) {
    return (
        <Checkbox.Root className={classNames("lc-checkbox", { "cursor-not-allowed opacity-60": isDisabled })} checked={checked} onCheckedChange={onChange} disabled={isDisabled} data-color={color}>
            <Checkbox.Indicator className="CheckboxIndicator">
                <CheckIcon />
            </Checkbox.Indicator>
        </Checkbox.Root>
    )
}
