
export function identity (v) { return !!v }


export function pickBy (target, predicate) {

  function resolveF(f) {
    if(!(Array.isArray(f) || typeof(f) === 'function')) {
      throw new Error("pickBy f should be either Array or function")
    }
    return (...args) => {
      if(Array.isArray(f)){
        return f.indexOf(args[0]) !== -1
      } else if(typeof(f) === 'function' ) {
        return f(...args)
      }
    }
  }

  const f = resolveF(predicate)

  if(Array.isArray(target)) {
    return Object.keys(target).reduce((acc, current) => {
      if(f(current)) {
        return [...acc, current]
      } else {
        return acc
      }
    }, {})

  } else if(typeof(target) === 'object') {

    return Object.keys(target).reduce((acc, current) => {
      if(f(current, target[current])) {
        return {...acc, [current]: target[current]}
      } else {
        return acc
      }
    }, {})
  } else {
    return target
  }
}


export function stuffIn(array, value, index) {
  return [
    ...array.slice(0, index),
    value,
    ...array.slice(index)
  ]
}

export function moveTo(array, value, fromIndex, toIndex) {
  const sliceOut = [
    ...array.slice(0, fromIndex),
    ...array.slice(fromIndex + 1)
  ]
  const adjustedToIndex = fromIndex >= toIndex ? toIndex : toIndex - 1
  return [
    ...sliceOut.slice(0, adjustedToIndex),
    value,
    ...sliceOut.slice(adjustedToIndex)

  ]
}

export function drop(array, index) {
  return [
    ...array.slice(0, index),
    ...array.slice(index + 1)
  ]
}

export function replace(array, value, index) {
  return [
    ...array.slice(0, index),
    value,
    ...array.slice(index + 1)
  ]
}


// this function flattened the multi-demension array to single-demension array
export function flattenArray(array) {
  return array.reduce((acc, current) => {
    if(Array.isArray(current)) {
      return [
        ...acc, ...flattenArray(current)
      ]
    } else {
      return [
        ...acc, current
      ]
    }
  }, [])
}

/// --- TEST
// console.log(flattenArray([2,3,1,[2,33,1],[[13,12,11],[23,22]], 100]))


export function mapObj(obj, f) {
  return Object.keys(obj).reduce((acc, k) => {
    return {
      ...acc,
      [k]: f(obj[k], k)
    }
  }, {})
}


// --- Test
// console.log(mapObj({a: 1, b:2}, (v, k) => v * 2))


export function uniq(a) {
  var prims = {"boolean":{}, "number":{}, "string":{}}, objs = [];

  return a.filter((item) => {
    var type = typeof item;
    if(type in prims)
        return prims[type].hasOwnProperty(item) ? false : (prims[type][item] = true);
    else
        return objs.indexOf(item) >= 0 ? false : objs.push(item);
  })
}

///--- Test
// console.log(uniq([1,2,4,3,2,3,1,8,3]))

export function equalSet(a, b, equals) {
  if(!a && !b) return true
  if(!a && b) return false
  if(a && !b) return false

  const equalsF = equals ? equals : (a, b) => a === b

  return (
    a.every(x => b.some(y => equalsF(x, y))) &&
    b.every(y => a.some(x => equalsF(y, x)))
  )
}

/// TEST
// console.log(equalSet([1,2,3], [3,2,1]))
// console.log(equalSet([1,2,3,4], [3,2,1]))
// console.log(equalSet([1,2,3], [3,2,1,5]))

export function range(start, end) {
  let l = []
  for(let i = start; i < end; i++) {
    //console.log("Push ", i, " to array", l)
    l = [...l, i]
  }
  return l
}
// Test
// console.log(range(1, 20))
