
// This file is generated. Don't modify it.
import React from 'react'
export default function CheckboxNormalIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M3,3 L3,13 L13,13 L13,3 L3,3 Z M2,2 L14,2 L14,14 L2,14 L2,2 Z" id="CheckboxNormalIcon-path-1"></path>
            </defs>
            <g id="Style" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Iconology" transform="translate(-48.000000, -348.000000)">
                    <g id="Icon/Checkbox" transform="translate(48.000000, 348.000000)">
                        <g id="Icon/Checkbox/Normal">
                            <g id="Group-3">
                                <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                                <mask id="CheckboxNormalIcon-mask-2" fill="white">
                                    <use href="#CheckboxNormalIcon-path-1"></use>
                                </mask>
                                <use id="Mask" fill={color} fillRule="nonzero" href="#CheckboxNormalIcon-path-1"></use>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
