
// This file is generated. Don't modify it.
import React from 'react'
export default function ChangeIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Change" fillRule="nonzero">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" x="0.5" y="0.5" width="15" height="15"></rect>
                        <path d="M9.06951618,4.54 L9.06951618,11.86 C9.06951618,11.884 9.07331552,11.908 9.07711487,11.932 C9.09231226,11.996 9.12270704,12.052 9.16449985,12.096 C9.26708221,12.204 9.42665479,12.224 9.55203324,12.144 C9.58622736,12.124 9.61282279,12.1 9.63561888,12.068 L11.1059662,10.528 C11.2313446,10.4 11.2313446,10.188 11.1059662,10.056 C10.9805877,9.92399999 10.7792223,9.928 10.6576432,10.056 L9.70780647,11.052 L9.70780647,4.54 C9.70780647,4.356 9.56343128,4.208 9.38866132,4.208 C9.21389137,4.208 9.06951618,4.356 9.06951618,4.54 Z M6.93428317,11.86 L6.93428317,4.54 C6.93428317,4.516 6.93048382,4.492 6.92668448,4.468 L6.92668448,4.472 C6.91528644,4.404 6.88489166,4.34 6.8392995,4.296 C6.71392105,4.168 6.516355,4.168 6.39097655,4.296 L4.89403384,5.864 C4.76865539,5.992 4.76865539,6.204 4.89403384,6.336 C5.01941229,6.464 5.22077768,6.464 5.34235678,6.336 L6.29219353,5.34 L6.29219353,11.868 C6.29219353,12.052 6.43656871,12.2 6.61133868,12.2 C6.79370733,12.192 6.93428317,12.044 6.93428317,11.86 Z" id="Shape" fill={color} transform="translate(8.000000, 8.200000) rotate(-270.000000) translate(-8.000000, -8.200000) "></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}
