
import React, { useEffect, useRef, useState } from 'react'

import { getFileIcon } from "bwax-ui/components/FileIcon";

import classNames from 'classnames';
import ClampedText from 'bwax-ui/components/ClampedText';

import getFieldUsedValues from 'bwax/query/getFieldUsedValues';
import TextInput from 'bwax-ui/components/inputs/TextInput';
import Button, { Pressable } from 'bwax-ui/components/Button';

export default function TranslationAdminTags({ task, facade, getNickNameAndAvatar, onChange }) {

    const fileIcon = getFileIcon(task.文档);
    const { nickName, avatar } = getNickNameAndAvatar(task.创建者);

    const entityName = "文档翻译-任务"

    const [usedTags, setUsedTags] = useState([]);

    useEffect(() => {
        (async () => {
            const values = await getFieldUsedValues({ entityName, fieldName: "后台标签" })(facade.dlc);
            if (values) {
                setUsedTags(values.map(v => v.value));
            }
        })();
    }, []);


    const existingTags = task.后台标签 || [];


    function renderExistingTags() {
        if (existingTags.length > 0) {
            return (
                <div className="flex flex-wrap gap-2 px-1">
                    {task.后台标签.map(label => {
                        return (
                            <div key={label} className={classNames("bg-[var(--color-3)] pl-3 pr-1 py-0.5 rounded text-[var(--color-11)] font-size-14 flex gap-1 items-center", {
                                // "cursor-pointer hover:bg-[var(--color-4)]": !!onPress,
                            })} data-color={"gray"}>
                                {label}
                                <Pressable onPress={_ => {
                                    submitTags(existingTags.filter(t => t !== label))
                                }}>
                                    <div className="w-6 h-6 flex justify-center items-center hover:bg-[var(--color-4)] cursor-pointer">
                                        <i className='bx bx-x'></i>
                                    </div>
                                </Pressable>
                            </div>
                        )
                    })}
                </div>
            )
        }
        return null
    };

    const [editing, setEditing] = useState("");

    const [isSubmiting, setIsSubmiting] = useState(false);
    async function submitTags(tags) {
        setIsSubmiting(true);
        const [result, error] = await facade.update({
            entityName: "文档翻译-任务", id: task.id,
            formData: { "后台标签": tags },
            fieldPaths: ["后台标签"]
        })
        setIsSubmiting(false);
        if (!error && task) {
            setEditing("")
            onChange(result.后台标签)
        }
    };

    const inputRef = useRef();

    function renderAddTag() {

        const candidates = usedTags.filter(t => existingTags.indexOf(t) == -1);

        return (
            <div className="flex flex-col gap-3">
                <div className="flex gap-2 items-center">
                    <TextInput className="!py-1" value={editing} color="violet" onChange={setEditing} styled={true} placeholder="输入标签" ref={inputRef} />
                    <Button isDisabled={!editing} onPress={_ => setEditing("")}><i className='bx bx-x'></i></Button>
                    <Button isLoading={isSubmiting} isDisabled={!editing || !editing.trim() || existingTags.indexOf(editing.trim()) == 0} color={"violet"} onPress={_ => {
                        // update tags
                        submitTags([...existingTags, editing.trim()])

                    }}><i className='bx bx-check'></i></Button>
                </div>
                {
                    candidates.length > 0 ? (
                        <div className="flex gap-2 flex-col p-2">
                            <div className="text-[var(--gray9)] font-size-12">
                                您可以在下面选择标签
                            </div>
                            <div className="flex flex-wrap gap-2">
                                {candidates.map(t => {
                                    return (
                                        <Pressable key={t} onPress={_ => {
                                            setEditing(t);
                                            inputRef.current && inputRef.current.focus();
                                        }}>
                                            <div className="rounded border-[1px] border-[var(--gray6)] px-2 py-0.5 text-[var(--gray11)] cursor-pointer hover:bg-[var(--gray2)]">
                                                {t}
                                            </div>
                                        </Pressable>
                                    )
                                })}
                            </div>
                        </div>
                    ) : null
                }

            </div>
        )
    }




    return (
        <div className="flex flex-col gap-6 px-6 pb-8 pt-2">
            <div className="flex gap-2 justify-between w-full">
                <div className="flex gap-1 items-center">
                    <div className="font-size-16 mr-1 ">
                        {fileIcon}
                    </div>
                    <ClampedText tipEnabled={true}>{task.文档.title}</ClampedText>
                </div>
                <div className="flex items-center gap-2 px-2 py-1 rounded">
                    {task.创建者.渠道 ? (
                        <div className="whitespace-pre text-[var(--lc-color-bg-1)] font-size-11 px-1 bg-[var(--indigo9)] opacity-90 rounded">
                            {task.创建者.渠道}
                        </div>
                    ) : null}
                    <ClampedText className="font-size-12 text-[var(--gray11)] whitespace-nowrap max-w-[5rem] flex items-center" tipEnabled={false}>{nickName}</ClampedText>
                    {avatar}
                </div>
            </div>
            {renderExistingTags()}
            {renderAddTag()}
        </div>
    )
}



