
import React, { useState } from 'react'
import { ReactEditor } from 'slate-react'
import { isIOS } from 'bwax/clientEnv'
import { AiOutlinePlus  } from 'react-icons/ai'
import { basicBlockTypes, getAtomicBlockTypes, addBlockBelow } from '../BrickToolbar/BrickToolbar'

export default function PlusButton (props) {

    const { editor } = props

    const [blocksListOpen, setBlocksListOpen] = useState(false)
    const [addBlockModalOpen, setAddBlockModalOpen] = useState(false)
    const [addingBlockType, setAddingBlockType] = useState(null)
    const [modalCanBeCanceledOutside, setModalCanBeCanceledOutside] = useState(true) // 上传中不允许点击外部隐藏 modal

    function changeMobileToolbarZIndex () {
        if(typeof(document) !== 'undefined') {
            const mobileToolbar = document.getElementsByClassName('mobile-toolbar-container')[0]
            // console.log("mobileToolbar: ", mobileToolbar);
            if(mobileToolbar.style['zIndex']) {
                mobileToolbar.style['zIndex'] = ''
            } else {
                mobileToolbar.style['zIndex'] = 1
            }
        }
    }

    function showBlocksList () {
        // 隐藏键盘，弹出 block list
        ReactEditor.blur(editor)
        setBlocksListOpen(true)
        changeMobileToolbarZIndex()
    }


    const slatePath = editor.selection.focus.path

    function hideModal () {
        setAddBlockModalOpen(false)
        setAddingBlockType(null)
    }

    const blockTypesProps = {...props, slatePath, setModalCanBeCanceledOutside, hideModal }

    function renderBlocksList () {

        return [...basicBlockTypes, ...getAtomicBlockTypes(blockTypesProps)].map((blockObj, index) => {
            const { subIcon, subLabel, type } = blockObj
            return (
                <div key={index} className='upward-menu-item' 
                    onClick={() => {
                        setBlocksListOpen(false)
                        addBlockBelow({ 
                            editor, slatePath, blockObj, 
                            setAddBlockModalOpen, setAddingBlockType
                        })
                        changeMobileToolbarZIndex()
                    }}
                >
                    <div className="icon" style={ typeof(subIcon) === 'string' ? { fontSize: 12 } : {}}>
                        {subIcon || null}
                    </div>
                    <div>{subLabel}</div>
                </div>
            )
        })
    }

    function renderAddingBlock () {

        const addingBlock = getAtomicBlockTypes(blockTypesProps).find(blockObj => blockObj.type === addingBlockType)
        const { renderContent } = addingBlock

        function changeModalOpen (open) {
            if(modalCanBeCanceledOutside && !open || open) {
                // hide || show
                setAddBlockModalOpen(open)
                if(modalCanBeCanceledOutside && !open) {
                    setAddingBlockType(null)
                }
            }
        }
        
        return renderContent(addBlockModalOpen, changeModalOpen)

    }

    return (
        <>
            <button className={`editor-button`} onClick={() => showBlocksList()}>
                <AiOutlinePlus style={{ fontSize: 14 }} />
            </button>
            {
                blocksListOpen ? (
                    <div className={`editor-mobile-upward-menu ${blocksListOpen ? 'show' : ''}`} 
                        style={isIOS() ? { marginTop: 48 } : {}}
                    >
                        <div className='header'>
                            <div style={{ color: "var(--blue9)" }}  onClick={() => {
                                setBlocksListOpen(false)
                                changeMobileToolbarZIndex()
                            }}>Cancel</div>
                        </div>
                        <div className='items-container'>
                            {renderBlocksList()}
                        </div>
                    </div>
                ) : null
            }
            { addingBlockType ? renderAddingBlock() : null }
        </>
       
    )

}
