import React from 'react'

class Discussion extends React.Component {

    render() {
        return (
            <svg width="56px" height="56px" viewBox="0 0 56 56">
                <defs>
                    <linearGradient x1="100%" y1="100%" x2="0%" y2="0%" id="linearGradient-1">
                        <stop stopColor="#3C3D3D" offset="0%"></stop>
                        <stop stopColor="#141414" offset="100%"></stop>
                    </linearGradient>
                    <linearGradient x1="25.5639963%" y1="-14.6128221%" x2="75.3584863%" y2="100%" id="linearGradient-2">
                        <stop stopColor="#FFE9C9" offset="0%"></stop>
                        <stop stopColor="#FFCF99" offset="100%"></stop>
                    </linearGradient>
                </defs>
                <g id="会员体系" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="会员套餐" transform="translate(-70.000000, -632.000000)" fillRule="nonzero">
                        <g id="会员特权" transform="translate(0.000000, 556.000000)">
                            <g id="群蜂拆解" transform="translate(16.000000, 64.000000)">
                                <g id="icon1" transform="translate(54.000000, 12.000000)">
                                    <circle id="Oval" fill="url(#linearGradient-1)" cx="28" cy="28" r="28"></circle>
                                    <path d="M40.1146312,16.921552 L40.1146312,31.3822115 L27.3636364,38.6125412 L14.6126415,31.3822115 L14.6126415,16.921552 L27.3636364,9.69122222 L40.1146312,16.921552 Z M18.8283951,19.3120548 L27.3636364,14.4722278 L35.8988776,19.3120548 L35.8988776,28.9917087 L27.3636364,33.8315357 L18.8283951,28.9917087 L18.8283951,19.3120548 Z M37.1279864,18.6151004 L27.3636364,13.078319 L17.5992863,18.6151004 L17.5992863,29.6886631 L27.3636364,35.2254444 L37.1279864,29.6886631 L37.1279864,18.6151004 Z M30.4436936,36.0336632 L35.670322,33.0160679 L41.5977413,43.2826594 L40.1961416,46.2705456 L36.371113,46.3002547 L30.4436936,36.0336632 Z M32.7393024,38.7212112 L33.3124147,39.6761777 L37.3827897,37.3475212 L36.8096774,36.3925547 L32.7393024,38.7212112 Z" id="Combined-Shape" fill="url(#linearGradient-2)"></path>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export default Discussion
