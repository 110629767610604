
import { wrapRK, wrapTenantCode, removeAbsolutePrefixIfPossible } from './Link.js'


// 这个应该跟 Link 整合到一起
export default function createRouteTo(history, externalLinkPrefixes = [], dlc) {

    const allPrefixes = [
        ...externalLinkPrefixes,
        "/-/",  // 特定指定了 tenant
        'http://',
        'https://',
        'weixin://'
    ]

    return (givenPath, replace) => {

        const path = removeAbsolutePrefixIfPossible(givenPath, dlc);

        const { sandbox } = dlc; 

        function samePath() {
            if (!path || !location || !location.pathname) {
                return false
            }
            // 现在数据都 Ready 了
            let pn = path.split("?")[0]
            let pathname = (!pn.startsWith('http') && sandbox) ? ('/sandbox' + pn) : pn;

            return pathname == location.pathname
        };

        if (path && allPrefixes.some(prefix => path.startsWith(prefix)) && !samePath()) {
            let processdPath = (sandbox && !path.startsWith('http') && !path.startsWith('weixin')) ? (
                /// in sandbox and a relative path
                '/sandbox' + path
            ) : path

            processdPath = wrapRK(processdPath);

            if (typeof (window) !== 'undefined') {
                if (replace) {
                    window.location.replace(processdPath);
                } else {
                    window.location.href = processdPath
                }

            } else {
                history.push(processdPath);
            }

        } else {

            const processedTenantCode = wrapTenantCode(path, dlc);

            if(replace) {
                history.replace(processedTenantCode)
            } else {
                history.push(processedTenantCode)
            }
        }
    }
}