
import React, { useEffect, useState, useRef, useContext } from "react";

import taskSettings, { renderStatus } from './taskSettings';

import ClampedText from 'bwax-ui/components/ClampedText';
import dayjs from 'dayjs';

import { ModalTrigger } from "bwax-ui/components/Modal";

import TaskJobDetail, { fieldPaths } from './TaskJobDetail'

import UsageQuotaContext from 'bwax-ui/ml/widget/ports/inbot/UsageQuotaContext';

import { addQueryParam } from 'bwax/ml/lang/mod/builtin/StringHelper';

import { useTrack } from 'bwax-ui/track';

import './TaskJobSummary.less';

export default function TaskJobSummary({ taskJob, facade, onJobRefreshed, viewEnv }) {

    const track = useTrack();

    const { routeTo } = viewEnv;

    const { reloadUsageQuota, remainingQuota } = useContext(UsageQuotaContext) || {};

    const setting = taskSettings.find(t => t.entityName == taskJob.类别);
    const { isLarge, component: Component, name: taskName, entityName,  } = setting;

    const [refreshedJob, setRefreshedJob] = useState(taskJob);

    async function refreshJob() {
        const [result, error] = await facade.findById(taskJob.id, { entityName: "OpenAI-任务", fieldPaths }, { forceRefreshing: true });
        if (!error && result) {
            setRefreshedJob(result)
        }
    }

    // const status = taskStatuses[refreshedJob.状态];

    useEffect(() => {
        if (refreshedJob.状态 == "Waiting" || refreshedJob.状态 == "Processing") {
            setTimeout(() => {
                refreshJob()
            }, 1000);
        }
        if (refreshedJob.状态 == "Completed" && reloadUsageQuota) {
            setTimeout(() => {
                reloadUsageQuota();
            }, 800)
        }
        onJobRefreshed && onJobRefreshed(refreshedJob);
    }, [refreshedJob]);

    function renderModal(closeModal) {

        if(Component) {
            return (
                <Component taskJob={refreshedJob} facade={facade} shouldRefreshAtFirst={true}
                    {...{ 
                        setRefreshedJob, 
                        entityName, fieldPaths: fieldPaths.map(p => "任务." + p),
                        closeModal,
                    }}
                />
            )
        }

        return (
            <TaskJobDetail taskJob={refreshedJob} facade={facade} shouldRefreshAtFirst={true}
                {...{ remainingQuota, reloadUsageQuota, setRefreshedJob, closeModal }}
            />
        )
    }

    const ref = useRef(null);
    useEffect(() => {
        if (ref.current) {
            // console.log(">> ref.cu", ref.current, ref.current.offsetParent);
        }
    }, [ref.current])


    return (
        <ModalTrigger content={close => renderModal(close)} 
            isLarge={isLarge} 
            modalClassName={isLarge ? "" : "max-w-3xl" }
            onOpenChange={open => {
                if(!open) {
                    routeTo("/task")
                }
            }}
            contentClassName="h-full"
            onBeforePress={_ => {
                track("task_view_job", { "task_category": refreshedJob.类别 })
                routeTo(addQueryParam("job", taskJob.id ,"/task/" + taskName))

            }}
        >
            <div className="lc-job-summary">
                <div className="icon">
                    {setting ? setting.icon : null}
                </div>
                <div className="job-info">
                    <ClampedText className="job-title" tipClassName="lc-inbot-tooltip" lines={2} tipEnabled={false}>
                        {refreshedJob.标题}
                    </ClampedText>
                    <div className="job-status-box font-size-12">
                        {renderStatus(refreshedJob.状态, refreshedJob.进度)}
                        {/* { renderStatus("Processing", 40)} */}
                        <div className="job-time font-size-10">
                            {dayjs(refreshedJob.创建时间).format("MM.DD")}
                        </div>
                    </div>
                </div>
            </div>
        </ModalTrigger>
    )
}