

import React, { useState, useRef, useEffect } from 'react'

import ScrollHelper from 'bwax-ui/ScrollHelper';
import TextInput from 'bwax-ui/components/inputs/TextInput';

import Button, { Pressable, iconDropdownMenu } from "bwax-ui/components/Button";
import { getFileIcon } from "bwax-ui/components/FileIcon";
import dayjs from 'dayjs';

import ClampedText from 'bwax-ui/components/ClampedText';
import classNames from 'classnames';

import TranslationTaskFlags from './TranslationTaskFlags';

import { useTrack } from 'bwax-ui/track';
import { toast } from 'bwax-ui/components/Toast';

import { createDialogConfirm } from 'bwax-ui/components/Dialog'

import { downloadURL } from 'bwax-ui/ml/FrontEndHelper';

const fieldPaths = [
    "文档", "源语言", "目标语言", "状态", "进度", "翻译后文档", "源语言词数", "价格", "已支付", "创建时间", "免费预览量", "模型", "分享",
    "已应用的翻译缓存修订",
    "背景修复", "翻译图片", "已应用模型", "术语表",
]

export default function TranslationList({ data, events, slots, facade, viewEnv }) {

    const track = useTrack();

    const { currentUserId } = data;
    const { routeTo } = viewEnv;

    const [tasks, setTasks] = useState();

    const [hasMore, setHasMore] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);

    const pageSize = 20;

    const listRef = useRef();

    const tasksRef = useRef();
    tasksRef.current = tasks;

    const [keyword, setKeyword] = useState("")

    const entityName = "文档翻译-任务";
    async function doLoadData(offset = 0) {

        setLoadingMore(true);
        const [result, error] = await facade.listAll({
            entityName, condition: [[
                { field: "创建者", op: "eq", value: currentUserId },
                { field: "已归档", op: "ne", value: true }
            ]], fieldPaths,
            sort: [
                { field: "创建时间", order: "DESC" }
            ],
            search: keyword ? {
                keyword,
                fields: [{ field: "文档.title", weight: 100 }]
            } : undefined,
            offset, pageSize

        })  // keyword, pageIndex / offset, ...
        setLoadingMore(false);

        if (error) {
            //  error handling TODO
        } else if (result) {
            const hasMore = result.pageSize + offset < result.count;
            setHasMore(hasMore);

            const existing = tasksRef.current || [];
            const toAppend = result.data.filter(s => !existing.some(e => e.id === s.id));
            const newData = [...existing, ...toAppend];

            setTasks(newData);
            tasksRef.current = newData;

            if (hasMore) {
                setTimeout(() => {
                    if (listRef.current) {
                        const { scrollHeight, clientHeight } = listRef.current;
                        if (scrollHeight <= clientHeight && hasMore) {
                            doLoadData(offset + pageSize);
                        }
                    }
                }, 300);
            }
        }
    }


    const loadMore = () => {
        if (hasMore) {
            doLoadData(tasks.length);
        }
    }

    const loadMoreRef = useRef();
    loadMoreRef.current = loadMore;

    useEffect(() => {
        if (listRef.current) {
            const scrollHelper = new ScrollHelper(listRef.current, {
                onScrolledToBottom: _ => {
                    // console.log("should load more", loadMoreRef);
                    loadMoreRef.current && loadMoreRef.current();
                }
            });
            return () => {
                scrollHelper.cleanUp();
            }
        }
    }, [listRef.current]);


    useEffect(() => {
        tasksRef.current = undefined;
        doLoadData();
    }, [])

    useEffect(() => {
        setTasks()
        tasksRef.current = undefined;
        doLoadData();
    }, [keyword])



    const displayLang = (fieldName, value) => {
        const entity = facade.entities.find(e => e.name == entityName);

        const field = entity && entity.fields.find(f => f.name == fieldName);
        if (field && field.options && field.options.options) {
            const o = field.options.options.find(o => o.value == value);
            return o ? o.name : value
        } else {
            return value
        }
    }


    function renderTranslationSummary(task) {
        return (
            <div className="text-[var(--gray11)] font-size-11">
                {displayLang("源语言", task.源语言)} <i className='bx bx-right-arrow-alt translate-y-[2px]'></i> {displayLang("目标语言", task.目标语言)}
            </div>
        )
    }

    async function archiveTask(task) {
        const [result, error] = await facade.update({ entityName, id: task.id, formData: { 已归档: true } });
        if (!error && result) {
            toast({ title: "已删除文档" });
            setTasks(prev => (prev.filter(p => p.id !== task.id)))
        }
    }

    function renderTask(task, index) {

        const pendingPayment = task && task.价格 > 0 && !task.已支付;

        function statusIn(...array) {
            return task && array.indexOf(task.状态) != -1;
        }

        const translatedFile = task && task.翻译后文档;

        const isGeneratingPreview = task && pendingPayment && statusIn("Waiting", "Processing") // 有 task、待支付、task.状态 == Waiting / Processing 没有文件，有进度
        const isPreviewGenerated = task && pendingPayment && statusIn("Completed") && translatedFile;  // 有 task、待支付、task.状态 == Completed、有文件

        const isTranslating = task && !pendingPayment && statusIn("Waiting", "Processing"); // 有 task, 已支付, task.状态 == Waiting / Processing, 有进度，没文件
        const isTranslated = task && !pendingPayment && statusIn("Completed") && translatedFile; // 有 task, 已支付, task.状态 == Completed, 有文件

        const hasFailed = task && statusIn("Terminated"); // 有 task, task.状态 == "Terminated"
        const isCancelled = task && statusIn("Cancelled"); // 有 task, task.状态 == "Cancelled"
        const isNotSupported = task && statusIn("NotSupported") // 有 task, task.状态 == "NotSupported"
        const isPendingFix =  task && statusIn("PendingFix");

        const freeNum = task.免费预览量 || 0;

        function renderStatus(className) {
            const [label, color, icon] = (() => {
                if (isGeneratingPreview) {
                    return [`正在翻译前 ${freeNum} token`, "indigo"]
                } else if (isPreviewGenerated) {
                    return [`待支付 (${task.价格}积分)`, "amber"]
                } else if (isTranslating) {
                    return ["正在翻译全文", "violet"]
                } else if (isTranslated) {
                    return ["已翻译完成", "green", <i className='bx bx-check-circle'></i>]
                } else if (hasFailed) {
                    return ["已中断", "red"]
                } else if (isCancelled) {
                    return ["已取消", "gray"]
                } else if (isNotSupported) {
                    return ["不支持", "red"]
                } else if (isPendingFix) {
                    return ["等待处理", "slate"]
                }
                return []
            })();

            if (label) {
                return (
                    <div data-color={color} className={
                        classNames("inline-flex gap-1 items-center rounded-sm px-2 py-1.5 font-size-12 bg-[var(--color-3)] text-[var(--color-11)] leading-none", className)
                    }>
                        {label}{icon || null}
                    </div>
                )
            }
            return null
        }

        const fileIcon = getFileIcon(task.文档);

        function renderModel() {
            if (task.模型 == "gpt-4") {
                return (
                    <div className="whitespace-pre text-[var(--blue11)] font-size-11 px-2 py-1 bg-[var(--blue3)] rounded leading-none">
                        GPT4
                    </div>
                )
            } else {
                return null
            }
        }


        const actionMenu = (
            iconDropdownMenu(<i className='bx bx-dots-horizontal-rounded font-size-12'></i>, [
                {
                    label: "下载原文", icon: <i className='bx bx-download'></i>, onSelect: _ => {
                        track("history_download_original_file")
                        downloadURL(task.文档.url)
                    }
                },
                task.翻译后文档 ? {
                    label: "下载译文", icon: <i className='bx bx-cloud-download'></i>, onSelect: _ => {
                        track("history_download_file")
                        downloadURL(task.翻译后文档.url)
                    }
                } : null,
                (isGeneratingPreview || isTranslating) ? null : {
                    label: "删除", icon: <i className='bx bx-trash'></i>, onSelect: _ => {
                        // restartTask()
                        // 删除的 confirm
                        track("history_delete_task_try")
                        createDialogConfirm({
                            content: (
                                <div className="flex flex-col gap-3">
                                    <div className="flex items-center gap-1.5">
                                        <i className='bx bx-info-circle text-[var(--tomato10)] text-[16px]'></i>
                                        <div>你确定要删除这个翻译文档吗？</div>
                                    </div>
                                    <div className="pl-5 text-[var(--gray11)] font-size-13">请确保你已经下载好了自己需要的译文</div>
                                </div>
                            ),
                            okText: "删除",
                            okColor: "tomato",
                            cancelText: "取消",
                            style: {
                                // width: "revert"
                            },
                            onConfirm: yes => {
                                if (yes) {
                                    archiveTask(task)
                                }
                            }
                        })
                    }
                },
                // {
                //     label: "下载原文", icon:<i className='bx bx-download'></i>, onSelect: _ => {
                //         downloadURL(task.文档.url)
                //     }
                // },
            ], { placement: "bottom end" })
        );

        function renderSharingStatus() {
            if (task.分享) {
                return (
                    <div className="whitespace-pre text-[var(--cyan11)] font-size-11 px-2 py-1 bg-[var(--cyan3)] rounded leading-none flex gap-1 items-center">
                        分享中<i className="bx bx-share bx-flip-horizontal" />
                    </div>
                )
            } else {
                return null
            }
        }

        function renderFlags() {
            return (
                <TranslationTaskFlags {...{ task, facade, hideModel: true }} />
            )
        }

        return (
            <Pressable key={task.id} onPress={_ => {
                track("history_goto_translation", { status: task.状态, price: task.价格 })
                routeTo("/u/task/" + task.id)
            }}>
                <div className="flex py-4 sm:py-3 sm:pl-3 sm:pr-5 pl-2 pr-3 rounded hover:bg-[var(--gray2)] cursor-pointer w-full">
                    <div className="flex gap-2 flex-col w-full">
                        <div className="flex gap-2 justify-between w-full">
                            <div className="flex gap-1 items-center">
                                <div className="whitespace-pre text-[var(--gray11)] font-size-12 px-2 ">
                                    {index + 1}
                                </div>
                                <div className="font-size-16 mr-1 ">
                                    {fileIcon}
                                </div>
                                <ClampedText tipEnabled={true}>{task.文档.title}</ClampedText>
                            </div>
                            <div className="flex items-center">
                                <div className="whitespace-pre text-[var(--gray11)] font-size-12 px-2 py-1.5 bg-[var(--gray2)] rounded  leading-none">
                                    ￥{task.价格}
                                </div>
                            </div>
                        </div>
                        <div className="hidden sm:flex gap-2 justify-between">
                            <div className="hidden sm:flex gap-2 items-center">
                                {renderStatus("ml-6")}
                                {renderTranslationSummary(task)}
                                {renderModel()}
                                {renderFlags()}
                                {renderSharingStatus()}
                                {actionMenu}
                            </div>
                            <div className="whitespace-pre text-[var(--gray11)] font-size-12 mt-1 nowrap">
                                {dayjs(task.创建时间).format("MM-DD HH:mm")}
                            </div>
                        </div>
                        <div className="sm:hidden flex flex-col gap-2 ml-8">
                            <div className="flex gap-2 justify-between">
                                {renderTranslationSummary(task)}
                                <div className="whitespace-pre text-[var(--gray11)] font-size-12 mt-1 nowrap">
                                    {dayjs(task.创建时间).format("MM-DD HH:mm")}
                                </div>
                            </div>
                            <div className="flex gap-2 items-center flex-wrap">
                                {renderStatus("-ml-1")}                                
                                {renderModel()}
                                {renderFlags()}
                                {renderSharingStatus()}
                                {actionMenu}
                            </div>
                        </div>
                    </div>
                </div>
            </Pressable>
        )
    }


    function renderEmpty() {
        return (
            <div className="w-full h-full flex flex-col justify-center items-center">
                <div className="flex flex-col px-2 gap-8 items-center">
                    <div className="flex flex-col px-2 gap-4 items-center">
                        <i className='bx bx-folder-open text-[6rem] text-[var(--gray10)] opacity-80'></i>
                        <div className="text-[var(--gray11)]">
                            {keyword ? "没有相关文档" : "您还没有上传过文档"}
                        </div>
                    </div>
                    <Button size={"xlarge"} className="!px-12" color="violet" onPress={_ => {
                        track("history_goto_new_first")
                        routeTo("/u/task")
                    }}>
                        上传文档，立即翻译
                    </Button>
                </div>
                <div className="h-1/3"></div>
            </div>
        )
    }


    return (
        <div className="flex flex-col h-full w-full max-w-3xl self-center pb-2 pt-16 sm:pt-2" >
            <div className="flex pt-2 pb-3 px-4 sm:px-6 justify-between">
                <TextInput styled={true} className="!py-1.5 !px-1" color="violet" placeholder="搜索"
                    onChange={v => setKeyword(v)} value={keyword}
                    prefix={<i className='bx bx-search'></i>}
                    suffix={keyword ? <i className='bx bx-x cursor-pointer' onClick={_ => setKeyword("")}></i> : null}
                />
                {(tasks === undefined || (tasks && tasks.length == 0)) ? null : (
                    <Button color="violet" onPress={_ => {
                        track("history_goto_new")
                        routeTo("/u/task")
                    }}>
                        上传文档
                    </Button>
                )}
            </div>
            <div className="grow flex py-2 flex-col gap-1 px-2 sm:px-4 overflow-auto" ref={listRef}>
                {
                    tasks ? (tasks.length == 0 ? renderEmpty() : tasks.map(renderTask))

                        : null
                }
                {
                    loadingMore ? (
                        Array(tasks && tasks.length > 0 ? 5 : 10).fill(0).map((_, index) => {
                            return (
                                <React.Fragment key={"l" + index}>
                                    <div className="hidden sm:flex gap-2 sm:gap-16 py-3 px-3 sm:px-5 w-full">
                                        <div className="flex gap-4 grow">
                                            <div className="bg-[var(--gray2)] h-4 w-4" />
                                            <div className="bg-[var(--gray2)] h-4 grow" />
                                        </div>
                                        <div className="bg-[var(--gray2)] h-4 w-10" />
                                    </div>
                                    <div className="flex flex-col sm:hidden gap-2 py-5 px-4 w-full">
                                        <div className="flex gap-2 grow">
                                            <div className="bg-[var(--gray2)] h-4 w-4" />
                                            <div className="bg-[var(--gray2)] h-4 grow" />
                                        </div>
                                        <div className="flex justify-between pl-6">
                                            <div className="bg-[var(--gray2)] h-4 w-24 " />
                                            <div className="bg-[var(--gray2)] h-4 w-12 " />
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })
                    ) : null
                }

            </div>
        </div>
    )
}




