

import React, { useContext, useEffect, useState, useRef } from 'react'

import MenuAndMain from './components/MenuAndMain'

import ContextMenu from 'bwax-ui/components/ContextMenu';
import DropdownMenu from 'bwax-ui/components/DropdownMenuLegacy';
import ClampedText from 'bwax-ui/components/ClampedText';

import Button, { Pressable } from 'bwax-ui/components/Button';

import classNames from 'classnames';
import { toast } from 'bwax-ui/components/Toast';

import './NoteManagement.less';
import { ReaderIcon, DotsVerticalIcon } from '@radix-ui/react-icons';

import SortableTree from 'bwax-ui/components/SortableTree';

import { hashCode, } from 'bwax/utils'

import { AiOutlineFileDone } from 'react-icons/ai'

import { useTrack } from 'bwax-ui/track';

import NoteEdit from './NoteEdit';

import UsageQuotaContext from 'bwax-ui/ml/widget/ports/inbot/UsageQuotaContext';
import ScrollHelper from 'bwax-ui/ScrollHelper';
import TextInput from 'bwax-ui/components/inputs/TextInput';

import { renderDate } from 'bwax-ui/renderTime';

export default function NoteManagement({ data, slots, events, facade, viewEnv }) {

    const track = useTrack();

    const {
        menuDrawerVisible,

        userId,
        currentNoteId,

        // for note edit
        titlePlaceholder, contentPlaceholder, isArticlePublisher,

    } = data;
    const {
        closeMenuDrawer, goToNote,

    } = events
    const { emptyContent } = slots

    const entityName = "笔记";

    // const notes = givenNotes.sort((a, b) => b.displayWeight - a.displayWeight);
    const fieldPaths = ["标题", "标签", "显示权重", "所属笔记", "子笔记数量", "已加入知识库", "可以更新知识库", "已归档", "内容保存标识",  "修改时间"];

    const [isUpdatingKnowledge, setIsUpdatingKnowledge] = useState(false);

    // 要实现  update position, 等函数

    const [notes, setNotes] = useState([]);


    const [currentNote, setCurrentNote] = useState();
    const [isLoadingCurrentNote, setIsLoadingCurrentNote] = useState(false);

    function mergeNotes(newOnes) {
        setNotes(prev => {
            // 要排除掉已经存在的，然后在 append 到后面
            const existing = prev || [];

            const refreshed = existing.map(e => {
                const found = newOnes.find(o => o.id == e.id);
                return found || e;
            })

            const toAppend = newOnes.filter(s => !existing.some(e => e.id === s.id));
            return [...refreshed, ...toAppend].sort((a, b) => b.显示权重 - a.显示权重);
        })
        if (currentNote) {
            const note = newOnes.find(n => n.id == currentNote.id);
            if (note) {
                setCurrentNote({
                    ...currentNote, ...note
                })
            }
        }
    }


    function refreshNote(note) {
        setNotes(prev => {
            return (prev || []).map(n => n.id == note.id ? { ...n, ...note } : n);
        })
        if (currentNote && note.id === currentNote.id) {
            setCurrentNote({
                ...currentNote, ...note
            })
        }
    }



    async function loadCurrentNote(id) {
        setIsLoadingCurrentNote(true);
        const [result, error] = await facade.findById(id, {
            entityName,
            fieldPaths: [...fieldPaths, "用户选项", "内容"],
        });

        setIsLoadingCurrentNote(false);
        if (!error && result) {
            setCurrentNote(result)
        }
    }


    const currentNoteRef = useRef();
    currentNoteRef.current = currentNote;
    async function syncCurrentNote(id) {
        const [r, e] = await facade.findById(id, { entityName, fieldPaths: ["修改时间"]}, { forceRefreshing: true });
        if(currentNoteRef.current && r) {
            if((new Date(r.修改时间)).getTime() > new Date(currentNoteRef.current.修改时间).getTime()) {
                const [result, error] = await facade.findById(id, {
                    entityName,
                    fieldPaths: [...fieldPaths, "用户选项", "内容"],
                });
                if (!error && result) {
                    refreshNote(result)
                }
            }
        }
    }
    const syncCurrentNoteRef = useRef();
    syncCurrentNoteRef.current = syncCurrentNote;

    useEffect(() => {
        if (currentNoteId) {
            loadCurrentNote(currentNoteId);
            const timerId = setInterval(() => {
                // 尝试 load 协议爱服务器端的内容
                syncCurrentNoteRef.current(currentNoteId);
            }, 2000);
            return () => {
                clearInterval(timerId);
            }

        } else {
            setCurrentNote()
        }
    }, [currentNoteId]);


    const [isSaving, setIsSaving] = useState();

    async function saveNote({ title, content, markdown, contentKey }) {
        setIsSaving(true);
        const [result, error] = await facade.update({
            entityName,
            id: currentNoteId,
            formData: { 标题: title, 内容: content, markdown, 内容保存标识: contentKey },
            fieldPaths: [...fieldPaths, "用户选项", "内容"],
        })
        setIsSaving(false)
        if (!error && result) {
            refreshNote(result);
        }

    }

    async function saveUserOptions(userOptions) {
        const [result, error] = await facade.update({
            entityName,
            id: currentNoteId,
            formData: { 用户选项: userOptions },
            fieldPaths: [...fieldPaths, "用户选项", "内容"],
        })
        setIsSaving(false)
        if (!error && result) {
            setCurrentNote(result);
        }
    }

    ////////// for normal list handling:

    const [hasMore, setHasMore] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);

    async function loadNotes(offset = 0) {
        const pageSize = 25;

        setLoadingMore(true);
        const [result, error] = await facade.listAll({
            entityName,
            // conxc
            condition: [[
                { field: "创建者.id", op: "eq", value: userId },
                { field: "所属笔记.id", op: "eq", value: null },
                { field: "已归档", op: "ne", value: true },
            ]],
            sort: [{ field: "显示权重", order: "DESC" }],
            fieldPaths,
            pageSize,
            offset,
        });

        setLoadingMore(false);
        if (error) {
            console.error(error);
            return
        }
        if (result) {
            const hasMore = result.pageSize + offset < result.count;
            setHasMore(hasMore);
            mergeNotes(result.data);
        }

    }

    const loadMore = () => {
        if (hasMore) {
            loadNotes(notes.filter(s => !s.已归档 && !s.所属笔记).length);
        }
    }




    const refreshKey = hashCode(JSON.stringify(notes.map(n => ({ id: n.id, parentId: n.所属笔记 && n.所属笔记.id }))));

    useEffect(() => {
        loadNotes()
    }, []);


    async function loadSubNotes(ids) {
        const [result, error] = await facade.list({
            entityName,
            // conxc
            condition: [[
                { field: "创建者.id", op: "eq", value: userId },
                { field: "所属笔记.id", op: "in", value: ids },
                { field: "已归档", op: "ne", value: true },
            ]],
            sort: [{ field: "显示权重", order: "DESC" }],
            fieldPaths,
            pageSize: 1000, // 全部加载吧
            offset: 0,
        });

        if (error) {
            console.error(error);
            return
        }
        if (result) {
            mergeNotes(result || []);
        }
    }

    function operateRecord(interfaceName, args, id) {
        return facade.customMutation({
            entityName,
            interfaceName,
            id, args,
            outputFieldPaths: [
                ["笔记", fieldPaths]
            ]
        });
    }

    // 
    async function createNew(parentId) {
        const [result, error] = await operateRecord("添加", [null, null, parentId || null]);
        if (!error && result) {
            setNotes(prev => [result, ...prev]);
            goToNote(result.id);

        }
    }

    async function updatePosition({ id, parentId = null, after = null, before = null, originalParentId }) {

        const [result, error] = await operateRecord("调整位置", [parentId, after, before], id);

        if (!error && result) {
            const idToRefresh = [id, parentId, originalParentId].filter(x => !!x);

            const [r, e] = await facade.list({
                entityName,
                // conxc
                condition: [[
                    { field: "创建者.id", op: "eq", value: userId },
                    { field: "id", op: "in", value: idToRefresh },
                ]],
                fieldPaths,
                pageSize: 1000, // 全部加载吧
                offset: 0,
            });
            mergeNotes(r);

        }

    }

    async function archiveOrUnarchiveNote(id, isToArchive, title) {
        const [result, error] = await operateRecord("归档", [isToArchive], id)
        if (!error && result) {
            refreshNote(result);
            if (isToArchive) {
                
                // toast()
                toast({ title: `笔记${title ? "\"" + title + "\"" : ""}已移入回收站`, undoAction: async _ => { archiveOrUnarchiveNote(id, false) }, duration: 3200 });
                if(id === currentNoteId) {
                    viewEnv.routeTo("/note");
                }
            }
            // refresh the parent count
            if (result.所属笔记) {
                const [r, e] = await facade.findById(result.所属笔记.id, { entityName, fieldPaths: ["子笔记数量"] });
                if (!e && r) {
                    refreshNote(r);
                }
            }
        }
    }
    async function archiveNote(id, note) {
        return archiveOrUnarchiveNote(id, true, note ? note.标题 : null)
    }

    async function addToOrUpdateKnowledge(id) {
        setIsUpdatingKnowledge(true);
        const [result, error] = await operateRecord("加入知识库", [], id);
        setIsUpdatingKnowledge(false);
        if (!error && result) {
            refreshNote(result);


        }
    }

    async function removeFromKnowledge(id) {
        setIsUpdatingKnowledge(true);
        const [result, error] = await operateRecord("从知识库移除", [], id);
        setIsUpdatingKnowledge(false);
        if (!error && result) {
            refreshNote(result);
        }
    }


    //// ----- for search -----
    const [keyword, setKeyword] = useState();

    const [searchResults, setSearchResults] = useState([]);
    const [hasMoreSearchResults, setHasMoreSearchResult] = useState(false);
    const [isSearching, setIsSearching] = useState(false);

    async function searchNotes(keyword, offset = 0) {
        const pageSize = 20;

        setIsSearching(true);
        const [result, error] = await facade.listAll({
            entityName,
            // conxc
            condition: [[
                { field: "创建者.id", op: "eq", value: userId },
                // { field: "所属笔记.id", op: "eq", value: null },
                { field: "已归档", op: "ne", value: true },
            ]],
            search: {
                keyword,
                fields: [{ field: "标题", weight: 100 }, { field: "markdown", weight: 10 }]
            },
            // sort: [{ field: "修改时间", order: "DESC" }],
            fieldPaths: [...fieldPaths, "修改时间", "父级以上笔记.标题"],
            pageSize,
            offset,
        });
        setIsSearching(false);

        if (!error && result) {
            const hasMore = result.pageSize + offset < result.count;
            setHasMoreSearchResult(hasMore);

            setSearchResults(prev => {
                // 要排除掉已经存在的，然后在 append 到后面
                const newOnes = result.data;
                const refreshed = prev.map(e => {
                    const found = newOnes.find(o => o.id == e.id);
                    return found || e;
                })

                const toAppend = newOnes.filter(s => !prev.some(e => e.id === s.id));
                return [...refreshed, ...toAppend];
            })
        }
    }

    async function searchMore () {
        if(keyword && keyword.trim()) {
            searchNotes(keyword.trim(), searchResults.length)
        }
    }

    const trackedSearchRef = useRef(false);
    useEffect(() => {
        if (keyword) {
            if(trackedSearchRef.current == false) {
                trackedSearchRef.current = true;
                track("note_search_start");
            }
            setSearchResults([])
            searchNotes(keyword.trim(), 0);
        } else {
            // clear
            trackedSearchRef.current = false
            setSearchResults([])
        }
    }, [keyword && keyword.trim()]);


    // handle both normal list and search results 
    const listRef = useRef();
    const listInDrawerRef = useRef();

    const loadMoreRef = useRef();
    loadMoreRef.current = loadMore;

    const searchMoreRef = useRef();
    searchMoreRef.current = searchMore;

    const keywordRef = useRef();
    keywordRef.current = keyword;


    useEffect(() => {
        if (listRef.current) {
            const scrollHelper = new ScrollHelper(listRef.current, {
                onScrolledToBottom: _ => {
                    const keyword = keywordRef.current;
                    if(keyword && keyword.trim()) {
                        searchMoreRef.current && searchMoreRef.current();
                    } else {
                        loadMoreRef.current && loadMoreRef.current();
                    }                    
                }
            });
            return () => {
                scrollHelper.cleanUp();
            }
        }
    }, [listRef.current]);

    useEffect(() => {
        if (listInDrawerRef.current) {
            const scrollHelper = new ScrollHelper(listInDrawerRef.current, {
                onScrolledToBottom: _ => {
                    const keyword = keywordRef.current;
                    if(keyword && keyword.trim()) {
                        searchMoreRef.current && searchMoreRef.current();
                    } else {
                        loadMoreRef.current && loadMoreRef.current();
                    }
                }
            });
            return () => {
                scrollHelper.cleanUp();
            }
        }
    }, [listInDrawerRef.current]);


    function renderSearchResult() {
        
        function renderItem(note) {

            const ancestors = note.父级以上笔记;

            function renderAncestor(a, index) {
                return (
                    <React.Fragment key={a.id}>
                        <span> <ClampedText text={a.标题 || "无标题"} tipEnabled={false} /></span>
                        {/* {index < ancestors.length -1 ? <span>/</span> : null} */}
                        <span>/</span>
                    </React.Fragment>
                )
            }

            return (
                <Pressable key={note.id} onPress={_ => goToNote(note.id)}>
                    <div className={classNames(
                        "flex flex-col gap-1 py-2 px-2 mx-1 hover:bg-[var(--gray3)] rounded",
                        { "bg-[var(--gray2)]": currentNote && note.id == currentNote.id }
                    )} 
                    >
                        <div className="flex gap-2 items-center">
                            {note.已加入知识库 ? <AiOutlineFileDone className="w-[14px] h-[14px] shrink-0" /> : <ReaderIcon className="w-[14px] h-[14px] shrink-0" />}
                            <ClampedText text={note.标题 || "无标题"} tipEnabled={false} />
                            {renderDate(note.修改时间)}
                        </div>
                        { ancestors && ancestors.length > 0 ? (
                            <div className="flex gap-1 font-size-12 text-[var(--gray11)] pl-[21px] leading-none">
                                { ancestors.map(renderAncestor)}
                            </div>
                        ) : null}
                    </div>
                    
                </Pressable>
            )
        }

        return (
            <div className="px-1 py-2 gap-0.5 flex flex-col">
                {searchResults.map(renderItem)}
                { isSearching ? (
                    Array(notes.length === 0 ? 10 : 5).fill(0).map((_, index) => {
                        return (
                            <div className="mx-2 pl-1.5 pr-1.5 py-2.5 flex gap-2" key={"l" + index}>
                                <div className="bg-[var(--gray2)] h-[15px] w-[14px]" />
                                <div className="bg-[var(--gray2)] h-[18px] grow" />
                                <div className="bg-[var(--gray2)] h-[12px] w-[32px]" />
                            </div>
                        )
                    })
                ) : null}
            </div>
        )

    }




    function renderNoteTree(usedInDrawer) {
        return (
            <SortableTree {...{
                items: (notes || []).map(n => ({ ...n, parentId: n.所属笔记 && n.所属笔记.id })).filter(n => !n.已归档),

                rootClassName: "lc-test-dnd-tree",
                activeId: currentNote && currentNote.id,
                onItemPressed: note => {
                    track("note_select_note")
                    if (usedInDrawer) {
                        closeMenuDrawer();
                        setTimeout(() => {
                            goToNote(note.id);
                        }, 200)
                    } else {
                        goToNote(note.id)
                    }
                },

                onChange: change => {
                    const { subjectId, newParentId, after, before, originalParentId } = change;
                    if (newParentId !== originalParentId) {
                        track("note_move_note", { change_parent: true })
                    } else {
                        track("note_move_note", { change_parent: false })
                    }
                    updatePosition({
                        id: subjectId, parentId: newParentId, after, before, originalParentId
                    })

                },

                renderItem: note => {
                    return (
                        <NoteSummary key={note.id} {...{
                            note, usedInDrawer, currentNote, createNew,
                            archiveNote, addToOrUpdateKnowledge, removeFromKnowledge,
                        }} />
                    )
                },
                // 
                getSubItems: note => {
                    return notes.filter(n => n.所属笔记 && n.所属笔记.id == note.id).filter(n => !n.已归档);

                },
                loadSubItems: parentIds => {
                    if (loadSubNotes) {
                        loadSubNotes(parentIds);
                    }
                    return
                },
                hasSubItems: note => {
                    return note.子笔记数量 && note.子笔记数量 > 0;
                },

                getTopLevelItems: items => {
                    // get top level items;
                    const topLevel = items.filter(n => !n.parentId);
                    return topLevel;
                },

                // gap: 8,
                color: "grass",
                cacheKey: "note-management::" + userId + "::collapsed-state",
                refreshKey,
            }} />
        )
    }

    function renderMenu(usedInDrawer, { setMenuCollapsed, menuCollapsed }) {
        // 
        return (
            <div className={"lc-note-list-container" + (usedInDrawer ? " used-in-drawer" : "")}>
                <div className="pt-0 px-1.5 pb-1.5 flex gap-2">
                    {usedInDrawer || menuCollapsed ? null : (
                        <Pressable onPress={_ => {
                            track("note_collapse_list")
                            setMenuCollapsed(true)
                        }}>
                            <div className="py-0.5 px-2 font-size-20 text-[var(--gray11)] hover:bg-[var(--gray3)] bg-[var(--gray2)] rounded cursor-pointer flex items-center">
                                <i className='bx bx-chevrons-left'></i>
                            </div>
                        </Pressable>
                    )}
                    <TextInput styled={true} className="!py-1.5 !px-1 grow" color="violet" placeholder="搜索笔记"
                        onChange={v => setKeyword(v)} value={keyword}
                        prefix={<i className='bx bx-search'></i>}
                        suffix={keyword ? <i className='bx bx-x cursor-pointer' onClick={_ => setKeyword("")}></i> : null}
                    />
                    <Pressable onPress={_ => {
                        if (createNew) {
                            track("note_create_new_note", { method: "add-button" })
                            createNew();
                            if (usedInDrawer && closeMenuDrawer) {
                                closeMenuDrawer()
                            }
                        }
                    }}>
                        <div className="h-[35px] py-0.5 px-2.5 font-size-17 text-[var(--gray11)] hover:bg-[var(--gray3)] bg-[var(--gray2)] rounded cursor-pointer flex items-center justify-center">
                            <i className='bx bx-plus'></i>
                        </div>
                    </Pressable>
                </div>
                <div className="grow overflow-auto" ref={ref => {
                    if (usedInDrawer) {
                        listInDrawerRef.current = ref;
                    } else {
                        listRef.current = ref;
                    }
                }}>
                    {(keyword && keyword.trim()) ? renderSearchResult(usedInDrawer) : renderNoteTree(usedInDrawer)}
                    {loadingMore ? (
                        Array(notes.length === 0 ? 15 : 5).fill(0).map((_, index) => {
                            return (
                                <div className="mx-2 pl-4 pr-8 py-2.5 flex gap-2" key={"l" + index}>
                                    <div className="bg-[var(--gray2)] h-[15px] w-[14px]" />
                                    <div className="bg-[var(--gray2)] h-[15px] grow" />
                                </div>
                            )
                        })
                    ) : null}
                </div>


            </div>
        )
    }

    function renderEmptyTip() {
        return (
            <div className="max-w-3xl sm:min-w-xl self-center flex flex-col gap-8 pb-16 px-6 pt-[15vh] sm:px-8 sm:pt-[25vh]">
                <div className="flex gap-8">
                    <i className="bx bx-edit text-[5rem] opacity-30" />
                    <div className="flex flex-col gap-1 pt-3 sm:pt-4 grow">
                        <span className="select-none opacity-60">与 AI 助手互动撰写笔记</span>
                        <span className="select-none opacity-60">用笔记整理你的知识</span>
                    </div>
                </div>
                <div className="pl-2">
                    <Button size="large" onPress={_ => createNew()}>编辑新的笔记</Button>
                </div>
            </div>
        )
    }


    function rendreMainContent() {

        return (
            <div className="flex flex-col w-full h-full relative">
                {currentNote ? (
                    <NoteEdit
                        data={{
                            currentNote, isSaving, isUpdatingKnowledge, currentUserId: userId,
                            isArticlePublisher, titlePlaceholder, contentPlaceholder,

                        }}
                        events={{
                            addToOrUpdateKnowledge: _ => addToOrUpdateKnowledge(currentNote.id),
                            removeFromKnowledge: _ => removeFromKnowledge(currentNote.id),
                            archiveNote: _ => archiveNote(currentNote.id, currentNote),

                            save: saveNote,
                            saveUserOptions,
                        }}
                        facade={facade}
                        viewEnv={viewEnv}
                    />
                ) : (
                    !currentNoteId ? renderEmptyTip() : (
                        // isLoadingCurrentNote 
                        null
                    )
                )}
            </div>
        )

    }


    return (
        <MenuAndMain {...{
            menuDrawerVisible, closeMenuDrawer, renderMenu, mainContent: rendreMainContent(),
            resizeKey: "note-management",

            collapsedMenuIcon: (
                <i className='bx bx-book'></i>
            ),
        }} />
    )
}



function NoteSummary({
    note, usedInDrawer, currentNote, createNew,
    archiveNote, addToOrUpdateKnowledge, removeFromKnowledge,

}) {

    const track = useTrack();

    const { reloadUsageQuota, isOutOfQuota } = useContext(UsageQuotaContext) || {};

    const items = [
        {
            label: "添加子笔记",
            icon: <i className='bx bx-plus'></i>,
            onSelect: async _ => {
                // 
                track("note_create_new_sub_note", { method: "menu-item" })
                createNew(note.id)
            },
        },
        {
            label: "加入知识库",
            icon: <i className='bx bx-folder-plus'></i>,
            onSelect: async _ => {
                // 
                track("note_add_to_knowledge_base", { method: "menu-item" })
                addToOrUpdateKnowledge(note.id);

                setTimeout(() => {
                    reloadUsageQuota()
                }, 2000)
            },
            hidden: note.已加入知识库,
            isDisabled: !note.可以更新知识库 || isOutOfQuota,
        },
        {
            label: "从知识库移除",
            icon: <i className='bx bx-folder-minus'></i>,
            onSelect: async _ => {
                // 
                track("note_remove_from_knowledge_base", { method: "menu-item" })
                removeFromKnowledge(note.id)
            },
            hidden: !note.已加入知识库
        },
        {
            label: "删除笔记",
            icon: <i className='bx bx-trash'></i>,
            onSelect: async _ => {
                track("note_archive_note", { method: "menu-item" })
                archiveNote(note.id, note)
            },
            isDisabled: note.已加入知识库 || note.子笔记数量 > 0
        },
        {
            label: "新窗口打开",
            icon: <i className='bx bx-windows'></i>,
            onSelect: async _ => {
                console.log("?>> open new links", note.id);

                track("note_open_with_new_tab", { method: "menu-item" })
                // document must exists
                // http://localhost:3200/-/u211005/note/RW50aXR5Qko6MTMx
                const { href } = document.location;
                const noteURL = href.replace(/\/note\/([^\/]+)/, "/note/" + note.id);


                window.open(noteURL);

            },
            // isDisabled: note.已加入知识库 || note.子笔记数量 > 0
        },

    ].filter(item => !item.hidden)

    const inner = (
        <div className={"note-summary" + (currentNote && currentNote.id == note.id ? " active" : "")}>
            <div className={classNames({ "icon-and-title": true, "untitled": !note.标题 })}>
                {note.已加入知识库 ? <AiOutlineFileDone /> : <ReaderIcon />}
                <ClampedText text={note.标题 || "无标题"} tipEnabled={false} />
            </div>
            <div className="op-icons" onClick={e => {
                e.stopPropagation();
            }}>
                <DropdownMenu items={items} withPortal={!usedInDrawer}>
                    <DotsVerticalIcon />
                </DropdownMenu>
            </div>
        </div>
    )

    return (
        usedInDrawer ? (
            inner
        ) : (
            <ContextMenu items={items} withPortal={!usedInDrawer}>
                {inner}
            </ContextMenu>
        )
    )
}
