
// This file is generated. Don't modify it.
import React from 'react'
export default function TimeFillingIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M7.998556,2.00422903 C11.3054047,2.00422903 13.995803,4.69162489 13.995803,7.99495857 C13.995803,11.2982923 11.3054047,13.9857613 7.998556,13.9857613 C4.69170727,13.9857613 2.00138222,11.2982923 2.00138222,7.99495857 C2.00138222,4.69162489 4.69170727,2.00422903 7.998556,2.00422903 Z M8,8.21748188 L8,4.5 C8,4.22385763 7.77614237,4 7.5,4 C7.22385763,4 7,4.22385763 7,4.5 L7,8.5 C7,8.64928864 7.06542735,8.78329591 7.16917203,8.87491179 C7.19826809,8.90319871 7.23130411,8.92837564 7.26805223,8.94959218 L9.86612844,10.4495922 C10.1052748,10.5876634 10.41107,10.5057258 10.5491411,10.2665795 C10.6872123,10.0274332 10.6052748,9.72163796 10.3661284,9.58356677 L8,8.21748188 Z" id="TimeFillingIcon-path-1"></path>
            </defs>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Filling/Time">
                    <g id="Icon/Reservation">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="TimeFillingIcon-mask-2" fill="white">
                            <use href="#TimeFillingIcon-path-1"></use>
                        </mask>
                        <use id="Combined-Shape" fill={color} fillRule="nonzero" href="#TimeFillingIcon-path-1"></use>
                        <g id="Color/UI/Text" mask="url(#TimeFillingIcon-mask-2)" fill={color} fillRule="nonzero">
                            <rect id="light-gray" x="0" y="0" width="16" height="16"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
