
import React from 'react';

export const Pie = React.lazy(() => import ('./nivo/Pie'));

function enhanceLengend (legend) {
    // add some default
    return {
        itemWidth: 80,
        itemHeight: 18,
        itemsSpacing: 8,
        direction: "row",
        anchor: "bottom",
        ...legend
    }
}


function renderPie (config) {
    const { data, legends, colorScheme } = config;

    // console.log("Render Pie", config);

    // 提供一些 Good Default
    const defaultConfig = {
        margin: { top: 32, right: 80, bottom: 64, left: 80}
    }

    const enhancedData = (data || []).map(datum => {
        return {
            ...datum,
            label: datum.id
        }
    })


    const dataHaveColor = data.every(d => d.color !== undefined);

    const colors = dataHaveColor ? 
        { colors: node => {
            return node.data.color
        }} :
        (colorScheme ? { colors: { scheme: colorScheme } }: {})
    
    return <Pie {...{
        ...defaultConfig,
        ...config,
        data: enhancedData,
        legends: (legends || []).map(enhanceLengend),
        ...colors,
    }} />
}


export default function NivoLazy (props) {

    const { name, config } = props;

    const renderers = {
        "pie": renderPie
    }
    
    const r = renderers[name];
    if(r) {
        return r(config)
    }
    return "unknown chart"

}
