
// This file is generated. Don't modify it.
import React from 'react'
export default function EditIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M2,11.2956645 L9.13680224,4.18181818 L11.8181818,6.85934251 L4.69016687,14 L2,14 L2,11.2956645 Z M11.0911959,2 L14,4.90948207 L12.5456175,6.36363636 L9.63636364,3.45519087 L11.0911959,2 Z" id="EditIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Edit">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="EditIcon-mask-2" fill="white">
                            <use href="#EditIcon-path-1"></use>
                        </mask>
                        <use id="Combined-Shape" fill={color} fillRule="nonzero" href="#EditIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
