
// This file is generated. Don't modify it.
import React from 'react'
export default function SignOutIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M13.9996429,8 C14.0001465,8.1227573 13.9533051,8.24563527 13.8592455,8.33889569 L11.8203772,10.3604414 C11.6322374,10.5469827 11.3288943,10.5469827 11.1407545,10.3604414 C11.1397962,10.3594913 11.138842,10.3585371 11.1378919,10.3575788 C10.9533947,10.1715007 10.9546763,9.87109002 11.1407545,9.68659283 L12.8417997,8 L11.1407545,6.31340717 C10.9546763,6.12890998 10.9533947,5.82849932 11.1378919,5.64242117 C11.138842,5.64146292 11.1397962,5.64050872 11.1407545,5.63955861 C11.3288943,5.45301725 11.6322374,5.45301725 11.8203772,5.63955861 L13.8592455,7.66110431 C13.9533051,7.75436473 14.0001465,7.8772427 13.9996429,8 Z M7.5,7.5 L10.5,7.5 C10.7761424,7.5 11,7.72385763 11,8 C11,8.27614237 10.7761424,8.5 10.5,8.5 L7.5,8.5 C7.22385763,8.5 7,8.27614237 7,8 C7,7.72385763 7.22385763,7.5 7.5,7.5 Z M11,12 L11,13 C11,13.5522847 10.5522847,14 10,14 L3,14 C2.44771525,14 2,13.5522847 2,13 L2,3 C2,2.44771525 2.44771525,2 3,2 L10,2 C10.5522847,2 11,2.44771525 11,3 L11,4 C10.9999997,4.00034362 11,4.00068733 11,4.00103111 C11,4.27717349 10.7761424,4.50103111 10.5,4.50103111 C10.2238576,4.50103111 10,4.27717349 10,4.00103111 C10,4.00068733 10.0000003,4.00034362 10.000001,4 L10,3 L3,3 L3,13 L10,13 L10,12 C10.0000003,11.9996564 10,11.9993127 10,11.9989689 C10,11.7228265 10.2238576,11.4989689 10.5,11.4989689 C10.7761424,11.4989689 11,11.7228265 11,11.9989689 C11,11.9993127 10.9999997,11.9996564 10.999999,12 Z" id="SignOutIcon-path-1"></path>
            </defs>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Personal/Sign-out">
                    <g>
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="SignOutIcon-mask-2" fill="white">
                            <use href="#SignOutIcon-path-1"></use>
                        </mask>
                        <use id="Combined-Shape" fill={color} fillRule="nonzero" href="#SignOutIcon-path-1"></use>
                        <g id="Color/UI/Text" mask="url(#SignOutIcon-mask-2)" fill={color} fillRule="nonzero">
                            <rect id="light-gray" x="0" y="0" width="16" height="16"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        
    )
}
