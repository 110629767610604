

import React, { useState, useEffect } from 'react'

import SelectFileButton from 'bwax-ui/components/inputs/SelectFileButton'
import classNames from 'classnames';

import ProgressBar from 'bwax-ui/components/ProgressBar';
import BeatLoader from 'react-spinners/BeatLoader';

import UploadFile from 'bwax-ui/actions/UploadFile';
import getImageURL from 'bwax-ui/getImageURL';

export default function ImageInput({ color = "gray", value, onChange, onError = _ => { }, accept, uploadFor = "add-record", facade, asButton = true, className }) {

    const [isUploading, setIsUploading] = useState(false);

    const [uploadProgress, setUploadProgress] = useState(0);
    async function uploadFile(lf) {

        setIsUploading(true)

        const [attachment, errors] = await UploadFile({
            file: lf, uploadFor,
            onUploadProgress: evt => {
                const { loaded, total } = evt;
                setUploadProgress(loaded * 100 / total)
            }
        })(facade.dlc);
        setIsUploading(false);
        setUploadProgress(0)

        if (errors || !attachment) {
            // onError(errors || "上传失败");
            return
        }
        onChange(attachment)
        // localStorage.setItem("translate::test::attachment", JSON.stringify(attachment))
    }

    return (
        <SelectFileButton {...{
            accept,
            asButton: false,
            className: classNames(`h-[120px] w-full rounded flex flex-col items-center justify-center gap-2 border-dashed border-2 border-[var(--${color}7)] bg-[var(--${color}2)]`, className),
            color,

            isUploading,
            onSelectFile: file => {
                uploadFile(file)
            }
        }}
        >
            {
                isUploading ? (
                    <div className="flex flex-col gap-3 items-center w-full px-2">
                        <BeatLoader size={6} color={`var(--${color}8)`} />
                        <ProgressBar barClassName="!h-1" progress={uploadProgress}></ProgressBar>
                    </div>
                ) : (
                    value ? (
                        <img src={getImageURL(value, "small")} />
                    ) : (
                        <div className={`flex flex-col gap-2 text-[var(--${color}10)] items-center cursor-pointer`}>
                            <i className={`bx bx-upload text-2xl text-[var(--${color}9)]`}></i>
                            <div>上传图片</div>
                            <div className="opacity-50 font-size-11 px-4 break-all text-center">{accept}</div>
                        </div>
                    )
                )
            }
        </SelectFileButton >
    )
}
