import React, { useEffect, useState } from 'react'

// 试试用 react-aria 做一组 ui kit

import './TestUIKit.less'

import ListBox from 'bwax-ui/components/ListBox';
import PopoverTrigger from 'bwax-ui/components/PopoverTrigger';
import { ModalTrigger } from 'bwax-ui/components/Modal';

import Button from 'bwax-ui/components/Button';

import SelectInput from 'bwax-ui/components/inputs/SelectInput';
import ToggleGroup from 'bwax-ui/components/inputs/ToggleGroup';

import ProgressBar from 'bwax-ui/components/ProgressBar';
import DropdownMenu from 'bwax-ui/components/DropdownMenu';
import TextInput from 'bwax-ui/components/inputs/TextInput';

export default function TestUIKit() {

    useEffect(() => {
        if (typeof (document) !== 'undefined') {
            document.body.className = "preview-page";
        }

    }, [])

    const [selected, setSelected] = useState();

    const [ editing, setEditing] = useState();

    const items = [
        { label: "简体中文", value: "简体中文" },
        {
            label: "常用",
            items: [
                { label: "繁体中文", value: "繁体中文" },
                { label: "英语", value: "英语" },
            ]
        },
        { label: "日语", value: "日语" },
        { label: "韩语", value: "韩语", isDisabled: true },
        { label: "德语", value: "德语" },
        {
            label: "小众",
            items: [
                { label: "阿拉伯语", value: "阿拉伯语" },
                { label: "波斯语", value: "波斯语", isDisabled: true },
                { label: "荷兰语", value: "荷兰语" },
            ]
        },
    ];

    const listBox = (
        <ListBox label="Alignment"
            selectionMode="single"
            style={{
                width: "20rem",
                height: "18rem",
            }}
            selected={selected}
            onSelect={setSelected}
            items={items}>
        </ListBox>
    );

    const widgets = [

        {
            title: "TextInput",
            widget: (
                <div className="flex flex-col gap-2">
                    <TextInput value={editing} onChange={setEditing} styled={true} maxLength={15} />
                    <TextInput value={editing} onChange={setEditing} styled={true} />
                </div>
            )
        },

        {
            title: "ToggleGroup",
            widget: (
                <ToggleGroup color={"violet"} items={[
                    { value: "dogs", label: "Dogs" },
                    { value: "cats", label: "Cats" },
                    { value: "pigs", label: "Pigs" },
                ]} />
            )
        },
        {
            title: "ToggleGroup (colored)",
            widget: (
                <ToggleGroup items={[
                    { value: "dogs", label: "Dogs", color: "tomato"},
                    { value: "cats", label: "Cats", color: "violet"},
                    { value: "pigs", label: "Pigs", color: "green"},
                ]} />
            )
        },

        {
            title: "Dropdown Menu",
            widget: (
                <DropdownMenu items={[
                    { label: "Copy", onSelect: _ => { console.log("Copy") }},
                    { label: "Delete", onSelect: _ => { console.log("Delete") }, isDisabled: true },
                    {   
                        label: "Other", 
                        subItems: [
                            { label: "Plugin A", onSelect: _ => { console.log("Plugin A") }},
                            { label: "Plugin B", onSelect: _ => { console.log("Plugin B") }},
                        ]
                    },
                    // { label: "Copy", onSelect: _ => { console.log("Copy") }},
                    // { label: "Delete", onSelect: _ => { console.log("Delete") }},
                ]}>
                    <i className='bx bx-dots-vertical-rounded'></i>
                </DropdownMenu>
            )
        },

        // {
        //     title: "ListBox",
        //     widget: listBox,
        // },
        {
            title: "Popover Trigger",
            widget: (
                <PopoverTrigger content={
                    // <div style={{ height: "10rem", width: "12rem" }}>
                    listBox
                    // </div>
                }>
                    <div>弹出</div>
                </PopoverTrigger>
            )
        },
        {
            title: "Select",
            widget: (
                <SelectInput
                    selected={selected}
                    onSelect={setSelected}
                    items={items}
                    style={{
                        width: "12rem"
                    }}
                />
            )
        },
        {
            title: "Select 2",
            widget: (
                <SelectInput
                    selected={selected}
                    onSelect={setSelected}
                    items={items}
                    style={{
                        width: "12rem"
                    }}
                />
            )
        },
        {
            title: "Modal",
            widget: (
                <ModalTrigger isDismissable content={
                    close => {
                        return (
                            <>
                                <div style={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "1rem" }}>
                                    <Button onPress={_ => close()}>关闭</Button>
                                    <SelectInput items={items}
                                        selected={selected}
                                        onSelect={setSelected}
                                        style={{
                                            width: "12rem"
                                        }}
                                        listBoxStyle={{
                                            maxHeight: "6rem"
                                        }}
                                    />
                                </div>

                            </>
                        )
                    }

                }>
                    <Button>弹出</Button>
                </ModalTrigger>
            )
        },
        {
            title: "Progress Bar",
            widget: <ProgressBar progress={88} type="circle" color={"violet"} style={{ width: "2rem", height: "2rem" }} doneColor="yellow" />
        }
    ]

    return (
        <div className="lc-test-uikit font-size-14">
            <div className="font-bold">
                Test UI Kit
            </div>
            {widgets.map(w => {
                return (
                    <div className="section" key={w.title}>
                        <div className="title">{w.title}</div>
                        <div className="widget">{w.widget}</div>
                    </div>
                )
            })}
        </div>
    )
}
