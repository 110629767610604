
// This file is generated. Don't modify it.
import React from 'react'
export default function CompanyIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M2,10 L7,10 L7,4 L7,1 L2,1 L2,10 Z M1,10 L1,1 C1,0.44771525 1.44771525,0 2,0 L7,0 C7.55228475,0 8,0.44771525 8,1 L8,3 L12,3 C12.5522847,3 13,3.44771525 13,4 L13,10 L13.5,10 C13.7761424,10 14,10.2238576 14,10.5 C14,10.7761424 13.7761424,11 13.5,11 L0.5,11 C0.223857625,11 3.38176876e-17,10.7761424 0,10.5 C-3.38176876e-17,10.2238576 0.223857625,10 0.5,10 L1,10 Z M8,4 L8,10 L12,10 L12,4 L8,4 Z M3.5,8 L5.5,8 C5.77614237,8 6,8.22385763 6,8.5 C6,8.77614237 5.77614237,9 5.5,9 L3.5,9 C3.22385763,9 3,8.77614237 3,8.5 C3,8.22385763 3.22385763,8 3.5,8 Z M3.5,5 L5.5,5 C5.77614237,5 6,5.22385763 6,5.5 C6,5.77614237 5.77614237,6 5.5,6 L3.5,6 C3.22385763,6 3,5.77614237 3,5.5 C3,5.22385763 3.22385763,5 3.5,5 Z M3.5,2 L5.5,2 C5.77614237,2 6,2.22385763 6,2.5 C6,2.77614237 5.77614237,3 5.5,3 L3.5,3 C3.22385763,3 3,2.77614237 3,2.5 C3,2.22385763 3.22385763,2 3.5,2 Z M9.5,8 L10.5,8 C10.7761424,8 11,8.22385763 11,8.5 C11,8.77614237 10.7761424,9 10.5,9 L9.5,9 C9.22385763,9 9,8.77614237 9,8.5 C9,8.22385763 9.22385763,8 9.5,8 Z M9.5,5 L10.5,5 C10.7761424,5 11,5.22385763 11,5.5 C11,5.77614237 10.7761424,6 10.5,6 L9.5,6 C9.22385763,6 9,5.77614237 9,5.5 C9,5.22385763 9.22385763,5 9.5,5 Z" id="CompanyIcon-path-1"></path>
            </defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Personal/Company">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <g id="Color/UI/Lighter-Text" transform="translate(1.000000, 2.000000)">
                            <mask id="CompanyIcon-mask-2" fill="white">
                                <use href="#CompanyIcon-path-1"></use>
                            </mask>
                            <use id="Mask" fill={color} fillRule="nonzero" href="#CompanyIcon-path-1"></use>
                            <g mask="url(#CompanyIcon-mask-2)" fill={color} fillRule="nonzero" id="light-gray">
                                <g transform="translate(-1.000000, -2.000000)">
                                    <rect x="0" y="0" width="16" height="16"></rect>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
