
import React, { useEffect, useState, useRef } from 'react'


import Button, { Pressable, iconButton, iconDropdownMenu } from "bwax-ui/components/Button";

import SelectFileButton from 'bwax-ui/components/inputs/SelectFileButton'
import UploadFile from 'bwax-ui/actions/UploadFile';

import BeatLoader from 'react-spinners/BeatLoader';

import ProgressBar from 'bwax-ui/components/ProgressBar';

import { toast } from 'bwax-ui/components/Toast';

import { useTrack } from 'bwax-ui/track';
import classNames from 'classnames';

// import
export default function GlossaryImport({ facade, glossary, onConfirm }) {

    const MAX_FILE_SIZE = 10; // 10m

    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const [termsToImport, setTermsToImport] = useState();

    const track = useTrack();

    async function uploadFile(file) {
        if (file.size > MAX_FILE_SIZE * 1024 * 1024) {
            toast("文件超过了" + MAX_FILE_SIZE + "M")
            return
        }

        // upload the attachment;
        const [attachment, error] = await UploadFile({
            file, uploadFor: "translate",
            onUploadProgress: evt => {
                const { loaded, total } = evt;
                setUploadProgress(loaded * 100 / total)
            }
        })(facade.dlc);

        if (error) {
            // TODO
            toast(error)
            return;
        }
        // create the ExcelData
        const [data, error2] = await facade.add({
            entityName: "Excel数据",
            formData: {
                文件: attachment,
            },
            fieldPaths: ["数据"]
        })
        if (error2) {
            toast(error2);
            return
        }


        // handle the data;
        if (data && data.数据) {
            setTermsToImport(data.数据.filter(r => r.原文 && r.译文));
        } else {
            setTermsToImport([])
        }

    }


    const existingContent = glossary.内容 || {};

    // const numToOverride = (termsToImport || []).filter(t => existingContent[t.原文]).length


    function renderTerm(t, index) {

        const eixstingTranslation = existingContent[t.原文]

        return (
            <div className={classNames("flex py-1", {
                "": eixstingTranslation,
            })} key={t.原文 + "::" + index}>
                <div className="w-[2rem] px-2 text-right text-[var(--gray11)] font-size-12 flex py-1.5">
                    {index + 1}
                </div>
                <div className="grow flex py-1">
                    <div className="w-1/2 px-2">
                        <div className="px-1">{t.原文}</div>
                    </div>
                    <div className="w-1/2 px-2 text-[var(--gray11)] flex flex-col gap-2">
                        { eixstingTranslation ? (
                            <>
                                <div className="px-2">{ t.译文 }</div>
                                <div className="flex flex-col gap-1 bg-[var(--teal3)] px-2 py-1.5">
                                    <div className="font-size-11">将覆盖原来的</div>
                                    <div>{ eixstingTranslation }</div>
                                </div>
                            </>
                        ) : <div className="px-2">{ t.译文 }</div>}
                    </div>
                </div>
                <div className="pl-2">
                    {iconButton(<i className='bx bx-trash cursor-pointer text-[var(--gray11)] font-size-13'></i>, _ => {
                        setTermsToImport(prev => {
                            return prev.filter(r => !(r.原文 == t.原文 && r.译文 == t.译文))
                        })
                    })}
                </div>
            </div>
        )
    }


    return (
        <div className="w-full pt-4 px-4 pb-6 flex flex-col gap-2" data-color={"violet"}>
            <div className="font-medium px-3">
                导入术语
            </div>
            {
                termsToImport ? (
                    <div className="h-[80vh] w-full flex flex-col gap-2">
                        <div className="flex gap-2 items-center">
                            <div className="text-[var(--gray11)] px-3 font-size-13">
                                共 {termsToImport.length} 条术语
                            </div>
                            <div className="flex gap-2">
                                <Button color="violet" onPress={_ => {
                                    track("glossary_import_terms_done")
                                    onConfirm(termsToImport.reduce((acc, t) => {
                                        return {
                                            ...acc,
                                            [t.原文]: t.译文
                                        }
                                    }, existingContent))
                                }}>
                                    确定导入
                                </Button>
                                <Button onPress={_ => {
                                    setTermsToImport();
                                }}>
                                    重新上传
                                </Button>
                            </div>
                        </div>
                        <div className="grow overflow-auto flex flex-col pl-3 pr-5">
                            {termsToImport.map(renderTerm)}
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="px-3 py-2">
                            <SelectFileButton {...{
                                accept: ".xlsx",
                                asButton: false,
                                className: `h-[260px] w-full rounded flex flex-col items-center justify-center gap-2 border-dashed border-2 border-[var(--color-8)] bg-[var(--color-2)]`,
                                color: "violet",

                                isUploading,
                                onSelectFile: async file => {
                                    track("glossary_import_select_file")
                                    setIsUploading(true);
                                    await uploadFile(file)
                                    setIsUploading(false);
                                }
                            }}
                            >
                                {
                                    isUploading ? (
                                        <div className="flex flex-col gap-6 items-center w-full px-4">
                                            <BeatLoader size={8} color="var(--violet8)" />
                                            <ProgressBar barClassName="!h-1.5" progress={uploadProgress}></ProgressBar>
                                        </div>
                                    ) : (
                                        <div className={`flex flex-col gap-2 text-[var(--color-11)] items-center`}>
                                            <i className={`bx bx-upload text-4xl text-[var(--color-8)]`}></i>
                                            <div className="font-size-12">上传 xlsx 文件（不超过 { }m）</div>
                                        </div>
                                    )
                                }
                            </SelectFileButton>
                        </div>
                        <div className="text-[var(--gray11)] px-4 font-size-12 flex flex-col gap-1">
                            <div>* 每行一条术语，第一行是标题行</div>
                            <div>* 标题分别为“原文”和“译文”</div>
                            <div>* 术语可以是公司名、产品名、人名或专业名词</div>
                            <div>* 这些术语应该只有一种对应翻译</div>
                            <div>* 如果有重复术语，将以后面的为准</div>
                        </div>
                    </>
                )
            }
        </div>
    )
}