
// This file is generated. Don't modify it.
import React from 'react'
export default function ArrowGuideRightIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M7.65097817,4.47358071 C12.0890815,4.50545616 12.8095137,6.8290515 13.4077119,7.95747094 C14.6053303,11.0740006 12.0740006,14 12.0740006,14 C12.9854684,8.00280602 7.94762494,8.2241809 7.65097817,8.24235273 L7.65097817,10.5296286 C7.47405727,10.7065495 7.20187128,10.7065495 7.02495038,10.5296286 L3.13269067,6.63736887 C2.95576978,6.46044797 2.95576978,6.18826198 3.13269067,6.01134109 L7.02495038,2.13269067 C7.20187128,1.95576978 7.47405727,1.95576978 7.65097817,2.13269067 L7.65097817,4.47358071 Z" id="ArrowGuideRightIcon-path-1"></path>
            </defs>
            <g id="Style" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Iconology" transform="translate(-48.000000, -92.000000)">
                    <g id="Icon/Arrow" transform="translate(48.000000, 92.000000)">
                        <g id="Icon/Arrow/Guide-Right">
                            <g id="Group-3">
                                <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                                <mask id="ArrowGuideRightIcon-mask-2" fill="white">
                                    <use href="#ArrowGuideRightIcon-path-1"></use>
                                </mask>
                                <use id="Mask" fill={color} transform="translate(8.364731, 8.000000) scale(1, -1) rotate(-180.000000) translate(-8.364731, -8.000000) " href="#ArrowGuideRightIcon-path-1"></use>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
