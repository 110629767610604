import React from 'react'

import createBlockTypeButton from './util/createBlockTypeButton';

import { MdOutlineHorizontalRule } from 'react-icons/md'

export default createBlockTypeButton({
    blockType: 'hr',
    children: (
        <MdOutlineHorizontalRule/>
    ),
});
