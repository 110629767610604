

import React, { useEffect, useState, useRef } from 'react'

import SelectInput from 'bwax-ui/components/inputs/SelectInput';
import DropdownMenu from 'bwax-ui/components/DropdownMenu';

import Button, { Pressable } from 'bwax-ui/components/Button';
import Modal from 'bwax-ui/components/Modal';

import { createDialogForm } from 'bwax-ui/components/Dialog';

import { toast } from 'bwax-ui/components/Toast';
import TextArea from 'bwax-ui/components/inputs/TextArea';

import { useTrack } from 'bwax-ui/track';

import GlossaryImport from './glossary/GlossaryImport';

export default function Translate_glossary({ data, facade, }) {

    const { currentUser } = data;

    const [glossaries, setGlossaries] = useState();

    const [currentGlossary, setCurrentGlossary] = useState();

    const track = useTrack();

    const entityName = "术语表";
    const fieldPaths = ["名称", "内容"];

    const [isLoading, setIsLoading] = useState(false);

    async function loadAll() {
        setIsLoading(true)
        const [result, error] = await facade.list({
            entityName: "术语表", condition: [[{ field: "创建者.id", op: "eq", value: currentUser.id }]],
            fieldPaths,
            sort: [{ field: "创建时间", order: "DESC" }],
            pageSize: 1000,
        });
        setIsLoading(false)
        return [result, error]
    }

    async function createNew(existing) {

        let index = 0;
        let name = "术语表";
        for (; existing.some(e => e.名称 == name);) {
            index = index + 1;
            name = "术语表" + index;
        }

        const [result, error] = await facade.add({
            entityName, formData: { 名称: name, 内容: {} }, fieldPaths,
        });

        if (!error && result) {
            setGlossaries(prev => {
                return [
                    result, ...prev,
                ]
            })
            setCurrentGlossary(result);
        }
    }

    useEffect(() => {
        // 如果一个术语表都没有，那么就自动创建一个
        (async () => {
            const [glossaries, error] = await loadAll();
            if (!error) {
                setGlossaries(glossaries);
                if (glossaries.length == 0) {
                    createNew(glossaries);
                } else {
                    setCurrentGlossary(glossaries[0]);
                }
            }
        })();
    }, [])


    async function updateItem(id, formData) {
        const [result, error] = await facade.update({
            entityName, id, formData, fieldPaths,
        });
        if (!error && result) {
            setGlossaries(prev => {
                return prev ? prev.map(p => p.id == result.id ? result : p) : prev
            })
            if (currentGlossary && currentGlossary.id == result.id) {
                setCurrentGlossary(result)
            }
        }
        return [result, error]
    };

    function updateName(id, name) {
        return updateItem(id, { 名称: name })
    }

    function renderGlossarActions(g) {
        const isDeletable = glossaries && glossaries.length > 0 && (!g.内容 || Object.keys(g.内容).length == 0);
        return (
            <DropdownMenu items={[{
                label: "重命名", onSelect: _ => {
                    // 
                    createDialogForm({
                        title: "修改术语表的名称",
                        description: "",
                        fields: [{
                            name: "title",
                            initialValue: g.名称,
                        }],
                        showLabel: false,
                        onDataCollected: async data => {
                            // console.log("collected data", data);
                            if (data.title !== undefined) {
                                const originalTitle = g.名称;
                                const [result, error] = await updateName(g.id, data.title);
                                if (!error) {
                                    toast({
                                        title: "术语表名称已修改",
                                        undoAction: async _ => { updateName(g.id, originalTitle) },
                                        duration: 2000
                                    });
                                }
                            }

                        }
                    })

                },
                icon: <i className='bx bx-edit' ></i>
            }, {
                label: "删除",
                isDisabled: !isDeletable,
                icon: <i className='bx bx-trash'></i>,
                onSelect: async _ => {
                    const [result, error] = await facade.delete({ entityName, id: g.id });
                    if (!error) {
                        toast({ title: `${g.名称}已被删除`, duration: 2000 });

                        const newGlossaries = glossaries.filter(p => p.id !== g.id);
                        setGlossaries(newGlossaries)
                        if (currentGlossary && currentGlossary.id == g.id) {
                            setCurrentGlossary(newGlossaries[0])
                        }
                    }

                }
            }]}>
                <div className="w-6 h-8 flex items-center justify-center cursor-pointer rounded hover:bg-[var(--gray3)] text-[var(--gray11)]">
                    <i className='bx bx-dots-vertical-rounded'></i>
                </div>
            </DropdownMenu>
        )
    }

    const [isAdding, setIsAdding] = useState(false);
    const [originalTextForUpdate, setOrignalTextForUpdate] = useState();

    function renderEditModal() {
        if ((isAdding || originalTextForUpdate) && currentGlossary) {
            return (
                <Modal className="max-w-xl" isMain={false} isOpen={true} contentClassName={"h-full !mt-2"} onOpenChange={open => {
                    if (!open) {
                        setIsAdding(false);
                        setOrignalTextForUpdate();
                    }
                }}>
                    {closeModal => {
                        return (
                            <EditTerm {...{
                                glossary: currentGlossary,
                                originalTextForUpdate,
                                onConfirm: async (newContent, isEnd) => {
                                    const [_, e] = await updateItem(currentGlossary.id, { 内容: newContent });
                                    if (!e) {
                                        if (isEnd) {
                                            closeModal()
                                        }
                                    } else {
                                        toast({ title : e })
                                    }
                                }
                            }} />
                        )
                    }}
                </Modal>
            )
        }
    }

    const [isImporting, setIsImporting] = useState(false);
    function renderImportModal() {
        if (isImporting && currentGlossary) {
            return (
                <Modal className="max-w-3xl" isMain={false} isOpen={true} contentClassName={"h-full !mt-2"} onOpenChange={open => {
                    if (!open) {
                        setIsImporting(false);
                    }
                }}>
                    {closeModal => {
                        return (
                            <GlossaryImport {...{
                                glossary: currentGlossary,
                                facade,
                                onConfirm: async newContent => {
                                    const [_, e] = await updateItem(currentGlossary.id, { 内容: newContent });
                                    if (!e) {
                                        closeModal()
                                    } else {
                                        toast({ title : e })
                                    }
                                }
                            }} />
                        )
                    }}
                </Modal>
            )
        }
    }


    function transformToTerms(content) {
        return Object.keys(content).reduce((acc, key) => {
            return [
                ...acc,
                [key, content[key]]
            ]
        }, [])
    }

    const terms = currentGlossary ? transformToTerms(currentGlossary.内容 || {}) : []

    return (
        <div className="flex flex-col h-full w-full max-w-3xl self-center pb-2 pt-12 sm:pt-2" >
            <div className="flex px-4 py-3 gap-2 items-center justify-between w-full">
                <SelectInput
                    selected={currentGlossary ? currentGlossary.id : ""}
                    onSelect={value => {
                        if (value == "createNew") {
                            createNew(glossaries);
                        } else {
                            setCurrentGlossary(
                                glossaries.find(g => g.id == value)
                            )
                        }
                    }}
                    items={[
                        ...((glossaries || []).map(g => ({ label: g.名称, value: g.id }))),
                        { label: "-- 添加术语表 --", value: "createNew" }
                    ]}
                    style={{
                        width: "12rem"
                    }}
                />
                {currentGlossary ? (
                    <div className="flex items-center px-1 sm:px-0 gap-1.5">
                        <div className="text-[var(--gray11)]">
                            共 {Object.keys(currentGlossary.内容 || {}).length} 条记录
                        </div>
                        {renderGlossarActions(currentGlossary)}
                    </div>
                ) : null}
            </div>
            <div className="flex px-4 py-1.5 gap-3 items-center w-full">
                <Button className="!px-6" onPress={_ => {
                    track("glossary_start_add")
                    setIsAdding(true)
                }}>添加术语</Button>
                <Button className="!px-6" onPress={_ => {
                    track("glossary_start_import")
                    setIsImporting(true)
                }}>导入术语</Button>
            </div>
            <div className="grow px-3 py-2 gap-1 flex flex-col overflow-auto">
                {terms.map(([k, v], index) => {
                    return (
                        <Pressable key={k} onPress={_ => {
                            setOrignalTextForUpdate(k);
                        }}>
                            <div key={k} className="flex w-full px-1 py-2 cursor-pointer hover:bg-[var(--gray3)] rounded">
                                <div className="w-[2rem] px-2 text-right text-[var(--gray11)] font-size-12 flex items-center">
                                    {index + 1}
                                </div>
                                <div className="grow flex">
                                    <div className="w-1/2 px-2">
                                        {k}
                                    </div>
                                    <div className="w-1/2 px-2 text-[var(--gray11)]">
                                        {v}
                                    </div>
                                </div>
                            </div>
                        </Pressable>
                    )
                })}
                {terms.length == 0 && !isLoading && currentGlossary ? (
                    <div className="w-full h-full flex flex-col justify-center items-center gap-4 pb-40">
                        <i className='bx bx-folder-open text-[5rem] text-[var(--gray8)]'></i>
                        <div className="text-[var(--gray10)]">[{currentGlossary.名称}]还没有内容</div>
                        <div className="text-[var(--gray10)] font-size-12 flex flex-col gap-2 px-2 py-4">
                            <div>* 术语可以是公司名、产品名、人名或专业名词</div>
                            <div>* 这些术语应该只有一种对应翻译</div>
                            <div>* 在翻译时，术语会被提供给大模型作为示例</div>
                        </div>
                    </div>
                ) : null}
            </div>
            {renderEditModal()}
            {renderImportModal()}
        </div>
    )
}


function EditTerm({ onConfirm, glossary, originalTextForUpdate }) {

    const [original, setOriginal] = useState(_ => {
        return originalTextForUpdate || ""
    });
    const [translation, setTranslation] = useState(_ => {
        return originalTextForUpdate ? (glossary.内容 || {})[originalTextForUpdate] : ""
    });

    const [toUpdate, setToUpdate] = useState(originalTextForUpdate);

    const [terms, setTerms] = useState([]);

    const content = glossary.内容 || {};

    useEffect(_ => {

        setTerms(Object.keys(content).reduce((acc, key) => {
            return [
                ...acc,
                [key, content[key]]
            ]
        }, []))

    }, [JSON.stringify(glossary.内容 || {})]);


    const isInputValid = original && original.trim() && translation && translation.trim();

    const isDirty = content[original] != translation;

    const shouldSave = isInputValid && isDirty;
    const canMove = isInputValid || (!original && !translation) || !isDirty;


    function packageContent() {
        const newTerms = toUpdate ? terms.map(([k, v]) => {
            return k == toUpdate ? [original, translation] : [k, v]
        }) : [
            ...terms,
            [original, translation],
        ];
        return newTerms.reduce((acc, [k, v]) => {
            return { ...acc, [k]: v }
        }, {})
    }

    function deleteCurrent() {
        const newTerms = toUpdate ? terms.filter(([k, v]) => {
            return k !== toUpdate
        }) : terms;
        return newTerms.reduce((acc, [k, v]) => {
            return { ...acc, [k]: v }
        }, {})
    }

    const updateIndex = toUpdate ? terms.map(([k]) => k).indexOf(toUpdate) : -1;


    const isLastOne = updateIndex == -1 || updateIndex == terms.length - 1;

    const isMatchingOther = original && ((toUpdate && toUpdate.trim() !== original.trim()) || !toUpdate) && content[original.trim()];

    const originalInputRef = useRef();
    const translateInputRef = useRef();

    return (
        <div className="w-full py-4 px-4 flex flex-col gap-2" data-color={"violet"}>
            <div className="font-medium px-3">
                {toUpdate ? "编辑术语" : "添加术语"}
            </div>
            <div className="flex flex-col sm:flex-row">
                <div className="flex flex-col gap-2 sm:w-1/2 px-2 py-2">
                    <div className="px-2 text-[var(--gray11)]">原文</div>
                    <TextArea className="w-full" value={original} onChange={setOriginal} placeholder={"输入原文"} styled={true}
                        autoFocus ref={originalInputRef}
                    />
                </div>
                <div className="flex flex-col gap-2 sm:w-1/2 px-2 py-2">
                    <div className="px-2 text-[var(--gray11)]">译文</div>
                    <TextArea className="w-full" value={translation} onChange={setTranslation} placeholder={"输入对应的译文"} styled={true}
                        ref={translateInputRef}
                    />
                </div>
            </div>
            <div className="text-[var(--gray11)] font-size-12 px-4 flex flex-col gap-1.5">
                {isMatchingOther ? (
                    <div>
                        术语表中已存在记录[{original}]。保存后会覆盖该记录的译文{toUpdate ? `并且删掉当前正在编辑的记录[${toUpdate}]。` : "。"}
                    </div>
                ) : null
                }
                <div>点击“上一个”、“下一个”或者“完成”都可以保存当前记录</div>
                <div>* 每个术语应该只有一种对应翻译，多义词不应被视为术语</div>
            </div>

            <div className="flex gap-2 justify-between px-2 py-2">
                <div className="flex gap-2 items-center">
                    <Button isDisabled={!(canMove && (terms.length > 0) && (updateIndex !== 0))} onPress={_ => {
                        if (shouldSave) {
                            onConfirm(packageContent(), false);
                        }
                        const prevIndex = updateIndex == -1 ? terms.length - 1 : updateIndex - 1;
                        const [k, v] = terms[prevIndex];
                        setToUpdate(k);
                        setOriginal(k);
                        setTranslation(v);
                        translateInputRef.current.focus()

                    }}>上一个</Button>
                    <Button isDisabled={!canMove || (isLastOne && !isInputValid)} onPress={_ => {

                        if (isLastOne) {
                            setToUpdate();
                            setOriginal("");
                            setTranslation("");
                            originalInputRef.current.focus();

                        } else {
                            const [k, v] = terms[updateIndex + 1];
                            setToUpdate(k);
                            setOriginal(k);
                            setTranslation(v);
                            translateInputRef.current.focus()
                        }
                        if (shouldSave) {
                            onConfirm(packageContent(), false);
                        }

                    }}>{isLastOne ? "添加下一个" : "下一个"}</Button>
                    {toUpdate ? (
                        <Pressable onPress={_ => {

                            onConfirm(deleteCurrent(toUpdate), false);

                            if (isLastOne) {
                                setToUpdate();
                                setOriginal("");
                                setTranslation("");
                                originalInputRef.current.focus();
                            } else {
                                const [k, v] = terms[updateIndex + 1];
                                setToUpdate(k);
                                setOriginal(k);
                                setTranslation(v);
                                translateInputRef.current.focus()
                            }

                            // move next


                        }}>
                            <div className="cursor-pointer flex justify-center items-center w-6 h-7 hover:bg-[var(--gray2)] text-[var(--gray10)] rounded">
                                <i className="bx bx-trash" />
                            </div>
                        </Pressable>

                    ) : null
                    }
                </div>
                <div className="flex gap-2">
                    <Button color="violet" isDisabled={!shouldSave} onPress={_ => {
                        onConfirm(packageContent(), true);
                    }}>完成</Button>
                </div>
            </div>
        </div>
    )
}



export function GlossaryView({ task }) {

    const content = task.术语表 || {};
    const terms = Object.keys(content).reduce((acc, key) => {
        return [
            ...acc,
            [key, content[key]]
        ]
    }, [])

    return (
        <div className="flex flex-col gap-1 w-full px-4 py-2">
            {terms.map(([k, v], index) => {
                return (
                    <div key={k} className="flex w-full px-1 py-2 rounded">
                        <div className="w-[2rem] px-2 text-right text-[var(--gray11)] font-size-12 flex items-center">
                            {index + 1}
                        </div>
                        <div className="grow flex">
                            <div className="w-1/2 px-2">
                                {k}
                            </div>
                            <div className="w-1/2 px-2 text-[var(--gray11)]">
                                {v}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}


