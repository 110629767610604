

import { jsx } from 'slate-hyperscript'

import { ELEMENT_TAGS, TEXT_TAGS } from './TagsToBeHandled'
import { Element } from 'slate'
import { isList } from './SlateEditor'
// import { message } from 'bwax-ui/auxiliary/uikit'

export default function (html) {
    function deserialize(el) {

        function isEndEmpty () {
            // 判断是否是末尾的空文本或者换行符
            function isEmpty(el) {
                // 当前元素是空文本
                if(el.nodeName === "BR") {
                    return true
                } else {
                    const cns = el.childNodes
                    if(cns.length === 1 && (cns[0].nodeType === 3 && (cns[0].data === "" || cns[0].data === "\n") || cns[0].nodeName === "BR")) {
                        return true
                    } else if (cns.length > 0) {
                        return isEmpty(cns[0])
                    } else {
                        return false
                    }
                }
                
            }

            function isContinuousEmpty(el) {
                // 是否是连续的空文本
                if(!el.nextSibling) {
                    return isEmpty(el)
                } else {
                    return isEmpty(el) && isContinuousEmpty(el.nextSibling)
                }
            }
            // console.log("el: ", el, "sibling", el.nextSibling, "isContinuousEmpty: ", isContinuousEmpty(el));
            
            return isContinuousEmpty(el)

        }

        if (el.nodeType === 3) { // text
            return el.textContent === '\n' ? '' : el.textContent.replace(/\n/g, '')
        } else if (el.nodeType !== 1) { // not an element
            return null
        } else if (el.nodeName === 'BR' && !isEndEmpty(el)) {
            return '\n'
        }

        const { nodeName, style } = el
        // console.log("nodeName: ", nodeName, "styles: ", style);
        let parent = el

        if (
            nodeName === 'PRE' &&
            el.childNodes[0] &&
            el.childNodes[0].nodeName === 'CODE'
        ) {
            parent = el.childNodes[0]
        }

        const parentClassName = parent.getAttribute('class') ? parent.getAttribute('class') : ''
        // 如果当前元素是 video 的父元素，则只传入第一个 childNode(video 元素)，其他子元素的是 videojs 产生的视频信息内容，不用处理。
        const actualChildNodes = parentClassName.includes('video-js') ? Array.from(parent.childNodes).slice(0, 1) : 
            Array.from(parent.childNodes)
        let children = actualChildNodes.map(deserialize).flat()
        if (children.length === 0) {
            children = [{ text: '' }]
        }

        if (el.nodeName === 'BODY') {
            return jsx('fragment', {}, children)
        }

        function isInvalidImage () {
            if(nodeName === "IMG") {
                const src = el.getAttribute('src')
                return !src.startsWith('http')
            } else {
                return false
            }
        }


        if (ELEMENT_TAGS[nodeName] && !isEndEmpty()) {
            const attrs = ELEMENT_TAGS[nodeName](el)

            // 如果是不能显示的 url，在页面上提示一下
            if(isInvalidImage()) {
                const { url } = attrs
                console.error(`invalid image src: ${url}`)
            }

            if (el.childNodes.length === 1 && el.childNodes[0].nodeType === 3) {
                // this element has only text child
                children = children.map(child => {
                    return {
                        text: child,
                        color: style.getPropertyValue("color"),
                        underline: style.getPropertyValue("text-decoration") === "underline",
                        bold: style.getPropertyValue("font-weight") === "bold" || style.getPropertyValue("font-weight") > 400,
                        italic: style.getPropertyValue("font-style") === "italic"
                    }
                })
            }

            if (attrs.type === "bulleted-list" || attrs.type === "numbered-list" && children.length > 0) {

                const listItems = children.filter(child => !!child).map(child => {
                    if (child.children && child.children.length > 0 && child.children.some(c => isList(c.type))) {
                        // has nest list
                        const { children } = child
                        const convertedNestListChildren = children.reduce((acc, child) => {
                            if(child.text) {
                                return [...acc, { type: 'paragraph', children: [child] }]
                            } else if (acc.length > 0 && isList(child.type)) {
                                const prev = acc[acc.length - 1]
                                if(prev.type === 'nestWrapper') {
                                    // 如果是 nw，则并入 nw 
                                    return acc.toSpliced(acc.length - 1, 1, {
                                        ...prev,
                                        children: [...prev.children, child]
                                    })
                                } else {
                                    // 如果不是 nw，则和此 child 合并成 nw
                                    return acc.toSpliced(acc.length - 1, 1, {
                                        type: 'nestWrapper',
                                        children: [prev, child]
                                    })
                                }
                            } else if (Element.isElement(child)) {
                                return [...acc, child]
                            }
                                
                            return acc
                        }, [])

                        return {
                            type: child.type,
                            children: convertedNestListChildren
                        }
                    } else if (child.text) {
                        return {
                            type: "list-item",
                            children: [{
                                type: "paragraph",
                                children: [child]
                            }]
                        }
                    } else if (child.type === 'list-item') {
                        return {
                            ...child,
                            children: child.children.reduce((acc, c) => {
                                if(Element.isElement(c)) {
                                    return [...acc, c]
                                } else if (!!c && !!c.text) {
                                    return [...acc, {
                                        type: 'paragraph',
                                        children: [c]
                                    }]
                                } 
                                
                                return acc
                            }, [])
                        }
                    } else {
                        return child
                    }

                })
                return [{ type: attrs.type, children: listItems }]
            } else if (attrs.type === "code" && children.length > 0) {
                const codeItems = children.map(child => {
                    return child.type === "paragraph" ? child :
                        child.text ? {
                            type: "paragraph",
                            children: [child]
                        } : {
                            type: "paragraph",
                            children: [{ text: child }]
                        }
                })
                return [{ type: attrs.type, children: codeItems }]
            } else if (attrs.type === 'block-quote' && children.length > 0) {
                // 转化 block-quote 中可能存在的 paragraph
                const contentStr = children.reduce((acc, child) => {
                    if(!!child) {
                        const isParagraph = child.type === 'paragraph'
                        return acc + (isParagraph ? child.children[0].text : (child.text ? child.text : child))
                    } else {
                        return acc
                    }
                    
                }, '')

                return [{ type: attrs.type, children: [{ text: contentStr }] }]
            } else {
                console.log("attrs: ", attrs);
                return jsx('element', attrs, children)
            }
        }

        function collectStyleToCss(cssText) {
            // console.log("nodeName: ", nodeName, "cssText: ", cssText);
            let convertedCss = {}
            if(cssText.trim() !== "") {
                
                const cssArr = cssText.split(";")
                /**
                 * https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1119
                 * 外部统一设置文本的 white-space 样式，因此去掉复制过来的 white-space（by 威豪 2022-11-15）
                 */
            
                cssArr.map(css => {
                    if(css) {
                        const k = css.split(":")[0].trim().replace(/\-(\w)/g, function (all, letter) {
                            return letter.toUpperCase();
                        })
                        const v = css.split(":")[1].trim()
                        if(k !== "whiteSpace") {
                            convertedCss[k] = v
                        }
                    }
                    
                })
            }

            return convertedCss
        }

        if (TEXT_TAGS[nodeName]) {
            let attrs = {
                ...TEXT_TAGS[nodeName](el),
                css: {
                    ...collectStyleToCss(style.cssText),
                    ...TEXT_TAGS[nodeName](el).css,
                }
            }

            return children.map(child => {
                if (typeof (child) === "object" && child.children) {
                    // console.log("convert text children: ");
                    return {
                        ...child,
                        children: child.children.map(c => {
                            if (c.text) {
                                return {
                                    ...c,
                                    ...attrs
                                }
                            } else {
                                return c
                            }

                        })
                    }
                } else {
                    return jsx('text', attrs, child)
                }

            })
        }

        return children
    }

    const parsed = new DOMParser().parseFromString(html, 'text/html')

    return deserialize(parsed.body)
}