

import React from 'react'

import classNames from 'classnames';
import RichText from "bwax-ui/basic/RichText";
import Avatar from 'bwax-ui/components/Avatar';

export default function FamilyOfficeModuleDetail({ data }) {

    const { name, desc, intro, host, cover } = data;

    return (
        <div className="flex flex-col w-full grow">
            <div className="flex justify-center relative">
                <div className="absolute bg-[var(--mauve2)] w-full h-3/4 -z-10">
                </div>
                <div className="max-w-[42rem]  pt-[3.5rem] sm:pt-[5rem] w-full rounded">
                    {
                        !cover ? null : (
                            <img className="w-full" src={cover.url} alt={"题图 |" + name} />
                        )
                    }
                </div>
            </div>
            <div className={classNames(
                "flex flex-col w-full pt-8 sm:pt-10",
            )}>
                <div className="flex flex-col max-w-[42rem] w-full gap-8 self-center px-6">
                    <h1 className="text-[28px] leading-[1.25]">{name}</h1>
                    <div className="mt-4 px-4 py-4 rounded bg-[var(--mauve2)] text-[16px]" style={{
                        borderLeft: "3px solid var(--cyan7)"
                    }}>
                        {desc}
                    </div>
                    {intro ? (
                        <RichText className="leading-[1.75] pb-4 text-[1rem] px-0.5 " params={{ value: intro }}></RichText>
                    ) : <div className="w-full h-8"/>}
                </div>
                {
                    host ? (
                        <div className="w-full bg-[var(--mauve2)] pt-16 pb-28 flex flex-col">
                            <div className="w-full max-w-[42rem] self-center px-8 sm:px-12 flex flex-col gap-4">
                                <div className="flex flex-col gap-2 self-start">
                                    <Avatar avatar={host.avatar} size={72}></Avatar>
                                    <div className="font-medium text-[1rem] self-center">{host.name}</div>
                                </div>
                                {host.intro ? (
                                    <RichText className="leading-[1.5] pb-4 text-[15px] px-2" params={{ value: host.intro }}></RichText>
                                ) : null}
                            </div>
                        </div>
                    ) : (
                        <div className="h-[10rem]">
                        </div>
                    )
                }
            </div>
        </div>
    )
}
