
// This file is generated. Don't modify it.
import React from 'react'
export default function ErrorIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M8.59134615,8.59134615 L8.59134615,4.98557692 L7.40865385,4.98557692 L7.40865385,8.59134615 L8.59134615,8.59134615 Z M8.59134615,11.0144231 L8.59134615,9.80288462 L7.40865385,9.80288462 L7.40865385,11.0144231 L8.59134615,11.0144231 Z M8,2 C9.09615933,2 10.1105723,2.27403572 11.0432692,2.82211538 C11.9471199,3.34134875 12.6586513,4.0528801 13.1778846,4.95673077 C13.7259643,5.88942774 14,6.90384067 14,8 C14,9.09615933 13.7259643,10.1105723 13.1778846,11.0432692 C12.6586513,11.9471199 11.9471199,12.6586513 11.0432692,13.1778846 C10.1105723,13.7259643 9.09615933,14 8,14 C6.90384067,14 5.88942774,13.7259643 4.95673077,13.1778846 C4.0528801,12.6490358 3.34134875,11.9326968 2.82211538,11.0288462 C2.27403572,10.0961492 2,9.08654389 2,8 C2,6.91345611 2.27403572,5.90385082 2.82211538,4.97115385 C3.35096418,4.06730317 4.06730317,3.35096418 4.97115385,2.82211538 C5.90385082,2.27403572 6.91345611,2 8,2 Z" id="ErrorIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Filling/Error">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="ErrorIcon-mask-2" fill="white">
                            <use href="#ErrorIcon-path-1"></use>
                        </mask>
                        <use id="error---material" fill={color} href="#ErrorIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
