
import React, { useEffect, useRef, useState } from 'react'

import { Range } from 'slate'
import { ReactEditor, useSelected } from 'slate-react'
import { Transforms } from 'slate'
import omit from 'lodash/omit'
import TextInput from 'bwax-ui/components/inputs/TextInput';

import { imageAlignStyle, processImageURL } from '../../RichTextRenderer'

const imageButtons = ["left", "center", "right", "seperator", "remove", "seperator", "caption"]
const tolerance = 6;

function WidthInfo ({ width = '100%' }) {
    const widthNum = width.match(/(.*)%/)
    const displayWidth = widthNum ? (parseFloat(widthNum[1]) < 100 ? `${parseFloat(widthNum[1]).toFixed(2)}%` : '100%') : width

    return (
        <div style={{ padding: "6px 10px", fontSize: 12, opacity: 0.75 }}>
            {displayWidth}
        </div>
    )
}

export default function Image({ editor, updateSelectedNode, updateToolbarButtons, setKeepInlineToolbar, 
    attributes, children, element, brickOpenedAt, path, captionInputVisible, setCaptionInputVisible }) {

    const { url, caption } = element

    const [resizable, setResizable] = useState(false)
    const [dimensions, setDimensions] = useState(null);
    const [lastActiveSelection, setLastActiveSelection] = useState(null)

    const selected = useSelected()

    const imageContainerRef = useRef()
    const imageBlockRef = useRef()

    const captionInputRef = useRef()

    const { selection } = editor

    useEffect(() => {
        if (selected) {
            updateSelectedNode(imageContainerRef.current)
            updateToolbarButtons([<WidthInfo key="width" width={dimensions ? dimensions.width : element.width}/>, "seperator", ...imageButtons])
            setLastActiveSelection(selection)
        } else if (lastActiveSelection && !Range.equals(selection, lastActiveSelection)) {
            updateSelectedNode(null)
            updateToolbarButtons(null)
            setCaptionInputVisible(false)
        }
    }, [selected])

    useEffect(() => {
        if(captionInputVisible && selected && captionInputRef.current) {
            captionInputRef.current.focus()
        }
    }, [captionInputVisible])

    const alignStyle = imageAlignStyle[element.align] //element.align === "center" ? { margin: "8px auto" } : {}

    const extraStyles = {
        ...omit(element, ['children', 'type', 'url']),
        ...alignStyle,
        width: dimensions ? dimensions.width : element.width
    }

    function mouseMove(e) {
        if (imageBlockRef && imageBlockRef.current) {
            const imageRect = imageBlockRef.current.getBoundingClientRect()
            const x = e.clientX - imageRect.left;
            const isLeft = x > 0 && x < tolerance
            const isRight = x >= imageRect.width - tolerance
            if (isLeft || isRight) {
                setResizable(true)
            } else {
                setResizable(false)
            }
        }
    }

    function mouseDown(e) {
        if (!resizable) {
            return;
        }

        if (!selected) {
            Transforms.select(editor, path)
        }

        const imageRect = imageBlockRef.current.getBoundingClientRect()
        const x = e.clientX - imageRect.left;
        const isLeft = x > 0 && x < tolerance
        const isRight = x >= imageRect.width - tolerance

        if (imageBlockRef && imageBlockRef.current && typeof (document) !== "undefined") {

            const imageDom = imageBlockRef.current
            const startX = e.clientX;
            const imageWidth = parseInt(document.defaultView.getComputedStyle(imageDom).width, 10);


            let width = 0;
            let widthPerc = 0

            // Do the actual drag operation
            function doDrag(dragEvent) {
                dragEvent.preventDefault();
                const containerRect = imageContainerRef.current.getBoundingClientRect()

                if (isLeft && width <= containerRect.width) {
                    width = imageWidth + startX - dragEvent.clientX
                } else if (isRight && width <= containerRect.width) {
                    width = imageWidth + dragEvent.clientX - startX
                }

                widthPerc = ((100 / imageContainerRef.current.clientWidth) * width).toFixed(2);

                let newState = {};
                if (isLeft || isRight) {
                    newState.width = `${widthPerc}%`
                }

                setDimensions(newState);
            }

            function stopDrag() {
                if(widthPerc !== 0) {
                    Transforms.setNodes(
                        editor,
                        { width: `${widthPerc}%` },
                        { match: (node) => node.type === "image" }
                    )
                }

                document.removeEventListener('mousemove', doDrag, false);
                document.removeEventListener('mouseup', stopDrag, false);
            }

            document.addEventListener('mousemove', doDrag, false);
            document.addEventListener('mouseup', stopDrag, false)
        }
    };

    function renderCaptionInput () {
        function changeCaption (value) {
            Transforms.setNodes(editor, { caption: value })
        }
        const inputEl = (
            <TextInput autoFocus={captionInputVisible && selected} placeholder={'添加图片描述'}
                ref={captionInputRef}
                className="text-[var(--gray10)] text-center text-sm mt-1.5"
                value={caption || ''} onChange={value => changeCaption(value)}
                onFocus={() => setKeepInlineToolbar(true)}
                onKeyDown={e => {
                    if(e.key === 'Enter' && e.target.selectionEnd === e.target.value.length) {
                        // 在 caption 末尾按 enter，新增一行
                        e.preventDefault()
                        Transforms.insertNodes(editor, { type: 'paragraph', children: [{ text: ''}] }, {
                            at: path.toSpliced(path.length - 1, 1, path[path.length - 1] + 1),
                            select: true
                        })
                        ReactEditor.focus(editor)
                    }
                }}
            />
        )

        return caption ? inputEl : (selected && captionInputVisible ? inputEl : null)
    }

    const altProp = caption ? { alt: caption } : {}

    return (
        <figure contentEditable={false}  {...attributes}>
            {children}
            <div ref={imageContainerRef}>
                <div className='editor-image-block' ref={imageBlockRef}
                    style={{ ...extraStyles, cursor: resizable ? "ew-resize" : "default" }}
                    onMouseDown={e => mouseDown(e)}
                    onMouseMove={e => mouseMove(e)}
                >
                    <img className={`${selected ? "active" : ""}`} src={processImageURL(url)} {...altProp}/>
                    {
                        brickOpenedAt ? (
                            <div className='editor-block-mask'/>
                        ) : null
                    }
                    { renderCaptionInput() }
                </div>
            </div>
        </figure>
    )
}
