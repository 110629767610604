import React from 'react'

class HealthIcon extends React.Component {

    render() {
        return (

            <svg width="1em" height="1em" viewBox="0 0 16 16">
                <defs>
                    <linearGradient x1="100%" y1="100%" x2="0%" y2="0%" id="linearGradient-1">
                        <stop stopColor="#3C3D3D" offset="0%"></stop>
                        <stop stopColor="#141414" offset="100%"></stop>
                    </linearGradient>
                    <linearGradient x1="100%" y1="100%" x2="0%" y2="0%" id="linearGradient-2">
                        <stop stopColor="#FFCF99" offset="0%"></stop>
                        <stop stopColor="#FFE2C2" offset="100%"></stop>
                    </linearGradient>
                </defs>
                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Icon/VIP-rights/健康" fillRule="nonzero">
                        <g>
                            <circle id="Oval" fill="url(#linearGradient-1)" cx="8" cy="8" r="8"></circle>
                            <path d="M4.57477113,8.60250155 L5.48492737,8.60250155 L6.09130374,7.81441211 L6.99354789,9.38648979 L7.94544305,7.52070101 L8.78770357,10.272381 L9.5827691,8.60250155 L11.4306949,8.60250155 L11.7786227,8.26123171 L9.3619989,8.26123171 L8.85837012,9.31900533 L8.02638531,6.60089633 L6.97553077,8.66065322 L6.13363546,7.19372785 L5.31227043,8.26123171 L4.22711758,8.26123171 L4.57477113,8.60250155 Z M4.57477113,8.60250155 L3.32725603,8.60250155 L3.32725603,8.26123171 L4.22711758,8.26123171 L3,7.05664677 L6.10893229,4 L8.00331433,5.86252262 L9.86388058,4.03324707 L12.9728129,7.08989385 L11.7786227,8.26123171 L13.1663706,8.26123171 L13.1663706,8.60250155 L11.4306949,8.60250155 L8.00331433,11.9642951 L7.85144856,11.8190118 L4.57477113,8.60250155 Z M5.3985989,8.26123171 L5.26018801,8.32892169 L5.31227043,8.26123171 L5.3985989,8.26123171 Z" id="Combined-Shape" fill="url(#linearGradient-2)"></path>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export default HealthIcon
