
// This file is generated. Don't modify it.
import React from 'react'
export default function HotIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M8,14 C5.58990909,14 3.63636364,12.0464545 3.63636364,9.63636364 C3.63636364,8.34963636 4.06263636,7.31081818 4.91490909,6.51990909 C5.08566474,6.36132642 5.33830671,6.32873953 5.54371354,6.4388031 C5.74912038,6.54886668 5.86191691,6.77726723 5.82445455,7.00727273 C5.68509091,7.86445455 5.94827273,8.29318182 6.61372727,8.29318182 C8.35781818,8.29318182 4.71581818,2.89318182 9.09090909,2 C8,3.86381818 10.7531818,5.99 11.5454545,7.09181818 C12.0603636,7.808 12.3636364,8.68672727 12.3636364,9.63636364 C12.3636364,12.0464545 10.4100909,14 8,14 Z" id="HotIcon-path-1"></path>
            </defs>
            <g id="Style" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Iconology" transform="translate(-360.000000, -124.000000)">
                    <g id="Icon" transform="translate(48.000000, 124.000000)">
                        <g id="Icon/hot" transform="translate(312.000000, 0.000000)">
                            <g id="Group-3">
                                <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                                <mask id="HotIcon-mask-2" fill="white">
                                    <use href="#HotIcon-path-1"></use>
                                </mask>
                                <use id="Mask" fill={color} fillRule="nonzero" href="#HotIcon-path-1"></use>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
