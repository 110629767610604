// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Plate$BwaxMobile = require("../../../../bwax-js/ml/plate.bs.js");
var Alipay$BwaxMobile = require("../../utils/alipay.bs.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Lang_eval$BwaxMobile = require("../../../../bwax-js/ml/lang/lang_eval.bs.js");
var Runtime_common$BwaxMobile = require("../../../../bwax-js/ml/lang/mod/runtime_common.bs.js");

var module_name = "AliPay";

function fail(name) {
  throw [
        Lang_eval$BwaxMobile.Eval_exn,
        "Cmd." + (name + ": Invalid Arguments")
      ];
}

function func(args) {
  return /* tuple */[
          /* V_tagged */Block.__(6, [
              "Cmd_AliPay_initiatePay",
              args
            ]),
          /* No_term */0
        ];
}

function func$1(args) {
  return /* tuple */[
          /* V_tagged */Block.__(6, [
              "Cmd_AliPay_initiatePayWithParams",
              args
            ]),
          /* No_term */0
        ];
}

var externals_vals_000 = /* tuple */[
  "initiatePay",
  Runtime_common$BwaxMobile.pack_func(3, func)
];

var externals_vals_001 = /* :: */[
  /* tuple */[
    "initiatePayWithParams",
    Runtime_common$BwaxMobile.pack_func(4, func$1)
  ],
  /* [] */0
];

var externals_vals = /* :: */[
  externals_vals_000,
  externals_vals_001
];

function handle_error(on_error, cont, error) {
  var error_v = Runtime_common$BwaxMobile.pack_string(error);
  var msg = Lang_eval$BwaxMobile.apply_value_args(undefined, on_error, /* :: */[
        error_v,
        /* [] */0
      ], Runtime_common$BwaxMobile.nt);
  return Curry._1(cont, /* Ok */Block.__(0, [/* :: */[
                  msg,
                  /* [] */0
                ]]));
}

function initiate_pay_command(context, param, on_trunk_msgs, on_branch_msgs) {
  var cmd = param[0];
  if (typeof cmd === "number" || cmd.tag !== /* V_tagged */6) {
    return false;
  } else {
    switch (cmd[0]) {
      case "Cmd_AliPay_initiatePay" :
          var match = cmd[1];
          if (match) {
            var match$1 = match[0][0];
            if (typeof match$1 === "number" || match$1.tag !== /* V_literal */5) {
              return false;
            } else {
              var match$2 = match$1[0];
              if (typeof match$2 === "number" || match$2.tag !== /* String */2) {
                return false;
              } else {
                var match$3 = match[1];
                if (match$3) {
                  var match$4 = match$3[1];
                  if (match$4 && !match$4[1]) {
                    var on_error = match$4[0];
                    var on_initiated = match$3[0];
                    console.log("Start ali payment");
                    Curry._1(on_trunk_msgs, /* Ok */Block.__(0, [/* [] */0]));
                    try {
                      Alipay$BwaxMobile.initiate_pay(context[/* query_runner */4], match$2[0], /* record */[/* hbfq */undefined], (function (payFormHtml) {
                              var v = Runtime_common$BwaxMobile.pack_maybe(Runtime_common$BwaxMobile.pack_string, payFormHtml);
                              var msg = Lang_eval$BwaxMobile.apply_value(undefined, on_initiated, v, Runtime_common$BwaxMobile.nt);
                              return Curry._1(on_branch_msgs, /* Ok */Block.__(0, [/* :: */[
                                              msg,
                                              /* [] */0
                                            ]]));
                            }), (function (param) {
                              return handle_error(on_error, on_branch_msgs, param);
                            }));
                    }
                    catch (raw_e){
                      var e = Caml_js_exceptions.internalToOCamlException(raw_e);
                      Curry._1(on_branch_msgs, /* Error */Block.__(1, [e]));
                    }
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              }
            }
          } else {
            return false;
          }
      case "Cmd_AliPay_initiatePayWithParams" :
          var match$5 = cmd[1];
          if (match$5) {
            var match$6 = match$5[0][0];
            if (typeof match$6 === "number" || match$6.tag !== /* V_literal */5) {
              return false;
            } else {
              var match$7 = match$6[0];
              if (typeof match$7 === "number" || match$7.tag !== /* String */2) {
                return false;
              } else {
                var match$8 = match$5[1];
                if (match$8) {
                  var match$9 = match$8[0][0];
                  if (typeof match$9 === "number" || match$9.tag !== /* V_record */2) {
                    return false;
                  } else {
                    var match$10 = match$8[1];
                    if (match$10) {
                      var match$11 = match$10[1];
                      if (match$11 && !match$11[1]) {
                        var on_error$1 = match$11[0];
                        var on_initiated$1 = match$10[0];
                        console.log("Start ali payment");
                        Curry._1(on_trunk_msgs, /* Ok */Block.__(0, [/* [] */0]));
                        var match$12 = Plate$BwaxMobile.List.assoc("hbfq", match$9[0]);
                        var hbfq;
                        if (match$12 !== undefined) {
                          var match$13 = match$12[0];
                          if (typeof match$13 === "number" || match$13.tag) {
                            hbfq = undefined;
                          } else {
                            var match$14 = match$13[0];
                            if (match$14) {
                              var match$15 = match$14[0][0];
                              if (typeof match$15 === "number" || match$15.tag !== /* V_literal */5) {
                                hbfq = undefined;
                              } else {
                                var match$16 = match$15[0];
                                if (typeof match$16 === "number" || match$16.tag) {
                                  hbfq = undefined;
                                } else {
                                  var match$17 = match$14[1];
                                  if (match$17) {
                                    var match$18 = match$17[0][0];
                                    if (typeof match$18 === "number" || match$18.tag !== /* V_literal */5) {
                                      hbfq = undefined;
                                    } else {
                                      var match$19 = match$18[0];
                                      hbfq = typeof match$19 === "number" || match$19.tag || match$17[1] ? undefined : /* tuple */[
                                          match$16[0],
                                          match$19[0]
                                        ];
                                    }
                                  } else {
                                    hbfq = undefined;
                                  }
                                }
                              }
                            } else {
                              hbfq = undefined;
                            }
                          }
                        } else {
                          hbfq = undefined;
                        }
                        try {
                          Alipay$BwaxMobile.initiate_pay(context[/* query_runner */4], match$7[0], /* record */[/* hbfq */hbfq], (function (payFormHtml) {
                                  var v = Runtime_common$BwaxMobile.pack_maybe(Runtime_common$BwaxMobile.pack_string, payFormHtml);
                                  var msg = Lang_eval$BwaxMobile.apply_value(undefined, on_initiated$1, v, Runtime_common$BwaxMobile.nt);
                                  return Curry._1(on_branch_msgs, /* Ok */Block.__(0, [/* :: */[
                                                  msg,
                                                  /* [] */0
                                                ]]));
                                }), (function (param) {
                                  return handle_error(on_error$1, on_branch_msgs, param);
                                }));
                        }
                        catch (raw_e$1){
                          var e$1 = Caml_js_exceptions.internalToOCamlException(raw_e$1);
                          Curry._1(on_branch_msgs, /* Error */Block.__(1, [e$1]));
                        }
                        return true;
                      } else {
                        return false;
                      }
                    } else {
                      return false;
                    }
                  }
                } else {
                  return false;
                }
              }
            }
          } else {
            return false;
          }
      default:
        return false;
    }
  }
}

var command_handlers = /* :: */[
  initiate_pay_command,
  /* [] */0
];

var externals_000 = module_name;

var externals = /* tuple */[
  externals_000,
  externals_vals
];

var no_type = /* No_term */0;

var src = "\n\nmodule AliPay;\n\ntype alias ExtendedParams = \n  { hbfq: (Int, Int)  -- 花呗分期参数，两个数，分别是分期数，以及商家负担的手续费百分比；如果不提供则不启用 \n  }\n;\n\n--- 支付宝 API\n-- orderId -> GotCodeUrl -> GotError -> cmd\ninitiatePay: String -> (Maybe String -> msg) -> (String -> msg) -> Cmd msg = external;\n\n-- orderId -> params -> GotCodeUrl -> GotError -> cmd\ninitiatePayWithParams: String -> ~ ExtendedParams -> (Maybe String -> msg) -> (String -> msg) -> Cmd msg = external;\n\n";

exports.no_type = no_type;
exports.module_name = module_name;
exports.src = src;
exports.fail = fail;
exports.externals_vals = externals_vals;
exports.handle_error = handle_error;
exports.initiate_pay_command = initiate_pay_command;
exports.command_handlers = command_handlers;
exports.externals = externals;
/* externals_vals Not a pure module */
