

export const ELEMENT_TAGS = {
    A: el => ({ type: 'link', url: el.getAttribute('href') }),
    BLOCKQUOTE: () => ({ type: 'block-quote' }),
    H1: () => ({ type: 'h1' }),
    H2: () => ({ type: 'h2' }),
    H3: () => ({ type: 'h3' }),
    H4: () => ({ type: 'h4' }),
    H5: () => ({ type: 'h5' }),
    H6: () => ({ type: 'h6' }),
    IMG: el => ({ type: 'image', url: el.getAttribute('src') }),
    LI: () => ({ type: 'list-item' }),
    OL: () => ({ type: 'numbered-list' }),
    P: () => ({ type: 'paragraph' }),
    // PRE: () => ({ type: 'code' }),
    UL: () => ({ type: 'bulleted-list' }),
    HR: () => ({ type: 'hr' }),
    VIDEO: el => ({ type: "video", url: el.getAttribute('src') }),
    IFRAME: el => ({ type: "video", url: el.getAttribute('src') }), // handle qq video
}

export const TEXT_TAGS = {
    EM: () => ({ italic: true }),
    I: () => ({ italic: true }),
    U: () => ({ underline: true }),
    DEL: () => ({ css: { textDecoration: "line-through" } }),
    S: () => ({ css: { textDecoration: "line-through" }  }),
    STRONG: () => ({ bold: true }),
    B: () => ({ bold: true }),
    SUP: () => ({ sup: true }),
    SUB: () => ({ sub: true }),
    ABBR: () => ({}),
    AREA: () => ({}),
    ARTICLE: () => ({}),
    ASIDE: () => ({}),
    CAPTION: () => ({}),
    MARK: () => ({}),
    TITLE: () => ({}),
    FONT: () => ({}),
    SPAN: () => ({}),
    DIV: () => ({}),
    FIGURE: () => ({}),
    FOOTER: () => ({}),
    HEADER: () => ({}),
    LABEL: () => ({})
}