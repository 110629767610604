
// This file is generated. Don't modify it.
import React from 'react'
export default function PlusIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <rect id="PlusIcon-path-1" x="0" y="0" width="16" height="16"></rect>
                <path d="M7.5,7.5 L7.5,1.5 C7.5,1.22385763 7.72385763,1 8,1 C8.27614237,1 8.5,1.22385763 8.5,1.5 L8.5,7.5 L14.5,7.5 C14.7761424,7.5 15,7.72385763 15,8 C15,8.27614237 14.7761424,8.5 14.5,8.5 L8.5,8.5 L8.5,14.5 C8.5,14.7761424 8.27614237,15 8,15 C7.72385763,15 7.5,14.7761424 7.5,14.5 L7.5,8.5 L1.5,8.5 C1.22385763,8.5 1,8.27614237 1,8 C1,7.72385763 1.22385763,7.5 1.5,7.5 L7.5,7.5 Z" id="PlusIcon-path-3"></path>
            </defs>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Plus">
                    <g>
                        <mask id="PlusIcon-mask-2" fill="white">
                            <use href="#PlusIcon-path-1"></use>
                        </mask>
                        <mask id="PlusIcon-mask-4" fill="white">
                            <use href="#PlusIcon-path-3"></use>
                        </mask>
                        <use id="Combined-Shape" fill={color} fillRule="nonzero" href="#PlusIcon-path-3"></use>
                        <g id="Color/UI/Text" mask="url(#PlusIcon-mask-4)" fill={color} fillRule="nonzero">
                            <rect id="light-gray" x="0" y="0" width="16" height="16"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
