import React from 'react'
import AIInputStreamMockup from './AIInputStreamMockup';
import AIInputStreamImpl from './AIInputStreamImpl';

const test = false;

export default function AIInputStream(props) {

    if(test) {
        return (
            <AIInputStreamMockup {...props}></AIInputStreamMockup>
        )
    } else {
        return (
            <AIInputStreamImpl {...props}></AIInputStreamImpl>
        )
    }
    
}
