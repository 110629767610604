


import React, { useRef } from 'react'

import "./FileInput.less"
import FileIcon from '../FileIcon';

import { downloadURL } from 'bwax-ui/ml/FrontEndHelper';
import { useButton } from 'react-aria';

export default function FileDisplay({ value }) {

    const ref = useRef();
    const { buttonProps } = useButton({
        onPress: _ => {
            if(value && value.url) {
                downloadURL(value.url)
            }
        },
    }, ref);

    return (
        <div className="lc-file-line" {...buttonProps} ref={ref}>
            { value ? 
                <>
                    <FileIcon file={value} />
                    <div className="file-title">{ value.title }</div>
                </>:
                <div className="placeholder">
                    <i className='bx bx-folder-open'></i>没有文件
                </div>
            }
        </div>
    )
}
