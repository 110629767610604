


export default function getHtmlColors() {
    return {
        "red": [
            "IndianRed",
            "LightCoral",
            "Salmon",
            "DarkSalmon",
            "LightSalmon",
            "Crimson",
            "Red",
            "FireBrick",
            "DarkRed"
        ],
        "pink": [
            "Pink",
            "LightPink",
            "HotPink",
            "DeepPink",
            "MediumVioletRed",
            "PaleVioletRed"
        ],
        "orange": [
            // "LightSalmon",
            "Coral",
            "Tomato",
            "OrangeRed",
            "DarkOrange",
            "Orange"
        ],
        "yellow": [
            "Gold",
            "Yellow",
            "LightYellow",
            "LemonChiffon",
            "LightGoldenrodYellow",
            "PapayaWhip",
            "Moccasin",
            "PeachPuff",
            "PaleGoldenrod",
            "Khaki",
            "DarkKhaki"
        ],
        "purple": [
            "Lavender",
            "Thistle",
            "Plum",
            "Violet",
            "Orchid",
            "Fuchsia",
            "Magenta",
            "MediumOrchid",
            "MediumPurple",
            "RebeccaPurple",
            "BlueViolet",
            "DarkViolet",
            "DarkOrchid",
            "DarkMagenta",
            "Purple",
            "Indigo",
            "SlateBlue",
            "DarkSlateBlue",
            "MediumSlateBlue"
        ],
        "green": [
            "GreenYellow",
            "Chartreuse",
            "LawnGreen",
            "Lime",
            "LimeGreen",
            "PaleGreen",
            "LightGreen",
            "MediumSpringGreen",
            "SpringGreen",
            "MediumSeaGreen",
            "SeaGreen",
            "ForestGreen",
            "Green",
            "DarkGreen",
            "YellowGreen",
            "OliveDrab",
            "Olive",
            "DarkOliveGreen",
            "MediumAquamarine",
            "DarkSeaGreen",
            "LightSeaGreen",
            "DarkCyan",
            "Teal"
        ],
        "blue": [
            "Aqua",
            "Cyan",
            "LightCyan",
            "PaleTurquoise",
            "Aquamarine",
            "Turquoise",
            "MediumTurquoise",
            "DarkTurquoise",
            "CadetBlue",
            "SteelBlue",
            "LightSteelBlue",
            "PowderBlue",
            "LightBlue",
            "SkyBlue",
            "LightSkyBlue",
            "DeepSkyBlue",
            "DodgerBlue",
            "CornflowerBlue",
            // "MediumSlateBlue",
            "RoyalBlue",
            "Blue",
            "MediumBlue",
            "DarkBlue",
            "Navy",
            "MidnightBlue"
        ],
        "brown": [
            "Cornsilk",
            "BlanchedAlmond",
            "Bisque",
            "NavajoWhite",
            "Wheat",
            "BurlyWood",
            "Tan",
            "RosyBrown",
            "SandyBrown",
            "Goldenrod",
            "DarkGoldenrod",
            "Peru",
            "Chocolate",
            "SaddleBrown",
            "Sienna",
            "Brown",
            "Maroon"
        ],
        "white": [
            "White",
            "Snow",
            "HoneyDew",
            "MintCream",
            "Azure",
            "AliceBlue",
            "GhostWhite",
            "WhiteSmoke",
            "SeaShell",
            "Beige",
            "OldLace",
            "FloralWhite",
            "Ivory",
            "AntiqueWhite",
            "Linen",
            "LavenderBlush",
            "MistyRose",
        ],
        "gray": [
            "Gainsboro",
            "LightGray",
            "Silver",
            "DarkGray",
            "Gray",
            "DimGray",
            "LightSlateGray",
            "SlateGray",
            "DarkSlateGray",
            "Black"
        ]
    }
}