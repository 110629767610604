
// This file is generated. Don't modify it.
import React from 'react'
export default function KuaiBaoIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M12.992213,9.12387455 L13.938307,11 L12.6935946,11 L12.3700578,10.3580432 L12.04632,11 L9.91620196,11 L9.91620196,5 L13.6487315,5 L13.6487315,6.86329532 L11.8425521,6.86329532 L12.5203718,5.52265906 L11.073901,5.52265906 L11.073901,7.12552522 L14,7.12552522 L12.992213,9.12387455 Z M11.073901,7.7877401 L11.073901,10.4602341 L11.7477016,9.12387455 L11.073901,7.7877401 Z M8.51032404,8.47561524 L8.51032404,6.88062725 L8.11163024,6.88062725 L8.11163024,5.65253602 L8.51032404,5.65253602 L8.51032404,5 L9.66822406,5 L9.66822406,11 L8.51032404,11 L8.51032404,9.7169868 L8.11082642,9.87229892 L8.11163024,8.62980192 L8.51032404,8.47561524 Z M2.57211756,6.17767107 L2.57211756,5 L3.73001758,5 L3.73001758,8.03038716 L4.41225822,8.03038716 L4.72534539,6.17496999 L3.97759356,6.17496999 L3.97759356,5.65231092 L4.81356443,5.65231092 L4.92388847,5 L6.16839989,5.00090036 L6.05887967,5.65231092 L7.65666918,5.65231092 L7.65666918,8.55304622 L5.57076112,8.55304622 L5.15900528,10.9997749 L3.91087666,10.9997749 L4.32383823,8.55304622 L3.73001758,8.55304622 L3.73001758,10.9997749 L2.57211756,10.9997749 L2.57211756,8.55597239 L2,8.55597239 L2,6.17767107 L2.57211756,6.17767107 Z M5.97086159,6.17496999 L5.65857824,8.03038716 L6.48952524,8.03038716 L6.48952524,6.17496999 L5.97066063,6.17496999 L5.97086159,6.17496999 Z M5.71666918,10.0278361 C5.71666918,9.49077131 6.15061655,9.05544718 6.68666918,9.05544718 C7.22229638,9.05544718 7.65666918,9.49077131 7.65666918,10.0278361 C7.65666918,10.5646759 7.22229638,11 6.68666918,11 C6.15061655,11 5.71666918,10.5646759 5.71666918,10.027611 L5.71666918,10.0278361 Z" id="KuaiBaoIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Social/Kuaibao">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="KuaiBaoIcon-mask-2" fill="white">
                            <use href="#KuaiBaoIcon-path-1"></use>
                        </mask>
                        <use id="Shape" fill={color} fillRule="nonzero" href="#KuaiBaoIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
