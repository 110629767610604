

import Loading from 'bwax-ui/components/Loading';
import React, { useState, useEffect } from 'react'
import { guid } from 'bwax/utils'

import Link from 'bwax-ui/page/Link';
import Button, { Pressable } from 'bwax-ui/components/Button';

import { copy } from "bwax-ui/ml/FrontEndHelper";
import { toast } from 'bwax-ui/components/Toast';
import QrCode from 'bwax-ui/lazy/QrCode';

import TextInput from 'bwax-ui/components/inputs/TextInput';
import TextArea from 'bwax-ui/components/inputs/TextArea';

import { loadReferralCode } from './TranslationInvitation';
import { addQueryParam } from 'bwax/ml/lang/mod/builtin/StringHelper';

export default function TranslationShareSetting({ viewEnv, taskId, facade, currentUser }) {

    const { webEnv } = viewEnv;
    const { host, isSandbox } = webEnv;

    const [share, setShare] = useState();

    const [referralCode, setReferralCode] = useState();

    // const 


    const [title, setTitle] = useState("");
    const [desc, setDesc] = useState("");

    // 先查找有没有对应的分享，如果没有则创建一个；
    //
    const entityName = "分享";
    const fieldPaths = [
        // "翻译.文档", "翻译.翻译后文档", "翻译.源语言", "翻译.目标语言", 
        "翻译.文档",
        "code", "已暂停", "标题", "简介"
    ];
    async function initShare() {

        const referralCode = await loadReferralCode({ facade, currentUser });

        setReferralCode(referralCode);

        const [existingShare, error] = await facade.findOne({
            entityName, condition: [[{ field: "翻译.id", op: "eq", value: taskId }]], fieldPaths,
        });
        if (error) {
            // TODO error handling
            setShare();
            return
        }
        if (existingShare) {
            setTitle(existingShare.标题 || "");
            setDesc(existingShare.简介 || "");

            setShare(existingShare);
            return
        } else {
            //  create one
            const code = guid();

            const [newShare, error] = await facade.add({
                entityName, formData: { 翻译: taskId, code }, fieldPaths,
            });
            if (error) {
                // TODO error handling
                return
            } else {
                setShare(newShare)
            }
        }
    }

    async function updateShare(shareId, formData) {
        const [newShare, error] = await facade.update({
            entityName, formData, fieldPaths, id: shareId,
        });
        if (error) {
            // TODO error handling
            return
        } else {
            toast({ title: "已更新", duration: 2000 })
            setShare(newShare)
        }
    }

    useEffect(() => {
        // 
        initShare();

    }, [taskId]);


    function buildURL() {
        let url = ""
        if (share) {
            url = "https://" + host + (isSandbox ? "/sandbox" : "") + "/u/share/" + share.code;
        }
        if (share && referralCode) {
            url = addQueryParam("ur", referralCode.内容.toLowerCase(), url);
        }
        return url
    };
    const url = buildURL();

    function renderLoading() {
        return (
            <div className="flex justify-center items-center h-[10rem]">
                <Loading />
            </div>
        )
    }



    const isTitleDirty = share ? title.trim() !== (share.标题 || "") : false;
    const isDescDirty = share ? desc.trim() !== (share.简介 || "") : false;
    const isDirty = isTitleDirty || isDescDirty;



    return (
        <div className="flex flex-col px-6 sm:px-8 pb-6 gap-6">
            {share ? (
                <>
                    <div className="flex flex-col gap-4">
                        <div className="flex gap-2 items-center">
                            <div className="font-medium font-size-16">
                                <i className='bx bx-share-alt text-[var(--gray10)] mr-2'></i>分享链接
                            </div>
                            {share.已暂停 ? (
                                <div className="px-2 py-0.5 bg-[var(--yellow4)] text-[var(--yellow11)] font-size-12 rounded flex items-center gap-2">
                                    已暂停分享
                                </div>
                            ) : (
                                <div className="px-2 py-0.5 bg-[var(--grass4)] text-[var(--grass11)] font-size-12 rounded flex items-center gap-2">
                                    正在分享 <i className='bx bx-check-circle font-size-14'></i>
                                </div>
                            )}
                        </div>
                        <div className="sm:px-2 break-all">
                            <Link to={url} className="underline text-[var(--violet10)]" openWithNewTab={true}>
                                {url}
                            </Link>
                            <Pressable onPress={_ => {
                                copy(url);
                                toast({ title: "已复制链接", duration: 2000 });
                            }}>
                                <div className="inline px-1 py-0.5 ml-1 translate-y-1">
                                    <i className='bx bx-copy text-[var(--gray10)]'></i>
                                </div>
                            </Pressable>
                        </div>
                        <div className="sm:px-2">
                            <QrCode info={url} size={120}></QrCode>
                        </div>
                    </div>
                    <div className="flex flex-col gap-2 sm:px-2">
                        <div className="font-medium">邀请朋友使用，获得积分🎖</div>
                        <div className="text-[var(--gray11)]">你可使用以下方式之一分享这次翻译的内容：</div>
                        <ol className="list-decimal pl-6 flex flex-col gap-1">
                            <li>复制上述链接网址</li>
                            <li>在微信内点击链接进入后，再转发</li>
                            <li>分享二维码给其他人</li>
                        </ol>
                    </div>
                    <div className="flex flex-col gap-2 sm:px-2">
                        <div className="text-[var(--gray11)]">你可以调整分享页的标题和简介</div>
                        <div className="list-decimal flex flex-col gap-2" data-color={"violet"}>
                            <TextInput styled={true} value={title} placeholder={share.翻译.文档.title} onChange={setTitle}></TextInput>
                            <TextArea styled={true} value={desc} placeholder={"SimplifyAI 提供的译文"} onChange={setDesc}></TextArea>
                        </div>
                    </div>
                    <div className="flex px-2 justify-between">
                        <div>
                            {isDirty ? (
                                <Button color="grass" appearance={"primary"} className="!px-4" onPress={_ => {
                                    updateShare(share.id, {
                                        标题: isTitleDirty ? title.trim() : undefined,
                                        简介: isDescDirty ? title.trim() : undefined
                                    })
                                }}>
                                    保存
                                </Button>
                            ) : null}
                        </div>
                        <div>
                            {share.已暂停 ? (
                                <Button color="grass" className="!px-4" onPress={_ => {
                                    updateShare(share.id, { 已暂停: false })
                                }}>
                                    继续分享
                                </Button>
                            ) : (
                                <Button color="tomato" className="!px-4" onPress={_ => {
                                    updateShare(share.id, { 已暂停: true })
                                }}>
                                    暂停分享
                                </Button>
                            )}
                        </div>
                    </div>
                </>
            ) : renderLoading()}
        </div>
    )
}
