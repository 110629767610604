
// 

export function runTasksOneByOne(cmdTasks, done) {

    // wrap task to promise
    const promises = cmdTasks.map(cmd => {
        return () => (
            new Promise((resolve, reject) => {
                cmd(result => {
                    resolve(result)
                });
            })
        );
    })

    runPromisesOneByOne(promises).then(results => {
        done(results)
    })

}


async function runPromisesOneByOne(promises) {

    let results = [];
    for (let i = 0; i < promises.length; i++) {    
        const result = await promises[i]();
        results.push(result);
    }
    return results

}

export function aggregateTasks(tasks, initial, done, onError) {
    // wrap task to promise
    const promises = tasks.map(task => {
        return acc =>
            new Promise((resolve, reject) => {
                task(acc, result => {
                    resolve(result)
                }, error => {
                    reject(error)
                });
            });
    })

    aggregateAsyncTasks(promises, initial).then(result => done(result)).catch(error => {
        if(onError) onError(error);
    });
}

async function aggregateAsyncTasks(asyncTasks, initial) {

    let acc = initial;
    for (let i = 0; i < asyncTasks.length; i++) {
        acc = await asyncTasks[i](acc);
    }
    return acc
}