
// This file is generated. Don't modify it.
import React from 'react'
export default function PhoneIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M5,11.0158691 L11,11.0158691 L11,3 L5,3 L5,11.0158691 Z M10.6784117,14 C10.6205191,14.0104261 10.5608952,14.0158691 10.5,14.0158691 L5.5,14.0158691 C5.43910483,14.0158691 5.37948094,14.0104261 5.32158833,14 L5,14 C4.44771525,14 4,13.5522847 4,13 L4,3 C4,2.44771525 4.44771525,2 5,2 L11,2 C11.5522847,2 12,2.44771525 12,3 L12,13 C12,13.5522847 11.5522847,14 11,14 L10.6784117,14 Z M8,13 C8.27614237,13 8.5,12.7761424 8.5,12.5 C8.5,12.2238576 8.27614237,12 8,12 C7.72385763,12 7.5,12.2238576 7.5,12.5 C7.5,12.7761424 7.72385763,13 8,13 Z" id="PhoneIcon-path-1"></path>
            </defs>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Filling/Phone">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="PhoneIcon-mask-2" fill="white">
                            <use href="#PhoneIcon-path-1"></use>
                        </mask>
                        <use id="Mask" fill={color} fillRule="nonzero" href="#PhoneIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
