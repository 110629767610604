
import React from 'react'

import createBlockTypeButton from './util/createBlockTypeButton';

import { MdFormatListNumbered } from 'react-icons/md'

export default createBlockTypeButton({
    blockType: 'numbered-list',
    children: (
        <MdFormatListNumbered style={{ fontSize: 16 }}/>
    ),
});
