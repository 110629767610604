
// This file is generated. Don't modify it.
import React from 'react'
export default function YiDianIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M7.21600304,3 L7.08426451,3 C6.62766972,3 6.24263773,3.2720868 6.09608673,3.65119379 L6.09395743,3.6469352 C6.09395743,3.6469352 5.98943685,3.98299342 5.44998472,4.43986595 C5.04403011,4.78379329 4.69121527,4.87646389 4.58076969,4.89859004 C4.57267497,4.89994935 4.56461783,4.90152374 4.55660683,4.90331152 L4.54142404,4.90562597 L4.54151661,4.90812557 C4.12306396,5.00644343 3.81024283,5.38110668 3.81024283,5.83020265 C3.81024283,6.35484229 4.23517594,6.78014572 4.75981558,6.78014572 L6.02989344,6.78014572 L6.02989344,12.0167288 C6.02989344,12.5591435 6.50204135,13 7.08417194,13 L7.21591046,13 C7.79813363,13 8.27009637,12.5591435 8.27009637,12.0167288 L8.27009637,3.98327115 C8.27018895,3.4405788 7.79822621,3 7.21600304,3 M10.8301564,10.2003481 C10.0791635,10.2003481 9.46972236,10.8091412 9.46972236,11.5599489 C9.46972236,12.3110344 10.0791635,12.9198274 10.8301564,12.9198274 C11.580779,12.9198274 12.1897572,12.3110344 12.1897572,11.5599489 C12.1896646,10.8091412 11.580779,10.2003481 10.8301564,10.2003481" id="YiDianIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Social/Yidian">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="YiDianIcon-mask-2" fill="white">
                            <use href="#YiDianIcon-path-1"></use>
                        </mask>
                        <use id="Shape" fill={color} fillRule="nonzero" href="#YiDianIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
