
// This file is generated. Don't modify it.
import React from 'react'
export default function WechatPayIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M6.57478932,9.55624904 C5.85652433,9.9901147 5.74999125,9.31278294 5.74999125,9.31278294 L4.84986002,7.03758825 C4.50346082,5.95639077 5.14959266,6.55012272 5.14959266,6.55012271 C5.14959266,6.5501227 5.70399137,7.00438831 6.12479038,7.28118767 C6.54532272,7.55812036 7.02465494,7.36252081 7.02465494,7.36252082 L12.9095745,4.42026102 C11.8237104,2.95679777 10.0302479,2 7.99971934,2 C4.68599374,2 2,4.54639406 2,7.68772006 C2,9.49478251 2.88933126,11.1027788 4.2747947,12.145043 L4.02492861,13.7007727 C4.02492861,13.7007727 3.90306222,14.154905 4.32519458,13.9442388 C4.6127939,13.8007725 5.34599219,13.2858403 5.78252451,12.9729077 C6.49130378,13.2399131 7.24258298,13.3763368 7.999986,13.3755735 C11.3134449,13.3755735 13.999972,10.8291794 13.999972,7.6878534 C13.999972,6.77798886 13.7735725,5.91865753 13.3726401,5.1558593 C11.4975778,6.37772312 7.13665468,9.21744983 6.57478932,9.55624904 Z" id="WechatPayIcon-path-1"></path>
            </defs>
            <g id="Style" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Iconology" transform="translate(-192.000000, -252.000000)">
                    <g id="Icon/Social" transform="translate(48.000000, 252.000000)">
                        <g id="Icon/Wechat-Pay" transform="translate(144.000000, 0.000000)">
                            <g id="Group-3">
                                <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                                <mask id="WechatPayIcon-mask-2" fill="white">
                                    <use href="#WechatPayIcon-path-1"></use>
                                </mask>
                                <use id="Path" fill={color} fillRule="nonzero" href="#WechatPayIcon-path-1"></use>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
