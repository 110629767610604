
import React, { useState } from "react";



import AddRecordForm from 'bwax-ui/components/records/AddRecordForm'
import Avatar from 'bwax-ui/components/Avatar';

import { useTrack } from 'bwax-ui/track';

import { toast } from 'bwax-ui/components/Toast';

export default function AddPersonaSharingForm({ facade, persona, currentUserId, fieldPaths, onAdded }) {

    const [initialValues, setInitialValues] = useState({})

    const track = useTrack();

    const renderSharingCreation = () => {
        return (
            <AddRecordForm {...{
                color: "violet",
                facade, entityName: "角色分享",
                // 
                fieldItems: getFieldItems({ facade, currentUserId, persona }),
                onAdded: r => {
                    track("persona_sharing_created");
                    onAdded(r);
                    toast({ title: "已生成分享链接" });

                },
                confirmText: "生成分享链接",
                fieldPaths,
                initialValues,
            }} />
        )
    }

    return (
        <div className="pt-0 px-8 pb-4">
            <div className="flex items-center font-size-18">
                <i className="bx bx-user-pin text-2xl sm:text-[22px] mr-2" />
                分享知识助手
            </div>
            <div className="flex pt-8 pb-2 gap-4">
                <Avatar avatar={persona.头像} nickName={persona.名称} size={48} /> 
                <div className="flex flex-col grow gap-2">
                    <div className="font-medium font-size-16">{persona.名称}</div>
                    <div className="whitespace-pre-line text-[var(--gray11)]">
                        { persona.提示 }
                    </div>
                </div>
            </div>
            <div className="px-2 pt-4 flex flex-col gap-4">
                { ["欢迎消息"].map(name => {
                        return (
                            <div className="flex flex-col gap-2" key={name}>
                                <div className="">{name}</div>
                                <div className="whitespace-pre-line text-[var(--gray11)]">
                                    { persona[name] || "-" }
                                </div>
                            </div>
                        )
                    })
                }
                { renderSharingCreation() }
            </div>
        </div>

    )
}

function getFieldItems({ persona }) { 
    
    return [
        { name: "说明", placeholder: "向其他用户简单介绍这个知识助手扮演什么角色，掌握哪些专业知识" },
        { name: "欢迎消息", placeholder: "知识助手发给其他用户的第一条欢迎消息" },
        { name: "角色", hidden: true, fixedValue: persona.id }
    ]
}
