
// This file is generated. Don't modify it.
import React from 'react'
export default function LabelWireFrameIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M12,12.2338096 L12,3 L4,3 L4,12.2338096 L8,9.83380962 L12,12.2338096 Z M4,2 L12,2 C12.5522847,2 13,2.44771525 13,3 L13,14 L8,11 L3,14 L3,3 C3,2.44771525 3.44771525,2 4,2 Z" id="LabelWireFrameIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Wireframe/Label">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="LabelWireFrameIcon-mask-2" fill="white">
                            <use href="#LabelWireFrameIcon-path-1"></use>
                        </mask>
                        <use id="Rectangle" fill={color} fillRule="nonzero" href="#LabelWireFrameIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
