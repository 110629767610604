// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Grid = require("./Grid");
var $$String = require("bs-platform/lib/js/string.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var BwaxUi = require("bwax-ui");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json$BwaxMobile = require("../../bwax-js/ml/utils/json.bs.js");
var Plate$BwaxMobile = require("../../bwax-js/ml/plate.bs.js");
var QrCode$BwaxMobile = require("./auxiliary/QrCode.bs.js");
var Link = require("bwax-ui/page/Link");
var UIRules$BwaxMobile = require("./UIRules.bs.js");
var Icon = require("bwax-ui/basic/Icon");
var Video = require("bwax-ui/lazy/Video");
var ReactHelmetAsync = require("react-helmet-async");
var DomUtils$BwaxMobile = require("./utils/DomUtils.bs.js");
var IconTypes$BwaxMobile = require("./IconTypes.bs.js");
var ColorTypes$BwaxMobile = require("./ColorTypes.bs.js");
var Palette = require("bwax-ui/color/Palette");
var StringUtils$BwaxMobile = require("../../bwax-js/re/utils/StringUtils.bs.js");
var RichText = require("bwax-ui/basic/RichText");
var PDFReader = require("bwax-ui/lazy/PDFReader");
var UseDebounce = require("bwax-ui/legacy/page/hooks/useDebounce");

import './BaseUI.less'
;

function themeColor(prim, prim$1) {
  return BwaxUi.themeColor(prim, prim$1);
}

function notLastChild(rules) {
  return Css.selector("> :not(:last-child)", rules);
}

function buildClassName(name, rules, toCss) {
  var combinedRules = Belt_List.concat(/* [] */0, rules);
  var styleClass = Css.merge(Belt_List.map(combinedRules, toCss));
  var globalFontSize = DomUtils$BwaxMobile.$$window !== undefined ? (
      DomUtils$BwaxMobile.$$document !== undefined ? Caml_format.caml_float_of_string(Plate$BwaxMobile.Str.drop_right(2, Caml_option.valFromOption(DomUtils$BwaxMobile.$$window).getComputedStyle(Caml_option.valFromOption(DomUtils$BwaxMobile.$$document).documentElement).fontSize.toString())) : 16.0
    ) : 16.0;
  var createSpacingClass = function (length, isHover, responsiveType) {
    if (typeof length === "number") {
      return "";
    } else {
      var variant = length[0];
      if (variant !== 25096) {
        if (variant !== 5691738) {
          return "";
        } else {
          var l = length[1];
          var match = Belt_List.getBy(UIRules$BwaxMobile.presetREM, (function (p) {
                  return p === l;
                }));
          if (match !== undefined) {
            var convertR = match * globalFontSize | 0;
            if (responsiveType !== undefined) {
              switch (responsiveType) {
                case "narrow" :
                    if (isHover) {
                      return "spacing-" + (String(convertR) + "-h-n");
                    } else {
                      return "spacing-" + (String(convertR) + "-n");
                    }
                case "wide" :
                    if (isHover) {
                      return "spacing-" + (String(convertR) + "-h-w");
                    } else {
                      return "spacing-" + (String(convertR) + "-w");
                    }
                default:
                  
              }
            }
            if (isHover) {
              return "spacing-" + (String(convertR) + "-h");
            } else {
              return "spacing-" + (String(convertR) + "");
            }
          } else {
            return "";
          }
        }
      } else {
        var l$1 = length[1];
        var match$1 = Belt_List.getBy(UIRules$BwaxMobile.presetPX, (function (p) {
                return p === l$1;
              }));
        if (match$1 !== undefined) {
          var p = match$1;
          if (responsiveType !== undefined) {
            switch (responsiveType) {
              case "narrow" :
                  if (isHover) {
                    return "spacing-" + (String(p) + "-h-n");
                  } else {
                    return "spacing-" + (String(p) + "-n");
                  }
              case "wide" :
                  if (isHover) {
                    return "spacing-" + (String(p) + "-h-w");
                  } else {
                    return "spacing-" + (String(p) + "-w");
                  }
              default:
                
            }
          }
          if (isHover) {
            return "spacing-" + (String(p) + "-h");
          } else {
            return "spacing-" + (String(p) + "");
          }
        } else {
          return "";
        }
      }
    }
  };
  var hasSpecialSpacing = function (rules) {
    return Plate$BwaxMobile.List.find((function (r) {
                  if (typeof r === "number" || r[0] !== 297481091) {
                    return false;
                  } else {
                    return UIRules$BwaxMobile.isSpecialSpacing(r[1]);
                  }
                }), rules);
  };
  var getResponsiveSpacingClass = function (rules, responsiveType) {
    return StringUtils$BwaxMobile.join(Plate$BwaxMobile.List.map((function (r) {
                      if (typeof r === "number") {
                        return "";
                      } else {
                        var variant = r[0];
                        if (variant !== 297481091) {
                          if (variant !== 728032700) {
                            return "";
                          } else {
                            var match = hasSpecialSpacing(r[1]);
                            if (match !== undefined) {
                              var s = match;
                              if (typeof s === "number" || s[0] !== 297481091) {
                                return "";
                              } else {
                                return createSpacingClass(s[1], true, responsiveType);
                              }
                            } else {
                              return "";
                            }
                          }
                        } else {
                          var length = r[1];
                          var match$1 = UIRules$BwaxMobile.isSpecialSpacing(length);
                          if (match$1) {
                            return createSpacingClass(length, false, responsiveType);
                          } else {
                            return "";
                          }
                        }
                      }
                    }), rules), " ");
  };
  var spacingClass = StringUtils$BwaxMobile.join(Plate$BwaxMobile.List.map((function (r) {
              if (typeof r === "number") {
                return "";
              } else {
                var variant = r[0];
                if (variant >= -265706863) {
                  if (variant !== 297481091) {
                    if (variant !== 728032700) {
                      return "";
                    } else {
                      var rules = r[1];
                      var match = hasSpecialSpacing(rules);
                      if (match !== undefined) {
                        var s = match;
                        if (typeof s === "number" || s[0] !== 297481091) {
                          return "";
                        } else {
                          return createSpacingClass(s[1], true, undefined);
                        }
                      } else {
                        return "";
                      }
                    }
                  } else {
                    var length = r[1];
                    var match$1 = UIRules$BwaxMobile.isSpecialSpacing(length);
                    if (match$1) {
                      return createSpacingClass(length, false, undefined);
                    } else {
                      return "";
                    }
                  }
                } else if (variant !== -593248680) {
                  if (variant >= -265706864) {
                    return getResponsiveSpacingClass(r[1], "wide");
                  } else {
                    return "";
                  }
                } else {
                  return getResponsiveSpacingClass(r[1], "narrow");
                }
              }
            }), rules), " ");
  var classNameInRule = Plate$BwaxMobile.Str.join("", Plate$BwaxMobile.List.map((function (r) {
              if (typeof r === "number" || r[0] !== -599944541) {
                return "";
              } else {
                return r[1];
              }
            }), rules));
  var classNames = /* :: */[
    name,
    /* :: */[
      styleClass,
      /* :: */[
        spacingClass,
        /* :: */[
          classNameInRule,
          /* [] */0
        ]
      ]
    ]
  ];
  return Plate$BwaxMobile.Str.join(" ", Plate$BwaxMobile.List.filter((function (name) {
                      return Plate$BwaxMobile.Str.length(name) > 0;
                    }))(Plate$BwaxMobile.List.map((function (name) {
                        return Plate$BwaxMobile.Str.trim(name);
                      }), classNames)));
}

function renderElNearby(eln, theme, rules, key) {
  var renderInner = function (className, el) {
    return React.createElement("div", {
                key: key,
                className: "nb " + className
              }, Curry._4(el, theme, /* column */-963948842, rules, "no-key"));
  };
  var variant = eln[0];
  if (variant >= 452579101) {
    if (variant >= 592113284) {
      if (variant >= 811186865) {
        return renderInner("below", eln[1]);
      } else {
        return renderInner("in-front", eln[1]);
      }
    } else if (variant >= 461385914) {
      return renderInner("behind", eln[1]);
    } else {
      return renderInner("above", eln[1]);
    }
  } else if (variant >= -6182339) {
    return renderInner("on-right", eln[1]);
  } else {
    return renderInner("on-left", eln[1]);
  }
}

function wrapWithNearby(inner, nearby, rules, theme) {
  var match = Belt_List.partition(nearby, (function (nb) {
          if (typeof nb === "number") {
            return false;
          } else {
            return nb[0] === 461385914;
          }
        }));
  var toRenderNearBy = function (idx, eln) {
    return renderElNearby(eln, theme, rules, String(idx));
  };
  var beforeElements = Belt_List.toArray(Belt_List.mapWithIndex(match[0], toRenderNearBy));
  var afterElements = Belt_List.toArray(Belt_List.mapWithIndex(match[1], toRenderNearBy));
  return React.createElement(React.Fragment, undefined, beforeElements, inner, afterElements);
}

function wrapForNearby(inner, rules, theme) {
  var nearby = Belt_List.keepMap(rules, (function (r) {
          if (typeof r === "number") {
            return ;
          } else {
            var variant = r[0];
            if (variant >= 452579102) {
              if (variant !== 461385914) {
                if (variant !== 592113284) {
                  if (variant !== 811186865) {
                    return ;
                  } else {
                    return /* `below */[
                            811186865,
                            r[1]
                          ];
                  }
                } else {
                  return /* `inFront */[
                          592113284,
                          r[1]
                        ];
                }
              } else {
                return /* `behind */[
                        461385914,
                        r[1]
                      ];
              }
            } else if (variant !== -365293370) {
              if (variant !== -6182339) {
                if (variant >= 452579101) {
                  return /* `above */[
                          452579101,
                          r[1]
                        ];
                } else {
                  return ;
                }
              } else {
                return /* `onRight */[
                        -6182339,
                        r[1]
                      ];
              }
            } else {
              return /* `onLeft */[
                      -365293370,
                      r[1]
                    ];
            }
          }
        }));
  if (Belt_List.length(nearby) > 0) {
    return wrapWithNearby(inner, nearby, rules, theme);
  } else {
    return inner;
  }
}

function renderWithClassName(className, inner, rules, theme, pc, key) {
  return UIRules$BwaxMobile.wrapForAlignment(UIRules$BwaxMobile.wrapForRegion(wrapForNearby(inner, rules, theme), className, rules, key), rules, pc, key);
}

function renderEl(name, rules, key, theme, pc, toCss, inner) {
  var className = buildClassName(name, rules, toCss);
  return renderWithClassName(className, inner, rules, theme, pc, key);
}

function box(rules, el, theme, pc, prs, key) {
  var toCss = function (r) {
    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_box */3, pc, prs);
  };
  return renderEl("box ctnr", rules, key, theme, pc, toCss, Curry._4(el, theme, /* box */4898315, rules, "no-key"));
}

function boxWithClassName(className, rules, el, theme, pc, prs, key) {
  var toCss = function (r) {
    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_box */3, pc, prs);
  };
  return renderEl("box ctnr " + className, rules, key, theme, pc, toCss, Curry._4(el, theme, /* box */4898315, rules, "no-key"));
}

function boxWithNearby(rules, el, nearby, theme, pc, prs, key) {
  var toCss = function (r) {
    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_box */3, pc, prs);
  };
  var inner = Curry._4(el, theme, /* box */4898315, rules, "no-key");
  var wrapedInner = wrapWithNearby(inner, nearby, rules, theme);
  return renderEl("box ctnr", rules, key, theme, pc, toCss, wrapedInner);
}

function pageWithStyles(pageStyles, rules, els, nearby, theme, pc, prs, key) {
  var toCss = function (r) {
    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_column */2, pc, prs);
  };
  var inner = Belt_List.toArray(Belt_List.mapWithIndex(els, (function (idx, el) {
              return Curry._4(el, theme, /* column */-963948842, rules, String(idx));
            })));
  var specifedStyle = buildClassName("", rules, toCss);
  var className = "page ctnr " + Css.merge(/* :: */[
        specifedStyle,
        pageStyles
      ]);
  return UIRules$BwaxMobile.wrapForAlignment(UIRules$BwaxMobile.wrapForRegion(wrapWithNearby(inner, nearby, rules, theme), className, rules, key), rules, pc, key);
}

function head(config, theme, pc, prs, key) {
  var orNull = function (proc, mv) {
    if (mv !== undefined) {
      return Curry._1(proc, Caml_option.valFromOption(mv));
    } else {
      return null;
    }
  };
  var meta = config[/* meta */8];
  var scalable = config[/* scalable */6];
  var match = Plate$BwaxMobile.List.length(meta) === 0;
  return React.createElement(ReactHelmetAsync.Helmet, {
              children: null,
              key: key
            }, orNull((function (t) {
                    return React.createElement("title", undefined, t);
                  }), config[/* title */0]), orNull((function (url) {
                    return React.createElement("link", {
                                href: url,
                                rel: "shortcut icon",
                                type: "image/png"
                              });
                  }), config[/* favicon */3]), orNull((function (url) {
                    return React.createElement("link", {
                                href: url,
                                rel: "wechat-icon",
                                type: "image/png"
                              });
                  }), config[/* wechatIcon */9]), orNull((function (keywords) {
                    var content = Plate$BwaxMobile.Str.join(",", keywords);
                    return React.createElement("meta", {
                                content: content,
                                name: "keywords"
                              });
                  }), config[/* keywords */1]), orNull((function (content) {
                    return React.createElement("meta", {
                                content: content,
                                name: "description"
                              });
                  }), config[/* description */2]), orNull((function (param) {
                    return React.createElement("script", {
                                type: "application/ld+json"
                              }, "{\"@context\":\"https://schema.org\", \"@type\":\"WebSite\", \"name\":\"" + (String(param[0]) + ("\", \"url\":\"" + (String(param[1]) + "\"}"))));
                  }), config[/* site */10]), orNull((function (list) {
                    return Curry._1(Plate$BwaxMobile.List.to_array, Plate$BwaxMobile.List.indexed_map((function (i, s) {
                                      return React.createElement("script", {
                                                  key: "s" + String(i)
                                                }, s);
                                    }), list));
                  }), config[/* scripts */4]), orNull((function (list) {
                    return Curry._1(Plate$BwaxMobile.List.to_array, Plate$BwaxMobile.List.indexed_map((function (i, s) {
                                      return React.createElement("noscript", {
                                                  key: "ns" + String(i)
                                                }, s);
                                    }), list));
                  }), config[/* noscripts */5]), scalable !== undefined && scalable ? React.createElement("meta", {
                    content: "width=device-width,initial-scale=" + (String(config[/* initialScale */7]) + ",user-scalable=yes"),
                    name: "viewport"
                  }) : null, match ? null : Curry._1(Plate$BwaxMobile.List.to_array, Plate$BwaxMobile.List.map((function (param) {
                          var name = param[0];
                          return React.createElement("meta", {
                                      key: name,
                                      content: param[1],
                                      name: name
                                    });
                        }), meta)));
}

function page(rules, els, nearby, theme, pc, prs, key) {
  return pageWithStyles(/* [] */0, rules, els, nearby, theme, pc, prs, key);
}

function row(rules, els, theme, pc, prs, key) {
  var inner = Belt_List.toArray(Belt_List.mapWithIndex(els, (function (idx, el) {
              return Curry._4(el, theme, /* row */5693978, rules, String(idx));
            })));
  var toCss = function (r) {
    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_row */1, pc, prs);
  };
  return renderEl("row ctnr", rules, key, theme, pc, toCss, inner);
}

function wrappedRow(rules, els, theme, pc, prs, key) {
  var inner = Belt_List.toArray(Belt_List.mapWithIndex(els, (function (idx, el) {
              return Curry._4(el, theme, /* row */5693978, rules, String(idx));
            })));
  var toCss = function (r) {
    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_wrappedRow */8, pc, prs);
  };
  var hasSpacingRule = Plate$BwaxMobile.List.some((function (r) {
          if (typeof r === "number") {
            return false;
          } else {
            return r[0] === 297481091;
          }
        }), rules);
  if (hasSpacingRule) {
    var match = Curry._2(Plate$BwaxMobile.List.partition, (function (r) {
            if (typeof r === "number") {
              if (r >= -626416848) {
                if (r >= 79394430) {
                  if (r !== 531263392) {
                    return r < 79394431;
                  } else {
                    return true;
                  }
                } else if (r !== -346313767) {
                  return r < -626416847;
                } else {
                  return true;
                }
              } else if (r !== -965797017 && r !== -701337526) {
                return r === -680157294;
              } else {
                return true;
              }
            } else {
              return r[0] === 297481091;
            }
          }), rules);
    var outerRules = match[1];
    var outerToCss = function (r) {
      return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_column */2, pc, prs);
    };
    var outerClassName = buildClassName("column ctnr", outerRules, outerToCss);
    var innerClassName = buildClassName("wrapped-row ctnr", Belt_List.concat(/* :: */[
              /* `width */[
                -899500538,
                /* `percent */[
                  -119887163,
                  100.0
                ]
              ],
              /* :: */[
                /* `height */[
                  38537191,
                  /* `percent */[
                    -119887163,
                    100.0
                  ]
                ],
                /* [] */0
              ]
            ], match[0]), toCss);
    var outer = React.createElement("div", {
          key: key,
          className: innerClassName
        }, inner);
    return UIRules$BwaxMobile.wrapForAlignment(UIRules$BwaxMobile.wrapForRegion(wrapForNearby(outer, rules, theme), outerClassName, outerRules, key), outerRules, pc, key);
  } else {
    var className = buildClassName("wrapped-row ctnr", rules, toCss);
    return UIRules$BwaxMobile.wrapForAlignment(wrapForNearby(UIRules$BwaxMobile.wrapForRegion(inner, className, rules, key), rules, theme), rules, pc, key);
  }
}

function column(rules, els, theme, pc, prs, key) {
  var inner = Belt_List.toArray(Belt_List.mapWithIndex(els, (function (idx, el) {
              return Curry._4(el, theme, /* column */-963948842, rules, String(idx));
            })));
  var toCss = function (r) {
    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_column */2, pc, prs);
  };
  return renderEl("column ctnr", rules, key, theme, pc, toCss, inner);
}

var make = Grid.default;

var Grid$1 = {
  make: make
};

function grid(rules, span, els, theme, pc, prs, key) {
  var toCss = function (r) {
    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_box */3, pc, prs);
  };
  var rulesWithoutSpacing = Plate$BwaxMobile.List.filter((function (r) {
            if (typeof r === "number") {
              return true;
            } else {
              return r[0] !== 297481091;
            }
          }))(rules);
  var className = buildClassName("ctnr", rulesWithoutSpacing, toCss);
  var spacing = Plate$BwaxMobile.$$Option.map(UIRules$BwaxMobile.Length.toString, UIRules$BwaxMobile.findSpacingLength(rules));
  var elements = Curry._1(Plate$BwaxMobile.List.to_array, Plate$BwaxMobile.List.indexed_map((function (idx, el) {
              return Curry._4(el, theme, /* row */5693978, rules, String(idx));
            }), els));
  return React.createElement(make, {
              className: className,
              spacing: spacing,
              span: span,
              elements: elements,
              key: key
            });
}

function paragraph(rules, els, theme, pc, prs, key) {
  var actualRules = Belt_List.concat(/* :: */[
        /* `spacing */[
          297481091,
          /* `em */[
            22632,
            0.2
          ]
        ],
        /* [] */0
      ], rules);
  var inner = Belt_List.toArray(Belt_List.mapWithIndex(els, (function (idx, el) {
              return Curry._4(el, theme, /* paragraph */-590209170, actualRules, String(idx));
            })));
  var toCss = function (r) {
    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_paragraph */4, pc, prs);
  };
  return renderEl("paragraph ctnr", actualRules, key, theme, pc, toCss, inner);
}

function text(rules, value, theme, pc, prs, key) {
  var toCss = function (r) {
    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_text */5, pc, prs);
  };
  return renderEl("text", rules, key, theme, pc, toCss, value);
}

var Bridge = { };

function BaseUI$RichText(Props) {
  var value = Props.value;
  var theme = Props.theme;
  return React.createElement(RichText.default, {
              params: {
                value: value
              },
              theme: theme
            });
}

var RichText$1 = {
  Bridge: Bridge,
  make: BaseUI$RichText
};

function richtext(rules, value, theme, pc, prs, key) {
  var inner = React.createElement(BaseUI$RichText, {
        value: value,
        theme: theme
      });
  var toCss = function (r) {
    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_richtext */7, pc, prs);
  };
  return renderEl("richtext", rules, key, theme, pc, toCss, inner);
}

function image(rules, config, theme, pc, prs, key) {
  var toCss = function (r) {
    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_image */6, pc, prs);
  };
  var className = buildClassName("image ctnr", rules, toCss);
  var match = config[/* description */1];
  var inner = match !== undefined ? React.createElement("img", {
          key: key,
          className: className,
          alt: match,
          src: config[/* src */0]
        }) : React.createElement("img", {
          key: key,
          className: className,
          src: config[/* src */0]
        });
  return UIRules$BwaxMobile.wrapForAlignment(wrapForNearby(inner, rules, theme), rules, pc, key);
}

var VideoBridge = { };

function video(rules, config, theme, pc, prs, key) {
  var toCss = function (r) {
    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_box */3, pc, prs);
  };
  var className = buildClassName("box ctnr", rules, toCss);
  var inner = React.createElement(Video.default, {
        poster: config[/* poster */1],
        src: config[/* src */0],
        className: className,
        videoType: Belt_Option.getWithDefault(config[/* videoType */2], "mp4"),
        key: key
      });
  return UIRules$BwaxMobile.wrapForAlignment(wrapForNearby(inner, rules, theme), rules, pc, key);
}

var BridgeToLink = { };

function link(rules, config, el, theme, pc, prs, key) {
  var toCss = function (r) {
    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_box */3, pc, prs);
  };
  var className = buildClassName("box link ctnr", rules, toCss);
  var inner = wrapForNearby(Curry._4(el, theme, /* box */4898315, rules, "no-key"), rules, theme);
  var outer = React.createElement(Link.default, {
        to_: config[/* to_ */0],
        className: className,
        openWithNewTab: config[/* openWithNewTab */1],
        children: inner,
        key: key
      });
  return UIRules$BwaxMobile.wrapForAlignment(outer, rules, pc, key);
}

function empty(param, param$1, param$2, param$3) {
  return null;
}

var BridgeToIcon = { };

function icon(size, iconType, color, theme, _pc, _prs, key) {
  var actualColor = Belt_Option.map(color, (function (c) {
          return BwaxUi.themeColor(theme, ColorTypes$BwaxMobile.toString(c));
        }));
  var typeStr = IconTypes$BwaxMobile.toString(iconType);
  var sizeStr = Belt_Option.map(size, (function (s) {
          if (s !== -20425611) {
            if (s >= -9768761) {
              return "small";
            } else {
              return "large";
            }
          } else {
            return "medium";
          }
        }));
  return React.createElement(Icon.default, {
              params: {
                color: actualColor,
                type: typeStr,
                size: sizeStr
              },
              key: key
            });
}

var BridgeToPDFReader = { };

function pdfView(rules, file, theme, pc, prs, key) {
  var toCss = function (r) {
    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_image */6, pc, prs);
  };
  var className = buildClassName("pdf-view", rules, toCss);
  var maybeContentType = Json$BwaxMobile.get_value("contentType", Js_json.decodeString, file);
  var maybeUrl = Json$BwaxMobile.get_value("url", Js_json.decodeString, file);
  var isPdfType = Plate$BwaxMobile.$$Option.with_default(true, Plate$BwaxMobile.$$Option.map((function (contentType) {
              if (Plate$BwaxMobile.Str.contains("pdf", contentType)) {
                return true;
              } else {
                return Plate$BwaxMobile.Str.contains("PDF", contentType);
              }
            }), maybeContentType));
  if (isPdfType) {
    if (maybeUrl !== undefined) {
      return React.createElement(PDFReader.default, {
                  src: maybeUrl,
                  className: className,
                  key: key
                });
    } else {
      return "未找到文件URL";
    }
  } else {
    return "这看起来不是 PDF 文件";
  }
}

function qrcode(rules, content, size, theme, pc, prs, key) {
  var toCss = function (r) {
    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_image */6, pc, prs);
  };
  var className = buildClassName("image ctnr", rules, toCss);
  var inner = React.createElement(QrCode$BwaxMobile.make, {
        value: content,
        size: size,
        className: className,
        key: key
      });
  return UIRules$BwaxMobile.wrapForAlignment(wrapForNearby(inner, rules, theme), rules, pc, key);
}

function BaseUI$ButtonWithState(Props) {
  var onPress = Props.onPress;
  var className = Props.className;
  var disabled = Props.disabled;
  var unstyled = Props.unstyled;
  var domRef = Props.domRef;
  var children = Props.children;
  var match = React.useState((function () {
          return false;
        }));
  var setInProgress = match[1];
  var inProgress = match[0];
  var baseClassName = $$String.concat(" ", /* :: */[
        "button box ctnr",
        /* :: */[
          disabled ? "disabled" : "",
          /* :: */[
            inProgress ? "in-progress" : "",
            /* :: */[
              unstyled ? "unstyled" : "",
              /* [] */0
            ]
          ]
        ]
      ]);
  var onClick = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (!disabled && !inProgress) {
      Curry._1(onPress, /* () */0).then((function (param) {
              Curry._1(setInProgress, (function (param) {
                      return false;
                    }));
              return Promise.resolve(/* () */0);
            }));
      Curry._1(setInProgress, (function (param) {
              return true;
            }));
      return /* () */0;
    } else {
      return 0;
    }
  };
  if (domRef !== undefined) {
    return React.createElement("button", {
                ref: Caml_option.valFromOption(domRef),
                className: baseClassName + (" " + className),
                role: "button",
                tabIndex: 0,
                disabled: disabled,
                onClick: onClick
              }, children);
  } else {
    return React.createElement("button", {
                className: baseClassName + (" " + className),
                role: "button",
                tabIndex: 0,
                disabled: disabled,
                onClick: onClick
              }, children);
  }
}

var ButtonWithState = {
  make: BaseUI$ButtonWithState
};

function button(rules, config, el, theme, pc, prs, key) {
  var disabled = config[/* disabled */1];
  var defaultRules = disabled ? /* [] */0 : /* :: */[
      /* pointer */-786317123,
      /* [] */0
    ];
  var actualRules = Belt_List.concat(defaultRules, rules);
  var domRef = Plate$BwaxMobile.$$Option.map(Plate$BwaxMobile.snd, Plate$BwaxMobile.List.hit((function (r) {
              if (typeof r === "number" || r[0] !== 762661873) {
                return ;
              } else {
                return Caml_option.some(r[1]);
              }
            }), rules));
  var toCss = function (r) {
    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_box */3, pc, prs);
  };
  var maskRadiusStyle = Css.style(Belt_List.keepMap(actualRules, (function (r) {
              if (typeof r === "number") {
                return ;
              } else {
                var variant = r[0];
                if (variant !== -1041690463) {
                  if (variant !== -979470621) {
                    return ;
                  } else {
                    var match = r[1];
                    return Caml_option.some(Css.selector("&::after", /* :: */[
                                    UIRules$BwaxMobile.LimitedCss.borderTopLeftRadius(match[/* tl */0]),
                                    /* :: */[
                                      UIRules$BwaxMobile.LimitedCss.borderTopRightRadius(match[/* tr */1]),
                                      /* :: */[
                                        UIRules$BwaxMobile.LimitedCss.borderBottomLeftRadius(match[/* bl */3]),
                                        /* :: */[
                                          UIRules$BwaxMobile.LimitedCss.borderBottomRightRadius(match[/* br */2]),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]));
                  }
                } else {
                  return Caml_option.some(Css.selector("&::after", /* :: */[
                                  UIRules$BwaxMobile.LimitedCss.borderRadius(r[1]),
                                  /* [] */0
                                ]));
                }
              }
            })));
  var className = Css.merge(/* :: */[
        buildClassName("", actualRules, toCss),
        /* :: */[
          maskRadiusStyle,
          /* [] */0
        ]
      ]);
  var inner = Curry._4(el, theme, /* box */4898315, actualRules, "no-key");
  var outer = React.createElement(BaseUI$ButtonWithState, {
        onPress: config[/* onPress */0],
        className: className,
        disabled: disabled,
        unstyled: config[/* unstyled */2],
        domRef: domRef,
        children: inner,
        key: key
      });
  return UIRules$BwaxMobile.wrapForAlignment(wrapForNearby(outer, rules, theme), rules, pc, key);
}

function BaseUI$ButtonWithState2(Props) {
  var onPress = Props.onPress;
  var className = Props.className;
  var disabled = Props.disabled;
  var unstyled = Props.unstyled;
  var children = Props.children;
  var domRef = Props.domRef;
  var baseClassName = $$String.concat(" ", /* :: */[
        "button box ctnr",
        /* :: */[
          disabled ? "disabled" : "",
          /* :: */[
            unstyled ? "unstyled" : "",
            /* [] */0
          ]
        ]
      ]);
  var onClick = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) {
      return 0;
    } else {
      return Curry._1(onPress, /* () */0);
    }
  };
  if (domRef !== undefined) {
    return React.createElement("button", {
                ref: Caml_option.valFromOption(domRef),
                className: baseClassName + (" " + className),
                role: "button",
                tabIndex: 0,
                disabled: disabled,
                onClick: onClick
              }, children);
  } else {
    return React.createElement("button", {
                className: baseClassName + (" " + className),
                role: "button",
                tabIndex: 0,
                disabled: disabled,
                onClick: onClick
              }, children);
  }
}

var ButtonWithState2 = {
  make: BaseUI$ButtonWithState2
};

function button2(rules, config, el, theme, pc, prs, key) {
  var mode = config[/* mode */3];
  var unstyled = config[/* unstyled */2];
  var disabled = config[/* disabled */1];
  var defaultRules = disabled ? (
      unstyled ? /* [] */0 : /* :: */[
          /* `opacity */[
            -345678389,
            0.4
          ],
          /* [] */0
        ]
    ) : /* :: */[
      /* pointer */-786317123,
      /* [] */0
    ];
  var findBgColor = function (rules) {
    var colors = Plate$BwaxMobile.List.keep_map((function (r) {
            if (typeof r === "number" || r[0] !== 222776981) {
              return ;
            } else {
              return Caml_option.some(r[1]);
            }
          }), rules);
    return Belt_List.get(colors, Belt_List.length(colors) - 1 | 0);
  };
  var findWideBgColor = function (rules) {
    var colors = Plate$BwaxMobile.List.keep_map((function (r) {
            if (typeof r === "number" || r[0] !== -265706864) {
              return ;
            } else {
              return findBgColor(r[1]);
            }
          }), rules);
    return Belt_List.get(colors, Belt_List.length(colors) - 1 | 0);
  };
  var findNarrowBgColor = function (rules) {
    var colors = Plate$BwaxMobile.List.keep_map((function (r) {
            if (typeof r === "number" || r[0] !== -593248680) {
              return ;
            } else {
              return findBgColor(r[1]);
            }
          }), rules);
    return Belt_List.get(colors, Belt_List.length(colors) - 1 | 0);
  };
  var shouldAddActivityStyle = function (colorString) {
    var whiteHexRegx = (/^#[fF]{3,6}$/);
    var whiteRgbRegx = (/^rgba?\(255, 255, 255(, 1)?\)$/);
    var blackHexRegx = (/^#0{3,6}$/);
    var blackRgbRegx = (/^rgba?\(0, 0, 0(, 1)?\)$/);
    return !(whiteHexRegx.test(colorString) || whiteRgbRegx.test(colorString) || colorString === "white" || blackHexRegx.test(colorString) || blackRgbRegx.test(colorString) || colorString === "black");
  };
  var toActivityRules = function (maybeColor) {
    if (maybeColor !== undefined) {
      var colorString = ColorTypes$BwaxMobile.toString(maybeColor);
      if (shouldAddActivityStyle(colorString)) {
        var m = mode / 5 | 0;
        var rate = mode % 5;
        var v = Caml_int32.imul(5, rate + 1 | 0);
        if (m !== 1) {
          return /* :: */[
                  /* `hover */[
                    728032700,
                    /* :: */[
                      /* `backgroundColor */[
                        222776981,
                        /* `raw */[
                          5690856,
                          Palette.brighten(colorString, v)
                        ]
                      ],
                      /* [] */0
                    ]
                  ],
                  /* :: */[
                    /* `active */[
                      373703110,
                      /* :: */[
                        /* `backgroundColor */[
                          222776981,
                          /* `raw */[
                            5690856,
                            Palette.darken(colorString, v)
                          ]
                        ],
                        /* [] */0
                      ]
                    ],
                    /* [] */0
                  ]
                ];
        } else {
          return /* :: */[
                  /* `hover */[
                    728032700,
                    /* :: */[
                      /* `backgroundColor */[
                        222776981,
                        /* `raw */[
                          5690856,
                          Palette.lighten(colorString, v)
                        ]
                      ],
                      /* [] */0
                    ]
                  ],
                  /* :: */[
                    /* `active */[
                      373703110,
                      /* :: */[
                        /* `backgroundColor */[
                          222776981,
                          /* `raw */[
                            5690856,
                            Palette.darken(colorString, v)
                          ]
                        ],
                        /* [] */0
                      ]
                    ],
                    /* [] */0
                  ]
                ];
        }
      } else {
        return /* [] */0;
      }
    } else {
      return /* [] */0;
    }
  };
  var match = disabled || unstyled;
  var activityStyleRules = match ? /* [] */0 : Pervasives.$at(toActivityRules(findBgColor(rules)), Pervasives.$at(/* :: */[
              /* `ifNarrow */[
                -593248680,
                toActivityRules(findNarrowBgColor(rules))
              ],
              /* [] */0
            ], /* :: */[
              /* `ifWide */[
                -265706864,
                toActivityRules(findWideBgColor(rules))
              ],
              /* [] */0
            ]));
  var actualRules = Pervasives.$at(defaultRules, Pervasives.$at(activityStyleRules, rules));
  var domRef = Plate$BwaxMobile.$$Option.map(Plate$BwaxMobile.snd, Plate$BwaxMobile.List.hit((function (r) {
              if (typeof r === "number" || r[0] !== 762661873) {
                return ;
              } else {
                return Caml_option.some(r[1]);
              }
            }), rules));
  var toCss = function (r) {
    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_box */3, pc, prs);
  };
  var className = buildClassName("", actualRules, toCss);
  var inner = Curry._4(el, theme, /* box */4898315, actualRules, "no-key");
  var outer = React.createElement(BaseUI$ButtonWithState2, {
        onPress: config[/* onPress */0],
        className: className,
        disabled: disabled,
        unstyled: unstyled,
        children: inner,
        domRef: domRef,
        key: key
      });
  return UIRules$BwaxMobile.wrapForAlignment(wrapForNearby(outer, rules, theme), rules, pc, key);
}

function BaseUI$Input(Props) {
  var type_ = Props.type_;
  var spellCheck = Props.spellCheck;
  var value = Props.value;
  var onChange = Props.onChange;
  var placeholder = Props.placeholder;
  var className = Props.className;
  var autoFocus = Props.autoFocus;
  var maxLength = Props.maxLength;
  var disabled = Props.disabled;
  var match = React.useState((function () {
          return value;
        }));
  var setEditing = match[1];
  var editing = match[0];
  var debouncedEditing = UseDebounce.default(editing, 108);
  React.useEffect((function () {
          if (debouncedEditing !== value) {
            Curry._1(onChange, debouncedEditing);
          }
          return (function (param) {
                    return /* () */0;
                  });
        }), /* array */[debouncedEditing]);
  React.useEffect((function () {
          if (value !== debouncedEditing) {
            Curry._1(setEditing, (function (param) {
                    return value;
                  }));
          }
          return (function (param) {
                    return /* () */0;
                  });
        }), /* array */[value]);
  var doChange = function ($$event) {
    return Curry._1(setEditing, $$event.target.value);
  };
  if (maxLength !== undefined) {
    return React.createElement("input", {
                className: className,
                spellCheck: spellCheck,
                autoFocus: autoFocus,
                maxLength: maxLength,
                placeholder: placeholder,
                type: type_,
                value: editing,
                onChange: doChange
              });
  } else {
    return React.createElement("input", {
                className: className,
                spellCheck: spellCheck,
                autoFocus: autoFocus,
                disabled: disabled,
                placeholder: placeholder,
                type: type_,
                value: editing,
                onChange: doChange
              });
  }
}

var Input = {
  make: BaseUI$Input
};

function renderInputField(type_, config, value, onChange, placeholder, disabled, rules, theme, pc, prs, key) {
  var actualRules = Belt_List.concat(/* :: */[
        /* `padding */[
          -797091375,
          /* `rem */[
            5691738,
            0.5
          ]
        ],
        /* [] */0
      ], rules);
  var toCss = function (r) {
    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_inputText */9, pc, prs);
  };
  var className = buildClassName("input-text", actualRules, toCss);
  var autoFocus = Belt_List.some(rules, (function (r) {
          return r === /* focusedOnLoad */894466172;
        }));
  var rendered = React.createElement(BaseUI$Input, {
        type_: type_,
        spellCheck: false,
        value: value,
        onChange: onChange,
        placeholder: placeholder,
        className: className,
        autoFocus: autoFocus,
        maxLength: config[/* maxLength */0],
        disabled: disabled,
        key: key
      });
  return UIRules$BwaxMobile.wrapForAlignment(rendered, rules, pc, key);
}

function inputText(config, value, onChange, placeholder) {
  var placeholder$1 = Belt_Option.getWithDefault(placeholder, "");
  var v = Belt_Option.getWithDefault(value, "");
  return (function (param, param$1, param$2, param$3, param$4, param$5) {
      return renderInputField("text", config, v, onChange, placeholder$1, param, param$1, param$2, param$3, param$4, param$5);
    });
}

function BaseUI$TextArea(Props) {
  var spellCheck = Props.spellCheck;
  var value = Props.value;
  var onChange = Props.onChange;
  var placeholder = Props.placeholder;
  var className = Props.className;
  var wrapperClassName = Props.wrapperClassName;
  var imlpClassName = Props.imlpClassName;
  var textareaClassName = Props.textareaClassName;
  var autoFocus = Props.autoFocus;
  Props.disabled;
  var match = React.useState((function () {
          return Plate$BwaxMobile.$$Option.with_default("", value);
        }));
  var setEditing = match[1];
  var editing = match[0];
  var debouncedEditing = UseDebounce.default(editing, 108);
  React.useEffect((function () {
          if (debouncedEditing !== Plate$BwaxMobile.$$Option.with_default("", value)) {
            Curry._1(onChange, debouncedEditing);
          }
          return (function (param) {
                    return /* () */0;
                  });
        }), /* array */[debouncedEditing]);
  React.useEffect((function () {
          if (value !== undefined) {
            var v = value;
            if (v !== debouncedEditing) {
              Curry._1(setEditing, (function (param) {
                      return v;
                    }));
            }
            
          } else {
            Curry._1(setEditing, (function (param) {
                    return "";
                  }));
          }
          return ;
        }), /* array */[value]);
  var doChange = function ($$event) {
    return Curry._1(setEditing, $$event.target.value);
  };
  var p = editing === "" ? placeholder : editing;
  var stretcherText = p + "|";
  var inner = React.createElement("textarea", {
        className: textareaClassName,
        spellCheck: spellCheck,
        autoFocus: autoFocus,
        placeholder: placeholder,
        value: editing,
        onChange: doChange
      });
  return React.createElement("div", {
              className: className
            }, React.createElement("div", {
                  className: wrapperClassName
                }, React.createElement("span", {
                      className: imlpClassName
                    }, stretcherText), React.createElement("div", {
                      className: "nb in-front"
                    }, inner)));
}

var TextArea = {
  make: BaseUI$TextArea
};

function inputTextMultiline(config, value, onChange, placeholder, disabled, rules, theme, pc, prs, key) {
  var placeholder$1 = Belt_Option.getWithDefault(placeholder, "");
  var actualRules = Belt_List.concat(/* :: */[
        /* `padding */[
          -797091375,
          /* `rem */[
            5691738,
            0.5
          ]
        ],
        /* :: */[
          /* `width */[
            -899500538,
            /* fill */-1011102077
          ],
          /* [] */0
        ]
      ], rules);
  var toBoxCss = function (r) {
    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_box */3, pc, prs);
  };
  var autoFocus = Belt_List.some(rules, (function (r) {
          return r === /* focusedOnLoad */894466172;
        }));
  var toTextareaCss = function (r) {
    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_textarea */0, pc, prs);
  };
  var wrapperRules = Belt_List.concat(Belt_List.keep(actualRules, (function (r) {
              if (typeof r === "number") {
                return false;
              } else {
                return r[0] === 297481091;
              }
            })), /* :: */[
        /* `width */[
          -899500538,
          /* fill */-1011102077
        ],
        /* :: */[
          /* `height */[
            38537191,
            /* fill */-1011102077
          ],
          /* [] */0
        ]
      ]);
  var toParagraphCss = function (r) {
    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_paragraph */4, pc, prs);
  };
  var wrapperClassName = buildClassName("box", wrapperRules, toParagraphCss);
  var match = Belt_List.partition(actualRules, (function (r) {
          if (typeof r === "number") {
            return false;
          } else {
            var variant = r[0];
            if (variant >= -688651524) {
              if (variant >= 76111833) {
                if (variant !== 222776981) {
                  return variant === 297481091;
                } else {
                  return true;
                }
              } else if (variant !== -481689226) {
                return variant >= 76111832;
              } else {
                return true;
              }
            } else if (variant >= -816909297) {
              if (variant !== -797091375) {
                return variant >= -688651525;
              } else {
                return true;
              }
            } else if (variant !== -1041690463) {
              return variant >= -816909298;
            } else {
              return true;
            }
          }
        }));
  var className = buildClassName("box", match[1], toBoxCss);
  var textareaRules = Belt_List.concat(match[0], /* :: */[
        /* `width */[
          -899500538,
          /* fill */-1011102077
        ],
        /* :: */[
          /* `height */[
            38537191,
            /* fill */-1011102077
          ],
          /* [] */0
        ]
      ]);
  var textareaClassName = buildClassName("textarea", textareaRules, toTextareaCss);
  var rendered = React.createElement(BaseUI$TextArea, {
        spellCheck: false,
        value: value,
        onChange: onChange,
        placeholder: placeholder$1,
        className: className,
        wrapperClassName: wrapperClassName,
        imlpClassName: "imlp",
        textareaClassName: textareaClassName,
        autoFocus: autoFocus,
        disabled: disabled,
        key: key
      });
  return UIRules$BwaxMobile.wrapForAlignment(rendered, rules, pc, key);
}

function inputNumber(value, onChange, placeholder) {
  var placeholder$1 = Belt_Option.getWithDefault(placeholder, "");
  var v = Belt_Option.getWithDefault(Belt_Option.map(value, (function (prim) {
              return String(prim);
            })), "");
  var doChange = function (s) {
    return Curry._1(onChange, Plate$BwaxMobile.$$Option.and_then(Plate$BwaxMobile.Str.to_int, s));
  };
  var partial_arg = /* record */[/* maxLength */undefined];
  return (function (param, param$1, param$2, param$3, param$4, param$5) {
      return renderInputField("number", partial_arg, v, doChange, placeholder$1, param, param$1, param$2, param$3, param$4, param$5);
    });
}

function inputDecimal(value, onChange, placeholder) {
  var placeholder$1 = Belt_Option.getWithDefault(placeholder, "");
  var v = Belt_Option.getWithDefault(Belt_Option.map(value, (function (prim) {
              return prim.toString();
            })), "");
  var doChange = function (s) {
    return Curry._1(onChange, Plate$BwaxMobile.$$Option.and_then(Plate$BwaxMobile.Str.to_float, s));
  };
  var partial_arg = /* record */[/* maxLength */undefined];
  return (function (param, param$1, param$2, param$3, param$4, param$5) {
      return renderInputField("number", partial_arg, v, doChange, placeholder$1, param, param$1, param$2, param$3, param$4, param$5);
    });
}

function option(value, label) {
  return /* record */[
          /* value */value,
          /* label */label
        ];
}

function inputRadio(rules, optionRules, config, theme, pc, prs, key) {
  var actualRules = Belt_List.concat(/* :: */[
        /* `spacing */[
          297481091,
          /* `rem */[
            5691738,
            1.0
          ]
        ],
        /* [] */0
      ], rules);
  var match = config[/* align */0];
  var ctnrClass = match >= 5693978 ? "row" : "column";
  var toCss = function (r) {
    var match = config[/* align */0];
    if (match >= 5693978) {
      return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_row */1, pc, prs);
    } else {
      return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_column */2, pc, prs);
    }
  };
  var className = buildClassName("input-radio " + ctnrClass, actualRules, toCss);
  var actualOptionRules = Belt_List.concat(/* :: */[
        /* `spacing */[
          297481091,
          /* `rem */[
            5691738,
            0.75
          ]
        ],
        /* [] */0
      ], optionRules);
  var rendered = React.createElement("div", {
        key: key,
        className: className
      }, Belt_List.toArray(Belt_List.map(config[/* options */3], (function (o) {
                  var selected = Caml_obj.caml_equal(config[/* selected */1], Caml_option.some(o[/* value */0]));
                  var toOptionCss = function (r) {
                    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_row */1, config[/* align */0], actualRules);
                  };
                  var match = config[/* disabled */4];
                  var radioOptionClass = buildClassName("radio-option" + (
                        match ? " radio-disabled" : ""
                      ), actualOptionRules, toOptionCss);
                  var toSelectorCss = function (r) {
                    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_box */3, /* row */5693978, /* [] */0);
                  };
                  var optionSelectorBoxClass = buildClassName("box", /* :: */[
                        /* alignTop */-908095280,
                        /* :: */[
                          /* `paddingEach */[
                            21151858,
                            /* record */[
                              /* top *//* `em */[
                                22632,
                                0.2
                              ],
                              /* right *//* zero */-789508312,
                              /* bottom *//* zero */-789508312,
                              /* left *//* zero */-789508312
                            ]
                          ],
                          /* [] */0
                        ]
                      ], toSelectorCss);
                  var optionSelectorClass = buildClassName("option-selector", Belt_List.concat(/* :: */[
                            /* borderSolid */222199711,
                            /* [] */0
                          ], selected ? /* :: */[
                              /* `borderColor */[
                                -690668329,
                                Belt_Option.getWithDefault(config[/* color */5], /* primary */58474434)
                              ],
                              /* :: */[
                                /* `borderWidth */[
                                  -690257542,
                                  /* `em */[
                                    22632,
                                    0.3
                                  ]
                                ],
                                /* [] */0
                              ]
                            ] : /* :: */[
                              /* `borderColor */[
                                -690668329,
                                /* N30 */3890283
                              ],
                              /* :: */[
                                /* `borderWidth */[
                                  -690257542,
                                  /* `px */[
                                    25096,
                                    1
                                  ]
                                ],
                                /* [] */0
                              ]
                            ]), toSelectorCss);
                  var onClick = function (param) {
                    var match = config[/* disabled */4];
                    if (match) {
                      return /* () */0;
                    } else {
                      return Curry._1(config[/* onChange */2], selected ? undefined : Caml_option.some(o[/* value */0]));
                    }
                  };
                  return React.createElement("div", {
                              key: o[/* label */1],
                              className: radioOptionClass,
                              onClick: onClick
                            }, React.createElement("div", {
                                  className: optionSelectorBoxClass
                                }, React.createElement("div", {
                                      className: optionSelectorClass
                                    })), React.createElement("div", {
                                  className: "option-label"
                                }, o[/* label */1]));
                }))));
  return UIRules$BwaxMobile.wrapForAlignment(rendered, rules, pc, key);
}

function inputCheckbox(rules, config, theme, pc, prs, key) {
  var actualRules = Belt_List.concat(/* [] */0, rules);
  var toCss = function (r) {
    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_box */3, pc, prs);
  };
  var className = buildClassName("input-checkbox box", actualRules, toCss);
  var toSelectorCss = function (r) {
    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_box */3, /* row */5693978, /* [] */0);
  };
  var match = config[/* checked */0];
  var optionSelectorClass = buildClassName("option-selector", Belt_List.concat(/* :: */[
            /* borderSolid */222199711,
            /* [] */0
          ], match ? /* :: */[
              /* `backgroundColor */[
                222776981,
                Belt_Option.getWithDefault(config[/* color */3], /* primary */58474434)
              ],
              /* :: */[
                /* `borderColor */[
                  -690668329,
                  Belt_Option.getWithDefault(config[/* color */3], /* primary */58474434)
                ],
                /* [] */0
              ]
            ] : /* :: */[
              /* `borderColor */[
                -690668329,
                /* N30 */3890283
              ],
              /* :: */[
                /* `borderWidth */[
                  -690257542,
                  /* `px */[
                    25096,
                    1
                  ]
                ],
                /* :: */[
                  /* `backgroundColor */[
                    222776981,
                    /* white */-910341463
                  ],
                  /* [] */0
                ]
              ]
            ]), toSelectorCss);
  var onClick = function (param) {
    var match = config[/* disabled */2];
    if (match) {
      return /* () */0;
    } else {
      return Curry._1(config[/* onChange */1], !config[/* checked */0]);
    }
  };
  var match$1 = config[/* disabled */2];
  var match$2 = config[/* checked */0];
  var rendered = React.createElement("div", {
        key: key,
        className: className
      }, React.createElement("div", {
            className: "checkbox-option" + (
              match$1 ? " checkbox-disabled" : ""
            ),
            onClick: onClick
          }, React.createElement("div", {
                className: optionSelectorClass
              }, match$2 ? React.createElement("div", {
                      className: "check"
                    }) : null)));
  return UIRules$BwaxMobile.wrapForAlignment(rendered, rules, pc, key);
}

function inputCheckboxes(rules, optionRules, config, theme, pc, prs, key) {
  var actualRules = Belt_List.concat(/* :: */[
        /* `spacing */[
          297481091,
          /* `rem */[
            5691738,
            1.0
          ]
        ],
        /* [] */0
      ], rules);
  var match = config[/* align */0];
  var ctnrClass = match >= 5693978 ? "row" : "column";
  var toCss = function (r) {
    var match = config[/* align */0];
    if (match >= 5693978) {
      return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_row */1, pc, prs);
    } else {
      return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_column */2, pc, prs);
    }
  };
  var className = buildClassName("input-checkboxes " + ctnrClass, actualRules, toCss);
  var actualOptionRules = Belt_List.concat(/* :: */[
        /* `spacing */[
          297481091,
          /* `rem */[
            5691738,
            0.75
          ]
        ],
        /* [] */0
      ], optionRules);
  var rendered = React.createElement("div", {
        key: key,
        className: className
      }, Belt_List.toArray(Belt_List.map(config[/* options */3], (function (o) {
                  var selected = Plate$BwaxMobile.List.is_in(Caml_obj.caml_equal, config[/* selected */1], o[/* value */0]);
                  var toOptionCss = function (r) {
                    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_row */1, config[/* align */0], actualRules);
                  };
                  var match = config[/* disabled */4];
                  var radioOptionClass = buildClassName("checkbox-option" + (
                        match ? " checkbox-disabled" : ""
                      ), actualOptionRules, toOptionCss);
                  var toSelectorCss = function (r) {
                    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_box */3, /* row */5693978, /* [] */0);
                  };
                  var optionSelectorBoxClass = buildClassName("box", /* :: */[
                        /* alignTop */-908095280,
                        /* :: */[
                          /* `paddingEach */[
                            21151858,
                            /* record */[
                              /* top *//* `em */[
                                22632,
                                0.2
                              ],
                              /* right *//* zero */-789508312,
                              /* bottom *//* zero */-789508312,
                              /* left *//* zero */-789508312
                            ]
                          ],
                          /* [] */0
                        ]
                      ], toSelectorCss);
                  var optionSelectorClass = buildClassName("option-selector", Belt_List.concat(/* :: */[
                            /* borderSolid */222199711,
                            /* [] */0
                          ], selected ? /* :: */[
                              /* `backgroundColor */[
                                222776981,
                                Belt_Option.getWithDefault(config[/* color */5], /* primary */58474434)
                              ],
                              /* :: */[
                                /* `borderColor */[
                                  -690668329,
                                  Belt_Option.getWithDefault(config[/* color */5], /* primary */58474434)
                                ],
                                /* [] */0
                              ]
                            ] : /* :: */[
                              /* `borderColor */[
                                -690668329,
                                /* N30 */3890283
                              ],
                              /* :: */[
                                /* `borderWidth */[
                                  -690257542,
                                  /* `px */[
                                    25096,
                                    1
                                  ]
                                ],
                                /* :: */[
                                  /* `backgroundColor */[
                                    222776981,
                                    /* white */-910341463
                                  ],
                                  /* [] */0
                                ]
                              ]
                            ]), toSelectorCss);
                  var onClick = function (param) {
                    var match = config[/* disabled */4];
                    if (match) {
                      return /* () */0;
                    } else {
                      return Curry._1(config[/* onChange */2], selected ? Plate$BwaxMobile.List.filter((function (e) {
                                            return e !== o[/* value */0];
                                          }))(config[/* selected */1]) : /* :: */[
                                    o[/* value */0],
                                    config[/* selected */1]
                                  ]);
                    }
                  };
                  return React.createElement("div", {
                              key: o[/* label */1],
                              className: radioOptionClass,
                              onClick: onClick
                            }, React.createElement("div", {
                                  className: optionSelectorBoxClass
                                }, React.createElement("div", {
                                      className: optionSelectorClass
                                    }, selected ? React.createElement("div", {
                                            className: "check"
                                          }) : null)), React.createElement("div", {
                                  className: "option-label"
                                }, o[/* label */1]));
                }))));
  return UIRules$BwaxMobile.wrapForAlignment(rendered, rules, pc, key);
}

function downloadAs(rules, config, el, theme, pc, prs, key) {
  var name = config[/* name */1];
  var url = config[/* url */0];
  var actualRules = Belt_List.concat(/* :: */[
        /* pointer */-786317123,
        /* [] */0
      ], rules);
  var toCss = function (r) {
    return UIRules$BwaxMobile.ruleToCss(r, theme, /* Item_box */3, pc, prs);
  };
  var className = Css.merge(/* :: */[
        buildClassName("download-as", actualRules, toCss),
        /* [] */0
      ]);
  var inner = Curry._4(el, theme, /* box */4898315, actualRules, "no-key");
  var onClick = function (param) {
    return DomUtils$BwaxMobile.downloadUrl(url, name);
  };
  var outer = React.createElement("div", {
        key: key,
        className: className,
        onClick: onClick
      }, inner);
  return UIRules$BwaxMobile.wrapForAlignment(outer, rules, pc, key);
}

var letterSpacing = /* `textLetterSpacing */[
  904663632,
  /* `em */[
    22632,
    0.03
  ]
];

var body_000 = /* `textSize */[
  -816909298,
  /* `rem */[
    5691738,
    1.0
  ]
];

var body_001 = /* :: */[
  letterSpacing,
  /* [] */0
];

var body = /* :: */[
  body_000,
  body_001
];

var bodyEm = /* :: */[
  /* textBold */-1005136686,
  body
];

var small_000 = /* `textSize */[
  -816909298,
  /* `rem */[
    5691738,
    0.875
  ]
];

var small_001 = /* :: */[
  letterSpacing,
  /* [] */0
];

var small = /* :: */[
  small_000,
  small_001
];

var smallEm = /* :: */[
  /* textBold */-1005136686,
  small
];

var helper_000 = /* `textSize */[
  -816909298,
  /* `rem */[
    5691738,
    0.75
  ]
];

var helper_001 = /* :: */[
  letterSpacing,
  /* [] */0
];

var helper = /* :: */[
  helper_000,
  helper_001
];

var quote_000 = /* `textSize */[
  -816909298,
  /* `rem */[
    5691738,
    0.875
  ]
];

var quote_001 = /* :: */[
  /* textBold */-1005136686,
  /* :: */[
    letterSpacing,
    /* [] */0
  ]
];

var quote = /* :: */[
  quote_000,
  quote_001
];

var fluid_000 = /* `ifWide */[
  -265706864,
  /* :: */[
    /* `textSize */[
      -816909298,
      /* `rem */[
        5691738,
        1.0
      ]
    ],
    /* [] */0
  ]
];

var fluid_001 = /* :: */[
  /* `ifNarrow */[
    -593248680,
    /* :: */[
      /* `textSize */[
        -816909298,
        /* `rem */[
          5691738,
          0.875
        ]
      ],
      /* [] */0
    ]
  ],
  /* :: */[
    letterSpacing,
    /* [] */0
  ]
];

var fluid = /* :: */[
  fluid_000,
  fluid_001
];

var fluidHelper_000 = /* `ifWide */[
  -265706864,
  /* :: */[
    /* `textSize */[
      -816909298,
      /* `rem */[
        5691738,
        0.875
      ]
    ],
    /* [] */0
  ]
];

var fluidHelper_001 = /* :: */[
  /* `ifNarrow */[
    -593248680,
    /* :: */[
      /* `textSize */[
        -816909298,
        /* `rem */[
          5691738,
          0.75
        ]
      ],
      /* [] */0
    ]
  ],
  /* :: */[
    letterSpacing,
    /* [] */0
  ]
];

var fluidHelper = /* :: */[
  fluidHelper_000,
  fluidHelper_001
];

var fluidQuote_000 = /* `ifWide */[
  -265706864,
  /* :: */[
    /* `textSize */[
      -816909298,
      /* `rem */[
        5691738,
        1.0
      ]
    ],
    /* [] */0
  ]
];

var fluidQuote_001 = /* :: */[
  /* `ifNarrow */[
    -593248680,
    /* :: */[
      /* `textSize */[
        -816909298,
        /* `rem */[
          5691738,
          0.875
        ]
      ],
      /* [] */0
    ]
  ],
  /* :: */[
    /* textLight */233447657,
    /* :: */[
      letterSpacing,
      /* [] */0
    ]
  ]
];

var fluidQuote = /* :: */[
  fluidQuote_000,
  fluidQuote_001
];

var headingLetterSpacing = /* `textLetterSpacing */[
  904663632,
  /* `em */[
    22632,
    0.03
  ]
];

var heading1_000 = /* `textSize */[
  -816909298,
  /* `rem */[
    5691738,
    2.25
  ]
];

var heading1_001 = /* :: */[
  /* `regionHeading */[
    -218331858,
    1
  ],
  /* :: */[
    headingLetterSpacing,
    /* [] */0
  ]
];

var heading1 = /* :: */[
  heading1_000,
  heading1_001
];

var heading2_000 = /* `textSize */[
  -816909298,
  /* `rem */[
    5691738,
    1.5
  ]
];

var heading2_001 = /* :: */[
  /* `regionHeading */[
    -218331858,
    2
  ],
  /* :: */[
    headingLetterSpacing,
    /* [] */0
  ]
];

var heading2 = /* :: */[
  heading2_000,
  heading2_001
];

var heading3_000 = /* `textSize */[
  -816909298,
  /* `rem */[
    5691738,
    1.25
  ]
];

var heading3_001 = /* :: */[
  /* `regionHeading */[
    -218331858,
    3
  ],
  /* :: */[
    headingLetterSpacing,
    /* [] */0
  ]
];

var heading3 = /* :: */[
  heading3_000,
  heading3_001
];

var heading4_000 = /* `textSize */[
  -816909298,
  /* `rem */[
    5691738,
    1.125
  ]
];

var heading4_001 = /* :: */[
  /* `regionHeading */[
    -218331858,
    4
  ],
  /* :: */[
    headingLetterSpacing,
    /* [] */0
  ]
];

var heading4 = /* :: */[
  heading4_000,
  heading4_001
];

var heading5_000 = /* `textSize */[
  -816909298,
  /* `rem */[
    5691738,
    0.875
  ]
];

var heading5_001 = /* :: */[
  /* `regionHeading */[
    -218331858,
    5
  ],
  /* :: */[
    headingLetterSpacing,
    /* [] */0
  ]
];

var heading5 = /* :: */[
  heading5_000,
  heading5_001
];

function heading(level) {
  switch (level) {
    case 1 :
        return heading1;
    case 2 :
        return heading2;
    case 3 :
        return heading3;
    case 4 :
        return heading4;
    default:
      return heading5;
  }
}

var fHeading1_000 = /* `ifWide */[
  -265706864,
  /* :: */[
    /* `textSize */[
      -816909298,
      /* `rem */[
        5691738,
        2.25
      ]
    ],
    /* [] */0
  ]
];

var fHeading1_001 = /* :: */[
  /* `ifNarrow */[
    -593248680,
    /* :: */[
      /* `textSize */[
        -816909298,
        /* `rem */[
          5691738,
          1.5
        ]
      ],
      /* [] */0
    ]
  ],
  /* :: */[
    /* `regionHeading */[
      -218331858,
      1
    ],
    /* :: */[
      headingLetterSpacing,
      /* [] */0
    ]
  ]
];

var fHeading1 = /* :: */[
  fHeading1_000,
  fHeading1_001
];

var fHeading2_000 = /* `ifWide */[
  -265706864,
  /* :: */[
    /* `textSize */[
      -816909298,
      /* `rem */[
        5691738,
        1.75
      ]
    ],
    /* [] */0
  ]
];

var fHeading2_001 = /* :: */[
  /* `ifNarrow */[
    -593248680,
    /* :: */[
      /* `textSize */[
        -816909298,
        /* `rem */[
          5691738,
          1.25
        ]
      ],
      /* [] */0
    ]
  ],
  /* :: */[
    /* `regionHeading */[
      -218331858,
      2
    ],
    /* :: */[
      headingLetterSpacing,
      /* [] */0
    ]
  ]
];

var fHeading2 = /* :: */[
  fHeading2_000,
  fHeading2_001
];

var fHeading3_000 = /* `ifWide */[
  -265706864,
  /* :: */[
    /* `textSize */[
      -816909298,
      /* `rem */[
        5691738,
        1.25
      ]
    ],
    /* [] */0
  ]
];

var fHeading3_001 = /* :: */[
  /* `ifNarrow */[
    -593248680,
    /* :: */[
      /* `textSize */[
        -816909298,
        /* `rem */[
          5691738,
          1.0
        ]
      ],
      /* [] */0
    ]
  ],
  /* :: */[
    /* `regionHeading */[
      -218331858,
      3
    ],
    /* :: */[
      headingLetterSpacing,
      /* [] */0
    ]
  ]
];

var fHeading3 = /* :: */[
  fHeading3_000,
  fHeading3_001
];

var fHeading4_000 = /* `ifWide */[
  -265706864,
  /* :: */[
    /* `textSize */[
      -816909298,
      /* `rem */[
        5691738,
        1.0
      ]
    ],
    /* [] */0
  ]
];

var fHeading4_001 = /* :: */[
  /* `ifNarrow */[
    -593248680,
    /* :: */[
      /* `textSize */[
        -816909298,
        /* `rem */[
          5691738,
          0.875
        ]
      ],
      /* [] */0
    ]
  ],
  /* :: */[
    /* `regionHeading */[
      -218331858,
      4
    ],
    /* :: */[
      headingLetterSpacing,
      /* [] */0
    ]
  ]
];

var fHeading4 = /* :: */[
  fHeading4_000,
  fHeading4_001
];

function fHeading(level) {
  switch (level) {
    case 1 :
        return fHeading1;
    case 2 :
        return fHeading2;
    case 3 :
        return fHeading3;
    default:
      return fHeading4;
  }
}

var TextTpl_fluidDisplay = /* :: */[
  /* `ifWide */[
    -265706864,
    /* :: */[
      /* `textSize */[
        -816909298,
        /* `rem */[
          5691738,
          3.125
        ]
      ],
      /* [] */0
    ]
  ],
  /* :: */[
    /* `ifNarrow */[
      -593248680,
      /* :: */[
        /* `textSize */[
          -816909298,
          /* `rem */[
            5691738,
            2.25
          ]
        ],
        /* [] */0
      ]
    ],
    /* :: */[
      /* textBold */-1005136686,
      /* :: */[
        /* `textLetterSpacing */[
          904663632,
          /* zero */-789508312
        ],
        /* [] */0
      ]
    ]
  ]
];

var TextTpl = {
  letterSpacing: letterSpacing,
  body: body,
  bodyEm: bodyEm,
  small: small,
  smallEm: smallEm,
  helper: helper,
  quote: quote,
  fluid: fluid,
  fluidHelper: fluidHelper,
  fluidQuote: fluidQuote,
  fluidDisplay: TextTpl_fluidDisplay,
  headingLetterSpacing: headingLetterSpacing,
  heading1: heading1,
  heading2: heading2,
  heading3: heading3,
  heading4: heading4,
  heading5: heading5,
  heading: heading,
  fHeading1: fHeading1,
  fHeading2: fHeading2,
  fHeading3: fHeading3,
  fHeading4: fHeading4,
  fHeading: fHeading
};

var CardTpl = {
  light: /* :: */[
    /* `hover */[
      728032700,
      /* :: */[
        /* `boxGlow */[
          338336312,
          /* tuple */[
            /* N20 */3890060,
            1.2
          ]
        ],
        /* [] */0
      ]
    ],
    /* [] */0
  ]
};

function BaseUI$WithState(Props) {
  var render = Props.render;
  var init = Props.init;
  var theme = Props.theme;
  var pc = Props.pc;
  var prs = Props.prs;
  var state = React.useState((function () {
          return Curry._1(init, /* () */0);
        }));
  return Curry._5(render, state, theme, pc, prs, "no-key");
}

function el(init, render, theme, pc, prs, key) {
  var Props = {
    render: render,
    init: init,
    theme: theme,
    pc: pc,
    prs: prs,
    key: key
  };
  var render$1 = Props.render;
  var init$1 = Props.init;
  var theme$1 = Props.theme;
  var pc$1 = Props.pc;
  var prs$1 = Props.prs;
  var state = React.useState((function () {
          return Curry._1(init$1, /* () */0);
        }));
  return Curry._5(render$1, state, theme$1, pc$1, prs$1, "no-key");
}

var WithState = {
  make: BaseUI$WithState,
  el: el
};

var Length = 0;

var LimitedCss = 0;

var ruleToCss = UIRules$BwaxMobile.ruleToCss;

var rawToRule = UIRules$BwaxMobile.rawToRule;

var themeColorCss = UIRules$BwaxMobile.themeColorCss;

var ifWide = UIRules$BwaxMobile.ifWide;

var ifNarrow = UIRules$BwaxMobile.ifNarrow;

var wrapForAlignment = UIRules$BwaxMobile.wrapForAlignment;

var wrapForRegion = UIRules$BwaxMobile.wrapForRegion;

var isSpecialSpacing = UIRules$BwaxMobile.isSpecialSpacing;

var presetPX = UIRules$BwaxMobile.presetPX;

var presetREM = UIRules$BwaxMobile.presetREM;

var defaultRules = /* [] */0;

var basePageStyles = /* [] */0;

var debounceBy = 108;

exports.Length = Length;
exports.LimitedCss = LimitedCss;
exports.ruleToCss = ruleToCss;
exports.rawToRule = rawToRule;
exports.themeColor = themeColor;
exports.themeColorCss = themeColorCss;
exports.ifWide = ifWide;
exports.ifNarrow = ifNarrow;
exports.wrapForAlignment = wrapForAlignment;
exports.wrapForRegion = wrapForRegion;
exports.isSpecialSpacing = isSpecialSpacing;
exports.presetPX = presetPX;
exports.presetREM = presetREM;
exports.notLastChild = notLastChild;
exports.defaultRules = defaultRules;
exports.buildClassName = buildClassName;
exports.renderElNearby = renderElNearby;
exports.wrapWithNearby = wrapWithNearby;
exports.wrapForNearby = wrapForNearby;
exports.renderWithClassName = renderWithClassName;
exports.renderEl = renderEl;
exports.box = box;
exports.boxWithClassName = boxWithClassName;
exports.boxWithNearby = boxWithNearby;
exports.pageWithStyles = pageWithStyles;
exports.basePageStyles = basePageStyles;
exports.head = head;
exports.page = page;
exports.row = row;
exports.wrappedRow = wrappedRow;
exports.column = column;
exports.Grid = Grid$1;
exports.grid = grid;
exports.paragraph = paragraph;
exports.text = text;
exports.RichText = RichText$1;
exports.richtext = richtext;
exports.image = image;
exports.VideoBridge = VideoBridge;
exports.video = video;
exports.BridgeToLink = BridgeToLink;
exports.link = link;
exports.empty = empty;
exports.BridgeToIcon = BridgeToIcon;
exports.icon = icon;
exports.BridgeToPDFReader = BridgeToPDFReader;
exports.pdfView = pdfView;
exports.qrcode = qrcode;
exports.ButtonWithState = ButtonWithState;
exports.button = button;
exports.ButtonWithState2 = ButtonWithState2;
exports.button2 = button2;
exports.debounceBy = debounceBy;
exports.Input = Input;
exports.renderInputField = renderInputField;
exports.inputText = inputText;
exports.TextArea = TextArea;
exports.inputTextMultiline = inputTextMultiline;
exports.inputNumber = inputNumber;
exports.inputDecimal = inputDecimal;
exports.option = option;
exports.inputRadio = inputRadio;
exports.inputCheckbox = inputCheckbox;
exports.inputCheckboxes = inputCheckboxes;
exports.downloadAs = downloadAs;
exports.TextTpl = TextTpl;
exports.CardTpl = CardTpl;
exports.WithState = WithState;
/*  Not a pure module */
