import React from 'react'

export default function Attachment (props) {

  const { doc } = props

    function readAttachment (doc) {
        
        if (document.userenv.isWeChat) {
            document.location.href = doc.docUrl
        } else {
            var link = document.createElement('a')
            link.href = doc.docUrl
            link.download = doc.title || '文本'
            link.dispatchEvent(new MouseEvent('click'))
        }
    }
        

    return (
        <div
            className="attachment-doc"
            onClick={() => readAttachment(doc)}>
            <div className={`attachment-doc-icon ${(doc.type || 'unknown-doc-type')}` }/>
            <span className="attachment-doc-title">
                {doc.title}
            </span>
        </div>
    )
}

