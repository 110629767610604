
import React from 'react'

import { pickBy } from 'bwax/list'

const convertToHTML = {
    blockToHTML: (block) => {
        if (block.type === "hr") {
            return <hr />
        }
    },
}

const convertToReact = {
    blockToReact: (block) => {
        if (block.type === "hr") {
            return <hr />
        }
    },
}

const convertFromHTML = {
    htmlToBlock: (nodeName, node) => {
        if (nodeName === 'hr') {
            return {
                type: "hr",
                data: {}
            }
        }
    }
}

export {
    convertToHTML, convertFromHTML, convertToReact
}
