
import { useState, useEffect, useContext } from 'react'
import ReactDOM from 'react-dom'

import invariant from 'invariant'

import { mapObj } from 'bwax/utils'

import DataLoaderContext from 'bwax-ui/store/DataLoaderContext'

import NProgress from 'nprogress'

import { isEqual } from 'lodash'

/**
 * The appData: {
 *      key =>  undefined / <Promise> / { data, loadedAt }
 * }
 * 
 * The paramsers:
 *      loaders: { key => loader function }
 * 
 */
export default function useDataLoader(loaders, { 
    noNProgress = false, dataLoaderContext, appData: givenAppData
} = {} ) {

    const dlc = useContext(DataLoaderContext);

    const { appData: dlcAppData, sessionToken, sandbox, headers, tenantCode, endpoints } = dataLoaderContext || dlc;

    const appData = givenAppData || dlcAppData;

    /// state with initial value from appData
    const [ loadedData, setLoadedData ] = useState(
        mapObj(loaders, (_, key) => appData[key])
    )

    ////  allow the loaders to be changed:
    const [ dataLoaders, setDataLoaders ] = useState(loaders)

    const loaderKeys = Object.keys(dataLoaders);

    const env = { sandbox, sessionToken, headers, tenantCode, endpoints };

    /// for all those not yet loaded
    loaderKeys.forEach(key => {
        const notLoadedYet = appData[key] === undefined
        if (notLoadedYet) {
            const loader = loaders[key]
            if(typeof(loader) !== 'function') {
                console.warn("Loader for", key, "is not a function", loader);
                return
            }
            appData[key] = loader(env) // start loading
            invariant(
                appData[key] instanceof Promise,
                `Loader of ${key} must be an async function (a function that returns a Promise)`
            )
        }
    })

    useEffect(() => {
        setDataLoaders(prev => ({ ...prev, ...loaders }))
    }, [ Object.keys(loaders).join(';')])

    useEffect(() => { 
        let forgetIt = false ///
        async function load() {
            let dataDict = {}
            for (let key of loaderKeys) {
                if (appData[key] instanceof Promise) {
                    /// being loaded
                    if(!noNProgress) {
                        NProgress.start()
                    }

                    const data = await appData[key]
                    if(!noNProgress) {
                        NProgress.done()
                    }                    
                    dataDict[key] = {
                        data,
                        loadedAt: Date.now()
                    }
                }
            }
            /// all pending are loaded:
            if (!forgetIt) {
                setLoadedData(prev => ({ ...prev, ...dataDict }))
            } else {
                //console.log("Forgot")
            }
        }
        load()
        return () => {
            // console.log("Need to clean up?")
            forgetIt = true
        }
    }, [Object.keys(dataLoaders).join(';')]) // 当 key 变化时，重新尝试加载

    useEffect(() => {
        // reload data if necessary
        let forgetIt = false ///
        async function reload() {
            let dataDict = {}
            for (let key of loaderKeys) {
                const target = loadedData[key]

              
                const expired = 
                    !!target 
                        && (target.loadedAt < appData.__cacheInvalidatedAt__)
                        && (key.indexOf("definitions") == -1)
                        && (key !== "NoData")
                          // definition will not be expired locally:
                          // http://git.qunfengshe.com/qunfengshe/bwax-app-admin/issues/792
                    ;

                if (target && expired) {
                    if (appData[key] instanceof Promise) {
                        /// it is already reloading
                        console.log("It is already reloading")
                        continue;
                    }
                    appData[key] = dataLoaders[key](env)                    
                    // NProgress.start()
                    const data = await appData[key]
                    // NProgress.done()
                    /// TODO error handling
                    dataDict[key] = {
                        data,
                        loadedAt: Date.now()
                    }
                }
            }
            /// all pending are loaded:
            if (!forgetIt) {
                setLoadedData(prev => ({ ...prev, ...dataDict }))
            } else {
                console.log("Forgot the reload")
            }
        }
        reload()

    }, [appData.__cacheInvalidatedAt__]) /// 当 cache 被 invalidate 后

    /// 由于 data 可能会被更新 appData[key] 要根据 data 的变化而变化
    useEffect(() => {
        loaderKeys.forEach(key => {
            const target = loadedData[key]
            if (target !== undefined && !(target instanceof Promise)) {
                /// 如果 target 存在且 不是一个 Promise
                appData[key] = loadedData[key]
            }
        })
    }, [loadedData])

    /// force reload, or add new loaders
    async function forceLoad(loaders) {
        let dataDict = {}
        for (let key of Object.keys(loaders)) {
            if (appData[key] instanceof Promise) {
                console.log("It is already reloading")
                continue
            }
            // TODO，这里的 sessionToken 等是否需要有机制来更新？
            appData[key] = loaders[key](env)
            // NProgress.start()
            const data = await appData[key]
            // NProgress.done()
            /// TODO error handling
            dataDict[key] = {
                data,
                loadedAt: Date.now()
            }
            appData[key] = dataDict[key];
        }
        /// all pending are loaded:
        ReactDOM.unstable_batchedUpdates(() => {
            setLoadedData(prev => {
                return ({ ...prev, ...dataDict })

            })
            setDataLoaders(prev => ({ ...prev, ...loaders }))
        })
        /// return dataDict  /// return the data as well

        return mapObj(dataDict, v => v.data)
    }

    function getData(key) {
        const v = (() => {
            let ld = loadedData[key]
            let ad = appData[key]
            if(ld !== undefined && ad !== undefined) {
                // 被其他地方 reload 了
                if(ld.loadedAt < ad.loadedAt) {
                    return ad
                } else {
                    return ld
                }
            } else if(ld !== undefined) {
                return ld
            } else {
                return ad
            }
        })();
        
        //const v = loadedData[key] || appData[key]
        const pickData = d =>  d === undefined ? d : d.data
        const setter = newValue => {
            setLoadedData(prev => {
                const pv = pickData(prev[key])
                const resolved = (
                    typeof (newValue) === 'function' ? newValue(pv) : newValue
                )
                return {
                    ...prev,
                    [k]: {
                        data: resolved,
                        loadedAt: Date.now()
                    }
                }
            })
        }
        return [
            pickData(v),
            setter,
        ]
    }
    return {
        getData, // key => [ value, setter ] 
        forceLoad,
    }
}


export function invalidateCache() {
    /// TODO this is really hardcoded, but let's live with it for now:
    console.log("Invalidate the cache")
    document.data.__cacheInvalidatedAt__ = Date.now()
}