import React from 'react'

import ClipLoader from 'react-spinners/ClipLoader';

import './LoadingPage.less'

export default function LoadingPage(props) {
    return (
        <div className="loading-page-container">
            <div className="loading-page-content">
                <ClipLoader
                    // sizeUnit={"px"}
                    // size={40}
                    color={"#8C8D8F"}
                    loading={true}
                />
            </div>
            <div style={{display: "none"}}>
                __bwax_loading__
            </div>
        </div>
    )
}