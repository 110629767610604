

import React, { useEffect, useState } from 'react'

import Link from 'bwax-ui/page/Link'
import dayjs from 'dayjs';
import Button, { Pressable, iconButton, iconDropdownMenu } from "bwax-ui/components/Button";

import ToggleGroup from 'bwax-ui/components/inputs/ToggleGroup';
import Toggle from 'bwax-ui/components/inputs/Toggle';

import TextInput from 'bwax-ui/components/inputs/TextInput';
import numeral from 'numeral';

import ClampedText from 'bwax-ui/components/ClampedText';

import getImageURL from 'bwax/util/getImageURL';

import UserTrends_inbot from './UserTrends_inbot';

export default function Stat_inbot(props) {

    const { data = {}, slots, events, facade, viewEnv } = props

    const { category } = data;

    const stats = {
        "active-users": ActiveUsers,
        "user-trends": UserTrends_inbot,
    }

    const Stat = category && stats[category];

    function renderContent() {
        if (Stat) {
            return <Stat {...{ facade }} />
        }
        function renderLink(category, label) {
            return (
                <Link className="text-[var(--indigo11)]" key={category} to={"/stat/" + category}>{label}</Link>
            )
        }
        return (
            <div className="py-6 px-6 flex flex-col gap-6">
                {renderLink("active-users", "过去 7 天活跃用户 >>>")}
                {renderLink("user-trends", "用户趋势 >>>")}
                {renderLink("paid-users", "付费用户 >>>")}
            </div>
        )
    }

    return (
        <div className="w-full max-w-3xl self-center h-full">
            {renderContent()}
        </div>
    )
}


// Active Users in recent 7 years:
function ActiveUsers({ facade }) {

    // all plans
    const [plans, setPlans] = useState();
    async function loadPlans() {
        const [result, error] = await facade.list({ entityName: "套餐", fieldPaths: ["名称", "icon"] });
        if (result) {
            setPlans(result);
        }
    }
    // all payments
    const [payments, setPayments] = useState();

    const [tenantPayments, setTenantPayments] = useState();
    async function loadPayments() {
        const [result, error] = await facade.list({
            entityName: "订阅记录",
            condition: [[{ field: "已处理", op: "eq", value: true }, { field: "金额", op: "gt", value: 1 }, { field: "已作废", op: "ne", value: true }]],
            pageSize: 10000,
            fieldPaths: ["租户.名称", "金额", "类型", "标题", "已处理", "已支付", "创建时间"]
        });
        if (result) {
            setPayments(result);
            const tenantPayments = result.reduce((acc, p) => {
                const tenantId = p.租户.id;
                const existing = acc[tenantId];
                if (existing) {
                    return { ...acc, [tenantId]: { ...existing, payments: [...existing.payments, p] } };
                } else {
                    return { ...acc, [tenantId]: { id: tenantId, name: p.租户.名称, payments: [p] } }
                }
            }, {});
            setTenantPayments(tenantPayments);
        }
    }

    useEffect(() => {
        loadPlans();
        loadPayments();
    }, []);

    const [activeTenants, setActiveTenants] = useState();

    const [days, setDays] = useState(7);
    const [sortBy, setSortBy] = useState("days"); // "count" or "days"

    const [onlyToday, setOnlyToday] = useState();
    const [selectedTenant, setSelectedTenant] = useState();

    const [tenantData, setTenantData] = useState({});

    async function loadTenantData(id) {
        if (!tenantData[id]) {
            const [result, error] = await facade.findOne({
                entityName: "助手设定", condition: [[{ field: "租户.id", op: "eq", value: id }]],
                fieldPaths: ["当前套餐.名称", "当前权益.到期日", "当日用量", "总计用量", "会话总量", "消息总量", "笔记总量", "提示执行总量", "文档总量", "任务总量", "网页总量"],
            });

            if (result) {
                setTenantData(prev => ({ ...prev, [id]: result }))
            }
        }
    }

    useEffect(() => {
        if (selectedTenant) {
            loadTenantData(selectedTenant.id);
        }
    }, [selectedTenant && selectedTenant.id]);

    const timeConditions = onlyToday ? 
        [
            // { field: "创建时间", op: "gte", value: dayjs(new Date).subtract(days, "day").hour(0).minute(0).second(0).millisecond(0).toJSON() },
            { field: "创建时间", op: "gte", value: dayjs(new Date).hour(0).minute(0).second(0).millisecond(0).toJSON() },
        ] :
        [
            { field: "创建时间", op: "gte", value: dayjs(new Date).subtract(days, "day").hour(0).minute(0).second(0).millisecond(0).toJSON() },
            { field: "创建时间", op: "lt", value: dayjs(new Date).hour(0).minute(0).second(0).millisecond(0).toJSON() },
        ];

    // aggregate 过去七天的用户事件，group by tenant, count 用户事件数 （事件数），count distinct day (活跃天数)
    async function loadActiveUser() {
        const [result, error] = await facade.aggregate({
            entityName: "页面统计-用户事件",
            aggregate: [{ field: "id", func: "COUNT", aliasName: "count" }],
            group: [
                { field: "租户.名称", aliasName: "name" },
                { field: "租户", aliasName: "tenantId" },
                { field: "创建时间", func: "DATE", aliasName: "date" }
            ],
            condition: [timeConditions],
            sort: [
                { field: "count", order: "DESC" }
            ]
        })
        if (result) {
            const dailyEventCounts = result.filter(r => !!r.tenantId);
            const activeTenantDict = dailyEventCounts.reduce((acc, ec) => {
                const { tenantId, name } = ec;
                const existing = acc[tenantId];
                if (existing) {
                    return {
                        ...acc,
                        [tenantId]: { ...existing, dailyEventCounts: [...existing.dailyEventCounts, ec] }
                    }
                } else {
                    return {
                        ...acc,
                        [tenantId]: { id: tenantId, name, dailyEventCounts: [ec] }
                    }
                }
            }, {});

            const activeTenants = Object.keys(activeTenantDict).map(k => {
                const t = activeTenantDict[k];
                const count = t.dailyEventCounts.map(c => c.count).reduce((acc, c) => acc + c, 0);
                return {
                    ...t,

                    count,
                    dailyEventCounts: t.dailyEventCounts.sort((a, b) => new Date(b.date) - (new Date(a.date))),
                }
            }).sort((a, b) => {
                // if (sortBy == "days") {
                //     return b.dailyEventCounts.length - a.dailyEventCounts.length
                // } else {
                //     return b.count - a.count
                // }
                const r = b.dailyEventCounts.length - a.dailyEventCounts.length;
                return r != 0 ? r : b.count - a.count

            });
            setActiveTenants(activeTenants);

            // 自动 load 前面三十个：
            for (const t of (activeTenants || []).slice(0, 30)) {
                await loadTenantData(t.id)
            }


        }
    }

    useEffect(() => {
        if (days > 0 || onlyToday) {
            loadActiveUser();
        }
    }, [days, onlyToday]);


    function fmk(num) {
        return numeral(num).format("0,0.0a")
    }

    function renderTenantView(tenant, className, onMouseEnter, showValues) {

        const d = tenantData[tenant.id];

        function iconAndText(icon, text, width) {
            return (
                <div className="flex items-center gap-1 font-size-13" style={{
                    width
                }}>
                    <div className="text-[var(--gray10)] font-size-14 flex items-center">{icon}</div>
                    <div>{text}</div>
                </div>
            )
        }

        return (
            <div className={className} onMouseEnter={e => {
                onMouseEnter && onMouseEnter(e)
            }}>
                <div className="flex gap-2 items-center">
                    {/* <div>
                        {tenant.name}
                    </div> */}
                    <ClampedText className="max-w-[10rem]">{tenant.name} </ClampedText>
                    <div className="text-[var(--gray11)] font-size-12">
                        ({tenant.count})
                    </div>
                    <div className="whitespace-pre text-[var(--gray11)] font-size-11 px-1.5 py-1 bg-[var(--gray2)] rounded  leading-none">
                        {tenant.dailyEventCounts.length} 天
                    </div>
                    {
                        d && d.当前套餐 && plans ? (() => {
                            const p = plans.find(p => p.名称 == d.当前套餐.名称);
                            if (p.icon) {
                                return <img className="w-[1rem]" src={getImageURL(p.icon, "thumbnail")} />
                            } else {
                                return "-"
                            }
                        })() : null
                    }
                    {
                        d && d.当前权益 ? (
                            <div className="text-[var(--gray11)] font-size-11">{dayjs(d.当前权益.到期日).format("YY.MM.DD")}</div>
                        ) : null
                    }
                    {
                        tenantPayments && tenantPayments[tenant.id] ? (
                            <div className="whitespace-pre text-[var(--gray11)] font-size-11 px-1.5 py-1 bg-[var(--gray2)] rounded  leading-none">
                                ￥{numeral(tenantPayments[tenant.id].payments.map(p => p.金额).reduce((acc, n) => acc + n, 0)).format("0,0.00")}
                            </div>
                        ) : null
                    }
                </div>
                {showValues && d ? (
                    <div className="flex gap-2 flex-wrap">
                        {iconAndText(<i className='bx bx-crown'></i>, <span>{fmk(d.当日用量)} / {fmk(d.总计用量)}</span>)}
                        {iconAndText(<i className='bx bx-message-square-dots'></i>, <span>{d.会话总量} / {d.消息总量}</span>)}
                        {iconAndText(<i className='bx bx-edit'></i>, <span>{d.笔记总量} / {d.提示执行总量}</span>)}
                        {iconAndText(<i className='bx bx-file-blank'></i>, <span>{d.文档总量}</span>)}
                        {iconAndText(<i className='bx bx-bookmark'></i>, <span>{d.网页总量}</span>)}
                        {iconAndText(<i className='bx bx-task'></i>, <span>{d.任务总量}</span>)}
                    </div>
                ) : null}
            </div>
        )
    }

    function renderTenant(tenant) {
        return (
            <Pressable key={tenant.id} onPress={_ => {
                setSelectedTenant(tenant);
            }}>
                {renderTenantView(tenant, "px-4 py-3 sm:py-2 flex flex-col sm:flex-row gap-2 rounded cursor-pointer sm:items-center hover:bg-[var(--gray2)] justify-between", _ => {
                    loadTenantData(tenant.id)
                }, true)}
            </Pressable>
        )
    }

    function labelAndText(label, t) {
        return (
            <div className="flex gap-2 px-4 px-2" key={label}>
                <div className="text-[var(--gray11)]">{label}</div>
                <div>{t}</div>
            </div>
        )
    }

    return (
        selectedTenant ? (
            <div className="w-full flex flex-col gap-1 py-2 px-2 h-full">
                <div className="px-4 py-2 flex gap-2 justify-between items-center">
                    {iconButton(<i className='bx bx-arrow-back'></i>, _ => {
                        setSelectedTenant();
                    })}
                    {renderTenantView(selectedTenant, "flex gap-2 py-1 px-2  flex-col sm:flex-row sm:items-center justify-between grow", null, true)}
                </div>
                <div className="grow pl-4 pr-4 py-2 overflow-auto gap-2 flex flex-col">
                    {selectedTenant.dailyEventCounts.map(({ date, count }) => {
                        return labelAndText(date, count);
                    })}
                    {/* {renderTenantData(selectedTenant.id)} */}
                </div>
            </div>
        ) : (
            <div className="w-full flex flex-col gap-2 py-2 px-2 h-full">
                <div className="px-4 py-2 flex gap-3 justify-between flex-col sm:flex-row">
                    <div className="flex gap-3 items-center">
                        <div className="flex gap-1 items-center">
                            {onlyToday ?  <div className="w-[0.25rem]"></div> : (
                                <>
                                    <TextInput className="w-[2.5rem] !py-1" styled={true} color={"violet"} value={days || ""} onChange={d => {
                                        if (d) {
                                            setDays(parseInt(d))
                                        } else {
                                            setDays(0)
                                        }
                                    }}></TextInput>
                                    天
                                </>
                            )}
                            活跃人数 {activeTenants ? activeTenants.length : "-"}
                        </div>
                        <Toggle label="只看今天" value={onlyToday} onChange={setOnlyToday} className="!px-2 !py-1"></Toggle>
                    </div>
                    <div className="flex gap-2 items-cennter">
                        {/* <div className="flex gap-1 items-center">
                            <ToggleGroup color={"violet"} value={sortBy} onChange={setSortBy} items={[
                                { value: "days", label: "天数" },
                                { value: "count", label: "事件" },
                            ]} />
                        </div> */}
                        <div className="text-[var(--gray11)]">共￥{payments ? numeral(payments.map(p => p.金额).reduce((acc, n) => acc + n, 0)).format("0,0.00") : "-"}</div>
                    </div>
                </div>
                <div className="grow pl-2 pr-4 py-2 overflow-auto gap-1 flex flex-col">
                    {(activeTenants || []).map(renderTenant)}
                </div>
            </div>
        )
    )
}



// 
function PaidUsers(props) {

    return (
        <div>
            Paid Users
        </div>
    )

}