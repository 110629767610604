

import React, { useEffect, useState } from 'react'

import { AiOutlineFileDone } from 'react-icons/ai'

import './KnowledgeSourceItem.less'

// { entityName, recordId } 

// OpenAI-知识文档, OpenAI-网页收藏, 笔记

export default function KnowledgeSourceItem({ source, facade }) {

    const { entityName, recordId } = source;

    const [data, setData] = useState();

    const sourceStrategy = sourceStrategies[entityName];

    // load data for different entityName
    async function loadData() {

        if (!sourceStrategy) return;

        const { fieldPaths } = sourceStrategy;
        const [result, errors] = await facade.findById(recordId, { entityName, fieldPaths });
        if (!errors) {
            setData(result)
        }
    }

    useEffect(() => {
        loadData();
    }, [source]);

    if (data === undefined || !sourceStrategy) {
        return null
    }

    const isKnowledge = data && (data["加入知识库"] || data["已加入知识库"] || entityName == "OpenAI-文件夹");

    const isNotValid = data == null || !isKnowledge;  // 被删掉，或者从知识库移除

    function renderTitle(data) {
        if(data == null || data["已归档"]) {
            return `该${sourceStrategy.categoryName}已被删除或放入回收站`
        };
        return sourceStrategy.title(data) + (isKnowledge ? "" : "（已不在知识库）")
    }

    return (
        <div className="lc-knowledge-source-item" style={isNotValid ? { opacity: 0.4 } : {}}>
            {sourceStrategy.icon}            
            <div className="item-title">
                {renderTitle(data)}
            </div>
        </div>
    )
}

const sourceStrategies = {
    "OpenAI-知识文档": {
        fieldPaths: ["标题", "加入知识库"],
        icon: <i className='bx bx-file'></i>,
        title: doc => doc.标题,
        categoryName: "文档",
    },

    "OpenAI-网页收藏": {
        fieldPaths: ["标题", "加入知识库"],
        icon: <i className='bx bx-link-alt'></i>,
        title: doc => doc.标题,
        categoryName: "网页链接",
    },

    "笔记": {
        fieldPaths: ["标题", "已加入知识库", "已归档"],
        icon: <AiOutlineFileDone />,
        title: doc => doc.标题,
        categoryName: "笔记",
    },

    "OpenAI-文件夹": {
        fieldPaths: ["名称"],
        icon: <i className='bx bx-folder'></i>,
        title: doc => doc.名称,
        categoryName: "文件夹",
    }
}

