

import React, { useState } from 'react'

import { Transforms, Editor, Path, Node } from 'slate'
import { ReactEditor } from 'slate-react'

import { BiPlus, BiCopy, BiMoviePlay } from 'react-icons/bi'
import { BsStars } from 'react-icons/bs';
import { RiDeleteBinLine } from 'react-icons/ri'
import { MdTextFields, MdFormatQuote, MdFormatListNumbered, MdFormatListBulleted, MdOutlineLoop, MdOutlineImage, 
    MdOutlineHorizontalRule, MdOutlineAudioFile, MdFormatIndentIncrease, MdFormatIndentDecrease
} from 'react-icons/md'

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { ChevronRightIcon, DragHandleDots2Icon, CheckIcon } from '@radix-ui/react-icons';

import omit from 'lodash/omit'

import { cleanSlateValue } from '../../traverseSlateValue';
import { toggleBlockType } from '../buttons/util/createBlockTypeButton'
import UploadImageModal from './UploadImageModal';
import UploadMediaModalContent from './UploadMediaModalContent'
import { isVideo, isAudio } from 'bwax-ui/auxiliary/richtext/plugins/media/mediaTypes'
import { tabF, shiftTabF } from '../handleKeydown';
import { isList } from '../SlateEditor';
import { getCurrentBlockType } from '../handleKeydown';


import { useTrack } from 'bwax-ui/track';

import './BrickToolbar.less'

export const basicBlockTypes = [{
    subLabel: '文本',
    subIcon: <MdTextFields/>,
    type: 'paragraph',
}, {
    subLabel: '标题2',
    subIcon: 'H2',
    type: 'h2',
}, {
    subLabel: '标题3',
    subIcon: 'H3',
    type: 'h3',
}, {
    subLabel: '引用',
    subIcon: <MdFormatQuote/>,
    type: 'block-quote',
}, {
    subLabel: '有序列表',
    subIcon: <MdFormatListNumbered/>,
    type: 'numbered-list',
}, {
    subLabel: '无序列表',
    subIcon: <MdFormatListBulleted/>,
    type: 'bulleted-list',
}]

export function addBlockBelow({editor, slatePath, blockObj, setAddBlockModalOpen, setAddingBlockType}) {
    
    const { type } = blockObj

    // TODO：在 list 中增加一个非 list 节点，原本的 list 被分割。

    if(type === 'image' || type === 'video' || type === 'audio') {
        setAddBlockModalOpen(true)
        setAddingBlockType(type)
    } else if (type === 'hr') {
        // 增加一个 hr 节点和一个新的文本节点，并将光标移到新文本节点上
        Transforms.insertNodes(editor, [ 
            { type: 'hr', children: [{ text: "" }] },
            { type: "paragraph", children: [{ text: ""}] }
        ], { at: [slatePath[0] + 1], select: true })
        ReactEditor.focus(editor)
    } else {
        let newBlock = {}
        if(isList(type)) {
            newBlock = {
                type: type,
                children: [{ 
                    type: 'list-item', 
                    children: [{ type: 'paragraph', children: [{ text: '' }] }]
                }]
            }
        } else {
            newBlock = {
                type: type,
                children: [{ text: '' }]
            }
        }
        Transforms.insertNodes(editor, newBlock, { at: [slatePath[0] + 1], select: true })
        ReactEditor.focus(editor)
    }
}

export function getAtomicBlockTypes (props) {
    const { editor, slatePath, uploadVideo, setModalCanBeCanceledOutside, hideModal } = props
    
    function insertMediaNode (mediaInfo) {
        const { playURL } = mediaInfo
        const mediaType = (() => {
            if (isVideo(playURL)) {
                return "VIDEO"
            }
            if (isAudio(playURL)) {
                return "AUDIO"
            }
            return "UNKNOWN"
        })()

        if (mediaType !== "UNKNOWN") {
            const atomicNode = {
                type: isVideo(playURL) ? "video" : "audio",
                url: playURL,
                children: [{ text: '' }]
            }
            Transforms.insertNodes(editor, [ 
                atomicNode,
                { type: "paragraph", children: [{ text: ""}] }
            ], { at: [slatePath[0] + 1], select: true })

            ReactEditor.focus(editor)
        } else {
            alert('暂不支持该格式')
        }
    }

    return [{
        subLabel: '分隔线',
        subIcon: <MdOutlineHorizontalRule/>,
        type: 'hr',
    }, {
        subLabel: '图片',
        subIcon: <MdOutlineImage/>,
        type: 'image',
        renderContent: (addBlockModalOpen, changeModalOpen) => {
            return (
                <UploadImageModal {...props} addBlockModalOpen={addBlockModalOpen} onOpenChange={changeModalOpen}/>
            )
        }
    }, {
        subLabel: '视频',
        subIcon: <BiMoviePlay/>,
        type: 'video',
        renderContent: (addBlockModalOpen, changeModalOpen) => (
            <UploadMediaModalContent addBlockModalOpen={addBlockModalOpen} onOpenChange={changeModalOpen} 
                mediaType="video" uploadMedia={uploadVideo} hideModal={hideModal}
                onConfirm={mediaInfo => insertMediaNode(mediaInfo)}
                setModalCanBeCanceledOutside={setModalCanBeCanceledOutside}
            />
        )
    }, {
        subLabel: '音频',
        subIcon: <MdOutlineAudioFile/>,
        type: 'audio',
        renderContent: (addBlockModalOpen, changeModalOpen) => (
            <UploadMediaModalContent addBlockModalOpen={addBlockModalOpen} onOpenChange={changeModalOpen} 
                mediaType="audio" uploadMedia={uploadVideo} hideModal={hideModal}
                onConfirm={mediaInfo => insertMediaNode(mediaInfo)}
                setModalCanBeCanceledOutside={setModalCanBeCanceledOutside}
            />
        )
    }]
} 

export default function BrickToolbar(props) {
    const { editor, brickToolbarInfoRef, changeBrickToolbarInfo, setAiInputInfo, aiInputInfo, setHighlightRange, updateBrickDOMRefs,
        addingBlockType, setAddingBlockType
    } = props
    const { show, top, slatePath, currentBlockType } = brickToolbarInfoRef.current

    const [addBlockModalOpen, setAddBlockModalOpen] = useState(false)
    // const [addingBlockType, setAddingBlockType] = useState(null)
    const [modalCanBeCanceledOutside, setModalCanBeCanceledOutside] = useState(true) // 上传中不允许点击外部隐藏 modal

    const track = useTrack();

    function toggleBrickToolbarMenuOpen (open) {
        if(Node.has(editor, slatePath)) {
            changeBrickToolbarInfo({
                ...brickToolbarInfoRef.current,
                isOpened: open
            })

            if(open) {
                Transforms.select(editor, slatePath)
            } else {
                Transforms.deselect(editor)
            }
        }
    }

    function selectChangedContent (range) {
        setTimeout(() => {
            if(typeof(window) !== 'undefined' && typeof(document) !== 'undefined') {
                Transforms.select(editor, range)
                const domRange = ReactEditor.toDOMRange(editor, range)
                const selection = window.getSelection()
                selection.removeAllRanges();
                selection.addRange(domRange)
            }
        }, 16)
    }

    function changeBlockType (blockType) {
        if(blockType === 'paragraph') {
            toggleBlockType(editor, getCurrentBlockType(editor, slatePath))
        } else {
            toggleBlockType(editor, blockType)
        }

        updateBrickDOMRefs()
    }

    function hideModal () {
        setAddBlockModalOpen(false)
        setAddingBlockType(null)
    }

    const blockTypesProps = {...props, slatePath, setModalCanBeCanceledOutside, hideModal }

    const mediaItems = [
        { 
            label: "复制", 
            icon: <BiCopy/>,
            onSelect: () => {
                const [node, __] = Editor.node(editor, slatePath)
                const highestNodeIsList = isList(getCurrentBlockType(editor, slatePath))

                if(highestNodeIsList) {
                    // 新增一个 list-item
                    Transforms.insertNodes(editor, 
                        {
                            type: 'list-item',
                            children: [cleanSlateValue(node)],
                            
                        }, 
                        { at: Path.next(slatePath.slice(0, 2)), select: true }
                    )
                } else {
                    Transforms.insertNodes(editor, cleanSlateValue(node), { at: Path.next(slatePath), select: true })
                }
                const nextPath = highestNodeIsList ? Path.next(slatePath.slice(0, 2)) : Path.next(slatePath)
            
                const newSelectionRange = {
                    ...editor.selection,
                    anchor: { offset: 0, path: highestNodeIsList ? [...nextPath.slice(0, 2), 0, 0] : [...nextPath, 0] },
                }
                selectChangedContent(newSelectionRange)
                updateBrickDOMRefs()
            }
        },
        { 
            label: "删除", 
            icon: <RiDeleteBinLine/>,
            onSelect: () => {
                const [node, path] = Editor.node(editor, slatePath, { depth: 1 })
                if(node.type === "bulleted-list" || node.type === "numbered-list") {
                    // list 中，只删除这个 list-item
                    Transforms.removeNodes(editor, { at: Path.parent(slatePath) })
                } else {
                    const prevBlock = Editor.previous(editor, { at: slatePath.slice(0, 1) });
                    Transforms.removeNodes(editor, { at: slatePath.slice(0, 1) })
                    if(!prevBlock || prevBlock[0].type === 'hr') {
                        // 如果是第一个节点或者前一个节点是分隔符，则重新添加一个空行
                        Transforms.insertNodes(editor,
                            { type: 'paragraph', children: [{ text: '' }] },
                            { at: slatePath.slice(0, 1), select: true }
                        )
                    }
                    
                }
                updateBrickDOMRefs()
            }
        },
        { 
            label: "在下方添加", 
            icon: <BiPlus/>,
            subItems: [...basicBlockTypes, ...getAtomicBlockTypes(blockTypesProps)].map(blockObj => {
                return {
                    ...blockObj,
                    onSubSelect: () => {
                        addBlockBelow({editor, slatePath, blockObj, setAddBlockModalOpen, setAddingBlockType})
                        updateBrickDOMRefs()
                    }
                }
            })
        }
    ]

    const items = [
        { 
            label: "AI", 
            icon: <BsStars className="mt-[-1px]" />,
            className: "text-[var(--violet11)] font-medium",
            onSelect: () => {
                // 选中整个 node, 弹出 ai 输入框
                const [focusedNode, focusedPath] = Editor.node(editor, editor.selection)
                const domNode = ReactEditor.toDOMNode(editor, focusedNode)
                const rect = domNode.getBoundingClientRect();

                track("note_ai_trigger", { method: "brick-toolbar"})

                setHighlightRange(editor.selection)

                setAiInputInfo({
                    show: true,
                    action: 'activeBySelect',
                    position: { ...omit(rect, ['width', 'height']), top: rect.top + rect.height + 4 },
                    triggerByBrickToolbar: true
                })
            }
        },
        { 
            label: "转化为", 
            icon: <MdOutlineLoop/>,
            subItems: basicBlockTypes.map(blockObj => {
                const { type } = blockObj
                let isBlockTypeActive = false
                if(slatePath && Node.has(editor, slatePath)) {
                    // 如果在 slatePath 处有 descendant node
                    isBlockTypeActive = getCurrentBlockType(editor, slatePath) === type
                }

                return {
                    ...blockObj,
                    isBlockTypeActive,
                    onSubSelect: () => changeBlockType(type)
                }
            })
        },
        ...mediaItems
    ]

    const indentItems = (() => {
        if(slatePath && Node.has(editor, slatePath) && isList(currentBlockType)) {
            const [parentNodeOfSlatePath, parentPathOfSlatePath] = Editor.parent(editor, slatePath)
            
            if((slatePath[slatePath.length - 1] !== 0) ||
                (parentNodeOfSlatePath.type === 'nestWrapper' && parentPathOfSlatePath[parentPathOfSlatePath.length - 1] !== 0)
            ) {
                return []
            }

            return [{
                label: '缩进',
                icon: <MdFormatIndentIncrease/>,
                subItems: [{
                    subLabel: '增加缩进',
                    subIcon: <MdFormatIndentIncrease/>,
                    onSubSelect: () => tabF(editor)
                }, {
                    subLabel: '减少缩进',
                    subIcon: <MdFormatIndentDecrease/>,
                    onSubSelect: () => shiftTabF(editor)
                }]
            }]
        }
        
        return []
        
    })()

    const listFormatItems = (() => {
        if(slatePath && slatePath.length >= 3 && Node.has(editor, slatePath) && isList(currentBlockType)) {
            const ancestors = Array.from(Node.ancestors(editor, slatePath, { reverse: true }))
            const [currentList, currentListPath] = ancestors.find(ancestor => {
                const [ancestorNode, _] = ancestor
                return isList(ancestorNode.type)
            })
            const [_, currentListItemPath] = ancestors.find(ancestor => {
                const [ancestorNode, _] = ancestor
                return ancestorNode.type === 'list-item'
            })

            const [__, parentPathOfSlatePath] = Editor.parent(editor, slatePath)

            if(slatePath[slatePath.length - 1] === 0 && currentListItemPath[currentListItemPath.length - 1] === 0 && 
                parentPathOfSlatePath[parentPathOfSlatePath.length - 1] === 0
            ) {
                // 是第一项 list-item 的第一项 paragraph 才显示编号样式选项
                let subItems = []
                const { listStyleType } = currentList
                function changeListStyleType (styleType) {
                    Transforms.setNodes(editor, { listStyleType: styleType }, {
                        at: currentListPath
                    })
                }

                if(currentList.type === 'numbered-list') {
                    subItems = [{
                        subLabel: '数字',
                        isBlockTypeActive: listStyleType === 'decimal',
                        onSubSelect: () => changeListStyleType("decimal")
                    }, {
                        subLabel: '字母',
                        isBlockTypeActive: listStyleType === 'lower-alpha',
                        onSubSelect: () => changeListStyleType("lower-alpha")
                    }, {
                        subLabel: '罗马数字',
                        isBlockTypeActive: listStyleType === 'lower-roman',
                        onSubSelect: () => changeListStyleType("lower-roman")
                    }]
                } else if (currentList.type === 'bulleted-list') {
                    subItems =[{
                        subLabel: '实心圆点',
                        isBlockTypeActive: listStyleType === 'disc',
                        onSubSelect: () => changeListStyleType("disc")
                    }, {
                        subLabel: '空心圆点',
                        isBlockTypeActive: listStyleType === 'circle',
                        onSubSelect: () => changeListStyleType("circle")
                    }, {
                        subLabel: '实心方块',
                        isBlockTypeActive: listStyleType === 'square',
                        onSubSelect: () => changeListStyleType("square")
                    }]
                }

                return [{
                    label: '编号样式',
                    icon: <MdFormatListBulleted/>,
                    subItems
                }]
            }
        }
        return []
    })()

    const isMedia = ['image', 'video', 'audio'].includes(currentBlockType)
    const actualItems = isMedia ? mediaItems : [...items, ...indentItems, ...listFormatItems]

    function renderAddingBlock () {

        const addingBlock = getAtomicBlockTypes(blockTypesProps).find(blockObj => blockObj.type === addingBlockType)
        const { renderContent } = addingBlock

        function changeModalOpen (open) {
            if(modalCanBeCanceledOutside && !open || open) {
                // hide || show
                setAddBlockModalOpen(open)
                if(modalCanBeCanceledOutside && !open) {
                    setAddingBlockType(null)
                }
            }
        }
        
        return renderContent(addBlockModalOpen, changeModalOpen)

    }

    return (
        <>
            { show && !aiInputInfo.show ? (
                <div className='editor-brick-toolbar' style={{ top: top + 10 }}>
                    <DropdownMenu.Root onOpenChange={open => {
                        toggleBrickToolbarMenuOpen(open)
                    }}
                    >
                        <DropdownMenu.Trigger asChild className={"editor-brick-toolbar-trigger"}>
                            <div className='editor-brick-toolbar-trigger rounded'>
                                <DragHandleDots2Icon />
                            </div>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Portal>
                            <DropdownMenu.Content
                                className="lc-context-menu lc-base"
                                sideOffset={5}
                                align={'center'}
                                side={'left'}
                            >
                                {actualItems.map(({ label, onSelect, icon, subItems, className }, index) => {
                                    function renderItemContent (icon, label, isBlockTypeActive) {

                                        return (
                                            <>
                                                <div style={{ display: 'flex', marginRight: 'auto' }} className={className}>
                                                    {
                                                        icon ? 
                                                            <div className="icon" 
                                                                style={ typeof(icon) === 'string' ? { fontSize: 12 } : {}}
                                                            >
                                                                {icon}
                                                            </div> : null
                                                    }
                                                    <div className="label">{label}</div>
                                                </div>
                                                {
                                                    isBlockTypeActive ? (
                                                        <div ><CheckIcon/></div>
                                                    ) : null
                                                }
                                            </>
                                        )
                                    }
                                    return subItems ? (
                                        <DropdownMenu.Sub key={index}>
                                            <DropdownMenu.SubTrigger className='context-menu-item brick-toolbar-sub-trigger'>
                                                {renderItemContent(icon, label)}
                                                <div className="arrow">
                                                    <ChevronRightIcon />
                                                </div>
                                            </DropdownMenu.SubTrigger>
                                            <DropdownMenu.Portal>
                                            <DropdownMenu.SubContent
                                                className="lc-context-menu lc-base"
                                                sideOffset={2}
                                                alignOffset={-5}
                                            >
                                                {
                                                    subItems.map(({ subLabel, subIcon, onSubSelect, isBlockTypeActive }, idx) => {

                                                        return (
                                                            <DropdownMenu.Item key={idx} className='context-menu-item brick-toolbar-subMenu-item' onSelect={() => {
                                                                if(!isBlockTypeActive) {
                                                                    onSubSelect()
                                                                    // changeBrickToolbarInfo({ show: false })
                                                                }
                                                            }}>
                                                                {renderItemContent(subIcon, subLabel, isBlockTypeActive)}
                                                            </DropdownMenu.Item>
                                                        )
                                                    })
                                                }
                                            </DropdownMenu.SubContent>
                                            </DropdownMenu.Portal>
                                        </DropdownMenu.Sub>
                                    ) : (
                                        <DropdownMenu.Item key={index} className="context-menu-item brick-toolbar-menu-item" onSelect={() => {
                                            onSelect()
                                            // changeBrickToolbarInfo({ show: false })
                                        }}>
                                            {renderItemContent(icon, label)}
                                        </DropdownMenu.Item>
                                    )
                                })}
                            </DropdownMenu.Content>
                        </DropdownMenu.Portal>
                    </DropdownMenu.Root>
                </div>
            ) : null
            }
            { addingBlockType ? renderAddingBlock() : null }
        </>
    )
}
