import React, { useEffect, useRef, useState } from 'react';

import ReactDOM from 'react-dom';

import RenderWithData from 'bwax-ui/store/RenderWithData';
import { USER_DATA } from 'bwax/store/DataCache';
import { hashCode } from 'bwax/utils';

import { loadRecordList } from './dataHelper';

import WindowScrollHelper from '../misc/WindowScrollHelper';

export default function RecordListWithPullUpLoadMore (props) {

    const { 
        query_config, get_depended_paths, wrapWithContainer, toElement, pageSize, offset = 0, isLazy, loading, loading_el,
        facade,
    } = props;

    const [ entityName, _ ] = query_config;

    const fieldPaths = get_depended_paths(facade.entity_dict, facade.data_type_dict, 0, entityName) || [];
    const dataKey = "WithRecordList-" + hashCode(JSON.stringify({ query_config, fieldPaths })); // query_config + render_fn deps

    const loadData = async () => {
        return await loadRecordList ({ query_config, entityName, pageSize, offset, fieldPaths, facade });
    };

    const loadMoreData = async offset => {
        return await loadRecordList ({ query_config, entityName, pageSize, offset, fieldPaths, facade });
    }

    return (
        <RenderWithData {...{
            loadData,
            cacheType: USER_DATA,
            dataKey,
            Component: Inner,
            noSuspense: true,
            
            isLazy, fallback: loading,

            loading_el, loadMoreData,            
            // 
            toElement, wrapWithContainer, facade, entityName, 
        }}
        />
    )

}

function Inner (props) {

    const { data, toElement, wrapWithContainer, facade, entityName, loading_el, loadMoreData } = props;
    const [ result, error ] = data;

    const [ loadingMore, setLoadingMore ] = useState(false);
    const [ nextOffset, setNextOffset ] = useState(
        result ? result.offset + result.data.length : -1
    );
    const [ hasMore, setHasMore ] = useState(
        result ? result.offset + result.data.length < result.count : false
    )
    // 初始用 loadData () 得到的值；
    const [ records, setRecords ] = useState(result && result.data ? result.data : []);

    // 用于 scroll helper 里面的 event
    const nextOffsetRef = useRef(nextOffset);
    nextOffsetRef.current = nextOffset;
    const hasMoreRef = useRef(hasMore);
    hasMoreRef.current = hasMore;
    // 监听上拉加载
    useEffect(() => {
        const scrollHelper = new WindowScrollHelper({
            // options
            topThreshold: 600,
            bottomThreshold: 300,
            onScrolledToBottom: _ => {
                console.log("RACHED TO BOTTOM", hasMoreRef.current);
                if(hasMoreRef.current) {
                    (async () => {

                        setLoadingMore(true);
                        const [ result, error ] = await loadMoreData(nextOffsetRef.current);
                        setLoadingMore(false);
                        if(result) {
                            ReactDOM.unstable_batchedUpdates(() => {
                                setNextOffset(result.offset + result.data.length);
                                setHasMore(result.offset + result.data.length < result.count);

                                setRecords(prev => [...prev, ...result.data]);

                            });
                        }


                    })();
    
                }

            },
        })
        return () => {
            scrollHelper.cleanUp();
        }
    }, [])


    const els = records.map(r =>  toElement(facade.entity_dict, facade.data_type_dict, entityName, r));


    return wrapWithContainer(loadingMore && loading_el ? [ ...els, loading_el ]: els );


}



export function create(props) {
    return <RecordListWithPullUpLoadMore {...props} />
}


