
import React, { Suspense, useEffect } from "react";

import { Overlay, useModalOverlay, useOverlayTrigger } from 'react-aria';

import { useOverlayTriggerState } from 'react-stately';
import { IconButton, Pressable } from './Button';

import { Cross2Icon } from '@radix-ui/react-icons';

import classNames from "classnames";

import './Modal.less';

export function ModalStateless({ modalRef, state, children, className, contentClassName, contentRef, isMain = true, closeButton = true, isLarge = false, isTransparent = false, ...props }) {
    const ref = React.useRef(null);
    const { modalProps, underlayProps } = useModalOverlay(props, state, ref);

    useEffect(() => {
        if (state.isOpen && ref.current) {
            ref.current.focus();
        }
    }, [state.isOpen, ref.current]);

    return (
        <Overlay>
            <div className="lc-modal-underlay" {...underlayProps}>

                <div {...modalProps} tabIndex={-1}
                    style={isTransparent ? {
                        height: "unset",
                        width: "unset",
                        background: "transparent",
                    } : {}}
                    className={
                        classNames(
                            "lc-modal lc-base font-size-14", className,
                            {
                                "is-large": isLarge, 
                                "is-transparent": isTransparent,
                            }
                        )
                    }
                    ref={r => {
                        ref.current = r;
                        if (modalRef) { modalRef.current = r };
                    }} data-main={isMain}
                >
                    <Suspense fallback={null}>
                        {/* 内容可能会是 promise mode */}
                        <div className={classNames("lc-modal-content", contentClassName)} ref={r => {
                            if(typeof(contentRef) == "function") {
                                contentRef(r)
                            } else if(contentRef) {
                                contentRef.current = r;
                            }
                            
                        }}
                        style={isTransparent ? { padding: 0, margin: 0 } : {}}
                        >
                            {children}
                        </div>
                        {closeButton ? (
                            <IconButton style={{ top: 10, right: 10 }} onPress={e => {
                                setTimeout(() => {
                                    state.close()
                                }, 16)
                            }}>
                                <Cross2Icon />
                            </IconButton>
                        ) : null}
                    </Suspense>
                </div>

            </div>
        </Overlay>

    );
}


export default function Modal({ children, ...props }) {

    const state = useOverlayTriggerState(props);
    const { overlayProps } = useOverlayTrigger(
        { type: 'dialog' },
        state
    );

    return state.isOpen ? (
        <ModalStateless {...props} state={state}>
            {React.cloneElement(typeof (children) == "function" ? children(state.close) : children, overlayProps)}
        </ModalStateless>
    ) : null
}


export const ModalTrigger = React.forwardRef(({ modalClassName, className, content, children, onBeforePress = _ => { }, ...props }, ref) => {

    const state = useOverlayTriggerState(props);
    const { triggerProps, overlayProps } = useOverlayTrigger(
        { type: 'dialog' },
        state
    );

    const { onPress, ...otherTriggerProps } = triggerProps

    return (
        <>
            <Pressable {...otherTriggerProps} onPress={e => {
                onBeforePress();
                onPress(e);
            }} ref={ref}>{children}</Pressable>
            {state.isOpen &&
                (
                    <ModalStateless className={modalClassName} {...props} state={state}>
                        {React.cloneElement(typeof (content) == "function" ? content(state.close) : content, overlayProps)}
                    </ModalStateless>
                )}
        </>
    );
});