// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Lang_eval$BwaxMobile = require("../../lang_eval.bs.js");

function fail(name) {
  throw [
        Lang_eval$BwaxMobile.Eval_exn,
        "Maybe." + (name + ": Invalid Arguments")
      ];
}

var externals_000 = "Maybe";

var externals = /* tuple */[
  externals_000,
  /* [] */0
];

var src = "\nmodule Maybe;\n\nwithDefault df m : a -> Maybe a -> a =\n  case m of\n  | Just v -> v\n  | Nothing -> df\n;\n\nmap f m : (a -> b) -> Maybe a -> Maybe b =\n  case m of\n  | Just v -> Just (f v)\n  | Nothing -> Nothing\n;\n\nmap2 f ma mb : (a -> b -> c) -> Maybe a -> Maybe b -> Maybe v =\n  case (ma, mb) of\n  | (Just x, Just y) -> Just (f x y)\n  | _ -> Nothing\n;\n\nandThen f m : (a -> Maybe b) -> Maybe a -> Maybe b =\n  case m of\n  | Just v -> f v\n  | Nothing -> Nothing\n;\n\n-- Monads functions:\n-- Same as andThen, but it is a more general name.\nflatMap f m : (a -> Maybe b) -> Maybe a -> Maybe b =\n  case m of\n  | Just v -> f v\n  | Nothing -> Nothing\n;\n\nreturn v: a -> Maybe a = Just v;\nfail: Maybe a = Nothing;\n\nguard test: Bool -> Maybe a = \n  if test then Just () else Nothing;\n;\n\n---\n\n\n\nisNothing m =\n  case m of \n  | Nothing -> True \n  | Just _ -> False\n;\n\nisSomething m =\n  case m of \n  | Nothing -> False \n  | Just _ -> True\n;\n\n\n";

var externals_vals = /* [] */0;

exports.src = src;
exports.fail = fail;
exports.externals_vals = externals_vals;
exports.externals = externals;
/* Lang_eval-BwaxMobile Not a pure module */
