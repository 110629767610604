

export default function convertSlateToMarkdown(slateValue) {

    /**
     * bulleted-list: - 
     * numbered-list: 1. 
     * blockquote: >
     * h2: ##
     * h3: ###
     */

    if(!Array.isArray(slateValue)) {
        return null
    }
    const markdownValues = slateValue.map((node, idx) => {
        function getTextContent (node) {
            function getChildrenTextContent (node, listType) {
                let textContent = ""
                if (node.children && node.children.length > 0) {
                    node.children.map((child, idx) => {
                        if(child.type === 'list-item') {
                            if(listType === 'bulleted-list') {
                                return textContent += `- ${getChildrenTextContent(child)}\n`
                            } else {
                                return textContent += `${idx + 1}. ${getChildrenTextContent(child)}\n`
                            }
                        } else {
                            return textContent += getChildrenTextContent(child)
                        }
                    })
                } else {
                    textContent = node.bold ? `**${node.text}**` : node.text
                }

                return textContent
            }

            if(node.type === 'h2') {
                return `## ${getChildrenTextContent(node)}`
            } else if (node.type === 'h3') {
                return `### ${getChildrenTextContent(node)}`
            } else if (node.type === 'block-quote') {
                return `> ${getChildrenTextContent(node)}`
            } else if (node.type === 'bulleted-list') {
                return `${getChildrenTextContent(node, 'bulleted-list')}`
            } else if (node.type === 'numbered-list') {
                return `${getChildrenTextContent(node, 'numbered-list')}`
            } else {
                return `${getChildrenTextContent(node)}`
            }
        }
        const n = getTextContent(node);

        return n;

    }).filter(x => x && x.trim() !== "").join("\n\n")

    return markdownValues


}