

import { parseJSON } from 'bwax/utils'
import { runDataQuery } from 'bwax/query/runClientQuery';

// first set is params;  second set is dlc
export default function UploadFile({ file, isPublic = false, uploadFor, onUploadProgress, cancelRequest }) {

    return async env => {
        if(!file) {
            //// TODO other error handling?
            return [undefined, "没有文件"]
        }

        const result = await runDataQuery(env)(QueryText)({
            input: {
                public: isPublic, 
                uploadFor,
                clientMutationId: `${Date.now()}`
            }
        }, file, { onUploadProgress, cancelRequest })

        const { data, errors } = parseJSON(result) || {}

        if(data && data.uploadAttachment && data.uploadAttachment.addedAttachment) {
            const attachment = data.uploadAttachment.addedAttachment.node
            return [ attachment, undefined ]
        }
        /// TODO error handling
        return [ undefined, errors || "未知错误" ]
    }
}


const QueryText = `
    mutation UploadAttachmentMutation(
        $input: UploadAttachmentInput!
    ) {
        uploadAttachment(input: $input) {
            addedAttachment {
                node {
                    id
                    url
                    title
                    path
                    size
                    contentType
                }
            }
        }
    }
`