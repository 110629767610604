
import React, { useState, useEffect } from 'react'



import { Pressable } from 'bwax-ui/components/Button';
import classNames from 'classnames';

import { useTrack } from 'bwax-ui/track';

import Tooltip from 'bwax-ui/components/Tooltip';


// default available functions
export const defaultAvailableFunctions = ["OnlineSearch"];

//  the given value is a full contentOptions (内容生成选项)
export default function FunctionToggle({ name, label, value: givenValue, onChange, mutualExclusive = [] }) {

    const track = useTrack();

    const [localValue, setLocalValue] = useState(givenValue);

    useEffect(() => {
        if (givenValue !== localValue) {
            setLocalValue(givenValue);
        }
    }, [givenValue]);

    const availableFunctions = (localValue || {}).availableFunctions || defaultAvailableFunctions;

    const enabled = availableFunctions.indexOf(name) !== -1;
    function toggle() {
        track("ui_function_toggle", { func_name: name, enabled: !enabled });

        const newValue = {
            ...(localValue || {}),
            availableFunctions: enabled ? (availableFunctions.filter(f => f !== name)) :
                [...availableFunctions, name].filter(n => {
                    return mutualExclusive.indexOf(n) == -1
                })
        };

        setLocalValue(newValue);

        onChange(newValue);
    }

    return (
        <Pressable onPress={_ => toggle()}>
            <div data-color={enabled ? "grass" : "gray"} className={classNames(
                "flex px-4 py-1.5 shadow rounded font-size-13 justify-between items-center cursor-pointer gap-2",
                {
                    "bg-[var(--grass3)] text-[var(--grass11)]": enabled,
                    "text-[var(--gray8)] bg-[var(--lc-color-bg-3)]": !enabled,
                }
            )}>
                {label}
                {enabled ? <i className='bx bx-check-circle' /> : <i className='bx bx-minus-circle'></i>}
            </div>
        </Pressable>
    )
}

