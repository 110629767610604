

import React from 'react'

import RelatedItems from './RelatedItems';

import { AiOutlineFileDone } from 'react-icons/ai'

export default function RelatedKnowledges({ value }) {

    return <RelatedItems items={value} title="使用的知识库内容" renderItem={(record, index) => <KnowledgeItem key={index} record={record} index={index} />} />
}


const icons = {
    "表单数据记录": <i className='bx bx-spreadsheet'></i>,
    "OpenAI-网页收藏": <i className='bx bx-link-alt'></i>,
    "OpenAI-知识文档": <i className='bx bx-file'></i>,
    "OpenAI-知识词条": <i className='bx bx-text' ></i>,
    "笔记": <AiOutlineFileDone />

}


   // 可能是 
    //     {
    //       "相关记录": {
    //         "标题": "Wuba公司介绍2023-3-16",
    //         "实体名称": "OpenAI-知识文档",
    //         "id": "T3BlbkFJRW50aXR5S25vd2xlZGdlRG9jdW1lbnQ6MTk="
    //       },
    //       "知识": "Wuba公司介绍\n- 数码化，智能化和信息化系统\n  - 目的：优化生产，全力支持客户快速稳定的发展\n  - 愿景：成为拥有全球竞争力的智能制造企业，创建一个让客户信赖，共同成长与发展的平台，实现为人民创造更美好的绿色生活的美好愿景。\n  - 环境保护与世界每个人都息息相关，所以我们一直在持续不断的在研发环境可持续性产品来引领人们走向美好的环保生活。也为了实现为人民创造更美好的绿色生活的愿景，建设更美丽的地球---我们共同的家园而不懈努力。\n    1. 我们承诺尽最大努力减少从资源利用到任何生产活动对环境的影响。\n    2. 减少生产过程中的用水和污染，以改善、管理和保护水资源。\n    3. 减少塑料的使用和浪费，使微塑料污染远离大自然和各种生物。\n    4. 减少纸质包装和木浆制品的使用，保护森林。\n    5. 减少温室气体的排放，减缓全球变暖。"
    //     },


    //  也可能是
    //  refKnowledgeList{
    //    text
    //    refRecord {id entity title}
    //  }
    //  

function KnowledgeItem ({ record, index }) {

    const entityName = record.相关记录 ? record.相关记录.实体名称 : record.refRecord.entity;
    const title = record.相关记录 ? record.相关记录.标题 : record.refRecord.title;

    // TODO 可能需要去进一步获取知识来源的具体信息，比如文档类型等
    const icon = icons[entityName] || null;


    return (
        <div className="knowledge-item">
            { index + 1 }. { icon } {title}
        </div>
    )

}
