import React, { useRef, useEffect } from 'react';

import throttle from "lodash/throttle";

export default function AutoPlayVideo({ className, src, alt, scrollContainer }) {

    const ref = useRef();

    const isVideoPlaying = video => !!video && !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);

    useEffect(() => {
        if (typeof (document) != "undefined" && typeof (window) !== 'undefined' && ref.current) {

            function play() {
                ref.current.play()
                if (window.WeixinJSBridge) {
                    WeixinJSBridge.invoke('getNetworkType', {}, function (e) {
                        ref.current.play()
                    })
                }
            }

            function onScroll() {
                const isInViewport = isElementInViewport(ref.current);
                const isPlaying = isVideoPlaying(ref.current);
                if (isInViewport && !isPlaying) {
                    play();
                } else if (!isInViewport && isPlaying) {
                    ref.current.pause()
                    if (window.WeixinJSBridge) {
                        WeixinJSBridge.invoke('getNetworkType', {}, function (e) {
                            ref.current.pause()
                        })
                    }

                }
            }

            const onScrollThrottled = throttle(onScroll, 128);

            (scrollContainer || window).addEventListener('scroll', onScrollThrottled)


            // 一开始也要尝试开始播放
            const isInViewport = isElementInViewport(ref.current);
            if(isInViewport) {
                play();
            }

            return _ => {
                (scrollContainer || window).removeEventListener('scroll', onScrollThrottled)
            }
        }

    }, [ref.current]);

    return (
        <video className={className} src={src} loop muted playsInline ref={ref} alt={alt} />
    )
}

export function create(props) {
    return (
        <AutoPlayVideo {...props} />
    )
}


function isElementInViewport(el) {
    if (!el) {
        return false;
    }

    const rect = el.getBoundingClientRect();
    if (rect.height == 0) {
        return false
    }

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
}
