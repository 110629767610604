

import React, { useState, useEffect } from 'react'

import Grid from "bwax-ui/components/Grid"
import ClampedText from 'bwax-ui/components/ClampedText';

import DropdownMenu from 'bwax-ui/components/DropdownMenu';
import Modal from 'bwax-ui/components/Modal'

import Avatar from 'bwax-ui/components/Avatar';
import EditPersonaForm from './EditPersonaForm';
import AddPersonaSharingForm from './AddPersonaSharingForm';

import { Pressable } from "bwax-ui/components/Button"

import { toast } from 'bwax-ui/components/Toast';
import ViewPersonaSharing from './ViewPersonaSharing';

import "./PersonaGrid.less"

export const personaFieldPaths = ["名称", "提示", "用户选项", "内容生成选项", "欢迎消息", "头像", "置顶", "chatModel"];

import { useTrack } from 'bwax-ui/track';

export default function PersonaGrid({ bindPersonaAdded, facade, onSelect, currentUserId, onPin, onPersonaListUpdate = _ => {} }) {

    const track = useTrack();
    
    const [personaList, setPersonalList] = useState();
    const [editingPersona, setEditingPersona] = useState();

    const [personaSharings, setPersonaSharings] = useState();

    useEffect(() => {
        if(personaList) {
            onPersonaListUpdate(personaList)
        }        
    }, [personaList]);

    const loadPersonas = async () => {
        const [result, error] = await facade.list({
            entityName: "OpenAI-会话角色",
            fieldPaths: personaFieldPaths,
            sort: [{ field: "置顶", order: "DESC" }, { field: "显示权重", order: "DESC" }],
            condition: [[{ field: "已归档", op: "ne", value: true }]],
            pageSize: 1000,
        });
        if (!error) {
            setPersonalList(result);
        }
    }

    const sharingFieldPaths = ["角色", "已暂停", "说明", "已归档", "code", "url"];
    const loadPersonaSharings = async () => {
        const [result, error] = await facade.list({
            entityName: "角色分享",
            fieldPaths: sharingFieldPaths,
            // sort: [{ field: "显示权重", order: "DESC" }],
            condition: [[{ field: "已归档", op: "ne", value: true }]],
            pageSize: 1000,
        });
        if (!error) {
            setPersonaSharings(result);
        }
    }

    useEffect(() => {
        loadPersonas();

        // if(facade.dlc.sandbox) {
        loadPersonaSharings()
        // }
        
    }, []);

    if(bindPersonaAdded) {
        bindPersonaAdded((newPersona) => {
            setPersonalList(prev => [newPersona, ...(prev || [])])
        })
    }

    async function updatePersona(id, formData) {
        const [ result, error ] = await facade.update({
            entityName: "OpenAI-会话角色",
            fieldPaths: personaFieldPaths,
            formData,
            id
        });
        if(!error) {
            loadPersonas()
        };
        return [result, error];
    }

    function archivePersona(id) {
        return updatePersona(id, { 已归档: true })
    }

    async function unArchivePersona(id) {
        return updatePersona(id, { 已归档: false })
    }

    function renderEditPersonaDialog() {
        return (
            <Modal isOpen={!!editingPersona} className="max-w-2xl" onOpenChange={open => {
                if (!open) {
                    // 
                    setEditingPersona();
                }
            }}>
                {closeModal => {
                    return (
                        <EditPersonaForm {...{
                            facade, currentUserId, fieldPaths: personaFieldPaths,                                                    
                            record: editingPersona,                            
                            onUpdated: r => {
                                setPersonalList(prev => prev.map(p => p.id == r.id ? r : p));
                                setEditingPersona();
                            }
                        }} />
                    )
                }}
            </Modal>
        )
    }

    const [ personaToShare, setPersonaToShare ] = useState();
    const [ sharingToView, setSharingToView ] = useState();

    function renderAddSharingDialog() {
        return (
            <Modal isOpen={personaToShare || sharingToView} className="max-w-2xl" onOpenChange={open => {
                if(!open) {
                    setPersonaToShare();
                    setSharingToView();
                }
            }}>
                {closeModal => {
                    if(sharingToView) {
                        const [ sharing, persona ] = sharingToView;
                        return (
                            <ViewPersonaSharing {...{
                                facade, sharing, persona, fieldPaths: sharingFieldPaths,
                                onUpdated: s => {
                                    setSharingToView([s, persona])
                                    setPersonaSharings(prev => {
                                        return prev.map(p => p.id == s.id ? s : p);
                                    });
                                }
                            }} />
                        )
                    } 
                    if(personaToShare) {
                        return (
                            <AddPersonaSharingForm {...{
                                facade, currentUserId, fieldPaths: sharingFieldPaths,                                                    
                                persona: personaToShare,
                                onAdded: r => {
                                    setPersonaSharings(prev => [r, ...prev]);
                                    // setEditingPersona();
                                    setSharingToView([r, personaToShare]);
                                    setPersonaToShare()
                                }
                            }} />
                        )
                    }


                }}
            </Modal>
        )
    }

    return (
        personaList && personaList.length > 0 ? (
            <>
            <div className="persona-list">
                <Grid
                    items={personaList.sort((a, b) => {
                        const aWeight = (a.置顶 ? 100000 : 0) + a.显示权重;
                        const bWeight = (b.置顶 ? 100000 : 0) + b.显示权重;
                        return bWeight - aWeight;

                    })}
                    minWidth={200}
                    // maxRows={3}
                    renderMore={() => {
                        return (
                            <div className="lc-persona-card-placeholder">
                                <div className="font-size-12">点击查看更多</div>
                            </div>
                        )
                    }}
                    renderItem={item => {
                        const sharing = (personaSharings || []).find(p => p.角色.id == item.id);

                        const cannotBeDeleted =  personaSharings == undefined || (sharing && !sharing.已暂停 && !sharing.已归档);
                        const menuItems = [...(
                            (personaSharings === undefined) ? [] :
                                sharing ? [
                                    {
                                        label: "查看分享", onSelect: _ => {
                                            track("persona_view_sharing");   
                                            setSharingToView([sharing, item]);
                                        },
                                        icon: <i className='bx bx-share-alt' ></i>
                                    }
                                ] : [
                                    // 暂时不支持新的分享了
                                    // {
                                    //     label: "分享", onSelect: _ => {
                                    //         // 分享给其他人
                                    //         track("persona_start_to_share");

                                    //         setPersonaToShare(item);
                                    //     },
                                    //     icon: <i className='bx bx-share-alt' ></i>
                                    // }
                                ]
                        ), (
                            item.置顶 ? {
                                label: "取消置顶", onSelect: _ => {
                                    track("persona_unpin");
                                    (async () => {
                                        const [r, e] = await updatePersona(item.id, { 置顶: false })
                                        if(!e) {
                                            onPin()
                                            toast({ title: "已取消置顶", undoAction: _ => updatePersona(item.id, { 置顶: true })});
                                        }
                                    })();
                                },
                                icon: <i className='bx bx-pin' ></i>
                            } : {
                                label: "置顶", onSelect: _ => {
                                    track("persona_pin");
                                    (async () => {
                                        const [r, e] = await updatePersona(item.id, { 置顶: true })
                                        if(!e) {
                                            onPin()
                                            toast({ title: "已置顶", undoAction: _ => updatePersona(item.id, { 置顶: false }) });
                                        }
                                    })();
                                },
                                icon: <i className='bx bx-pin' ></i>
                            }

                        ),  {
                            label: "编辑", onSelect: _ => {
                                track("persona_edit");
                                setEditingPersona(item);
                            },
                            icon: <i className='bx bx-edit' ></i>
                        }, {
                            label: "移除",
                            icon: <i className='bx bx-trash'></i>,
                            isDisabled: cannotBeDeleted,
                            onSelect: async _ => {
                                track("persona_archive");
                                const [result, error] = await archivePersona(item.id);
                                if (!error) {
                                    toast({ title: "角色已移入回收站", undoAction: async _ => { unArchivePersona(item.id) }, duration: 3000 });
                                }
                            }
                        }]

                        return (
                            <Pressable onPress={_ => {
                                onSelect(item);
                            }}>
                                <div className="lc-persona-card">
                                    { item.置顶 ? <i className='bx bx-pin absolute -top-1 right-0.5 text-[var(--orange9)]'></i> : null }
                                    <div className="flex gap-1.5">
                                        <div className="">
                                            <Avatar avatar={item.头像} nickName={item.名称} size={22} /> 
                                        </div>                                    
                                        <div className="title grow">
                                            {item.名称}
                                            {
                                                sharing ?                                            
                                                    (() => {
                                                        const bgColor = sharing.已暂停 ? "bg-[var(--amber8)]" : "bg-[var(--grass8)]";
                                                        return (
                                                            <span className={
                                                                "px-1 py-[1px] ml-1.5 font-size-10 text-[var(--lc-color-inversed)] rounded-sm -translate-y-px inline-block " +
                                                                bgColor
                                                            }>
                                                                { sharing.已暂停 ? "暂停分享" : "分享中" }
                                                            </span>
                                                        )
                                                    })() :
                                                    null
                                            }    
                                        </div>
                                    </div>
                                    <div className="caption font-size-12">
                                        <ClampedText text={item.提示} tipEnabled={false} lines={3} />
                                    </div>
                                    <div className="toolbar">
                                        <DropdownMenu items={menuItems} placement="bottom end">
                                            <div className="h-8 w-8 flex items-center justify-center">
                                                <i className='bx bx-dots-horizontal-rounded'></i>
                                            </div>                                            
                                        </DropdownMenu>
                                    </div>
                                </div>
                            </Pressable>
                        )
                    }}
                />                
            </div>
            {renderEditPersonaDialog()}
            {renderAddSharingDialog()}
            </>
        ) : null
    )
}
