

function getPrefix (sandbox, tenantCode) {
    // TODO 也要考虑用域名区分 tenant 的情况
    return (sandbox ? "/sandbox" : "") + (tenantCode ? "/-/" + tenantCode : "");
}

export default function getAuthURL(params, { sandbox, tenantCode }) {

    if (typeof (window) != "undefined") {

        const { wxInfo } = params || {};
        const returnTo = encodeURIComponent(window.location.href)

        //wxInfo 为true，表示需要微信头像昵称信息
        const baseLoginUrl = wxInfo ? `/ext/auth/wxLogin/info` : `/ext/auth/wxLogin`
        const loginUrl = getPrefix(sandbox, tenantCode) + baseLoginUrl;

        return `${loginUrl}?returnTo=${returnTo}`
    }
    return "";
}

export function getWxWorkAuthURL({ sandbox, tenantCode }) {

    if (typeof (window) != "undefined") {
        const returnTo = encodeURIComponent(window.location.href)
        const baseLoginUrl = `/ext/auth/wxWorkLogin`;
        const loginUrl = getPrefix(sandbox, tenantCode) + baseLoginUrl;
        return `${loginUrl}?returnTo=${returnTo}`
    }
    return "";
}