

import React from 'react'

import { make as LoginQrCode } from 'bwax-ui/re/auxiliary/LoginQrCodeAlt.bs'

export default function WechatLoginQrCode({ size = 128, onCountDown = _ => { }, onSuccess, showCountDown, loadingElement = null, tip, reloadUser }) {

    return (
        <LoginQrCode {...{
            size,
            loadingElement,
            onCountDown,
            showCountDown,
            onSuccess,
            tip,
            reloadUser
        }} />

    )
}
