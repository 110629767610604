

import React, { useEffect, useState, useRef } from 'react'

import AiInputUI from './AiInputUI'

export default function AIInputStreamMockup(props) {

    const [ isResponding, setIsResponding ] = useState(false); 
    const [ respondingText, setRespondingText ] = useState("");
    const [respondingStepMsg, setRespondingStepMsg] = useState("")

    const respondingTextRef = useRef();
    respondingTextRef.current = respondingText;

    const stopRef = useRef();

    // aiInputAction: 输入框的状态: activeBySpace, activeBySelect, replace, finishContinueWriting
    function startGenerate ({userPrompt, operation, contents, aiInputAction}) {
        setIsResponding(true)

        function onData(s, isEnd){
            const newResponseText = respondingTextRef.current + s;

            if(isEnd) {
                setIsResponding(false);
                setRespondingText('')
            } else {
                setRespondingText(newResponseText);
                respondingTextRef.current = newResponseText;
            }
            
        }

        generateContent({
            userPrompt, operation, contents, aiInputAction, onData, 
            bindStopper: stop => stopRef.current = stop
        })
    }

    return (
        <AiInputUI {...props} isResponding={isResponding} respondingText={respondingText}
            startGenerate={startGenerate} respondingStepMsg={respondingStepMsg}
            stopGeneration={_ => {
                if(stopRef.current){
                    stopRef.current()
                }
            }}
        />
    )
}

const testResponding = `这是一篇关于人工智能的演讲。
在接下来的演讲中，我将从不同的角度探讨人工智能技术的现状、发展趋势以及对我们的影响。
1. 人工智能的现状
2. 人工智能发展趋势
3. 对我们的影响`

// const content = `${testResponding}`
// const contentWithPrompt = `${testResponding}`
// const contentWithSelected = `**根据选中文本生成内容**${testResponding}`
// const contentWithPromptAndSelected = `**根据 prompt 和选中文本生成内容**${testResponding}`
// const tryAgainContent = `**重新生成的内容**${testResponding}`
// const tryAgainContentWithPrompt = `**根据 prompt 重新生成的内容**${testResponding}`
// const longerContent = `**更长的内容**${testRespondingLonger}`
// const continueWritingContent = `**以下是续写内容**${testResponding}`

function generateContent({userPrompt, operation, contents, aiInputAction, onData, bindStopper}) {
    let generated = ''
    // console.log("operation: ", operation);
    /**
     * 在替换时，有 previousRespondingText 时输入 prompt，则根据 userPrompt 重新生成。
     */

    // console.log("operation: ", operation);

    const actualPrompt = userPrompt || operation.prompt;

    generated = `**${actualPrompt}**${testResponding}`

    // 模拟逐字生成
    let letterIndex = 0
    const step = 1

    function generateLiterally() {
        const generatedSlice = generated.slice(letterIndex, letterIndex + step)
        onData(generatedSlice, false)

        const id = setTimeout(generateLiterally, 100);

        bindStopper(() => {
            onData(generatedSlice, true)
            clearTimeout(id)
        })
        
        if(letterIndex >= generated.length){
            onData(generatedSlice, true)
            clearTimeout(id);
        }

        letterIndex += step
    }

    generateLiterally()
    
}