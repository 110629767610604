
import React from 'react'

import BookmarkList from './BookmarkList'

export default function BookmarkManagement({ facade, viewEnv, data }) {

    return (
        <div className="pt-16 sm:pt-4 self-center w-full flex flex-col h-full">
            <BookmarkList {...{
                facade,
                viewEnv,
                data
            }} />
        </div>
    )
}
