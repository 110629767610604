// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Select$BwaxMobile = require("../../../../re/Select.bs.js");

function PaginationSelect(Props) {
  var options = Props.options;
  var selected = Props.selected;
  var onChange = Props.onChange;
  var control = Props.control;
  var theme = Props.theme;
  var rules_003 = /* optionListRules : :: */[
    /* `maxHeight */[
      836180395,
      /* `rem */[
        5691738,
        20.0
      ]
    ],
    /* :: */[
      /* scrollY */819475372,
      /* :: */[
        /* `maxWidth */[
          -192935742,
          /* `rem */[
            5691738,
            8.0
          ]
        ],
        /* :: */[
          /* `borderRoundEach */[
            -979470621,
            /* record */[
              /* tl : zero */-789508312,
              /* tr : zero */-789508312,
              /* br : `rem */[
                5691738,
                0.25
              ],
              /* bl : `rem */[
                5691738,
                0.25
              ]
            ]
          ],
          /* :: */[
            /* `boxShadow */[
              -586247093,
              /* record */[
                /* offset : tuple */[
                  0.0,
                  5.0
                ],
                /* size */0.0,
                /* blur */10.0,
                /* color : `rgba */[
                  -878128972,
                  /* tuple */[
                    0,
                    0,
                    0,
                    0.1
                  ]
                ]
              ]
            ],
            /* [] */0
          ]
        ]
      ]
    ]
  ];
  var rules_004 = function (label, value, isSelected, isDisabled) {
    return /* [] */0;
  };
  var rules_005 = function (label, value) {
    return /* [] */0;
  };
  var rules_007 = function (label, value, isSelected) {
    return /* [] */0;
  };
  var rules = /* record */[
    /* containerRules : [] */0,
    /* controlRules : [] */0,
    /* menuRules : [] */0,
    rules_003,
    rules_004,
    rules_005,
    /* controlFocusedRules : [] */0,
    rules_007
  ];
  return React.createElement(Select$BwaxMobile.make, {
              rules: rules,
              options: options,
              selected: selected,
              onChange: onChange,
              disabled: false,
              placeholder: "请选择",
              control: control,
              theme: theme,
              pc: /* row */5693978,
              prs: /* [] */0
            });
}

var make = PaginationSelect;

exports.make = make;
/* react Not a pure module */
