
import React, { useEffect, useMemo } from 'react'

import { MdUndo } from 'react-icons/md'

export default function UndoButton(props) {

    const { editor } = props

    const { history } = editor

    // 过滤掉 history 中的 set_selection 操作，只改变光标不触发 undo/redo
    const disabled = history.undos.filter(ua => {
        if(ua.operations) {
            return ua.operations.some(u => u.type !== 'set_selection')
        }
    }).length === 0

    function undo () {
        if(disabled) {
            return 
        }
        
        editor.undo()
    }

    return (
        <button className="editor-button" style={disabled ? { cursor: "auto" }: {}} disabled={disabled} onClick={() => undo()}>
            <MdUndo style={{ fontSize: 14 }}/>
        </button>
    )
}
