import React from 'react'

class DiscussionIcon extends React.Component {

    render() {
        return (

            <svg width="1em" height="1em" viewBox="0 0 16 16" >
                <defs>
                    <linearGradient x1="100%" y1="100%" x2="0%" y2="0%" id="linearGradient-1">
                        <stop stopColor="#3C3D3D" offset="0%"></stop>
                        <stop stopColor="#141414" offset="100%"></stop>
                    </linearGradient>
                    <linearGradient x1="100%" y1="100%" x2="0%" y2="0%" id="linearGradient-2">
                        <stop stopColor="#FFCF99" offset="0%"></stop>
                        <stop stopColor="#FFE2C2" offset="100%"></stop>
                    </linearGradient>
                </defs>
                <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="Icon/VIP-rights/拆解" fillRule="nonzero">
                        <g>
                            <circle id="Oval" fill="url(#linearGradient-1)" cx="8" cy="8" r="8"></circle>
                            <path d="M11.4613232,4.83472913 L11.4613232,8.96634613 L7.81818182,11.0321546 L4.17504044,8.96634613 L4.17504044,4.83472913 L7.81818182,2.76892063 L11.4613232,4.83472913 Z M5.37954147,5.51772993 L7.81818182,4.13492222 L10.2568222,5.51772993 L10.2568222,8.28334534 L7.81818182,9.66615304 L5.37954147,8.28334534 L5.37954147,5.51772993 Z M10.6079961,5.3186001 L7.81818182,3.73666257 L5.02836753,5.3186001 L5.02836753,8.48247517 L7.81818182,10.0644127 L10.6079961,8.48247517 L10.6079961,5.3186001 Z M8.69819817,10.2953324 L10.1915206,9.43316227 L11.885069,12.3664741 L11.4846119,13.2201559 L10.3917466,13.2286442 L8.69819817,10.2953324 Z M9.35408639,11.0632032 L9.51783277,11.3360508 L10.6807971,10.6707203 L10.5170507,10.3978728 L9.35408639,11.0632032 Z" id="Combined-Shape" fill="url(#linearGradient-2)"></path>
                        </g>
                    </g>
                </g>
            </svg>
        )
    }
}

export default DiscussionIcon
