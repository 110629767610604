
// This file is generated. Don't modify it.
import React from 'react'
export default function SalesDataIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M3,13 L14,13 L14,14 L3,14 L2,14 L2,2 L3,2 L3,13 Z M7.28713752,7.61557538 L4.94551074,9.95720216 L4.23840396,9.25009538 L6.57576575,6.91273359 L7.282448,6.20520737 L9.0475842,7.94917804 L11.2274598,5.76930247 L10.0457856,5.76930247 L10.0457856,4.76930247 L13.0457856,4.76930247 L13.0457856,5.76930247 L13.0457856,7.76930247 L12.0457856,7.76930247 L12.0457856,6.3651902 L9.62241624,8.78855956 L9.59653106,8.76267439 L9.02580788,9.33339757 L7.28713752,7.61557538 Z" id="SalesDataIcon-path-1"></path>
            </defs>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Sales-data">
                    <g>
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="SalesDataIcon-mask-2" fill="white">
                            <use href="#SalesDataIcon-path-1"></use>
                        </mask>
                        <use id="Combined-Shape" fill={color} fillRule="nonzero" href="#SalesDataIcon-path-1"></use>
                        <g id="Color/UI/Text" mask="url(#SalesDataIcon-mask-2)" fill={color} fillRule="nonzero">
                            <rect id="light-gray" x="0" y="0" width="16" height="16"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
