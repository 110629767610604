// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Dict$BwaxMobile = require("../../bwax-js/ml/dict.bs.js");
var Plate$BwaxMobile = require("../../bwax-js/ml/plate.bs.js");
var Lang_eval$BwaxMobile = require("../../bwax-js/ml/lang/lang_eval.bs.js");
var Query_env$BwaxMobile = require("../../bwax-js/ml/query/query_env.bs.js");
var Widget_env$BwaxMobile = require("./widget/widget_env.bs.js");
var Performance$BwaxMobile = require("../../bwax-js/ml/performance.bs.js");
var Lang_runtime$BwaxMobile = require("../../bwax-js/ml/lang/lang_runtime.bs.js");
var Widget_mod_ui$BwaxMobile = require("./mod/widget/widget_mod_ui.bs.js");
var Command_engine$BwaxMobile = require("../../bwax-js/ml/lang/command_engine.bs.js");
var Query_env_cmdm$BwaxMobile = require("../../bwax-js/ml/query/query_env_cmdm.bs.js");
var Runtime_common$BwaxMobile = require("../../bwax-js/ml/lang/mod/runtime_common.bs.js");
var Lang_entry_slim$BwaxMobile = require("../../bwax-js/ml/lang/lang_entry_slim.bs.js");
var Widget_mod_nivo$BwaxMobile = require("./mod/widget/widget_mod_nivo.bs.js");
var Widget_mod_wxpay$BwaxMobile = require("./mod/widget/widget_mod_wxpay.bs.js");
var Widget_mod_alipay$BwaxMobile = require("./mod/widget/widget_mod_alipay.bs.js");
var Widget_mod_wechat$BwaxMobile = require("./mod/widget/widget_mod_wechat.bs.js");
var Widget_mod_data_ui$BwaxMobile = require("./mod/widget/widget_mod_data_ui.bs.js");
var Widget_mod_misc_ui$BwaxMobile = require("./mod/widget/widget_mod_misc_ui.bs.js");
var Widget_mod_spinner$BwaxMobile = require("./mod/widget/widget_mod_spinner.bs.js");
var Widget_mod_adhoc_ui$BwaxMobile = require("./mod/widget/widget_mod_adhoc_ui.bs.js");
var Widget_mod_styled_ui$BwaxMobile = require("./mod/widget/widget_mod_styled_ui.bs.js");
var Widget_mod_admin_page$BwaxMobile = require("./mod/widget/widget_mod_admin_page.bs.js");
var Widget_mod_admin_chart$BwaxMobile = require("./mod/widget/widget_mod_admin_chart.bs.js");
var Widget_mod_custom_admin_page$BwaxMobile = require("./mod/widget/widget_mod_custom_admin_page.bs.js");
var Widget_mod_custom_page_component$BwaxMobile = require("./mod/widget/widget_mod_custom_page_component.bs.js");

var match = Widget_env$BwaxMobile.build_widget_base(/* () */0);

var page_runtime_modules = Pervasives.$at(Lang_runtime$BwaxMobile.common_runtime_modules, Pervasives.$at(/* :: */[
          Query_env$BwaxMobile.preset_module,
          /* [] */0
        ], Plate$BwaxMobile.List.assoc_vals(match[0])));

var page_runtime_externals = Pervasives.$at(Lang_runtime$BwaxMobile.common_runtime_externals, match[1]);

function get_genv(modules) {
  return Lang_entry_slim$BwaxMobile.build_env(Dict$BwaxMobile.$$String.empty(/* () */0), modules, page_runtime_externals);
}

function prepare_eval_env_with_base(base_env, entity_dict, data_type_dict, maybePageComonents, maybeAdminPages, base_mods) {
  var query_externals = Query_env$BwaxMobile.get_query_externals(entity_dict);
  var cmd_externals = Query_env_cmdm$BwaxMobile.get_cmd_externals(entity_dict);
  var custom_externals = Plate$BwaxMobile.List.clean(/* :: */[
        Plate$BwaxMobile.$$Option.map(Widget_mod_custom_page_component$BwaxMobile.build_externals, maybePageComonents),
        /* :: */[
          Plate$BwaxMobile.$$Option.map(Widget_mod_custom_admin_page$BwaxMobile.build_externals, maybeAdminPages),
          /* [] */0
        ]
      ]);
  return Lang_entry_slim$BwaxMobile.build_env(base_env, Plate$BwaxMobile.$$Option.with_default(/* [] */0, base_mods), Pervasives.$at(query_externals, Pervasives.$at(cmd_externals, custom_externals)));
}

function prepare_eval_env(gmods, entity_dict, data_type_dict, maybePageComponents, maybeAdminPages, base_mods) {
  var genv = get_genv(gmods);
  return prepare_eval_env_with_base(genv, entity_dict, data_type_dict, maybePageComponents, maybeAdminPages, base_mods);
}

function get_command_handlers(param) {
  return Plate$BwaxMobile.List.flat(/* :: */[
              Command_engine$BwaxMobile.get_builtin_command_handlers(/* () */0),
              /* :: */[
                Widget_mod_ui$BwaxMobile.command_handlers,
                /* :: */[
                  Widget_mod_wechat$BwaxMobile.command_handlers,
                  /* :: */[
                    Widget_mod_wxpay$BwaxMobile.command_handlers,
                    /* :: */[
                      Widget_mod_alipay$BwaxMobile.command_handlers,
                      /* :: */[
                        Widget_mod_nivo$BwaxMobile.command_handlers,
                        /* :: */[
                          Widget_mod_spinner$BwaxMobile.command_handlers,
                          /* :: */[
                            Widget_mod_data_ui$BwaxMobile.command_handlers,
                            /* :: */[
                              Widget_mod_misc_ui$BwaxMobile.command_handlers,
                              /* :: */[
                                Widget_mod_styled_ui$BwaxMobile.command_handlers,
                                /* :: */[
                                  Widget_mod_adhoc_ui$BwaxMobile.command_handlers,
                                  /* :: */[
                                    Widget_mod_admin_page$BwaxMobile.command_handlers,
                                    /* :: */[
                                      Widget_mod_admin_chart$BwaxMobile.command_handlers,
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

function execute_to_js(query_runner, queryCache, domainEnv, webEnv, dlc, entity_dict, data_type_dict, base_env, dts, ast, cb) {
  var t0 = Performance$BwaxMobile.now(/* () */0);
  var route_to = function (s, _replace) {
    console.log("Goto", s);
    return /* () */0;
  };
  var params = /* tuple */[
    /* V_unit */0,
    Runtime_common$BwaxMobile.nt
  ];
  var command_handlers = get_command_handlers(/* () */0);
  var match = Lang_eval$BwaxMobile.evaluate_defs(base_env, ast);
  var domain_env_000 = /* mobileHost */domainEnv.mobileHost;
  var domain_env_001 = /* isSandbox */domainEnv.isSandbox;
  var domain_env_002 = /* protocol */domainEnv.protocol;
  var domain_env_003 = /* tenantCode */domainEnv.tenantCode;
  var domain_env_004 = /* urlPrefix */domainEnv.urlPrefix;
  var domain_env = /* record */[
    domain_env_000,
    domain_env_001,
    domain_env_002,
    domain_env_003,
    domain_env_004
  ];
  var query_cache = Runtime_common$BwaxMobile.to_query_cache(queryCache);
  var web_env_000 = /* protocol */webEnv.protocol;
  var web_env_001 = /* host */webEnv.protocol;
  var web_env_002 = /* currentURL */webEnv.currentURL;
  var web_env_003 = /* currentURLPath */webEnv.currentURLPath;
  var web_env_004 = /* originalURL */webEnv.originalURL;
  var web_env_005 = /* isSandbox */webEnv.isSandbox;
  var web_env_006 = /* isIOS */webEnv.isIOS;
  var web_env_007 = /* isWeChat */webEnv.isWeChat;
  var web_env_008 = /* isMobile */webEnv.isMobile;
  var web_env = /* record */[
    web_env_000,
    web_env_001,
    web_env_002,
    web_env_003,
    web_env_004,
    web_env_005,
    web_env_006,
    web_env_007,
    web_env_008
  ];
  var context_003 = /* env */match[0];
  var context = /* record */[
    /* entity_dict */entity_dict,
    /* data_type_dict */data_type_dict,
    /* dts */dts,
    context_003,
    /* query_runner */query_runner,
    /* query_cache */query_cache,
    /* timers */undefined,
    /* domain_env */domain_env,
    /* route_to */route_to,
    /* web_env */web_env
  ];
  return Lang_runtime$BwaxMobile.run_init_to_model(command_handlers, context, /* :: */[
              params,
              /* [] */0
            ], (function (result) {
                if (result.tag) {
                  return Curry._1(cb, /* tuple */[
                              undefined,
                              result[0]
                            ]);
                } else {
                  var t1 = Performance$BwaxMobile.now(/* () */0);
                  console.log("Used::", t1 - t0);
                  var view = Lang_runtime$BwaxMobile.run_view(context, result[0][0]);
                  return Curry._1(cb, /* tuple */[
                              view,
                              undefined
                            ]);
                }
              }), (function (_msgs) {
                console.log("abandon branch msgs");
                return /* () */0;
              }));
}

function execute_init_to_model_with_multiparams(query_runner, queryCache, domainEnv, webEnv, entity_dict, data_type_dict, dts, env, params, cb) {
  var query_cache = Runtime_common$BwaxMobile.to_query_cache(queryCache);
  var route_to = function (s, _replace) {
    console.log("Goto", s);
    return /* () */0;
  };
  var web_env_000 = /* protocol */webEnv.protocol;
  var web_env_001 = /* host */webEnv.host;
  var web_env_002 = /* currentURL */webEnv.currentURL;
  var web_env_003 = /* currentURLPath */webEnv.currentURLPath;
  var web_env_004 = /* originalURL */webEnv.originalURL;
  var web_env_005 = /* isSandbox */webEnv.isSandbox;
  var web_env_006 = /* isIOS */webEnv.isIOS;
  var web_env_007 = /* isWeChat */webEnv.isWeChat;
  var web_env_008 = /* isMobile */webEnv.isMobile;
  var web_env = /* record */[
    web_env_000,
    web_env_001,
    web_env_002,
    web_env_003,
    web_env_004,
    web_env_005,
    web_env_006,
    web_env_007,
    web_env_008
  ];
  var domain_env_000 = /* mobileHost */domainEnv.mobileHost;
  var domain_env_001 = /* isSandbox */domainEnv.isSandbox;
  var domain_env_002 = /* protocol */domainEnv.protocol;
  var domain_env_003 = /* tenantCode */domainEnv.tenantCode;
  var domain_env_004 = /* urlPrefix */domainEnv.urlPrefix;
  var domain_env = /* record */[
    domain_env_000,
    domain_env_001,
    domain_env_002,
    domain_env_003,
    domain_env_004
  ];
  var backend_command_handlers = Command_engine$BwaxMobile.get_builtin_command_handlers(/* () */0);
  var context = /* record */[
    /* entity_dict */entity_dict,
    /* data_type_dict */data_type_dict,
    /* dts */dts,
    /* env */env,
    /* query_runner */query_runner,
    /* query_cache */query_cache,
    /* timers */undefined,
    /* domain_env */domain_env,
    /* route_to */route_to,
    /* web_env */web_env
  ];
  return Lang_runtime$BwaxMobile.run_init_to_model(backend_command_handlers, context, params, (function (result) {
                if (result.tag) {
                  return Curry._4(cb, undefined, /* array */[], /* array */[], result[0]);
                } else {
                  var match = result[0];
                  return Curry._4(cb, match[0], Curry._1(Plate$BwaxMobile.List.to_array, match[1]), Curry._1(Plate$BwaxMobile.List.to_array, match[2]), undefined);
                }
              }), (function (_msgs) {
                console.log("abandon branch msgs");
                return /* () */0;
              }));
}

function evaluate_view(query_runner, queryCache, domainEnv, webEnv, entity_dict, data_type_dict, dts, env, model) {
  var route_to = function (s, _replace) {
    console.log("Goto", s);
    return /* () */0;
  };
  var query_cache = Runtime_common$BwaxMobile.to_query_cache(queryCache);
  var web_env_000 = /* protocol */webEnv.protocol;
  var web_env_001 = /* host */webEnv.host;
  var web_env_002 = /* currentURL */webEnv.currentURL;
  var web_env_003 = /* currentURLPath */webEnv.currentURLPath;
  var web_env_004 = /* originalURL */webEnv.originalURL;
  var web_env_005 = /* isSandbox */webEnv.isSandbox;
  var web_env_006 = /* isIOS */webEnv.isIOS;
  var web_env_007 = /* isWeChat */webEnv.isWeChat;
  var web_env_008 = /* isMobile */webEnv.isMobile;
  var web_env = /* record */[
    web_env_000,
    web_env_001,
    web_env_002,
    web_env_003,
    web_env_004,
    web_env_005,
    web_env_006,
    web_env_007,
    web_env_008
  ];
  var domain_env_000 = /* mobileHost */domainEnv.mobileHost;
  var domain_env_001 = /* isSandbox */domainEnv.isSandbox;
  var domain_env_002 = /* protocol */domainEnv.protocol;
  var domain_env_003 = /* tenantCode */domainEnv.tenantCode;
  var domain_env_004 = /* urlPrefix */domainEnv.urlPrefix;
  var domain_env = /* record */[
    domain_env_000,
    domain_env_001,
    domain_env_002,
    domain_env_003,
    domain_env_004
  ];
  var context = /* record */[
    /* entity_dict */entity_dict,
    /* data_type_dict */data_type_dict,
    /* dts */dts,
    /* env */env,
    /* query_runner */query_runner,
    /* query_cache */query_cache,
    /* timers */undefined,
    /* domain_env */domain_env,
    /* route_to */route_to,
    /* web_env */web_env
  ];
  return Lang_runtime$BwaxMobile.run_view(context, model);
}

function execute_to_view(query_runner, queryCache, domainEnv, webEnv, entity_dict, data_type_dict, dts, env, params, cb) {
  return execute_init_to_model_with_multiparams(query_runner, queryCache, domainEnv, webEnv, entity_dict, data_type_dict, dts, env, params, (function (maybe_model, _cmds, _msg, maybe_error) {
                if (maybe_model !== undefined) {
                  var v = evaluate_view(query_runner, queryCache, domainEnv, webEnv, entity_dict, data_type_dict, dts, env, maybe_model);
                  return Curry._2(cb, v, maybe_error);
                } else {
                  return Curry._2(cb, undefined, maybe_error);
                }
              }));
}

var evaluate_defs = Lang_eval$BwaxMobile.evaluate_defs;

var string_of_value = Lang_eval$BwaxMobile.string_of_value;

exports.page_runtime_modules = page_runtime_modules;
exports.page_runtime_externals = page_runtime_externals;
exports.get_genv = get_genv;
exports.prepare_eval_env_with_base = prepare_eval_env_with_base;
exports.prepare_eval_env = prepare_eval_env;
exports.get_command_handlers = get_command_handlers;
exports.evaluate_defs = evaluate_defs;
exports.execute_to_js = execute_to_js;
exports.execute_init_to_model_with_multiparams = execute_init_to_model_with_multiparams;
exports.evaluate_view = evaluate_view;
exports.execute_to_view = execute_to_view;
exports.string_of_value = string_of_value;
/* match Not a pure module */
