
// This file is generated. Don't modify it.
import React from 'react'
export default function CollectionIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M11.2254162,13.7626334 C11.095293,13.7626334 10.9451464,13.7185865 10.8150115,13.6545161 L8.00426664,12.2491437 L5.16949831,13.6545161 C4.88922851,13.8066686 4.52086488,13.784651 4.26061854,13.5904575 C4.00037221,13.3962639 3.85021385,13.0499296 3.9142725,12.7256129 L4.49884728,9.69865689 L2.27267719,7.62263343 C2.03443673,7.38439296 1.94836048,7.01604106 2.03443673,6.71374194 C2.1425423,6.38942522 2.40280036,6.15119648 2.74714054,6.10915543 L5.84016107,5.54659824 L7.22549831,2.73385924 C7.37766253,2.43156012 7.67994992,2.23736657 8.00426664,2.23736657 C8.32858335,2.23736657 8.65290007,2.43156012 8.78303497,2.73385924 L10.1663781,5.54460411 L13.2593986,6.08513196 C13.5837153,6.12916716 13.8639968,6.36540176 13.9520672,6.68971848 C14.0601845,7.01403519 13.9740965,7.3603695 13.7358678,7.59860997 L11.505686,9.69865689 L12.0682432,12.7256129 C12.1322901,13.0719472 11.9821435,13.3962639 11.7218971,13.5904575 C11.5937798,13.698563 11.4196097,13.7626217 11.2254162,13.7626217 L11.2254162,13.7626334 Z" id="CollectionIcon-path-1"></path>
            </defs>
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Filling/Collection">
                    <g id="Group">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="CollectionIcon-mask-2" fill="white">
                            <use href="#CollectionIcon-path-1"></use>
                        </mask>
                        <use id="Path" fill={color} href="#CollectionIcon-path-1"></use>
                    </g>
                </g>
            </g>
        </svg>
    )
}
