
import { parseJSON } from 'bwax/utils'

export default function UploadFile ({ queryRunner }) {

    return async function (params) {

        const { file, isPublic = false, uploadFor, onUploadProgress, cancelRequest } = params

        if(!file) {
            //// TODO other error handling?
            return undefined
        }

        const result = await queryRunner(QueryText, {
            input: {
                public: isPublic, 
                uploadFor,
                clientMutationId: `${Date.now()}`
            }
        }, file, { onUploadProgress, cancelRequest })

        /// TODO ERROR HANDLING
        const { data, errors } = parseJSON(result) || {}

        if(errors) {
            /// TODO error handling
            return undefined
        }
        if(data && data.uploadAttachment && data.uploadAttachment.addedAttachment) {
            const attachment = data.uploadAttachment.addedAttachment.node
            return attachment
        }
        /// TODO error handling
        return undefined


    }
}

const QueryText = `
    mutation UploadAttachmentMutation(
        $input: UploadAttachmentInput!
    ) {
        uploadAttachment(input: $input) {
            addedAttachment {
                node {
                    id
                    url
                    title
                    path
                    size
                    contentType
                }
            }
        }
    }
`