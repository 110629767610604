

import React from 'react'

import PageComponentContainer from 'bwax-ui/ml/widget/PageComponentContainer'

import RenderWithData from 'bwax-ui/store/RenderWithData'
import { USER_DURABLE_RUNTIME } from 'bwax/store/DataCache';

import setupPageEnv from './setupPageEnv';


export default function PageComponentEnvContainer (props) {

    // page, facade, route_to, dlc, 
    const { pageComponent, facade, componentName, isLazy } = props

    const { compiled } = pageComponent;

    const loadData = () => resolveRuntime(componentName, compiled, facade);

    return (
        <RenderWithData {...{
            ...props, 
            loadData,
            cacheType: USER_DURABLE_RUNTIME, // 因为 setupPageEnv 涉及到 facade 的 prepare（所以 cacheType 不能是 global runtime）
            dataKey: "PageComponentEnv_" + componentName,
            Component: PageComponentEnvContainer_inner,

            isLazy,
            noSuspense: true,
        }} />
    )
}

async function resolveRuntime (componentName, compiled, facade) {

    try {

        const [ ast, dts, entity_dict, data_type_dict, env ] = await setupPageEnv(compiled, facade)

        const runtime = {
            ast,
            dts,
            entity_dict,
            data_type_dict,
            env,
            componentName,

            error: undefined
        };
        return runtime

    } catch (e) {

        // TODO error 的处理放进 RenderWithData
        console.log("ERROR", e);
        
        return {
            error: e,
            componentName
        };

    }

}

export function PageComponentEnvContainer_inner (props) {    

    const { 
        data: runtime, 
        
        initParams, maybeViewParams,

        componentKey,
        dlc, 

        parentId,

        facade, viewEnv,

    } = props
    
    const { env, ast, dts, entity_dict, data_type_dict, componentName, error } = runtime;

    if(error) {
        return "ERROR";
    }

    const rendered =  (
        <PageComponentContainer 
            key={componentName}
            {...{
                componentKey,
                componentName,
                dlc,
                entity_dict,
                data_type_dict,
                base_env: env,
                dts, 
                ast,
                
                viewEnv,

                facade,

                initParams, maybeViewParams,

                parentId,
            }}


        />
    )
    return rendered;
}

