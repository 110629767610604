
// require("isomorphic-fetch")

import axios from 'axios'
import { hashCode } from 'bwax/utils'

import Cookies from 'js-cookie'
import getTokenCookieName from './getTokenCookieName';

function doFetch({ endpoint, sessionToken, headers = {}, tenantCode, sandbox }, query, variables, file, requestConfig = {}) {

    function getCookieValue(name) {
        // headers is used for SSR case
        return Cookies.get(name) || getCookie(name, headers.cookie)
    }

    const sessionTokenName = 'X-SessionToken' + (tenantCode ? "-" + tenantCode : "");

    const sessionTokenCookieName = getTokenCookieName({ tenantCode, sandbox })

    function getPlatformSessionToken() {
        if (tenantCode) {
            // 
            const platformTokenName = 'X-SessionToken';
            const platformTokenCookieName = getTokenCookieName({ sandbox })

            const token = getCookieValue(platformTokenCookieName);

            if(token) {
                return { [platformTokenName]: token }
            } else {
                return {}
            }
        }
        return {} // already inside platform
    }

    const st = sessionToken || getCookieValue(sessionTokenCookieName);

    const deviceId = getCookieValue('X-DeviceId')

    // remove the given accept, content-type if there's any
    let passedInHeaders = { ...(headers || {}) };
    delete passedInHeaders["accept"];
    delete passedInHeaders["content-type"];


    const authHeaders = {
        ...(st ? { [sessionTokenName]: st } : { [sessionTokenName]: "_" }), // prevent the server from getting sessionToken from cookie
        ...getPlatformSessionToken(),
        ...(deviceId ? { "X-DeviceId": deviceId } : {}),
    }

    // console.log(">>> get platform ", authHeaders);

    let request = {
        method: "POST",
        headers: {
            ...passedInHeaders,
            "Accept": "application/json",
            ...authHeaders
        }
    }

    if (file) {
        const formData = new FormData()
        formData.append('query', query)
        formData.append('variables', JSON.stringify(variables))
        formData.append('file', file)

        request.headers = {
            ...request.headers,
            'Content-Type': file.type
        }

        request.data = formData
    } else {

        request.headers = {
            ...request.headers,
            'Content-Type': 'application/json'
        }
        request.data = JSON.stringify({
            query,
            variables
        })
    }




    //requestConfig e.g. onUploadProgress, onDownloadProgress
    const { cancelRequest, ...remainRequestConfig } = requestConfig
    const CancelToken = axios.CancelToken

    function getEndpoint() {
        if(typeof(document) !== "undefined" && file) {
            const url = document.userenv && document.userenv.dataEndpoint ? document.userenv.dataEndpoint : endpoint;
            if(url && url.startsWith("http://")) {
                return url.replace("http://", "https://");
            }
            return url;
        }
        return endpoint
    }


    return axios({
        url: getEndpoint(),
        ...request,
        ...(remainRequestConfig || {}),
        transformResponse: [function (data) {
            // Do whatever you want to transform the data
            // TODO: handle parse json to js
            return data
        }],

        cancelToken: new CancelToken(function executor(c) {
            if (cancelRequest) {
                cancelRequest(c)
            }
        })
    })
}


// 目前只用在 client 端
class RequestManager {
    constructor() {
        this.requests = {};  // key -> promise
    }
    async fetch(options, query, variables = {}) {
        // 如果有一个 key 一样的正在查，则等他
        const key = hashCode(JSON.stringify({ options, query, variables })); /// 是不是不应该 hash code？

        const existingRequest = this.requests[key];
        const now = Date.now();

        if (existingRequest && existingRequest[0] instanceof Promise && now - existingRequest[1] < 3000) {
            return (await existingRequest[0]);

        } else {
            const request = doFetch(options, query, variables)

            this.requests[key] = [request, now];
            const resultText = await request;
            if (this.requests[key] && request === this.requests[key][0]) {
                delete this.requests[key];
            }

            return resultText;
        }
    }
}
const requestManager = new RequestManager();

export function fetchQ(env, query, variables, file, requestConfig) {

    if (!file && !requestConfig
        // && typeof(document) !== 'undefined' // 没有必要只在 client 端用吧？
    ) {
        // 增加一个控制，避免同时发送多个完全同样的请求（这在 React 18 的 concurrent model 下很常见）
        return requestManager.fetch(env, query, variables)
    }

    return doFetch(env, query, variables, file, requestConfig)

}

export const runDefinitionQuery = ({
    endpoints,
    sessionToken,
    tenantCode,
    headers } = {}
) => queryText => (variables, file, requestConfig) => {

    const endpoint = endpoints && endpoints.definition ? endpoints.definition : "/gql/b";



    return fetchQ(
        { endpoint, sessionToken, headers, tenantCode }, queryText, variables, file, requestConfig
    ).then(res => res.data)

}

/// it supports sandbox
export const runDataQuery = ({
    endpoints,
    sessionToken,
    tenantCode,
    sandbox,
    headers } = {}) => queryText => (variables, file, requestConfig) => {

        function getShortEndpoint() {
            const suffix = sandbox ? "/s" : "/c";
            return "/gql" + (tenantCode ? "/-/" + tenantCode : "") + suffix;
        }

        const endpoint = endpoints && endpoints.data ? endpoints.data : getShortEndpoint();

        return fetchQ(
            { endpoint, sessionToken, headers, tenantCode, sandbox }, queryText, variables, file, requestConfig
        ).then(res => res.data)
    }



// >>>> some helper for the ML side >>>>>>
// getData: string => 'a => option(Js.Json.t)
export const getData = (key, appData) => {
    if (!appData) {
        return undefined;
    }
    let d = appData[key];
    let cacheInvalidatedAt = appData.__cacheInvalidatedAt__ || 0;
    if (d && typeof (d) == 'object' && d.data && d.loadedAt > cacheInvalidatedAt) {
        return d.data
    } else {
        return undefined
    }
}
export const setData = (key, data, appData) => {
    if (!appData) {
        return
    }
    let d = { data, loadedAt: Date.now() };
    appData[key] = d;

}

export const setPromise = (key, promise, appData) => {
    if (!appData) {
        return
    }
    appData[key] = promise;
}

export const clearCache = appData => {
    if (!appData) {
        return
    }
    appData.__cacheInvalidatedAt__ = Date.now()
}

// >>>> helper ends >>>>>>>>>>>>>>>>>>>>>>

// used to setup custom runner for bwax
export const setupDataQueryRunner = env => (queryText, variables, file) => {
    return runDataQuery(env)(queryText)(variables, file)
}


export const setupDefinitionQueryRunner = env => (queryText, variables, file) => {
    return runDefinitionQuery(env)(queryText)(variables, file)
}




//// below are for actions:
export const runDefinitionQuery_a = env => (queryText, variables, file, requestConfig) => {
    return runDefinitionQuery(env)(queryText)(variables, file, requestConfig)
}


export const runDataQuery_a = env => (queryText, variables, file, requestConfig) => {
    return runDataQuery(env)(queryText)(variables, file, requestConfig)
}


/// 




///// below are for ReasonML use

export const runDefinitionQuery_re = (env, queryText, variables, file) => {
    let runQuery = env && env.customQueryRunner ? env.customQueryRunner : runDefinitionQuery;
    return runQuery(env)(queryText)(variables, file)
}

export const runDataQuery_re = (env, queryText, variables, file) => {
    let runQuery = env && env.customQueryRunner ? env.customQueryRunner : runDataQuery;

    return runQuery(env)(queryText)(variables, file)
}



// get cookie from headers:
// https://github.com/js-cookie/js-cookie/blob/main/src/api.mjs#L50
function getCookie (name, cookieStr) {

    if(!cookieStr) {
        return undefined
    }

    const cookies = cookieStr.split('; ');

    for(let i = 0; i < cookies.length; i++) {
        const parts = cookies[i].split('=');
        const value = parts.slice(1).join('=');

        if(name == decodeURIComponent(parts[0])) {
            return value
        }
    }

}