
// This file is generated. Don't modify it.
import React from 'react'
export default function TargetAmountIcon (props) {

    const { color } = props
    
    return (
                <svg width="0.875em" height="0.875em" viewBox="0 0 14 14">
            <defs>
                <path d="M12.9898334,6.79960942 C12.9680311,6.72805616 12.9124959,6.67356687 12.8431778,6.65571577 L9.82347457,5.86719963 L8.17572667,3.09527025 C8.1001465,2.96824325 7.90043465,2.96824325 7.82485448,3.09527025 L6.17710659,5.86772671 L3.15690283,6.65518868 C3.08764785,6.67300411 3.03208904,6.72730782 3.01006538,6.79870903 C2.98804171,6.87011023 3.00269322,6.94842886 3.04878815,7.00569886 L5.05091195,9.50723467 L4.83167939,12.7672429 C4.82673739,12.8422344 4.85888706,12.9146043 4.91671984,12.9586715 C4.97455262,13.0027387 5.0498836,13.0122666 5.11598097,12.983874 L8.00004032,11.757879 L10.8851007,12.983874 C10.9511672,13.0116669 11.0261451,13.0019218 11.0838115,12.9580469 C11.1416291,12.9142457 11.1738176,12.8420673 11.1689018,12.7672429 L10.9496692,9.50776176 L12.951793,7.00622595 C12.9975241,6.94869483 13.0118988,6.87041974 12.9898334,6.79908233 L12.9898334,6.79960942 Z" id="TargetAmountIcon-path-1"></path>
                <filter x="-29.5%" y="-29.0%" width="157.9%" height="158.9%" filterUnits="objectBoundingBox" id="filter-2">
                    <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                    <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                </filter>
            </defs>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Progress-rate/默认目标金额" transform="translate(-1.000000, -1.000000)" fillRule="nonzero">
                    <g>
                        <rect id="bg" x="0" y="0" width="16" height="16"></rect>
                        <g id="Shape">
                            <use fill="black" fillOpacity="1" filter="url(#filter-2)" href="#TargetAmountIcon-path-1"></use>
                            <use fill={color} href="#TargetAmountIcon-path-1"></use>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        
    )
}
