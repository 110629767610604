
import React, { useEffect } from "react"

import RenderWithData from 'bwax-ui/store/RenderWithData'

import { make as ComponentRenderer } from 'bwax-ui/ml/widget/ComponentRenderer.bs'

import { setupDataQueryRunner } from 'bwax/query/runClientQuery'

import queryUser from "bwax/query/queyUser"

import { Helmet } from 'react-helmet-async'
import {
    setupQueryCache, getInstance, buildCacheKey,
    USER_DATA, USER_RUNTIME, USER_TRANSIENT_DATA
} from "bwax/store/DataCache";

import element_helper from './element_helper.bs';

import { resolveDependenciesAndPrepareData } from 'bwax/BwaxExecHelper';

import PageRuntime from 'bwax-ui/ml/widget/PageRuntime'
import page_runtime_helper from 'bwax-ui/ml/widget/page_runtime_helper.bs'


import { hashCode, guid } from 'bwax/utils'
import { gotoLoginUrlOrWechatLogin, gotoWechatLoginIfPossible } from "bwax-ui/ml/FrontEndHelper";

import { tag, untag } from "bwax/lang/LangHelper";

export default function PageComponentContainer(props) {


    const {
        dlc, componentName,
        entity_dict, data_type_dict, base_env, dts, ast,
         
        initParams, // maybeViewParams, - passed to inner via props

        viewEnv,

        // parentId,

    } = props;

    const { routeTo, domainEnv, webEnv } = viewEnv;

    const dataCache = getInstance(dlc);
    const queryRunner = setupDataQueryRunner(dlc);
    const queryCache = setupQueryCache(
        dataCache, dlc
    );

    // https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/890
    // 目前使用 componentKey 来解决，但 componentKey 不能确保唯一性。
    // 确保唯一性，可以通过给语法树每个节点加上 uid 来解决

    // 2022-11-03：init 参数只允许可序列化，可比较的基本类型后，就不需要这个 componentKey 来做缓存了 key；
    // 因为一样 component + 一样的 init params 得到的状态是值是可以共享的
    // https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1102
    
    const paramKey = hashCode({
        paramsString: element_helper.string_of_value(initParams),
    });

    const loadData = async () => {
        try {
            // 在这里先 prepare data 
            const [preparedData, error] = await (async () => {

                // Page Component always need prepared data

                async function prepareData() {
                    const getUser = async userDeps => {
                        const [_tree, paths, selectionText, _deps] = userDeps;
                        if (!paths || paths.length == 0) {
                            return {}
                        }

                        const dataKey = buildCacheKey(USER_DATA, "GetCurrentUser_" + hashCode(selectionText), dlc);

                        let user = dataCache.get(dataKey);
                        if (!user) {
                            user = await queryUser(dlc, selectionText);
                            if (user) {
                                dataCache.set(dataKey, user);
                            }
                        }

                        return user;
                    }
                    const getRecord = () => { 
                        invariant(false, "Invalid get record here") 
                    };

                    const [predata, error] = await resolveDependenciesAndPrepareData(ast, {
                        getRecord, getUser,
                        entity_dict, data_type_dict, dts, domainEnv, webEnv
                    })

                    return [predata, error]
                }

                // 为了短暂地 cache NOW 和 RANDOM
                // https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/793

                const preparedDataKey = buildCacheKey(USER_TRANSIENT_DATA, "PreparedPageComponentData_" + componentName + "_" + paramKey, dlc);
                const cachedPreparedData = dataCache.get(preparedDataKey);


                if (cachedPreparedData) {
                    const [preparedData, error] = cachedPreparedData;
                    return [untag(preparedData), error];
                } else {
                    const [preparedData, error] = await prepareData();
                    if (preparedData) {
                        dataCache.set(preparedDataKey, [tag(preparedData), error]);
                    }
                    return [preparedData, error]
                }

                // } else {
                //     return ["__no_need__", undefined]
                // }
            })();

            if (!preparedData) {
                return [undefined, error];
            }
            const actualPreparedData =  preparedData ===  "__no_need__" ? undefined : preparedData;

            const [result, initError] = await page_runtime_helper.runInit(
                queryRunner, queryCache,
                domainEnv, webEnv,
                entity_dict, data_type_dict,
                base_env, dts, ast,
                routeTo,
                initParams,
                actualPreparedData
            )

            return [ [ result, actualPreparedData ], error || initError];

        } catch (error) {

            return [undefined, error]
        }
    }

    return (
        <RenderWithData {...{
            ...props, paramKey,
            loadData,
            cacheType: USER_RUNTIME,
            

            // 2022-10-06 加 parentId 是为了解决 https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1029

            // 2022-11-03 因为
            // https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1102
            // 增加了 view 参数 https://git.qunfengshe.com/qunfengshe/bwax-app-admin/-/issues/1046
            // 所以不需要 parentId 作为 key 了

            dataKey: "PageComponentInitModel_" + componentName + "_" + paramKey, // + ":" + parentId,
            Component: Inner,
            queryCache, queryRunner,

            instanceKey: "PageComponentInitModel_" + componentName, // + ":" + parentId,

            // fallback: <PlainColor name="R60" />,
            noSuspense: true
        }} />
    )
}



function Inner({
    data: v, 
    initParams, maybeViewParams,
    componentName,
    entity_dict, data_type_dict, dts, ast,

    queryCache, queryRunner,

    parentId, dlc,

    facade, viewEnv
}) {

    const [resultData, error] = v;

    const { routeTo, domainEnv, webEnv } = viewEnv;

    const missingRequiredUser = error => error == "ERROR: 未找到必需的当前用户";
    const missingUser = error => error == "INFO: 未找到当前用户";

    useEffect(() => {
        if (missingRequiredUser(error)) {
            // preserve 一个 login 页面吧
            // 默认是要让 Design 前端来实现，如果没有实现，则 fallback 到一个内置页面（todo）
            gotoLoginUrlOrWechatLogin("/logon", routeTo, webEnv.currentURL, domainEnv.isSandbox, domainEnv.tenantCode);
        } else if (missingUser(error)) {
            gotoWechatLoginIfPossible(domainEnv.isSandbox, domainEnv.tenantCode);

        }
    }, [error])

    // 如果是 missing user 则继续显示：
    if (error && !missingUser(error)) {
        if (missingRequiredUser(error)) {
            // 暂时啥都不显示
            return null
        }

        function printError() {
            if (error.stack) {
                return error.stack;
            } else if (Array.isArray(error)) {
                // bucklescript error
                const [_, msg] = error;
                return msg
            } else {
                return error;
            }
        }

        return (
            <>
                <Helmet>
                    <meta name="viewport" content="width=device-width,initial-scale=1" />
                </Helmet>
                <div style={{ padding: 16, width: "100%" }}>
                    <h3>页面组件初始化时出错了</h3>
                    <div style={{ padding: "16px 0", width: "100%" }}>
                        <pre style={{ width: "100%", overflow: "auto" }}>
                            {printError()}
                        </pre>
                    </div>
                </div>
            </>
        );
    }

    const [ r, preparedData ] = resultData;

    const pageID = "Component_" + componentName + "@" + parentId;

    const rendered = (
        <PageRuntime
            pageID={pageID}
            domainEnv={domainEnv}
            queryCache={queryCache}
            queryRunner={queryRunner}
            webEnv={webEnv}
            initParams={initParams}
            maybeViewParams={maybeViewParams}
            dlc={dlc}
            entity_dict={entity_dict}
            data_type_dict={data_type_dict}
            dts={dts}
            ast={ast}
            route_to={routeTo}
            init_result={r}
            preparedData={preparedData}
            renderView={(value, onMsg, getInstanceID, model) => {
                return (
                    <ComponentRenderer key={pageID} {...{ value, onMsg, dlc, getInstanceID, facade, viewEnv, pageID }} />
                )
            }}
        />
    )
    return rendered;
}
