
// This file is generated. Don't modify it.
import React from 'react'
export default function TimeIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <defs>
                <path d="M8.08578644,14.25 C5.04822031,14.25 2.58578644,11.7875661 2.58578644,8.75 C2.58578644,5.71243388 5.04822031,3.25 8.08578644,3.25 C11.1233526,3.25 13.5857864,5.71243388 13.5857864,8.75 C13.5857864,11.7875661 11.1233526,14.25 8.08578644,14.25 Z M8.08578644,13.25 C10.5710678,13.25 12.5857864,11.2352814 12.5857864,8.75 C12.5857864,6.26471863 10.5710678,4.25 8.08578644,4.25 C5.60050506,4.25 3.58578644,6.26471863 3.58578644,8.75 C3.58578644,11.2352814 5.60050506,13.25 8.08578644,13.25 Z M11.0857864,2.45710678 L11.7928932,1.75 L13.9142136,3.87132034 L13.2071068,4.57842712 L11.0857864,2.45710678 Z M7.58578644,8.75 L7.58578644,5.75 L8.58578644,5.75 L8.58578644,8.33618904 L10.4142136,10.1646162 L9.70710678,10.8717229 L7.58578644,8.7504026 L7.58578644,8.75 Z M4.91421356,2.45710678 L2.79289322,4.57842712 L2.08578644,3.87132034 L4.20710678,1.75 L4.91421356,2.45710678 Z" id="TimeIcon-path-1"></path>
            </defs>
            <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Time">
                    <g>
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="0.5" width="15" height="15"></rect>
                        <mask id="TimeIcon-mask-2" fill="white">
                            <use href="#TimeIcon-path-1"></use>
                        </mask>
                        <use id="Combined-Shape" fill={color} fillRule="nonzero" href="#TimeIcon-path-1"></use>
                        <g id="Color/UI/Text" mask="url(#TimeIcon-mask-2)" fill={color} fillRule="nonzero">
                            <rect id="light-gray" x="0" y="0" width="16" height="16"></rect>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
