
import React from 'react'
import MenuAndMain from './components/MenuAndMain'

export default function Layout_menuAndMain({ data, slots, events, pageID}) {

    const { menuDrawerVisible } = data;
    const { closeMenuDrawer } = events;
    const { menu, mainContent } = slots;

    function renderMenu() {
        return menu;
    }

    return (
        <MenuAndMain {...{
            menuDrawerVisible, closeMenuDrawer, renderMenu, mainContent, resizeKey: pageID,
        }} />
    )
}
