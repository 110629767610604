
import React, { useEffect } from 'react'

export default function TranslateStatTemp({ facade }) {

    // 
    // 充值申请，比充值记录多 60； 
    // 找到没有对应充值记录的充值申请
    async function findNotRecordApp() {
        const [apps, error] = await facade.list({
            entityName: "充值申请",
            // aggregate: [{ field: "金额", func: "SUM", aliasName: "sum", }],
            // group: timeGroup,
            condition: [[
                // timeCondition,
                { field: "订单.支付状态", op: "eq", value: "已支付"}
            ]],
            fieldPaths: ["金额", "用户.昵称", "用户.手机号用户.手机号", "订单.订单号"],
            pageSize: 5000
        });

        const [records, error2] = await facade.list({
            entityName: "充值记录",
            // aggregate: [{ field: "金额", func: "SUM", aliasName: "sum", }],
            // group: timeGroup,
            condition: [[
                // timeCondition,
                { field: "相关申请.订单.支付状态", op: "eq", value: "已支付"}
            ]],
            fieldPaths: ["相关申请.金额"],
            pageSize: 5000
        });

        for(let app of apps) {
            if(!records.some(r => r.相关申请.id == app.id)) {
                console.log(">>>>", app);
            }
        }
    }

    // 充值记录比订单记录多 30
    // 看看那些充值记录对应的订单重复了

    async function findDuplicateOrderRecords() {
        const [records, error2] = await facade.list({
            entityName: "充值记录",
            // aggregate: [{ field: "金额", func: "SUM", aliasName: "sum", }],
            // group: timeGroup,
            condition: [[
                // timeCondition,
                { field: "相关申请.订单.支付状态", op: "eq", value: "已支付"}
            ]],
            fieldPaths: ["相关申请.金额", "相关申请.订单.订单号", "用户.昵称", "用户.手机号用户.手机号", "创建时间", "相关申请.订单.实付金额"],
            pageSize: 5000
        });

        let orderToRecords = {}; // id -> []
        for(let r of records) {
            const rs = orderToRecords[r.相关申请.订单.id] || [];
            const nrs = [...rs , r];
            if(nrs.length > 1) {
                console.log(">>> duplciated", nrs);
            }
            orderToRecords[r.相关申请.订单.id] = nrs;
        }
       


        for(let r of records) {
            if(r.相关申请.金额 * 100 !== r.相关申请.订单.实付金额) {
                console.log(">>> INCORRECT", r.相关申请.金额, r.相关申请.订单.实付金额, r);
            }
        }

        console.log(">>> end", )
        

    }

    

    useEffect(() => {
        findNotRecordApp();

        // findDuplicateOrderRecords();


    }, []);

    return (
        <div>TranslateStatTemp</div>
    )
}
