

import React from 'react'

import * as ContextMenu from '@radix-ui/react-context-menu';

import './ContextMenu.less';

export default function ContextMenuComp({ children, items, withPortal = true }) {

    // items - [{ icon: react node,  label: string / react node, onSelect }]
    const content = (
        <ContextMenu.Content
            className="lc-context-menu lc-base"
            sideOffset={5}
            align="end"
        >
            {items.map(({ label, onSelect, icon, isDisabled }, index) => {
                return (
                    <ContextMenu.Item key={index} className="context-menu-item" onSelect={onSelect} disabled={isDisabled}>
                        <div className="icon">{icon || null}</div>
                        <div className="label">{label}</div>
                    </ContextMenu.Item>
                )
            })}
        </ContextMenu.Content>
    )

    return (
        <ContextMenu.Root>
            <ContextMenu.Trigger asChild className="lc-menu-trigger">
                {children}
            </ContextMenu.Trigger>
           { withPortal ? (
             <ContextMenu.Portal>
                { content}
            </ContextMenu.Portal>
           ) : content }
        </ContextMenu.Root>
    )
}
