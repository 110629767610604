


import React, { useState, useEffect } from 'react'


import './TestUI.less';
import Button from 'bwax-ui/components/Button';
import { MoonIcon, SunIcon } from '@radix-ui/react-icons';

function colorVarLine(name, index) {
    return `--color-${index}: var(--${name}${index});`
}

function colorVars(name, isNeutral) {

    // [data-color="red"] {
    //     --color-1: var(--red1);
    //     --color-3: var(--red3);
    //     --color-4: var(--red4);
    //     --color-5: var(--red5);
    //     --color-6: var(--red6);
    //     --color-11: var(--red11);

    //     --color-label: var(--red11);
    // }
    const lines = Array(12).fill(1).map((_, index) => colorVarLine(name, index + 1)).join("\n        ");

    return `
    [data-color="${name}"] {
        ${lines}

        --color-label: var(--${isNeutral ? name + "12" : name + "11"});
    }    
    `

}


export default function TestUI() {

    const [mode, setMode] = useState("light");

    const [loading, setLoading] = useState({});

    useEffect(() => {
        if (typeof (document) !== 'undefined') {
            if(document.body.className == "bw-admin") {
                document.body.className = "preview-page";
            }            
        }
    }, [])

    useEffect(() => {
        if (typeof (document) !== 'undefined') {
            if(mode == "dark") {
                if(document.body.className.indexOf("dark-theme") == -1) {
                    document.body.className = document.body.className + " dark-theme"
                }
            } else {
                document.body.className = document.body.className.replace(" dark-theme", "")
            }         
        }
    }, [mode])


    const label = "确定"

    const colors = [
        "tomato", 
        "red", "crimson", "pink", "plum", "purple", "violet", "indigo", "blue", "cyan", "teal", "green", "grass", "orange", "brown"
    ];

    const lightColors = ["sky", "mint", "lime", "yellow", "amber"];

    const neutralColors = ["gray", "mauve", "slate", "sage", "olive", "sand"];

    // console.log(neutralColors.map(name => colorVars(name, true)).join("\n\n"));
    const buttonAppearances = ["default", "primary", "outline"];

    const buttonColorGroups = {
        "standard": colors,
        "light": lightColors,
        "neutral": neutralColors,
    }

    return (
        <div className={"test-ui"}>
            <div style={{ padding: "0.5rem 2rem 0" }}>
                <span onClick={_ => setMode(prev => prev == "light" ? "dark" : "light")}>
                    {/* {mode == "light" ? <SunIcon></SunIcon> : <MoonIcon />} */}
                    <SunIcon />
                </span>
            </div>
            {
                buttonAppearances.map(appearance => {
                    return (
                        <div className='section' key={appearance}>
                            <div className="head">
                                Button {appearance}
                            </div>
                            {Object.keys(buttonColorGroups).map(cg => {
                                const cs = buttonColorGroups[cg];
                                return (
                                    <React.Fragment key={cg}>
                                        <div className="group">
                                            {cs.map((c, index) => {
                                                const id = cg + "-" + c + "-" + appearance;

                                                return (
                                                    <Button  style={{width: "6rem"}}  color={c} key={index} appearance={appearance} isLoading={!!loading[id]} onPress={_ => {
                                                        setLoading(prev => ({ ...prev, [id]: true }));
                                                        setTimeout(() => {
                                                            setLoading(prev => {
                                                                return  ({ ...prev, [id]: false })
                                                            })
                                                        }, 800);

                                                    }}>{label}</Button>
                                                )
                                            })}
                                        </div>
                                        <div className="group">
                                            {cs.map((c, index) => {
                                                return (
                                                    <Button style={{width: "6rem"}} color={c} key={index} isDisabled appearance={appearance} onPress={_ => {
                                                        console.log(">>> pressed")
                                                    }}>
                                                        {label}
                                                    </Button>
                                                )
                                            })}
                                        </div>
                                    </React.Fragment>
                                )

                            })}
                        </div>
                    )
                })
            }
        </div>
    )
}
