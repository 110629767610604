

import React from 'react'

import './Grid.less';

export default function Grid(props) {

    const { className, elements, span = 4, toFill, spacing } = props
    // 三个级

    return (
        <div className={"bw-grid " + className}>
            {
                elements.map((e, i) => {
                    return (
                        <div className={"col-" + span} key={i} style={{
                            padding: (spacing ? `calc(${spacing} / 2)` : 0)
                        }}>
                            {e}
                        </div>
                    )
                })
            }
        </div>
    )
}
