// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Dict$BwaxMobile = require("../dict.bs.js");
var Plate$BwaxMobile = require("../plate.bs.js");
var Lang_eval$BwaxMobile = require("./lang_eval.bs.js");

function list_to_array(l) {
  return Curry._1(Plate$BwaxMobile.List.to_array, l);
}

function array_to_list(arr) {
  return Curry._1(Plate$BwaxMobile.List.from_array, arr);
}

function dict_to_array(d) {
  return Dict$BwaxMobile.$$String.to_array(d);
}

function array_to_dict(array) {
  return Dict$BwaxMobile.$$String.from_array(array);
}

function concat_lists(l_arr) {
  return Plate$BwaxMobile.List.concat(Curry._1(Plate$BwaxMobile.List.from_array, l_arr));
}

function merge_env(base_env, env) {
  return Dict$BwaxMobile.$$String.union(env, base_env);
}

function merge_dts(base_dts, dts) {
  return Pervasives.$at(dts, base_dts);
}

function merge_dts_list(base_dts, dts_list) {
  return Pervasives.$at(Plate$BwaxMobile.List.concat(dts_list), base_dts);
}

function merge_envs(base_env, envs) {
  return Plate$BwaxMobile.List.foldr(Dict$BwaxMobile.$$String.union, envs, base_env);
}

var string_of_value = Lang_eval$BwaxMobile.string_of_value;

exports.list_to_array = list_to_array;
exports.array_to_list = array_to_list;
exports.dict_to_array = dict_to_array;
exports.array_to_dict = array_to_dict;
exports.concat_lists = concat_lists;
exports.merge_env = merge_env;
exports.merge_dts = merge_dts;
exports.merge_dts_list = merge_dts_list;
exports.merge_envs = merge_envs;
exports.string_of_value = string_of_value;
/* Dict-BwaxMobile Not a pure module */
