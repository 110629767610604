
// This file is generated. Don't modify it.
import React from 'react'
export default function NumberIcon (props) {

    const { color } = props
    
    return (
                
        <svg width="1em" height="1em" viewBox="0 0 16 16">
            <g id="Icon" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Icon/Notification/Number">
                    <g id="Group" transform="translate(0.000000, -1.000000)">
                        <rect id="16px-Bounding-Box" stroke="none" fill="none" fillRule="nonzero" x="0.5" y="1.5" width="15" height="15"></rect>
                        <circle id="Oval" fill={color} cx="8" cy="9" r="6"></circle>
                        <text id="1" fontFamily="PingFangSC-Semibold, PingFang SC" fontSize="8" fontWeight="500" line-spacing="18" fill="#FFFFFF">
                            <tspan x="5.5" y="11">1</tspan>
                        </text>
                    </g>
                </g>
            </g>
        </svg>
        
    )
}
