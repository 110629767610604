// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var QueryString = require("query-string");
var DomUtils$BwaxMobile = require("../../utils/DomUtils.bs.js");

var href = Belt_Option.flatMap(DomUtils$BwaxMobile.$$document, (function (doc) {
        return doc.location.href;
      }));

function getParam(name) {
  return Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(DomUtils$BwaxMobile.$$document, (function (doc) {
                            return Belt_Option.map(QueryString.parse(doc.location.search), (function (parsed) {
                                          return parsed;
                                        }));
                          })), Js_json.decodeObject), (function (paramsDict) {
                    return Js_dict.get(paramsDict, name);
                  })), Js_json.decodeString);
}

function getHash(name) {
  return Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(Belt_Option.flatMap(DomUtils$BwaxMobile.$$document, (function (doc) {
                            return Belt_Option.map(QueryString.parse(doc.location.hash), (function (parsed) {
                                          return parsed;
                                        }));
                          })), Js_json.decodeObject), (function (hashDict) {
                    return Js_dict.get(hashDict, name);
                  })), Js_json.decodeString);
}

var $$document = DomUtils$BwaxMobile.$$document;

exports.$$document = $$document;
exports.href = href;
exports.getParam = getParam;
exports.getHash = getHash;
/* href Not a pure module */
