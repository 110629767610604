

import React, { useState, useRef, useContext, useEffect } from 'react'

import Button, { Pressable } from 'bwax-ui/components/Button';
import classNames from 'classnames';
import numeral from 'numeral';

import { runDataQuery } from 'bwax/query/runClientQuery';
import { toast } from 'bwax-ui/components/Toast';
import Wx_pay from "bwax-ui/ml/utils/wx_pay.bs"
import Alipay from "bwax-ui/ml/utils/alipay.bs"

import { RiWechatPayFill, RiAlipayFill } from "react-icons/ri";

import QrCode from 'bwax-ui/auxiliary/QrCode'
import Modal from 'bwax-ui/components/Modal'

import { addQueryParam } from 'bwax/ml/lang/mod/builtin/StringHelper';

import TranslationInvitation from './TranslationInvitation';
import TextInput from 'bwax-ui/components/inputs/TextInput';

import { addInvisibleElement } from "bwax-ui/ml/FrontEndHelper";

import { useTrack } from 'bwax-ui/track';

// 支付流程:
// 1. 创建订单
// 2. 调用 Wx_pay.initiate_pay
// 3. 然后好像就轮询订单状态就好了？

export default function Pay_translate({ data, events, facade, closeModal, viewEnv }) {

    const track = useTrack();

    const { balance, currentUserId, currentUserNickName, returnTo, price } = data;

    const shouldPay = price && price > balance ? price - balance : 0;

    const { reloadBalance } = events;

    const { routeTo, webEnv } = viewEnv;

    const allWholesales = [[30, 33], [100, 120], [300, 400], [1000, 1500]];

    const wholesales = allWholesales.filter(([p]) => p > shouldPay);

    const possibleChoices = [(shouldPay ? [shouldPay, shouldPay] : null), ...wholesales].filter(x => !!x);
    const [choice, setChoice] = useState(possibleChoices[0]);

    useEffect(() => {
        // 如果填了兑换券，should pay 可能会变
        if (choice && possibleChoices.every(([p]) => p != choice[0])) {
            setChoice();
        }

    }, [shouldPay]);


    const [isPaying, setIsPaying] = useState(); // "ali" / "wx"

    const [isSucceeded, setIsSucceeded] = useState(false);

    const [hasInitiated, setHasInitiated] = useState(false);

    const [paymentCodeUrl, setPaymentCodeUrl] = useState();
    const [polling, setPolling] = useState(false);
    const pollingRef = useRef();
    pollingRef.current = polling;


    function doClose() {
        if (closeModal) {
            closeModal()
        } else if (returnTo) {
            routeTo(addQueryParam("after-payment", true, returnTo))
        }
    }

    async function tryToPay(method = "wx") {
        // 新的"订单"
        // 新的订阅记录，关联上面的订单

        if (!choice || choice[0] <= 0) {
            // 提示?
            return
        }
        // // sandbox 自动 x 0.001 

        const [price, credits] = choice;

        const totalAmount = viewEnv.domainEnv.isSandbox ? Math.max(price / 100000, 0.01) : price;

        const title = "购买积分 " + credits + ` (￥${price})`;

        setIsPaying(method);

        const [order, error] = await facade.add({
            entityName: "订单",
            formData: {
                标题: title,
                金额: Math.round(totalAmount * 100),
            }
        })

        if (error || !order) {
            setIsPaying();
            // TODO 提示
            console.error(error);
            toast({ title: "出错了" })
            return
        }

        // 创建充值申请
        const [sub, error2] = await facade.add({
            entityName: "充值申请",
            formData: {
                金额: totalAmount,
                数量: credits,
                用户: currentUserId,
                订单: order.id
            }
        });

        if (error2 || !sub) {
            setIsPaying();
            console.error(error);
            toast({ title: "出错了" })
            // TODO 提示
            return
        }

        // 开始微信支付
        const queryRunner = (queryText, variables, file) => {
            return runDataQuery(facade.dlc)(queryText)(variables, file);
        }



        if (method == "ali") {

            Alipay.initiate_pay(
                queryRunner,
                order.id,
                {},
                formHTML => {
                    // 如果有 code， 基本上就是弹出
                    // setIsPaying();
                    addInvisibleElement(formHTML);

                    // 
                },
                error => {
                    console.error(error);
                    pollingRef.current = false;
                    setIsPaying();
                    setPolling(false);
                }
            )

        } else {
            // 默认微信

            setPolling(true);
            pollingRef.current = true;

            setHasInitiated(true);

            Wx_pay.initiate_pay(
                queryRunner,
                order.id,
                codeUrl => {
                    // 如果有 code， 基本上就是弹出
                    setIsPaying();
                    setPaymentCodeUrl(codeUrl);
                },
                error => {
                    console.error(error);
                    pollingRef.current = false;
                    setIsPaying();
                    setPolling(false);
                }
            )
        }


        // 
        // start polling
        async function pollOrder() {
            const [polledOrder, error] = await facade.findById(order.id, {
                entityName: "订单",
                fieldPaths: ["支付状态"]
            }, { forceRefreshing: true });

            if (polledOrder && polledOrder.支付状态 == "已支付") {

                // 这个可以改成 pop confirm
                toast({ title: returnTo ? "已成功支付，将跳回原页面" : "已成功支付", duration: 2000 });

                setPolling(false);
                pollingRef.current = false;

                setPaymentCodeUrl();

                setIsSucceeded(true);

                reloadBalance();
                setTimeout(() => {
                    doClose();
                }, 600)

            } else {

                if (pollingRef.current) {
                    setTimeout(() => {
                        pollOrder()
                    }, 500)
                }
            }

        }
        if (pollingRef.current) {
            setTimeout(() => {
                pollOrder()
            }, 500)
        }

    }

    function selectBox(price, credits) {

        const isActive = choice && price == choice[0];
        const extra = (credits / price) - 1;

        return (
            <Pressable key={price} onPress={_ => {
                track("pay_select_price", { price })
                setChoice([price, credits]);
            }}>
                <div key={price} data-color={"violet"} className={classNames(
                    "py-3 flex flex-col gap-0.5 border rounded min-w-[140px] items-center w-[156px]",
                    {
                        "bg-[var(--mauve2)] border-transparent text-[var(--mauve10)]": !isActive,
                        "border-[var(--violet9)] text-[var(--violet11)]": isActive,
                    }
                )}>
                    <div className="text-[1.5rem] font-medium pr-4">￥{price}</div>
                    <div className={classNames("flex gap-0.5", {
                        "text-[var(--mauve10)]": !isActive,
                        // "text-[var(--plum11)]": isActive
                    })}>
                        {credits} 积分
                        {extra - 0.01 > 0 ? <div className="font-medium font-size-11">+{numeral(extra).format("0%")}</div> : null}
                    </div>

                </div>
            </Pressable>
        )
    }

    // const shouldDisplayWholesales = shouldPay ? wholesales.every(([price]) => price > shouldPay) : true;

    const [invitationShown, setInvitationShown] = useState(false);

    const [redeemCodeInputShown, setRedeemCodeInputShown] = useState(false);

    return (
        <>
            <div className="flex flex-col gap-5 px-6">
                <div className="font-medium font-size-16">
                    购买积分
                </div>
                <div className="flex flex-col gap-3.5">
                    <div className="opacity-90">您的余额为 {balance} 积分{!isSucceeded && shouldPay ? `，需要购买 ${shouldPay} 积分` : ""}</div>
                    {shouldPay ? (
                        <div className="flex flex-wrap">
                            {selectBox(shouldPay, shouldPay)}
                        </div>
                    ) : null}
                </div>
                {wholesales.length > 0 ?
                    <div className="flex flex-col gap-3.5">
                        {shouldPay ? <div className="opacity-90">您也可以购买更多积分，价格更划算</div> : null}
                        <div className="flex gap-3 flex-wrap">
                            {wholesales.map(([price, credits]) => {
                                return selectBox(price, credits)
                            })}
                        </div>
                    </div> : null}
                <div className="text-[var(--gray10)] font-size-12 flex flex-col gap-[7px]">
                    <div className="py-1">* 每翻译大约 3000 token 需要花费 1 积分</div>
                    <div className="py-1">* 购买后可点击左侧菜单下方用户栏<span className="text-[var(--gray12)]">申请发票</span></div>
                    <div className="self-start">
                        <Pressable onPress={_ => {
                            track("pay_open_invitation")
                            setInvitationShown(true)
                        }
                        }>
                            <div className="text-[var(--indigo10)] font-size-12 px-2 py-1 rounded cursor-pointer">
                                {"邀请朋友获得积分 >>"}
                            </div>
                        </Pressable>
                    </div>
                    <div className="self-start">
                        {redeemCodeInputShown ? (
                            <RedeemCodeInput {...{ facade, reloadBalance }} />
                        ) : (
                            <Pressable onPress={_ => {
                                track("pay_use_redeem_code")
                                setRedeemCodeInputShown(true)
                            }
                            }>
                                <div className="text-[var(--indigo10)] font-size-12 px-2 py-1 rounded cursor-pointer">
                                    {"输入兑换码 >>"}
                                </div>
                            </Pressable>
                        )}
                    </div>
                </div>
                <div className="flex flex-col gap-2 pt-1 pb-6 sm:pb-4">
                    <div className="px-2 font-size-12 text-[var(--gray10)]">选择支付方式:</div>
                    <div className="flex flex-col-reverse sm:flex-row gap-4 py-1 px-2">
                        {isSucceeded ?
                            <Button size={"large"} color={"amber"} appearance="primary" className="min-w-[120px]" onPress={_ => {
                                doClose()
                            }}>已成功支付, 点击{returnTo ? "返回" : "关闭"}</Button>
                            :
                            <>
                                {returnTo ? (
                                    <Button size={"large"} className="min-w-[120px]" onPress={_ => {
                                        doClose()
                                    }}>返回</Button>
                                ) : null}
                                {choice ? (
                                    <>
                                        {!webEnv.isWeChat && !webEnv.isMobile ? (
                                            <Button color={"blue"} className="min-w-[120px] flex gap-1.5 !h-[44px] sm:!h-[40px]" appearance="primary" size={"large"}
                                                isLoading={isPaying == "ali"} isDisabled={isPaying && isPaying != "ali"} onPress={_ => {
                                                    track("pay_confirm", { method: "ali_pay" })
                                                    tryToPay("ali")
                                                }}><RiAlipayFill />支付宝 ￥{choice[0]}</Button>) : null
                                        }
                                        <Button color={"grass"} className="min-w-[120px] flex gap-1.5 !h-[44px] sm:!h-[40px]" appearance="primary" size={"large"}
                                            isLoading={isPaying == "wx" || polling} isDisabled={isPaying && isPaying != "wx"} onPress={_ => {
                                                track("pay_confirm", { method: "wechat_pay" })
                                                tryToPay()
                                            }}><RiWechatPayFill />微信支付 ￥{choice[0]}</Button>

                                    </>
                                ) : null}
                            </>
                        }
                    </div>
                </div>
            </div>
            <Modal {...{
                className: "max-w-xs",
                isOpen: !!paymentCodeUrl,
                isMain: false,
                onOpenChange: open => {
                    if (!open) {
                        setPaymentCodeUrl();
                        setPolling(false);
                    }
                },
            }}>
                <div className="flex flex-col gap-3 px-4 py-8 self-center items-center">
                    <QrCode
                        info={paymentCodeUrl}
                        size={200}
                        onGenerated={() => {
                            // console.log("Generated", text)
                        }}
                    />
                    <div className="pt-2">请用微信扫描二维码支付</div>
                    <div className="text-[var(--gray11)] font-size-12">* 支付之后，请稍等一两秒，等待后台读取信息</div>
                </div>
            </Modal>
            {invitationShown ? (
                <Modal className="max-w-xl px-6 pt-4 pb-8" isMain={true} isOpen={true} contentClassName={"h-full"} onOpenChange={open => {
                    if (!open) {
                        setInvitationShown(false);
                    }
                }}>
                    {
                        closeModal => (
                            <TranslationInvitation {...{
                                facade, viewEnv,
                                currentUser: { id: currentUserId, nickName: currentUserNickName },
                                // preference, reloadPreference,
                            }} />
                        )
                    }
                </Modal>
            ) : null}
        </>


    )
}


function RedeemCodeInput({ reloadBalance, facade }) {

    const [code, setCode] = useState();

    const [error, setError] = useState();

    async function submit(code) {
        const [result, error] = await facade.customMutation({
            entityName: "兑换码",
            interfaceName: "兑换",
            args: [code],
            outputFieldPaths: [["兑换码", ["赠送积分"]]]
        });
        if (error) {
            // error handling
            setError(error)
        } else if (result) {
            setError();
            reloadBalance();
            setCode("");
            setTimeout(() => {
                reloadBalance()
            }, 2000)
            toast({ title: "兑换成功，积分余额增加 " + result.赠送积分, duration: 5000 })
        }
    }


    return (
        <div className="flex flex-col gap-1">
            <div className="flex gap-2">
                <TextInput className="!py-1 w-[8rem] !px-3 text-[var(--gray12)] font-size-14" color={"violet"} styled={true} onChange={setCode} value={code || ""} placeholder="输入兑换码" autoFocus>
                </TextInput>
                <Button className="self-start" isDisabled={!(code && code.length >= 5)} color="violet" onPress={_ => {
                    submit(code)
                }}>
                    兑换
                </Button>
            </div>
            {error ? <div className="text-[var(--tomato9)] px-3">{error}</div> : null}
        </div>
    )

}

