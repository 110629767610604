
import React, { useContext, useState, useEffect } from 'react'

import Loading from 'bwax-ui/components/Loading';

import Button, { Pressable } from 'bwax-ui/components/Button';
import classNames from 'classnames';

import Tabs from 'bwax-ui/components/Tabs';
import ChatContext from './ChatContext';
import UsageQuotaContext from './UsageQuotaContext';

import ChatStream from './components/chat/ChatStream';

import FunctionToggle, { defaultAvailableFunctions } from './components/input/FunctionToggle';

import { useTrack } from 'bwax-ui/track';

export default function NoteSupportPanel({
    collapsed, setCollapsed, noteId, facade, viewEnv, currentUserId
}) {

    const track = useTrack();

    function renderChat() {
        return (
            <NoteChat noteId={noteId} facade={facade} viewEnv={viewEnv} currentUserId={currentUserId} />
        )
    }

    function renderAssociation() {
        return (
            <div>Associate</div>
        )
    }


    const tabs = [
        { label: "对话", key: "chat", content: renderChat() },
        // { label: "联想", key: "associate", content: renderAssociation() },
        // { label: "笔记", key: "notes", content: renderNotes() },
        // { label: (<div className="selected-tab">已选{ selected ? <span className="badge">{ selected.length }</span> : null }</div>), key: "selected", content: renderSelected() },
    ]

    return collapsed ? (
        <div className="w-8 h-full relative wide-only">
            <Pressable onPress={_ => {
                track("note_support_panel_open")
                setCollapsed(false)
            }}>
                <div
                    className={classNames(
                        "py-2 px-2 font-size-20 text-[var(--gray11)] hover:bg-[var(--gray2)] active:bg-[var(--gray3)]",
                        "rounded cursor-pointer flex items-center absolute top-2 right-0.5",
                        // "bg-[var(--gray2)]"
                    )}>
                    <i className='bx bx-book-content'></i>
                </div>
            </Pressable>
        </div>
    ) : (
        <div className="grow h-full relative wide-only pt-2.5 pb-0 px-1 relative">
            <div className="pt-0 px-1.5 pb-1.5 flex gap-2 absolute top-2.5 right-2">
                <div className="grow"></div>
                <Pressable onPress={_ => {
                    track("note_support_panel_close")
                    setCollapsed(true)
                }}>
                    <div className="h-[35px] py-0.5 px-2.5 font-size-17 text-[var(--gray11)] hover:bg-[var(--gray3)] bg-[var(--gray2)] rounded cursor-pointer flex items-center justify-center">
                        <i className='bx bx-chevrons-right'></i>
                    </div>
                </Pressable>
            </div>
            <Tabs tabs={tabs} defaultTab={"chat"} className="h-full" tabContentClassName="!py-0 !px-0" />
        </div>
    )

}

const sessionFieldPaths = [
    "起始消息", "标题", "已归档", "用户选项", "内容生成选项", "chatModel",
    "会话角色.名称", "会话角色.用户选项", "会话角色.内容生成选项", "会话角色.欢迎消息",
    "修改时间",
]


// 
function NoteChat({ noteId, facade, viewEnv, currentUserId }) {

    const { botAvatarUrl, userAvatar, userNickName } = useContext(ChatContext);
    const { reloadUsageQuota, remainingQuota } = useContext(UsageQuotaContext) || {};

    const [session, setSession] = useState();

    const scene = "note-chat::" + noteId;

    function makeSession(r) {
        return {
            id: r.id, firstMessage: r.起始消息, title: r.标题,
            userOptions: r.用户选项,
            contentOptions: r.内容生成选项,
            chatModel: r.chatModel,
            persona: r.会话角色,
            updatedAt: r.修改时间,
        }
    }

    async function createSession() {

        // const 
        const [lastNoteChatSession, _] = await facade.findOne({
            entityName: "OpenAI-会话", sort: [{ field: "修改时间", order: "DESC" }], fieldPaths: ["内容生成选项", "chatModel"],
            condition: [
                [
                    {
                        field: "用户", op: "eq", value: currentUserId,
                    }, {
                        field: "场景", op: "startsWith", value: "note-chat"
                    },
                ]
            ],
        }, { forceRefreshing: true });


        const [createdSession, error] = await facade.add({
            entityName: "OpenAI-会话",
            // condition: [[{ field: "场景", op: "eq", value: scene}, { field: "创建者", op: "eq", value: currentUserId }]]
            formData: {
                场景: scene,
                // 有其他的默认项吗？
                用户: currentUserId,
                用户选项: {
                    possibleQuestionsEnabled: true // 这个默认开吗?
                },
                内容生成选项: lastNoteChatSession ? lastNoteChatSession.内容生成选项 : {
                    // "temperature": 0.2,
                    // "max_tokens": null,
                    availableFunctions: defaultAvailableFunctions,
                },
                chatModel: lastNoteChatSession && lastNoteChatSession.chatModel
            },
            fieldPaths: sessionFieldPaths,
        });

        setSession(makeSession(createdSession))
    }

    useEffect(() => {
        // 直接用 scene
        (async () => {
            const [foundSession, error] = await facade.findOne({
                entityName: "OpenAI-会话",
                condition: [[
                    { field: "场景", op: "eq", value: scene },
                    { field: "用户", op: "eq", value: currentUserId },
                    { field: "已归档", op: "ne", value: true },
                ]],
                fieldPaths: sessionFieldPaths,
            });

            if (error) {
                // TODO handle error
                return
            }
            if (foundSession) {
                setSession(makeSession(foundSession))

            } else {
                createSession()
            }
        })()

    }, [noteId]);



    async function updateSession(id, formData) {
        const [result, error] = await facade.update({
            entityName: "OpenAI-会话",
            formData,
            id,
            fieldPaths: sessionFieldPaths
        });
        return [result, error];
    }

    const [possibleQuestions, setPossibleQuestions] = useState({});

    function isPossibleQuestionsEnabled(session) {
        return session && session.userOptions && session.userOptions.possibleQuestionsEnabled;
    }

    async function loadPossibleQuestions(sessionId) {
        const [result, error] = await facade.findById(sessionId, {
            entityName: "OpenAI-会话",
            fieldPaths: ["可能的问题"],
        }, { forceRefreshing: true });

        if (!error && result) {
            setPossibleQuestions(prev => ({ ...prev, [sessionId]: result.可能的问题 }))
        }
    };

    useEffect(() => {
        if (session && isPossibleQuestionsEnabled(session)) {
            loadPossibleQuestions(session.id);
        }
    }, [session && session.id, isPossibleQuestionsEnabled(session)]);



    async function refreshSession(sessionId) {
        const [result, error] = await facade.findById(sessionId, { entityName: "OpenAI-会话", fieldPaths: sessionFieldPaths }, { forceRefreshing: true });
        if (!error && result) {
            const newSession = makeSession(result);
            setSession(newSession)

            // refresh the possible questions:
            if (isPossibleQuestionsEnabled(newSession)) {
                loadPossibleQuestions(newSession.id);
            }
        }
    }


    if (!session) {
        return (
            <div className="h-1/2 flex flex-col justify-center items-center">
                {/* <Loading /> */}
            </div>
        )
    } else {

        return (
            <ChatStream {...{
                facade, viewEnv,

                // additional,
                currentSessionId: session && session.id,
                currentSession: session,
                updateSessionAndList: async (sessionId, formData) => {
                    const [result, error] = await updateSession(sessionId, formData);
                    if (!error) {
                        setSession(makeSession(result))
                    }
                },

                refreshSession: sessionId => {
                    refreshSession(sessionId)
                },

                reloadUsageQuota,
                remainingQuota,

                botAvatarUrl, userAvatar, userNickName,
                userId: currentUserId,
                // instructionPrompt,

                maxInputCount: 1000,

                imageEnabled: viewEnv.domainEnv.isSandbox,

                // className,

                // showConfig: false,
                // directToKnowledge: true,

                getPossibleQuestions: (session, messages) => {

                    const { possibleQuestionsEnabled } = session.userOptions || {};
                    if (!possibleQuestionsEnabled) {
                        return []
                    }
                    return (possibleQuestions[session.id] || []).filter(q => {
                        return !messages.find(m => m.content && m.content.trim() == q.trim());
                    }).slice(0, 4);

                },

                emptyView: (
                    <div className="flex flex-col text-[var(--gray9)] py-10 px-8 gap-6">

                        <div className="flex flex-col gap-2">
                            <div>这里方便你直接跟 AI 助手对话，快速获得信息</div>
                            <div>但暂时没有针对当前笔记进行优化，它还没有“这个笔记”的概念</div>
                        </div>
                        
                    </div>
                ),

                // possibleQuestionsExpandByDefault: true,

                inputBoxClassName: "!px-1"

            }} />
        )
    }

}

