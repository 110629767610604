
// TODO in server rendering

const clientUserAgent = typeof (navigator) !== 'undefined' ? navigator.userAgent : null

const getUserEnv = name => {
  if(typeof(document) !== "undefined") {
    if(document.userenv) {
      return document.userenv[name];
    } else {
      return undefined
    }
  } else {
    return undefined
  }
}

export function isMobile(ua) {

  const userenvValue = getUserEnv("isMobile")
  if(userenvValue !== undefined) {
    return userenvValue;
  }

  const userAgent = ua || clientUserAgent;
  return /Android/i.test(userAgent) ||
    /BlackBerry/i.test(userAgent) ||
    /iPhone|iPad|iPod/i.test(userAgent) ||
    /IEMobile/i.test(userAgent)
}

export function isWeChat(ua) {

  const userenvValue = getUserEnv("isWeChat")
  if(userenvValue !== undefined) {
    return userenvValue;
  }

  const userAgent = ua || clientUserAgent;
  return /MicroMessenger/i.test(userAgent) && !(/wxwork/i.test(userAgent));
}


export function isWxWork(ua) {

  const userenvValue = getUserEnv("isWxWork")
  if(userenvValue !== undefined) {
    return userenvValue;
  }

  const userAgent = ua || clientUserAgent;
  return /MicroMessenger/i.test(userAgent) && (/wxwork/i.test(userAgent));
}


export function isIOS(ua) {

  const userenvValue = getUserEnv("isIOS")
  if(userenvValue !== undefined) {
    return userenvValue;
  }

  const userAgent = ua || clientUserAgent;
  return /iPhone|iPad|iPod/i.test(userAgent)
}

