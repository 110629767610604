
import React, { useState, useEffect } from 'react'



import { Pressable } from 'bwax-ui/components/Button';
import classNames from 'classnames';


export default function Toggle({ label, value: givenValue, onChange, className, color = "grass" }) {

    const [ localValue, setLocalValue ] = useState(givenValue || false);
    
    useEffect(() => {
        if(givenValue !== localValue) {
            setLocalValue(givenValue);
        }
    }, [ givenValue]);


    function toggle() {
        const newValue = !localValue;
        setLocalValue(newValue);
        onChange(newValue);
    }

    return (
        <Pressable onPress={_ => toggle()}>
            <div data-color={localValue ? color : "gray"} className={classNames(
                "flex px-4 py-1.5 shadow rounded font-size-13 justify-between items-center cursor-pointer gap-2", className,
                {
                    "bg-[var(--color-3)] text-[var(--color-11)]": localValue,
                    "text-[var(--gray8)] bg-[var(--lc-color-bg-3)]": !localValue,
                }
            )}>
                { label }
                { localValue ? <i className='bx bx-check-circle' /> : <i className='bx bx-minus-circle'></i> }
            </div>
        </Pressable>
    )
}

