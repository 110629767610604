
import { runDataQuery } from 'bwax/query/runClientQuery'


export default async function cutText({ text, tokenNum, direction = "right", dlc }) {

    if(text.length < tokenNum) {
        // 如果字符串长度都小于 token 数，就不用 cut 了
        return text 
    }

    const runQuery = runDataQuery(dlc);

    const queryText = `
        query($text:String!, $offset: Float!) {
            openAITokens {
                cutText(text: $text, direction: ${direction}, offset: $offset) {
                    result
                }
            }
        }
    `
    const result = await runQuery(queryText)({ text, offset: tokenNum });

    const { errors, data } = JSON.parse(result);

    // console.log(">>> data", data)

    if(data && data.openAITokens && data.openAITokens.cutText ) {
        return data.openAITokens.cutText.result

    } else {

        // simply return by character count.
        if(direct == "right") {
            return text.substring(text.length - tokenNum);
        } else {
            return text.substring(0, tokenNum);
        }
    }

}