
import React, { useState, useEffect, useRef } from 'react'

import Modal from 'bwax-ui/components/Modal';

import TranslateEditView from './TranslateEditView';

import classNames from 'classnames';

import { Pressable } from 'bwax-ui/components/Button';
import { GlossaryView } from './Translate_glossary';

const simpleTag = (label, color = "mint", onPress) => {
    const inner = (
        <div key={label} className={classNames("bg-[var(--color-3)] px-1.5 py-0.5 rounded text-[var(--color-11)] whitespace-pre font-size-10", {
            "cursor-pointer hover:bg-[var(--color-4)]": !!onPress,
        })} data-color={color}>
            { label }
        </div>
    );
    if(onPress) {
        return (
            <Pressable onPress={onPress} key={label}>
                { inner }
            </Pressable>
        )
    } else {
        return inner
    }
}


export default function TranslationTaskFlags({ task, facade, clickable = true, hideModel = false }) {

    const [ infoModalOpen, setInfoModalOpen] = useState();

    const infoModals = {
        "edits": TranslateEditView,
        "glossary": GlossaryView,
    }


    function renderInfoModal() {
        if(infoModalOpen) {
            const Comp = infoModals[infoModalOpen];
            if(!Comp) {
                return null;
            }
            return (
                <Modal isDismissable className="pt-3 max-w-2xl" isOpen={true} contentClassName={""} onOpenChange={open => {
                    if (!open) {
                        setInfoModalOpen();
                    }
                }}>
                    {
                        closeModal => {
                            return (
                                <Comp task={task} facade={facade} />
                            )
                        }
                    }
                </Modal>
            )
        }
        return null
    }

    return (
        <>
            { task.翻译图片 ? simpleTag("OCR", ) : null }
            { task.背景修复 ? simpleTag("背景修复",) : null }
            { Object.keys(task.已应用的翻译缓存修订 || {}).length > 0 ? simpleTag("已修订", "mint", clickable ? _ => setInfoModalOpen("edits") : undefined) : null }
            { task.已应用模型 == "gpt-4" && !hideModel ? simpleTag("GPT4", "blue") : null }
            {task.术语表 && Object.keys(task.术语表).length > 0 ? simpleTag("术语表", "mint", clickable ? _ => setInfoModalOpen("glossary") : null) : null}
            { renderInfoModal() }
        </>

    )
}