


import React, { useState, useEffect, useRef, useContext } from 'react'

import ChatStream from './components/chat/ChatStream';

import ChatNewSession from './components/chat/ChatNewSession';

import ChatSessionList from './components/chat/ChatSessionList';

import MenuAndMain from './components/MenuAndMain';

import PersonaGrid, { personaFieldPaths } from './components/persona/PersonaGrid';

import UsageQuotaContext from 'bwax-ui/ml/widget/ports/inbot/UsageQuotaContext';

import { toast } from 'bwax-ui/components/Toast';

import Avatar from 'bwax-ui/components/Avatar';

import PersonaManagement from './components/persona/PersonaManagement';
import ClampedText from 'bwax-ui/components/ClampedText';
import { Pressable } from 'bwax-ui/components/Button';
import { addQueryParam } from 'bwax/ml/lang/mod/builtin/StringHelper';
import classNames from 'classnames';

export default function ChatView(props) {

    const { data, events, slots, viewEnv, facade } = props;

    const {
        sessionListDrawerOpen,

        currentSessionId: givenCurrentSessionId,
        personaId,

        // change url;
        shouldChangeUrl = false,


        botAvatarUrl, userAvatar, userNickName,
        userId, scene,
        instructionPrompt,
        instructionPromptForLimitedKnowledge,

        maxInputCount,

    } = data;

    const { closeSessionListDrawer } = events;

    const { routeTo } = viewEnv;

    const [sessions, setSessions] = useState([]);
    const [hasMoreSession, setHasMoreSession] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);

    const [currentSessionId, setCurrentSessionId] = useState(givenCurrentSessionId == "characters" ? undefined : givenCurrentSessionId);

    useEffect(() => {
        if (givenCurrentSessionId == "characters") {
            if (currentSessionId) {
                setCurrentSessionId()
            }

        } else if (currentSessionId !== givenCurrentSessionId) {
            setCurrentSessionId(givenCurrentSessionId);
        }
    }, [givenCurrentSessionId]);

    const currentSession = currentSessionId ? sessions.find(s => s.id == currentSessionId) : undefined;

    useEffect(() => {
        if (personaId) {
            setCurrentSessionId();
        }
    }, [personaId]);


    function makeSession(r) {
        return {
            id: r.id, firstMessage: r.起始消息, title: r.标题, userOptions: r.用户选项,
            contentOptions: r.内容生成选项,
            chatModel: r.chatModel,
            persona: r.会话角色,
            updatedAt: r.修改时间,
            createdAt: r.创建时间,
            isArchived: r.已归档
        }
    }
    //

    const sessionFieldPaths = [
        "起始消息", "标题", "已归档", "用户选项", "内容生成选项", "chatModel",
        "会话角色.名称", "会话角色.用户选项", "会话角色.内容生成选项", "会话角色.欢迎消息", "会话角色.头像",
        "修改时间", "创建时间",
    ]


    const pageSize = 30;

    async function loadSessions(userId, offset = 0) {

        setLoadingMore(true);

        const entityName = "OpenAI-会话";
        await facade.prepare([entityName])

        const entity = facade.entities.find(e => e.name == entityName);
        const hasSharing = entity.fields.find(f => f.name === "角色分享");

        const [result, error] = await facade.listAll({
            entityName,
            condition: [
                [
                    {
                        field: "用户", op: "eq", value: userId,
                    }, {
                        field: "已归档", op: "ne", value: true
                    }, {
                        field: "场景", op: "eq", value: scene
                    },
                    ...(
                        hasSharing ? [
                            {
                                field: "角色分享.id", op: "eq", value: null
                            }
                        ] : []
                    )
                ]
            ],
            sort: [
                { field: "修改时间", order: "DESC" }
            ],
            pageSize,
            offset,
            fieldPaths: sessionFieldPaths,
        }, { forceRefreshing: true });

        setLoadingMore(false);

        if (!error && result) {

            const hasMore = result.pageSize + offset < result.count;
            setHasMoreSession(hasMore);

            setSessions(prev => {

                // 要排除掉已经存在的，然后在 append 到后面

                const existing = prev || [];

                const toAppend = result.data.filter(s => !existing.some(e => e.id === s.id)).map(r => makeSession(r));

                return [...existing, ...toAppend];

            })
        }
    }

    const loadMore = () => {
        if (hasMoreSession) {
            loadSessions(userId, sessions.filter(s => !s.isArchived).length);
        }
    }

    const [possibleQuestions, setPossibleQuestions] = useState({});

    const [possibleQuestionLoading, setPossibleQuestionsLoading] = useState({});

    function isPossibleQuestionsEnabled(session) {
        return session && session.userOptions && session.userOptions.possibleQuestionsEnabled;
    }

    async function loadPossibleQuestions(sessionId) {
        // setPossibleQuestionsLoading(prev => ({ ...prev, [sessionId]: true}))
        const [result, error] = await facade.findById(sessionId, {
            entityName: "OpenAI-会话",
            fieldPaths: ["可能的问题"],
        }, { forceRefreshing: true });
        // setPossibleQuestionsLoading(prev => ({ ...prev, [sessionId]: false}))

        if (!error && result) {
            setPossibleQuestions(prev => ({ ...prev, [sessionId]: result.可能的问题 }))
        }
    };

    useEffect(() => {
        if (currentSession && isPossibleQuestionsEnabled(currentSession)) {
            loadPossibleQuestions(currentSession.id);
        }
    }, [currentSession && currentSession.id, isPossibleQuestionsEnabled(currentSession)]);


    async function createSession(userOptions, contentOptions, personaId, chatModel) {
        const [result, error] = await facade.add({
            entityName: "OpenAI-会话",
            formData: {
                用户: userId,
                场景: scene,
                用户选项: userOptions,
                内容生成选项: contentOptions,
                会话角色: personaId,
                chatModel,
            },
            fieldPaths: sessionFieldPaths
        });
        return [result, error];
    }

    async function updateSession(id, formData) {
        const [result, error] = await facade.update({
            entityName: "OpenAI-会话",
            formData,
            id,
            fieldPaths: sessionFieldPaths
        });
        return [result, error];
    }


    async function refreshSession(sessionId) {
        const [result, error] = await facade.findById(sessionId, { entityName: "OpenAI-会话", fieldPaths: sessionFieldPaths }, { forceRefreshing: true });
        if (!error && result) {
            const newSession = makeSession(result);
            setSessions(prev => {
                return (prev || []).map(s => s.id == result.id ? newSession : s).sort((a, b) => (new Date(b.updatedAt)).getTime() - (new Date(a.updatedAt)).getTime());
            });

            // refresh the possible questions:
            if (isPossibleQuestionsEnabled(newSession)) {
                loadPossibleQuestions(newSession.id);
            }

        }
    }

    async function archiveSession(id) {
        const [result, error] = await updateSession(id, { 已归档: true });
        if (!error) {
            const newSession = makeSession(result);
            setSessions(prev => {
                return (prev || []).map(s => s.id == result.id ? newSession : s).sort((a, b) => (new Date(b.updatedAt)).getTime() - (new Date(a.updatedAt)).getTime());
            });

            if (id == currentSessionId) {
                setCurrentSessionId();
                if (shouldChangeUrl) {
                    routeTo("/chat");
                }
            }

        }
        return [result, error]
    }

    async function unArchiveSession(id) {
        const [result, error] = await updateSession(id, { 已归档: false });
        if (!error) {
            const newSession = makeSession(result);
            setSessions(prev => {
                return (prev || []).map(s => s.id == result.id ? newSession : s).sort((a, b) => (new Date(b.updatedAt)).getTime() - (new Date(a.updatedAt)).getTime());
            });
        }
        return [result, error]
    }

    function updateSessionTitle(id, title) {
        return updateSessionAndList(id, { 标题: title });
    }

    async function updateSessionAndList(id, formData) {
        const [result, error] = await updateSession(id, formData);
        if (!error) {
            setSessions(prev => {
                return prev.map(s => s.id == result.id ? makeSession(result) : s).sort((a, b) => (new Date(b.updatedAt)).getTime() - (new Date(a.updatedAt)).getTime());
            })
        }
        return [result, error]
    }

    // load all the sessions
    useEffect(() => {
        loadSessions(userId);
    }, []);


    function changeSession(sessionId) {
        // 
        setCurrentSessionId(sessionId);
        if (shouldChangeUrl) {
            routeTo("/chat/" + sessionId);
        }
    }

    function newSession() {
        setCurrentSessionId(null);
        if (shouldChangeUrl) {
            routeTo("/chat");
        }
    }

    const { reloadUsageQuota } = useContext(UsageQuotaContext) || {};

    const sendMessageToSessionRef = useRef();
    const messageToSendRef = useRef();

    async function startSession({ message, userOptions, contentOptions, persona, chatModel, images, }) {

        if(reloadUsageQuota) {
            const remainingQuota = await reloadUsageQuota();
            if(remainingQuota !== undefined && remainingQuota < 0) {
                toast({ title: "您已用完今天的限额"});
                return;
            }
        }

        // 类似于 sendMessage 没有 currentSession
        // 先创建一个 sessoion，再向新的 Session 发送消息

        const [session, error] = await createSession(userOptions, contentOptions, persona && persona.id, chatModel);
        if (!error && session) {
            // 
            setCurrentSessionId(session.id);

            const newSession = { ...makeSession(session), firstMessage: message };

            setSessions(prev => (
                [newSession, ...prev]
            ))

            if (sendMessageToSessionRef.current) {
                sendMessageToSessionRef.current(message, images, newSession);
            } else {
                // 还没有 mount，等一下 sent
                messageToSendRef.current = [message, images, newSession];
            }

            if (shouldChangeUrl) {
                routeTo("/chat/" + session.id);
            }
        }
    }

    useEffect(() => {
        if (sendMessageToSessionRef.current && messageToSendRef.current) {
            const [message, images, session] = messageToSendRef.current;
            sendMessageToSessionRef.current(message, images, session);
            messageToSendRef.current = null;
        }

    }, [sendMessageToSessionRef.current])




    // // 
    // const [pinnedPersonaList, setPinnedPersonaList] = useState();
    // async function loadPinnedPersonas() {
    //     const [result, error] = await facade.list({
    //         entityName: "OpenAI-会话角色",
    //         fieldPaths: personaFieldPaths,
    //         sort: [{ field: "置顶", order: "DESC" }, { field: "显示权重", order: "DESC" }],
    //         condition: [[{ field: "已归档", op: "ne", value: true }, { field: "置顶", op: "eq", value: true }]],
    //         pageSize: 1000,
    //     });
    //     if (!error) {
    //         setPinnedPersonaList(result);
    //     }
    // }

    const [personaList, setPersonaList] = useState();
    async function loadPersonaList() {
        const [result, error] = await facade.list({
            entityName: "OpenAI-会话角色",
            fieldPaths: personaFieldPaths,
            sort: [{ field: "置顶", order: "DESC" }, { field: "显示权重", order: "DESC" }],
            condition: [[{ field: "已归档", op: "ne", value: true }]],
            pageSize: 1000,
        });
        if (!error) {
            setPersonaList(result);
        }
    }


    useEffect(() => {
        loadPersonaList()
    }, []);

    function onPersonaUpdate(r) {

        setPersonaList(l => {
            return l && l.map(p => p.id == r.id ? r : p)
        });
        setSessions(l => {
            return l && l.map(s => {
                if (s.persona && s.persona.id == r.id) {
                    return { ...s, persona: r }
                }
                return s
            })
        })
    }

    function renderPersonaList(personalList, usedInDrawer, withNewButton, onSelect) {

        return (
            <div className="py-3 px-1.5 flex flex-col gap-1 border-b-[1px] mx-1 lc-base">
                {
                    personalList.map(p => {
                        return (
                            <Pressable key={p.id} onPress={_ => {
                                if (usedInDrawer) {
                                    closeSessionListDrawer()
                                }
                                routeTo(addQueryParam("pid", p.id, "/chat"))

                                if (onSelect) {
                                    setTimeout(() => {
                                        onSelect(p.id);
                                    }, 200)
                                }

                            }}>
                                <div className={classNames("flex gap-2 px-2.5 py-2 items-center hover:bg-[var(--gray3)] cursor-pointer rounded", {
                                    "bg-[var(--gray2)]": p.id == personaId
                                })}>
                                    <div className="flex gap-2 grow items-center">
                                        <Avatar avatar={p.头像} nickName={p.名称} size={25} />
                                        <ClampedText>{p.名称}</ClampedText>
                                    </div>
                                    <i className='bx bx-chevron-right text-[var(--gray10)]'></i>
                                </div>
                            </Pressable>
                        )
                    })
                }
                { withNewButton && viewEnv.webEnv.currentURLPath !== "/chat/characters" ? (
                    <Pressable onPress={_ => {
                        if (usedInDrawer) {
                            closeSessionListDrawer()
                        }
                        routeTo("/chat/characters")
    
                        if (onSelect) {
                            setTimeout(() => {
                                onSelect();
                            }, 200)
                        }
    
                    }}>
                        <div className={classNames("flex gap-2 px-2.5 py-2 items-center hover:bg-[var(--gray3)] bg-[var(--gray2)] border cursor-pointer rounded min-w-[180px]", {
                            // "bg-[var(--gray2)]": p.id == personaId
                        })}>
                            <div className="flex gap-2 grow items-center px-2 ">
                                <div>创建角色</div>
                            </div>
                            <i className='bx bx-plus text-[var(--gray10)]'></i>
                        </div>
                    </Pressable>
                ) : null }
            </div>
        )
    }



    const pinnedPersonaList = personaList && personaList.filter(p => p.置顶);

    function renderSessionList(usedInDrawer, { setMenuCollapsed, menuCollapsed }) {

        return (
            <ChatSessionList {...{
                usedInDrawer,
                sessions: sessions.filter(s => !s.isArchived),
                newSession,
                currentSessionId, closeSessionListDrawer, changeSession,
                archiveSession, unArchiveSession, updateSessionTitle,

                toggleMenuCollapsed: setMenuCollapsed,
                menuCollapsed,

                additionalPanel: pinnedPersonaList && pinnedPersonaList.length > 0 ? renderPersonaList(pinnedPersonaList, usedInDrawer) : null,

                renderPersonaList: onSelect => (
                    <div className="max-w-[240px] font-size-14">
                        {renderPersonaList(personaList || [], usedInDrawer, true, onSelect)}
                    </div>
                ),


                loadMore,
                isLoadingMore: loadingMore
            }} />
        )
    }


    function renderNewSessionTip() {

        return (
            <ChatNewSession {...{
                facade, currentUserId: userId, maxInputCount, startSession, viewEnv, scene,

                personaId,

                botAvatarUrl, userAvatar, userNickName,

                onPersonaUpdate,

            }} />
        )
    }


    const mainContent = (
        <>
            {currentSessionId ? null : (
                givenCurrentSessionId == "characters" ? (
                    <PersonaManagement {...{
                        currentUserId: userId, facade, viewEnv, onPersonaPin: _ => loadPersonaList()
                    }} />
                ) : renderNewSessionTip()
            )}
            <ChatStream {...{
                currentSessionId,
                currentSession,
                updateSessionAndList,

                refreshSession,

                botAvatarUrl, userAvatar, userNickName,
                userId,
                instructionPrompt,
                instructionPromptForLimitedKnowledge,

                maxInputCount,

                facade, viewEnv,


                onPersonaUpdate,


                imageEnabled: true,

                getPossibleQuestions: (session, messages) => {

                    const { possibleQuestionsEnabled } = session.userOptions || {};
                    if (!possibleQuestionsEnabled) {
                        return []
                    }
                    // if(possibleQuestionLoading[session.id]) {
                    //     return []
                    // }

                    const lastMessage = messages.length > 0 && messages[messages.length - 1];

                    if (lastMessage && lastMessage.messageType == "newTopic") {
                        return []  // TODO 或者是角色的 starter
                    }

                    return (possibleQuestions[session.id] || []).filter(q => {
                        return !messages.find(m => m.content && m.content.trim() == q.trim());
                    }).slice(0, 4);

                },

                bindSendMessageToSession: sendMessageToSession => sendMessageToSessionRef.current = sendMessageToSession,

            }} />
        </>

    )


    return (
        <MenuAndMain {... {
            mainContent,
            closeMenuDrawer: closeSessionListDrawer,
            menuDrawerVisible: sessionListDrawerOpen,
            renderMenu: renderSessionList,

            collapsedMenuIcon: (
                <i className='bx bx-chat'></i>
            ),

            mainContentClassName: "lc-chat-ui-main-part",

            resizeKey: "chat-ui",
        }} />
    )

}


