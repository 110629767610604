

import React, { useEffect, useState } from 'react';
import { Editor, Transforms, Text } from 'slate'

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { ReactEditor } from 'slate-react';
import { MdFormatColorText, MdKeyboardArrowDown } from 'react-icons/md'

import { isIOS, isMobile } from 'bwax/clientEnv'

const presetColors = ['default', 'tomato', 'red', 'crimson', 'pink', 'plum', 'purple', 'violet', 'indigo', 'blue', 'cyan', 'teal', 'green', 
    'grass', 'orange', 'brown', 'sky', 'mint', 'lime', 'yellow', 'amber', 'bronze', 'gold']

const lastUsedColorKey = "editor-last-used-color"

function getLocalItem (key) {
    if(typeof(localStorage) !== 'undefined') {
        return JSON.parse(localStorage.getItem(key)) || null
    } else {
        return null
    }
}

export default function ColorButton(props) {

    const { editor, bindOverrideContent, setBrickToolbarInfo, mobileToolbarRef } = props

    const [colorsListOpen, setColorsListOpen] = useState(false)
    const [lastUsedColor, setLastUsedColor] = useState(null)

    useEffect(() => {
        const localColor = getLocalItem(lastUsedColorKey)
        setLastUsedColor(localColor)
    }, [])

    const { selection } = editor
    
    if(!selection) {
        return null
    }

    const marks = Editor.marks(editor)

    function changeColor (colorObj) {
        setLastUsedColor(colorObj)

        const colorValue = Object.values(colorObj)[0]
        const matchResult = colorValue.match(/^var\(--(\D*)\d+\)$/)

        if(matchResult) {
            const colorKeyword = matchResult[1]
            if(colorKeyword === 'default') {
                Editor.removeMark(editor, 'background')
                Editor.removeMark(editor, 'color')

                return 
            }
        }

        if(colorObj.color && marks && marks.background) {
            Editor.removeMark(editor, 'background')
        } else if (colorObj.background && marks && marks.color) {
            Editor.removeMark(editor, 'color')
        }

        Transforms.setNodes(
            editor,
            colorObj,
            { match: Text.isText, split: true }
        )

        if(typeof(localStorage) !== 'undefined') {
            localStorage.setItem(lastUsedColorKey, JSON.stringify(colorObj));
        }

        ReactEditor.focus(editor)
    }

    function renderColoredText (isInToolbar, colorObj) {
        const colorStyle = colorObj ? {...colorObj} : {}
        const classNameInOption = 'px-1.5 leading-[1.375rem] text-[16px] shadow-[inset_0_0px_0px_1px_rgba(15,15,15,0.1)] rounded'
        return (
            <div className={`font-bold  ${isInToolbar ? 'px-1 text-[15px] leading-[18px]' : classNameInOption}`}
                style={{ ...colorStyle }}>
                A
            </div>
        )
    }

    function getMarksColor () {
        if(marks) {
            const { color, background } = marks
            return color ? { color } : (background ? { background } : {})
        } else return {}
    }

    function renderDropDownLabel (label, extraMarginClass) {
        return (
            <DropdownMenu.Label className={`font-medium text-xs pl-[11px] mb-2 text-[var(--gray9)] ${extraMarginClass}`}>
                {label}
            </DropdownMenu.Label>
        )
    }

    function getColorStr (colorObj) {
        if(colorObj) {
            const colorValue = Object.values(colorObj)[0]
            const matchResult = colorValue.match(/^var\(--(\D*)\d+\)$/)

            if(matchResult) {
                const colorKeyword = matchResult[1]
                return colorKeyword.slice(0, 1).toUpperCase() + colorKeyword.slice(1) + 
                    (colorObj.background ? ' background' : '')
            } else return ''
        } else return ''
    }

    function renderDropDownItem (key, colorObj) {
        
        return (
            <DropdownMenu.Item key={key} className="context-menu-item" 
                onSelect={() => changeColor(colorObj)}
            >
                <div className="flex mr-auto items-center">
                    {renderColoredText(false, colorObj)}
                    <div className='ml-2.5 text-sm'>{getColorStr(colorObj)}</div>
                </div>
            </DropdownMenu.Item>
        )
    }
 
    const pcButton = (
        <DropdownMenu.Root modal={false} onOpenChange={open => {
            bindOverrideContent({ persistent: open })
            if(open) {
                setBrickToolbarInfo({ show: false })
            }
        }}>
            <DropdownMenu.Trigger asChild>
                <button className="editor-button break-keep gap-1 !shadow-none">
                    {renderColoredText(true, getMarksColor())}
                    <MdKeyboardArrowDown/>
                </button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
                <DropdownMenu.Content
                    className="lc-context-menu lc-base !overflow-scroll max-h-[var(--radix-popper-available-height)]"
                    sideOffset={5}
                >
                    {
                        lastUsedColor ? (
                            <>
                                {renderDropDownLabel('Last used')}
                                {renderDropDownItem('last-used', lastUsedColor)}
                            </>
                        ) : null
                    }
                    { renderDropDownLabel('Color', lastUsedColor ? 'mt-3' : '') }
                    {
                        presetColors.map(color => {
                            return renderDropDownItem(color, {color: `var(--${color}11)`})
                        })
                    }
                    { renderDropDownLabel('Background', 'mt-3') }
                    {
                        presetColors.map(color => {
                            return renderDropDownItem(color, {background: `var(--${color}3)`})
                        })
                    }
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
            
        </DropdownMenu.Root>
    )

    function changeMobileToolbarStyle () {
        if(mobileToolbarRef && mobileToolbarRef.current) {
            if(mobileToolbarRef.current.style['zIndex']) {
                mobileToolbarRef.current.style['zIndex'] = ''
                mobileToolbarRef.current.style['overflowX'] = 'scroll'
            } else {
                mobileToolbarRef.current.style['zIndex'] = 1
                // iOS 端，在 overflow scroll 容器内的 fixed 元素无法充满窗口
                mobileToolbarRef.current.style['overflowX'] = 'hidden' 
            }
        }
    }

    function showColorsList () {
        ReactEditor.blur(editor)
        setColorsListOpen(true)
        changeMobileToolbarStyle()
    }

    function renderMobileGroup (label, colors) {
        return (
            <div className='pt-7 bg-[var(--gray2)]'>
                <div className='font-medium text-xs px-4 mb-2 text-[var(--gray9)]'>{label}</div>
                {
                    colors.map(color => {
                        const colorObj = label === 'Color' ? {color: `var(--${color}11)`} : 
                            label === 'Background' ? {background: `var(--${color}3)`} : color
                        return (
                            <div key={color} className='upward-menu-item bg-white' 
                                onClick={() => {
                                    setColorsListOpen(false)
                                    changeColor(colorObj)
                                    changeMobileToolbarStyle()
                                }}
                            >
                                <div className="flex ml-1 items-center">
                                    {renderColoredText(false, colorObj)}
                                </div>
                                <div className='ml-3.5 text-base'>{getColorStr(colorObj)}</div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const mobileButton = (
        <>
            <button className="editor-button" onClick={() => {
                showColorsList()
                const selection = window.getSelection()
                selection.removeAllRanges();
            }}>
                {renderColoredText(true, getMarksColor())}
            </button>
            {
                colorsListOpen ? (
                    <div className={`editor-mobile-upward-menu ${colorsListOpen ? 'show' : ''}`} 
                        style={isIOS() ? { marginTop: 48 } : {}}
                    >
                        
                        <div className='header !mb-0'>
                            <div className='font-bold my-0 mx-auto'>Text color</div>
                            <div className='absolute right-3.5' style={{ color: "var(--blue9)" }} 
                                onClick={() => {
                                    setColorsListOpen(false)
                                    changeMobileToolbarStyle()
                                }
                            }>
                                Cancel
                            </div>
                        </div>
                        <div className='items-container'>
                            { lastUsedColor ? renderMobileGroup('Last used', [lastUsedColor]) : null }
                            { renderMobileGroup('Color', presetColors)}
                            { renderMobileGroup('Background', presetColors)}
                        </div>
                    </div>
                ) : null
            }
        </>
       
    )

    return !isMobile() ? pcButton : mobileButton
}
