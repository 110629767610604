
import React from "react";

import Avatar, { colors, stringAsciiPRNG } from "bwax-ui/components/Avatar";
import numeral from 'numeral';
import dayjs from 'dayjs';
import WindowScrollHelper from 'bwax-ui/ml/widget/impl/misc/WindowScrollHelper';

import getImageURL from 'bwax/util/getImageURL';

export default function Logo({ logo, name, size = 48, className }) {

    if (logo) {
        return (
            <div className={"rounded-md shadow " + className} style={{
                backgroundImage: `url(${getImageURL(logo, "thumbnail")})`,
                width: size,
                height: size,
                minWidth: size,
                backgroundPosition: "center",
                backgroundSize: "cover",
            }} />
        )
    } else {

        const colorName = colors[stringAsciiPRNG(name, colors.length)];
        const color = `var(--${colorName}4)`

        // const isLight = lightOrDark(color) == "light"
        const isLight = true;

        // 最多两个字符
        const abbr = name.split(" ").map(n => n.substring(0, 1)).join("").substring(0, 2).toUpperCase();

        return (
            <div className={"flex items-center justify-center rounded-md shadow " + className} style={{
                width: size,
                height: size,
                minWidth: size,

                backgroundColor: color,

                fontSize: size,

                // fontWeight: "bold",
                ...(isLight ? { color: `var(--${colorName}12)` } : { color: "--lc-color-inversed" }),
            }}>
                <span style={{ fontSize: "0.4em" }}>{abbr}</span>
            </div>
        )
    }


}


