
import { useState, useRef } from 'react';

export default function useStateWithLocalCache (key, defaultValue, { parse, stringify } = {}) {

    const [ value, setValue ] = useState(() => {
        const stored = key ? getLocalItem(key, parse) : undefined;
        if(stored !== undefined) {
            return stored
        } else if(typeof(defaultValue) === 'function') {
            return defaultValue()
        } else {
            return defaultValue
        }
    })

    function updateValue (v){
        if(typeof(v) === 'function') {
            setValue(prev => {
                const newValue = v(prev);
                if(key){
                    updateLocalItem(key, newValue, stringify);
                }
                
                return newValue
            })
        } else {
            if(key) {
                updateLocalItem(key, v, stringify);
            }
            setValue(v);
        }
    }

    return [ value, updateValue ]

}

const NOT_INIT = "___not_initialized___";
export function useRefWithLocalCache (key, defaultValue, { parse, stringify } = {}) {

    const valueRef = useRef(NOT_INIT);
    if(valueRef.current === NOT_INIT) {
        valueRef.current = (() => {
            const stored = key ? getLocalItem(key, parse) : undefined;
            if(stored) {
                return stored
            } else if(typeof(defaultValue) === 'function') {
                return defaultValue()
            } else {
                return defaultValue
            }
        })();
    }

    function updateValue (v){
        if(typeof(v) === 'function') {
            const newValue = v(valueRef.current);
            if(key){
                updateLocalItem(key, newValue, stringify);
            }
            valueRef.current = newValue;

        } else {
            if(key) {
                updateLocalItem(key, v, stringify);
            }
            valueRef.current = v;
        }
    }


    return [ _ => valueRef.current, updateValue ]

}




export function getLocalItem(key, parse) {
    if (typeof (localStorage) !== 'undefined') {
        const stored = localStorage.getItem(key)
        const f = parse || JSON.parse;
        return stored ? f(stored) : undefined;
    } else {
        return undefined
    }
}
export function updateLocalItem(key, value, stringify) {
    if(typeof(localStorage) !== 'undefined'){
        if(value === undefined){
            localStorage.removeItem(key)
        } else {
            const f = stringify || JSON.stringify;
            localStorage.setItem(key, f(value));
        }
    }
}